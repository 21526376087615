import styled from 'styled-components'
import variables from 'ca-common/variables'

import {Layout, Menu} from 'antd'

const {Sider} = Layout

export const StyledSider = styled(Sider)`
    height: calc(100vh - ${variables.headerHeight});
    position: fixed;
    left: 0;
    transition: all 0.3s ease-in-out;
    border-right: 1px solid ${variables.navyBorder};
    z-index: 10;
    overflow: auto;
    .ant-menu {
        background: none;
    }
    background: none;
`

export const StyledMenu = styled(Menu)`
    overflow: hidden;
    display: flex;
    height: 100%;
    min-height: 500px;
    flex-direction: column;
    align-items: stretch;
    line-height: 1.5;
    border-right: none;

    .ant-menu-item {
        height: auto;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;

        .anticon {
            font-size: 20px;
            margin-right: 0;
            margin-left: 0;
        }
        a:hover {
            color: ${variables.white};
            text-decoration: none;
        }
    }
    .ant-menu-item:hover {
        color: rgba(0, 0, 0, 0.4);
    }

    .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected,
    .active {
        font-weight: 600;
        background: ${variables.white};
        color: ${variables.navyBlue} !important;
        &.external {
            font-weight: normal;
            background: transparent;
            color: inherit;
            border-right: none;
        }
    }

    .active-menu-item,
    .ant-menu-submenu {
        .ant-menu-submenu-title {
            padding: 0;
            height: auto;
            margin: 0;
            line-height: normal;
        }

        .anticon {
            font-size: 20px;
            margin-right: 0;
            margin-left: 0;
        }
        a:hover {
            text-decoration: none;
        }
    }

    .is-sticky {
        margin-top: auto;
    }

    .is-fake {
        visibility: hidden;
    }
`
