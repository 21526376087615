import React, {useEffect, Fragment} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import Icon from '@ant-design/icons'
import {Form, Checkbox, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {getSvcAccountsDescription} from 'ca-common/utils/text'

import {addRulesToRemovedList, clearRemovedRulesList} from 'src/newcore/redux/modules/backups/bulkActivate'
import {useBackupSettingsFormContext} from 'src/newcore/features/BackupSettings/contexts'

import Info from 'ca-common/icons/Info.svg'

const CustomCheckbox = ({entityName, ...passProps}) => {
    const {t} = useTranslation()

    return (
        <Fragment>
            <Checkbox {...passProps}>{t('forms:backupSettings:fields:detectNewAccounts:label', {entityName})}</Checkbox>

            <Tooltip title={t('forms:backupSettings:fields:detectNewAccounts:tooltip', {entityName})}>
                <Icon component={Info} />
            </Tooltip>
        </Fragment>
    )
}

const ActivateAllNewAccountsRaw = props => {
    const {actions, bulkActivationRules, detectNewAccounts, source} = props
    const form = useBackupSettingsFormContext()

    const handleChange = event => {
        const removedBulkActivationRules = bulkActivationRules?.data.map(rule => rule.id)

        if (event.target.checked) {
            actions.addRulesToRemovedList(removedBulkActivationRules)
        } else {
            actions.clearRemovedRulesList()
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            detectNewAccounts: detectNewAccounts
        })
    }, [detectNewAccounts])

    const entityName = getSvcAccountsDescription(source, true).toLowerCase()

    return (
        <Form.Item name="detectNewAccounts" initialValue={detectNewAccounts} valuePropName="checked">
            <CustomCheckbox onChange={handleChange} entityName={entityName} />
        </Form.Item>
    )
}

const mapStateToProps = state => ({
    bulkActivationRules: state.backups.bulkActivation.bulkActivationRules.rules.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            addRulesToRemovedList,
            clearRemovedRulesList
        },
        dispatch
    )
})

export const ActivateAllNewAccounts = connect(mapStateToProps, mapDispatchToProps)(ActivateAllNewAccountsRaw)
