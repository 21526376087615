import React from 'react'

import {IconComponent, SideMenuItem} from 'ca-common/types'

import './RouterMenuItem.scss'
import {StyledIcon, StyledNavLink, StyledTitle, StyledLink, StyledMenuLink, StyledBadge} from './StyledRouterMenuItem'

type MenuItemProps = Pick<SideMenuItem, 'icon' | 'link' | 'title' | 'target' | 'pureLink' | 'className' | 'badge'>

function isJSX(badge: IconComponent | JSX.Element): badge is JSX.Element {
    return !!(badge as JSX.Element).type
}

const Badge = (props: Pick<MenuItemProps, 'badge'>) => {
    const {badge} = props

    if (!badge) {
        return null
    }

    return isJSX(badge) ? badge : <StyledBadge component={badge} />
}

export const MenuItem = (props: MenuItemProps) => {
    const {badge, icon, link, title, className, target, pureLink} = props

    if (pureLink) {
        return (
            <StyledLink to={link} pureLink target={target} className={className}>
                <Badge badge={badge} />
                {icon && <StyledIcon component={icon} />}
                <StyledTitle>{title}</StyledTitle>
            </StyledLink>
        )
    }

    return (
        <StyledNavLink to={link} className={className}>
            <Badge badge={badge} />
            {icon && <StyledIcon component={icon} />}
            <StyledTitle>{title}</StyledTitle>
        </StyledNavLink>
    )
}

type SubmenuItemProps = Pick<SideMenuItem, 'icon' | 'link' | 'title'>

export const SubmenuItem = (props: SubmenuItemProps) => {
    const {link, icon, title} = props

    return (
        <StyledMenuLink to={link}>
            {icon && <StyledIcon component={icon} />}
            <StyledTitle>{title}</StyledTitle>
        </StyledMenuLink>
    )
}
