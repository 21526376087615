import styled from 'styled-components'
import {rgba, transparentize} from 'polished'
import {Button} from 'antd'
import variables from 'ca-common/variables'

export const Wrapper = styled.div`
    height: 46px;
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    padding: ${variables.gutterXs} ${variables.gutterSm} ${variables.gutterXs} ${variables.gutterSm};
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0 0 ${variables.gutterMd} ${rgba(variables.black, 0.08)};
    border-radius: 2px;
    width: 80%;
    justify-content: space-between;
`

export const ServiceWithIcon = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    overflow: hidden;

    .ant-badge-count {
        background: ${variables.inProcess};
    }
`

export const Description = styled.span`
    color: ${variables.dark};
    font-size: ${variables.fontSizeBase};
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(62.5% - 1px);
    border-left: 1px solid ${variables.blue};
    padding-left: 47px;
    line-height: 31px;
`

export const Service = styled.span`
    color: ${variables.text};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: ${variables.gutterXs};
`

export const StyledNewHomePageButton = styled(Button)`
    display: inline-flex;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: ${variables.text};
    background-color: ${variables.white};
    margin: 10px;
    cursor: pointer;
    border-radius: 8px;
    border-color: ${variables.navyBlue};
    border-width: 1px;

    &:hover {
        background-color: ${transparentize(0.1, variables.interaction)};
        border-color: ${variables.white};
        transition: 0.1s;
    }
`

export const StyledNewWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const StyledTitleText = styled.span`
    color: ${variables.navyBlue};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
`
