import {CHECK_STATE, DataWithChildren, Node} from 'src/newcore/organisms/CustomTree/atoms/Node'

type TreeMapList = Map<string, Node>

export type CheckedIds = {
    checked: Array<string>
    halfChecked: Array<string>
}

export type AdditionalCheckedIds = {
    checkedLeafs?: Array<string>
    checkedConnectedEntities: Array<string>
}

export type CheckedElements = CheckedIds & AdditionalCheckedIds

const getList = (arr: Array<DataWithChildren>, res?: TreeMapList, parent?: Node): TreeMapList => {
    const result: TreeMapList = res ?? new Map()
    arr.forEach(a => {
        let newNode
        if (parent !== undefined) {
            newNode = parent.addChild(a)
        } else {
            newNode = new Node(a)
        }
        result.set(a.key, newNode)
        if (a.children) {
            getList(a.children, result, newNode)
        }
    })
    return result
}

export class TreeMap {
    public list: TreeMapList

    constructor(data: Array<DataWithChildren>) {
        this.list = getList(data)
    }

    private setCheckInChildren(node: Node, flag: boolean): TreeMap {
        node.check = flag ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED
        node.connectedState = flag ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED
        if (node.children) {
            node.children.forEach(child => this.setCheckInChildren(child, flag))
        }
        return this
    }

    private setCheckInParents(node: Node, checkState: CHECK_STATE): TreeMap {
        node.check = checkState

        if (node.connectedState === CHECK_STATE.PARTIAL) {
            node.check = CHECK_STATE.PARTIAL
        }
        if (node.connectedState === CHECK_STATE.CHECKED) {
            node.check = node.getAllChildren().every(el => el === CHECK_STATE.CHECKED)
                ? CHECK_STATE.CHECKED
                : CHECK_STATE.PARTIAL
        }
        if (node.connectedState === CHECK_STATE.UNCHECKED) {
            node.check = node.getAllChildren().every(el => el === CHECK_STATE.UNCHECKED)
                ? CHECK_STATE.UNCHECKED
                : CHECK_STATE.PARTIAL
        }

        if (node.parent) {
            this.setCheckInParents(node.parent, node.check)
        }
        return this
    }

    public setCheckedNodes(id: string, checkState: boolean | CHECK_STATE, connectState?: CHECK_STATE): TreeMap {
        const node = this.list.get(id)
        if (node === undefined) return this

        if (connectState) {
            node.connectedState = connectState
        }
        if (typeof checkState === 'boolean') {
            node.connectedState = checkState ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED
            this.setCheckInChildren(node, checkState).setCheckInParents(node, node.connectedState)
        } else {
            this.setCheckInParents(node, checkState)
        }
        return this
    }

    public setAllCheckedNodes(check: boolean): TreeMap {
        this.list.forEach(el => {
            const checkStatus = check ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED
            el.check = checkStatus
            el.connectedState = checkStatus
        })
        return this
    }

    public setPartialAndCheckedNodes({checked, halfChecked}: CheckedIds): TreeMap {
        checked.forEach(el => {
            const node = this.list.get(el)
            if (node !== undefined) {
                node.check = CHECK_STATE.CHECKED
            }
        })
        halfChecked.forEach(el => {
            const node = this.list.get(el)
            if (node !== undefined) {
                node.check = CHECK_STATE.PARTIAL
            }
        })
        return this
    }

    public getExpandedNodes(id: string, arr?: Array<string>): Array<string> {
        const node = this.list.get(id)
        const result = arr ?? []
        if (node === undefined) return []
        result.push(node.value)
        if (node.children) {
            for (const i in node.children) {
                this.getExpandedNodes(node.children[i].value, result)
            }
        }
        return result
    }

    public getCheckedNodes(): CheckedElements {
        const check: Array<string> = []
        const half: Array<string> = []
        const checkedLeafs: Array<string> = []
        const checkedConnectedEntities: Array<string> = []
        this.list.forEach(el => {
            if (el.check === CHECK_STATE.CHECKED) {
                check.push(el.value)
                if (el.getAllChildren().length === 0) {
                    checkedLeafs.push(el.value)
                }
            }
            if (el.check === CHECK_STATE.PARTIAL) half.push(el.value)
            if (el.connectedState === CHECK_STATE.CHECKED) checkedConnectedEntities.push(el.value)
        })
        return {
            checked: check,
            halfChecked: half,
            checkedLeafs,
            checkedConnectedEntities
        }
    }

    public getFilteredNodes(str: string): Array<string> {
        const result: Array<string> = []
        const subStr = str.toLowerCase()

        if (subStr.length > 0) {
            this.list.forEach(el => {
                if (el.title.toLowerCase().includes(subStr)) result.push(el.value)
            })
        }
        return result
    }
}
