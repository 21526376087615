import styled from 'styled-components'
import variables from 'ca-common/variables'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${variables.mainFont};
    font-size: 12px;
    font-weight: 400;
`

export const Header = styled.div`
    padding: 8px 12px 0;
    border-bottom: 1px solid ${variables.navyBorder};

    > * {
        margin-bottom: 4px;
    }
`

export const Content = styled.div`
    padding: 0 12px 8px;
    font-size: 12px;

    > * {
        margin-top: 4px;
    }
`

export const Total = styled.div`
    color: ${variables.navyBlue};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
`

export const Date = styled.div`
    color: ${variables.asphalt};
`
