import React, {useState} from 'react'
import type {TableProps} from 'antd/lib/table/Table'
import type {Key} from 'antd/lib/table/interface'

import {SOURCE} from 'ca-common/common/enum'
import type {Id, MS365SubSource} from 'ca-common/types'

import type {TableItemState} from 'src/newcore/features/Recovery/localState/localState'
import type {TableDataItems} from 'src/newcore/features/Recovery/fetch'
import type {UIEntity} from 'src/newcore/features/Recovery/lib/types'

import {getIsExpandable} from 'src/newcore/features/Recovery/lib'
import {ExpandedRow} from 'src/newcore/features/Recovery/molecules/ExpandedRow'
import {CustomExpandIcon} from 'src/newcore/features/Recovery/atoms'

import {StyledRecoveryItemsTable} from './StyledRecoveryItemsTable'
import {EVENT_NAMES, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {getSourceName} from 'ca-common/utils/sources'

const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactNode
): React.ReactNode => {
    if (type === 'prev') {
        return <a>Prev</a>
    }

    if (type === 'next') {
        return <a>Next</a>
    }
    return originalElement
}

type SelectHandler = (
    keys: Array<Id>,
    checkedId: Id | null,
    uncheckedId: Id | null,
    itemsInfo: Record<Id, UIEntity>
) => void

export type RecoveryItemsTableProps<TableDataItems> = TableProps<TableDataItems> & {
    selectHandler: SelectHandler
    headerCheckbox: JSX.Element | null
    checkedItems: TableItemState
    tablePlaceholder: JSX.Element
    selectedSubSource: MS365SubSource
    hasPermissionToExpand?: boolean
    source: SOURCE
}

export const RecoveryItemsTable = <T extends TableDataItems>(props: RecoveryItemsTableProps<T>): JSX.Element => {
    const {
        rowSelection,
        dataSource,
        selectHandler,
        headerCheckbox,
        checkedItems,
        pagination,
        tablePlaceholder,
        selectedSubSource,
        hasPermissionToExpand,
        source
    } = props

    const [expandedKeys, setExpandedKeys] = useState<string[]>([])

    const setAllSelected = () => {
        const selectedKeys: Array<string> = []
        if (dataSource !== undefined) {
            if (checkedItems.unchecked.length > 0) {
                dataSource.forEach(({entityId}) => {
                    if (!checkedItems.unchecked.includes(entityId)) {
                        selectedKeys.push(entityId)
                    }
                })
            } else {
                dataSource.forEach(({entityId}) => selectedKeys.push(entityId))
            }
        }
        return selectedKeys
    }

    const checkedKeys = checkedItems ? (checkedItems.checked === true ? setAllSelected() : checkedItems.checked) : []

    const onSelectChange = function (selectedRowKeys: Key[], items: Array<UIEntity>) {
        let uncheckedId = null
        let checkedId = null
        const itemsInfo: Record<Id, UIEntity> = {}
        items.forEach(item => (itemsInfo[item.entityId] = item))
        if (checkedKeys.length > selectedRowKeys.length) {
            uncheckedId = checkedKeys.filter(el => !selectedRowKeys.includes(el))[0]
        }
        if (checkedKeys.length < selectedRowKeys.length) {
            checkedId = selectedRowKeys.filter(el => !checkedKeys.includes(el as string))[0]
        }
        selectHandler(selectedRowKeys as Array<string>, checkedId as string, uncheckedId, itemsInfo)
    }

    const isExpandable = getIsExpandable(selectedSubSource, hasPermissionToExpand)

    const onRowHandler = (record: {entityId: string}) => {
        return expandedKeys.includes(record.entityId) ? {className: 'expand-parent'} : {}
    }

    return (
        <StyledRecoveryItemsTable
            {...props}
            pagination={{itemRender: itemRender, ...pagination}}
            rowClassName={(record: T, index: number) => (index % 2 !== 0 ? 'table-row-blue' : '')}
            locale={{
                emptyText: tablePlaceholder
            }}
            rowSelection={{
                columnWidth: 50,
                columnTitle: headerCheckbox,
                onChange: onSelectChange,
                selectedRowKeys: checkedKeys,
                preserveSelectedRowKeys: true,
                ...rowSelection
            }}
            expandable={{
                expandIcon: props => (isExpandable ? <CustomExpandIcon {...props} /> : null),
                expandedRowRender: record => <ExpandedRow record={record} selectedSubSource={selectedSubSource} />,
                rowExpandable: () => isExpandable,
                expandedRowKeys: expandedKeys,
                onExpand: (expanded, record) => {
                    if (expanded) {
                        track(EVENT_NAMES.RECOVERY_PAGE_ITEM_EXPANDED, {
                            Service: getServiceNameMixpanel(source),
                            'Sub Service Name': getSourceName(selectedSubSource),
                            'View Type': 'Browse'
                        })

                        setExpandedKeys(values => {
                            return [...values, record.entityId]
                        })
                    } else {
                        setExpandedKeys(values => {
                            return values.filter(i => i !== record.entityId)
                        })
                    }
                }
            }}
            showSorterTooltip={false}
            scroll={{...props.scroll, x: dataSource?.length ? 1050 : 0}}
            onRow={onRowHandler}
        />
    )
}
