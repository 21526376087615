import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledTileView = styled.div`
    @media screen and (min-width: ${variables.screenLg}) {
        grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    }
    display: grid;
    grid-gap: 12px;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fit, minmax(284px, 410px));
`
