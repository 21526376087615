import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    font-size: 24px;
    color: ${variables.navyBlue};
`

export const StyledEdit = styled(StyledIcon)`
    margin-left: 16px;
`
