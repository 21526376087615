import {post} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SFC_OBJECT_HISTORY = 'GET_SFC_OBJECT_HISTORY'
const GET_SFC_OBJECT_HISTORY_API = 'getSfcObjectHistory'

const sfcObjectHistoryWrapper = fetchWrapperRT(
    GET_SFC_OBJECT_HISTORY,
    async data => await post(GET_SFC_OBJECT_HISTORY_API, data)
)

export const getSfcObjectHistory = sfcObjectHistoryWrapper.fetcher

const sfcObjectHistorySlice = createSlice({
    name: 'sfcObjectHistory',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSfcObjectHistory: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => sfcObjectHistoryWrapper.makeReducers(builder)
})

export const {clearSfcObjectHistory} = sfcObjectHistorySlice.actions
export const sfcObjectHistory = sfcObjectHistorySlice.reducer
