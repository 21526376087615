import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledBulkCondition = styled.div`
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-right: ${variables.gutterSm};
    margin-top: ${variables.gutterMd};
    margin-bottom: ${variables.gutterXs};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const BulkConditionGroup = styled.div`
    height: auto;
    padding: 8px 12px;
    border-radius: ${variables.borderRadiusBase};
    line-height: ${variables.gutterSm};
    text-align: left;
    min-width: 320px;
    border: 1px solid ${variables.blue};
`

export const DeleteIcon = styled(Icon)`
    font-size: 24px;
    margin-left: 10px;
    position: absolute;
    right: 10px;
`

export const WarningIcon = styled(Icon)`
    && {
        color: ${variables.orange};
        font-size: 24px;
    }
`

export const PopconfirmTitle = styled.p`
    margin-left: ${variables.gutterMd};
    margin-bottom: 12px;
    max-width: 170px;
`

export const Title = styled.span`
    margin-right: ${variables.gutterXs};
`

export const BulkConditionTitle = styled.div`
    margin-bottom: 4px;
`

export const ValuesWrapper = styled.div`
    font-weight: 600;
    margin: 0;
    display: flex;
    white-space: pre-wrap;
    line-height: 20px;
`

export const Values = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 230px;
`
