import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const EditIcon = styled(Icon)`
    cursor: pointer;
    margin-left: ${variables.gutterXs};
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
    width: 20px;

    svg {
        fill: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 2;
    }

    :hover {
        &:after {
            content: '';
            border-radius: 50%;
            width: 20px;
            height: 20px;
            position: absolute;
            transition: all 0.2s;
            background: ${variables.interaction};
        }
    }
`

export const SourceName = styled.span`
    color: ${variables.navyBlue};
    margin-bottom: 0;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const TaskNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`
