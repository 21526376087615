import i18n from 'i18next'
import {ReactSVGElement} from 'react'

import {TASK_STATUS, TaskStatus} from 'ca-common/constants'
import {TASK_STATUS as DEEP_TASK_STATUS} from 'ca-common/common/enum/TaskStatus'

import NotActive from 'ca-common/icons/backupStatus/NotActive.svg'
import Scheduled from 'ca-common/icons/backupStatus/Scheduled.svg'
import InProcess from 'ca-common/icons/backupStatus/InProcess.svg'
import Failed from 'ca-common/icons/backupStatus/Failed.svg'
import Paused from 'ca-common/icons/backupStatus/Paused.svg'
import Archived from 'ca-common/icons/backupStatus/Archived.svg'
import Partial from 'ca-common/icons/backupStatus/Partial.svg'
import Duplicate from 'ca-common/icons/backupStatus/Duplicate.svg'
import Success from 'ca-common/icons/backupStatus/Success.svg'

export const getStatusIcon = (status: DEEP_TASK_STATUS): ReactSVGElement | null => {
    switch (status) {
        case DEEP_TASK_STATUS.NOT_ACTIVE:
            return NotActive
        case DEEP_TASK_STATUS.SCHEDULED:
            return Scheduled
        case DEEP_TASK_STATUS.SUCCESS:
            return Success
        case DEEP_TASK_STATUS.IN_PROCESS:
        case TASK_STATUS.PENDING:
            return InProcess
        case DEEP_TASK_STATUS.FAILED:
            return Failed
        case DEEP_TASK_STATUS.PAUSED:
            return Paused
        case DEEP_TASK_STATUS.ARCHIVED:
            return Archived
        case DEEP_TASK_STATUS.PARTIAL:
            return Partial
        case DEEP_TASK_STATUS.DUPLICATE:
            return Duplicate
        default:
            return null
    }
}

const dropUnusedStatus = (status: keyof typeof TASK_STATUS | string) =>
    ![TASK_STATUS.QUEUED, TASK_STATUS.DISPATCHED, TASK_STATUS.SKIPPED].includes(status)
const getStatusOption = (status: keyof typeof TASK_STATUS | string) => ({
    label: i18n.t(`backups:taskStatus:${status}`),
    value: status
})

export const getOptionsFromTaskStatus = (): {label: string; value: string}[] =>
    Object.keys(TaskStatus).filter(dropUnusedStatus).map(getStatusOption)
