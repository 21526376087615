import React, {Component} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {formValueSelector, change} from 'redux-form'

import {CheckboxTree} from 'ca-common/ui-lib/components/CheckboxTree'

class GeneralListRaw extends Component {
    componentDidUpdate = prevProps => {
        const {dashboard, isDashboardDependsActivated, actions} = this.props

        if (isDashboardDependsActivated !== prevProps.isDashboardDependsActivated) {
            if (isDashboardDependsActivated && !dashboard) {
                actions.setGeneralSubValue('DASHBOARD', true)
            }
        }
    }

    render = () => {
        const {data, ...passThroughProps} = this.props

        return <CheckboxTree data={this.fixDisableState(data)} {...passThroughProps} />
    }

    fixDisableState = data => {
        const {isDashboardDependsActivated} = this.props

        return {
            ...data,
            sub: data.sub.map(i => ({
                ...i,
                disabled: this.fixDashboardTask(i.name, isDashboardDependsActivated)
            }))
        }
    }

    fixDashboardTask = (name, disabled) => {
        return name === 'DASHBOARD' && disabled
    }
}

const mapStateToProps = (state, {form}) => {
    const selector = formValueSelector(form)
    const getGeneralSubValue = name => selector(state, `permissions.ppGeneral.sub.${name}`)

    return {
        isDashboardDependsActivated: ['DASHBOARD_ACCOUNT', 'DASHBOARD_SUBSCRIBE'].some(getGeneralSubValue),
        dashboard: getGeneralSubValue('DASHBOARD')
    }
}

const mapDispatchToProps = (dispatch, {form}) => ({
    actions: {
        ...bindActionCreators(
            {
                setGeneralSubValue: (name, value) => dispatch(change(form, `permissions.ppGeneral.sub.${name}`, value))
            },
            dispatch
        )
    }
})

export const GeneralList = connect(mapStateToProps, mapDispatchToProps)(GeneralListRaw)
