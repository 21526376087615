import {i18n} from 'src/locales'

import {TRIGGERED_BY_ALL, TRIGGERED_BY_SINGLE} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getSmartAlertTriggeredByFriendly = triggered => {
    switch (triggered) {
        case TRIGGERED_BY_SINGLE:
            return i18n.t('smartAlerts:triggeredBy:singleUser')

        case TRIGGERED_BY_ALL:
            return i18n.t('smartAlerts:triggeredBy:allUsers')

        default:
            return ''
    }
}
