import React from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import CAInput from '../../Input'

const dumbFormGroup = dumbBem('ca-form-group')
const FormGroup = tx(dumbFormGroup)('div')
const Error = tx([{element: 'error'}, dumbFormGroup])('span')

export class Input extends React.Component {
    render() {
        const {
            input,
            htmlFor,
            meta: {touched, error, warning},
            ...rest
        } = this.props

        const fieldModifier = touched && error ? 'error' : ''

        return (
            <FormGroup modifier="default">
                <CAInput {...input} {...rest} modifier={fieldModifier} id={htmlFor} />
                {touched && ((error && <Error>{error}</Error>) || (warning && <span>{warning}</span>))}
            </FormGroup>
        )
    }
}
