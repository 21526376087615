import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledContainer = styled.div`
    width: 416px;
    display: flex;
`

export const StyledHeader = styled.h2`
    margin-top: ${variables.gutterXxl};

    font-size: ${variables.mainTitleFontSize};
    font-family: ${variables.publicPageFont};
    text-align: center;
`

export const StyledContentContainer = styled(StyledContainer)`
    flex-direction: column;
    padding: 0 ${variables.gutterXxl} ${variables.gutterXxl};
`

export const StyledText = styled.span`
    text-align: center;
    color: ${variables.navyAsphalt};
    font-size: ${variables.subTitleFontSize};
`
