import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useLocation, useRouteMatch} from 'react-router'
import {useTranslation} from 'react-i18next'
import queryString from 'query-string'
import _ from 'lodash'

import {TASK_STATUS} from 'ca-common/common/enum/TaskStatus'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {checkPermission} from 'ca-common/utils/permissions'

import {PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {performEntityAction} from 'src/newcore/redux/modules/backups'

import {BackupActivate, BackupCancel, BackupPause, BackupNow} from '../BackupActions'
import {StyledBackupActions} from './StyledBackupActions'
import {SOURCE, TASK_ACTION} from 'ca-common/common/enum'
import {openErrorModal} from 'ca-common/utils/modals'
import {AppState, useAppDispatch} from 'src/newcore/components/Root'
import {getServiceNameMixpanel, track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

type BackupActionsProps = {
    performEntities: Array<string>
    taskInfo: {
        status: TASK_STATUS
    }
    source: SOURCE
} & ReturnType<typeof mapStateToProps>

const BackupActionsRaw = ({taskInfo, userInfo, source, performEntities, entities, companyName}: BackupActionsProps) => {
    if (entities.status !== 'fulfilled' || userInfo.status !== 'fulfilled') {
        return <></>
    }

    const {t} = useTranslation()
    const match = useRouteMatch<any>()
    const location = useLocation()
    const [disabled, setDisabled] = useState(false)
    const dispatch = useAppDispatch()

    const handlePerformBackupTaskAction = (action: any) => {
        const taskId = match.params.taskId
        if (action === TASK_ACTION.ACTIVATE) {
            track(EVENT_NAMES.ENTITY_BACKUP_ACTIVATION, {
                Service: getServiceNameMixpanel(source),
                'Number of Activated Entities': 1,
                'Number of Discovered Entities': entities.response.totalTaskEntities,
                'Number of Previously Activated Entities': entities.response.activeEntities,
                'Activation Option': 'Entity Row'
            })
        }

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        const performActionRequest = {
            entities: performEntities,
            action,
            taskId
        }

        const getBackupEntitiesRequest = {
            rows: PAGE_SIZE,
            page: entities.response.scrollPage,
            taskId,
            ...getRequestParamsFromUrl(queryParams)
        }

        setDisabled(true)

        dispatch(performEntityAction(performActionRequest, getBackupEntitiesRequest))
            .then(() => openSuccessNotification(t(`backups:backupActions:${action}:responseSuccess`)))
            .catch(err => {
                if (err.data?.status) {
                    openErrorNotification(err.data.status)
                } else {
                    openErrorNotification(t(`backups:backupActions:${action}:responseFailed`))
                }
            })
            .finally(() => setDisabled(false))
    }

    const openSubscribeErrorModal = () => {
        openErrorModal(t('backups:unsubscribedErrorMessage', {companyName}))
    }

    const {isSubscribed, trialDaysLeft} = userInfo.response
    return (
        <StyledBackupActions disabled={disabled}>
            {(() => {
                switch (taskInfo.status) {
                    case TASK_STATUS.NOT_ACTIVE:
                    case TASK_STATUS.PAUSED:
                        return (
                            checkPermission('PAGE.BACKUPS.ACTIVATE', _.get(userInfo, 'response.permissions')) && // CLOUD-6923
                            checkPermission(`BACKUP.${source}.EDIT`, _.get(userInfo, 'response.permissions')) && (
                                <BackupActivate handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                            )
                        )
                    case TASK_STATUS.IN_PROCESS:
                        return (
                            checkPermission(`BACKUP.${source}.CANCEL`, _.get(userInfo, 'response.permissions')) && (
                                <BackupCancel handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                            )
                        )
                    case TASK_STATUS.SCHEDULED:
                    case TASK_STATUS.SUCCESS:
                    case TASK_STATUS.FAILED:
                    case TASK_STATUS.PARTIAL:
                        return (
                            <>
                                {checkPermission(`BACKUP.${source}.PAUSE`, _.get(userInfo, 'response.permissions')) && (
                                    <BackupPause handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                                )}
                                {checkPermission(`BACKUP.${source}.EXECUTE`, _.get(userInfo, 'response.permissions')) &&
                                    (isSubscribed || trialDaysLeft > 0 ? (
                                        <BackupNow
                                            handlePerformBackupTaskAction={() =>
                                                handlePerformBackupTaskAction(TASK_ACTION.RUN)
                                            }
                                        />
                                    ) : (
                                        <BackupNow greyedOut handlePerformBackupTaskAction={openSubscribeErrorModal} />
                                    ))}
                            </>
                        )
                    default:
                        return null
                }
            })()}
        </StyledBackupActions>
    )
}

const mapStateToProps = (state: AppState) => ({
    entities: state.backups.backupEntities.entities,
    userInfo: state.userInfo,
    companyName: state.companyInfo.status === 'fulfilled' ? state.companyInfo.response.name : ''
})

export const BackupActions = connect(mapStateToProps)(BackupActionsRaw)
