import React from 'react'
import {useTranslation} from 'react-i18next'

import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'

import {Status, StyledPaymentsStatus} from './StyledPaymentsStatus'

type PaymentsStatusProps = {
    status: PaymentStatus
    amount?: string
}

export const PaymentsStatus = (props: PaymentsStatusProps) => {
    const {status, amount} = props
    const {t} = useTranslation()

    return (
        <StyledPaymentsStatus>
            <Status status={status}>{t(`billing:status:${status}`)}</Status>
            {(status === PaymentStatus.UNPAID || status === PaymentStatus.DUE || status === PaymentStatus.OVERDUE) &&
                amount && <span> {amount}</span>}
        </StyledPaymentsStatus>
    )
}
