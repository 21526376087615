import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {NewTaskSettings, TODO_ANY} from 'ca-common/types'

const RecoveryTitleRaw = ({settings}: {settings: NewTaskSettings}): JSX.Element => {
    const name = settings?.alias
    const {t} = useTranslation()

    return <span>{t('recovery:browseBackup', {name})}</span>
}

const mapStateToProps = (state: TODO_ANY) => ({
    settings: state.recoveryNew.settings.response
})

export const RecoveryTitle = connect(mapStateToProps)(RecoveryTitleRaw)
