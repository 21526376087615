import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import LogoEmail from 'ca-common/icons/logoThankYouPage.svg'
import {MainTitle} from 'ca-common/ui-lib/atoms/Typography'

export const StyledThankYou = styled.div`
    max-width: 715px;
    background: ${variables.white};
    padding: ${({isCloudally}) => (isCloudally ? variables.gutterLg : '86px')} ${variables.gutterLg}
        ${variables.gutterLg} ${variables.gutterLg};
    text-align: center;
    margin: 60px auto auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: ${variables.screenSm}) {
        margin: auto;
        padding: ${({isCloudally}) => (isCloudally ? variables.gutterXxl : '93px')} 86px 72px 86px;
    }
`
export const EmailTitle = styled(MainTitle)`
    && {
        font-family: ${variables.publicPageFont};
        margin-bottom: ${variables.gutterLg};
        color: ${variables.navyBlue};
    }
`
export const SendActivationLink = styled.p`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.headingFontSize};
    margin: 0;
    strong {
        margin-top: ${variables.gutterSm};
        font-weight: 600;
        font-size: 16px;
        color: ${variables.navyBlueBorder};
        display: block;
    }
`
export const StyledLogo = styled(LogoEmail)`
    width: 330px;
    height: 53px;
    margin-bottom: ${variables.gutterXxl};
    @media screen and (max-width: ${variables.screenSm}) {
        width: 200px;
    }
`
export const StyledIcon = styled(Icon)`
    font-size: 140px;
    margin: ${variables.gutterMd} 0;
    color: ${variables.white};
`

export const ClickActivationLink = styled.p`
    max-width: 455px;
    margin: 0 auto;
`

export const Resent = styled.p`
    margin: ${variables.gutterSm} auto;
`
export const StyledLink = styled.a`
    font-weight: 600;
    text-decoration: underline;
    color: ${variables.navyBlue};
`

export const EmailSentText = styled.div`
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${props => (props.error ? variables.red : variables.green)};
    margin: ${variables.gutterSm} 0;
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    flex-grow: 1;

    @media screen and (max-width: ${variables.screenLaptop}) {
        justify-content: space-between;
    }

    @media screen and (max-width: ${variables.screenMd}) {
        flex-direction: column;
        align-items: center;
        padding: 0 ${variables.gutterXs};
    }
`
