import _ from 'lodash'
import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {post, postLessData} from 'src/newcore/utils/rest'
import {TAG_STATE} from 'ca-common/common/enum/TagsState'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {Id, TODO_ANY} from 'ca-common/types'

const GET_TAGS = 'GET_TAGS'
const GET_TAGS_API = 'getTags'
const getTagsWrapper = fetchWrapperRT(GET_TAGS, async data => await post(GET_TAGS_API, data))
export const getTags = getTagsWrapper.fetcher

const CREATE_TAG_STATE = 'CREATE_TAG_STATE'
const CREATE_TAG_STATE_API = 'createTag'

const modifyTagState = (response: TODO_ANY, state: TODO_ANY) => {
    return [{...response.data, new: true, state: TAG_STATE.UNCHECKED}, ...state.response]
}

const createTagStateWrapper = fetchWrapperRT(
    CREATE_TAG_STATE,
    async (data, {rejectWithValue}) => {
        try {
            return await postLessData(CREATE_TAG_STATE_API, data)
        } catch (err: any) {
            return rejectWithValue(err)
        }
    },
    {
        after: modifyTagState
    }
)
export const createTagState = createTagStateWrapper.fetcher

const CREATE_AND_APPLY_TAG_STATE = 'CREATE_AND_APPLY_TAG_STATE'
const modifyApplyingTagState = (response: TODO_ANY, state: TODO_ANY) => {
    return [{...response.data, new: true, state: TAG_STATE.CHECKED, applied: true}, ...state.response]
}

const createAndApplyTagStateWrapper = fetchWrapperRT(
    CREATE_AND_APPLY_TAG_STATE,
    async (data, {rejectWithValue}) => {
        try {
            return await postLessData(CREATE_TAG_STATE_API, data)
        } catch (err: any) {
            return rejectWithValue(err)
        }
    },
    {
        after: modifyApplyingTagState
    }
)
export const createAndApplyTagState = createAndApplyTagStateWrapper.fetcher

const ADD_ENTITY_TAG = 'ADD_ENTITY_TAG'
const ADD_ENTITY_TAG_API = 'addEntityTag'
const addEntityTagWrapper = fetchWrapperRT(ADD_ENTITY_TAG, async data => await post(ADD_ENTITY_TAG_API, data))
export const addEntityTag = addEntityTagWrapper.fetcher

const REMOVE_ENTITY_TAG = 'REMOVE_ENTITY_TAG'
const REMOVE_ENTITY_TAG_API = 'removeEntityTag'
const removeEntityTagWrapper = fetchWrapperRT(REMOVE_ENTITY_TAG, async data => await post(REMOVE_ENTITY_TAG_API, data))
export const removeEntityTag = removeEntityTagWrapper.fetcher

const GET_TAGS_STATE = 'GET_TAGS_STATE'
const GET_TAGS_STATE_API = 'getTagsState'

const getTagsStateWrapper = fetchWrapperRT(GET_TAGS_STATE, async data => await post(GET_TAGS_STATE_API, data))
export const getTagsState = getTagsStateWrapper.fetcher

const updateTagStateReducer = (state: TODO_ANY, action: TODO_ANY) => {
    const {id, initialTagsState} = action.payload
    const index = state.response.findIndex((item: TODO_ANY) => item.id === id)

    if (index >= 0) {
        const appliedTagState = state.response[index].applied ? TAG_STATE.CHECKED : TAG_STATE.UNCHECKED

        const modifiedInitialTagsState = state.response.map((item: TODO_ANY) => {
            const tagState = initialTagsState.find((tag: TODO_ANY) => tag.id === item.id)

            return {
                ...item,
                state: tagState ? tagState.state : appliedTagState
            }
        })

        const updatedTagState =
            state.response[index].state !== TAG_STATE.CHECKED ? TAG_STATE.CHECKED : TAG_STATE.UNCHECKED

        const toDelete = state.response[index].applied
            ? updatedTagState !== appliedTagState
            : updatedTagState === appliedTagState

        const toApply = state.response[index].applied
            ? updatedTagState === appliedTagState
            : updatedTagState !== appliedTagState

        state.response[index] = {
            ...state.response[index],
            state: updatedTagState,
            toDelete: modifiedInitialTagsState[index].state !== updatedTagState && toDelete,
            toApply: modifiedInitialTagsState[index].state !== updatedTagState && toApply
        }
    }
}

type Tag = {
    id: Id
    text: string
}

const tagsSlice = createSlice({
    name: 'tags',
    initialState: InitFetchedState as FetchedState<Tag[]>,
    reducers: {
        updateTags: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response = [...state.response, action.payload]
            }

            return state
        }
    },
    extraReducers: builder => getTagsWrapper.makeReducers(builder)
})

export const {updateTags} = tagsSlice.actions

type TagState = {
    id: Id
    state: TAG_STATE
    text: string

    toDelete?: boolean
    toApply?: boolean
}

const tagsStateSlice = createSlice({
    name: 'tagsState',
    initialState: InitFetchedState as FetchedState<TagState[]>,
    reducers: {
        updateTagState: updateTagStateReducer,
        updateTagsState: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response = [...state.response, action.payload]
            }

            return state
        },
        resetTagsState: state => {
            if (state.status === 'fulfilled') {
                state.response = [...state.response.map(tag => _.omit(tag, ['toDelete', 'toApply']))]
            }

            return state
        }
    },
    extraReducers: builder => {
        getTagsStateWrapper.makeReducers(builder)
        createTagStateWrapper.makeReducers(builder)
        createAndApplyTagStateWrapper.makeReducers(builder)
    }
})

export const {updateTagsState, resetTagsState, updateTagState} = tagsStateSlice.actions

export const backupTags = combineReducers({
    tags: tagsSlice.reducer,
    tagsState: tagsStateSlice.reducer
})
