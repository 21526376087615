import {Menu, Popover} from 'antd'
import Icon from '@ant-design/icons'
import styled, {css} from 'styled-components'
import React from 'react'
import {PopoverProps} from 'antd/lib/popover'

import variables from 'ca-common/variables'

export const StyledMenu = styled(Menu)<{ref: any}>`
    border: none;
    .ant-menu-item {
        margin: 0;
        text-align: center;
    }
    .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
    }
    .ant-menu-item-selected.ant-menu-item-selected,
    .ant-menu-item-active {
        background-color: ${variables.navyBlue};
        color: ${variables.white} !important;
    }
`

const PopoverRaw = ({className, ...props}: PopoverProps & Record<string, any>) => (
    <Popover {...props} overlayClassName={className} />
)

export const ButtonPopover = styled(PopoverRaw)`
    width: ${props => props.width}px;

    .ant-popover-inner {
        border-radius: ${variables.borderRadiusBase};
    }

    .ant-popover-inner-content {
        padding: 0;
    }
`

export const StyledIcon = styled(Icon)`
    font-size: 29px;
    margin-left: -15px;
    margin-top: 3px;
`

export const StyledChevron = styled<any>(Icon)`
    margin-right: -6px;
    margin-top: 4px;

    ${props =>
        props.ghost &&
        css`
            font-size: 12px;
        `}

    ${props =>
        props.open &&
        css`
            transform-origin: 50% 40%;
            transform: rotate(180deg);
        `}
`
