import React from 'react'
import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'

import {TWO_FA_STATUS} from 'ca-common/common/enum/TwoFactorStatus'

type TwoFAProps = {
    twoFactorStatus: keyof typeof TWO_FA_STATUS
    twoFaEnabled?: boolean
    isPP?: boolean
}

export const TwoFA = (props: TwoFAProps) => {
    const {twoFactorStatus, twoFaEnabled, isPP} = props

    if (isPP) {
        return twoFaEnabled ? (
            <Tooltip title={TWO_FA_STATUS.TWO_FA_ENABLED.status}>
                <Icon component={TWO_FA_STATUS.TWO_FA_ENABLED.icon} style={{fontSize: '24px'}} />
            </Tooltip>
        ) : null
    }

    return (
        <Tooltip title={TWO_FA_STATUS[twoFactorStatus].status}>
            <Icon component={TWO_FA_STATUS[twoFactorStatus].icon} style={{fontSize: '24px'}} />
        </Tooltip>
    )
}
