import {createSlice} from '@reduxjs/toolkit'

import {postLessData} from 'src/newcore/utils/rest'

import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {BackupEntitiesResponse} from 'src/newcore/redux/modules/backups'

const GET_BACKUP_ENTITIES_RECOVERY = 'GET_BACKUP_ENTITIES_RECOVERY'
const GET_BACKUP_ENTITIES_API = 'getEntities'

const getBackupEntitiesWrapper = fetchWrapperRT(
    GET_BACKUP_ENTITIES_RECOVERY,
    async data => await postLessData(GET_BACKUP_ENTITIES_API, data)
)
export const getBackupEntities = getBackupEntitiesWrapper.fetcher

const entitiesSlice = createSlice({
    name: 'entities',
    initialState: InitFetchedState as FetchedState<BackupEntitiesResponse>,
    reducers: {
        clearBackupEntities: () => InitFetchedState as FetchedState<BackupEntitiesResponse>
    },
    extraReducers: builder => getBackupEntitiesWrapper.makeReducers(builder)
})

export const {clearBackupEntities} = entitiesSlice.actions
export const entities = entitiesSlice.reducer
