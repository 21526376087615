export const AUTH_TYPE = {
    OAUTH: 'OAUTH',
    CREDENTIAL: 'CREDENTIAL'
}

export enum OAUTH_SERVICES {
    GOOGLE,
    AZURE,
    OKTA
}
