import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const Postfix = styled.div`
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
`

export const StyledBackupNameInputWrapper = styled(Form.Item)`
    height: 64px;

    .ant-row {
        flex-direction: column;
    }

    .ant-col {
        text-align: left;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-right: 0;
    }

    .ant-form-item-control-input-content {
        display: flex;
        white-space: nowrap;
        align-items: center;
        gap: ${variables.gutterXs};
    }

    .ant-form-item-explain-error {
        font-size: ${variables.fontSizeSecondary};
    }
`

export const BackupNameFieldTitle = styled.div`
    font-weight: 400;
    font-size: ${variables.subTitleFontSize};
    color: ${variables.text};
`

export const BodyWrapper = styled.div`
    padding: ${variables.gutterSm} ${variables.gutterMd} 0;
    border-top: 1px solid ${variables.blue};
`
