import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {StyledSignInTwoFA, Title} from 'ca-common/forms/SignIn/StyledSignIn'
import {TwoFACodeForm} from 'ca-common/forms/SignInTwoFA/TwoFACode'
import {LostDeviceForm} from 'ca-common/forms/SignInTwoFA/LostDevice'

type SignInTwoFAProps = {
    onSubmit: (arg0: any) => void
    onChange: (error: string | null) => void
    error: null | string
    loading: boolean
}

export const SignInTwoFA = (props: SignInTwoFAProps): JSX.Element => {
    const [isDeviceLost, setIsDeviceLost] = useState(false)
    const {onChange, error, onSubmit, loading} = props
    const {t} = useTranslation()

    const handleLostDevice = () => {
        setIsDeviceLost(true)
        props.onChange(null)
    }

    return (
        <StyledSignInTwoFA>
            <Title>
                {isDeviceLost ? t('sign:in:partnerPortal:fillRecoveryCode') : t('sign:in:partnerPortal:fillCode')}
            </Title>
            {isDeviceLost ? (
                <LostDeviceForm onChange={onChange} error={error} onSubmit={onSubmit} loading={loading} />
            ) : (
                <TwoFACodeForm
                    onChange={onChange}
                    error={error}
                    onSubmit={onSubmit}
                    handleLostDevice={handleLostDevice}
                    loading={loading}
                />
            )}
        </StyledSignInTwoFA>
    )
}
