import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledLabel = styled.span`
    display: block;
    margin-bottom: 12px;

    font-family: ${variables.mainFont};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;

    color: ${variables.brandDark};
`
