import styled from 'styled-components'

import variables from 'ca-common/variables'

export const MaxTagPlaceholder = styled.div`
    display: flex;
    width: 100%;
    margin-left: 6px;
    cursor: pointer;

    & span:last-child {
        margin-left: auto;
        margin-right: 7px;
    }
    & span:first-child {
        text-overflow: ellipsis;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
    }
`

export const OptionsWrapper = styled.div`
    && .ant-select-item-option-selected,
    && .ant-select-item-option-active,
    && .ant-select-item-option:hover {
        background-color: inherit;
        color: inherit;
        font-weight: inherit;
    }

    .ant-select-item-option {
        padding: ${variables.gutterXs} ${variables.gutterSm};
    }

    .ant-select-item-option-state {
        display: none;
    }

    .ant-select-item-option-content {
        padding-left: 0;
        position: relative;
        display: flex;
        align-items: center;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .ant-select-item-option .ant-select-item-option-content:before {
        content: '\\00A0';
        display: inline-block;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        background-color: ${variables.white};
        border: 1px solid #d9d9d9;
        border-radius: 0;
        margin-right: 6px;
        vertical-align: middle;
        border-collapse: separate;
        transition: all 0.3s;
        color: ${variables.white};
        flex-shrink: 0;

        &:hover {
            border-color: ${variables.navyBlue};
        }
    }

    .ant-select-item-option:hover .ant-select-item-option-content:before {
        border-color: ${variables.navyBlue};
    }

    .ant-select-item-option .ant-select-item-option-content:after {
        position: absolute;
        display: table;
        border: 2px solid ${variables.white};
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
        width: 5.71428571px;
        height: 9.14285714px;
        color: ${variables.white};
        top: 50%;
        left: 4px;
    }

    .ant-select-item-option-selected .ant-select-item-option-content:before {
        background-color: ${variables.navyBlue};
        border-color: ${variables.navyBlue};
    }
`
