import React from 'react'
import {FormInstance} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {IndexStatus} from 'src/newcore/features/BackupSettings/molecules/IndexStatus'
import {AccountDeactivation} from 'src/newcore/features/BackupSettings/molecules/AccountDeactivation'
import {BackupUnlicensedAccounts} from 'src/newcore/features/BackupSettings/molecules/BackupUnlicensedAccounts'
import {DetectNewAccounts} from 'src/newcore/features/BackupSettings/molecules/DetectNewAccounts'

import {Wrapper} from './StyledSettingsOptions'

type FrequencyAndHourSelectorsProps = {
    service: SOURCE
    form: FormInstance<Record<string, any>>
    isFinra?: boolean
}

export const SettingsOptions = (props: FrequencyAndHourSelectorsProps) => {
    const {service, form, isFinra} = props

    return (
        <Wrapper>
            <IndexStatus namePrefix={service} />
            {service === SOURCE.MS365 && <BackupUnlicensedAccounts namePrefix={service} form={form} />}
            <AccountDeactivation namePrefix={service} source={service} isFinra={isFinra} />
            <DetectNewAccounts namePrefix={service} source={service} />
        </Wrapper>
    )
}
