import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledBackupName, StyledInput} from './StyledBackupName'

const ALIAS_MAX_LENGTH = 500

export const BackupName = (): JSX.Element => {
    const {t} = useTranslation()
    return (
        <StyledBackupName
            label={t('forms:backupAuth:fields:backupName:label')}
            name="alias"
            colon={false}
            rules={[
                {
                    required: true,
                    message: t('forms:common:validation:required')
                },
                {
                    whitespace: true,
                    message: t('forms:common:validation:required')
                },
                {
                    max: ALIAS_MAX_LENGTH,
                    message: t('validation:maxLength', {length: ALIAS_MAX_LENGTH})
                }
            ]}
        >
            <StyledInput placeholder={t('forms:backupAuth:fields:backupName:placeholder')} />
        </StyledBackupName>
    )
}
