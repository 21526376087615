import styled, {css} from 'styled-components'
import {rgba} from 'polished'

import variables from 'ca-common/variables'

export const StyledEntityColumn = styled.div<{isHighlighted?: boolean}>`
    display: flex;
    flex-direction: column;
    margin-right: ${variables.gutterSm};

    ${props => {
        if (props.isHighlighted) {
            return css`
                padding: ${variables.gutterXs};
                margin: 0 7px 0 -7px;
                border: 1px solid ${variables.orange};
                background: ${rgba(variables.orange, 0.1)};
                border-radius: 2px;
            `
        }
    }}
`
export const ColumnName = styled.div`
    color: ${variables.navyAsphalt};
    margin: 0;
    text-align: right;
`

export const ColumnValue = styled.div`
    color: ${variables.navyBlue};
    max-width: 95px;
`
