import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const GET_ACTIVE_DIRECTORY_ACCOUNTS_API = 'getAccountsWithPropertyValues'

const GET_ACTIVE_DIRECTORY_ACCOUNTS = 'GET_ACTIVE_DIRECTORY_ACCOUNTS'
const getActiveDirectoryAccountsWrapper = fetchWrapperRT<{data: TODO_ANY[]}>(
    GET_ACTIVE_DIRECTORY_ACCOUNTS,
    async (data, {rejectWithValue}) => {
        try {
            return await postLessData(GET_ACTIVE_DIRECTORY_ACCOUNTS_API, data)
        } catch (e) {
            throw rejectWithValue(e)
        }
    },
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                return {
                    ...response,
                    data: [...(state.response?.data || []), ...response.data]
                }
            }

            return response
        }
    }
)
export const getActiveDirectoryAccounts = getActiveDirectoryAccountsWrapper.fetcher

const activeDirectoryAccountsSlice = createSlice({
    name: 'activeDirectoryAccounts',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearActiveDirectoryAccounts: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getActiveDirectoryAccountsWrapper.makeReducers(builder)
})

export const {clearActiveDirectoryAccounts} = activeDirectoryAccountsSlice.actions
export const activeDirectoryAccounts = activeDirectoryAccountsSlice.reducer
