type ErrorPreprocessor = (error: {
    status: string
    fieldErrors: Record<string, any>
}) => Promise<{
    status: string
    data: Record<string, string>
}>

export const errorPreprocessor: ErrorPreprocessor = e => {
    return Promise.reject({
        status: e.status,
        data: e.fieldErrors
    })
}
