import React, {Fragment, useMemo} from 'react'
import {useLocation} from 'react-router'
import {createGlobalStyle} from 'styled-components'

import variables from 'ca-common/variables'
import {SideMenuItem} from 'ca-common/types'
import {GlobalStyles} from 'ca-common/ui-lib/GlobalStyles'

import {SubmenuItem, MenuItem} from './RouterMenuItem'
import {StyledSider, StyledMenu} from './StyledSidebar'

const SUBMENU_CLASS_NAME = 'side-menu-submenu'

const GlobalSideMenuStyles = createGlobalStyle`
  .ant-menu-submenu.${SUBMENU_CLASS_NAME} {

    .ant-menu-sub {
      border-radius: ${variables.borderRadiusBase};
    }
    
    .ant-menu-item {
      display: flex;
      line-height: normal;
      align-content: center;
      align-items: center;
      margin: 0;
      height: auto;
      padding-right: ${variables.gutterLg};
    }

    .ant-menu-item-active,
    .ant-menu-item-selected {
      a {
        color: ${variables.navyBlue};
      }
      
      :hover {
        background: ${variables.pastelBlue};
        
        a {
          color: ${variables.black};
        }
      }
    }
  }
`

const prepareUtilClassNames = (item: SideMenuItem): string => {
    let result = item.className ?? ''

    if (item.bottom) {
        result += ' is-sticky'
    }

    if (item.isFake) {
        result += ' is-fake'
    }

    if (item.target === '_blank') {
        result += ' external'
    }

    return result
}

export const CASideMenu = React.memo((props: {menu: SideMenuItem[]}) => {
    const {menu} = props

    const location = useLocation()

    const prepareMenuItemClassNames = (item: SideMenuItem) => {
        let result = item.className ?? ''
        if (item.extraLinksForHighlight?.some(link => location.pathname.startsWith(link))) {
            result += ' active'
        }
        return result
    }

    const preparedMenu = useMemo(() => {
        let selectedSubmenuItems = [] as string[]
        const items = menu.map(i => {
            if (i.submenu) {
                return {
                    key: i.link,
                    popupClassName: SUBMENU_CLASS_NAME,
                    'data-inlinemanual': `InlineManual-sidemenu-${i.name}`,
                    label: (
                        <MenuItem icon={i.icon} link={i.link} className={i.className} badge={i.badge} title={i.title} />
                    ),
                    children: i.submenu.map(j => {
                        const links = (j.extraLinksForHighlight || []).concat([j.link])

                        if (links.some(link => location.pathname.startsWith(link))) {
                            selectedSubmenuItems = links
                        }

                        return {
                            key: j.link,
                            label: <SubmenuItem icon={j.icon} link={j.link} title={j.title} />
                        }
                    })
                }
            }

            return {
                key: i.link,
                'data-inlinemanual': `InlineManual-sidemenu-${i.name}`,
                label: (
                    <MenuItem
                        pureLink={i.pureLink}
                        icon={i.icon}
                        link={i.link}
                        className={prepareMenuItemClassNames(i)}
                        badge={i.badge}
                        title={i.title}
                        target={i.target}
                    />
                ),
                className: prepareUtilClassNames(i)
            }
        })

        return {items, selectedSubmenuItems}
    }, [menu])

    return (
        <Fragment>
            <GlobalStyles />
            <GlobalSideMenuStyles />
            <StyledSider width={variables.sidebarWidth}>
                <StyledMenu
                    selectedKeys={preparedMenu.selectedSubmenuItems}
                    items={preparedMenu.items}
                    expandIcon={() => null}
                />
            </StyledSider>
        </Fragment>
    )
})
