import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {changeConfig, setWizardDescription, setWizardTitle, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import {Footer} from 'src/newcore/features/OneClickActivation/molecules'

import {ContentWrapper, LoadingText} from './StyledOCAProcessingStepCommon'
import {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

export const OCAProcessingStepCommon = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData

    const onCancel = () => {
        const data = {
            'Loading Phase': 'Tasks Processing'
        } as Record<string, any>

        if (wizardData.secondProcessingStartedAt || wizardData.firstProcessingStartedAt) {
            const waitingFrom = (wizardData.secondProcessingStartedAt || wizardData.firstProcessingStartedAt) as number
            data['Loading Page Duration'] = (Date.now() - waitingFrom) / 1000
        }

        track(EVENT_NAMES.OCA_CANCEL_LOADING_PAGE, data)
    }

    useEffect(() => {
        dispatch(setWizardTitle(''))
        dispatch(setWizardDescription(''))
        dispatch(changeConfig({modalWidth: 485}))

        return () => {
            dispatch(changeConfig({modalWidth: null}))
        }
    }, [])

    return (
        <div>
            <ContentWrapper>
                <Spinner modifier="small" />
                <LoadingText>{t('oneClickActivation:wizard:loadingText3')}</LoadingText>
            </ContentWrapper>
            <Footer hideNextButton onCancel={onCancel} />
        </div>
    )
}
