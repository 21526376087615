import {LINK_TYPES} from './LinkTypes'

export const GLOBAL_LINKS = {
    [LINK_TYPES.SLA]: 'https://www.cloudally.com/sla',
    [LINK_TYPES.TERMS]: 'https://www.carbonite.com/legal/products-and-services-terms',
    [LINK_TYPES.PRIVACY]: 'https://www.opentext.com/about/privacy',
    [LINK_TYPES.GDPR]: 'https://www.cloudally.com/resources/gdpr/',
    [LINK_TYPES.SECURITY]: 'https://www.cloudally.com/resources/secure-saas-backup/',
    [LINK_TYPES.COOKIE]: 'https://www.opentext.com/about/cookie-policy'
}
