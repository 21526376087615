import {createSlice} from '@reduxjs/toolkit'

import {get, getLessData} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_DOWNLOAD_INFO = 'GET_DOWNLOAD_INFO'
const GET_DOWNLOAD_INFO_API = 'getDownloadInfo'
const getDownloadInfoWrapper = fetchWrapperRT(GET_DOWNLOAD_INFO, async (data, {rejectWithValue}) => {
    try {
        return await getLessData(GET_DOWNLOAD_INFO_API, data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getDownloadInfo = getDownloadInfoWrapper.fetcher

const GET_DOWNLOAD_URL = 'GET_DOWNLOAD_URL'
const GET_DOWNLOAD_URL_API = 'getDownloadUrl'
const getDownloadUrlWrapper = fetchWrapperRT(GET_DOWNLOAD_URL, async data => await get(GET_DOWNLOAD_URL_API, data))

export const getDownloadUrl = getDownloadUrlWrapper.fetcher

export const downloadInfo = createSlice({
    name: 'downloadInfo',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getDownloadInfoWrapper.makeReducers(builder)
}).reducer
