import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const REFRESH_ENTITIES = 'REFRESH_ENTITIES'
const REFRESH_ENTITIES_API = 'refreshEntities'
const refreshEntitiesWrapper = fetchWrapperRT(REFRESH_ENTITIES, async data => await post(REFRESH_ENTITIES_API, data))

export const refreshEntities = refreshEntitiesWrapper.fetcher

const refreshEntitiesSlice = createSlice({
    name: 'refreshEntities',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => refreshEntitiesWrapper.makeReducers(builder)
})
export const refreshEntitiesReducer = refreshEntitiesSlice.reducer
