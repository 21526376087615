import React from 'react'
import i18n from 'i18next'
import {ColumnProps} from 'antd/lib/table'

import {SourceIcon} from 'ca-common/components/SourceIcon'
import {Status} from 'ca-common/atoms/Status'
import {formatUTCDateTime} from 'ca-common/utils/datetime'
import {SOURCE} from 'ca-common/common/enum/Source'
import {Site} from 'src/newcore/components/BackupEntities/CellModifier/Site'

import {ActivityEntity} from './molecules/ActivityEntity'
import {DownloadButton} from './atoms/DownloadButton'
import {SubsourceTitle} from './atoms/SubsourceTitle'

import {isDownloadButton} from './lib'
import {ActivityRecord} from 'ca-common/features/ActivityInfinityTable'

const ACTIVITY_WIDTH = 100
const DATE_AND_TIME_WIDTH = 110
const SERVICE_WIDTH = 100
const SUBSOURCE_WIDTH = 100
const STATUS_WIDTH = 140
const TASK_NAME_WIDTH = 150
const ITEM_WIDTH = 150
const DOWNLOAD_WIDTH = 40

export const getColumns = (): ColumnProps<ActivityRecord>[] => [
    {
        title: i18n.t('table:columns:activityNew'),
        dataIndex: 'activity',
        width: ACTIVITY_WIDTH,
        render: (text: string, record: ActivityRecord) => <ActivityEntity data={record} />
    },
    {
        title: i18n.t('table:columns:dateAndTime'),
        dataIndex: 'date',
        width: DATE_AND_TIME_WIDTH,
        render: (text: string) => formatUTCDateTime(Number(text))
    },
    {
        title: i18n.t('table:columns:service'),
        dataIndex: 'source',
        width: SERVICE_WIDTH,
        align: 'center',
        render: (text: string, record: ActivityRecord) => <SourceIcon fontSize={28} source={record.source} />
    },
    {
        title: i18n.t('table:columns:subService'),
        dataIndex: 'subSource',
        width: SUBSOURCE_WIDTH,
        render: (text: SOURCE) => text && <SubsourceTitle title={text} />
    },
    {
        title: i18n.t('table:columns:status'),
        dataIndex: 'status',
        width: STATUS_WIDTH,
        render: (text: string, record: ActivityRecord) => (
            <Status status={record.activityStatus} statusDescription={record.statusDescription} />
        )
    },
    {
        title: i18n.t('table:columns:taskName'),
        dataIndex: 'account',
        width: TASK_NAME_WIDTH,
        ellipsis: true
    },
    {
        title: i18n.t('table:columns:item'),
        dataIndex: 'entity',
        width: ITEM_WIDTH,
        render: (text: string) => <Site site={text} />,
        ellipsis: false
    },
    {
        width: DOWNLOAD_WIDTH,
        dataIndex: 'downloadLink',
        render: (link: string, record: ActivityRecord) =>
            isDownloadButton(link, record) && <DownloadButton link={link} record={record} />
    }
]
