import React from 'react'
import {Tooltip} from 'antd'
import {Trans} from 'react-i18next'

import {CompanyNameText, StyledSecurityPageTitle} from './StyledSecurityPageTitle'

type SecurityPageTitleProps = {
    children: string
}

export const SecurityPageTitle = ({children}: SecurityPageTitleProps): JSX.Element => {
    return (
        <StyledSecurityPageTitle>
            <Trans i18nKey="securityPage:title" values={{companyName: children}}>
                <div>First and last text</div>
                <Company>{children}</Company>
            </Trans>
        </StyledSecurityPageTitle>
    )
}

const Company = ({children}: SecurityPageTitleProps) => {
    return (
        <Tooltip placement="top" title={children}>
            <CompanyNameText>{children}</CompanyNameText>
        </Tooltip>
    )
}
