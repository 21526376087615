import styled from 'styled-components'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import {
    BackupActionButton,
    ButtonsWrapper,
    DisabledButtonWrapper
} from 'src/newcore/features/Homepage/organisms/BackupActions/StyledBackupActions'

export const StyledList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${variables.white};
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 10px ${variables.gutterMd} 10px 12px;
    margin-bottom: ${variables.gutterSm};
    transition: 0.5s ease all;

    &:hover {
        box-shadow: 0 12px 28px rgba(32, 40, 128, 0.16);
        transition: 0.5s ease all;
    }
`
export const ContentActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
export const LinkWrapper = styled(CALink)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
`
export const StyledActionsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${ButtonsWrapper} {
        flex-direction: column;
      margin-right: ${variables.gutterSm}
    }
    ${BackupActionButton} {
        width: 140px;
        
      }
    }
    ${DisabledButtonWrapper} {
      margin-bottom: ${variables.gutterXs};
        &:last-child {
          margin-bottom: 0;
  }`
