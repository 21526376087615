import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledWrapper = styled.div`
    width: 100%;
    min-height: 392px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const StyledIcon = styled(Icon)`
    svg {
        width: 136px;
        height: 128px;
    }
`

export const StyledText = styled.span`
    margin-top: 12px;
    font-family: ${variables.accentFont};
    font-size: 24px;
    color: ${variables.asphaltLight};
`
