import React, {useState} from 'react'
import {Modal} from 'antd'
import {isEmpty} from 'lodash'
import {useTranslation} from 'react-i18next'

import {openSuccessNotification} from 'ca-common/utils/toasts'

import {EditForm} from 'src/newcore/features/SmartAlerts/molecules'

import {StyledTitle} from './StyledSmartAlert'
import {checkAlertConfiguration} from '../../../SmartAlertsWizard/lib'

export const SmartAlertEdit = props => {
    const {record, setRecord, editSmartAlert, taskId} = props

    const {t} = useTranslation()
    const [waitCompletion, setWaitCompletion] = useState(false)
    const [warning, setWarning] = useState(false)
    const [error, setError] = useState(false)

    const checkValues = values => {
        setError(false)
        setWarning(checkAlertConfiguration({values, level: record.level, triggeredBy: record.triggerType}))
    }

    const visible = !isEmpty(record)

    const closeModal = () => {
        setWarning(false)
        setError(false)
        setRecord(null)
    }

    const handleSubmit = values => {
        const request = {
            config: {amount: values.total, unit: values.unit, event: values.event},
            taskId,
            smartAlertId: record.id
        }

        setWaitCompletion(true)

        editSmartAlert(request)
            .then(() => {
                openSuccessNotification('')
                closeModal()
            })
            .catch(error => {
                setError(error?.status || error)
            })
            .finally(() => {
                setWaitCompletion(false)
            })
    }

    return (
        <Modal
            title={<StyledTitle>{t('smartAlerts:edit:title')}</StyledTitle>}
            open={visible}
            destroyOnClose
            onCancel={closeModal}
            onOk={closeModal}
            closable={false}
            maskClosable={false}
            footer={null}
            width={660}
        >
            <EditForm
                initialValues={record}
                closeModal={closeModal}
                loading={waitCompletion}
                handleSubmit={handleSubmit}
                warning={warning}
                error={error}
                checkValues={checkValues}
            />
        </Modal>
    )
}
