import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'

import Connected from 'ca-common/icons/BackupConnection/Connected.svg'
import Disconnected from 'ca-common/icons/BackupConnection/Disconnected.svg'

import {ConnectIcon} from './StyledConnectStatus'

type ConnectStatusProps = {
    isAuthenticated: boolean
}

export const ConnectStatus = ({isAuthenticated}: ConnectStatusProps): JSX.Element => {
    const {t} = useTranslation()

    return isAuthenticated ? (
        <Tooltip title={t('homepage:tooltip:connected')}>
            <ConnectIcon component={Connected} />
        </Tooltip>
    ) : (
        <Tooltip title={t('homepage:tooltip:disconnected')}>
            <ConnectIcon component={Disconnected} />
        </Tooltip>
    )
}
