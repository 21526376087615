import {post, postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const CREATE_SMART_ALERT = 'CREATE_SMART_ALERT'
const CREATE_SMART_ALERT_API = 'createSmartAlert'
const createSmartAlertWrapper = fetchWrapperRT(CREATE_SMART_ALERT, async (data, {rejectWithValue}) => {
    try {
        return await postLessData(CREATE_SMART_ALERT_API, data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const createSmartAlert = createSmartAlertWrapper.fetcher

const GET_SFC_ALERT_OBJECTS = 'GET_SFC_ALERT_OBJECTS'
const GET_SFC_ALERT_OBJECTS_API = 'getSfcAlertsObjects'
const getSFCAlertObjectsWrapper = fetchWrapperRT(
    GET_SFC_ALERT_OBJECTS,
    async data => await post(GET_SFC_ALERT_OBJECTS_API, data)
)
export const getSFCAlertsObjects = getSFCAlertObjectsWrapper.fetcher

const sfcAlertObjectsSlice = createSlice({
    name: 'sfcAlertObjects',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSFCAlertObjects: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getSFCAlertObjectsWrapper.makeReducers(builder)
})

export const {clearSFCAlertObjects} = sfcAlertObjectsSlice.actions
export const sfcAlertObjects = sfcAlertObjectsSlice.reducer
