import styled from 'styled-components'

import variables from 'ca-common/variables'

export const JobTile = styled.div`
    display: flex;
    flex-direction: column;
    background: ${variables.white};
    padding: ${variables.gutterSm} ${variables.gutterMd} ${variables.gutterMd} ${variables.gutterMd};
    width: 100%;
    border-radius: ${variables.borderRadiusBlocks};
`
