import styled from 'styled-components'
import Icon from '@ant-design/icons'

export const StyledIcon = styled(Icon)`
    width: 140px;
    height: 140px;
    svg {
        width: 100%;
        height: 100%;
    }
`
