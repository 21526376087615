import React, {Fragment, useEffect, useMemo, useState} from 'react'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import intersection from 'lodash/intersection'
import toLower from 'lodash/toLower'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import queryString from 'query-string'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import {BackLink, CancelLink, LinkWrapper, ModalContentWrapper, ModalFooter} from 'ca-common/components/Modal'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import Warning from 'ca-common/icons/Warning.svg'
import {openErrorModal} from 'ca-common/utils/modals'

import {previousStep, setWizardDescription, clearWizard} from 'src/newcore/redux/modules/wizard'
import {clearExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {AccountList} from 'src/newcore/components/BulkActivation/ActiveDirectory/ActiveDirectory/AccountList'
import {activateBulkFlow} from 'src/newcore/redux/modules/backups/bulkActivate'
import {clearActiveDirectoryData} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'
import {PAGE_SIZE, DEFAULT_PAGE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {pullBackupEntities} from 'src/newcore/redux/modules/backups'
import {Header, WarningIcon} from 'src/newcore/forms/ConfirmationRuleSelection/StyledConfirmationRuleSelection'
import {ConfirmationRuleSelectionModal} from 'src/newcore/components/Modals/ConfirmationRuleSelectionModal'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {trackBulkActivation, TypesOfRules} from 'src/newcore/components/BulkActivation/trackBulkActivation'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

const ActiveDirectoryAccountsRaw = ({
    actions,
    activeDirectoryAccounts,
    excludeEmails,
    checkedAccountPropertyValues,
    accountPropertyName,
    taskSettings,
    location,
    bulkActivationRules
}) => {
    const [isLoading, setLoading] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const {t} = useTranslation()

    const notValidSelectedItems = useMemo(() => {
        const groupsRules = bulkActivationRules.filter(
            item => item.type === 'ACTIVE_DIRECTORY' && intersection(item.adValues, checkedAccountPropertyValues).length
        )
        const rulesNames = groupsRules.flatMap(i => i.adValues)
        return (
            groupsRules[0] && {
                items: checkedAccountPropertyValues.filter(item => rulesNames.includes(item)).map(item => ` ${item}`),
                field: capitalize(groupsRules[0].adProperty)
            }
        )
    }, [bulkActivationRules, checkedAccountPropertyValues])

    const notValidRule = notValidSelectedItems?.items.length > 0

    const handleActivate = (createRule = false) => {
        setLoading(true)
        return actions
            .activateBulkFlow({
                taskSettings,
                selectedProperty: accountPropertyName,
                selectedPropertyValues: checkedAccountPropertyValues,
                excludes: excludeEmails,
                createRule,
                cacheToken: activeDirectoryAccounts.response.cacheToken
            })
            .then(data => {
                const succeed = data.succeed
                const total = data.total

                openSuccessNotification(t('backups:activatedSuccessfully', {succeed, total}))
            })
            .catch(() => {
                openErrorNotification(t('backups:MS365Groups:activationFailed'))
            })
            .finally(() => {
                const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

                actions.clearActiveDirectoryData()
                actions.clearWizard()
                actions.pullBackupEntities({
                    taskId: taskSettings.taskId,
                    rows: PAGE_SIZE,
                    page: DEFAULT_PAGE,
                    ...getRequestParamsFromUrl(queryParams)
                })
            })
    }

    const handleActivateRule = async () => {
        if (taskSettings.detectNewAccounts) {
            setIsVisible(true)
        } else {
            await handleActivate(true)
        }

        trackBulkActivation(EVENT_NAMES.CREATE_AN_ACTIVATION_RULE, taskSettings?.source, TypesOfRules.ACTIVE_DIRECTORY)
    }
    const handleConfirmationCancelClick = () => {
        setIsVisible(false)
        trackBulkActivation(
            EVENT_NAMES.REFUSAL_TO_CONFIRM_ACTIVATION_RULES_CREATION,
            taskSettings?.source,
            TypesOfRules.ACTIVE_DIRECTORY
        )
    }
    const handleConfirmationSubmit = () => {
        setIsVisible(false)
        handleActivate(true).then(() => {
            trackBulkActivation(
                EVENT_NAMES.CONFIRM_AN_ACTIVATION_RULE_CREATION,
                taskSettings?.source,
                TypesOfRules.ACTIVE_DIRECTORY
            )
        })
    }

    const onCancel = () => {
        trackBulkActivation(
            EVENT_NAMES.CANCEL_BULK_ACTIVATION_LAST_STEP,
            taskSettings?.source,
            TypesOfRules.ACTIVE_DIRECTORY
        )
        actions.clearWizard()
    }

    const onActivate = () => {
        handleActivate().then(() => {
            trackBulkActivation(
                EVENT_NAMES.PERFORM_ONE_TIME_ACTIVATION,
                taskSettings.source,
                TypesOfRules.ACTIVE_DIRECTORY
            )
        })
    }

    useEffect(() => {
        return () => {
            actions.clearExcludeEmails()
        }
    }, [])

    const isActivationDisabled = () =>
        'fulfilled' !== activeDirectoryAccounts.status ||
        get(activeDirectoryAccounts, 'response.total') === excludeEmails.length

    return (
        <Fragment>
            <ModalContentWrapper>
                <AccountList />
            </ModalContentWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}> {t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={onActivate} disabled={isActivationDisabled()} loading={isLoading}>
                        <span>{t('backups:bulkActivation:activate')}</span>
                    </Button>
                    <Button
                        type="primary"
                        onClick={
                            notValidRule
                                ? () =>
                                      openErrorModal(
                                          <Trans
                                              i18nKey="backups:bulkActivation:validationError"
                                              values={{
                                                  type: t('backups:bulkActivation:choice:ACTIVE_DIRECTORY:type'),
                                                  items: notValidSelectedItems.items,
                                                  field: notValidSelectedItems.field
                                              }}
                                          >
                                              <strong>0</strong>
                                          </Trans>
                                      )
                                : handleActivateRule
                        }
                        disabled={isActivationDisabled()}
                        loading={isLoading}
                    >
                        <span>{t('backups:bulkActivation:activateRule')}</span>
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => actions.previousStep()} />
            </ModalFooter>
            <ConfirmationRuleSelectionModal
                open={isVisible}
                onOk={handleConfirmationSubmit}
                onCancel={handleConfirmationCancelClick}
            >
                <Header>
                    <WarningIcon component={Warning} />
                    <p>
                        {t('backups:byCondition:popoverHeader', {
                            entityName: toLower(getSvcAccountsDescription(taskSettings.source, true))
                        })}
                    </p>
                </Header>
            </ConfirmationRuleSelectionModal>
        </Fragment>
    )
}

const mapStateToProps = state => {
    const {
        checkedAccountPropertyValues,
        activeDirectoryAccounts,
        accountPropertyName
    } = state.backups.bulkActivation.activeDirectory

    return {
        checkedAccountPropertyValues,
        activeDirectoryAccounts,
        accountPropertyName,
        taskSettings: state.backups.settings.response,
        excludeEmails: state.backups.bulkActivation.excludeEmails,
        bulkActivationRules: state.backups.bulkActivation.bulkActivationRules.rules.response?.data || []
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            previousStep,
            clearExcludeEmails,
            setWizardDescription,
            activateBulkFlow,
            clearActiveDirectoryData,
            pullBackupEntities,
            clearWizard
        },
        dispatch
    )
})

export const ActiveDirectoryAccountsStep = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ActiveDirectoryAccountsRaw)
)
