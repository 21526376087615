import React, {MouseEventHandler} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Now from 'ca-common/icons/backupAction/Now.svg'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {BackupAction, GreyedBackupAction} from '../StyledBackupActions'

type BackupNowProps = {
    handlePerformBackupTaskAction: MouseEventHandler<HTMLSpanElement>
    disabled?: boolean
    greyedOut?: boolean
}

export const BackupNow = ({handlePerformBackupTaskAction, disabled, greyedOut}: BackupNowProps) => {
    const {t} = useTranslation()

    const Component = greyedOut ? GreyedBackupAction : BackupAction

    return (
        <Tooltip title={t(`backups:newActions:${TASK_ACTION.RUN}`)}>
            <Component component={Now} onClick={handlePerformBackupTaskAction} disabled={disabled} data-icon="now" />
        </Tooltip>
    )
}
