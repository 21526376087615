import {useCallback} from 'react'
import {FormInstance, FormProps} from 'antd'

import {AddIpFormFields} from 'ca-common/features/IpRestriction/types'
import {BOUND_FIELDS, FORM_FIELDS} from 'ca-common/features/IpRestriction/lib/constants'

export const useOnFieldsChange = (form: FormInstance<AddIpFormFields>) => {
    return useCallback<Exclude<FormProps<AddIpFormFields>['onFieldsChange'], undefined>>(
        (fields, allFields) => {
            const changedFields = fields.reduce((acc, field) => {
                const fieldName = (field.name as FORM_FIELDS[])[0]

                if (acc[fieldName]) {
                    return acc
                }

                if (BOUND_FIELDS.includes(fieldName)) {
                    BOUND_FIELDS.forEach(boundField => (acc[boundField] = true))
                } else if (fieldName === FORM_FIELDS.TYPE) {
                    acc[FORM_FIELDS.FROM] = true
                    acc[FORM_FIELDS.TO] = true
                    acc[FORM_FIELDS.ADDRESSES] = true
                } else {
                    acc[fieldName] = true
                }

                return acc
            }, {} as Record<FORM_FIELDS, boolean>)

            const newFields = allFields.map(field => {
                if (changedFields[(field.name as FORM_FIELDS[])[0]]) {
                    field.errors = []
                }

                return field
            })

            form.setFields(newFields)
        },
        [form]
    )
}
