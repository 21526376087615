import React from 'react'

import {StyledEntityColumn, ColumnValue, ColumnName} from './StyledEntityColumn'

type EntityColumnProps = {
    name: React.ReactNode
    value: React.ReactNode
    isHighlighted?: boolean
}

export const EntityColumn = ({name, value, isHighlighted}: EntityColumnProps): JSX.Element => {
    return (
        <StyledEntityColumn isHighlighted={isHighlighted}>
            <ColumnName>{name}</ColumnName>
            <ColumnValue>{value}</ColumnValue>
        </StyledEntityColumn>
    )
}
