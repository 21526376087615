import i18n from 'i18next'
import {MS365TASK_STATUS} from 'src/newcore/features/Recovery/lib/constants'

export const getTaskStatusFriendlyName = (status: MS365TASK_STATUS): JSX.Element => {
    switch (status) {
        case MS365TASK_STATUS.COMPLETED:
            return i18n.t('recovery:MS365:taskStatus:completed')
        case MS365TASK_STATUS.DEFERRED:
            return i18n.t('recovery:MS365:taskStatus:deferred')
        case MS365TASK_STATUS.INPROGRESS:
            return i18n.t('recovery:MS365:taskStatus:inprogress')
        case MS365TASK_STATUS.NOTSTARTED:
            return i18n.t('recovery:MS365:taskStatus:notstarted')
        case MS365TASK_STATUS.WAITINGONOTHERS:
            return i18n.t('recovery:MS365:taskStatus:waitingonothers')
    }
}
