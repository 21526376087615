import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Pause from 'ca-common/icons/backupAction/Pause.svg'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {BackupAction} from '../StyledBackupActions'

type BackupPauseProps = {
    handlePerformBackupTaskAction: (arg0: TASK_ACTION) => void
    disabled?: boolean
}

export const BackupPause = ({handlePerformBackupTaskAction, disabled}: BackupPauseProps) => {
    const {t} = useTranslation()

    return (
        <Tooltip title={t(`backups:newActions:${TASK_ACTION.PAUSE}`)}>
            <BackupAction
                component={Pause}
                onClick={() => handlePerformBackupTaskAction(TASK_ACTION.PAUSE)}
                disabled={disabled}
                data-icon="pause"
            />
        </Tooltip>
    )
}
