import React, {Dispatch, SetStateAction} from 'react'

import Search from 'ca-common/icons/SearchNew.svg'
import {RoundedInput} from 'ca-common/ui-lib/components/FormItem'

import {SearchIcon, StyledForm, StyledFormItem} from './StyledSearchField'

type SearchFieldProps = {
    placeholder: string
    searchData: Dispatch<SetStateAction<string>>
    disabled: boolean
}

export const SearchField = ({placeholder, searchData, disabled}: SearchFieldProps): JSX.Element => {
    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        searchData(event.target.value)
    }
    return (
        <StyledForm>
            <StyledFormItem>
                <RoundedInput
                    prefix={<SearchIcon component={Search} />}
                    placeholder={placeholder}
                    onChange={onInputChange}
                    allowClear
                    disabled={disabled}
                />
            </StyledFormItem>
        </StyledForm>
    )
}
