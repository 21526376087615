import type {LongDateNumber, MS365SubSource} from 'ca-common/types'
import type {SelectiveRecoveryState} from 'src/newcore/features/Recovery/localState'
import {ENTITY_SHAPE} from 'ca-common/common/enum/EntityShape'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import type {UIEntitiesBySubsource} from 'src/newcore/features/Recovery/lib/types'

export const getItemsForSelectiveRecovery = (
    date: LongDateNumber,
    selectedSubsourses: Array<MS365SubSource>,
    state?: SelectiveRecoveryState
): Partial<UIEntitiesBySubsource> => {
    const result: UIEntitiesBySubsource = {} as UIEntitiesBySubsource
    if (!state) return result
    selectedSubsourses?.forEach(source => {
        const subSourceState = state[source]
        result[source] = {
            isFull: false,
            uiEntities: []
        }
        if (subSourceState.checkAll === CHECK_STATE.CHECKED) {
            result[source].isFull = true
            return
        }
        subSourceState.checkedFolders.checked.forEach(folder => {
            result[source].uiEntities.push({
                filename: '',
                entityType: ENTITY_SHAPE.FOLDER,
                entityId: folder
            })
        })
        subSourceState.checkedFolders.halfChecked.forEach(folder => {
            const itemsByFolder = subSourceState.checkedItems[folder]
            if (!itemsByFolder) return
            if (typeof itemsByFolder.checked !== 'boolean' && itemsByFolder.checked.length > 0) {
                itemsByFolder.checked.forEach(itemId => {
                    const {itemsInfo} = subSourceState
                    if (!itemsInfo || !itemsInfo[itemId]) {
                        console.error(new Error(`${itemId}: no info found`))
                        return
                    }
                    result[source].uiEntities.push({
                        filename: itemsInfo[itemId].filename,
                        entityType: ENTITY_SHAPE.DOCUMENT,
                        entityId: itemId,
                        folderid: folder
                    })
                })
            }
            if (itemsByFolder.checked === true || itemsByFolder.unchecked.length) {
                result[source].uiEntities.push({
                    entityType: ENTITY_SHAPE.SEMI_FOLDER,
                    entityId: folder,
                    exclude: itemsByFolder.unchecked
                })
            }
        })
    })
    return result
}
