import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {get} from 'lodash'

import {BackLink, CancelLink, LinkWrapper, ModalFooter, ModalCenterContentWrapper} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'

import {
    changeData,
    changeProgress,
    closeWizard,
    nextStep,
    previousStep,
    changeStep,
    removeData
} from 'src/newcore/redux/modules/wizard'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

import {CHOOSE_OBJECT_TYPE_STEP} from 'src/newcore/components/Seeding/seedingConstants'
import {withSetWizardTitleAndDescription} from 'src/newcore/components/Seeding/hooks'
import {AnonymizationModeSwitcher} from 'src/newcore/components/Seeding/AnonymizationModeSwitcher'

export class SecondStepCommonRaw extends React.Component {
    onChange = e => {
        const {actions} = this.props

        actions.changeProgress(CHOOSE_OBJECT_TYPE_STEP)
        actions.changeData({objectType: e.target.value})
    }

    previousStep = () => {
        const {actions} = this.props

        actions.previousStep()
    }

    nextStep = () => {
        const {actions, isEnabledAnonymization} = this.props

        if (isEnabledAnonymization) {
            track(EVENT_NAMES.SALESFORCE_SANDBOX_ANONYMIZATION, {})
        }

        actions.nextStep()
    }

    render() {
        const {t, actions, objectType, objectSelection, isEnabledAnonymization, dataType} = this.props

        return (
            <Fragment>
                <ModalCenterContentWrapper>
                    {objectSelection ? objectSelection(this.onChange, objectType) : null}
                </ModalCenterContentWrapper>
                {dataType === SFC_DATA_TYPE.DATA && (
                    <AnonymizationModeSwitcher
                        isEnabledAnonymization={isEnabledAnonymization}
                        changeData={actions.changeData}
                    />
                )}
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={() => actions.closeWizard()}> {t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={this.nextStep} disabled={!objectType}>
                            {t('forms:common:actions:next')}
                        </Button>
                    </LinkWrapper>
                    <BackLink onClick={this.previousStep} />
                </ModalFooter>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    objectType: get(state, 'wizard.data.objectType'),
    dataType: get(state, 'wizard.data.dataType'),
    isEnabledAnonymization: get(state, 'wizard.data.isEnabledAnonymization')
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            changeStep,
            changeProgress,
            previousStep,
            closeWizard,
            changeData,
            removeData
        },
        dispatch
    )
})

export const SecondStepCommon = withSetWizardTitleAndDescription(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SecondStepCommonRaw))
)
