import React, {useState} from 'react'

import Arrow from 'ca-common/icons/old/uEA0D-arrow-right.svg'

import {
    Content,
    Description,
    Header,
    StyledIcon,
    StyledJumbotronToggle,
    Title,
    StyledJumbotronToggleProps
} from './StyledJumbotronToggle'

type JumbotronToggleProps = {
    title: string
} & Omit<StyledJumbotronToggleProps, 'isOpen'>

export const JumbotronToggle = (props: React.PropsWithChildren<JumbotronToggleProps>) => {
    const {children, title, um, padding, margin} = props

    const [isOpen, setIsOpen] = useState(true)

    const toggleSection = () => {
        setIsOpen(prev => !prev)
    }

    return (
        <StyledJumbotronToggle isOpen={isOpen} um={um} padding={padding} margin={margin}>
            <Header onClick={toggleSection}>
                <StyledIcon component={Arrow} rotate={isOpen ? 90 : 0} />
                <Title>{title}</Title>
            </Header>
            <Content isOpen={isOpen}>
                <Description um={um}>{children}</Description>
            </Content>
        </StyledJumbotronToggle>
    )
}
