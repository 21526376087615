import React from 'react'

import West from 'ca-common/images/clientLogo/West.png'
import Aya from 'ca-common/images/clientLogo/Aya.png'
import Abbott from 'ca-common/images/clientLogo/Abbott.png'
import Wix from 'ca-common/images/clientLogo/Wix.png'
import Jaguar from 'ca-common/images/clientLogo/Jaguar.png'
import Mitsubishi from 'ca-common/images/clientLogo/Mitsubishi.png'
import Monday from 'ca-common/images/clientLogo/Monday.png'
import Ramvik from 'ca-common/images/clientLogo/Ramvik.png'
import Single from 'ca-common/images/clientLogo/Single.png'

export enum BADGES {
    SA_FR_2023 = '01_SA_FR_2023',
    C_SL_2023 = '02_C_SL_2023',
    HPW_2023 = '03_HPW_2023',
    SF_CLU_2023 = '04_SF_CLU_2023',
    GA_CL_2023 = '05_GA_CL_2023'
}

export const LogoComponents = [Abbott, Ramvik, Aya, Single, Wix, West, Mitsubishi, Jaguar, Monday] as string[]
