import React, {Fragment, useMemo} from 'react'

import {Tooltip} from 'antd'

import {
    ACTION_EDIT,
    COLUMN_NAMES,
    ALERT_STATUS_ACTIVE,
    ALERT_STATUS_PAUSED,
    ACTION_DISABLE,
    ACTION_ENABLE,
    getSmartAlertActionIcon,
    getSmartAlertActionTooltipFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import {StyledEdit, StyledIcon} from './StyledAction'

export const Actions = props => {
    const {record, onSingleActionHandler} = props

    const triggeredAction = useMemo(() => {
        switch (record[COLUMN_NAMES.ALERT_STATUS]) {
            case ALERT_STATUS_ACTIVE:
                return ACTION_DISABLE

            case ALERT_STATUS_PAUSED:
                return ACTION_ENABLE

            default:
                return ''
        }
    }, [record[COLUMN_NAMES.ALERT_STATUS]])

    const onEditHandler = () => onSingleActionHandler(ACTION_EDIT, record)

    const onTriggeredHandler = () => onSingleActionHandler(triggeredAction, record?.[COLUMN_NAMES.ID])

    return (
        <Fragment>
            <Tooltip mouseLeaveDelay={0} title={getSmartAlertActionTooltipFriendly(triggeredAction)}>
                <StyledIcon component={getSmartAlertActionIcon(triggeredAction)} onClick={onTriggeredHandler} />
            </Tooltip>
            <Tooltip mouseLeaveDelay={0} title={getSmartAlertActionTooltipFriendly(ACTION_EDIT)}>
                <StyledEdit component={getSmartAlertActionIcon(ACTION_EDIT)} onClick={onEditHandler} />
            </Tooltip>
        </Fragment>
    )
}
