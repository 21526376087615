import React from 'react'
import styled, {css} from 'styled-components'
import {transparentize} from 'polished'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

type IconProps = {accent: boolean; checked: boolean; halfChecked: boolean; component: any}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledIcon = styled(({accent, checked, halfChecked, ...props}: IconProps) => <Icon {...props} />)`
    font-size: 24px;

    ${props =>
        props.accent &&
        css`
            path {
                stroke: ${variables.inProcess} !important;
            }
        `}

    ${props =>
        props.accent &&
        props.checked &&
        css`
            path {
                fill: ${variables.inProcess} !important;
            }
        `}

    ${props =>
        props.accent &&
        props.halfChecked &&
        css`
            path {
                fill: ${transparentize(0.3, variables.inProcess)} !important;
            }
        `}
`
