import _ from 'lodash'
import {findDOMNode} from 'react-dom'
import {checkPermission} from 'ca-common/utils/permissions'
import {CASelectItem, CASelectAll, CACollapseArrow} from 'ca-common/components/Table/CellModifiers/Content'

export const generateModifier = modifiers =>
    _.reduce(
        modifiers,
        (result, value, key) => {
            result += value ? `${key} ` : ''
            return result
        },
        ''
    )

export const handleScrollTable = (target, header, content, scrollLeft) => {
    const headerNode = findDOMNode(header)
    const contentNode = findDOMNode(content)

    if (target === contentNode) {
        headerNode.scrollLeft = scrollLeft
    } else if (target === headerNode) {
        contentNode.scrollLeft = scrollLeft
    }
}

export const getTableMinWidth = header => {
    const headerNode = findDOMNode(header)
    let firstChild
    let lastChild

    if (headerNode) {
        firstChild = headerNode.firstElementChild.getBoundingClientRect()
        lastChild = headerNode.lastElementChild.getBoundingClientRect()

        return {minWidth: lastChild.x + lastChild.width - firstChild.x}
    }

    return null
}

export const makeRowModifiers = (item, i, config) => {
    const modifier = []

    if (config.row && config.row.getRowModifier) {
        modifier.push(config.row.getRowModifier(item))
    }

    return modifier
}

export const excludeOptionFromFormValues = (options, excludeOption) => _.pickBy(options, v => v !== excludeOption)

//@TODO make it in one func with makeCollapsibleConfig
export const makeSelectableConfig = (config, rowSelection) => {
    const selectColumn = {
        name: 'selection',
        modifier: '30px selection',
        headerCell: CASelectAll,
        contentCell: CASelectItem
    }

    return rowSelection
        ? _.reduce(
              config,
              (selectableConfig, value, key) => {
                  if (key === 'columns' && value[0].name !== 'selection') {
                      value.unshift(selectColumn)
                  }
                  selectableConfig[key] = value

                  return selectableConfig
              },
              {}
          )
        : config
}

export const makeCollapsibleConfig = config => {
    const collapseColumn = {
        name: 'collapse',
        modifier: '30px center no-padding',
        contentCell: CACollapseArrow
    }

    return config.table.rowCollapseContent
        ? _.reduce(
              config,
              (collapsibleConfig, value, key) => {
                  if (key === 'columns' && value[1].name !== 'collapse') {
                      value.unshift(collapseColumn)
                  }
                  collapsibleConfig[key] = value

                  return collapsibleConfig
              },
              {}
          )
        : config
}

export const makeModifiedConfig = (config, rowSelection) =>
    makeSelectableConfig(makeCollapsibleConfig(config), rowSelection)

//@TODO Move it from table utils
export const showButtonGroup = (actions, permissions) => {
    return Array.isArray(actions) ? actions.some(action => isActionPermitted(action, permissions)) : false
}

export const isActionPermitted = (action, permissions) => {
    return checkPermission(`BACKUP.${action}`, permissions)
}

/*
Functions relates only for selectable tables
 */

export const checkedOnThisPage = rowSelection =>
    _.intersectionBy(rowSelection.currentPageItems, rowSelection.selectedItems, 'id')

export const uncheckedOnThisPage = rowSelection =>
    _.xorBy(rowSelection.currentPageItems, [...checkedOnThisPage(rowSelection)], 'id')

export const isAllItemsOnPageAreSelected = rowSelection => {
    const currentPageItems = rowSelection.currentPageItems
    const selectedItems = rowSelection.selectedItems || []
    let evenOneUncheckedItem

    if (currentPageItems && currentPageItems.length && currentPageItems.length <= selectedItems.length) {
        _.forEach(currentPageItems, item => {
            evenOneUncheckedItem = _.some(selectedItems, item) ? evenOneUncheckedItem : true
        })
    } else {
        evenOneUncheckedItem = true
    }
    return !evenOneUncheckedItem
}
