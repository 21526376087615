import React from 'react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Spin} from 'antd'
import isEmpty from 'lodash/isEmpty'

import {ObjectHistory} from 'src/newcore/features/ObjectHistory'

import {Chart} from './organisms'
import {SmallContainer} from './atoms'

type SmallBackupChartProps = {
    sfcObjectHistory: ObjectHistory
}

export const SmallBackupChart = (props: SmallBackupChartProps): JSX.Element => {
    const {sfcObjectHistory} = props

    const hasData = (sfcObjectHistory: ObjectHistory): boolean => {
        return !isEmpty(sfcObjectHistory?.response?.data)
    }

    return (
        <Spin spinning={sfcObjectHistory?.status !== 'fulfilled'}>
            <SmallContainer>
                <ParentSize>
                    {({width, height}) =>
                        hasData(sfcObjectHistory) ? (
                            <Chart width={width} height={height} data={sfcObjectHistory?.response?.data} />
                        ) : null
                    }
                </ParentSize>
            </SmallContainer>
        </Spin>
    )
}
