import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import {CancelLink, LinkWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import Replicate from 'ca-common/icons/Replicate.svg'
import variables from 'ca-common/variables'

import {clearWizard, setWizardDescription, previousStep, wizardDataSelector} from 'src/newcore/redux/modules/wizard'

import type {WizardData} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'
import {initSeeding} from 'src/newcore/redux/modules/backups/seeding'
import {setActiveTab} from 'src/newcore/redux/modules/backups/backupTaskSettings'
import {PANES_KEYS} from 'src/newcore/components/SFCTabs/SFCTabPanes'
import {getReplicates} from 'src/newcore/redux/modules/backups/backupSeeding'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {anonymizationObjectsSelector} from 'src/newcore/components/Seeding/DataAnonymizationStep/redux'
import {getSeedingRequest} from 'src/newcore/components/Seeding/seedingUtils'
import type {MetadataObjectsList, ObjectsList} from 'src/newcore/components/Seeding/types'
import {SelectDestination} from './SelectDestination'
import {LastStepStyled} from './styled'
import {trackSFCReplicationData} from './trackSFCReplicationData'

type LastStepCommonProps = {
    objectsList: {
        response: {
            data: ObjectsList | MetadataObjectsList
        }
    }
}

export const LastStepCommon = ({objectsList}: LastStepCommonProps) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)
    const replicatesStatus = useAppSelector((state: AppState) => state.backups.seeding.replicates.status)
    const wizardData = useAppSelector(wizardDataSelector) as WizardData
    const anonymizationObjects = useAppSelector(anonymizationObjectsSelector)

    useEffect(() => {
        dispatch(setWizardDescription(t('seeding:fifthStep:description')))
    }, [])

    const submitWizard = () => {
        dispatch(initSeeding(getSeedingRequest(taskSettings, wizardData, objectsList, anonymizationObjects)))
            .unwrap()
            .then((response: any) => {
                if (response.needRedirect) {
                    window.location.href = response.redirectUrl
                } else {
                    dispatch(setActiveTab(PANES_KEYS.REPLICATES))
                    if (replicatesStatus !== 'init') {
                        dispatch(
                            getReplicates({
                                taskId: taskSettings?.taskId
                            })
                        )
                    }

                    openSuccessNotification(response.notificationMessage)
                }
                dispatch(clearWizard())

                trackSFCReplicationData({wizardData, objectsList: objectsList?.response?.data})
            })
            .catch((err: any) => err.data?.status && openErrorModal(err.data?.status))
    }

    return (
        <div>
            <LastStepStyled>
                <SelectDestination sameEmail={taskSettings?.account || ''} />
            </LastStepStyled>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={() => dispatch(clearWizard())}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" color={variables.iceBlue} onClick={submitWizard}>
                        <Icon component={Replicate} />
                        {t('seeding:title')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => dispatch(previousStep())} />
            </ModalFooter>
        </div>
    )
}
