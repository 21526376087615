import React from 'react'
import i18n from 'i18next'
import {TODO_ANY} from '../types'

/**
 * @deprecated showMultiline should be removed in the future, use Trans component with <br /> instead
 */
export function showMultiline(text: string): React.ReactNode {
    return text.split('\n').map(line => <p key={line.toString()}>{line}</p>)
}

export function generateCardLabelName(card: TODO_ANY): string {
    return `${card.cardType}: XXXX-XXXX-XXXX-${card.lastFourDigits} (${card.expirationMonth}/${card.expirationYear})`
}

export const utf8ToB64 = (str: string): string => {
    return window.btoa(unescape(encodeURIComponent(str)))
}

export const getSvcAccountsDescription = (source: string, isPlural = false, isDelete?: boolean): string => {
    const key = isPlural ? `${source}_other` : isDelete ? `${source}_one` : source

    return i18n.t(`subSvcCountDisp:${key}`)
}

export const formatEntity = (name: string): string => {
    let index = name.indexOf('/personal/')

    if (index !== -1) {
        return `...${name.slice(index + 9)}`
    }
    index = name.indexOf('/')
    if (index !== -1) {
        return `...${name.slice(index)}`
    }
    return name
}

export const getCountedEntityName = (source: string, count: number): string | null => {
    const entityType = getSvcAccountsDescription(source, count > 1).toLowerCase()

    return entityType ? `${count} ${entityType}` : null
}
