import {useEffect} from 'react'

import {WIZARD_TYPE} from 'ca-common/constants'
import {SOURCE} from 'ca-common/common/enum'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {changeData, openWizardWithConfig, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {getAllowedServices} from 'src/newcore/utils/sources'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

import {useGetMSTotalEntitiesQuery, useCreateOCATasksQuery} from 'src/newcore/features/OneClickActivation/redux'
import type {BackupConfig, CreateOCATasksError, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {
    DEFAULT_WIZARD_CONFIG,
    ENTITIES_OPTION,
    FIELDS,
    getBackupConfigDefaultValue,
    useOpenServicesSelectionModal
} from 'src/newcore/features/OneClickActivation/lib'

type UseOCAWizardParams = {
    showWizard?: boolean
    stateId: string
}

export const useOCAWizard = ({showWizard, stateId}: UseOCAWizardParams) => {
    const dispatch = useAppDispatch()
    const openServicesSelectionModal = useOpenServicesSelectionModal()

    const userInfo = useAppSelector(userInfoSelector)
    const availableServices = getAllowedServices(userInfo?.permissions)
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData

    const {data: createOCATasksResponse, error: createOCATasksResponseError} = useCreateOCATasksQuery(stateId, {
        skip: !showWizard || !wizardData.isWaitingOver
    })
    const {data: totalEntities} = useGetMSTotalEntitiesQuery(createOCATasksResponse?.id as string, {
        skip: !showWizard || !createOCATasksResponse || !wizardData.isWaitingOver
    })

    useEffect(() => {
        if (showWizard) {
            dispatch(changeData({stateId}))
            dispatch(
                openWizardWithConfig({
                    type: WIZARD_TYPE.ONE_CLICK_ACTIVATION,
                    config: {
                        ...DEFAULT_WIZARD_CONFIG,
                        maskClosable: false,
                        destroyInactiveTabPane: true
                    }
                })
            )
        }
    }, [])

    useEffect(() => {
        if (totalEntities && !wizardData.isTotalEntitiesLoaded) {
            const isUseCustomEntitiesSelection =
                wizardData[FIELDS.ENTITIES_OPTION] === ENTITIES_OPTION.ACTIVATE_SELECTED_ENTITIES

            const defaultFormsData = totalEntities.reduce((acc, {source, totalItems}) => {
                acc[source] = getBackupConfigDefaultValue(source, totalEntities)

                if (isUseCustomEntitiesSelection) {
                    acc[source].enabledEntitiesBitSet = ''.padStart(totalItems, '0')
                }

                return acc
            }, {} as Record<keyof typeof SOURCE, BackupConfig>)

            dispatch(changeData({...defaultFormsData, isTotalEntitiesLoaded: true}))
        }
    }, [totalEntities, wizardData])

    useEffect(() => {
        if (createOCATasksResponseError) {
            const {enabledServices, error} =
                ((createOCATasksResponseError as unknown) as CreateOCATasksError)?.data || {}

            if (enabledServices || error) {
                openServicesSelectionModal({
                    enabledServices,
                    error,
                    availableServices
                })
            }
        }
    }, [createOCATasksResponseError])
}
