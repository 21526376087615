import React, {useEffect, useState} from 'react'
import {Menu} from 'antd'
import map from 'lodash/map'
import {TooltipPlacement} from 'antd/lib/tooltip'
import {PopoverProps} from 'antd/lib/popover'

import {ButtonState} from 'ca-common/ui-lib/atoms/Button'
import {ButtonPopover, StyledChevron, StyledIcon, StyledMenu} from './StyledDropDownButton'

import DropDown from 'ca-common/icons/DropDown.svg'
import {IconComponent} from 'ca-common/types'

type DropDownButtonProps = {
    options?: Array<{
        value: string | number
        label: string | number
    }>
    color: string
    buttonText: string | JSX.Element
    disabled?: boolean
    width: number
    action?: (optionValue: string | number) => void
    ghost?: boolean
    dropdownRender?: JSX.Element | false
    icon?: IconComponent
    className?: string
    placement?: TooltipPlacement
    getPopupContainer?: PopoverProps['getPopupContainer']
}

export const DropDownButton = React.forwardRef(
    (
        {
            options,
            color,
            buttonText,
            disabled,
            width,
            action,
            ghost,
            dropdownRender,
            icon,
            className,
            placement = 'bottom',
            getPopupContainer
        }: DropDownButtonProps,
        ref
    ) => {
        const [visible, setVisible] = useState(false)

        useEffect(() => {
            setVisible(!disabled && visible)
        }, [disabled, visible])

        const content = (
            <StyledMenu ref={ref}>
                {map(options, option => (
                    <Menu.Item
                        onClick={() => {
                            if (action) action(option.value)
                            setVisible(false)
                        }}
                        key={option.value}
                    >
                        {option.label}
                    </Menu.Item>
                ))}
            </StyledMenu>
        )

        return (
            <ButtonPopover
                content={dropdownRender || content}
                open={visible}
                onOpenChange={setVisible}
                width={width}
                placement={placement}
                trigger="click"
                className={className}
                getPopupContainer={getPopupContainer}
            >
                <ButtonState
                    icon={<StyledIcon component={icon} />}
                    color={color}
                    shape="round"
                    active={visible}
                    disabled={disabled}
                    width={width}
                    ghost={ghost}
                >
                    {(buttonText as JSX.Element).type ? buttonText : <span>{buttonText}</span>}
                    <StyledChevron ghost={ghost} open={visible} component={DropDown} />
                </ButtonState>
            </ButtonPopover>
        )
    }
)
