import React, {useEffect, useMemo} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import capitalize from 'lodash/capitalize'
import {useHistory} from 'react-router-dom'
import {Spin} from 'antd'

import {checkPermission} from 'ca-common/utils/permissions'
import {NewTaskSettings, ResponseType, UserInfoDataType, MSTaskEntityType} from 'ca-common/types'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {Button} from 'ca-common/ui-lib/atoms'
import {BackLink, CancelLink, CenteredContent, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {PAGES} from 'ca-common/constants'
import {RECOVERY_TYPE, SOURCE} from 'ca-common/common/enum'
import {isEntityAvailableForSelectiveRecovery} from 'ca-common/utils/recovery'
import {getServiceNameMixpanel, track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

import {
    changeData,
    changeProgress,
    closeWizard,
    nextStep,
    previousStep,
    setWizardIcon,
    setWizardTitle,
    setWizardDescription
} from 'src/newcore/redux/modules/wizard'
import {TooltipedInfo} from 'src/newcore/features/RecoveryWizard/atoms/TooltipedInfo'
import {RECOVERY_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'
import {FullRecoveryOptions} from 'src/newcore/features/RecoveryWizard/molecules/FullRecoveryOptions'
import {getNewBackupTaskSettings, clearBackupTaskSettingsForAdvanced} from 'src/newcore/features/Recovery/redux'

import {AppDispatch, AppState} from 'src/newcore/components/Root'

export type dataType = {
    userInfo: ResponseType<UserInfoDataType>
    wizardData: {
        entity: MSTaskEntityType
        recoveryOptions?: RECOVERY_OPTIONS
        fullRecoveryOptions?: RECOVERY_TYPE
        taskId: string
        from?: string
        source: SOURCE
    }
    task: NewTaskSettings
    wizardReferral: string
}

enum PERMITTED_RECOVERY_OPTIONS {
    FULL = 'FULL',
    EXPORT_ONLY = 'EXPORT_ONLY',
    RESTORE_ONLY = 'RESTORE_ONLY'
}

const getWizardReferral = (referral: string): string => {
    const wizardReferral: Record<string, string> = {
        [PAGES.HOMEPAGE]: 'Homepage Advanced Recovery Wizard',
        [PAGES.BACKUPS]: 'Backup Page Advanced Recovery Wizard'
    }

    return wizardReferral[referral] || 'Other'
}

export const AdvancedRecoveryOptionsRaw = ({
    actions,
    wizardData,
    userInfo,
    task,
    wizardReferral,
    allEntities
}: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>): JSX.Element => {
    const {t} = useTranslation()
    const {from, recoveryOptions, fullRecoveryOptions, entity, taskId} = wizardData
    const changeRecoveryOptions = (recoveryOptions: RECOVERY_OPTIONS) => actions.changeData({recoveryOptions})
    const changeFullRecoveryOptions = (fullRecoveryOptions: RECOVERY_TYPE) => actions.changeData({fullRecoveryOptions})
    const history = useHistory()
    const isMsEndUser = userInfo.response?.isMsEndUser

    const permittedOption = useMemo(() => {
        if (
            checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response?.permissions) &&
            checkPermission('BACKUP.MS365.RESTORE', userInfo.response?.permissions)
        ) {
            return PERMITTED_RECOVERY_OPTIONS.FULL
        } else if (checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response?.permissions)) {
            return PERMITTED_RECOVERY_OPTIONS.EXPORT_ONLY
        } else {
            return PERMITTED_RECOVERY_OPTIONS.RESTORE_ONLY
        }
    }, [userInfo.response?.permissions])

    const fullRecoveryDescription = useMemo(() => {
        switch (permittedOption) {
            case PERMITTED_RECOVERY_OPTIONS.FULL:
                return t(`recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:description`)

            default:
                return t(`recovery:wizard:advancedOptions:${permittedOption}:description`)
        }
    }, [permittedOption])

    const fullRecoveryTitle = useMemo(() => {
        switch (permittedOption) {
            case PERMITTED_RECOVERY_OPTIONS.FULL:
                return t(`recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:label`)

            default:
                return (
                    <>
                        {t(`recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:label`)}
                        <TooltipedInfo title={t(`recovery:wizard:advancedOptions:${permittedOption}:info`)} />
                    </>
                )
        }
    }, [permittedOption])

    useEffect(() => {
        if (task?.taskId !== taskId) {
            actions.clearBackupTaskSettingsForAdvanced()
            actions.getNewBackupTaskSettings({taskId})
        }
        actions.setWizardTitle(t(`recovery:wizard:advancedOptions:title`))
        actions.setWizardDescription(entity?.entity)
    }, [])

    useEffect(() => {
        if (!fullRecoveryOptions) return

        actions.setWizardTitle(
            t('recovery:wizard:fullMailboxDateConfigure:title', {option: capitalize(fullRecoveryOptions)})
        )
        actions.setWizardIcon(fullRecoveryOptions)
        if (isMsEndUser) actions.changeData({entity: allEntities[0]})
    }, [fullRecoveryOptions])

    useEffect(() => {
        if (permittedOption !== PERMITTED_RECOVERY_OPTIONS.FULL) {
            actions.changeData({
                fullRecoveryOptions:
                    permittedOption === PERMITTED_RECOVERY_OPTIONS.RESTORE_ONLY
                        ? RECOVERY_TYPE.RESTORE
                        : RECOVERY_TYPE.EXPORT
            })
        }
    }, [permittedOption])

    if (isMsEndUser) {
        useEffect(() => {
            actions.setWizardDescription(allEntities && allEntities[0]?.entity)
        }, [allEntities])
    }

    const handleCancel = () => {
        actions.closeWizard()
    }

    const handleNextStep = () => {
        actions.nextStep()
    }

    if (RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY === recoveryOptions) {
        const recoveryId = isMsEndUser && allEntities && allEntities[0].id
        const entityId = recoveryId ? recoveryId : entity.id
        track(EVENT_NAMES.VIEW_RECOVERY_PAGE, {
            Service: getServiceNameMixpanel(wizardData.source),
            'Initiated From': getWizardReferral(PAGES.HOMEPAGE)
        })
        history.push(`/${PAGES.RECOVERY}/${taskId}/${entityId}`)
        handleCancel()
    }

    return (
        <>
            <Spin spinning={!task || (isMsEndUser && !allEntities)}>
                <CenteredContent>
                    <RadioBlock
                        checked={RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY === recoveryOptions}
                        value={RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}
                        onChange={() => changeRecoveryOptions(RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY)}
                        showRadioIcon={false}
                        title={fullRecoveryTitle}
                        description={fullRecoveryDescription}
                    >
                        {permittedOption === PERMITTED_RECOVERY_OPTIONS.FULL && (
                            <FullRecoveryOptions
                                fullRecoveryOptions={fullRecoveryOptions}
                                changeFullRecoveryOptions={changeFullRecoveryOptions}
                            />
                        )}
                    </RadioBlock>
                    {(isEntityAvailableForSelectiveRecovery(task, entity) || isMsEndUser) && (
                        <RadioBlock
                            checked={RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY === recoveryOptions}
                            value={RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY}
                            onChange={() => changeRecoveryOptions(RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY)}
                            showRadioIcon={false}
                            title={t(
                                `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY}:label`
                            )}
                            description={t(
                                `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.SELECTIVE_MAILBOX_RECOVERY}:description`
                            )}
                        />
                    )}
                </CenteredContent>
            </Spin>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button disabled={!fullRecoveryOptions} type="primary" onClick={handleNextStep}>
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                {from === PAGES.HOMEPAGE && (
                    <BackLink
                        onClick={() => {
                            actions.previousStep()
                            actions.setWizardTitle(t('recovery:wizard:mailboxSelection:title'))
                            actions.setWizardDescription(entity?.entity)
                        }}
                    />
                )}
            </ModalFooter>
        </>
    )
}

const mapStateToProps = ({wizard, userInfo, recoveryNew, backups}: AppState) => ({
    recoveryOptions: wizard.data.recoveryOptions,
    fullRecoveryOptions: wizard.data.fullRecoveryOptions,
    userInfo,
    wizardData: wizard.data,
    task: recoveryNew.settings?.response,
    wizardReferral: wizard.data.from,
    allEntities: backups.backupEntities.allEntities.response?.data
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            changeData,
            setWizardTitle,
            changeProgress,
            setWizardIcon,
            previousStep,
            getNewBackupTaskSettings,
            clearBackupTaskSettingsForAdvanced,
            setWizardDescription
        },
        dispatch
    )
})

export const AdvancedRecoveryOptions = connect(mapStateToProps, mapDispatchToProps)(AdvancedRecoveryOptionsRaw)
