import React from 'react'
import {useTranslation} from 'react-i18next'

import {EmailsTableItem} from 'src/newcore/features/Recovery/fetch'

import {EmptyRow, SingleField, StyledExpandedRow} from 'src/newcore/features/Recovery/atoms'

type ExpandedEmailRowProps = {
    record: EmailsTableItem
}

export const ExpandedEmailRow = (props: ExpandedEmailRowProps): JSX.Element => {
    const {
        record: {message, recipients: recipientsArr}
    } = props
    const {t} = useTranslation()
    const recipients = recipientsArr.filter(Boolean).join('; ')

    if (!message && !recipients) {
        return <EmptyRow />
    }

    return (
        <StyledExpandedRow>
            {recipients && <SingleField title={t('table:rows:fields:recipients')} value={recipients} isShortValue />}
            {message && <SingleField title={t('table:rows:fields:message')} value={message} />}
        </StyledExpandedRow>
    )
}
