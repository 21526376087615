import React from 'react'
import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'

import type {SizeType} from 'antd/lib/config-provider/SizeContext'

import type {IconComponent} from 'ca-common/types'
import {StyledButton} from './StyledIconButton'

type IconButtonProps = {
    disabled?: boolean
    onClick?: () => void
    tooltipText?: string
    icon: IconComponent
    size?: SizeType
}

export const IconButton = ({disabled, onClick, tooltipText, icon, size}: IconButtonProps): JSX.Element => {
    return (
        <Tooltip arrowPointAtCenter placement="topRight" title={tooltipText}>
            <div>
                <StyledButton
                    size={size}
                    borderWidth={2}
                    align="center"
                    shape="circle"
                    onClick={onClick}
                    disabled={disabled}
                >
                    <Icon component={icon} />
                </StyledButton>
            </div>
        </Tooltip>
    )
}
