import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Note} from 'ca-common/atoms/Note'
import {Table} from 'ca-common/ui-lib/organisms/Table'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'

import {post} from 'src/newcore/utils/rest'
import {useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {StatusInfo} from 'src/newcore/features/BillingStatus/molecules/StatusInfo'
import {TableTitle} from 'src/newcore/features/BillingStatus/molecules/TableTitle'
import {useColumns} from 'src/newcore/features/BillingStatus/lib/useColumns'
import {
    billingLineSelector,
    billingSelector,
    paymentSelector,
    userEmailSelector
} from 'src/newcore/features/BillingStatus/redux'
import {getScrollAfterX} from 'src/newcore/features/BillingStatus/lib/getScrollAfterX'
import {usePaymentsColumns} from 'src/newcore/features/BillingStatus/lib/usePaymentsColums'
import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'

import {Email, StyledBillingStatusContent} from './StyledBillingStatusContent'

type BillingStatusContentProps = {
    isCustomerShowWithoutDiscount: boolean
}

export const BillingStatusContent = ({isCustomerShowWithoutDiscount}: BillingStatusContentProps) => {
    const {t} = useTranslation()

    const billingLine = useAppSelector(billingLineSelector)
    const billing = useAppSelector(billingSelector)
    const userEmail = useAppSelector(userEmailSelector)
    const paymentTable = useAppSelector(paymentSelector)
    const paymentStatus = billing.response?.status
    const isDueOrOverdueorUnpaid =
        paymentStatus === PaymentStatus.DUE ||
        paymentStatus === PaymentStatus.OVERDUE ||
        paymentStatus === PaymentStatus.UNPAID

    const sendUserInvoiceEmail = async (documentId: string) => {
        return post('sendUserInvoiceEmail', {documentId})
            .then(() => {
                openSuccessNotification(
                    <Trans i18nKey="billing:alert:emailSentSuccessfulText" tOptions={{email: userEmail}}>
                        <Email />
                    </Trans>,
                    t('billing:alert:emailSentSuccessfulTitle')
                )
            })
            .catch(error => {
                openErrorNotification(error.status)
            })
    }

    const columns = useColumns({
        paymentStatus: billing.response?.status,
        response: billing.response,
        sendUserInvoiceEmail
    })
    const paymentsColumns = usePaymentsColumns()

    return (
        <StyledBillingStatusContent>
            <Note i18nKey="billing:note" />
            <StatusInfo
                loading={billing.status !== 'fulfilled'}
                data={billing.response}
                isCustomerShowWithoutDiscount={isCustomerShowWithoutDiscount}
            />
            <div>
                {isDueOrOverdueorUnpaid && !isCustomerShowWithoutDiscount && (
                    <Table
                        columns={columns}
                        rowKey={(row: any) => row.id || row.service}
                        dataSource={billingLine.response || []}
                        loading={
                            !(
                                billingLine.status == 'fulfilled' ||
                                billingLine.status == 'rejected' ||
                                billing.status == 'fulfilled'
                            )
                        }
                        pagination={false}
                        scroll={
                            billingLine.response?.length ? {x: getScrollAfterX(billing.response?.status)} : undefined
                        }
                        title={() =>
                            billing?.response?.paymentType ? (
                                <TableTitle paymentStatus={billing.response?.status} />
                            ) : null
                        }
                    />
                )}
                <Table
                    columns={paymentsColumns}
                    rowKey={(row: any) => row.id || row.service}
                    dataSource={paymentTable.response || []}
                    loading={paymentTable.status !== 'fulfilled' || billing.status !== 'fulfilled'}
                    pagination={false}
                    scroll={paymentTable.response?.length ? {x: getScrollAfterX(billing.response?.status)} : undefined}
                    title={() => <TableTitle paymentStatus={PaymentStatus.PAID} />}
                />
            </div>
        </StyledBillingStatusContent>
    )
}
