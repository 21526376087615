import {Tooltip} from 'antd'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ListTitle} from 'src/newcore/components/Seeding/DataAnonymizationStep/StyledDataAnonymizationStep'

import {TOOLTIP_MOUSE_ENTER_DELAY} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib'
import {ListWrapper, MainWrapper, StyledSearchField, Label} from './StyledObjectsList'

type ObjectsListProps = {
    objectNameList: string[]
    activeObject?: string
    setActiveObject: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const ObjectsList = ({objectNameList, activeObject, setActiveObject}: ObjectsListProps) => {
    const {t} = useTranslation()
    const [search, setSearch] = useState('')
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value)
    }

    const showedObjects = useMemo(() => {
        const showedObjects = objectNameList.filter(item => item.toLowerCase().includes(search.toLowerCase()))

        setActiveObject(prev => {
            if (!showedObjects.find(item => item === prev)) {
                return showedObjects[0]
            }

            return prev
        })

        return showedObjects
    }, [search, objectNameList])

    return (
        <MainWrapper>
            <StyledSearchField
                value={search}
                onChange={onSearchChange}
                placeholder={t('seeding:dataAnonymizationStep:searchObjectPlaceholder')}
            />
            <ListTitle>
                {search
                    ? t(`seeding:dataAnonymizationStep:objectFilterOptions:SEARCH_RESULT`)
                    : t(`seeding:dataAnonymizationStep:objectFilterOptions:ALL_OBJECTS`)}
            </ListTitle>
            <ListWrapper>
                {showedObjects.map(name => (
                    <Tooltip title={name} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
                        <Label isActive={name === activeObject} onClick={() => setActiveObject(name)} key={name}>
                            {name}
                        </Label>
                    </Tooltip>
                ))}
            </ListWrapper>
        </MainWrapper>
    )
}
