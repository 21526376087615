import React from 'react'
import {Tooltip} from 'antd'
import styled from 'styled-components'
import {Trans, useTranslation} from 'react-i18next'

import {useCompanyNameContext} from '../libs'

const StyleWrapper = styled.div`
    .ant-tooltip {
        max-width: 308px;

        .ant-tooltip-inner {
            text-align: center;
        }
    }
`

export const NotAvailableTooltip = props => {
    const {type, children, ...passProps} = props
    const {t} = useTranslation()
    const companyName = useCompanyNameContext()

    return (
        <StyleWrapper>
            <Tooltip
                title={
                    type && (
                        <Trans
                            i18nKey="objectsList:notAvailableTooltip"
                            values={{type: t(`objectsList:columns:${type}`), companyName}}
                        >
                            <strong>FrendlyType</strong>
                        </Trans>
                    )
                }
                getPopupContainer={triggerNode => triggerNode}
                mouseLeaveDelay={0}
                {...passProps}
            >
                {children}
            </Tooltip>
        </StyleWrapper>
    )
}
