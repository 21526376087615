import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledActions = styled.div`
    margin-top: ${variables.gutterMd};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledSwither = styled.div`
    display: flex;
    align-items: center;

    .ant-switch {
        margin-left: ${variables.gutterSm};
    }
`

export const StyledText = styled.span`
    font-family: ${variables.accentFont};
    font-style: normal;
    font-weight: 400;
    font-size: ${variables.headingFontSize};
    line-height: 160%;
`

export const StyledConfirmTitle = styled.span`
    font-family: ${variables.mainFont};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
`

export const StyledConfirmText = styled.span`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
`

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledErrorHeader = styled.div`
    color: ${variables.red};
    font-family: ${variables.mainFont};
    font-weight: 600;
`

export const StyledErrorText = styled.div`
    margin-top: 12px;
    font-family: ${variables.mainFont};
`

export const StyledIcon = styled(Icon)`
    font-size: 40px;
    margin-right: 24px;
`

export const StyledTooltipWrapper = styled.div`
    .ant-tooltip-inner {
        display: flex;
        align-items: center;
        text-align: center;
        width: 286px;

        font-size: ${variables.fontSizeSecondary};
        font-family: ${variables.publicPageFont};
    }
`
