import React from 'react'
import {Form, FormInstance} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {BackupHour} from 'src/newcore/features/BackupSettings/molecules/BackupHour'
import {BackupFrequency} from 'src/newcore/features/BackupSettings/molecules/BackupFrequency'

import {Wrapper} from './StyledFrequencyAndHourSelectors'

type FrequencyAndHourSelectorsProps = {
    service: SOURCE
    form: FormInstance<Record<string, any>>
    isHighFrequencyBackupEnabled?: boolean
}

export const FrequencyAndHourSelectors = (props: FrequencyAndHourSelectorsProps) => {
    const {service, form, isHighFrequencyBackupEnabled} = props

    const backupFrequency = Form.useWatch(`${service}.backupFrequency`, form)

    return (
        <Wrapper>
            <BackupFrequency namePrefix={service} isHighFrequencyBackupEnabled={isHighFrequencyBackupEnabled} />
            <BackupHour namePrefix={service} backupFrequency={backupFrequency} />
        </Wrapper>
    )
}
