import styled from 'styled-components'
import variables from 'ca-common/variables'

import {ModalCenterContentWrapper} from 'ca-common/components/Modal'

export const Description = styled.span`
    font-size: ${variables.fontSizeBase};
    line-height: 14px;
    align-self: flex-start;
`

export const RadioBlockWrapper = styled.span`
    display: flex;
`
export const TooltipInfoWrapper = styled.span`
    padding-left: ${variables.gutterXs};
    margin: auto;
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
`

export const StyledModalCenterContentWrapper = styled(ModalCenterContentWrapper)`
    position: relative;
`
