import React from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import './Radio.scss'

const dumbRadio = dumbBem('ca-form-radio')
const Radio = tx(dumbRadio)('div')
const Input = tx([{element: 'input'}, dumbRadio])('input')
const Check = tx([{element: 'check'}, dumbRadio])('div')

export class CARadio extends React.Component {
    render() {
        const {checked, ...rest} = this.props

        return (
            <Radio>
                <Input type="radio" checked={checked} {...rest} />
                <Check />
            </Radio>
        )
    }
}
