import React from 'react'
import {Switch} from 'react-router'
import {Route} from 'react-router-dom'
import queryString from 'query-string'

import {PAGES} from 'ca-common/constants'
import {openErrorModal} from 'ca-common/utils/modals'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {NotFound} from 'ca-common/components/NotFound'
import {PermissionRoute} from 'ca-common/components/PermissionRoute'

import {CABackupCompareResults, CABackupInfo} from 'src/newcore/pages'
import {AvailableSourceList} from 'src/newcore/features/AvailableSourceList'
import {CAHomepage} from 'src/newcore/features/Homepage'

import {Compare} from 'src/newcore/components/CompareSnapshots/CompareTable'
import {makeUrlParams} from 'ca-common/utils/url'

export class BackupsRouting extends React.Component {
    componentDidMount = () => {
        this.checkUrlMessages()
    }

    checkUrlMessages = () => {
        const {msgType, status, ...otherParams} = queryString.parse(this.props.history.location.search)
        if (msgType === 'error') {
            openErrorModal(status, undefined, () =>
                this.props.history.replace({
                    pathname: this.props.history.location.pathname,
                    search: makeUrlParams(otherParams)
                })
            )
        } else if (msgType === 'info') {
            openSuccessNotification(status)
        }
    }

    render() {
        const {match} = this.props

        return (
            <Switch>
                <Route path={`${match.url}/`} exact component={CAHomepage} />
                <PermissionRoute
                    checkPage={PAGES.BACKUPS_NEW}
                    path={`${match.url}/new`}
                    component={AvailableSourceList}
                />
                <Route path={`${match.url}/:taskId`} exact component={CABackupInfo} />
                <PermissionRoute
                    checkPage={PAGES.COMPARE}
                    path={`${match.url}/:taskId/:compareId`}
                    exact
                    component={Compare}
                />
                <PermissionRoute
                    checkPage={PAGES.COMPARE}
                    path={`${match.url}/:taskId/:compareId/:resultType/:objectName`}
                    exact
                    component={CABackupCompareResults}
                />
                <Route component={NotFound} />
            </Switch>
        )
    }
}
