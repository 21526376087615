import {useCallback, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import {useLocation, useHistory} from 'react-router'
import {ParsedQuery} from 'query-string'

import {getUrlParams, makeUrlParams} from 'ca-common/utils/url'

export const useUrlParams = (): {
    currentUrlParams: ParsedQuery<string | number>
    changeUrlParams: (params: any) => void
} => {
    const location = useLocation()
    const history = useHistory()
    const currentUrlParams = useMemo(() => getUrlParams(location.search), [location.search])

    const changeUrlParams = useCallback(
        params => {
            const newParams = pickBy(
                {
                    ...currentUrlParams,
                    ...params
                },
                i => !isEmpty(i)
            )

            if (!isEqual(currentUrlParams, newParams)) {
                history.push({
                    search: makeUrlParams(newParams)
                })
            }
        },
        [currentUrlParams]
    )

    return {currentUrlParams, changeUrlParams}
}
