import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'

import {CARowWrapper, CACellWrapper} from 'ca-common/ui-lib/components/TableElements'

import {config} from 'ca-common/shapes/table'
import {items as itemsShape, rowSelection as rowSelectionShape} from '../../../shapes/table'

@withTranslation('table', {withRef: true})
export class CAHeader extends React.Component {
    static propTypes = {
        config: PropTypes.shape(config).isRequired,
        items: PropTypes.shape(itemsShape).isRequired,
        rowSelection: PropTypes.shape(rowSelectionShape)
    }

    static defaultProps = {
        rowSelection: null
    }

    render() {
        return <CARowWrapper modifier="header">{this.renderHeader()}</CARowWrapper>
    }

    renderHeader = () =>
        this.props.config.columns.map(
            (i, index) =>
                !i.whiteLabel && (
                    <CACellWrapper key={index + i.name + i.modifier} modifier={i.modifier}>
                        {this.renderCell(i)}
                    </CACellWrapper>
                )
        )

    renderCell = column => {
        const {t, items, rowSelection} = this.props
        const CAHeaderCell = column.headerCell

        if (CAHeaderCell) {
            return <CAHeaderCell column={column} items={items} rowSelection={rowSelection} />
        }

        return t(`table:columns:${column.name}`)
    }
}
