import React from 'react'
import {useTranslation} from 'react-i18next'

import {TotalObjects} from 'ca-common/molecules/TotalObjects'

import {FilterForm} from 'src/newcore/features/SmartAlerts/molecules'

import {StyledHeader, FirstRow} from './StyledSmartAlertsHeader'

export const SmartAlertsHeader = props => {
    const {totalSmartAlerts, changeURLSearchParams, loading, urlValues, multiActions, addNewSmartAlert} = props

    const {t} = useTranslation()

    return (
        <StyledHeader>
            <FirstRow>
                <TotalObjects loading={loading}>{t('smartAlerts:totalSmartAlerts', {totalSmartAlerts})}</TotalObjects>
                {addNewSmartAlert}
            </FirstRow>
            <FilterForm
                changeURLSearchParams={changeURLSearchParams}
                loading={loading}
                values={urlValues}
                multiActions={multiActions}
            />
        </StyledHeader>
    )
}
