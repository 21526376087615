import get from 'lodash/get'
import {Route} from 'react-router-dom'
import {Switch, useHistory, useLocation} from 'react-router'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {Modal} from 'antd'

import {hotjarIdentify, hotjarInit} from 'ca-common/utils/hotjar'
import {getUrlParams} from 'ca-common/utils/url'

import {openCustomModalNotification} from 'ca-common/features/CustomModalNotification'
import {PUBLIC_PAGES, ContainerType} from 'ca-common/constants'
import {createInlineManualPlayer} from 'ca-common/features/InlineManual'
import {isProduction} from 'ca-common/utils/runtime'

import {ResetPasswordInitPage} from 'src/newcore/components/ResetPassword'

import {PublicContainer} from 'src/newcore/components/PublicContainer'
import {SignInPage} from 'src/newcore/pages/SignIn'
import {ChangePublicPassword} from 'src/newcore/pages/ChangePublicPassword'
import {ChangePasswordPage} from 'src/newcore/pages/ChangePassword'
import {fetchHotjarId} from 'src/newcore/redux/modules/hotjar'
import {SignUpWrapper, SignUp} from 'src/newcore/features/SignUp'
import {ThankYou} from 'src/newcore/pages/ThankYou'

import {MSLanding, MSLandingError} from 'src/newcore/pages/MSLanding'
import {Error} from 'src/newcore/pages/Error'
import {OKTALanding} from 'src/newcore/pages/OKTALanding'
import {adroll} from 'src/newcore/utils/adroll'

import {AuthenticatedUserRouting} from './AuthenticatedUserRouting'

const openCustomModal = search => {
    const urlParams = getUrlParams(search)

    if (urlParams.msgType === 'custom') {
        openCustomModalNotification(urlParams.modalName, urlParams.status)
    }
}

const RoutingRaw = ({companyInfo, userInfo, actions}) => {
    const {t} = useTranslation()
    const companyName = get(companyInfo, 'response.name', '')
    const companyFavicon = get(companyInfo, 'response.logo15x16', '')
    const Pid = get(companyInfo, 'response.partnerId', '')
    const robots = isProduction ? 'all' : 'noindex, nofollow'
    const history = useHistory()
    const location = useLocation()
    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false)

    if (location.hash.startsWith('#/')) {
        history.replace(location.hash.replace('#', ''))
    }

    useEffect(() => {
        openCustomModal(location.search)
        history.listen((location, action) => {
            Modal.destroyAll()
            if (action === 'POP' || action === 'REPLACE') {
                openCustomModal(location.search)
            }
            userInfo.response?.data && hotjarIdentify(userInfo.response.data)
        })
    }, [])

    useEffect(() => {
        if (userInfo.response && companyInfo.response && !isUserDataLoaded) {
            setIsUserDataLoaded(true)
            actions.fetchHotjarId().then(data => {
                const {hotjarId} = data.payload
                const isUserAuditForbidden = get(userInfo, 'response.isUserAuditForbidden', false)

                hotjarInit(hotjarId, isUserAuditForbidden)
                hotjarIdentify(userInfo.response)
            })
            createInlineManualPlayer({userInfo: userInfo.response, companyInfo: companyInfo.response})
        }
    }, [userInfo.response, companyInfo.response, isUserDataLoaded])

    return (
        <>
            <>
                {companyInfo.status === 'fulfilled' && (
                    <Helmet>
                        <title>{t('common:title', {companyName})}</title>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                )}
                {companyInfo.status === 'fulfilled' &&
                    (Pid ? (
                        <Helmet>
                            <link rel="icon" href={companyFavicon} />
                        </Helmet>
                    ) : (
                        <Helmet>
                            <link rel="icon" type="image/svg+xml" href="/ca-new/favicon/favicon.svg" />
                            <link rel="icon" type="image/png" sizes="32x32" href="/ca-new/favicon/favicon32x32.png" />
                            <link rel="icon" type="image/png" sizes="16x16" href="/ca-new/favicon/favicon16x16.png" />
                            <link rel="shortcut icon" href="/ca-new/favicon/favicon.ico" sizes="any" />
                            <link rel="apple-touch-icon" href="/ca-new/favicon/apple-touch-icon.png" sizes="180x180" />
                            <link rel="manifest" href="/ca-new/favicon/site.webmanifest" />
                        </Helmet>
                    ))}
            </>

            <Switch>
                <SignUpPublicRoute
                    title={t('PUBLIC_PAGE_TITLES:SIGN_UP', {companyName})}
                    meta={{
                        description: t('PUBLIC_PAGE_META:SIGN_UP:description', {companyName}),
                        keywords: t('PUBLIC_PAGE_META:SIGN_UP:keywords', {companyName}),
                        robots: robots
                    }}
                    path={PUBLIC_PAGES.SIGN_UP}
                    exact
                    component={SignUp}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:RESET_PASSWORD', {companyName})}
                    path={PUBLIC_PAGES.RESET_PASSWORD}
                    exact
                    component={ResetPasswordInitPage}
                    type={ContainerType.CLOUD}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:RESET_PASSWORD', {companyName})}
                    path={PUBLIC_PAGES.RESET_PASSWORD_CONFIRM}
                    exact
                    component={ChangePasswordPage}
                    type={ContainerType.CLOUD}
                />
                <SignUpPublicRoute
                    title={t('PUBLIC_PAGE_TITLES:THANK_YOU', {companyName})}
                    path={PUBLIC_PAGES.THANK_YOU}
                    exact
                    component={ThankYou}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:SIGN_IN', {companyName})}
                    meta={{
                        description: t('PUBLIC_PAGE_META:SIGN_IN:description', {companyName}),
                        keywords: t('PUBLIC_PAGE_META:SIGN_IN:keywords', {companyName}),
                        robots: robots
                    }}
                    path={PUBLIC_PAGES.SIGN_IN}
                    exact
                    component={SignInPage}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:CHANGE_PASSWORD', {companyName})}
                    path={PUBLIC_PAGES.CHANGE_PASSWORD}
                    exact
                    component={ChangePublicPassword}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:ERROR', {companyName})}
                    path={PUBLIC_PAGES.ERROR}
                    showMenu={false}
                    exact
                    component={Error}
                />
                {/*<PublicRoute path={'/password-sent'} exact component={PasswordSent} />*/}
                {/*<PublicRoute path={'/expiration-password'} exact component={PasswordExpiration} />*/}
                {/*<PublicRoute path={'/change-password'} exact component={ChangePassword} />*/}
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:MS_LANDING')}
                    path={PUBLIC_PAGES.MS_LANDING}
                    exact
                    component={MSLanding}
                    marketing={true}
                    toCloudallyUrl={false}
                    showMenu={false}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:MS_LANDING')}
                    path={PUBLIC_PAGES.MS_LANDING_ERROR}
                    exact
                    component={MSLandingError}
                    toCloudallyUrl={false}
                    showMenu={false}
                />
                <PublicRoute
                    title={t('PUBLIC_PAGE_TITLES:OKTA_LANDING')}
                    path={PUBLIC_PAGES.OKTA_LANDING}
                    exact
                    component={OKTALanding}
                />
                <Route path={'*'} component={AuthenticatedUserRouting} />
            </Switch>
        </>
    )
}

const initialMeta = {
    description: undefined,
    keywords: undefined,
    robots: undefined
}

const ComponentWithHelmet = ({title, meta, component: Component, ...props}) => (
    <>
        <Helmet>
            <title>{title}</title>
            {meta.description && <meta name="description" content={meta.description} />}
            {meta.keywords && <meta name="keywords" content={meta.keywords} />}
            {meta.robots && <meta name="robots" content={meta.robots} />}
            <script type="text/javascript">{adroll}</script>
        </Helmet>
        <Component {...props} />
    </>
)

const PublicRoute = ({
    title,
    component,
    isLogout,
    type = ContainerType.ARROW,
    marketing = false,
    showMenu,
    meta = initialMeta,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => (
            <PublicContainer marketing={marketing} type={type} isLogout={isLogout} showMenu={showMenu} {...props}>
                <ComponentWithHelmet title={title} meta={meta} component={component} {...props} />
            </PublicContainer>
        )}
    />
)

const SignUpPublicRoute = ({title, component, meta = initialMeta, ...rest}) => (
    <Route
        {...rest}
        render={props => (
            <SignUpWrapper>
                <ComponentWithHelmet title={title} meta={meta} component={component} {...props} />
            </SignUpWrapper>
        )}
    />
)

const mapStateToProps = state => ({
    userInfo: state.userInfo,
    companyInfo: state.companyInfo
})

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                fetchHotjarId
            },
            dispatch
        )
    }
}

export const PortalRouting = connect(mapStateToProps, mapDispatchToProps)(RoutingRaw)
