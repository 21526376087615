import React from 'react'
import Icon from '@ant-design/icons'

import ActionStart from 'ca-common/icons/ActionStart.svg'
import {
    StyledTutorialButton,
    StyledTutorialJumbotron,
    StyledTutorialLink,
    StyledTutorialText
} from './StyledTutorialButton'

export const TutorialButton = ({url, text}: {url: string; text: string}) => {
    return (
        <StyledTutorialButton>
            <StyledTutorialJumbotron>
                <StyledTutorialLink href={url} target="_blank">
                    <Icon component={ActionStart} />
                    <StyledTutorialText>{text}</StyledTutorialText>
                </StyledTutorialLink>
            </StyledTutorialJumbotron>
        </StyledTutorialButton>
    )
}
