import styled, {css} from 'styled-components'
import {transparentize} from 'polished'

import variables from 'ca-common/variables'

export const StyledColumn = styled.div`
    display: flex;
    flex-flow: column;

    &:nth-child(2) {
        flex-grow: 1;
        margin-left: 12px;
    }
`

export const StyledCount = styled.span`
    font-family: ${variables.mainFont};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    margin-bottom: 5px;
`

export const StyledTitle = styled.span`
    font-family: ${variables.accentFont};
    font-style: normal;
    font-weight: bold;
    font-size: ${variables.subTitleFontSize};
    line-height: 20px;
`

export const StyledCardBody = styled.li<{disabled: boolean; selected: boolean}>`
    display: flex;
    flex-grow: 1;

    padding: 12px;
    border-bottom: 2px solid ${({selected}) => (selected ? variables.navyBlue : variables.white)};
    border-radius: ${variables.borderRadiusBase};
    background-color: ${variables.white};
    box-shadow: ${props =>
        props.selected
            ? `0 12px 20px 0 ${transparentize(0.84, variables.navyBlue)}`
            : `0 2px 5px ${transparentize(0.88, variables.black)}`};

    span {
        color: ${props => (props.disabled ? variables.asphaltLight : variables.navyBlue)};
    }

    &:hover {
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
        ${p =>
            !p.selected &&
            css`
                box-shadow: 0 4px 10px ${transparentize(0.9, variables.black)};
            `}
    }
`

export const TooltipStyleWrapper = styled.div`
    display: flex;
    min-width: 176px;

    @media screen and (min-width: 1024px) {
        min-width: 170px;
    }

    @media screen and (min-width: 1440px) {
        max-width: 328px;
        flex: 1;
    }

    .ant-tooltip {
        width: 236px;

        .ant-tooltip-content {
            margin-top: -24px;
        }

        .ant-tooltip-inner {
            text-align: center;
        }
    }
`
