import React, {Component} from 'react'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {withTranslation, Trans} from 'react-i18next'

import {PublicChangePasswordForm} from 'ca-common/forms/PublicChangePasswordForm'

import {changeSignInPassword} from 'src/newcore/redux/modules/sign'

import {StyledHeader, StyledContentContainer, StyledText} from './StyledChangePublicPassword'

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeSignInPassword
        },
        dispatch
    )
})

class ChangePublicPasswordRaw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitting: false,
            submitError: ''
        }
    }

    handleSubmit = ({confirmPassword, ...values}) => {
        this.setState({submitting: true})
        this.props.actions
            .changeSignInPassword({
                ...values,
                passwordConfirm: confirmPassword
            })
            .unwrap()
            .then(() => {
                this.props.history.push('/')
            })
            .catch(error => {
                this.setState({submitError: error.status, submitting: false})
            })
    }

    render() {
        const {t, location} = this.props
        const {submitting, submitError} = this.state

        return (
            <StyledContentContainer>
                <StyledHeader>{t('passwordResetNew:changeYour')}</StyledHeader>
                <StyledText>
                    <Trans i18nKey="passwordResetNew:expiredPassword" />
                </StyledText>
                <PublicChangePasswordForm
                    handleSubmit={this.handleSubmit}
                    initialValues={queryString.parse(location.search)}
                    withEmail
                    withCurrentPassword
                    submitting={submitting}
                    submitError={submitError}
                />
            </StyledContentContainer>
        )
    }
}

export const ChangePublicPassword = withRouter(
    connect(null, mapDispatchToProps)(withTranslation()(ChangePublicPasswordRaw))
)
