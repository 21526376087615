import React from 'react'

import {SOCIAL_TYPES} from 'ca-common/common/enum'

import Blogger from 'ca-common/icons/social/blogger.svg'
import Facebook from 'ca-common/icons/social/facebook.svg'
import Linkedin from 'ca-common/icons/social/linkedin.svg'
import Twitter from 'ca-common/icons/social/twitter.svg'
import Rss from 'ca-common/icons/social/rss.svg'

import {StyledIcon} from './StyledSocialIcon'

export const SocialIcon = ({type}: {type: SOCIAL_TYPES}): JSX.Element => {
    switch (type) {
        case SOCIAL_TYPES.BLOGGER:
            return <StyledIcon component={Blogger} />

        case SOCIAL_TYPES.TWITTER:
            return <StyledIcon component={Twitter} />

        case SOCIAL_TYPES.LINKEDIN:
            return <StyledIcon component={Linkedin} />

        case SOCIAL_TYPES.FACEBOOK:
            return <StyledIcon component={Facebook} />

        case SOCIAL_TYPES.RSS:
            return <StyledIcon component={Rss} />

        default:
            return <></>
    }
}
