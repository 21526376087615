import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'

import Run from 'ca-common/icons/backupActionsNew/BackupNow.svg'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {TASK_STATUS} from 'ca-common/common/enum'

import {
    ActionIcon,
    BackupActionButton,
    DisabledButtonWrapper
} from '../../../organisms/BackupActions/StyledBackupActions'
import {ACTIONS} from '../../../utils/constants'
import {isTaskPaused, isTaskProcessing, isTaskQueued} from '../../../utils/utils'

import {status} from 'src/newcore/features/Homepage/types'

type BackupNowProps = {
    sendAction: any
    pullAndUpdateLocalServices: () => void
    status: status
    disabled: boolean
    isSubscribed: boolean
    trialDaysLeft: number
    companyName: string
    deepTaskStatus: TASK_STATUS
}

export const BackupNow = ({
    sendAction,
    pullAndUpdateLocalServices,
    status,
    disabled,
    isSubscribed,
    trialDaysLeft,
    companyName,
    deepTaskStatus
}: BackupNowProps): JSX.Element => {
    const {t} = useTranslation()

    const getTitleForTooltip = () => {
        if (disabled && !isTaskPaused(status)) {
            return deepTaskStatus === TASK_STATUS.SCHEDULED
                ? t('backups:actions:tooltips:awaitingFirstRun')
                : t('backups:actions:tooltips:backupRunning')
        }
        return null
    }
    return isSubscribed || trialDaysLeft > 0 ? (
        <Tooltip title={getTitleForTooltip()}>
            <DisabledButtonWrapper disabled={disabled}>
                <BackupActionButton
                    disabled={disabled}
                    data-action={ACTIONS.BACKUP_NOW.toLowerCase()}
                    onClick={onClickHandler}
                >
                    <ActionIcon component={Run} />
                    {t(`backups:actions:${ACTIONS.BACKUP_NOW}`)}
                </BackupActionButton>
            </DisabledButtonWrapper>
        </Tooltip>
    ) : (
        <BackupActionButton greyedOut data-action={ACTIONS.BACKUP_NOW.toLowerCase()} onClick={openSubscribeErrorModal}>
            <ActionIcon component={Run} />
            {t(`backups:actions:${ACTIONS.BACKUP_NOW}`)}
        </BackupActionButton>
    )
    function openSubscribeErrorModal() {
        openErrorModal(t('backups:unsubscribedErrorMessage', {companyName}))
    }
    function onClickHandler() {
        if (!isTaskProcessing(status) && !isTaskQueued(status)) {
            sendAction(ACTIONS.BACKUP_NOW)
                .then(() => {
                    openInfoNotification(t('backups:taskScheduled'))
                    pullAndUpdateLocalServices()
                })
                .catch((error: any) => openErrorModal(t('backups:failedToSchedule') + (error ? error.status : '')))
        }
    }
}
