import styled from 'styled-components'
import {transparentize} from 'polished'

import {TASK_STATUS, JOBS_STATUSES} from 'ca-common/common/enum'
import variables from 'ca-common/variables'

function handleTextColor(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.SUCCESS:
        case JOBS_STATUSES.SUCCEEDED:
            return variables.green
        case TASK_STATUS.FAILED:
        case JOBS_STATUSES.FAILED:
            return variables.red
        case TASK_STATUS.PARTIAL:
        case JOBS_STATUSES.PARTIALLY_SUCCEEDED:
            return variables.orange
        case TASK_STATUS.SCHEDULED:
        case JOBS_STATUSES.CANCELLED:
        case JOBS_STATUSES.CANCELLING:
            return variables.asphaltLight
        case TASK_STATUS.ARCHIVED:
        case TASK_STATUS.PAUSED:
            return variables.text
        case JOBS_STATUSES.PENDING:
            return variables.inProcess
        default:
            return ''
    }
}

function handleBackgroundColor(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.SUCCESS:
        case JOBS_STATUSES.SUCCEEDED:
            return transparentize(0.9, variables.green)
        case TASK_STATUS.FAILED:
        case JOBS_STATUSES.FAILED:
            return transparentize(0.9, variables.red)
        case TASK_STATUS.PARTIAL:
        case JOBS_STATUSES.PARTIALLY_SUCCEEDED:
            return transparentize(0.9, variables.orange)
        case TASK_STATUS.SCHEDULED:
        case JOBS_STATUSES.CANCELLED:
        case JOBS_STATUSES.CANCELLING:
            return transparentize(0.8, variables.asphaltLight)
        case TASK_STATUS.ARCHIVED:
        case TASK_STATUS.PAUSED:
            return variables.blueLight
        default:
            return ''
    }
}

function handlePaddings(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.PAUSED:
        case TASK_STATUS.ARCHIVED:
            return '1px 20px'
        default:
            return '2px 10px'
    }
}

function handleBorderRadius(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.PAUSED:
        case TASK_STATUS.ARCHIVED:
            return '8px'
        default:
            return '35px'
    }
}

function handleBorder(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.PAUSED:
        case TASK_STATUS.ARCHIVED:
            return `1px solid ${variables.navyBorder}`
        case JOBS_STATUSES.PENDING:
            return `1px solid ${variables.inProcess}`
        default:
            return 'none'
    }
}

type StatusProps = {
    status: TASK_STATUS | JOBS_STATUSES
}
export const Status = styled.span<StatusProps>`
    display: inline-block;
    color: ${({status}) => handleTextColor(status)};
    background: ${({status}) => handleBackgroundColor(status)};
    border-radius: ${({status}) => handleBorderRadius(status)};

    border: ${({status}) => handleBorder(status)};
    padding: ${({status}) => handlePaddings(status)};
`
