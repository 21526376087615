import PropTypes from 'prop-types'

import {COMPARE_STATUS} from 'ca-common/common/enum/CompareItem'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {SFCBackupPropTypes} from './SFCBackupPropTypes'

export const compareItemPropTypes = {
    type: PropTypes.oneOf([SFC_DATA_TYPE.DATA, SFC_DATA_TYPE.METADATA]).isRequired,
    object: PropTypes.string,
    compareStatus: PropTypes.oneOf([
        COMPARE_STATUS.SUCCESS,
        COMPARE_STATUS.IN_PROCESS,
        COMPARE_STATUS.FAILED,
        COMPARE_STATUS.LIMIT_EXCEEDED,
        COMPARE_STATUS.CANCELED
    ]),
    leftObject: PropTypes.shape(SFCBackupPropTypes).isRequired,
    rightObject: PropTypes.shape(SFCBackupPropTypes).isRequired,
    progress: PropTypes.number.isRequired,
    exception: PropTypes.string,
    cancelTask: PropTypes.func.isRequired
}

export const compareItemDefaultProps = {
    object: '',
    compareStatus: COMPARE_STATUS.IN_PROCESS,
    exception: ''
}
