import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Collapse, Form} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {
    changeConfig,
    changeData,
    setWizardDescription,
    setWizardTitle,
    wizardConfigSelector,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import {useGetMSTotalEntitiesQuery} from 'src/newcore/features/OneClickActivation/redux'
import {
    convertTaskSettingsToWizardData,
    FIELDS,
    getTaskSettingsInitialValues
} from 'src/newcore/features/OneClickActivation/lib'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'

import {Footer} from 'src/newcore/features/OneClickActivation/molecules'
import {FrequencyAndHourSelectors, ServiceIcon, SettingsOptions} from 'src/newcore/features/OneClickActivation/atoms'

import {Body, Header, Title} from './StyledOCASettingsSelectionStep'

const {Panel} = Collapse

export const OCASettingsSelectionStep = (): JSX.Element => {
    const {t} = useTranslation()

    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const wizardConfig = useAppSelector(wizardConfigSelector) as {
        defaultOpenedTaskSettings?: SOURCE
        skipableSteps: string[]
    }
    const userInfo = useAppSelector(userInfoSelector)

    const [form] = Form.useForm()
    const initialValues = getTaskSettingsInitialValues(wizardData)

    const {data: totalEntities = []} = useGetMSTotalEntitiesQuery(wizardData.id)
    const editableEntities = totalEntities.filter(
        ({source}) => !wizardData.tasksForReinstate?.find(task => task.source === source)
    )

    const beforeStepChange = async () => {
        const values = form.getFieldsValue() as typeof initialValues
        const newData = convertTaskSettingsToWizardData(wizardData, values)

        dispatch(changeData(newData))
    }

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:settingsSelectionStep')))
        dispatch(setWizardDescription(wizardData.email))
        dispatch(changeConfig({beforeStepChange, defaultOpenedTaskSettings: undefined}))
    }, [])

    return (
        <Form form={form} initialValues={initialValues}>
            <Body>
                <Collapse
                    accordion
                    defaultActiveKey={wizardConfig.defaultOpenedTaskSettings || editableEntities[0]?.source}
                    expandIconPosition="end"
                >
                    {editableEntities.map(({source}) => (
                        <Panel
                            header={
                                <Header>
                                    <ServiceIcon source={source} />
                                    <Title>
                                        {`${wizardData[FIELDS.BACKUP_NAME]}_${t(
                                            `oneClickActivation:wizard:confirmationStep:services:${source}`
                                        )}`}
                                    </Title>
                                </Header>
                            }
                            key={source}
                        >
                            <SettingsOptions service={source} form={form} isFinra={userInfo?.isFinra} />
                            <FrequencyAndHourSelectors
                                service={source}
                                form={form}
                                isHighFrequencyBackupEnabled={userInfo?.isHighFrequencyBackupEnabled}
                            />
                        </Panel>
                    ))}
                </Collapse>
            </Body>
            <Footer />
        </Form>
    )
}
