import React, {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'

import {isBackupPermitted} from 'src/newcore/utils/permissions'

import {actionOnTask} from '../../redux'

import {backupNowTaskState, cancelTaskState, isTaskPaused, isTaskQueued} from '../../utils/utils'
import {Task} from 'src/newcore/features/Homepage/types'
import {BackupNow, Recovery, Resume} from 'src/newcore/features/Homepage/molecules/actions'
import {SOURCE_INFO, TASK_STATUS} from 'ca-common/common/enum'
import {ActionsDropdown} from 'src/newcore/features/Homepage/molecules/ActionsDropdown'
import {Menu} from 'antd'
import {
    BackupNowItem,
    CancelItem,
    ResumeItem,
    PauseItem,
    DeleteItem
} from 'src/newcore/features/Homepage/molecules/listActions'
import {LAST_BACKUP_STATUS} from 'src/newcore/features/Homepage/utils/constants'
import {ButtonsWrapper, MenuItem} from 'src/newcore/features/Homepage/organisms/BackupActions/StyledBackupActions'
import {AppState, useAppDispatch} from 'src/newcore/components/Root'

type BackupActionsProps = {
    pullAndUpdateLocalServices: () => void
    setSpin?: React.Dispatch<React.SetStateAction<boolean>>
    friendlyName: string
} & ReturnType<typeof mapStateToProps> &
    Task

const BackupActionsRaw = ({
    task,
    pullAndUpdateLocalServices,
    userInfo,
    actionStatus,
    friendlyName,
    companyName,
    setSpin
}: BackupActionsProps): JSX.Element => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const appDispatch = useAppDispatch()

    const {permissions, isSubscribed, trialDaysLeft} = userInfo.response
    const {id, availableBackupNow, status, progress, source, account, deepTaskStatus, lastBackup} = task
    const [backupNowDisabled, setBackupNowDisabled] = useState(false)
    const [cancelDisabled, setCancelDisabled] = useState(false)
    const [deleteDisabled, setDeleteDisabled] = useState(false)
    const [resumeDisabled, setResumeDisabled] = useState(false)
    const [pauseDisabled, setPauseDisabled] = useState(false)
    const [recoveryDisabled, setRecoveryDisabled] = useState(false)

    const disableAction = actionStatus === 'pending'

    useEffect(() => {
        setBackupNowDisabled(
            disableAction ||
                !availableBackupNow ||
                isTaskPaused(status) ||
                backupNowTaskState(status, progress) ||
                isTaskQueued(status)
        )
        setCancelDisabled(disableAction || (cancelTaskState(status, progress) && !isTaskQueued(status)))
        setPauseDisabled(
            isTaskPaused(status) || disableAction || backupNowTaskState(status, progress) || isTaskQueued(status)
        )
        setResumeDisabled(
            disableAction || backupNowTaskState(status, progress) || isTaskQueued(status) || !isTaskPaused(status)
        )
    }, [disableAction, status, progress, availableBackupNow])

    useEffect(() => {
        setDeleteDisabled(userInfo.response.isFinra || disableAction)
    }, [userInfo.response.isFinra, disableAction])

    useEffect(() => {
        setRecoveryDisabled(deepTaskStatus === TASK_STATUS.SCHEDULED || lastBackup === LAST_BACKUP_STATUS.NEVER)
    }, [deepTaskStatus, lastBackup])

    const isActionPermitted = function getActionPermission(actionType: string) {
        return isBackupPermitted(`${source}.${actionType}`, permissions)
    }
    const actionsPermissionsForMenu = useMemo(
        () => [
            isActionPermitted('EXECUTE'),
            isActionPermitted('PAUSE'),
            isActionPermitted('DELETE'),
            isActionPermitted('CANCEL')
        ],
        []
    )

    const changeSpinHandler = (val: boolean): void => {
        if (setSpin !== undefined) {
            setSpin(val)
        }
        return
    }

    const menuOfActions = (
        <Menu>
            {isActionPermitted('EXECUTE') && isTaskPaused(status) && (
                <MenuItem disabled={!isSubscribed || trialDaysLeft < 0 || backupNowDisabled}>
                    <BackupNowItem
                        isSubscribed={isSubscribed}
                        trialDaysLeft={trialDaysLeft}
                        status={status}
                        sendAction={sendAction}
                        disabled={backupNowDisabled}
                        pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                    />
                </MenuItem>
            )}
            {isActionPermitted('PAUSE') && !isTaskPaused(status) && (
                <MenuItem disabled={resumeDisabled}>
                    <ResumeItem
                        disabled={resumeDisabled}
                        sendAction={sendAction}
                        status={status}
                        pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                    />
                </MenuItem>
            )}
            {isActionPermitted('PAUSE') && (
                <MenuItem disabled={pauseDisabled}>
                    <PauseItem
                        status={status}
                        disabled={pauseDisabled}
                        sendAction={sendAction}
                        pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                        setSpin={changeSpinHandler}
                    />
                </MenuItem>
            )}
            {isActionPermitted('DELETE') && (
                <MenuItem disabled={deleteDisabled}>
                    <DeleteItem
                        disabled={deleteDisabled}
                        source={source}
                        name={friendlyName}
                        id={id}
                        isFinra={userInfo.response.isFinra}
                        sendAction={sendAction}
                        account={account}
                    />
                </MenuItem>
            )}
            {isActionPermitted('CANCEL') && !SOURCE_INFO[source].hasSubAccounts && (
                <MenuItem disabled={cancelDisabled}>
                    <CancelItem
                        disabled={cancelDisabled}
                        id={id}
                        sendAction={sendAction}
                        status={status}
                        progress={progress}
                        pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                    />
                </MenuItem>
            )}
        </Menu>
    )

    return (
        <>
            <ButtonsWrapper>
                {(isActionPermitted('RESTORE') || isActionPermitted('DOWNLOAD')) && (
                    <Recovery task={task} disabled={recoveryDisabled} />
                )}
                {isTaskPaused(status)
                    ? isActionPermitted('PAUSE') && (
                          <Resume
                              status={status}
                              setSpin={changeSpinHandler}
                              pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                              sendAction={sendAction}
                          />
                      )
                    : isActionPermitted('EXECUTE') && (
                          <BackupNow
                              companyName={companyName}
                              isSubscribed={isSubscribed}
                              trialDaysLeft={trialDaysLeft}
                              disabled={backupNowDisabled}
                              pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                              sendAction={sendAction}
                              status={status}
                              deepTaskStatus={deepTaskStatus}
                          />
                      )}
            </ButtonsWrapper>
            {actionsPermissionsForMenu.some(value => value) && <ActionsDropdown content={menuOfActions} />}
        </>
    )

    async function sendAction(actionType: string, activate?: boolean) {
        await appDispatch(
            actionOnTask({
                taskId: id,
                action: actionType,
                activate
            })
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    userInfo: state.userInfo,
    actionStatus: state.backups.actionOnTask.status,
    companyName: state.companyInfo.status === 'fulfilled' ? state.companyInfo.response.name : ''
})

export const BackupActions = connect(mapStateToProps)(BackupActionsRaw)
