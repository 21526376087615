import React, {useRef, useState, useMemo} from 'react'
import {scaleLinear, scaleBand, scaleOrdinal} from '@visx/scale'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import {Brush} from '@visx/brush'
import {Bounds} from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import variables from 'ca-common/variables'

import {SfcObjectHistory} from 'src/newcore/features/ObjectHistory'

import {TooltipContent, Legend, AreaChart} from 'src/newcore/features/BackupChart/molecules'
import {COLORS, ADDED, DELETED, UPDATED, DATE, CustomBarGroup} from 'src/newcore/features/BackupChart/lib'
import {ChartContainer, LegendWrapper} from 'src/newcore/features/BackupChart/atoms'

const brushMargin = {top: 20, bottom: 20, left: 50, right: 20}
const chartSeparation = 40

const selectedBrushStyle = {
    fill: `rgba(85, 130, 255,0.3)`,
    stroke: variables.asphalt,
    rx: 4
}

type Colors = {
    [key: string]: string
}

const getColor = (keys: string[]) => {
    const colors2 = {
        [ADDED]: COLORS[ADDED],
        [UPDATED]: COLORS[UPDATED],
        [DELETED]: COLORS[DELETED]
    } as Colors

    return keys.map((k: string): string => {
        return colors2[k]
    })
}

const keys = [ADDED, UPDATED, DELETED]

const getDate = (d: any) => d[DATE]

const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: getColor(keys)
})

type ChartProps = {
    width: number
    height: number
    margin?: {top: number; right: number; bottom: number; left: number}
    compact?: boolean
    data?: SfcObjectHistory[]
}

const tooltipStyles = {
    ...defaultStyles,
    padding: 0
}

const DEFAULT_DATA_RANGE = 10
const getRangeIndexes = (data: SfcObjectHistory[] = []) => {
    const rangeIndexes = {
        start: 0,
        end: 0
    }

    const length = data.length

    if (length <= DEFAULT_DATA_RANGE) {
        rangeIndexes.end = length
    }

    if (length > DEFAULT_DATA_RANGE) {
        rangeIndexes.end = length
        rangeIndexes.start = length - DEFAULT_DATA_RANGE
    }

    return rangeIndexes
}

let tooltipTimeout: number

export const Chart = ({
    compact = false,
    width,
    height,
    margin = {
        top: 20,
        left: 50,
        bottom: 20,
        right: 20
    },
    data = []
}: ChartProps): JSX.Element => {
    const brushRef = useRef<BaseBrush | null>(null)
    const [rangeIndexes] = useState(() => getRangeIndexes(data))
    const [filteredStock, setFilteredStock] = useState(() => data.slice(rangeIndexes.start, rangeIndexes.end))
    const [filteredKeys, setFilteredKeys] = useState(keys)

    const {tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip} = useTooltip<
        CustomBarGroup<string>
    >()

    const {containerRef, TooltipInPortal, containerBounds} = useTooltipInPortal({
        // TooltipInPortal is rendered in a separate child of <body /> and positioned
        // with page coordinates which should be updated on scroll. consider using
        // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
        scroll: true
    })

    const onBrushChange = (domain: Bounds | null) => {
        if (!domain) return

        const stockCopy = data.filter(s => {
            return domain?.xValues?.includes(getDate(s))
        })
        setFilteredStock(stockCopy)
    }

    const onLegendChange = (hide: string[]) => {
        const stockCopy = filteredStock.map(i => {
            const t = {...data.find(j => j[DATE] === i[DATE])} as any

            for (const iterator of Object.keys(t)) {
                if (hide.includes(iterator)) {
                    delete t[iterator]
                }
            }

            return t
        })

        const keysCopy = keys.filter(i => {
            return !hide.includes(i)
        })

        setFilteredStock(stockCopy)
        setFilteredKeys(keysCopy)
    }

    const innerHeight = height - margin.top - margin.bottom
    const topChartBottomMargin = compact ? chartSeparation / 2 : chartSeparation + 10
    const topChartHeight = 0.72 * innerHeight - topChartBottomMargin
    const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

    const xMax = Math.max(width - margin.left - margin.right, 0)
    const yMax = Math.max(topChartHeight, 0)

    const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
    const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0)

    const xScale = useMemo(
        () =>
            scaleBand<string>({
                range: [0, xMax],
                domain: filteredStock.map(getDate),
                padding: 0.2
            }),
        [xMax, filteredStock]
    )

    const yMaxValue = useMemo(() => {
        return Math.max(...filteredStock.map((d: any) => Math.max(...filteredKeys.map(key => d[key]))))
    }, [filteredStock, filteredKeys])

    const yNumTicks = useMemo(() => {
        if (yMaxValue <= 5) {
            return 1
        } else if (yMaxValue <= 15) {
            return 3
        } else {
            return 5
        }
    }, [yMaxValue])

    const yScale = useMemo(() => {
        const fixedMaxValue = yMaxValue <= 1 ? 1 : yMaxValue <= 5 ? 5 : yMaxValue <= 15 ? 15 : yMaxValue

        return scaleLinear<number>({
            domain: [0, fixedMaxValue]
        })
    }, [yMax, yMaxValue])

    const xBandScale = useMemo(
        () =>
            scaleBand<string>({
                domain: filteredKeys,
                range: [0, xScale.bandwidth()],
                padding: 0.1
            }),
        [xMax, filteredStock]
    )

    const topChartColorScale = useMemo(
        () =>
            scaleOrdinal<string, string>({
                domain: filteredKeys,
                range: getColor(filteredKeys)
            }),
        [filteredKeys]
    )

    const brushXScale = useMemo(
        () =>
            scaleBand<string>({
                range: [0, xBrushMax],
                domain: data.map(getDate),
                padding: 0.2
            }),
        [xBrushMax]
    )

    const brushXBandScale = useMemo(
        () =>
            scaleBand<string>({
                domain: filteredKeys,
                range: [0, brushXScale.bandwidth()],
                padding: 0.1
            }),
        [xBrushMax]
    )

    const brushYScale = useMemo(
        () =>
            scaleLinear({
                range: [yBrushMax, 0],
                domain: [0, Math.max(...data.map((d: any) => Math.max(...filteredKeys.map(key => d[key]))))],
                nice: true
            }),
        [yBrushMax]
    )

    const initialBrushPosition = useMemo(
        () => ({
            start: {x: brushXScale(getDate(data[rangeIndexes.start]))},
            end: {x: brushXScale(getDate(data[rangeIndexes.end - 1]))}
        }),
        [brushXScale]
    )

    const onMouseLeave = () => {
        tooltipTimeout = window.setTimeout(() => {
            hideTooltip()
        }, 300)
    }

    const onMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => (barGroup: CustomBarGroup<string>) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout)

        const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left
        const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top

        showTooltip({
            tooltipData: barGroup,
            tooltipTop: containerY,
            tooltipLeft: containerX
        })
    }

    return (
        <ChartContainer>
            <svg width={width} height={height} ref={containerRef}>
                <rect x={0} y={0} width={width} height={height} fill={variables.blueLight} rx={14} />
                <AreaChart
                    data={filteredStock}
                    keys={filteredKeys}
                    width={width}
                    margin={{...margin, bottom: topChartBottomMargin}}
                    yMax={yMax}
                    xScale={xScale}
                    xBandScale={xBandScale}
                    yScale={yScale}
                    colorScale={topChartColorScale}
                    getDate={getDate}
                    onMouseLeaveHandler={onMouseLeave}
                    onMouseMoveHandler={onMouseMove}
                    xMax={xMax}
                    yNumTicks={yNumTicks}
                />
                <AreaChart
                    simplified
                    data={data}
                    keys={keys}
                    width={width}
                    yMax={yBrushMax}
                    xScale={brushXScale}
                    xBandScale={brushXBandScale}
                    yScale={brushYScale}
                    colorScale={colorScale}
                    getDate={getDate}
                    margin={brushMargin}
                    top={topChartHeight + topChartBottomMargin + margin.top}
                >
                    <Brush
                        xScale={brushXScale}
                        yScale={brushYScale}
                        width={xBrushMax}
                        height={yBrushMax}
                        margin={brushMargin}
                        handleSize={8}
                        innerRef={brushRef}
                        resizeTriggerAreas={[]}
                        initialBrushPosition={initialBrushPosition}
                        onChange={onBrushChange}
                        selectedBoxStyle={selectedBrushStyle}
                        useWindowMoveEvents
                    />
                </AreaChart>
            </svg>

            <LegendWrapper top={bottomChartHeight + topChartHeight + topChartBottomMargin + (margin.bottom / 2 - 10)}>
                <Legend events onLegendChange={onLegendChange} keys={keys} colors={getColor(keys)} />
            </LegendWrapper>

            {tooltipOpen && tooltipData && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                    <TooltipContent tooltipData={tooltipData} />
                </TooltipInPortal>
            )}
        </ChartContainer>
    )
}
