import React from 'react'
import {useTranslation} from 'react-i18next'

import {LogoComponents} from 'src/newcore/features/SignUp/lib'

import {StyledCustomers, CustomersWrapper, LogoWrapper} from './StyledCustomers'

export const Customers = () => {
    const {t} = useTranslation()

    return (
        <StyledCustomers>
            <CustomersWrapper>
                {LogoComponents.map((Component, index) => (
                    <LogoWrapper key={`1${index}`}>
                        <img src={Component} />
                    </LogoWrapper>
                ))}
                {LogoComponents.map((Component, index) => (
                    <LogoWrapper key={`2${index}`}>
                        <img src={Component} />
                    </LogoWrapper>
                ))}
                {LogoComponents.map((Component, index) => (
                    <LogoWrapper key={`3${index}`}>
                        <img src={Component} />
                    </LogoWrapper>
                ))}
            </CustomersWrapper>
        </StyledCustomers>
    )
}
