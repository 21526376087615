import styled from 'styled-components'
import variables from 'ca-common/variables'

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Left = styled.div`
    color: ${variables.asphalt};

    > :first-child {
        margin-right: ${variables.fontSizeBase};
    }
`

export const Right = styled.div`
    color: ${variables.navyBlue};
`
