import React, {useCallback, useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useLocation, useHistory, useParams} from 'react-router'
import {isEmpty, pickBy, isEqual} from 'lodash'

import {getUrlParams, makeUrlParams} from 'ca-common/utils/url'
import {openErrorNotification} from 'ca-common/utils/toasts'

import {setExpandedRowKeys, clearExpandedRowKeys} from 'src/newcore/redux/modules/expandedRowKeys'

import {StyledWrapped} from './molecules'
import {ObjectsTable, DEFAULT_PAGE, PAGE_SIZE, ObjectsTableHeader} from './organisms'
import {getSfcStats as fetcherSfcStats, clearSfsObjects} from './redux'
import {CompanyNameContext} from './libs'

export const ObjectsRaw = props => {
    const {actions, sfcObjects, companyInfo, expandedRowKeys} = props
    const location = useLocation()
    const history = useHistory()
    const {taskId} = useParams()

    const currentUrlParams = useMemo(() => getUrlParams(location.search), [location.search])

    const getSfcStats = async ({page, ...data} = {}) => {
        const fetchParams = {page: page || DEFAULT_PAGE, limit: PAGE_SIZE, taskId, ...currentUrlParams, ...data}

        try {
            await actions.fetcherSfcStats(fetchParams)
        } catch (error) {
            if (error?.status) {
                openErrorNotification(error.status)
            }
        }
    }

    const changeURLSearchParams = params => {
        const newParams = pickBy(
            {
                ...currentUrlParams,
                ...params
            },
            i => !isEmpty(i)
        )

        if (!isEqual(currentUrlParams, newParams)) {
            history.push({
                search: makeUrlParams(newParams)
            })
        }
    }

    useEffect(() => {
        getSfcStats()
    }, [location.search])

    useEffect(() => {
        return () => {
            actions.clearSfsObjects()
            actions.clearExpandedRowKeys()
        }
    }, [])

    const fetchOnScroll = useCallback(() => {
        if (sfcObjects.status === 'fulfilled') {
            const nextPage = sfcObjects.page + 1

            if (nextPage <= sfcObjects.totalPages) {
                getSfcStats({page: nextPage, append: true})
            }
        }
    }, [sfcObjects])

    return (
        <CompanyNameContext.Provider value={companyInfo?.name}>
            <StyledWrapped>
                <ObjectsTableHeader
                    totalObjects={sfcObjects?.total}
                    changeURLSearchParams={changeURLSearchParams}
                    loading={sfcObjects.status === 'pending'}
                    urlValues={currentUrlParams}
                />
                <ObjectsTable
                    sfcObjects={sfcObjects?.data || []}
                    changeURLSearchParams={changeURLSearchParams}
                    fetchOnScroll={fetchOnScroll}
                    loading={sfcObjects.status === 'pending'}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={actions.setExpandedRowKeys}
                    taskId={taskId}
                />
            </StyledWrapped>
        </CompanyNameContext.Provider>
    )
}

const mapStateToProps = state => ({
    sfcObjects: {
        ...state.backups.sfcObjects?.response,
        status: state.backups.sfcObjects.status
    },
    companyInfo: state.companyInfo.response,
    expandedRowKeys: state.expandedRowKeys
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setExpandedRowKeys,
            clearExpandedRowKeys,
            fetcherSfcStats,
            clearSfsObjects
        },
        dispatch
    )
})

export const Objects = connect(mapStateToProps, mapDispatchToProps)(ObjectsRaw)
