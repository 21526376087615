import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Edit from 'src/ca-common/icons/backupActionsNew/Edit.svg'

import {EditIcon} from 'src/newcore/features/Homepage/atoms/TaskView/StyledTaskView'
import {TODO_ANY} from 'ca-common/types'

export const EditName: React.FC<TODO_ANY> = ({onClick}) => {
    const {t} = useTranslation()

    return (
        <Tooltip title={t('homepage:editName')}>
            <EditIcon onClick={onClick} component={Edit} />
        </Tooltip>
    )
}
