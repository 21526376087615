import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {
    SEEDING_DESTINATION_TYPE,
    SEEDING_FILTERS,
    SEEDING_OBJECT_TYPES,
    SEEDING_PICK_OPTIONS
} from 'ca-common/common/enum/seeding'
import {getFilterOfObjects} from 'src/newcore/utils/seeding'
import {MetadataObjectsList, ObjectsList} from 'src/newcore/components/Seeding/types'

type TrackSFCReplicationDataProps = {
    wizardData: any
    objectsList?: ObjectsList | MetadataObjectsList
}

export const trackSFCReplicationData = ({wizardData, objectsList}: TrackSFCReplicationDataProps) => {
    const isMetadata = wizardData.dataType === SFC_DATA_TYPE.METADATA
    const isFull = wizardData.objectType === SEEDING_OBJECT_TYPES.FULL
    const granularity = isFull ? 'Full org' : isMetadata ? 'Multiple MD types' : 'Multiple objects'

    const destination =
        wizardData.destinationType === SEEDING_DESTINATION_TYPE.SAME ? 'Same organization' : 'Other organization'

    const {filter, criteria, numberOfRecords, selectedObjects, totalObjectsWithPickFilter} = (() => {
        let filter = ''
        let criteria = ''
        let numberOfRecords: number | undefined

        let objectsMap: {selectedObjects: string[]; totalObjectsWithPickFilter: number} | undefined

        if (!isMetadata && !isFull && objectsList) {
            const {name, filteringType, pickCount} = getFilterOfObjects(objectsList as ObjectsList)
            numberOfRecords = pickCount

            objectsMap = Object.entries(objectsList as ObjectsList).reduce(
                (acc, [objectName, item]) => {
                    if (!item.isDeleted) {
                        acc.selectedObjects.push(objectName)

                        if (item.filter.name === SEEDING_FILTERS.PICK) {
                            acc.totalObjectsWithPickFilter += 1
                        }
                    }

                    return acc
                },
                {selectedObjects: [], totalObjectsWithPickFilter: 0} as {
                    selectedObjects: string[]
                    totalObjectsWithPickFilter: number
                }
            )

            switch (name) {
                case SEEDING_FILTERS.ALL_RECORDS:
                    filter = 'All Records'
                    break
                case SEEDING_FILTERS.PICK:
                    filter = 'Selected Records'
                    break
                case SEEDING_FILTERS.WHERE_CLAUSE:
                    filter = 'SOQL Query'
                    break
                case SEEDING_FILTERS.MIXED:
                    filter = 'Mixed'
                    break
            }

            if (name === SEEDING_FILTERS.PICK) {
                switch (filteringType) {
                    case SEEDING_PICK_OPTIONS.NEWEST:
                        criteria = 'Newest'
                        break
                    case SEEDING_PICK_OPTIONS.OLDEST:
                        criteria = 'Oldest'
                        break
                    case SEEDING_PICK_OPTIONS.RANDOM:
                        criteria = 'Random'
                        break
                }
            }
        }

        return {
            filter,
            criteria,
            numberOfRecords,
            selectedObjects: objectsMap?.selectedObjects,
            totalObjectsWithPickFilter: objectsMap?.totalObjectsWithPickFilter
        }
    })()

    const selectedMetadata =
        isMetadata && !isFull && objectsList
            ? Object.entries(objectsList as MetadataObjectsList).reduce((acc, [name, value]) => {
                  if (value) {
                      acc.push(name)
                  }
                  return acc
              }, [] as string[])
            : null

    const trackData = {
        'Replicated Data Type': isMetadata ? 'Metadata' : 'Data',
        'Replication Granularity': granularity,
        'Replication Destination': destination
    } as Record<string, any>

    if (!isMetadata) {
        trackData['Disable Rules, Triggers, Workflows'] = wizardData.disableTriggers
    }

    if (typeof wizardData.includeAttachments === 'boolean') {
        trackData['Include Attachments'] = wizardData.includeAttachments
    }

    if (selectedMetadata) {
        trackData['Selected Metadata Types'] = selectedMetadata
    }

    if (selectedObjects) {
        trackData['Selected Objects'] = selectedObjects
    }

    if (!isMetadata && typeof wizardData.hierarchyDepth === 'number') {
        trackData['Hierarchy Depth'] = wizardData.hierarchyDepth
    }

    if (filter) {
        trackData['Filtering Records'] = filter
    }

    if (criteria) {
        trackData['Records Selection Criteria'] = criteria
    }

    if (typeof numberOfRecords === 'number') {
        trackData['Number Of Records'] = numberOfRecords
    }

    track(EVENT_NAMES.SALESFORCE_DATA_REPLICATION, trackData)

    if (totalObjectsWithPickFilter) {
        track(EVENT_NAMES.SALESFORCE_REPLICATION_OF_SELECTED_RECORDS, {
            'Number Of Selected Records': totalObjectsWithPickFilter
        })
    }
}
