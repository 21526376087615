import styled from 'styled-components'
import {Drawer} from 'antd'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledDrawer = styled(Drawer)`
    font-family: ${variables.publicPageFont};
    top: 64px;
    height: 100vh;
    color: ${variables.text};

    .ant-drawer-header {
        padding: ${variables.gutterXs} ${variables.gutterSm};
        background-color: ${variables.royalBlue};
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }

    .ant-drawer-body {
        padding: ${variables.gutterXs};
        gap: ${variables.gutterXs};
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ant-drawer-title {
        color: ${variables.white};
    }

    .ant-drawer-close {
        color: ${variables.white};
        margin-right: 0;
    }
`

export const StyledSubtitle = styled.div`
    font-weight: 500;
    font-size: ${variables.fontSizeBase};
    padding: ${variables.gutterXs};
`

export const StyledSeeAll = styled(CALink)`
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.royalBlue};
    height: 36px;
    display: flex;
    align-items: center;
    padding: ${variables.gutterXs};
    font-weight: 300;
`

export const StyledReadUserGuides = styled.div`
    font-weight: 400;
    font-size: ${variables.fontSizeSecondary};
    border-top: 1px solid ${variables.blueLight};
    border-bottom: 1px solid ${variables.blueLight};
    padding: ${variables.gutterXs};
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    margin-bottom: ${variables.gutterXs};

    svg {
        fill: none;
    }
`

export const StyledUserGuidesLink = styled(CALink)`
    color: ${variables.royalBlue};
    text-decoration: underline;
`

export const StyledButton = styled(Button)`
    text-align: left;
    width: 100%;
    height: 36px;
    padding: 0 ${variables.gutterXs};
    font-weight: 300;
    font-family: ${variables.publicPageFont};
    box-shadow: none !important;

    svg {
        fill: none;
    }
`
