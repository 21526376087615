import styled from 'styled-components'
import {Checkbox} from 'antd'

import variables from 'ca-common/variables'

const CheckboxGroup = Checkbox.Group

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    display: flex;
    flex-direction: column;
    gap: 19px;
`

export const InfiniteContainer = styled.div`
    overflow: auto;
    height: 354px;
    width: 100%;
    padding: 42px 0 0;
`

export const ModalContentWrapper = styled.div`
    width: auto;
    border-top: 1px solid ${variables.blue};
    padding-left: ${variables.gutterMd};
`
