import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SNAPSHOT = 'GET_SNAPSHOT'

const GET_SNAPSHOTS = 'GET_SNAPSHOTS'

const getSnapshotWrapper = fetchWrapperRT(GET_SNAPSHOT, async data => await get('getSnapshot', data))
export const getSnapshot = getSnapshotWrapper.fetcher

const snapshotSlice = createSlice({
    name: 'snapshot',
    initialState: InitFetchedState,
    reducers: {
        clearSnapshot: () => InitFetchedState
    },
    extraReducers: builder => getSnapshotWrapper.makeReducers(builder)
})

export const {clearSnapshot} = snapshotSlice.actions

const getSnapshotsWrapper = fetchWrapperRT(GET_SNAPSHOTS, async data => await get('getSnapshots', data))
export const getSnapshots = getSnapshotsWrapper.fetcher

const snapshotsSlice = createSlice({
    name: 'snapshots',
    initialState: InitFetchedState,
    reducers: {
        clearSnapshots: () => InitFetchedState
    },
    extraReducers: builder => getSnapshotsWrapper.makeReducers(builder)
})

export const {clearSnapshots} = snapshotsSlice.actions

export const snapshotsReducer = combineReducers({
    snapshot: snapshotSlice.reducer,
    snapshots: snapshotsSlice.reducer
})
