import {InputNumber, Form} from 'antd'
import styled from 'styled-components'
import Icon, {ExclamationCircleFilled} from '@ant-design/icons'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    margin: -${variables.gutterMd};
`

export const StyledFrame = styled.div`
    max-width: 420px;

    display: flex;
    flex-direction: column;

    .ant-form-item {
        margin-bottom: 20px;
        align-items: center;
        .ant-form-item-control {
            line-height: 40px;
        }
    }

    .ant-input-number-suffix {
        margin-right: ${variables.gutterMd};
    }
`

export const StyledWrapper = styled.div`
    padding: 28px ${variables.gutterMd};
    border-bottom: 1px solid ${variables.navyBorder};
`

export const StyledFooter = styled.div`
    margin: ${variables.gutterSm} ${variables.gutterMd};
    display: flex;
    justify-content: space-between;
`

export const StyledButton = styled(Button)`
    padding: 0;
`

export const StyledAsFormItem = styled.span.attrs(() => ({className: 'ant-row ant-form-item'}))``

export const StyledHeader = styled(StyledAsFormItem)`
    font-weight: 700;
    font-size: 16px;
    color: ${variables.navyBlue};
`

export const StyledSpan = styled(StyledAsFormItem)`
    font-weight: 600;
`

export const StyledIcon = styled(Icon)`
    margin-right: 8px;
`

export const StyledInputNumber = styled(InputNumber)`
    width: 100%;
`

export const StyledWarningIcon = styled(ExclamationCircleFilled)`
    color: ${variables.orange};
    margin: 4px 8px 0 0;
`

export const StyledErrorIcon = styled(Icon)`
    color: ${variables.red};
    margin: 4px 8px 0 0;
`

export const StyledError = styled.div`
    display: flex;
    pointer-events: none;

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeSecondary};
    line-height: 18px;

    color: ${variables.red};
`
