import styled, {css} from 'styled-components'

import variables from 'ca-common/variables'

export const StyledSwitcher = styled.div`
    display: flex;
`

export const SwitcherItem = styled.div<{isActive?: boolean}>`
    padding: ${variables.gutterXs} ${variables.gutterMd};
    background-color: ${variables.white};
    color: ${variables.navyAsphalt};
    border: 1px solid ${variables.navyAsphalt};
    cursor: pointer;
    border-right: none;
    font-weight: 400;

    :hover {
        background-color: ${variables.navyBorder};
    }

    :first-child {
        border-bottom-left-radius: ${variables.borderRadiusBlocks};
        border-top-left-radius: ${variables.borderRadiusBlocks};
    }

    :last-child {
        border-bottom-right-radius: ${variables.borderRadiusBlocks};
        border-top-right-radius: ${variables.borderRadiusBlocks};
        border-right: 1px solid ${variables.navyAsphalt};
    }

    ${({isActive}) => {
        return (
            isActive &&
            css`
                color: ${variables.white};
                border-color: ${variables.navyBlue} !important;
                background-color: ${variables.navyBlue} !important;
            `
        )
    }}
`
