import React, {useState} from 'react'
import {LegendLabel} from '@visx/legend'
import {useTranslation} from 'react-i18next'

import varaibles from 'ca-common/variables'
import {StyledLegendItem} from './StyledLegendCheckbox'

const legendGlyphSize = 15

type CheckBoxProps = {
    onClickHandler?: any
    label?: any
}

export const CheckBox = (props: CheckBoxProps): JSX.Element => {
    const {onClickHandler, label} = props
    const [checked, setChecked] = useState(true)
    const {t} = useTranslation()

    const onClick = () => {
        onClickHandler()

        setChecked(s => !s)
    }

    return (
        <StyledLegendItem margin="0 10px" onClick={onClick}>
            <svg width={legendGlyphSize} height={legendGlyphSize}>
                <rect
                    fill={checked ? label.value : 'transparent'}
                    stroke={checked ? 'none' : varaibles.asphaltLight}
                    width={legendGlyphSize}
                    height={legendGlyphSize}
                    rx={3}
                />
            </svg>
            <LegendLabel align="left" margin="0 0 0 8px">
                {t(`backupChart:${label.text}`)}
            </LegendLabel>
        </StyledLegendItem>
    )
}
