import styled from 'styled-components'

import variables from 'ca-common/variables'

import {BackupAuthBlock} from '../StyledOrganisms'

export const BackupAuthHeader = styled(BackupAuthBlock)`
    height: 72px;
`

export const HeaderBase = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row;
    gap: ${variables.gutterMd};

    h3 {
        margin-bottom: 0;
    }
`
