import React, {Fragment, useState} from 'react'
import {Drawer} from 'antd'
import {useTranslation, Trans} from 'react-i18next'

import {SOURCE} from 'src/ca-common/common/enum'

const DRAWER_WIDTH = 600
const DRAWER_Z_INDEX = 1051

import {StyledHowTo} from './StyledHowTo'

export const HowTo = ({source}: {source: SOURCE}): JSX.Element => {
    const [visible, setVisible] = useState(false)
    const {t} = useTranslation()
    const title = t(`forms:backupAuth:howTo:${source}:title`)

    return (
        <Fragment>
            <StyledHowTo onClick={() => setVisible(true)}>{title}</StyledHowTo>

            <Drawer
                title={title}
                placement="right"
                visible={visible}
                onClose={() => setVisible(false)}
                destroyOnClose={true}
                width={DRAWER_WIDTH}
                zIndex={DRAWER_Z_INDEX}
            >
                <Trans i18nKey={`forms:backupAuth:howTo:${source}:text`} />
            </Drawer>
        </Fragment>
    )
}
