import React from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {PAGES} from 'ca-common/constants'

export const RecoveryHomepageBreadcrumb = (): JSX.Element => {
    const {t} = useTranslation()

    return (
        <NavLink title="Homepage" to={`/${PAGES.BACKUPS}`}>
            {t('breadcrumb:homepage')}
        </NavLink>
    )
}
