import {Button, Select} from 'antd'
import styled, {css} from 'styled-components'
import {asphaltLight, borderRadiusBase, disabled, iceBlue, navyBlue} from 'ca-common/variables'

export const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`

export const StyledYearSelect = styled(Select)`
    padding-right: 24px;

    &&& .ant-select-selector {
        border: 0;
        border-radius: ${borderRadiusBase};
        color: ${navyBlue};

        .ant-select-selection-item {
            padding-right: 0;
        }

        &:focus {
            box-shadow: 0 0 0 2px ${iceBlue};
        }
    }

    .ant-select-arrow {
        display: none;
    }
`

export const StyledMonthSelectButton = styled(Button)`
    padding: 0 16px;

    &:focus {
        border-color: ${iceBlue};
    }
`

export const StyledCalendarArrowButton = styled.button<{double?: boolean; toRight?: boolean; disabled: boolean}>`
    width: 18px;
    height: 18px;
    background: none;
    border: 0;
    position: relative;
    top: -2px;
    outline-color: ${iceBlue};

    &::before,
    &::after {
        box-sizing: border-box;
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 8px;
        top: 4px;
        left: 4px;
        vertical-align: middle;
        border-color: ${asphaltLight};
        border-style: solid;
        border-width: 1.5px 0 0 1.5px;
        border-radius: 1px;
        transform: rotate(-45deg);
        transition: all 300ms;
        content: '';
    }

    &::after {
        display: none;
    }

    ${props =>
        props.double &&
        css`
            &::after {
                display: inline-block;
                left: 9px;
            }
        `}

    ${props =>
        props.toRight &&
        css`
            &::before,
            &::after {
                transform: rotate(135deg);
                left: auto;
                right: 4px;
            }

            &::after {
                right: 9px;
            }
        `}

    &:hover, &:focus {
        &::before,
        &::after {
            border-color: ${navyBlue};
            cursor: pointer;
        }
    }

    &:disabled {
        &::before,
        &::after {
            border-color: ${disabled};
            cursor: not-allowed;
        }
    }
`
