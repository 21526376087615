import i18n from 'i18next'
import {Table} from 'antd'

import {DATE_WITH_AT_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

export const calendarsColumns = [
    {
        title: i18n.t('table:columns:event'),
        dataIndex: 'event',
        key: 'calendarEvent',
        width: '55%',
        render: (text: string) => ({
            children: text,
            props: {
                'data-hj-suppress': true
            }
        })
    },
    {
        title: i18n.t('table:columns:start'),
        dataIndex: 'startDate',
        key: 'calendarStart',
        width: '20%',
        ellipsis: true,
        render: (text: string): string => getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT)
    },
    {
        title: i18n.t('table:columns:end'),
        dataIndex: 'endDate',
        key: 'calendarEnd',
        width: '20%',
        ellipsis: true,
        render: (text: string): string => getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT)
    },
    Table.EXPAND_COLUMN
]
