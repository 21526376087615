import React, {useCallback, useState} from 'react'
import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'
import {DEBOUNCE_TIMEOUT} from 'ca-common/constants'

import {DEFAULT_PAGE} from 'src/newcore/features/OneClickActivation/lib'

type UseSearch = (params: {
    service: SOURCE
    fetchEntities: (params: {search?: string; page?: number; service: SOURCE; entities?: string}) => void
    enabledEntitiesBitSet: string
    cancelFetchEntities: () => void
}) => {
    search: string
    cancelOnSearchHandler: () => void
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    setSearch: (value: ((prevState: string) => string) | string) => void
}

export const useSearch: UseSearch = ({fetchEntities, service, enabledEntitiesBitSet, cancelFetchEntities}) => {
    const [search, setSearch] = useState('')

    const onSearchChangedDebounced = useCallback(
        _.debounce((search: string) => {
            cancelFetchEntities()
            fetchEntities({search, service, page: DEFAULT_PAGE, entities: enabledEntitiesBitSet})
        }, DEBOUNCE_TIMEOUT),
        [service, enabledEntitiesBitSet]
    )

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        onSearchChangedDebounced(value)
    }

    const cancelOnSearchHandler = () => {
        onSearchChangedDebounced.cancel()
    }

    return {search, onSearchChange, setSearch, cancelOnSearchHandler}
}
