import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledForecastCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: ${variables.gutterLg};
`

export const StyledSubscriptionBlock = styled.div`
    width: min-content;
    min-width: 327px;
    margin: 0 auto 50px;
`

export const StyledSubscribe = styled.div`
    font-size: ${variables.headingFontSize};
    font-weight: 400;
    white-space: nowrap;
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${variables.gutterSm};
`
