import React, {Component} from 'react'
import PropTypes from 'prop-types'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {Field, formValueSelector, change} from 'redux-form'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {countPositiveValues} from 'ca-common/utils/usersManagement'
import {AntdCheckbox} from 'ca-common/ui-lib/components/ReduxFormElements/CheckBox'

import './CheckboxTree.scss'

const dumbBlock = dumbBem('ca-tree')
const CATree = tx(dumbBlock)('div')
const CATreeNode = tx([{element: 'node'}, dumbBlock])('div')

class CheckboxTreeRaw extends Component {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        form: PropTypes.string.isRequired,
        prefix: PropTypes.string
    }

    static defaultProps = {
        prefix: ''
    }

    componentDidUpdate = ({count: prevCount}) => {
        const {
            data: {name, sub, label},
            actions,
            prefix,
            count: newCount
        } = this.props

        if (prevCount !== newCount) {
            const namePrefix = `${prefix ? `${prefix}.` : ''}`

            if (newCount < sub.length && prevCount === sub.length) {
                actions.setValue(`${namePrefix}${label}.${name}`, false)
            } else if (newCount === sub.length) {
                actions.setValue(`${namePrefix}${label}.${name}`, true)
            }
        }
    }

    render = () => {
        const {
            data: {name, frendlyName, label, sub, ...params},
            prefix
        } = this.props
        const namePrefix = `${prefix ? `${prefix}.` : ''}`
        const parentName = `${namePrefix}${label}.${name}`

        return (
            <CATree>
                <Field
                    component={AntdCheckbox}
                    type="checkbox"
                    {...params}
                    name={parentName}
                    htmlFor={parentName}
                    label={frendlyName}
                    onChange={this.handleAll}
                />

                {this.renderNodes(sub, {prefix: namePrefix, group: label})}
            </CATree>
        )
    }

    renderNodes = (nodes = [], {prefix, group}) => {
        return nodes.map((node, index) => {
            const {name, frendlyName, onChange, permissionGroup, ...params} = node
            const _name = `${prefix}${group}.sub.${name}`
            const _permissionGroup = permissionGroup // eslint-disable-line no-unused-vars

            return node ? (
                <CATreeNode key={index}>
                    <Field
                        component={AntdCheckbox}
                        type="checkbox"
                        {...params}
                        name={_name}
                        htmlFor={_name}
                        label={frendlyName}
                        onChange={onChange ? this.execAction(onChange) : null}
                    />
                </CATreeNode>
            ) : null
        })
    }

    handleAll = (_event, value) => {
        const {
            data: {sub, label},
            prefix,
            actions
        } = this.props
        const namePrefix = `${prefix ? `${prefix}.` : ''}`

        sub.forEach(i => {
            actions.setValue(`${namePrefix}${label}.sub.${i.name}`, value)
        })
    }

    execAction = action => (_, val) => action(val)
}

const mapStateToProps = (state, {prefix, form, data: {label}}) => {
    const selector = formValueSelector(form)
    const namePrefix = `${prefix ? `${prefix}.` : ''}`

    return {
        count: countPositiveValues(selector(state, `${namePrefix}${label}.sub`))
    }
}

const mapDispatchToProps = (dispatch, {form}) => ({
    actions: {
        ...bindActionCreators(
            {
                setValue: (name, value) => dispatch(change(form, name, value))
            },
            dispatch
        )
    }
})

export const CheckboxTree = connect(mapStateToProps, mapDispatchToProps)(CheckboxTreeRaw)
