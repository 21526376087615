import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledPopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: -12px -24px;
`
export const StyledHeader = styled.div`
    border-bottom: 1px solid ${variables.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${variables.asphalt};
    height: 32px;
    padding: 0 ${variables.gutterMd};
`

export const StyledHeaderKeys = styled.span`
    color: ${variables.dark};
    padding: 0 3px;
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 ${variables.gutterMd};
`
