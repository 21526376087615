import React from 'react'
import {Typography} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

const {Title} = Typography

export const StyledTitle = styled(Title)`
    font-family: ${variables.accentFont};
`

export const MainTitle = styled(props => <StyledTitle level={1} {...props} />)`
    && {
        font-size: ${variables.sectionFontSize};
    }
`

export const HeadingTitle = styled(props => <StyledTitle level={2} {...props} />)`
    && {
        font-size: ${variables.mainTitleFontSize};
        font-weight: bold;
    }
`

export const SubTitle = styled(props => <StyledTitle level={3} {...props} />)``

export const BlockTitle = styled.h5`
    font-family: ${variables.accentFont};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
`

export const SectionTitle = styled(props => <StyledTitle level={4} {...props} />)`
    && {
        font-weight: normal;
        color: ${variables.text};
    }
`
