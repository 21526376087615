import styled from 'styled-components'

import variables from 'ca-common/variables'

export const TilesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: ${variables.gutterSm};
    padding: 2px ${variables.gutterSm} ${variables.gutterSm} 0;
`
export const ScrollWrapper = styled.section`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 300px;
    max-width: 500px;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${variables.asphaltLight};
        border-radius: 5px;
    }
`
