import {createAction, createReducer, createSelector} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'
import {AppState} from 'src/newcore/components/Root/newAppStore'
import cloneDeep from 'lodash/cloneDeep'

// WIZZARD
const INIT_WIZARD_STATE = {
    type: '',
    step: 0,
    progress: 0,
    data: {} as Record<string, any>,
    config: {
        disabledSteps: [] as string[],
        skipableSteps: [] as string[],
        beforeStepChange: undefined as (() => Promise<void>) | undefined
    },
    title: undefined,
    description: undefined,
    icon: undefined
}

export type WizardState = typeof INIT_WIZARD_STATE

export const nextStep = createAction('WIZARD_NEXT_STEP')
export const previousStep = createAction('WIZARD_PREVIOUS_STEP')
export const clearWizard = createAction('WIZARD_CLEAR_WIZARD')
export const changeData = createAction<WizardState['data']>('WIZARD_CHANGE_DATA')
export const removeData = createAction<string>('WIZARD_REMOVE_DATA')
export const changeStep = createAction<WizardState['step']>('WIZARD_CHANGE_STEP')
export const changeProgress = createAction<Partial<WizardState['progress']>>('WIZARD_CHANGE_PROGRESS')
export const changeConfig = createAction<TODO_ANY>('WIZARD_CHANGE_CONFIG')
export const openWizard = createAction<string>('WIZARD_OPEN_WIZARD')
export const openWizardWithConfig = createAction<TODO_ANY>('WIZARD_OPEN_WIZARD_CONFIG')
export const setWizardTitle = createAction<TODO_ANY>('WIZARD_SET_TITLE')
export const setWizardDescription = createAction<TODO_ANY>('WIZARD_SET_DESCRIPTION')
export const setWizardIcon = createAction<TODO_ANY>('WIZARD_SET_ICON')
export const closeWizard = createAction('WIZARD_CLOSE')

const getNextStep = (activeStep: number, disabledSteps?: string[], skipableSteps?: string[]) => {
    let newStep = activeStep + 1

    while (disabledSteps?.includes(String(newStep)) || skipableSteps?.includes(String(newStep))) {
        newStep++
    }

    return newStep
}

const getPreviousStep = (activeStep: number, disabledSteps?: string[], skipableSteps?: string[]) => {
    for (let newStep = activeStep - 1; newStep > 0; newStep--) {
        if (!disabledSteps?.includes(String(newStep)) && !skipableSteps?.includes(String(newStep))) {
            return newStep
        }
    }

    return 0
}

export const wizardReducers = createReducer(INIT_WIZARD_STATE, builder => {
    builder.addCase(nextStep, state => {
        const newStep = getNextStep(state.step, state.config.disabledSteps, state.config.skipableSteps)

        state.progress = Math.max(newStep, state.progress)
        state.step = newStep
    })

    builder.addCase(previousStep, state => {
        state.step = getPreviousStep(state.step, state.config.disabledSteps, state.config.skipableSteps)
    })

    builder.addCase(changeData, (state, action) => {
        state.data = {
            ...state.data,
            ...action.payload
        }
    })

    builder.addCase(removeData, (state, action) => {
        const dataCopy = cloneDeep(state.data)
        delete dataCopy[action.payload]

        state.data = dataCopy
    })

    builder.addCase(changeStep, (state, action) => {
        state.step = state.progress >= action.payload ? action.payload : state.step
    })

    builder.addCase(changeProgress, (state, action) => {
        state.progress = action.payload
    })

    builder.addCase(changeConfig, (state, action) => {
        state.config = {
            ...state.config,
            ...action.payload
        }
    })

    builder.addCase(openWizard, (state, action) => {
        state.type = action.payload
    })

    builder.addCase(openWizardWithConfig, (state, action) => {
        state.type = action.payload?.type
        state.config = action.payload?.config
        state.step = action.payload?.step || state.step
    })

    builder.addCase(setWizardTitle, (state, action) => {
        state.title = action.payload
    })

    builder.addCase(setWizardDescription, (state, action) => {
        state.description = action.payload
    })

    builder.addCase(setWizardIcon, (state, action) => {
        state.icon = action.payload
    })

    builder.addCase(clearWizard, () => INIT_WIZARD_STATE)

    builder.addCase(closeWizard, state => {
        state.type = ''
    })
})

export const wizardDataSelector = createSelector(
    (state: AppState) => state.wizard.data,
    data => data
)

export const wizardConfigSelector = createSelector(
    (state: AppState) => state.wizard.config,
    config => config
)

export const wizardStepSelector = createSelector(
    (state: AppState) => state.wizard.step,
    step => step
)
