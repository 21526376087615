import {RESTORE_OPTIONS} from 'ca-common/common/enum'
import {isDevelopment} from 'ca-common/utils/runtime'

export const DONE_AND_ENABLED_RESTORE_OPTIONS = [] as RESTORE_OPTIONS[]

export const checkIsRestoreOptionEnabled = (option: RESTORE_OPTIONS) =>
    DONE_AND_ENABLED_RESTORE_OPTIONS.includes(option) ||
    (isDevelopment &&
        (() => {
            const found = localStorage.getItem('ENABLED_RESTORE_OPTIONS')

            console.log('ENABLED_RESTORE_OPTIONS: ', found)

            return Boolean(found?.split(',')?.find(i => i.trim() === option))
        })())
