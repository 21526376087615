import styled from 'styled-components'
import variables from 'ca-common/variables'
import {SubTitle} from '../Typography'

export const PageTitle = styled(SubTitle)`
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: ${variables.gutterXs};
    color: ${variables.black};
`
