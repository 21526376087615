import React from 'react'
import {Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'
import {SFC_TYPE} from 'ca-common/common/enum'
import {RadioButton, RadioGroup} from 'ca-common/ui-lib/components/RadioBox'

import {Header, Footer, HowTo} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const SFC = ({source, backupSettings, onSubmit, onCancel, loading, companyName}: BackupAuthTemplate) => {
    const {t} = useTranslation()
    const isNew = isEmpty(backupSettings)
    const initialValues = {
        alias: backupSettings.alias,
        account: backupSettings.account,
        sfctype: backupSettings.sfcProdAccount || isNew ? SFC_TYPE.PRODUCTION : SFC_TYPE.SANDBOX
    }

    return (
        <StyledBackupAuthForm initialValues={initialValues} layout="horizontal" onFinish={values => onSubmit(values)}>
            <Header source={source} howTo={<HowTo source={source} />} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>
                        <p>
                            {t('forms:backupAuth:text:SFCInfo', {
                                companyName
                            })}
                        </p>
                    </Col>
                    <Col>
                        <Form.Item name="sfctype">
                            <RadioGroup disabled={!isNew}>
                                <RadioButton value={SFC_TYPE.PRODUCTION} key={SFC_TYPE.PRODUCTION}>
                                    {t('forms:backupAuth:fields:SFCBackupProduction:label')}
                                </RadioButton>
                                <RadioButton value={SFC_TYPE.SANDBOX} key={SFC_TYPE.SANDBOX}>
                                    {t('forms:backupAuth:fields:SFCBackupSandbox:label')}
                                </RadioButton>
                            </RadioGroup>
                        </Form.Item>
                    </Col>
                    {!isNew && (
                        <Col>
                            <TaskCreatedAccount account={backupSettings.account} />
                        </Col>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('forms:backupAuth:text:SFCSubmittingText')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
