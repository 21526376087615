import React from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import queryString from 'query-string'
import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'

import {getBackupEntities} from 'src/newcore/redux/modules/backups'
import {refreshEntities} from 'src/newcore/redux/modules/backups/backupEntities/refreshEntities'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {openErrorModal} from 'ca-common/utils/modals'

import Refresh from 'ca-common/icons/Refresh.svg'

import {PAGE_SIZE, DEFAULT_PAGE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'
import {getBulkActivationRules} from 'src/newcore/redux/modules/backups/bulkActivate'

import {StyledButton, StyledButtonWrapper} from './StyledRefreshEntities'

@withTranslation('backups')
class RefreshEntitiesRaw extends React.Component {
    refresh = () => {
        const {
            taskSettings: {taskId, source},
            actions,
            history
        } = this.props
        const queryParams = queryString.parse(history.location.search, {arrayFormat: 'bracket'})

        actions
            .refreshEntities({
                taskId
            })
            .then(() => {
                actions
                    .getBackupEntities({
                        taskId,
                        rows: PAGE_SIZE,
                        page: DEFAULT_PAGE,
                        source,
                        ...getRequestParamsFromUrl(queryParams)
                    })
                    .unwrap()
                    .then(() => {
                        actions.getNewBackupTaskSettings({taskId})
                        actions.getBulkActivationRules({taskId})
                    })
                    .catch(err => {
                        if (err.status) {
                            openErrorModal(err.status)
                        }
                    })
            })
            .catch(err => {
                openErrorModal(err.status)
            })
    }

    render() {
        const {entities, refreshEntitiesStatus, t} = this.props

        return (
            <Tooltip title={t('refreshEntities:tooltip')}>
                <StyledButtonWrapper>
                    <StyledButton
                        shape="circle"
                        onClick={this.refresh}
                        disabled={'pending' === entities.status || 'pending' === refreshEntitiesStatus}
                    >
                        <Icon component={Refresh} />
                    </StyledButton>
                </StyledButtonWrapper>
            </Tooltip>
        )
    }
}
const mapStateToProps = state => ({
    entities: state.backups.backupEntities.entities,
    refreshEntitiesStatus: state.backups.backupEntities.refreshEntities.status,
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            refreshEntities,
            getBackupEntities,
            getNewBackupTaskSettings,
            getBulkActivationRules
        },
        dispatch
    )
})

export const RefreshEntities = withRouter(connect(mapStateToProps, mapDispatchToProps)(RefreshEntitiesRaw))
