import _ from 'lodash'
import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import {withTranslation} from 'react-i18next'

import {CancelLink, LinkWrapper, ModalCenterContentWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms/Button'

import {
    getMS365Groups,
    appendMS365Groups
} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups/MS365GroupsList'
import {setSelectedGroups} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups/SelectedGroups'
import {GroupListWrapper} from 'src/newcore/components/BulkActivation/StyledBulkActivation'
import {closeWizard, nextStep, setWizardDescription, previousStep} from 'src/newcore/redux/modules/wizard'
import {InfiniteContainer, StyledCheckbox, StyledGroup} from './StyledMS365GroupsList'
import {MS365GroupsForm} from './MS365GroupsForm'

export const DEFAULT_PAGE = 1
export const ROWS_BULK_GROUPS = 50

class MS365GroupsListRaw extends Component {
    state = {
        query: ''
    }

    componentDidMount() {
        const {taskSettings, actions, t} = this.props
        actions.getMS365Groups({taskId: taskSettings.taskId, page: DEFAULT_PAGE, rows: ROWS_BULK_GROUPS})
        actions.setWizardDescription(t('backups:MS365Groups:description'))
    }

    handleScroll = () => {
        const {actions, MS365GroupsList, taskSettings} = this.props
        const {query} = this.state

        //@TODO hack here
        if (MS365GroupsList.status === 'fulfilled' && MS365GroupsList.page < MS365GroupsList.totalPages) {
            actions.appendMS365Groups({
                taskId: taskSettings.taskId,
                page: MS365GroupsList.page + 1,
                rows: ROWS_BULK_GROUPS,
                query
            })
        }
    }

    handleFilterSubmit = values => {
        const {actions, taskSettings} = this.props

        this.setState({
            query: values.query
        })

        actions.getMS365Groups({
            taskId: taskSettings.taskId,
            page: DEFAULT_PAGE,
            rows: ROWS_BULK_GROUPS,
            ...values
        })
    }

    handleCheckboxChange = group => {
        const {selectedGroups, actions} = this.props
        const isGroupSelected = !!_.find(selectedGroups, ['id', group.id])

        actions.setSelectedGroups(
            isGroupSelected ? _.reject(selectedGroups, ['id', group.id]) : [...selectedGroups, group]
        )
    }

    render() {
        const {t, MS365GroupsList, selectedGroups, actions} = this.props

        const sortedList = (x, y) => {
            return x.name.localeCompare(y.name)
        }

        return (
            <Fragment>
                <GroupListWrapper>
                    <ModalCenterContentWrapper>
                        <Spin spinning={MS365GroupsList.status === 'pending'}>
                            <MS365GroupsForm onSubmit={this.handleFilterSubmit} />
                            <InfiniteContainer data-hj-suppress>
                                <InfiniteScroll
                                    initialLoad={false}
                                    pageStart={DEFAULT_PAGE}
                                    useWindow={false}
                                    hasMore={MS365GroupsList.page < MS365GroupsList.totalPages}
                                    loadMore={this.handleScroll}
                                >
                                    {!!MS365GroupsList.data?.length &&
                                        [...MS365GroupsList.data].sort(sortedList).map(group => {
                                            return (
                                                <StyledGroup key={group.id}>
                                                    <StyledCheckbox
                                                        value={group.id}
                                                        checked={!!_.find(selectedGroups, ['id', group.id])}
                                                        onChange={() => this.handleCheckboxChange(group)}
                                                    >
                                                        {group.name}
                                                    </StyledCheckbox>
                                                </StyledGroup>
                                            )
                                        })}
                                </InfiniteScroll>
                            </InfiniteContainer>
                        </Spin>
                    </ModalCenterContentWrapper>
                </GroupListWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={() => actions.closeWizard()}> {t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={() => actions.nextStep()} disabled={_.isEmpty(selectedGroups)}>
                            {t('forms:common:actions:next')}
                        </Button>
                    </LinkWrapper>
                    <BackLink onClick={() => actions.previousStep()} />
                </ModalFooter>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {MS365GroupsList} = state.backups.bulkActivation.MS365Groups

    return {
        MS365GroupsList: {
            status: MS365GroupsList.status,
            ...MS365GroupsList.response
        },
        taskSettings: state.backups.settings.response,
        selectedGroups: state.backups.bulkActivation.MS365Groups.selectedGroups,
        bulkActivationRules: state.backups.bulkActivation.bulkActivationRules.rules.response || [],
        createRule: state.backups.bulkActivation.createRule
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getMS365Groups,
            appendMS365Groups,
            setSelectedGroups,
            nextStep,
            closeWizard,
            setWizardDescription,
            previousStep
        },
        dispatch
    )
})

export const MS365GroupsList = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MS365GroupsListRaw))
