import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox, Col, Form, InputNumber, Row, Switch, Tooltip} from 'antd'

import Info from 'ca-common/icons/Info.svg'
import {ACCESS_LEVEL} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms'
import {AppState, useAppSelector} from 'src/newcore/components/Root'

import {StyledFooter, StyledIcon} from './StyledSecurity'
import {StyledEnforceTwoStep, StyledExpirationForm, StyledExpiredPasswordRow} from './StyledExpirationForm'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

type ExpirationPageFormType = {
    isOkta: boolean
    onSubmit: () => void
    initialValues: Record<string, any>
}

export const ExpirationPageForm = (props: ExpirationPageFormType) => {
    const {t} = useTranslation()
    const {onSubmit, initialValues, isOkta} = props
    const [expirationPageForm] = Form.useForm<ExpirationPageFormType>()
    const userInfo = useAppSelector(userInfoSelector)
    const [expiredPassword, setExpiredPassword] = useState(Boolean(userInfo?.passwordExpiredDays))
    const [expiredPasswordToggle, setExpiredPasswordToggle] = useState(Boolean(userInfo?.passwordExpiredDays))
    const [isDisable, setIsDisable] = useState(true)
    const onSwtichChange = () => {
        setExpiredPassword(!expiredPassword)
        setExpiredPasswordToggle(!expiredPasswordToggle)
    }

    const onValuesChange = () => {
        setIsDisable(false)
    }

    const reset = () => {
        setIsDisable(true)
        expirationPageForm.resetFields()
    }

    return (
        <StyledExpirationForm
            onFinish={onSubmit}
            colon={false}
            initialValues={initialValues}
            form={expirationPageForm}
            onValuesChange={onValuesChange}
        >
            {userInfo?.permissions.accessLevel === ACCESS_LEVEL.FULL && (
                <Row>
                    <Col>
                        <StyledEnforceTwoStep>
                            <Form.Item
                                name={'enforceTwoStepAuthOnAllUsers'}
                                label={t('securityPage:twoFaAuth:checkbox')}
                                colon={false}
                                htmlFor={'enforceTwoStepAuthOnAllUsers'}
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </StyledEnforceTwoStep>

                        {!isOkta && (
                            <Tooltip title={t('securityPage:twoFaAuth:switcherTooltip')}>
                                <StyledIcon component={Info} />
                            </Tooltip>
                        )}
                    </Col>
                </Row>
            )}
            {!userInfo?.enforceSecuritySettings && (
                <StyledExpiredPasswordRow>
                    <Col>
                        <Form.Item
                            name="expiredPassword"
                            label={t('forms:changePasswordExpired:fields:expiredPassword:label')}
                        >
                            <Switch checked={expiredPasswordToggle} onChange={onSwtichChange} />
                        </Form.Item>
                        {expiredPassword && (
                            <Form.Item name="days" label={t('forms:changePasswordExpired:fields:days:label')}>
                                <InputNumber min={1} max={365} />
                            </Form.Item>
                        )}
                    </Col>
                </StyledExpiredPasswordRow>
            )}

            <StyledFooter>
                <Button htmlType="button" onClick={reset} disabled={isDisable}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type={'primary'} htmlType="submit" disabled={isDisable}>
                    {t('forms:common:actions:save')}
                </Button>
            </StyledFooter>
        </StyledExpirationForm>
    )
}
