import styled from 'styled-components'
import {Table} from 'antd'

import variables from 'ca-common/variables'

import {TABLE_HEIGHT} from 'src/newcore/features/OneClickActivation/lib'

export const EntitiesCounters = styled.div`
    background: ${variables.white};
    box-shadow: 0 1px 6px rgba(32, 40, 128, 0.08);
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.gutterXs} ${variables.gutterSm};
    display: flex;
    gap: ${variables.gutterSm};
    width: 60%;
    color: ${variables.navyBlue};
    font-size: ${variables.gutterSm};
    margin-bottom: ${variables.gutterSm};

    @media (max-width: ${variables.screenLg}) {
        width: 100%;
    }
`

export const EntitiesCounter = styled.div`
    display: flex;
    gap: ${variables.gutterXs};

    span:first-child {
        font-weight: 600;
    }
`

export const StyledTable = styled(Table)`
    margin-top: ${variables.gutterMd};
    font-size: ${variables.gutterSm};
    line-height: 20px;

    .ant-table table {
        border-spacing: 0 10px;
    }

    .ant-table-header table {
        border-spacing: 0;
    }

    .ant-table-header,
    .ant-table-body {
        padding-right: 30%;

        @media (max-width: ${variables.screenLg}) {
            padding-right: 10px;
        }
    }

    .ant-table-body {
        margin-top: ${variables.gutterXs};
        height: ${TABLE_HEIGHT};
        max-height: ${TABLE_HEIGHT};

        & > table tr:first-child {
            visibility: collapse;
        }
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        height: 31px;
    }

    .ant-table-cell {
        padding: 5px ${variables.gutterSm} 5px 5px !important;
        color: ${variables.text};
        border-spacing: 0;
        border: none;

        &.ant-table-selection-column {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: ${variables.borderRadiusBase} 0 0 ${variables.borderRadiusBase};

            .ant-checkbox-wrapper {
                height: 16px;

                .ant-checkbox {
                    top: 0;
                    border-radius: 2px;
                    overflow: hidden;
                }
            }
        }
    }

    .ant-table-row {
        line-height: 16px;
        font-size: ${variables.subTitleFontSize};

        &.ant-table-row-selected td {
            background-color: ${variables.interaction5};

            &:last-child {
                border-radius: 0 ${variables.borderRadiusBase} ${variables.borderRadiusBase} 0;
            }
        }
    }

    .ant-table-title,
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
    }
`

export const SearchFieldWrapper = styled.div`
    width: 60%;

    @media (max-width: ${variables.screenLg}) {
        width: 100%;
    }
`
