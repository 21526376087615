import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ButtonWithState} from 'ca-common/ui-lib/atoms'
import {checkPermission} from 'ca-common/utils/permissions'
import type {UserInfoDataType} from 'ca-common/types'
import Restore from 'ca-common/icons/recovery/Restore.svg'
import {StyledActionButton, StyledActionButtonIcon} from './StyledActionButton'
import {FetchedState} from 'ca-common/utils/fetchWrapperRT'

type RestoreButtonProps = ButtonWithState & {
    userInfo: FetchedState<UserInfoDataType>
}

export const RestoreButton = ({userInfo, ...props}: RestoreButtonProps): JSX.Element => {
    const {t} = useTranslation()
    return checkPermission('BACKUP.MS365.RESTORE', userInfo.response?.permissions) ? (
        <StyledActionButton borderWidth={0} icon={<StyledActionButtonIcon component={Restore} />} {...props}>
            {t('recovery:actions:restore')}
        </StyledActionButton>
    ) : (
        <></>
    )
}
