import React, {useState} from 'react'
import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'

import type {Entity, OCAWizardConfig, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'

import {
    useTableData,
    useSearch,
    useFetchEntities,
    useRowSelection,
    useEntitiesSelectionTabInit,
    useEntitiesInfoState,
    DEFAULT_PAGE,
    useWriteEntitiesToWizardDataAndClean
} from 'src/newcore/features/OneClickActivation/lib'
import {getTotalEntitiesByService} from 'src/newcore/features/OneClickActivation/lib/getTotalEntitiesByService'

type UseEntitiesSelectionStep = (params: {
    totalEntities: {source: SOURCE; totalItems: number}[]
    wizardData: OCAWizardData
    wizardConfig: OCAWizardConfig
}) => {
    isLoading: boolean
    rowSelection: {
        onSelectAll: (checked: boolean) => void
        selectedRowKeys: any
        onSelect: ({id, ordinal}: {id: string; ordinal: number}, checked: boolean) => void
    }
    search: string
    fetchMore: () => void
    totalSelectedEntities: number
    service: SOURCE
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    tableData: Entity[]
    onTabChange: (service: SOURCE) => void
}

export const useEntitiesSelectionStep: UseEntitiesSelectionStep = ({wizardData, wizardConfig, totalEntities}) => {
    const [entitiesBitSet, setEntitiesBitSet] = useState('')
    const [service, setService] = useState(
        wizardConfig.defaultOpenedEntitiesSelector || (totalEntities[0]?.source as SOURCE)
    )
    const totalEntitiesBySource = getTotalEntitiesByService({totalEntities, service})
    const {
        enabledEntitiesBitSet,
        updateManyEnabledEntitiesBitSet,
        updateOneEnabledEntitiesBitSet,
        totalSelectedEntities,
        setTotalSelectedEntitiesFromBitSet,
        setEnabledEntitiesBitSet
    } = useEntitiesInfoState({wizardData, service, entitiesBitSet, totalEntities: totalEntitiesBySource})

    const {modifyEntity, modifyAllEntities, tableData, appendToTableData, clearTableData} = useTableData()

    const {cancelFetchEntities, fetchEntities, isLastPage, isLoading} = useFetchEntities({
        appendToTableData,
        enabledEntitiesBitSet,
        clearTableData,
        batchId: wizardData.id,
        setEntitiesBitSet,
        stateId: wizardData.stateId
    })

    const writeEntitiesToWizardDataAndClean = useWriteEntitiesToWizardDataAndClean({
        service,
        wizardData,
        wizardConfig,
        clearTableData
    })

    const {search, onSearchChange, setSearch, cancelOnSearchHandler} = useSearch({
        service,
        fetchEntities,
        enabledEntitiesBitSet,
        cancelFetchEntities
    })

    const rowSelection = useRowSelection({
        modifyEntity,
        modifyAllEntities,
        service,
        tableData,
        updateManyEnabledEntitiesBitSet,
        updateOneEnabledEntitiesBitSet
    })

    useEntitiesSelectionTabInit({
        search,
        service,
        cancelFetchEntities,
        fetchEntities,
        setTotalSelectedEntitiesFromBitSet,
        enabledEntitiesBitSet,
        writeEntitiesToWizardDataAndClean
    })

    const fetchMore = () => {
        if (!isLoading && !isLastPage) {
            fetchEntities({search, service})
        }
    }

    const onTabChange = (service: SOURCE) => {
        const newEnabledEntitiesBitSet = _.cloneDeep(wizardData[service]!.enabledEntitiesBitSet)

        writeEntitiesToWizardDataAndClean({enabledEntitiesBitSet, cancelFetchEntities})

        setService(service)
        setEnabledEntitiesBitSet(newEnabledEntitiesBitSet)
        setSearch('')
        setTotalSelectedEntitiesFromBitSet(newEnabledEntitiesBitSet)
        cancelOnSearchHandler()
        fetchEntities({service, page: DEFAULT_PAGE, entities: newEnabledEntitiesBitSet})
    }

    return {
        isLoading,
        tableData,
        onTabChange,
        rowSelection,
        service,
        onSearchChange,
        search,
        totalSelectedEntities,
        fetchMore
    }
}
