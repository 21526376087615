import React from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {omit, pick, merge} from 'lodash'

import {CAHeader} from 'src/newcore/components/Header'
import {ZendeskChat} from 'src/newcore/components/ZendesChat'
import {CAModal} from 'ca-common/components/Modal'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {updateQueryParams} from 'ca-common/redux/modules/navigation'

import {INTEGRATION_TYPE} from 'ca-common/constants'

import {CASideMenu} from 'ca-common/components/Layout/SideMenu'

import {
    fetchUserInfo,
    signOut,
    setAccountSettings,
    updateUserInfo,
    setCustomS3Credentials,
    clearUserInfo,
    hideBillsState
} from 'src/newcore/redux/modules/account'
import {makeOnlySaveModal, eatModal} from 'ca-common/redux/modules/modal'

import {ArchiveLocationForm} from 'src/newcore/forms/ArchiveLocation'
import {showMobileAlert} from 'ca-common/utils/screen'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {getDataCenters} from 'ca-common/utils/Region'

import {getNextPaymentDate, getPaymentMethod} from 'src/newcore/redux/modules/billing'
import {CAPageNavigation} from '../PageNavigation'
import {StyledContent, StyledContentWrapper, StyledMainLayout} from 'ca-common/components/Layout'
import {getPrivateCompanyInfo} from 'src/newcore/redux/modules/companyInfo'
import {getDownloadUrl} from 'src/newcore/redux/modules/recovery/recoveries'
import {initAnalytics} from 'src/newcore/utils/mixpanel'
import {newUsersNotification} from 'src/newcore/redux/modules/notifications'

import {getPortalSideMenu} from 'src/newcore/molecules/PortalSideMenu'

import './App.scss'

class CAAppRaw extends React.Component {
    componentWillUnmount() {
        this.props.actions.clearUserInfo()
    }

    componentDidMount() {
        const {actions, t} = this.props
        actions.updateQueryParams()

        actions.fetchUserInfo().then(response => {
            const data = response.payload

            initAnalytics(data)

            if (!Object.prototype.hasOwnProperty.call(data, 'region')) {
                actions.makeOnlySaveModal({
                    children: (
                        <ArchiveLocationForm onSubmit={this.onSubmit} storageType={data.undefinedCustomStorage} />
                    ),
                    title: t('modals:archiveLocation:title'),
                    closable: false
                })
            }

            actions.getPrivateCompanyInfo()
            actions.getPaymentMethod()
            actions.getNextPaymentDate()
        })

        showMobileAlert()
    }

    isPrivateCompanyInfoFetched = () => {
        const {companyInfo} = this.props

        return companyInfo.status === 'fulfilled' && companyInfo.response.isPrivateCompanyInfo
        // @TODO don't judge me, it's under the necessity. We have to separarate public and private company info reducers
    }

    isZendeskEnabled = companyInfo => {
        if (companyInfo?.enableZendesk) {
            return true
        }

        return !companyInfo?.whiteLable
    }

    render() {
        const {userInfo, companyInfo, actions, children} = this.props

        return userInfo.status !== 'fulfilled' ||
            companyInfo.status !== 'fulfilled' ||
            !this.isPrivateCompanyInfoFetched() ? (
            <Spinner modifier="page" />
        ) : (
            <StyledMainLayout>
                <CAHeader
                    userInfo={userInfo.response}
                    actions={{signOut: actions.signOut, hideBillsState: actions.hideBillsState}}
                    companyInfo={companyInfo.response}
                />
                <StyledContentWrapper>
                    <CASideMenu
                        menu={getPortalSideMenu({companyInfo: companyInfo.response, userInfo: userInfo?.response})}
                    />
                    <StyledContent>
                        <CAPageNavigation />
                        {children}
                    </StyledContent>
                </StyledContentWrapper>
                <CAModal />
                {this.isZendeskEnabled(companyInfo.response) &&
                    userInfo?.response &&
                    !userInfo?.response?.isMsEndUser && <ZendeskChat />}
            </StyledMainLayout>
        )
    }

    onSubmit = async values => {
        const {actions, userInfo, t} = this.props

        const isZix = userInfo?.response?.integrationType === INTEGRATION_TYPE.ZIX
        const storageValues = omit(values, ['name', 'email'])
        const isCloudallyDataCenter = region => getDataCenters().includes(region)
        const notificationValues = merge(pick(values, ['name', 'email']), {
            summaryReport: true,
            recovery: true,
            exception: true,
            statusReport: true,
            smartAlerts: true,
            isDefaultRecipient: true,
            trialAccountsReport: true
        })

        if (values.region && isCloudallyDataCenter(values.region)) {
            try {
                await actions.setAccountSettings(storageValues).unwrap()

                if (isZix) {
                    await actions.newUsersNotification(notificationValues)
                }

                actions.updateUserInfo(storageValues)
                actions.eatModal()
            } catch (e) {
                openErrorNotification(e.data.status)
            }
        } else {
            try {
                await actions
                    .setCustomS3Credentials({
                        storageType: storageValues.region || userInfo.response.undefinedCustomStorage,
                        ...storageValues
                    })
                    .unwrap()
                if (isZix) {
                    await actions.newUsersNotification(notificationValues)
                }
                actions.updateUserInfo({
                    region: storageValues.s3region
                })
                actions.eatModal()
            } catch (err) {
                actions.eatModal()
                actions.makeOnlySaveModal({
                    children: (
                        <ArchiveLocationForm
                            errorMessage={err.data.status}
                            onSubmit={this.onSubmit}
                            storageType={userInfo.response.undefinedCustomStorage}
                        />
                    ),
                    title: t('modals:archiveLocation:title'),
                    closable: false
                })
            }
        }
    }
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo,
    userInfo: state.userInfo,
    queryParams: state.queryParams
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                fetchUserInfo,
                clearUserInfo,
                getPrivateCompanyInfo,
                signOut,
                getDownloadUrl,
                updateQueryParams,
                setAccountSettings,
                getNextPaymentDate,
                updateUserInfo,
                setCustomS3Credentials,
                getPaymentMethod,
                makeOnlySaveModal,
                eatModal,
                newUsersNotification,
                hideBillsState
            },
            dispatch
        )
    }
})

export const CAApp = connect(mapStateToProps, mapDispatchToProps)(withTranslation('modals')(CAAppRaw))
