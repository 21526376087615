import _ from 'lodash'
import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'

import {CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'

import {
    nextStep,
    changeData,
    closeWizard,
    setWizardDescription,
    setWizardTitle,
    changeProgress
} from 'src/newcore/redux/modules/wizard'
import {CHOOSE_SNAPSHOT_DATE_STEP} from 'src/newcore/components/Seeding/seedingConstants'
import {DataTypeSelection} from './DataTypeSelection'
import {FirstStepModalWrapper} from './StyledFirstStep'

export class SeedingWizardFirstStepRaw extends React.Component {
    componentDidMount() {
        const {t, actions, taskSettings} = this.props

        actions.setWizardTitle(`${taskSettings.alias} ${t('seeding:firstStep:title')}`)
        actions.setWizardDescription(t('seeding:firstStep:description'))
        actions.changeData({dataType: SFC_DATA_TYPE.DATA})
    }

    onNextTabClick = () => {
        const {actions, dataDate, metadataDate, dataType} = this.props
        const date = dataType === SFC_DATA_TYPE.DATA ? dataDate : metadataDate
        actions.changeData({date})
        actions.nextStep()
    }

    changeDataType = dataType => {
        const {actions} = this.props

        actions.changeProgress(CHOOSE_SNAPSHOT_DATE_STEP)
        actions.changeData({
            dataType,
            dataDate: undefined,
            metadataDate: undefined,
            objectType: undefined,
            isEnabledAnonymization: undefined,
            anonymizationSettingsChanges: undefined,
            disableTriggers: undefined,
            destinationType: undefined
        })
    }

    render() {
        const {t, actions, dataDate, metadataDate, dataType, taskSettings} = this.props
        const isValid = dataType === SFC_DATA_TYPE.DATA ? !!dataDate : !!metadataDate

        return (
            <Fragment>
                <FirstStepModalWrapper>
                    <DataTypeSelection
                        selectedDataType={dataType}
                        changeDataType={this.changeDataType}
                        taskSettings={taskSettings}
                    />
                </FirstStepModalWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={actions.closeWizard}>{t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={this.onNextTabClick} disabled={!isValid}>
                            {t('modals:buttons:next')}
                        </Button>
                    </LinkWrapper>
                </ModalFooter>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    dataType: _.get(state, 'wizard.data.dataType'),
    dataDate: _.get(state, 'wizard.data.dataDate'),
    metadataDate: _.get(state, 'wizard.data.metadataDate'),
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            changeData,
            setWizardTitle,
            setWizardDescription,
            changeProgress
        },
        dispatch
    )
})

export const SeedingWizardFirstStep = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(SeedingWizardFirstStepRaw))
