import {SOURCE} from 'ca-common/common/enum/Source'
import {getCompanyInfo} from 'src/newcore/utils/store'
import {Id} from 'ca-common/types'

export const isExternal = () => {
    const companyInfo = getCompanyInfo()

    return companyInfo.status === 'fulfilled' && companyInfo.response.externalUserManagment
}

export const getServiceWhitelist = () => {
    const companyInfo = getCompanyInfo()

    return companyInfo.status === 'fulfilled' ? companyInfo.response.serviceWhitelist : []
}

export const getGapiId = (source: SOURCE): Id => {
    const companyInfo = getCompanyInfo()

    if (companyInfo.status !== 'fulfilled') {
        return ''
    }

    const {googleAppsMarketplaceId, gteamDriveMarketplaceId} = companyInfo.response

    switch (source) {
        case SOURCE.GOOGLEAPPS:
            return googleAppsMarketplaceId
        case SOURCE.GDRIVETEAM:
            return gteamDriveMarketplaceId
        default:
            return ''
    }
}

export const isWhiteLable = () => {
    const companyInfo = getCompanyInfo()

    return companyInfo.status === 'fulfilled' && companyInfo.response.whiteLable
}
