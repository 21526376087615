import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Icon from '@ant-design/icons'
import {Dropdown, Menu, Tooltip} from 'antd'
import {withTranslation, useTranslation} from 'react-i18next'

import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {filterByPermissions} from 'ca-common/utils/backup/backupActions'
import DropDown from 'ca-common/icons/DropDown.svg'
import {ButtonState} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'
import {openErrorModal} from 'ca-common/utils/modals'
import {USER_STATUS} from 'ca-common/constants'

import {ConfirmAction} from './ConfirmAction'
import {StyledBackupTaskActions, DropdownButtonWrapper, TooltipWrapper} from './StyledBackupTaskActions'

const Action = ({action, isFinra}) => {
    if (isFinra && action === TASK_ACTION.DELETE) {
        const {t} = useTranslation()

        return (
            <Tooltip title={t('finra:taskTooltip')}>
                <TooltipWrapper disabled>
                    <BackupTaskAction action={action} />
                </TooltipWrapper>
            </Tooltip>
        )
    }

    return <BackupTaskAction action={action} />
}

export class BackupTaskActionsRaw extends React.Component {
    static propTypes = {
        selectedAll: PropTypes.bool,
        selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        resetRowSelection: PropTypes.func.isRequired
    }

    static defaultProps = {
        selectedAll: false
    }

    state = {
        confirmModalVisible: false,
        actionsPopoverVisible: false
    }

    toggleConfirmVisible = () => {
        this.setState({confirmModalVisible: !this.state.confirmModalVisible})
    }

    setActionsPopoverVisible = value => {
        this.setState({
            actionsPopoverVisible: value
        })
    }

    handleActionClick = action => {
        this.setState({action})
        this.setActionsPopoverVisible(false)
        this.toggleConfirmVisible()
    }

    render() {
        const {t, taskSettings, selectedAll, selectedRowKeys, userInfo, companyName} = this.props
        const isDropdownDisabled = !selectedAll && _.isEmpty(selectedRowKeys)
        const {isFinra, permissions} = userInfo.response

        const menu = (
            <Menu>
                {_(TASK_ACTION)
                    .filter(action => filterByPermissions(action, taskSettings.source, permissions))
                    .filter(action => action !== TASK_ACTION.CANCEL)
                    .map(action => {
                        const onClickHandler =
                            isFinra && action === TASK_ACTION.DELETE
                                ? null
                                : action === TASK_ACTION.RUN
                                ? () => {
                                      if (
                                          this.props.userInfo.response.status === USER_STATUS.TRIAL &&
                                          this.props.userInfo.response.trialDaysLeft <= 0
                                      ) {
                                          openErrorModal(t('backups:unsubscribedErrorMessage', {companyName}))
                                      } else {
                                          this.handleActionClick(action)
                                      }
                                  }
                                : () => this.handleActionClick(action)
                        return (
                            <Menu.Item key={action} onClick={onClickHandler}>
                                <Action action={action} isFinra={isFinra}></Action>
                            </Menu.Item>
                        )
                    })
                    .value()}
            </Menu>
        )

        return (
            <StyledBackupTaskActions>
                <Tooltip
                    placement="top"
                    title={
                        isDropdownDisabled &&
                        t('backups:backupActions:disabledTooltip', {
                            entityName: _.toLower(getSvcAccountsDescription(taskSettings.source))
                        })
                    }
                >
                    <Dropdown
                        overlay={menu}
                        disabled={isDropdownDisabled}
                        placement="bottom"
                        onOpenChange={this.setActionsPopoverVisible}
                        trigger={['click']}
                    >
                        <DropdownButtonWrapper>
                            <ButtonState
                                shape="round"
                                disabled={isDropdownDisabled}
                                active={this.state.actionsPopoverVisible}
                                color={variables.iceBlue}
                            >
                                <span>{t('backups:backupActions:title')}</span>
                                <Icon component={DropDown} />
                            </ButtonState>
                        </DropdownButtonWrapper>
                    </Dropdown>
                </Tooltip>

                <ConfirmAction {...this.state} {...this.props} toggleConfirmVisible={this.toggleConfirmVisible} />
            </StyledBackupTaskActions>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    entities: state.backups.backupEntities.entities.response,
    userInfo: state.userInfo,
    companyName: state.companyInfo.response.name
})

export const BackupTaskActions = connect(mapStateToProps)(withTranslation()(BackupTaskActionsRaw))
