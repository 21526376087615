import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    color: ${variables.orange};
    margin-right: ${variables.gutterSm};
`

export const ButtonStyles = {fontFamily: 'Quicksand', fontWeight: 700}
