import React, {Fragment, useMemo} from 'react'
import {Form} from 'antd'
import _ from 'lodash'
import {useTranslation} from 'react-i18next'

import {DEBOUNCE_TIMEOUT} from 'ca-common/constants'
import {SearchField} from 'ca-common/ui-lib/atoms/SearchField'
import {MultiSelect} from 'ca-common/ui-lib/atoms/MultiSelect'

import {
    FIELD_SEARCH_QUERY,
    FIELD_EVENTS,
    FIELD_TRIGGERED_BY,
    EVENTS_OPTIONS,
    TRIGGERED_BY_OPTIONS,
    getSmartAlertEventFriendly,
    getSmartAlertTriggeredByFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import {SelectedFilters} from 'src/newcore/features/SmartAlerts/molecules'

import {StyledForm, StyledField} from './StyledFilterForm'

const {Item: FormItem} = Form

export const FilterForm = props => {
    const {loading, values, multiActions} = props
    const [form] = Form.useForm()
    const {setFieldsValue} = form

    const {t} = useTranslation()

    const eventsOptions = useMemo(
        () =>
            _.map(EVENTS_OPTIONS, event => ({
                label: getSmartAlertEventFriendly(event),
                value: event
            })),
        []
    )
    const triggeredByOptions = useMemo(
        () =>
            _.map(TRIGGERED_BY_OPTIONS, triggeredBy => ({
                label: getSmartAlertTriggeredByFriendly(triggeredBy),
                value: triggeredBy
            })),
        []
    )
    const selectedFilters = useMemo(() => _.pick(values, [FIELD_TRIGGERED_BY, FIELD_EVENTS]), [values])

    const onChangeFilters = newValues => {
        setFieldsValue(newValues)
        props.changeURLSearchParams(newValues)
    }

    return (
        <Fragment>
            <StyledForm
                layout="inline"
                form={form}
                onValuesChange={_.debounce((changedValues, allValues) => {
                    props.changeURLSearchParams(allValues)
                }, DEBOUNCE_TIMEOUT)}
            >
                <StyledField name={[FIELD_SEARCH_QUERY]}>
                    <SearchField disabled={loading} placeholder={t('smartAlerts:searchPlaceholder')} />
                </StyledField>
                <FormItem name={[FIELD_EVENTS]}>
                    <MultiSelect
                        width={104}
                        popoverWidth={124}
                        disabled={loading}
                        buttonText={t('smartAlerts:eventButtonText')}
                        options={eventsOptions}
                    />
                </FormItem>
                <FormItem name={[FIELD_TRIGGERED_BY]}>
                    <MultiSelect
                        width={152}
                        disabled={loading}
                        buttonText={t('smartAlerts:triggeredByButtonText')}
                        options={triggeredByOptions}
                    />
                </FormItem>
                {multiActions}
            </StyledForm>
            <SelectedFilters loading={loading} values={selectedFilters} setValues={onChangeFilters} />
        </Fragment>
    )
}
