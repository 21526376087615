import React, {useMemo} from 'react'
import {Tooltip} from 'antd'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {MSTaskEntityType} from 'ca-common/types'
import {checkPermission} from 'ca-common/utils/permissions'
import {WIZARD_TYPE, PAGES} from 'ca-common/constants'
import Recovery from 'ca-common/icons/Recovery.svg'
import {openWizardWithConfig, setWizardDescription, changeData} from 'src/newcore/redux/modules/wizard'
import {RecoveryButton} from './StyledRecoveryActionButton'
import {SOURCE} from 'ca-common/common/enum'
import {checkLimitedAccess} from 'ca-common/utils/backup/limitedAccess'
import {LIMITED_ACCESS_PAGES} from 'ca-common/common/enum/Backups'

import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {AppState, useAppDispatch} from 'src/newcore/components/Root'

type RecoveryBtnProps = {
    entityInfo: MSTaskEntityType
    source: SOURCE
} & ReturnType<typeof mapStateToProps>

const RecoveryActionButtonRaw = ({userInfo, entityInfo, source}: RecoveryBtnProps) => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const appDispatch = useAppDispatch()

    const {t} = useTranslation()
    const {taskId} = useParams() as {taskId: string}
    const openRecoveryWizard = () => {
        appDispatch(changeData({entity: entityInfo, taskId, source, from: PAGES.BACKUPS}))
        appDispatch(setWizardDescription(entityInfo.entity))
        appDispatch(
            openWizardWithConfig({
                type: WIZARD_TYPE.RECOVERY,
                config: {autoDismissProgress: true, destroyInactiveTabPane: false}
            })
        )
        sendAnalytics()
    }

    const sendAnalytics = () => {
        track('Advanced Recovery Wizard Opened', {
            Service: getServiceNameMixpanel(source),
            'Initiated From': 'Backup Task Table Button'
        })
    }
    const haveSomePermission = useMemo(() => {
        return (
            checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response.permissions) ||
            checkPermission('BACKUP.MS365.RESTORE', userInfo.response.permissions)
        )
    }, [userInfo.response.permissions])

    if (!haveSomePermission) return <></>

    const limitedAccessValue = checkLimitedAccess(LIMITED_ACCESS_PAGES.EXCHANGE_RECOVERY, entityInfo)
    const tooltipText = limitedAccessValue
        ? t('forms:backupTags:limitedAccess')
        : entityInfo.lastBackupDate
        ? t(`recovery:recovery`)
        : t(`recovery:noBackups`)
    return (
        <Tooltip title={tooltipText}>
            <RecoveryButton
                disabled={!entityInfo.lastBackupDate || limitedAccessValue}
                component={Recovery}
                onClick={entityInfo.lastBackupDate && !limitedAccessValue ? openRecoveryWizard : undefined}
            />
        </Tooltip>
    )
}

const mapStateToProps = ({userInfo}: AppState) => ({
    userInfo
})

export const RecoveryActionButton = connect(mapStateToProps)(RecoveryActionButtonRaw)
