import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, FormInstance} from 'antd'
import {Moment} from 'moment'
import debounce from 'lodash/debounce'

import {Select} from 'ca-common/ui-lib/atoms/Select'
import {RangePicker} from 'ca-common/ui-lib/atoms/RangePicker'
import Button from 'ca-common/ui-lib/components/Button'

import {activityOptions, statusOptions, DEBOUNCE_TIME} from 'src/newcore/features/SecurityAudit/lib/constants'
import {SecurityAuditExport} from 'src/newcore/features/SecurityAudit/SecurityAuditTable/SecurityAuditExport'
import {StyledSecurityAuditTableWrapper} from 'src/newcore/features/SecurityAudit/SecurityAuditTable/StyledSecurityAuditTableWrapper'
import {SecurityAuditFormValues} from 'src/newcore/features/SecurityAudit/types'

type SecurityAuditTableOperationProps = {
    submitForm: (arg0: any) => void
    initialValues: {
        date: Moment
        activity: string
        status: string
    }
    resetForm: () => void
    securityAuditForm: FormInstance<SecurityAuditFormValues>
}

export const SecurityAuditTableOperation = (props: SecurityAuditTableOperationProps) => {
    const {submitForm, resetForm, securityAuditForm, initialValues} = props
    const {t} = useTranslation()

    const handleValuesChange = (changedValues: any, values: SecurityAuditFormValues): void => {
        submitForm(values)
    }

    return (
        <StyledSecurityAuditTableWrapper>
            <Form
                form={securityAuditForm}
                initialValues={initialValues}
                onValuesChange={debounce(handleValuesChange, DEBOUNCE_TIME)}
            >
                <Form.Item name="date" label={'Date'}>
                    <RangePicker />
                </Form.Item>
                <Form.Item name="activity" label={t('forms:common:fields:userActivity:label')}>
                    <Select
                        defaultValue="All"
                        style={{width: 165}}
                        options={activityOptions}
                        allowClear={false}
                        showSearch={false}
                    />
                </Form.Item>
                <Form.Item name="status" label={t('forms:common:fields:activityStatus:label')}>
                    <Select
                        defaultValue="All"
                        style={{width: 120}}
                        options={statusOptions}
                        allowClear={false}
                        showSearch={false}
                    />
                </Form.Item>
                <Button modifier="secondary" type="button" onClick={resetForm}>
                    {t('forms:common:actions:reset')}
                </Button>
            </Form>
            <SecurityAuditExport securityAuditForm={securityAuditForm} />
        </StyledSecurityAuditTableWrapper>
    )
}
