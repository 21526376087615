import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

export const StyledSelectedFilters = styled.div<{disabled: boolean}>`
    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;

            > * {
                pointer-events: none;
            }

            :hover {
                cursor: not-allowed;
            }
        `}
`

export const StyledIcon = styled(Icon)`
    font-size: 22px;
    margin: 1px 8px 0 -4px;
`
