import {combineReducers} from '@reduxjs/toolkit'

import {calendar} from './getCalendar'
import {tasks} from './getTasks'
import {taskInfo} from './getTaskInfo'
import {settings} from './getTaskSettings'
import {entities} from './getEntities'
import {subsourcesInfo} from './getSubsourcesInfo'

export const recoveryNew = combineReducers({
    taskInfo,
    tasks,
    settings,
    calendar,
    entities,
    subsourcesInfo
})
