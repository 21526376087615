import Disabled from 'ca-common/icons/old/uEA40-fa-authentication-disabled.svg'
import Enabled from 'ca-common/icons/old/uEA41-fa-authentication-enabled.svg'
import Unknown from 'ca-common/icons/old/uEA42-fa-authentication-unknown.svg'

export const TWO_FA_STATUS = {
    TWO_FA_DISABLED: {
        status: '2FA disabled',
        icon: Disabled
    },
    TWO_FA_ENABLED: {
        status: '2FA enabled',
        icon: Enabled
    },
    TWO_FA_UNKNOWN: {
        status: '2FA unknown',
        icon: Unknown
    }
}
