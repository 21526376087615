import styled from 'styled-components'
import variables from 'ca-common/variables'

export const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${variables.gutterSm};
`
export const ToolbarWithBorder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${variables.navyBorder};
    padding-bottom: ${variables.gutterSm};
    margin-bottom: ${variables.gutterSm};
    gap: ${variables.gutterSm};
`

export const StyledEmail = styled.span`
    color: ${variables.navyBlue};
`
