import {LIMITED_ACCESS_PAGES} from 'ca-common/common/enum/Backups'

export const checkLimitedAccess = (page: string, data: Record<string, any>) => {
    switch (page) {
        case LIMITED_ACCESS_PAGES.TAG_DROPDOWN:
            if (data.limitedAccess) {
                return data.denyDeleteAccessTag
            } else {
                return data.denySetAccessTag
            }
        case LIMITED_ACCESS_PAGES.JOB_LIST:
        case LIMITED_ACCESS_PAGES.EXCHANGE_RECOVERY:
            return data.accessDenied
    }
}
