import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;

    .ant-radio-group {
        margin: -${variables.gutterXs};
    }
`

export const StyledForm = styled(Form)`
    margin: -${variables.gutterMd};
`

export const StyledWrapper = styled.div`
    margin-top: ${variables.gutterMd};
    padding: 0 ${variables.gutterMd} ${variables.gutterMd};
    border-bottom: 1px solid ${variables.navyBorder};
`

export const SlyledFoorter = styled.div`
    margin: ${variables.gutterSm} ${variables.gutterMd};
    display: flex;
    justify-content: flex-end;

    > * + * {
        margin-left: ${variables.gutterSm};
    }
`
