import React from 'react'

import {SOURCE} from 'ca-common/common/enum'

import {BackupTaskSettingsTooltip} from 'src/newcore/features/OneClickActivation/atoms'

export const ENTITIES_PER_PAGE = 100
export const TABLE_HEIGHT = '350px'
export const DEFAULT_PAGE = 1
export const WAITING_DURATION = 30

export enum ENTITIES_OPTION {
    ACTIVATE_ALL_ENTITIES = 'ACTIVATE_ALL_ENTITIES',
    ACTIVATE_SELECTED_ENTITIES = 'ACTIVATE_SELECTED_ENTITIES'
}

export enum SETTINGS_OPTION {
    USE_DEFAULT_SETTINGS = 'USE_DEFAULT_SETTINGS',
    USE_CUSTOM_SETTINGS = 'USE_CUSTOM_SETTINGS'
}

export enum FIELDS {
    ENTITIES_OPTION = 'entitiesOption',
    SETTINGS_OPTION = 'settingsOption',
    BACKUP_NAME = 'backupName'
}

export const ENTITIES_RADIO_OPTIONS = [
    {value: ENTITIES_OPTION.ACTIVATE_ALL_ENTITIES},
    {
        value: ENTITIES_OPTION.ACTIVATE_SELECTED_ENTITIES
    }
]

export const SETTINGS_RADIO_OPTIONS = [
    {value: SETTINGS_OPTION.USE_DEFAULT_SETTINGS, tooltip: <BackupTaskSettingsTooltip />},
    {value: SETTINGS_OPTION.USE_CUSTOM_SETTINGS}
]

export const OCA_SERVICES = [SOURCE.MS365, SOURCE.MS365GROUPS, SOURCE.SHAREPOINT_MULTI, SOURCE.ONEDRIVE]

export enum STEPS {
    INIT_PROCESSING = '0',
    TASKS_ALREADY_EXIST = '1',
    RECENTLY_DELETED_TASKS = '2',
    BACKUP_SETTINGS = '3',
    PROCESSING = '4',
    ENTITIES_SELECTION = '5',
    SETTINGS_SELECTION = '6',
    CONFIRMATION = '7'
}

export const DEFAULT_WIZARD_CONFIG = {
    hiddenTabBarItems: [
        STEPS.INIT_PROCESSING,
        STEPS.TASKS_ALREADY_EXIST,
        STEPS.RECENTLY_DELETED_TASKS,
        STEPS.PROCESSING
    ],
    stepsWithHiddenTabBar: [
        STEPS.INIT_PROCESSING,
        STEPS.TASKS_ALREADY_EXIST,
        STEPS.RECENTLY_DELETED_TASKS,
        STEPS.PROCESSING
    ],
    disabledSteps: []
}

export const EXTRA_DISABLED_STEPS_FOR_FULLY_REINSTATE = [
    STEPS.ENTITIES_SELECTION,
    STEPS.SETTINGS_SELECTION,
    STEPS.BACKUP_SETTINGS
]
