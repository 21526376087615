import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import _ from 'lodash'
import {bindActionCreators} from '@reduxjs/toolkit'

import {InfinityContainer, StyledCheckboxGroup} from './StyledFieldValues'
import {NoData} from './NoData'

import {
    appendAccountPropertyValues,
    setCheckedAccountPropertyValues
} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'

export const DEFAULT_PAGE = 1
export const ROWS_PROPERTY_VALUES = 20

class FieldValuesRaw extends Component {
    handleScroll = () => {
        const {accountPropertyValues, taskSettings, accountPropertyName, actions} = this.props

        if (
            accountPropertyValues.status === 'fulfilled' &&
            accountPropertyValues.page < accountPropertyValues.totalPages
        ) {
            actions.appendAccountPropertyValues({
                page: accountPropertyValues.page + 1,
                rows: ROWS_PROPERTY_VALUES,
                taskId: taskSettings.taskId,
                property: accountPropertyName,
                cacheToken: accountPropertyValues.cacheToken
            })
        }
    }

    handleCheckboxGroup = checkedList => {
        const {actions} = this.props

        actions.setCheckedAccountPropertyValues(checkedList)
    }

    render() {
        const {accountPropertyValues, checkedAccountPropertyValues} = this.props

        return (
            <Spin spinning={accountPropertyValues.status === 'pending'}>
                <InfinityContainer data-hj-suppress>
                    {accountPropertyValues.status === 'fulfilled' &&
                        (_.get(accountPropertyValues, 'data', []).length > 0 ? (
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={DEFAULT_PAGE}
                                useWindow={false}
                                hasMore={accountPropertyValues.page < accountPropertyValues.totalPages}
                                loadMore={this.handleScroll}
                            >
                                <StyledCheckboxGroup
                                    options={_.get(accountPropertyValues, 'data', [])}
                                    onChange={this.handleCheckboxGroup}
                                    value={checkedAccountPropertyValues}
                                />
                            </InfiniteScroll>
                        ) : (
                            <NoData />
                        ))}
                </InfinityContainer>
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    const {
        accountPropertyValues,
        accountPropertyName,
        checkedAccountPropertyValues
    } = state.backups.bulkActivation.activeDirectory

    return {
        accountPropertyValues: {
            status: accountPropertyValues.status,
            ...accountPropertyValues.response
        },
        taskSettings: state.backups.settings.response,
        accountPropertyName,
        checkedAccountPropertyValues: checkedAccountPropertyValues || []
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            appendAccountPropertyValues,
            setCheckedAccountPropertyValues
        },
        dispatch
    )
})

export const FieldValues = connect(mapStateToProps, mapDispatchToProps)(FieldValuesRaw)
