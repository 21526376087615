import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const TitleIcon = styled(Icon)`
    width: 40px;
    height: 40px;
    font-size: 40px;
    margin-right: ${variables.gutterSm};
    svg {
        fill: none;
    }
`
