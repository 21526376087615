import styled from 'styled-components'
import variables from 'ca-common/variables'
import {ContainerType} from 'ca-common/constants'
import Arrow from './Arrow.svg'
import Cloud from './Cloud.svg'

export const PublicLayoutWrapper = styled.div<{type?: ContainerType}>`
    background: ${variables.white};
    min-height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    font-family: ${variables.mainFont};

    @media screen and (min-width: ${variables.screenMd}) and (min-height: ${variables.screenMd}) {
        background-image: url(${props => (props.type === ContainerType.ARROW ? Arrow : Cloud)});
        background-repeat: no-repeat;
        background-position: ${props => (props.type === ContainerType.ARROW ? 'left 90%' : 'right 80%')};
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        background-position: ${props => (props.type === ContainerType.ARROW ? 'left 25%' : 'right 75%')};
    }
`

export const PublicContainer = styled.div`
    padding-top: 80px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: ${variables.screenSm}) {
        padding-top: 96px;
    }
`
