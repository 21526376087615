import {useCallback, useMemo} from 'react'

import {SOURCE} from 'ca-common/common/enum'

import type {Entity} from 'src/newcore/features/OneClickActivation/types'
import {useEntitiesInfoState} from 'src/newcore/features/OneClickActivation/lib'

type UseRowSelection = (
    params: {
        tableData: Entity[]
        service: SOURCE
        modifyEntity: ({id, checked}: {service: SOURCE; id: string; checked: boolean}) => void
        modifyAllEntities: ({checked}: {checked: boolean}) => void
    } & Pick<
        ReturnType<typeof useEntitiesInfoState>,
        'updateManyEnabledEntitiesBitSet' | 'updateOneEnabledEntitiesBitSet'
    >
) => {
    onSelectAll: (checked: boolean) => void
    selectedRowKeys: any
    onSelect: ({id, ordinal}: {id: string; ordinal: number}, checked: boolean) => void
}

export const useRowSelection: UseRowSelection = ({
    tableData,
    service,
    modifyAllEntities,
    modifyEntity,
    updateOneEnabledEntitiesBitSet,
    updateManyEnabledEntitiesBitSet
}) => {
    const onSelect = useCallback(
        ({id, ordinal}: {id: string; ordinal: number}, checked: boolean) => {
            modifyEntity({id, checked, service})
            updateOneEnabledEntitiesBitSet(ordinal, checked)
        },
        [service, tableData]
    )

    const onSelectAll = (checked: boolean) => {
        modifyAllEntities({checked: checked})
        updateManyEnabledEntitiesBitSet(checked)
    }

    const selectedRowKeys = useMemo(
        () => (tableData || []).reduce((acc, {id, checked}) => (checked ? acc.concat(id) : acc), [] as string[]),
        [tableData]
    )

    return useMemo(
        () => ({
            selectedRowKeys,
            onSelect,
            onSelectAll
        }),
        [selectedRowKeys, onSelect, onSelectAll]
    )
}
