import styled from 'styled-components'
import {Radio} from 'antd'

import variables from 'ca-common/variables'

export const RadioGroupWrapper = styled(Radio.Group)`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterXs};
    font-weight: 600;
`

export const FieldTitle = styled.div`
    font-weight: 600;
    font-size: ${variables.subTitleFontSize};
    color: ${variables.text};
`
