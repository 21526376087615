import React from 'react'
import {Modal, ModalProps} from 'antd'

export const BackupUnlicensedAccountsModal = (props: ModalProps) => {
    const {children, ...rest} = props

    return (
        <Modal {...rest} width={418} closable={false} footer={null} bodyStyle={{padding: 0}}>
            {children}
        </Modal>
    )
}
