import React from 'react'
import {CAIssueDate, CADueDate, CAReferenceNumber} from './CellModifiers/Content'

export const getHistoryConfig = subscriptionType => {
    return {
        table: {
            limitKey: 'limit'
        },
        columns: [
            {
                name: 'documentNumber',
                modifier: 'first 300px',
                contentCell: props => <CAReferenceNumber {...props} subscriptionType={subscriptionType} />
            },
            {name: 'issueDate', contentCell: CAIssueDate, modifier: '300px'},
            {name: 'dueDate', contentCell: CADueDate, modifier: '300px'},
            {name: 'amount', modifier: '300px'}
        ]
    }
}

export const PAGE_SIZE = 5
