import React from 'react'
import {useTranslation} from 'react-i18next'

import {StyledSubtitle} from './StyledSubtitle'

export const Subtitle = () => {
    const {t} = useTranslation()

    return <StyledSubtitle>{t('homepage:whatWouldYouLikeToRecover')}</StyledSubtitle>
}
