import React from 'react'
import {Trans} from 'react-i18next'
import {Tooltip} from 'antd'

import {TASK_STATUS, JOBS_STATUSES} from 'ca-common/common/enum'

import {Status} from './StyledDeepTaskStatus'

type DeepTaskStatusProps = {
    status: TASK_STATUS | JOBS_STATUSES
    statusText: string
}

export const DeepTaskStatus = ({status, statusText}: DeepTaskStatusProps): JSX.Element => {
    return (
        <Tooltip title={status === JOBS_STATUSES.PENDING ? <Trans i18nKey="JobsPage:jobPending" /> : undefined}>
            <Status status={status}>{statusText}</Status>
        </Tooltip>
    )
}
