import React from 'react'

import Restore from 'ca-common/icons/recoveryWizard/restore.svg'
import Export from 'ca-common/icons/recoveryWizard/export.svg'
import MSIcon from 'ca-common/icons/source/MS365.svg'

import {TitleIcon} from './StyledWizardTitleIcon'

export enum WIZARD_ICON {
    RESTORE = 'RESTORE',
    EXPORT = 'EXPORT',
    ONE_CLICK_ACTIVATION = 'ONE_CLICK_ACTIVATION'
}

type WizardTitleIconProps = {
    icon: WIZARD_ICON
}

function setIcon(icon: WIZARD_ICON) {
    switch (icon) {
        case WIZARD_ICON.RESTORE:
            return Restore
        case WIZARD_ICON.EXPORT:
            return Export
        case WIZARD_ICON.ONE_CLICK_ACTIVATION:
            return MSIcon
    }
}

export const WizardTitleIcon = ({icon}: WizardTitleIconProps): JSX.Element => {
    return <TitleIcon component={setIcon(icon)} />
}
