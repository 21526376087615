import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import queryString from 'query-string'

import {APP_DEFAULT_PAGE} from 'ca-common/constants'

import {validatePageAccess} from 'ca-common/utils/permissions'
import {NotFound} from 'ca-common/components/NotFound'

import {getPortalSideMenu} from 'src/newcore/molecules/PortalSideMenu'

const mapStateToProps = state => ({
    userInfo: state.userInfo.response,
    companyInfo: state.companyInfo.response
})

const getNextPage = appMenuConfig => {
    return appMenuConfig.find(i => i.name)?.name
}

export const getOtherPermittedPage = (userInfo, companyInfo) => {
    return validatePageAccess({page: APP_DEFAULT_PAGE, userInfo, companyInfo})
        ? APP_DEFAULT_PAGE
        : getNextPage(getPortalSideMenu({companyInfo, userInfo}))
}

export const PermissionRoute = connect(mapStateToProps)(({checkPage, userInfo, companyInfo, ...params}) => {
    const {isForSubscribe} = queryString.parse(params.location.search)

    if (validatePageAccess({page: checkPage, userInfo, companyInfo, isForSubscribeLink: isForSubscribe})) {
        return <Route {...params} />
    }

    const otherPermittedPage = getOtherPermittedPage(userInfo, companyInfo)

    return otherPermittedPage ? (
        <Redirect to={`/${otherPermittedPage}?msgType=custom&modalName=AccessDenied`} />
    ) : (
        <Route component={NotFound} />
    )
})

PermissionRoute.propTypes = {
    checkPage: PropTypes.string.isRequired
}
