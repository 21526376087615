import {utf8ToB64} from 'ca-common/utils/text'
import _ from 'lodash'
import {TODO_ANY} from '../types'

import type {FieldData, FieldError} from 'rc-field-form/lib/interface'

export const isEmpty = (value: string | number | undefined): boolean => {
    return value === '' || value === undefined || value === null
}

export const isEmptyOrOnlyWhitespaces = (value: string | number): boolean => {
    if (_.isString(value)) {
        value = _.trim(value)
    }
    return _.isEmpty(value)
}

export const isQueryOnRange = (value: TODO_ANY, range: number): boolean => {
    return value.length === 0 || !(value.length < range)
}

export const makeFormFieldRequest = (values: Record<string, any>): Record<string, any> => {
    return _.map(values, (v, k) => ({id: k, val: k === 'password' ? utf8ToB64(v) : v}))
}

export const EMPTY_ERROR_MESSAGE = ' '

export const hasErrors = (fieldsError: Record<string, string[] | undefined>): boolean => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
}

export const hasEmptyErrors = (fieldsError: Record<string, string[] | undefined>): boolean => {
    return Object.keys(fieldsError).some(
        field => fieldsError[field] && fieldsError[field]?.some(i => i === EMPTY_ERROR_MESSAGE)
    )
}

export const hasEmptyErrorsAntd4 = (allFields: FieldData[]): boolean => {
    return allFields.some(i => i?.touched && i?.errors?.some(j => j === EMPTY_ERROR_MESSAGE))
}

export const hasErrorsAntd4 = (allFields: FieldData[]): boolean => {
    return allFields.some(i => i?.touched && i?.errors && i?.errors?.length > 0)
}

export const hasSomeErrorsAntd4 = (errors: FieldError[]): boolean => {
    return errors.some(i => i?.errors && i?.errors?.length > 0)
}

export const createRules = (validators: ((value: any) => string | undefined)[]) => {
    return [
        () => ({
            validator: (_: any, value: any) => {
                let error: string | undefined

                for (const validator of validators) {
                    error = validator(value)

                    if (error) {
                        return Promise.reject(error)
                    }
                }
                return Promise.resolve()
            }
        })
    ]
}

export const COUNTRIES_REQUIRED_TAX_ID = ['IL']
