import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Text = styled.span`
    font-size: ${variables.fontSizeSecondary};
    font-weight: 300;
`

export const Title = styled(Text)`
    font-weight: 400;
`
