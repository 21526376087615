import {useEffect, useRef} from 'react'

type SavedCallback = () => void

export const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef<SavedCallback | null>(null)

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current && savedCallback.current()
        }
        if (delay) {
            const id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}
