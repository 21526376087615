import React from 'react'
import {Trans} from 'react-i18next'
import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'
import Info from 'ca-common/icons/Info.svg'
export type SummaryReportType = {
    reportFormat: string
    provideType: string
    reportFreq: number
    reportDay: number
    failureOnlyReport: boolean
    summaryReport: boolean
    reportType: string
}
export const selectDayOptions = [
    {value: 2, label: <Trans i18nKey="notifications:dayOptions:Monday" />},
    {value: 3, label: <Trans i18nKey="notifications:dayOptions:Tuesday" />},
    {value: 4, label: <Trans i18nKey="notifications:dayOptions:Wednesday" />},
    {value: 5, label: <Trans i18nKey="notifications:dayOptions:Thursday" />},
    {value: 6, label: <Trans i18nKey="notifications:dayOptions:Friday" />},
    {value: 7, label: <Trans i18nKey="notifications:dayOptions:Saturday" />},
    {value: 1, label: <Trans i18nKey="notifications:dayOptions:Sunday" />}
]
export const provideTypes = [
    {value: 'CSV', label: <Trans i18nKey="notifications:provideTypes:CSVAttachment" />},
    {value: 'LINK', label: <Trans i18nKey="notifications:provideTypes:DownloadLink" />}
]

export const emailReportOptions = [
    {value: 2, label: <Trans i18nKey="notifications:emailReport:OnceAMonth" />},
    {value: 4, label: <Trans i18nKey="notifications:emailReport:OnceAWeek" />},
    {value: 5, label: <Trans i18nKey="notifications:emailReport:OnceADay" />}
]
export const taskLevelPreviewLink = 'https://public-content-resources.s3.amazonaws.com/assets/mail-task-level.png'
export const entityLevelPreviewLink = 'https://public-content-resources.s3.amazonaws.com/assets/mail-entity-level.png'
export const hasDayOptions = (reportFreq: number) => [4].includes(reportFreq)
export const renderDayOptions = (value: number) => {
    switch (value) {
        case 4:
            return selectDayOptions.map(dayOptions => ({value: dayOptions.value, label: dayOptions.label}))
        default:
    }
}
export const reportFormats = [
    {
        value: 'TASK_LEVEL',
        label: (
            <>
                <Trans i18nKey="notifications:taskLevelReportFormatLabel" />
                <Tooltip title={<Trans i18nKey="notifications:taskLevelReportFormatTooltip" />}>
                    <Icon component={Info} />
                </Tooltip>
            </>
        )
    },
    {
        value: 'ENTITY_LEVEL',
        label: (
            <>
                <Trans i18nKey="notifications:entityLevelReportFormatLabel" />
                <Tooltip title={<Trans i18nKey="notifications:entityLevelReportFormatTooltip" />}>
                    <Icon component={Info} />
                </Tooltip>
            </>
        )
    }
]
export const reportTypes = [
    {value: 'CONSOLIDATED_REPORT', label: <Trans i18nKey="notifications:reportTypes:consolidatedReport" />},
    {value: 'INDIVIDUAL_REPORT', label: <Trans i18nKey="notifications:reportTypes:individualReport" />}
]
