import React from 'react'
import {useTranslation} from 'react-i18next'

import NoJobs from 'ca-common/icons/jobs/NoJobs.svg'

import {IconRoundWrapper, NoJobsText, StyledNoJobsFound, StyledNoJobsIcon} from './StyledNoJobsFound'

export const NoJobsFound = (): JSX.Element => {
    const {t} = useTranslation()

    return (
        <StyledNoJobsFound>
            <IconRoundWrapper>
                <StyledNoJobsIcon component={NoJobs} />
            </IconRoundWrapper>
            <NoJobsText>{t('JobsPage:noJobsFound')}</NoJobsText>
        </StyledNoJobsFound>
    )
}
