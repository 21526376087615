import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {AuditActivityValues, SYSTEM_AUDIT_ACTIVITY, ActivityRecord} from 'ca-common/features/ActivityInfinityTable'
import {StyledIcon} from 'src/newcore/atoms/StyledDownloadButton'
import DownloadIcon from 'ca-common/icons/Download.svg'
import ComparisionResults from 'ca-common/icons/ComparisionResults.min.svg'

type DownloadProps = {
    link: string
    record: ActivityRecord
}

const iconComponent = (activity: AuditActivityValues) => {
    switch (activity) {
        case SYSTEM_AUDIT_ACTIVITY.COMPARE:
            return ComparisionResults

        default:
            return DownloadIcon
    }
}

export const DownloadButton = ({link, record}: DownloadProps): JSX.Element => {
    const {t} = useTranslation()
    const {isExpired, downloadPermitted, activity} = record
    const getTooltipTitle = () => {
        if (!downloadPermitted) return t('activity:downloadTooltip:notPermitted')
        if (isExpired) {
            if (activity === SYSTEM_AUDIT_ACTIVITY.COMPARE) return t('activity:downloadTooltip:compareExpired')
            return t('activity:downloadTooltip:expired')
        }
        if (activity === SYSTEM_AUDIT_ACTIVITY.RESTORE) return t('activity:downloadTooltip:report')
        if (activity === SYSTEM_AUDIT_ACTIVITY.COMPARE) return t('activity:downloadTooltip:compare')
        return t('activity:downloadTooltip:download')
    }

    return (
        <Tooltip arrowPointAtCenter placement="topRight" title={getTooltipTitle()}>
            <a role="button" aria-disabled={!link} href={link} target="_blank" title={getTooltipTitle()}>
                <StyledIcon fade={isExpired || !downloadPermitted} component={iconComponent(activity)} />
            </a>
        </Tooltip>
    )
}
