import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledCompareList = styled.div`
    width: 100%;
`

export const Title = styled.h2`
    font-size: 18px;
`

export const Description = styled.div`
    font-size: 18px;
    font-weight: 300;
    color: ${variables.asphalt};
`

export const LeftHeader = styled.div`
    display: flex;
`

export const HeaderRow = styled.div`
    margin: 0 0 ${variables.gutterSm};
    display: flex;
    justify-content: space-between;
    align-items: center;
`
