import React, {useState} from 'react'
import {Tooltip} from 'antd'
import {DefaultOptionType} from 'antd/lib/select'

import {SelectProps, Select, Option} from 'ca-common/ui-lib/atoms/Select'
import {MaxTagPlaceholder, OptionsWrapper} from './StyledMultiSelect'

export type MultiSelectProps = Omit<
    SelectProps,
    'mode' | 'maxTagCount' | 'dropdownRender' | 'maxTagPlaceholder' | 'children' | 'options'
> & {
    buttonText: string
    showTooltip?: boolean
    value?: string[]
    disabled?: boolean
    width?: number
    popoverWidth?: number
    options?: (DefaultOptionType | string)[]
}

export const MultiSelect = React.forwardRef((props: MultiSelectProps, ref) => {
    const {options = [], buttonText, width, showTooltip, popoverWidth, ...restProps} = props
    const [isInverted, setIsInverted] = useState(false)
    const dropdownMatchSelectWidth = typeof popoverWidth === 'number' ? popoverWidth : false

    const preparedOptions = (options.length > 0 && typeof options[0] === 'string'
        ? options.map((option: any) => ({
              value: option,
              label: option
          }))
        : options) as DefaultOptionType[]

    const [updateSearch, setUpdateSearch] = useState('')

    return (
        <Select
            onSearch={value => setUpdateSearch(value)}
            showArrow
            searchValue={updateSearch}
            optionFilterProp="title"
            defaultActiveFirstOption={false}
            allowClear={false}
            showSearch={false}
            mode="multiple"
            placeholderWeight="bold"
            maxTagCount={0}
            selectWidth={width}
            placeholder={buttonText}
            filterOption={(inputValue: string, option?: DefaultOptionType) =>
                (option?.value as string).toLowerCase().includes(inputValue.toLowerCase())
            }
            dropdownRender={(menu: any) => <OptionsWrapper>{menu}</OptionsWrapper>}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            isInverted={isInverted}
            maxTagPlaceholder={(values: any[]) => {
                setIsInverted(Array.isArray(values) && !!values.length)
                return (
                    <MaxTagPlaceholder>
                        {props.showSearch ? (
                            <>
                                <span>{props.buttonText}</span>
                                <span>({values.length})</span>
                            </>
                        ) : (
                            `${props.buttonText} (${values.length})`
                        )}
                    </MaxTagPlaceholder>
                )
            }}
            {...ref}
            {...restProps}
        >
            {preparedOptions?.map(option => (
                <Option key={String(option.value)} value={option.value}>
                    {showTooltip ? <Tooltip title={option.label}>{option.label}</Tooltip> : <span>{option.label}</span>}
                </Option>
            ))}
        </Select>
    )
})
