import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'

import Add from 'ca-common/icons/backupAction/Add.svg'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {openSuccessNotification} from 'ca-common/utils/toasts'

import {addEntity} from 'src/newcore/redux/modules/backups'
import {BackupAddUserModal} from 'src/newcore/components/Modals/BackupAddUserModal'
import {BackupAddUserForm} from 'src/newcore/forms/BackupAddUser'

import {StyledAddEntity} from './StyledAddEntity'
import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'
import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms/Button'

class AddEntityRaw extends React.Component {
    state = {
        addEntityFormVisible: false
    }

    toggleVisible = () => this.setState({addEntityFormVisible: !this.state.addEntityFormVisible})

    addEntitySubmit = (values, form) => {
        const {
            t,
            actions,
            taskSettings: {source, taskId}
        } = this.props

        this.setState({
            error: null,
            loading: true
        })
        actions
            .addEntity({
                ...values,
                source,
                taskId
            })
            .unwrap()
            .then(() => {
                this.setState({loading: false})
                this.toggleVisible()
                openSuccessNotification(
                    t('forms:addEntity:response:added', [
                        t(`serviceNames:${source}`),
                        getSvcAccountsDescription(source, true).toLowerCase()
                    ])
                )
            })
            .catch(error => {
                if (error.status) {
                    this.setState({error: error.status, loading: false})
                }
                form.setFields([
                    {
                        name: 'entity',
                        value: values.entity,
                        errors: ['']
                    }
                ])
            })
    }

    render() {
        const {taskSettings, t} = this.props
        const {addEntityFormVisible, error, loading} = this.state

        return (
            <StyledAddEntity>
                <Tooltip
                    title={t('addEntity:tooltip', {
                        entity: getSvcAccountsDescription(taskSettings.source).toLowerCase()
                    })}
                >
                    <ButtonHoverReverse shape="circle" onClick={this.toggleVisible}>
                        <Icon component={Add} />
                    </ButtonHoverReverse>
                </Tooltip>
                <BackupAddUserModal open={addEntityFormVisible} onCancel={this.toggleVisible}>
                    <BackupAddUserForm
                        source={taskSettings.source}
                        onCancel={this.toggleVisible}
                        onSubmit={this.addEntitySubmit}
                        error={error}
                        loading={loading}
                    />
                </BackupAddUserModal>
            </StyledAddEntity>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            addEntity
        },
        dispatch
    )
})

export const AddEntity = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddEntityRaw))
