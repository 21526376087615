import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {Upload} from 'antd'

import Failed from 'ca-common/icons/backupStatus/Failed.svg'
import {Button} from 'ca-common/ui-lib/atoms/Button'

import {StyledFailedIcon, StyledFailedText, UploadFailedText, UploadFailedFiles} from './StyledUploadCSV'

export const UploadCSVFailed = ({fileList, draggerProps, invalidFileSize, errorMsg}) => {
    const {t} = useTranslation()

    return (
        <Fragment>
            <StyledFailedIcon component={Failed} />
            <StyledFailedText>{t('backups:uploadCSV:failedToProcess')}</StyledFailedText>
            <UploadFailedFiles>
                {fileList.map((i, index) => (
                    <span key={index}>{i.name}</span>
                ))}
            </UploadFailedFiles>
            <UploadFailedText>
                {invalidFileSize ? <span>{t('backups:uploadCSV:fileSizeInvalid')}</span> : <span>{errorMsg}</span>}
            </UploadFailedText>
            <Upload {...draggerProps}>
                <Button type="primary">{t('backups:uploadCSV:tryAnotherButton')}</Button>
            </Upload>
        </Fragment>
    )
}
