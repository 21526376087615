import {postLessData} from 'src/newcore/utils/rest'

import type {GetEntitiesPayload, GetEntitiesResponse} from 'src/newcore/features/OneClickActivation/types'
import {ENTITIES_PER_PAGE, getBinaryFromHex} from 'src/newcore/features/OneClickActivation/lib'

const GET_ENTITIES_API = 'getMSEntities'

export const fetchEntities = async (data: GetEntitiesPayload) => {
    const {search, page, service, requestSource, batchId, stateId} = data

    const response = await postLessData<GetEntitiesResponse>(
        GET_ENTITIES_API,
        {page, search, service, rows: ENTITIES_PER_PAGE, batchId, stateId},
        {cancelToken: requestSource.token}
    )

    const entitiesBitSet = response.entitiesBitSet
        ? getBinaryFromHex(response.entitiesBitSet).padStart(response.total, '0')
        : ''.padStart(response.total, '1')

    return {...response, entitiesBitSet}
}
