import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {white, blueLight, borderRadiusBase, screenLg, gutterXs, gutterSm, gutterMd} from 'ca-common/variables'

export const StyledContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    padding: ${gutterXs} ${gutterMd};
    margin-bottom: 20px;
    border-radius: ${borderRadiusBase};
    background-color: ${white};
    overflow-x: auto;

    > * {
        flex-shrink: 0;
    }
`

export const StyledIcon = styled(Icon)`
    width: 52px;
    height: 52px;
    padding: 10px;
    margin-right: ${gutterSm};
    border-radius: 50%;
    background-color: ${blueLight};
    display: none;

    svg {
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width: ${screenLg}) {
        display: block;
    }
`
