import React from 'react'
import {StyledTag, TagName} from './StyledTag'

export const Tag = ({tag}: {tag: string}): JSX.Element => {
    return (
        <StyledTag>
            <TagName>{tag}</TagName>
        </StyledTag>
    )
}
