import React from 'react'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum'
import {SourceIcon} from 'ca-common/components/SourceIcon'

import {
    StyledHomeServiceButton,
    StyledHomeServiceContent,
    StyledHomeServiceName
} from 'src/newcore/features/NewHomePage/NewHomeServiceButton/StyledNewHomeServiceButton'

export const NewHomeServiceButton = ({service, onClick}: {service: SOURCE; onClick: any}) => {
    const {t} = useTranslation()
    return (
        <StyledHomeServiceButton key={service}>
            <StyledHomeServiceContent onClick={() => onClick(service)}>
                <SourceIcon source={service} fontSize={60} />
            </StyledHomeServiceContent>
            <StyledHomeServiceName>
                {t(`homepage:newHomePage:${service}:displayTitle`) || t(`homepage:newHomePage:${service}:altText`)}
            </StyledHomeServiceName>
        </StyledHomeServiceButton>
    )
}
