import {parseAddressesToFE} from 'ca-common/features/IpRestriction/lib/addressesParse'
import {RESTRICTION_RULES} from 'ca-common/features/IpRestriction/lib/constants'
import type {AddIpFormFields, RestrictionEntry} from 'ca-common/features/IpRestriction/types'

export const isFieldsChanged = (record: RestrictionEntry, values: AddIpFormFields): boolean => {
    if (record.description !== values.description?.trim()) return true
    switch (record.type) {
        case RESTRICTION_RULES.LIST: {
            return parseAddressesToFE(record.addresses) !== values.addresses?.trim()
        }
        case RESTRICTION_RULES.RANGE: {
            return record.from !== values.from || record.to !== values.to
        }
    }
    return false
}
