import React from 'react'
import {useTranslation} from 'react-i18next'
import accessDenied from 'ca-common/images/accessDenied.png'
import {StyledImageWrapper, StyledParagraph, StyledTitle, ModalContent} from './StyledCustomNotification'

export const AccessDenied = (): JSX.Element => {
    const {t} = useTranslation()
    return (
        <ModalContent>
            <StyledImageWrapper>
                <img height={200} width={255} src={accessDenied} alt={t('modals:accessDenied:title')} />
            </StyledImageWrapper>
            <StyledTitle>{t('modals:accessDenied:title')}</StyledTitle>
            <StyledParagraph>{t('modals:accessDenied:firstParagraph')}</StyledParagraph>
            <StyledParagraph padding="0 60px">{t('modals:accessDenied:secondParagraph')}</StyledParagraph>
        </ModalContent>
    )
}
