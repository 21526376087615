import styled from 'styled-components'
import {InputNumber, Form} from 'antd'
import variables from 'ca-common/variables'

export const StyledThroughput = styled(Form.Item)`
    display: flex;
`

export const ThroughputInputNumber = styled(InputNumber)`
    width: 75px;
    margin-right: ${variables.gutterXs};
`
