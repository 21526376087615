import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_BACKUPED_ITEMS = 'GET_BACKUPED_ITEMS'

const getBackupedItemsWrapper = fetchWrapperRT(
    GET_BACKUPED_ITEMS,
    async data => await get('getBackupedItemsAsync', data)
)
export const getBackupedItems = getBackupedItemsWrapper.fetcher

const backupedItemsSlice = createSlice({
    name: 'backupedItems',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getBackupedItemsWrapper.makeReducers(builder)
})
const backupedItems = backupedItemsSlice.reducer

export const backupedItemsReducer = combineReducers({
    backupedItems
})
