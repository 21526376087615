import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Form} from 'antd'

import {RoundedInput, RoundedSelect, VerticalFormItem} from 'ca-common/ui-lib/components/FormItem'
import variables from 'ca-common/variables'

const {Option} = RoundedSelect

export const StyledBackupEntities = styled.section`
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    margin-bottom: ${variables.gutterMd};
`

export const BackupEntitiesHeaderMessage = styled.p`
    font-size: 18px;
    margin: 0 0 0 10px;
    font-weight: 300;
    color: ${variables.asphalt};
`

export const BackupEntitiesHeaderMessageService = styled.span`
    text-transform: lowercase;
`

export const BackupEntitiesHeaderTitle = styled.div`
    font-size: 18px;
    margin: 0;
`

export const TitleBar = styled.div`
    display: flex;
`

export const BackupEntitiesTable = styled.div`
    .ant-table-body {
        overflow-x: auto !important;

        .ant-table-tbody {
            > tr > td {
                border-bottom: 1px solid ${variables.white};
            }

            .expand-parent,
            .expand-parent:hover,
            .ant-table-expanded-row,
            .ant-table-expanded-row:hover {
                background-color: ${variables.blueLight};
            }
        }

        .ant-table-column-title {
            font-weight: 600;
        }
    }
`

export const BackupEntitiesTableOperations = styled.div`
    display: flex;
    padding: 0 ${variables.gutterMd} ${variables.gutterMd};
    flex-direction: column;
`

export const SearchInput = styled(RoundedInput)``

export const SearchSelect = styled(RoundedSelect)`
    min-width: 140px;
`
export const SearchSelectOption = styled(Option)``

export const StyledForm = styled(Form)`
    display: flex;
    flex: 1;
    align-items: center;

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }
`

export const SearchIcon = styled(Icon)``

export const StyledFormItem = styled(VerticalFormItem)`
    && {
        height: 32px;
        flex: 1;
    }
`

export const StyledFormItemLicensing = styled(StyledFormItem)`
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        font-weight: 600;
    }

    .ant-form-item-control-input-content > div {
        justify-content: flex-end;
    }
`

export const StyledFormItemTags = styled(StyledFormItem)`
    && .ant-select-selection-item,
    .ant-select-selection__choice {
        background: ${variables.navyBorder};
        border-radius: 24px;
        font-weight: 600;
        font-size: ${variables.fontSizeSecondary};
        color: ${variables.navyAsphalt};
        text-align: center;
    }

    .ant-select-search__field__placeholder,
    .ant-select-selection-overflow,
    .ant-select-selection-placeholder {
        left: ${variables.gutterXxl};
    }

    .ant-select-selection--multiple .ant-select-selection__rendered {
        padding-left: ${variables.gutterXxl};
        margin-left: 0;
    }

    .ant-select-selection-search {
        margin-inline-start: -4px;
    }

    .ant-select-arrow {
        display: none;
    }
`

export const StyledFormItemStatus = styled(StyledFormItem)`
    && {
        flex: none;
    }
`

export const StyledLabel = styled.span`
    /* display: flex;
    align-items: center; */
`

export const StyledIcon = styled(Icon)`
    font-size: 24px;
    margin-left: 4px;
    vertical-align: middle;
`
export const StyledActions = styled.div`
    display: flex;
    justify-content: flex-start;
`
