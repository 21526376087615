import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import Warning from 'ca-common/icons/Warning.svg'
import {BlockedOusContentStyled, WarningIconWrapper} from './OUAccountStyled'
import {WarningIcon} from 'src/newcore/forms/ConfirmationRuleSelection/StyledConfirmationRuleSelection'

type OUAccessProps = {
    blockedGroupList: any
}

export const OUAccess = (props: OUAccessProps) => {
    const {t} = useTranslation()
    const {blockedGroupList} = props

    const ousString = blockedGroupList?.map((ou: any) => ou.name).join(', ')

    return (
        <BlockedOusContentStyled>
            <WarningIconWrapper>
                <WarningIcon component={Warning} />
            </WarningIconWrapper>
            <Trans>
                {t('backups:bulkActivation:choice:GOOGLE_OU:GROUPS:access_ou:description', {BlockedOus: ousString})}
            </Trans>
        </BlockedOusContentStyled>
    )
}
