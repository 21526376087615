import styled from 'styled-components'

export const StyledExpandedContactRow = styled.div`
    display: flex;
    column-gap: 10%;

    & > div {
        width: 45%;
    }
`

export const Items = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`
