import React, {useMemo} from 'react'
import {reduce, map, reject} from 'lodash'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar'

import {
    FIELD_EVENTS,
    FIELD_TRIGGERED_BY,
    getSmartAlertTriggeredByFriendly,
    getSmartAlertEventFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import {StyledSelectedFilters} from './StyledSelectedFilters'

export const SelectedFilters = props => {
    const {loading, setValues, values} = props

    const remove = value => {
        const [parent, target] = value.split('.')

        const newValues = reduce(
            values,
            (result, current, key) => ({
                ...result,
                [key]: parent === key ? reject(current, i => i === target) : current
            }),
            {}
        )

        setValues(newValues)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: []}), {})

        setValues(newValues)
    }

    const selectedFilterValues = useMemo(() => {
        const localize = (key, item) => {
            switch (key) {
                case FIELD_EVENTS:
                    return getSmartAlertEventFriendly(item)

                case FIELD_TRIGGERED_BY:
                    return getSmartAlertTriggeredByFriendly(item)

                default:
                    return ''
            }
        }

        return reduce(
            values,
            (result, current, key) => [
                ...result,
                ...map(current, item => ({
                    value: `${key}.${item}`,
                    label: localize(key, item)
                }))
            ],
            []
        )
    }, [values])

    return (
        <StyledSelectedFilters disabled={loading}>
            <SelectedFiltersBar onRemove={remove} onRemoveAll={removeAll} selectedFilterValues={selectedFilterValues} />
        </StyledSelectedFilters>
    )
}
