import moment from 'moment'

import {excludeOptionFromFormValues} from 'ca-common/utils/table'
import {DAY_MONTH_FORMAT} from 'ca-common/utils/datetime'

import {SecurityAuditFormValues} from 'src/newcore/features/SecurityAudit/types'

export const convertDateFormatForServer = (date: any) => {
    return date ? moment(date).format(DAY_MONTH_FORMAT) : ''
}

export const prepareFormValues = (values: SecurityAuditFormValues) => {
    const {date, status, activity, ...filters} = values
    const preparedValues =
        date && date.length > 0
            ? excludeOptionFromFormValues({
                  ...filters,
                  to: convertDateFormatForServer(date[1]),
                  from: convertDateFormatForServer(date[0])
              })
            : excludeOptionFromFormValues({})

    if (status) preparedValues.statuses = [status]
    if (activity) preparedValues.activities = [activity]

    return preparedValues
}
