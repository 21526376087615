import React from 'react'

import Info from 'ca-common/icons/Info.svg'

import {StyledIcon, Title, TitleWrapper} from 'src/newcore/features/Homepage/atoms/TitleInfo/StyledTitleInfo'
import {Tooltip} from 'antd'

type TitleInfoProps = {
    title: string
    fullTitle: string
    isWarningIcon?: boolean
}

export const TitleInfo = ({title, fullTitle, isWarningIcon}: TitleInfoProps): JSX.Element => {
    return (
        <TitleWrapper>
            <Title>{title}</Title>
            <Tooltip title={fullTitle}>
                <StyledIcon isWarningIcon={isWarningIcon} component={Info} />
            </Tooltip>
        </TitleWrapper>
    )
}
