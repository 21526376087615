import {Modal} from 'antd'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ModalTitle, ModalWrapper, StyledWarningIcon} from './StyledConfirmationModal'

export const ConfirmationModal = ({
    onOk,
    onCancel,
    visible
}: {
    onOk: () => void
    onCancel: () => void
    visible: boolean
}) => {
    const {t} = useTranslation()

    return (
        <Modal
            width={722}
            open={visible}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            title={
                <ModalTitle>
                    <StyledWarningIcon /> {t(`recovery:wizard:confirmation:modal:title`)}
                </ModalTitle>
            }
            okText={t('modals:buttons:confirm')}
            cancelButtonProps={{type: 'link'}}
        >
            <ModalWrapper>
                <strong>{t(`recovery:wizard:confirmation:modal:subtitle`)}</strong>
                {t(`recovery:wizard:confirmation:modal:text`)}
            </ModalWrapper>
        </Modal>
    )
}
