import React from 'react'
import {Tooltip} from 'antd'

import {Tag} from 'ca-common/ui-lib/atoms/Tag'
import {StyledTagsPopover, Content, ContentTag} from './StyledTagsPopover'

export const TagsPopover = ({tags, showingTagsCount}: {tags: any[]; showingTagsCount: number}) => {
    const content = (
        <Content>
            {tags.slice(showingTagsCount).map(tag => (
                <ContentTag key={tag}>
                    <Tooltip title={tag} placement="top">
                        <Tag tag={tag} />
                    </Tooltip>
                </ContentTag>
            ))}
        </Content>
    )

    return (
        <StyledTagsPopover placement="right" content={content} trigger="hover">
            <Tag tag={`${tags.length - showingTagsCount}+`} />
        </StyledTagsPopover>
    )
}
