import styled from 'styled-components'
import {transparentize} from 'polished'
import variables from 'ca-common/variables'

export const StyledEmailSettings = styled.div``

export const StyledEmailFormItemWrapper = styled.div`
    .ant-form-item-label {
        width: 150px;
        margin-right: ${variables.gutterMd};

        font-family: ${variables.publicPageFont};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        color: ${variables.text};
    }

    .ant-form-item-control-input {
        width: 310px;
    }
`

export const StyledEmailWrapper = styled.div<{changing: boolean}>`
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-bottom: ${props => (props.changing ? `20px` : '8px')};

    .ant-form-item {
        margin-bottom: 0;
    }
`

export const StyledNewEmailWrapper = styled(StyledEmailFormItemWrapper)`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (min-width: ${variables.screenMd}) {
        flex-wrap: nowrap;
    }

    .ant-form-item-label {
        width: 208px !important;
    }
`

export const StyledEmailExtra = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeSecondary};
    font-weight: 300;
    line-height: 17px;
    color: ${variables.asphalt};
`

export const StyledEmailExtraBold = styled.span`
    font-weight: 400;
`

export const StyledConfirmationActionButton = styled.button`
    background-color: transparent;
    border: none;
    font-family: ${variables.publicPageFont};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    text-decoration-line: underline;
    color: ${variables.black};

    &:hover {
        color: ${variables.navyBlue};
        cursor: pointer;
    }
`

export const StyledConfirmationNote = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-bottom: 30px;
    padding: 15px;
    background-color: ${transparentize(0.9, variables.orange)};
    font-weight: 400;

    @media screen and (min-width: ${variables.screenMd}) {
        flex-wrap: nowrap;
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin-left: 174px;
    }
`

export const StyledConfirmationNoteTitle = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0;
    margin-right: 4px;
`

export const StyledModalContent = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 156px;

    span {
        font-family: ${variables.publicPageFont};
        font-size: ${variables.subTitleFontSize};
        font-weight: 300;
        line-height: 27px;
        letter-spacing: 0;
        text-align: center;
    }
`

export const StyledResendMessage = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
`
