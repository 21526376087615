import React, {Fragment} from 'react'
import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'

import {StyledCheckbox} from './StyledAntdCheckbox'

import Info from 'ca-common/icons/Info.svg'

const InfoTooltip = ({title}) => {
    return (
        <Tooltip title={title} mouseLeaveDelay={0}>
            <Icon component={Info} />
        </Tooltip>
    )
}

export const AntdCheckbox = props => {
    const {
        label,
        input: {value, ...input},
        tooltip,
        infoTooltip,
        ...rest
    } = props

    return (
        <Fragment>
            <Tooltip title={tooltip} mouseLeaveDelay={0}>
                <StyledCheckbox {...input} {...rest} defaultChecked={!!value}>
                    {label}
                </StyledCheckbox>
            </Tooltip>
            {infoTooltip && <InfoTooltip title={infoTooltip} />}
        </Fragment>
    )
}
