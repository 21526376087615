import styled from 'styled-components'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const ObjectListWrapper = styled.div`
    padding-right: ${variables.gutterSm};
    border-right: 1px solid ${variables.navyBorder};
    width: 100%;
    height: 390px;
    max-width: 33%;
`

export const ObjectAnonymizationWrapper = styled.div`
    padding-left: ${variables.gutterSm};
    width: 100%;
    height: 390px;
    max-width: 67%;
`

export const Body = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${variables.gutterSm};
    padding: ${variables.gutterMd};
    padding-top: 0;
    align-items: flex-end;
    min-height: 462px;
`

export const ColumnsWrapper = styled.div`
    display: flex;
    width: 100%;
`

export const ListTitle = styled.div`
    padding-left: ${variables.gutterXs};
`

export const DefaultSystemTemplateButton = styled(Button)`
    color: ${variables.oktaColor} !important;
    cursor: not-allowed;
    border-color: transparent !important;
    box-shadow: none !important;

    &::after {
        display: none;
    }
`

export const ResetToDefaultSystemTemplateButton = styled(Button)`
    color: ${variables.navyBlue};
    border-color: ${variables.navyBlue};
`

export const Subheader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

export const Description = styled.span`
    font-family: ${variables.publicPageFont};
    color: ${variables.text};
    font-weight: 300;
    font-size: ${variables.fontSizeBase};
`

export const Row = styled.div<{isHighlighted?: boolean}>`
    display: flex;
    gap: ${variables.gutterMd};
    align-items: center;
    flex-wrap: nowrap;
    padding: ${variables.gutterXs};
    background-color: ${props => (props.isHighlighted ? variables.blueLight : variables.white)};
    border: 1px solid ${variables.blueLight};
    border-radius: 2px;

    & > *:first-child {
        width: 100%;
        max-width: 27%;
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > *:nth-child(2) {
        width: 100%;
        max-width: 25%;
        flex-shrink: 0;

        .ant-select-selector {
            padding-left: 0;
        }
    }

    & > *:last-child {
        width: 100%;
        flex-shrink: 0;
        max-width: calc(48% - 2 * ${variables.gutterMd});
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
