import styled from 'styled-components'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StatusInfoWrapper = styled.div`
    display: flex;
    padding: ${variables.gutterSm} ${variables.gutterMd};
    width: 100%;
    background: ${variables.white};
    overflow: auto;
    border-radius: ${variables.borderRadiusBase};
    white-space: nowrap;
    justify-content: space-between;
    gap: ${variables.gutterMd};
`

export const LoadingWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    height: 74px;
`

export const StatusItemWrapper = styled.div`
    padding-left: ${variables.gutterMd};
    border-left: 1px solid ${variables.navyBorder};
`

export const UpdateCreditCardLink = styled(CALink)`
    font-weight: 600;
    text-decoration: underline;
`

export const StatusColumnsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterMd};
`

export const UpdateCreditCardLinkWrapper = styled.div`
    padding-left: ${variables.gutterMd};
    border-left: 1px solid ${variables.navyBorder};
    display: flex;
    align-items: center;
`
