import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'
import Calendar from 'rc-calendar'

export const StyledSpinner = styled.div`
    .ant-spin-blur {
        opacity: 1;
    }

    & .ant-spin-nested-loading > div > .ant-spin {
        z-index: 10000;
    }
` // @TODO improve spinner styles. Need to add in global styles

export const CalendarCell = styled.div`
    text-align: center;
    padding-bottom: 5px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background: ${variables.blueLight};
    }

    .rc-calendar-disabled-cell & {
        opacity: 0.4;
        cursor: not-allowed;
    }
`

const CalendarDotsCell = styled(CalendarCell)`
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 6px;
        right: 0;
        margin: auto;
        width: 4px;
        height: 4px;
        background: ${variables.green};
        border-radius: 50%;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -6px;
        right: 0;
        margin: auto;
        width: 4px;
        height: 4px;
        background: ${variables.green};
        border-radius: 50%;
    }
`

export const OneSuccessDate = styled(CalendarDotsCell)`
    &::before {
        content: none;
    }

    &::after {
        left: 0;
        background: ${props => (props.retention ? variables.interaction20 : variables.green)};
    }
`
export const OnePartialDate = styled(CalendarDotsCell)`
    &::before {
        content: none;
    }

    &::after {
        left: 0;
        background: ${variables.orange};
    }
`
export const AllPartialDate = styled(CalendarDotsCell)`
    &::after {
        background: ${variables.orange};
    }
    &::before {
        background: ${variables.orange};
    }
`
export const OneFailedDate = styled(CalendarDotsCell)`
    user-select: none;
    cursor: not-allowed;

    &::after {
        left: 0;
        background: ${variables.red};
    }

    &::before {
        content: none;
    }
`

export const AllFailedDate = styled(CalendarDotsCell)`
    user-select: none;
    cursor: not-allowed;

    &::after {
        background: ${variables.red};
    }

    &::before {
        background: ${variables.red};
    }
`

export const AllSuccessDate = styled(CalendarDotsCell)`
    &::after {
        background: ${variables.green};
    }

    &::before {
        background: ${variables.green};
    }
`

export const MixedDate = styled(CalendarDotsCell)`
    &::after {
        background: ${variables.green};
    }

    &::before {
        background: ${variables.red};
    }
`
export const MixedPartialDate = styled(CalendarDotsCell)`
    &::after {
        background: ${variables.green};
    }
    &::before {
        background: ${variables.orange};
    }
`
export const StyledSnapshotCalendar = styled.div`
    display: flex;
    flex-flow: ${props => props.flexFlow};
    margin: 0 -${variables.gutterXs};
`

export const DayPicker = styled.div`
    padding: 0 ${variables.gutterXs};
    margin-bottom: ${variables.gutterSm};

    .rc-calendar-picker {
        top: 18px !important;
    }
`
export const TimePicker = styled.div`
    padding: 0 ${variables.gutterXs};
    margin-bottom: ${variables.gutterSm};
`

export const StyledCalendar = styled(Calendar)`
    ${props =>
        !props.isNextYearBtnAvailable &&
        css`
            .rc-calendar-next-year-btn {
                cursor: not-allowed;
                pointer-events: none;
                opacity: 0.5;
            }
        `};

    ${props =>
        !props.isNextMonthBtnAvailable &&
        css`
            .rc-calendar-next-month-btn {
                cursor: not-allowed;
                pointer-events: none;
                opacity: 0.5;
            }
        `};
`
