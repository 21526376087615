import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useTranslation} from 'react-i18next'
import Icon, {ExclamationCircleFilled} from '@ant-design/icons'
import {Popconfirm, Tooltip} from 'antd'
import {
    StyledBulkCondition,
    PopconfirmTitle,
    Title,
    DeleteIcon,
    BulkConditionGroup,
    WarningIcon,
    BulkConditionTitle,
    ValuesWrapper,
    Values
} from './StyledBulkCondition'

import {BULK_ACTIVATION_TYPE} from 'ca-common/common/enum/BulkActivation'
import {addRulesToRemovedList} from 'src/newcore/redux/modules/backups/bulkActivate'
import Delete from 'ca-common/icons/Delete.svg'
import Warning from 'ca-common/icons/Warning.svg'
import Info from 'ca-common/icons/Info.svg'
import {SOURCE} from 'src/ca-common/common/enum'

const BulkConditionRaw = props => {
    const {t} = useTranslation()
    const handleConfirm = id => props.actions.addRulesToRemovedList([id])

    const {
        rule: {type, id, adValues, groupItems, name, urlConditions, source, blockedUnitsNames, hasBlockedGroups},
        removedRulesList
    } = props

    const values = useMemo(() => {
        if (type === BULK_ACTIVATION_TYPE.ACTIVE_DIRECTORY) {
            return adValues
        }

        if (type === BULK_ACTIVATION_TYPE.URL_CONDITIONS && urlConditions) {
            return urlConditions.map(({value, operator}) => {
                return t(`backups:bulkActivation:sharepoint:preview:${operator}`, {value})
            })
        }

        return (groupItems || []).map(group => group.name)
    }, [type, adValues, groupItems, urlConditions])

    const {isOUAccessBlocked, blockedOusStr} = useMemo(() => {
        if (source !== SOURCE.GOOGLEAPPS) {
            return {
                isOUAccessBlocked: false,
                blockedOusStr: ''
            }
        }

        const blockedOuList = []
        let isOUAccessBlocked = false

        if (hasBlockedGroups) {
            blockedUnitsNames.map(ous => {
                const isBlockedOu = values.find(selectedOu => selectedOu == ous)
                if (isBlockedOu) {
                    isOUAccessBlocked = true
                    blockedOuList.push({ous})
                }
            })
        }

        return {
            isOUAccessBlocked: isOUAccessBlocked,
            blockedOusStr: blockedOuList
                ?.flat()
                .map(data => data.ous)
                .join(', ')
        }
    }, [source, hasBlockedGroups, values, blockedUnitsNames])

    return (
        <StyledBulkCondition disabled={removedRulesList.includes(id)}>
            <BulkConditionGroup>
                <BulkConditionTitle>
                    <Title>
                        {t(
                            `${
                                source == SOURCE.GOOGLEAPPS
                                    ? 'backups:bulkActivation:choice:GOOGLE_OU'
                                    : 'backups:bulkActivation:choice'
                            }:${type}:title`
                        )}
                    </Title>
                    <Tooltip
                        title={t(
                            `${
                                source == SOURCE.GOOGLEAPPS
                                    ? 'backups:bulkActivation:choice:GOOGLE_OU'
                                    : 'backups:bulkActivation:choice'
                            }:${type}:${
                                (source == SOURCE.GOOGLEAPPS) & isOUAccessBlocked
                                    ? 'access_ou:tooltipDescription'
                                    : 'newAccounts'
                            }`,
                            {BlockedOus: blockedOusStr}
                        )}
                    >
                        {isOUAccessBlocked ? (
                            <ExclamationCircleFilled style={{fontSize: '16.33px', color: '#F2994A'}} />
                        ) : (
                            <Icon component={Info} />
                        )}
                    </Tooltip>
                </BulkConditionTitle>
                <ValuesWrapper>
                    {(type === BULK_ACTIVATION_TYPE.ACTIVE_DIRECTORY && `${name}: `) ||
                        type === BULK_ACTIVATION_TYPE.GROUPS}
                    <Tooltip title={values.join(', ')}>
                        <Values>{values.join(', ')}</Values>
                    </Tooltip>
                </ValuesWrapper>
            </BulkConditionGroup>
            <Popconfirm
                placement="top"
                icon={<WarningIcon component={Warning} />}
                title={<PopconfirmTitle>{t('backups:bulkCondition:delete')}</PopconfirmTitle>}
                onConfirm={() => handleConfirm(id)}
                okText={t('modals:buttons:yes')}
                cancelText={t('modals:buttons:cancel')}
            >
                <DeleteIcon component={Delete} />
            </Popconfirm>
        </StyledBulkCondition>
    )
}

const mapStateToProps = state => ({
    removedRulesList: state.backups.bulkActivation.bulkActivationRules.removedRulesList
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            addRulesToRemovedList
        },
        dispatch
    )
})

export const BulkCondition = connect(mapStateToProps, mapDispatchToProps)(BulkConditionRaw)
