import styled, {css} from 'styled-components'

import variables from 'ca-common/variables'

export const StyledCard = styled.div<{noEntities?: boolean; isForReinstate?: boolean}>`
    padding: ${variables.gutterXs} ${variables.gutterSm};
    box-shadow: 0 5px 5px rgba(8, 35, 48, 0.12);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    font-family: ${variables.publicPageFont};
    background-color: ${props => (props.noEntities ? variables.blueLight : variables.white)};
    color: ${props => (props.isForReinstate ? variables.navyAsphalt : variables.navyBlue)};
`

export const ConfirmationData = styled.div`
    display: flex;
    justify-content: space-between;
    width: 70%;
    flex-shrink: 0;
`

export const Text = styled.span<{isClickabled?: boolean}>`
    font-size: ${variables.gutterSm};
    font-weight: 300;

    ${({isClickabled}) =>
        isClickabled &&
        css`
            cursor: pointer;
            text-decoration: underline;
        `}
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    &:last-child {
        width: 180px;
    }

    & > span:nth-child(2) {
        margin-top: auto;
    }

    .anticon {
        text-align: start;
    }
`

export const ServiceColumn = styled(Column)`
    width: 140px !important;
`

export const Service = styled(Text)`
    font-weight: 600;
`

export const Total = styled(Service)``

export const ReinstateTag = styled.span`
    border: 0.5px solid ${variables.navyAsphalt};
    background-color: rgba(110, 136, 104, 0.2);
    font-weight: 300;
    font-size: 11px;
    border-radius: 19px;
    padding: 0 ${variables.gutterXs};
    width: min-content;
`

export const ServiceAndIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`
