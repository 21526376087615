import React, {useMemo, useCallback} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {getServiceName} from 'src/newcore/utils/sources'

import {JobFields} from 'src/newcore/features/Jobs/types'
import {CancelConfirmation} from 'src/newcore/features/Jobs/atoms'
import {getActivityFriendly} from 'src/newcore/features/Jobs/lib'

type CancelButtonProps = Pick<JobFields, 'account' | 'taskType' | 'source'> & {
    cancelTask: () => void
    canceling: boolean
    permitted: boolean
    inProcess: boolean
}

export const CancelButton = (props: CancelButtonProps): JSX.Element => {
    const {account, taskType, source, cancelTask, canceling, permitted, inProcess} = props
    const {t} = useTranslation()

    const tooltipDisabledText = useMemo(() => {
        if (!permitted) {
            return t('JobsPage:cancelNotPermitted')
        }

        return undefined
    }, [inProcess, permitted])

    const button = useCallback(
        onClick => (
            <Tooltip title={tooltipDisabledText}>
                <Button
                    type="link"
                    onClick={onClick}
                    loading={canceling}
                    disabled={!permitted || !inProcess || canceling}
                >
                    {t('JobsPage:cancelText')}
                </Button>
            </Tooltip>
        ),
        [canceling, permitted, inProcess]
    )

    return (
        <CancelConfirmation
            cancelTask={cancelTask}
            type={getActivityFriendly(taskType)}
            email={account}
            source={getServiceName({source, short: true})}
            button={button}
        />
    )
}
