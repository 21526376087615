import React from 'react'
import {Trans} from 'react-i18next'

import NoHaveSmartAlerts from 'ca-common/icons/smartAlerts/NoHaveSmartAlerts.min.svg'

import {StyledIcon, StyledText, StyledWrapper} from './StyledTableWithoutAlerts'

export const TableWithoutAlerts = () => {
    return (
        <StyledWrapper>
            <StyledIcon component={NoHaveSmartAlerts} />
            <StyledText>
                <Trans i18nKey="smartAlerts:tableWithoutAlertsPlaceholder" />
            </StyledText>
        </StyledWrapper>
    )
}
