import styled from 'styled-components'

import variables from 'ca-common/variables'

import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'

export const StyledPaymentsStatus = styled.div``

export const Status = styled.span<{status: PaymentStatus}>`
    color: ${({status}) => {
        if (status === PaymentStatus.PAID) {
            return variables.green
        }

        if (status === PaymentStatus.NO_BILLS) {
            return variables.asphalt
        }

        if (status === PaymentStatus.DUE) {
            return variables.dark
        }

        return variables.red
    }};
`
