import {SOURCE} from 'ca-common/common/enum'
import {BACKUP_FREQUENCY} from 'ca-common/utils/datetime'

import type {BackupConfig} from 'src/newcore/features/OneClickActivation/types'
import {getTotalEntitiesByService} from 'src/newcore/features/OneClickActivation/lib/getTotalEntitiesByService'

export const getBackupConfigDefaultValue = (
    service: SOURCE,
    totalEntities?: {
        source: SOURCE
        totalItems: number
    }[]
): BackupConfig => {
    return {
        enabledEntitiesBitSet: ''.padStart(getTotalEntitiesByService({totalEntities, service}), '1'),
        settings: Object.assign(
            {
                backupFrequency: BACKUP_FREQUENCY.DAILY,
                backupHour: '6',
                detectNewAccounts: true,
                daysToAccountDeactivation: undefined,
                indexStatus: true
            },
            service === SOURCE.MS365 ? {backupUnlicensed: true, disableUnlicensedOption: undefined} : {}
        )
    }
}
