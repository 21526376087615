import {combineReducers} from '@reduxjs/toolkit'
import {getIpRulesSlice} from 'ca-common/features/IpRestriction/lib/getIpRulesSlice'
import {getIpRulesWrapper} from 'src/newcore/features/IpRestriction/getIpRules'
import {AppState} from 'src/newcore/components/Root/newAppStore'

export const ipRulesSlice = getIpRulesSlice(getIpRulesWrapper)
export const {createRuleLocal, modifyRuleLocal, removeRuleLocal, setActivateLocal} = ipRulesSlice.actions
export const ipRules = ipRulesSlice.reducer

export const ipRestrictions = combineReducers({
    ipRules
})

export const selectIpRules = (state: AppState) => state.ipRestrictions.ipRules
