import React from 'react'
import {Input} from 'antd'
import {useTranslation} from 'react-i18next'

import {StyledAddressList, StyledHeader, StyledComments, StyledFormItem} from './StyledAddressList'
import {FORM_FIELDS, useAddressListValidator} from 'ca-common/features/IpRestriction/lib'

type AddressListProps = {
    disabled?: boolean
    width?: number
    initialValue?: string
}

export const AddressList = (props: AddressListProps): JSX.Element => {
    const {disabled = false, width = 488, initialValue = ''} = props
    const {t} = useTranslation()
    const addressListValidator = useAddressListValidator()

    return (
        <StyledAddressList>
            <StyledHeader>{t('ipRestrictions:addAddress:list:header')}</StyledHeader>
            <StyledComments>{t('ipRestrictions:addAddress:list:advice')}</StyledComments>
            <StyledFormItem
                name={FORM_FIELDS.ADDRESSES}
                initialValue={initialValue}
                rules={[
                    {
                        validator: (_, value) => addressListValidator(value, !disabled)
                    }
                ]}
                normalize={(value: string) => {
                    return value.replace(/[^\d\s.,]+/g, '')
                }}
            >
                <Input.TextArea rows={2} style={{resize: 'vertical', borderRadius: 4, width}} disabled={disabled} />
            </StyledFormItem>
            <StyledComments>{t('ipRestrictions:addAddress:list:example')}</StyledComments>
        </StyledAddressList>
    )
}
