import mixpanel from 'mixpanel-browser'
import {PARTNER_TYPE, USER_STATUS} from 'ca-common/constants'
import {SOURCE, STORAGE_TYPE} from 'ca-common/common/enum'
import {BACKUP_FREQUENCY} from 'ca-common/utils/datetime'
import {TODO_ANY} from 'ca-common/types'
import {getUserInfo} from './store'

const serviceNamesMixpanel: Record<SOURCE, string> = {
    MS365: 'MS Exchange',
    MS365GROUPS: 'MS Groups & Teams',
    SHAREPOINT: 'MS SharePoint',
    SHAREPOINT_MULTI: 'MS SharePoint',
    ONEDRIVE: 'MS OneDrive',
    DROPBOX: 'Dropbox',
    GACCOUNTS: 'Google Account',
    SFC: 'Salesforce',
    BOX: 'Box',
    GOOGLEAPPS: 'Google Workspace',
    GDRIVETEAM: 'Google Shared Drives',
    IMAP: 'IMAP',
    YMAIL: 'Yahoo Mail',
    DDB: 'DynamoDB',
    SDB: 'SimpleDB',
    GMAIL: 'Google (Email)',
    GDOC: 'Google (Documents)',
    GCONTACTS: 'Google (Contacts)',
    GTASK: 'Google (Tasks)',
    GCALENDAR: 'Google (Calendars)',
    GSITES: 'Google (Sites)',
    O365MAIL: 'MS Exchange (Email)',
    O365CONTACTS: 'MS Exchange (Contacts)',
    O365TASKS: 'MS Exchange (Tasks)',
    O365CALENDAR: 'MS Exchange (Calendars)',
    O365NOTES: 'MS Exchange (Notes)',
    MS365GROUP_CONVERSATIONS: 'MS Groups (Conversation)',
    MS365GROUP_SITE: 'MS Groups (Site)',
    MS365GROUP_CALENDAR: 'MS Groups (Calendars)',
    MS365GROUP_META: 'MS Groups (Metadata)',
    MS365TEAM_CHANNELS: 'MS Groups (Channels)',
    MS365TEAM_APPS: 'MS Groups (Apps)',
    DYNAMICS365: 'Dynamics 365'
}

export const getServiceNameMixpanel = (source: SOURCE): string => {
    return serviceNamesMixpanel[source]
}

export const getStatusValue = (status: USER_STATUS) => {
    switch (status) {
        case USER_STATUS.NOTACTIVE:
            return 'Not Active'
        case USER_STATUS.TRIAL:
            return 'Trial'
        case USER_STATUS.ACTIVE:
            return 'Subscribed'
        case USER_STATUS.UNSUBSCRIBED:
            return 'Unsubscribed'
        default:
            return status
    }
}

export const getBackupFrequencyValue = (frequency: BACKUP_FREQUENCY) => {
    switch (frequency) {
        case BACKUP_FREQUENCY.DAILY:
            return 'Daily'
        case BACKUP_FREQUENCY.EVERY3DAYS:
            return 'Every 3 Days'
        case BACKUP_FREQUENCY.WEEKLY:
            return 'Weekly'
        case BACKUP_FREQUENCY.DAILY3TIMES:
            return '3 times a day'
        default:
            return ''
    }
}

export const getPartnerTypeValue = (type: PARTNER_TYPE) => {
    switch (type) {
        case PARTNER_TYPE.AFFILIATE_RESELLER:
            return 'Affiliate'
        case PARTNER_TYPE.DIRECT_RESELLER:
            return 'Direct Reseller'
        case PARTNER_TYPE.DISTRIBUTOR:
            return 'Distributor'
        case PARTNER_TYPE.INDIRECT_RESELLER:
            return 'Indirect Reseller'
        default:
            return type
    }
}

export const getCustomerTypeValue = (pid: string) => {
    return pid ? 'Indirect Customer' : 'Direct Customer'
}

export const getIsPartnerUserTypeValue = (isSpecialPartnerAccount: boolean) => {
    return !!isSpecialPartnerAccount
}

type InitParams = {
    realId: string
    id: string
    status: USER_STATUS
    partnerID: string
    partnerType: PARTNER_TYPE
    isSpecialPartnerAccount: boolean
}

export const initAnalytics = ({
    realId,
    id,
    partnerID,
    status,
    partnerType,
    isSpecialPartnerAccount
}: InitParams): void => {
    mixpanel.identify(realId)
    if (mixpanel.people) {
        mixpanel.people.set({
            USER_ID: realId,
            'Customer Account Status': getStatusValue(status),
            'Customer ID': id,
            'Partner ID': partnerID,
            'Partner Type': getPartnerTypeValue(partnerType),
            'Customer Type': getCustomerTypeValue(partnerID),
            'Is Partner User': getIsPartnerUserTypeValue(isSpecialPartnerAccount)
        })
    }
}

export const getMixpanelStorageName = (storageType: STORAGE_TYPE) => {
    switch (storageType) {
        case STORAGE_TYPE.AMAZON_S3:
            return 'AWS S3'
        case STORAGE_TYPE.BOX:
            return 'Box'
        case STORAGE_TYPE.AMAZON_S3_COMPATIBLE:
            return 'AWS S3 Compatible'
        case STORAGE_TYPE.AZURE:
            return 'Azure Blob'
        case STORAGE_TYPE.CLOUDALLY_S3:
            return 'Download'
        case STORAGE_TYPE.DROPBOX:
            return 'Dropbox'
        case STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM:
            return 'Google Cloud Platform'
        default:
            return ''
    }
}

export const track = (eventName: string, eventData: TODO_ANY): void => {
    const userInfo = getUserInfo()?.response
    let userTrackingData = {}

    if (userInfo) {
        userTrackingData = {
            'Customer Account Status': getStatusValue(userInfo.status),
            'Customer ID': userInfo.id,
            'Partner ID': userInfo.partnerID,
            'Tracking Method': 'Frontend',
            Application: 'Portal',
            'Partner Type': getPartnerTypeValue(userInfo.partnerType),
            'Customer Type': getCustomerTypeValue(userInfo.partnerID),
            'Is Partner User': getIsPartnerUserTypeValue(userInfo.isSpecialPartnerAccount),
            'Is End User': Boolean(userInfo.isMsEndUser)
        }
    }
    try {
        mixpanel.track(eventName, {...userTrackingData, ...eventData})
    } catch (error) {
        console.error(error)
    }
}

export const clearAnalytics = (): void => {
    mixpanel.identify('')
    if (mixpanel.people) {
        mixpanel.people.set({
            USER_ID: '',
            'Customer Account Status': '',
            'Customer ID': '',
            'Partner ID': '',
            'Partner Type': '',
            'Customer Type': ''
        })
    }
}

export * from './mixpanelEvents'
