import React, {useMemo} from 'react'
import {i18n} from 'ca-common/locales'

import {getTableAddress} from 'ca-common/features/IpRestriction/atoms'
import {RestrictionEntry} from 'ca-common/features/IpRestriction/types'
import {TableActions} from 'ca-common/features/IpRestriction/molecules'

export const useColumns = (data: RestrictionEntry[]) =>
    useMemo(
        () => [
            {
                title: i18n.t('ipRestrictions:table:columns:desc'),
                dataIndex: 'description',
                key: 'description',
                width: 220,
                ellipsis: true,
                render: (text: string) => ({
                    children: text,
                    props: {
                        'data-hj-suppress': true
                    }
                })
            },
            {
                title: i18n.t('ipRestrictions:table:columns:address'),
                render: (record: RestrictionEntry) => getTableAddress(record),
                ellipsis: true
            },
            {
                title: i18n.t('ipRestrictions:table:columns:actions'),
                render: (record: RestrictionEntry) => {
                    return <TableActions record={record} />
                },
                width: 150
            }
        ],
        [JSON.stringify(data)]
    )
