import {combineReducers} from '@reduxjs/toolkit'

import {MS365GroupsReducer as MS365Groups} from './MS365Groups'
import {bulkActivateAccountsResultReducer as bulkActivateAccountsResult} from './bulkActivateAccounts'
import {excludeEmailsReducer as excludeEmails} from './excludeEmails'
import {ActiveDirectoryReducer as activeDirectory} from './ActiveDirectory'
import {bulkActivationRules} from './Rules'
import {bulkActivationSP} from 'src/newcore/components/BulkActivation/SharepointUrl/redux/redux'
import {GoogleOUReducer as GoogleOUs} from './GoogleOU'
export const bulkActivationReducer = combineReducers({
    MS365Groups,
    bulkActivateAccountsResult,
    excludeEmails,
    bulkActivationRules,
    activeDirectory,
    bulkActivationSP,
    GoogleOUs
})
