import React from 'react'
import {useTranslation} from 'react-i18next'
import {Input, Form} from 'antd'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {atLeastOneNumAndChar, specialCharValidation, trailingSpaceValidation, LIMITS} from 'ca-common/utils/validation'

import {StyledForm, StyledError, StyledButtonText} from './StyledPublicCnahgePasswordForm'

const {Item, useForm} = Form

export const PublicChangePasswordForm = ({
    initialValues = {},
    handleSubmit,
    withEmail = false,
    withToken = false,
    withCurrentPassword = false,
    submitting = false,
    submitError = ''
}) => {
    const [form] = useForm()
    const {t} = useTranslation()

    return (
        <StyledForm onFinish={handleSubmit} hideRequiredMark form={form} initialValues={initialValues}>
            {withEmail && (
                <Item
                    hidden={initialValues.email}
                    name="email"
                    rules={[
                        {required: true, message: t('forms:common:validation:shortRequired')},
                        {type: 'email', message: t('forms:common:fields:email:validation:invalid')}
                    ]}
                >
                    <Input size="large" placeholder={t('forms:changePassword:fields:email:placeholder')} />
                </Item>
            )}
            {withToken && (
                <Item
                    hidden={initialValues.token}
                    name="token"
                    rules={[{required: true, message: t('forms:common:validation:shortRequired')}]}
                >
                    <Input size="large" placeholder={t('forms:changePassword:fields:token:placeholder')} />
                </Item>
            )}
            {withCurrentPassword && (
                <Item name="password" rules={[{required: true, message: t('forms:common:validation:shortRequired')}]}>
                    <Input.Password size="large" placeholder={t('forms:changePassword:fields:password:placeholder')} />
                </Item>
            )}
            <Item
                name="newPassword"
                rules={[
                    {required: true, message: t('forms:common:validation:shortRequired')},
                    ({getFieldValue}) => ({
                        async validator(_, value) {
                            if (withCurrentPassword && value === getFieldValue('password')) {
                                throw new Error(t('validation:dontRepeatPasswords'))
                            } else if (value && value.length > LIMITS.PASSWORD.MAX) {
                                throw new Error(t('validation:maxPassword', [LIMITS.PASSWORD.MAX]))
                            } else if (value && value.length < LIMITS.PASSWORD.MIN) {
                                throw new Error(t('validation:minPassword', [LIMITS.PASSWORD.MIN]))
                            } else if (value && !specialCharValidation(value)) {
                                return Promise.reject(t('validation:specialCharValidation'))
                            } else if (value && !trailingSpaceValidation(value)) {
                                return Promise.reject(t('validation:trailingOrLeadingSpace'))
                            } else if (value && !atLeastOneNumAndChar(value)) {
                                throw new Error(t('validation:atLeastOneNumAndChar'))
                            }
                        }
                    })
                ]}
                dependencies={['password']}
            >
                <Input.Password size="large" placeholder={t('forms:changePassword:fields:newPassword:placeholder')} />
            </Item>
            <Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                    {required: true, message: t('forms:common:validation:shortRequired')},
                    ({getFieldValue}) => ({
                        async validator(_, value) {
                            if (value && value !== getFieldValue('newPassword')) {
                                throw new Error(t('forms:common:fields:passwordConfirm:validation:invalid'))
                            }
                        }
                    })
                ]}
            >
                <Input.Password
                    size="large"
                    placeholder={t('forms:changePassword:fields:confirmPassword:placeholder')}
                />
            </Item>

            {submitError && <StyledError>{submitError}</StyledError>}

            <Button block size="large" color={variables.iceBlue} type="primary" htmlType="submit" disabled={submitting}>
                <StyledButtonText>{t('forms:common:actions:save')}</StyledButtonText>
            </Button>
            <Button
                block
                size="small"
                type="link"
                htmlType="button"
                onClick={() => form.resetFields()}
                disabled={submitting}
            >
                <StyledButtonText>{t('forms:common:actions:cancel')}</StyledButtonText>
            </Button>
        </StyledForm>
    )
}
