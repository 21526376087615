import {SOURCE} from 'ca-common/common/enum'

export enum ACTIONS {
    PAUSE = 'PAUSE',
    BACKUP_NOW = 'BACKUP_NOW',
    CANCEL = 'CANCEL',
    DELETE = 'DELETE',
    RESUME = 'RESUME',
    RECOVERY = 'RECOVERY'
}

export enum BACKUPS_VIEW {
    TILE = 'TILE',
    LIST = 'LIST'
}
export const LAST_BACKUP_STATUS = {
    NEVER: 'Never'
}

export const sourcesWithNoActivatedEntitiesNote = [
    SOURCE.ONEDRIVE,
    SOURCE.SHAREPOINT_MULTI,
    SOURCE.SHAREPOINT,
    SOURCE.MS365,
    SOURCE.BOX,
    SOURCE.GOOGLEAPPS,
    SOURCE.GDRIVETEAM,
    SOURCE.DROPBOX,
    SOURCE.MS365GROUPS
]
