import {getUTCDateFromLocal} from 'ca-common/utils/datetime'
import {SOURCE} from 'ca-common/common/enum/Source'
import {TASK_TYPE} from 'ca-common/constants'
import {
    METADATA_ENTITY_TYPE,
    SEEDING_DESTINATION_TYPE,
    SEEDING_FILTERS,
    SEEDING_OBJECT_TYPES
} from 'ca-common/common/enum/seeding'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import _ from 'lodash'
import {SeedingWizardFirstStep} from './FirstStep'
import {DataSecondStep, MetadataSecondStep} from './SecondStep'
import {DataLastStep, MetadataLastStep} from './LastStep'
import {MetadataThirdStep, SeedingWizardThirdStep} from './ThirdStep'
import {SeedingWizardFourthStep} from './FourthStep'
import {DataAnonymizationStep} from './DataAnonymizationStep'

const prepareMetadataUiEntities = (data, backupDate) => {
    return _.reduce(
        data,
        (result, el, key) => {
            if (el) {
                result.push({filename: key, backupDate, entityType: METADATA_ENTITY_TYPE})
            }

            return result
        },
        []
    )
}

export const getSeedingRequest = (taskSettings, wizardData, objectsList, anonymizationObjects) => {
    const objectsData = _.get(objectsList, 'response.data', {})

    const base = {
        account: taskSettings.account,
        taskId: taskSettings.taskId,
        date: getUTCDateFromLocal(wizardData.date),
        includeAttachments: wizardData.includeAttachments,
        disableTriggers: wizardData.disableTriggers,
        includeMetadata: wizardData.dataType === SFC_DATA_TYPE.DATA ? -1 : 1,
        isUseSandbox: true,
        source: SOURCE.SFC,
        isSeeding: true,
        type: TASK_TYPE.RESTORE,
        timeZoneOffset: new Date().getTimezoneOffset()
    }

    if (wizardData.isEnabledAnonymization && anonymizationObjects && wizardData.anonymizationSettingsChanges) {
        if (_.size(wizardData.anonymizationSettingsChanges) !== 0) {
            base.anonymizationSettings = anonymizationObjects.map(object => {
                const fieldChanges = wizardData.anonymizationSettingsChanges[object.name]
                if (fieldChanges) {
                    const newFields = object.fields.map(field => {
                        if (fieldChanges[field.name]) {
                            return {
                                ...field,
                                ...fieldChanges[field.name]
                            }
                        }

                        return field
                    })

                    return {...object, fields: newFields}
                }

                return object
            })
        } else {
            base.anonymizationSettings = anonymizationObjects
        }
    }

    if (wizardData.objectType === SEEDING_OBJECT_TYPES.MULTIPLE) {
        base.uiEntities =
            wizardData.dataType === SFC_DATA_TYPE.DATA
                ? formUIEntitiesForRequest(objectsData)
                : prepareMetadataUiEntities(objectsData, wizardData.date)
    }

    if (wizardData.destinationType === SEEDING_DESTINATION_TYPE.SAME) {
        base.targetAccount = taskSettings.account
    }

    return base
}

const formUIEntitiesForRequest = data => {
    return _.reduce(
        data,
        (result, el, key) => {
            let preparedUIEntity
            if (el.isDeleted) {
                // return accumulator without adding deleted item
                return result
            }
            switch (el.filter.name) {
                case SEEDING_FILTERS.WHERE_CLAUSE:
                    preparedUIEntity = {
                        filename: key,
                        filteringType: SEEDING_FILTERS.WHERE_CLAUSE,
                        whereClause: el.filter.query
                    }
                    break
                case SEEDING_FILTERS.PICK:
                    preparedUIEntity = {
                        filename: key,
                        filteringType: el.filter.filteringType,
                        pickCount: el.filter.pickCount
                    }
                    break
                default:
                    preparedUIEntity = {
                        filename: key,
                        filteringType: SEEDING_FILTERS.ALL_RECORDS
                    }
            }
            result.push(preparedUIEntity)
            return result
        },
        []
    )
}

export const getSeedingSteps = wizard => {
    const isMetadata = wizard?.data?.dataType === SFC_DATA_TYPE.METADATA
    const isFull = _.get(wizard, 'data.objectType') === SEEDING_OBJECT_TYPES.FULL
    const isEnabledAnonymization = _.get(wizard, 'data.isEnabledAnonymization')

    if (isMetadata) {
        return [SeedingWizardFirstStep, MetadataSecondStep, !isFull && MetadataThirdStep, MetadataLastStep].filter(
            Boolean
        )
    } else {
        return [
            SeedingWizardFirstStep,
            DataSecondStep,
            !isFull && SeedingWizardThirdStep,
            !isFull && SeedingWizardFourthStep,
            isEnabledAnonymization && DataAnonymizationStep,
            DataLastStep
        ].filter(Boolean)
    }
}
