import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Form, FormInstance, Input, Select} from 'antd'
import {useDispatch} from 'react-redux'
import uniqBy from 'lodash/uniqBy'
import some from 'lodash/some'
import {
    CURRENCIES,
    CURRENCY,
    TITLE,
    VENDOR,
    PAYMENT_TYPE,
    PAYMENT_METHOD,
    FULFILLED,
    ANNUAL_CURRENCY
} from 'ca-common/constants'
import {PROVIDER} from 'ca-common/common/enum/Provider'
import type {PaymentDetailsType} from '../types'
import {hasStates, renderStates} from 'ca-common/utils/statesData'
import {
    amexValidate,
    emailValidate,
    maxBillingFieldLength,
    nameValidate,
    phoneNumberValidate,
    requiredValidate,
    zipCodeValidate,
    dateValidate,
    validatePositiveInt
} from 'ca-common/utils/validation'
import countries from 'ca-common/utils/countriesData'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'
import {Button} from 'ca-common/ui-lib/atoms'
import {FieldsWrapper, FormFooter, Row, Separator} from '../StyledForm'
import {COUNTRIES_REQUIRED_TAX_ID, createRules} from './utils'

type PaymentDetailsProps = {
    isPP: boolean
    setPaymentDetails: (data: PaymentDetailsType) => void
    currencyEditable: boolean
    paymentCurrency: CURRENCIES
    paymentMethod?: any
    paymentMethods: any[]
    showAmexCurrencyError?: boolean
    setShowAmexCurrencyError: React.Dispatch<React.SetStateAction<boolean>>
    form: FormInstance<PaymentDetailsType>
    subscriptionType?: string
    subscriptionStartedDate?: any
    submitting: boolean
    annualSubsPaymentMethod?: string
    isSubscribed?: boolean
    isCreditCardPaymentMethod?: boolean
    initialValues: any
    setdisableCreditCardOption?: React.Dispatch<React.SetStateAction<boolean>>
    setZoozSubscribeMonthly?: any
    paymentDayEditable?: boolean
    isPPDistributor?: boolean
    isPaymentProviderExists?: boolean
}

export const PaymentDetails = (props: PaymentDetailsProps) => {
    const {
        isPP,
        currencyEditable,
        paymentCurrency,
        setPaymentDetails,
        paymentMethod,
        paymentMethods,
        showAmexCurrencyError,
        setShowAmexCurrencyError,
        form,
        subscriptionType,
        subscriptionStartedDate,
        submitting,
        annualSubsPaymentMethod,
        isSubscribed,
        initialValues,
        isCreditCardPaymentMethod,
        setdisableCreditCardOption,
        setZoozSubscribeMonthly,
        paymentDayEditable,
        isPPDistributor,
        isPaymentProviderExists
    } = props
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const watchedCountry = Form.useWatch('country', form)
    const isRequiredTaxID = COUNTRIES_REQUIRED_TAX_ID.includes(watchedCountry)
    const [isFormValueChanged, setFormValueChanged] = useState(false)
    const isAmex = some(paymentMethods, {vendor: VENDOR.AMEX})
    const currencyValue = isCreditCardPaymentMethod ? CURRENCY : ANNUAL_CURRENCY
    let options = useMemo(() => uniqBy([...currencyValue, {value: paymentCurrency, label: paymentCurrency}], 'value'), [
        paymentCurrency
    ])
    if (isPP) {
        options = isCreditCardPaymentMethod ? CURRENCY : ANNUAL_CURRENCY
    }

    const onValuesChange = (changedValues: Partial<PaymentDetailsType>) => {
        setFormValueChanged(true)
        if (Object.prototype.hasOwnProperty.call(changedValues, 'country')) {
            const newCountry = changedValues['country'] as string

            form.setFieldValue('state', renderStates(newCountry)[0].value || undefined)
            form.validateFields(['taxID'])
        }

        if (Object.prototype.hasOwnProperty.call(changedValues, 'currency')) {
            if (isPP) {
                const newCurrency = changedValues['currency'] as string
                if (newCurrency == 'AUD' || newCurrency == 'JPY') {
                    setdisableCreditCardOption != undefined ? setdisableCreditCardOption(true) : null
                } else {
                    setdisableCreditCardOption != undefined ? setdisableCreditCardOption(false) : null
                }
            }
            setShowAmexCurrencyError(false)
        }
    }
    const onFinish = (values: PaymentDetailsType) => {
        if (form.isFieldsTouched()) {
            setPaymentDetails(values)
        }
    }

    useEffect(() => {
        if (!isPP && paymentMethod.status === FULFILLED && !paymentMethod.response.isSubscribe) {
            dispatch(setZoozSubscribeMonthly({provider: PROVIDER.ZOOZ}))
        }
    }, [])

    const currencyExtraProps = showAmexCurrencyError
        ? {
              help: t('validation:amex'),
              validateStatus: 'error' as const
          }
        : {}

    //disable currency selector (CLOUD-21683)
    const isCurrencySelectorDisabled = !currencyEditable

    return (
        <Form
            onFinish={onFinish}
            colon={false}
            initialValues={initialValues}
            form={form}
            onValuesChange={onValuesChange}
        >
            <FieldsWrapper>
                <JumbotronToggle title={t('forms:paymentDetails:name')} padding="19px 30px 0 30px" margin="0px" um>
                    <Row>
                        {!isPP && subscriptionType == PAYMENT_TYPE.ANNUAL && (
                            <Form.Item name="subscriptionDate" label={t('forms:common:fields:startingDate:label')}>
                                <Input placeholder={subscriptionStartedDate()} disabled={true} />
                            </Form.Item>
                        )}
                        <Form.Item
                            name="currency"
                            rules={
                                isAmex ? createRules([amexValidate, requiredValidate]) : createRules([requiredValidate])
                            }
                            label={t('forms:common:fields:currency:label')}
                            initialValue={CURRENCIES.USD}
                            {...currencyExtraProps}
                        >
                            <Select
                                options={options}
                                placeholder={t('forms:common:fields:currency:placeholder')}
                                disabled={isCurrencySelectorDisabled}
                            />
                        </Form.Item>
                        {(isPP || (!isPP && subscriptionType == PAYMENT_TYPE.MONTHLY)) && (
                            <Form.Item
                                name="customerTitle"
                                rules={createRules([requiredValidate])}
                                label={t('forms:common:fields:title:label')}
                                initialValue={TITLE[0].value}
                            >
                                <Select options={TITLE} showSearch={false} allowClear={false} />
                            </Form.Item>
                        )}
                    </Row>
                    {(isPP || subscriptionType == PAYMENT_TYPE.MONTHLY) && (
                        <Row>
                            <Form.Item
                                name="customerFirstName"
                                rules={createRules([requiredValidate, nameValidate])}
                                label={t('forms:common:fields:firstName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="customerLastName"
                                rules={createRules([requiredValidate, nameValidate])}
                                label={t('forms:common:fields:lastName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                        </Row>
                    )}
                    {(isPP || subscriptionType == PAYMENT_TYPE.MONTHLY) && (
                        <Row>
                            <Form.Item
                                name="email"
                                rules={createRules([requiredValidate, emailValidate])}
                                label={t('forms:common:fields:billingEmail:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="taxID"
                                rules={
                                    isRequiredTaxID
                                        ? createRules([requiredValidate, maxBillingFieldLength])
                                        : createRules([maxBillingFieldLength])
                                }
                                required={isRequiredTaxID}
                                label={t('forms:common:fields:taxID:label')}
                            >
                                <Input />
                            </Form.Item>
                        </Row>
                    )}

                    {(isPP || subscriptionType == PAYMENT_TYPE.MONTHLY) && (
                        <Row>
                            <Form.Item
                                name="companyName"
                                rules={createRules([requiredValidate, maxBillingFieldLength])}
                                label={t('forms:common:fields:companyName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                rules={createRules([requiredValidate, maxBillingFieldLength])}
                                label={t('forms:common:fields:addressLine:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                        </Row>
                    )}

                    {isPP ||
                        (subscriptionType == PAYMENT_TYPE.MONTHLY && (
                            <Row>
                                <Form.Item
                                    name="address2"
                                    rules={createRules([maxBillingFieldLength])}
                                    label={t('forms:common:fields:addressLine2:label')}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="address3"
                                    rules={createRules([maxBillingFieldLength])}
                                    label={t('forms:common:fields:addressLine3:label')}
                                >
                                    <Input />
                                </Form.Item>
                            </Row>
                        ))}
                    <Row>
                        <Form.Item
                            name="country"
                            rules={createRules([requiredValidate])}
                            label={t('forms:common:fields:country:label')}
                            required
                        >
                            <Select
                                showSearch
                                options={countries.getObjectSelect()}
                                placeholder={t('forms:common:fields:country:placeholder')}
                                optionFilterProp="label"
                                allowClear={false}
                            />
                        </Form.Item>
                        <Form.Item
                            name="state"
                            rules={hasStates(watchedCountry) ? createRules([requiredValidate]) : []}
                            label={t('forms:common:fields:state:label')}
                            required={hasStates(watchedCountry)}
                        >
                            <Select
                                showSearch
                                disabled={!hasStates(watchedCountry)}
                                options={renderStates(watchedCountry)}
                                placeholder={t('forms:common:fields:state:placeholder')}
                                optionFilterProp="label"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item
                            name="city"
                            rules={createRules([requiredValidate])}
                            label={t('forms:common:fields:city:label')}
                            required
                        >
                            <Input placeholder={t('forms:common:fields:city:placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="zipCode"
                            rules={createRules([requiredValidate, zipCodeValidate])}
                            label={t('forms:common:fields:zip:label')}
                            required
                        >
                            <Input placeholder={t('forms:common:fields:zip:placeholder')} />
                        </Form.Item>
                    </Row>
                    {subscriptionType == PAYMENT_TYPE.ANNUAL && (
                        <Row>
                            <Form.Item
                                name="companyName"
                                rules={createRules([requiredValidate, maxBillingFieldLength])}
                                label={t('forms:common:fields:companyName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="taxID"
                                rules={
                                    isRequiredTaxID
                                        ? createRules([requiredValidate, maxBillingFieldLength])
                                        : createRules([maxBillingFieldLength])
                                }
                                required={isRequiredTaxID}
                                label={t('forms:common:fields:taxID:label')}
                            >
                                <Input />
                            </Form.Item>
                        </Row>
                    )}

                    {subscriptionType == PAYMENT_TYPE.ANNUAL && (
                        <Row>
                            <Form.Item
                                name={
                                    annualSubsPaymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT
                                        ? 'address'
                                        : 'companyAddress'
                                }
                                rules={createRules([requiredValidate, maxBillingFieldLength])}
                                label={t('forms:common:fields:companyAddress:label')}
                                required
                            >
                                <Input placeholder={t('forms:common:fields:companyAddress:placeholder')} />
                            </Form.Item>

                            <Form.Item
                                name="orderNumber"
                                rules={
                                    isRequiredTaxID
                                        ? createRules([requiredValidate, maxBillingFieldLength])
                                        : createRules([maxBillingFieldLength])
                                }
                                label={t('forms:common:fields:purchafesOrderNumber:label')}
                            >
                                <Input placeholder={t('forms:common:fields:purchafesOrderNumber:placeholder')} />
                            </Form.Item>
                        </Row>
                    )}
                    {(isPP || subscriptionType == PAYMENT_TYPE.MONTHLY) && (
                        <Row isSingleData={true}>
                            <Form.Item
                                name="phoneNumber"
                                rules={createRules([requiredValidate, phoneNumberValidate])}
                                label={t('forms:common:fields:phone:label')}
                                required
                            >
                                <Input placeholder={t('forms:common:fields:phone:placeholder')} />
                            </Form.Item>
                        </Row>
                    )}
                    {!isPP && subscriptionType == PAYMENT_TYPE.MONTHLY && (
                        <FormFooter>
                            <Button
                                htmlType="submit"
                                type="primary"
                                style={{margin: '-19px 0px 16px 0px'}}
                                loading={submitting}
                                disabled={!isFormValueChanged}
                            >
                                {t('billing:zoozActions:updateBillingDetails')}
                                {isPP && t('billing:zoozActions:saveInformation')}
                            </Button>
                        </FormFooter>
                    )}
                    {isPP && (
                        <>
                            {paymentDayEditable !== null && (
                                <Row isSingleData={true}>
                                    <Form.Item
                                        name="paymentDay"
                                        rules={createRules([dateValidate, validatePositiveInt])}
                                        label={t('forms:common:fields:paymentDay:label')}
                                    >
                                        <Input
                                            placeholder={t('forms:common:fields:phone:placeholder')}
                                            disabled={!paymentDayEditable}
                                        />
                                    </Form.Item>
                                </Row>
                            )}
                            <FormFooter>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={
                                        isPPDistributor
                                            ? !(isFormValueChanged && isPaymentProviderExists)
                                            : !isFormValueChanged
                                    }
                                    style={{margin: '-19px 0px 16px 0px'}}
                                    loading={submitting}
                                >
                                    {isPPDistributor
                                        ? t('billing:zoozActions:updateBillingDetails')
                                        : t('billing:zoozActions:saveInformation')}
                                </Button>
                            </FormFooter>
                        </>
                    )}

                    <Separator />
                </JumbotronToggle>
                {subscriptionType == PAYMENT_TYPE.ANNUAL && (
                    <JumbotronToggle
                        title={t('forms:paymentDetails:billingContact')}
                        padding="19px 30px 0 30px"
                        margin="0px"
                        um
                    >
                        <Row>
                            <Form.Item
                                name={
                                    annualSubsPaymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT
                                        ? 'customerFirstName'
                                        : 'billingFirstName'
                                }
                                rules={createRules([requiredValidate, nameValidate])}
                                label={t('forms:common:fields:firstName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name={
                                    annualSubsPaymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT
                                        ? 'customerLastName'
                                        : 'billingLastName'
                                }
                                rules={createRules([requiredValidate, nameValidate])}
                                label={t('forms:common:fields:lastName:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                        </Row>

                        <Row>
                            <Form.Item
                                name={
                                    annualSubsPaymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT
                                        ? 'email'
                                        : 'bilingContact'
                                }
                                rules={createRules([requiredValidate, emailValidate])}
                                label={t('forms:common:fields:billingEmail:label')}
                                required
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={
                                    annualSubsPaymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT
                                        ? 'phoneNumber'
                                        : 'billingPhoneNumber'
                                }
                                rules={createRules([requiredValidate, phoneNumberValidate])}
                                label={t('forms:common:fields:phone:label')}
                                required
                            >
                                <Input placeholder={t('forms:common:fields:phone:placeholder')} />
                            </Form.Item>
                        </Row>

                        <FormFooter>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isFormValueChanged}
                                style={{margin: '-19px 0px 16px 0px'}}
                                loading={submitting}
                            >
                                {isSubscribed && !isPP && t('billing:zoozActions:updateBillingDetails')}
                                {!isSubscribed && !isPP && t('billing:zoozActions:completeSubscriptions')}
                            </Button>
                        </FormFooter>
                    </JumbotronToggle>
                )}
            </FieldsWrapper>
        </Form>
    )
}
