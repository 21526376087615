import React from 'react'
import {Spin} from 'antd'

import {Permissions} from 'ca-common/types'
import {TASK_TYPE} from 'ca-common/common/enum'
import {checkLimitedAccess} from 'ca-common/utils/backup/limitedAccess'
import {LIMITED_ACCESS_PAGES} from 'ca-common/common/enum/Backups'

import {JobFields} from 'src/newcore/features/Jobs/types'
import {Job} from 'src/newcore/features/Jobs/molecules'
import {NoJobsFound} from 'src/newcore/features/Jobs/atoms'
import {isPagePermitted, isBackupPermitted} from 'src/newcore/utils/permissions'

import {StyledJobsList} from './StyledJobsList'

type JobsListProps = {
    jobs?: JobFields[]
    loading: boolean
    totalCount?: number
    jobCanceling: any
    userPermissions?: Permissions
    isMsEndUser?: boolean
}

export const JobsList = (props: JobsListProps): JSX.Element => {
    const {jobs, loading, totalCount, jobCanceling, userPermissions, isMsEndUser} = props
    return (
        <Spin spinning={loading}>
            <StyledJobsList>
                {totalCount && totalCount > 0 ? (
                    jobs?.map(job => (
                        <Job
                            job={job}
                            key={job.id}
                            isMsEndUser={isMsEndUser}
                            activityLogPermitted={isPagePermitted('ACCOUNT_ACTIVITY.ACCESS', userPermissions)}
                            jobCanceling={jobCanceling}
                            cancelPermitted={isBackupPermitted(`${job.source}.CANCEL`, userPermissions)}
                            downloadReportPermitted={
                                job.taskType === TASK_TYPE.RESTORE
                                    ? isBackupPermitted(`${job.source}.RESTORE`, userPermissions)
                                    : isBackupPermitted(`${job.source}.DOWNLOAD`, userPermissions) &&
                                      !checkLimitedAccess(LIMITED_ACCESS_PAGES.JOB_LIST, job)
                            }
                        />
                    ))
                ) : (
                    <NoJobsFound />
                )}
            </StyledJobsList>
        </Spin>
    )
}
