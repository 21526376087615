import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal} from 'antd'

import {BACKUP_FREQUENCY} from 'ca-common/utils/datetime'
import {Button} from 'ca-common/ui-lib/atoms'

import {PANEL_KEY} from 'src/newcore/features/BackupSettings/lib'

import {StyledFooter, StyledControl} from '../StyledFooter'

export const SingleEntityFooter = props => {
    const {activeKey, clearRemovedRulesList, cached, loading, togglePanel, additionalActions, form} = props
    const {t} = useTranslation()
    const isFieldsTouched = form.isFieldsTouched()

    const reset = () => {
        form.resetFields()
        togglePanel()

        clearRemovedRulesList()
    }

    const onSubmit = () => {
        if (
            form.isFieldTouched('backupFrequency') &&
            form.getFieldValue('backupFrequency') === BACKUP_FREQUENCY.DAILY3TIMES
        ) {
            Modal.confirm({
                title: <Trans i18nKey="backups:bulkActivation:notification:title" />,
                content: `${t('backups:bulkActivation:notification:headerTitle')}  ${t(
                    'backups:bulkActivation:notification:description'
                )}`,
                okText: t('modals:buttons:continue'),
                onOk: () => form.submit(),
                closable: true,
                width: 620
            })
        } else {
            form.submit()
        }
    }
    return (
        <StyledFooter>
            <StyledControl>
                {activeKey === PANEL_KEY ? (
                    <div>
                        <Button type="link" onClick={reset}>
                            {t('forms:common:actions:cancel')}
                        </Button>

                        {cached ? (
                            <Button type="primary" onClick={form.submit} loading={loading}>
                                {t('forms:common:actions:activate')}
                            </Button>
                        ) : (
                            <Button disabled={!isFieldsTouched} type="primary" onClick={onSubmit} loading={loading}>
                                {t('forms:common:actions:save')}
                            </Button>
                        )}
                    </div>
                ) : (
                    <Button type="default" onClick={togglePanel}>
                        {t('forms:common:actions:edit')}
                    </Button>
                )}
                {isFieldsTouched && t('forms:backupSettings:fieldChanged')}
                {additionalActions}
            </StyledControl>
        </StyledFooter>
    )
}

SingleEntityFooter.propTypes = {
    clearRemovedRulesList: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    cached: PropTypes.bool,
    activeKey: PropTypes.string,
    additionalActions: PropTypes.node
}

SingleEntityFooter.defaultProps = {
    cached: false,
    additionalActions: null,
    activeKey: '',
    loading: false
}
