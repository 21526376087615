import {createSelector} from '@reduxjs/toolkit'

import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import {AppState} from 'src/newcore/components/Root/newAppStore'
import {postLessData} from 'src/newcore/utils/rest'
import {ANONYMIZATION_OBJECTS_TAG} from 'src/newcore/redux/tags'

import type {
    GetAnonymizationObjectsPayload,
    GetObjectsResponse,
    ResponseData
} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'

const GET_ANONYMIZATION_OBJECTS_ENDPOINT_NAME = 'getObjects'

export const dataAnonymizationApi = rtkQueryBaseApi
    .enhanceEndpoints({addTagTypes: [ANONYMIZATION_OBJECTS_TAG.type]})
    .injectEndpoints({
        endpoints: build => ({
            [GET_ANONYMIZATION_OBJECTS_ENDPOINT_NAME]: build.query<
                GetObjectsResponse,
                {payload?: GetAnonymizationObjectsPayload}
            >({
                query: ({payload = []}) => ({
                    url: 'getAnonymizationObjects',
                    method: postLessData,
                    data: payload
                }),
                transformResponse: (response: GetObjectsResponse) => {
                    return response
                },
                providesTags: [ANONYMIZATION_OBJECTS_TAG]
            })
        })
    })

export const {useGetObjectsQuery} = dataAnonymizationApi

export const objectsListSelector = createSelector(
    (state: AppState) => state.backups.seeding.objectsList?.response?.data,
    (obj: Record<string, boolean | {isDeleted: boolean}> = {}) => {
        return Object.entries(obj).reduce((acc, [objectName, value]) => {
            if ((typeof value === 'boolean' && value) || (typeof value === 'object' && !value.isDeleted)) {
                acc.push(objectName)
            }

            return acc
        }, [] as string[])
    }
)

export const anonymizationObjectsSelector = createSelector(
    (state: AppState) => state.api,
    ({provided, queries}: any) => {
        const key = provided?.[ANONYMIZATION_OBJECTS_TAG.type]?.[ANONYMIZATION_OBJECTS_TAG.id]?.[0]
        return queries?.[key]?.data?.data as ResponseData[]
    }
)
