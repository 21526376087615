import React from 'react'
import {useTranslation} from 'react-i18next'
import variables from 'ca-common/variables'

import {LinkOutlined, DisconnectOutlined} from '@ant-design/icons'
import {Button} from 'ca-common/ui-lib/atoms/Button'

export const BackupAuthStatus = props => {
    const {isAuthenticated = false} = props
    const {t} = useTranslation()

    const onClick = e => {
        e.stopPropagation()

        props.onClick()
    }

    return (
        <Button color={isAuthenticated ? variables.green : variables.red} onClick={onClick} shape="round">
            {isAuthenticated ? t('backups:typeConnection:connected') : t('backups:typeConnection:disconnected')}
            {isAuthenticated ? <LinkOutlined /> : <DisconnectOutlined />}
        </Button>
    )
}
