import React from 'react'
import {Row} from 'antd'
import {withTranslation} from 'react-i18next'

import PasswordResetExpired from 'ca-common/icons/PasswordResetExpired.svg'
import {showMultiline} from 'ca-common/utils/text'

import {StyledHeader, StyledContentContainer, StyledButtonText} from './StyledResetPassword'
import {StyledIcon, StyledText, StyledTextLink, StyledResetLink} from './StyledResetPasswordFailed'

export const ResetPasswordFailed = withTranslation()(({t, signIn, resetPassword}) => {
    return (
        <StyledContentContainer>
            <StyledHeader>{t('passwordResetFailed:linkExpired')}</StyledHeader>
            <Row type="flex" justify="center">
                <StyledIcon component={PasswordResetExpired} />
            </Row>
            <Row type="flex" justify="center">
                <StyledText>{showMultiline(t('passwordResetFailed:hasFailed'))}</StyledText>
            </Row>
            <Row type="flex" justify="center">
                <StyledResetLink to={resetPassword.to}>
                    <StyledButtonText>{t('passwordResetFailed:resetPasswordButton')}</StyledButtonText>
                </StyledResetLink>
            </Row>
            <Row type="flex" justify="center">
                <StyledTextLink {...signIn}>{t('logout:loginLink')}</StyledTextLink>
            </Row>
        </StyledContentContainer>
    )
})
