import React from 'react'

import {CACellModifier} from 'ca-common/ui-lib/components/TableElements/index'

import './CheckCell.scss'

export class CheckCell extends React.Component {
    render() {
        const {item, configColumn} = this.props

        return (
            <CACellModifier modifier={'centered'}>
                {item[configColumn.name] && <span className="checkbox-cell">&#10003;</span>}
            </CACellModifier>
        )
    }
}
