import styled from 'styled-components'
import {transparentize} from 'polished'
import {Card, Form} from 'antd'
import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    .ant-form-item-label {
        text-align: left;
    }
`
export const StyledAccountDestroyForm = styled(Form)`
    .ant-form-item-label {
        width: 35%;
        text-align: left;
    }
`

export const StyledButton = styled(Button)`
    margin-left: ${variables.gutterSm};
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 70px;
    padding-top: 12px;
    border-top: 1px solid ${variables.blue};
`

export const StyledHeader = styled.span`
    font-family: ${variables.publicPageFont};
    font-weight: 600;
    font-size: ${variables.subTitleFontSize};
`

export const StyledCard = styled(Card)`
    margin-bottom: 12px;
`

export const TooltipWrapper = styled.div<{disabled: boolean}>`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
    opacity: ${props => (props.disabled ? 0.5 : 'initial')};

    > .ca-jumbotron {
        pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
    }
`

export const StyledDestroyHeader = styled.h3`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.headingFontSize};
    font-weight: 500;
    line-height: 28px;
    color: ${variables.text};
    margin-bottom: ${variables.gutterSm};
`

export const StyledDestroyDescription = styled.p`
    font-family: ${variables.publicPageFont};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${variables.text};
`

export const StyledFormItem = styled(Form.Item)`
    .ant-form-item-label {
        width: 150px;
        margin-right: ${variables.gutterMd};

        font-family: ${variables.publicPageFont};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        color: ${variables.text};
    }

    .ant-form-item-control-input {
        width: 310px;
    }
`

export const StyledNewEmailFormItem = styled(StyledFormItem)`
    .ant-form-item-label {
        width: 208px;
    }
`

export const StyledEmailWrapper = styled.div<{changing: boolean}>`
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-bottom: ${props => (props.changing ? `20px` : '8px')};

    .ant-form-item {
        margin-bottom: 0;
    }
`

export const StyledNewEmailWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (min-width: ${variables.screenMd}) {
        flex-wrap: nowrap;
    }
`

export const StyledEmailExtra = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeSecondary};
    font-weight: 300;
    line-height: 17px;
    color: ${variables.asphalt};
`

export const StyledEmailExtraBold = styled.span`
    font-weight: 400;
`

export const StyledConfirmationActionButton = styled.button`
    background-color: transparent;
    border: none;
    font-family: ${variables.publicPageFont};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    text-decoration-line: underline;
    color: ${variables.black};

    &:hover {
        color: ${variables.navyBlue};
        cursor: pointer;
    }
`

export const StyledConfirmationNote = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-bottom: 30px;
    padding: 15px;
    background-color: ${transparentize(0.9, variables.orange)};
    font-weight: 400;

    @media screen and (min-width: ${variables.screenMd}) {
        flex-wrap: nowrap;
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin-left: 174px;
    }
`

export const StyledConfirmationNoteTitle = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0;
    margin-right: 4px;
`

export const StyledModalContent = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 156px;

    span {
        font-family: ${variables.publicPageFont};
        font-size: ${variables.subTitleFontSize};
        font-weight: 300;
        line-height: 27px;
        letter-spacing: 0;
        text-align: center;
    }
`

export const StyledResendMessage = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
`
export const StyledDestroyCheckbox = styled.div`
    .ant-form-item-row {
        display: flex;
        flex-direction: row-reverse;
        .ant-form-item-label {
            width: 93%;
        }
    }
`
