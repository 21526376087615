/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {ButtonProps} from 'antd/lib/button'
import variables from 'ca-common/variables'
import {StyledButton, StyledButtonState, StyledHoverReverseButton} from './StyledButton'

export type ButtonWithState = ButtonProps & {
    visible?: boolean
    active?: boolean
    color?: string
    align?: string
    borderWidth?: number
    selected?: boolean
    width?: number
}

export const Button = ({...rest}: ButtonWithState): JSX.Element => <StyledButton visible={true} {...rest} />

export const ButtonState = ({type, ...rest}: ButtonWithState): JSX.Element => (
    <StyledButtonState visible={true} active={false} color={variables.brand} {...rest} />
)

export const ButtonHoverReverse = React.forwardRef((props: ButtonWithState, ref) => {
    const {type, ...rest} = props

    return <StyledHoverReverseButton visible={true} active={false} color={variables.brand} {...rest} {...ref} />
})
