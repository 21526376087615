import _ from 'lodash'
import React from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'

import {Button} from 'ca-common/ui-lib/atoms/Button'

import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'

import {
    getAvailableStatusesByTaskAction,
    getEntitiesStatusRequest,
    getEntitiesSum,
    getPerformAvailableEntities
} from 'ca-common/utils/backup/backupActions'

import {clearEntitiesStatus, getEntitiesStatus} from 'src/newcore/redux/modules/backups/backupEntities'
import {Header, Footer, StatusResult} from '../StyledConfirmAction'
import {getServiceNameMixpanel, track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

@withTranslation()
class DefaultRaw extends React.Component {
    componentDidMount() {
        const {
            action,
            actions,
            location,
            selectedAll,
            selectedRowKeys,
            taskSettings: {taskId}
        } = this.props

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        actions.getEntitiesStatus(
            getEntitiesStatusRequest(action, taskId, selectedAll, selectedRowKeys, getRequestParamsFromUrl(queryParams))
        )
    }
    handleActivateClick = availableEntities => {
        const {entities, taskSettings, action, onSubmit} = this.props
        onSubmit(action)
        track(EVENT_NAMES.ENTITY_BACKUP_ACTIVATION, {
            Service: getServiceNameMixpanel(taskSettings.source),
            'Number of Activated Entities': availableEntities,
            'Number of Discovered Entities': entities.totalTaskEntities,
            'Number of Previously Activated Entities': entities.activeEntities,
            'Activation Option': 'Action Menu'
        })
    }

    componentWillUnmount() {
        this.props.actions.clearEntitiesStatus()
    }

    render() {
        const {t, entitiesStatus, taskSettings, action, loading, onCancel} = this.props

        const availableEntities = getPerformAvailableEntities(
            getAvailableStatusesByTaskAction(action),
            _.get(entitiesStatus, 'response.data')
        )
        const sum = getEntitiesSum(_.get(entitiesStatus, 'response.data'))
        const entityName = _.toLower(getSvcAccountsDescription(taskSettings.source, true))

        return (
            <Spin spinning={'pending' === entitiesStatus.status}>
                <Header>
                    <StatusResult>
                        {t(`modals:performAction:statusResult:${action}`, {
                            availableEntities,
                            sum,
                            entityName
                        })}
                    </StatusResult>
                    <BackupTaskAction action={action} withTitle={false} />
                </Header>

                <Footer>
                    <Button onClick={onCancel} type="link">
                        {t('modals:buttons:cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            this.handleActivateClick(availableEntities)
                        }}
                        disabled={!availableEntities}
                        loading={loading}
                        type={'primary'}
                    >
                        {t(`backups:newActions:${action}`)}
                    </Button>
                </Footer>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    entitiesStatus: state.backups.backupEntities.entitiesStatus,
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getEntitiesStatus,
            clearEntitiesStatus
        },
        dispatch
    )
})

export const Default = withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultRaw))
