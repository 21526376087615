import {Form} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSecurityForm = styled(Form)`
    .ant-form-item-label {
        width: 35%;
        text-align: left;
    }
`
export const StyledFooter = styled.div`
    display: flex;
    height: 65px;
    border-top: 1px solid ${variables.blue};
    justify-content: right;
    button {
        align-self: center;
        margin-left: 18px;
    }
`
