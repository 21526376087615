import {createAction, createReducer} from '@reduxjs/toolkit'

const INIT_TABLE_ALL_SELECTED = false

export const makeTableAllSelected = (reducerName: string, init = INIT_TABLE_ALL_SELECTED) => {
    const [set, reset] = [`SET_TABLE_ALL_SELECTED_${reducerName}`, `RESET_TABLE_ALL_SELECTED_${reducerName}`]

    const actions = {
        set: createAction<boolean>(set),
        reset: createAction(reset)
    }

    const reducer = createReducer(init, builder => {
        builder.addCase(actions.set, (state, action) => action.payload)

        builder.addCase(actions.reset, () => init)
    })

    return {
        reducer,
        actions
    }
}
