import {useEffect, useRef} from 'react'

import {SOURCE} from 'ca-common/common/enum'

import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {changeConfig} from 'src/newcore/redux/modules/wizard'

import {DEFAULT_PAGE, useWriteEntitiesToWizardDataAndClean} from 'src/newcore/features/OneClickActivation/lib'

type UseEntitiesSelectionTabInit = {
    enabledEntitiesBitSet: string
    service: SOURCE
    fetchEntities: (params: {search: string; page?: number; service: SOURCE}) => void
    cancelFetchEntities: () => void
    search: string
    writeEntitiesToWizardDataAndClean: ReturnType<typeof useWriteEntitiesToWizardDataAndClean>
    setTotalSelectedEntitiesFromBitSet: (value: string) => void
}

type BeforeTabChangedFnType = () => void

export const useEntitiesSelectionTabInit = ({
    search,
    service,
    enabledEntitiesBitSet,
    cancelFetchEntities,
    fetchEntities,
    setTotalSelectedEntitiesFromBitSet,
    writeEntitiesToWizardDataAndClean
}: UseEntitiesSelectionTabInit) => {
    const dispatch = useAppDispatch()
    const beforeStepChangedRef = useRef<null | BeforeTabChangedFnType>(null)

    useEffect(() => {
        beforeStepChangedRef.current = () => {
            writeEntitiesToWizardDataAndClean({enabledEntitiesBitSet, cancelFetchEntities})
        }
    }, [enabledEntitiesBitSet, cancelFetchEntities, writeEntitiesToWizardDataAndClean])

    useEffect(() => {
        fetchEntities({search, service, page: DEFAULT_PAGE})

        setTotalSelectedEntitiesFromBitSet(enabledEntitiesBitSet)

        dispatch(
            changeConfig({
                beforeStepChange: () => beforeStepChangedRef.current && beforeStepChangedRef.current()
            })
        )
    }, [])
}
