import styled, {css} from 'styled-components'
import {transparentize} from 'polished'

import {TASK_STATUS, JOBS_STATUSES} from 'ca-common/common/enum'
import variables from 'ca-common/variables'

function handleTextColor(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.SUCCESS:
        case JOBS_STATUSES.SUCCEEDED:
            return variables.green
        case TASK_STATUS.FAILED:
        case JOBS_STATUSES.FAILED:
            return variables.red
        case TASK_STATUS.PARTIAL:
        case JOBS_STATUSES.PARTIALLY_SUCCEEDED:
            return variables.orange
        case TASK_STATUS.SCHEDULED:
        case JOBS_STATUSES.CANCELLED:
        case JOBS_STATUSES.CANCELLING:
            return variables.asphaltLight
        case JOBS_STATUSES.PENDING:
            return variables.inProcess
        default:
            return ''
    }
}

function handleBackgroundColor(status: TASK_STATUS | JOBS_STATUSES) {
    switch (status) {
        case TASK_STATUS.SUCCESS:
        case JOBS_STATUSES.SUCCEEDED:
            return transparentize(0.9, variables.green)
        case TASK_STATUS.FAILED:
        case JOBS_STATUSES.FAILED:
            return transparentize(0.9, variables.red)
        case TASK_STATUS.PARTIAL:
        case JOBS_STATUSES.PARTIALLY_SUCCEEDED:
            return transparentize(0.9, variables.orange)
        case TASK_STATUS.SCHEDULED:
        case JOBS_STATUSES.CANCELLED:
        case JOBS_STATUSES.CANCELLING:
            return transparentize(0.8, variables.asphaltLight)
        default:
            return ''
    }
}

type StatusProps = {
    status: TASK_STATUS | JOBS_STATUSES
}
export const Status = styled.span<StatusProps>`
    border-radius: 35px;
    display: inline-block;
    padding: 2px 10px;

    color: ${({status}) => handleTextColor(status)};
    background: ${({status}) => handleBackgroundColor(status)};

    ${props =>
        props.status === JOBS_STATUSES.PENDING &&
        css`
            border-color: ${variables.inProcess};
            border-style: solid;
            border-width: 1px;
        `}
`
