import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SEARCH_ITEMS_TO_RESTORE = 'GET_SEARCH_ITEMS_TO_RESTORE'

const getSearchItemsToRestoreWrapper = fetchWrapperRT(
    GET_SEARCH_ITEMS_TO_RESTORE,
    async data => await post('searchItemsToRestoreCommand', data)
)
export const getSearchItemsToRestore = getSearchItemsToRestoreWrapper.fetcher

const itemsSlice = createSlice({
    name: 'items',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSearch: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getSearchItemsToRestoreWrapper.makeReducers(builder)
})

export const {clearSearch} = itemsSlice.actions

export const searchItemsReducer = combineReducers({
    items: itemsSlice.reducer
})
