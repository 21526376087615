import React from 'react'
import {Checkbox} from 'antd'
import {RecoveryItemsTableProps} from 'src/newcore/features/Recovery/molecules/RecoveryItemsTable'
import type {TableDataItems} from 'src/newcore/features/Recovery/fetch'
import type {CheckboxChangeEvent} from 'antd/es/checkbox'

type TableHeaderCheckboxProps = {
    checkedTableItems: RecoveryItemsTableProps<TableDataItems>['checkedItems']
    selectAllHandler: (e: CheckboxChangeEvent) => void
}

export const TableHeaderCheckbox = ({checkedTableItems, selectAllHandler}: TableHeaderCheckboxProps) => (
    <Checkbox
        checked={checkedTableItems?.checked === true && !checkedTableItems?.unchecked?.length}
        indeterminate={
            checkedTableItems?.unchecked?.length > 0 ||
            (checkedTableItems?.checked !== true && checkedTableItems?.checked?.length > 0)
        }
        onChange={selectAllHandler}
    />
)
