export function getMeta(metaName: META_TAGS): string {
    const metas = document.getElementsByTagName('meta')

    for (const meta of metas) {
        if (meta.getAttribute('name') === metaName) {
            return meta.getAttribute('content') || ''
        }
    }

    return ''
}

export enum META_TAGS {
    CSRF = '_csrf',
    CSRF_HEADER = '_csrf_header'
}
