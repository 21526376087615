import {TFunctionResult} from 'i18next'
import React from 'react'
import {StyledLabel, StyledRow, StyledText} from './StyledConfirmationRow'

type Props = {
    label: string
    content: string | number | TFunctionResult
}

export const ConfirmationRow = ({label, content}: Props): JSX.Element => (
    <StyledRow>
        <StyledLabel>{label}</StyledLabel>
        <StyledText>{content}</StyledText>
    </StyledRow>
)
