import React from 'react'
import {CheckOutlined, WarningOutlined} from '@ant-design/icons'

import {StyledValidateStatus} from './StyledValidateStatus'
import {VALIDATE_STATUS} from 'ca-common/common/enum/ValidateStatus'

export const ValidateStatus = ({formValidateStatus}) => {
    return (
        <StyledValidateStatus validateStatus={formValidateStatus.validateStatus}>
            {(() => {
                switch (formValidateStatus.validateStatus) {
                    case VALIDATE_STATUS.ERROR:
                        return <WarningOutlined />
                    case VALIDATE_STATUS.SUCCESS:
                        return <CheckOutlined />
                    default:
                        return null
                }
            })()}

            <span>{formValidateStatus.message}</span>
        </StyledValidateStatus>
    )
}
