import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import intersection from 'lodash/intersection'
import omitBy from 'lodash/omitBy'
import difference from 'lodash/difference'
import {useParams} from 'react-router-dom'

import {Button} from 'ca-common/ui-lib/atoms'
import {RECOVERY_TYPE, SOURCE} from 'ca-common/common/enum'
import {BackLink, CancelLink, LinkWrapper, ModalFlexWrapper, ModalFooter} from 'ca-common/components/Modal'
import {MSTaskEntityType, TODO_ANY} from 'ca-common/types'
import {PAGES} from 'ca-common/constants'

import {getBackupEntities} from 'src/newcore/features/RecoveryWizard/redux'
import {changeData, closeWizard, nextStep, previousStep} from 'src/newcore/redux/modules/wizard'
import {
    SelectiveRecoverySubservicesSelection,
    SubservicesSelection
} from 'src/newcore/features/RecoveryWizard/molecules/SubservicesSelection'
import {RestoreDestinationSelection} from 'src/newcore/features/RecoveryWizard/molecules/RestoreDestinationSelection'
import {ExportTargetSelection} from 'src/newcore/features/RecoveryWizard/molecules/ExportTargetSelection'
import {
    EMAIL_FORMAT,
    FULL_RECOVERY_ERRORS,
    getSelectedSubsources,
    INITIAL_STORAGE_SETTINGS,
    INITIAL_SUBSERVICES_STATE,
    RecoveryErrorType,
    SubsourceFormat,
    SubsourcesBooleanState,
    TARGET_DESTINATION,
    TargetsWithSettings
} from 'src/newcore/features/RecoveryWizard/lib'
import type {SelectiveRecoveryState} from 'src/newcore/features/Recovery/localState'
import {useRestoreOptions} from 'src/newcore/features/RecoveryWizard/hooks/useRestoreOptions'

type RestoreOrExportProps = {
    fullRecoveryOptions: RECOVERY_TYPE
    entity: MSTaskEntityType
    taskId: string
    allEntities: {entity: string}[]
    error: RecoveryErrorType
    selectiveRecovery?: SelectiveRecoveryState
    from?: string
    userInfo: Record<string, any>
}
type Props = ReturnType<typeof mapDispatchToProps> & RestoreOrExportProps

const FullMailBoxRestoreOrExportRaw = ({
    fullRecoveryOptions,
    actions,
    entity,
    taskId,
    allEntities,
    error,
    selectiveRecovery,
    userInfo,
    from
}: Props): JSX.Element => {
    const {t} = useTranslation()

    const isRestore = fullRecoveryOptions === RECOVERY_TYPE.RESTORE

    const excludeSubservices = useMemo((): SubsourcesBooleanState => {
        const SubserviceWithoutExcludedList = difference(INITIAL_SUBSERVICES_STATE, entity?.excludedSub || [])
        return SubserviceWithoutExcludedList.reduce(
            (object, key) => ({...object, [key]: true}),
            {} as SubsourcesBooleanState
        )
    }, [entity?.excludedSub])
    const selectedSubsources = getSelectedSubsources(selectiveRecovery)

    const [subServices, setSubServices] = useState(
        selectiveRecovery ? selectedSubsources.subSourcesState : excludeSubservices
    )
    const [mailboxEmailSettings, setMailboxEmailSettings] = useState({})
    const [format, setFormat] = useState<SubsourceFormat>(EMAIL_FORMAT.PST)
    const [targetAccount, setTargetAccount] = useState(entity?.entity)
    const [storageType, setStorageType] = useState(TARGET_DESTINATION.CLOUDALLY_S3)
    const [additionalStorageSettings, setAdditionalStorageSettings] = useState(INITIAL_STORAGE_SETTINGS)
    const {restoreOptions, setRestoreOptions} = useRestoreOptions(isRestore ? subServices : null)
    const {entityId} = useParams<{taskId?: string; entityId?: string}>()

    const saveChanges = () =>
        actions.changeData({
            emailSettings: mailboxEmailSettings,
            format,
            subServices,
            targetAccount: targetAccount || entity.entity,
            storageType,
            additionalStorageSettings,
            selectiveRecovery,
            restoreOptions
        })
    const settingsIncomplete = useMemo(() => Object.values(additionalStorageSettings).some(value => !value), [
        additionalStorageSettings
    ])

    const checkSubServices = useCallback(
        (error: RecoveryErrorType) => ({
            ...error,
            sourcesAlreadyExist: intersection(
                error?.sourcesAlreadyExist,
                Object.keys(omitBy(subServices, value => !value))
            )
        }),
        [subServices, error]
    )

    const filteredSubServiceError = useMemo(() => checkSubServices(error), [subServices, error])

    const isRestoreError = useMemo(
        () => isRestore && (!targetAccount || error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.INVALID_PRINCIPAL),
        [targetAccount, error, isRestore]
    )

    const isExportError = useMemo(() => !isRestore && TargetsWithSettings.includes(storageType) && settingsIncomplete, [
        isRestore,
        storageType,
        settingsIncomplete
    ])

    const nextDisabled = useMemo(
        () =>
            !Object.values(subServices).includes(true) ||
            isRestoreError ||
            isExportError ||
            Boolean(filteredSubServiceError?.sourcesAlreadyExist.length),
        [subServices, isRestoreError, isExportError, filteredSubServiceError]
    )

    useEffect(() => {
        actions.getBackupEntities({
            taskId,
            rows: 10000,
            page: 1,
            source: SOURCE.MS365,
            ...(userInfo?.isMsEndUser ? {entitiesIds: [entityId]} : {})
        })
    }, [])

    useEffect(() => {
        actions.changeData({error: undefined})
    }, [targetAccount])

    useEffect(() => {
        if (error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.ACCESS_DENIED) actions.changeData({error: undefined})
    }, [storageType, additionalStorageSettings])

    const handleCancel = () => {
        actions.closeWizard()
    }

    const handleNextStep = () => {
        saveChanges()
        actions.nextStep()
    }

    const handlePreviousStep = () => {
        saveChanges()
        actions.previousStep()
    }

    return (
        <>
            <ModalFlexWrapper minHeight={443} isSelective={Boolean(selectiveRecovery)}>
                {selectiveRecovery ? (
                    <SelectiveRecoverySubservicesSelection
                        fullRecoveryOptions={fullRecoveryOptions}
                        settings={mailboxEmailSettings}
                        setSettings={setMailboxEmailSettings}
                        format={format}
                        setFormat={setFormat}
                        subServices={subServices}
                        setSubServices={setSubServices}
                        error={filteredSubServiceError}
                        selectedSubsources={selectedSubsources}
                    />
                ) : (
                    <SubservicesSelection
                        fullRecoveryOptions={fullRecoveryOptions}
                        settings={mailboxEmailSettings}
                        setSettings={setMailboxEmailSettings}
                        subServices={subServices}
                        setSubServices={setSubServices}
                        error={filteredSubServiceError}
                    />
                )}
                {isRestore ? (
                    <RestoreDestinationSelection
                        account={entity.entity}
                        targetAccount={targetAccount}
                        setTargetAccount={setTargetAccount}
                        allEntities={allEntities}
                        error={error}
                        restoreOptions={restoreOptions}
                        setRestoreOptions={setRestoreOptions}
                        isSelective={Boolean(selectiveRecovery)}
                        isMsEndUser={userInfo?.isMsEndUser}
                    />
                ) : (
                    <ExportTargetSelection
                        storageType={storageType}
                        setStorageType={setStorageType}
                        setAdditionalStorageSettings={setAdditionalStorageSettings}
                        error={error}
                    />
                )}
            </ModalFlexWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button disabled={nextDisabled} type="primary" onClick={handleNextStep}>
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                {from !== PAGES.RECOVERY && <BackLink onClick={handlePreviousStep} />}
            </ModalFooter>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    fullRecoveryOptions: state.wizard.data.fullRecoveryOptions,
    error: state.wizard.data.error,
    entity: state.wizard.data.entity,
    allEntities: state.backups.backupEntities.allEntities.response?.data,
    taskId: state.wizard.data.taskId,
    selectiveRecovery: state.wizard.data.selectiveRecovery,
    from: state.wizard.data.from,
    userInfo: state.userInfo?.response
})

const mapDispatchToProps = (dispatch: TODO_ANY) => ({
    actions: bindActionCreators(
        {
            nextStep,
            previousStep,
            closeWizard,
            changeData,
            getBackupEntities
        },
        dispatch
    )
})

export const FullMailBoxRestoreOrExport = connect(mapStateToProps, mapDispatchToProps)(FullMailBoxRestoreOrExportRaw)
