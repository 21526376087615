import {post} from 'src/newcore/utils/rest'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'

const RESET_PASSWORD = 'RESET_PASSWORD'
const RESET_PASSWORD_API = 'resetPassword'
const resetPasswordWrapper = fetchWrapperRT(RESET_PASSWORD, async data => await post(RESET_PASSWORD_API, data))

export const resetPassword = resetPasswordWrapper.fetcher

const RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM'
const RESET_PASSWORD_CONFIRM_API = 'resetPasswordConfirm'
const resetPasswordConfirmWarpper = fetchWrapperRT(
    RESET_PASSWORD_CONFIRM,
    async data => await post(RESET_PASSWORD_CONFIRM_API, data)
)

export const resetPasswordConfirm = resetPasswordConfirmWarpper.fetcher
