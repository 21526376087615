import {createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'

import type {Id} from 'ca-common/types'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'

const GET_TASK_INFO_RECOVERY = 'GET_TASK_INFO_RECOVERY'
const TASK_INFO_API = 'getBackupTaskInfo'
const getTaskInfoWrapper = fetchWrapperRT(GET_TASK_INFO_RECOVERY, async data => await get(TASK_INFO_API, data))
export const getTaskInfo = (data: {taskId: Id}) => (dispatch: AppDispatch) => {
    return dispatch(
        getTaskInfoWrapper.fetcher({
            _search: false,
            rows: 10000,
            page: 1,
            sord: 'asc',
            ...data
        })
    )
}

const taskInfoSlice = createSlice({
    name: 'taskInfo',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearTaskInfo: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getTaskInfoWrapper.makeReducers(builder)
})

export const {clearTaskInfo} = taskInfoSlice.actions
export const taskInfo = taskInfoSlice.reducer
