import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledCoreLink = styled(Button)`
    color: ${variables.white};
    width: 92px;
    padding: 0;
    display: flex;
    border-radius: 0;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        color: ${variables.white};

        .anticon {
            color: ${variables.white};
        }
    }
`

export const BackButtonLink = styled(StyledCoreLink)`
    && {
        height: 60px;
        font-family: ${variables.publicPageFont};
        font-weight: 400;
        font-size: ${variables.fontSizeBase};

        &:hover *,
        &:focus * {
            color: ${variables.iceBlue};
        }

        &:active * {
            color: ${variables.brightBlue};
        }
    }
`

export const StyledIcon = styled(Icon)`
    width: 16px;
    font-size: 16px;
    height: 16px;
`
