import {ACTIVITY_STATUS} from 'ca-common/common/enum/Statuses'

export const FIELD_SEARCH_QUERY = 'searchQuery'
export const FIELD_OBJECT_STATUS = 'statuses'
export const FIELD_OBJECT_TYPE = 'types'

export const TYPES_DATA = 'DATA'
export const TYPES_METADATA = 'METADATA'

export const COLUMN_NAMES = {
    TYPE: 'type',
    NAME: 'name',
    TOTAL: 'total',
    STATUS: 'status',
    LAST_SNAPSHOT: 'lastSnapshot',
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted'
}

export const TYPES_OPTIONS = [TYPES_DATA, TYPES_METADATA]

export const SORT_NAMES = {
    [COLUMN_NAMES.TYPE]: 'TYPE',
    [COLUMN_NAMES.NAME]: 'OBJECT_NAME',
    [COLUMN_NAMES.TOTAL]: 'TOTAL',
    [COLUMN_NAMES.STATUS]: 'STATUS',
    [COLUMN_NAMES.LAST_SNAPSHOT]: 'LAST_SNAPSHOT',
    [COLUMN_NAMES.ADDED]: 'ADDED',
    [COLUMN_NAMES.UPDATED]: 'UPDATED',
    [COLUMN_NAMES.DELETED]: 'DELETED'
}

export const N_A = -1
export const DASH = -2

export const STATUS_OPTIONS = [
    ACTIVITY_STATUS.SUCCEED,
    ACTIVITY_STATUS.PARTIALLY_SUCCEED,
    ACTIVITY_STATUS.FAILED,
    ACTIVITY_STATUS.ARCHIVED
]
