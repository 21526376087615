import React, {Fragment} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import Icon from '@ant-design/icons'
import {Modal} from 'antd'
import {withTranslation, useTranslation} from 'react-i18next'

import {eatModal} from 'ca-common/redux/modules/modal'
import CAButton from 'ca-common/ui-lib/components/Button'
import {renderTo} from 'ca-common/utils/render'
import ArrowLeft from 'ca-common/icons/ArrowLeft.svg'

import {BackLinkWrapper} from './StyledModal'

export const CA_DIALOG_TYPE = {
    OK: 'OK',
    SAVE: 'SAVE',
    YESNO: 'YESNO'
}

class CAModalRaw extends React.Component {
    onClose = closeAction => {
        if (closeAction) {
            closeAction()
        }

        this.props.actions.eatModal()
    }
    render() {
        const {modals, t} = this.props

        if (!modals || Object.keys(modals).length === 0) {
            return null
        }

        return (
            <Modal
                open
                title={modals.title}
                onCancel={() => this.onClose(modals.onClose)}
                onOk={modals.accept}
                footer={
                    modals.isShowFooter && modals.type === CA_DIALOG_TYPE.YESNO ? (
                        <Fragment>
                            <CAButton onClick={modals.accept} modifier={'primary modal-footer'} type="button">
                                {t('modals:buttons:yes')}
                            </CAButton>
                            <CAButton onClick={() => this.onClose(modals.onClose)} modifier={'secondary'} type="button">
                                {t('modals:buttons:no')}
                            </CAButton>
                        </Fragment>
                    ) : null
                }
                closable={modals.closable}
                maskClosable={modals.closable}
                keyboard={modals.closable}
            >
                {modals.children}
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    modals: state.modals
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                eatModal
            },
            dispatch
        )
    }
})

export const CAModal = connect(mapStateToProps, mapDispatchToProps)(withTranslation(['modal'])(CAModalRaw))

export let MODAL
// modal.destroy();

export const makeConfirmModal = config => {
    MODAL = Modal.confirm({
        icon: null,
        ...config
    })
}

export const makeModal = config => {
    MODAL = Modal.confirm({
        icon: null,
        ...config
    })
}

export const BackLink = props => {
    const {t} = useTranslation()
    const {isHighlighted} = props
    return (
        <BackLinkWrapper isHighlighted={isHighlighted} onClick={props.onClick}>
            <Icon component={ArrowLeft} />
            {t('modals:buttons:back')}
        </BackLinkWrapper>
    )
}

export const makeModalWithoutDefaultButton = config => {
    MODAL = Modal.confirm({
        icon: null,
        okButtonProps: {
            style: {
                display: 'none'
            }
        },
        cancelButtonProps: {
            style: {
                display: 'none'
            }
        },
        ...config
    })
}

export function renderModalTo(domElement, store, props) {
    renderTo(domElement, CAModal, store, props)
}
