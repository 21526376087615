import React from 'react'
import {useTranslation} from 'react-i18next'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {DROPBOX_TYPE, SOURCE_INFO} from 'ca-common/common/enum'

import {ContentColumn, EntitiesColumnWrapper} from './StyledBackupContentList'
import {
    renderValueForNextBackup,
    sourcesWithNoActivatedEntitiesNote,
    useActiveEntityTooltip
} from 'src/newcore/features/Homepage/utils'
import {Task} from 'src/newcore/features/Homepage/types'
import {EntityColumn} from 'src/newcore/features/Homepage/atoms/Column'
import {TitleInfo} from 'src/newcore/features/Homepage/atoms/TitleInfo'
import {ValueInfo} from 'src/newcore/features/Homepage/atoms/ValueInfo'

export const BackupContentList = ({task}: Task): JSX.Element => {
    const {t} = useTranslation()

    const {
        nextBackup,
        status,
        progress,
        isDropboxProfessionalAccount,
        lastBackup,
        size,
        subAccountsCount,
        source,
        subActiveAccountsCount
    } = task

    const nextBackupValue = renderValueForNextBackup(progress, status, nextBackup)

    const entityName = getSvcAccountsDescription(
        isDropboxProfessionalAccount ? DROPBOX_TYPE.DROPBOX_PROFESSIONAL : source,
        true
    )

    const activeEntityTooltip =
        useActiveEntityTooltip({source, subActiveAccountsCount, subAccountsCount}) ||
        (t(`homepage:rowName:entityActive`, {entity: entityName}) as string)

    return (
        <ContentColumn>
            <EntitiesColumnWrapper textAlignRight data-inlinemanual="InlineManual-homepage-overview">
                {!isDropboxProfessionalAccount && (
                    <EntityColumn
                        name={
                            <TitleInfo
                                title={t('homepage:rowName:total')}
                                fullTitle={t(`homepage:rowName:entityTotal`, {entity: entityName})}
                            />
                        }
                        value={<ValueInfo value={subAccountsCount} />}
                    />
                )}
                {!isDropboxProfessionalAccount && SOURCE_INFO[source].hasSubAccounts && (
                    <EntityColumn
                        isHighlighted={!subActiveAccountsCount && sourcesWithNoActivatedEntitiesNote.includes(source)}
                        name={
                            <TitleInfo
                                title={t('homepage:rowName:active')}
                                fullTitle={activeEntityTooltip}
                                isWarningIcon={
                                    !subActiveAccountsCount && sourcesWithNoActivatedEntitiesNote.includes(source)
                                }
                            />
                        }
                        value={<ValueInfo value={subActiveAccountsCount} />}
                    />
                )}
            </EntitiesColumnWrapper>

            <EntitiesColumnWrapper textAlignRight width={115} data-inlinemanual="InlineManual-homepage-dataSize">
                <EntityColumn name={t(`homepage:rowName:totalSize`)} value={size} />
            </EntitiesColumnWrapper>
            <EntitiesColumnWrapper
                textAlignRight
                alignItemsBetween
                data-inlinemanual="InlineManual-homepage-backupSchedule"
            >
                <EntityColumn name={t(`homepage:rowName:lastSnapshot`)} value={lastBackup} />
                <EntityColumn name={t(`homepage:rowName:nextBackup`)} value={nextBackupValue} />
            </EntitiesColumnWrapper>
        </ContentColumn>
    )
}
