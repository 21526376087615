import React, {useState} from 'react'

import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'
import {BackupAuthModal} from 'src/newcore/components/Modals'
import {AuthTemplateSelector} from './templates'

type BackupAuthType = Omit<BackupAuthTemplate, 'onSubmit' | 'loading'> & {
    onSubmit: (values: Record<string, unknown>) => Promise<any>
    visible: boolean
}

export const BackupAuth = ({
    visible,
    onCancel,
    onSubmit,
    backupSettings,
    source,
    companyName,
    isCloudally
}: BackupAuthType): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const handleSubmit = (values: Record<string, unknown>) => {
        setLoading(true)
        onSubmit(values)
            .catch((err: any) => console.log(err))
            .finally(() => setLoading(false))
    }

    return (
        <BackupAuthModal open={visible} onCancel={onCancel}>
            <AuthTemplateSelector
                visible={visible}
                isCloudally={isCloudally}
                source={source}
                backupSettings={backupSettings}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                loading={loading}
                companyName={companyName}
            />
        </BackupAuthModal>
    )
}
