import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ButtonWithState} from 'ca-common/ui-lib/atoms'
import {checkPermission} from 'ca-common/utils/permissions'
import type {UserInfoDataType} from 'ca-common/types'
import Export from 'ca-common/icons/ExportNew.svg'
import {StyledActionButton, StyledActionButtonIcon} from './StyledActionButton'
import {FetchedState} from 'ca-common/utils/fetchWrapperRT'

type ExportButtonProps = ButtonWithState & {
    userInfo: FetchedState<UserInfoDataType>
}

export const ExportButton = ({userInfo, ...props}: ExportButtonProps): JSX.Element => {
    const {t} = useTranslation()

    return checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response?.permissions) ? (
        <StyledActionButton borderWidth={0} icon={<StyledActionButtonIcon component={Export} />} {...props}>
            {t('recovery:actions:export')}
        </StyledActionButton>
    ) : (
        <></>
    )
}
