import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledNoBackupsInfo = styled.div`
    font-family: ${variables.publicPageFont};
    padding: ${variables.gutterMd} ${variables.gutterXxl};
    height: 236px;
    background-color: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
`

export const Title = styled.div`
    color: ${variables.dark};
    font-weight: 400;
    font-size: ${variables.subTitleFontSize};
`
export const WarningIcon = styled(Icon)`
    font-size: 40px;
    color: ${variables.orange};
    margin-right: ${variables.gutterXs};
`

export const Notification = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NotificationText = styled.div`
    font-weight: 500;
    font-size: ${variables.mainTitleFontSize};
`
