import React from 'react'
import {Tabs, Spin, Form} from 'antd'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {WizardHeader} from 'ca-common/ui-lib/molecules/WizardHeader'
import {SNAPSHOT_TYPE} from 'ca-common/common/enum/Backups'
import {openSuccessNotification, openErrorNotification} from 'ca-common/utils/toasts'
import {SFC_TYPE} from 'ca-common/common/enum/SalesforceType'

import {getUTCDateFromLocal} from 'ca-common/utils/datetime'

import {startCompareTask, getCompareResults} from 'src/newcore/redux/modules/backups/backupCompare'
import {setActiveTab} from 'src/newcore/redux/modules/backups/backupTaskSettings'
import {PANES_KEYS} from 'src/newcore/components/SFCTabs'
import {SelectType} from './SelectType'
import {SelectSnapshot} from './SelectSnapshot'

const {TabPane} = Tabs

class CompareSnapshotsRaw extends React.Component {
    state = {
        activeKey: 0,
        progress: 0,
        request: {}
    }

    formRef = React.createRef()

    addDataToRequest = values => {
        this.setState(prevState => ({
            request: {
                ...prevState.request,
                ...values
            }
        }))
    }

    formRequest = values => {
        const {t} = this.props

        const {request} = this.state
        const requestData = {
            includeMetadata: request.includeMetadata,
            taskId: request.taskId,
            date: getUTCDateFromLocal(values.date)
        }

        if (request.includeMetadata) {
            requestData.compareObject = values.metadataObject
        } else {
            requestData.compareObject = values.dataObject
            requestData.includeChildren = values.includeChildren
        }

        if (request.snapshotType === SNAPSHOT_TYPE.BACKUP) {
            // BACKUP
            if (request.taskId === values.compareTaskId && values.date === values.compareDate) {
                this.formRef.current.setFields([
                    {
                        name: 'compareDate',
                        value: values.compareDate,
                        errors: [t('forms:compareSnapshots:fields:backupTask:messageSameTask')]
                    }
                ])
                return
            }
            requestData.compareDate = getUTCDateFromLocal(values.compareDate)
            requestData.compareTaskId = values.compareTaskId
        } else if (request.snapshotType === SNAPSHOT_TYPE.OTHER) {
            // OTHER ORGANIZATION
            requestData.useSandbox = values.sfcType === SFC_TYPE.SANDBOX
        } else {
            // SALESFORCE
            requestData.compareTaskId = request.taskId
        }

        return requestData
    }

    handleSubmit = values => {
        const {actions, handleCancel, match} = this.props

        const requestData = this.formRequest(values)

        if (requestData) {
            actions
                .startCompareTask(requestData)
                .unwrap()
                .then(({data}) => {
                    if (data.needRedirect) {
                        window.location = data.redirectUrl
                    } else if (data.showNotification) {
                        handleCancel()
                        actions.setActiveTab(PANES_KEYS.COMPARES)
                        actions.getCompareResults({taskId: match.params.taskId})
                        openSuccessNotification(data.notificationMessage)
                    }
                })
                .catch(err => {
                    openErrorNotification(err.status)
                })
        }
    }

    onTabClick(key) {
        if (key <= this.state.progress) {
            this.setState({
                activeKey: key
            })
        }
    }

    nextTab = () => {
        this.setState(state => ({
            activeKey: state.activeKey + 1,
            progress: state.activeKey + 1
        }))
    }

    previousTab = () => {
        const {activeKey} = this.state
        if (activeKey > 0) {
            this.setState({
                activeKey: activeKey - 1
            })
        }
    }

    render() {
        const {currentTask, handleCancel, t, SFCTasks} = this.props
        const {activeKey, request} = this.state

        return (
            <Spin spinning={SFCTasks.status !== 'fulfilled'}>
                <Form onFinish={this.handleSubmit} ref={this.formRef}>
                    <Tabs
                        activeKey={this.state.activeKey.toString()}
                        renderTabBar={props => (
                            <WizardHeader
                                onChange={key => this.onTabClick(Number(key))}
                                title={`${currentTask.alias} ${t('comparison')}`}
                                description={activeKey ? t('descriptionSecondStep') : t('descriptionFirstStep')}
                                progress={String(activeKey)}
                                {...props}
                            />
                        )}
                    >
                        <TabPane tab="Tab 1" key="0">
                            <SelectType
                                nextTab={this.nextTab}
                                closeModal={handleCancel}
                                addDataToRequest={this.addDataToRequest}
                                form={this.formRef.current}
                            />
                        </TabPane>
                        <TabPane tab="Tab 2" key="1">
                            <SelectSnapshot
                                previousTab={this.previousTab}
                                closeModal={handleCancel}
                                addDataToRequest={this.addDataToRequest}
                                form={this.formRef.current}
                                isMetadata={request.includeMetadata}
                            />
                        </TabPane>
                    </Tabs>
                </Form>
            </Spin>
        )
    }
}
const mapStateToProps = state => ({
    SFCTasks: {
        status: state.backups.compare.tasksSFC.status
    },
    currentTask: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            startCompareTask,
            getCompareResults,
            setActiveTab
        },
        dispatch
    )
})

export const CompareSnapshotsForm = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('compareSnapshot')(CompareSnapshotsRaw))
)
