import styled from 'styled-components'
import variables from 'src/ca-common/variables'

export const StyledText = styled.span`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};

    color: ${variables.navyBlue};
`

export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: ${variables.gutterSm};

    &:last-child {
        margin-bottom: 0;
    }
`

export const StyledLabel = styled.span`
    display: inline-block;
    width: 100%;
    padding-right: ${variables.gutterSm};

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: ${variables.black};

    @media screen and (min-width: ${variables.screenMd}) {
        width: 210px;
    }
`
