import styled, {css} from 'styled-components'
import {rgba} from 'polished'
import variables from 'ca-common/variables'

export const ExportButtonWrapper = styled.div<{isPP?: boolean}>`
    background: ${variables.white};
    border-radius: 50%;

    ${props => {
        if (props.isPP) {
            return css`
                button {
                    color: ${variables.iceBlue};
                    border-color: ${variables.iceBlue};

                    &:hover {
                        color: ${variables.iceBlue};
                        border-color: ${variables.iceBlue};
                        background: ${rgba(variables.iceBlue, 0.1)};
                    }
                }
            `
        }
    }}
`

export const StyledTitle = styled.div`
    font-size: ${variables.headingFontSize};
    font-weight: 400;
`

export const StyledEmail = styled.span`
    font-weight: 600;
`
