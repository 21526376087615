import {i18n} from 'src/locales'

import {COLUMN_NAMES} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getColumnNameFriendly = name => {
    switch (name) {
        case COLUMN_NAMES.TRIGGER_OBJECT:
            return i18n.t('smartAlerts:columns:triggerObjects')
        case COLUMN_NAMES.RULE:
            return i18n.t('smartAlerts:columns:rule')
        case COLUMN_NAMES.TOTAL:
            return i18n.t('smartAlerts:columns:total')
        case COLUMN_NAMES.UNIT:
            return i18n.t('smartAlerts:columns:unit')
        case COLUMN_NAMES.EVENT:
            return i18n.t('smartAlerts:columns:event')
        case COLUMN_NAMES.TRIGGERED_BY:
            return i18n.t('smartAlerts:columns:triggeredBy')

        default:
            return ''
    }
}
