import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledWrapper = styled.div``

export const StyledData = styled.div`
    font-size: ${variables.fontSizeBase};
    line-height: ${variables.fontSizeBase};
    color: ${variables.dark};
    margin-bottom: 11px;
`
