import styled from 'styled-components'
import {Table, Input} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const CompareWrapper = styled.div`
    background: ${variables.white};
    padding: 24px;
    margin-bottom: 24px;
`

export const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: ${variables.white};
    }

    & .ant-table-thead > tr > th:hover {
        background: ${variables.white};
    }

    .ant-table-tbody > tr > td {
        border-bottom: none;
    }

    td:nth-child(3),
    th:nth-child(3) {
        text-align: center;
    }
    td:nth-child(4),
    th:nth-child(4) {
        text-align: right;
    }
    td:nth-child(5),
    th:nth-child(5) {
        text-align: right;
    }
`

export const HeaderCell = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${variables.fontSizeBase};
`

export const CentralCell = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;

    color: ${variables.navyBlue};
    align-items: center;
    cursor: default;
`

export const HeaderCellTitle = styled.div`
    font-weight: 600;
`

export const CellWrapper = styled.span`
    font-size: ${variables.fontSizeBase};
    cursor: default;
`

export const Badge = styled.span`
    border-radius: 5px;
    background: ${variables.brandLight};
    padding: 5px;
    color: ${variables.navyBlue};
    font-size: 16px;
    font-weight: 600;

    cursor: pointer;
`

export const BadgeTitle = styled.div`
    color: ${variables.asphaltLight};
    font-size: ${variables.fontSizeBase};
    opacity: 0;
    transition: 0.2s;
    margin-bottom: 5px;

    tr:hover & {
        opacity: 1;
    }
`

export const Total = styled.span`
    color: ${variables.dark};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    padding: 3px;
    cursor: pointer;
`

export const Description = styled.p`
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
`

export const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${variables.gutterMd};
`

export const TaskTitle = styled.div`
    border-radius: 24px;
    background: ${variables.blueLight};
    font-weight: 600;
    color: ${variables.asphalt}

    font-size: ${variables.fontSizeBase};
    text-align: center;
    flex: 1;
    line-height: 32px;

    &:first-child {
        border-radius: ${variables.borderRadiusBase} 24px 24px ${variables.borderRadiusBase};
    }

    &:last-child {
        border-radius: 24px ${variables.borderRadiusBase} ${variables.borderRadiusBase} 24px;
    }
`

export const TaskDate = styled.div`
    color: ${variables.dark};
`

export const FilterInput = styled(Input)`
    margin: 0 30px;
    background: ${variables.blueLight};
    border-radius: 24px;
    flex: none;
    width: 264px;

    .ant-input {
        text-align: center;
        border-radius: 24px;
    }
`

export const FilterIcon = styled(Icon)`
    font-size: 18px;
    color: ${variables.asphalt};
`
