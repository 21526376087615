import {SOURCE} from 'ca-common/common/enum/Source'

export const BULK_ACTIVATION_TYPE = {
    ACTIVE_DIRECTORY: 'ACTIVE_DIRECTORY',
    GROUPS: 'GROUPS',
    CSV: 'CSV',
    SHAREPOINT_URL: 'SHAREPOINT_URL',
    URL_CONDITIONS: 'URL_CONDITIONS',
    GOOGLE_OU: 'GOOGLE_OU'
}

export const AUTOMATICALLY_ACTIVATION_TYPE = {
    ALL: 'ALL',
    BY_RULE: 'BY_RULE'
}

export const GROUPS_CONDITION_TYPE = {
    ACTIVATE_BY_CONDITION: 'ACTIVATE_BY_CONDITION',
    CONTINUE_ALL_NEW: 'CONTINUE_ALL_NEW'
}

export const BULK_ACTIVATION_METHODS_BY_SOURCE = {
    [SOURCE.MS365]: [BULK_ACTIVATION_TYPE.ACTIVE_DIRECTORY, BULK_ACTIVATION_TYPE.GROUPS, BULK_ACTIVATION_TYPE.CSV],
    [SOURCE.ONEDRIVE]: [BULK_ACTIVATION_TYPE.GROUPS],
    [SOURCE.SHAREPOINT]: [BULK_ACTIVATION_TYPE.SHAREPOINT_URL],
    [SOURCE.SHAREPOINT_MULTI]: [BULK_ACTIVATION_TYPE.SHAREPOINT_URL],
    [SOURCE.GOOGLEAPPS]: [BULK_ACTIVATION_TYPE.GOOGLE_OU]
}
