import React from 'react'
import {withTranslation} from 'react-i18next'
import {Select, Checkbox, Tooltip, Form} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import _ from 'lodash'

import {StyledInputRow, RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {StyledTooltipIcon} from './StyledSelectCompareType'

import Info from 'ca-common/icons/Info.svg'
import {Button} from 'ca-common/ui-lib/atoms'
import {Label} from 'ca-common/ui-lib/components/FormItem'

import {
    getCompareDataObjects,
    getCompareMetadataObjects,
    clearSFCObjects
} from 'src/newcore/redux/modules/backups/backupCompare'
import {ModalContent, StyledIcon} from '../StyledCompareSnapshot'

const {Option} = Select
const ALL_OBJECTS_VALUE = '*'

export class SelectTypeModal extends React.Component {
    state = {
        type: SFC_DATA_TYPE.DATA
    }

    componentDidMount() {
        const {type} = this.state
        const {actions, match} = this.props

        actions.getCompareDataObjects({
            taskId: match.params.taskId,
            type
        })
    }

    componentWillUnmount() {
        const {actions} = this.props
        actions.clearSFCObjects()
    }

    onDataTypeChange = el => {
        const {actions, match, compareMetadata, form} = this.props

        if (el.target.value === SFC_DATA_TYPE.METADATA && compareMetadata.status !== 'fulfilled') {
            actions.getCompareMetadataObjects({
                taskId: match.params.taskId,
                type: el.target.value
            })
        }
        this.setState({
            type: el.target.value
        })

        form.resetFields(['dataObject', 'includeChildren', 'metadataObject'])
    }

    onNextTabClick = () => {
        const {nextTab, addDataToRequest} = this.props
        const {type} = this.state

        addDataToRequest({
            includeMetadata: type === SFC_DATA_TYPE.METADATA ? 1 : 0
        })

        nextTab()
    }

    render() {
        const {type} = this.state
        const {closeModal, t, compareData, compareMetadata, form} = this.props
        const compareDataObjects = _.get(compareData, 'data.data', {})
        const compareMetadataObjects = _.get(compareMetadata, 'data.data', {})

        return (
            <div>
                <ModalContent>
                    <RadioBlock
                        checked={type === SFC_DATA_TYPE.DATA}
                        title={t('compareSnapshot:data:title')}
                        description={t('compareSnapshot:data:description')}
                        value={SFC_DATA_TYPE.DATA}
                        showRadioIcon={false}
                        onChange={this.onDataTypeChange}
                    >
                        <Tooltip
                            title={t('compareSnapshot:data:tooltipTitle')}
                            overlayInnerStyle={{width: '271px', fontSize: '14px'}}
                        >
                            <StyledTooltipIcon component={Info} />
                        </Tooltip>

                        <Label>
                            <span>{t('compareSnapshot:objectLabel')}</span>
                        </Label>
                        <StyledInputRow>
                            <Form.Item name="dataObject" initialValue={ALL_OBJECTS_VALUE}>
                                <Select
                                    loading={compareData.status !== 'fulfilled'}
                                    style={{width: 290}}
                                    showSearch
                                    filterOption={(input, option) =>
                                        _.includes(option.props.children.toLowerCase(), input.toLowerCase())
                                    }
                                    onSelect={option => this.setState({selectedObject: option})}
                                    disabled={type !== SFC_DATA_TYPE.DATA}
                                    getPopupContainer={t => t.parentElement}
                                >
                                    <Option value={ALL_OBJECTS_VALUE} key={ALL_OBJECTS_VALUE}>
                                        {t('compareSnapshot:allObjects')}
                                    </Option>
                                    {_.map(compareDataObjects, value => (
                                        <Option value={value} key={value}>
                                            {value}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {form?.getFieldValue('dataObject') !== ALL_OBJECTS_VALUE && (
                                <Form.Item name="includeChildren" valuePropName="checked">
                                    <Checkbox>
                                        <span>{t('forms:compareSnapshots:fields:includeChildObjects:label')}</span>
                                        <Tooltip title={t('forms:compareSnapshots:fields:includeChildObjects:tooltip')}>
                                            <StyledIcon component={Info} />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </StyledInputRow>
                    </RadioBlock>
                    <RadioBlock
                        checked={type === SFC_DATA_TYPE.METADATA}
                        title={t('compareSnapshot:metadata:title')}
                        description={t('compareSnapshot:metadata:description')}
                        value={SFC_DATA_TYPE.METADATA}
                        showRadioIcon={false}
                        onChange={this.onDataTypeChange}
                    >
                        <Label>
                            <span>{t('compareSnapshot:objectLabel')}</span>
                        </Label>
                        <Form.Item name="metadataObject" initialValue={ALL_OBJECTS_VALUE}>
                            <Select
                                loading={compareMetadata.status !== 'fulfilled'}
                                style={{width: '100%'}}
                                showSearch
                                filterOption={(input, option) =>
                                    _.includes(option.props.children.toLowerCase(), input.toLowerCase())
                                }
                                disabled={type !== SFC_DATA_TYPE.METADATA}
                                getPopupContainer={t => t.parentElement}
                            >
                                <Option value={ALL_OBJECTS_VALUE} key={ALL_OBJECTS_VALUE}>
                                    {t('compareSnapshot:allObjects')}
                                </Option>
                                {_.map(compareMetadataObjects, value => (
                                    <Option value={value} key={value}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </RadioBlock>
                </ModalContent>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={closeModal}>{t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={this.onNextTabClick}>
                            {t('modals:buttons:next')}
                        </Button>
                    </LinkWrapper>
                </ModalFooter>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    compareData: {
        status: state.backups.compare.objects.data.status,
        data: state.backups.compare.objects.data.response
    },
    compareMetadata: {
        status: state.backups.compare.objects.metadata.status,
        data: state.backups.compare.objects.metadata.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getCompareDataObjects,
            getCompareMetadataObjects,
            clearSFCObjects
        },
        dispatch
    )
})

export const SelectType = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectTypeModal)))
