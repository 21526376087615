import React from 'react'

import {CATable, CATableWrapper} from 'ca-common/ui-lib/components/TableElements'
import {CAHeader} from './Header'
import {CAContent} from './Content'
import {CAFooter} from './Footer'
import {CANoTotalFooter} from './NoTotalFooter'
import {CASelectAll} from './SelectAll'

import {generateModifier, getTableMinWidth, handleScrollTable, makeModifiedConfig} from 'ca-common/utils/table'

import {baseTable as baseTableShape} from 'ca-common/shapes/table'

export class CABaseTable extends React.PureComponent {
    static propTypes = baseTableShape

    static defaultProps = {
        parent: false,
        scrollable: false,
        noTotal: false,
        rowSelection: null,
        fetchItems: null,
        options: {}
    }

    constructor(props) {
        super(props)
        this.scrollLeft = 0
        this.header = null
        this.content = null

        const {config, rowSelection} = props
        this.state = {config: makeModifiedConfig(config, rowSelection)}
    }

    render() {
        const {config} = this.state
        const {
            items,
            pageSize,
            fetchItems,
            parent,
            scrollable,
            noTotal,
            rowSelection,
            options,
            onMouseEnter,
            disabled
        } = this.props

        return (
            <CATableWrapper>
                <CASelectAll rowSelection={rowSelection} items={items} />

                <CATable
                    modifier={generateModifier({parent, scrollable})}
                    onScroll={e => this.handleScroll(e, this.header, this.content)}
                >
                    <CAHeader rowSelection={rowSelection} config={config} items={items} ref={this.setHeaderRef} />
                    <CAContent
                        disabled={disabled}
                        rowSelection={rowSelection}
                        items={items}
                        scrollable={scrollable}
                        config={config}
                        ref={this.setContentRef}
                        minWidth={getTableMinWidth(this.header)}
                        onMouseEnter={onMouseEnter}
                    />
                    {fetchItems && !noTotal && (
                        <CAFooter
                            rowSelection={rowSelection}
                            config={config}
                            items={items}
                            fetchItems={fetchItems}
                            pageSize={pageSize}
                            options={options}
                        />
                    )}

                    {fetchItems && noTotal && (
                        <CANoTotalFooter
                            rowSelection={rowSelection}
                            config={config}
                            items={items}
                            fetchItems={fetchItems}
                            pageSize={pageSize}
                            options={options}
                        />
                    )}
                </CATable>
            </CATableWrapper>
        )
    }

    setHeaderRef = element => {
        this.header = element
    }

    setContentRef = element => {
        this.content = element
    }

    handleScroll = (e, header, content) => {
        const {target} = e
        const targetScrollLeft = target.scrollLeft

        if (targetScrollLeft !== this.scrollLeft) {
            this.scrollLeft = targetScrollLeft
            handleScrollTable(target, header, content, targetScrollLeft)
        }
    }
}
