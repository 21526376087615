import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Modal} from 'antd'

import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {SOURCE} from 'ca-common/common/enum'
import variables from 'ca-common/variables'

import {
    StyledHeader,
    StyledFooter,
    StyledModal,
    StyledHeaderWithIcon,
    StyledLink
} from './StyledActionDeleteConfirmation'

export const ActionDeleteConfirmation = ({name, account, onDelete, onCancel, visible, supportEmail, source}) => {
    const {t} = useTranslation()

    return (
        <Modal width={variables.modalWidth} open={visible} footer={null} closable={false}>
            <StyledModal>
                {SOURCE.SHAREPOINT_MULTI === source ? (
                    <StyledHeader>
                        <Trans i18nKey="backups:modal:delete:sharePointText" values={{supportEmail}}>
                            <StyledLink href={`mailto:${supportEmail}`}>Support Email</StyledLink>
                        </Trans>
                    </StyledHeader>
                ) : (
                    <StyledHeaderWithIcon action={TASK_ACTION.DELETE}>
                        {t('backups:modal:delete:text', {name, account})}
                        <BackupTaskAction action={TASK_ACTION.DELETE} withTitle={false} />
                    </StyledHeaderWithIcon>
                )}
                <StyledFooter>
                    <Button htmlType="button" onClick={onCancel}>
                        {t('backups:modal:delete:buttonCancel')}
                    </Button>
                    <Button type="primary" htmlType="button" color={variables.red} onClick={onDelete}>
                        {t('backups:modal:delete:buttonDelete')}
                    </Button>
                </StyledFooter>
            </StyledModal>
        </Modal>
    )
}
