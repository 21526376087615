import {createAction, createReducer} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'

export const setSelectedGroups = createAction<TODO_ANY[]>('SET_SELECTED_GROUPS')

export const clearSelectedGroups = createAction('CLEAR_SELECTED_GROUPS')

export const selectedGroupsReducer = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearSelectedGroups, () => [])
    builder.addCase(setSelectedGroups, (state, action) => action.payload)
})
