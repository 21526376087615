import _ from 'lodash'
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import Icon from '@ant-design/icons'

import Refresh from 'ca-common/icons/Refresh.svg'
import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms'
import {validatePageAccess} from 'ca-common/utils/permissions'
import {PAGES} from 'ca-common/constants'
import {Empty} from 'ca-common/ui-lib/atoms/Empty'

import {CompareItem} from 'src/newcore/components/CompareItem'

import {Description, HeaderRow, LeftHeader, StyledCompareList} from './StyledCompareList'
import {withPulling} from 'src/newcore/hoc/withPulling'

@withTranslation()
class CompareListComponentRaw extends Component {
    prepareResult = result => ({
        type: result.comparingType,
        leftObject: result.leftObject,
        rightObject: result.rightObject,
        progress: result.progress,
        object: result.compareObject,
        exception: result.error,
        compareStatus: result.status
    })

    render() {
        const {data, cancelTask, redirect, fetch, t, userInfo} = this.props

        return (
            <StyledCompareList>
                <HeaderRow>
                    <LeftHeader>
                        <Description>{t('compareSnapshot:titleDescription')}</Description>
                    </LeftHeader>
                    <ButtonHoverReverse shape="circle" onClick={fetch}>
                        <Icon component={Refresh} />
                    </ButtonHoverReverse>
                </HeaderRow>
                {data.length ? (
                    _.map(data, result => (
                        <CompareItem
                            key={`${result.taskId}item`}
                            redirect={() => redirect(result.compareId || '')}
                            cancelTask={() => cancelTask(result.taskId)}
                            hasPermissions={validatePageAccess({page: PAGES.COMPARE, userInfo})}
                            {...this.prepareResult(result)}
                        />
                    ))
                ) : (
                    <Empty description={t('compareList:empty')} />
                )}
            </StyledCompareList>
        )
    }
}

const mapStateToProps = state => ({
    userInfo: state.userInfo.response
})

export const CompareListComponent = withPulling(connect(mapStateToProps)(CompareListComponentRaw))
