import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.section`
    margin: ${variables.gutterSm} ${variables.gutterMd};

    > * + * {
        margin-top: 16px;
    }
`
