import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Wrapper = styled.div`
    margin: -${variables.gutterSm};
    padding: ${variables.gutterMd} 52px 0 52px;

    > :first-child {
        margin-bottom: ${variables.gutterMd};
    }
`
