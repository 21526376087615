import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ReactGhLikeDiff} from 'react-gh-like-diff'
import 'react-gh-like-diff/dist/css/diff2html.min.css'

import {StyledCompareDiffItem} from './StyledCompareDiffItem'

export class CompareDiffItem extends Component {
    static propTypes = {
        first: PropTypes.string.isRequired,
        second: PropTypes.string.isRequired,
        outputFormat: PropTypes.string.isRequired
    }

    render() {
        const {first, second, outputFormat} = this.props

        return (
            <StyledCompareDiffItem>
                <ReactGhLikeDiff
                    options={{
                        outputFormat
                    }}
                    past={first}
                    current={second}
                />
            </StyledCompareDiffItem>
        )
    }
}
