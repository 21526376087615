import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next'
import capitalize from 'lodash/capitalize'
import omitBy from 'lodash/omitBy'
import moment from 'moment'

import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms'
import {ACTIVITY_STATUS, AZURE_APP_VERSION, RECOVERY_TYPE, RESTORE_TYPE, SOURCE} from 'ca-common/common/enum'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {BackLink, CancelLink, LinkWrapper, ModalFlexColumnWrapper, ModalFooter} from 'ca-common/components/Modal'
import {getUTCDateFromLocal, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import type {MS365SubSource, MSTaskEntityType, TODO_ANY} from 'ca-common/types'
import {StyledLink} from 'ca-common/atoms/StyledLink'
import {PAGES, PORTAL_BASENAME} from 'ca-common/constants'

import {changeData, closeWizard, previousStep} from 'src/newcore/redux/modules/wizard'
import {BACKUP_DATE_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'
import {startRecovery, startRestoreOrDownload} from 'src/newcore/features/RecoveryWizard/redux'
import {
    EMAIL_FORMAT,
    EmailSettingsType,
    FULL_RECOVERY_ERRORS,
    getConfirmButtonName,
    getItemsForSelectiveRecovery,
    RecoveryErrorType,
    sendSelectiveExport,
    sendSelectiveRestore,
    StorageSettingType,
    SubsourceFormat,
    SubsourcesBooleanState,
    TARGET_DESTINATION,
    WizardRestoreOptions
} from 'src/newcore/features/RecoveryWizard/lib'
import {RestoreOrExportConfirmation} from 'src/newcore/features/RecoveryWizard/molecules/Confirmation'
import {SpinnerWrapper, StyledNote} from 'src/newcore/features/RecoveryWizard/molecules/Confirmation/StyledConfirmation'
import {EVENT_NAMES, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {SelectiveRecoveryState} from 'src/newcore/features/Recovery/localState'
import {ConfirmationModal} from 'src/newcore/features/RecoveryWizard/atoms/ConfirmationModal'
import {useAppDispatch} from 'src/newcore/components/Root'
import {AUTH_ERRORS, waitForStorageFlag} from 'src/newcore/utils/waitForStorageFlag'

export type ConfirmationProps = {
    wizardData: {
        fullRecoveryOptions: RECOVERY_TYPE
        emailSettings: EmailSettingsType
        targetAccount: string
        entity: MSTaskEntityType
        subServices: SubsourcesBooleanState
        storageType: TARGET_DESTINATION
        additionalStorageSettings: StorageSettingType
        date: number
        source: SOURCE
        dateType: BACKUP_DATE_OPTIONS
        format?: SubsourceFormat
        selectiveRecovery?: SelectiveRecoveryState
        uncheckAll?: () => void
        restoreOptions: WizardRestoreOptions
        originSubsources: {status: ACTIVITY_STATUS; source: SOURCE}[]
        isPartialSelectedDate: boolean
    }
    taskId: string
    isIndexMessagesChannel: boolean
    azureAppVersion: AZURE_APP_VERSION
    calendar?: Record<
        string,
        [
            {
                subsSourceResponseList?: [
                    {
                        status: string
                        source: string
                    }
                ]
                dateTime: string
                status: string
                retention?: boolean
            }
        ]
    >
}

const ConfirmationRaw = ({
    wizardData,
    taskId,
    isIndexMessagesChannel,
    calendar,
    azureAppVersion
}: ConfirmationProps): JSX.Element => {
    const {t} = useTranslation()
    const {
        fullRecoveryOptions,
        targetAccount,
        entity,
        subServices,
        date,
        storageType,
        emailSettings,
        additionalStorageSettings,
        source,
        dateType,
        format,
        selectiveRecovery,
        uncheckAll,
        restoreOptions
    } = wizardData
    const {entity: account, extid} = entity || {}
    const multipleSources: Array<MS365SubSource> = Object.keys(
        omitBy(subServices, prop => prop === false)
    ) as Array<MS365SubSource>
    const [loading, setLoading] = useState(false)
    const [dataObj, setDataObj] = useState<TODO_ANY>(undefined)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const dispatch = useAppDispatch()

    const sendAnalytics = (): boolean => {
        const trackEventName = selectiveRecovery
            ? EVENT_NAMES.SELECTIVE_RECOVERY_MESSAGE_LINK_PRESSED
            : EVENT_NAMES.FULL_RECOVERY_MESSAGE_LINK_PRESSED

        track(trackEventName, {
            Service: getServiceNameMixpanel(source),
            'Recovery Operation': capitalize(fullRecoveryOptions)
        })
        return true
    }

    const finishRecovery = () => {
        dispatch(closeWizard())
        if (uncheckAll) uncheckAll()
        if (selectiveRecovery && fullRecoveryOptions != RECOVERY_TYPE.RESTORE) {
            sendSelectiveExport({wizardData})
        }

        openSuccessNotification(
            <Trans
                i18nKey={'recovery:wizard:confirmation:restoreSuccessNotification:description'}
                values={{recovery: capitalize(fullRecoveryOptions.toLowerCase())}}
            >
                <StyledLink href={`/${PORTAL_BASENAME}/${PAGES.JOBS}`} target="_blank" onClick={sendAnalytics} />
            </Trans>,
            selectiveRecovery
                ? t('recovery:wizard:confirmation:selectiveRestoreSuccessNotification:title')
                : t('recovery:wizard:confirmation:restoreSuccessNotification:title')
        )
    }

    const handleSuccessResponse = (res?: any) => {
        if (res?.needRedirect && res.redirectUrl) {
            waitForStorageFlag(res.redirectUrl)
                .then(() => {
                    finishRecovery()
                })
                .catch(err => {
                    if (err === AUTH_ERRORS.timeout) {
                        openErrorNotification(t('recovery:wizard:authTimeoutError'))
                    } else if (err === AUTH_ERRORS.accessDenied) {
                        dispatch(
                            changeData({
                                error: {
                                    fullRecoveryErrorType: FULL_RECOVERY_ERRORS.ACCESS_DENIED,
                                    success: false,
                                    status: t('recovery:wizard:accessDeniedError')
                                }
                            })
                        )
                        dispatch(previousStep())
                    }
                    setLoading(false)
                })
        } else {
            finishRecovery()
        }
    }

    const foundedBackup = useMemo(() => {
        return calendar?.[taskId]?.find(i => moment(i.dateTime).isSame(moment(date, LONG_DATE_FORMAT)))
    }, [calendar, date, taskId])

    const selectedSubServices = useMemo(() => {
        if (dateType === BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE) {
            return foundedBackup?.subsSourceResponseList?.map(i => ({source: i.source, status: i.status}))
        }
    }, [foundedBackup, dateType])

    const action = selectiveRecovery ? startRecovery : startRestoreOrDownload

    function handlerRestoreOrDownload(dataObj: TODO_ANY) {
        setLoading(true)
        dispatch(
            action({
                ...dataObj,
                timeZoneOffset: new Date().getTimezoneOffset()
            })
        )
            .unwrap()
            .then((res: any) => handleSuccessResponse(res))
            .catch((err: RecoveryErrorType) => {
                if (err.status && !err.fullRecoveryErrorType) {
                    openErrorNotification(err.status)
                } else if (err.fullRecoveryErrorType) {
                    dispatch(changeData({error: err}))
                    dispatch(previousStep())
                }
                setLoading(false)
            })
    }

    const handleAuthorizeClick = () => {
        const commonData: Record<string, any> = {
            type: fullRecoveryOptions,
            account,
            source: SOURCE.MS365,
            extid,
            taskId,
            date: getUTCDateFromLocal(date),
            multipleSources,
            backupDateOption: dateType, // mixpanel
            backupStatus: foundedBackup?.status && foundedBackup?.retention ? 'N/A' : foundedBackup?.status, // mixpanel
            subServicesStatuses: selectedSubServices, // mixpanel
            subserviceExcluded: entity?.excludedSub // mixpanel
        }
        let resultData: TODO_ANY
        if (selectiveRecovery) {
            commonData.uiEntitiesInfo = getItemsForSelectiveRecovery(date, multipleSources, selectiveRecovery)
        }
        if (fullRecoveryOptions === RECOVERY_TYPE.RESTORE) {
            resultData = {
                ...commonData,
                targetAccount,
                isIndexMessagesChannel,
                azureAppVersion,
                ...(Object.keys(restoreOptions).length
                    ? (() => {
                          const {restoreType, ...pass} = restoreOptions

                          return {
                              restoreOption: restoreType,
                              ...pass
                          }
                      })()
                    : {})
            }
        } else {
            resultData = {
                ...commonData,
                downloadToPst: format === EMAIL_FORMAT.PST,
                storageType,
                ...additionalStorageSettings,
                ...emailSettings
            }
        }

        setDataObj(resultData)

        if (restoreOptions?.restoreType === RESTORE_TYPE.REPLACE) {
            setIsConfirmationModalOpen(true)
        } else {
            handlerRestoreOrDownload(resultData)
        }
    }

    const handleCancel = () => {
        dispatch(closeWizard())
    }
    const handlePreviousStep = () => {
        dispatch(previousStep())
    }

    const handleClickConfirm = () => {
        handlerRestoreOrDownload(dataObj)
        setIsConfirmationModalOpen(false)
    }

    useEffect(() => {
        if (selectiveRecovery && fullRecoveryOptions === RECOVERY_TYPE.RESTORE) {
            sendSelectiveRestore({wizardData})
        }
    }, [])

    return (
        <>
            <ModalFlexColumnWrapper minHeight={443}>
                {loading ? (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                ) : (
                    <>
                        <RestoreOrExportConfirmation
                            fullRecoveryOptions={fullRecoveryOptions}
                            restoreType={restoreOptions?.restoreType}
                            multipleSources={multipleSources}
                            date={date}
                            storageType={storageType}
                            targetAccount={targetAccount}
                            account={account}
                            format={format}
                        />
                        <StyledNote>
                            {fullRecoveryOptions === RECOVERY_TYPE.RESTORE
                                ? t(`recovery:wizard:confirmation:restoreEmailNotification`)
                                : t(`recovery:wizard:confirmation:emailNotification`)}
                        </StyledNote>

                        <StyledNote>
                            {t(`recovery:wizard:confirmation:activity`, {
                                action: capitalize(fullRecoveryOptions)
                            })}
                        </StyledNote>
                    </>
                )}
            </ModalFlexColumnWrapper>

            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <ButtonHoverReverse disabled={loading} type="primary" onClick={handleAuthorizeClick}>
                        {getConfirmButtonName(fullRecoveryOptions, storageType)}
                    </ButtonHoverReverse>
                </LinkWrapper>
                <BackLink onClick={handlePreviousStep} />
            </ModalFooter>
            <ConfirmationModal
                onOk={handleClickConfirm}
                onCancel={() => setIsConfirmationModalOpen(false)}
                visible={isConfirmationModalOpen}
            />
        </>
    )
}

const mapStateToProps = (state: TODO_ANY) => ({
    wizardData: state.wizard.data,
    isIndexMessagesChannel: state.backups.settings.response?.isIndexMessagesChannel,
    taskId: state.wizard.data.taskId,
    calendar: state.calendar,
    azureAppVersion: state.backups.settings.response?.azureAppVersion
})

export const Confirmation = connect(mapStateToProps)(ConfirmationRaw)
