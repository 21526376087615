import React, {Fragment} from 'react'
import {withTranslation, Trans} from 'react-i18next'

import {ResetPasswordForm} from './ResetPasswordForm'
import {SuccessSubmit} from './SuccessSubmit'
import {
    StyledContentContainer,
    StyledRow,
    StyledText,
    StyledLinkText,
    StyledLink,
    StyledHeader
} from './StyledResetPassword'

export const ResetPasswordInit = withTranslation()(({t, submitted, onSubmit, signIn, signUp}) => {
    return (
        <>
            <StyledContentContainer>
                <StyledHeader>{t('passwordResetNew:passwordReset')}</StyledHeader>
                <StyledText>
                    <Trans i18nKey="passwordResetNew:header" />
                </StyledText>

                {submitted ? (
                    <SuccessSubmit />
                ) : (
                    <Fragment>
                        <ResetPasswordForm handleSubmit={onSubmit} />

                        <StyledRow type="flex" justify="space-between">
                            {signIn && (
                                <StyledLink {...signIn}>
                                    <StyledLinkText>{t('passwordResetNew:buttonSignIn')}</StyledLinkText>
                                </StyledLink>
                            )}
                            {signUp && (
                                <StyledLink {...signUp}>
                                    <StyledLinkText>{t('passwordResetNew:buttonSignUp')}</StyledLinkText>
                                </StyledLink>
                            )}
                        </StyledRow>
                    </Fragment>
                )}
            </StyledContentContainer>
        </>
    )
})
