import {i18n} from 'src/locales'
import {isWhiteLable} from 'src/newcore/utils/companyInfo'
import {BILLING_VIEW} from 'ca-common/common/enum'

const SERVICE_WIDTH = 260
const TOTAL_QUANTITY_WIDTH = 260
const BILLABLE_QUANTITY_WIDTH = 260
const UNIT_PRICE_WIDTH = 260
const TOTAL_AMOUNT_WIDTH = 260

export const getStatusConfig = (isMonthly: boolean, showBillingValues: boolean) => {
    const initialDataValue = [
        {
            title: i18n.t('table:columns:backup'),
            dataIndex: 'service',
            key: 'service',
            width: SERVICE_WIDTH,
            ellipsis: true
        },
        {
            title: i18n.t('table:columns:originalQty'),
            dataIndex: 'originalQty',
            key: 'originalQty',
            width: TOTAL_QUANTITY_WIDTH
        },
        {
            title: i18n.t('table:columns:quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: BILLABLE_QUANTITY_WIDTH
        }
    ]
    const additionalDataValue = [
        {
            title:
                i18n.t('table:columns:price') +
                (isMonthly ? i18n.t('table:columns:perMonth') : i18n.t('table:columns:perYear')),
            dataIndex: isMonthly ? 'priceMonthly' : 'priceAnnual',
            key: isMonthly ? 'priceMonthly' : 'priceAnnual',
            width: UNIT_PRICE_WIDTH
        },
        {
            title: isMonthly ? i18n.t('table:columns:totalMonthly') : i18n.t('table:columns:totalAnnual'),
            dataIndex: isMonthly ? 'totalMonthly' : 'totalAnnual',
            key: isMonthly ? 'totalMonthly' : 'totalAnnual',
            width: TOTAL_AMOUNT_WIDTH
        }
    ]
    const dataValue = showBillingValues ? initialDataValue.concat(additionalDataValue) : initialDataValue
    return dataValue
}
