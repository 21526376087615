export enum REGION {
    //S3_REGIONS
    US_EST = 'US_EST',
    US_WEST_OREGON = 'US_WEST_OREGON',
    US_WEST_CAL = 'US_WEST_CAL',
    CANADA = 'CANADA',
    EU = 'EU',
    EU_FRANKFURT = 'EU_FRANKFURT',
    EU_PARIS = 'EU_PARIS',
    LONDON = 'LONDON',
    ASIA_SINGAPORE = 'ASIA_SINGAPORE',
    ASIA_MUMBAI = 'ASIA_MUMBAI',
    ASIA_SIDNEY = 'ASIA_SIDNEY',
    ASIA_TOKYO = 'ASIA_TOKYO',
    SOUTH_AMERICA = 'SOUTH_AMERICA',
    ASIA_CHINA = 'ASIA_CHINA',
    AFRICA_CAPE_TOWN = 'AFRICA_CAPE_TOWN'
}

export const REGION_INFO: Record<REGION, {isActive: boolean}> = {
    US_EST: {isActive: true},
    US_WEST_OREGON: {isActive: false},
    US_WEST_CAL: {isActive: false},
    CANADA: {isActive: true},
    EU: {isActive: true},
    EU_FRANKFURT: {isActive: true},
    EU_PARIS: {isActive: true},
    LONDON: {isActive: true},
    ASIA_SINGAPORE: {isActive: false},
    ASIA_MUMBAI: {isActive: true},
    ASIA_SIDNEY: {isActive: true},
    ASIA_TOKYO: {isActive: true},
    SOUTH_AMERICA: {isActive: false},
    ASIA_CHINA: {isActive: false},
    AFRICA_CAPE_TOWN: {isActive: true}
}
