import {createAction, createReducer} from '@reduxjs/toolkit'

import {CA_DIALOG_TYPE} from 'ca-common/components/Modal'

const MAKE_MODAL = 'MAKE_MODAL'
const EAT_MODAL = 'EAT_MODAL'

const initialState = {}

export const makeModal = createAction(MAKE_MODAL, (children, title, accept, isShowFooter, type, onClose, closable) => ({
    payload: {
        children,
        title,
        accept,
        isShowFooter,
        type,
        onClose,
        closable
    }
}))

export const makeDefaultModal = ({children, title, accept, isShowFooter = false, type = CA_DIALOG_TYPE.DEFAULT}) =>
    makeModal(children, title, accept, isShowFooter, type)

export const makeOkModal = ({children, title, accept, isShowFooter = true, type = CA_DIALOG_TYPE.OK}) =>
    makeModal(children, title, accept, isShowFooter, type)

export const makeYesNoModal = ({children, title, accept, isShowFooter = true, type = CA_DIALOG_TYPE.YESNO}) =>
    makeModal(children, title, accept, isShowFooter, type)

export const makeSaveModal = ({children, title, accept = null, isShowFooter = false, type = CA_DIALOG_TYPE.SAVE}) =>
    makeModal(children, title, accept, isShowFooter, type)

export const makeOnlySaveModal = ({
    children,
    title,
    accept = null,
    isShowFooter = false,
    type = CA_DIALOG_TYPE.SAVE,
    onClose = false,
    closable = true
}) => makeModal(children, title, accept, isShowFooter, type, onClose, closable)

export const makeModalReducer = (_state, action) => action.payload

export const eatModal = createAction(EAT_MODAL)
export const eatModalReducer = () => initialState

export const modalsReducer = createReducer(initialState, {
    MAKE_MODAL: makeModalReducer,
    EAT_MODAL: eatModalReducer
})
