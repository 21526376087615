import React from 'react'

import {SOURCE} from 'ca-common/common/enum/Source'

import IMAP from 'ca-common/icons/sourceGreyIcon/Imap.svg'
import GoogleAccount from 'ca-common/icons/sourceGreyIcon/GoogleAccount.svg'
import Sharepoint from 'ca-common/icons/sourceGreyIcon/Sharepoint.svg'
import OneDrive from 'ca-common/icons/sourceGreyIcon/OneDrive.svg'
import MS365 from 'ca-common/icons/colorIcon/Microsoft_365.svg'
import MS365Groups from 'ca-common/icons/sourceGreyIcon/Office365Groups.svg'
import Box from 'ca-common/icons/colorIcon/box.svg'
import Dropbox from 'ca-common/icons/colorIcon/dropbox.svg'
import SDB from 'ca-common/icons/sourceGreyIcon/SimpleDB.svg'
import DDB from 'ca-common/icons/sourceGreyIcon/DynamoDB.svg'
import Gworkspace from 'ca-common/icons/colorIcon/Gworkspace.svg'
import TeamDrive from 'ca-common/icons/sourceGreyIcon/GoogleDrive.svg'
import SFC from 'ca-common/icons/colorIcon/salesforce.svg'

import {StyledProductLogo, StyledIcon} from './StyledProductLogo'

type ProductLogoProps = {
    product: SOURCE
}

export const ProductLogo = ({product}: ProductLogoProps) => {
    const renderProductLogo = () => {
        switch (product) {
            case SOURCE.IMAP:
                return IMAP
            case SOURCE.GACCOUNTS:
                return GoogleAccount
            case SOURCE.SHAREPOINT:
            case SOURCE.SHAREPOINT_MULTI:
                return Sharepoint
            case SOURCE.ONEDRIVE:
                return OneDrive
            case SOURCE.MS365:
                return MS365
            case SOURCE.MS365GROUPS:
                return MS365Groups
            case SOURCE.BOX:
                return Box
            case SOURCE.DROPBOX:
                return Dropbox
            case SOURCE.SDB:
                return SDB
            case SOURCE.DDB:
                return DDB
            case SOURCE.GOOGLEAPPS:
                return Gworkspace
            case SOURCE.GDRIVETEAM:
                return TeamDrive
            case SOURCE.SFC:
                return SFC
            default:
                return null
        }
    }

    return (
        <StyledProductLogo product={product}>
            <StyledIcon product={product} component={renderProductLogo()} width={'100%'} height={'100%'} />
        </StyledProductLogo>
    )
}
