import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Spin} from 'antd'

import Search from 'ca-common/icons/Search.svg'

import {compareDateDescription} from 'src/newcore/utils/backups'

import {CompareTable} from './CompareTable'
import {CompareWrapper, TaskTitle, FilterInput, FilterWrapper, FilterIcon, TaskDate} from './StyledCompareTable'
import {CompareSummary} from './CompareSummary'
import {getCompareResult} from 'src/newcore/redux/modules/backups/backupCompare'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

@withTranslation('compareSnapshot')
export class CompareRaw extends React.Component {
    state = {
        data: this.props.compareObjects.data
    }

    componentDidMount() {
        const {actions, match, taskSettingsStatus} = this.props

        if (taskSettingsStatus !== 'fulfilled') {
            actions.getNewBackupTaskSettings({
                taskId: match.params.taskId
            }) //for breadcrumbs
        }

        actions.getCompareResult({
            resultId: match.params.compareId
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.compareObjects !== this.props.compareObjects) {
            this.setState({
                data: this.props.compareObjects.data
            })
        }
    }

    onChange = e => {
        const value = e.target.value

        this.setState(state => ({
            data: {
                ...state.data,
                changes: _.pickBy(this.props.compareObjects.data.changes, (val, key) => {
                    return _.includes(key.toLowerCase(), value.toLowerCase())
                })
            }
        }))
    }

    render() {
        const {t, compareObjects} = this.props
        const {data} = this.state
        const isLoading = compareObjects.status !== 'fulfilled'

        return (
            <Spin spinning={isLoading}>
                {!isLoading && (
                    <Fragment>
                        <CompareWrapper>
                            <FilterWrapper>
                                <TaskTitle>
                                    {compareObjects.data.backupAccount}{' '}
                                    <TaskDate>
                                        {compareDateDescription({
                                            date: compareObjects.data.backupDate
                                        })}
                                    </TaskDate>
                                </TaskTitle>
                                <FilterInput
                                    placeholder={t('searchObject')}
                                    prefix={<FilterIcon component={Search} />}
                                    onChange={this.onChange}
                                />
                                <TaskTitle>
                                    {compareObjects.data.compareAccount}
                                    <TaskDate>
                                        {compareDateDescription({
                                            date: compareObjects.data.compareBackupDate,
                                            isSandbox: compareObjects.data.isSandbox
                                        })}
                                    </TaskDate>
                                </TaskTitle>
                            </FilterWrapper>
                            <CompareTable data={data} />
                        </CompareWrapper>
                        <CompareSummary data={compareObjects.data} />
                    </Fragment>
                )}
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    compareObjects: {
        status: _.get(state, 'backups.compare.compareResult.status'),
        data: _.get(state, 'backups.compare.compareResult.response.data', {})
    },
    taskSettingsStatus: state.backups.settings.status
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getCompareResult,
            getNewBackupTaskSettings
        },
        dispatch
    )
})

export const Compare = connect(mapStateToProps, mapDispatchToProps)(CompareRaw)
