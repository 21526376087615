import PropTypes from 'prop-types'

export const SFCBackupPropTypes = {
    type: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export const SFCBackupDefaultProps = {
    date: ''
}
