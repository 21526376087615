import {get, groupBy, last, map} from 'lodash'

import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'
import {TODO_ANY} from 'ca-common/types'

const GET_SFC_OBJECTS = 'GET_SFC_OBJECTS'
const GET_SFC_OBJECTS_API = 'getSfcStats'
const sfcObjectsWrapper = fetchWrapperRT<SfcObjectsResponse>(
    GET_SFC_OBJECTS,
    async data => await postLessData(GET_SFC_OBJECTS_API, data)
)

const GET_AND_APPEND_SFC_OBJECTS = 'GET_AND_APPEND_SFC_OBJECTS'
const appendSfcObjectsWrapper = fetchWrapperRT<SfcObjectsResponse>(
    GET_AND_APPEND_SFC_OBJECTS,
    async data => await postLessData(GET_SFC_OBJECTS_API, data),
    {
        after: (coming, stored) => {
            const {data, ...rest} = coming
            const storedData = get(stored, 'response.data', [])

            const result = map(groupBy([...storedData, ...data], 'id'), last)

            return {
                data: result,
                ...rest
            }
        }
    }
)

export const getSfcStats = ({append, ...data}: {append?: boolean} = {}) => async (dispatch: AppDispatch) => {
    const wrapper = append ? appendSfcObjectsWrapper : sfcObjectsWrapper

    try {
        return await dispatch(wrapper.fetcher(data)).unwrap()
    } catch (error) {
        throw error
    }
}

type SfcObjectsResponse = {data: TODO_ANY[]}

const sfcObjectsSlice = createSlice({
    name: 'sfcObjects',
    initialState: InitFetchedState as FetchedState<SfcObjectsResponse>,
    reducers: {
        clearSfsObjects: () => InitFetchedState as FetchedState<SfcObjectsResponse>
    },
    extraReducers: builder => {
        sfcObjectsWrapper.makeReducers(builder)
        appendSfcObjectsWrapper.makeReducers(builder)
    }
})

export const {clearSfsObjects} = sfcObjectsSlice.actions
export const sfcObjects = sfcObjectsSlice.reducer
