import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledLabel, StyledRow, StyledText} from './StyledPopoverContentRow'

type PopoverRow = {
    label: string
    rowText: string | number
    labelWidth?: number
    textWidth?: number
}

export const PopoverContentRow = ({label, rowText, labelWidth = 115, textWidth = 200}: PopoverRow): JSX.Element => {
    const {t} = useTranslation()
    return rowText ? (
        <StyledRow>
            <StyledLabel labelWidth={labelWidth}>{t(`activity:description:${label}`)}</StyledLabel>
            <StyledText textWidth={textWidth}>{rowText}</StyledText>
        </StyledRow>
    ) : (
        <></>
    )
}
