import styled, {keyframes} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {getFillColor, getBorderColor, getStatusColor} from 'src/newcore/features/Recovery/lib/getSubsourceCardColors'
import type {Status} from 'src/newcore/features/Recovery/lib/types'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'

export const StyledContainer = styled.div<{selection: CHECK_STATE; disabled: boolean}>`
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid ${p => (p.disabled ? variables.asphaltLight : getBorderColor(p.selection))};
    background-color: ${p => getFillColor(p.selection)};
    transition: background-color 0s step-end, border 0s step-end;
    transition-delay: 0.3s;
`

export const StyledSubsourceIcon = styled(Icon)`
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: ${variables.inProcess};
`

export const StyledStatusIndicator = styled.div<{status: Status}>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 12px;
    height: 12px;
    border: 2px solid ${variables.white};
    border-radius: 50%;
    background-color: ${p => getStatusColor(p.status)};
`

const fillTransition = keyframes`
    0% {
        width: 0;
        height: 0;
    }
    100% {
        width: 100%;
        height: 100%;
    }
`

export const StyledFill = styled.span<{selection: CHECK_STATE}>`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${p => getFillColor(p.selection)};
    animation: ${fillTransition} 0.3s ease forwards;
`

export const TooltipStyleWrapper = styled.div`
    &&& .ant-tooltip-content {
        margin-top: -10px;
    }
`
