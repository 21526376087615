import {combineReducers, createSlice} from '@reduxjs/toolkit'
import {get, post, postLessData} from 'src/newcore/utils/rest'
import {AccountActivityResponse, ActivityRecord} from 'ca-common/features/ActivityInfinityTable'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'
import {groupBy, last, map} from 'lodash'

const GET_ACTIVITY_API = 'accountActivity'

const GET_ACTIVITY = 'GET_ACTIVITY'

const getActivityWrapper = fetchWrapperRT<AccountActivityResponse>(
    GET_ACTIVITY,
    async data => await postLessData(GET_ACTIVITY_API, data),
    {
        after: (newActivities, oldActivity) => {
            const {data, page, success} = newActivities
            const oldActivityData = oldActivity.response?.data || []

            const result = map(groupBy([...oldActivityData, ...data], 'id'), last) as ActivityRecord[]

            return {
                page,
                success,
                data: result
            }
        }
    }
)

export const getActivity = getActivityWrapper.fetcher

const accountActivitySlice = createSlice({
    name: 'accountActivity',
    initialState: InitFetchedState as FetchedState<AccountActivityResponse>,
    reducers: {
        clearActivity: () => InitFetchedState as FetchedState<AccountActivityResponse>
    },
    extraReducers: builder => getActivityWrapper.makeReducers(builder)
})

export const {clearActivity} = accountActivitySlice.actions
export const accountActivity = accountActivitySlice.reducer

const GET_TASKS = 'GET_TASKS'
const GET_TASKS_API = 'getBackupTasks'
const getTasksWrapper = fetchWrapperRT(GET_TASKS, async data => await post(GET_TASKS_API, data))
export const getTasks = getTasksWrapper.fetcher

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearTasks: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getTasksWrapper.makeReducers(builder)
})

export const {clearTasks} = tasksSlice.actions
export const tasks = tasksSlice.reducer

const GET_TASK_INFO = 'GET_TASK_INFO'
const TASK_INFO_API = 'getBackupTaskInfo'
const getTaskInfoWrapper = fetchWrapperRT(GET_TASK_INFO, async data => await get(TASK_INFO_API, data))

const taskInfoSlice = createSlice({
    name: 'taskInfo',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearTaskInfo: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getTaskInfoWrapper.makeReducers(builder)
})

export const {clearTaskInfo} = taskInfoSlice.actions
export const taskInfo = taskInfoSlice.reducer

export const getTaskInfo = (data: {taskId: string}) => (dispatch: AppDispatch) => {
    return dispatch(
        getTaskInfoWrapper.fetcher({
            _search: false,
            rows: 1000,
            page: 1,
            sord: 'asc',
            ...data
        })
    )
}

const GET_SERVICE_LIST = 'GET_SERVICE_LIST'
const GET_SERVICE_LIST_API = 'getUserSources'
const serviceListWrapper = fetchWrapperRT(
    GET_SERVICE_LIST,
    async data => await postLessData(GET_SERVICE_LIST_API, data)
)
export const getServiceList = serviceListWrapper.fetcher

const serviceListSlice = createSlice({
    name: 'serviceList',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearServiceList: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => serviceListWrapper.makeReducers(builder)
})

export const {clearServiceList} = serviceListSlice.actions
export const serviceList = serviceListSlice.reducer

const CREATE_EXPORT_ACTIVITY = 'CREATE_EXPORT_ACTIVITY_TASK'
const CREATE_EXPORT_ACTIVITY_API = 'exportActivity'
const createExportActivityWrapper = fetchWrapperRT(
    CREATE_EXPORT_ACTIVITY,
    async data => await post(CREATE_EXPORT_ACTIVITY_API, data)
)
export const createExportActivity = createExportActivityWrapper.fetcher

export const activity = combineReducers({
    accountActivity,
    tasks,
    taskInfo,
    serviceList
})
