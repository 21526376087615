import styled from 'styled-components'

import variables from 'ca-common/variables'

export const MessageWrapper = styled.div`
    display: flex;
    margin-top: ${variables.gutterXxl};
    max-width: 500px;
    width: 100%;
    min-height: 300px;
    padding-top: ${variables.gutterXxl};
    text-align: center;
    font-size: 18px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h3 {
        margin: 0;
    }
`
