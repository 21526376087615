import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useHistory} from 'react-router-dom'

import {ChangePassword} from 'ca-common/organisms/ChangePassword'
import {toggleRecoveryExpiredVisible} from 'ca-common/redux/modules/sign'
import {PUBLIC_PAGES} from 'ca-common/constants'

import {useAppDispatch} from 'src/newcore/components/Root'
import {changeTemporaryPassword} from 'src/newcore/redux/modules/sign'

const mapStateToProps = state => ({
    isRecoveryExpiredVisible: state.signIn.isRecoveryExpiredVisible
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                toggleRecoveryExpiredVisible,
                changeTemporaryPassword
            },
            dispatch
        )
    }
})

const WrappedComponent = props => {
    const {actions, isRecoveryExpiredVisible} = props
    const history = useHistory()
    const dispatch = useAppDispatch()

    const newActions = {
        toggleRecoveryExpiredVisible: actions.toggleRecoveryExpiredVisible,
        changeTemporaryPassword: data => {
            return changeTemporaryPassword(data, dispatch, () => {
                history.push(PUBLIC_PAGES.SIGN_IN)
            })
        }
    }

    return <ChangePassword actions={newActions} isRecoveryExpiredVisible={isRecoveryExpiredVisible} />
}

export const ChangePasswordPage = connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
