import i18next, {i18n as I18n, Resource} from 'i18next'
import {initReactI18next} from 'react-i18next'
import _ from 'lodash'

import en from './en'

export const initLocale = (i18next: I18n, resources: Resource = {}) => {
    i18next.use(initReactI18next).init({
        lng: 'en-US',
        fallbackLng: 'en-US',
        resources: _.merge({en}, resources),
        ns: ['common'],
        defaultNS: 'common',
        react: {
            useSuspense: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'ol', 'li', 'strong', 'p', 'span', 'b', 'ul', 'i']
        }
    })

    return i18next
}

export const i18n = initLocale(i18next)
