import styled from 'styled-components'

import variables from 'ca-common/variables'

export const TotalObjectsWrapper = styled.div`
    margin-bottom: 20px;
`

export const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
export const CountJobs = styled.p`
    margin-bottom: 0;
    font-size: ${variables.headingFontSize};
    color: ${variables.navyBlue};
    margin-right: 4px;
`
export const SubtitleText = styled.p`
    margin: 0;
    font-size: ${variables.headingFontSize};
    color: ${variables.navyBlue};
    font-weight: 300;
`
