import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {FileDiffItemList} from 'src/newcore/components/CompareResults/FileDiffItemList'

import {getCompareMetadataFiles} from 'src/newcore/redux/modules/backups/backupCompare'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

@withTranslation()
export class CABackupCompareResultsRaw extends React.Component {
    componentDidMount() {
        const {actions, match, taskSettingsStatus} = this.props

        if (taskSettingsStatus !== 'fulfilled') {
            actions.getNewBackupTaskSettings({
                taskId: match.params.taskId
            }) //for breadcrumbs
        }

        actions.getCompareMetadataFiles({
            resultId: match.params.compareId,
            objectName: match.params.objectName,
            resultType: match.params.resultType
        })
    }

    render() {
        const {compareMetadataFiles, match} = this.props

        return compareMetadataFiles.status !== 'fulfilled' ? (
            <Spinner modifier="page" />
        ) : (
            <FileDiffItemList data={compareMetadataFiles.data} resultId={match.params.compareId} />
        )
    }
}

const mapStateToProps = state => ({
    compareMetadataFiles: {
        status: state.backups.compare.compareMetadataFiles.status,
        ...state.backups.compare.compareMetadataFiles.response
    },
    taskSettingsStatus: state.backups.settings.status
})

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                getCompareMetadataFiles,
                getNewBackupTaskSettings
            },
            dispatch
        )
    }
}

export const CABackupCompareResults = connect(mapStateToProps, mapDispatchToProps)(CABackupCompareResultsRaw)
