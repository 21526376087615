import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import queryString from 'query-string'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {SOURCE} from 'ca-common/common/enum'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {openErrorModal} from 'ca-common/utils/modals'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {BackLink, CancelLink, LinkWrapper, ModalContentWrapper, ModalFooter} from 'ca-common/components/Modal'
import Warning from 'ca-common/icons/Warning.svg'
import {OUAccountList} from './OUAccountList'
import {ModelButtonWrapper} from './OUAccountStyled'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {
    getAccountsWithOU,
    GoogleAccountsWithOUResponse
} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/GoogleAccountsWithOU'
import {clearExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {clearWizard, previousStep} from 'src/newcore/redux/modules/wizard'
import {clearGoogleData} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU'
import {pullBackupEntities} from 'src/newcore/redux/modules/backups/backupEntities/entities'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {activateBulkFlow} from 'src/newcore/redux/modules/backups/bulkActivate'
import {ConfirmationRuleSelectionModal} from 'src/newcore/components/Modals/ConfirmationRuleSelectionModal'
import {trackBulkActivation, TypesOfRules} from 'src/newcore/components/BulkActivation/trackBulkActivation'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'
import {Header, WarningIcon} from 'src/newcore/forms/ConfirmationRuleSelection/StyledConfirmationRuleSelection'
import {OUAccess} from './OUAccess'

export const GoogleOuAccounts = () => {
    const [isLoading, setLoading] = useState(true)
    const [oneTimeActivationLoader, setoneTimeActivationLoader] = useState(false)
    const [activationRuleLoader, setactivationRuleLoader] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [isOUAccessBlocked, setisOUAccessBlocked] = useState(true)
    const [blockedOuList, setblockedOuList] = useState([])
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)
    const selectedGroups = useAppSelector((state: AppState) => state.backups.bulkActivation.GoogleOUs.selectedOU)
    const {bulkAccountsStatus, bulkAccountsData} = useAppSelector((state: AppState) => ({
        bulkAccountsStatus: state.backups.bulkActivation.GoogleOUs.GoogleAccountsWithOU.status,
        bulkAccountsData: state.backups.bulkActivation.GoogleOUs.GoogleAccountsWithOU
            ?.response as GoogleAccountsWithOUResponse
    }))
    const excludeEmails = useAppSelector((state: AppState) => state.backups.bulkActivation.excludeEmails)
    const bulkActivationRules = useAppSelector(
        (state: AppState) => state.backups.bulkActivation.bulkActivationRules.rules.response?.data || []
    )
    const OURules = bulkActivationRules?.filter((item: any) => item.type === 'OUS')
    const rulesNames = OURules?.flatMap((i: any) => i.googleOu).map((item: any) => item.name)
    const notValidSelectedItems = selectedGroups
        .filter(item => rulesNames.includes(item.name))
        .map(item => ` ${item.name}`)

    const handleActivate = (createRule = false) => {
        if (createRule) {
            setactivationRuleLoader(true)
        } else {
            setoneTimeActivationLoader(true)
        }

        return dispatch(
            activateBulkFlow({
                taskSettings,
                selectedGroups,
                excludes: excludeEmails,
                createRule,
                cacheToken: bulkAccountsData?.cacheToken
            }) as any
        )
            .then((data: any) => {
                const succeed = data.succeed
                const total = data.total

                openSuccessNotification(t('backups:activatedSuccessfully', {succeed, total}))
            })
            .catch(() => {
                openErrorNotification(t('backups:MS365Groups:activationFailed'))
            })
            .finally(() => {
                const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

                dispatch(clearWizard())
                dispatch(clearGoogleData() as any)
                if (createRule) {
                    setactivationRuleLoader(false)
                } else {
                    setoneTimeActivationLoader(false)
                }
                dispatch(
                    pullBackupEntities({
                        taskId: taskSettings?.taskId,
                        rows: PAGE_SIZE,
                        page: DEFAULT_PAGE,
                        ...getRequestParamsFromUrl(queryParams)
                    }) as any
                )
            })
    }

    const handleActivateRule = async () => {
        if (taskSettings?.detectNewAccounts) {
            setIsVisible(true)
        } else {
            await handleActivate(true)
        }

        trackBulkActivation(EVENT_NAMES.CREATE_AN_ACTIVATION_RULE, taskSettings?.source, TypesOfRules.OU)
    }
    const handleConfirmationCancelClick = () => {
        setIsVisible(false)
        trackBulkActivation(
            EVENT_NAMES.REFUSAL_TO_CONFIRM_ACTIVATION_RULES_CREATION,
            taskSettings?.source,
            TypesOfRules.OU
        )
    }
    const handleConfirmationSubmit = () => {
        setIsVisible(false)
        handleActivate(true).then(() => {
            trackBulkActivation(EVENT_NAMES.CONFIRM_AN_ACTIVATION_RULE_CREATION, taskSettings?.source, TypesOfRules.OU)
        })
    }

    const isActivationDisabled = () =>
        'fulfilled' !== bulkAccountsStatus || bulkAccountsData?.total === excludeEmails.length

    const onCancel = () => {
        trackBulkActivation(EVENT_NAMES.CANCEL_BULK_ACTIVATION_LAST_STEP, taskSettings?.source, TypesOfRules.OU)
        dispatch(clearWizard())
    }

    const onActivate = () => {
        handleActivate().then(() => {
            trackBulkActivation(EVENT_NAMES.PERFORM_ONE_TIME_ACTIVATION, taskSettings?.source, TypesOfRules.OU)
        })
    }

    const fetchBlockedAccessOUs = (page: any) => {
        setLoading(true)
        dispatch(
            getAccountsWithOU({
                taskId: taskSettings?.taskId,
                rows: PAGE_SIZE,
                page,
                cacheToken: (page !== DEFAULT_PAGE && bulkAccountsData.cacheToken) || undefined,
                groups: selectedGroups
            })
        )
            .unwrap()
            .then((response: any) => {
                setLoading(false)
                if (response?.hasBlockedOUs) {
                    trackBulkActivation(EVENT_NAMES.GOOGLE_WORKSPACE_NO_ACCESS_ORG_UNIT)
                    setisOUAccessBlocked(true)
                    setblockedOuList(response?.blockedOUs)
                } else {
                    setisOUAccessBlocked(false)
                }
            })
            .catch((err: any) => {
                setLoading(false)
                openErrorModal(err)
            })
    }
    useEffect(() => {
        dispatch(clearExcludeEmails())
        fetchBlockedAccessOUs(DEFAULT_PAGE)
    }, [])

    return (
        <>
            <ModalContentWrapper>
                {isLoading && <Spinner modifier="page" />}
                {isOUAccessBlocked ? !isLoading && <OUAccess blockedGroupList={blockedOuList} /> : <OUAccountList />}
            </ModalContentWrapper>

            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}> {t('modals:buttons:cancel')}</CancelLink>
                    {isOUAccessBlocked ? null : (
                        <ModelButtonWrapper>
                            <Button type="primary" onClick={onActivate} disabled={false} loading={isLoading}>
                                {t('backups:bulkActivation:activate')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={
                                    notValidSelectedItems.length > 0
                                        ? () =>
                                              openErrorModal(
                                                  <Trans
                                                      i18nKey="backups:bulkActivation:groupsValidationError"
                                                      values={{
                                                          type: t('backups:bulkActivation:choice:GOOGLE_OU:type'),
                                                          items: notValidSelectedItems.toString()
                                                      }}
                                                  >
                                                      <strong>0</strong>
                                                  </Trans>
                                              )
                                        : handleActivateRule
                                }
                                disabled={isActivationDisabled()}
                                loading={isLoading}
                            >
                                <span>{t('backups:bulkActivation:activateRule')}</span>
                            </Button>
                        </ModelButtonWrapper>
                    )}
                </LinkWrapper>
                <BackLink onClick={() => dispatch(previousStep())} isHighlighted={true} />
            </ModalFooter>
            <ConfirmationRuleSelectionModal
                open={isVisible}
                onOk={handleConfirmationSubmit}
                onCancel={handleConfirmationCancelClick}
            >
                <Header>
                    <WarningIcon component={Warning} />
                    <p>
                        {t('backups:byCondition:popoverHeader', {
                            entityName: getSvcAccountsDescription(SOURCE.GOOGLEAPPS, true).toLowerCase()
                        })}
                    </p>
                </Header>
            </ConfirmationRuleSelectionModal>
        </>
    )
}
