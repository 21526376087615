import {Checkbox, Tooltip} from 'antd'
import React from 'react'
import {HotjarHidden} from 'ca-common/atoms/HotJarHidden'

import {AccountCheckbox, AccountEmail, AccountOUs, AccountName, AccountWrapper} from './OUAccountStyled'

type OUAccountItemProps = {
    active: boolean
    name: string
    mail: string
    groups: any
    onClick: any
}

export const OUAccountItem = (props: OUAccountItemProps) => {
    const {active, name, mail, groups, onClick} = props
    const ousString = groups?.map((ou: any) => ou.name).join(', ')

    return (
        <AccountWrapper onClick={onClick}>
            <AccountCheckbox>
                <Checkbox checked={active} />
            </AccountCheckbox>
            <AccountName>
                <Tooltip placement="top" title={<HotjarHidden>{name}</HotjarHidden>}>
                    {name}
                </Tooltip>
            </AccountName>
            <AccountEmail>
                <Tooltip placement="top" title={mail}>
                    {mail}
                </Tooltip>
            </AccountEmail>
            <AccountOUs>
                <Tooltip placement="top" title={<HotjarHidden>{ousString}</HotjarHidden>}>
                    {ousString}
                </Tooltip>
            </AccountOUs>
        </AccountWrapper>
    )
}
