import _ from 'lodash'
import React, {useEffect} from 'react'
import moment from 'moment'
import {useHistory} from 'react-router'
import queryString from 'query-string'
import {Form} from 'antd'

import {excludeOptionFromFormValues} from 'ca-common/utils/table'
import {dateFormat, DAY_MONTH_FORMAT} from 'ca-common/utils/datetime'
import {openErrorModal} from 'ca-common/utils/modals'
import {makeUrlParams} from 'ca-common/utils/url'

import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {getSecurityAudit, clearSecurityAudit} from 'src/newcore/redux/modules/accountActivity'
import {SecurityAuditTableOperation} from 'src/newcore/features/SecurityAudit/SecurityAuditTable/SecurityAuditTableOperation'
import {SecurityAuditTable} from 'src/newcore/features/SecurityAudit/organisms'
import {PAGE_SIZE} from 'src/newcore/features/SecurityAudit/lib/constants'
import {SecurityAuditWrapper} from 'src/newcore/features/SecurityAudit/StyledSecurityAudit'
import {SecurityAuditFormValues} from 'src/newcore/features/SecurityAudit/types'
import {convertDateFormatForServer} from 'src/newcore/features/SecurityAudit/lib/prepareFormValues'

export const SecurityAuditPage = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const securityAuditStatus = useAppSelector((state: AppState) => state.securityAudit.status)
    const securityAudit = useAppSelector((state: AppState) => state.securityAudit.response)
    const [securityAuditForm] = Form.useForm<SecurityAuditFormValues>()
    const params = queryString.parse(history.location.search) as any

    useEffect(() => {
        const pickedParams = makeUrlParams({...params, page: 'page' in params ? +params.page : 1})

        if (!securityAudit?.success) {
            dispatch(
                getSecurityAudit({
                    ...prepareFormValues(),
                    rows: PAGE_SIZE,
                    page: +params.page || 1
                })
            )
                .then(() => history.replace(`${history.location.pathname}?${pickedParams}`))
                .catch((response: any) => {
                    openErrorModal(response.status)
                })
        }
        return () => dispatch(clearSecurityAudit())
    }, [])

    const prepareInitialValues = () => {
        const temp = params
        const {startDate, endDate} = temp
        if (startDate && endDate) temp.date = [moment(startDate), moment(endDate)]
        else temp.date = []

        return temp
    }

    const prepareFormValues = () => {
        const values = securityAuditForm.getFieldsValue()
        const {date, ...filters} = values
        const startDate = date ? date[0] : ''
        const endDate = date ? date[1] : ''
        return excludeOptionFromFormValues(
            {
                ...filters,
                to: convertDateFormatForServer(endDate),
                from: convertDateFormatForServer(startDate)
            },
            ''
        )
    }

    const refreshPage = (values: Record<string, any>) => {
        const {date, ...rest} = values
        const startDate = date ? date[0] : ''
        const endDate = date ? date[1] : ''
        const options = {
            ...rest,
            page: 1,
            startDate: startDate && dateFormat(startDate, DAY_MONTH_FORMAT),
            endDate: endDate && dateFormat(endDate, DAY_MONTH_FORMAT)
        }

        const params = makeUrlParams(options)
        history.replace(`${history.location.pathname}?${params}`)
    }

    const submitForm = (values: Record<string, any>) => {
        dispatch(
            getSecurityAudit({
                ...prepareFormValues(),
                page: 1,
                rows: PAGE_SIZE
            })
        )
            .then(() => refreshPage(values))
            .catch((response: {status: any}) => {
                openErrorModal(response.status)
            })
    }
    const resetForm = () => {
        securityAuditForm.setFieldsValue({activity: '', status: '', date: []})
        submitForm({activity: '', status: '', date: moment()})
    }

    return (
        <SecurityAuditWrapper style={{maxWidth: '100%'}}>
            <SecurityAuditTableOperation
                submitForm={submitForm}
                initialValues={prepareInitialValues()}
                resetForm={resetForm}
                securityAuditForm={securityAuditForm}
            />
            <SecurityAuditTable
                status={securityAuditStatus === 'fulfilled'}
                data={securityAudit?.data}
                page={securityAudit?.page}
                fetchItemsByPage={(page: any) =>
                    dispatch(
                        getSecurityAudit({
                            rows: PAGE_SIZE,
                            ...prepareFormValues(),
                            page
                        })
                    )
                }
            />
        </SecurityAuditWrapper>
    )
}
