import React, {Ref} from 'react'
import {Spin} from 'antd'
import type {TreeProps} from 'antd/lib/tree/Tree'
import {CheckedElements, CHECK_STATE, CustomTree, TreeData, TreeStateProps} from 'src/newcore/organisms/CustomTree'
import {FolderTreeIcon, FolderTreeNodeProps} from 'src/newcore/features/Recovery/atoms'
import {
    FolderTreeHeader,
    FolderTreeHeaderProps
} from 'src/newcore/features/Recovery/organisms/FolderTree/atoms/FolderTreeHeader'

import {CheckFolderPayload} from 'src/newcore/features/Recovery/localState/actionsLocalState'
import {StyledContainer, StyledTreeContainer} from './StyledFolderTree'

type selectHandlerType = TreeProps['onSelect']

export type FolderTreeProps = {
    selectedFolder: TreeStateProps['selectedKey']
    checkedFolders: TreeStateProps['checkedKeys']
    setCheckedFolders: (data: CheckFolderPayload) => void
    setSelectedFolder: (key: string) => void
    treeSize: number
    parent: Ref<unknown> | undefined
    treeData: TreeData['data']
    loadingFolders: boolean
    restoredFolders: Record<string, boolean>
} & FolderTreeHeaderProps

export const FolderTree = ({
    treeData,
    checked,
    onCheckHeader,
    setSelectedFolder,
    selectedFolder,
    setCheckedFolders,
    checkedFolders,
    treeSize,
    parent,
    loadingFolders,
    restoredFolders
}: FolderTreeProps): JSX.Element => {
    const onSelect: selectHandlerType = (selectedKeys, {node}) => {
        setSelectedFolder(node.key as string)
    }

    const onCheck = (keys: CheckedElements) => {
        const {checked, halfChecked} = keys
        let checkAll = CHECK_STATE.PARTIAL

        if (treeSize === checked.length) {
            checkAll = CHECK_STATE.CHECKED
        }
        if (checked.length === 0 && halfChecked.length === 0) {
            checkAll = CHECK_STATE.UNCHECKED
        }
        setCheckedFolders({keys, checkAll})
    }

    return (
        <Spin spinning={loadingFolders}>
            <StyledContainer>
                <FolderTreeHeader checked={checked} onCheckHeader={onCheckHeader} />
                <StyledTreeContainer>
                    <CustomTree
                        restoredFolders={restoredFolders}
                        data={treeData}
                        search={false}
                        ref={parent}
                        onSelect={onSelect}
                        setCheckedKeys={onCheck}
                        selectedKey={selectedFolder}
                        checkedKeys={checkedFolders}
                        icon={(props: Omit<FolderTreeNodeProps, 'accent'>) => (
                            <FolderTreeIcon accent={restoredFolders[props.eventKey]} {...props} />
                        )}
                    />
                </StyledTreeContainer>
            </StyledContainer>
        </Spin>
    )
}
