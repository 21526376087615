import React from 'react'

import DropDownIcon from 'ca-common/icons/backupActionsNew/actionDropdown.svg'

import {
    ActionsDropdownIcon,
    StyledDropdown
} from 'src/newcore/features/Homepage/molecules/ActionsDropdown/StyledActionsDropdown'

type ActionsDropdownProps = {
    content: React.ReactElement
}
export const ActionsDropdown = ({content}: ActionsDropdownProps): JSX.Element => {
    return (
        <StyledDropdown placement="bottom" overlay={content}>
            <ActionsDropdownIcon component={DropDownIcon} />
        </StyledDropdown>
    )
}
