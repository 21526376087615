import styled from 'styled-components'

import variables from 'ca-common/variables'
import {VALIDATE_STATUS} from 'ca-common/common/enum/ValidateStatus'

export const StyledValidateStatus = styled.p`
    color: ${props => (props.validateStatus === VALIDATE_STATUS.SUCCESS ? variables.green : variables.red)};

    span {
        margin-left: ${variables.gutterXs};
    }
`
