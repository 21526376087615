import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_JOB_INITIATORS = 'GET_JOB_INITIATORS'
const GET_JOB_INITIATORS_API = 'getJobsInitiators'
const getJobInitiatorsWrapper = fetchWrapperRT(
    GET_JOB_INITIATORS,
    async data => await post(GET_JOB_INITIATORS_API, data)
)
export const getJobInitiators = getJobInitiatorsWrapper.fetcher

type JobInitiator = {
    mail: string
    name: string
}

type JobInitiatorsResponse = {
    initiators: JobInitiator[]
}

const initiatorsSlice = createSlice({
    name: 'initiators',
    initialState: InitFetchedState as FetchedState<JobInitiatorsResponse>,
    reducers: {
        clearJobInitiators: () => InitFetchedState as FetchedState<JobInitiatorsResponse>
    },
    extraReducers: builder => getJobInitiatorsWrapper.makeReducers(builder)
})

export const {clearJobInitiators} = initiatorsSlice.actions
export const initiators = initiatorsSlice.reducer
