import {useState} from 'react'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import type {AnonymizationSettingsChanges, Field} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'

export const useAnonymizationSettingsChanges = (initValues: AnonymizationSettingsChanges) => {
    const [anonymizationSettingsChanges, _setAnonymizationSettingsChanges] = useState(initValues)

    const setAnonymizationSettingsChanges = (object: string, field: string, value: Partial<Field>) => {
        let newValue: AnonymizationSettingsChanges = {}
        _setAnonymizationSettingsChanges(prev => {
            newValue = merge(cloneDeep(prev), {[object]: {[field]: value}})
            return newValue
        })

        return newValue
    }

    const resetAnonymizationChanges = () => {
        _setAnonymizationSettingsChanges({})
    }

    return {anonymizationSettingsChanges, resetAnonymizationChanges, setAnonymizationSettingsChanges}
}
