import React from 'react'
import PropTypes from 'prop-types'

import {adaptSeedingStatusToProgressBox, SEEDING_STATUS} from 'ca-common/common/enum/SeedingItem'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {openErrorModal} from 'ca-common/utils/modals'

import {ProgressBox} from 'src/newcore/components/ProgressBox'
import {SFCBackup} from 'src/newcore/components/SFCBackup'

import {StyledSeedingItem, StyledContent} from './StyledSeedingItem'
import {SeedingItemHeader} from './SeedingItemHeader'
import {ItemFooter} from './ItemFooter'

const seedingItemPropTypes = {
    status: PropTypes.oneOf([
        SEEDING_STATUS.SUCCESS,
        SEEDING_STATUS.IN_PROCESS,
        SEEDING_STATUS.FAILED,
        SEEDING_STATUS.LIMIT_EXCEEDED
    ]).isRequired,
    progress: PropTypes.number.isRequired,
    exception: PropTypes.string,
    createDate: PropTypes.number.isRequired,
    snapshotDate: PropTypes.number.isRequired,
    isSandbox: PropTypes.bool,
    target: PropTypes.string.isRequired,
    taskAlias: PropTypes.string.isRequired,
    objectCount: PropTypes.number.isRequired,
    type: PropTypes.oneOf([SFC_DATA_TYPE.DATA, SFC_DATA_TYPE.METADATA]).isRequired
}

const seedingItemDefaultProps = {
    isSandbox: false,
    exception: ''
}

export const SeedingItem = props => {
    const {
        status,
        progress,
        error,
        createDate,
        snapshotDate,
        isSandbox,
        target,
        taskAlias,
        objectCount,
        type,
        exportTaskId,
        getDownloadUrl
    } = props

    const onDownloadHandler = () => {
        getDownloadUrl({taskId: exportTaskId})
            .then(data => {
                setTimeout(() => {
                    window.open(data.payload.data, '_blank')
                }, 100)
            })
            .catch(err => openErrorModal(err))
    }

    return (
        <StyledSeedingItem>
            <SeedingItemHeader createDate={createDate} type={type} object={objectCount} />
            <StyledContent>
                <SFCBackup account={taskAlias} isSandbox={isSandbox} date={snapshotDate} />
                <ProgressBox status={adaptSeedingStatusToProgressBox(status)} progress={progress} exception={error} />
                <SFCBackup account={target} isSandbox date={-1} />
            </StyledContent>
            {exportTaskId && <ItemFooter onDownloadHandler={onDownloadHandler} />}
        </StyledSeedingItem>
    )
}

SeedingItem.propTypes = seedingItemPropTypes
SeedingItem.defaultProps = seedingItemDefaultProps
