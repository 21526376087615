import {createSlice} from '@reduxjs/toolkit'

import {InitFetchedState, fetchWrapperRT, FetchedState} from 'ca-common/utils/fetchWrapperRT'
import {postLessData} from 'src/newcore/utils/rest'
import {NewTaskSettings} from 'ca-common/types'

const NEW_BACKUP_TASK_SETTINGS_RECOVERY = 'NEW_BACKUP_TASK_SETTINGS_RECOVERY'
const NEW_BACKUP_TASK_SETTINGS_API = 'getNewBackupTaskSettings'

const wrapper = fetchWrapperRT(NEW_BACKUP_TASK_SETTINGS_RECOVERY, async (data, {rejectWithValue}) => {
    try {
        return await postLessData(NEW_BACKUP_TASK_SETTINGS_API, data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getNewBackupTaskSettings = wrapper.fetcher

const settingsSlice = createSlice({
    name: 'settings',
    initialState: InitFetchedState as FetchedState<NewTaskSettings>,
    reducers: {
        clearBackupTaskSettingsForAdvanced: () => InitFetchedState as FetchedState<NewTaskSettings>
    },
    extraReducers: builder => wrapper.makeReducers(builder)
})

export const {clearBackupTaskSettingsForAdvanced} = settingsSlice.actions
export const settings = settingsSlice.reducer
