import React from 'react'
import styled, {css} from 'styled-components'
import {transparentize} from 'polished'
import {Link} from 'react-router-dom'
import {Button, Tooltip, TooltipProps} from 'antd'
import {WarningFilled, ExclamationCircleFilled, InfoCircleFilled} from '@ant-design/icons'
import variables from 'ca-common/variables'
import type {AlertType} from './Alert'

export const StyledAlert = styled.aside<{type: AlertType}>`
    display: flex;
    align-items: center;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    background-color: ${variables.white};
    border: 1px solid
        ${props =>
            props.type === 'ERROR' ? variables.red : props.type === 'WARNING' ? variables.orange : variables.navyBlue};
    border-radius: ${variables.borderRadiusBase};
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.publicPageFont};

    &,
    a {
        color: ${props => (props.type === 'ERROR' ? variables.black : variables.navyBlue)};
    }

    svg {
        ${props =>
            props.type === 'WARNING' &&
            css`
                fill: ${variables.orange};
            `}
    }

    button {
        color: ${transparentize(0.45, variables.black)};
    }
`

export const StyledTitle = styled.h4<{type: AlertType}>`
    font-weight: 500;
    font-size: ${variables.fontSizeBase};
    margin: 0 0.2em 0 0;
    color: ${props =>
        props.type === 'ERROR' ? variables.black : props.type === 'WARNING' ? variables.orange : variables.navyBlue};
`

export const StyledMessage = styled.span`
    font-weight: 300;
    margin-right: 4px;
`

export const StyledLinkContainer = styled.span``

export const StyledLink = styled(Link)`
    text-decoration: underline;
`

export const StyledRightContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 22px;
    padding-left: ${variables.gutterSm};
    gap: 4px;
`

export const StyledDate = styled.span`
    font-size: 11px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0;

    color: ${variables.text};
`

export const StyledAlertButton = styled(Button)`
    margin-right: -10px;
`

export const StyledWarningIcon = styled(WarningFilled)`
    color: ${variables.red};
    font-size: 16px;
    margin-right: ${variables.gutterXs};
`

export const StyledReminderIcon = styled(ExclamationCircleFilled)`
    color: ${variables.navyBlue};
    font-size: 16px;
    margin-right: ${variables.gutterXs};
`

export const StyledTooltip = (props: JSX.IntrinsicAttributes & (TooltipProps & React.RefAttributes<unknown>)) => {
    const innerStyles: React.CSSProperties = {
        fontWeight: 300,
        fontFamily: variables.publicPageFont
    }
    return <Tooltip overlayInnerStyle={innerStyles} {...props} />
}

export const StyledTooltipIcon = styled(InfoCircleFilled)`
    color: ${variables.asphaltLight};
    font-size: 16px;
`
