import React, {useCallback, useState} from 'react'
import axios, {CancelTokenSource} from 'axios'

import {SOURCE} from 'ca-common/common/enum'

import type {Entity} from 'src/newcore/features/OneClickActivation/types'
import {fetchEntities as fetchEntitiesApi} from 'src/newcore/features/OneClickActivation/redux'
import {DEFAULT_PAGE, ENTITIES_PER_PAGE} from 'src/newcore/features/OneClickActivation/lib'

type UseFetchEntities = (params: {
    appendToTableData: (data: Entity[]) => void
    enabledEntitiesBitSet: string
    clearTableData: () => void
    batchId: string
    stateId: string
    setEntitiesBitSet: React.Dispatch<React.SetStateAction<string>>
}) => {
    isLoading: boolean
    cancelFetchEntities: () => void
    fetchEntities: (params: {search?: string; page?: number; service: SOURCE; entities?: string}) => void
    isLastPage: boolean
}

export const useFetchEntities: UseFetchEntities = ({
    appendToTableData,
    enabledEntitiesBitSet,
    clearTableData,
    batchId,
    setEntitiesBitSet,
    stateId
}) => {
    const [nextPage, setNextPage] = useState(DEFAULT_PAGE)
    const [isLastPage, setIsLastPage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [requestSource, setRequestSource] = useState<undefined | CancelTokenSource>(undefined)

    const cancelFetchEntities = () => {
        requestSource?.cancel()
    }

    const fetchEntities = useCallback(
        ({
            search = '',
            page = nextPage,
            service,
            entities = enabledEntitiesBitSet
        }: {
            search?: string
            page?: number
            service: SOURCE
            entities?: string
        }) => {
            setIsLoading(true)

            const CancelToken = axios.CancelToken
            const requestSource = CancelToken.source()
            setRequestSource(() => requestSource)

            fetchEntitiesApi({
                search,
                page,
                service,
                requestSource,
                batchId,
                stateId
            })
                .then(({data, entitiesBitSet, totalTaskEntities}) => {
                    setEntitiesBitSet(entitiesBitSet)

                    if (data.length > 0) {
                        setNextPage(page + 1)
                    }

                    if (data.length < ENTITIES_PER_PAGE) {
                        setIsLastPage(true)
                    } else {
                        setIsLastPage(false)
                    }

                    if (page === DEFAULT_PAGE) {
                        clearTableData()
                    }

                    appendToTableData(
                        data.map(item => ({
                            ...item,
                            checked: !!+entities[totalTaskEntities - item.ordinal - 1]
                        }))
                    )
                })
                .finally(() => setIsLoading(false))
        },
        [nextPage, enabledEntitiesBitSet]
    )

    return {fetchEntities, isLastPage, cancelFetchEntities, isLoading}
}
