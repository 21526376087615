import {Checkbox, Tooltip} from 'antd'
import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import {AccountCheckbox, AccountEmail, AccountValues, AccountName, AccountWrapper, AccountField} from './Styled'
import {HotjarHidden} from 'ca-common/atoms/HotJarHidden'

export const AccountItem = props => {
    const {active, name, mail, propertyValues, onClick, propertyName} = props
    const [values, setValues] = useState('')

    useEffect(() => {
        const result = _.join(propertyValues, ', ')
        setValues(result)
    }, [propertyValues])

    return (
        <AccountWrapper onClick={onClick}>
            <AccountCheckbox>
                <Checkbox checked={active} />
            </AccountCheckbox>
            <AccountName data-hj-suppress>
                <Tooltip placement="top" title={<HotjarHidden>{name}</HotjarHidden>}>
                    {name}
                </Tooltip>
            </AccountName>
            <AccountEmail>
                <Tooltip placement="top" title={mail}>
                    {mail}
                </Tooltip>
            </AccountEmail>
            <AccountField>
                <Tooltip placement="top" title={propertyName}>
                    {propertyName}
                </Tooltip>
            </AccountField>
            <AccountValues>
                <Tooltip placement="top" title={values}>
                    {values}
                </Tooltip>
            </AccountValues>
        </AccountWrapper>
    )
}
