import {combineReducers} from '@reduxjs/toolkit'
import {activeDirectoryAccounts, clearActiveDirectoryAccounts} from './ActiveDirectoryAccounts'
import {accountPropertyList, clearAccountPropertyList} from './ActiveDirectoryList'
import {
    accountPropertyName,
    accountPropertyValues,
    checkedAccountPropertyValues,
    clearAccountPropertyName,
    clearAccountPropertyValues,
    clearCheckedAccountPropertyValues
} from './AccountPropertyValues'
import {AppDispatch} from 'src/newcore/components/Root'

export const ActiveDirectoryReducer = combineReducers({
    activeDirectoryAccounts,
    accountPropertyList,
    accountPropertyValues,
    accountPropertyName,
    checkedAccountPropertyValues
})

export const clearActiveDirectoryData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(clearActiveDirectoryAccounts())
        dispatch(clearAccountPropertyList())
        dispatch(clearAccountPropertyValues())
        dispatch(clearAccountPropertyName())
        dispatch(clearCheckedAccountPropertyValues())
    }
}
