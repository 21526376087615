import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
    width: 300px;

    @media screen and (min-width: ${variables.screenXl}) {
        width: 170px;
    }

    @media screen and (min-width: ${variables.screenXxl}) {
        width: 220px;
    }

    :last-child {
        width: 92px;
        margin-right: ${variables.gutterSm};
    }
`

export const InfoLabel = styled.p`
    color: ${variables.navyAsphalt};
    margin-bottom: 4px;
`
export const Info = styled.p`
    color: ${variables.navyBlue};
    margin: 0;
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
