import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'

import {ResetPasswordSuccess, ResetPasswordFailed} from 'ca-common/components/ResetPassword'

import {resetPasswordConfirm} from 'src/newcore/redux/modules/resetPassword'
import {PORTAL_BASENAME, PUBLIC_PAGES} from 'ca-common/constants'

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            resetPasswordConfirm
        },
        dispatch
    )
})

const signIn = {
    to: `/${PORTAL_BASENAME + PUBLIC_PAGES.SIGN_IN}`,
    pureLink: true
}

const resetPassword = {
    to: `/${PORTAL_BASENAME + PUBLIC_PAGES.RESET_PASSWORD}`
}

export const ResetPasswordConfirmPage = withRouter(
    connect(
        null,
        mapDispatchToProps
    )(({actions, location}) => {
        const [confirmed, setConfirmed] = useState(true)

        const params = queryString.parse(location.search)

        useEffect(() => {
            actions
                .resetPasswordConfirm({
                    email: params.email,
                    resetToken: params.token
                })
                .catch(() => {
                    setConfirmed(false)
                })
        }, [])

        return confirmed ? (
            <ResetPasswordSuccess />
        ) : (
            <ResetPasswordFailed signIn={signIn} resetPassword={resetPassword} />
        )
    })
)
