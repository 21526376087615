import PropTypes from 'prop-types'
import {SELECTION_TYPE} from 'ca-common/common/enum/Table'

export const column = {
    name: PropTypes.string.isRequired,
    modifier: PropTypes.string,
    headerCell: PropTypes.func,
    contentCell: PropTypes.func
}

export const config = {
    table: PropTypes.shape({
        limitKey: PropTypes.string.isRequired
    }),
    columns: PropTypes.arrayOf(PropTypes.shape(column)).isRequired,
    row: PropTypes.shape({
        getRowModifier: PropTypes.func
    })
}

export const item = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export const items = {
    status: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape(item)),
    page: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number
}

export const rowSelection = {
    onItemClick: PropTypes.func,
    toggleSelectAllItems: PropTypes.func,
    setSelectAllItems: PropTypes.func,
    areAllItemsSelected: PropTypes.bool,
    arePageItemsSelected: PropTypes.bool,
    selectedItems: PropTypes.arrayOf(PropTypes.shape(item)),
    type: PropTypes.oneOf([SELECTION_TYPE.CHECKBOX, SELECTION_TYPE.RADIO])
}

export const baseTable = {
    parent: PropTypes.bool,
    scrollable: PropTypes.bool,
    noTotal: PropTypes.bool,
    rowSelection: PropTypes.shape(rowSelection),
    config: PropTypes.shape(config).isRequired,
    items: PropTypes.shape(items).isRequired,
    pageSize: PropTypes.number.isRequired,
    fetchItems: PropTypes.func,
    options: PropTypes.object
}
