import {Checkbox, Tooltip} from 'antd'
import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import {HotjarHidden} from 'ca-common/atoms/HotJarHidden'
import {siteFormatter} from 'ca-common/utils/entity'

import {AccountCheckbox, AccountEmail, AccountGroups, AccountName, AccountSite, AccountWrapper} from './Styled'

export const AccountItem = props => {
    const {active, name, mail, groups, onClick, url} = props
    const [groupsString, setGroupsString] = useState('')

    useEffect(() => {
        const result = _.join(_.map(groups, 'name'), ', ')
        setGroupsString(result)
    }, [groups])

    return (
        <AccountWrapper onClick={onClick}>
            <AccountCheckbox>
                <Checkbox checked={active} />
            </AccountCheckbox>
            <AccountName>
                <Tooltip placement="top" title={<HotjarHidden>{name}</HotjarHidden>}>
                    {name}
                </Tooltip>
            </AccountName>
            <AccountEmail>
                <Tooltip placement="top" title={mail}>
                    {mail}
                </Tooltip>
            </AccountEmail>
            {url && (
                <AccountSite>
                    <Tooltip placement="topLeft" title={url}>
                        {siteFormatter(url)}
                    </Tooltip>
                </AccountSite>
            )}
            <AccountGroups>
                <Tooltip placement="top" title={<HotjarHidden>{groupsString}</HotjarHidden>}>
                    {groupsString}
                </Tooltip>
            </AccountGroups>
        </AccountWrapper>
    )
}
