import queryString, {ParseOptions} from 'query-string'

export const QUERY_STRING_OPTIONS: ParseOptions = {arrayFormat: 'bracket'}

export const getUrlParams = (searchURL: string) => {
    return queryString.parse(searchURL, QUERY_STRING_OPTIONS)
}

export const makeUrlParams = (paramsObj: Record<string, unknown>) => {
    return queryString.stringify(paramsObj, QUERY_STRING_OPTIONS)
}
