import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {AnyAction} from 'redux'

import {i18n} from 'ca-common/locales'
import {openWarningNotification} from 'ca-common/utils/toasts'

import {
    RESTRICTION_TABLE_ACTIONS,
    MAX_IP_RULES_ALLOWED,
    prepareData,
    IpRestrictionActionsContext
} from 'ca-common/features/IpRestriction/lib'
import {Actions, Introduction, AddIpAddress, AddNewForm} from 'ca-common/features/IpRestriction/molecules'
import {RestrictionsTable} from 'ca-common/features/IpRestriction/organisms'
import {
    ActivateIpRules,
    CreateIpRules,
    RemoveIpRules,
    UpdateIpRules,
    RestrictionEntry,
    ActionHandler,
    AddIpFormFields
} from './types'

type IpRestrictionProps = {
    getIpRules: any
    selectIpRules: (state: any) => any
    createIpRules: CreateIpRules
    removeIpRules: RemoveIpRules
    updateIpRules: UpdateIpRules
    activateIpRules: ActivateIpRules
}

export const IpRestriction = (props: IpRestrictionProps): JSX.Element => {
    const {getIpRules, selectIpRules, createIpRules, removeIpRules, updateIpRules, activateIpRules} = props
    const ipRules = useSelector(selectIpRules)
    const dispatch = useDispatch()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [activateErrorText, setActivateErrorText] = useState('')

    useEffect(() => {
        dispatch(getIpRules())
    }, [])

    const restrictionAction: ActionHandler = (action: any) => {
        if (action.type === RESTRICTION_TABLE_ACTIONS.DELETE) {
            return dispatch((removeIpRules({options: {id: action.id}}) as unknown) as AnyAction)
                .then(() => {
                    const isLast = ipRules?.response?.items?.length === 1
                    const isActive = ipRules?.response?.active

                    isLast &&
                        isActive &&
                        openWarningNotification(
                            i18n.t('ipRestrictions:switcher:disableToast:text'),
                            i18n.t('ipRestrictions:switcher:disableToast:title')
                        )
                })
                .catch((err: any) => {
                    if (err?.data?.securitySettings) {
                        const errorText = err.data.securitySettings

                        setActivateErrorText(errorText)
                    }
                })
        }

        if (action.type === RESTRICTION_TABLE_ACTIONS.EDIT) {
            return dispatch(updateIpRules({...prepareData(action.entry), options: {id: action.id}}))
        }

        return Promise.reject('was called with an invalid action')
    }

    const tableNotEmpty = ipRules?.response?.items?.length > 0

    const addIpAddressHandler = (values: AddIpFormFields): Promise<any> => {
        return dispatch((createIpRules(prepareData(values)) as unknown) as AnyAction).then((response: any) => {
            if (!tableNotEmpty && !ipRules?.response?.active) {
                setShowConfirmation(true)
            }

            return Promise.resolve(response)
        })
    }

    const activateRules = (activate: boolean) => (dispatch(activateIpRules({activate})) as unknown) as Promise<any>

    return (
        <IpRestrictionActionsContext.Provider value={restrictionAction}>
            <Introduction yourIpAddress={ipRules?.response?.currentIp} />
            <Actions
                activateRules={activateRules}
                tableIsEmpty={!tableNotEmpty}
                active={ipRules?.response?.active}
                showConfirmation={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                activateErrorText={activateErrorText}
                setActivateErrorText={setActivateErrorText}
                addIpAddress={
                    <AddIpAddress
                        isLimitReached={ipRules?.response?.items?.length >= MAX_IP_RULES_ALLOWED}
                        addIpAddressHandler={addIpAddressHandler}
                        addNewForm={(form: any) => (
                            <AddNewForm form={form} yourIpAddress={ipRules?.response?.currentIp} />
                        )}
                    />
                }
            />
            <RestrictionsTable
                data={ipRules?.response?.items as RestrictionEntry[]}
                tableDisabled={!ipRules?.response?.active}
                loading={ipRules.status === 'pending'}
            />
        </IpRestrictionActionsContext.Provider>
    )
}
