import React, {useState} from 'react'

import {Collapse, MultiEntityHeader, MultiEntityFooter} from 'src/newcore/features/BackupSettings/organisms'
import {useBackupSettingsFormContext} from 'src/newcore/features/BackupSettings/contexts'

import {StyledBackupSettings, BackupSettingsForm} from '../StyledBackupSettings'

export const MultiEntitySettings = props => {
    const {
        taskSettings,
        onBackupAuthClick,
        removedRulesList,
        loading,
        editBackupSettings,
        clearRemovedRulesList,
        activeKey,
        togglePanel,
        children
    } = props

    const [formValues, setFormValues] = useState()

    const form = useBackupSettingsFormContext()

    const onValuesChange = values => {
        setFormValues(values) // hack for rerender footer when form select item changed, like as antd3; need refactor
    }

    return (
        <StyledBackupSettings>
            <Collapse
                activeKey={activeKey}
                header={
                    <MultiEntityHeader
                        source={taskSettings.source}
                        isAuthenticated={taskSettings.isAuthenticated}
                        onBackupAuthClick={onBackupAuthClick}
                        togglePanel={togglePanel}
                        activeKey={activeKey}
                    />
                }
                panelFooter={
                    <MultiEntityFooter
                        clearRemovedRulesList={clearRemovedRulesList}
                        removedRulesList={removedRulesList}
                        loading={loading}
                        activeKey={activeKey}
                        togglePanel={togglePanel}
                        form={form}
                        formValues={formValues}
                        taskSettings={taskSettings}
                    />
                }
            >
                <BackupSettingsForm
                    id="backupSettings"
                    form={form}
                    onValuesChange={onValuesChange}
                    onFinish={editBackupSettings}
                >
                    {children}
                </BackupSettingsForm>
            </Collapse>
        </StyledBackupSettings>
    )
}
