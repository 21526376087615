import React from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'

import {StyledFooter, TagsAction, TagsActionIcon} from '../StyledBackupTags'

import Create from 'ca-common/icons/Create.svg'
import Apply from 'ca-common/icons/Apply.svg'

import {
    createTagState,
    addEntityTag,
    removeEntityTag,
    resetTagsState,
    updateTags
} from 'src/newcore/redux/modules/backups/backupTags'

import {getBackupEntities} from 'src/newcore/redux/modules/backups'

import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {openErrorModal} from 'ca-common/utils/modals'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {LimitedAccessModal} from 'src/newcore/components/Modals'
import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

@withTranslation()
class FooterRaw extends React.Component {
    state = {
        modalVisible: false
    }

    applyTagState = () => {
        const {
            t,
            history,
            actions,
            taskSettings: {taskId, source},
            selectedAll,
            selectedRowKeys,
            setPopoverVisible,
            tagsState,
            setInitialTagsState,
            tags,
            setSearchTag,
            updatedLimitedAccess
        } = this.props

        let addListRequest
        let removeTagsRequest

        const {addedTagsStateList, removedTagsStateList} = this.getChangedTagStateList(tags)

        const queryParams = queryString.parse(history.location.search, {arrayFormat: 'bracket'})

        const getRequsetParams = (tagIds, flipAccessTag) => ({
            entityIds: selectedRowKeys,
            source,
            tagIds,
            all: selectedAll,
            taskId,
            taskFilter: getRequestParamsFromUrl(queryParams),
            flipAccessTag
        })

        if (updatedLimitedAccess && !this.state.modalVisible) {
            this.setState({modalVisible: true})
        } else {
            const needToAddTag = addedTagsStateList.length || (updatedLimitedAccess && this.props.selectedLimitedAccess)
            const needToRemoveTag =
                removedTagsStateList.length || (updatedLimitedAccess && !this.props.selectedLimitedAccess)

            if (needToAddTag) {
                addListRequest = actions.addEntityTag(
                    getRequsetParams(addedTagsStateList, updatedLimitedAccess && this.props.selectedLimitedAccess)
                )
            }

            if (needToRemoveTag) {
                removeTagsRequest = actions.removeEntityTag(
                    getRequsetParams(removedTagsStateList, updatedLimitedAccess && !this.props.selectedLimitedAccess)
                )
            }

            const Service = getServiceNameMixpanel(source)

            for (let i = 0; i < addedTagsStateList.length; i++) {
                track(EVENT_NAMES.TAG_ASSIGNED, {
                    'Tag Type': 'Custom',
                    Service
                })
            }

            for (let i = 0; i < removedTagsStateList.length; i++) {
                track(EVENT_NAMES.TAG_UNASSIGNED, {
                    'Tag Type': 'Custom',
                    Service
                })
            }

            if (updatedLimitedAccess) {
                track(this.props.selectedLimitedAccess ? EVENT_NAMES.TAG_ASSIGNED : EVENT_NAMES.TAG_UNASSIGNED, {
                    'Tag Type': 'Limited Access',
                    Service
                })
            }

            Promise.all([addListRequest, removeTagsRequest])
                .then(() => {
                    setSearchTag('')
                    actions.resetTagsState()
                    setInitialTagsState(tagsState.response)
                    openSuccessNotification(t('forms:backupTags:applyResponse:success'))
                    actions.getBackupEntities({
                        taskId,
                        rows: PAGE_SIZE,
                        page: DEFAULT_PAGE,
                        source,
                        ...getRequestParamsFromUrl(queryParams)
                    })
                    setPopoverVisible(false)
                })
                .catch(error => {
                    if (error.status) {
                        openErrorModal(error.status)
                    }
                })
        }
    }

    createTagStateFromSearch = () => {
        const {
            actions,
            searchTag,
            taskSettings: {taskId, source},
            t,
            setSearchTag,
            setPopoverVisible
        } = this.props

        const Service = getServiceNameMixpanel(source)

        track(EVENT_NAMES.CREATE_NEW_TAG, {Service})

        return actions
            .createTagState({
                text: searchTag,
                taskId,
                source
            })
            .unwrap()
            .then(payload => {
                setSearchTag('')
                actions.updateTags(payload.data)
                openSuccessNotification(t('forms:backupTags:response:success'))
            })
            .catch(error => {
                if (error.status) {
                    setPopoverVisible(false)
                    openErrorModal(error.status, null, () => setPopoverVisible(true))
                }
            })
    }

    getChangedTagStateList = tags =>
        tags.reduce(
            (result, key) => {
                result.addedTagsStateList = key.toApply
                    ? [...result.addedTagsStateList, key.id]
                    : result.addedTagsStateList
                result.removedTagsStateList = key.toDelete
                    ? [...result.removedTagsStateList, key.id]
                    : result.removedTagsStateList
                return result
            },
            {
                addedTagsStateList: [],
                removedTagsStateList: []
            }
        )

    updateModal = value => {
        this.setState({modalVisible: value})
        this.props.setPopoverVisible(true)
        if (value) {
            this.setState({modalVisible: false})
            this.applyTagState()
        }
    }

    render() {
        const {t, tags, filteredTags, searchTag, toggleBackupTagsForm} = this.props
        const {addedTagsStateList, removedTagsStateList} = this.getChangedTagStateList(tags)

        return (
            <>
                <StyledFooter>
                    {(filteredTags.length && (addedTagsStateList.length || removedTagsStateList.length)) ||
                    this.props.updatedLimitedAccess ? (
                        <TagsAction onClick={this.applyTagState}>
                            <TagsActionIcon component={Apply} />
                            {t('forms:backupTags:actions:apply')}
                        </TagsAction>
                    ) : (
                        <TagsAction
                            onClick={
                                filteredTags.length || !searchTag ? toggleBackupTagsForm : this.createTagStateFromSearch
                            }
                        >
                            <TagsActionIcon component={Create} />
                            {t('forms:backupTags:actions:create')}
                        </TagsAction>
                    )}
                </StyledFooter>
                <LimitedAccessModal modalVisible={this.state.modalVisible} updateModal={this.updateModal} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    tagsState: state.backups.backupTags.tagsState
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            createTagState,
            addEntityTag,
            removeEntityTag,
            getBackupEntities,
            resetTagsState,
            updateTags
        },
        dispatch
    )
})

export const Footer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterRaw))
