import React, {useEffect, Fragment, useMemo} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import queryString from 'query-string'
import {Tabs} from 'antd'
import {useHistory, useLocation} from 'react-router-dom'

import {setActiveTab} from 'src/newcore/redux/modules/backups/backupTaskSettings'

import {PANES, PANES_KEYS} from './SFCTabPanes'
import {StyledTabs} from './StyledTabs'
import {validatePageAccess} from 'ca-common/utils/permissions'

const {TabPane} = Tabs

export const SFCTabsRaw = props => {
    const location = useLocation()
    const history = useHistory()
    const {taskSettings, actions, activeTab, userInfo} = props

    const onChange = name => {
        actions.setActiveTab(name)
        history.replace({search: ''})
    }

    useEffect(() => {
        const {tab} = queryString.parse(location.search)

        if (tab === PANES_KEYS.OBJECTS && taskSettings.statDisabled) {
            actions.setActiveTab(PANES_KEYS.COMPARES)
        } else if (Object.values(PANES_KEYS).includes(tab)) {
            actions.setActiveTab(tab)
        } else {
            actions.setActiveTab(taskSettings.statDisabled ? PANES_KEYS.COMPARES : PANES_KEYS.OBJECTS)
        }
    }, [])

    const filteredPanes = useMemo(() => {
        return PANES.filter(pane => {
            const {key, permission} = pane

            return (
                validatePageAccess({page: permission, userInfo}) &&
                taskSettings.lastBackup &&
                (key === PANES_KEYS.OBJECTS ? !taskSettings.statDisabled : true)
            )
        })
    }, [taskSettings.lastBackup, userInfo, taskSettings.statDisabled])

    return (
        <Fragment>
            {!taskSettings.cached && (
                <StyledTabs destroyInactiveTabPane={true} activeKey={activeTab} onChange={onChange}>
                    {filteredPanes.map(({title, content, key}) => (
                        <TabPane tab={title} key={key}>
                            {content}
                        </TabPane>
                    ))}
                </StyledTabs>
            )}
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        activeTab: state.backups.backupTaskSettingsActiveTab,
        taskSettings: state.backups.settings?.response || {},
        userInfo: state.userInfo.response
    }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setActiveTab
        },
        dispatch
    )
})

export const SFCTabs = connect(mapStateToProps, mapDispatchToProps)(SFCTabsRaw)
