import React, {useState} from 'react'
import {Tooltip, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import queryString from 'query-string'
import {useHistory} from 'react-router-dom'

import type {FieldData} from 'rc-field-form/lib/interface'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {Errors} from 'ca-common/atoms/Errors'
import {
    Footer,
    StyledInput,
    StyledPasswordInput,
    StyledNoAccount,
    StyledForgotPassword,
    StyledLink,
    StyledLinkText,
    StyledFont,
    StyledFormItem
} from 'ca-common/forms/SignIn/StyledSignIn'
import {hasErrorsAntd4, hasEmptyErrorsAntd4, EMPTY_ERROR_MESSAGE} from 'ca-common/utils/form'

type SignInFormOwnProps = {
    onSubmit: (arg0: any) => void
    hasAccount: boolean
    error: null | string
    loading: boolean
}

export const SignInForm = (props: SignInFormOwnProps) => {
    const {t} = useTranslation()
    const history = useHistory()
    const {hasAccount, error, onSubmit, loading} = props
    const {email} = queryString.parse(history.location.search)

    const [hasErrors, setHasErrors] = useState(false)
    const [hasEmptyErrors, setHasEmptyErrors] = useState(false)

    const onFieldsChange = (_: FieldData[], allValues: FieldData[]) => {
        setHasErrors(state => {
            const temporary = hasErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })

        setHasEmptyErrors(state => {
            const temporary = hasEmptyErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })
    }

    return (
        <Form
            layout="horizontal"
            hideRequiredMark
            onFinish={onSubmit}
            initialValues={{email}}
            onFieldsChange={onFieldsChange}
        >
            {hasAccount && (
                <StyledNoAccount>
                    {t('sign:noAccounts')}
                    <StyledLink
                        to={{
                            pathname: '/sign-up',
                            search: history.location.search
                        }}
                    >
                        <StyledLinkText>{t('sign:up:signUpText')}</StyledLinkText>
                    </StyledLink>
                </StyledNoAccount>
            )}
            <StyledFormItem
                name="email"
                rules={[
                    {
                        required: true,
                        message: EMPTY_ERROR_MESSAGE
                    },
                    {
                        type: 'email',
                        message: t('forms:common:fields:email:validation:invalid')
                    }
                ]}
            >
                <StyledInput size="large" placeholder={t('forms:common:fields:email:placeholder')} />
            </StyledFormItem>
            <StyledFormItem
                name="password"
                rules={[
                    {
                        required: true,
                        message: EMPTY_ERROR_MESSAGE
                    }
                ]}
            >
                <StyledPasswordInput size="large" placeholder={t('forms:common:fields:password:placeholder')} />
            </StyledFormItem>
            <StyledLink
                to={{
                    pathname: '/reset-password',
                    search: history.location.search
                }}
            >
                <StyledForgotPassword>{t('sign:forgotPassword')}</StyledForgotPassword>
            </StyledLink>
            {error && <Errors error={error} />}
            <Footer>
                <Tooltip title={hasEmptyErrors && t('forms:common:fields:common:validation:required')}>
                    <Button
                        block
                        size="large"
                        color={variables.iceBlue}
                        type="primary"
                        htmlType="submit"
                        disabled={hasEmptyErrors || hasErrors || loading}
                    >
                        <StyledFont>{t('sign:in:submitText')}</StyledFont>
                    </Button>
                </Tooltip>
            </Footer>
        </Form>
    )
}
