import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const BULK_ACTIVATE_ACCOUNTS = 'BULK_ACTIVATE_ACCOUNTS'
const BULK_ACTIVATE_ACCOUNTS_API = 'bulkActivateTasks'
const bulkActivateAccountsWrapper = fetchWrapperRT(
    BULK_ACTIVATE_ACCOUNTS,
    async data => await post(BULK_ACTIVATE_ACCOUNTS_API, data)
)

export const bulkActivateAccounts = bulkActivateAccountsWrapper.fetcher

const bulkActivateAccountsResultSlice = createSlice({
    name: 'bulkActivateAccountsResult',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => bulkActivateAccountsWrapper.makeReducers(builder)
})
export const bulkActivateAccountsResultReducer = bulkActivateAccountsResultSlice.reducer
