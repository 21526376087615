import styled from 'styled-components'
import {Collapse} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'

const {Panel} = Collapse

export const StyledFileDiffItemList = styled.div``

export const FileDiffItemListCollapse = styled(Collapse)`
    &&& {
        border-radius: 0;

        .ant-collapse-header {
            height: 56px;
            display: flex;
            align-items: center;
            font-weight: 600;
            background: ${variables.white};

            &:hover {
                background: ${variables.blueLight};
            }
        }

        .ant-collapse-borderless > .ant-collapse-item {
            border: none;
        }

        .ant-collapse-content-box {
            padding: 0;
        }

        .ant-collapse-item-active {
            .ant-collapse-header {
                background: ${variables.brandLight};
            }
        }
    }
`

export const FileDiffItemListPanel = styled(Panel)`
    && {
        border: 0;
    }
`

export const ListTitle = styled.span`
    margin-left: ${variables.gutterSm};
`

export const DownloadIcon = styled.span`
    position: absolute;
    right: 70px;
    left: initial;
    color: ${variables.asphalt};
    padding: 5px;
    border-radius: 5px;

    &:hover {
        background-color: ${variables.blue};
    }
`

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 73px;
    background: ${variables.white};
    padding: 0 ${variables.gutterMd};
    border-bottom: 1px solid ${variables.blue};
`

export const HeaderTitile = styled.div`
    p {
        font-size: 18px;
        margin: 0;
        color: ${variables.dark};

        span {
            display: inline-block;
            color: ${variables.asphalt};
            margin-left: ${variables.gutterXs};
        }
    }
`

export const BarIcon = styled(Icon)`
    font-size: 24px;
    cursor: pointer;
    color: ${props => (props.active ? variables.dark : variables.blue)};

    &:last-child {
        transform: rotate(90deg);
        margin-right: ${variables.gutterMd};
    }
`

export const FileContentIcon = styled(Icon)`
    font-size: 16px;
    color: ${variables.dark};
`

export const ActionButton = styled(Button)`
    margin-left: ${variables.gutterSm};
    font-weight: 700;
`

export const ArrowDownIcon = styled(Icon)`
    &&&& {
        color: ${props => (props.active === 'true' ? variables.navyBlue : variables.asphalt)};
        font-size: 16px;
    }
`
