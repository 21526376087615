import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'
import {InputNumber, Radio, Select, Spin} from 'antd'
import React from 'react'
import {SeedingContentWrapper} from '../StyledSeeding'

export const SQLWhereClauseWrapper = styled.div`
    margin-top: 20px;
    white-space: normal;
`

export const ContentWrapper = styled(SeedingContentWrapper)`
    display: flex;
    padding: 0;

    & > div {
        flex: 1;

        &:first-child {
            border-right: 1px solid ${variables.navyBorder};
        }
    }
`

export const FilteringListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${variables.gutterMd};
    position: relative;
`

export const ListWrapper = styled.div`
    height: 400px;
    overflow-y: auto;
    padding-bottom: 48px; // to avoid intersections with deselect overlay
    margin-right: -${variables.gutterSm}; //Adjust scroll bar closer to the middle vertical line
    padding-right: ${variables.gutterXs};
    padding-top: ${variables.gutterXs};
`

export const DeselectOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${variables.white};
    padding: ${variables.gutterXs};
    opacity: 0.9;
    text-align: center;
`

export const ObjectItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${variables.gutterXs};
    border: 1px solid transparent;
    border-radius: ${variables.borderRadiusBase};
    transition: 0.2s;
    cursor: pointer;
    margin-bottom: 2px;

    &:hover {
        border: 1px solid ${variables.navyBlue};
    }
    ${props =>
        props.isActive &&
        css`
            background-color: ${variables.navyBlue};
            border: 1px solid ${variables.navyBlue};
            color: ${variables.white};

            &:hover {
                background-color: ${variables.brandDark};
            }

            ${ObjectItemName} {
                color: white;
            }
        `}

    ${props =>
        props.isDeleted &&
        css`
            cursor: not-allowed;

            &:hover {
                border: 1px solid transparent;
            }

            ${ObjectItemName} {
                color: ${variables.asphaltLight};
            }
            ${ObjectItemStatus} {
                color: ${variables.asphaltLight};
            }
            ${ObjectItemAction} {
                cursor: pointer;
                color: ${variables.navyBlue};
            }
        `}
`

export const ObjectItemName = styled.div`
    width: 200px;
    transition: 0.2s;
    color: ${variables.brandDark};
`

export const ObjectItemStatus = styled.div`
    margin: 0 ${variables.gutterXs};
    width: 140px;
`

export const ObjectItemAction = styled.div`
    font-size: 18px;
    line-height: 20px;
`

export const Hint = styled.div`
    font-size: ${variables.fontSizeSecondary};
    padding: 0 ${variables.gutterMd};
    color: ${variables.brandDark};
    margin-top: -18px;
`

// eslint-disable-next-line no-unused-vars
export const StyledRadio = styled(({isSeparated, ...props}) => <Radio {...props} />)`
    display: flex;
    align-items: center;
    margin-bottom: ${variables.gutterSm};
    margin-right: 0;
    ${props => props.visible && 'display: none;'}

    ${props =>
        props.isSeparated &&
        css`
            border-top: 1px solid ${variables.navyBorder};
            padding-top: ${variables.gutterSm};
        `}

    & > .ant-radio {
        margin-right: ${variables.gutterXs};
    }

    & > .ant-radio + span {
        width: 100%;
        padding-right: 0;
    }
`

export const StyledRadioWithoutMargin = styled(StyledRadio)`
    margin-bottom: 0;
`

export const StyledRadioWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: ${variables.gutterSm};
`

export const StyledInputNumber = styled(InputNumber)`
    margin: 0 ${variables.gutterXs};
`

export const SeedingPickTypeSelect = styled(Select)`
    margin: 0 ${variables.gutterXs};
    min-width: 100px;
`

export const FormTitleLabel = styled.span`
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    border-radius: 32px;
    background: ${props => (props.disabled ? variables.lightGray : variables.interaction)};
    color: ${props => (props.disabled ? variables.asphaltLight : variables.navyBlue)};
    margin-left: ${variables.gutterSm};
    padding: ${variables.gutterXs} ${variables.gutterSm};
`

export const FormTitle = styled.div`
    font-size: 20px;
    margin-bottom: ${variables.gutterSm};
`

export const CenteredSpinner = styled(Spin)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LabelWithTooltip = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledRecords = styled.span`
    font-size: ${variables.fontSizeBase};
`
