import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ContactsTableItem} from 'src/newcore/features/Recovery/fetch'

import {EmptyRow, StyledExpandedRow as Column, SingleField} from 'src/newcore/features/Recovery/atoms'
import {Items, StyledExpandedContactRow} from './StyledExpandedContactRow'

type ExpandedContactRowProps = {
    record: ContactsTableItem
}

export const ExpandedContactRow = (props: ExpandedContactRowProps): JSX.Element => {
    const {record} = props
    const {emails, job, addresses, phones, company} = record
    const {t} = useTranslation()

    const {firstColumn, secondColumn} = useMemo(() => {
        const firstColumn = [] as Array<'emails' | 'phones' | 'addresses'>
        const secondColumn = [] as Array<'company' | 'job'>

        if (emails.length) firstColumn.push('emails')
        if (phones.length) firstColumn.push('phones')
        if (addresses.length) firstColumn.push('addresses')
        if (company) secondColumn.push('company')
        if (job) secondColumn.push('job')

        return {firstColumn, secondColumn}
    }, [emails, job, addresses, phones, company])

    if (!firstColumn.length && !secondColumn.length) {
        return <EmptyRow />
    }

    return (
        <StyledExpandedContactRow>
            {firstColumn.length !== 0 && (
                <Column>
                    {firstColumn.map(field => (
                        <SingleField
                            title={t(`table:rows:fields:${field}`)}
                            key={field}
                            value={
                                <Items>
                                    {record[field].map(item => (
                                        <div key={item}>{item}</div>
                                    ))}
                                </Items>
                            }
                        />
                    ))}
                </Column>
            )}
            {secondColumn.length !== 0 && (
                <Column>
                    {secondColumn.map(field => (
                        <SingleField
                            key={field}
                            title={t(`table:rows:fields:${field}`)}
                            value={record[field] as string}
                        />
                    ))}
                </Column>
            )}
        </StyledExpandedContactRow>
    )
}
