import React, {useState} from 'react'
import {Form, Input, FormInstance} from 'antd'
import {Trans, useTranslation} from 'react-i18next'

import {
    VENDOR,
    VENDOR_CURRENCY_ERROR,
    ZOOZ_SUBSCRIBE_ERROR,
    CURRENCIES,
    PAYMENT_TYPE_UPPER_CASE
} from 'ca-common/constants'
import {createRules} from 'ca-common/utils/form'
import {requiredValidate} from 'ca-common/utils/validation'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {CompanyInfoType} from 'ca-common/types'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'
import {Button} from 'ca-common/ui-lib/atoms'
import {FieldsWrapper, FormFooter, RowWithOneItem, Separator} from '../StyledForm'
import type {PaymentDetailsType} from '../types'
import {CARD_SECURE_FIELDS_ID, usePaymentOS} from './usePaymentOS'
import {CardSecureFields} from './StyledCardDetails'

const SUBMIT_CARD_ERROR = -1
const AMEX_WITHOUT_USD_ERROR = 3

type CardDetailsProps = {
    isPP: boolean
    zoozSubscribeStatus: string
    companyInfo?: CompanyInfoType
    currency: CURRENCIES
    checkIsPaymentDetailsValid: () => Promise<boolean>
    closeAddBlock: () => void
    setShowAmexCurrencyError: React.Dispatch<React.SetStateAction<boolean>>
    getPaymentDetailsValues: () => PaymentDetailsType
    subscriptionType?: PAYMENT_TYPE_UPPER_CASE
    form: FormInstance<PaymentDetailsType>
    isSubscribed?: boolean
    dispatch: any
    setZoozSubscribe: any
    getPartnerPaymentInformation?: any
    getPosPublicKey: any
    getZoozPaymentMethods?: any
    updateUsersBilling?: any
    updatePaymentMethod?: any
    getNextPaymentDate?: any
    isPPDistributor?: boolean
}

export const CardDetailsPage = (props: CardDetailsProps) => {
    const {
        isPP,
        zoozSubscribeStatus,
        companyInfo,
        currency,
        checkIsPaymentDetailsValid,
        closeAddBlock,
        setShowAmexCurrencyError,
        getPaymentDetailsValues,
        subscriptionType,
        form,
        isSubscribed,
        dispatch,
        setZoozSubscribe,
        getPosPublicKey,
        getPartnerPaymentInformation,
        getZoozPaymentMethods,
        updateUsersBilling,
        updatePaymentMethod,
        getNextPaymentDate,
        isPPDistributor
    } = props
    const {t} = useTranslation()
    const [addCardLoader, setaddCardLoader] = useState(false)
    const createToken = usePaymentOS(dispatch, getPosPublicKey)

    const submitCardData = () => {
        const cardholderName = form.getFieldValue('cardholderName')
        return createToken(cardholderName)
    }

    const createBillingMethod = (data: {vendor: string; token: string}) => {
        const {vendor, token} = data
        const paymentDetailsValues = getPaymentDetailsValues()
        return dispatch(
            setZoozSubscribe({
                ...paymentDetailsValues,
                currency,
                vendor,
                paymentMethodToken: token,
                subscriptionType
            })
        )
            .unwrap()
            .then(() => {
                closeAddBlock()
                setaddCardLoader(false)
                openSuccessNotification(t('billing:zoozMessages:cardWasAdded'))
                if (isPP) {
                    dispatch(getPartnerPaymentInformation({}))
                } else {
                    dispatch(getZoozPaymentMethods())
                    dispatch(updateUsersBilling({paymentType: t('table:billing:subscribeType:monthly')}))
                    dispatch(getNextPaymentDate())
                    dispatch(
                        updatePaymentMethod({
                            subscriptionType: t('table:billing:subscribeType:monthly'),
                            isSubscribe: true
                        })
                    )
                }
            })
            .catch((error: any) => {
                setaddCardLoader(false)
                if (error.data?.currency?.includes('0x32')) {
                    return Promise.reject({statusCode: VENDOR_CURRENCY_ERROR})
                }
                return Promise.reject({statusCode: ZOOZ_SUBSCRIBE_ERROR, description: error.status})
            })
    }

    const handleOnClick = () => {
        return submitCardData()
            .then((data: any) => {
                if (data.vendor === VENDOR.AMEX && currency !== CURRENCIES.USD) {
                    return Promise.reject({statusCode: AMEX_WITHOUT_USD_ERROR})
                }

                return createBillingMethod(data)
            })
            .catch((err: any) => {
                setaddCardLoader(false)
                let errorMessage: string | JSX.Element = t('billing:zoozMessages:fillFormFields')
                const supportEmail = 'support@cloudally.com'
                if (err.statusCode === SUBMIT_CARD_ERROR) {
                    errorMessage = err.description
                } else if (err.statusCode === AMEX_WITHOUT_USD_ERROR) {
                    errorMessage = (
                        <Trans
                            i18nKey="billing:zoozMessages:amex"
                            values={{supportEmail: companyInfo != null ? companyInfo.supportEmail : null}}
                        >
                            <br />
                            <a href={`mailto:${companyInfo != null ? companyInfo.supportEmail : null}`}>
                                Support Email
                            </a>
                        </Trans>
                    )

                    setShowAmexCurrencyError(true)
                } else if (err.statusCode === VENDOR_CURRENCY_ERROR) {
                    errorMessage = (
                        <Trans i18nKey="billing:zoozMessages:amex" values={{supportEmail}}>
                            <br />
                            <a href={`mailto:${supportEmail}`}>Support Email</a>
                        </Trans>
                    )

                    setShowAmexCurrencyError(true)
                }

                return Promise.reject(errorMessage)
            })
    }

    const billCard = async () => {
        setaddCardLoader(true)
        if (await checkIsPaymentDetailsValid()) {
            handleOnClick().catch((err: any) => openErrorModal(err))
        } else {
            openErrorModal(t('billing:zoozMessages:fillFormFields'))
            setaddCardLoader(false)
        }
    }

    return (
        <JumbotronToggle
            title={t(
                `${
                    subscriptionType == PAYMENT_TYPE_UPPER_CASE.MONTHLY || isPPDistributor
                        ? 'forms:cards:details'
                        : 'forms:cards:name'
                }`
            )}
            margin={(isPP || subscriptionType == PAYMENT_TYPE_UPPER_CASE.ANNUAL) && !isSubscribed ? '0px' : undefined}
        >
            <Form form={form}>
                <FieldsWrapper>
                    <RowWithOneItem>
                        <Form.Item
                            name="cardholderName"
                            label={t('billing:cardDetails:cardHodersName')}
                            required
                            rules={createRules([requiredValidate])}
                        >
                            <Input placeholder={t('billing:cardDetails:cardHodersName')} />
                        </Form.Item>
                    </RowWithOneItem>
                    <RowWithOneItem>
                        <Form.Item name="cardSecureFields" label={t('billing:cardDetails:cardNumber')} required>
                            <CardSecureFields id={CARD_SECURE_FIELDS_ID} />
                        </Form.Item>
                    </RowWithOneItem>
                </FieldsWrapper>
                {(subscriptionType == PAYMENT_TYPE_UPPER_CASE.MONTHLY ||
                    ((isPP || subscriptionType == PAYMENT_TYPE_UPPER_CASE.ANNUAL) && isSubscribed)) && (
                    <FormFooter>
                        <Button
                            type="primary"
                            disabled={zoozSubscribeStatus === 'pending'}
                            onClick={billCard}
                            loading={addCardLoader}
                        >
                            {t('billing:zoozActions:addCard')}
                        </Button>
                    </FormFooter>
                )}
            </Form>
            {(isPP || subscriptionType == PAYMENT_TYPE_UPPER_CASE.ANNUAL) && !isSubscribed && <Separator />}
        </JumbotronToggle>
    )
}
