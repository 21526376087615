import {createAction, createReducer} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'

const INIT_SELECTED_ITEMS = [] as TODO_ANY[]

export const makeTableSelectedItems = (reducerName: string, init = INIT_SELECTED_ITEMS) => {
    const [set, append, reset] = [
        `SET_SELECTED_${reducerName}`,
        `APPEND_SELECTED_${reducerName}`,
        `RESET_SELECTED_${reducerName}`
    ]

    const actions = {
        set: createAction<TODO_ANY[]>(set),
        append: createAction<TODO_ANY[]>(append),
        reset: createAction(reset)
    }

    const reducer = createReducer(init, builder => {
        builder.addCase(actions.set, (state, action) => action.payload)
        builder.addCase(actions.append, (state, action) => [...state, ...action.payload])
        builder.addCase(actions.reset, () => init)
    })

    return {
        reducer,
        actions
    }
}
