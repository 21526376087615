import {Col} from 'antd'
import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledCol = styled(Col)`
    text-align: center;
`

export const StyledIcon = styled(Icon)`
    font-size: 50px;
    margin-top: ${variables.gutterMd};
`

export const StyledText = styled.strong`
    margin-top: ${variables.gutterSm};
    text-align: center;
    color: ${variables.brandDark};

    p {
        margin-bottom: 0;
    }
`

export const StyledFooterText = styled.span`
    margin-top: ${variables.gutterLg};
    color: ${variables.navyAsphalt};
    font-size: ${variables.fontSizeSecondary};
    text-align: center;
`
