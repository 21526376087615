import React, {useEffect} from 'react'
import {Select, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {REGION} from 'src/ca-common/common/enum'

import {
    TargetsWithSettings,
    STORAGE_SETTINGS,
    StorageSettingType,
    TARGET_DESTINATION,
    RecoveryErrorType,
    FULL_RECOVERY_ERRORS
} from 'src/newcore/features/RecoveryWizard/lib'
import {InlineErrorMessage} from 'src/newcore/features/RecoveryWizard/atoms/InlineErrorMessage'

import {
    StyledExportSubtitle,
    StyledInput,
    StyledLabel,
    StyledPasswordInput,
    StyledWrapper,
    FormItem
} from './StyledExportTargetSelection'
import {getFriendlyRegion} from 'ca-common/utils/Region'

const {Option} = Select

type Props = {
    storageType: TARGET_DESTINATION
    setStorageType: React.Dispatch<React.SetStateAction<TARGET_DESTINATION>>
    setAdditionalStorageSettings: React.Dispatch<React.SetStateAction<StorageSettingType>>
    error?: RecoveryErrorType
}

export const ExportTargetSelection = ({
    storageType,
    setStorageType,
    setAdditionalStorageSettings,
    error
}: Props): JSX.Element => {
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const i18settings = storageType === TARGET_DESTINATION.AZURE ? 'azureSettings' : 'settings'

    const formHandler = (values: StorageSettingType) =>
        setAdditionalStorageSettings(prevState => ({...prevState, ...values}))

    useEffect(() => {
        setAdditionalStorageSettings(form.getFieldsValue())
    }, [storageType])

    return (
        <Form form={form} onValuesChange={formHandler} style={{marginBottom: 50}}>
            <StyledWrapper>
                <StyledExportSubtitle>{t(`recovery:wizard:exportTargetSelection:subtitle`)}</StyledExportSubtitle>
                <Select
                    onChange={(value: TARGET_DESTINATION) => setStorageType(value)}
                    value={storageType}
                    style={{width: 330}}
                    getPopupContainer={t => t.parentElement as HTMLElement}
                >
                    {Object.keys(TARGET_DESTINATION).map(value => (
                        <Option key={value} value={value}>
                            {t(`recovery:wizard:targets:${value}`)}
                        </Option>
                    ))}
                </Select>
                {TargetsWithSettings.includes(storageType) && (
                    <div>
                        <label>
                            <StyledLabel>
                                {t(
                                    `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportBucketName}`
                                )}
                            </StyledLabel>
                            <FormItem
                                name={STORAGE_SETTINGS.exportBucketName}
                                rules={[
                                    {
                                        required: true,
                                        message: t('forms:common:validation:shortRequired')
                                    }
                                ]}
                            >
                                <StyledInput
                                    placeholder={t(
                                        `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportBucketName}`
                                    )}
                                />
                            </FormItem>
                        </label>
                        <label>
                            <StyledLabel>
                                {t(
                                    `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportToKey}`
                                )}
                            </StyledLabel>
                            <FormItem
                                name={STORAGE_SETTINGS.exportToKey}
                                rules={[
                                    {
                                        required: true,
                                        message: t('forms:common:validation:shortRequired')
                                    }
                                ]}
                            >
                                <StyledInput
                                    id={STORAGE_SETTINGS.exportToKey}
                                    placeholder={t(
                                        `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportToKey}`
                                    )}
                                />
                            </FormItem>
                        </label>
                        <label>
                            <StyledLabel>
                                {t(
                                    `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportToSecretKey}`
                                )}
                            </StyledLabel>
                            <FormItem
                                name={STORAGE_SETTINGS.exportToSecretKey}
                                rules={[
                                    {
                                        required: true,
                                        message: t('forms:common:validation:shortRequired')
                                    }
                                ]}
                            >
                                <StyledPasswordInput
                                    placeholder={t(
                                        `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.exportToSecretKey}`
                                    )}
                                />
                            </FormItem>
                        </label>
                        {storageType === TARGET_DESTINATION.AMAZON_S3_COMPATIBLE && (
                            <label>
                                <StyledLabel>
                                    {t(
                                        `recovery:wizard:exportTargetSelection:${i18settings}:${STORAGE_SETTINGS.compatibleS3Endpoint}`
                                    )}
                                </StyledLabel>
                                <FormItem
                                    name={STORAGE_SETTINGS.compatibleS3Endpoint}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('forms:common:validation:shortRequired')
                                        }
                                    ]}
                                >
                                    <StyledInput
                                        placeholder={t(
                                            `recovery:wizard:exportTargetSelection:settings:${STORAGE_SETTINGS.compatibleS3Endpoint}`
                                        )}
                                    />
                                </FormItem>
                            </label>
                        )}

                        {storageType !== TARGET_DESTINATION.AZURE && (
                            <label>
                                <StyledLabel>
                                    {t(
                                        `recovery:wizard:exportTargetSelection:settings:${STORAGE_SETTINGS.exportToRegion}`
                                    )}
                                </StyledLabel>
                                <FormItem name={STORAGE_SETTINGS.exportToRegion} initialValue={REGION.US_EST}>
                                    <Select
                                        style={{width: 330}}
                                        getPopupContainer={t => t.parentElement as HTMLElement}
                                    >
                                        {Object.values(REGION).map(value => (
                                            <Option key={value} value={value}>
                                                {getFriendlyRegion(value)}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </label>
                        )}
                    </div>
                )}
                {error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.ACCESS_DENIED && (
                    <InlineErrorMessage status={error.status} />
                )}
            </StyledWrapper>
        </Form>
    )
}
