import React, {useEffect} from 'react'
import _ from 'lodash'
import {Trans, useTranslation} from 'react-i18next'
import {Space, Form, Tooltip} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import Info from 'ca-common/icons/Info.svg'

import {
    changeConfig,
    changeData,
    setWizardDescription,
    setWizardTitle,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import {
    ENTITIES_OPTION,
    ENTITIES_RADIO_OPTIONS,
    FIELDS,
    OCA_SERVICES,
    SETTINGS_OPTION,
    SETTINGS_RADIO_OPTIONS,
    STEPS,
    getBackupConfigDefaultValue,
    getTotalEntitiesByService
} from 'src/newcore/features/OneClickActivation/lib'
import {useGetMSTotalEntitiesQuery} from 'src/newcore/features/OneClickActivation/redux'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {Footer} from 'src/newcore/features/OneClickActivation/molecules'
import {BackupNameInput, RadioGroupField} from 'src/newcore/features/OneClickActivation/atoms'

import {BackupNameFieldTitle, BodyWrapper, StyledBackupNameInputWrapper} from './StyledOCABackupSettingsStep'

export const OCABackupSettingsStep = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const {data: totalEntities = []} = useGetMSTotalEntitiesQuery(wizardData.id)

    const [form] = Form.useForm()

    const initialValues = {
        [FIELDS.BACKUP_NAME]: wizardData[FIELDS.BACKUP_NAME] || '',
        [FIELDS.ENTITIES_OPTION]: wizardData[FIELDS.ENTITIES_OPTION] || ENTITIES_OPTION.ACTIVATE_ALL_ENTITIES,
        [FIELDS.SETTINGS_OPTION]: wizardData[FIELDS.SETTINGS_OPTION] || SETTINGS_OPTION.USE_DEFAULT_SETTINGS
    }

    const beforeStepChange = async () => {
        await form.validateFields()
        const values = form.getFieldsValue() as typeof initialValues

        const skipableSteps = []

        const isDefaultSettings = values[FIELDS.SETTINGS_OPTION] === SETTINGS_OPTION.USE_DEFAULT_SETTINGS
        const isDefaultEntities = values[FIELDS.ENTITIES_OPTION] === ENTITIES_OPTION.ACTIVATE_ALL_ENTITIES

        if (isDefaultSettings) {
            skipableSteps.push(STEPS.SETTINGS_SELECTION)
        }

        if (isDefaultEntities) {
            skipableSteps.push(STEPS.ENTITIES_SELECTION)
        }

        const extraData = OCA_SERVICES.reduce((acc, service) => {
            const defaultConfig = getBackupConfigDefaultValue(service, totalEntities)
            if (acc[service] && isDefaultSettings) {
                acc[service]!.settings = defaultConfig.settings
            }

            if (acc[service] && isDefaultEntities) {
                acc[service]!.enabledEntitiesBitSet = defaultConfig.enabledEntitiesBitSet
            }

            if (acc[service] && !isDefaultEntities && !acc[service]!.enabledEntitiesBitSet.includes('0')) {
                acc[service]!.enabledEntitiesBitSet = ''.padStart(
                    getTotalEntitiesByService({totalEntities, service}),
                    '0'
                )
            }

            return acc
        }, _.cloneDeep(wizardData))

        dispatch(changeConfig({skipableSteps}))
        dispatch(changeData({...extraData, ...form.getFieldsValue()}))
    }

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:backupSettingsStep')))
        dispatch(setWizardDescription(wizardData.email))
    }, [])

    useEffect(() => {
        dispatch(changeConfig({beforeStepChange}))
    }, [wizardData])

    return (
        <Form initialValues={initialValues} form={form}>
            <BodyWrapper>
                <Space direction="vertical" size={16}>
                    <StyledBackupNameInputWrapper
                        colon={false}
                        label={
                            <Space size={4}>
                                <BackupNameFieldTitle>
                                    {t('oneClickActivation:wizard:labels:backupName')}
                                </BackupNameFieldTitle>
                                <Tooltip
                                    overlayInnerStyle={{width: '291px', fontSize: variables.fontSizeSecondary}}
                                    title={<Trans i18nKey="oneClickActivation:wizard:tooltips:backupName" />}
                                >
                                    <Icon component={Info} />
                                </Tooltip>
                            </Space>
                        }
                        required
                        rules={[
                            {
                                required: true,
                                message: t('oneClickActivation:wizard:errors:backupNameRequired')
                            }
                        ]}
                        name={FIELDS.BACKUP_NAME}
                    >
                        <BackupNameInput />
                    </StyledBackupNameInputWrapper>
                    <Form.Item name={FIELDS.ENTITIES_OPTION}>
                        <RadioGroupField
                            fieldName={t('oneClickActivation:wizard:labels:activationOfBackupEntities')}
                            options={ENTITIES_RADIO_OPTIONS}
                        />
                    </Form.Item>
                    <Form.Item name={FIELDS.SETTINGS_OPTION}>
                        <RadioGroupField
                            fieldName={t('oneClickActivation:wizard:labels:backupTaskSettings')}
                            options={SETTINGS_RADIO_OPTIONS}
                        />
                    </Form.Item>
                </Space>
            </BodyWrapper>
            <Footer isBackupSettingsStep />
        </Form>
    )
}
