import React from 'react'
import {Skeleton} from 'antd'

import {StyledText} from './StyledTotalObjects'

const DEFAULT_TITLE_HEIGHT = 30
const DEFAULT_TITLE_WIDTH = 250

type TotalObjectsProps = {
    loading?: boolean
    children: React.ReactNode
    height?: number
    width?: number
}

export const TotalObjects = (props: TotalObjectsProps): JSX.Element => {
    const {loading, children, height = DEFAULT_TITLE_HEIGHT, width = DEFAULT_TITLE_WIDTH} = props

    const titleProps = {
        width,
        style: {
            margin: 0,
            height
        }
    }

    return (
        <Skeleton loading={loading} active paragraph={false} title={titleProps}>
            <StyledText>{children}</StyledText>
        </Skeleton>
    )
}
