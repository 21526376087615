import {createSlice} from '@reduxjs/toolkit'

import {post, postLessData} from 'src/newcore/utils/rest'

import {PAGE_SIZE, BOTTOM_ENTITY_GAP} from 'src/newcore/components/BackupEntities/TableColumnsConfig'

import {getAppendedResult, getBottomEntityId} from 'ca-common/utils/backup/appendBackupEntities'
import {getBottomEntityIds, getPulledResult} from 'ca-common/utils/backup/appendPulledBackupEntities'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'
import {Id, TODO_ANY} from 'ca-common/types'
import {BACKUP_PROGRESS_STATUS} from 'ca-common/constants'
import {TASK_STATUS} from 'ca-common/common/enum'

const GET_BACKUP_ENTITIES = 'GET_BACKUP_ENTITIES'
const GET_BACKUP_ENTITIES_APPEND = 'GET_BACKUP_ENTITIES_APPEND'
const PULL_BACKUP_ENTITIES = 'PULL_BACKUP_ENTITIES'

const GET_BACKUP_ENTITIES_API = 'getEntities'
const PULL_BACKUP_ENTITIES_API = 'pullEntities'

const getBackupEntitiesWrapper = fetchWrapperRT<BackupEntitiesResponse>(
    GET_BACKUP_ENTITIES,
    async data => await postLessData(GET_BACKUP_ENTITIES_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                return {
                    ...response,
                    bottomEntities: [getBottomEntityId(response.data, PAGE_SIZE, BOTTOM_ENTITY_GAP)],
                    scrollPage: response.page
                }
            }

            return response
        }
    }
)

const getBackupEntitiesAppendWrapper = fetchWrapperRT<BackupEntitiesResponse>(
    GET_BACKUP_ENTITIES_APPEND,
    async data => await postLessData(GET_BACKUP_ENTITIES_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                const newEntitiesData = response.data
                const oldEntitiesData = state.response.data
                const oldBottomEntities = state.response.bottomEntities || []

                const result = getAppendedResult(newEntitiesData, oldEntitiesData)

                return {
                    ...response,
                    data: result,
                    bottomEntities: [
                        ...oldBottomEntities,
                        getBottomEntityId(response.data, PAGE_SIZE, BOTTOM_ENTITY_GAP)
                    ],
                    scrollPage: response.page
                }
            }

            return response
        }
    }
)

export const pullBackupEntitiesWrapper = fetchWrapperRT<BackupEntitiesResponse>(
    PULL_BACKUP_ENTITIES,
    async data => await postLessData(PULL_BACKUP_ENTITIES_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                const newEntitiesData = response.data
                const scrollPage = state.response.scrollPage
                const oldEntitiesData = state.response.data || []

                const entities = getPulledResult(newEntitiesData, oldEntitiesData, scrollPage, PAGE_SIZE)
                const bottomEntities = getBottomEntityIds(entities, PAGE_SIZE, BOTTOM_ENTITY_GAP)

                return {
                    ...state.response,
                    data: entities,
                    bottomEntities
                }
            }

            return response
        }
    }
)

export const getBackupEntities = getBackupEntitiesWrapper.fetcher
export const getBackupEntitiesAppend = getBackupEntitiesAppendWrapper.fetcher
export const pullBackupEntities = (getBackupEntitiesRequest: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(pullBackupEntitiesWrapper.fetcher(getBackupEntitiesRequest)).then(response => {
        return dispatch(updateEntities(response.payload))
    })
}

export type BackupEntity = {
    current: number
    entity: string
    extid: Id
    firstBackupDate: number
    id: Id
    lastBackup: string
    lastBackupDate: number
    name: string
    progressData: []
    retentionDate: number
    selected: boolean
    size: string
    state: BACKUP_PROGRESS_STATUS
    status: TASK_STATUS
    tags?: string[]
    limitedAccess: boolean
}

export type BackupEntitiesResponse = {
    activeEntities: number
    data: BackupEntity[]
    page: number
    pausedArchivedEntities: number
    totalPages: number
    total: number
    totalTaskEntities: number
    success: boolean
    scrollPage: number
    bottomEntities?: string[]
}

const entitiesSlice = createSlice({
    name: 'entities',
    initialState: InitFetchedState as FetchedState<BackupEntitiesResponse>,
    reducers: {
        updateEntities: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response.data = action.payload.data
                state.response.pausedArchivedEntities = action.payload.pausedArchivedEntities
                state.response.activeEntities = action.payload.activeEntities
            }

            return state
        },
        setCurrentScrollPage: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response.scrollPage = action.payload
            }

            return state
        },
        clearBackupEntities: () => InitFetchedState as FetchedState<BackupEntitiesResponse>
    },
    extraReducers: builder => {
        getBackupEntitiesWrapper.makeReducers(builder)
        getBackupEntitiesAppendWrapper.makeReducers(builder)
    }
})

export const {clearBackupEntities, updateEntities, setCurrentScrollPage} = entitiesSlice.actions
export const entitiesReducer = entitiesSlice.reducer

const ADD_ENTITY = 'ADD_ENTITY'
const ADD_ENTITY_API = 'addEntity'
const addEntityWrapper = fetchWrapperRT(ADD_ENTITY, async (data, {rejectWithValue}) => {
    try {
        return await post(ADD_ENTITY_API, data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})

export const addEntity = addEntityWrapper.fetcher
