import styled from 'styled-components'
import variables from 'ca-common/variables'

export const ModalContent = styled.div`
    padding: ${variables.gutterMd};
    max-height: 536px;
    overflow-y: auto;

    h2 {
        margin-bottom: ${variables.gutterMd};
        font-size: 18px;
    }
`

export const ObjectLabel = styled.span`
    color: ${variables.asphalt};
    background: ${variables.blue};
    padding: 0 ${variables.gutterXs};
    margin-right: ${variables.gutterSm};
    margin-bottom: ${variables.gutterSm};
    border-radius: ${variables.borderRadiusBase};
    line-height: 26px;
    cursor: pointer;
`

export const ObjectsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`
