import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledCompareItem = styled.div`
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${variables.gutterMd};
`

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 ${variables.gutterMd} ${variables.gutterMd};
`
