import React from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {DebounceInput} from 'react-debounce-input'
import ReactDOM from 'react-dom'

import CAInput from '../../Input'

import './LabeledInput.scss'

const dumbFormGroup = dumbBem('ca-form-group')
const FormGroup = tx(dumbFormGroup)('div')
const Label = tx([{element: 'label'}, dumbFormGroup])('label')
const Error = tx([{element: 'error'}, dumbFormGroup])('span')

export class DebouncedLabeledInput extends React.Component {
    inputRef = null
    componentDidUpdate = () => {
        const {meta} = this.props

        if (meta.active) {
            ReactDOM.findDOMNode(this.inputRef).focus()
        }
    }

    render() {
        const {
            input,
            label,
            htmlFor,
            meta: {touched, error, warning},
            required,
            ...rest
        } = this.props

        const fieldModifier = touched && error ? 'error' : ''
        const labelModifier = required ? 'required' : ''

        return (
            <FormGroup modifier="default labeled">
                {label && (
                    <Label htmlFor={htmlFor} modifier={labelModifier}>
                        {label}
                    </Label>
                )}
                <DebounceInput
                    {...input}
                    {...rest}
                    element={CAInput}
                    modifier={fieldModifier}
                    id={htmlFor}
                    ref={this.getInputRef}
                    autoFocus
                />
                {touched && ((error && <Error>{error}</Error>) || (warning && <span>{warning}</span>))}
            </FormGroup>
        )
    }

    getInputRef = node => {
        this.inputRef = node
    }
}
