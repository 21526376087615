import React from 'react'
import i18n from 'i18next'
import {reduce, map, reject, omit, isEmpty} from 'lodash'
import moment from 'moment'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar'
import {ActivityStatusValues, getStatusIcon} from 'ca-common/utils/getActivityStatusIcon'
import {getSourceName} from 'ca-common/utils/sources'
import type {TODO_ANY} from 'ca-common/types'

import {ACTIVITY_FIELD_NAMES} from 'ca-common/features/ActivityInfinityTable'
import {ValuesWithMomentDate} from 'src/newcore/features/ActivityTable/organisms/ActivityFilterForm'
import {FieldValues} from 'src/newcore/features/ActivityTable/types/fieldValues'
import {StyledSelectedFilters, StyledIcon} from './StyledSelectedFilters'

const nonArrayFields: string[] = [
    ACTIVITY_FIELD_NAMES.DATE_RANGE,
    ACTIVITY_FIELD_NAMES.TASK,
    ACTIVITY_FIELD_NAMES.ENTITIES
]

type Tag = {
    value: string
    label: string
    isSubSource?: boolean
    icon?: JSX.Element
}

type Task = {
    id: string
    alias: string
}

const prepareData = (data: ValuesWithMomentDate, tasks: Task[]) => {
    const masterSources = new Set()
    const {dateRange, taskId, entities} = data

    const omittedData = omit(data, nonArrayFields)

    const getIsSubSource = (item: string) => item.includes('__')

    const addMasterSource = (item: string, masterSourcesSet: Set<unknown>) => {
        const [masterSource] = item.split('__')
        masterSourcesSet.add(masterSource)
    }

    const getServiceName = (source: string, isSubSource: boolean) =>
        getSourceName(isSubSource ? source.split('__')[1] : source)

    const resultArray: Tag[] = reduce(
        (omittedData as unknown) as TODO_ANY[],
        (result: Tag[], current, key: string) => [
            ...result,
            ...map(current, item => {
                const isSubSource = getIsSubSource(item)
                const label = key === 'services' ? getServiceName(item, isSubSource) : i18n.t(`activity:${key}:${item}`)

                if (isSubSource) {
                    addMasterSource(item, masterSources)
                }

                return {
                    value: `${key}.${item}`,
                    isSubSource,
                    label,
                    icon: <StyledIcon component={getStatusIcon(label as ActivityStatusValues)} />
                }
            })
        ],
        []
    )

    masterSources.forEach(value => {
        const idx = resultArray.findIndex((el: {value: string}) => el.value.includes(`${value}__`))
        resultArray.splice(idx, 0, {
            value: `${ACTIVITY_FIELD_NAMES.SERVICE}.${value}`,
            label: getSourceName(value as string)
        })
    })

    if (dateRange) {
        const dateLabel = dateRange.map((momentDate: moment.Moment) => momentDate.format('DD MMM YYYY')).join(' - ')
        dateLabel &&
            resultArray.push({
                value: `${ACTIVITY_FIELD_NAMES.DATE_RANGE}.${dateRange}`,
                label: dateLabel
            })
    }

    if (tasks && taskId) {
        resultArray.push({
            value: `${ACTIVITY_FIELD_NAMES.TASK}.${taskId}`,
            label: tasks.find((task: {id: string}) => task.id === taskId)?.alias ?? ''
        })
    }

    if (!isEmpty(entities)) {
        resultArray.push({
            value: `${ACTIVITY_FIELD_NAMES.ENTITIES}.${entities}`,
            label: entities as string
        })
    }

    return resultArray
}

type SelectedFiltersProps = {
    tasks: Task[]
    values: ValuesWithMomentDate
    loading: boolean
    setFieldsValue: (values: FieldValues) => void
}

export const SelectedFilters = ({tasks, values, loading, setFieldsValue}: SelectedFiltersProps): JSX.Element => {
    const remove = (value: string): void => {
        const [parent, target] = value.split('.')

        if (nonArrayFields.includes(parent)) {
            setFieldsValue({...values, [parent]: undefined})
            return
        }

        const newValues = reduce(
            values as Pick<ValuesWithMomentDate, 'statuses' | 'services' | 'activities'>,
            (result, current, key) => ({
                ...result,
                [key]: parent === key ? reject(current, i => i.includes(`${target}__`) || i === target) : current
            }),
            {} as FieldValues
        )

        setFieldsValue(newValues)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: undefined}), {} as FieldValues)

        setFieldsValue(newValues)
    }

    const data = prepareData(values, tasks)

    return (
        <StyledSelectedFilters disabled={loading}>
            <SelectedFiltersBar selectedFilterValues={data} onRemove={remove} onRemoveAll={removeAll} />
        </StyledSelectedFilters>
    )
}
