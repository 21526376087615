import _ from 'lodash'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'
import {Spin} from 'antd'
import moment from 'moment'

import {
    getCompareResults,
    cancelCompareTask,
    clearCompareResults
} from 'src/newcore/redux/modules/backups/backupCompare'
import {actionOnTask} from 'src/newcore/features/Homepage/redux'

import {openErrorNotification} from 'ca-common/utils/toasts'
import {CompareListComponent} from './CompareListComponent'
import {ACTIONS} from 'src/newcore/features/Homepage/utils'

class CompareListRaw extends Component {
    fetchCompareList = () => {
        const {actions, match} = this.props

        actions.getCompareResults({taskId: match.params.taskId})
    }
    componentDidMount() {
        this.fetchCompareList()
    }

    componentWillUnmount() {
        const {actions} = this.props
        actions.clearCompareResults()
    }

    redirect = compareId => {
        const {match, history} = this.props

        history.push(`${match.url}/${compareId}`)
    }

    getPullConfig = () => {
        return {
            pull: this.fetchCompareList,
            short: moment.duration(1, 'minutes'),
            long: moment.duration(5, 'minutes'),
            stop: moment.duration(20, 'minutes')
        }
    }

    cancelTask = taskId => {
        const {actions} = this.props

        actions
            .actionOnTask({
                taskId,
                action: ACTIONS.CANCEL
            })
            .then(() => {
                this.fetchCompareList()
            })
            .catch(err => openErrorNotification(err.status))
    }

    render() {
        const {results} = this.props

        return (
            <Spin spinning={results.status !== 'fulfilled'}>
                <CompareListComponent
                    data={_.get(results, 'response.data', [])}
                    cancelTask={this.cancelTask}
                    redirect={this.redirect}
                    pullConfig={this.getPullConfig()}
                    fetch={this.fetchCompareList}
                />
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    results: state.backups.compare.compareResults
})

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                getCompareResults,
                actionOnTask,
                cancelCompareTask,
                clearCompareResults
            },
            dispatch
        )
    }
}

export const CompareList = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompareListRaw))
