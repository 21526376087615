import styled from 'styled-components'
import {Form} from 'antd'

import {RoundedInput} from 'ca-common/ui-lib/components/FormItem'
import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    padding: 0 ${variables.gutterMd} ${variables.gutterSm};

    & .ant-row.ant-form-item {
        width: 100%;
    }

    & .ant-col.ant-form-item-control-wrapper {
        width: 100%;
    }

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }
`

export const SearchInput = styled(RoundedInput)`
    min-width: 280px;
`
