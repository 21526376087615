import React from 'react'
import type {SelectProps as SelectPropsLib} from 'antd/lib/select'

import {SuffixIcon} from '../SuffixIcon'
import {SelectWrapper, StyledSelect, StyledSelectIcon, StyledInvertedSelectedIcon} from './StyledSelect'

import {IconComponent} from 'ca-common/types'
import DropDown from 'ca-common/icons/DropDown.svg'

export type SelectProps = SelectPropsLib & {
    loading?: boolean
    prefixIcon?: IconComponent
    selectWidth?: number
    placeholderWeight?: string | number
    clear?: boolean
    isInverted?: boolean
    borderWidth?: number
}

export const Select = React.forwardRef(
    (props: SelectProps, ref): JSX.Element => {
        const IconComponent = props.isInverted ? StyledInvertedSelectedIcon : StyledSelectIcon

        return (
            <SelectWrapper>
                <StyledSelect
                    suffixIcon={<SuffixIcon loading={props.loading} component={DropDown} />}
                    allowClear
                    showSearch
                    placeholderWeight="normal"
                    {...ref}
                    {...props}
                    disabled={props.disabled || props.loading}
                />
                {props.prefixIcon && <IconComponent disabled={props.disabled} component={props.prefixIcon} />}
            </SelectWrapper>
        )
    }
)
