import {post, postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const GET_EMAIL_REPORTS = 'GET_EMAIL_REPORTS'
const GET_EMAIL_REPORT = 'GET_EMAIL_REPORT'
const GET_REPORTS_SETTINGS = 'GET_REPORTS_SETTINGS'

const UsersNotifications = fetchWrapperRT(
    GET_EMAIL_REPORTS,
    async data => await postLessData('getNotificationUsers', data)
)
export const usersNotifications = UsersNotifications.fetcher

const notificationListSlice = createSlice({
    name: 'notificationList',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => UsersNotifications.makeReducers(builder)
})
export const usersNotificationsReducer = notificationListSlice.reducer

const UsersNotification = fetchWrapperRT(
    GET_EMAIL_REPORT,
    async data => await postLessData('getNotificationUserById', data)
)
export const usersNotification = UsersNotification.fetcher

const notificationReportSlice = createSlice({
    name: 'notificationReport',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => UsersNotification.makeReducers(builder)
})
export const usersNotificationReducer = notificationReportSlice.reducer

const GetNotificationSettings = fetchWrapperRT(
    GET_REPORTS_SETTINGS,
    async data => await post('getNotificationSettings', data)
)
export const getNotificationSettings = GetNotificationSettings.fetcher

const notificationsSettingsSlice = createSlice({
    name: 'notificationsSettings',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => GetNotificationSettings.makeReducers(builder)
})
export const getNotificationSettingsReducer = notificationsSettingsSlice.reducer

const NEW_EMAIL_REPORT = 'NEW_EMAIL_REPORT'

const NewUsersNotification = fetchWrapperRT(NEW_EMAIL_REPORT, async (data, {rejectWithValue}) => {
    try {
        return await post('newNotificationUser', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const newUsersNotification = NewUsersNotification.fetcher
// export const newUsersNotificationReducer = NewUsersNotification.getReducer()

const CHECK_REPORT_FILE = 'CHECK_REPORT_FILE'

const CheckReportFile = fetchWrapperRT(CHECK_REPORT_FILE, async data => await post('getDownloadReportAvailable', data))
export const checkReportFile = CheckReportFile.fetcher
// export const checkReportFileReducer = CheckReportFile.getReducer()

const DELETE_EMAIL_REPORT = 'DELETE_EMAIL_REPORT'

const DeleteUsersNotification = fetchWrapperRT(
    DELETE_EMAIL_REPORT,
    async data => await post('deleteNotificationUser', data)
)
export const deleteUsersNotification = DeleteUsersNotification.fetcher
// export const deleteUsersNotificationReducer = DeleteUsersNotification.getReducer()

const UPDATE_EMAIL_REPORTS = 'UPDATE_EMAIL_REPORTS'

const UpdateUsersNotification = fetchWrapperRT(UPDATE_EMAIL_REPORTS, async (data, {rejectWithValue}) => {
    try {
        return await post('updateNotificationUser', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const updateUsersNotification = UpdateUsersNotification.fetcher
// export const updateUsersNotificationReducer = UpdateUsersNotification.getReducer()

const UPDATE_REPORTS_SETTINGS = 'UPDATE_REPORTS_SETTINGS'

const UpdateNotificationSettings = fetchWrapperRT(
    UPDATE_REPORTS_SETTINGS,
    async data => await post('updateNotificationSettings', data)
)
export const updateNotificationSettings = UpdateNotificationSettings.fetcher
// export const updateNotificationSettingsReducer = UpdateNotificationSettings.getReducer()
