import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import {getLessData, postLessData} from 'src/newcore/utils/rest'
import type {EndUserType, EndUserResponseType} from 'src/newcore/components/Settings/Pages/Users/EndUserRecovery/types'

export const endUserApi = rtkQueryBaseApi.injectEndpoints({
    endpoints: build => ({
        getEndUserValue: build.query<boolean, any>({
            query: userId => ({
                url: 'endUserRecovery',
                method: getLessData,
                data: {}
            }),
            transformResponse: (res: EndUserResponseType) => {
                const isEnabled = res?.data?.enabled
                return isEnabled
            }
        }),
        updateEndUserValue: build.mutation<boolean, EndUserType>({
            query: value => ({
                url: 'endUserRecovery',
                method: postLessData,
                data: {
                    value
                }
            }),
            transformResponse: (res: EndUserResponseType) => {
                return res?.data?.enabled
            }
        })
    })
})

export const {useGetEndUserValueQuery, useUpdateEndUserValueMutation} = endUserApi
