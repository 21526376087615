import {combineReducers} from '@reduxjs/toolkit'

import {clearMS365Groups, MS365GroupsListReducer as MS365GroupsList} from './MS365GroupsList'
import {clearSelectedGroups, selectedGroupsReducer as selectedGroups} from './SelectedGroups'
import {clearAccountsWithGroups, MS365AccountsWithGroups} from './MS365AccountsWithGroups'
import {AppDispatch} from 'src/newcore/components/Root'

export const MS365GroupsReducer = combineReducers({
    MS365GroupsList,
    MS365AccountsWithGroups,
    selectedGroups
})

export const clearMS365Data = () => {
    return (dispatch: AppDispatch) => {
        dispatch(clearSelectedGroups())
        dispatch(clearAccountsWithGroups())
        dispatch(clearMS365Groups())
    }
}
