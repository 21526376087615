import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Body = styled.div`
    padding: 50px;
    border-top: 1px solid ${variables.blue};

    .ant-collapse {
        display: flex;
        flex-direction: column;
        gap: ${variables.gutterXxl};
        background-color: ${variables.white};
        border: none;
    }

    .ant-collapse-item {
        border: none;
    }

    .ant-collapse-header {
        background-color: ${variables.white};
        border: 1px solid ${variables.navyBorder};
        border-radius: ${variables.borderRadiusBase} !important;
    }

    .ant-collapse-header-text {
        max-width: 100%;
    }

    .ant-collapse-content {
        border: 1px solid ${variables.navyBorder};
        border-radius: ${variables.borderRadiusBase} !important;
        border-top: none;
        padding-top: 10px;
        margin-top: -10px;
        background-color: ${variables.blueLight};
    }

    .ant-collapse-content-box {
        padding: ${variables.gutterXxl} ${variables.gutterLg};
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: ${variables.gutterSm};
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon svg {
        transform: rotate(90deg);
    }

    .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-expand-icon svg {
        transform: rotate(-90deg) !important;
    }
`

export const Title = styled.span`
    color: ${variables.navyBlue};
    font-size: ${variables.gutterSm};
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Header = styled.div`
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
`
