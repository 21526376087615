import React from 'react'

import {transformBeNumbers, N_A} from 'src/newcore/features/Objects/libs'

import {NotAvailableTooltip} from 'src/newcore/features/Objects/atoms'

import {Number} from './StyledObjects'

export const DataColumn = props => {
    const {type, data} = props

    return (
        <NotAvailableTooltip type={data === N_A && type}>
            <Number>{transformBeNumbers(data)}</Number>
        </NotAvailableTooltip>
    )
}
