import React, {Fragment} from 'react'
import {Row, Col, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {compareDateFormat} from 'ca-common/utils/datetime'

import Info from 'ca-common/icons/Info.svg'
import {SOURCE_INFO} from 'ca-common/common/enum/Source'

import {
    StyledContainer,
    StyledTooltipIcon,
    StyledSourceIcon,
    StyledSource,
    StyledTaskStatus
} from './StyledEntitySourceInfo'

const Source = ({source}) => {
    return (
        <Fragment>
            <StyledSourceIcon source={source} />
            <StyledSource>{SOURCE_INFO[source].displayName}</StyledSource>
        </Fragment>
    )
}

const Items = ({items, indexEnabled}) => {
    const {t} = useTranslation()
    const title = indexEnabled
        ? t('entitySourceInfo:itemsTooltipTitle:indexEnabled')
        : t('entitySourceInfo:itemsTooltipTitle:indexNotEnabled')
    const isFinishInitialIndexation = +items >= 0

    return (
        <Fragment>
            {t('entitySourceInfo:itemsLabel')}
            <strong>{indexEnabled && isFinishInitialIndexation ? items : t('entitySourceInfo:noItems')}</strong>
            <Tooltip title={title}>
                <StyledTooltipIcon component={Info} />
            </Tooltip>
        </Fragment>
    )
}

export const EntitySourceInfo = ({subsource, status, items, lastBackupDate, indexEnabled}) => {
    const {t} = useTranslation()
    return (
        <StyledContainer>
            <Row type="flex" align="middle">
                <Col span={12}>
                    <Source source={subsource} />
                </Col>
                <Col span={12}>
                    <Row type="flex" justify="end">
                        <StyledTaskStatus status={status} reverse />
                    </Row>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={10}>
                    <Items items={items} indexEnabled={indexEnabled} />
                </Col>
                <Col span={14}>
                    <Row type="flex" justify="end">
                        {lastBackupDate ? compareDateFormat(lastBackupDate) : t('entitySourceInfo:notApplicable')}
                    </Row>
                </Col>
            </Row>
        </StyledContainer>
    )
}

EntitySourceInfo.propTypes = {
    subsource: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    items: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    lastBackupDate: PropTypes.number.isRequired,
    indexEnabled: PropTypes.bool
}

EntitySourceInfo.defaultProps = {
    indexEnabled: false
}
