import React from 'react'
import {Switch} from 'react-router'
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'

import {NotFound} from 'ca-common/components/NotFound'
import {PAGES} from 'ca-common/constants'
import {PermissionRoute} from 'ca-common/components/PermissionRoute'

import {NotificationsRouting} from 'src/newcore/components/Routing/NotificationsRouting'
import {UsersRouting} from 'src/newcore/components/Routing/UsersRouting'
import {SettingsPage, AccountPage, Security} from 'src/newcore/components/Settings/Pages'
import {callReload} from 'src/newcore/utils/logout'

class SettingsRoutingRaw extends React.Component {
    render() {
        const {match, userInfo} = this.props
        const {isSpecialPartnerAccount} = userInfo

        userInfo?.isMsEndUser && callReload('', '?msgType=custom&modalName=AccessDenied')
        return (
            <Switch>
                <Route path={`${match.url}/`} exact component={SettingsPage} />
                <PermissionRoute
                    checkPage={PAGES.NOTIFICATIONS}
                    component={NotificationsRouting}
                    path={`${match.url}/${PAGES.NOTIFICATIONS}`}
                />
                <PermissionRoute
                    checkPage={PAGES.ACCOUNT}
                    exact
                    component={AccountPage}
                    path={`${match.url}/${PAGES.ACCOUNT}`}
                />
                <PermissionRoute
                    checkPage={PAGES.USERS}
                    component={UsersRouting}
                    path={`${match.url}/${PAGES.USERS}`}
                />
                {!isSpecialPartnerAccount && <Route path={`${match.url}/security`} component={Security} />}
                <Route component={NotFound} />
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    userInfo: state.userInfo.response
})

export const SettingsRouting = connect(mapStateToProps)(SettingsRoutingRaw)
