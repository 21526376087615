import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledJobsList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-bottom: ${variables.gutterLg};
    position: relative;
`
