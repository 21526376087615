type Player = {
    deactivate: () => void
}

export type InlineManualCallbacksType = Record<string, (player: Player) => void>

export const inlineManualCallbacks: InlineManualCallbacksType = {
    releaseHighlightsStep: function (player) {
        const skipButton = document.querySelector('.release-highlights #inline-manual-skip-button')
        const closeIcon = document.querySelector('.release-highlights #inline-manual-close-icon')

        skipButton &&
            skipButton.addEventListener('click', () => {
                player.deactivate()
            })

        closeIcon &&
            closeIcon.addEventListener('click', () => {
                player.deactivate()
            })
    }
}
