export default {
    res_svc_gmail: 'service_icons/service_google.svg',
    res_svc_gapps: 'service_icons/service_gsuits.svg',
    res_svc_sforce: 'service_icons/service_salesforce.svg',
    res_svc_aws_sdb: 'service_icons/service_simpledb.svg',
    res_svc_aws_ddb: 'service_icons/service_dynamodb.svg',
    res_svc_aws_ymail: 'service_icons/service_yahoo.svg',
    res_svc_aws_mso365: 'service_icons/service_officeexchange.svg',
    res_svc_aws_msg365: 'service_icons/service_officegroups.svg',
    res_svc_sharepoint: 'service_icons/service_sharepoint.svg',
    res_svc_onedrive: 'service_icons/service_onedrive.svg',
    res_svc_imap: 'service_icons/service_imap.svg',
    res_svc_box: 'service_icons/service_box.svg',
    res_svc_gdrive: 'service_icons/service_gtdrive.svg',
    res_svc_dropbox: 'service_icons/service_dropbox.svg',

    //App Message
    res_message_icon_Info: 'message/icon_info.png',
    res_message_icon_Success: 'message/icon_success.png',
    res_message_icon_Warning: 'message/icon_warning.png',
    res_message_icon_Error: 'message/icon_error.png',
    res_message_icon_Progress: 'message/loading.gif'
}
