import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledIcon = styled(Icon)`
    font-size: 50px;
    margin-top: ${variables.gutterMd};
`

export const StyledText = styled.strong`
    margin-top: ${variables.gutterSm};
    margin-bottom: ${variables.gutterLg};
    text-align: center;
    color: ${variables.brandDark};

    p {
        margin-bottom: 0;
    }
`

export const StyledLink = styled(CALink)`
    padding: 0;
`

export const StyledTextLink = styled(StyledLink)`
    margin-top: ${variables.gutterMd};
    font-family: ${variables.accentFont};
    font-weight: bold;
    color: ${variables.iceBlue};
`

export const StyledResetLink = styled(CALink)`
    background: ${variables.iceBlue};
    border-color: ${variables.iceBlue};
    font-weight: 600;
    line-height: 38px;
    width: 100%;
    text-align: center;
    border-radius: ${variables.borderRadiusBase};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.white} !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

    &:hover,
    &:focus {
        background: #82d7ff;
        border-color: #82d7ff;
    }
`
