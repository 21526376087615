import React from 'react'
import {Radio, Space} from 'antd'
import {useTranslation} from 'react-i18next'
import {SwitchRadioGroup, SwitchSettingsLabel} from 'ca-common/ui-lib/molecules/SwitchBar'
import {SubsourceFormat} from 'src/newcore/features/RecoveryWizard/lib'

type FormatRadioGroupProps = {
    handleFormatChange: any
    value: SubsourceFormat
    options: {value: SubsourceFormat; label: React.ReactElement}[]
}

export const FormatRadioGroup = ({handleFormatChange, value, options}: FormatRadioGroupProps) => {
    const {t} = useTranslation()
    return (
        <>
            <SwitchSettingsLabel>{t('recovery:wizard:subServiceSelection:format:title')}</SwitchSettingsLabel>
            <SwitchRadioGroup name="format" onChange={handleFormatChange} value={value}>
                <Space direction="vertical">
                    {options.map(({value, label}) => (
                        <Radio key={value} value={value}>
                            {label}
                        </Radio>
                    ))}
                </Space>
            </SwitchRadioGroup>
        </>
    )
}
