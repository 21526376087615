import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {Form, Input, Checkbox, Row, Col} from 'antd'
import {Button} from 'ca-common/ui-lib/atoms'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {requiredValidate, emailValidate} from 'ca-common/utils/validation'
import {createRules} from './utils'
import {StyledWrapper, StyledTitle, Footer, CheckboxRow} from './StyledNotificationForm'

type NotificationFormProps = {
    isPortal?: boolean
    onSubmit: any
    initialValues: Record<string, any>
    isLoaded: boolean
}
type NotificationFormType = {
    email: string
    name: string
    summaryReport: boolean
    recovery: boolean
    exception: boolean
    statusReport: boolean
    smartAlerts: boolean
}
export const NotificationForm = (props: NotificationFormProps) => {
    const {t} = useTranslation()
    const history = useHistory()
    const [notificationForm] = Form.useForm<NotificationFormType>()
    const {isPortal, onSubmit, initialValues, isLoaded} = props
    const [isDisable, setDisabled] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [emailValidateStatus, setEmailValidateStatus] = useState(false)
    const onValuesChange = () => {
        setDisabled(false)

        if (notificationForm.isFieldTouched('email')) {
            setEmailError('')
            setEmailValidateStatus(!emailValidateStatus)
        }
    }

    const onFinish = (values: NotificationFormType) => {
        setDisabled(true)
        setSubmitting(true)
        onSubmit(values).catch((err: any) => {
            if (err.errors.email === 'Notification user exists in the notification list') {
                setEmailError(err.errors.email)
                setEmailValidateStatus(!emailValidateStatus)
                setSubmitting(false)
            }
        })
    }
    const extraproperties = emailError
        ? ({
              validateStatus: emailValidateStatus ? 'error' : 'validating',
              help: emailError
          } as any)
        : {}

    if (!isLoaded) {
        return <Spinner modifier="page" />
    }

    return (
        <Form
            initialValues={initialValues}
            onFinish={onFinish}
            colon={false}
            form={notificationForm}
            onValuesChange={onValuesChange}
        >
            <StyledWrapper>
                <StyledTitle>{t('notifications:userInfo')}</StyledTitle>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name={'email'}
                            label={t('notifications:email')}
                            colon={false}
                            htmlFor={'email'}
                            rules={createRules([requiredValidate, emailValidate])}
                            {...extraproperties}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'name'}
                            label={t('notifications:displayName')}
                            colon={false}
                            htmlFor={'name'}
                            rules={createRules([requiredValidate])}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </StyledWrapper>
            <StyledWrapper style={{overflow: 'inherit'}}>
                <StyledTitle>{t('notifications:notifications')}</StyledTitle>
                <CheckboxRow>
                    <Form.Item
                        name={'summaryReport'}
                        label={t('notifications:summaryReport')}
                        colon={false}
                        htmlFor={'summaryReport'}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name={'recovery'}
                        label={t('notifications:recoveryNotification')}
                        colon={false}
                        htmlFor={'recovery'}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name={'exception'}
                        label={t('notifications:exceptionNotification')}
                        colon={false}
                        htmlFor={'exception'}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        name={'statusReport'}
                        label={t('notifications:statusReport')}
                        colon={false}
                        htmlFor={'statusReport'}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                    {isPortal && (
                        <Form.Item
                            name={'smartAlerts'}
                            label={t('notifications:smartAlertsHeader')}
                            colon={false}
                            htmlFor={'smartAlerts'}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    )}
                    {!isPortal && (
                        <Form.Item
                            name={'trialAccountsReport'}
                            label={t('notifications:trialAccountsReport')}
                            colon={false}
                            htmlFor={'trialAccountsReport'}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    )}
                </CheckboxRow>
            </StyledWrapper>
            <Footer>
                <Button htmlType="submit" type="primary" disabled={isDisable}>
                    {t('userManagement:actions:save')}
                </Button>
                <Button htmlType="button" type="default" onClick={history.goBack} disabled={submitting}>
                    {t('userManagement:actions:cancel')}
                </Button>
            </Footer>
        </Form>
    )
}
