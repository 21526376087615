import {createSlice} from '@reduxjs/toolkit'

import {post, postLessData} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_ALL_BACKUP_ENTITIES = 'GET_ALL_BACKUP_ENTITIES'

const GET_BACKUP_ENTITIES_API = 'getEntities'

const getBackupEntitiesWrapper = fetchWrapperRT(
    GET_ALL_BACKUP_ENTITIES,
    async data => await postLessData(GET_BACKUP_ENTITIES_API, data)
)

export const getBackupEntities = getBackupEntitiesWrapper.fetcher

const allEntitiesSlice = createSlice({
    name: 'allEntities',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearBackupEntities: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getBackupEntitiesWrapper.makeReducers(builder)
})

export const {clearBackupEntities} = allEntitiesSlice.actions
export const allEntities = allEntitiesSlice.reducer

const START_RESTORE_OR_DOWNLOAD = 'START_RESTORE_OR_DOWNLOAD'
const START_RESTORE_OR_DOWNLOAD_API = 'startRestoreOrDownload'
const startRestoreOrDownloadWrapper = fetchWrapperRT(START_RESTORE_OR_DOWNLOAD, async (data, {rejectWithValue}) => {
    try {
        return await post(START_RESTORE_OR_DOWNLOAD_API, data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})
export const startRestoreOrDownload = startRestoreOrDownloadWrapper.fetcher

// For Selective Recovery
const START_RECOVERY = 'START_RECOVERY'
const START_RECOVERY_API = 'startRecovery'
const startRecoveryWrapper = fetchWrapperRT(START_RECOVERY, async (data, {rejectWithValue}) => {
    try {
        return await post(START_RECOVERY_API, data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})
export const startRecovery = startRecoveryWrapper.fetcher
