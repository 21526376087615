import styled from 'styled-components'
import React from 'react'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

type IconToggleProps = {
    isActive: boolean
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconToggle = styled(({isActive, ...props}) => <Icon {...props} />)<IconToggleProps>`
    color: ${props => (props.isActive ? variables.white : variables.navyBlue)};
    font-size: 16px;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 0.3s ease;
    position: absolute;
    z-index: 3;
    top: 8px;
    left: 8px;
    :last-child {
        left: 40px;
    }
`
export const ToggleWrapper = styled.div`
    position: relative;
`
export const StyledSwitch = styled.div`
    border-radius: 100px;
    position: relative;
    top: 50%;
    width: 64px;
    height: 32px;
    overflow: hidden;
    margin: 0;
`
export const Knobs = styled.div`
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 26px;
        height: 26px;
        color: ${variables.navyBlue};
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        padding: 9px 4px;
        background-color: ${variables.navyBlue};
        border-radius: 50%;
        transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    }
`
export const Layer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${variables.white};
    transition: 0.3s ease all;
    z-index: 1;
    border-radius: 100px;
`
export const StyledInput = styled.input`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
    &:active + div:before {
        width: 58px;
        border-radius: 100px;
    }
    &:checked:active + div:before {
        margin-left: -32px;
    }
    &:checked + div:before {
        content: '';
        left: 35px;
        background-color: ${variables.navyBlue};
    }
    &:checked ~ div {
        background-color: ${variables.white};
    }
`
