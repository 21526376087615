import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import EnabledLock from 'ca-common/icons/EnabledLock.svg'
import DisabledLock from 'ca-common/icons/Lock.svg'

import {StyledWrapper, StyledIcon, StyledText} from './StyledLimitedAccess'

export const LimitedAccess = ({
    isBackground,
    isCheckEnable
}: {
    isBackground: boolean
    isCheckEnable?: boolean
}): JSX.Element => {
    const {t} = useTranslation()

    return (
        <Tooltip title={isBackground ? undefined : t('forms:backupTags:limitedAccessTooltip')}>
            <StyledWrapper isBackground={isBackground} isCheckEnable={isCheckEnable}>
                <StyledIcon isBackground={isBackground} component={isCheckEnable ? DisabledLock : EnabledLock} />
                <StyledText isBackground={isBackground}> {t('forms:backupTags:limitedAccess')} </StyledText>
            </StyledWrapper>
        </Tooltip>
    )
}
