import React from 'react'

import {StyledIcon} from './StyledIcon'
import ArrowRight from 'ca-common/icons/old/uEA0D-arrow-right.svg'

export const CACollapseArrow = ({item, collapse}) => {
    return item.preview || item.collapsible ? (
        <StyledIcon component={ArrowRight} rotate={collapse.isExpanded ? 90 : 0} onClick={collapse.onExpand} />
    ) : null
}
