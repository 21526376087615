import React from 'react'
import {Modal} from 'antd'
import {ModalProps} from 'antd/lib/modal'

type BackupTitleModalProps = ModalProps & {
    children: React.ReactNode
}
export const BackupTitleModal = ({children, ...rest}: BackupTitleModalProps): JSX.Element => {
    const width = 448
    return (
        <Modal {...rest} width={width} closable={false} footer={null} bodyStyle={{padding: 0}}>
            {children}
        </Modal>
    )
}
