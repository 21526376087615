import React from 'react'
import {useTranslation} from 'react-i18next'

import {TotalObjects} from 'ca-common/molecules/TotalObjects'

import {FilterForm} from 'src/newcore/features/Objects/molecules'

import {StyledHeader} from './StyledObjectsTableHeader'

export const ObjectsTableHeader = props => {
    const {totalObjects, changeURLSearchParams, loading, urlValues} = props
    const {t} = useTranslation()

    return (
        <StyledHeader>
            <TotalObjects loading={loading}>{t('objectsList:totalObjects', {totalObjects})}</TotalObjects>
            <FilterForm changeURLSearchParams={changeURLSearchParams} loading={loading} values={urlValues} />
        </StyledHeader>
    )
}
