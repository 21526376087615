import React from 'react'
import {Trans} from 'react-i18next'
import {EMAIL_FORMAT, EMAIL_SETTINGS, SubsourceFormat} from 'src/newcore/features/RecoveryWizard/lib/constants'

export const getEmailSettingsOptions = (full: boolean, format: SubsourceFormat) => {
    return full
        ? [
              {
                  label: <Trans i18nKey={`forms:export:fields:${EMAIL_SETTINGS.includeLegalHold}:label`} />,
                  value: EMAIL_SETTINGS.includeLegalHold
              },
              {
                  label: <Trans i18nKey={`forms:export:fields:${EMAIL_SETTINGS.includeDeleted}:label`} />,
                  value: EMAIL_SETTINGS.includeDeleted
              },
              {
                  label: <Trans i18nKey={`forms:export:fields:${EMAIL_SETTINGS.preferSinglePST}:label`} />,
                  value: EMAIL_SETTINGS.preferSinglePST,
                  disabled: format !== EMAIL_FORMAT.PST
              }
          ]
        : [
              {
                  label: <Trans i18nKey={`forms:export:fields:${EMAIL_SETTINGS.preferSinglePST}:label`} />,
                  value: EMAIL_SETTINGS.preferSinglePST,
                  disabled: format !== EMAIL_FORMAT.PST
              }
          ]
}
