import styled from 'styled-components'
import {transparentize} from 'polished'
import Icon from '@ant-design/icons'
import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledAccountInfoButton = styled(Button)`
    display: flex;
    align-items: center;
    padding: ${variables.gutterXs} 4px;

    width: 170px;
    height: 58px;

    background: ${transparentize(0.8, variables.brandDark)};
    border: none !important;
    border-radius: ${variables.borderRadiusBase};

    font-family: ${variables.publicPageFont};

    &&&:hover,
    &&&:focus {
        background-color: ${transparentize(0.75, variables.brandDark)} !important;
    }

    &&&:active {
        background-color: ${transparentize(0.7, variables.brandDark)} !important;
    }

    && {
        padding: ${variables.gutterXs} 4px !important;
        line-height: 19.6px;
    }
`

export const StyledAccountIcon = styled(Icon)`
    width: 29px;
    height: 29px;
    font-size: 29px;
`
export const StyledAccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
`

export const StyledUserName = styled.span`
    width: 122px;
    color: ${variables.brandDark};
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
`

export const StyledUserEmail = styled(StyledUserName)`
    font-weight: 300;
`

export const TooltipWrapper = styled.div`
    .ant-tooltip {
        bottom: -12px;
        top: inherit !important;
        padding: 0 !important;
        max-width: initial;
    }

    .ant-tooltip-content {
        font-size: ${variables.fontSizeSecondary};
        font-family: ${variables.publicPageFont};
        font-weight: 400;
        white-space: initial;
    }
    a.ant-btn-disabled {
        background: ${transparentize(0.75, variables.brandDark)};
    }
`
