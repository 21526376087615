import {get} from 'src/newcore/utils/rest'
import type {MS365SubSource, TODO_ANY} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum'
import type {TreeElement} from 'src/newcore/organisms/CustomTree'
import {cancellable} from 'src/newcore/features/Recovery/lib'
import {openErrorNotification} from 'src/ca-common/utils/toasts'

const BACKUPED_TREE_API = 'getBackupedTreeAsync'

type TreeRequestType = {
    account: string
    taskId: string | undefined
    backupDate: number
    extid: string
    source: MS365SubSource
}

type FolderResponseObject = {
    id: string
    parentId: string
    name: string
}

export type FoldersData = {
    data: Array<TreeElement>
    size: number
    restored: Record<string, boolean>
}

export type FoldersDataState = {loading: boolean} & FoldersData

export const parseTreeDataState = (
    root: Array<FolderResponseObject>,
    folders: any,
    restoreFoldersIds: Array<string>,
    source: SOURCE
): FoldersData => {
    let treeNodeCount = 0
    const data: Array<TreeElement> = []
    const restoredFoldersIdObject: Record<string, boolean> = {}
    restoreFoldersIds.forEach((folderId: string) => (restoredFoldersIdObject[folderId] = true))

    const sortFolders = (a: TreeElement, b: TreeElement) => {
        const t1 = a.title.toLowerCase()
        const t2 = b.title.toLowerCase()
        return t1 < t2 ? -1 : t1 > t2 ? 1 : 0
    }

    const getChildren = (el: FolderResponseObject) => {
        const restoredFolders: Array<TreeElement> = []
        return folders[el.id]
            .map((el: FolderResponseObject) => getTree(el))
            .filter((el: TreeElement) => {
                if (restoredFoldersIdObject[el.key]) {
                    restoredFolders.push(el)
                    return false
                }
                return true
            })
            .sort(sortFolders)
            .concat(restoredFolders.sort(sortFolders))
    }

    const getTree = (el: FolderResponseObject): TreeElement => {
        treeNodeCount++
        const result: TreeElement = {
            key: el.id,
            title: el.name
        }

        if (source === SOURCE.O365CALENDAR) {
            result.noIcon = true
        }

        if (Object.prototype.hasOwnProperty.call(folders, el.id)) {
            return {
                ...result,
                children: getChildren(el)
            }
        }
        return result
    }

    root.forEach(el => {
        const treeElement = getTree(el)
        data.push(treeElement)
    })

    return {data, size: treeNodeCount, restored: restoredFoldersIdObject}
}

export const fetchTree = async (req: TreeRequestType) => {
    const {source} = req
    let tree
    try {
        tree = await get(BACKUPED_TREE_API, req)
    } catch (e: any) {
        openErrorNotification(e.status)
    }
    const {foldersMap, rootFolders, restoreFolderIds} = tree?.data
    return parseTreeDataState(rootFolders, foldersMap, restoreFolderIds, source)
}

export const cancellableFetchTree = (req: TODO_ANY) => cancellable(fetchTree(req))
