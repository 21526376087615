import {UserInfoDataType} from 'ca-common/types'
import {USER_STATUS} from 'ca-common/constants'

export const hotjar = (hjid: string) =>
    function (h: Window, o: Document, t: string, j: string, a?: Node, r?: HTMLScriptElement) {
        h.hj =
            h.hj ||
            function () {
                // eslint-disable-next-line @typescript-eslint/no-extra-semi,prefer-rest-params
                ;(h.hj.q = h.hj.q || []).push(arguments)
            }
        h._hjSettings = {hjid, hjsv: 6}
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = true
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
    }

const hotjarReload = () => {
    sessionStorage.setItem('isHjReloaded', 'true')
    location.reload()
}

const hotjarCleanKeys = () => {
    sessionStorage.removeItem('isHjReloaded')
}

export const setHotjarId = (hotjarId: string) => {
    sessionStorage.setItem('hotjarId', hotjarId)
}

export const getHotjarId = () => {
    return sessionStorage.getItem('hotjarId')
}

const isBeforeReload = () => {
    return sessionStorage.getItem('isHjReloaded') !== 'true'
}

export const hotjarIdentify = (userInfo: UserInfoDataType) => {
    const trackingAttrs = {
        on_trial: userInfo.status === USER_STATUS.TRIAL
    }
    window.hj('identify', userInfo.id, trackingAttrs)
}

export const hotjarInit = (hotjarId: string, isUserAuditForbidden: boolean) => {
    if (!hotjarId && isUserAuditForbidden && isBeforeReload()) {
        hotjarReload()
        return
    }

    if (hotjarId && !window.hj) {
        hotjar(hotjarId)(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
        hotjarCleanKeys()
    }
}
