import _ from 'lodash'
import React from 'react'
import {Table} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {withPulling} from 'src/newcore/hoc/withPulling'
import {DEFAULT_PAGE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {setCurrentScrollPage} from 'src/newcore/redux/modules/backups/backupEntities/entities'
import {StyledInfiniteTable} from './StyledInfiniteTable'

class InfiniteTableRaw extends React.Component {
    ref = React.createRef()
    lastScrollTop = window.pageYOffset

    constructor(props) {
        super(props)
        this.onScroll = this.onScroll.bind(this)
        // throttle needs to avoid multiple calling of listener which leads to scroll freezing
        this.throttledOnScroll = _.throttle(this.onScroll, 200)
    }

    onScroll(e) {
        const eventTarget = e.target
        const wrapper = eventTarget.documentElement ? eventTarget.documentElement : eventTarget
        const height = wrapper.offsetHeight

        const {entities} = this.props
        const {page, totalPages} = _.get(this.props.data, 'response', {})
        const scrollPage = _.get(entities, 'response.scrollPage', 1)

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop

        // Detect direction of the scroll
        if (scrollTop > this.lastScrollTop) {
            if (page !== totalPages && _.last(this.bottomEntityNodes).getBoundingClientRect().top - height < 0) {
                this.fetchOnScroll(page + 1)
            }

            this.setScrollPage(this.findCurrentPageDown(scrollPage))
        } else {
            this.setScrollPage(this.findCurrentPageUp(scrollPage))
        }

        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    }

    // the getting current page is pretty close to real but not exact
    // and it's ok cause it needs only for data pulling which pulls +-1 page
    findCurrentPageDown = page => {
        const scrollTop = document.documentElement.scrollTop
        const entityNodes = this.bottomEntityNodes

        const {totalPages} = _.get(this.props.data, 'response', {})

        if (
            page !== totalPages &&
            entityNodes[page - 1] &&
            scrollTop + this.ref.current.offsetTop > entityNodes[page - 1].offsetTop
        ) {
            return this.findCurrentPageDown(page + 1)
        }

        return page
    }

    // see findCurrentPageDown comment
    findCurrentPageUp = page => {
        const scrollTop = document.documentElement.scrollTop
        const entityNodes = this.bottomEntityNodes

        if (
            page !== DEFAULT_PAGE &&
            entityNodes[page - 1] &&
            scrollTop < entityNodes[page - 1].offsetTop - document.documentElement.offsetHeight
        ) {
            return this.findCurrentPageUp(page - 1)
        }

        return page
    }

    setScrollPage = scrollPage => {
        const {actions, entities} = this.props

        if (scrollPage !== entities.response.scrollPage) {
            actions.setCurrentScrollPage(scrollPage)
        }
    }

    fetchOnScroll = newPage => {
        const {data, fetchData} = this.props

        if (data.status !== 'pending') {
            fetchData(newPage)
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.throttledOnScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttledOnScroll, false)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.status === 'pending' && this.props.data.status === 'fulfilled') {
            this.bottomEntityNodes = this.ref.current.querySelectorAll('.bottom-entity')
        }
    }

    render() {
        const {data, onTableChange, refreshEntitiesStatus} = this.props

        return (
            <StyledInfiniteTable ref={this.ref}>
                <Table
                    {...this.props}
                    dataSource={_.get(data, 'response.data', [])}
                    loading={'pending' === data.status || 'pending' === refreshEntitiesStatus}
                    pagination={false}
                    onChange={onTableChange}
                />
            </StyledInfiniteTable>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.backups.backupEntities.entities,
    refreshEntitiesStatus: state.backups.backupEntities.refreshEntities.status
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setCurrentScrollPage
        },
        dispatch
    )
})

export const InfiniteTable = withPulling(connect(mapStateToProps, mapDispatchToProps)(InfiniteTableRaw))
