import React from 'react'
import {useTranslation} from 'react-i18next'
import Icon, {PlusCircleOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'
import {WIZARD_TYPE} from 'ca-common/constants'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {navyBlue} from 'ca-common/variables'
import {SOURCE} from 'src/ca-common/common/enum'
import Info from 'ca-common/icons/Info.svg'
import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {openWizardWithConfig} from 'src/newcore/redux/modules/wizard'

import {StyledBulkActivation, StyledBulkActivationTitle} from './StyledBulkActivation'

import {EVENT_NAMES} from 'src/newcore/utils/mixpanel'
import {trackBulkActivation} from 'src/newcore/components/BulkActivation/trackBulkActivation'

type BulkActivationProps = {
    isAuthenticated?: boolean
    source: SOURCE
}

export const BulkActivation = ({isAuthenticated, source}: BulkActivationProps) => {
    const dispatch = useAppDispatch()
    const entityName = getSvcAccountsDescription(source, true).toLowerCase()
    const {t} = useTranslation()
    const openBulkWizardWithConfig = () => {
        dispatch(openWizardWithConfig({type: WIZARD_TYPE.BULK, config: {destroyInactiveTabPane: true}}))
        trackBulkActivation(EVENT_NAMES.RUN_BULK_ACTIVATION, source)
    }

    return (
        <StyledBulkActivation>
            {source == SOURCE.GOOGLEAPPS ? (
                <StyledBulkActivationTitle>
                    <h3>{t(`backups:bulkActivation:Google:title`)}</h3>
                    <Tooltip title={t(`backups:bulkActivation:Google:msg`, {entityName: entityName})}>
                        <Icon component={Info} />
                    </Tooltip>
                </StyledBulkActivationTitle>
            ) : (
                <StyledBulkActivationTitle>
                    <h3>{t(`backups:bulkActivation:title`)}</h3>
                    <Tooltip title={t(`backups:bulkActivation:msg`, {entityName: entityName})}>
                        <Icon component={Info} />
                    </Tooltip>
                </StyledBulkActivationTitle>
            )}

            <Button
                borderWidth={1}
                icon={<PlusCircleOutlined color={navyBlue} />}
                disabled={!isAuthenticated}
                onClick={openBulkWizardWithConfig}
                color={navyBlue}
                size="large"
            >
                {t(`backups:bulkActivation:action`)}
            </Button>
        </StyledBulkActivation>
    )
}
