import {BarGroup} from '@visx/shape/lib/types'

import variables from 'ca-common/variables'

export const ADDED = 'added'
export const DELETED = 'deleted'
export const UPDATED = 'updated'
export const FAILED = 'failed'
export const TOTAL = 'total'
export const DATE = 'snapshot'

export const COLORS = {
    [ADDED]: variables.navyBlue,
    [DELETED]: variables.asphaltLight,
    [UPDATED]: variables.brightBlue
}

export const CATEGORIES = [ADDED, DELETED, UPDATED]

export const N_A = -1
export const failureBarHeight = 2

export interface CustomBarGroup<Key> extends BarGroup<Key> {
    date: string
    total: number
}
