import styled from 'styled-components'

import variables from 'ca-common/variables'
import {BlockTitle} from 'ca-common/ui-lib/atoms/Typography'
import {SourceName as SourceNameRaw} from 'src/newcore/features/Homepage/atoms/TaskView'

export const TitleColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: ${variables.gutterSm};
`
export const IconBorderList = styled.div`
    border: 1px solid ${variables.blueLight};
    border-radius: 4px;
    padding: 4px;
    margin: 0 12px 0 12px;

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin: 0 12px 0 ${variables.gutterSm};
    }
`
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
export const TaskTitle = styled(BlockTitle)`
    margin: 0;
    color: ${variables.navyBlue};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
    text-transform: capitalize;

    @media screen and (min-width: ${variables.screenXxl}) {
        max-width: 200px;
    }
`

export const SourceName = styled(SourceNameRaw)`
    padding: 4px 8px 4px 0;

    @media screen and (min-width: ${variables.screenLaptop}) {
        max-width: 200px;
    }
`
