import {post} from 'src/newcore/utils/rest'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'

const CHANGE_BACKUP_NAME = 'CHANGE_BACKUP_NAME'
const changeBackupNameWrapper = fetchWrapperRT(CHANGE_BACKUP_NAME, async (data, {rejectWithValue}) => {
    try {
        return await post('changeBackupName', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const changeBackupName = changeBackupNameWrapper.fetcher
