import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledFooterSocialLinks = styled.div`
    display: flex;
    gap: ${variables.gutterSm};

    @media (max-width: ${variables.screenXl}) {
        order: 2;
    }

    @media (max-width: ${variables.screenSm}) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`
