import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TableColumnsType} from 'antd'
import _ from 'lodash'

import {
    GetEditPageUrlFnType,
    RemoveCredentialsFnType,
    ResendConfirmationEmailFnType,
    UserCredential
} from 'ca-common/features/UserManagementTable/types'
import {TwoFA, EmailColumnItem} from 'ca-common/features/UserManagementTable/atoms'
import {ActionsCell, ResendConfirmationEmail} from 'ca-common/features/UserManagementTable/molecules'
import {UserInfoDataType} from 'ca-common/types'

const EMAIL_WIDTH = 240
const TYPE_WIDTH = 100
const TWO_FA_WIDTH = 160
const ACTIONS_WIDTH = 100
const RESEND_CONFIRMATION_EMAIL_WIDTH = 300

export type UseColumnsProps = {
    removeCredential: RemoveCredentialsFnType
    getEditPageUrl: GetEditPageUrlFnType
    resendConfirmationEmail: ResendConfirmationEmailFnType
    isZix?: boolean
    userInfo: UserInfoDataType
    isPP?: boolean
}

export const useColumns = (props: UseColumnsProps): TableColumnsType<UserCredential> => {
    const {removeCredential, userInfo, isZix, resendConfirmationEmail, getEditPageUrl, isPP} = props
    const {t} = useTranslation()

    return useMemo(
        () =>
            ([
                {
                    title: t('table:columns:email'),
                    width: EMAIL_WIDTH,
                    render: (_, {email, isNotactive}) => <EmailColumnItem email={email} isNotactive={isNotactive} />
                },
                {
                    title: t('table:columns:type'),
                    width: TYPE_WIDTH,
                    render: (__, {type}) => _.capitalize(type)
                },
                !isZix && {
                    title: t('table:columns:faAuthentication'),
                    width: TWO_FA_WIDTH,
                    render: (_, {twoFactorStatus, twoFaEnabled}) => (
                        <TwoFA twoFactorStatus={twoFactorStatus} twoFaEnabled={twoFaEnabled} isPP={isPP} />
                    )
                },
                {
                    width: ACTIONS_WIDTH,
                    render: item => (
                        <ActionsCell
                            item={item}
                            editPageUrl={getEditPageUrl(item.id)}
                            removeCredential={removeCredential}
                            userInfo={userInfo}
                        />
                    )
                },
                {
                    width: RESEND_CONFIRMATION_EMAIL_WIDTH,
                    render: (_, {isNotactive, email, type}) =>
                        isNotactive && (
                            <ResendConfirmationEmail
                                resend={(password?: string) =>
                                    resendConfirmationEmail({email, credentialType: type, password})
                                }
                                type={type}
                            />
                        )
                }
            ] as TableColumnsType<UserCredential>).filter(Boolean),
        []
    )
}
