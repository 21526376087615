import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {isEmpty} from 'lodash'
import {withTranslation} from 'react-i18next'

import {
    getAccountPropertyValues,
    setAccountPropertyName,
    clearAccountPropertyValues,
    setCheckedAccountPropertyValues
} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'

import {StyledHeader} from './StyledSearchFiled'
import {SearchFieldForm} from './SearchFieldForm'

export const DEFAULT_PAGE = 1
export const ROWS_ACCOUNT_PROPERTY_LIST = 100

class SearchFieldRaw extends Component {
    handleChange = property => {
        const {taskSettings, actions} = this.props

        if (!isEmpty(property)) {
            actions
                .getAccountPropertyValues({
                    taskId: taskSettings.taskId,
                    property
                })
                .then(() => {
                    actions.setAccountPropertyName(property)
                })
        } else {
            actions.clearAccountPropertyValues()
        }
        actions.setCheckedAccountPropertyValues([])
    }

    render() {
        const {t, accountPropertyName} = this.props

        return (
            <Fragment>
                <StyledHeader>{t('backups:bulkActivation:property')}</StyledHeader>
                <SearchFieldForm name={accountPropertyName} handleChange={this.handleChange} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    accountPropertyName: state.backups.bulkActivation.activeDirectory.accountPropertyName
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAccountPropertyValues,
            setAccountPropertyName,
            clearAccountPropertyValues,
            setCheckedAccountPropertyValues
        },
        dispatch
    )
})

export const SearchField = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchFieldRaw))
