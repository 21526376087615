import {createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {MS365SubSource, TODO_ANY} from 'ca-common/types'
import {Status} from 'src/newcore/features/Recovery/lib/types'

const GET_SUBSOURCES_INFO = 'GET_SUBSOURCES_INFO'
const SUBSOURCES_INFO_API = 'getTotalBySources'
const getSubsourcesInfoWrapper = fetchWrapperRT<TODO_ANY>(GET_SUBSOURCES_INFO, async (data, {rejectWithValue}) => {
    try {
        return await get(SUBSOURCES_INFO_API, data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getSubsourcesInfo = getSubsourcesInfoWrapper.fetcher

export type SubsourcesInfo = {
    folders: number
    items: number
    source: MS365SubSource
    status: Status
}

export type SubsourcesInfoResponse = {
    data: SubsourcesInfo[]
    success: boolean
}

const subsourcesInfoSlice = createSlice({
    name: 'subsourcesInfo',
    initialState: InitFetchedState as FetchedState<SubsourcesInfoResponse>,
    reducers: {
        clearSubsourcesInfo: () => InitFetchedState as FetchedState<SubsourcesInfoResponse>
    },
    extraReducers: builder => getSubsourcesInfoWrapper.makeReducers(builder)
})

export const {clearSubsourcesInfo} = subsourcesInfoSlice.actions
export const subsourcesInfo = subsourcesInfoSlice.reducer
