import React from 'react'
import {Tooltip} from 'antd'
import {siteFormatter} from 'ca-common/utils/entity'
import {HotjarHidden} from 'ca-common/atoms/HotJarHidden'

type SiteProps = {
    site: string
}

export const Site = ({site}: SiteProps): JSX.Element => (
    <Tooltip placement="topLeft" title={<HotjarHidden>{site}</HotjarHidden>} mouseEnterDelay={0.4}>
        {site && <span>{siteFormatter(site)}</span>}
    </Tooltip>
)
