import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {SOURCE, SOURCE_INFO} from 'ca-common/common/enum'
import {PAGES, WIZARD_TYPE} from 'ca-common/constants'
import RecoveryIcon from 'ca-common/icons/backupActionsNew/Recovery.svg'
import {TODO_ANY} from 'ca-common/types'

import {
    ActionIcon,
    BackupActionButton,
    DisabledButtonWrapper
} from 'src/newcore/features/Homepage/organisms/BackupActions/StyledBackupActions'

import {ACTIONS, LAST_BACKUP_STATUS} from 'src/newcore/features/Homepage/utils/constants'
import {TaskObj} from 'src/newcore/features/Homepage/types'
import {configureUrlData, getUrlToTaskOnOldApp} from 'src/newcore/features/Homepage/utils/utils'
import {changeData, openWizardWithConfig, setWizardDescription, setWizardTitle} from 'src/newcore/redux/modules/wizard'
import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

type RecoveryProps = {
    disabled: boolean
    task: TaskObj
}

export const Recovery = ({disabled, task}: RecoveryProps): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector(userInfoSelector)
    const isMsEndUser = userInfo?.isMsEndUser

    const linkToRecovery = (task: TaskObj): string => {
        if (SOURCE_INFO[task.source].hasSubAccounts) {
            if (task.subActiveAccountsCount > 0) {
                return getUrlToTaskOnOldApp(PAGES.RESTORE_ITEMS, configureUrlData(task))
            }
        } else if (task.lastBackup !== LAST_BACKUP_STATUS.NEVER) {
            return getUrlToTaskOnOldApp(PAGES.RESTORE_SEARCH, configureUrlData(task))
        }
        return ''
    }
    const sendAnalytics = () => {
        track('Advanced Recovery Wizard Opened', {
            Service: getServiceNameMixpanel(task.source),
            'Initiated From': 'Homepage Recovery Button'
        })
    }
    const openRecoveryWizard = (e: any) => {
        e.stopPropagation()
        sendAnalytics()
        dispatch(
            changeData({
                taskId: task.id,
                from: isMsEndUser ? PAGES.HOMEPAGE_ENDUSER : PAGES.HOMEPAGE,
                source: task.source
            })
        )
        dispatch(
            openWizardWithConfig({
                type: WIZARD_TYPE.RECOVERY,
                config: {
                    destroyInactiveTabPane: false,
                    autoDismissProgress: true
                }
            })
        )
    }

    return (
        <Tooltip title={disabled && t('backups:actions:tooltips:noBackup')}>
            <DisabledButtonWrapper disabled={disabled}>
                {task.source === SOURCE.MS365 ? (
                    <BackupActionButton onClick={e => openRecoveryWizard(e)} disabled={disabled} isRecovery>
                        <ActionIcon component={RecoveryIcon} />
                        {t(`backups:actions:${ACTIONS.RECOVERY}`)}
                    </BackupActionButton>
                ) : (
                    <BackupActionButton type="link" href={linkToRecovery(task)} disabled={disabled} isRecovery>
                        <ActionIcon component={RecoveryIcon} />
                        {t(`backups:actions:${ACTIONS.RECOVERY}`)}
                    </BackupActionButton>
                )}
            </DisabledButtonWrapper>
        </Tooltip>
    )
}
