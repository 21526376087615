import React from 'react'

import {TaskObj} from 'src/newcore/features/Homepage/types'
import {EndUserCard} from 'src/newcore/features/Homepage/molecules/EndUserCard'

import {StyledEndUserCards} from './StyledEndUserCards'

type EndUserCardsProps = {
    tasks: TaskObj[]
    clickEndUserCard: (id?: string, recoveryEntityId?: string) => any
}

export const EndUserCards = (props: EndUserCardsProps) => {
    const {tasks, clickEndUserCard} = props

    return (
        <StyledEndUserCards>
            {tasks.map(task => (
                <EndUserCard task={task} clickEndUserCard={clickEndUserCard} />
            ))}
        </StyledEndUserCards>
    )
}
