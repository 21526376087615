import React from 'react'

import {createIpRules, removeIpRules, updateIpRules, activateIpRules, getIpRules, selectIpRules} from './api'

import {IpRestriction} from 'ca-common/features/IpRestriction'

export const IpRestrictionPortal = (): JSX.Element => {
    return (
        <IpRestriction
            getIpRules={getIpRules}
            selectIpRules={selectIpRules}
            createIpRules={createIpRules}
            removeIpRules={removeIpRules}
            updateIpRules={updateIpRules}
            activateIpRules={activateIpRules}
        />
    )
}
