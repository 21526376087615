import React from 'react'

import {StyledStatus, StyledIcon} from './StyledStatus'

import {getActivityStatusIcon, getActivityStatusFriendly} from 'ca-common/common/enum/Statuses'

import {StatusTooltip} from 'src/newcore/features/Objects/atoms'

export const Status = props => {
    const {status, failureReason} = props

    return (
        <StyledStatus>
            <StyledIcon component={getActivityStatusIcon(status)} />
            <StatusTooltip tooltip={failureReason}>{getActivityStatusFriendly(status)}</StatusTooltip>
        </StyledStatus>
    )
}
