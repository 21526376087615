import styled, {css} from 'styled-components'

import variables from 'ca-common/variables'

export const StyledTableTitle = styled.div<{isDanger?: boolean}>`
    ${({isDanger}) =>
        isDanger &&
        css`
            color: ${variables.red};
        `}
`
