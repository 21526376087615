import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Title = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${variables.gutterXs};

    & > div {
        margin-bottom: 0;
    }
`

export const ReportFormTitle = styled.span`
    margin-right: ${variables.gutterMd};
`

export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    color: ${variables.reportHeaderColor};
    padding: initial;
`

export const PreviewLink = styled.div`
    a {
        font-family: ${variables.mainFont};
        font-weight: 600;
        color: ${variables.navyBlue};
        text-decoration: underline;
    }
    margin-bottom: ${variables.fontSizeSecondary};
`
export const PreviewWrapper = styled.div`
    padding-left: 221px;
    margin-top: -87px;
    margin-bottom: ${variables.mainTitleFontSize};
    position: absolute;
`
export const EmailReport = styled.div`
    > * {
        margin-bottom: ${variables.fontSizeSecondary};
    }
    padding-left: 10px;
    margin-right: ${variables.headingFontSize};
    margin-bottom: ${variables.fontSizeSecondary};
    width: 150px;
    flex: none;
`
export const FailureOnlyReportCheckBoxWrapper = styled.div`
    padding-left: 10px;
`
export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    padding-top: ${variables.fontSizeSecondary};
    border-top: 1px solid ${variables.blue}; ;
`
export const FieldsWrapper = styled.div`
    .ant-form-item-label {
        flex: none;
        font-weight: 600;
        flex: none;
        padding-left: 9px;
        margin-top: -5px;
    }
    .ant-row {
        align-items: center;
        width: 100%;
    }
    .ant-form-item-row {
        display: block;
    }
    .ant-radio-wrapper-in-form-item {
        display: block;
        padding-left: ${variables.gutterXs};
        font-size: ${variables.fontSizeBase};
        color: ${variables.dark};
        font-weight: normal;
        margin-bottom: ${variables.gutterXs};
    }
    .anticon {
        margin-left: ${variables.gutterXs};
    }
`
export const Row = styled.div`
    display: flex;
    margin-right: -15px;
    margin-left: -15px;

    @media screen and (max-width: ${variables.screenMd}) {
        flex-flow: column;
    }
`

export const StyledSubLine = styled.div`
    width: 0.75px;
    height: 24.546px;
    background: ${variables.asphaltLight};
`
