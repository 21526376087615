import React, {memo} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {getSourceName} from 'ca-common/utils/sources'
import type {MS365SubSource} from 'ca-common/types'
import {StyledCardBody, StyledColumn, StyledTitle, StyledCount, TooltipStyleWrapper} from './StyledSubsourceCard'

type SubsourceCardProps = {
    icon: JSX.Element
    count: number
    controls: JSX.Element
    selected: boolean
    source: MS365SubSource
    select: (subsource: MS365SubSource) => void
}

export const SubsourceCard = memo(
    ({icon, count, controls, selected, select, source}: SubsourceCardProps): JSX.Element => {
        const title = getSourceName(source)
        const disabled = count < 1
        const {t} = useTranslation()

        return (
            <TooltipStyleWrapper key={source}>
                <Tooltip
                    title={disabled && t('recovery:MS365:zeroItems', {title: title.toLowerCase()})}
                    getPopupContainer={triggerNode => triggerNode}
                    placement="bottom"
                    mouseEnterDelay={0.4}
                >
                    <StyledCardBody disabled={disabled} selected={selected} onClick={() => select(source)}>
                        <StyledColumn>{icon}</StyledColumn>
                        <StyledColumn>
                            <StyledCount>{count}</StyledCount>
                            <StyledTitle>{title}</StyledTitle>
                        </StyledColumn>
                        <StyledColumn>{controls}</StyledColumn>
                    </StyledCardBody>
                </Tooltip>
            </TooltipStyleWrapper>
        )
    }
)
