import isEmpty from 'lodash/isEmpty'
import {Spin, Modal} from 'antd'
import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'

import {hasEntities} from 'ca-common/utils/sources'
import {makeFormFieldRequest} from 'ca-common/utils/form'

import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {PAGES} from 'ca-common/constants'
import {SOURCE, SOURCE_INFO} from 'ca-common/common/enum/Source'
import {Note} from 'ca-common/atoms/Note'
import Info from 'ca-common/icons/Info.svg'

import {BackupAuth} from 'src/newcore/features/BackupAuth'
import {CABackupEntities} from 'src/newcore/components'
import {SFCTabs} from 'src/newcore/components/SFCTabs'
import {CABackupSettings} from 'src/newcore/features/BackupSettings'
import {appendLocalBackupTaskSettings, getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'
import {updateBackupAuth} from 'src/newcore/redux/modules/backups/backupAuth'
import {getBulkActivationRules} from 'src/newcore/redux/modules/backups/bulkActivate'
import {ScrollToTopOnMount} from 'src/newcore/features/ScrollToTopOnMount'
import {clearBulkActivationRules} from 'src/newcore/redux/modules/backups/bulkActivation/Rules'
import {sourcesWithNoActivatedEntitiesNote} from 'src/newcore/features/Homepage/utils'
import {NoteWrapper} from './StyledBackupInfo'
import {callReload} from 'src/newcore/utils/logout'

export class CABackupInfoRaw extends React.Component {
    state = {
        backupAuthVisible: false
    }

    toggleVisible = () => {
        const {t} = this.props
        const {source} = this.props.taskSettings
        const showReAuthModal = () =>
            Modal.warning({
                title: t('backups:modal:GoogleReAuth')
            })

        if ([SOURCE.GOOGLEAPPS, SOURCE.GDRIVETEAM].includes(source)) {
            showReAuthModal()
        } else {
            this.setState({
                backupAuthVisible: !this.state.backupAuthVisible
            })
        }
    }

    handleEditBackupAuth = values => {
        return new Promise((res, rej) => {
            const {
                t,
                actions,
                match,
                taskSettings: {source}
            } = this.props
            const taskId = match.params.taskId

            const fields = makeFormFieldRequest({...values, taskId})

            actions
                .updateBackupAuth({
                    source,
                    fields
                })
                .unwrap()
                .then(response => {
                    if (response.needRedirect) {
                        window.location = response.redirectUrl
                    } else {
                        actions.appendLocalBackupTaskSettings(response)
                        this.toggleVisible()
                        openSuccessNotification(t('backups:changeSettings'))
                        res(response)
                    }
                })
                .catch(error => {
                    if (error.status) {
                        openErrorModal(error.status)
                    }
                    rej(error)
                })
        })
    }

    componentDidMount() {
        const {match, actions, history, userInfo} = this.props
        if (userInfo?.response?.isMsEndUser) {
            callReload('', '?msgType=custom&modalName=AccessDenied')
        }
        actions
            .getNewBackupTaskSettings({
                taskId: match.params.taskId
            })
            .then(response => {
                const {oauth, source} = response.payload
                if (SOURCE_INFO[source].hasBulkActivation && oauth) {
                    actions.getBulkActivationRules({
                        taskId: match.params.taskId
                    })
                }
            })
            .catch(error => {
                if (error.status) {
                    openErrorModal(error.status, undefined, () => history.push(`/${PAGES.BACKUPS}`))
                } else {
                    history.push(`/${PAGES.BACKUPS}`)
                }
            })
    }

    componentWillUnmount() {
        const {actions} = this.props
        actions.clearBulkActivationRules()
    }

    componentDidUpdate(prevProps) {
        const {match, actions, history} = this.props
        const taskId = match.params.taskId
        const prevTaskId = prevProps.match.params.taskId
        const isNew = queryString.parse(history.location.search).isNew
        const prevIsNew = queryString.parse(prevProps.location.search).isNew

        if (taskId !== prevTaskId || isNew !== prevIsNew) {
            actions.getNewBackupTaskSettings({
                taskId: match.params.taskId
            })
        }
    }

    render() {
        const {taskSettingsStatus, taskSettings, companyName, isCloudally, t, activeEntities} = this.props
        const {backupAuthVisible} = this.state

        if (isEmpty(taskSettings)) return null

        const showNote = sourcesWithNoActivatedEntitiesNote.includes(taskSettings.source) && activeEntities === 0

        return (
            <Spin spinning={'pending' === taskSettingsStatus}>
                {showNote && (
                    <NoteWrapper>
                        <Note
                            type="warning"
                            i18nKey="backups:noActiveBackups"
                            icon={Info}
                            values={{entityName: t(`homepage:rowName:entityNames:${taskSettings.source}`)}}
                        />
                    </NoteWrapper>
                )}
                <CABackupSettings onBackupAuthClick={this.toggleVisible} />
                {hasEntities(taskSettings.source) && (
                    <CABackupEntities source={taskSettings.source} taskId={taskSettings.taskId} />
                )}
                {taskSettings.source === SOURCE.SFC && (
                    <Fragment>
                        <ScrollToTopOnMount />
                        <SFCTabs />
                    </Fragment>
                )}
                <BackupAuth
                    isCloudally={isCloudally}
                    companyName={companyName}
                    backupSettings={taskSettings}
                    source={taskSettings.source}
                    visible={backupAuthVisible}
                    onSubmit={this.handleEditBackupAuth}
                    onCancel={this.toggleVisible}
                />
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    taskSettingsStatus: state.backups.settings.status,
    taskSettings: state.backups.settings.response || {},
    companyName: state.companyInfo.response?.name,
    isCloudally: state.companyInfo.response.isCloudally,
    activeEntities: state.backups.backupEntities.entities.response?.activeEntities,
    userInfo: state.userInfo
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getNewBackupTaskSettings,
            updateBackupAuth,
            appendLocalBackupTaskSettings,
            getBulkActivationRules,
            clearBulkActivationRules
        },
        dispatch
    )
})

export const CABackupInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CABackupInfoRaw)))
