import styled, {keyframes} from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

const popUp = keyframes` 
    0% {
        transform: translateX(400px);
        opacity: 0;
    } 
    100% {
        transform: translateX(0);
        opacity: 1
    }
`

export const StyledBackupAuthForm = styled(Form)``

export const BackupAuthBody = styled.div`
    border-top: 1px solid ${variables.blue};
    border-bottom: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};

    .ant-row,
    .ant-form-item {
        display: block;
    }

    .ant-form-item-label,
    .ant-form-item-control-input {
        min-height: 40px;
        line-height: 40px;
    }
    overflow: hidden;
`

export const StyledLink = styled.a`
    text-decoration: underline;
    color: ${variables.navyBlue};
    font-weight: 600;
`

export const FlexDiv = styled.div`
    display: flex;
`

export const StyledAuthList = styled.ul`
    margin-left: ${variables.gutterSm};
`

export const StyledFailureBody = styled.div`
    animation: ${popUp} 200ms ease-out;
`

export const StyledBackButton = styled(Button)`
    margin-left: auto;
    margin-right: ${variables.gutterSm};
`
