import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useInterval} from 'ca-common/utils/useInterval'

type Config = {
    pull: () => void
    short: moment.Duration
    long: moment.Duration
    stop: moment.Duration
    resetDelayProp?: number
}

type WithPullingProps = {
    pullConfig: Config
}

export function withPulling<T>(WrappedComponent: React.ComponentType<T & WithPullingProps>) {
    return (props: T & WithPullingProps): JSX.Element => {
        const {pullConfig} = props

        const [currentDateTime, setCurrentDateTime] = useState<moment.Moment>(moment())

        const [delay, setDelay] = useState<moment.Duration | null>(pullConfig.short)
        useInterval(
            () => {
                pullConfig.pull()
                setDelay(getDelay())
            },
            delay ? delay.asMilliseconds() : null
        )
        useEffect(() => {
            setDelay(pullConfig.short)
            setCurrentDateTime(moment())
        }, [pullConfig.resetDelayProp])

        const getDelay = () => {
            const dateDiff = moment().diff(currentDateTime)

            if (dateDiff >= pullConfig.long.asMilliseconds() && dateDiff <= pullConfig.stop.asMilliseconds()) {
                return pullConfig.long
            }

            if (dateDiff >= pullConfig.stop.asMilliseconds()) {
                return null
            }

            return delay
        }
        return <WrappedComponent {...props} />
    }
}

export const usePulling = (config: Config): null => {
    const [currentDateTime, setCurrentDateTime] = useState<moment.Moment>(moment())

    const [delay, setDelay] = useState<moment.Duration | null>(config.short)

    const getDelay = () => {
        const dateDiff = moment().diff(currentDateTime)

        if (dateDiff >= config.long.asMilliseconds() && dateDiff <= config.stop.asMilliseconds()) {
            return config.long
        }

        if (dateDiff >= config.stop.asMilliseconds()) {
            return null
        }

        return delay
    }

    useInterval(
        () => {
            config.pull()
            setDelay(getDelay())
        },
        delay ? delay.asMilliseconds() : null
    )

    useEffect(() => {
        setDelay(config.short)
        setCurrentDateTime(moment())
    }, [config.resetDelayProp])

    return null
}
