import styled from 'styled-components'
import variables from 'ca-common/variables'

export const LegendWrapper = styled.div`
    .legend {
        line-height: 0.9em;
        color: #efefef;
        font-size: 10px;
        font-family: ${variables.mainFont};
        padding: 10px 10px;
        float: left;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        margin: 5px 5px;
    }

    .title {
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 100;
    }
`

export const LegendLabelsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
