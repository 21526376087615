import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledContent = styled.div`
    border-top: 1px solid ${variables.blue};
`

export const StyledBody = styled.div`
    padding: ${variables.gutterLg} ${variables.gutterMd} 77px;
    width: 708px;
    display: flex;
    flex-direction: column;
    gap: 31px;
    font-family: ${variables.publicPageFont};

    @media screen and (max-width: ${variables.screenMd}) {
        width: 100%;
    }
`

export const StyledSubtitle = styled.span`
    font-size: ${variables.subTitleFontSize};
    font-weight: 300;
`

export const StyledEmail = styled.span`
    color: ${variables.navyBlue};
    text-decoration: underline;
`

export const StyledRecentlyDeletedTaskSources = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterMd};
    width: 100%;
    min-height: 296px;
`
