import styled from 'styled-components'

import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledTextWithInfo = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
`

export const StyledIcon = styled(Icon)`
    font-size: 14px;
    color: ${variables.old.duskyBlue};
`
