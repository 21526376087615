import React from 'react'
import {useTranslation} from 'react-i18next'
import accountDeleted from 'ca-common/images/accountDeleted.png'
import {StyledImageWrapper, StyledParagraph, StyledTitle, ModalContent} from './StyledCustomNotification'

export const AccountDeleted = (): JSX.Element => {
    const {t} = useTranslation()
    return (
        <ModalContent>
            <StyledImageWrapper>
                <img height={204} width={260} src={accountDeleted} alt={t('modals:accountDeleted:title')} />
            </StyledImageWrapper>
            <StyledTitle>{t('modals:accountDeleted:title')}</StyledTitle>
            <StyledParagraph padding="0 80px">{t('modals:accountDeleted:firstParagraph')}</StyledParagraph>
            <StyledParagraph>{t('modals:accountDeleted:secondParagraph')}</StyledParagraph>
        </ModalContent>
    )
}
