import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    color: ${variables.old.grayDark};
    font-size: 18px;
    padding: 0 10px;
    position: relative;
    transition: all 300ms ease;
`

export const StyledLink = styled(CALink)`
    text-decoration: none;

    &:hover {
        text-decoration: underline;

        ${StyledIcon} {
            color: ${variables.old.blueLight};
        }
    }
`
