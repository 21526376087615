import React from 'react'
import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'
import {Menu, Button, Dropdown} from 'antd'
import {transparentize} from 'polished'

import {
    navyBlue,
    white,
    black,
    fontSizeBase,
    interaction,
    borderRadiusBase,
    gutterXs,
    mainFont
} from 'ca-common/variables'

import {SELECTION_MENU_ACTION_NAME} from 'src/newcore/features/Recovery/lib/constants'

export const StyledMenu = styled(Menu)`
    padding: 0;
    border-radius: ${borderRadiusBase};
    overflow: hidden;
`

type StyledMenuProps = {
    selectionActionName: SELECTION_MENU_ACTION_NAME
    disabled: boolean
    key: string
    onClick: any
    children: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledMenuItem = styled(({selectionActionName, ...props}: StyledMenuProps) => <Menu.Item {...props} />)`
    margin: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    ${p =>
        !p.disabled &&
        css`
            background-color: ${p.selectionActionName === SELECTION_MENU_ACTION_NAME.SELECT_ALL ? navyBlue : white};
            color: ${p.selectionActionName === SELECTION_MENU_ACTION_NAME.SELECT_ALL ? white : navyBlue};

            &:hover {
                background-color: ${p.selectionActionName === SELECTION_MENU_ACTION_NAME.SELECT_ALL ? navyBlue : white};
                color: ${p.selectionActionName === SELECTION_MENU_ACTION_NAME.SELECT_ALL ? white : navyBlue};
                text-decoration: underline;
            }

            svg {
                stroke: ${p.selectionActionName === SELECTION_MENU_ACTION_NAME.SELECT_ALL ? white : navyBlue};
            }
        `}
    ${p =>
        p.disabled &&
        css`
            background-color: ${transparentize(0.95, black)};

            &:hover {
                background-color: ${transparentize(0.95, black)};
            }

            svg {
                stroke: ${transparentize(0.75, black)};
            }
        `}
`

export const StyledIcon = styled(Icon)`
    margin-right: ${gutterXs};
    font-size: 24px !important;

    svg {
        fill: transparent;
    }
`
export const StyledText = styled.span`
    font-family: ${mainFont};
    font-weight: 400;
    font-style: normal;
    font-size: ${fontSizeBase};
`

export const StyledKebabIcon = styled(Icon)`
    color: ${navyBlue};
    vertical-align: top;
`

export const StyledKebabButton = styled(Button)`
    border: none;
    width: 28px;
    height: 28px;
    margin: -12px;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background: ${interaction};
    }
`

export const StyledDropDown = styled(Dropdown)<{disabled: boolean}>`
    background-color: transparent !important;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
