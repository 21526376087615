import React from 'react'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'

import {
    BackupFrequency,
    BackupHour,
    IndexStatus,
    RetentionPeriod,
    StorageRegion
} from 'src/newcore/features/BackupSettings/molecules'
import {SingleEntitySettings} from 'src/newcore/features/BackupSettings/organisms'

import {BackupSettingsDivider, SettingsInfo} from '../StyledSettings'

export const IMAP = props => {
    const {t} = useTranslation()
    const {taskSettings, isHighFrequencyBackupEnabled, isMultiFrequencyBlocked, isSpecialPartnerAccount} = props

    return (
        <SingleEntitySettings {...props}>
            <Row gutter={16}>
                <Col lg={14}>
                    <SettingsInfo>
                        <h2>{t('forms:backupSettings:title')}</h2>
                        <p>{t('forms:backupSettings:text:noticeContent')}</p>
                    </SettingsInfo>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col lg={18} xl={14} xxl={11}>
                    <IndexStatus indexStatus={taskSettings.indexStatus} />
                </Col>

                <Col span={1}>
                    <BackupSettingsDivider />
                </Col>

                <Col lg={7} xl={9} xxl={12}>
                    <Row gutter={16}>
                        <Col lg={24} xl={12}>
                            <StorageRegion storageRegion={taskSettings.storageRegion} />
                        </Col>
                        <Col lg={24} xl={12}>
                            <RetentionPeriod
                                retentionType={taskSettings.retentionType}
                                retentionPeriod={taskSettings.retentionPeriod}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupFrequency
                                backupFrequency={taskSettings.backupFrequency}
                                isHighFrequencyBackupEnabled={isHighFrequencyBackupEnabled}
                                isHighFrequencyBackupBlocked={
                                    taskSettings.isHighFrequencyBackupBlocked || isMultiFrequencyBlocked
                                }
                                isSpecialPartnerAccount={isSpecialPartnerAccount}
                                isSurchargeNotificationDisplayed={taskSettings.isSurchargeNotificationDisplayed}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupHour
                                backupHour={taskSettings.backupHour}
                                backupFrequency={taskSettings.backupFrequency}
                                isDistributedBackupHour={taskSettings.isDistributedBackupHour}
                                distributedBackupHourValue={taskSettings.distributedBackupHourValue}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </SingleEntitySettings>
    )
}
