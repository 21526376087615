import React, {useEffect} from 'react'
import {useLocation} from 'react-router'

import {SOURCE} from 'ca-common/common/enum'
import {WIZARD_TYPE} from 'ca-common/constants'
import {getUrlParams} from 'ca-common/utils/url'

import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'
import {useOpenServicesSelectionModal} from 'src/newcore/features/OneClickActivation/lib'
import {OCAButton} from 'src/newcore/features/OneClickActivation/molecules'

type OCAButtonWithModalProps = {
    availableServices: SOURCE[]
}

export const OCAButtonWithModal = ({availableServices}: OCAButtonWithModalProps) => {
    const location = useLocation()
    const {modalType, enabledServices, error} = getUrlParams(location.search.slice(1)) as {
        modalType?: WIZARD_TYPE
        enabledServices?: SOURCE[]
        error?: string
    }

    const openServicesSelectionModal = useOpenServicesSelectionModal()

    const onClick = () => {
        openServicesSelectionModal({enabledServices, availableServices, error})
        track(EVENT_NAMES.OCA_WIZARD_OPENED, {})
    }

    useEffect(() => {
        if (modalType === WIZARD_TYPE.MS_BACKUP) {
            openServicesSelectionModal({enabledServices, availableServices, error})
        }
    }, [modalType, enabledServices, error, availableServices])

    return (
        <div>
            <OCAButton onClick={onClick} />
        </div>
    )
}
