import React from 'react'
import PropTypes from 'prop-types'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {withTranslation} from 'react-i18next'
import {convertUnixToDate, STANDARD_DATE_FORMAT} from 'ca-common/utils/datetime'

const dumbIssueDate = dumbBem('ca-issue-date')
const IssueDate = tx(dumbIssueDate)('div')

@withTranslation('table')
export class CAIssueDate extends React.Component {
    static propTypes = {
        item: PropTypes.shape({
            date: PropTypes.number
        }).isRequired
    }

    render() {
        const {item, t} = this.props
        return (
            <IssueDate>
                {item.issueDate ? convertUnixToDate(item.issueDate, STANDARD_DATE_FORMAT) : t('table:date:noDate')}
            </IssueDate>
        )
    }
}
