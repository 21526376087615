import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {PAGES} from 'ca-common/constants'
import NewBackup from 'ca-common/icons/old/uEA30-new-backup.svg'
import {NotificationsTable, PAGE_SIZE} from 'ca-common/features/NotificationsTable'

import {usersNotifications, deleteUsersNotification} from 'src/newcore/redux/modules/notifications'
import {AppState, useAppSelector, useAppDispatch} from 'src/newcore/components/Root'
import {
    StyledIcon,
    StyledLink,
    StyledTableActions,
    StyledTableActionTitle,
    StyledTableActionNew
} from './StyledNotifications'

export const TableContainer = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const status = useAppSelector((state: AppState) => state.notifications.notificationList.status)
    const tableData = useAppSelector((state: AppState) => state.notifications.notificationList.response)
    useEffect(() => {
        dispatch(
            usersNotifications({
                page: 1,
                limit: PAGE_SIZE
            })
        )
    }, [])

    return (
        <div>
            <StyledTableActions>
                <StyledTableActionTitle>{t('notifications:tableHeader')}</StyledTableActionTitle>
                <StyledLink to={`${PAGES.NOTIFICATIONS}/new`}>
                    <StyledIcon component={NewBackup} />
                    <StyledTableActionNew>{t('notifications:addNew')}</StyledTableActionNew>
                </StyledLink>
            </StyledTableActions>
            <NotificationsTable
                data={tableData?.data}
                page={tableData?.page}
                status={status}
                fetchItemsByPage={page => dispatch(usersNotifications({page, limit: PAGE_SIZE}))}
                total={tableData?.total}
                deleteUsersNotification={email => dispatch(deleteUsersNotification({email}))}
            />
        </div>
    )
}
