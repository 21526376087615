import React from 'react'
import map from 'lodash/map'

import {Tile} from '../../organisms/Tile'
import {StyledTileView} from './StyledTileVIew'
import {TaskObj} from 'src/newcore/features/Homepage/types'

type TileViewProps = {
    tasks: TaskObj[]
}

export const TileView = ({tasks}: TileViewProps): JSX.Element => {
    return (
        <StyledTileView>
            {map(
                tasks,
                (task: TaskObj): JSX.Element => (
                    <Tile key={task.id} task={task} />
                )
            )}
        </StyledTileView>
    )
}
