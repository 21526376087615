import React, {Fragment} from 'react'
import {Row} from 'antd'
import {Trans} from 'react-i18next'

import EmailSent from 'ca-common/icons/EmailSent.svg'

import {StyledText, StyledIcon} from './StyledSuccessSubmit'

export const SuccessSubmit = () => {
    return (
        <Fragment>
            <Row type="flex" justify="center">
                <StyledIcon component={EmailSent} />
            </Row>
            <StyledText>
                <Trans i18nKey="passwordResetNew:thank">
                    <strong>in 0 text wrapper</strong>
                </Trans>
            </StyledText>
        </Fragment>
    )
}
