import React, {useEffect} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {Select} from 'antd'

import {changeData} from 'src/newcore/redux/modules/wizard'
import {getSFCAlertsObjects, clearSFCAlertObjects} from '../../redux'
import {StyledLabel, StyledSelect} from '../../atoms'

const {Option} = Select

const SpecificObjectSelectionRaw = ({actions, objects, checked, specificObject, setSpecificObject}) => {
    const {t} = useTranslation()
    const {taskId} = useParams()
    const specificObjects = objects.data ? [...objects.data].sort() : null

    useEffect(() => {
        actions.getSFCAlertsObjects({taskId})
        actions.changeData({taskId})

        return () => actions.clearSFCAlertObjects()
    }, [])

    return (
        <>
            <StyledLabel>{t('smartAlerts:wizard:chooseSpecificObject')}</StyledLabel>
            <StyledSelect
                disabled={!checked}
                loading={objects.status !== 'fulfilled'}
                style={{width: 290}}
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                onSelect={setSpecificObject}
                value={specificObject}
                placeholder={t('smartAlerts:wizard:specificObjectPlaceholder')}
                getPopupContainer={t => t.parentElement}
            >
                {specificObjects &&
                    specificObjects.map(value => (
                        <Option value={value} key={value}>
                            {value}
                        </Option>
                    ))}
            </StyledSelect>
        </>
    )
}

const mapStateToProps = state => ({
    objects: {
        status: state.backups.sfcSmartAlerts.sfcAlertObjects.status,
        data: state.backups.sfcSmartAlerts.sfcAlertObjects.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeData,
            getSFCAlertsObjects,
            clearSFCAlertObjects
        },
        dispatch
    )
})

export const SpecificObjectSelection = connect(mapStateToProps, mapDispatchToProps)(SpecificObjectSelectionRaw)
