import React, {useMemo} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum/Source'
import type {CompanyInfoType, UserInfoDataType} from 'ca-common/types'

import {WizardModal} from 'src/newcore/components/Wizard'
import {NEW_HOME_PAGE} from 'src/newcore/features/NewHomePage/constants'
import {StyledColSpace, StyledHomeDivider, StyledSubHeading} from 'src/newcore/features/NewHomePage/StyledNewHomepage'
import {isBackupPermitted, permittedWhiteList} from 'src/newcore/utils/permissions'
import {StartUsingTutorials, StyledHowTo, StyledLine} from 'src/newcore/features/StartUsing/StyledStartUsing'
import {TutorialButton} from 'src/newcore/features/StartUsing/molecules/TutorialButton/TutorialButton'
import {HomeContent} from './NewHomeContents/HomeContent'

const NewHomepage = (props: {userInfo: UserInfoDataType; companyInfo: CompanyInfoType; isBackup: boolean}) => {
    const {t} = useTranslation()
    const {userInfo, companyInfo, isBackup} = props
    const permittedWhitelist = permittedWhiteList(userInfo?.permissions)

    const MicrosoftServices = [SOURCE.SHAREPOINT_MULTI, SOURCE.MS365, SOURCE.MS365GROUPS, SOURCE.ONEDRIVE]
    const GoogleServices = [SOURCE.GOOGLEAPPS, SOURCE.GDRIVETEAM]
    const OtherServices = [SOURCE.BOX, SOURCE.DROPBOX, SOURCE.SFC, SOURCE.IMAP, SOURCE.DDB, SOURCE.GACCOUNTS]
    const DynamicsServices = [SOURCE.DYNAMICS365]

    const authorizedServices = (listServices: SOURCE[]) => {
        return listServices.filter(
            service =>
                isBackupPermitted(`${service}.ACCESS`, userInfo?.permissions) && permittedWhitelist.includes(service)
        )
    }

    const MicrosoftServiceList = authorizedServices(MicrosoftServices)
    const GoogleServiceList = authorizedServices(GoogleServices)
    const OtherServiceList = authorizedServices(OtherServices)
    const DynamicsServiceList = authorizedServices(DynamicsServices)

    const FirstRow = useMemo(() => {
        const FirstRow = {} as any
        if (MicrosoftServiceList.length < 1) {
            return FirstRow
        }
        if (MicrosoftServiceList.length >= 1) {
            FirstRow.Microsoft = MicrosoftServiceList
            return FirstRow
        }
    }, [MicrosoftServiceList])

    const SecondRow = useMemo(() => {
        const SecondRow = {} as any
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length >= 1
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 1 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 0
        ) {
            SecondRow.Google = GoogleServiceList
            SecondRow.Dynamics = [SOURCE.DYNAMICS365]
            return SecondRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 0
        ) {
            SecondRow.Google = GoogleServiceList
            return SecondRow
        }
        if (
            GoogleServiceList.length === 2 &&
            OtherServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365)
        ) {
            SecondRow.Google = GoogleServiceList
            SecondRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 1
        ) {
            SecondRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return SecondRow
        }
        if (!DynamicsServiceList.includes(SOURCE.DYNAMICS365) && GoogleServiceList.length > 1) {
            SecondRow.Google = GoogleServiceList

            return SecondRow
        }
        if (
            (GoogleServiceList.length === 1 &&
                DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
                OtherServiceList.length === 3) ||
            (GoogleServiceList.length === 1 &&
                DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
                OtherServiceList.length === 4)
        ) {
            SecondRow.Google = GoogleServiceList
            SecondRow.Dynamics = [SOURCE.DYNAMICS365]
            SecondRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 0
        ) {
            SecondRow.Dynamics = [SOURCE.DYNAMICS365]

            return SecondRow
        }
        if (
            GoogleServiceList.length === 1 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 1
        ) {
            SecondRow.Dynamics = [SOURCE.DYNAMICS365]
            SecondRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return SecondRow
        }

        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length >= 4
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length >= 4
        ) {
            return SecondRow
        }

        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length <= 3
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            return SecondRow
        }
        if (
            GoogleServiceList.length === 2 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length >= 1
        ) {
            SecondRow.Google = GoogleServiceList
            SecondRow.Dynamics = [SOURCE.DYNAMICS365]

            return SecondRow
        }
    }, [GoogleServiceList])

    const ThirdRow = useMemo(() => {
        const ThirdRow = {} as any
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }

        if (GoogleServiceList.length === 1 && OtherServiceList.length === 3) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]
            return ThirdRow
        }

        if (
            GoogleServiceList.length === 1 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 1 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]
            ThirdRow[OtherServiceList[3]] = [OtherServiceList[3]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 2 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 1
        ) {
            ThirdRow.Dynamics = [SOURCE.DYNAMICS365]
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 5
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 6
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 2 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]
            ThirdRow[OtherServiceList[3]] = [OtherServiceList[3]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            ThirdRow.Dynamics = [SOURCE.DYNAMICS365]
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            ThirdRow.Dynamics = [SOURCE.DYNAMICS365]
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length >= 4
        ) {
            ThirdRow.Dynamics = [SOURCE.DYNAMICS365]
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }

        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]

            return ThirdRow
        }

        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 1
        ) {
            ThirdRow.Google = GoogleServiceList
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]

            return ThirdRow
        }

        if (GoogleServiceList.length === 2 && OtherServiceList.length === 1) {
            return ThirdRow
        }

        if (GoogleServiceList.length === 0 && OtherServiceList.length === 1) {
            return ThirdRow
        }

        if (OtherServiceList.length <= 4) {
            OtherServiceList.forEach(source => {
                ThirdRow[source] = [source]
            })
            return ThirdRow
        }

        if (OtherServiceList.length === 5) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }

        if (OtherServiceList.length === 6) {
            ThirdRow[OtherServiceList[0]] = [OtherServiceList[0]]
            ThirdRow[OtherServiceList[1]] = [OtherServiceList[1]]
            ThirdRow[OtherServiceList[2]] = [OtherServiceList[2]]

            return ThirdRow
        }
    }, [OtherServiceList])

    const FourthRow = useMemo(() => {
        const FourthRow = {} as any
        if (
            GoogleServiceList.length === 2 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 2
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 3
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 5
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]

            return FourthRow
        }
        if (
            GoogleServiceList.length === 0 &&
            DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 6
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]
            FourthRow[OtherServiceList[5]] = [OtherServiceList[5]]

            return FourthRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 6
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]
            FourthRow[OtherServiceList[5]] = [OtherServiceList[5]]

            return FourthRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 5
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]

            return FourthRow
        }
        if (
            GoogleServiceList.length === 1 &&
            !DynamicsServiceList.includes(SOURCE.DYNAMICS365) &&
            OtherServiceList.length === 4
        ) {
            FourthRow[OtherServiceList[2]] = [OtherServiceList[2]]
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]

            return FourthRow
        }
        if (OtherServiceList.length <= 4) {
            return FourthRow
        }

        if (OtherServiceList.length === 5) {
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]

            return FourthRow
        }

        if (OtherServiceList.length === 6) {
            FourthRow[OtherServiceList[3]] = [OtherServiceList[3]]
            FourthRow[OtherServiceList[4]] = [OtherServiceList[4]]
            FourthRow[OtherServiceList[5]] = [OtherServiceList[5]]

            return FourthRow
        }
    }, [OtherServiceList])
    return (
        <>
            {!isBackup && (
                <>
                    <StyledSubHeading>{t(`homepage:headerSubTitle`)}</StyledSubHeading>
                    <StyledHomeDivider />
                </>
            )}
            <HomeContent userInfo={userInfo} companyInfo={companyInfo} services={FirstRow} />
            <StyledColSpace />
            <HomeContent userInfo={userInfo} companyInfo={companyInfo} services={SecondRow} />
            {Object.values(SecondRow).length >= 1 && <StyledColSpace />}
            <HomeContent userInfo={userInfo} companyInfo={companyInfo} services={ThirdRow} />
            <StyledColSpace />
            <HomeContent userInfo={userInfo} companyInfo={companyInfo} services={FourthRow} />

            {!isBackup && !!companyInfo && companyInfo.isCloudally && (
                <>
                    <StyledLine />
                    <StyledHowTo>
                        <span>
                            {t('startUsing:videos')} {t('startUsing:howTo')}
                        </span>
                    </StyledHowTo>

                    <StartUsingTutorials>
                        <TutorialButton url={NEW_HOME_PAGE.BACKUP_HOW_TO_URL} text={t('startUsing:howToBackup')} />
                    </StartUsingTutorials>
                </>
            )}
            <WizardModal />
        </>
    )
}

const mapStateToProps = (state: any) => ({
    companyInfo: state.companyInfo.response,
    userInfo: state.userInfo.response
})

export const NewHome = connect(mapStateToProps)(NewHomepage)
