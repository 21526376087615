import React from 'react'
import {useTranslation} from 'react-i18next'

import {UserInfoDataType, CompanyInfoType} from 'ca-common/types'
import {PAGES, PORTAL_BASENAME} from 'ca-common/constants'
import {isNotAnyExternalOrOkta} from 'ca-common/utils/billing'
import {validatePageAccess} from 'ca-common/utils/permissions'
import {
    StyledSubscribeButton,
    StyledTrialMessage
} from 'ca-common/features/BaseHeader/organisms/TrialMessage/StyledTrialMessage'

type TrialMessageContentProps = {
    userInfo: UserInfoDataType
    companyInfo: CompanyInfoType
}

export const TrialMessage = ({userInfo, companyInfo}: TrialMessageContentProps): null | JSX.Element => {
    const {t} = useTranslation()
    const {isSubscribed, isMsMarketplaceUser, trialDaysLeft} = userInfo

    if (Object.keys(userInfo).length === 0 || isMsMarketplaceUser || isSubscribed || companyInfo.autoSubscribe) {
        return null
    }

    if (!isNotAnyExternalOrOkta(companyInfo, userInfo)) {
        return <span>{t('header:subscribe:externalUnsubscribed')}</span>
    }

    return (
        <StyledTrialMessage>
            <span>
                {trialDaysLeft > 0
                    ? t('header:subscribe:trialDaysLeft', {days: trialDaysLeft})
                    : t('header:subscribe:trialExpired')}
            </span>
            {validatePageAccess({page: PAGES.BILLING, userInfo, isForSubscribeLink: true, companyInfo}) ? (
                <StyledSubscribeButton href={`/${PORTAL_BASENAME}/billing?isForSubscribe=true`}>
                    {t('header:subscribe:button')}
                </StyledSubscribeButton>
            ) : null}
        </StyledTrialMessage>
    )
}
