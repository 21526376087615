import React, {useState} from 'react'
import {Switch, Tooltip, Modal} from 'antd'
import {useTranslation, Trans} from 'react-i18next'

import CloseCircle from 'ca-common/icons/restrictions/CloseCircle.svg'

import {openWarningNotification, openSuccessNotification} from 'ca-common/utils/toasts'

import {
    StyledActions,
    StyledSwither,
    StyledText,
    StyledConfirmText,
    StyledConfirmTitle,
    StyledErrorHeader,
    StyledErrorText,
    StyledWrapper,
    StyledIcon,
    StyledTooltipWrapper
} from './StyledActions'

type ActionsProps = {
    activateRules: (arg0: boolean) => Promise<any>
    tableIsEmpty: boolean
    addIpAddress: JSX.Element
    active?: boolean
    showConfirmation: boolean
    setShowConfirmation: (arg0: boolean) => void
    activateErrorText: string
    setActivateErrorText: (arg0: string) => void
}

export const Actions = (props: ActionsProps): JSX.Element => {
    const {
        activateRules,
        tableIsEmpty,
        addIpAddress,
        active = false,
        showConfirmation,
        setShowConfirmation,
        setActivateErrorText,
        activateErrorText
    } = props
    const {t} = useTranslation()
    const [waitingActivate, setWaitingActivate] = useState(false)

    const onChangeHandler = () => {
        setShowConfirmation(true)
    }

    const onOkHandler = () => {
        setWaitingActivate(true)
        activateRules(!active)
            .then(response => {
                const res = response.data ?? response
                if (res.success && res.restrictionsActive) {
                    openSuccessNotification(
                        t('ipRestrictions:switcher:enableToast:text'),
                        t('ipRestrictions:switcher:enableToast:title')
                    )
                } else if (res.success && !res.restrictionsActive) {
                    openWarningNotification(
                        t('ipRestrictions:switcher:disableToast:text'),
                        t('ipRestrictions:switcher:disableToast:title')
                    )
                }
            })
            .catch(err => {
                if (err?.data?.activate) {
                    const errorText = err.data.activate

                    setActivateErrorText(errorText)
                }
            })
            .finally(() => {
                setWaitingActivate(false)
            })

        setShowConfirmation(false)
    }

    const onCancelHandler = () => {
        setShowConfirmation(false)
    }

    const hideActivateError = () => {
        setActivateErrorText('')
    }

    return (
        <React.Fragment>
            <StyledActions>
                <StyledSwither>
                    <StyledText>{t('ipRestrictions:switcher:label')}</StyledText>
                    <StyledTooltipWrapper>
                        <Tooltip
                            getPopupContainer={triggerNode => triggerNode}
                            title={tableIsEmpty && t('ipRestrictions:switcher:tooltip')}
                        >
                            <Switch
                                onChange={onChangeHandler}
                                checked={active}
                                loading={waitingActivate}
                                disabled={tableIsEmpty}
                            />
                        </Tooltip>
                    </StyledTooltipWrapper>
                </StyledSwither>
                {addIpAddress}
            </StyledActions>
            <Modal
                centered
                open={showConfirmation}
                closable
                okText="Confirm"
                cancelButtonProps={{type: 'text'}}
                onOk={onOkHandler}
                onCancel={onCancelHandler}
                width={628}
                title={
                    <StyledConfirmTitle>
                        {active
                            ? t('ipRestrictions:activateModal:deactivate:title')
                            : t('ipRestrictions:activateModal:activate:title')}
                    </StyledConfirmTitle>
                }
            >
                <StyledConfirmText>
                    {active ? (
                        <Trans i18nKey="ipRestrictions:activateModal:deactivate:text" />
                    ) : (
                        <Trans i18nKey="ipRestrictions:activateModal:activate:text" />
                    )}
                </StyledConfirmText>
            </Modal>
            <Modal
                centered
                open={!!activateErrorText}
                closable
                width={520}
                onOk={hideActivateError}
                onCancel={hideActivateError}
                cancelButtonProps={{style: {display: 'none'}}}
                bodyStyle={{display: 'flex'}}
            >
                <React.Fragment>
                    <StyledIcon component={CloseCircle} />
                    <StyledWrapper>
                        <StyledErrorHeader>{t('ipRestrictions:activateModal:validationErrorHeader')}</StyledErrorHeader>
                        <StyledErrorText>{activateErrorText}</StyledErrorText>
                    </StyledWrapper>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    )
}
