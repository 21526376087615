import React from 'react'
import {useTranslation} from 'react-i18next'

import Warning from 'ca-common/icons/WarningFilled.svg'

import {StyledNoBackupsInfo, Title, Notification, NotificationText, WarningIcon} from './StyledNoBackupsInfo'

export const NoBackupsInfo = () => {
    const {t} = useTranslation()
    return (
        <StyledNoBackupsInfo>
            <Title>{t('table:billing:billingForecast')}</Title>
            <Notification>
                <WarningIcon component={Warning} />
                <NotificationText>{t('table:billing:noBackups')}</NotificationText>
            </Notification>
        </StyledNoBackupsInfo>
    )
}
