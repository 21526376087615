import React, {useMemo} from 'react'
import {SOURCE} from 'ca-common/common/enum'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import Settings from 'ca-common/icons/sidebar/Settings.svg'

import {ServiceIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {
    Column,
    StyledCard,
    Text,
    Total,
    Service,
    ConfirmationData,
    ReinstateTag,
    ServiceAndIcon,
    ServiceColumn
} from './StyledCard'

type CardProps = {
    totalEntities: number
    selectedEntities?: number
    service: SOURCE
    onChangeSettings?: (service: SOURCE) => void
    onChangeEntities?: (service: SOURCE) => void
    isDefaultTaskSettings?: boolean
    isForReinstate?: boolean
}

export const Card = ({
    service,
    totalEntities,
    selectedEntities,
    onChangeSettings,
    onChangeEntities,
    isDefaultTaskSettings,
    isForReinstate
}: CardProps) => {
    const {t} = useTranslation()

    const settingsText = useMemo(() => {
        if (isForReinstate) {
            return t('oneClickActivation:wizard:confirmationStep:reinstateTaskSettings')
        }

        if (isDefaultTaskSettings) {
            return t('oneClickActivation:wizard:confirmationStep:defaultTaskSettings')
        }

        return t('oneClickActivation:wizard:confirmationStep:customizedTaskSettings')
    }, [isForReinstate, isDefaultTaskSettings])

    return (
        <StyledCard noEntities={!selectedEntities} isForReinstate={isForReinstate}>
            <ServiceAndIcon>
                <ServiceIcon source={service} />
                <ServiceColumn>
                    <Service>{t(`oneClickActivation:wizard:confirmationStep:services:${service}`)}</Service>
                    {isForReinstate && <ReinstateTag>{t('oneClickActivation:wizard:options:reinstate')}</ReinstateTag>}
                </ServiceColumn>
            </ServiceAndIcon>
            <ConfirmationData>
                <Column>
                    <Total>{totalEntities}</Total>
                    <Text>{t('oneClickActivation:wizard:confirmationStep:totalEntities')}</Text>
                </Column>
                <Column>
                    <Total>{selectedEntities}</Total>
                    <Text
                        isClickabled={!!onChangeEntities && !isForReinstate}
                        onClick={() => onChangeEntities && !isForReinstate && onChangeEntities(service)}
                    >
                        {t('oneClickActivation:wizard:confirmationStep:selectedEntities')}
                    </Text>
                </Column>
                <Column>
                    <Icon component={Settings} style={{fontSize: '24px'}} />
                    <Text
                        isClickabled={!!onChangeSettings && !isForReinstate}
                        onClick={() => onChangeSettings && !isForReinstate && onChangeSettings(service)}
                    >
                        {settingsText}
                    </Text>
                </Column>
            </ConfirmationData>
        </StyledCard>
    )
}
