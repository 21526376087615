import {css} from 'styled-components'

export const InlineManualGlobalStyle = css`
    .inmplayer-popover-wrapper {
        border-width: 0 !important;
        box-shadow: 0 0 36px rgba(79, 125, 255, 0.12) !important;
    }

    .release-highlights {
        margin: -1em;
    }
`
