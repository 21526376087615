import {useTranslation} from 'react-i18next'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {DROPBOX_TYPE} from 'ca-common/common/enum'

export const BackupEntitiesTitle = props => {
    const {source, total, affectedByFilters, isDropboxProfessionalAccount} = props
    const {t} = useTranslation()

    const currentSource = isDropboxProfessionalAccount ? DROPBOX_TYPE.DROPBOX_PROFESSIONAL : source

    const entityName = getSvcAccountsDescription(currentSource, true)
    const title =
        affectedByFilters !== total
            ? t('backups:totalOfEntities', {entityName, total, affectedByFilters})
            : t('backups:totalEntities', {entityName, total})

    return title
}
