import {i18n} from 'src/locales'

import {RULE_LESS_THAN, RULE_MORE_THAN, RULE_RECORD_LEVEL} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getSmartAlertRuleFriendly = rule => {
    switch (rule) {
        case RULE_LESS_THAN:
            return i18n.t('smartAlerts:rule:lessThan')

        case RULE_MORE_THAN:
            return i18n.t('smartAlerts:rule:moreThan')

        case RULE_RECORD_LEVEL:
            return i18n.t('smartAlerts:rule:recordLevel')

        default:
            return ''
    }
}
