import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {Button} from 'ca-common/ui-lib/atoms'
import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

export const RefreshIcon = styled(Icon)`
    font-size: 32px;
    color: ${variables.navyBlue};
`

export const RefreshButton = styled(Button)`
    padding: 0;
    border: none;
`

export const StyledButton = styled(ButtonHoverReverse)`
    pointer-events: ${props => props.disabled && 'none'};
`

export const StyledButtonWrapper = styled.span`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
