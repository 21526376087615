import {inlineManualCallbacks} from 'ca-common/features/InlineManual/inlineManualCallbacks'
import {isDevelopment} from 'ca-common/utils/runtime'

const inlineManualSrc = `https://cdn.inlinemanual.com/embed/player.${process.env.INLINE_MANUAL_API}.bare.js`

export const inlineManualInit = (): void => {
    const script = document.createElement('script')
    script.src = inlineManualSrc
    script.async = true
    script.defer = true

    if (isDevelopment) {
        script.onload = () => {
            console.log('InlineManual has been initialized')
        }
    }

    try {
        document.body.appendChild(script)
        window.inlineManualCallbacks = inlineManualCallbacks
    } catch (error) {
        console.error('InlineManual initialization error: ', error)
    }
}
