import _ from 'lodash'
import React, {useEffect} from 'react'
import {Tabs} from 'antd'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router'

import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {ACCESS_LEVEL, CREDENTIALS_TYPES} from 'ca-common/constants'

import {makeDefaultModal, eatModal} from 'ca-common/redux/modules/modal'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {
    changePassword,
    fetchTwoFa,
    disableTwoFa,
    passwordExpired,
    enforceTwoFa
} from 'src/newcore/redux/modules/security'
import {updateUserInfo, fetchUserInfo, userInfoSelector} from 'src/newcore/redux/modules/account'
import {isExternal} from 'src/newcore/utils/companyInfo'
import {isOpenID} from 'src/newcore/components/Settings/Utils'
import {IpRestriction} from 'src/newcore/features/IpRestriction'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

import {SecurityPageForm} from './SecurityForm'
import {PasswordPolicies} from './PasswordPolicies'
import {SAML} from './SAML'
import {StyledActive, StyledIndicator, StyledSecurityPageWrapper, StyledTabs} from './StyledSecurity'
import {getPages} from '../Settings/Config'

const {TabPane} = Tabs

const SETTINGS_PAGE = '/settings'

export const SecurityPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation()
    const twoFa = useAppSelector((state: AppState) => state.twoFa)
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const curLoc = location.pathname.split('/')[2]
    const renderPages = getPages().filter(value => {
        return value.name === curLoc
    })
    useEffect(() => {
        const pageIsAllow = renderPages[0].allow
        if (isExternal() || !pageIsAllow) {
            history.push(SETTINGS_PAGE)
        }

        dispatch(fetchTwoFa())
    }, [])

    const openChangePasswordForm = () => {
        dispatch(
            makeDefaultModal({
                children: (
                    <SecurityPageForm
                        isOpenID={isOpenID(userInfo?.credentialType)}
                        passwordExpiredDays={userInfo?.passwordExpiredDays}
                        onSubmit={submitForm}
                        onReject={reject}
                    />
                ),
                title: t('securityPage:action'),
                isShowFooter: false
            })
        )
    }

    const submitForm = (values: any) => {
        dispatch(changePassword({...values}))
            .unwrap()
            .then(() => {
                openSuccessNotification(t('securityPage:passwordChanged'))
                dispatch(eatModal())
            })
            .catch((err: {status: any}) => {
                openErrorModal(err.status)
                dispatch(eatModal())
            })
    }

    const reject = () => dispatch(eatModal())

    const submitExpirationForm = (values: {
        expiredPassword: boolean
        days: string | number
        enforceTwoStepAuthOnAllUsers: boolean
    }) => {
        const days = values.expiredPassword ? +values.days : null

        dispatch(passwordExpired({expiredPassword: values.expiredPassword, days})).then(() => {
            openSuccessNotification(t('securityPage:settingChanged'))
        })

        dispatch(updateUserInfo({passwordExpiredDays: days}))

        if (!_.isNull(values.enforceTwoStepAuthOnAllUsers)) {
            dispatch(enforceTwoFa({enableEnforceTwoFA: values.enforceTwoStepAuthOnAllUsers}))
            dispatch(updateUserInfo({enforceTwoStepAuthOnAllUsers: values.enforceTwoStepAuthOnAllUsers}))
        }
    }

    const disable2FA = () => {
        dispatch(disableTwoFa())
            .then(() => {
                openSuccessNotification(t('twoFaAuthPage:disable2FA'))
                dispatch(fetchTwoFa())
            })
            .catch(() => openErrorModal(t('twoFaAuthPage:disableFailed')))
    }

    const isAdmin = userInfo?.permissions?.accessLevel === ACCESS_LEVEL.FULL
    const twoFaEnabled = twoFa?.response?.data?.twoFaEnabled
    const indicatorText =
        userInfo?.credentialType === CREDENTIALS_TYPES.OKTA
            ? t('securityPage:samlIndicator')
            : t('securityPage:emailIndicator')

    const SecurityTabs = {
        Password: t('securityPage:password'),
        SAML: t('securityPage:saml:title'),
        IpRestrictions: t('securityPage:ipRestrictions')
    }

    return twoFa.status !== 'fulfilled' ? (
        <Spinner modifier="page" />
    ) : (
        <StyledSecurityPageWrapper>
            <StyledTabs>
                {userInfo?.credentialType !== CREDENTIALS_TYPES.OKTA && (
                    <TabPane tab={SecurityTabs.Password} key="1">
                        <PasswordPolicies
                            twoFaEnabled={twoFaEnabled}
                            userInfo={userInfo}
                            companyInfo={companyInfo}
                            openChangePasswordForm={openChangePasswordForm}
                            submitExpirationForm={submitExpirationForm}
                            disableTwoFa={disable2FA}
                            reset={onreset}
                        />
                    </TabPane>
                )}
                {isAdmin && (
                    <TabPane tab={SecurityTabs.IpRestrictions} key="2">
                        <IpRestriction />
                    </TabPane>
                )}
                {!companyInfo?.whiteLable && (
                    <TabPane tab={SecurityTabs.SAML} key="3">
                        <SAML fetchUserInfo={() => dispatch(fetchUserInfo)} />
                    </TabPane>
                )}
            </StyledTabs>
            <StyledIndicator>
                {indicatorText}:<StyledActive>{t('securityPage:indicatorActive')}</StyledActive>
            </StyledIndicator>
        </StyledSecurityPageWrapper>
    )
}
