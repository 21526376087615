import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'
import {getLocalDateFromUTC} from 'ca-common/utils/datetime'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {SOURCE, RECOVERY_TYPE} from 'ca-common/common/enum'
import {MSTaskEntityType} from 'ca-common/types'

import {changeData, closeWizard, nextStep, previousStep} from 'src/newcore/redux/modules/wizard'
import {DateConfiguration} from 'src/newcore/features/RecoveryWizard/organisms/DateConfiguration'
import {BACKUP_DATE_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'

type FullMailboxDateConfigureProps = {
    entity: MSTaskEntityType
    taskId: string
    source: SOURCE
    fullRecoveryOptions: RECOVERY_TYPE
} & ReturnType<typeof mapDispatchToProps>

const FullMailboxDateConfigureRaw = ({
    actions,
    entity,
    taskId,
    source,
    fullRecoveryOptions
}: FullMailboxDateConfigureProps): JSX.Element => {
    const {t} = useTranslation()
    const lastBackupDate = +getLocalDateFromUTC(entity?.lastBackupDate)

    const [date, setDate] = useState(0)
    const [dateType, setDateType] = useState(
        lastBackupDate ? BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP : BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE
    )

    const handleCancel = () => {
        actions.closeWizard()
    }

    const handleNextStep = () => {
        actions.changeData({
            date: dateType === BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP ? lastBackupDate : date,
            dateType,
            error: undefined
        })
        actions.nextStep()
    }

    const handlePreviousStep = () => {
        actions.previousStep()
    }

    const nextDisabled = dateType === BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE && !date

    return (
        <>
            <DateConfiguration
                entity={entity}
                lastBackupDate={lastBackupDate}
                date={date}
                setDate={setDate}
                dateType={dateType}
                setDateType={setDateType}
                taskId={taskId}
                fullRecoveryOptions={fullRecoveryOptions}
                source={source}
            />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button disabled={nextDisabled} onClick={handleNextStep} type="primary">
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={handlePreviousStep} />
            </ModalFooter>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    entity: state.wizard.data.entity,
    taskId: state.wizard.data.taskId,
    source: state.wizard.data.source,
    fullRecoveryOptions: state.wizard.data.fullRecoveryOptions
})

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            previousStep,
            changeData
        },
        dispatch
    )
})

export const FullMailboxDateConfigure = connect(mapStateToProps, mapDispatchToProps)(FullMailboxDateConfigureRaw)
