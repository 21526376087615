import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import React from 'react'

import {FULL_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

export const LastSnapshot = props => {
    const {date} = props

    const {t} = useTranslation()

    return date ? (
        <Tooltip placement="top" title={moment.utc(date, LONG_DATE_FORMAT).format(FULL_FORMAT)} mouseEnterDelay={0.4}>
            <span>{moment.utc(date, LONG_DATE_FORMAT).fromNow().toString()}</span>
        </Tooltip>
    ) : (
        t('lastBackup:never')
    )
}
