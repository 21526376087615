import styled from 'styled-components'
import {Table} from 'antd'

import variables from 'ca-common/variables'

export const StyledTable = styled(Table)`
    font-family: ${variables.publicPageFont};

    .ant-table {
        border-radius: ${variables.borderRadiusBase};
        padding: ${variables.gutterXs};
        padding-bottom: 53px;
        font-size: ${variables.subTitleFontSize};
    }

    .ant-table-title {
        font-weight: 400;
        font-size: ${variables.subTitleFontSize};
    }

    .ant-table-thead > tr > th {
        font-weight: 500;
    }

    .ant-table-tbody > tr > td {
        font-weight: 300;
    }

    .ant-pagination,
    .ant-pagination li,
    .ant-pagination li a {
        color: ${variables.navyAsphalt};
        border-color: ${variables.navyAsphalt};
        border-radius: ${variables.borderRadiusBase};
        font-weight: 300;
    }
`
