import React from 'react'
import {useTranslation} from 'react-i18next'

import {TotalObjects} from 'ca-common/molecules/TotalObjects'

import {CountJobs, SubtitleText, SubtitleWrapper, TotalObjectsWrapper} from './StyledJobsSubstitle'

type JobsSubtitleProps = {
    totalCount?: number
    loading: boolean
}

export const JobsSubtitle = ({totalCount, loading}: JobsSubtitleProps): JSX.Element => {
    const {t} = useTranslation()
    return (
        <TotalObjectsWrapper>
            <TotalObjects loading={loading}>
                <SubtitleWrapper>
                    <CountJobs>{totalCount}</CountJobs>
                    <SubtitleText>{t('JobsPage:subtitle')}</SubtitleText>
                </SubtitleWrapper>
            </TotalObjects>
        </TotalObjectsWrapper>
    )
}
