import {SOURCE} from 'ca-common/common/enum'

type GetTotalEntitiesBySourceProps = {
    totalEntities?: {source: SOURCE; totalItems: number}[]
    service: SOURCE
}

export const getTotalEntitiesByService = ({totalEntities, service}: GetTotalEntitiesBySourceProps) => {
    return totalEntities?.find(entity => entity.source === service)?.totalItems || 0
}
