import styled from 'styled-components'
import {Layout} from 'antd'
import variables from 'ca-common/variables'

const {Content} = Layout

export const StyledMainLayout = styled(Layout)`
    min-height: 100vh;

    background: none;
`

export const StyledContentWrapper = styled(Layout)`
    margin-top: ${variables.headerHeight};
    background: none;
    // @media (max-width: ${variables.screenLg}) {
    //     margin-top: ${variables.headerWideHeight};
    // }
`

export const StyledContent = styled(Content)`
    position: relative;
    padding: ${variables.gutterMd} ${variables.gutterMd} 100px ${variables.gutterMd};
    background: ${variables.background};
    transition: all 0.3s ease-in-out;
    margin-left: ${variables.sidebarWidth};
`
