import React from 'react'
import {useTranslation} from 'react-i18next'

import {CalendarsTableItem} from 'src/newcore/features/Recovery/fetch'

import {EmptyRow, SingleField, StyledExpandedRow} from 'src/newcore/features/Recovery/atoms'

type ExpandedCalendarRowProps = {
    record: CalendarsTableItem
}

export const ExpandedCalendarRow = (props: ExpandedCalendarRowProps): JSX.Element => {
    const {
        record: {description}
    } = props
    const {t} = useTranslation()

    if (!description) {
        return <EmptyRow />
    }

    return (
        <StyledExpandedRow>
            <SingleField title={t('table:rows:fields:description')} value={description} isShortValue />
        </StyledExpandedRow>
    )
}
