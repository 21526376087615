import styled from 'styled-components'
import variables from 'ca-common/variables'

export const OptionContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const BackupNoteHeader = styled.text`
    color: ${variables.red};
    font-weight: bold;
`

export const BackupNote = styled.div`
    min-width: 300px;
    font-size: ${variables.fontSizeBase};
    font-style: ${variables.mainFont};
    padding-bottom: ${variables.gutterSm};

    @media screen and (min-width: ${variables.screenLg}) and (max-width: ${variables.screenXl}) {
        min-width: 200px;
    }

    @media screen and (min-width: ${variables.screenXl}) and (max-width: ${variables.screenXsLaptop}) {
        min-width: 400px;
    }

    @media screen and (min-width: ${variables.screenXsLaptop}) and (max-width: ${variables.screenFullHD}) {
        min-width: 500px;
    }
`
