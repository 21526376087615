import React from 'react'
import {Tooltip} from 'antd'

import {Tag} from 'ca-common/ui-lib/atoms/Tag'
import {StyledTags} from './StyledTags'

export const Tags = ({tags, children}: {tags: any[]; children: JSX.Element | null}) => {
    return (
        <StyledTags>
            {tags.map((tag: any) => (
                <Tooltip key={tag} title={tag} placement="top">
                    <Tag tag={tag} />
                </Tooltip>
            ))}
            {children}
        </StyledTags>
    )
}
