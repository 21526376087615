import React from 'react'
import {Modal} from 'antd'
import {useTranslation} from 'react-i18next'

export const ConfirmationRuleSelectionModal = (props: any) => {
    const {children, ...rest} = props
    const {t} = useTranslation()

    return (
        <Modal
            {...rest}
            width={550}
            closable={false}
            bodyStyle={{padding: 0}}
            style={{marginTop: 60}}
            okText={t('forms:common:actions:confirm')}
            centered
        >
            {children}
        </Modal>
    )
}
