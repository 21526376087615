import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {Collapse, SingleEntityHeader, SingleEntityFooter} from 'src/newcore/features/BackupSettings/organisms'
import {useBackupSettingsFormContext} from 'src/newcore/features/BackupSettings/contexts'

import {StyledBackupSettings, BackupSettingsForm} from '../StyledBackupSettings'

export const SingleEntitySettings = props => {
    const {
        activateBackup,
        editBackupSettings,
        loading,
        onBackupAuthClick,
        removedRulesList,
        clearRemovedRulesList,
        taskSettings,
        children,
        headerRight,
        activeKey,
        togglePanel,
        additionalActions
    } = props

    const [formValues, setFormValues] = useState()

    const form = useBackupSettingsFormContext()

    const onValuesChange = values => {
        setFormValues(values) // hack for rerender footer when form select item changed, like as antd3; need refactor
    }

    return (
        <StyledBackupSettings>
            <Collapse
                activeKey={activeKey}
                header={
                    <SingleEntityHeader
                        taskSettings={taskSettings}
                        onBackupAuthClick={onBackupAuthClick}
                        right={headerRight}
                    />
                }
                collapseFooter={
                    <SingleEntityFooter
                        activateBackup={activateBackup}
                        activeKey={activeKey}
                        additionalActions={additionalActions}
                        cached={taskSettings.cached}
                        clearRemovedRulesList={clearRemovedRulesList}
                        editBackupSettings={editBackupSettings}
                        loading={loading}
                        removedRulesList={removedRulesList}
                        togglePanel={togglePanel}
                        form={form}
                        formValues={formValues}
                    />
                }
            >
                <BackupSettingsForm
                    id="backupSettings"
                    form={form}
                    onValuesChange={onValuesChange}
                    onFinish={taskSettings.cached ? activateBackup : editBackupSettings}
                >
                    {children}
                </BackupSettingsForm>
            </Collapse>
        </StyledBackupSettings>
    )
}

SingleEntitySettings.propTypes = {
    headerInfo: PropTypes.node,
    headerRight: PropTypes.node,
    additionalActions: PropTypes.node
}

SingleEntitySettings.defaultProps = {
    headerInfo: null,
    headerRight: null,
    additionalActions: null
}
