import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-end;
`

type EntitiesColumnWrapperType = {
    textAlignRight?: boolean
    alignItemsBetween?: boolean
    width?: number
}
export const EntitiesColumnWrapper = styled.div<EntitiesColumnWrapperType>`
    display: flex;
    flex-direction: row;
    border-right: 2px solid ${variables.blueLight};
    margin-right: ${variables.gutterSm};
    justify-content: flex-end;
    width: 250px;
    align-items: center;
    ${props =>
        props.width &&
        css`
            width: ${props.width}px;
        `}
    
    ${props =>
        props.textAlignRight &&
        css`
            text-align: right;
        `}
    
    ${props =>
        props.alignItemsBetween &&
        css`
            justify-content: space-between;
        `}
   
    }
`
export const SingleEntityWrapper = styled.div`
    min-height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
