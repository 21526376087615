import pick from 'lodash/pick'
import {NewTaskSettings} from 'ca-common/types'
import {makeUrlParams} from 'ca-common/utils/url'
import {BackupEntity} from 'src/newcore/redux/modules/backups'

export const getRestoreSearchUrl = (
    task: NewTaskSettings,
    entity: BackupEntity,
    extraParams: Record<string, any> = {}
) => {
    if (!task || !entity) {
        return ''
    }

    const pickedParams = pick(task, [
        'source',
        'account',
        'useRest',
        'domain',
        'canDeleted',
        'isSupportPermissions',
        'isIndexMessagesChannel',
        'indexUpdatedByIdField',
        'azureAppVersion'
    ])

    const computedParams = {
        id: task?.taskId,
        region: task?.storageRegion,
        endpointURI: encodeURIComponent(task?.endpoint),
        alias: encodeURIComponent(task?.alias),
        index: Number(task?.indexStatus),
        entity: entity.entity,
        extid: entity.extid,
        msEntityId: entity.id
    }

    return `/App/restoreSearch?${makeUrlParams({...pickedParams, ...computedParams, ...extraParams})}`
}
