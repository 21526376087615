import React from 'react'
import {useTranslation} from 'react-i18next'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {isTaskPaused, isTaskProcessing, isTaskQueued} from '../../../utils/utils'
import {ACTIONS} from '../../../utils/constants'
import {status} from 'src/newcore/features/Homepage/types'
import {TaskActionsItem} from 'src/newcore/features/Homepage/atoms/TaskActionsItem'

type PauseProps = {
    sendAction: any
    status: status
    pullAndUpdateLocalServices: () => void
    disabled: boolean
    setSpin: (value: boolean) => void
}

export const PauseItem = ({
    sendAction,
    status,
    setSpin,
    pullAndUpdateLocalServices,
    disabled
}: PauseProps): JSX.Element => {
    const {t} = useTranslation()

    return <TaskActionsItem action={ACTIONS.PAUSE} onClick={onClickHandler} disabled={disabled} />

    function onClickHandler() {
        if (!isTaskProcessing(status) && !isTaskQueued(status)) {
            const activate = isTaskPaused(status)
            setSpin(true)
            sendAction(ACTIONS.PAUSE, activate)
                .then(() => {
                    openInfoNotification(t('backups:statusChange', [t(`backups:activate:success:false`)]))
                    pullAndUpdateLocalServices()
                })
                .catch((error: any) => {
                    setSpin(false)
                    openErrorModal(
                        t('backups:failedTo', [t(`backups:activate:error:false`)]) + (error ? error.status : '')
                    )
                })
        }
    }
}
