import React from 'react'
import {Trans} from 'react-i18next'
import Icon from '@ant-design/icons'

import {IconComponent} from 'ca-common/types'

import {NoteTitle, StyledNote, StyledNoteProps} from './StyledNote'

type NoteProps = {
    i18nKey: string
    icon?: IconComponent
    values?: Record<string, any>
} & Partial<StyledNoteProps>

export const Note = (props: NoteProps) => {
    const {icon, type = 'info', i18nKey, values} = props
    return (
        <StyledNote type={type}>
            {icon && <Icon component={icon} />}
            <Trans
                i18nKey={i18nKey}
                values={values}
                tOptions={{
                    interpolation: {
                        escapeValue: false
                    }
                }}
            >
                <NoteTitle />
            </Trans>
        </StyledNote>
    )
}
