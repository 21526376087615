import React, {useState} from 'react'
import {Form, Input} from 'antd'
import {useTranslation} from 'react-i18next'

import Button from 'ca-common/ui-lib/components/Button'
import {passwordValidate, requiredValidate} from 'src/ca-common/utils/validation'
import {createRules} from 'src/ca-common/utils/form'

import {StyledFooter, StyledSecurityForm} from './StyledSecurityForm'

type SecurityPageFormType = {
    onSubmit: (values: any) => void
    onReject: () => void
    isOpenID: boolean
    passwordExpiredDays?: string | number
}
export const SecurityPageForm = (props: SecurityPageFormType) => {
    const {onSubmit, onReject} = props
    const {t} = useTranslation()
    const [securityPageForm] = Form.useForm<SecurityPageFormType>()
    const {getFieldValue} = securityPageForm
    const [isDisable, setDisabled] = useState(true)
    const duplicateOldValidate = (value: any): string | undefined => {
        if (value && value !== getFieldValue('password')) {
            return undefined
        }
        return t(`forms:changePassword:fields:error:newOldNotMatch`)
    }

    const confirmPasswordValidate = (value: any): string | undefined => {
        if (value !== getFieldValue('newPassword')) {
            return t(`forms:changePassword:fields:error:passNotMatch`)
        }
        return undefined
    }
    const onValuesChange = () => {
        setDisabled(false)
    }
    return (
        <StyledSecurityForm onFinish={onSubmit} colon={false} form={securityPageForm} onValuesChange={onValuesChange}>
            <Form.Item
                name={'password'}
                label={t(`forms:changePassword:fields:password:label`)}
                rules={createRules([requiredValidate])}
            >
                <Input.Password visibilityToggle={false} />
            </Form.Item>
            <Form.Item
                name={'newPassword'}
                label={t(`forms:changePassword:fields:newPassword:label`)}
                rules={createRules([requiredValidate, passwordValidate, duplicateOldValidate])}
            >
                <Input.Password visibilityToggle={false} />
            </Form.Item>
            <Form.Item
                name={'confirmPassword'}
                label={t(`forms:changePassword:fields:confirmPassword:label`)}
                rules={createRules([requiredValidate, passwordValidate, confirmPasswordValidate])}
            >
                <Input.Password visibilityToggle={false} />
            </Form.Item>
            <StyledFooter>
                <Button modifier={'primary'} type="submit" disabled={isDisable}>
                    {t('forms:common:actions:save')}
                </Button>
                <Button modifier={'secondary'} onClick={onReject} type="button">
                    {t('forms:common:actions:cancel')}
                </Button>
            </StyledFooter>
        </StyledSecurityForm>
    )
}
