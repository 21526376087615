import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'

import {COLUMN_NAMES} from 'src/newcore/features/SmartAlerts/lib'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const GET_SFC_SMART_ALERTS = 'GET_SFC_SMART_ALERTS'
const GET_SFC_SMART_ALERTS_API = 'getSmartAlerts'
const sfcSmartAlertsWrapper = fetchWrapperRT(GET_SFC_SMART_ALERTS, async (data, {rejectWithValue}) => {
    try {
        return await postLessData(GET_SFC_SMART_ALERTS_API, data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})

export const getSfcSmartAlerts = sfcSmartAlertsWrapper.fetcher

const mergeStatebyId = (objects: TODO_ANY[], newParams: TODO_ANY) =>
    objects.map(object => {
        if (object.id === newParams.smartAlertId) {
            return {
                ...object,
                [COLUMN_NAMES.EVENT]: newParams.config.event,
                [COLUMN_NAMES.TOTAL]: newParams.config.amount,
                [COLUMN_NAMES.UNIT]: newParams.config.unit
            }
        }

        return object
    })

const alertsSlice = createSlice({
    name: 'alerts',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        updateSfcSmartAlertsLocal: (state, action) => {
            state.response.data = mergeStatebyId(state.response.data, action.payload)
        },
        clearSfsSmartAlerts: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => sfcSmartAlertsWrapper.makeReducers(builder)
})

export const {updateSfcSmartAlertsLocal, clearSfsSmartAlerts} = alertsSlice.actions
export const alerts = alertsSlice.reducer
