import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledEmailIcon = styled(Icon)`
    font-size: 24px;
    color: ${variables.white};
    cursor: pointer;
`
