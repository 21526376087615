import React, {memo} from 'react'
import {Space, Switch, Tooltip} from 'antd'
import {SwitchChangeEventHandler} from 'antd/lib/switch'
import {Trans} from 'react-i18next'

import {
    SettingsIcon,
    ExcludedIcon,
    StyledInnerWrapper,
    StyledRow,
    StyledWrapper,
    ToggleWrapper,
    StyledSettingsWrapper
} from './StyledSwitchBar'

import Settings from 'ca-common/icons/sidebar/Settings.svg'

export const SwitchBar = memo(
    ({
        active = true,
        excluded,
        checked,
        onChange,
        error,
        icon,
        name,
        children,
        isSettingsOpen,
        setIsSettingsOpen
    }: {
        active?: boolean
        excluded?: boolean
        checked?: boolean
        onChange?: SwitchChangeEventHandler
        error?: boolean
        icon: JSX.Element
        name: string
        isSettingsOpen?: boolean
        setIsSettingsOpen?: (arg0: (arg: boolean) => boolean) => void
        children?: JSX.Element | null
    }): JSX.Element => {
        const handleSettingsClick = () => (setIsSettingsOpen ? setIsSettingsOpen(prev => !prev) : null)

        return (
            <StyledRow checked={checked} error={error}>
                <StyledWrapper>
                    <StyledInnerWrapper>
                        <ToggleWrapper active={active}>
                            {excluded ? (
                                <Tooltip
                                    title={<Trans i18nKey={'recovery:wizard:subServiceSelection:excludedTooltip'} />}
                                >
                                    <ExcludedIcon type="stop" />
                                </Tooltip>
                            ) : (
                                <Switch checked={checked} onChange={onChange} />
                            )}
                        </ToggleWrapper>
                        <Space size={16}>
                            {icon}
                            <span>{name}</span>
                        </Space>
                    </StyledInnerWrapper>
                    {children && (
                        <SettingsIcon
                            disabled={!checked}
                            active={isSettingsOpen && checked}
                            onClick={handleSettingsClick}
                            component={Settings}
                        />
                    )}
                </StyledWrapper>
                <StyledSettingsWrapper active={isSettingsOpen && checked}>{children}</StyledSettingsWrapper>
            </StyledRow>
        )
    }
)
