import styled from 'styled-components'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'
import {ModalContentWrapper} from 'ca-common/components/Modal'

export const StyledModalContentWrapper = styled(ModalContentWrapper)`
    padding: ${variables.gutterMd};
    padding-top: 0;
    height: 100%;
`

export const SitesListWrapper = styled.div`
    width: 100%;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    margin-top: 8px;

    .ant-spin-spinning {
        display: block;
    }
`

export const SitesListHeader = styled.div`
    display: flex;
    border-bottom: 1px solid ${variables.navyBorder};
    gap: ${variables.gutterXs};
`

export const SitesListHeaderCell = styled.div`
    padding: ${variables.gutterXs};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    line-height: 17px;
`

export const StyledSiteWrapper = styled.div`
    padding: 8px 4px;
`

export const WarningIcon = styled(Icon)`
    font-size: 24px;
    color: ${variables.orange};
    margin-right: ${variables.gutterMd};
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: ${variables.gutterMd};

    p {
        margin-bottom: 0;
    }
`
