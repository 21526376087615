import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FetchedState, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {RestrictionEntry} from '../types'
import {GET_IP_RULES} from './constants'

export const getIpRulesSlice = (getIpRulesWrapper: any) =>
    createSlice({
        name: GET_IP_RULES,
        initialState: InitFetchedState as FetchedState<any>,
        reducers: {
            clearBackupTaskSettings: () => InitFetchedState as FetchedState,
            createRuleLocal: (state, action: PayloadAction<RestrictionEntry>) =>
                void state.response.items.push(action.payload),
            modifyRuleLocal: (state, action: PayloadAction<RestrictionEntry>) => {
                state.response.items = state.response.items.map((i: RestrictionEntry) => {
                    if (i.id === action.payload.id) {
                        return action.payload
                    }

                    return i
                })
            },
            removeRuleLocal: (state, action: PayloadAction<string>) => {
                const isLast = state.response.items.length === 1
                state.response.items = state.response.items.filter((i: RestrictionEntry) => i.id !== action.payload)

                if (isLast) {
                    state.response.active = false
                }
            },
            setActivateLocal: (state, action: PayloadAction<boolean>) => {
                state.response.active = action.payload
            }
        },
        extraReducers: builder => getIpRulesWrapper.makeReducers(builder)
    })
