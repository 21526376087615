import React from 'react'
import {CheckboxChangeEvent} from 'antd/es/checkbox'
import {useTranslation} from 'react-i18next'

import {RESTORE_OPTIONS, RESTORE_TYPE} from 'ca-common/common/enum'
import {RestoreDestinationSelectionProps} from 'src/newcore/features/RecoveryWizard/molecules/RestoreDestinationSelection'
import {
    getRestoreTypeDescriptionFriendly,
    getRestoreOptionsFriendly,
    getRestoreTypeSelectOptions
} from 'src/newcore/features/RecoveryWizard/lib'

import {StyledCheckbox, StyledRestoreHeader, StyledSelect, StyledTypeDescription} from './StyledRestoreOptions'

export const RestoreOptions = (
    props: Pick<RestoreDestinationSelectionProps, 'setRestoreOptions' | 'restoreOptions'>
) => {
    const {setRestoreOptions, restoreOptions} = props
    const {t} = useTranslation()

    const hasAddRestoreTag = Object.prototype.hasOwnProperty.call(restoreOptions, RESTORE_OPTIONS.ADD_RESTORE_TAG)
    const hasAddFolderPrefix = Object.prototype.hasOwnProperty.call(
        restoreOptions,
        RESTORE_OPTIONS.ADD_RESTORE_FOLDER_PREFIX
    )
    const hasCreateSnapshot = Object.prototype.hasOwnProperty.call(restoreOptions, RESTORE_OPTIONS.CREATE_SNAPSHOT)
    const hasRestoreType = Object.prototype.hasOwnProperty.call(restoreOptions, 'restoreType')

    const typeOnChangeHandler = (value: any) =>
        setRestoreOptions({
            ...restoreOptions,
            restoreType: value as RESTORE_TYPE
        })

    const optionOnChangeHandler = (restoreOption: RESTORE_OPTIONS) => (e: CheckboxChangeEvent) => {
        setRestoreOptions({
            ...restoreOptions,
            [restoreOption]: e.target.checked
        })
    }

    return (
        <>
            {hasRestoreType && (
                <>
                    <StyledRestoreHeader>{t('restoreType:label')}</StyledRestoreHeader>
                    <StyledSelect
                        onChange={typeOnChangeHandler}
                        value={restoreOptions.restoreType}
                        style={{width: 260}}
                        options={getRestoreTypeSelectOptions()}
                        getPopupContainer={t => t.parentElement as HTMLElement}
                    />
                    <StyledTypeDescription>
                        {getRestoreTypeDescriptionFriendly(restoreOptions.restoreType)}
                    </StyledTypeDescription>
                </>
            )}
            {(hasAddRestoreTag || hasAddFolderPrefix || hasCreateSnapshot) && (
                <StyledRestoreHeader>{t('restoreOptions:label')}</StyledRestoreHeader>
            )}
            {hasAddRestoreTag && (
                <StyledCheckbox
                    checked={Boolean(restoreOptions?.[RESTORE_OPTIONS.ADD_RESTORE_TAG])}
                    onChange={optionOnChangeHandler(RESTORE_OPTIONS.ADD_RESTORE_TAG)}
                >
                    {getRestoreOptionsFriendly(RESTORE_OPTIONS.ADD_RESTORE_TAG)}
                </StyledCheckbox>
            )}
            {hasAddFolderPrefix && (
                <StyledCheckbox
                    checked={Boolean(restoreOptions?.[RESTORE_OPTIONS.ADD_RESTORE_FOLDER_PREFIX])}
                    onChange={optionOnChangeHandler(RESTORE_OPTIONS.ADD_RESTORE_FOLDER_PREFIX)}
                >
                    {getRestoreOptionsFriendly(RESTORE_OPTIONS.ADD_RESTORE_FOLDER_PREFIX)}
                </StyledCheckbox>
            )}
            {hasCreateSnapshot && (
                <StyledCheckbox
                    checked={Boolean(restoreOptions?.[RESTORE_OPTIONS.CREATE_SNAPSHOT])}
                    onChange={optionOnChangeHandler(RESTORE_OPTIONS.CREATE_SNAPSHOT)}
                >
                    {getRestoreOptionsFriendly(RESTORE_OPTIONS.CREATE_SNAPSHOT)}
                </StyledCheckbox>
            )}
        </>
    )
}
