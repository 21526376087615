import React, {useState, useEffect} from 'react'
import {useTranslation, Trans} from 'react-i18next'

import {ALERT_LEVEL_ALL, ALERT_LEVEL_OBJECT} from 'src/newcore/features/SmartAlerts/lib/constants'
import {StyledLabel} from '../../atoms'
import {TriggerUserSelection} from '../../molecules/TriggerUserSelection'
import {RuleConfiguration} from '../../molecules/RuleConfiguration'
import {checkAlertConfiguration} from '../../lib'

import {StyledConfigurationWrapper, StyledDiv, StyledWarning, StyledIcon} from './StyledAlertConfiguration'

export const AlertConfiguration = ({
    changeTrigger,
    triggeredBy,
    specificObject,
    setConfig,
    config,
    setError,
    error,
    currentStep
}) => {
    const {t} = useTranslation()
    const [warning, setWarning] = useState(false)
    const [validateStatus, setValidateStatus] = useState('')
    const level = specificObject ? ALERT_LEVEL_OBJECT : ALERT_LEVEL_ALL

    useEffect(() => {
        !config && setWarning(false)
    }, [currentStep])

    useEffect(() => {
        if (error) {
            setValidateStatus('error')
        } else if (warning) {
            setValidateStatus('warning')
        } else {
            setValidateStatus('')
        }
    }, [error, warning])

    const checkValues = values => {
        setWarning(checkAlertConfiguration({values, level, triggeredBy}))
    }

    return (
        <StyledConfigurationWrapper>
            <StyledDiv>
                <StyledLabel>{t('smartAlerts:wizard:configurationMoreTitle')}</StyledLabel>
                <RuleConfiguration
                    config={config}
                    setConfig={setConfig}
                    setError={setError}
                    checkValues={checkValues}
                    validateStatus={validateStatus}
                    triggeredBy={triggeredBy}
                    level={level}
                    currentStep={currentStep}
                />
                {(error || warning) && config && (
                    <StyledWarning error={error} warning={warning}>
                        <StyledIcon type="exclamation-circle" theme="filled" />
                        <Trans i18nKey={'smartAlerts:wizard:warning:wideCriteria'} />
                    </StyledWarning>
                )}
            </StyledDiv>
            <StyledDiv>
                <StyledLabel>{t('smartAlerts:wizard:configurationTriggerTitle')}</StyledLabel>
                <TriggerUserSelection triggeredBy={triggeredBy} changeTrigger={changeTrigger} />
            </StyledDiv>
        </StyledConfigurationWrapper>
    )
}
