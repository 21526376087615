import React, {Dispatch, SetStateAction, useState} from 'react'
import {Skeleton, Spin} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {RecoveryCalendarButton} from 'src/newcore/features/Recovery/atoms/RecoveryCalendarButton'
import {ChangeDateModal} from 'src/newcore/features/Recovery/atoms/ChangeDateModal'
import {ChooseDateModal} from 'src/newcore/features/Recovery/organisms/ChooseDateModal'

type RecoveryCalendarProps = {
    subsourcesInfo: any
    entity: any
    isStoreEmpty: boolean
    date: number
    setDate: Dispatch<SetStateAction<number>>
    setIsPartialSelectedDate: Dispatch<SetStateAction<boolean>>
    source: SOURCE
}

export const RecoveryCalendar = ({
    entity,
    subsourcesInfo,
    isStoreEmpty,
    date,
    setDate,
    source,
    setIsPartialSelectedDate
}: RecoveryCalendarProps) => {
    const [dateModalVisible, setDateModalVisible] = useState(false)
    const [changeDateModalVisible, setChangeDateModalVisible] = useState(false)

    return (
        <Spin size="large" spinning={entity.loadingStatus !== 'fulfilled'}>
            <Skeleton
                active
                title={{width: '250px', style: {margin: '0', height: '32px'}}}
                paragraph={false}
                loading={!entity.lastBackupDate || !subsourcesInfo?.data}
            >
                <RecoveryCalendarButton
                    onClick={() => {
                        isStoreEmpty ? setDateModalVisible(true) : setChangeDateModalVisible(true)
                    }}
                    date={date}
                />
            </Skeleton>
            {date ? (
                <ChooseDateModal
                    date={date}
                    setDate={setDate}
                    visible={dateModalVisible}
                    setVisible={setDateModalVisible}
                    source={source}
                    setIsPartialSelectedDate={setIsPartialSelectedDate}
                />
            ) : (
                <></>
            )}
            <ChangeDateModal
                visible={changeDateModalVisible}
                onOk={() => setDateModalVisible(true)}
                setVisible={setChangeDateModalVisible}
            />
        </Spin>
    )
}
