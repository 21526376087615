import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Title = styled.span`
    font-weight: 400;
    font-size: ${variables.headingFontSize};
    color: ${variables.text};
`

export const Subtitle = styled.div`
    color: ${variables.text};
    font-size: ${variables.fontSizeBase};
    margin-bottom: ${variables.gutterMd};
    font-weight: 600;
`

export const StyledSwitchWrapper = styled.div`
    max-width: 660px;
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterXs};
    margin-bottom: 104px;
`

export const Header = styled.div`
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
    padding: ${variables.gutterXs} ${variables.gutterMd};
`

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Body = styled.div`
    border-top: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};
    overflow: hidden;
`

export const StyledError = styled.div`
    color: ${variables.red};
    font-style: italic;
    font-size: ${variables.mainTitleFontSize};
    text-align: center;
    font-weight: 300;
    margin-bottom: 42px;
    height: 34px;
    font-family: ${variables.publicPageFont};
`
