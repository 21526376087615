import {post} from 'src/newcore/utils/rest'
import {combineReducers, createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import {replicatesReducer} from '../backupSeeding'

import {validateSfcWhereClauseResultReducer} from './SQLWhereClause'
import {SEEDING_FILTERS} from 'ca-common/common/enum/seeding'
import {sourceObjectsListWrapperReducer} from './sourceObjectsList'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const appendData = (response: TODO_ANY) => ({
    data: Object.assign(
        {},
        ..._.map(response, object => ({
            [object]: {
                filter: {
                    name: SEEDING_FILTERS.ALL_RECORDS
                }
            }
        }))
    )
})

const toggleActive = (data: Record<string, any>, key: string) => ({
    ...data,
    [key]: {
        ...data[key],
        isActive: !data[key].isActive
    }
})

const toggleDeleted = (data: Record<string, any>, key: string) => ({
    ...data,
    [key]: {
        ...data[key],
        isDeleted: !data[key].isDeleted,
        isActive: false
    }
})

const setFilters = (data: TODO_ANY, filter: TODO_ANY, allObjects?: boolean) =>
    _.mapValues(data, object => ({
        ...object,
        filter: object.isActive || allObjects ? filter : object.filter
    }))

const appendFilters = (data: TODO_ANY, filter: TODO_ANY, allObjects?: boolean) =>
    _.mapValues(data, object => ({
        ...object,
        filter: object.isActive || allObjects ? {...object.filter, ...filter} : object.filter
    }))

const deselectAll = (data: TODO_ANY) =>
    _.mapValues(data, object => ({
        ...object,
        isActive: object.isActive ? false : undefined
    }))

const SEEDING_INIT = 'SEEDING_INIT'
const initSeedingWrapper = fetchWrapperRT(SEEDING_INIT, async (data, {rejectWithValue}) => {
    try {
        return await post('startRestoreOrDownload', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const initSeeding = initSeedingWrapper.fetcher

const SEEDING_GET_OBJECTS_LIST = 'SEEDING_GET_OBJECTS_LIST'
const getObjectsListWrapper = fetchWrapperRT(
    SEEDING_GET_OBJECTS_LIST,
    async data => await post('getSfcSnapshotObjectsWithChilds', data),
    {
        after: appendData
    }
)
export const getObjectsList = getObjectsListWrapper.fetcher

const objectsListSlice = createSlice({
    name: 'objectsList',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearCheckedObjects: () => InitFetchedState as FetchedState,
        deselectObjects: state => {
            state.response.data = deselectAll(state.response.data)
        },
        toggleDeletedObject: (state, action) => {
            state.response.data = toggleDeleted(state.response.data, action.payload)
        },
        toggleActiveObject: (state, action) => {
            state.response.data = toggleActive(state.response.data, action.payload)
        },
        appendActiveObjectsFilter: (state, action) => {
            state.response.data = appendFilters(state.response.data, action.payload)
        },
        appendAllObjectsFilter: (state, action) => {
            state.response.data = appendFilters(state.response.data, action.payload, true)
        },
        setActiveObjectsFilter: (state, action) => {
            state.response.data = setFilters(state.response.data, action.payload)
        },
        setAllObjectsFilter: (state, action) => {
            state.response.data = setFilters(state.response.data, action.payload, true)
        }
    },
    extraReducers: builder => getObjectsListWrapper.makeReducers(builder)
})

export const {
    clearCheckedObjects,
    deselectObjects,
    appendActiveObjectsFilter,
    appendAllObjectsFilter,
    toggleActiveObject,
    toggleDeletedObject,
    setActiveObjectsFilter,
    setAllObjectsFilter
} = objectsListSlice.actions

export const seedingReducers = combineReducers({
    sourceObjectsList: sourceObjectsListWrapperReducer,
    objectsList: objectsListSlice.reducer,
    validateSfcWhereClauseResult: validateSfcWhereClauseResultReducer,
    replicates: replicatesReducer
})
