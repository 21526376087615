import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    line-height: ${variables.gutterMd};
    padding: ${variables.gutterMd} ${variables.gutterMd};
`

export const HeaderTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`

export const ObjectName = styled.span`
    color: ${variables.navyBlue};
`
