import _ from 'lodash'
import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import queryString from 'query-string'
import {withRouter} from 'react-router'
import {Tooltip} from 'antd'

import {TASK_STATUS} from 'ca-common/common/enum/TaskStatus'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {LICENSE_STATUS, SOURCE} from 'ca-common/common/enum'

import {StyledActiveBackup, StyledAction, ActionWrapper} from './StyledActiveBackup'

const PAUSED_SET = [TASK_STATUS.PAUSED]
const ARCHIVED_SET = [TASK_STATUS.ARCHIVED]
const ACTIVATED_SET = [
    TASK_STATUS.SCHEDULED,
    TASK_STATUS.SUCCESS,
    TASK_STATUS.IN_PROCESS,
    TASK_STATUS.FAILED,
    TASK_STATUS.PARTIAL
]
const ARRAY_FORMAT = {arrayFormat: 'bracket'}
const ACTIONS = {
    PAUSED: 'paused',
    ARCHIVED: 'archived',
    ACTIVATED: 'activated',
    LICENSED: 'licensed'
}

const Action = props => {
    const {entityName, disabled, actionType, onClickHandler, active} = props
    const {t} = useTranslation()

    const actionName = t(`backups:activeBackup:text:${actionType}`)

    return (
        <ActionWrapper disabled={disabled}>
            <Tooltip
                placement="bottom"
                title={
                    <Trans
                        i18nKey="backups:activeBackup:text:totalOf"
                        values={{
                            actionName,
                            entityName
                        }}
                    ></Trans>
                }
                getPopupContainer={triggerNode => triggerNode} // https://stackoverflow.com/questions/63493223/customize-antd-tooltip-styles-using-styled-components
            >
                <StyledAction disabled={disabled} onClick={onClickHandler} active={active}>
                    {actionName}
                </StyledAction>
            </Tooltip>
        </ActionWrapper>
    )
}

class ActiveBackupRaw extends React.Component {
    constructor(props) {
        super(props)

        this.state = {activeAction: ''}
    }

    componentDidMount() {
        const statuses = this.getStatuses(this.props)
        const isLicensed = this.getLicensing(this.props) === LICENSE_STATUS.LICENSED
        const activeAction = this.getActiveActions(statuses, isLicensed)

        this.setState({activeAction})
    }

    componentDidUpdate(prevProps) {
        const statuses = this.getStatuses(this.props)
        const isLicensed = this.getLicensing(this.props) === LICENSE_STATUS.LICENSED
        const prevStatuses = this.getStatuses(prevProps)
        const isLicensedPrev = this.getLicensing(prevProps) === LICENSE_STATUS.LICENSED

        if (!_.isEqual(prevStatuses, statuses) || isLicensed !== isLicensedPrev) {
            this.setState({activeAction: this.getActiveActions(statuses, isLicensed)})
        }
    }

    getURLQuery(props) {
        const search = props?.location?.search || {}

        return queryString.parse(search, ARRAY_FORMAT)
    }

    clearFilter = () => {
        const {history, resetRowSelection} = this.props
        const queryParams = this.getURLQuery(this.props)

        resetRowSelection()

        history.push({
            search: queryString.stringify({..._.omit(queryParams, ['query', 'statusFilter', 'tagIds'])})
        })
    }

    refreshBackupEntities = statusFilter => {
        const {history} = this.props
        const statuses = this.getStatuses(this.props)

        if (!_.isEqual(statuses, statusFilter)) {
            // prevent console error. history.push dont work with equal params
            const newParams = {
                ...this.getURLQuery(this.props),
                statusFilter,
                licenseStatus: undefined
            }

            history.push({
                search: queryString.stringify(newParams, ARRAY_FORMAT)
            })
        }
    }

    activated = () => {
        this.refreshBackupEntities(ACTIVATED_SET)
    }

    paused = () => {
        this.refreshBackupEntities(PAUSED_SET)
    }

    archived = () => {
        this.refreshBackupEntities(ARCHIVED_SET)
    }

    licensed = () => {
        const {history} = this.props
        const statuses = this.getStatuses(this.props)
        const isLicensed = this.getLicensing(this.props) === LICENSE_STATUS.LICENSED

        if (!_.isEqual(statuses, []) || !isLicensed) {
            // prevent console error. history.push dont work with equal params
            const newParams = {
                ...this.getURLQuery(this.props),
                statusFilter: [],
                licenseStatus: LICENSE_STATUS.LICENSED
            }

            history.push({
                search: queryString.stringify(newParams, ARRAY_FORMAT)
            })
        }
    }

    getActiveActions(statuses, isLicensed) {
        const activeActions = []

        if (_.isEqual(statuses, PAUSED_SET)) {
            activeActions.push(ACTIONS.PAUSED)
        } else if (_.isEqual(statuses, ARCHIVED_SET)) {
            activeActions.push(ACTIONS.ARCHIVED)
        } else if (_.isEqual(statuses, ACTIVATED_SET)) {
            activeActions.push(ACTIONS.ACTIVATED)
        }

        if (isLicensed) {
            activeActions.push(ACTIONS.LICENSED)
        }

        return activeActions
    }

    getStatuses(props) {
        return _.get(this.getURLQuery(props), 'statusFilter', [])
    }

    getLicensing(props) {
        return _.get(this.getURLQuery(props), 'licenseStatus', null)
    }

    render() {
        const {fetched, source} = this.props
        const {activeAction} = this.state
        const entityName = getSvcAccountsDescription(source, true)

        return (
            <StyledActiveBackup>
                <Action
                    actionType={ACTIONS.ACTIVATED}
                    active={activeAction.includes(ACTIONS.ACTIVATED)}
                    disabled={fetched}
                    entityName={entityName}
                    onClickHandler={this.activated}
                />
                <Action
                    actionType={ACTIONS.PAUSED}
                    active={activeAction.includes(ACTIONS.PAUSED)}
                    disabled={fetched}
                    entityName={entityName}
                    onClickHandler={this.paused}
                />
                <Action
                    actionType={ACTIONS.ARCHIVED}
                    active={activeAction.includes(ACTIONS.ARCHIVED)}
                    disabled={fetched}
                    entityName={entityName}
                    onClickHandler={this.archived}
                />
                {source === SOURCE.MS365 && (
                    <Action
                        actionType={ACTIONS.LICENSED}
                        active={activeAction.includes(ACTIONS.LICENSED)}
                        disabled={fetched}
                        entityName={entityName}
                        onClickHandler={this.licensed}
                    />
                )}
            </StyledActiveBackup>
        )
    }
}

export const ActiveBackup = withRouter(ActiveBackupRaw)
