import React, {useCallback} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {ColumnsType} from 'antd/lib/table/Table'

import {SOURCE} from 'ca-common/common/enum'
import {siteFormatter} from 'ca-common/utils/entity'

import type {Entity} from 'src/newcore/features/OneClickActivation/types'

export const useGetColumns = () => {
    const {t} = useTranslation()
    return useCallback((service: SOURCE): ColumnsType<Entity> => {
        switch (service) {
            case SOURCE.MS365:
                return [
                    {
                        title: t('oneClickActivation:wizard:columns:mailboxName'),
                        dataIndex: 'mailBoxName',
                        key: 'mailBoxName',
                        ellipsis: true
                    },
                    {
                        title: t('oneClickActivation:wizard:columns:email'),
                        dataIndex: 'entity',
                        key: 'entity',
                        ellipsis: true
                    }
                ]
            case SOURCE.MS365GROUPS:
                return [
                    {
                        title: t('oneClickActivation:wizard:columns:name'),
                        dataIndex: 'entity',
                        key: 'entity',
                        ellipsis: true
                    },
                    {
                        title: t('oneClickActivation:wizard:columns:type'),
                        dataIndex: 'isTeam',
                        key: 'isTeam',
                        render: isTeam => t(`backups:ms365EntityType:${isTeam ? 'team' : 'group'}`)
                    }
                ]
            default:
                return [
                    {
                        title: t('oneClickActivation:wizard:columns:site'),
                        dataIndex: 'entity',
                        key: 'entity',
                        ellipsis: {showTitle: false},
                        render: site =>
                            site && (
                                <Tooltip title={site} mouseEnterDelay={0.7}>
                                    {siteFormatter(site)}
                                </Tooltip>
                            )
                    }
                ]
        }
    }, [])
}
