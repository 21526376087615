import _ from 'lodash'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import queryString from 'query-string'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {BillingHistory} from 'ca-common/components/Billing/BillingHistory'

import {getInvoiceHistory} from 'src/newcore/redux/modules/billing'
import {track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

import {getHistoryConfig, PAGE_SIZE} from './HistoryConfig'

class History extends Component {
    componentDidMount() {
        const {actions, history} = this.props
        const params = queryString.parse(history.location.search)

        const pickedParams = _.pickBy({...params, page: 'page' in params ? +params.page : 1}, _.identity)

        track(EVENT_NAMES.VIEW_BILLING_HISTORY, {})

        actions
            .getInvoiceHistory({
                page: +params.page || 1,
                limit: PAGE_SIZE
            })
            .then(() =>
                history.push(`${history.location.pathname}?${queryString.stringify(pickedParams, {sort: false})}`)
            )
    }

    render() {
        const {actions, invoiceHistory, paymentMethod} = this.props

        const subscriptionType = paymentMethod.status === 'fulfilled' ? paymentMethod.subscriptionType : null
        return (
            <div>
                {invoiceHistory.status === 'pending' && <Spinner modifier="page" />}
                {invoiceHistory.status === 'fulfilled' && (
                    <BillingHistory
                        config={getHistoryConfig(subscriptionType)}
                        invoiceHistory={invoiceHistory}
                        getInvoiceHistory={actions.getInvoiceHistory}
                        pageSize={PAGE_SIZE}
                    />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        paymentMethod: {
            status: state.billing.paymentMethod.status,
            ...state.billing.paymentMethod.response
        },
        invoiceHistory: {
            status: state.billing.invoiceHistory.status,
            ...state.billing.invoiceHistory.response
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    getInvoiceHistory
                },
                dispatch
            )
        }
    }
}

export const HistoryPage = connect(mapStateToProps, mapDispatchToProps)(History)
