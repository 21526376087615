import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledServiceCell = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
`

export const StyledServiceName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
