import React, {Fragment} from 'react'
import styled from 'styled-components'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import Info from 'ca-common/icons/Info.svg'

const StyledIcon = styled(Icon)`
    margin-right: 4px;
`

export const TotalColumnHeader = props => {
    const {children} = props

    const {t} = useTranslation()

    return (
        <Fragment>
            <Tooltip title={t('objectsList:totalTooltip')}>
                <StyledIcon component={Info} />
            </Tooltip>
            {children}
        </Fragment>
    )
}
