import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {closeWizard, nextStep, previousStep, wizardConfigSelector} from 'src/newcore/redux/modules/wizard'

export const useFooterProps = () => {
    const dispatch = useAppDispatch()
    const {beforeStepChange} = useAppSelector(wizardConfigSelector)

    const onClose = () => {
        dispatch(closeWizard())
    }

    const onNext = async () => {
        try {
            beforeStepChange && (await beforeStepChange())
            dispatch(nextStep())
        } catch (e) {
            return
        }
    }

    const onBack = async () => {
        try {
            beforeStepChange && (await beforeStepChange())
            dispatch(previousStep())
        } catch (e) {
            return
        }
    }

    return {onBack, onNext, onClose}
}
