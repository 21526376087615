import React from 'react'

import {SOURCE} from 'ca-common/common/enum'
import {SourceIcon} from 'ca-common/components/SourceIcon'
import {StyledServiceIcon} from 'src/newcore/features/OneClickActivation/atoms/ServiceIcon/StyledServiceIcon'

type ServiceIconProps = {
    source: SOURCE
}

export const ServiceIcon = ({source}: ServiceIconProps) => {
    return (
        <StyledServiceIcon>
            <SourceIcon source={source} fontSize={28} />
        </StyledServiceIcon>
    )
}
