import React from 'react'
import Icon from '@ant-design/icons'

import {SOURCE} from 'ca-common/common/enum/Source'

import YMail from 'ca-common/icons/source/yahoo.svg'
import IMAP from 'ca-common/icons/source/imap.svg'
import Google from 'ca-common/icons/source/Google.svg'
import GoogleAccount from 'ca-common/icons/source/GAccount.svg'
import Sharepoint from 'ca-common/icons/source/sharepoint.svg'
import OneDrive from 'ca-common/icons/source/onedrive.svg'
import MS365 from 'ca-common/icons/source/Exchange.svg'
import MS365Groups from 'ca-common/icons/source/officegroups.svg'
import Box from 'ca-common/icons/source/box.svg'
import Dropbox from 'ca-common/icons/source/dropbox.svg'
import SDB from 'ca-common/icons/source/simpledb.svg'
import DDB from 'ca-common/icons/source/dynamodb.svg'
import GDrive from 'ca-common/icons/source/GDrive.svg'
import SFC from 'ca-common/icons/source/salesforce.svg'

type SourceIconProps = {
    source: SOURCE
    fontSize?: number
    margin?: string
}

export const SourceIcon = ({source, fontSize = 36, margin = '0', ...rest}: SourceIconProps): JSX.Element => {
    return (
        <Icon
            style={{fontSize, margin}}
            {...rest}
            component={(() => {
                switch (source) {
                    case SOURCE.YMAIL:
                        return YMail
                    case SOURCE.IMAP:
                        return IMAP
                    case SOURCE.GOOGLEAPPS:
                        return Google
                    case SOURCE.GACCOUNTS:
                        return GoogleAccount
                    case SOURCE.SHAREPOINT:
                    case SOURCE.SHAREPOINT_MULTI:
                        return Sharepoint
                    case SOURCE.ONEDRIVE:
                        return OneDrive
                    case SOURCE.MS365:
                        return MS365
                    case SOURCE.MS365GROUPS:
                        return MS365Groups
                    case SOURCE.BOX:
                        return Box
                    case SOURCE.DROPBOX:
                        return Dropbox
                    case SOURCE.SDB:
                        return SDB
                    case SOURCE.DDB:
                        return DDB
                    case SOURCE.GDRIVETEAM:
                        return GDrive
                    case SOURCE.SFC:
                        return SFC
                    default:
                        return null
                }
            })()}
        />
    )
}
