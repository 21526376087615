import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import {PAYMENT_STATUS} from 'ca-common/constants'
import {CACellModifier} from 'ca-common/ui-lib/components/TableElements/index'
import StatusAttention from 'ca-common/icons/old/uEA31-status-attention.svg'

@withTranslation('table')
export class CABillingStatus extends React.Component {
    static propTypes = {
        item: PropTypes.shape({
            id: PropTypes.string,
            billingStatus: PropTypes.string
        }).isRequired
    }

    render() {
        const {t, item} = this.props
        const isBillingStatusOverdue = item.billingStatus === PAYMENT_STATUS.OVERDUE

        return (
            <CACellModifier modifier={isBillingStatusOverdue ? 'warning' : ''}>
                {isBillingStatusOverdue && <Icon componet={StatusAttention} />}
                {t(`table:billing:subscribeStatus:${_.camelCase(item.billingStatus)}`)}
            </CACellModifier>
        )
    }
}
