import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledIconWrapper = styled.span`
    margin-left: ${variables.gutterXs};
    font-size: ${variables.fontSizeBase};

    path {
        fill: ${variables.orange};
    }
`

export const StyledEmail = styled.span`
    font-weight: 400;
    text-decoration: underline;
`
