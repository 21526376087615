import styled from 'styled-components'

import variables from 'ca-common/variables'
import {HeadingTitle} from 'ca-common/ui-lib/atoms/Typography'

export const HeaderInformation = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-bottom: ${variables.gutterSm};
    @media screen and (min-width: ${variables.screenMd}) {
        flex-direction: row;
        justify-content: space-between;
    }
`
export const HeaderTitle = styled(HeadingTitle)`
    && {
        position: absolute;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);

        text-transform: capitalize;
        color: ${variables.navyBlue};
    }
`
export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 150px;
`
