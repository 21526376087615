import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledTags = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin: -4px;

    .ant-popover-open {
        background: ${variables.navyBlue};
        color: ${variables.white};
        border-color: ${variables.navyBlue};
    }
`
