import React from 'react'
import {ErrorIcon, StyledError} from './StyledInlineErrorMessage'

import CloseIcon from 'ca-common/icons/closeIcon.svg'

type InlineErrorType = {
    status: string
}

export const InlineErrorMessage = (error: InlineErrorType): JSX.Element => (
    <StyledError>
        <ErrorIcon component={CloseIcon} /> {error.status}
    </StyledError>
)
