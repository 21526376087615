import {useState} from 'react'
import {useInterval} from 'ca-common/utils/useInterval'

const SECONDS = 30
const _TICK = 1000

export const useTimer = (seconds: number = SECONDS) => {
    const [delay, setDelay] = useState(10000000)
    const [timer, setTimer] = useState(0)

    useInterval(() => {
        if (timer === 1) {
            setDelay(10000000)
        }
        setTimer(time => time - 1)
    }, delay)

    const runTimer = () => {
        setTimer(seconds)
        setDelay(_TICK)
    }

    return {
        timer,
        runTimer
    }
}
