import {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {zendeskChat} from 'src/newcore/redux/modules/support'
import {initZendesk} from 'ca-common/utils/zendesk'

const Zendesk = ({actions, companyInfo}) => {
    useEffect(() => {
        window.zESettings = {
            webWidget: {
                authenticate: {
                    chat: {
                        jwtFn: callback => {
                            actions.zendeskChat().then(response => {
                                const jwt = response.payload

                                callback(jwt)
                                const Pid = companyInfo?.response?.partnerId
                                if (Pid) {
                                    window.zE('webWidget', 'chat:addTags', [Pid])
                                }
                            })
                        }
                    }
                }
            }
        }

        initZendesk()
    }, [])

    return null
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo
})

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    zendeskChat
                },
                dispatch
            )
        }
    }
}

export const ZendeskChat = connect(mapStateToProps, mapDispatchToProps)(Zendesk)
