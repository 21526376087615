import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledSubtitle = styled.div`
    font-size: ${variables.headingFontSize};
    color: ${variables.text};
    font-weight: 400;
    line-height: 32px;
    border-bottom: 1px solid ${variables.navyBorder};
    padding-bottom: ${variables.gutterSm};
    margin-bottom: ${variables.gutterSm};
`
