import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Icon, {CloseOutlined, MenuOutlined} from '@ant-design/icons'

import variables from 'ca-common/variables'
import HeaderArrowLeft from 'ca-common/icons/HeaderArrowLeft.svg'
import Account from 'ca-common/icons/Account.svg'
import {CLOUDALLY_URL} from 'ca-common/constants'

import {
    LogoIcon,
    PublicHeaderLogo,
    PublicHeaderRightMenu,
    PublicHeaderWrapper,
    PublicHeaderButton,
    PublicHeaderBackButton,
    PublicHeaderButtonLink,
    MobileButton,
    MobileMenuWrapper,
    DesktopMenuWrapper,
    UnderLayer,
    MobileMenu,
    CALogo,
    StyledHeaderSeparator,
    StyledHeaderTitle
} from './StyledHeader'

export type PublicHeaderProps = {
    logoSrc?: string
    name?: string
    title: string
    isExternalUserManagement?: boolean
    showMenu?: boolean
    toCloudallyUrl?: false | string
} & PublicHeaderMenuProps

export const Header = ({
    logoSrc,
    name,
    isExternalUserManagement,
    showMenu = true,
    title,
    ...rest
}: PublicHeaderProps): JSX.Element => {
    const [isMenuVisible, setMenuVisibility] = useState(false)

    const url = isExternalUserManagement ? '' : '/'

    return (
        <PublicHeaderWrapper>
            <StyledHeaderTitle>
                <PublicHeaderLogo>
                    <a href={url}>{logoSrc ? <LogoIcon src={logoSrc} alt={name} /> : <CALogo alt="Cloudally" />}</a>
                </PublicHeaderLogo>
                <StyledHeaderSeparator />
                <p>{title}</p>
            </StyledHeaderTitle>
            {showMenu && (
                <>
                    <DesktopMenuWrapper>
                        <Menu {...rest} />
                    </DesktopMenuWrapper>
                    <MobileButton
                        icon={isMenuVisible ? <CloseOutlined /> : <MenuOutlined />}
                        onClick={() => setMenuVisibility(!isMenuVisible)}
                    />
                    <MobileMenu visible={isMenuVisible}>
                        <UnderLayer onClick={() => setMenuVisibility(false)} />
                        <MobileMenuWrapper>
                            <Menu {...rest} />
                        </MobileMenuWrapper>
                    </MobileMenu>
                </>
            )}
        </PublicHeaderWrapper>
    )
}

type PublicHeaderMenuProps = {
    showSignUp?: boolean
    toCloudallyUrl?: string
    isLogout?: boolean
}

const Menu = ({showSignUp, toCloudallyUrl, isLogout}: PublicHeaderMenuProps) => {
    const history = useHistory()
    const {t} = useTranslation()

    return (
        <>
            <PublicHeaderRightMenu>
                {toCloudallyUrl && (
                    <PublicHeaderButtonLink pureLink to={CLOUDALLY_URL}>
                        <PublicHeaderBackButton size="large" type="link">
                            <Icon component={HeaderArrowLeft} />
                            {t('header:menu:toCloudally')}
                        </PublicHeaderBackButton>
                    </PublicHeaderButtonLink>
                )}
                {!isLogout && showSignUp && (
                    <PublicHeaderButtonLink
                        to={{
                            pathname: '/sign-up',
                            search: history.location.search
                        }}
                    >
                        <PublicHeaderButton color={variables.brightOrange} size="large" type="primary">
                            {t('header:menu:freeTrial')}
                        </PublicHeaderButton>
                    </PublicHeaderButtonLink>
                )}
                {!isLogout && (
                    <PublicHeaderButtonLink
                        to={{
                            pathname: '/sign-in',
                            search: history.location.search
                        }}
                    >
                        <PublicHeaderButton color={variables.iceBlue} size="large" type="primary">
                            <Icon component={Account} />
                            {t('header:menu:login')}
                        </PublicHeaderButton>
                    </PublicHeaderButtonLink>
                )}
            </PublicHeaderRightMenu>
        </>
    )
}
