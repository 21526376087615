import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledTrialMessage = styled.div`
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
    color: ${variables.white};
`

export const StyledSubscribeButton = styled(Button)`
    color: ${variables.brandDark};
    font-family: ${variables.publicPageFont};
    font-weight: 400;

    span {
        text-decoration: underline;
    }

    @media (max-width: ${variables.screenMd}) {
        padding: ${variables.gutterXs};
    }
`
