import styled from 'styled-components'

import variables from 'ca-common/variables'

import {SourceIcon} from 'src/newcore/components/SourceIcon'

export const StyledSource = styled.div`
    display: flex;
    align-items: center;
`

export const StyledSourceIcon = styled(SourceIcon)`
    margin-right: ${variables.gutterXs};
`
