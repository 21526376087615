import React, {useState} from 'react'
import Icon from '@ant-design/icons'
import {Checkbox, Tooltip, Form, InputNumberProps} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {getSvcAccountsDescription} from 'ca-common/utils/text'

import {AccountDeactivationInputNumber, LabelPostfix} from './StyledAccountDeactivation'
import {SOURCE} from 'ca-common/common/enum'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

const DAYS_TO_ACCOUNT_DEACTIVATION = 99

type CustomInputProps = {
    isFinra?: boolean
    source: SOURCE
    value?: number
    onChange?: (value?: number | null) => void
    disabled?: boolean
}

const CustomInput = ({isFinra, source, value, disabled, onChange}: CustomInputProps) => {
    const {t} = useTranslation()
    const [checked, setChecked] = useState(() => !!value)

    const triggerChange = (changedValue?: number | null) => {
        if (onChange) {
            onChange(changedValue)
        }
    }

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked)

        triggerChange(e.target.checked ? DAYS_TO_ACCOUNT_DEACTIVATION : undefined)
    }

    const handleInputChange: InputNumberProps<number>['onChange'] = days => {
        triggerChange(days)
    }

    return (
        <>
            <Checkbox checked={checked} onChange={handleCheckboxChange} disabled={disabled}>
                {t('forms:backupSettings:fields:daysToAccountDeactivation:label:prefix')}
            </Checkbox>
            <AccountDeactivationInputNumber<(props: InputNumberProps<number>) => JSX.Element>
                size="small"
                min={1}
                value={value}
                onChange={handleInputChange}
                disabled={disabled || !checked}
            />
            <LabelPostfix disabled={disabled}>
                {t('forms:backupSettings:fields:daysToAccountDeactivation:label:postfix')}
            </LabelPostfix>

            <Tooltip
                title={
                    !isFinra &&
                    t('forms:backupSettings:fields:daysToAccountDeactivation:tooltip', [
                        getSvcAccountsDescription(source, true).toLowerCase()
                    ])
                }
            >
                <Icon component={Info} />
            </Tooltip>
        </>
    )
}

type AccountDeactivationProps = {
    daysToAccountDeactivation?: number
    source: SOURCE
    isFinra?: boolean
    namePrefix?: string
}

export const AccountDeactivation = (props: AccountDeactivationProps) => {
    const {t} = useTranslation()
    const {daysToAccountDeactivation, source, isFinra, namePrefix = ''} = props

    const name = `${namePrefix ? `${namePrefix}.` : ''}daysToAccountDeactivation`

    return (
        <Tooltip title={isFinra && t('finra:taskTooltip')}>
            <Form.Item name={name} initialValue={daysToAccountDeactivation}>
                <CustomInput disabled={isFinra} source={source} isFinra={isFinra} />
            </Form.Item>
        </Tooltip>
    )
}
