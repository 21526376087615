import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const GET_ACCOUNTS_WITH_GROUPS_API = 'getAccountsWithGroups'

const GET_ACCOUNTS_WITH_GROUPS = 'GET_ACCOUNTS_WITH_GROUPS'
const getAccountsWithGroupsWrapper = fetchWrapperRT<MS365AccountsWithGroupsResponse>(
    GET_ACCOUNTS_WITH_GROUPS,
    async data => await postLessData(GET_ACCOUNTS_WITH_GROUPS_API, data),
    {
        after: (response, state) => ({
            ...response,
            data: [..._.get(state, 'response.data', []), ...response.data]
        })
    }
)
export const getAccountsWithGroups = getAccountsWithGroupsWrapper.fetcher

type MS365AccountsWithGroupsResponse = {data: TODO_ANY[]}

const MS365AccountsWithGroupsSlice = createSlice({
    name: 'MS365AccountsWithGroups',
    initialState: InitFetchedState as FetchedState<MS365AccountsWithGroupsResponse>,
    reducers: {
        clearAccountsWithGroups: () => InitFetchedState as FetchedState<MS365AccountsWithGroupsResponse>
    },
    extraReducers: builder => getAccountsWithGroupsWrapper.makeReducers(builder)
})

export const {clearAccountsWithGroups} = MS365AccountsWithGroupsSlice.actions
export const MS365AccountsWithGroups = MS365AccountsWithGroupsSlice.reducer
