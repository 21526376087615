import React from 'react'
import {Switch} from 'react-router'
import {Route} from 'react-router-dom'

import {PermissionRoute} from 'ca-common/components/PermissionRoute'
import {PAGES} from 'ca-common/constants'
import {NotFound} from 'ca-common/components/NotFound'

import {BillingStatus} from 'src/newcore/features/BillingStatus'
import {BillingPage, HistoryPage, PaymentPage, AnnualSubscriptionPage} from 'src/newcore/components/Billing/Pages'

import {CABillingWrapper} from './BillingWrapper'

export const BillingRouting = (props: {match: Record<string, any>}) => {
    const {match} = props

    return (
        <Switch>
            <PermissionRoute
                checkPage={PAGES.STATUS}
                path={`${match.url}/${PAGES.STATUS}`}
                exact
                component={BillingStatus}
            />
            <CABillingWrapper>
                <Switch>
                    <Route path={`${match.url}/`} exact component={BillingPage} />
                    <PermissionRoute
                        checkPage={PAGES.HISTORY}
                        path={`${match.url}/${PAGES.HISTORY}`}
                        exact
                        component={HistoryPage}
                    />
                    <PermissionRoute
                        checkPage={PAGES.PAYMENTS_DETAILS}
                        path={`${match.url}/${PAGES.PAYMENTS_DETAILS}`}
                        exact
                        component={PaymentPage}
                    />
                    <PermissionRoute
                        checkPage={PAGES.ANNUAL_SUBSCRIPTION}
                        path={`${match.url}/${PAGES.ANNUAL_SUBSCRIPTION}`}
                        exact
                        component={AnnualSubscriptionPage}
                    />
                    <Route component={NotFound} />
                </Switch>
            </CABillingWrapper>
        </Switch>
    )
}
