import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    padding: 48px ${variables.gutterMd};
    border-bottom: 1px solid ${variables.blue};
`

export const StyledHeaderWithIcon = styled(StyledHeader)`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: ${variables.accentFont};

    i {
        font-size: 48px;
        color: ${variables.red};
        margin-right: 20px;
    }
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${variables.gutterSm};
    > * + * {
        margin-left: ${variables.gutterMd};
    }
`

export const StyledModal = styled.div`
    margin: -${variables.gutterMd};
    word-break: break-all;
`
