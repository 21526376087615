import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'
import {postLessData} from 'src/newcore/utils/rest'

const GOOGLE_BULK_ACTIVATE_ACCOUNTS = 'GOOGLE_BULK_ACTIVATE_ACCOUNTS'
const GOOGLE_BULK_ACTIVATE__ALLOWED_API = 'googleBulkActivateAllowed'
const googleBulkActivateAllowedWrapper = fetchWrapperRT(
    GOOGLE_BULK_ACTIVATE_ACCOUNTS,
    async (data, {rejectWithValue}) => {
        try {
            return await postLessData(GOOGLE_BULK_ACTIVATE__ALLOWED_API, data)
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const googleBulkActivateAllowed = googleBulkActivateAllowedWrapper.fetcher
type GoogleBulkAccountsAllowedResponse = {data: TODO_ANY[]}

const GoogleBulkAccountsAllowedDataSlice = createSlice({
    name: 'GoogleBulkAccountsAllowedData',
    initialState: InitFetchedState as FetchedState<GoogleBulkAccountsAllowedResponse>,
    reducers: {
        clearGoogleBulkAccountsAllowedData: () => InitFetchedState as FetchedState<GoogleBulkAccountsAllowedResponse>
    },
    extraReducers: builder => googleBulkActivateAllowedWrapper.makeReducers(builder)
})

export const {clearGoogleBulkAccountsAllowedData} = GoogleBulkAccountsAllowedDataSlice.actions
export const GoogleBulkAccountsAllowedData = GoogleBulkAccountsAllowedDataSlice.reducer
