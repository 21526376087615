import {useEffect, useState} from 'react'

import variables from 'ca-common/variables'

const BREAKPOINT = parseInt(variables.screenDesktop, 10)

export const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (getWidth() < BREAKPOINT) {
            setIsMobile(true)
        }
    }, [])
    useEffect(() => {
        window.addEventListener('resize', handleResizeWindow)
        return () => {
            window.removeEventListener('resize', handleResizeWindow)
        }
    })

    function handleResizeWindow() {
        if (!isMobile && getWidth() < BREAKPOINT) {
            setIsMobile(true)
        } else if (isMobile && getWidth() >= BREAKPOINT) {
            setIsMobile(false)
        }
    }

    function getWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
    return isMobile
}
