import {combineReducers} from '@reduxjs/toolkit'
import {initiators} from './jobInitiators'
import {tasks} from './jobTasks'
import {items} from './jobItems'
import {services} from './jobServices'
import {exportRestoreJobs} from './jobs'
import {dashboard} from './jobDashboard'
import {downloadInfo} from './jobDownload'

export const jobs = combineReducers({
    initiators,
    tasks,
    items,
    services,
    exportRestoreJobs,
    dashboard,
    downloadInfo
})

export * from './jobInitiators'
export * from './jobTasks'
export * from './jobItems'
export * from './jobServices'
export * from './jobs'
export * from './jobDashboard'
export * from './jobCanceling'
export * from './jobDownload'
