import styled from 'styled-components'

import {Collapse} from 'antd'

import variables from 'ca-common/variables'

const {Panel} = Collapse

export const StyledBackupSettings = styled.div`
    margin-bottom: ${variables.gutterMd};
`

export const StyledCollapse = styled(Collapse)`
    border-radius: ${variables.borderRadiusBase};

    .ant-collapse-header {
        min-height: 70px;
        max-height: 120px;
        display: flex;
        align-items: center;
    }

    .ant-collapse-content-box {
        padding: 0;
    }
`

export const StyledPanel = styled(Panel)`
    && {
        border: 0;
    }
`
