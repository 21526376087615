import styled from 'styled-components'
import {Empty} from 'antd'
import variables from 'src/ca-common/variables'

export const StyledEmpty = styled(Empty)`
    font-size: ${variables.mainTitleFontSize};
    line-height: 30px;
    color: ${variables.asphaltLight};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-empty-image {
        height: 140px;
    }
`
