import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {SourceIcon} from 'ca-common/components/SourceIcon'

import {PAGES, END_USER_STATUS} from 'src/ca-common/constants'
import {IconBorder} from 'src/newcore/features/Homepage/molecules/BackupTitle/StyledBackupTitle'
import {EntityRow} from 'src/newcore/features/Homepage/atoms/Row'
import {Recovery} from 'src/newcore/features/Homepage/molecules/actions'
import {TaskObj} from 'src/newcore/features/Homepage/types'
import {getServiceName} from 'src/newcore/utils/sources'
import {getServiceNameMixpanel, track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

import {StyledEndUserCard, Title, Subtitle, RecoveryWrapper, Note, StyledCardWrapper} from './StyledEndUserCard'

type EndUserCardProps = {
    task: TaskObj
    clickEndUserCard: (id?: string, recoveryEntityId?: string) => any
}

export const EndUserCard = (props: EndUserCardProps) => {
    const {task, clickEndUserCard} = props
    const {source, alias, lastBackup, isDropboxProfessionalAccount, id, recoveryEntityId, entityStatus} = task
    const friendlyName = getServiceName({source, isDropboxProfessionalAccount})
    const {t} = useTranslation()
    const history = useHistory()

    useEffect(() => {
        clickEndUserCard(id, recoveryEntityId)
    }, [])

    const openRecoveryPage = () => {
        track(EVENT_NAMES.VIEW_RECOVERY_PAGE, {
            Service: getServiceNameMixpanel(source),
            'Initiated From': 'Homepage Tile'
        })
        history.push(`/${PAGES.RECOVERY}/${task.id}/${task.recoveryEntityId}`)
    }

    return (
        <StyledEndUserCard
            onClick={() => {
                entityStatus === END_USER_STATUS.ACTIVE ? openRecoveryPage() : undefined
            }}
            isUserActive={entityStatus == END_USER_STATUS.ACTIVE}
        >
            <StyledCardWrapper blur={entityStatus !== END_USER_STATUS.ACTIVE}>
                <IconBorder>
                    <SourceIcon source={source} fontSize={76} />
                </IconBorder>
                <Title>{friendlyName}</Title>
                <Subtitle>{alias}</Subtitle>
            </StyledCardWrapper>
            {entityStatus === END_USER_STATUS.ACTIVE ? (
                <>
                    <EntityRow name={t(`homepage:rowName:lastBackup`)} value={lastBackup} />
                    <RecoveryWrapper>
                        <Recovery task={task} disabled={entityStatus !== END_USER_STATUS.ACTIVE} />
                    </RecoveryWrapper>
                </>
            ) : (
                <Note>
                    {entityStatus === END_USER_STATUS.NOT_ACTIVE ? (
                        <Trans i18nKey="homepage:rowName:noBackups"></Trans>
                    ) : (
                        <Trans i18nKey="homepage:rowName:backupInProgress"></Trans>
                    )}
                </Note>
            )}
        </StyledEndUserCard>
    )
}
