import React from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import Edit from 'ca-common/icons/old/uEA5E-action-edit.svg'
import Cancellation from 'ca-common/icons/old/uEA02-action-cancellation.svg'

import {INTEGRATION_TYPE, USER_ACCOUNT_TYPE} from 'ca-common/constants'
import {UserInfoDataType} from 'ca-common/types'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {makeYesNoModal, eatModal} from 'ca-common/redux/modules/modal'

import type {UserCredential, RemoveCredentialsFnType} from 'ca-common/features/UserManagementTable/types'
import {PARTNER_PREFIX} from 'ca-common/features/UserManagementTable//lib/constants'

import {StyledActionsCell, StyledIcon} from './StyledActionsCell'

export type ActionsCellProps = {
    item: UserCredential
    removeCredential: RemoveCredentialsFnType
    editPageUrl: string
    userInfo: UserInfoDataType
}

export const ActionsCell = (props: ActionsCellProps) => {
    const {item, userInfo, removeCredential, editPageUrl} = props
    const history = useHistory()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const redirectToEditPage = () => {
        dispatch(eatModal())
        history.push(editPageUrl)
    }

    const isPartnerTechnicalAccount = userInfo.partnerID && item.email === `${PARTNER_PREFIX}${userInfo.email}`

    const onEdit = () => {
        isPartnerTechnicalAccount
            ? dispatch(
                  makeYesNoModal({
                      children: t('userManagementToast:editConfirmation'),
                      title: t('modals:editModal:title'),
                      accept: redirectToEditPage
                  })
              )
            : redirectToEditPage()
    }

    const onRemoveCredential = () => {
        removeCredential(
            {
                email: item.email,
                type: item.type,
                id: item.id
            },
            () => {
                dispatch(eatModal())
                openSuccessNotification(t('userManagementToast:credentialRemoved', [item.email]))
            },
            err => openErrorNotification(`${t('userManagementToast:removeFailed')} ${err.status}`)
        )
    }

    const submitDelete = () => {
        dispatch(
            makeYesNoModal({
                children: isPartnerTechnicalAccount
                    ? t('userManagementToast:removeTechnicalConfirmation')
                    : t('userManagementToast:removeConfirmation'),
                title: t('modals:removeModal:title'),
                accept: onRemoveCredential
            })
        )
    }

    if (item.userAccountType === USER_ACCOUNT_TYPE.PRIMARY || item.isSpecialPartnerAccount) {
        return null
    }

    return (
        <StyledActionsCell>
            <StyledIcon data-action="edit" component={Edit} onClick={onEdit} />
            {userInfo?.integrationType !== INTEGRATION_TYPE.ZIX && (
                <StyledIcon data-action="remove" component={Cancellation} onClick={submitDelete} />
            )}
        </StyledActionsCell>
    )
}
