import styled, {css, keyframes} from 'styled-components'

import variables from 'ca-common/variables'

import type {SpinnerProps} from './types'

const spinnerRotation = keyframes`
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
`

export const SpinnerWrapper = styled.div<SpinnerProps>`
    position: relative;
    width: 100px;
    height: 100px;
    ${({modifier}) => {
        switch (modifier) {
            case 'large':
                return css`
                    width: 200px;
                    height: 200px;
                `
            case 'small':
                return css`
                    width: 50px;
                    height: 50px;
                `

            case 'modal':
                return css`
                    margin: 0 auto;
                `
            case 'page':
                return css`
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-top: -50px;
                    margin-left: -50px;
                `
            case 'table':
                return css`
                    position: relative;
                    left: 50%;
                    top: 0;
                    margin-top: 0;
                    margin-left: -50px;
                `
        }
    }}
`

export const SpinnerCircle = styled.div<{positionNumber: number}>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(${({positionNumber}) => positionNumber * 30}deg);

    &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: ${variables.navyBlue};
        border-radius: 100%;
        animation: ${spinnerRotation} 1.2s infinite ease-in-out both;
        animation-delay: -${({positionNumber}) => 1.2 - positionNumber / 10}s;
    }
`
