import React, {useEffect} from 'react'

import {ResetPasswordFailed} from 'ca-common/components/ResetPassword'
import {toggleRecoveryExpiredVisible} from 'ca-common/redux/modules/sign'

const signIn = {
    to: '/sign-in'
}

const resetPassword = {
    to: '/reset-password'
}

type PasswordTokenProps = {
    actions: {
        toggleRecoveryExpiredVisible: (value: boolean) => void
    }
}

export const PasswordTokenExpired = (props: PasswordTokenProps): JSX.Element => {
    useEffect(() => {
        return () => {
            props.actions.toggleRecoveryExpiredVisible(false)
        }
    }, [])

    return <ResetPasswordFailed signIn={signIn} resetPassword={resetPassword} />
}
