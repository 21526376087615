export enum SFC_DATA_TYPE {
    DATA = 'DATA',
    METADATA = 'METADATA'
}

export enum SNAPSHOT_TYPE {
    CURRENT = 'CURRENT',
    SALESFORCE = 'SALESFORCE',
    OTHER = 'OTHER',
    BACKUP = 'BACKUP'
}

export enum LIMITED_ACCESS_PAGES {
    TAG_DROPDOWN = 'TAG_DROPDOWN',
    JOB_LIST = 'JOB_LIST',
    EXCHANGE_RECOVERY = 'EXCHANGE_RECOVERY'
}
