import styled from 'styled-components'
import {
    asphalt,
    navyBlue,
    fontSizeBase,
    screenLg,
    screenXxl,
    gutterXs,
    gutterLg,
    gutterXxl,
    mainFont
} from 'ca-common/variables'

export const StyledSection = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    max-width: 200px;
    margin-right: ${gutterLg};
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: ${screenLg}) {
        margin-right: ${gutterXxl};
        max-width: 300px;
    }

    @media screen and (min-width: ${screenXxl}) {
        max-width: none;
    }
`

export const StyledTitle = styled.p`
    margin: 0 0 ${gutterXs};
    font-family: ${mainFont};
    font-size: ${fontSizeBase};
    white-space: nowrap;
    color: ${asphalt};
    font-weight: 600;
    max-width: inherit;
    overflow: inherit;
    text-overflow: inherit;
`

export const StyledText = styled.p`
    margin: 0;
    font-family: ${mainFont};
    font-size: ${fontSizeBase};
    color: ${navyBlue};
    max-width: inherit;
    overflow: inherit;
    text-overflow: inherit;
`
