export enum SELECTION {
    NONE = 'NONE',
    PARTIAL = 'PARTIAL',
    FULL = 'FULL'
}

export enum SELECTION_MENU_ACTION_NAME {
    SELECT_ALL = 'SELECT_ALL',
    CLEAR_SELECTED = 'CLEAR_SELECTED'
}

export enum INFO_TITLES {
    USERNAME = 'name',
    EMAIL = 'entity',
    LAST_SUCCESSFUL_BACKUP = 'lastBackup',
    TOTAL_SIZE = 'size',
    BACKUP_STATUS = 'status'
}

export enum MS365TASK_STATUS {
    COMPLETED = 'completed',
    DEFERRED = 'deferred',
    INPROGRESS = 'inprogress',
    NOTSTARTED = 'notstarted',
    WAITINGONOTHERS = 'waitingonothers'
}
