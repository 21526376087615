import React, {useMemo} from 'react'
import {Tooltip} from 'antd'
import {connect} from 'react-redux'

import {HotjarHidden} from 'ca-common/atoms/HotJarHidden'
import {checkPermission} from 'ca-common/utils/permissions'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import {PAGES} from 'ca-common/constants'
import {isEntityAvailableForSelectiveRecovery} from 'ca-common/utils/recovery'
import {checkLimitedAccess} from 'ca-common/utils/backup/limitedAccess'
import {LIMITED_ACCESS_PAGES} from 'ca-common/common/enum/Backups'

import {getServiceNameMixpanel, track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'

import {StyledName} from './StyledName'
import {AppState} from 'src/newcore/components/Root'
import {BackupEntity} from 'src/newcore/redux/modules/backups'

type NameProps = {
    name: string
    entity?: BackupEntity
} & ReturnType<typeof mapStateToProps>

export const NameRaw = ({name, userInfo, taskSettings, entity}: NameProps): JSX.Element => {
    if (userInfo.status !== 'fulfilled' || taskSettings.status !== 'fulfilled') {
        return <></>
    }
    const hasPermissions = useMemo(() => {
        if (entity && entity.limitedAccess) {
            return !checkLimitedAccess(LIMITED_ACCESS_PAGES.EXCHANGE_RECOVERY, entity)
        }
        return (
            checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response?.permissions) ||
            checkPermission('BACKUP.MS365.RESTORE', userInfo.response?.permissions)
        )
    }, [userInfo.response?.permissions, entity?.limitedAccess])

    const onClick = () => {
        track(EVENT_NAMES.VIEW_RECOVERY_PAGE, {
            Service: getServiceNameMixpanel(taskSettings.response.source),
            'Initiated From': 'Backup Page Entity Link'
        })
    }

    return (
        <Tooltip placement="topLeft" title={<HotjarHidden>{name}</HotjarHidden>} mouseEnterDelay={0.4}>
            {hasPermissions && isEntityAvailableForSelectiveRecovery(taskSettings.response, entity) ? (
                <CALink to={`/${PAGES.RECOVERY}/${taskSettings.response.taskId}/${entity?.id}`} onClick={onClick}>
                    <StyledName>{name}</StyledName>
                </CALink>
            ) : (
                <span>{name}</span>
            )}
        </Tooltip>
    )
}

const mapStateToProps = ({userInfo, backups}: AppState) => ({
    userInfo: userInfo,
    taskSettings: backups.settings
})

export const Name = connect(mapStateToProps)(NameRaw)
