import React, {useEffect, useState} from 'react'
import type {TreeProps} from 'antd/es/tree'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import {useTranslation} from 'react-i18next'
import {openErrorModal} from 'ca-common/utils/modals'
import {CancelLink, LinkWrapper, ModalCenterContentWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'
import {DEFAULT_PAGE, ROWS_BULK_GROUPS} from 'src/ca-common/constants'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import {
    getGoogleOU,
    appendGoogleOU,
    GoogleOUListResponse
} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/GoogleOUList'
import {setSelectedOU, clearSelectedOU} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/SelectedOU'
import {GroupListWrapper} from 'src/newcore/components/BulkActivation/StyledBulkActivation'
import {clearWizard, nextStep, setWizardDescription, previousStep} from 'src/newcore/redux/modules/wizard'
import {InfiniteContainer} from './StyledGoogleOUList'
import {GoogleOUForm} from './GoogleOUForm'
import {NestedCheckbox} from './NestedCheckbox'
import {GoogleAccountsWithOUResponse} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/GoogleAccountsWithOU'
import {setBlockedOU} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/BlockedOU'

export const GoogleOUList = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [searchQuery, setsearchQuery] = useState('')
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)
    const selectedOus = useAppSelector((state: AppState) => state.backups.bulkActivation.GoogleOUs.selectedOU)
    const blockedOus = useAppSelector((state: AppState) => state.backups.bulkActivation.GoogleOUs.blockedOU)

    const removedBlockedOusFromSelectedOus = () => {
        if (hasBlockedOus()) {
            const selectedOusByBlockedOus: any[] = []
            const updatedBlockedOus: any[] = []
            blockedOus.map(blockedOu => {
                updatedBlockedOus.push(blockedOu)
            })
            bulkAccountsData?.blockedOUs?.map((blockedOu: any) => {
                updatedBlockedOus.push(blockedOu)
            })
            dispatch(setBlockedOU(updatedBlockedOus))
            selectedOus.map(ou => {
                const isBlockedOu = bulkAccountsData?.blockedOUs?.find((blockedOus: any) => blockedOus.id == ou.id)
                if (!isBlockedOu) {
                    selectedOusByBlockedOus.push(ou)
                }
            })
            dispatch(setSelectedOU(selectedOusByBlockedOus))
        }
    }

    useEffect(() => {
        dispatch(getGoogleOU({taskId: taskSettings?.taskId, page: DEFAULT_PAGE, rows: ROWS_BULK_GROUPS}))
            .unwrap()
            .catch((err: any) => {
                openErrorModal(err.status)
            })
        dispatch(setWizardDescription(t('backups:GOOGLEOU:description')))
        removedBlockedOusFromSelectedOus()
    }, [])
    const {bulkAccountsData} = useAppSelector((state: AppState) => ({
        bulkAccountsData: state.backups.bulkActivation.GoogleOUs.GoogleAccountsWithOU
            ?.response as GoogleAccountsWithOUResponse
    }))
    const {GoogleOUListStatus, GoogleOUListData} = useAppSelector((state: AppState) => ({
        GoogleOUListStatus: state.backups.bulkActivation.GoogleOUs.GoogleOUList.status,
        GoogleOUListData: state.backups.bulkActivation.GoogleOUs.GoogleOUList?.response as GoogleOUListResponse
    }))
    const hasBlockedOus = () => {
        if (bulkAccountsData?.hasBlockedOUs || blockedOus.length > 0) {
            return true
        }
        return false
    }
    const handleScroll = () => {
        if (GoogleOUListStatus === 'fulfilled' && GoogleOUListData?.page < GoogleOUListData?.totalPages) {
            dispatch(
                appendGoogleOU({
                    taskId: taskSettings?.taskId,
                    page: GoogleOUListData?.page + 1,
                    rows: ROWS_BULK_GROUPS,
                    searchQuery
                })
            )
        }
    }
    const handleFilterSubmit = (values: any) => {
        setsearchQuery(values.query)
        dispatch(
            getGoogleOU({
                taskId: taskSettings?.taskId,
                page: DEFAULT_PAGE,
                rows: ROWS_BULK_GROUPS,
                ...values
            })
        )
    }

    const handleCancelClick = () => {
        dispatch(clearWizard())
    }

    const handleNextClick = () => {
        dispatch(nextStep())
    }

    const onCheck: TreeProps['onCheck'] = (checkedKeys, nodeInfo) => {
        if (nodeInfo.checkedNodes.length != 0) {
            const selectedOuParent: any[] = []
            nodeInfo.checkedNodes.forEach(ou => {
                const isOuSelected = selectedOuParent.find(selectedOu => selectedOu.id == ou.key)
                isOuSelected
                    ? selectedOuParent.filter(value => {
                          value.id != ou.key
                      })
                    : selectedOuParent.push({id: ou.key, name: ou.title})
            })
            dispatch(setSelectedOU(selectedOuParent.flat()))
        } else {
            dispatch(clearSelectedOU())
        }
    }
    const checkedSelectedOus = () => {
        const checkedKeys: any[] = []
        selectedOus.map(ou => {
            if (bulkAccountsData?.hasBlockedOUs) {
                const isBlockedOu = bulkAccountsData?.blockedOUs?.find((blockedOus: any) => blockedOus.id == ou.id)
                if (!isBlockedOu) {
                    checkedKeys.push(ou.id)
                }
            } else {
                checkedKeys.push(ou.id)
            }
        })
        return checkedKeys
    }

    const [checkedSelectedOusList, setcheckedSelectedOusList] = useState(checkedSelectedOus())

    return (
        <>
            <GroupListWrapper>
                <ModalCenterContentWrapper>
                    <Spin spinning={GoogleOUListStatus === 'pending'}>
                        <GoogleOUForm handleFilterSubmit={handleFilterSubmit} />
                        <InfiniteContainer data-hj-suppress>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={DEFAULT_PAGE}
                                useWindow={false}
                                hasMore={GoogleOUListData?.page < GoogleOUListData?.totalPages}
                                loadMore={handleScroll}
                            >
                                <NestedCheckbox
                                    ousList={GoogleOUListData?.data}
                                    onCheck={onCheck}
                                    defaultCheckedKeysList={checkedSelectedOusList}
                                    blockedOusAccount={hasBlockedOus() ? blockedOus : []}
                                ></NestedCheckbox>
                            </InfiniteScroll>
                        </InfiniteContainer>
                    </Spin>
                </ModalCenterContentWrapper>
            </GroupListWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancelClick}> {t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={handleNextClick} disabled={selectedOus.length == 0}>
                        {t('forms:common:actions:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => dispatch(previousStep())} isHighlighted={true} />
            </ModalFooter>
        </>
    )
}
