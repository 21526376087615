import React, {Component} from 'react'
import tx from 'transform-props-with'

import {dumbFormGroup, Error} from '../FormGroup'
import 'ca-common/ui-lib/components/RadioButton/RadioButton.scss'

const RadioLabel = tx([{element: 'radio'}, dumbFormGroup])('label')
const RadioWrapper = tx([{element: 'radio-wrapper'}, dumbFormGroup])('div')

export class RadioButton extends Component {
    render = () => {
        const {
            input,
            htmlFor,
            option,
            meta: {touched, error, warning},
            ...rest
        } = this.props

        return (
            <RadioWrapper key={input.index}>
                <input type="radio" value={option.value} id={htmlFor} {...input} {...rest} />
                <RadioLabel htmlFor={htmlFor}>{option.label || ''}</RadioLabel>
                {touched && ((error && <Error>{error}</Error>) || (warning && <span>{warning}</span>))}
            </RadioWrapper>
        )
    }
}
