import {i18n} from 'src/locales'
import {TASK_TYPE} from 'ca-common/common/enum/TaskType'
import {JOBS_STATUSES} from 'ca-common/common/enum'

import {SORT_BY, JOB_STATUS_OPTIONS, JOBS_DASHBOARD_STATUSES} from './constants'

const sortByFriendly: Record<string, string> = {
    [SORT_BY.LATEST_FIRST]: i18n.t('jobs:sortBy:latest'),
    [SORT_BY.OLDER_FIRTS]: i18n.t('jobs:sortBy:oldest')
}

export const getSortByFriendly = (sorter: string): string => {
    return sortByFriendly[sorter] || ''
}

const jobStatusFriendly: Record<JOB_STATUS_OPTIONS, string> = {
    [JOBS_STATUSES.IN_PROCESS]: i18n.t('jobs:jobStatus:inProcess'),
    [JOBS_STATUSES.SUCCEEDED]: i18n.t('jobs:jobStatus:succeed'),
    [JOBS_STATUSES.PARTIALLY_SUCCEEDED]: i18n.t('jobs:jobStatus:partiallySucceed'),
    [JOBS_STATUSES.FAILED]: i18n.t('jobs:jobStatus:failed'),
    [JOBS_STATUSES.PENDING]: i18n.t('jobs:jobStatus:pending'),
    [JOBS_STATUSES.CANCELLED]: i18n.t('jobs:jobStatus:cancelled')
}

export const getJobStatusFriendly = (jobStatus: JOB_STATUS_OPTIONS): string => {
    return jobStatusFriendly[jobStatus] || ''
}

const activityFriendly: Record<TASK_TYPE, string> = {
    [TASK_TYPE.RESTORE]: i18n.t('jobs:activity:restore'),
    [TASK_TYPE.EXPORT]: i18n.t('jobs:activity:export')
}

export const getActivityFriendly = (activity: TASK_TYPE): string => {
    return activityFriendly[activity] || ''
}

const dashboardStatusFriendly: Record<JOBS_DASHBOARD_STATUSES, string> = {
    [JOBS_STATUSES.IN_PROCESS]: i18n.t('JobsPage:dashboard:inProcess'),
    [JOBS_STATUSES.SUCCEEDED]: i18n.t('JobsPage:dashboard:succeed'),
    [JOBS_STATUSES.FAILED]: i18n.t('JobsPage:dashboard:failed')
}

export const getDashboardStatusFriendly = (jobStatus: JOBS_DASHBOARD_STATUSES): string => {
    return dashboardStatusFriendly[jobStatus] || ''
}
