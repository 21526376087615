import i18n from 'i18next'
import {USER_MANAGEMENT_GROUPS, PERMISSION_GROUPS} from 'ca-common/constants'
import {isNotCloudAllyExternalOrOkta} from 'ca-common/utils/billing'
import type {CompanyInfoType, UserInfoDataType} from 'ca-common/types'

import {getServiceName} from 'src/newcore/utils/sources'

export const getGeneralList = (companyInfo: CompanyInfoType, userInfo: UserInfoDataType) => ({
    name: 'all',
    label: USER_MANAGEMENT_GROUPS.GENERAL,
    frendlyName: i18n.t('userManagement:permissions:general'),
    sub: [
        {
            name: 'ACCOUNT',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:manageUserInfo'),
            infoTooltip: i18n.t('userManagement:permissions:manageUserInfoTooltip')
        },
        isNotCloudAllyExternalOrOkta(companyInfo, userInfo) && {
            name: 'PAYMENTS',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:manage_payments'),
            infoTooltip: i18n.t('userManagement:permissions:managePaymentsTooltip')
        },
        isNotCloudAllyExternalOrOkta(companyInfo, userInfo) && {
            name: 'BILLING_STATE',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:billingNotifications'),
            infoTooltip: i18n.t('userManagement:permissions:billingNotificationsTooltip')
        },
        isNotCloudAllyExternalOrOkta(companyInfo, userInfo) && {
            name: 'SUPPORT',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:view_support'),
            infoTooltip: i18n.t('userManagement:permissions:viewSupportTooltip')
        },
        {
            name: 'ACCOUNT_ACTIVITY',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:view_accounts_activity'),
            infoTooltip: i18n.t('userManagement:permissions:viewAccountActivityTooltip')
        },
        {
            name: 'ACCOUNT_SECURITY_AUDIT',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:view_security_audit_page')
        },
        {
            name: 'MANAGE_NOTIFICATIONS',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:manage_notifications')
        },
        {
            name: 'BACKUPS',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:view_backup'),
            infoTooltip: i18n.t('userManagement:permissions:viewBackupTooltip')
        }
    ].filter(Boolean)
})

export const getTasksList = () => ({
    name: 'all',
    label: USER_MANAGEMENT_GROUPS.TASKS,
    frendlyName: i18n.t('userManagement:permissions:tasks'),
    sub: [
        {
            name: 'BACKUPS',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACTIVATE'},
            frendlyName: i18n.t('userManagement:permissions:activate_backup'),
            infoTooltip: i18n.t('userManagement:permissions:activateBackupTooltip')
        },
        {
            name: 'EDIT',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:edit_backup'),
            infoTooltip: i18n.t('userManagement:permissions:editBackupTooltip')
        },
        {
            name: 'DELETE',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:delete_backup'),
            infoTooltip: i18n.t('userManagement:permissions:deleteBackupTooltip')
        },
        {
            name: 'PAUSE',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:pause_backup')
        },
        {
            name: 'EXECUTE',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:execute_backup')
        },
        {
            name: 'CANCEL',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:cancel_backup')
        },
        {
            name: 'RESTORE_AND_DOWNLOAD',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:exploreBackups')
        },
        {
            name: 'PREVIEW',
            permissionGroup: {type: PERMISSION_GROUPS.PAGES, action: 'ACCESS'},
            frendlyName: i18n.t('userManagement:permissions:previewBackups')
        },
        {
            name: 'RESTORE',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:restoreBackups')
        },
        {
            name: 'DOWNLOAD',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:exportBackups')
        },
        {
            name: 'DELETEITEMS',
            permissionGroup: {type: PERMISSION_GROUPS.BACKUP},
            frendlyName: i18n.t('userManagement:permissions:delete_items')
        }
    ]
})

export const SERVICES_LIST = {
    name: 'all',
    label: USER_MANAGEMENT_GROUPS.SERVICES,
    frendlyName: i18n.t('serviceNames:all'),
    sub: [
        {name: 'MS365', frendlyName: getServiceName({source: 'MS365'})},
        {name: 'MS365GROUPS', frendlyName: getServiceName({source: 'MS365GROUPS'})},
        {name: 'SHAREPOINT_MULTI', frendlyName: getServiceName({source: 'SHAREPOINT_MULTI'})},
        {name: 'ONEDRIVE', frendlyName: getServiceName({source: 'ONEDRIVE'})},
        {name: 'GACCOUNTS', frendlyName: getServiceName({source: 'GACCOUNTS'})},
        {name: 'SFC', frendlyName: getServiceName({source: 'SFC'})},
        {name: 'DROPBOX', frendlyName: getServiceName({source: 'DROPBOX'})},
        {name: 'BOX', frendlyName: getServiceName({source: 'BOX'})},
        {name: 'GOOGLEAPPS', frendlyName: getServiceName({source: 'GOOGLEAPPS'})},
        {name: 'GDRIVETEAM', frendlyName: getServiceName({source: 'GDRIVETEAM'})},
        {name: 'IMAP', frendlyName: getServiceName({source: 'IMAP'})},
        {name: 'YMAIL', frendlyName: getServiceName({source: 'YMAIL'})},
        {name: 'DDB', frendlyName: getServiceName({source: 'DDB'})},
        {name: 'SDB', frendlyName: getServiceName({source: 'SDB'})}
    ]
}
