import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Calendar from 'ca-common/icons/subServices/Calendar.svg'
import Contacts from 'ca-common/icons/subServices/Contacts.svg'
import Tasks from 'ca-common/icons/subServices/Tasks.svg'
import Mail from 'ca-common/icons/subServices/Emails.svg'
import Notes from 'ca-common/icons/subServices/Notes.svg'

import {ACTIVITY_STATUS, getActivityStatusFriendly, SOURCE} from 'ca-common/common/enum'

import {getDisplayName} from 'src/newcore/utils/sources'

import {IconsWrapper, StyledIcon} from './StyledSubsourceIcon'

type SubsourceIconProps = {
    subsources: {
        dateTime: number
        status: ACTIVITY_STATUS
        source: SOURCE
    }[]
}

export const SubsourceIcons = ({subsources = []}: SubsourceIconProps): JSX.Element => {
    const {t} = useTranslation()
    const getSubsource = (subsource: SOURCE) => {
        switch (subsource) {
            case SOURCE.O365CALENDAR:
                return Calendar
            case SOURCE.O365CONTACTS:
                return Contacts
            case SOURCE.O365MAIL:
                return Mail
            case SOURCE.O365NOTES:
                return Notes
            case SOURCE.O365TASKS:
                return Tasks
        }
    }
    return (
        <IconsWrapper>
            {subsources.map(({source, dateTime, status}) => (
                <Tooltip
                    key={dateTime}
                    title={t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:subsourceIconTooltip', {
                        subsource: getDisplayName(source),
                        status: getActivityStatusFriendly(status)
                    })}
                >
                    <StyledIcon status={status} key={dateTime} component={getSubsource(source)} />
                </Tooltip>
            ))}
        </IconsWrapper>
    )
}
