import styled from 'styled-components'

import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledColumnTitle = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    justify-content: center;
`

export const StyledIcon = styled(Icon)`
    font-size: 18px;
    color: ${variables.old.duskyBlue};
`
