import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledCustomers, Header, CustomersWrapper} from './StyledCustomers'
import {ClientLogo} from 'ca-common/atoms/ClientLogo'
import {CLIENT_LOGO} from 'ca-common/constants'

export const Customers = () => {
    const {t} = useTranslation()

    return (
        <StyledCustomers>
            <Header>{t('sign:up:customersTitle')}</Header>
            <CustomersWrapper>
                {Object.values(CLIENT_LOGO).map(client => (
                    <ClientLogo key={client} client={client} />
                ))}
            </CustomersWrapper>
        </StyledCustomers>
    )
}
