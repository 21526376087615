import styled from 'styled-components'
import {Form, Input} from 'antd'

import variables from 'ca-common/variables'

export const StyledBackupName = styled(Form.Item)`
    display: block;
    label {
        padding-top: 13px;
    }
`

export const StyledInput = styled(Input)`
    margin-top: ${variables.gutterXs};
`
