import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    line-height: ${variables.gutterMd};
    padding: ${variables.gutterMd} ${variables.gutterMd};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const HeaderTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`

export const ObjectCounts = styled.span`
    color: ${variables.navyBlue};
`

export const HeaderRightText = styled.div`
    text-align: right;
    color: ${variables.navyBlue};
    font-weight: 600;
`
