import {isExternal} from 'src/newcore/utils/companyInfo'
import {isPrimary} from 'src/newcore/components/Settings/Utils'
import {isPagePermitted} from 'src/newcore/utils/permissions'
import {INTEGRATION_TYPE} from 'ca-common/constants'

import Settings from 'ca-common/icons/old/uEA1D-homescreen-settings.svg'
import ManagementSettings from 'ca-common/icons/old/uEA19-homescreen-management-settings.svg'
import Security from 'ca-common/icons/old/uEA1C-homescreen-security.svg'
import NotificationSettings from 'ca-common/icons/old/uEA1A-homescreen-notification-settings.svg'
import {getUserInfo} from 'src/newcore/utils/store'

type PAGE = {
    name: string
    icon: SVGAElement
    allow?: boolean
}

export const getPages = (): PAGE[] => {
    const userInfo = getUserInfo()

    if (userInfo.status !== 'fulfilled') {
        return []
    }

    const {userAccountType, permissions, isSpecialPartnerAccount, integrationType} = userInfo.response
    const isZix = integrationType === INTEGRATION_TYPE.ZIX

    return [
        {
            name: 'account',
            icon: Settings,
            allow: (isPrimary(userAccountType) || isPagePermitted('ACCOUNT.ACCESS', permissions)) && !isZix
        },
        {
            name: 'users',
            icon: ManagementSettings,
            allow:
                (isPrimary(userAccountType) || isPagePermitted('USERS.ACCESS', permissions)) && (isZix || !isExternal())
        },
        {
            name: 'security',
            icon: Security,
            allow: !isExternal() && !isSpecialPartnerAccount && !isZix
        },
        {
            name: 'notifications',
            icon: NotificationSettings,
            allow: isPrimary(userAccountType) || isPagePermitted('MANAGE_NOTIFICATIONS.ACCESS', permissions)
        }
    ]
}
