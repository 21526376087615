import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {omit} from 'lodash'

import {createExportActivity, getActivity} from 'src/newcore/features/ActivityTable/redux'
import {openErrorModal} from 'ca-common/utils/modals'
import {timeZone} from 'ca-common/utils/datetime'
import {ActivityTable} from 'src/newcore/features/ActivityTable'
import {INITIAL_PAGE, PAGE_SIZE} from 'ca-common/features/ActivityInfinityTable'
import {ACTIVITY_TYPE} from 'ca-common/common/enum/AccountActivity'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {FieldValues} from 'src/newcore/features/ActivityTable/types/fieldValues'
import {AppState, useAppDispatch} from 'src/newcore/components/Root'
import {TODO_ANY} from 'ca-common/types'

type PreparedQueryParams = {
    from?: string
    to?: string
    sources?: Record<string, string[]>
}

const ActivityRaw = ({activity}: ReturnType<typeof mapStateToProps>) => {
    const {t} = useTranslation()
    const [page, setPage] = useState(INITIAL_PAGE)
    const [isLastPage, setIsLastPage] = useState(false)
    const dispatch = useAppDispatch()

    const prepareQueryParams = (params: FieldValues) => {
        const {dateRange, services, entities} = params
        let result: Omit<FieldValues, 'dateRange' | 'services'> & PreparedQueryParams = {
            ...omit(params, ['dateRange', 'services'])
        }

        if (dateRange?.length) {
            result = {
                ...result,
                from: dateRange[0],
                to: dateRange[1]
            }
        }

        if (services && services.length) {
            const sources = {} as Record<string, string[]>
            services.forEach(service => {
                if (service.includes('__')) {
                    const [source, subSource] = service.split('__')
                    if (!Object.prototype.hasOwnProperty.call(sources, source)) {
                        sources[source] = []
                    }
                    sources[source].push(subSource)
                } else {
                    sources[service] = []
                }
            })
            result = {...result, sources}
        }

        if (entities) {
            result = {...result, entities: [entities] as string[]}
        }

        return result
    }

    const fetchActivity = (queryParams: FieldValues, newPage?: number) => {
        if (newPage) {
            setPage(newPage)
            setIsLastPage(false)
        }
        return dispatch(
            getActivity({
                rows: PAGE_SIZE,
                page: newPage || page,
                timeZone,
                ...prepareQueryParams(queryParams)
            })
        )
            .then((response: TODO_ANY) => {
                if (response.payload?.data?.length > 0) {
                    setPage(prevState => ++prevState)
                }
                if (response.payload?.data?.length < PAGE_SIZE) {
                    setIsLastPage(true)
                }
            })
            .catch((err: {status: string}) => {
                if (err.status) {
                    openErrorModal(err.status)
                }
            })
    }

    const submitExport = (queryParams: FieldValues) => () => {
        const data = {
            filter: prepareQueryParams(queryParams),
            activityType: ACTIVITY_TYPE.SYSTEM_ACTIVITY,
            timeZone
        }

        dispatch(createExportActivity(data))
            .then(() => {
                openSuccessNotification(
                    t(`accountActivity:exportActivatedSuccessfully:${ACTIVITY_TYPE.SYSTEM_ACTIVITY}`)
                )
            })
            .catch((response: {message: string}) => {
                openErrorNotification(response.message)
            })
    }

    return (
        <ActivityTable
            submitExport={submitExport}
            activity={activity}
            isLastPage={isLastPage}
            fetchActivity={fetchActivity}
        />
    )
}

const mapStateToProps = (state: AppState) => ({
    activity: state.activity.accountActivity
})

export const Activity = connect(mapStateToProps)(ActivityRaw)
