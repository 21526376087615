import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form} from 'antd'
import {Button} from 'ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalContentWrapper, ModalFooter} from 'ca-common/components/Modal'

import {changeData, clearWizard, nextStep, previousStep, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import type {WizardData} from 'src/newcore/components/BulkActivation/SharepointUrl/types'
import {Condition} from 'src/newcore/components/BulkActivation/SharepointUrl/ConditionsList'

export const ConditionsList = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const wizardData = useAppSelector(wizardDataSelector) as WizardData

    const initialValues = {urlConditions: wizardData?.urlConditions || [{}]}

    const handleNextClick = () => {
        form.submit()
    }

    const handleCancelClick = () => {
        dispatch(clearWizard())
    }
    const onFinish = (values: any) => {
        if (JSON.stringify(values.urlConditions) !== JSON.stringify(wizardData?.urlConditions || [{}])) {
            dispatch(changeData({excludedUrls: {}}))
            dispatch(changeData(values))
        }

        dispatch(nextStep())
    }

    return (
        <>
            <ModalContentWrapper>
                <Form
                    initialValues={initialValues}
                    form={form}
                    name="sharepoint-conditions"
                    onFinish={onFinish}
                    style={{maxWidth: 600}}
                >
                    <Form.List name="urlConditions">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(field => (
                                    <Condition
                                        key={field.key}
                                        fields={fields}
                                        field={field}
                                        add={add}
                                        remove={remove}
                                        form={form}
                                    />
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>
            </ModalContentWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancelClick}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={handleNextClick}>
                        {t('forms:common:actions:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => dispatch(previousStep())} />
            </ModalFooter>
        </>
    )
}
