import {MS365SubSource} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum'
import {SelectiveRecoveryState, SubsourceState} from 'src/newcore/features/Recovery/localState'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import {INITIAL_SUBSERVICES_STATE} from 'src/newcore/features/RecoveryWizard/lib/constants'

export type SubsourcesBooleanState = {[key in MS365SubSource]?: boolean}

export type SelectedSubsourses = {
    [key in MS365SubSource]?: SubsourceState
} & {
    subSourcesState: SubsourcesBooleanState
    allEmails: boolean
    size: number
}

export const getSelectedSubsources = (selectiveRecovery?: SelectiveRecoveryState): SelectedSubsourses => {
    const result: SelectedSubsourses = {
        size: 0,
        allEmails: false,
        subSourcesState: {}
    }
    if (!selectiveRecovery) return result

    INITIAL_SUBSERVICES_STATE.forEach(subsource => {
        if (selectiveRecovery[subsource].checkAll !== CHECK_STATE.UNCHECKED) {
            result.size++
            result.subSourcesState[subsource] = true
            result[subsource] = selectiveRecovery[subsource]
        }
    })

    result.allEmails = selectiveRecovery[SOURCE.O365MAIL].checkAll === CHECK_STATE.CHECKED
    return result
}
