import {useCallback, useEffect, useRef} from 'react'
import _ from 'lodash'

import {SOURCE} from 'src/ca-common/common/enum'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {changeConfig, changeData, wizardConfigSelector} from 'src/newcore/redux/modules/wizard'

import type {RecentlyDeletedTask} from 'src/newcore/features/OneClickActivation/types'
import {EXTRA_DISABLED_STEPS_FOR_FULLY_REINSTATE, STEPS} from './constants'

type BeforeStepChangeFnType = () => void

export const useBeforeRecentlyDeletedTasksStepChange = (
    formData: Record<SOURCE, boolean>,
    availableSources: SOURCE[],
    recentlyDeletedTasks: RecentlyDeletedTask[]
) => {
    const dispatch = useAppDispatch()
    const wizardConfig = useAppSelector(wizardConfigSelector)

    const getBeforeStepChange = useCallback(
        (formData: Record<SOURCE, boolean>) => () => {
            const tasksForReinstate = Object.keys(formData).reduce((acc, source) => {
                if (formData[source as SOURCE]) {
                    const task = recentlyDeletedTasks.find(task => task.source === (source as SOURCE))
                    task && acc.push(task)
                }

                return acc
            }, [] as RecentlyDeletedTask[])

            dispatch(changeData({tasksForReinstate}))

            const newDisabledSteps =
                availableSources.length === tasksForReinstate.length
                    ? _.union(wizardConfig.disabledSteps, EXTRA_DISABLED_STEPS_FOR_FULLY_REINSTATE)
                    : wizardConfig.disabledSteps.filter(
                          step => !EXTRA_DISABLED_STEPS_FOR_FULLY_REINSTATE.includes(step as STEPS)
                      )

            dispatch(
                changeConfig({
                    disabledSteps: newDisabledSteps
                })
            )
        },
        [availableSources, wizardConfig.disabledSteps, recentlyDeletedTasks]
    )

    const beforeStepChangeRef = useRef<null | BeforeStepChangeFnType>(getBeforeStepChange(formData))

    useEffect(() => {
        dispatch(
            changeConfig({
                beforeStepChange: () => beforeStepChangeRef.current && beforeStepChangeRef.current()
            })
        )
    }, [])

    const reinitBeforeStepChange = (formData: Record<SOURCE, boolean>) => {
        beforeStepChangeRef.current = getBeforeStepChange(formData)
    }

    return {reinitBeforeStepChange}
}
