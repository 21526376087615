import React, {useMemo} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {MS365SubSource} from 'ca-common/types'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'
import {getSourceName} from 'ca-common/utils/sources'

import {getSubsourceIcon} from 'src/newcore/features/Recovery/lib/getSubsourceIcon'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import type {Status} from 'src/newcore/features/Recovery/lib/types'

import {
    StyledContainer,
    StyledSubsourceIcon,
    StyledStatusIndicator,
    StyledFill,
    TooltipStyleWrapper
} from './StyledSubsourceIconWithStatus'

type PropTypes = {
    name: MS365SubSource
    status: Status
    selection: CHECK_STATE
    disabled: boolean
}

type CustomTooltipProps = React.PropsWithChildren<Pick<PropTypes, 'disabled' | 'name' | 'status'>>

const CustomTooltip = (props: CustomTooltipProps): JSX.Element => {
    const {children, status, name, disabled} = props
    const {t} = useTranslation()

    const tooltipMessage = useMemo(() => {
        switch (status) {
            case ACTIVITY_STATUS.FAILED:
                return t('recovery:MS365:failedTooltip', {serviceName: getSourceName(name)})

            case ACTIVITY_STATUS.SUCCEED:
                return t('recovery:MS365:succeededTooltip', {serviceName: getSourceName(name)})

            default:
                return ''
        }
    }, [status, name])

    return (
        <TooltipStyleWrapper>
            <Tooltip
                title={!disabled && tooltipMessage}
                getPopupContainer={triggerNode => triggerNode}
                placement="bottom"
                mouseEnterDelay={0.4}
            >
                <div>{children}</div>
            </Tooltip>
        </TooltipStyleWrapper>
    )
}

export const SubsourceIconWithStatus = ({name, status, selection, disabled}: PropTypes): JSX.Element => {
    return (
        <CustomTooltip status={status} name={name} disabled={disabled}>
            <StyledContainer selection={selection} disabled={disabled}>
                <StyledStatusIndicator status={status} />
                <StyledSubsourceIcon component={getSubsourceIcon(name)} />
                <StyledFill selection={selection} key={selection} />
            </StyledContainer>
        </CustomTooltip>
    )
}
