import styled from 'styled-components'
import variables from 'ca-common/variables'

export const NoteWrapper = styled.div`
    margin-bottom: ${variables.gutterSm};

    .anticon path {
        fill: ${variables.orange};
    }
`
