import React, {useState} from 'react'
import {scaleOrdinal} from '@visx/scale'
import {LegendOrdinal} from '@visx/legend'

import {CheckBox} from '../../atoms'

import {LegendWrapper, LegendLabelsWrapper} from './StyledLegend'

type LegendProps = {
    events?: boolean
    onLegendChange: any
    keys: string[]
    colors: string[]
}

export const Legend = (props: LegendProps): JSX.Element => {
    const {events = false, onLegendChange, keys, colors} = props
    const [hide, setHide] = useState<string[]>([])

    const ordinalColorScale = scaleOrdinal({
        domain: keys,
        range: colors
    })

    const pushHide = (value: string) => {
        return [...hide, value]
    }

    const remove = (value: string) => {
        return hide.filter(i => i !== value)
    }

    const existHide = (value: string) => {
        return hide.includes(value)
    }

    return (
        <LegendWrapper>
            <LegendOrdinal scale={ordinalColorScale}>
                {labels => (
                    <LegendLabelsWrapper>
                        {labels.map((label, i) => (
                            <CheckBox
                                key={`legend-quantile-${i}`}
                                label={label}
                                onClickHandler={() => {
                                    if (events) {
                                        let newHide: string[]

                                        if (existHide(label.datum)) {
                                            newHide = remove(label.datum)
                                        } else {
                                            newHide = pushHide(label.datum)
                                        }

                                        setHide(newHide)
                                        onLegendChange(newHide)
                                    }
                                }}
                            />
                        ))}
                    </LegendLabelsWrapper>
                )}
            </LegendOrdinal>
        </LegendWrapper>
    )
}
