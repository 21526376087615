import styled, {css} from 'styled-components'
import {Button} from 'antd'
import React from 'react'
import {lighten, darken} from 'polished'
import {ButtonWithState} from './Button'

import variables from 'ca-common/variables'

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export const StyledButton = styled(({visible, active, align, borderWidth, ...props}: ButtonWithState) => (
    <Button {...props} />
))`
    display: ${props => (props.visible ? 'inline-block' : 'none')};
    text-align: ${props => (props.align ? props.align : 'center')};
    font-family: ${variables.accentFont};
    font-weight: bold;
    border-width: ${props => (typeof props.borderWidth === 'number' ? `${props.borderWidth}px` : '1px')};
    padding-top: 0;
    padding-bottom: 0;
    ${props => props.size === 'large' && 'font-weight: 600;'};

    .anticon {
        vertical-align: middle;
    }

    ${props =>
        props.width &&
        css`
            display: flex;
            align-items: center;

            & span {
                max-width: calc(${props.width}px - 60px);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        `}

    ${props =>
        props.color &&
        (props.type === 'primary'
            ? css`
                  background: ${props.color};
                  border-color: ${props.color};

                  &:hover,
                  &:focus {
                      background: ${lighten(0.1, props.color)};
                      border-color: ${lighten(0.1, props.color)};
                  }

                  &:active {
                      background: ${darken(0.1, props.color)};
                      border-color: ${darken(0.1, props.color)};
                  }
              `
            : css`
                  color: ${props.color};
                  border-color: ${props.color};

                  &:hover,
                  &:focus {
                      color: ${lighten(0.1, props.color)};
                      border-color: ${lighten(0.1, props.color)};
                  }

                  &:active {
                      color: ${darken(0.1, props.color)};
                      border-color: ${darken(0.1, props.color)};
                  }
              `)}

&:focus {
        box-shadow: 0 0 0 2px rgba(38, 45, 97, 0.2);
    }

    &:disabled {
        background: ${variables.blueLight};
        color: ${variables.asphaltLight};
        border-color: ${variables.asphaltLight};
    }
`

export const StyledHoverReverseButton = styled(StyledButton)`
    ${props =>
        props.color &&
        css`
            color: ${props.color};
            border-color: ${props.color};
            background: none;

            &:hover {
                color: ${variables.white};
                border-color: ${lighten(0.1, props.color)};
                background: ${lighten(0.1, props.color)};
            }

            &:focus {
                box-shadow: 0 0 0 2px rgba(38, 45, 97, 0.2);
            }

            &:active {
                color: ${variables.white};
                border-color: ${darken(0.1, props.color)};
                background: ${darken(0.1, props.color)};
            }
        `}

    ${props =>
        props.color &&
        props.ghost &&
        css`
            color: ${props.color} !important;
            border-color: ${props.color} !important;
            background: transparent;
            font-family: ${variables.publicPageFont};
            font-weight: 400;

            &:hover,
            &:focus {
                background-color: rgba(0, 0, 0, 0.2) !important;
            }

            &:focus {
                box-shadow: 0 0 0 2px rgba(38, 45, 97, 0.2) !important;
            }

            &:active {
                background-color: rgba(0, 0, 0, 0.3) !important;
            }
        `}
`

export const StyledButtonState = styled(StyledHoverReverseButton)`
    ${props =>
        props.active &&
        !props.ghost &&
        css`
            && {
                color: ${variables.white};
                border-color: ${props.color};
                background: ${props.color};
            }
        `}
    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
        `}
`

// See https://github.com/ant-design/ant-design/issues/19392
export const ButtonIconWrapper = styled.span`
    display: flex;
    align-items: center;
    text-align: center;

    i {
        margin-right: 4px;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }
`
