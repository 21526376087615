import {i18n} from 'src/locales'
import {DATA_TYPE, FIELD_TYPE, MODE} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib'

const DATA_TYPES_WITH_SKIP_OPTION_ONLY = [
    DATA_TYPE.ENCRYPTED_STRING,
    DATA_TYPE.BOOLEAN,
    DATA_TYPE.DATE,
    DATA_TYPE.LOOKUP
]

const DATA_TYPES_WITH_PREDEFINED_OPTION = [DATA_TYPE.PICKLIST, DATA_TYPE.PHONE, DATA_TYPE.EMAIL, DATA_TYPE.URL]

const skipOption = {
    label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${MODE.SKIP}`),
    value: MODE.SKIP
}

const randomOption = {
    label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${MODE.RANDOM}`),
    value: MODE.RANDOM
}

const numberOptions = [
    {
        label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${FIELD_TYPE.NUMBER}`),
        value: FIELD_TYPE.NUMBER
    },
    {
        label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${FIELD_TYPE.LONGITUDE}`),
        value: FIELD_TYPE.LONGITUDE
    },
    {
        label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${FIELD_TYPE.LATITUDE}`),
        value: FIELD_TYPE.LATITUDE
    }
]

const fieldTypeBasedOptions = Object.values(FIELD_TYPE).map(value => ({
    label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${value}`),
    value
}))

export const getAnonymizationOptions = (dataType: DATA_TYPE) => {
    if (DATA_TYPES_WITH_SKIP_OPTION_ONLY.includes(dataType)) {
        return [skipOption]
    }

    if (DATA_TYPES_WITH_PREDEFINED_OPTION.includes(dataType)) {
        return [
            skipOption,
            randomOption,
            {
                label: i18n.t(`seeding:dataAnonymizationStep:fieldTypes:${dataType}`),
                value: dataType
            }
        ]
    }

    if (dataType === DATA_TYPE.NUMBER) {
        return [skipOption, randomOption, ...numberOptions]
    }

    return [skipOption, randomOption, ...fieldTypeBasedOptions]
}
