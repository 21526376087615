import {combineReducers} from '@reduxjs/toolkit'

import {backupEntitiesReducer as backupEntities} from './backupEntities'
import {compareReducers as compare} from './backupCompare'
import {seedingReducers as seeding} from './seeding'
import {uploadCSV} from './uploadCSV'
import {bulkActivationReducer as bulkActivation} from './bulkActivation'
import {backupDrilldown} from './backupDrilldown'
import {backupTaskSettingsActiveTab} from './backupTaskSettings'
import {backupTags} from './backupTags'
import {settings} from 'src/newcore/features/BackupSettings/redux'
import {listTasksNew, actionOnTaskReducer as actionOnTask} from 'src/newcore/features/Homepage/redux'
import {sfcObjects} from 'src/newcore/features/Objects/redux'
import {sfcSmartAlerts} from 'src/newcore/features/SmartAlerts/redux'
import {sfcObjectHistory} from 'src/newcore/features/ObjectHistory/redux'
import {backupProgress} from './progress'

export const backups = combineReducers({
    backupEntities,
    backupTaskSettingsActiveTab,
    uploadCSV,
    bulkActivation,
    compare,
    backupDrilldown,
    seeding,
    backupTags,
    settings,
    listTasksNew,
    actionOnTask,
    sfcSmartAlerts,
    sfcObjects,
    sfcObjectHistory,
    backupProgress
})

export * from './backupActions'
export * from './backupAuth'
export * from './backupEntities'
export * from './backupTags'
export * from './backupCompare'
