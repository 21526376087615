import styled, {keyframes} from 'styled-components'
import {Popover} from 'antd'
import React from 'react'
import {PopoverProps} from 'antd/es/popover'

import variables from 'ca-common/variables'

const gradient = keyframes`
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 0 0;
  }
`

export const ProgressContainer = styled.div`
    position: relative;
    width: 132px;
    height: 12px;
    border-radius: 22px;
    background: ${variables.navyBorder};
    overflow: hidden;
    box-sizing: content-box;
    margin: 2px;
    &:hover {
        border: 2px solid ${variables.interaction20};
        margin: 0;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    }
`
export const SubSourceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: 3;
    position: absolute;
    height: 100%;
`
export const FillingBar = styled.div<{progress?: number; pending?: boolean}>`
    display: flex;
    align-items: center;
    z-index: 2;
    position: absolute;
    width: ${props => (props.pending ? 100 : props.progress)}%;
    height: 100%;
    background-size: 300% 100%;
    background-image: ${props =>
        props.pending
            ? `linear-gradient(90deg, ${variables.brandLight} 35%, ${variables.blue} 50%, ${variables.brandLight}  65%)`
            : 'linear-gradient(90deg, #5582ff 35%, #c2d1ff 50%, #5582ff 65%)'};
    animation: ${gradient} 2s linear infinite;
`

const PopoverRaw = ({className, ...props}: {className?: string} & PopoverProps) => (
    <Popover {...props} overlayClassName={className} />
)

export const StyledPopover = styled(PopoverRaw)`
    min-width: 196px;

    .ant-popover-inner-content {
        padding: ${variables.gutterXs} 12px;
    }
`
