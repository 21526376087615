import {post} from 'src/newcore/utils/rest'
import {pullBackupEntities} from '../backupEntities'
import {AppDispatch} from 'src/newcore/components/Root'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const PERFORM_ACTION = 'PERFORM_ACTION_AVAILABILITY'
const PERFORM_ACTION_API = 'performActionToEntities'

const performActionWrapper = fetchWrapperRT(PERFORM_ACTION, async (data, {rejectWithValue}) => {
    try {
        return await post(PERFORM_ACTION_API, data)
    } catch (e) {
        return rejectWithValue(e)
    }
})

export const performAction = performActionWrapper.fetcher

export const performEntityAction = (performActionRequest: TODO_ANY, getBackupEntitiesRequest: TODO_ANY) => async (
    dispatch: AppDispatch
) => {
    return dispatch(performActionWrapper.fetcher(performActionRequest))
        .unwrap()
        .then(() => {
            return dispatch(pullBackupEntities(getBackupEntitiesRequest))
        })
}
