import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Activate from 'ca-common/icons/backupAction/Activate.svg'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {BackupAction} from '../StyledBackupActions'

type BackupActivateProps = {
    handlePerformBackupTaskAction: (arg0: TASK_ACTION) => void
}

export const BackupActivate = ({handlePerformBackupTaskAction}: BackupActivateProps) => {
    const {t} = useTranslation()

    return (
        <Tooltip title={t(`backups:newActions:${TASK_ACTION.ACTIVATE}`)}>
            <BackupAction
                component={Activate}
                onClick={() => handlePerformBackupTaskAction(TASK_ACTION.ACTIVATE)}
                data-icon="activate"
            />
        </Tooltip>
    )
}
