import React from 'react'
import {Trans} from 'react-i18next'

import {GLOBAL_LINKS, LINK_TYPES, LOCAL_LINKS} from 'ca-common/common/enum'

import {StyledTerms} from './StyledTerms'

type Props = {
    isCloudally: boolean
}

export const Terms = ({isCloudally}: Props): JSX.Element => {
    return (
        <StyledTerms>
            <Trans i18nKey="sign:agreement">
                <a target="_blank" href={isCloudally ? GLOBAL_LINKS[LINK_TYPES.TERMS] : LOCAL_LINKS[LINK_TYPES.TERMS]}>
                    terms
                </a>
                <a
                    target="_blank"
                    href={isCloudally ? GLOBAL_LINKS[LINK_TYPES.PRIVACY] : LOCAL_LINKS[LINK_TYPES.PRIVACY]}
                >
                    privacy
                </a>
            </Trans>
        </StyledTerms>
    )
}
