import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {BackupAuthFooter} from './StyledFooter'

type Props = {
    children: React.ReactNode
    onCancel: () => void
}

export const Footer = ({children, onCancel}: Props): JSX.Element => {
    const {t} = useTranslation()
    return (
        <BackupAuthFooter>
            <Button type="link" onClick={onCancel}>
                {t('forms:common:actions:cancel')}
            </Button>
            {children}
        </BackupAuthFooter>
    )
}
