import styled from 'styled-components'
import {Spin} from 'antd'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledSuffixIcon = styled(Icon)`
    font-size: ${variables.fontSizeBase};
    pointer-events: none !important;
`

export const StyledSpinner = styled(Spin)`
    margin-top: -3px;
`
