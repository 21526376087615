import React from 'react'

import {SOURCE_INFO} from 'ca-common/common/enum/Source'

import {StyledSource, StyledSourceIcon} from './StyledSource'

export const Source = ({source}) => {
    return (
        <StyledSource>
            <StyledSourceIcon source={source} />
            {SOURCE_INFO[source].displayName}
        </StyledSource>
    )
}
