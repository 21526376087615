import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'

import {getEndDate, getStartDate} from './datetime'

export const ITEM_TYPE = {
    FOLDER: 'FOLDER',
    DOCUMENT: 'DOCUMENT'
}

export const makeSnapshotsRequestParams = (
    taskId,
    account,
    targetAccount,
    extid,
    rows = 50,
    page = 1,
    sord = 'asc'
) => {
    return {
        taskId,
        account,
        targetAccount,
        extid,
        fromDate: getStartDate(),
        toDate: getEndDate(),
        rows,
        page,
        sord
    }
}

export const makeBackupedItemsRequestParams = (
    taskId,
    account,
    targetAccount,
    backupDate,
    source,
    folder,
    extid,
    rows = 50,
    page = 1,
    sord = 'asc'
) => {
    return {
        taskId,
        account,
        targetAccount,
        backupDate,
        source,
        extid,
        folder,
        rows,
        page,
        sord
    }
}

const makeFullfilledEntities = entities => {
    const {status} = entities
    const {data} = entities.response

    return {
        ...entities.response,
        data: mapEntities(data),
        status
    }
}

const mapEntities = entities => _.map(entities, entity => ({...entity, name: entity.entity}))

export const makeEntitiesTableItems = entities => {
    const {status} = entities

    switch (status) {
        case 'pending':
        case 'init':
            return {status}
        case 'fulfilled':
            return makeFullfilledEntities(entities)
        default:
            return null
    }
}

const makeFullfilledBackupedItems = (backupedItems, subsource, folderId, backupDate) => {
    const {status} = backupedItems
    const {folders, items} = backupedItems.response.data.data
    const {page, total, totalPages} = backupedItems.response.data

    return {
        data: _.union(mapFolders(folders, folderId, backupDate), mapItems(items, subsource, folderId, backupDate)),
        status,
        page,
        total,
        totalPages
    }
}

const makePendingBackupedItems = backupedItems => ({status: backupedItems.status})

export const makeBackupedItemsTableItems = (backupedItems, subsource, folderId, backupDate) => {
    const {status} = backupedItems

    switch (status) {
        case 'pending':
        case 'init':
            return makePendingBackupedItems(backupedItems)
        case 'fulfilled':
            return makeFullfilledBackupedItems(backupedItems, subsource, folderId, backupDate)
        default:
            return null
    }
}

export const mapFolders = (folders, folderId, backupDate) =>
    _.map(folders, folder => ({
        id: folder.first,
        entityId: folder.first,
        entityType: ITEM_TYPE.FOLDER,
        name: folder.second,
        folderId,
        backupDate
    }))

export const mapItems = (items, subsource, folderId, backupDate) => {
    switch (subsource) {
        case SOURCE.MS365GROUP_META:
            return mapMS365GroupMetaItems(items, subsource, folderId, backupDate)
        case SOURCE.MS365GROUP_CONVERSATIONS:
        case SOURCE.GMAIL:
        case SOURCE.YMAIL:
        case SOURCE.IMAP:
        case SOURCE.O365MAIL:
            return mapMailboxItems(items, subsource, folderId, backupDate)
        case SOURCE.MS365GROUP_CALENDAR:
        case SOURCE.GCALENDAR:
        case SOURCE.O365CALENDAR:
            return mapCalendarItems(items, subsource, folderId, backupDate)
        case SOURCE.O365TASKS:
        case SOURCE.GTASK:
            return mapTaskItems(items, subsource, folderId, backupDate)
        case SOURCE.O365CONTACTS:
        case SOURCE.GCONTACTS:
            return mapContactItems(items, subsource, folderId, backupDate)
        default:
            return mapDefaultItems(items, subsource, folderId, backupDate)
    }
}

export const mapContactItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.fullName,
        date: item.backupDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapMS365GroupMetaItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        preview: true,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.displayName || item.key,
        date: item.backupDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapMailboxItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.subject,
        date: item.sentDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapCalendarItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.summary,
        date: item.sentDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapTaskItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.summary,
        date: item.dueDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapDefaultItems = (items, subsource, folderId, backupDate) =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        name: item.title,
        date: item.backupDate,
        folderId,
        backupDate,
        subsource
    }))

export const mapSearchItems = items =>
    _.map(items, item => ({
        ...item,
        id: item.entityId,
        entityType: ITEM_TYPE.DOCUMENT,
        date: item.backupDate
    }))

export const makeFolderDrilldownLink = (item, match) => {
    if (match.params.folder) {
        return _.replace(match.url, _.last(_.split(match.url, '/')), item.id)
    }

    return `${match.url}/${item.id}`
}

export const getCurrentFolderId = folderId => {
    return folderId ? _.last(_.split(folderId, '/')) : null
}
