import {useAppSelector} from 'src/newcore/components/Root'
import {wizardConfigSelector, wizardStepSelector} from 'src/newcore/redux/modules/wizard'
import {useMemo} from 'react'
import _ from 'lodash'

export const useIsFirstStep = () => {
    const {disabledSteps = [], skipableSteps = []} = useAppSelector(wizardConfigSelector)
    const step = useAppSelector(wizardStepSelector)

    return useMemo(() => {
        const unavailableSteps = _.union(disabledSteps, skipableSteps)

        for (let newStep = step - 1; newStep >= 0; newStep--) {
            if (!unavailableSteps?.includes(String(newStep))) {
                return false
            }
        }

        return true
    }, [disabledSteps, skipableSteps, step])
}
