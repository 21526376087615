import React from 'react'
import {get} from 'src/newcore/utils/rest'
import type {MS365SubSource, TODO_ANY} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum'
import {cancellable} from 'src/newcore/features/Recovery/lib'

const ITEMS_IN_FOLDER_API = 'getNewItemsInFolderAsync'
type Key = React.Key

export type TableRequestType = {
    rows: number
    page: number
    folder: string
    account: string
    taskId: string | undefined
    backupDate: number
    extid: string
    source: MS365SubSource
    sortBy?: Key | readonly Key[]
    order?: string
}

export type EmailsTableItem = {
    from: string
    entityId: string
    subject: string
    sentDate: string
    recipients: string[]
    preview: string
    message: string
}

export type ContactsTableItem = {
    emails: string[]
    entityId: string
    name: string
    updateDate: string
    job?: string
    company?: string
    phones: string[]
    addresses: string[]
}

export type TasksTableItem = {
    subject: string
    entityId: string
    status: string
    dueDate: string
    updateDate: string
}

export type NotesTableItem = {
    subject: string
    entityId: string
    updateDate: string
    body: string
}

export type CalendarsTableItem = {
    event: string
    startDate: string
    endDate: string
    entityId: string
    description?: string
}

export type TableDataItems = EmailsTableItem | ContactsTableItem | TasksTableItem | NotesTableItem | CalendarsTableItem

export type TableData = {
    data: Array<TableDataItems>
    size: number
}

export type TableDataState = {loading: boolean} & TableData

type ParserType = (data: Array<TODO_ANY>) => Array<TableDataItems>

const fetchTableItems = async (req: TableRequestType, parser: ParserType) => {
    const response = (await get(ITEMS_IN_FOLDER_API, req)) as Record<string, any>

    const {
        data: {items},
        total: size
    } = response

    return {data: parser(items), size}
}

export const cancellableFetchTableItems = (req: TableRequestType, parser: ParserType) =>
    cancellable(fetchTableItems(req, parser))

const parseToEmails = (data: Array<TODO_ANY>): Array<EmailsTableItem> => {
    return data.map(el => {
        return {
            from: el.from,
            entityId: el.entityId,
            subject: el.subject,
            sentDate: el.sentDate,
            filename: el.filename,
            backupDate: el.backupDate,
            recipients: el.recipients,
            preview: el.preview,
            message: el.message
        }
    })
}

const parseToContacts = (data: Array<TODO_ANY>): Array<ContactsTableItem> => {
    return data.map(el => {
        return {
            name: el.fullName,
            entityId: el.entityId,
            updateDate: el.backupDate,
            filename: el.filename,
            backupDate: el.backupDate,
            emails: el.emails,
            job: el.title,
            phones: el.phones,
            addresses: el.addresses,
            company: el.company
        }
    })
}

const parseToTasks = (data: Array<TODO_ANY>): Array<TasksTableItem> => {
    return data.map(el => {
        return {
            subject: el.summary,
            status: el.status,
            entityId: el.entityId,
            dueDate: el.dueDate,
            updateDate: el.backupDate,
            filename: el.filename,
            backupDate: el.backupDate
        }
    })
}

const parseToNotes = (data: Array<TODO_ANY>): Array<NotesTableItem> => {
    return data.map(el => {
        return {
            subject: el.subject,
            entityId: el.entityId,
            updateDate: el.backupDate,
            filename: el.filename,
            backupDate: el.backupDate,
            body: el.body
        }
    })
}

const parseToCalendars = (data: Array<TODO_ANY>): Array<CalendarsTableItem> => {
    return data.map(el => {
        return {
            entityId: el.entityId,
            event: el.summary,
            startDate: el.startDate,
            endDate: el.endDate,
            filename: el.filename,
            backupDate: el.backupDate,
            description: el.description
        }
    })
}

export const getParser = (subSource: MS365SubSource): ParserType => {
    switch (subSource) {
        case SOURCE.O365MAIL:
            return parseToEmails
        case SOURCE.O365CONTACTS:
            return parseToContacts
        case SOURCE.O365TASKS:
            return parseToTasks
        case SOURCE.O365NOTES:
            return parseToNotes
        case SOURCE.O365CALENDAR:
            return parseToCalendars
    }
}
