import styled from 'styled-components'

import variables from 'ca-common/variables'

export const BackupAuthBlock = styled.div`
    padding: 0 ${variables.gutterMd};
    display: flex;
    align-items: center;
    justify-content: space-between;
`
