import styled from 'styled-components'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import Icon from '@ant-design/icons'

export const StyledFooter = styled.div`
    font-family: ${variables.publicPageFont};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${variables.gutterLg};
    background: ${variables.darkBlue};

    @media (min-width: 768px) {
        padding: 28px ${variables.gutterLg} 36px;
    }

    @media (min-width: 1440px) {
        padding: ${variables.gutterMd} 72px;
    }
`
export const StyledCopyright = styled.div`
    display: flex;
    gap: ${variables.gutterLg};
    align-items: center;

    span {
        font-size: ${variables.fontSizeBase};
        color: ${variables.white};
    }

    @media (max-width: ${variables.screenXl}) {
        order: 1;
    }

    @media (max-width: ${variables.screenSm}) {
        order: 0;
    }
`

export const StyledFooterLinks = styled.div`
    display: flex;

    & :last-child {
        span {
            padding-right: 0;
            border-right: 0;
        }
    }

    & :first-child {
        span {
            padding-left: 0;
        }
    }

    @media (max-width: ${variables.screenXl}) {
        width: 100%;
        order: 0;
        justify-content: space-between;
        margin-bottom: ${variables.gutterLg};
    }

    @media (max-width: ${variables.screenSm}) {
        margin-top: ${variables.gutterLg};
        order: 1;
        flex-direction: column;
    }
`

export const LinkText = styled.span`
    font-size: ${variables.fontSizeBase};
    color: ${variables.white};
    font-family: ${variables.publicPageFont};

    &:hover {
        text-decoration: underline;
    }
`

export const StyledCAlink = styled(CALink)`
    width: 100%;
    padding: 0 ${variables.gutterXs};
    white-space: nowrap;
    text-align: center;

    @media (max-width: ${variables.screenSm}) {
        border-right: none;
        margin-bottom: ${variables.gutterLg};
        text-align: left;
        padding: 0;
    }
`

export const StyledLastColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 116px;
`

export const StyledAWSLogo = styled(Icon)`
    svg {
        width: 93px;
        height: 16px;
    }
`
