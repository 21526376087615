import {transform, isArray} from 'lodash'
import {SOURCE, SOURCE_INFO} from 'ca-common/common/enum/Source'

export const hasEntities = source => SOURCE_INFO[source].hasSubAccounts

export const getSubsources = masterSource => {
    return transform(
        SOURCE_INFO,
        (result, value, key) => {
            if (
                value.masterSource &&
                (isArray(value.masterSource)
                    ? value.masterSource.includes(masterSource)
                    : value.masterSource === masterSource)
            ) {
                result.push(SOURCE[key])
            }
        },
        []
    )
}

export const getSourceName = (source, short = false) => {
    return short ? SOURCE_INFO[source]?.shortName : SOURCE_INFO[source]?.displayName
}
