import {
    OCABackupSettingsStep,
    OCAConfirmationStep,
    OCASettingsSelectionStep,
    OCAProcessingStep,
    OCAInitProcessingStep,
    OCAEntitiesSelectionStep,
    OCATasksAlreadyExistStep,
    OCARecentlyDeletedTasksStep
} from 'src/newcore/features/OneClickActivation/orgamisms'

export const getOneClickActivationSteps = () => [
    OCAInitProcessingStep,
    OCATasksAlreadyExistStep,
    OCARecentlyDeletedTasksStep,
    OCABackupSettingsStep,
    OCAProcessingStep,
    OCAEntitiesSelectionStep,
    OCASettingsSelectionStep,
    OCAConfirmationStep
]
