import {REGION, SOURCE, STORAGE_TYPE as TARGET_DESTINATION, RESTORE_TYPE, RESTORE_OPTIONS} from 'ca-common/common/enum'

export type WizardRestoreOptions = Partial<Record<RESTORE_OPTIONS, boolean> & {restoreType: RESTORE_TYPE}>

export const INITIAL_SUBSERVICES_STATE = [
    SOURCE.O365MAIL,
    SOURCE.O365CONTACTS,
    SOURCE.O365TASKS,
    SOURCE.O365CALENDAR,
    SOURCE.O365NOTES
] as const

export const INITIAL_STORAGE_SETTINGS: StorageSettingType = {
    exportToRegion: REGION.US_EST
}

export enum FULL_RECOVERY_ERRORS {
    SUB_TASKS_ALREADY_EXIST = 'SUB_TASKS_ALREADY_EXIST',
    INVALID_PRINCIPAL = 'INVALID_PRINCIPAL',
    ACCESS_DENIED = 'ACCESS_DENIED'
}

export type RecoveryErrorType = {
    fullRecoveryErrorType: FULL_RECOVERY_ERRORS
    sourcesAlreadyExist: string[]
    status: string
    success: boolean
}

export {STORAGE_TYPE as TARGET_DESTINATION} from 'ca-common/common/enum'

export const TargetsWithSettings = [
    TARGET_DESTINATION.AMAZON_S3,
    TARGET_DESTINATION.AZURE,
    TARGET_DESTINATION.AMAZON_S3_COMPATIBLE,
    TARGET_DESTINATION.GOOGLE_CLOUD_PLATFORM
]

export enum EMAIL_SETTINGS {
    includeLegalHold = 'includeLegalHold',
    includeDeleted = 'includeDeleted',
    preferSinglePST = 'preferSinglePST'
}

export enum EMAIL_FORMAT {
    EML = 'EML',
    PST = 'PST'
}

export enum TASKS_FORMAT {
    ICS = 'ICS',
    PST = 'PST'
}

export enum NOTES_FORMAT {
    CSV = 'CSV',
    PST = 'PST'
}

export enum CALENDARS_FORMAT {
    ICAL = 'ICAL',
    PST = 'PST'
}

export enum CONTACTS_FORMAT {
    VCF = 'VCF',
    PST = 'PST'
}

export type SubsourceFormat = EMAIL_FORMAT | TASKS_FORMAT | NOTES_FORMAT | CALENDARS_FORMAT | CONTACTS_FORMAT
export type EmailSettingsType = Partial<Record<EMAIL_SETTINGS, boolean>>

export type StorageSettingType = {
    exportBucketName?: string
    exportToKey?: string
    exportToSecretKey?: string
    compatibleS3Endpoint?: string
    exportToRegion?: REGION
}

export enum STORAGE_SETTINGS {
    exportBucketName = 'exportBucketName',
    exportToKey = 'exportToKey',
    exportToSecretKey = 'exportToSecretKey',
    compatibleS3Endpoint = 'compatibleS3Endpoint',
    exportToRegion = 'exportToRegion'
}
