import {createReducer, createAction, combineReducers, createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const CREATE_BULK_ACTIVATION_RULES = 'CREATE_BULK_ACTIVATION_RULES'
const CREATE_BULK_ACTIVATION_RULES_API = 'createBulkActivationRule'
const createBulkActivationRuleWrapper = fetchWrapperRT(
    CREATE_BULK_ACTIVATION_RULES,
    async data => await post(CREATE_BULK_ACTIVATION_RULES_API, data)
)

export const createBulkActivationRule = createBulkActivationRuleWrapper.fetcher

const GET_BULK_ACTIVATION_RULES = 'GET_BULK_ACTIVATION_RULES'
const GET_BULK_ACTIVATION_RULES_API = 'getBulkActivationRules'
const getBulkActivationRulesWrapper = fetchWrapperRT(
    GET_BULK_ACTIVATION_RULES,
    async data => await post(GET_BULK_ACTIVATION_RULES_API, data)
)

export const getBulkActivationRules = getBulkActivationRulesWrapper.fetcher

const rulesSlice = createSlice({
    name: 'rules',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearBulkActivationRules: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getBulkActivationRulesWrapper.makeReducers(builder)
})

export const {clearBulkActivationRules} = rulesSlice.actions

const REMOVE_BULK_ACTIVATION_RULES = 'REMOVE_BULK_ACTIVATION_RULES'
const REMOVE_BULK_ACTIVATION_RULES_API = 'removeBulkActivationRules'

const removeBulkActivationRulesWrapper = fetchWrapperRT(
    REMOVE_BULK_ACTIVATION_RULES,
    async data => await post(REMOVE_BULK_ACTIVATION_RULES_API, data)
)

export const removeBulkActivationRules = removeBulkActivationRulesWrapper.fetcher

const REMOVED_RULES_LIST = 'REMOVED_RULES_LIST'
export const getRemovedRulesList = createAction<TODO_ANY[]>(REMOVED_RULES_LIST)

const CLEAR_REMOVED_RULES_LIST = 'CLEAR_REMOVED_RULES_LIST'
export const clearRemovedRulesList = createAction(CLEAR_REMOVED_RULES_LIST)

export const removedRulesList = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearRemovedRulesList, () => [])
    builder.addCase(getRemovedRulesList, (state, action) => [...state, ...action.payload])
})

export const bulkActivationRules = combineReducers({
    rules: rulesSlice.reducer,
    removedRulesList
})
