import React from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import 'ca-common/ui-lib/components/CheckBox/CheckBox.scss'

const dumbCheckBox = dumbBem('ca-form-checkbox')
const CheckBox = tx(dumbCheckBox)('div')
const Input = tx([{element: 'input'}, dumbCheckBox])('input')
const Label = tx([{element: 'label'}, dumbCheckBox])('label')
const Error = tx([{element: 'error'}, dumbCheckBox])('span')

export class CACheckBox extends React.Component {
    render() {
        const {
            props: {
                input,
                label,
                meta: {touched, error, warning},
                ...rest
            }
        } = this

        const fieldModifier = touched && error ? 'error' : ''

        return (
            <CheckBox modifier={label ? 'labeled' : ''}>
                <Input type="checkbox" {...input} {...rest} id={input.name} />
                <Label htmlFor={input.name} modifier={fieldModifier}>
                    {label}
                </Label>
                {touched && ((error && <Error>[{error}]</Error>) || (warning && <span>[{warning}]</span>))}
            </CheckBox>
        )
    }
}
