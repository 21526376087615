import React, {useCallback, useEffect, useState} from 'react'
import {Spin} from 'antd'
import moment, {Moment} from 'moment'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useRouteMatch} from 'react-router'

import {DAY_MONTH_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import variables from 'ca-common/variables'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'

import {CustomCalendarHeader} from 'ca-common/ui-lib/molecules/CustomCalendarHeader'

import {getCalendar, clearCalendar} from 'src/newcore/features/Recovery/redux'
import {StyledCalendarDotsCell, StyledCalendar} from './StyledRecoverySnapshotCalendar'

type RecoverySnapshotCalendarProps = {
    onSelect: ({selectedDate, isPartial}: {selectedDate?: Moment; isPartial: boolean}) => void
    backupDate: number
} & ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

export const RecoverySnapshotCalendarRaw = ({
    calendar,
    actions,
    onSelect,
    entity,
    backupDate
}: RecoverySnapshotCalendarProps): JSX.Element => {
    const [snapshotMap, setSnapshotMap] = useState({} as any)
    const [innerCalendarDate, setInnerCalendarDate] = useState(moment(backupDate, LONG_DATE_FORMAT).local())
    const [calendarMode, setCalendarMode] = useState<'month' | 'year'>('month')
    const {
        params: {taskId}
    }: any = useRouteMatch('/recovery/:taskId/')
    const renderDateStatusIndicators = useCallback(
        (current: Moment) => {
            const {partial, succeed} = snapshotMap[current.format(DAY_MONTH_FORMAT)] || {}

            let left, right
            if (!partial) {
                if (succeed) {
                    left = variables.green
                    if (succeed > 1) {
                        right = variables.green
                    }
                }
            } else {
                left = variables.orange
                if (succeed) {
                    right = variables.green
                } else if (partial > 1) {
                    right = variables.orange
                }
            }

            return <StyledCalendarDotsCell left={left} right={right} />
        },
        [snapshotMap]
    )

    const onPanelChangeHandler = (current?: Moment) => {
        if (current && current !== innerCalendarDate) {
            setInnerCalendarDate(current)
        }
    }

    const handleCalendarSelect = (selectedDate?: Moment) => {
        if (selectedDate) {
            if (calendarMode === 'year') setCalendarMode('month')
            setInnerCalendarDate(selectedDate)

            const {partial} = snapshotMap[selectedDate.format(DAY_MONTH_FORMAT)] || {}

            onSelect({selectedDate, isPartial: partial})
        }
    }

    const getCalendarSnapshot = () =>
        actions.getCalendar({
            taskId,
            accountId: entity.entity,
            extid: entity.extid,
            year: innerCalendarDate.year(),
            month: innerCalendarDate.month() + 1
        })

    useEffect(() => {
        const res: Record<string, Record<string, number>> = {} as any
        calendar?.response?.forEach((snapshot: any) => {
            const date = String(getLocalDateFromUTC(snapshot.dateTime)).slice(0, 8)
            if (!res[date]) {
                res[date] = {
                    succeed: 0,
                    partial: 0
                }
            }
            if (snapshot.status === ACTIVITY_STATUS.SUCCEED) {
                res[date].succeed++
            } else {
                res[date].partial++
            }
        })
        setSnapshotMap(res)
    }, [calendar])

    useEffect(() => {
        if (calendar.response) {
            const isSameMonth =
                String(calendar.response[0]?.dateTime).slice(0, 6) === innerCalendarDate.format('YYYYMM')
            !isSameMonth && getCalendarSnapshot()
        }
    }, [innerCalendarDate])

    useEffect(() => {
        setInnerCalendarDate(moment(backupDate, LONG_DATE_FORMAT).local())
        if (calendar.status === 'init') {
            getCalendarSnapshot()
        }
    }, [])

    return (
        <Spin spinning={calendar.status !== 'fulfilled'}>
            <StyledCalendar
                value={innerCalendarDate}
                onPanelChange={onPanelChangeHandler}
                dateCellRender={renderDateStatusIndicators}
                onSelect={handleCalendarSelect}
                mode={calendarMode}
                fullscreen={false}
                headerRender={({type}) => (
                    <CustomCalendarHeader
                        type={type}
                        value={innerCalendarDate}
                        onChange={setInnerCalendarDate}
                        onTypeChange={setCalendarMode}
                        sinceDate={entity.retentionDate || entity.firstBackupDate}
                        toDate={entity.lastBackupDate}
                    />
                )}
            />
        </Spin>
    )
}

const mapStateToProps = ({recoveryNew}: {recoveryNew: any}) => ({
    calendar: recoveryNew.calendar,
    entity: {...recoveryNew.entities.response?.data[0]}
})

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(
        {
            getCalendar,
            clearCalendar
        },
        dispatch
    )
})

export const RecoverySnapshotCalendar = connect(mapStateToProps, mapDispatchToProps)(RecoverySnapshotCalendarRaw)
