import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {InfoCircleOutlined} from '@ant-design/icons'
import {useExitModal} from 'src/newcore/features/Recovery/lib'

import {StyledModal, StyledModalTitle} from 'src/newcore/features/Recovery/atoms'

type ModalProps = {
    needToShowExitModal: boolean
}

export const ExitModal = ({needToShowExitModal}: ModalProps): JSX.Element => {
    const {show, onOk, onCancel} = useExitModal(needToShowExitModal)
    const {t} = useTranslation()

    const title = (
        <StyledModalTitle>
            <InfoCircleOutlined />
            {t('recovery:modals:exit:title')}
        </StyledModalTitle>
    )

    return (
        <StyledModal
            width={610}
            title={title}
            open={show}
            onOk={() => {
                onOk && onOk()
            }}
            onCancel={() => {
                onCancel()
            }}
            closable={true}
            destroyOnClose={true}
            cancelButtonProps={{type: 'link'}}
            okText={t('recovery:modals:exit:ok')}
            cancelText={t('recovery:modals:exit:cancel')}
            centered
        >
            <Trans i18nKey={'recovery:modals:exit:description'} />
        </StyledModal>
    )
}
