import styled from 'styled-components'
import variables from 'ca-common/variables'
import {CLIENT_LOGO} from 'ca-common/constants'

export const StyledClientLogo = styled.div<{client: keyof typeof CLIENT_LOGO}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${variables.gutterSm};

    svg {
        height: 72px;
        width: auto;
    }

    @media (max-width: ${variables.screenXxl}) {
        ${props => {
            switch (props.client) {
                case CLIENT_LOGO.AACP:
                    return `
                     order: 3;
                     width: 28%;
                `
                case CLIENT_LOGO.BGC:
                    return `
                    order: 1;
                     width: 30%;
                `
                case CLIENT_LOGO.JAGUAR:
                    return `
                     order: 0;
                     width: 30%;
                `
                case CLIENT_LOGO.RAMVIK:
                    return `
                     width: 28%;
                     order: 4;
                `
                case CLIENT_LOGO.SHOOSHMONKEY:
                    return `
                     width: 15%;
                      order: 5;
                `
                case CLIENT_LOGO.SINGLEHANDED:
                    return `
                     order: 2;
                     width: 30%;
                `
                case CLIENT_LOGO.WESTLONDON:
                    return `
                     width: 28%;
                     order: 6;

                `
                default:
                    return ''
            }
        }}
    }

    @media (max-width: ${variables.screenMd}) {
        ${props => {
            switch (props.client) {
                case CLIENT_LOGO.AACP:
                    return `
                     order: 6;
                     width: 50%;
                `
                case CLIENT_LOGO.BGC:
                    return `
                    order: 2;
                     width: 50%;
                `
                case CLIENT_LOGO.JAGUAR:
                    return `
                     order: 1;
                     width: 50%;
                `
                case CLIENT_LOGO.RAMVIK:
                    return `
                     width: 50%;
                     order: 5;
                `
                case CLIENT_LOGO.SHOOSHMONKEY:
                    return `
                     width: 40%;
                      order: 3;
                `
                case CLIENT_LOGO.SINGLEHANDED:
                    return `
                     order: 0;
                     width: 100%;
                `
                case CLIENT_LOGO.WESTLONDON:
                    return `
                     width: 60%;
                     order: 4;

                `
                default:
                    return ''
            }
        }}
    }
`
