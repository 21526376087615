import React, {useEffect} from 'react'
import Icon from '@ant-design/icons'
import {Checkbox, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import Info from 'ca-common/icons/Info.svg'

import {
    StyledIncludeAttachments,
    StyledDepth,
    StyledInputNumber,
    StyledModalContentWrapper
} from './StyledIncludeAttachments'
import {changeData} from 'src/newcore/redux/modules/wizard'

const DEPTH_MIN_VALUE = 0
const DEPTH_MAX_VALUE = 7

export const IncludeAttachmentsRaw = ({actions, wizard}) => {
    const {t} = useTranslation()

    const {includeAttachments, hierarchyDepth} = wizard.data

    const onChangeCheckbox = e => {
        const {checked} = e.target

        actions.changeData({
            includeAttachments: checked
        })
    }

    const onHierarchyDepthChange = value => {
        actions.changeData({
            hierarchyDepth: value
        })
    }

    useEffect(() => {
        actions.changeData({
            includeAttachments: false,
            hierarchyDepth: DEPTH_MIN_VALUE
        })
    }, [])

    return (
        <StyledIncludeAttachments>
            <StyledModalContentWrapper>
                <Checkbox checked={includeAttachments} onChange={onChangeCheckbox}>
                    {t('seeding:includeAttachments:title')}
                </Checkbox>
                <Tooltip title={t('seeding:includeAttachments:info')}>
                    <Icon component={Info} />
                </Tooltip>
                <StyledDepth>
                    {t('seeding:hierarchyDepth:title')}
                    <StyledInputNumber
                        value={hierarchyDepth}
                        min={DEPTH_MIN_VALUE}
                        max={DEPTH_MAX_VALUE}
                        onChange={onHierarchyDepthChange}
                    />
                </StyledDepth>
                <Tooltip title={t('seeding:hierarchyDepth:info')}>
                    <Icon component={Info} />
                </Tooltip>
            </StyledModalContentWrapper>
        </StyledIncludeAttachments>
    )
}

const mapStateToProps = state => ({
    wizard: state.wizard
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeData
        },
        dispatch
    )
})

export const IncludeAttachments = connect(mapStateToProps, mapDispatchToProps)(IncludeAttachmentsRaw)
