import React from 'react'
import {Trans} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum'
import type {MS365SubSource} from 'ca-common/types'
import ChooseFolder from 'ca-common/icons/ChooseFolder.svg'
import ChooseCalendar from 'ca-common/icons/ChooseCalendar.svg'
import {StyledIcon} from 'src/newcore/features/Recovery/atoms/StyledIcon'

import {StyledChooseFolderIcon} from './StyledChooseFolderIcon'

export const ChooseFolderIcon = ({selectedSubSource}: {selectedSubSource: MS365SubSource}): JSX.Element => {
    let image
    let description

    switch (selectedSubSource) {
        case SOURCE.O365CALENDAR: {
            image = <StyledIcon component={ChooseCalendar} />
            description = <Trans i18nKey={'forms:recovery:table:chooseCalendar'} />
            break
        }
        default: {
            image = <StyledIcon component={ChooseFolder} />
            description = <Trans i18nKey={'forms:recovery:table:chooseFolder'} />
        }
    }

    return <StyledChooseFolderIcon image={image} description={description} />
}
