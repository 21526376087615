import React from 'react'
import {connect} from 'react-redux'

import {LastStepCommon} from './LastStepCommon'

const MetadataLastStepRaw = props => {
    const {objectsList} = props

    return <LastStepCommon objectsList={objectsList} />
}

const mapStateToProps = state => ({
    objectsList: state.backups.seeding.sourceObjectsList
})

export const MetadataLastStep = connect(mapStateToProps)(MetadataLastStepRaw)
