import React, {useEffect, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {omitBy, isNil} from 'lodash'
import {useParams} from 'react-router'

import {
    changeData,
    clearWizard,
    setWizardDescription,
    setWizardTitle,
    previousStep
} from 'src/newcore/redux/modules/wizard'
import {setActiveTab} from 'src/newcore/redux/modules/backups/backupTaskSettings'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {PANES_KEYS} from 'src/newcore/components/SFCTabs'
import {Button} from 'ca-common/ui-lib/atoms'
import {AlertReview} from '../organisms/AlertReview'
import {createSmartAlert} from '../redux'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {getSfcSmartAlerts} from 'src/newcore/features/SmartAlerts/redux'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/features/SmartAlerts/organisms'

export const SmartAlertsThirdStepRaw = ({actions, wizardData, currentStep}) => {
    const {t} = useTranslation()
    const {taskId} = useParams()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [step] = useState(currentStep)

    const onAddNewAlert = () => {
        setLoading(true)
        actions.setActiveTab(PANES_KEYS.SMART_ALERTS)
        actions
            .createSmartAlert(omitBy(wizardData, isNil))
            .unwrap()
            .then(response => {
                actions.clearWizard()
                openSuccessNotification(response?.status)
                actions.getSfcSmartAlerts({taskId, limit: PAGE_SIZE, page: DEFAULT_PAGE})
            })
            .catch(err => {
                if (err.data?.status) {
                    setError(err.data.status)
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (step === currentStep) {
            actions.setWizardDescription([t('smartAlerts:wizard:thirdStepDescription')])
        } else if (error) {
            setError(false)
        }
    }, [currentStep])

    const handleCancel = () => actions.clearWizard()
    const handleBack = () => actions.previousStep()

    return (
        <>
            <AlertReview error={error} wizardData={wizardData} />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button disabled={error} loading={loading} type="primary" onClick={onAddNewAlert}>
                        {t('smartAlerts:addNewAlert')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={handleBack} />
            </ModalFooter>
        </>
    )
}

const mapStateToProps = state => ({
    wizardData: state.wizard.data,
    currentStep: state.wizard.step
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            previousStep,
            clearWizard,
            changeData,
            setWizardTitle,
            setWizardDescription,
            setActiveTab,
            createSmartAlert,
            getSfcSmartAlerts
        },
        dispatch
    )
})

export const SAThirdStep = connect(mapStateToProps, mapDispatchToProps)(SmartAlertsThirdStepRaw)
