import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {map} from 'lodash'

import {SEEDING_OBJECT_TYPES} from 'ca-common/common/enum/seeding'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'

import {SecondStepCommon} from './SecondStepCommon'

export const DataSecondStepRaw = props => {
    const {taskSettings} = props

    const {t} = useTranslation()

    return (
        <SecondStepCommon
            title={`${taskSettings.alias} ${t('seeding:secondStep:title')}`}
            description={t('seeding:secondStep:dataDescription')}
            objectSelection={(onChange, objectType) =>
                map(SEEDING_OBJECT_TYPES, type => {
                    return (
                        <RadioBlock
                            checked={objectType === type}
                            key={type}
                            value={type}
                            onChange={onChange}
                            showRadioIcon={false}
                            title={t(`seeding:objectTypes:${type}:label`)}
                            description={t(`seeding:objectTypes:${type}:description`)}
                        />
                    )
                })
            }
        />
    )
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response
})

export const DataSecondStep = connect(mapStateToProps)(DataSecondStepRaw)
