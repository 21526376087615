import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Link} from 'react-router-dom'

import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    font-size: 34px;
    color: ${variables.old.blueLight};

    &:hover {
        cursor: pointer;
    }
`

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`

export const StyledTableActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 20px;
`

export const StyledTableActionTitle = styled.h2`
    font-size: 18px;
    letter-spacing: 0.3px;
`
export const StyledTableActionNew = styled.div`
    font-size: ${variables.subTitleFontSize};
    margin-left: ${variables.gutterXs};
`
