import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useRouteMatch} from 'react-router'
import {SubmissionError} from 'redux-form'
import _ from 'lodash'

import {PAGES} from 'ca-common/constants'
import {NotificationForm} from 'ca-common/ui-lib/components/Notifications/NotificationForm'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {updateUsersNotification, usersNotification} from 'src/newcore/redux/modules/notifications'
import {useAppDispatch} from 'src/newcore/components/Root'

export const EditNotificationPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [currentUser, setCurrentUser] = useState({})
    const [isLoaded, setLoaded] = useState(false)
    const match = useRouteMatch<{id: string}>()

    useEffect(() => {
        dispatch(
            usersNotification({
                id: _.toInteger(match.params.id)
            })
        )
            .then((data: {payload: {data: any}}) => {
                setCurrentUser(data.payload.data)
                setLoaded(true)
            })
            .catch(() => {
                history.push(`/${PAGES.SETTINGS}/${PAGES.NOTIFICATIONS}`)
            })
    }, [])

    const submitForm = (values: any) => {
        return dispatch(
            updateUsersNotification({
                id: (currentUser as Record<string, any>).id,
                ...values
            })
        )
            .unwrap()
            .then(() => {
                openSuccessNotification(t('notifications:emailUpdated'))
                history.push('/settings/notifications')
            })
            .catch((err: {status: string}) => {
                if (err.status === 'Notification user exists in the notification list') {
                    throw new SubmissionError({
                        email: err.status
                    })
                } else {
                    openErrorModal(t('notifications:updateFailed'))
                }
            })
    }

    return <NotificationForm isPortal={true} onSubmit={submitForm} initialValues={currentUser} isLoaded={isLoaded} />
}
