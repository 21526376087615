import React from 'react'
import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'
import {Checkbox, Radio} from 'antd'

import variables from 'ca-common/variables'

export const StyledRow = styled.div<{checked?: boolean; error?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-height: 48px;

    border: 1px solid
        ${props =>
            props.error ? `rgba(255, 14, 14, 0.3)` : props.checked ? variables.interaction : variables.blueLight};
    box-sizing: border-box;
    box-shadow: ${props => (props.checked ? `0 2px 4px rgba(0, 0, 0, 0.06)` : `0px 2px 6px rgba(0, 0, 0, 0.02)`)};
    border-radius: ${variables.borderRadiusBase};

    padding: 0 ${variables.gutterXs};
    margin-bottom: ${variables.gutterSm};

    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};

    color: ${props => (props.checked ? variables.navyBlue : variables.asphaltLight)};
    transition: color 150ms ease-in-out;
    user-select: none;
`

export const StyledInnerWrapper = styled.div`
    display: flex;
    min-height: 48px;
    align-items: center;
`

export const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${variables.gutterXs};
`

export const ToggleWrapper = styled.div<{active: boolean}>`
    display: ${props => (props.active ? 'block' : 'none')};
    margin-right: ${props => (props.active ? variables.gutterMd : 0)};
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SettingsIcon = styled(({active, disabled, ...props}) => <Icon {...props} />)`
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 16px;
    border-radius: 50%;

    font-size: 16px;

    transition: transform 300ms ease-in-out;
    transform: ${props => (props.active ? 'rotate(-90deg)' : 'rotate(0)')};

    pointer-events: none;

    .anticon[tabindex],
    &:hover {
        cursor: default;
    }

    ${props =>
        !props.disabled &&
        css`
            pointer-events: auto;

            &:hover {
                background-color: ${variables.interaction};
                cursor: pointer;
            }

            &:active {
                background-color: ${variables.interaction20};
            }
        `};
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledCheckboxGroup = styled(Checkbox.Group)`
    .ant-checkbox-group-item {
        display: flex;
        font-family: ${variables.mainFont};
        font-style: normal;
        font-weight: normal;
        font-size: ${variables.fontSizeBase};
        margin-bottom: ${variables.gutterXs};
    }
`

export const StyledRadioGroup = styled(Radio.Group)`
    margin-bottom: 12px;

    .ant-radio-wrapper {
        font-weight: 400;
    }
`

export const StyledSettingsLabel = styled.label`
    display: block;
    color: ${variables.black};
    margin-bottom: 12px;
`

export const StyledSettingsWrapper = styled.div<{active?: boolean}>`
    margin-top: ${props => (props.active ? variables.gutterXs : 0)};
    margin-bottom: ${props => (props.active ? variables.gutterSm : 0)};
    padding: 0 ${variables.gutterXs};

    max-height: ${props => (props.active ? '250px' : 0)};
    opacity: ${props => (props.active ? 1 : 0)};
    pointer-events: ${props => (props.active ? 'auto' : 'none')};
    overflow: hidden;

    transition: max-height 300ms linear, opacity 150ms linear 100ms, margin-top 300ms linear, margin-bottom 300ms linear;
`

export const ExcludedIcon = styled(Icon)`
    width: 44px;
    padding-top: 4px;

    color: ${variables.asphaltLight};
    font-size: 18px;
    transform: rotate(90deg);
`
