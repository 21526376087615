import _ from 'lodash'

import {TASK_STATUS, TASK_ACTION} from 'ca-common/common/enum'
import {checkPermission} from 'ca-common/utils/permissions'

export const activateActionAvailableStatuses = [TASK_STATUS.NOT_ACTIVE, TASK_STATUS.PAUSED]

const pauseActionAvailableStatuses = [
    TASK_STATUS.SCHEDULED,
    TASK_STATUS.SUCCESS,
    TASK_STATUS.IN_PROCESS,
    TASK_STATUS.FAILED,
    TASK_STATUS.PARTIAL
]

const runActionAvailableStatuses = [TASK_STATUS.SCHEDULED, TASK_STATUS.SUCCESS, TASK_STATUS.FAILED, TASK_STATUS.PARTIAL]

const deleteActionAvailableStatuses = [
    TASK_STATUS.SCHEDULED,
    TASK_STATUS.SUCCESS,
    TASK_STATUS.IN_PROCESS,
    TASK_STATUS.FAILED,
    TASK_STATUS.PAUSED,
    TASK_STATUS.ARCHIVED,
    TASK_STATUS.PARTIAL
]

export const getAvailableStatusesByTaskAction = action => {
    switch (action) {
        case TASK_ACTION.ACTIVATE:
            return activateActionAvailableStatuses
        case TASK_ACTION.PAUSE:
            return pauseActionAvailableStatuses
        case TASK_ACTION.RUN:
            return runActionAvailableStatuses
        default:
            return deleteActionAvailableStatuses
    }
}

export const getPerformAvailableEntities = (availableStatuses, entitiesStatus) =>
    _.reduce(
        entitiesStatus,
        (result, value, key) => {
            if (_.includes(availableStatuses, key)) {
                result += value
            }
            return result
        },
        0
    )

export const getEntitiesSum = entitiesStatus => _.reduce(entitiesStatus, (sum, entity) => sum + entity, 0)

export const getEntitiesStatusRequest = (action, taskId, all, entities, filter) => ({
    action,
    taskId,
    all,
    entities,
    filter
})

export const getPerformActionRequest = (action, taskId, all, entities, filter, excludeEntities, cacheToken) => ({
    action,
    taskId,
    all,
    entities,
    filter,
    excludeEntities,
    cacheToken
})

export const getSelectedEntitiesRequest = (action, taskId, all, entities, filter, rows, page) => ({
    action,
    taskId,
    all,
    entities,
    filter,
    rows,
    page
})

export const limitExceeded = (availableStatuses, inProcessEntities, maximumAllowedItems) => {
    return availableStatuses + inProcessEntities > maximumAllowedItems
}

export const filterByPermissions = (action, source, permissions) => {
    switch (action) {
        case TASK_ACTION.ACTIVATE:
            return (
                checkPermission('PAGE.BACKUPS.ACTIVATE', permissions) && // CLOUD-6923
                checkPermission(`BACKUP.${source}.EDIT`, permissions)
            )

        case TASK_ACTION.RUN:
            return checkPermission(`BACKUP.${source}.EXECUTE`, permissions)

        default:
            return checkPermission(`BACKUP.${source}.${action}`, permissions)
    }
}
