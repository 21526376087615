import {Table} from 'antd'
import styled from 'styled-components'

import variables from 'ca-common/variables'
import {TODO_ANY} from 'ca-common/types'

export const StyledTable = styled(Table)<TODO_ANY>`
    .ant-table-thead {
        .ant-table-cell {
            font-weight: 600;
            color: ${variables.text};
            line-height: 21px;
        }
        > tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }

        tr {
            th {
                border-top: 1px solid ${variables.navyBorder};
                border-bottom: 1px solid #e8e8e8;
                padding-right: 0;
            }
        }
    }

    .ant-table-tbody > .ant-table-row > td {
        font-size: ${variables.fontSizeSecondary};
        line-height: 18px;
        padding: 12px;
        padding-right: 0;
        color: ${variables.text};
        background-color: ${variables.white};
        border-bottom: 1px solid ${variables.navyBorder};
    }

    .ant-table-row {
        &--succeeded > td:first-child,
        &--failed > td:first-child,
        &--partial > td:first-child {
            position: relative;

            &::before {
                position: absolute;
                left: 0;
                top: 4px;
                bottom: 4px;
                content: '';
                width: 2px;
            }
        }

        &--succeeded > td:first-child::before {
            background-color: ${variables.green};
        }

        &--failed > td:first-child::before {
            background-color: ${variables.red};
        }

        &--partial > td:first-child::before {
            background-color: ${variables.orange};
        }
    }
`

export const StyledActivityInfinityTable = styled.section`
    padding: ${variables.gutterSm} ${variables.gutterMd};
    background-color: ${variables.white};
    border-radius: 0 0 ${variables.borderRadiusBase} ${variables.borderRadiusBase};
`
