import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_TASKS_RECOVERY = 'GET_TASKS_RECOVERY'
const GET_TASKS_API = 'getBackupTasks'
const getTasksWrapper = fetchWrapperRT(GET_TASKS_RECOVERY, async data => await post(GET_TASKS_API, data))
export const getTasks = getTasksWrapper.fetcher

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearTasks: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getTasksWrapper.makeReducers(builder)
})

export const {clearTasks} = tasksSlice.actions
export const tasks = tasksSlice.reducer
