import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StatusIcon = styled(Icon)`
    margin-right: ${variables.gutterXs};
    width: ${variables.gutterMd};
    font-size: ${variables.gutterMd};
`

export const StatusWrapper = styled.span`
    display: flex;
    align-items: center;
`
