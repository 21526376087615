export enum AUTH_ERRORS {
    accessDenied,
    timeout
}

const STORAGE_AUTH_KEY = 'successStorageAuthentication'
const BOX_DROPBOX_AUTH_TIMEOUT = 180000

export const waitForStorageFlag = async (redirectUrl: string) => {
    const openedWindow = window.open(redirectUrl, '_blank', 'width=600,height=900,left=600')
    localStorage.removeItem(STORAGE_AUTH_KEY)

    return new Promise((resolve, reject) => {
        function boxAuthHandler(event: StorageEvent) {
            if (event.storageArea === localStorage && event.key === STORAGE_AUTH_KEY) {
                window.removeEventListener('storage', boxAuthHandler)

                if (event.newValue === 'true') {
                    resolve(event.newValue)
                } else if (event.newValue === 'false') {
                    reject(AUTH_ERRORS.accessDenied)
                }
                localStorage.removeItem(STORAGE_AUTH_KEY)
            }
        }

        window.addEventListener('storage', boxAuthHandler)

        const timeout = setTimeout(() => {
            window.removeEventListener('storage', boxAuthHandler)
            reject(AUTH_ERRORS.timeout)
            openedWindow?.close()
        }, BOX_DROPBOX_AUTH_TIMEOUT)

        const interval = setInterval(() => {
            if (openedWindow?.closed) {
                clearTimeout(timeout)
                clearInterval(interval)
                window.removeEventListener('storage', boxAuthHandler)
                reject(AUTH_ERRORS.accessDenied)
            }
        }, 3000)
    })
}
