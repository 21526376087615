import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledBackupTitleForm = styled(Form)``

const BackupTitleBlock = styled.div`
    padding: 0 ${variables.gutterMd};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const StyledBackupTitle = styled.div`
    display: flex;
    align-items: center;
`

export const BackupTitleHeader = styled.h3`
    padding: ${variables.gutterMd} ${variables.gutterMd} 0;
    margin-bottom: 0;
    font-size: 18px;
`

export const BackupTitleFooter = styled(BackupTitleBlock)`
    height: 60px;
`

export const StyledBackupEdit = styled(Icon)`
    display: flex;
    margin-left: 14px;
    cursor: pointer;
    color: ${variables.navyBlue};
`

export const BackupTitleBody = styled.div`
    border-bottom: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};
`
