import React from 'react'
import {reduce, map, reject} from 'lodash'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar'
import {getActivityStatusFriendly} from 'ca-common/common/enum/Statuses'

import {FIELD_OBJECT_STATUS, FIELD_OBJECT_TYPE, getTypeFrendly} from 'src/newcore/features/Objects/libs'
import {StyledSelectedFilters} from './StyledSelectedFilters'

const prepareData = data => {
    const localize = (key, item) => {
        switch (key) {
            case FIELD_OBJECT_STATUS:
                return getActivityStatusFriendly(item)

            case FIELD_OBJECT_TYPE:
                return getTypeFrendly(item)

            default:
                return ''
        }
    }

    return reduce(
        data,
        (result, current, key) => [
            ...result,
            ...map(current, item => ({
                value: `${key}.${item}`,
                label: localize(key, item)
            }))
        ],
        []
    )
}
export const SelectedFilters = props => {
    const {loading, setValues, values} = props

    const remove = value => {
        const [parent, target] = value.split('.')

        const newValues = reduce(
            values,
            (result, current, key) => ({
                ...result,
                [key]: parent === key ? reject(current, i => i === target) : current
            }),
            {}
        )

        setValues(newValues)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: []}), {})

        setValues(newValues)
    }

    return (
        <StyledSelectedFilters disabled={loading}>
            <SelectedFiltersBar onRemove={remove} onRemoveAll={removeAll} selectedFilterValues={prepareData(values)} />
        </StyledSelectedFilters>
    )
}
