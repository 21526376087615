import {createSlice} from '@reduxjs/toolkit'
import {postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState, FetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'
const GET_GOOGLE_OU = 'GET_GOOGLE_OU'
const GET_GOOGLE_OU_API = 'getGroups'

const getGoogleOUWrapper = fetchWrapperRT(GET_GOOGLE_OU, async (data, {rejectWithValue}) => {
    try {
        return await postLessData(GET_GOOGLE_OU_API, data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getGoogleOU = getGoogleOUWrapper.fetcher
const APPEND_GOOGLE_OU = 'APPEND_GOOGLE_OU'
const appendBulkOUWrapper = fetchWrapperRT<GoogleOUListResponse>(
    APPEND_GOOGLE_OU,
    async data => await postLessData(GET_GOOGLE_OU_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                return {
                    ...response,
                    data: [...state.response.data, ...response.data]
                }
            }

            return response
        }
    }
)
export const appendGoogleOU = appendBulkOUWrapper.fetcher

export type GoogleOUListResponse = {data: TODO_ANY[]; totalPages: number; total: number; page: number}

const GoogleOUListSlice = createSlice({
    name: 'GoogleOUList',
    initialState: InitFetchedState as FetchedState<GoogleOUListResponse>,
    reducers: {
        clearGoogleOU: () => InitFetchedState as FetchedState<GoogleOUListResponse>
    },
    extraReducers: builder => {
        getGoogleOUWrapper.makeReducers(builder)
        appendBulkOUWrapper.makeReducers(builder)
    }
})

export const {clearGoogleOU} = GoogleOUListSlice.actions
export const GoogleOUListReducer = GoogleOUListSlice.reducer
