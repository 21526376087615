import _ from 'lodash'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'
import moment from 'moment'

import {TABLE_COLUMN_TYPE} from 'ca-common/common/enum/TableColumnType'
import {SORD_ORDER} from 'ca-common/common/enum/SordOrder'
import {getTaskStatuses} from 'ca-common/utils/backupTaskStatus'

import {DROPBOX_TYPE} from 'ca-common/common/enum'

import {TaskStatus} from 'ca-common/components/TaskStatus'
import {openErrorModal} from 'ca-common/utils/modals'
import {getEntitiesKeys, getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {LIMITED_ACCESS} from 'ca-common/constants'

import {setExpandedRowKeys, clearExpandedRowKeys} from 'src/newcore/redux/modules/expandedRowKeys'
import {DrillDown} from 'src/newcore/components/BackupEntities/CellModifier/DrillDown'

import {InfiniteTable} from 'src/newcore/components/InfiniteTable'
import {
    getBackupEntities,
    getBackupEntitiesAppend,
    pullBackupEntities,
    clearBackupEntities
} from 'src/newcore/redux/modules/backups'
import {getBackupTasks, clearBackupTasks} from 'src/newcore/features/Homepage/redux'
import {clearBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

import {getTags} from 'src/newcore/redux/modules/backups/backupTags'
import {SelectedFilters} from 'src/newcore/molecules/SelectedFilters'

import {BackupEntityDrilldown} from 'src/newcore/components/BackupEntityDrilldown'
import {BackupEntitiesHeader} from 'src/newcore/organisms'

import {StyledBackupEntities, BackupEntitiesTable, BackupEntitiesTableOperations} from './StyledBackupEntities'

import {SearchEntitiesForm} from './SearchEntities'
import {getTableConfigBySource, DEFAULT_PAGE, PAGE_SIZE} from './TableColumnsConfig'
import {WizardModal} from 'src/newcore/components/Wizard'

const TABLE_X_SCROLL_AFTER = 1100

export class CABackupEntitiesRaw extends React.Component {
    constructor(props) {
        super(props)

        _.bindAll(this, ['pullEntities'])
    }

    state = {
        selectedRowKeys: []
    }

    componentDidMount() {
        const {
            actions,
            taskSettings: {taskId, source}
        } = this.props

        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
        this.getInitialBackupEntities(queryParams)
        actions.getTags({taskId, source})
        actions.getBackupTasks() // Temporarily for transfer to old recovery from new wizard if selective mailbox recovery
    }

    componentDidUpdate(prevProps) {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
        const oldQueryParams = queryString.parse(prevProps.location.search, {arrayFormat: 'bracket'})

        if (!_.isEqual(queryParams, oldQueryParams) || this.props.taskId !== prevProps.taskId) {
            this.getInitialBackupEntities(queryParams)
        }
    }

    componentWillUnmount() {
        const {actions} = this.props
        actions.clearBackupEntities()
        actions.clearBackupTaskSettings()
        actions.clearExpandedRowKeys()
        actions.clearBackupTasks()
    }

    // Triggers on each selection change. Set selectedRowKeys
    onSelectChange = selectedRowKeys => {
        this.setState({
            selectedRowKeys
        })
    }

    // Keep all selected items but reset selectAll
    onSelectRow = (record, selected, selectedRows) => {
        const {entities} = this.props

        this.setState({
            selectedAll: _.get(entities, 'response.data', []).length === selectedRows.length
        })
    }

    // Toggle selectAll checkbox
    onSelectAll = () => {
        this.setState({
            selectedAll: !this.state.selectedAll
        })
    }

    // Get entities with selectedStatus and set selectAll
    onStatusClick = (entities, status) => {
        const {location, history} = this.props
        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        this.setState({
            selectedAll: true
        })

        if (queryParams.status !== status) {
            history.push({
                search: queryString.stringify({statusFilter: [status]}, {arrayFormat: 'bracket'})
            })
        }
    }

    resetRowSelection = () => {
        this.setState({
            selectedAll: false,
            selectedRowKeys: []
        })
    }

    setLimitedAccessData = urlData => {
        const updatedUrl = urlData
        if (urlData?.tagIds) {
            const limitedCheck = urlData?.tagIds.some(value => value === LIMITED_ACCESS)
            if (limitedCheck) {
                const tagIds = urlData?.tagIds.filter(value => value !== LIMITED_ACCESS)
                updatedUrl.tagIds = tagIds.length !== 0 ? tagIds : undefined
                updatedUrl.limitedAccess = true
            }
        }
        return updatedUrl
    }

    getInitialBackupEntities = values => {
        const {actions, source, taskId} = this.props
        const urlData = getRequestParamsFromUrl(values)
        const getUrlValue = this.setLimitedAccessData(urlData)
        actions
            .getBackupEntities({
                taskId,
                rows: PAGE_SIZE,
                page: DEFAULT_PAGE,
                source,
                ...getUrlValue
            })
            .then(response => this.setSelectedRowKeys(response.payload.data))
            .catch(err => {
                if (err.status) {
                    openErrorModal(err.status)
                }
            })
    }

    onFilterSubmit(values) {
        const {location, history} = this.props

        const URLparams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        const newParams = Object.assign({}, URLparams, values)

        this.resetRowSelection()

        history.push({
            search: queryString.stringify(
                _.omitBy(newParams, el => el === ''),
                {arrayFormat: 'bracket'}
            )
        })
    }

    fetchOnScroll = nextPage => {
        const {actions, taskSettings} = this.props
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})

        actions
            .getBackupEntitiesAppend({
                taskId: taskSettings.taskId,
                rows: PAGE_SIZE,
                page: nextPage,
                ...getRequestParamsFromUrl(queryParams)
            })
            .then(response => this.setSelectedRowKeys(response.payload.data))
            .catch(err => {
                if (err.status) {
                    openErrorModal(err.status)
                }
            })
    }

    setSelectedRowKeys = entities => {
        const {selectedAll, selectedRowKeys} = this.state

        if (selectedAll) {
            this.setState({
                selectedRowKeys: [...selectedRowKeys, ...getEntitiesKeys(entities)]
            })
        }
    }

    pullEntities() {
        const {actions, location, taskSettings, entities} = this.props
        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})
        const {scrollPage} = _.get(entities, 'response')

        actions.pullBackupEntities({
            taskId: taskSettings.taskId,
            rows: PAGE_SIZE,
            page: scrollPage,
            ...getRequestParamsFromUrl(queryParams)
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        const order = SORD_ORDER[sorter.order]
        const sortBy = TABLE_COLUMN_TYPE[sorter.field]

        this.onFilterSubmit({order, sortBy})
    }

    getRowClassName = entity => {
        const {entities} = this.props

        if (_.includes(entities.response.bottomEntities, entity.id)) {
            return 'bottom-entity'
        }

        return null
    }

    getPullConfig = () => {
        const {entities} = this.props

        return {
            pull: this.pullEntities,
            short: moment.duration(1, 'minutes'),
            long: moment.duration(5, 'minutes'),
            stop: moment.duration(20, 'minutes'),
            resetDelayProp: _.get(entities, 'response.data.scrollPage')
        }
    }

    onRow = record => this.props.expandedRowKeys.includes(record.id) && {className: 'expand-parent'}

    render() {
        const {entities, taskSettings, location, source, expandedRowKeys, actions} = this.props
        const {selectedAll, selectedRowKeys} = this.state

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        const dataSource = _.get(entities, 'response', [])

        const getValidStatuses = statuses => {
            const temp = {...statuses}
            delete temp.PENDING
            return temp
        }

        const selections = _.map(getValidStatuses(getTaskStatuses(source)), status => ({
            key: status,
            text: <TaskStatus status={status} />,
            onSelect: () => this.onStatusClick(dataSource.data, status)
        }))

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: this.onSelectRow,
            onSelectAll: this.onSelectAll,
            selections,
            columnWidth: 60
        }

        const pullConfig = this.getPullConfig()
        const columns = getTableConfigBySource(
            taskSettings.isDropboxProfessionalAccount ? DROPBOX_TYPE.DROPBOX_PROFESSIONAL : taskSettings.source
        )

        const tags = this.props.tags.response || []

        return (
            <StyledBackupEntities>
                <BackupEntitiesHeader />
                <BackupEntitiesTable>
                    {!taskSettings.isDropboxProfessionalAccount && (
                        <BackupEntitiesTableOperations>
                            <SearchEntitiesForm
                                onSubmit={values => this.onFilterSubmit(values)}
                                initialValues={queryParams}
                                source={source}
                                selectedAll={selectedAll}
                                selectedRowKeys={selectedRowKeys}
                                tags={tags}
                                resetRowSelection={this.resetRowSelection}
                            />
                            <SelectedFilters fetched={entities.status === 'pending'} />
                        </BackupEntitiesTableOperations>
                    )}
                    <InfiniteTable
                        rowSelection={rowSelection}
                        rowKey={entity => entity.id}
                        expandedRowKeys={expandedRowKeys}
                        columns={columns}
                        expandedRowRender={(record, index, indent, expanded) => (
                            <BackupEntityDrilldown entity={record} expanded={expanded} />
                        )}
                        data={entities}
                        fetchData={this.fetchOnScroll}
                        onTableChange={this.handleTableChange}
                        rowClassName={this.getRowClassName}
                        pullConfig={pullConfig}
                        onRow={this.onRow}
                        expandIconAsCell={false}
                        expandIcon={props => <DrillDown {...props} onClickHandler={actions.setExpandedRowKeys} />}
                        scroll={{x: TABLE_X_SCROLL_AFTER}}
                        showSorterTooltip={false}
                    />
                </BackupEntitiesTable>
                <WizardModal />
            </StyledBackupEntities>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    entities: state.backups.backupEntities.entities,
    activeEntities: state.backups.backupEntities.activeEntities,
    expandedRowKeys: state.expandedRowKeys,
    tags: state.backups.backupTags.tags
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            pullBackupEntities,
            getBackupEntities,
            getBackupEntitiesAppend,
            clearBackupEntities,
            clearBackupTaskSettings,
            setExpandedRowKeys,
            clearExpandedRowKeys,
            getTags,
            getBackupTasks,
            clearBackupTasks
        },
        dispatch
    )
})

export const CABackupEntities = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CABackupEntitiesRaw))
)
