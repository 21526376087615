import React, {useState} from 'react'
import Icon from '@ant-design/icons'
import {Row, Col, Input, Tooltip, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import type {FieldData} from 'rc-field-form/lib/interface'

import TwoFa from 'ca-common/icons/TwoFa.svg'
import ErrorTwoFa from 'ca-common/icons/ErrorTwoFa.svg'

import variables from 'ca-common/variables'
import {Errors} from 'ca-common/atoms/Errors'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {Footer, TwoFaInfo} from 'ca-common/forms/SignIn/StyledSignIn'
import {hasEmptyErrorsAntd4, EMPTY_ERROR_MESSAGE} from 'ca-common/utils/form'

type TwoFAOwnProps = {
    onSubmit: (arg0: any) => void
    onChange: (error: string | null) => void
    handleLostDevice: () => void
    error: null | string
    loading: boolean
}

export const TwoFACodeForm = (props: TwoFAOwnProps) => {
    const {onSubmit, handleLostDevice, error, loading} = props
    const {t} = useTranslation()

    const [hasEmptyErrors, setHasEmptyErrors] = useState(false)

    const onFieldsChange = (_: FieldData[], allValues: FieldData[]) => {
        setHasEmptyErrors(state => {
            const temporary = hasEmptyErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })
    }

    return (
        <Form layout="horizontal" hideRequiredMark onFinish={onSubmit} onFieldsChange={onFieldsChange}>
            <Row gutter={24}>
                <Col lg={24}>
                    <Form.Item
                        name="twoFaCode"
                        rules={[
                            {
                                required: true,
                                message: EMPTY_ERROR_MESSAGE
                            }
                        ]}
                    >
                        <Input size="large" placeholder={t('forms:signInTwoFA:fields:twoFaCode:placeholder')} />
                    </Form.Item>
                </Col>

                <Col lg={24}>
                    {error && <Errors error={error} />}
                    <Footer>
                        <Tooltip title={hasEmptyErrors && t('forms:common:fields:common:validation:required')}>
                            <Button
                                block
                                size="large"
                                color={variables.iceBlue}
                                type="primary"
                                htmlType="submit"
                                disabled={loading || hasEmptyErrors}
                            >
                                {t('forms:common:actions:verify')}
                            </Button>
                        </Tooltip>
                        <TwoFaInfo>
                            <Icon component={error ? ErrorTwoFa : TwoFa} />
                            {t('sign:in:twoFaInfo')}
                        </TwoFaInfo>
                        <Button onClick={handleLostDevice} block type="link">
                            {t('sign:in:partnerPortal:lostDevice')}
                        </Button>
                    </Footer>
                </Col>
            </Row>
        </Form>
    )
}
