import React from 'react'
import {Dispatch} from '@reduxjs/toolkit'
import {useTranslation} from 'react-i18next'

import Logout from 'ca-common/icons/Logout.svg'

import {StyledLogoutButton, StyledLogoutIcon} from './StyledLogoutButton'

type LogoutButtonProps = {
    onClick: Dispatch
}

export const LogoutButton = ({onClick}: LogoutButtonProps) => {
    const {t} = useTranslation()
    return (
        <StyledLogoutButton onClick={onClick} icon={<StyledLogoutIcon component={Logout} />}>
            {t('header:logout')}
        </StyledLogoutButton>
    )
}
