import React from 'react'
import type {TableProps} from 'antd/lib/table/Table'
import type {CheckboxChangeEvent} from 'antd/es/checkbox'

import {MS365SubSource, TODO_ANY} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum'

import {RecoveryItemsTable, RecoveryItemsTableProps} from 'src/newcore/features/Recovery/molecules/RecoveryItemsTable'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import {CheckTablePayload} from 'src/newcore/features/Recovery/localState/actionsLocalState'
import {TableHeaderCheckbox} from 'src/newcore/features/Recovery/atoms/TableHeaderCheckbox'

import type {TableDataItems} from 'src/newcore/features/Recovery/fetch'

export type RecoveryTableOwnProps = {
    tableSize: number
    treeSize: number
    loadingTable: boolean
    onTableItemChecked: (data: CheckTablePayload) => void
    checkedTableItems: RecoveryItemsTableProps<TableDataItems>['checkedItems']
    tableData: RecoveryItemsTableProps<TableDataItems>['dataSource']
    onChangeTable: TableProps<TableDataItems>['onChange']
    columns: TableProps<TableDataItems>['columns']
    selectedFolder: string
    tablePlaceholder: JSX.Element
    selectedSubSource: MS365SubSource
    hasPermissionToExpand?: boolean
    source: SOURCE
}

type SelectHandlerType = RecoveryItemsTableProps<TableDataItems>['selectHandler']

const totalCount = (total: TODO_ANY, range: TODO_ANY) => `${range[0]}-${range[1]} of ${total} items`

export const RecoveryTable = (props: RecoveryTableOwnProps): JSX.Element => {
    const {
        tableSize,
        onTableItemChecked,
        checkedTableItems,
        tableData,
        onChangeTable,
        loadingTable,
        columns,
        tablePlaceholder,
        selectedSubSource,
        hasPermissionToExpand,
        source
    } = props
    const elementsCount = tableData?.length ?? 0
    const pagination = {
        pageSize: 30,
        hideOnSinglePage: true,
        total: tableSize,
        showSizeChanger: false,
        showTotal: totalCount
    }
    const scroll = tableSize > 12 && elementsCount > 12 ? {y: 636} : {}

    const selectHandler: SelectHandlerType = (keys, checkedId, uncheckedId, itemsInfo) => {
        let checkAll = CHECK_STATE.PARTIAL
        if (tableSize === keys.length) {
            checkAll = CHECK_STATE.CHECKED
        }
        if (keys.length === 0) {
            checkAll = CHECK_STATE.UNCHECKED
        }
        if (checkedTableItems) {
            if (checkedTableItems.checked === true && checkedTableItems.unchecked.length === 1 && checkedId) {
                checkAll = CHECK_STATE.CHECKED
            }
        }
        onTableItemChecked({keys, checkAll, checkedId, uncheckedId, itemsInfo})
    }
    const selectAllHandler = (e: CheckboxChangeEvent) => {
        onTableItemChecked({
            keys: [],
            checkAll: e.target.checked ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED,
            checkedId: null,
            uncheckedId: null,
            itemsInfo: {}
        })
    }

    return (
        <RecoveryItemsTable
            tablePlaceholder={tablePlaceholder}
            loading={loadingTable}
            onChange={onChangeTable}
            pagination={pagination}
            columns={columns}
            rowKey={entity => entity.entityId}
            selectHandler={selectHandler}
            headerCheckbox={
                elementsCount ? (
                    <TableHeaderCheckbox checkedTableItems={checkedTableItems} selectAllHandler={selectAllHandler} />
                ) : null
            }
            checkedItems={checkedTableItems}
            dataSource={tableData}
            scroll={scroll}
            selectedSubSource={selectedSubSource}
            hasPermissionToExpand={hasPermissionToExpand}
            source={source}
        />
    )
}
