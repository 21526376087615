import React from 'react'
import {useTranslation} from 'react-i18next'
import {map} from 'lodash'
import {useSelector} from 'react-redux'

import {GLOBAL_LINKS} from 'ca-common/common/enum'
import {SOCIAL_NETWORK_LINKS} from 'ca-common/constants'
import AwsLogo from 'ca-common/icons/AwsLogoLight.svg'
import {Logo} from 'ca-common/components/PublicSite/MarketingFooter/Header/StyledHeader'

import {FooterSocialLinks} from './FooterSocialLinks'
import {StyledFooter, StyledCopyright, LinkText, StyledFooterLinks, StyledCAlink, StyledAWSLogo} from './StyledFooter'

export const Footer = ({socialNetworks}: {socialNetworks: typeof SOCIAL_NETWORK_LINKS}): JSX.Element => {
    const {t} = useTranslation()
    const logo = useSelector((state: any) => state?.companyInfo?.response?.logo)

    return (
        <StyledFooter>
            <StyledCopyright>
                <Logo src={logo} />
                <span>{t('footer:copyright', {year: new Date().getFullYear()})}</span>
            </StyledCopyright>
            <StyledFooterLinks>
                {map(GLOBAL_LINKS, (link, type) => {
                    return (
                        <StyledCAlink to={link} pureLink target="_blank" key={type}>
                            <LinkText>{t(`footer:footerLinks:${type}`)}</LinkText>
                        </StyledCAlink>
                    )
                })}
            </StyledFooterLinks>
            <FooterSocialLinks socialNetworks={socialNetworks} />
            <StyledAWSLogo component={AwsLogo} />
        </StyledFooter>
    )
}
