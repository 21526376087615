import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {ADDRESS_REGEXP, convertIpAddressToNumber, isAddressNumbersValid} from 'ca-common/features/IpRestriction/lib'
import {IPv4Address} from 'ca-common/features/IpRestriction/types'

export const useAddressRangeValidator = () => {
    const {t} = useTranslation()
    return useCallback((from: IPv4Address, to: IPv4Address, isNeededValidation?: boolean) => {
        if (!isNeededValidation) {
            return Promise.resolve()
        }

        const fromArr = from.split('.').map(Number)

        if (!ADDRESS_REGEXP.test(from) || !ADDRESS_REGEXP.test(to)) {
            return Promise.reject(t('validation:invalidIpAddressFormat'))
        }

        const toArr = to.split('.').map(Number)

        if (!isAddressNumbersValid(toArr) || !isAddressNumbersValid(fromArr)) {
            return Promise.reject(t('validation:invalidIpAddressNumbers'))
        }

        const fromNum = convertIpAddressToNumber(from)
        const toNum = convertIpAddressToNumber(to)

        if (fromNum >= toNum) {
            return Promise.reject(t('validation:invalidIpAddressRange'))
        }

        return Promise.resolve()
    }, [])
}
