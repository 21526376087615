import React from 'react'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const OneDriveSOAP = ({
    source,
    backupSettings,
    onSubmit,
    onCancel,
    loading
}: BackupAuthTemplate): JSX.Element => {
    const {t} = useTranslation()
    const initialValues = {
        alias: backupSettings.alias,
        account: backupSettings.account,
        sharePointUrl: backupSettings.endpoint
    }

    return (
        <StyledBackupAuthForm initialValues={initialValues} layout="horizontal" onFinish={values => onSubmit(values)}>
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="account"
                            label={t('forms:backupAuth:fields:OneDriveAccount:label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:fields:email:validation:required')
                                },
                                {
                                    type: 'email',
                                    message: t('forms:common:fields:email:validation:invalid')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="password"
                            label={t('forms:backupAuth:fields:OneDrivePassword:label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:fields:password:validation:required')
                                }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="sharePointUrl"
                            label={t('forms:backupAuth:fields:OneDriveUrl:label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:validation:required')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('forms:common:actions:authenticate')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
