import React, {useState} from 'react'
import {Input, Select, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {useWatch} from 'antd/lib/form/Form'

import {BACKUP_FREQUENCY, getOptionsOfHours} from 'ca-common/utils/datetime'
import {VerticalFormItem, Label} from 'ca-common/ui-lib/components/FormItem'

const {Option} = Select

type BackupHourProps = {
    backupHour?: number
    backupFrequency: BACKUP_FREQUENCY
    namePrefix?: string
    isDistributedBackupHour?: boolean
    distributedBackupHourValue?: string
}

export const BackupHour = ({
    backupHour,
    namePrefix = '',
    isDistributedBackupHour,
    distributedBackupHourValue
}: BackupHourProps) => {
    const {t} = useTranslation()
    const name = `${namePrefix ? `${namePrefix}.` : ''}backupHour`
    const backupFrequencyFieldName = `${namePrefix ? `${namePrefix}.` : ''}backupFrequency`
    const tooltipText = t('forms:backupSettings:fields:backupHour:tooltip')

    const backupFrequency = useWatch(backupFrequencyFieldName)

    return (
        <VerticalFormItem
            label={
                <Label>
                    <span>{t('forms:backupSettings:fields:backupHour:label')}</span>
                </Label>
            }
            colon={false}
            name={name}
            initialValue={backupHour}
        >
            {!isDistributedBackupHour ? (
                <BackupHourSelect backupFrequency={backupFrequency} tooltipText={tooltipText} />
            ) : (
                <Tooltip title={tooltipText}>
                    <div>
                        <Input value={distributedBackupHourValue} disabled />
                    </div>
                </Tooltip>
            )}
        </VerticalFormItem>
    )
}

type BackupHourSelectProps = {
    value?: string
    onChange?: (value: string) => void
    backupFrequency: BACKUP_FREQUENCY
    tooltipText: string
}

const BackupHourSelect = ({backupFrequency, onChange, value, tooltipText}: BackupHourSelectProps) => {
    const isTripleBackup = backupFrequency === BACKUP_FREQUENCY.DAILY3TIMES
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <Tooltip title={tooltipText} open={isTripleBackup && showTooltip}>
            <Select
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                disabled={isTripleBackup}
                onChange={onChange}
                value={value}
                getPopupContainer={t => t.parentElement}
            >
                {getOptionsOfHours(isTripleBackup).map(region => (
                    <Option key={region.value} value={region.value}>
                        {region.label}
                    </Option>
                ))}
            </Select>
        </Tooltip>
    )
}
