import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledWrapper = styled.div<{isBackground: boolean; isCheckEnable?: boolean}>`
    width: 60%;
    margin-top: 0%;
    font-size: ${variables.fontSizeBase};
    color: ${props => (props.isCheckEnable ? variables.asphaltLight : variables.navyBlue)};

    ${props =>
        props.isBackground &&
        css`
            border-radius: ${variables.fontSizeBase};
            background: ${variables.interaction20};
            width: 100%;
            margin-top: 5%;
            font-size: 13px;
        `}
`

export const StyledIcon = styled(Icon)<{isBackground: boolean}>`
    margin-left: ${props => (props.isBackground ? '5%' : '0')};
`

export const StyledText = styled.span<{isBackground: boolean}>`
    margin-right: ${props => (props.isBackground ? '1%' : '0%')};
`
