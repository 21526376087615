import React /*, {useRef}*/ from 'react'
// import Icon from '@ant-design/icons'
// import {useTranslation} from 'react-i18next'

// import Download from 'ca-common/icons/DownloadAll.svg'

// import {getServiceName} from 'src/newcore/utils/sources'
import {JobFields} from 'src/newcore/features/Jobs/types'

import {
    Account,
    /*DownloadButton, */
    StyledDownloadTableHeader,
    TaskName,
    TitleWrapper
} from './StyledDownloadTableHeader'

type DownloadTableHeaderProps = {
    // selectedRowsWithLink: Record<string, string>[]
    // onDownloadHandler: (arg0: string) => void
} & Pick<JobFields, 'account' | 'alias'>

export const DownloadTableHeader = ({
    // selectedRowsWithLink,
    // onDownloadHandler,
    account,
    alias
}: // source
DownloadTableHeaderProps): JSX.Element => {
    // const {t} = useTranslation()

    // const refs = useRef<HTMLAnchorElement[] | null[]>([])

    // const onDownload = () => {
    //     selectedRowsWithLink.forEach((row, index) => {
    //         setTimeout(() => refs.current[index]?.click(), 50 * index)
    //     })
    // }
    return (
        <StyledDownloadTableHeader>
            <TitleWrapper>
                <TaskName>{alias}</TaskName>
                <Account>{account}</Account>
            </TitleWrapper>
            {/* <DownloadButton onClick={onDownload} disabled={!selectedRowsWithLink.length} shape="round" type="primary">
                <Icon component={Download} />
                {t('JobsPage:downloadTable:download')}
            </DownloadButton> */}
            {/* {selectedRowsWithLink.map((row, index) => {
                return (
                    <a
                        style={{display: 'none'}}
                        key={row.exportDataId}
                        onClick={() => onDownloadHandler(row.exportDataId)}
                        ref={el => (refs.current[index] = el)}
                    />
                )
            })} */}
        </StyledDownloadTableHeader>
    )
}
