import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {InfoCircleOutlined} from '@ant-design/icons'
import {StyledModal, StyledModalTitle} from 'src/newcore/features/Recovery/atoms'

type ModalProps = {
    visible: boolean
    setVisible: (arg0: boolean) => void
    onOk: () => void
}

export const ChangeDateModal = ({visible, setVisible, onOk}: ModalProps): JSX.Element => {
    const {t} = useTranslation()
    const title = (
        <StyledModalTitle>
            <InfoCircleOutlined />
            {t('recovery:modals:changeDate:title')}
        </StyledModalTitle>
    )
    return (
        <StyledModal
            width={610}
            title={title}
            open={visible}
            onOk={() => {
                setVisible(false)
                onOk()
            }}
            onCancel={() => setVisible(false)}
            closable={true}
            destroyOnClose={true}
            cancelButtonProps={{type: 'link'}}
            okText={t('recovery:modals:changeDate:ok')}
            cancelText={t('recovery:modals:changeDate:cancel')}
            centered
        >
            <Trans i18nKey={'recovery:modals:changeDate:description'} />
        </StyledModal>
    )
}
