import styled from 'styled-components'

import variables from 'ca-common/variables'
import {ACTIVITY_STATUS} from 'ca-common/common/enum/AccountActivity'

export const StyledStatusCell = styled.div<{status: ACTIVITY_STATUS}>`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    color: ${props => (props.status === ACTIVITY_STATUS.FAILED ? variables.old.redLight : variables.dark)};
`
