import {createAction, createReducer} from '@reduxjs/toolkit'

export type CalendarState = Record<string, unknown>

type CalendarActionType = {
    type: string
    payload: any
}

const SET_SNAPSHOTS = 'SET_SNAPSHOTS'
const CLEAR_SNAPSHOTS = 'CLEAR_SNAPSHOTS'

export const setCalendarSnapshots = createAction(SET_SNAPSHOTS)
export const clearCalendarSnapshots = createAction(CLEAR_SNAPSHOTS)

const setSnapshotsReducer = (state: CalendarState, action: CalendarActionType) => action.payload

export const calendar = createReducer(
    {},
    {
        SET_SNAPSHOTS: setSnapshotsReducer,
        CLEAR_SNAPSHOTS: () => ({})
    }
)
