import {createSelector} from '@reduxjs/toolkit'

import {AppState} from 'src/newcore/components/Root/newAppStore'

export const billingLineSelector = createSelector(
    (state: AppState) => state.billing.billingStatus.billingTableDataReducer,
    billingTableDataReducer => billingTableDataReducer
)

export const billingSelector = createSelector(
    (state: AppState) => state.billing.billingStatus.billingReducer,
    billingReducer => billingReducer
)

export const userEmailSelector = createSelector(
    (state: AppState) => state.userInfo,
    userInfo => userInfo.response?.email
)

export const paymentSelector = createSelector(
    (state: AppState) => state.billing.billingStatus.paymentTableDataReducer,
    paymentTableDataReducer => paymentTableDataReducer
)
