import {Input, Form} from 'antd'
import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    margin-top: ${variables.gutterMd};
`

export const StyledInput = styled(Input)`
    border-radius: ${variables.borderRadiusBase};

    &::placeholder {
        color: ${variables.navyAsphalt};
    }
`

export const StyledButtonText = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
`
