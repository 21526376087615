import React from 'react'
import PropTypes from 'prop-types'

import {CABaseTable} from 'ca-common/components/Table'
import {baseTable as baseTableShape} from 'ca-common/shapes/table'

export class CATableSelect extends React.Component {
    static propTypes = {
        ...baseTableShape,
        selectedItem: PropTypes.shape({
            id: PropTypes.string
        })
    }

    static defaultProps = {
        selectedItem: {}
    }

    render() {
        const {config, items, pageSize, fetchItems, options, onItemClick, selectedItem} = this.props

        return (
            <CABaseTable
                parent
                scrollable
                isSelected
                config={config}
                items={items}
                pageSize={pageSize}
                fetchItems={fetchItems}
                options={options}
                onItemClick={onItemClick}
                selectedItem={selectedItem}
            />
        )
    }
}
