import React, {memo, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TFunction, TFunctionResult} from 'i18next'

import Spinner from 'ca-common/icons/spinnerProgress.svg'
import {ProgressBar} from 'ca-common/ui-lib/atoms/ProgressBar'
import {SUBSOURCE_PROGRESS_STATUS} from 'ca-common/common/enum'
import type {ProgressData} from 'ca-common/types'

import {
    Content,
    SubSourceIcon,
    SubSourceIconWrapper,
    SubSourceIndicator,
    SubSourceName,
    SubSourcesRow,
    SubSourceStatus
} from './StyledMultiSourceProgressBar'
import {getDisplayName} from 'src/newcore/utils/sources'

type MultiEntityProgressBarProps = {
    progressData: ProgressData[]
}

const getSubSourceCountOrStatus = (subSource: ProgressData, t: TFunction): number | TFunctionResult => {
    switch (subSource.taskProgress.status) {
        case SUBSOURCE_PROGRESS_STATUS.INPROGRESS:
            return subSource.taskProgress.totalCount
                ? t('progressBar:jobsProgress', {
                      count: subSource.taskProgress.count,
                      total: subSource.taskProgress.totalCount
                  })
                : subSource.taskProgress.count
        case SUBSOURCE_PROGRESS_STATUS.UNAVAILABLE:
            return t('progressBar:unavailable')
        default:
            return t(`progressBar:status:${subSource.taskProgress.status}`)
    }
}

export const MultiSourceProgressBar = memo(
    ({progressData: data = []}: MultiEntityProgressBarProps): JSX.Element => {
        const {t} = useTranslation()
        const progressData = [...data]

        if (progressData.length === 0) {
            progressData.push({
                subSource: null,
                taskProgress: {
                    status: SUBSOURCE_PROGRESS_STATUS.PENDING,
                    count: 0,
                    totalCount: 0
                }
            })
        }

        const pending =
            progressData[0].subSource === null &&
            progressData[0].taskProgress.status === SUBSOURCE_PROGRESS_STATUS.PENDING

        const getProgressInfo = useMemo(() => {
            return (
                <Content>
                    {progressData?.map(subSource => {
                        return (
                            <SubSourcesRow key={subSource.subSource} status={subSource.taskProgress.status}>
                                <SubSourceIconWrapper>
                                    <SubSourceIcon status={subSource.taskProgress.status} component={Spinner} />
                                    <SubSourceName>
                                        {subSource.subSource
                                            ? getDisplayName(subSource.subSource)
                                            : t('progressBar:singleEntityItemName')}
                                    </SubSourceName>
                                </SubSourceIconWrapper>
                                <SubSourceStatus>{getSubSourceCountOrStatus(subSource, t)}</SubSourceStatus>
                            </SubSourcesRow>
                        )
                    })}
                </Content>
            )
        }, [progressData])

        const multiServicesProgressWidth = useMemo((): number => {
            const sectionWidth = 1 / progressData?.length
            let progressWidth = 0

            progressData?.forEach(el => {
                if (
                    el.taskProgress.status === SUBSOURCE_PROGRESS_STATUS.INPROGRESS ||
                    el.taskProgress.status === SUBSOURCE_PROGRESS_STATUS.DONE
                ) {
                    progressWidth += sectionWidth
                }
            })

            return progressWidth * 100 // make percents from decimal fraction
        }, [progressData])

        return (
            <ProgressBar pending={pending} progressInfo={getProgressInfo} progress={multiServicesProgressWidth}>
                {progressData?.map(subSource => {
                    return (
                        <SubSourceIndicator
                            key={subSource.subSource}
                            subSourceCount={progressData.length}
                            status={subSource.taskProgress.status}
                        />
                    )
                })}
            </ProgressBar>
        )
    }
)
