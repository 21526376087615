import React from 'react'
import isEmpty from 'lodash/isEmpty'

import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {SharepointREST} from './SharepointREST'
import {SharepointSOAP} from './SharepointSOAP'

export const Sharepoint = ({backupSettings, ...props}: BackupAuthTemplate) => {
    const isNew = isEmpty(backupSettings)
    const Component = isNew || backupSettings.useRest ? SharepointREST : SharepointSOAP

    return <Component backupSettings={backupSettings} {...props} />
}
