import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {get, post} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_RESTORE_ITEM = 'GET_RESTORE_ITEM'

const GET_RESTORE_ITEMS = 'GET_RESTORE_ITEMS'

const getRestoreItemWrapper = fetchWrapperRT(GET_RESTORE_ITEM, async data => await post('getRestoreItemById', data))
export const getRestoreItem = getRestoreItemWrapper.fetcher

const restoreItemSlice = createSlice({
    name: 'restoreItem',
    initialState: InitFetchedState,
    reducers: {
        clearRestoreItem: () => InitFetchedState
    },
    extraReducers: builder => getRestoreItemWrapper.makeReducers(builder)
})

export const {clearRestoreItem} = restoreItemSlice.actions

const getRestoreItemsWrapper = fetchWrapperRT(GET_RESTORE_ITEMS, async data => await get('getRestoreItems', data))
export const getRestoreItems = getRestoreItemsWrapper.fetcher

const restoreItemsSlice = createSlice({
    name: 'restoreItems',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getRestoreItemsWrapper.makeReducers(builder)
})

export const restoreItemsReducer = combineReducers({
    restoreItem: restoreItemSlice.reducer,
    restoreItems: restoreItemsSlice.reducer
})
