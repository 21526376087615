import React from 'react'
import {Row} from 'antd'
import {withTranslation} from 'react-i18next'

import PasswordReset from 'ca-common/icons/PasswordReset.svg'
import EmailSent from 'ca-common/icons/EmailSent.svg'
import {showMultiline} from 'ca-common/utils/text'

import {StyledHeader, StyledContentContainer} from './StyledResetPassword'
import {StyledIcon, StyledText, StyledFooterText} from './StyledPasswordSent'

export const ResetPasswordSuccess = withTranslation()(({t}) => {
    return (
        <StyledContentContainer>
            <StyledHeader>{t('passwordResetNew:passwordReset')}</StyledHeader>
            <Row type="flex" justify="center">
                <StyledIcon component={PasswordReset} />
            </Row>
            <Row type="flex" justify="center">
                <StyledText>{t('passwordSent:beenReset')}</StyledText>
            </Row>
            <Row type="flex" justify="center">
                <StyledIcon component={EmailSent} />
            </Row>
            <Row type="flex" justify="center">
                <StyledText>{showMultiline(t('passwordSent:beenSent'))}</StyledText>
            </Row>
            <Row type="flex" justify="center">
                <StyledFooterText>{t('passwordSent:footer')}</StyledFooterText>
            </Row>
        </StyledContentContainer>
    )
})
