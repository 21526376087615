import styled from 'styled-components'

import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms'

export const StyledButton = styled(ButtonHoverReverse)`
    & > span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            width: 70%;
            height: 70%;
        }
    }
`
