export enum TASK_STATUS {
    NOT_ACTIVE = 'NOT_ACTIVE',
    SCHEDULED = 'SCHEDULED',
    SUCCESS = 'SUCCESS',
    IN_PROCESS = 'IN_PROCESS',
    FAILED = 'FAILED',
    PAUSED = 'PAUSED',
    ARCHIVED = 'ARCHIVED',
    PARTIAL = 'PARTIAL',
    DUPLICATE = 'DUPLICATE'
}

export enum JOBS_STATUSES {
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
    PARTIALLY_SUCCEEDED = 'PARTIALLY_SUCCEEDED',
    PENDING = 'PENDING',
    IN_PROCESS = 'IN_PROCESS',
    CANCELLED = 'CANCELLED',
    CANCELLING = 'CANCELLING'
}
