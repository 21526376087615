import React, {useState} from 'react'
import {times} from 'lodash'
import {useTranslation} from 'react-i18next'
import {Row, Col, Input, Tooltip, Form} from 'antd'

import type {FieldData} from 'rc-field-form/lib/interface'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {Errors} from 'ca-common/atoms/Errors'
import {Footer, Code, CodeElement} from 'ca-common/forms/SignIn/StyledSignIn'
import {hasEmptyErrorsAntd4, EMPTY_ERROR_MESSAGE} from 'ca-common/utils/form'

const CODE_LENGTH = 8

type LostDeviceOwnProps = {
    onSubmit: (arg0: any) => void
    onChange: (error: string | null) => void
    error: null | string
    loading: boolean
}

export const LostDeviceForm = (props: LostDeviceOwnProps): JSX.Element => {
    const {t} = useTranslation()
    const {onSubmit, error, loading} = props

    const [hasEmptyErrors, setHasEmptyErrors] = useState(false)

    const onFieldsChange = (_: FieldData[], allValues: FieldData[]) => {
        setHasEmptyErrors(state => {
            const temporary = hasEmptyErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })
    }

    return (
        <Form layout="horizontal" onFinish={onSubmit} hideRequiredMark onFieldsChange={onFieldsChange}>
            <Row gutter={24}>
                <Col lg={24}>
                    <Form.Item
                        name="twoFaRecoveryCode"
                        rules={[
                            {
                                required: true,
                                message: EMPTY_ERROR_MESSAGE
                            }
                        ]}
                    >
                        <Input size="large" placeholder={t('forms:signInTwoFA:fields:twoFaRecoveryCode:placeholder')} />
                    </Form.Item>
                </Col>

                <Col lg={24}>
                    {error && <Errors error={error} />}
                    <Footer>
                        <Tooltip title={hasEmptyErrors && t('forms:common:fields:common:validation:required')}>
                            <Button
                                block
                                size="large"
                                color={variables.iceBlue}
                                type="primary"
                                htmlType="submit"
                                disabled={loading || hasEmptyErrors}
                            >
                                {t('sign:in:partnerPortal:recover')}
                            </Button>
                        </Tooltip>
                        <Code error={error}>
                            {times(CODE_LENGTH, code => (
                                <CodeElement key={code} error={error} />
                            ))}
                        </Code>
                    </Footer>
                </Col>
            </Row>
        </Form>
    )
}
