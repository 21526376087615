import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_REPLICATES = 'GET_REPLICATES'
const GET_REPLICATES_API = 'getReplicates'
const getReplicatesWrapper = fetchWrapperRT(GET_REPLICATES, async data => await post(GET_REPLICATES_API, data))

export const getReplicates = getReplicatesWrapper.fetcher

const replicatesSlice = createSlice({
    name: 'replicates',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearReplicates: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getReplicatesWrapper.makeReducers(builder)
})

export const {clearReplicates} = replicatesSlice.actions
export const replicatesReducer = replicatesSlice.reducer
