export const OKTALandingFormFields = {
    domain: 'domain',
    oktaDomain: 'oktaDomain'
}

export const OKTADomains = {
    com: 'okta.com',
    emea: 'okta-emea.com',
    gov: 'okta-gov.com',
    preview: 'oktapreview.com'
}

export const endpoint = '/okta'
