import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useTranslation, Trans} from 'react-i18next'
import queryString from 'query-string'
import moment from 'moment'

import {sendUserRegistration} from 'src/newcore/redux/modules/sign'

import {
    StyledThankYou,
    SendActivationLink,
    StyledIcon,
    ClickActivationLink,
    EmailSentText,
    StyledLogo,
    EmailTitle,
    StyledLink,
    Resent,
    Content
} from './StyledThankYou'

import EmailSent from 'ca-common/icons/EmailSentIconNew.svg'
import {useInterval} from 'ca-common/utils/useInterval'

const INTERVAL_SECONDS = 30
const TICK = 1000

const ThankYouPageRaw = ({actions, history, companyInfo}) => {
    const [emailSentText, setEmailSentText] = useState('')

    const [error, setError] = useState(false)

    const addTime = () => {
        return moment().add(INTERVAL_SECONDS, 'seconds').format()
    }

    const [delay, setDelay] = useState()
    const [gapTime, setGapTime] = useState()
    const [leftTime, setLeftTime] = useState(INTERVAL_SECONDS)

    const {t} = useTranslation()

    useInterval(() => {
        const diff = moment(gapTime).diff(moment())

        if (diff > TICK) {
            setLeftTime(moment(diff).format('ss'))
        } else {
            setLeftTime()
            setGapTime()
            setDelay()
        }
    }, delay)

    useEffect(() => {
        runTimer()
    }, [])

    const runTimer = () => {
        setGapTime(addTime())
        setDelay(TICK)
    }

    const sendUserRegistrationInThankYou = () => {
        const {userId} = queryString.parse(history.location.search)
        runTimer()
        actions
            .sendUserRegistration({userId})
            .then(() => {
                setEmailSentText(t('sign:up:thankyou:emailSent'))
                setError(false)
            })
            .catch(errorFromFetch => {
                setEmailSentText(errorFromFetch.status)
                setError(true)
            })
    }

    const {email} = queryString.parse(history.location.search)

    return (
        <Content>
            <StyledThankYou isCloudally={companyInfo.isCloudally}>
                {companyInfo.isCloudally && <StyledLogo />}
                <EmailTitle>{t('sign:up:thankYou:title', {companyName: companyInfo.name})}</EmailTitle>
                <SendActivationLink>
                    <Trans i18nKey="sign:up:thankYou:sendActivationLink" values={{email}}>
                        <strong>email</strong>
                    </Trans>
                </SendActivationLink>
                <StyledIcon component={EmailSent} />
                <ClickActivationLink>
                    <Trans i18nKey="sign:up:thankYou:clickActivationLink" />
                </ClickActivationLink>
                {error ? (
                    <EmailSentText error={error}>{emailSentText}</EmailSentText>
                ) : (
                    <Resent>
                        {leftTime ? (
                            t('sign:up:thankYou:resentTime', {
                                time: leftTime
                            })
                        ) : (
                            <Trans i18nKey="sign:up:thankYou:resentEmailLink">
                                <StyledLink onClick={sendUserRegistrationInThankYou}>Click Here</StyledLink>
                            </Trans>
                        )}
                    </Resent>
                )}
            </StyledThankYou>
            <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2109321&vkey=79c00783c3050b3a6b52b9e500f30e93" />
        </Content>
    )
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            sendUserRegistration
        },
        dispatch
    )
})

export const ThankYou = connect(mapStateToProps, mapDispatchToProps)(ThankYouPageRaw)
