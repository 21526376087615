import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {StyledModal, StyledTitle} from './StyledModalView'

type ModalProps = {
    modalVisible: boolean
    closeModal: () => any
    modalData: Record<string, any>
    modalWidth: number
    onSubmit: () => any
}

export const ModalView = ({modalVisible, closeModal, modalData, modalWidth, onSubmit}: ModalProps) => {
    const {t} = useTranslation()
    const {buttonTitle, title, description} = modalData

    return (
        <StyledModal
            centered
            width={modalWidth}
            open={modalVisible}
            destroyOnClose={true}
            onCancel={() => closeModal()}
            title={<StyledTitle>{title}</StyledTitle>}
            footer={[
                <Button type="link" key="cancel" onClick={() => closeModal()}>
                    {t('modals:buttons:cancel')}
                </Button>,
                <Button type="primary" key="submit" onClick={() => onSubmit()}>
                    {buttonTitle}
                </Button>
            ]}
        >
            {description}
        </StyledModal>
    )
}
