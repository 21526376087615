import {useEffect, useRef} from 'react'
import {TODO_ANY} from 'ca-common/types'

export function usePrevious<T extends TODO_ANY>(value: T, conditional?: (value: T) => boolean): T | undefined {
    const ref = useRef<T>()
    useEffect(() => {
        if (conditional) {
            if (conditional(value)) {
                ref.current = value
            }
        } else {
            ref.current = value
        }
    }, [value])
    if (ref.current !== undefined) return ref.current
}
