import {SOURCE} from 'ca-common/common/enum'
import type {MS365SubSource} from 'ca-common/types'

export const getIsExpandable = (subSource: MS365SubSource, hasPermissionToExpand?: boolean) => {
    if (!hasPermissionToExpand) {
        return false
    }

    switch (subSource) {
        case SOURCE.O365MAIL:
        case SOURCE.O365CALENDAR:
        case SOURCE.O365CONTACTS:
        case SOURCE.O365NOTES:
            return true
        default:
            return false
    }
}
