import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import './TableElements.scss'

export const dumbTableWrapper = dumbBem('ca-table-wrapper')
export const CATableWrapper = tx(dumbTableWrapper)('div')

export const dumbTable = dumbBem('ca-table')
export const CATable = tx(dumbTable)('div')
export const CABody = tx([{element: 'body'}, dumbTable])('div')
export const CAScrollableWrapper = tx([{element: 'scrollable-wrapper'}, dumbTable])('div')
export const CARowWrapper = tx([{element: 'row'}, dumbTable])('div')
export const CARowItems = tx([{element: 'row-items'}, dumbTable])('div')
export const CARowIndicator = tx([{element: 'row-indicator'}, dumbTable])('div')
export const CARowCollapse = tx([{element: 'row-collapse'}, dumbTable])('div')
export const CACellWrapper = tx([{element: 'cell'}, dumbTable])('div')
export const CAPageControl = tx([{element: 'page-control'}, dumbTable])('div')
export const CACurrentPage = tx([{element: 'current-page'}, dumbTable])('input')
export const CAPaginationInfo = tx([{element: 'pagination-info'}, dumbTable])('span')

export const dumbCellModifier = dumbBem('ca-cell-modifier')
export const CACellModifier = tx(dumbCellModifier)('div')

const dumbTableActions = dumbBem('table-actions')
export const TableActions = tx([{element: 'wrapper'}, dumbTableActions])('div')
export const TableActionNew = tx([{element: 'link'}, dumbTableActions])('div')
export const TableActionTitle = tx([{element: 'title'}, dumbTableActions])('h2')

export const dumbSelectAll = dumbBem('ca-select-all')
export const CASelectAllWrapper = tx(dumbSelectAll)('div')
