import React, {useEffect, useRef, useState} from 'react'
import {Spin} from 'antd'

import {postLessData} from 'src/newcore/utils/rest'
import {ServletResponse} from 'ca-common/types'
import {openErrorNotification} from 'ca-common/utils/toasts'

export type GoogleSignInProps = {
    googleClientId: string
    callback: (arg0: string) => void
    onCancel: () => void
}

const G_SCRIPTID = 'google-client-script'
const G_SCRIPTSRC = 'https://accounts.google.com/gsi/client'
const G_COMMANDNAME = 'execGoogleStartTask'
const EXPIRATION_TIME = 5 * 60 * 1000

export const GoogleSignIn = ({googleClientId, callback, onCancel}: GoogleSignInProps): JSX.Element => {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const ref = useRef(null)

    const gCallback = (response: CredentialResponse) => {
        setLoading(true)
        postLessData(G_COMMANDNAME, response)
            .then((response: ServletResponse) => {
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl
                    onCancel()
                }
            })
            .catch((e: ServletResponse) => {
                if (e.status === 'No Permission.') {
                    callback(e.redirectUrl as string)
                } else {
                    onCancel()
                    openErrorNotification(e.status)
                }
            })
            .finally(() => setLoading(false))
    }

    const getDomain = (hostname: string) => {
        const hostnameArray = hostname.split('.')
        hostnameArray.reverse()
        return `${hostnameArray[1]}.${hostnameArray[0]}`
    }

    const writeCookie = () => {
        const dt = new Date()
        dt.setTime(dt.getTime() + EXPIRATION_TIME)
        const expires = `expires=${dt.toUTCString()}`
        const initDomain = `initDomain=${document.domain}`
        const domain = `domain=${getDomain(document.domain)}`
        const path = 'path=/'
        document.cookie = `${initDomain}; ${expires}; ${domain}; ${path}`
    }

    useEffect(() => {
        writeCookie()

        if (gsiScriptLoaded) return

        const initializeGsi = () => {
            if (!window.google || gsiScriptLoaded) return

            setGsiScriptLoaded(true)
            window.google.accounts.id.initialize({
                client_id: googleClientId,
                callback: gCallback,
                context: 'use',
                ux_mode: 'popup'
            })

            window.google.accounts.id.renderButton(ref.current, {
                type: 'standard',
                theme: 'outline',
                size: 'medium',
                text: 'signin_with'
            })

            window.google.accounts.id.disableAutoSelect()
        }

        const script = document.createElement('script')
        script.src = G_SCRIPTSRC
        script.onload = initializeGsi
        script.async = true
        script.id = G_SCRIPTID
        document.querySelector('body')?.appendChild(script)

        return () => {
            window.google?.accounts.id.cancel()
            document.getElementById(G_SCRIPTID)?.remove()
        }
    }, [])

    return (
        <Spin size="small" spinning={loading}>
            <div ref={ref} />
        </Spin>
    )
}
