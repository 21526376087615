import styled from 'styled-components'
import {rgba} from 'polished'

import variables from 'ca-common/variables'

export const StyledIntroduction = styled.div`
    border-radius: ${variables.borderRadiusBase};
    background: ${variables.white};
    box-shadow: 0 0 10px ${rgba(variables.shadowBaseColor, 0.08)};
    padding: ${variables.gutterSm} ${variables.gutterMd} ${variables.gutterSm} ${variables.gutterSm};
`

export const StyledBody = styled.div`
    margin-top: ${variables.gutterSm};
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 400;
    font-size: ${variables.fontSizeBase};
    line-height: 140%;
    color: ${variables.navyAsphalt};
`
