import {post} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_BACKUP_PROGRESS = 'GET_BACKUP_PROGRESS'
const GET_BACKUP_PROGRESS_API = 'getBackupProgress'

const getBackupProgressWrapper = fetchWrapperRT(
    GET_BACKUP_PROGRESS,
    async data => await post(GET_BACKUP_PROGRESS_API, data)
)
export const getBackupProgress = getBackupProgressWrapper.fetcher

const backupProgressSlice = createSlice({
    name: 'backupProgress',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getBackupProgressWrapper.makeReducers(builder)
})

export const backupProgress = backupProgressSlice.reducer
