import styled from 'styled-components'

import variables from 'ca-common/variables'
import {SearchField} from 'ca-common/ui-lib/atoms/SearchField'

import {Row} from 'src/newcore/components/Seeding/DataAnonymizationStep/StyledDataAnonymizationStep'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
`

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 400px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: ${variables.gutterXs};
    padding-right: ${variables.gutterXs};
`

export const TableHeader = styled(Row)`
    border: none;
    padding: 0 ${variables.gutterLg} 0 ${variables.gutterXs};
    margin-bottom: ${variables.gutterXs};
`

export const Title = styled.span`
    font-weight: 500;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
`

export const StyledSearchField = styled(SearchField)`
    margin-bottom: ${variables.gutterSm};
`

export const SelectWrapper = styled.div`
    & > div {
        margin-left: -11px;
    }

    .ant-select-selection-item {
        color: ${variables.text};
        font-weight: 400;
    }
`
