import styled from 'styled-components'
import {Radio} from 'antd'
import variables from 'ca-common/variables'

const {Group} = Radio

const Block = styled.div`
    padding: 0 ${variables.gutterMd};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Header = styled.div`
    padding: ${variables.gutterMd} ${variables.gutterMd} 0;
    display: flex;
    align-items: baseline;
`

export const HeaderTitle = styled.h2`
    font-size: 18px;
    margin: 0;
`

export const Footer = styled(Block)`
    height: 60px;
`

export const Body = styled.div`
    border-bottom: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};
`

export const StyledInfo = styled.div`
    display: flex;
    align-items: baseline;
    color: ${variables.asphalt};
`

export const Text = styled.span`
    margin-left: ${variables.gutterXs};
`

export const RadioGroup = styled(Group)`
    display: flex;
    width: 100%;
    justify-content: space-between;
`
