import styled from 'styled-components'

import variables from 'ca-common/variables'

export const ContentWrapper = styled.div`
    padding-top: 80px;
    background: linear-gradient(#f0f4f7, #fefefe) center;
    display: flex;
    flex-direction: column;
    font-family: ${variables.publicPageFont};
`
