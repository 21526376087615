import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)<{status: string}>`
    font-size: 34px;
    padding: 0 20px;

    color: ${props => {
        switch (props.status) {
            case 'complete':
            case 'upcoming':
                return variables.old.green
            case 'overdue':
            case 'failed':
                return variables.old.redLight
            case 'no-tasks':
                return variables.old.blueLight
            case 'nobill':
            default:
                return variables.old.gray
        }
    }};
`

export const StyledPageStatus = styled.div<{status: string; titleParameters?: Record<string, any>}>`
    padding: 30px 0;
    display: flex;
    width: 100%;
    justify-content: ${props => {
        if (props.status == 'nobill' && props.titleParameters) {
            return null
        }
        return 'center'
    }};
    align-items: ${props => {
        if (props.status == 'nobill' && props.titleParameters) {
            return null
        }
        return 'center'
    }};
    p {
        font-size: ${props => {
            if (props.status == 'nobill' && props.titleParameters) {
                return variables.gutterLg
            }
            return variables.gutterXxl
        }};
        font-weight: ${props => {
            if (props.status == 'nobill' && props.titleParameters) {
                return 700
            }
            return 300
        }};
        margin-bottom: ${props => {
            if (props.status == 'nobill' && props.titleParameters) {
                return 'auto'
            }
            return 0
        }};
        font-family: ${props => {
            if (props.status == 'nobill' && props.titleParameters) {
                return variables.publicPageFont
            }
        }};
        color: ${props => {
            switch (props.status) {
                case 'complete':
                case 'upcoming':
                case 'succeed':
                    return variables.old.green
                case 'overdue':
                case 'failed':
                    return variables.old.redLight
                case 'no-tasks':
                case 'no-accounts':
                    return variables.old.blueLight
                case 'nobill':
                    if (props.titleParameters) {
                        return variables.black
                    }
                    break
                default:
                    return variables.old.gray
            }
        }};
    }
    span {
        font-weight: 400;
        font-size: 16px;
        font-family: 'Lato';
        line-height: 20.8px;
    }
`
