import React, {useState} from 'react'
import {SearchOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import _ from 'lodash'
import {bindActionCreators} from '@reduxjs/toolkit'

import {RoundedInput} from 'ca-common/ui-lib/components/FormItem'
import {Button} from 'ca-common/ui-lib/atoms/Button'

import {DeselectOverlay, FilteringListWrapper, ListWrapper} from './Styled'
import {toggleActiveObject, toggleDeletedObject, deselectObjects} from 'src/newcore/redux/modules/backups/seeding'
import {ObjectItem} from './ObjectItem'
import {statusFormat} from 'src/newcore/utils/seeding'

export const ObjectsFilteringListRaw = props => {
    const {objectsList, actions} = props
    const [search, setSearch] = useState('')
    const {t} = useTranslation()

    const onChange = e => {
        setSearch(e.target.value)
    }

    const handleDeleteObject = (e, name) => {
        e.stopPropagation()
        actions.toggleDeletedObject(name)
    }

    const handleActivateObject = (name, isDeleted) => {
        if (!isDeleted) {
            actions.toggleActiveObject(name)
        }
    }

    const checkedObjects = _.get(objectsList, 'response.data')
    const anySelected = _.some(checkedObjects, {isActive: true})

    return (
        <FilteringListWrapper>
            <RoundedInput
                prefix={<SearchOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                onChange={onChange}
                placeholder={t('seeding:thirdStep:objectsSearch')}
            />
            <ListWrapper>
                {_.map(
                    checkedObjects,
                    (object, name) =>
                        _.includes(name.toLowerCase(), search.toLowerCase()) && (
                            <ObjectItem
                                name={name}
                                key={name}
                                status={statusFormat(t, object.filter)}
                                isDeleted={object.isDeleted}
                                isActive={object.isActive}
                                onDelete={e => handleDeleteObject(e, name)}
                                onClick={() => handleActivateObject(name, object.isDeleted)}
                            />
                        )
                )}
                {anySelected && (
                    <DeselectOverlay>
                        <Button onClick={() => actions.deselectObjects()}>{t('seeding:fourthStep:deselect')}</Button>
                    </DeselectOverlay>
                )}
            </ListWrapper>
        </FilteringListWrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            toggleActiveObject,
            toggleDeletedObject,
            deselectObjects
        },
        dispatch
    )
})

const mapStateToProps = state => ({
    objectsList: _.get(state, 'backups.seeding.objectsList', [])
})

export const ObjectsFilteringList = withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectsFilteringListRaw))
