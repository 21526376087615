import React from 'react'

import {Source} from 'src/newcore/components/DeltaChart/Source'

import {StyledRow, Counts} from './StyledRow'

export const Row = ({value, source}) => {
    if (value === 0) {
        return null
    }

    return (
        <StyledRow>
            <Source source={source} />
            <Counts>{`${value} new`}</Counts>
        </StyledRow>
    )
}
