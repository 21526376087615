import React from 'react'

import InProgress from 'ca-common/icons/jobs/inProgress.svg'
import Completed from 'ca-common/icons/jobs/completed.svg'
import Failures from 'ca-common/icons/jobs/failedPartialSuccess.svg'
import {JOBS_STATUSES} from 'ca-common/common/enum'
import type {IconComponent} from 'ca-common/types'

import {getDashboardStatusFriendly, JOBS_DASHBOARD_STATUSES} from 'src/newcore/features/Jobs/lib'

import {StatusCount, StatusIcon, StatusText, StatusTextWrapper, StyledStatusTile} from './StyledStatusTile'

type StatusTileProps = {
    status: JOBS_DASHBOARD_STATUSES
    count: string
    onClick: any
    disabled: boolean
}

const statusIcon: Record<JOBS_DASHBOARD_STATUSES, IconComponent> = {
    [JOBS_STATUSES.IN_PROCESS]: InProgress,
    [JOBS_STATUSES.SUCCEEDED]: Completed,
    [JOBS_STATUSES.FAILED]: Failures
}

export const StatusTile = (props: StatusTileProps): JSX.Element => {
    const {status, count, ...passProps} = props

    return (
        <StyledStatusTile type="button" {...passProps}>
            <StatusIcon component={statusIcon[status]} />
            <StatusTextWrapper>
                <StatusCount>{count}</StatusCount>
                <StatusText>{getDashboardStatusFriendly(status)}</StatusText>
            </StatusTextWrapper>
        </StyledStatusTile>
    )
}
