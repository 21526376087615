import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {AdvantageRow} from 'src/newcore/features/SignUp/atoms/Advantage'

import {
    Subtitle,
    StyledDescriptionBlock,
    FirstTitle,
    SecondTitle,
    CenteredContent,
    AdvantagesWrapper
} from './StyledDescriptionBlock'

import {CompanyInfoType} from 'ca-common/types'

export const DescriptionBlock = ({companyInfo}: {companyInfo: CompanyInfoType}): JSX.Element => {
    const {t} = useTranslation()
    const {zix} = companyInfo
    return (
        <StyledDescriptionBlock>
            <CenteredContent>
                <Subtitle>{t('sign:up:titles:subtitle')}</Subtitle>
                <FirstTitle>
                    <Trans i18nKey="sign:up:titles:first">
                        <div>text</div>
                    </Trans>
                </FirstTitle>
                <SecondTitle>
                    <Trans i18nKey="sign:up:titles:thirdSubtitle">
                        <strong>text</strong>
                    </Trans>
                </SecondTitle>
                <AdvantagesWrapper>
                    <div>
                        {!zix && <AdvantageRow text={t('sign:up:advantages:trial')} />}
                        <AdvantageRow text={t('sign:up:advantages:noCard')} />
                        <AdvantageRow text={t('sign:up:advantages:setup')} />
                    </div>
                </AdvantagesWrapper>
            </CenteredContent>
        </StyledDescriptionBlock>
    )
}
