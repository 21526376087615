import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {CompanyInfoType, UserInfoDataType} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum/Source'

import {AddBackup} from 'src/newcore/features/AddBackup'
import {StyledIndividualServiceName} from 'src/newcore/features/NewHomePage/NewHomeServiceButton/StyledNewHomeServiceButton'
import {isOCAVisible} from 'src/newcore/features/AvailableSourceList/lib/isOCAVisible'
import {useOCAEnabled} from 'src/newcore/features/Flags'
import {OCAButtonWithModal} from 'src/newcore/features/OneClickActivation'
import {NewHomeServiceButton} from 'src/newcore/features/NewHomePage/NewHomeServiceButton/NewHomeServiceButton'
import {
    StyledColSpace,
    StyledContainerContents,
    StyledSubLine,
    StyledSubText,
    StyledHomeContainer,
    StyledHomeContentContainer
} from 'src/newcore/features/NewHomePage/StyledNewHomepage'

export const HomeContent = (props: {
    userInfo: UserInfoDataType
    companyInfo: CompanyInfoType

    services: {
        key: any
        value: []
    }
}) => {
    const {t} = useTranslation()
    const {services} = props
    const [backupAuthVisible, setBackupAuthVisible] = useState(false)
    const [pickedSource, setPickedSource] = useState<SOURCE | undefined>(undefined)

    const toggleVisible = () => setBackupAuthVisible(!backupAuthVisible)

    const handleClick = (source: SOURCE) => {
        setBackupAuthVisible(true)
        setPickedSource(source)
    }

    const isOCAEnabled = useOCAEnabled()

    const rowList = Object.entries(services).map(([key, value]) => {
        return (
            <StyledHomeContentContainer flexCondition={Object.keys(services).length}>
                <StyledIndividualServiceName>
                    {t(`homepage:newHomePage:${key}:displayName`)}
                </StyledIndividualServiceName>
                {isOCAEnabled && isOCAVisible(value) && (
                    <>
                        <OCAButtonWithModal availableServices={Object.values(value)} />
                        <StyledSubText>{t(`homepage:alternateText`)}</StyledSubText>
                    </>
                )}
                <StyledColSpace />
                <StyledContainerContents>
                    {value.map((item: SOURCE, index: number) => (
                        <>
                            <NewHomeServiceButton key={index} service={item} onClick={handleClick} />
                            {index < value.length - 1 && <StyledSubLine />}
                        </>
                    ))}
                </StyledContainerContents>
            </StyledHomeContentContainer>
        )
    })

    return (
        <StyledHomeContainer flexVal={Object.keys(services).length}>
            {rowList}
            {pickedSource && (
                <AddBackup source={pickedSource} visible={backupAuthVisible} toggleVisible={toggleVisible} />
            )}
        </StyledHomeContainer>
    )
}
