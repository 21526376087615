import React, {Component} from 'react'
import {Tooltip} from 'antd'

import {SFCBackupPropTypes, SFCBackupDefaultProps} from 'ca-common/shapes/SFCBackupPropTypes'

import {compareDateDescription} from 'src/newcore/utils/backups'

import {SFCBox, SFCBoxTitle, SFCAlias} from './StyledSFCBackup'

export class SFCBackup extends Component {
    static propTypes = SFCBackupPropTypes
    static defaultProps = SFCBackupDefaultProps

    render() {
        const {type, isSandbox, date, account} = this.props

        return (
            <SFCBox>
                <SFCAlias>
                    <Tooltip title={account}>{account}</Tooltip>
                </SFCAlias>
                <SFCBoxTitle>{type}</SFCBoxTitle>
                {compareDateDescription({date, isSandbox})}
            </SFCBox>
        )
    }
}
