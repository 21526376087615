import React from 'react'
import {useTranslation} from 'react-i18next'
import {Input, Tooltip} from 'antd'

import {
    StyledReviewWrapper,
    StyledLabel,
    StyledRow,
    StyledInputWrapper,
    StyledHeader,
    StyledInnerWrapper,
    StyledIcon,
    StyledErrorIcon,
    StyledError
} from './StyledAlertReview'

import {
    TRIGGERED_BY_SINGLE,
    getSmartAlertEventFriendly,
    getSmartAlertUnitFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import AddNewAlert from 'ca-common/icons/smartAlerts/AddNewAlert.svg'
import CloseIcon from 'ca-common/icons/closeIcon.svg'

export const AlertReview = ({wizardData, error}) => {
    const {t} = useTranslation()
    const {triggeredBy, config, specificObject} = wizardData

    const triggeredByValue =
        triggeredBy === TRIGGERED_BY_SINGLE
            ? t(`smartAlerts:triggeredBy:singleUser`)
            : t('smartAlerts:triggeredBy:allUsers')

    const eventLabel = specificObject
        ? t(`smartAlerts:wizard:review:eventSpecific`, {specificObject})
        : t('smartAlerts:wizard:review:eventAll')

    return (
        <StyledReviewWrapper>
            <StyledHeader>{t('smartAlerts:wizard:thirdStepTitle')}</StyledHeader>
            <StyledInnerWrapper>
                <div>
                    <StyledRow>
                        <StyledLabel>{t('smartAlerts:wizard:review:moreThan')}</StyledLabel>
                        <StyledInputWrapper>
                            <Input value={config?.amount} disabled />
                            <Input value={getSmartAlertUnitFriendly(config?.unit)} disabled />
                        </StyledInputWrapper>
                    </StyledRow>
                    <StyledRow>
                        <Tooltip title={specificObject ? eventLabel : null}>
                            <StyledLabel>{eventLabel}</StyledLabel>
                        </Tooltip>
                        <StyledInputWrapper>
                            <Input value={getSmartAlertEventFriendly(config?.event)} disabled />
                        </StyledInputWrapper>
                    </StyledRow>
                    <StyledRow>
                        <StyledLabel>{t('smartAlerts:wizard:review:triggeredBy')}</StyledLabel>
                        <StyledInputWrapper>
                            <Input value={triggeredByValue} disabled />
                        </StyledInputWrapper>
                    </StyledRow>
                </div>
                <StyledIcon component={AddNewAlert} />
            </StyledInnerWrapper>
            {error && (
                <StyledError>
                    <StyledErrorIcon component={CloseIcon} />
                    {error}
                </StyledError>
            )}
        </StyledReviewWrapper>
    )
}
