import styled from 'styled-components'
import {transparentize} from 'polished'

import variables from 'ca-common/variables'

export const DetailedInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: ${variables.gutterSm};
    justify-content: center;
    background: ${transparentize(0.5, variables.newBlueLight)};
    border-radius: ${variables.borderRadiusBlocks};
    padding: 10px 12px;
    margin-bottom: ${variables.gutterSm};
    @media screen and (min-width: ${variables.screenXl}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`
