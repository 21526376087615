import styled, {css} from 'styled-components'
import {Calendar} from 'antd'
import {green, orange, navyBlue, borderRadiusBase, gutterXs} from 'ca-common/variables'

type DotColor = typeof green | typeof orange
type CalendarDotsProps = {
    left?: DotColor
    right?: DotColor
}

export const StyledCalendarDotsCell = styled.div<CalendarDotsProps>`
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 12px;
    margin: 0 auto;
    bottom: -${gutterXs};
    left: 50%;
    transform: translateX(-50%);

    ${props =>
        props.left &&
        css`
            &::before {
                content: '';
                width: 4px;
                height: 4px;
                background: ${props.left};
                border-radius: 50%;
            }
        `}

    ${props =>
        props.right &&
        css`
            &::after {
                content: '';
                margin-left: 4px;
                width: 4px;
                height: 4px;
                background: ${props.right};
                border-radius: 50%;
            }
        `}
`

export const StyledCalendar = styled(Calendar)`
    width: 339px;

    .ant-picker-panel {
        border-top: none;
        padding: 0 ${gutterXs};

        .ant-picker-cell-selected .ant-picker-cell-inner.ant-picker-calendar-date {
            background: ${navyBlue};
        }

        .ant-picker-date-panel .ant-picker-cell-inner.ant-picker-calendar-date,
        .ant-picker-date-panel .ant-picker-cell-inner.ant-picker-calendar-date::before {
            border-radius: 50%;
        }

        .ant-picker-month-panel {
            .ant-picker-cell-inner.ant-picker-calendar-date {
                border-radius: ${borderRadiusBase};
            }
        }

        th {
            color: ${navyBlue};
        }
    }
`
