import React from 'react'
import {useTranslation} from 'react-i18next'
import {FormInstance} from 'antd'

import {ACTIVITY_TYPE} from 'ca-common/common/enum/AccountActivity'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import Button from 'ca-common/ui-lib/components/Button'
import {createExportActivityTask} from 'src/newcore/redux/modules/accountActivity'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {timeZone} from 'ca-common/utils/datetime'

import {prepareFormValues} from 'src/newcore/features/SecurityAudit/lib/prepareFormValues'
import {SecurityAuditFormValues} from 'src/newcore/features/SecurityAudit/types'

type SecurityAuditExportProps = {
    securityAuditForm: FormInstance<SecurityAuditFormValues>
}

export const SecurityAuditExport = (props: SecurityAuditExportProps) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {securityAuditForm} = props
    const securityAuditStatus = useAppSelector((state: AppState) => state.securityAudit.status)

    const submitExport = () => () => {
        const data = {
            filter: prepareFormValues(securityAuditForm.getFieldsValue()),
            activityType: ACTIVITY_TYPE.SECURITY_AUDIT,
            timeZone
        }
        dispatch(createExportActivityTask(data))
            .then(() => {
                openSuccessNotification(
                    t(`accountActivity:exportActivatedSuccessfully:${ACTIVITY_TYPE.SECURITY_AUDIT}`)
                )
            })
            .catch((response: any) => {
                openErrorNotification(response.message)
            })
    }
    return (
        <div>
            <Button
                modifier="secondary"
                type="button"
                onClick={submitExport()}
                disabled={securityAuditStatus === 'fullfilled'}
            >
                {t('accountActivity:exportButton')}
            </Button>
        </div>
    )
}
