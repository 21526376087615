import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import PropTypes from 'prop-types'

import {
    getBackupEntityDelta,
    clearBackupEntityDelta
} from 'src/newcore/redux/modules/backups/backupDrilldown/deltaChart'

import {Chart} from './Chart'

class DeltaChartRaw extends Component {
    static propTypes = {
        entityId: PropTypes.string.isRequired
    }

    componentDidMount() {
        const {
            actions,
            taskSettings: {taskId, source},
            entityId
        } = this.props

        actions.getBackupEntityDelta({
            taskId,
            source,
            entityId
        })
    }

    componentWillUnmount() {
        const {actions} = this.props

        actions.clearBackupEntityDelta()
    }

    render() {
        const {backupEntityDelta} = this.props

        return <Chart data={backupEntityDelta.data} />
    }
}

const mapStateToProps = state => ({
    backupEntityDelta: {
        ...state.backups.backupDrilldown.backupEntityDelta.response,
        status: state.backups.backupDrilldown.backupEntityDelta.status
    },
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getBackupEntityDelta,
            clearBackupEntityDelta
        },
        dispatch
    )
})

export const DeltaChart = connect(mapStateToProps, mapDispatchToProps)(DeltaChartRaw)
