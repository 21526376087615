import React from 'react'
import {Table} from 'antd'
import type {TableProps} from 'antd/lib/table/Table'

import {transparentize} from 'polished'
import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledRecoveryItemsTable: React.FunctionComponent<TableProps<any>> = styled(Table)`
    height: 100%;

    .ant-table-container {
        min-height: 692px;
    }

    .ant-table-column-title {
        white-space: nowrap;
    }

    .ant-table-thead {
        max-height: 56px;
        height: 56px;

        > tr {
            height: 56px;
        }

        .ant-table-column-sorters {
            justify-content: flex-start;
        }

        .ant-table-column-sorter {
            margin-left: ${variables.gutterXs};
        }
    }

    .ant-table-thead {
        span.ant-table-column-title,
        th.ant-table-cell {
            font-weight: 600;
            flex-basis: content;
            flex-grow: 0;
        }
        .ant-table-column-sorters {
            justify-content: flex-start;
        }

        > tr {
            > th {
                background: ${variables.white};
                font-weight: 600;
                font-size: ${variables.fontSizeBase};
                color: ${variables.navyAsphalt};
                border-color: ${variables.navyBorder};
            }
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                content: none;
            }
        }
    }

    .ant-table-tbody > tr > td {
        border-bottom: none;
    }

    .ant-table-tbody > tr.ant-table-expanded-row > td {
        background-color: ${variables.interaction5};
        padding: ${variables.gutterSm} 66px;
    }

    ul > li.ant-pagination-item {
        border: 1px solid;
        box-sizing: border-box;
        border-radius: ${variables.borderRadiusBase};
    }
    ul > li.ant-pagination-item.ant-pagination-item-active {
        background: ${variables.brightBlue};
        border-color: ${variables.brightBlue};
        a {
            color: ${variables.white};
        }
    }

    .ant-table-tbody {
        background: ${variables.white};
        font-size: ${variables.fontSizeSecondary};
        color: ${variables.black};
        font-weight: normal;

        a {
            color: ${variables.navyBlue};
            font-weight: 600;
            font-size: ${variables.fontSizeBase};
            text-decoration: underline;
        }

        tr > td {
            background: none;
        }
        tr.table-row-blue:not(.expand-parent) {
            background: ${transparentize(0.95, variables.inProcess)};
        }
        tr > td:last-child {
            color: ${variables.navyBlue};
            padding-right: 26px;
        }

        .ant-table-placeholder > td {
            padding-top: 190px;
        }

        .expand-parent,
        .expand-parent:hover {
            background-color: ${variables.interaction};

            .ant-table-cell-row-hover {
                background-color: ${variables.interaction};
            }
        }
    }
    .ant-table-pagination {
        margin: 16px 150px 16px 0;
        .ant-pagination-total-text {
            color: ${variables.navyBlue};
            margin-right: auto;
        }
        .ant-pagination-next,
        .ant-pagination-prev {
            width: 76px;
            height: 32px;
            border: 1px solid ${variables.navyBorder};
            background: ${variables.white};
            border-radius: ${variables.borderRadiusBase};
            a {
                color: ${variables.navyBlue};
            }
            :focus:not(.ant-pagination-disabled) {
                border-color: ${variables.navyBlue};
            }
            :hover:not(.ant-pagination-disabled) {
                border-color: ${variables.navyBlue};
                transition: all 0.3s;
            }
        }
        .ant-pagination-item.ant-pagination-item-active {
            background: ${variables.navyBlue};
            border: 0;

            a {
                color: ${variables.white};
            }
        }
        .ant-pagination-item {
            border-radius: ${variables.borderRadiusBase};
            a {
                color: ${variables.navyBlue};
            }
        }
    }
`
export const StyledFromSpan = styled.span`
    font-size: 14px;
    color: ${variables.navyBlue};
`
