import React from 'react'
import {connect} from 'react-redux'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'
import {AppState} from 'src/newcore/components/Root'

type DDBProps = BackupAuthTemplate & ReturnType<typeof mapStateToProps>

const DDBRaw = ({source, backupSettings, onSubmit, onCancel, userInfo}: DDBProps): JSX.Element => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const {t} = useTranslation()
    const initialValues = {
        ddbregion: backupSettings.dbRegion || userInfo.response.region,
        alias: backupSettings.alias,
        ddbkey: backupSettings.domain
    }

    return (
        <StyledBackupAuthForm initialValues={initialValues} layout="horizontal" onFinish={values => onSubmit(values)}>
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>

                    <Col>
                        <Form.Item
                            name="ddbregion"
                            label={t('forms:backupAuth:fields:ddbRegion.label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:validation:required')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="ddbkey"
                            label={t('forms:backupAuth:fields:ddbAccessKey.label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:validation:required')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="ddbsecret"
                            label={t('forms:backupAuth:fields:ddbSecretKey.label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:fields:password:validation:required')
                                }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:common:actions:apply')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}

const mapStateToProps = (state: AppState) => ({userInfo: state.userInfo})

export const DDB = connect(mapStateToProps)(DDBRaw)
