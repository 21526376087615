import {REGION, REGION_INFO} from 'ca-common/common/enum/Region'
import i18n from 'i18next'

export const getDataCenters = (): REGION[] => Object.values(REGION).filter(value => REGION_INFO[value].isActive)

export const getFriendlyRegion = (region: REGION) => {
    return i18n.t(`REGION:${region}`)
}

export const getOptionsFromDataCenters = (): any[] =>
    getDataCenters().map(value => ({
        value,
        label: getFriendlyRegion(value)
    }))
