import React, {useMemo, useEffect, useCallback, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useLocation, useHistory, useParams} from 'react-router'
import {isEmpty, pickBy, isEqual, pick} from 'lodash'
import {useTranslation} from 'react-i18next'

import {getUrlParams, makeUrlParams} from 'ca-common/utils/url'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {AddNewButton} from 'ca-common/ui-lib/atoms/AddNewButton'
import {openWizard} from 'src/newcore/redux/modules/wizard'
import {WIZARD_TYPE} from 'ca-common/constants'
import {SmartAlertDeleteConfirmation} from 'src/newcore/molecules/SmartAlertDeleteConfirmation'

import {ACTION_EDIT, FIELD_SEARCH_QUERY, FIELD_TRIGGERED_BY, FIELD_EVENTS, ACTION_DELETE} from './lib'
import {StyledWrapped} from './atoms'
import {SmartAlertsTable, DEFAULT_PAGE, PAGE_SIZE, SmartAlertsHeader, SmartAlertEdit} from './organisms'
import {MultiActions} from './molecules'
import {
    getSfcSmartAlerts as fetcher,
    tableAllSelectedActions,
    tableSelectedItemsActions,
    execSfcSmartAlert,
    editSfcSmartAlert,
    updateSfcSmartAlertsLocal
} from './redux'
import {track} from 'src/newcore/utils/mixpanel'

const SmartAlertsRaw = props => {
    const {sfcSmartAlerts, actions, tableAllSelected, tableSelectedItems} = props

    const location = useLocation()
    const history = useHistory()
    const {taskId} = useParams()
    const {t} = useTranslation()

    const [waitingCompletionAction, setWaitingCompletionAction] = useState(false)
    const [editPayload, setEditPayload] = useState(null)
    const [visibleDeleteConfirmation, setVisibleDeleteConfirmation] = useState(false)

    const loading = useMemo(() => 'pending' === sfcSmartAlerts.status, [sfcSmartAlerts.status])
    const currentUrlParams = useMemo(() => getUrlParams(location.search), [location.search])
    const filterValues = useMemo(() => pick(currentUrlParams, [FIELD_EVENTS, FIELD_SEARCH_QUERY, FIELD_TRIGGERED_BY]), [
        currentUrlParams
    ])

    const getSfcSmartAlerts = useCallback(
        async ({page, ...data} = {}) => {
            const fetchParams = {
                page: page || DEFAULT_PAGE,
                limit: PAGE_SIZE,
                taskId,
                ...currentUrlParams,
                ...data
            }

            try {
                await actions.fetcher(fetchParams)
            } catch (error) {
                openErrorNotification(error?.status || error)
            }
        },
        [currentUrlParams]
    )

    const changeURLSearchParams = useCallback(
        params => {
            const newParams = pickBy(
                {
                    ...currentUrlParams,
                    ...params
                },
                i => !isEmpty(i)
            )

            if (!isEqual(currentUrlParams, newParams)) {
                actions.clearTableSelectedItems()
                history.push({
                    search: makeUrlParams(newParams)
                })
            }
        },
        [currentUrlParams]
    )

    const execAction = async params => {
        try {
            setWaitingCompletionAction(true)
            await actions.execSfcSmartAlert(params)
            await getSfcSmartAlerts()
        } catch (error) {
            openErrorNotification(error?.status || error)
        } finally {
            setWaitingCompletionAction(false)
            setVisibleDeleteConfirmation(false)
            actions.clearTableSelectedItems()
        }
    }

    const onSingleActionHandler = useCallback((action, payload) => {
        if (action === ACTION_EDIT) {
            setEditPayload(payload)
        } else {
            execAction({taskId, action, ids: [payload]})
        }
    }, [])

    const onMultiActionHandler = useCallback(
        action => {
            execAction({
                taskId,
                action,
                ids: tableSelectedItems,
                allSelected: tableAllSelected,
                ...(tableAllSelected ? filterValues : {})
            })
        },
        [tableAllSelected, tableSelectedItems, filterValues]
    )

    const withConfirmationHandler = action => {
        if (action == ACTION_DELETE) {
            setVisibleDeleteConfirmation(true)
        } else {
            onMultiActionHandler(action)
        }
    }

    const continueDelete = () => {
        onMultiActionHandler(ACTION_DELETE)
    }

    const cancelledDeleteConfirmation = () => {
        setVisibleDeleteConfirmation(false)
    }

    const fetchOnScroll = useCallback(() => {
        if (sfcSmartAlerts.status === 'fulfilled') {
            const nextPage = sfcSmartAlerts.page + 1

            if (nextPage <= sfcSmartAlerts.totalPages) {
                getSfcSmartAlerts({page: nextPage, append: true})
            }
        }
    }, [sfcSmartAlerts])

    const editAndUpdateLocal = async data => {
        await actions.editSfcSmartAlert(data)
        await actions.updateSfcSmartAlertsLocal(data)
    }

    const handleClickOnCreate = () => {
        actions.openWizard(WIZARD_TYPE.SMART_ALERT)
        track('Smart Alerts Wizard Opened', {
            Service: 'Salesforce',
            'Initiated From': 'Backup Task Smart Alerts Tab'
        })
    }

    useEffect(() => {
        getSfcSmartAlerts()
    }, [currentUrlParams])

    useEffect(() => {
        return () => {
            actions.clearTableSelectedItems()
        }
    }, [])

    return (
        <StyledWrapped>
            <SmartAlertEdit
                record={editPayload}
                setRecord={setEditPayload}
                taskId={taskId}
                editSmartAlert={editAndUpdateLocal}
            />
            <SmartAlertsHeader
                changeURLSearchParams={changeURLSearchParams}
                loading={loading}
                urlValues={currentUrlParams}
                totalSmartAlerts={sfcSmartAlerts?.total}
                multiActions={
                    <MultiActions
                        onMultiActionHandler={withConfirmationHandler}
                        disabled={waitingCompletionAction || loading || tableSelectedItems?.length === 0}
                    />
                }
                addNewSmartAlert={
                    <AddNewButton
                        text={t('smartAlerts:addNewAlert')}
                        onClick={handleClickOnCreate}
                        disabled={loading}
                    />
                }
            />
            {visibleDeleteConfirmation && (
                <SmartAlertDeleteConfirmation
                    visible={visibleDeleteConfirmation}
                    selectedCount={tableSelectedItems?.length}
                    isAllSelected={tableAllSelected}
                    onDelete={continueDelete}
                    onCancel={cancelledDeleteConfirmation}
                />
            )}
            <SmartAlertsTable
                changeURLSearchParams={changeURLSearchParams}
                smartAlerts={sfcSmartAlerts?.data || []}
                loading={loading}
                tableSelectedItems={tableSelectedItems}
                tableAllSelected={tableAllSelected}
                setTableAllSelected={actions.setTableAllSelected}
                setTableSelectedItems={actions.setTableSelectedItems}
                onSingleActionHandler={onSingleActionHandler}
                fetchOnScroll={fetchOnScroll}
            />
        </StyledWrapped>
    )
}

const mapStateToProps = state => {
    const {alerts, tableAllSelected, tableSelectedItems} = state.backups.sfcSmartAlerts

    return {
        sfcSmartAlerts: {
            ...alerts?.response,
            status: alerts.status
        },
        tableAllSelected,
        tableSelectedItems,
        companyInfo: state.companyInfo.response
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            fetcher,
            setTableAllSelected: tableAllSelectedActions.set,
            setTableSelectedItems: tableSelectedItemsActions.set,
            execSfcSmartAlert,
            openWizard,
            clearTableSelectedItems: tableSelectedItemsActions.reset,
            editSfcSmartAlert,
            updateSfcSmartAlertsLocal
        },
        dispatch
    )
})

export const SmartAlerts = connect(mapStateToProps, mapDispatchToProps)(SmartAlertsRaw)
