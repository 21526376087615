import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {get} from 'lodash'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {SignInForm} from 'ca-common/forms/SignIn'
import {SignInTwoFA} from 'ca-common/components/SignInPage/SignInTwoFA'
import {openSuccessNotification} from 'ca-common/utils/toasts'

import {StyledSignInPage, Header, EmailSignIn, Content} from 'ca-common/components/SignInPage/StyledSignInPage'

import {SignService} from 'ca-common/components/SignService'
import {LineSeparator} from 'ca-common/ui-lib/atoms/LineSeparator'
import {PublicCompanyInfoType, OAuthServiceType} from 'ca-common/types'

type SignInPageProps = {
    services: OAuthServiceType[]
    title: string
    onSubmit: (arg0: any) => void
    onChange: (error: string | null) => void
    account: boolean
    error: null | string
    showOauth?: boolean
    isTwoFADisplayed: boolean
    companyInfo: PublicCompanyInfoType
    loading: boolean
}

const SignInPageRaw = (props: SignInPageProps) => {
    const history = useHistory()
    const {t} = useTranslation()
    const {isTwoFADisplayed, title, services, onChange, error, account, onSubmit, companyInfo, loading} = props
    const showOauth = get(companyInfo, 'response.isCloudally') || props.showOauth

    const checkUrlMessages = () => {
        const {msgType, status, error} = queryString.parse(history.location.search)

        if (status && msgType === 'error') {
            props.onChange(String(status))
        }

        if (status && error) {
            props.onChange(String(error))
        }

        if (status && msgType === 'info') {
            openSuccessNotification(status)
        }
    }

    useEffect(() => {
        checkUrlMessages()
    }, [])

    return (
        <StyledSignInPage>
            <Header>
                <h1>{title}</h1>
            </Header>
            <Content>
                <EmailSignIn>
                    {isTwoFADisplayed ? (
                        <SignInTwoFA onChange={onChange} error={error} onSubmit={onSubmit} loading={loading} />
                    ) : (
                        <SignInForm hasAccount={account} error={error} onSubmit={onSubmit} loading={loading} />
                    )}
                </EmailSignIn>
                <LineSeparator text={t('sign:up:separator')} />
                <SignService services={showOauth ? services : [services[1]]} />
            </Content>
        </StyledSignInPage>
    )
}

const mapStateToProps = (state: any) => ({
    isTwoFADisplayed: state.signIn.displayTwoFA,
    companyInfo: state.companyInfo
})

export const SignInPage = connect(mapStateToProps)(SignInPageRaw)
