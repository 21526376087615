import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 15px 21px;
    color: ${variables.text};
    background: ${variables.white};
    box-shadow: 0 0 10px #e1e1e1;

    .ant-row {
        display: flex;
        justify-content: space-between;
        .ant-col {
            padding-right: 15px;
            .ant-input {
                border-radius: 0;
                border: 1px solid ${variables.old.gray};
            }
            .ant-input:hover {
                border-color: #7b8da0;
            }
        }
    }

    .ant-form-item-row {
        display: flex;
    }

    .ant-form-item-label {
        width: 25%;
        text-align: start;
    }
`

export const StyledTitle = styled.h4`
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
`

export const CheckboxRow = styled.div`
    display: flex;
    .ant-form-item-label {
        width: 86%;
    }

    .ant-form-item-row {
        flex-direction: row-reverse;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #6290c8;
        border-color: #6290c8;
    }

    .ant-checkbox-inner {
        border-radius: 0;
        border: 1px solid #b0bbc6;
    }
`

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    padding-top: ${variables.fontSizeSecondary};
    border-top: 1px solid ${variables.blue};
    .ant-btn {
        padding: 0 14px;
        border: 1px solid ${variables.blue};
        border-radius: 12px;
        margin-left: 10px;
        margin-top: 10px;
        height: 26px;
        box-shadow: 2px 3.5px 9.1px 0.9px rgba(200, 200, 200, 0.56);
    }
    .ant-btn-primary:disabled {
        color: ${variables.white};
        background: ${variables.brand};
        opacity: 0.5;
    }
    .ant-btn-default {
        border: 1px solid ${variables.brand};
        color: ${variables.brand};
        font-weight: 500;
    }
    .ant-btn-default:disabled {
        opacity: 0.5;
        border: 1px solid ${variables.brand};
        color: ${variables.brand};
    }
`
