import React, {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useParams} from 'react-router'

import {BackupAuthStatus} from 'ca-common/components/BackupAuthStatus'
import {TASK_STATUS} from 'ca-common/common/enum'
import {BACKUP_PROGRESS_STATUS} from 'ca-common/constants'

import {StyledHeader, StyledHeaderLeft, StyledHeaderRight, StyledSourceIcon} from '../StyledHeader'

import {AdditionalBackupInfo} from 'src/newcore/organisms/AdditionalBackupInfo'
import {getBackupProgress} from 'src/newcore/redux/modules/backups/progress'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux/settings'

const pullConfigTimes = {
    short: moment.duration(1, 'minutes'),
    long: moment.duration(5, 'minutes'),
    stop: moment.duration(20, 'minutes')
}

export const SingleEntityHeaderRaw = props => {
    const {taskSettings, onBackupAuthClick, right, actions, progress} = props
    const {taskStatus} = taskSettings
    const {taskId} = useParams()
    const fetchBackupProgress = useCallback(() => {
        if (taskStatus === TASK_STATUS.IN_PROCESS) {
            actions.getBackupProgress({taskId})
        }
    }, [taskStatus])
    useEffect(() => {
        if (progress?.currentCount === 0 && progress?.backupState === BACKUP_PROGRESS_STATUS.PREPARE) {
            actions.getNewBackupTaskSettings({taskId})
        }
    }, [progress?.currentCount, progress?.backupState, taskId])

    useEffect(() => {
        actions.getBackupProgress({taskId})
    }, [])

    return (
        <StyledHeader>
            <StyledHeaderLeft>
                <StyledSourceIcon source={taskSettings.source} fontSize={48} />
                <BackupAuthStatus isAuthenticated={taskSettings.isAuthenticated} onClick={onBackupAuthClick} />
            </StyledHeaderLeft>

            <StyledHeaderRight>{right}</StyledHeaderRight>
            <AdditionalBackupInfo
                pullConfig={{
                    pull: fetchBackupProgress,
                    ...pullConfigTimes
                }}
                progress={progress}
                taskSettings={taskSettings}
            />
        </StyledHeader>
    )
}

SingleEntityHeaderRaw.propTypes = {
    info: PropTypes.node,
    right: PropTypes.node
}

SingleEntityHeaderRaw.defaultProps = {
    info: null,
    right: null
}
const mapStateToProps = state => ({
    progress: state.backups.backupProgress.response
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({getBackupProgress, getNewBackupTaskSettings}, dispatch)
})

export const SingleEntityHeader = connect(mapStateToProps, mapDispatchToProps)(SingleEntityHeaderRaw)
