import {post} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const appendData = (response: TODO_ANY) => {
    return {
        data: Object.assign(
            {},
            ..._.map(response, object => ({
                [object]: false
            }))
        )
    }
}

const toggleSourceObjectValue = (data: Record<string, any>, key: string) => ({
    ...data,
    [key]: !data[key]
})

const SEEDING_GET_SOURCE_OBJECTS_LIST = 'SEEDING_GET_SOURCE_OBJECTS_LIST'
const getObjectsListWrapper = fetchWrapperRT(
    SEEDING_GET_SOURCE_OBJECTS_LIST,
    async data => await post('getSfcSnapshotObjects', data),
    {
        after: appendData
    }
)
export const getSourceObjectsList = getObjectsListWrapper.fetcher

const sourceObjectsListSlice = createSlice({
    name: 'sourceObjectsList',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        toggleSourceObject: (state, action) => {
            state.response.data = toggleSourceObjectValue(state.response.data, action.payload)
        },
        clearSourceObjectsList: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getObjectsListWrapper.makeReducers(builder)
})

export const {clearSourceObjectsList, toggleSourceObject} = sourceObjectsListSlice.actions
export const sourceObjectsListWrapperReducer = sourceObjectsListSlice.reducer
