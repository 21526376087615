import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Spin} from 'antd'

import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import {openErrorModal} from 'ca-common/utils/modals'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {TODO_ANY} from 'ca-common/types'

import {changeBackupName} from 'src/newcore/redux/modules/backups/backupName'
import {getServiceName} from 'src/newcore/utils/sources'
import {BackupTitleModal} from 'src/newcore/components/Modals'
import {BackupTitle as BackupTitleForm} from 'src/newcore/forms'

import {linkToExactBackup} from 'src/newcore/features/Homepage/utils'
import {BackupTitle} from 'src/newcore/features/Homepage/molecules/BackupTitle'
import {BackupContent} from 'src/newcore/features/Homepage/molecules/BackupContent'
import {BackupActions} from 'src/newcore/features/Homepage/organisms/BackupActions'
import {getBackupTasksPull, updateLocalBackupTasks} from 'src/newcore/features/Homepage/redux'
import type {Task} from 'src/newcore/features/Homepage/types'

import {StatusContentWrapper, StyledActionsTile, StyledTile} from './StyledTile'
import {useAppDispatch} from 'src/newcore/components/Root'

export const Tile = ({task}: Task): JSX.Element => {
    const {t} = useTranslation()
    const {source, id, alias, isDropboxProfessionalAccount} = task
    const friendlyName = getServiceName({source, isDropboxProfessionalAccount})
    const [titleFormVisible, setTitleFormVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState(false)
    const dispatch = useAppDispatch()

    function toggleVisibleForTile() {
        setTitleFormVisible(!titleFormVisible)
    }

    async function handleEditName(values: Record<string, unknown>) {
        setLoading(true)

        await dispatch(
            changeBackupName({
                taskId: id,
                ...values
            })
        )
            .unwrap()
            .then(() => {
                toggleVisibleForTile()
                openSuccessNotification(t('forms:backupTitle:response:nameChanged'))
                pullAndUpdateLocalServices()
            })
            .catch((error: any) => {
                if (error.status) {
                    openErrorModal(error.status)
                }
            })
            .finally(() => setLoading(false))
    }

    async function pullAndUpdateLocalServices() {
        await dispatch(getBackupTasksPull({}))
            .then((response: TODO_ANY) => {
                const currentService = response?.payload?.find((service: TODO_ANY) => service.id === id)

                if (currentService) {
                    dispatch(updateLocalBackupTasks(currentService))
                }
            })
            .finally(() => setSpin(false))
    }

    return (
        <Spin spinning={spin}>
            <StyledTile data-taskid={id}>
                <CALink to={linkToExactBackup(id)}>
                    <BackupTitle toggleVisible={toggleVisibleForTile} friendlyName={friendlyName} task={task} />
                    <StatusContentWrapper>
                        <BackupContent task={task} />
                    </StatusContentWrapper>
                </CALink>
                <BackupTitleModal open={titleFormVisible} onCancel={toggleVisibleForTile}>
                    <BackupTitleForm
                        alias={alias}
                        onSubmit={handleEditName}
                        onCancel={toggleVisibleForTile}
                        loading={loading}
                    />
                </BackupTitleModal>
                <StyledActionsTile data-inlinemanual="InlineManual-homepage-controls">
                    <BackupActions
                        pullAndUpdateLocalServices={pullAndUpdateLocalServices}
                        setSpin={setSpin}
                        friendlyName={friendlyName}
                        task={task}
                    />
                </StyledActionsTile>
            </StyledTile>
        </Spin>
    )
}
