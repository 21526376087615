import React, {Fragment, useState} from 'react'
import {Tooltip, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {StyledButtonText, StyledForm, StyledInput} from './StyledResetPasswordForm'
import {hasErrorsAntd4, hasEmptyErrorsAntd4, EMPTY_ERROR_MESSAGE} from 'ca-common/utils/form'

const {Item} = Form

export const ResetPasswordForm = ({handleSubmit}) => {
    const {t} = useTranslation()
    const [hasErrors, setHasErrors] = useState(false)
    const [hasEmptyErrors, setHasEmptyErrors] = useState(false)
    const [hasErrorFromServer, setHasErrorFromServer] = useState(false)

    const onFieldsChange = (_, allValues) => {
        setHasErrors(state => {
            const temporary = hasErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })

        setHasEmptyErrors(state => {
            const temporary = hasEmptyErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })

        setHasErrorFromServer(false)
    }

    const onFinish = values => {
        handleSubmit(values).catch(() => {
            setHasErrorFromServer(true)
            setHasErrors(true)
        })
    }

    const errorProps = hasErrorFromServer
        ? {
              validateStatus: 'error',
              help: t('forms:common:fields:email:validation:errorFromServer')
          }
        : {}

    return (
        <Fragment>
            <StyledForm onFinish={onFinish} initialValues={{email: ''}} onFieldsChange={onFieldsChange}>
                <Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: EMPTY_ERROR_MESSAGE
                        },
                        {
                            type: 'email',
                            message: t('forms:common:fields:email:validation:invalid')
                        }
                    ]}
                    {...errorProps}
                >
                    <StyledInput size="large" placeholder={t('passwordResetNew:email:placeholder')} />
                </Item>

                <Tooltip title={hasEmptyErrors && t('forms:common:fields:common:validation:required')}>
                    <Button
                        block
                        size="large"
                        color={variables.iceBlue}
                        type="primary"
                        htmlType="submit"
                        disabled={hasEmptyErrors || hasErrors}
                    >
                        <StyledButtonText>{t('passwordResetNew:buttonReset')}</StyledButtonText>
                    </Button>
                </Tooltip>
            </StyledForm>
        </Fragment>
    )
}
