import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledInfiniteTable = styled.div`
    .ant-table-thead {
        tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }

        span.ant-table-column-title,
        th.ant-table-cell {
            font-weight: 600;
            flex-basis: content;
            flex-grow: 1;
        }

        .ant-table-column-sorters {
            justify-content: flex-start;
        }
    }
    .ant-table-tbody > tr > td {
        border-bottom: none;
    }
    th {
        border-top: 1px solid ${variables.blue};
        border-bottom-color: ${variables.blue};
    }
`
