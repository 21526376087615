import React from 'react'
import {Tooltip} from 'antd'
import styled from 'styled-components'

const StyleWrapper = styled.div`
    .ant-tooltip-inner {
        max-width: 216px;
    }
`

export const StatusTooltip = props => {
    const {tooltip, children, ...passProps} = props

    return (
        <StyleWrapper>
            <Tooltip title={tooltip} getPopupContainer={triggerNode => triggerNode} mouseLeaveDelay={0} {...passProps}>
                {children}
            </Tooltip>
        </StyleWrapper>
    )
}
