import PropTypes from 'prop-types'

export const optionShape = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export const filterConfigShape = {
    ignoreCase: PropTypes.bool,
    ignoreAccents: PropTypes.bool,
    trim: PropTypes.bool,
    matchFromStart: PropTypes.string
}

export const inputShape = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
}
