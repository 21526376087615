import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {match} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Skeleton} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {getBackupEntities, clearBackupEntities, getNewBackupTaskSettings} from 'src/newcore/features/Recovery/redux'
import {AppDispatch, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {callReload} from 'src/newcore/utils/logout'

type Props = {
    match: match<{taskId: string; entityId: string}>
} & ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const RecoveryBreadcrumbEntityRaw = ({entities, actions, match, userInfo}: Props) => {
    const {taskId, entityId} = match.params
    const name = entities?.data[0]?.name
    const dispatch = useAppDispatch()
    useEffect(() => {
        actions.getBackupEntities({taskId, entitiesIds: [entityId], source: SOURCE.MS365, rows: 1, page: 1})
        if (userInfo?.isMsEndUser) {
            dispatch(getNewBackupTaskSettings({taskId: taskId}))
                .unwrap()
                .catch((err: any) => {
                    if (err.status === 404 && err.data?.success === false) {
                        callReload('', '?msgType=custom&modalName=AccessDenied')
                    }
                })
        }

        return () => {
            actions.clearBackupEntities()
        }
    }, [])

    return (
        <Skeleton active title={{width: '150px', style: {margin: '4px 0 0 0'}}} paragraph={false} loading={!name}>
            <span data-hj-suppress>{name}</span>
        </Skeleton>
    )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            getBackupEntities,
            clearBackupEntities
        },
        dispatch
    )
})

const mapStateToProps = (state: AppState) => ({
    entities: state.recoveryNew.entities.response,
    userInfo: state.userInfo.response
})

export const RecoveryBreadcrumbEntity = connect(mapStateToProps, mapDispatchToProps)(RecoveryBreadcrumbEntityRaw)
