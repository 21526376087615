import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {NavLink, match} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import truncate from 'lodash/truncate'

import {PAGES} from 'ca-common/constants'

import {ALIAS_TRIM_LENGTH} from 'src/newcore/components/BackupTitle'
import {getNewBackupTaskSettings, clearBackupTaskSettingsForAdvanced} from 'src/newcore/features/Recovery/redux'
import {AppDispatch, AppState} from '../../Root/newAppStore'

type Props = {
    match: match<{taskId: string}>
} & ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const RecoveryBreadcrumbRaw = ({settings, actions, match}: Props) => {
    const {taskId} = match.params
    const name = settings?.alias

    useEffect(() => {
        actions.getNewBackupTaskSettings({taskId: taskId})

        return () => {
            actions.clearBackupTaskSettingsForAdvanced()
        }
    }, [])

    return (
        <NavLink title={name} to={`/${PAGES.BACKUPS}/${taskId}`}>
            {truncate(name, {length: ALIAS_TRIM_LENGTH})}
        </NavLink>
    )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            getNewBackupTaskSettings,
            clearBackupTaskSettingsForAdvanced
        },
        dispatch
    )
})

const mapStateToProps = (state: AppState) => ({
    settings: state.recoveryNew.settings.response
})

export const RecoveryBreadcrumb = connect(mapStateToProps, mapDispatchToProps)(RecoveryBreadcrumbRaw)
