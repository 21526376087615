import styled, {css} from 'styled-components'
import {rgba} from 'polished'

import variables from 'ca-common/variables'

import {SourceName, TaskTitle} from 'src/newcore/features/Homepage/molecules/BackupTitle/StyledBackupTitle'

type EndUserType = {
    isUserActive: boolean
}

export const StyledEndUserCard = styled.div<EndUserType>`
    box-shadow: 0 0 10px ${rgba(variables.navyBlue, 0.29)};
    padding: ${variables.gutterLg} 22px;
    width: 269px;
    display: flex;
    min-height: 288px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${props => {
        if (props.isUserActive) {
            return `${variables.white}`
        }
        return `${variables.blueLight}`
    }};
`
export const StyledCardWrapper = styled.div<{blur: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${props =>
        props.blur &&
        css`
            opacity: 0.5;
        `}
`
export const Title = styled(TaskTitle)`
    margin: 5px 0 2px;
`

export const Subtitle = styled(SourceName)`
    margin-bottom: 12px;
`
export const Note = styled.div`
    font-family: ${variables.publicPageFont};
    color: ${variables.navyBlue};
    text-align: center;
    font-size: ${variables.fontSizeBase};
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
`

export const RecoveryWrapper = styled.div`
    display: flex;
    margin-top: ${variables.gutterSm};
`
