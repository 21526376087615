import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {JumbotronLink} from 'ca-common/ui-lib/molecules/JumbotronLink'
import {updateQueryParams} from 'ca-common/redux/modules/navigation'
import {PAGES, USER_STATUS} from 'ca-common/constants'

import {getPortalSideMenu} from 'src/newcore/molecules/PortalSideMenu'

import {StatusPage} from '../Status'

import './Billing.scss'

const dumbBilling = dumbBem('ca-billing-page')
const CABilling = tx(dumbBilling)('div')

const annualPaymentType = 'Annual'

@withTranslation('billing')
class Billing extends Component {
    componentDidMount() {
        this.props.actions.updateQueryParams()
    }

    billingLinkFormat = name => {
        const {paymentMethod} = this.props
        if (
            paymentMethod.status === 'fulfilled' &&
            paymentMethod.subscriptionType === annualPaymentType &&
            name === PAGES.PAYMENTS_DETAILS
        ) {
            return 'billing/annualSubscription'
        }
        return `billing/${name}`
    }

    billingtList = () => {
        const {userInfo, companyInfo, t} = this.props

        const menu = getPortalSideMenu({companyInfo, userInfo})
        const sections = menu.find(({name}) => name === PAGES.BILLING)?.submenu || []

        return sections.map(({name, icon}) => (
            <JumbotronLink
                key={name}
                title={t(`billing:${name}:title`)}
                icon={icon}
                link={this.billingLinkFormat(name)}
                description={t(`billing:${name}:description`)}
                tile
            />
        ))
    }

    render() {
        const {paymentMethod, userInfo, children} = this.props

        return (
            children || (
                <div>
                    {'pending' === paymentMethod.status && <Spinner modifier="page" />}
                    {(paymentMethod.status === 'fulfilled' &&
                        paymentMethod.response.subscriptionType !== annualPaymentType &&
                        userInfo.status === USER_STATUS.TRIAL) ||
                    userInfo.status === USER_STATUS.UNSUBSCRIBED ? (
                        <StatusPage {...this.props} />
                    ) : (
                        <CABilling>{this.billingtList()}</CABilling>
                    )}
                </div>
            )
        )
    }
}

function mapStateToProps(state) {
    return {
        paymentMethod: state.billing.paymentMethod,
        userInfo: state.userInfo.response,
        companyInfo: state.companyInfo.response
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    updateQueryParams
                },
                dispatch
            )
        }
    }
}

export const BillingPage = connect(mapStateToProps, mapDispatchToProps)(Billing)
