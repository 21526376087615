import React from 'react'
import PropTypes from 'prop-types'

import {StyledCollapse, StyledPanel} from './StyledCollapse'

export const Collapse = props => {
    const {activeKey, children, panelFooter, collapseFooter, header} = props

    return (
        <StyledCollapse bordered={false} expandIcon={() => {}} activeKey={activeKey}>
            <StyledPanel header={header} key={activeKey}>
                {children}
                {panelFooter}
            </StyledPanel>
            {collapseFooter}
        </StyledCollapse>
    )
}

Collapse.propTypes = {
    activeKey: PropTypes.string,
    collapseFooter: PropTypes.node,
    header: PropTypes.node,
    panelFooter: PropTypes.node
}

Collapse.defaultProps = {
    activeKey: '',
    collapseFooter: null,
    header: null,
    panelFooter: null
}
