import styled from 'styled-components'

import variables from 'ca-common/variables'
import {SearchField} from 'ca-common/ui-lib/atoms/SearchField'

export const MainWrapper = styled.div`
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterSm};
`

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterXs};
    max-height: 400px;
    height: 400px;
    overflow: auto;
    padding-right: ${variables.gutterXs};
`

export const StyledSearchField = styled(SearchField)``

export const Wrapper = styled.div<{isActive: boolean}>`
    display: flex;
    align-items: center;
    padding: ${variables.gutterXs};
    padding-left: ${variables.gutterSm};
    border-radius: 2px;
    background-color: ${({isActive}) => isActive && variables.interaction};
    cursor: default;
`

export const Label = styled.div<{isActive: boolean}>`
    padding: ${variables.gutterXs};
    border-radius: 2px;
    background-color: ${({isActive}) => isActive && variables.interaction};
    cursor: pointer;
    font-weight: 300;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    line-height: 17px;
    max-height: 34px;
    min-height: 34px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
