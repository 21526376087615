import React from 'react'
import {Switch, Tooltip} from 'antd'
import {Trans, useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'
import {rgba} from 'polished'

import Info from 'ca-common/icons/Info.svg'

import variables from 'ca-common/variables'

import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

import {Wrapper, SwitcherWrapper, Text, DownloadAnonymizationTemplate} from './StyledAnonymizationModeSwitcher'

type AnonymizationModeSwitcherProps = {
    isEnabledAnonymization?: boolean
    changeData: (newData: Record<string, any>) => void
}

const ANONYMIZATION_TEMPLATE_SRC =
    'https://public-content-resources.s3.amazonaws.com/static/Anonimyzation+template.xlsx'

export const AnonymizationModeSwitcher = (props: AnonymizationModeSwitcherProps) => {
    const {t} = useTranslation()
    const {isEnabledAnonymization, changeData} = props

    const onChange = (isEnabledAnonymization: boolean) => {
        changeData({
            isEnabledAnonymization
        })
    }

    const onDownload = () => {
        track(EVENT_NAMES.SALESFORCE_ANONYMIZATION_TEMPLATE, {})
    }

    return (
        <Wrapper>
            <SwitcherWrapper>
                <Switch checked={isEnabledAnonymization} onChange={onChange} />
                <Text>{t('seeding:secondStep:anonymizeData')}</Text>
                <Tooltip
                    title={<Trans i18nKey="seeding:secondStep:anonymizeDataTooltip" />}
                    overlayInnerStyle={{
                        width: 446,
                        fontFamily: variables.publicPageFont
                    }}
                >
                    <Icon component={Info} />
                </Tooltip>
            </SwitcherWrapper>
            <DownloadAnonymizationTemplate
                to={ANONYMIZATION_TEMPLATE_SRC}
                target="_blank"
                pureLink
                onClick={onDownload}
            >
                {t('seeding:secondStep:downloadAnonymizationTemplate')}
            </DownloadAnonymizationTemplate>
        </Wrapper>
    )
}
