import React from 'react'

import {SourceIcon} from 'ca-common/components/SourceIcon'
import {getSourceName} from 'ca-common/utils/sources'
import {Site} from 'src/newcore/components/BackupEntities/CellModifier/Site'

import {JobFields} from 'src/newcore/features/Jobs/types'

import {
    IconRadiusWrapper,
    InfoBlock,
    SourceName,
    StyledHeaderTaskInfo,
    TaskName,
    UserEmail
} from './StyledHeaderTaskInfo'

type HeaderTaskInfoProps = Pick<JobFields, 'source' | 'alias' | 'account'>

export const HeaderTaskInfo = ({source, alias, account}: HeaderTaskInfoProps): JSX.Element => {
    return (
        <StyledHeaderTaskInfo>
            <IconRadiusWrapper>
                <SourceIcon source={source} />
            </IconRadiusWrapper>
            <InfoBlock>
                <TaskName title={alias}>{alias}</TaskName>
                <SourceName>{getSourceName(source)}</SourceName>
                <UserEmail>
                    <Site site={account} />
                </UserEmail>
            </InfoBlock>
        </StyledHeaderTaskInfo>
    )
}
