import {useState, useEffect, SetStateAction, Dispatch} from 'react'

import {BACKUPS_VIEW} from '../utils/constants'

const BACKUP_VIEW = 'backupView'

export const toggleBackupsView = (): [BACKUPS_VIEW, Dispatch<SetStateAction<BACKUPS_VIEW>>] => {
    const savedValue = JSON.parse(localStorage.getItem(BACKUP_VIEW) as string)
    const [backupView, setBackupView] = useState(savedValue === null ? BACKUPS_VIEW.LIST : savedValue)

    useEffect(() => {
        localStorage.setItem(BACKUP_VIEW, JSON.stringify(backupView))
    }, [backupView])

    return [backupView, setBackupView]
}
