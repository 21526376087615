import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {ACCESS_LEVEL, CREDENTIALS_TYPES, PASSWORD_POLICIES} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms'
import {TODO_ANY} from 'ca-common/types'
import {SecurityPageTitle} from 'ca-common/components/SecurityPageTitle'

import {isOpenID} from 'src/newcore/components/Settings/Utils'
import {StyledJumbotron} from 'src/newcore/components/Settings/Pages/Security/StyledSecurity'

import {ExpirationPageForm} from './ExpirationForm'

export const PasswordPolicies = (props: TODO_ANY): JSX.Element => {
    const {userInfo, companyInfo, openChangePasswordForm, submitExpirationForm, disableTwoFa, twoFaEnabled} = props
    const {t} = useTranslation()
    const openID = isOpenID(userInfo.credentialType)
    const isOkta = userInfo.credentialType === CREDENTIALS_TYPES.OKTA
    const isNone = userInfo.permissions.accessLevel === ACCESS_LEVEL.NONE

    const text = twoFaEnabled
        ? t('securityPage:twoFaAuth:deactivateAction')
        : t('securityPage:twoFaAuth:activateAction')
    const download = 'CloudAllyWebRecoveryCodes'

    return (
        <>
            <StyledJumbotron>
                <h3>{t('securityPage:personalPasswordPolicies')}</h3>
                {!isNone && (
                    <p>
                        <div>
                            <SecurityPageTitle>{companyInfo.name}</SecurityPageTitle>
                        </div>
                        <Button type="primary" onClick={openChangePasswordForm}>
                            {t('securityPage:action')}
                        </Button>
                    </p>
                )}

                {!openID && (
                    <p>
                        <span>{t('securityPage:twoFaAuth:text')}</span>
                        {twoFaEnabled ? (
                            <div>
                                <Button onClick={disableTwoFa} type="primary">
                                    {text}
                                </Button>
                                <a href={PASSWORD_POLICIES.SAVE_CODES} download={download}>
                                    {t('twoFaAuthPage:actions:get')}
                                </a>
                            </div>
                        ) : (
                            <Link to={PASSWORD_POLICIES.TWOFA_PAGE} className={'ca-link'}>
                                <Button type="primary">{text}</Button>
                            </Link>
                        )}
                    </p>
                )}
            </StyledJumbotron>
            {!isOkta && !isNone && (
                <StyledJumbotron>
                    <h3>{t('securityPage:enforcePasswordPolicies')}</h3>
                    <ExpirationPageForm
                        isOkta={isOkta}
                        onSubmit={submitExpirationForm}
                        initialValues={{
                            expiredPassword: !!userInfo.passwordExpiredDays,
                            days: userInfo.passwordExpiredDays || '1',
                            enforceTwoStepAuthOnAllUsers: userInfo.enforceTwoStepAuthOnAllUsers || null
                        }}
                    />
                </StyledJumbotron>
            )}
        </>
    )
}
