import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import moment from 'moment'

import {Pagination} from 'ca-common/features/Pagination'
import {TODO_ANY} from 'ca-common/types'

import {openErrorNotification} from 'ca-common/utils/toasts'
import {hasEntities} from 'ca-common/utils/sources'
import {JOBS_STATUSES} from 'ca-common/common/enum'
import {getInlineManualTracking, reInitInlineManualPlayer} from 'ca-common/features/InlineManual'

import {usePulling} from 'src/newcore/hoc/withPulling'
import {StatusDashboard} from 'src/newcore/features/Jobs/molecules'
import {Filters, JobsList} from 'src/newcore/features/Jobs/organisms'
import {JobsSubtitle} from 'src/newcore/features/Jobs/atoms'
import {
    getJobInitiators,
    getJobTasks,
    getJobItems,
    clearJobItems,
    getJobServices,
    clearJobServices,
    clearJobInitiators,
    clearJobTasks,
    getExportRestoreJobs as fetcher,
    jobCanceling
} from 'src/newcore/features/Jobs/redux'
import {JOB_FIELDS, JOBS_PER_PAGE, DEFAULT_PAGE, SORT_BY} from 'src/newcore/features/Jobs/lib'
import {useUrlParams} from 'src/newcore/features/Jobs/hooks/useUrlParams'
import {track} from 'src/newcore/utils/mixpanel'

import {JobsPaginationWrapper} from './StyledJobs'
import {AppState} from 'src/newcore/components/Root'

export type JobsProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const JobsRaw = (props: JobsProps): JSX.Element => {
    const {actions, jobInitiators, jobTasks, jobItems, jobServices, exportRestoreJobs, userInfo} = props
    const {currentUrlParams, changeUrlParams} = useUrlParams()
    const [firstRender, setFirstRender] = useState(false)

    const loading = useMemo(() => {
        return [
            jobInitiators.status,
            jobTasks.status,
            jobItems.status,
            jobServices.status,
            exportRestoreJobs.status
        ].some(i => i === 'pending')
    }, [jobInitiators.status, jobTasks.status, jobItems.status, jobServices.status, exportRestoreJobs.status])
    const totalCount = exportRestoreJobs?.total

    const getExportRestoreJobs = useCallback(
        async ({page, pull, ...data} = {}) => {
            const fetchParams = {
                limit: JOBS_PER_PAGE,
                page: page || DEFAULT_PAGE,
                pull,
                filter: {
                    [JOB_FIELDS.SORT_BY]: SORT_BY.LATEST_FIRST,
                    ...currentUrlParams,
                    ...data
                }
            }

            try {
                await actions.fetcher(fetchParams)
                updateInlineManualInfo()
            } catch (error: TODO_ANY) {
                openErrorNotification(error?.status)
            }
        },
        [currentUrlParams]
    )

    const sendAnalytics = () => {
        const getDashboardElements = (jobStatus: JOBS_STATUSES) => {
            return exportRestoreJobs?.dashboard?.dashboardElements.find(
                (i: {status: JOBS_STATUSES}) => i.status === jobStatus
            )
        }
        track('View Jobs Page', {
            'Total Recent Jobs': exportRestoreJobs?.dashboard?.total,
            'In Progress Jobs': getDashboardElements(JOBS_STATUSES.IN_PROCESS)?.count,
            'Successfully Completed Jobs': getDashboardElements(JOBS_STATUSES.SUCCEEDED)?.count,
            'Failed Jobs': getDashboardElements(JOBS_STATUSES.FAILED)?.count
        })
    }

    const updateInlineManualInfo = () => {
        if (getInlineManualTracking()?.metadata.has_jobs || !totalCount) return
        reInitInlineManualPlayer({has_jobs: totalCount})
    }

    if (firstRender) {
        if (exportRestoreJobs?.dashboard) {
            updateInlineManualInfo()
            sendAnalytics()
            setFirstRender(false)
        }
    }

    useEffect(() => {
        actions.getJobTasks({})
        actions.getJobServices({})
        actions.getJobInitiators({})
        setFirstRender(true)

        return () => {
            actions.clearJobItems()
            actions.clearJobServices()
            actions.clearJobInitiators()
            actions.clearJobTasks()
        }
    }, [])

    const settedTaskId = currentUrlParams[JOB_FIELDS.TASK_QUERY]

    const selectedTask = useMemo(() => {
        return jobTasks.response?.find(i => i?.id === settedTaskId)
    }, [settedTaskId, jobTasks])

    const getItems = async () => {
        try {
            await actions.getJobItems({taskId: settedTaskId})
        } catch (err: any) {
            openErrorNotification(err?.status)
        }
    }

    useEffect(() => {
        if (selectedTask) {
            if (settedTaskId && hasEntities(selectedTask.source)) {
                getItems()
            } else {
                actions.clearJobItems()
            }
        }
    }, [selectedTask])

    useEffect(() => {
        getExportRestoreJobs()
    }, [currentUrlParams])

    usePulling({
        pull: () => {
            getExportRestoreJobs({pull: true})
        },
        short: moment.duration(30, 'seconds'),
        long: moment.duration(3, 'minutes'),
        stop: moment.duration(30, 'minutes')
    })

    return (
        <>
            <JobsSubtitle totalCount={exportRestoreJobs?.dashboard?.total} loading={loading} />
            <StatusDashboard
                loading={loading}
                changeUrlParams={changeUrlParams}
                statuses={exportRestoreJobs?.dashboard?.dashboardElements}
            />
            <Filters
                changeUrlParams={changeUrlParams}
                values={currentUrlParams}
                jobInitiators={jobInitiators?.response}
                loading={loading}
                jobTasks={jobTasks?.response}
                jobItems={jobItems?.response}
                jobServices={jobServices?.response}
                isMsEndUser={userInfo?.isMsEndUser}
            />

            <JobsList
                totalCount={totalCount}
                loading={loading}
                jobs={exportRestoreJobs?.data}
                jobCanceling={actions.jobCanceling}
                userPermissions={userInfo?.permissions}
                isMsEndUser={userInfo?.isMsEndUser}
            />
            <JobsPaginationWrapper>
                <Pagination
                    disabled={loading}
                    currentPage={(exportRestoreJobs?.page as number) || DEFAULT_PAGE}
                    changeUrlParams={getExportRestoreJobs}
                    pageSize={JOBS_PER_PAGE}
                    total={totalCount}
                />
            </JobsPaginationWrapper>
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    jobInitiators: state.jobs.initiators,
    jobTasks: state.jobs.tasks,
    jobItems: state.jobs.items,
    jobServices: state.jobs.services,
    exportRestoreJobs: {
        ...state.jobs.exportRestoreJobs.response,
        status: state.jobs.exportRestoreJobs.status
    },
    userInfo: state.userInfo.response
})

const mapDispatchToProps = (dispatch: TODO_ANY) => ({
    actions: bindActionCreators(
        {
            getJobInitiators,
            clearJobInitiators,
            getJobTasks,
            clearJobTasks,
            getJobItems,
            clearJobItems,
            getJobServices,
            clearJobServices,
            fetcher,
            jobCanceling
        },
        dispatch
    )
})

export const Jobs = connect(mapStateToProps, mapDispatchToProps)(JobsRaw)
