import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {ALTERNATIVE_FULL_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

import {SnapshotCalendar} from 'src/newcore/organisms/SnapshotCalendar'

import {
    CalendarInputWrapper,
    DateConfigurationSection,
    RadioDescription,
    RadioDescriptionSmall
} from './StyledDateChoose'

import {BACKUP_DATE_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'

type DateChooseProps = {
    firstBackupDate: number
    lastBackupDate: number
    retentionDate: number
    dateType: BACKUP_DATE_OPTIONS
    setBackupDateType: (arg0: BACKUP_DATE_OPTIONS) => void
    setBackupDate: React.Dispatch<React.SetStateAction<number>>
    calendarPayload: any
}

export const DateChoose = ({
    firstBackupDate,
    lastBackupDate,
    retentionDate,
    dateType,
    setBackupDate,
    setBackupDateType,
    calendarPayload
}: DateChooseProps): JSX.Element => {
    const {t} = useTranslation()

    return (
        <DateConfigurationSection>
            {lastBackupDate ? (
                <RadioBlock
                    defaultChecked
                    checked={BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP === dateType}
                    value={BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP}
                    showRadioIcon
                    onChange={() => setBackupDateType(BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP)}
                    title={t(
                        `recovery:wizard:fullMailboxDateConfigure:${BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP}:label`
                    )}
                >
                    <RadioDescription>
                        {moment(lastBackupDate, LONG_DATE_FORMAT).format(ALTERNATIVE_FULL_FORMAT)}
                    </RadioDescription>
                </RadioBlock>
            ) : (
                <Tooltip title={t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:tooltipDisabled')}>
                    <RadioBlock
                        disabled
                        checked={BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP === dateType}
                        value={BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP}
                        showRadioIcon
                        title={t(
                            `recovery:wizard:fullMailboxDateConfigure:${BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP}:label`
                        )}
                    />
                </Tooltip>
            )}

            <RadioBlock
                checked={BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE === dateType}
                value={BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE}
                showRadioIcon
                onChange={() => setBackupDateType(BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE)}
                title={t(`recovery:wizard:fullMailboxDateConfigure:${BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE}:label`)}
                description={
                    <RadioDescriptionSmall>
                        {t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:description')}
                    </RadioDescriptionSmall>
                }
            >
                <CalendarInputWrapper>
                    <SnapshotCalendar
                        showPartialStatus
                        needTimePicker
                        succeedOnly
                        onChange={(value: string) => setBackupDate(+value)}
                        payload={calendarPayload}
                        firstBackupDate={firstBackupDate}
                        retentionDate={retentionDate}
                        label={t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:calendarLabel')}
                    />
                </CalendarInputWrapper>
            </RadioBlock>
        </DateConfigurationSection>
    )
}
