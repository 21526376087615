import React from 'react'
import {formatUTCDateTime} from 'ca-common/utils/datetime'

import {PopoverContentRow} from 'src/newcore/features/ActivityTable/atoms/PopoverContentRow'
import {ComparePopoverProps, ComparePopoverDescription} from 'src/newcore/features/ActivityTable/types'
import {Content, Wrapper} from './StyledPopoverContent'

export const ComparePopoverContent = ({description}: ComparePopoverProps<ComparePopoverDescription>): JSX.Element => {
    const {
        comparedObject,
        comparedObjectType,
        firstComparedTaskName,
        firstComparedTaskDate,
        secondComparedTaskName,
        secondComparedTaskDate
    } = description
    const compareLabelWidth = 235

    return (
        <Content>
            <Wrapper>
                <PopoverContentRow labelWidth={compareLabelWidth} label="comparedObject" rowText={comparedObject} />
                <PopoverContentRow
                    labelWidth={compareLabelWidth}
                    label="comparedObjectType"
                    rowText={comparedObjectType}
                />
            </Wrapper>
            {(firstComparedTaskName || firstComparedTaskDate) && (
                <Wrapper>
                    <PopoverContentRow
                        labelWidth={compareLabelWidth}
                        label="firstComparedName"
                        rowText={firstComparedTaskName}
                    />
                    <PopoverContentRow
                        labelWidth={compareLabelWidth}
                        label="firstComparedDate"
                        rowText={formatUTCDateTime(firstComparedTaskDate)}
                    />
                </Wrapper>
            )}
            {(secondComparedTaskName || secondComparedTaskDate) && (
                <Wrapper>
                    <PopoverContentRow
                        labelWidth={compareLabelWidth}
                        label="secondComparedName"
                        rowText={secondComparedTaskName}
                    />
                    <PopoverContentRow
                        labelWidth={compareLabelWidth}
                        label="secondComparedDate"
                        rowText={formatUTCDateTime(secondComparedTaskDate)}
                    />
                </Wrapper>
            )}
        </Content>
    )
}
