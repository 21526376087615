import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledTag = styled.div`
    display: flex;
    align-items: center;
    margin: 4px;
    height: 24px;
    border-radius: 24px;
    background: ${variables.navyBorder};
    color: ${variables.navyAsphalt};
    font-weight: 600;
    font-size: ${variables.fontSizeSecondary};
    padding: 0 ${variables.gutterXs};
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
`

export const TagName = styled.span`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
