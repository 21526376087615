import React from 'react'

import {SOURCE, TASK_STATUS} from 'ca-common/common/enum'
import {DeepTaskStatus} from 'ca-common/ui-lib/atoms/DeepTaskStatus'

import Connected from 'ca-common/icons/BackupConnection/Connected.svg'

import {getServiceName} from 'src/newcore/utils/sources'
import {ServiceIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {
    StyledInfoWrapper,
    StyledSourceName,
    StyledStatusWrapper,
    StyledExistedTask,
    StyledIcon,
    StyledTaskName
} from './StyledExistedTask'

type ExistedTaskProps = {
    source: SOURCE
    taskName: string
    status: TASK_STATUS
}

export const ExistedTask = ({taskName, source, status}: ExistedTaskProps) => {
    const sourceName = getServiceName({source})

    return (
        <StyledExistedTask>
            <StyledIcon component={Connected} />
            <ServiceIcon source={source} />
            <StyledInfoWrapper>
                <StyledTaskName>{taskName}</StyledTaskName>
                <StyledSourceName>{sourceName}</StyledSourceName>
                <StyledStatusWrapper>
                    <DeepTaskStatus status={status} />
                </StyledStatusWrapper>
            </StyledInfoWrapper>
        </StyledExistedTask>
    )
}
