import styled from 'styled-components'
import React from 'react'
import Icon from '@ant-design/icons'
import {Radio} from 'antd'

import variables, {orange, systemGreen} from 'ca-common/variables'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'
import type {IconComponent} from 'ca-common/types'

type StyledRadioProps = {
    status: ACTIVITY_STATUS
    retention: boolean
}

const getBorderColor = (retention: boolean) => (retention ? variables.asphaltLight : systemGreen)

export const StyledRadio = styled(Radio)<StyledRadioProps>`
    background: ${variables.white};
    box-shadow: 0 1px 5px rgba(8, 35, 48, 0.12);
    border: 0;
    padding: ${variables.gutterXs};
    width: 190px;
    min-height: 52px;
    height: fit-content;
    border-left: 2px solid
        ${({status, retention}) => (status === ACTIVITY_STATUS.SUCCEED ? getBorderColor(retention) : orange)};
    border-radius: ${variables.borderRadiusBase};
    margin-right: 0;
    transition: all 0.2s;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &.ant-radio-wrapper-checked,
    &:focus,
    &:hover {
        background: ${variables.interaction};
        border-right: 1px solid ${variables.interaction20};
        border-top: 1px solid ${variables.interaction20};
        border-bottom: 1px solid ${variables.interaction20};
        box-sizing: border-box;
        box-shadow: 0 2px 5px rgba(8, 35, 48, 0.2);
        border-radius: ${variables.borderRadiusBase};
        transition: all 0.2s;
    }
    span.ant-radio {
        display: none;
        & + * {
            padding: 0;
        }
    }
`
export const BackupStatus = styled.p`
    margin: 0 4px 4px 0;
    color: ${variables.navyBlue};
    line-height: 1.5;
    font-size: ${variables.fontSizeSecondary};
`
export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
type DropDownIcon = {
    expanded: boolean
    onClick: (e: React.SyntheticEvent<HTMLElement>) => void
    component: IconComponent
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DropDownIcon = styled(({expanded, ...props}: DropDownIcon) => <Icon {...props} />)`
    color: ${variables.navyBlue};
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 18px;
    height: 18px;
    transition: transform 0.2s ease;
    transform: ${({expanded}) => (expanded ? 'rotate(180deg)' : 'none')};
`
export const DateWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
export const DateTime = styled.p`
    font-weight: 600;
    margin: 0 ${variables.gutterXs} 0 0;
    line-height: 1.5;
    font-size: ${variables.fontSizeSecondary};
`
export const DateDay = styled.p`
    margin: 0;
    line-height: 1.5;
    font-size: ${variables.fontSizeSecondary};
`
