import React from 'react'
import {Checkbox} from 'antd'
import {StyledHeader} from 'src/newcore/features/Recovery/organisms/FolderTree/atoms/StyledFolderTreeHeader'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'

export type FolderTreeHeaderProps = {
    checked: CHECK_STATE
    onCheckHeader: () => void
}

export const FolderTreeHeader = ({checked, onCheckHeader}: FolderTreeHeaderProps): JSX.Element => {
    const check = checked === CHECK_STATE.CHECKED
    const partial = checked === CHECK_STATE.PARTIAL
    return (
        <StyledHeader>
            <Checkbox checked={check} onChange={onCheckHeader} indeterminate={partial}>
                Folders
            </Checkbox>
        </StyledHeader>
    )
}
