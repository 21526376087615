import React, {useMemo, useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Modal} from 'antd'

import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

import {StyledFooter, StyledModal, StyledHeaderWithIcon} from './StyledSmartAlertDeleteConfirmation'

type Props = {
    visible: boolean
    selectedCount: number
    isAllSelected: boolean
    onDelete: () => void
    onCancel: () => void
}

const MODAL_WIDTH = '500px'

export const SmartAlertDeleteConfirmation = ({
    selectedCount = 0,
    isAllSelected,
    onDelete,
    onCancel,
    visible = false
}: Props): JSX.Element => {
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)

    const confirmationSuffix = useMemo<string>(() => {
        if (isAllSelected) {
            return t('smartAlerts:selectedAll')
        }

        return selectedCount.toString()
    }, [isAllSelected, selectedCount])

    const onDeleteHandler = () => {
        setLoading(true)
        onDelete()
    }

    return (
        <Modal width={MODAL_WIDTH} open={visible} footer={null} closable={false}>
            <StyledModal>
                <StyledHeaderWithIcon>
                    <BackupTaskAction action={TASK_ACTION.DELETE} withTitle={false} />

                    <Trans i18nKey="smartAlerts:deleteConfirmation" values={{confirmationSuffix}} />
                </StyledHeaderWithIcon>

                <StyledFooter>
                    <Button onClick={onCancel} type="link" loading={loading}>
                        {t('backups:modal:delete:buttonCancel')}
                    </Button>
                    <Button type="primary" color={variables.red} onClick={onDeleteHandler} loading={loading}>
                        {t('backups:modal:delete:buttonDelete')}
                    </Button>
                </StyledFooter>
            </StyledModal>
        </Modal>
    )
}
