import _ from 'lodash'
import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const selectEntitiesAfter = (response: TODO_ANY, state: TODO_ANY) => {
    const oldEntities = _.get(state, 'response.data.entities', [])
    const entities = _.unionBy(oldEntities, response.data.entities, 'id') // hack for lib bug. Remove it after changing react-infinite-scroller lib

    return {
        ...response,
        data: {
            cacheToken: response.data.cacheToken,
            entities
        }
    }
}

const GET_SELECTED_ENTITIES = 'GET_SELECTED_ENTITIES'
const GET_SELECTED_ENTITIES_API = 'selectedEntities'
const selectedEntitiesWrapper = fetchWrapperRT(
    GET_SELECTED_ENTITIES,
    async data => await postLessData(GET_SELECTED_ENTITIES_API, data),
    {
        after: selectEntitiesAfter
    }
)

export const getSelectedEntities = selectedEntitiesWrapper.fetcher

const selectedEntitiesSlice = createSlice({
    name: 'selectedEntities',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSelectedEntities: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => selectedEntitiesWrapper.makeReducers(builder)
})

export const {clearSelectedEntities} = selectedEntitiesSlice.actions
export const selectedEntities = selectedEntitiesSlice.reducer
