import React from 'react'

import {LogAndDownloadWrapper, StyledJobFooter} from './StyledJobFooter'

type JobFooterProps = {
    activityLog?: JSX.Element
    cancel: JSX.Element
    downloadReport: JSX.Element
}

export const JobFooter = (props: JobFooterProps): JSX.Element => {
    const {cancel, activityLog, downloadReport} = props

    return (
        <StyledJobFooter data-inlinemanual="InlineManual-jobspage-jobCardFooter">
            {cancel}
            <LogAndDownloadWrapper>
                {downloadReport}
                {activityLog}
            </LogAndDownloadWrapper>
        </StyledJobFooter>
    )
}
