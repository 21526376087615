import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Form, Row, Col, Radio, Checkbox, Select, Switch} from 'antd'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'
import {Button} from 'ca-common/ui-lib/atoms'
import {
    ReportFormTitle,
    Title,
    StyledLabel,
    PreviewWrapper,
    EmailReport,
    Footer,
    FieldsWrapper,
    FailureOnlyReportCheckBoxWrapper,
    PreviewLink,
    StyledSubLine
} from './StyledSummaryReport'
import {
    SummaryReportType,
    hasDayOptions,
    selectDayOptions,
    renderDayOptions,
    provideTypes,
    emailReportOptions,
    taskLevelPreviewLink,
    entityLevelPreviewLink,
    reportFormats,
    reportTypes
} from './SummaryReportType'

type SummaryReportProps = {
    isPP: boolean
    onSubmit: (arg0: any) => void
    initialValues: {
        summaryReport?: boolean
        reportFormat: string
        provideType: string
        reportFreq: number
        failureOnlyReport: boolean
        reportDay: number
        reportType: string
    }
}

export const SummaryReport = (props: SummaryReportProps) => {
    const {t} = useTranslation()
    const {isPP, initialValues, onSubmit} = props
    const {summaryReport, failureOnlyReport} = initialValues
    const [summaryReportForm] = Form.useForm<SummaryReportType>()
    const [isFailureOnlyReportChecked, setChecked] = useState(failureOnlyReport)
    const [isDisable, setDisabled] = useState(true)
    const [summaryReportValue, setsummaryReportValue] = useState(summaryReport)
    const watchedReportFrequency = Form.useWatch('reportFreq', summaryReportForm)

    const handleFailureReportCheckboxChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked)

        summaryReportForm.setFieldValue('failureOnlyReport', !isFailureOnlyReportChecked)
    }

    const onFinish = (values: SummaryReportType) => {
        onSubmit(values)
        setDisabled(true)
    }
    const onValuesChange = () => {
        if (
            summaryReportForm.isFieldTouched('summaryReport') ||
            summaryReportForm.isFieldTouched('reportFormat') ||
            summaryReportForm.isFieldTouched('provideType') ||
            summaryReportForm.isFieldTouched('reportFreq') ||
            summaryReportForm.isFieldTouched('reportDay') ||
            summaryReportForm.isFieldTouched('failureOnlyReport') ||
            summaryReportForm.isFieldTouched('reportType')
        ) {
            setDisabled(false)
        }
    }
    const onSwtichChange = (value: boolean) => {
        setsummaryReportValue(value)
        summaryReportForm.setFieldValue('summaryReport', value)
    }
    return (
        <Form
            onFinish={onFinish}
            colon={false}
            initialValues={initialValues}
            form={summaryReportForm}
            onValuesChange={onValuesChange}
        >
            <FieldsWrapper>
                <Jumbotron>
                    <Title>
                        <ReportFormTitle>{t('notifications:reportFormTitle')}</ReportFormTitle>
                        <Form.Item name="summaryReport">
                            <Switch checked={summaryReportValue} onChange={onSwtichChange} />
                        </Form.Item>
                    </Title>
                    <Row>
                        <Col span={6}>
                            {!isPP && (
                                <Form.Item name="reportFormat" label="Report Format:">
                                    <Radio.Group>
                                        {reportFormats.map(({value, label}) => (
                                            <Radio key={value} value={value} disabled={!summaryReportValue}>
                                                {label}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            {!isPP && (
                                <PreviewWrapper>
                                    <PreviewLink>
                                        <a href={taskLevelPreviewLink} target="_blank">
                                            {t('notifications:preview')}
                                        </a>
                                    </PreviewLink>
                                    <PreviewLink>
                                        <a href={entityLevelPreviewLink} target="_blank">
                                            {t('notifications:preview')}
                                        </a>
                                    </PreviewLink>
                                </PreviewWrapper>
                            )}
                            <Form.Item name="provideType" label={t('notifications:provideType')}>
                                <Radio.Group>
                                    {provideTypes.map(({value, label}) => (
                                        <Radio key={value} value={value} disabled={!summaryReportValue}>
                                            {label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {isPP ? (
                            <>
                                <Col span={2}>
                                    <StyledSubLine />
                                </Col>
                                <Col span={7}>
                                    <Form.Item name="reportType" label={t('notifications:reportType')}>
                                        <Radio.Group>
                                            {reportTypes.map(({value, label}) => (
                                                <Radio key={value} value={value} disabled={!summaryReportValue}>
                                                    {label}
                                                </Radio>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <StyledSubLine />
                                </Col>
                            </>
                        ) : (
                            <Col span={6} />
                        )}
                        <Col span={7}>
                            <EmailReport>
                                <StyledLabel>{t('notifications:reportFreq')}</StyledLabel>
                                <Form.Item name="reportFreq">
                                    <Select
                                        options={emailReportOptions}
                                        defaultValue={emailReportOptions[0]}
                                        disabled={!summaryReportValue}
                                    />
                                </Form.Item>
                                {watchedReportFrequency == 4 && (
                                    <Form.Item name="reportDay" required={hasDayOptions(watchedReportFrequency)}>
                                        <Select
                                            options={renderDayOptions(watchedReportFrequency)}
                                            disabled={!summaryReportValue}
                                            defaultValue={selectDayOptions[0]}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                )}
                            </EmailReport>
                            <FailureOnlyReportCheckBoxWrapper>
                                <Form.Item name="failureOnlyReport">
                                    <Checkbox
                                        onChange={handleFailureReportCheckboxChange}
                                        disabled={!summaryReportValue}
                                        defaultChecked={isFailureOnlyReportChecked}
                                    >
                                        <Trans i18nKey="notifications:failureOnlyReport" />
                                    </Checkbox>
                                </Form.Item>
                            </FailureOnlyReportCheckBoxWrapper>
                        </Col>
                    </Row>
                    <Footer>
                        <Button htmlType="submit" type="primary" disabled={isDisable}>
                            {t('forms:common:actions:save')}
                        </Button>
                    </Footer>
                </Jumbotron>
            </FieldsWrapper>
        </Form>
    )
}
