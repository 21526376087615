import {PARTNER_TYPE} from '../constants'

export const isPartnerReseller = userInfo => {
    return userInfo.partnerType === PARTNER_TYPE.INDIRECT_RESELLER
}

export function hasBillingPage(userInfo) {
    return (
        userInfo.partnerType === PARTNER_TYPE.DISTRIBUTOR ||
        userInfo.partnerType === PARTNER_TYPE.DIRECT_RESELLER ||
        userInfo.partnerType === PARTNER_TYPE.AFFILIATE_RESELLER
    )
}

export const isSelfPartnerIDByUrl = (urlId, storeId) => {
    return !urlId || urlId === storeId
}

export const hasAccessToSupport = userInfo => {
    return userInfo.partnerType === PARTNER_TYPE.DISTRIBUTOR || userInfo.partnerType === PARTNER_TYPE.DIRECT_RESELLER
}
