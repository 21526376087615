import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${variables.gutterSm};

    &:last-child {
        margin-bottom: 0;
    }
`

export const Counts = styled.div`
    margin-left: ${variables.gutterMd};
    color: ${variables.navyBlue};
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
