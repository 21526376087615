import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Popover, Checkbox, Tooltip, Input, Spin, Form} from 'antd'

import variables from 'ca-common/variables'

const CheckboxGroup = Checkbox.Group

export const StyledPopover = styled(({className, ...props}) => <Popover {...props} overlayClassName={className} />)`
    z-index: 999;

    && {
        .ant-popover-inner {
            border-radius: ${variables.borderRadiusBase};
        }

        .ant-popover-arrow {
            display: none;
        }
    }
`

export const PopoverInnerContent = styled.div`
    width: 270px;
    margin: -12px -${variables.gutterMd};
`

export const StyledEmptyContent = styled.div`
    height: 120px;
`

export const StyledContent = styled.div`
    display: flex;
    justify-content: ${props => (props.isLoaded ? 'flex-start' : 'center')};
    border-top: 1px solid ${variables.blue};
    position: relative;
    padding-bottom: ${props => (props.isLoaded ? '57px' : '0px')};
`

export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid ${variables.blue};
    position: absolute;
    bottom: 0;
    left: 0;

    &:hover {
        cursor: pointer;
        background: ${variables.navyBlue};
        color: ${variables.white};
        border-bottom-left-radius: ${variables.borderRadiusBase};
        border-bottom-right-radius: ${variables.borderRadiusBase};

        i {
            color: ${variables.white};
        }
    }
`

export const TagsAction = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${variables.gutterSm};
`

export const TagsActionIcon = styled(Icon)`
    font-size: 24px;
    margin-right: ${variables.gutterSm};
`

export const StyledBackupTagsForm = styled(Form)``

const BackupTagsBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${variables.gutterMd};
`

export const BackupTagsHeader = styled.h3`
    margin-bottom: 0;
    font-size: 18px;
    padding: ${variables.gutterMd} ${variables.gutterMd} 0;
`

export const BackupTagsFooter = styled(BackupTagsBlock)`
    height: 60px;
`

export const BackupTagsBody = styled.div`
    border-bottom: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};
`

export const Tags = styled.div`
    width: 100%;
    max-height: 210px;
    text-align: left;
    overflow: auto;
    flex: 1;
    label:first-child {
        border-bottom: 1px dotted ${variables.gray};
    }
`

export const TagCheckbox = styled(Checkbox)`
    && {
        margin-left: 0;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: ${variables.gutterXs} ${variables.gutterSm} ${variables.gutterXs} 20px;

        > span:last-child {
            display: block;
            width: calc(100% - ${variables.gutterSm});
            padding-right: 0;
        }

        .ant-checkbox {
            top: unset;
        }
    }
`

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    width: 100%;
`

export const TagName = styled.span`
    display: flex;
    flex-direction: row;
    padding-right: ${props => (props.new ? '40px' : '0')};
`

export const TagTooltip = styled(Tooltip)`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const DropdownButtonWrapper = styled.div`
    margin-right: ${variables.gutterSm};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const Search = styled(Input)`
    padding: ${variables.gutterSm};
    border: none;
    color: ${variables.asphalt};

    :focus {
        box-shadow: none;
    }

    .ant-input-prefix {
        left: ${variables.gutterSm};
        color: ${variables.blue};
    }
`

export const NewTagLabel = styled.div`
    position: absolute;
    right: 0;
    top: ${variables.gutterXs};
    font-weight: 600;
    color: ${variables.navyBlue};
`

export const ContentSpin = styled(Spin)`
    padding: ${variables.gutterSm};
`

export const StyledSearchIcon = styled(Icon)`
    font-size: 24px;
`

export const StyledDropdownIcon = styled(Icon)`
    font-size: ${variables.fontSizeSecondary};
`
