import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledTitle = styled.span`
    font-family: ${variables.accentFont};
    font-size: ${variables.headingFontSize};
    color: ${variables.black};
`

export const StyledIpAddress = styled.span`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.black};
`
