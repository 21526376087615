import React, {useMemo} from 'react'
import findKey from 'lodash/findKey'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'

import AttentionIcon from 'ca-common/icons/old/uEA31-status-attention.svg'
import InfoIcon from 'ca-common/icons/old/uEA5B-info-tooltip.svg'

import {ACTIVITY_STATUS, SECURITY_AUDIT_STATUS} from 'ca-common/common/enum/AccountActivity'

import {StyledStatusCell} from './StyledStatusCell'

type StatusCellProps = {
    status: ACTIVITY_STATUS
    statusDescription: string
    description: string
    service: SECURITY_AUDIT_STATUS
}

export const StatusCell = (props: StatusCellProps) => {
    const {status, statusDescription, service, description} = props
    const {t} = useTranslation()

    const statusText = useMemo(
        () => t(`securityAudit:status:${findKey(ACTIVITY_STATUS, objectItem => objectItem === status)}`),
        [status]
    )

    return (
        <StyledStatusCell status={status}>
            {status !== ACTIVITY_STATUS.COMPLETED && (
                <Tooltip title={service === SECURITY_AUDIT_STATUS.SIGNIN ? description : statusDescription}>
                    <Icon component={status === ACTIVITY_STATUS.FAILED ? AttentionIcon : InfoIcon} />
                </Tooltip>
            )}
            <span>{statusText}</span>
        </StyledStatusCell>
    )
}
