import React, {useState} from 'react'
import {Select, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import {BACKUP_FREQUENCY, getOptionsOfFrequency} from 'ca-common/utils/datetime'
import {Label, VerticalFormItem} from 'ca-common/ui-lib/components/FormItem'
import Info from 'ca-common/icons/Info.svg'
import LockIcon from 'ca-common/icons/Lock.svg'

import {OptionContentWrapper, BackupNoteHeader, BackupNote} from './StyledBackupFrequency'

const {Option} = Select

type BackupFrequencyProps = {
    backupFrequency?: BACKUP_FREQUENCY
    namePrefix?: string
    isHighFrequencyBackupBlocked?: boolean
    isHighFrequencyBackupEnabled?: boolean
    isSpecialPartnerAccount?: boolean
    isSurchargeNotificationDisplayed?: boolean
}

export const BackupFrequency = ({
    backupFrequency,
    namePrefix = '',
    isHighFrequencyBackupEnabled,
    isHighFrequencyBackupBlocked,
    isSpecialPartnerAccount,
    isSurchargeNotificationDisplayed
}: BackupFrequencyProps) => {
    const {t} = useTranslation()
    const [value, setValue] = useState('')

    const handleChange = (e: any) => {
        setValue(e)
    }

    const name = `${namePrefix ? `${namePrefix}.` : ''}backupFrequency`
    const titleValue = isHighFrequencyBackupBlocked
        ? 'forms:backupSettings:fields:backupFrequency:highBackupTooltip'
        : 'forms:backupSettings:fields:backupFrequency:tooltip'
    const showTooltip = backupFrequency === BACKUP_FREQUENCY.DAILY3TIMES || isHighFrequencyBackupBlocked
    const isHighFrequencyDisabled = !isHighFrequencyBackupEnabled || isHighFrequencyBackupBlocked

    return (
        <>
            <VerticalFormItem
                label={
                    <Label>
                        <span>{t('forms:backupSettings:fields:backupFrequency:label')}</span>
                        {showTooltip && (
                            <Tooltip title={t(titleValue)}>
                                <Icon component={Info} />
                            </Tooltip>
                        )}
                    </Label>
                }
                colon={false}
                name={name}
                initialValue={backupFrequency}
            >
                <Select onChange={handleChange} getPopupContainer={t => t.parentElement}>
                    {getOptionsOfFrequency(isHighFrequencyDisabled).map(freq => (
                        <Option key={freq.value} value={freq.value} disabled={freq.disabled}>
                            <OptionContentWrapper>
                                <span>{freq.label}</span>
                                {backupFrequency !== BACKUP_FREQUENCY.DAILY3TIMES && freq.tooltip && freq.disabled && (
                                    <Tooltip title={freq.tooltip}>
                                        <Icon component={LockIcon} />
                                    </Tooltip>
                                )}
                            </OptionContentWrapper>
                        </Option>
                    ))}
                </Select>
            </VerticalFormItem>
            {isSurchargeNotificationDisplayed && !isHighFrequencyDisabled && value === BACKUP_FREQUENCY.DAILY3TIMES && (
                <BackupNote>
                    <BackupNoteHeader>{t('backups:bulkActivation:notification:noteHeader')}</BackupNoteHeader>
                    {t('backups:bulkActivation:notification:headerTitle')}
                    <br />
                    {t('backups:bulkActivation:notification:alert')}
                </BackupNote>
            )}
        </>
    )
}
