import React from 'react'
import {Tooltip} from 'antd'

import InfoTooltip from 'ca-common/icons/old/uEA5B-info-tooltip.svg'

import {StyledColumnTitle, StyledIcon} from './StyledColumnTitle'

type ColumnTitleProps = {
    title: string | JSX.Element
    tooltipText: string
}

export const ColumnTitle = (props: ColumnTitleProps) => {
    const {tooltipText, title} = props

    return (
        <StyledColumnTitle>
            <span>{title}</span>
            <Tooltip title={tooltipText} overlayInnerStyle={{width: '300px'}}>
                <StyledIcon component={InfoTooltip} />
            </Tooltip>
        </StyledColumnTitle>
    )
}
