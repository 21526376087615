import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledLabel = styled.p`
    margin: 0 0 12px 0;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.brandDark};
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${variables.gutterMd};
`
export const ScrollWrapper = styled.div`
    overflow-y: auto;
    width: 100%;
    margin-bottom: ${variables.gutterXs};
`
export const EntitiesWrapper = styled.div`
    display: flex;
    max-width: 670px;
    height: 272px;
    flex-direction: column;
    align-items: start;
`
