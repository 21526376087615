import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledActionsCell = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterSm};
`

export const StyledIcon = styled(Icon)`
    font-size: ${variables.subTitleFontSize};
    color: ${variables.brand};

    &:hover {
        cursor: pointer;
    }
`
