export const INITIAL_PAGE = 1
export const PAGE_SIZE = 50
export const ACTIVITY_DEBOUNCE = 2000

export const ROOT_NAMESPACE = 'activity'

export const ACTIVITY_STATUS = {
    COMPLETED: 'Succeeded',
    FAILED: 'Failed',
    EXCEPTION: 'Partially Succeeded',
    CANCELLED: 'Cancelled',
    PAUSED: 'Paused'
}

export const SYSTEM_AUDIT_ACTIVITY = {
    DELETEACCOUNTDATA: 'Delete Backup',
    BACKUP: 'Backup',
    RESTORE: 'Restore',
    EXPORT: 'Download',
    NOTIFICATION: 'Notification',
    COMPARE: 'Compare'
} as const

export type AuditActivityKeys = keyof typeof SYSTEM_AUDIT_ACTIVITY
export type AuditActivityValues = typeof SYSTEM_AUDIT_ACTIVITY[AuditActivityKeys]

export const ACTIVITY_FIELD_NAMES = {
    STATUSES: 'statuses',
    SERVICE: 'services',
    TASK: 'taskId',
    ENTITIES: 'entities',
    TYPE: 'activities',
    DATE_RANGE: 'dateRange'
} as const
