import React, {Component} from 'react'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'

import {PublicChangePasswordForm} from 'ca-common/forms/PublicChangePasswordForm'

import {StyledHeader, StyledContentContainer, StyledText} from './StyledChangePublicPassword'

class ChangePublicPasswordRaw extends Component {
    handleSubmit = values => {
        this.props.actions.changeTemporaryPassword(values)
    }

    render() {
        const {t, location} = this.props

        return (
            <StyledContentContainer>
                <StyledHeader>{t('passwordResetNew:changeYour')}</StyledHeader>
                <StyledText>{t('passwordResetNew:changePassword')}</StyledText>
                <PublicChangePasswordForm
                    handleSubmit={this.handleSubmit}
                    initialValues={queryString.parse(location.search)}
                    withEmail
                    withToken
                />
            </StyledContentContainer>
        )
    }
}

export const ChangePublicPassword = withRouter(withTranslation()(ChangePublicPasswordRaw))
