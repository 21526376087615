import React from 'react'
import _ from 'lodash'

import {FiltersBar} from './StyledSelectedFiltersBar'
import {ClearTag, FilterTag} from 'ca-common/ui-lib/atoms/FilterTag'

type SelectedFiltersBarProps = {
    selectedFilterValues: Array<{value: string; label: string}>
    onRemove: (arg0: string) => void
    onRemoveAll: () => void
}

export const SelectedFiltersBar = ({
    selectedFilterValues,
    onRemove,
    onRemoveAll
}: SelectedFiltersBarProps): JSX.Element => {
    return !_.isEmpty(selectedFilterValues) ? (
        <FiltersBar>
            {selectedFilterValues.map(filterValue => (
                <FilterTag onClick={() => onRemove(filterValue.value)} key={filterValue.value} {...filterValue} />
            ))}
            <ClearTag onClick={onRemoveAll} />
        </FiltersBar>
    ) : (
        <></>
    )
}
