import _ from 'lodash'
import {UserInfoDataType, CompanyInfoType, PaymentMethod} from 'ca-common/types'

import {generateCardLabelName} from './text'
import {PROVIDER} from 'ca-common/common/enum/Provider'
import {CREDENTIALS_TYPES, PAYMENT_TYPE} from 'ca-common/constants'

export function generatePaymentMethodsOptions(
    paymentMethods: PaymentMethod[]
): (Pick<PaymentMethod, 'active' | 'paymentMethodToken'> & {label: string})[] {
    return _.map(paymentMethods, card => ({
        paymentMethodToken: card.paymentMethodToken,
        label: generateCardLabelName(card),
        active: card.active
    }))
}

export function getActiveCard(
    paymentMethods?: (Pick<PaymentMethod, 'active' | 'paymentMethodToken'> & {label: string})[]
) {
    return _.find(paymentMethods, 'active', 0)
}

export function isMonthlyManualBilling(billingProvider: PROVIDER | null, subscriptionType: PAYMENT_TYPE): boolean {
    return (
        (subscriptionType === PAYMENT_TYPE.MONTHLY && billingProvider === PROVIDER.OTHER) ||
        billingProvider === PROVIDER.ODIN
    )
}

// export const isForce

export const isNotCloudAllyExternalOrOkta = (companyInfo: CompanyInfoType, userInfo: UserInfoDataType): boolean =>
    (companyInfo.isCloudally && !companyInfo.externalUserManagment) ||
    userInfo.credentialType === CREDENTIALS_TYPES.OKTA

export const isNotAnyExternalOrOkta = (companyInfo: CompanyInfoType, userInfo: UserInfoDataType): boolean =>
    !companyInfo.externalUserManagment || userInfo.credentialType === CREDENTIALS_TYPES.OKTA
