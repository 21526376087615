import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledAddressCIDR = styled.div`
    margin-top: ${variables.gutterSm};
    display: flex;
    flex-direction: column;
    width: 480px;
`

export const StyledHeader = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeBase};
`

export const StyledComments = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.sectionFontSize};
    color: ${variables.navyAsphalt};
    margin-top: ${variables.gutterXs};
`

export const StyledFormItem = styled(Form.Item)`
    margin-top: ${variables.gutterXs};
    margin-bottom: 0;
    width: 100%;
`
export const StyledWrapper = styled.div`
    display: flex;
    gap: ${variables.gutterSm};
`

export const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
`
