import styled from 'styled-components'

export const Alert = styled.div`
    display: block;
    justify-content: space-between;
    padding: 20px 20px 20px 55px;
    margin-bottom: 10px;
    background: #ebebeb;
    color: #3a4a64;
    font-size: 14px;
    line-height: 24px;
    a {
        font-size: 14px;
        font-weight: 400;
        color: #487685;
    }
`
