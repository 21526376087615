import React from 'react'

import {InfinityScroller} from 'ca-common/features/InfinityScroller'

import {SORT_NAMES, SORD_ORDER, COLUMN_NAMES} from 'src/newcore/features/SmartAlerts/lib'
import {TableWithoutAlerts} from 'src/newcore/features/SmartAlerts/atoms'

import {StyledTable} from './StyledSmartAlertsTable'
import {columns} from './SmartAlertsTableConfig'

export const SmartAlertsTable = props => {
    const {
        smartAlerts,
        changeURLSearchParams,
        loading,
        tableAllSelected,
        tableSelectedItems,
        setTableAllSelected,
        setTableSelectedItems,
        onSingleActionHandler,
        fetchOnScroll
    } = props

    const manualSortingHandler = (pagination, filters, sorter) => {
        const order = SORD_ORDER[sorter.order]
        const sortBy = order && SORT_NAMES[sorter.field]

        changeURLSearchParams({order, sortBy})
    }

    const toggleAllSelected = () => {
        setTableAllSelected(!tableAllSelected)
    }

    const onRowSelect = (record, selected, selectedRows) => {
        const calculatedSelectAll = smartAlerts.length === selectedRows.length

        if (calculatedSelectAll !== tableAllSelected) {
            setTableAllSelected(calculatedSelectAll)
        }
    }

    const rowSelection = {
        selectedRowKeys: tableSelectedItems,
        onChange: setTableSelectedItems,
        onSelectAll: toggleAllSelected,
        onSelect: onRowSelect
    }

    const locale = {
        emptyText: <TableWithoutAlerts />
    }

    return (
        <InfinityScroller fetchOnScroll={fetchOnScroll}>
            <StyledTable
                locale={locale}
                pagination={false}
                dataSource={smartAlerts}
                columns={columns(onSingleActionHandler)}
                rowKey={record => record[COLUMN_NAMES.ID]}
                onChange={manualSortingHandler}
                loading={loading}
                rowSelection={rowSelection}
            />
        </InfinityScroller>
    )
}
