import React, {useState} from 'react'
import {Button} from 'antd'

import Download from 'ca-common/icons/Download.svg'

import {TODO_ANY} from 'ca-common/types'
import {STORAGE_TYPE} from 'ca-common/common/enum'
import {openErrorModal} from 'ca-common/utils/modals'

import {StyledIcon} from 'src/newcore/atoms/StyledDownloadButton'

type DownloadButtonProps = {
    record: {
        exportDataId: string
        storageType: STORAGE_TYPE
    }
    onDownloadHandler: (arg0: {taskId: string}) => Record<string, TODO_ANY>
}

export const DownloadButton = ({record, onDownloadHandler}: DownloadButtonProps): JSX.Element => {
    const {exportDataId} = record
    const [loading, setLoading] = useState(false)

    const onClick = async () => {
        try {
            setLoading(true)
            const response = await onDownloadHandler({taskId: exportDataId})
            const downloadLink = response?.payload

            if (downloadLink) {
                window.open(downloadLink, '_blank')
            }
        } catch (error: TODO_ANY) {
            openErrorModal(error?.status)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Button type="link" onClick={onClick} disabled={loading}>
            <StyledIcon component={Download} />
        </Button>
    )
}
