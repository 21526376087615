import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {withTranslation} from 'react-i18next'

import {addCredential, updateLocalCredentials} from 'src/newcore/redux/modules/userManagement'

import {ACCESS_LEVEL, CREDENTIALS_TYPES} from 'ca-common/constants'
import {parseFormPermissions, getServicesByWhiteList} from 'ca-common/utils/usersManagement'
import {permittedWhiteList} from 'src/newcore/utils/permissions'

import {UserPageForm} from './UserForm'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

const dumbNewUser = dumbBem('ca-newUser-page')
const CANewUser = tx(dumbNewUser)('div')

const USERS_PAGE = '/settings/users'

class NewUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            serviceList: {}
        }
    }

    componentDidMount() {
        const {
            userInfo: {permissions}
        } = this.props

        this.setState({serviceList: getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(permissions))})
    }

    render = () => {
        const {serviceList} = this.state
        const {
            userInfo: {isFinra},
            userInfo,
            companyInfo
        } = this.props

        const generalList = getGeneralList(companyInfo, userInfo)
        const tasksList = getTasksList()
        const ORIGINAL_LIST = [serviceList, generalList, tasksList]

        return (
            <CANewUser>
                <UserPageForm
                    onSubmit={this.submitForm(ORIGINAL_LIST)}
                    initialValues={{
                        credentials: {
                            role: ACCESS_LEVEL.CUSTOM,
                            type: CREDENTIALS_TYPES.EMAIL
                        }
                    }}
                    groups={{
                        services: serviceList,
                        tasks: tasksList,
                        general: generalList
                    }}
                    isFinra={isFinra}
                    disableOAuth={!companyInfo.isCloudally}
                />
            </CANewUser>
        )
    }

    submitForm = originalList => values => {
        const {t, actions, history} = this.props

        const data = {
            type: values.credentials.type,
            email: values.credentials.email.toLowerCase(),
            password: values.credentials.password,
            permissions: parseFormPermissions(values, originalList)
        }

        return actions
            .addCredential(data)
            .unwrap()
            .then(response => {
                openSuccessNotification(t('userManagementToast:credentialAdded'))
                actions.updateLocalCredentials(response)
                history.push(USERS_PAGE)
            })
            .catch(err => {
                openErrorModal(`${t('userManagementToast:addFailed')} ${err.status}`)
            })
    }
}

const mapStateToProps = state => ({
    userInfo: state.userInfo.response,
    companyInfo: state.companyInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                addCredential,
                updateLocalCredentials
            },
            dispatch
        )
    }
})

export const NewUserPage = connect(mapStateToProps, mapDispatchToProps)(withTranslation('userManagementToast')(NewUser))
