import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import moment from 'moment'

import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'
import {JOBS_STATUSES} from 'ca-common/common/enum'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import {PAGES} from 'ca-common/constants'

import {makeUrlParams} from 'ca-common/utils/url'
import {LONG_DATE_FORMAT, DAY_MONTH_FORMAT} from 'ca-common/utils/datetime'

import {JobFields} from 'src/newcore/features/Jobs/types'
import {ACTIVITY_FIELD_NAMES} from 'ca-common/features/ActivityInfinityTable'
import {getActivityFriendly} from 'src/newcore/features/Jobs/lib'
import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'

type ActivityLogButtonProps = Pick<
    JobFields,
    'backupTaskId' | 'taskType' | 'startTime' | 'status' | 'source' | 'account'
> & {
    permitted: boolean
}

export const ActivityLogButton = (props: ActivityLogButtonProps): JSX.Element => {
    const {account, backupTaskId, taskType, startTime, status, permitted, source} = props
    const {t} = useTranslation()

    const url = useMemo(() => {
        const fixedDate = moment.utc(startTime, LONG_DATE_FORMAT).local().format(DAY_MONTH_FORMAT).toString()

        const urlSearch = {
            [ACTIVITY_FIELD_NAMES.TYPE]: [taskType],
            [ACTIVITY_FIELD_NAMES.DATE_RANGE]: [fixedDate, fixedDate],
            [ACTIVITY_FIELD_NAMES.TASK]: backupTaskId,
            [ACTIVITY_FIELD_NAMES.ENTITIES]: account
        }

        return {
            pathname: `/${PAGES.ACCOUNT_ACTIVITY}/system`,
            search: makeUrlParams(urlSearch)
        } as Location
    }, [taskType, startTime, backupTaskId])

    const sendAnalytics = () => {
        track('View Job Log', {
            Activity: getActivityFriendly(taskType),
            Service: getServiceNameMixpanel(source),
            'Job Status': t(`JobsPage:jobTile:status:${status}`)
        })
    }

    return permitted ? (
        <CALink to={url} target="_blank">
            <ButtonHoverReverse
                color={variables.navyBlue}
                disabled={
                    status === JOBS_STATUSES.IN_PROCESS ||
                    status === JOBS_STATUSES.PENDING ||
                    status === JOBS_STATUSES.CANCELLED ||
                    status === JOBS_STATUSES.CANCELLING
                }
                onClick={sendAnalytics}
            >
                {t('JobsPage:buttons:viewLog')}
            </ButtonHoverReverse>
        </CALink>
    ) : (
        <Tooltip title={t('JobsPage:viewLogNotPermitted')} mouseLeaveDelay={0}>
            <ButtonHoverReverse color={variables.navyBlue} disabled>
                {t('JobsPage:buttons:viewLog')}
            </ButtonHoverReverse>
        </Tooltip>
    )
}
