import React, {useEffect, useMemo, useCallback, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import type {TableProps} from 'antd'
import {SorterResult} from 'antd/es/table/interface'
import {SORT_TYPE_ASC, SORT_TYPE_DESC, SORT_TYPE_DESCEND, SIZE, FILE_NAME, FILE_SIZE} from 'ca-common/constants'
import {getUrlParams} from 'ca-common/utils/url'
import {TODO_ANY} from 'ca-common/types'
import {openErrorModal} from 'ca-common/utils/modals'
import {Pagination} from 'ca-common/features/Pagination'
import {DownloadTableHeader} from 'src/newcore/features/Jobs/molecules'
import {getDownloadInfo, getDownloadUrl} from 'src/newcore/features/Jobs/redux'
import {JobFields} from 'src/newcore/features/Jobs/types'
import {useUrlParams} from 'src/newcore/features/Jobs/hooks/useUrlParams'
import {DEFAULT_PAGE, JOBS_PER_PAGE} from 'src/newcore/features/Jobs/lib'
import {AppDispatch, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {callReload} from 'src/newcore/utils/logout'
import {columns} from './DownloadTableConfig'
import {StyledDownloadTable, StyledTableWrapper, ExportPaginationWrapper} from './StyledDownloadTable'

type DownloadTableProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const DownloadTableRaw = ({actions, downloadInfo}: DownloadTableProps): JSX.Element => {
    // const [selectedRows, setSelectedRows] = useState<Record<string, string>[]>([])
    // const [downloadLink, setDownloadLink] = useState('')
    const {search} = useLocation()
    const dispatch = useAppDispatch()
    const [sortOrderType, setSortOrderType] = useState(SORT_TYPE_ASC)
    const [sortType, setSortType] = useState(FILE_NAME)
    const {taskId, account, alias} = getUrlParams(search) as Pick<JobFields, 'taskId' | 'account' | 'source' | 'alias'>
    const {currentUrlParams, changeUrlParams} = useUrlParams()
    const totalCount = downloadInfo?.response?.total
    useEffect(() => {
        getDownloadInfoData(DEFAULT_PAGE, sortOrderType, sortType)
    }, [])
    // useEffect(() => {
    //     if (downloadLink) {
    //         setTimeout(() => {
    //             window.open(downloadLink, '_blank')
    //         }, 100)
    //     }
    // }, [downloadLink])

    // const onSelectRow = (record: TODO_ANY, selected: boolean, selectedRows: Record<any, any>[]) => {
    //     setSelectedRows(selectedRows)
    // }

    // const onSelectAll = (selected: boolean, selectedRows: Record<any, any>[]) => {
    //     setSelectedRows(selectedRows)
    // }

    // const rowSelection: Record<any, any> = {
    //     onSelect: onSelectRow,
    //     onSelectAll
    // }
    // const selectedRowsWithLink = selectedRows.filter(row => row.exportDataId)

    const loading = useMemo(() => {
        return [downloadInfo.status].some(i => i === 'pending')
    }, [downloadInfo.status])

    const getDownloadInfoData = useCallback(
        async (page: number, sortOrder: string, sortType: string) => {
            const fetchParams = {
                taskId,
                _search: false,
                rows: JOBS_PER_PAGE,
                sord: sortOrder,
                page: page || DEFAULT_PAGE,
                sortField: sortType
            }
            try {
                dispatch(getDownloadInfo(fetchParams)).then((response: any) => {
                    if (response?.payload?.statusCode === 401 && response?.payload?.success === false) {
                        callReload('', '?msgType=custom&modalName=AccessDenied')
                    }
                })
            } catch (error: any) {
                openErrorModal(`${error?.status}`)
            }
        },
        [currentUrlParams]
    )
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        const sorterData = (sorter as SorterResult<any>).order
        const sorterTypeData = (sorter as SorterResult<any>).column?.title
        const sortType = sorterTypeData === SIZE ? FILE_SIZE : FILE_NAME
        const sortOrder = sorterData === SORT_TYPE_DESCEND ? SORT_TYPE_DESC : SORT_TYPE_ASC
        setSortOrderType(sortOrder)
        setSortType(sortType)
        getDownloadInfoData((downloadInfo?.response?.page as number) || DEFAULT_PAGE, sortOrder, sortType)
    }
    return (
        <>
            <StyledTableWrapper>
                <DownloadTableHeader
                    // onDownloadHandler={onDownloadHandler}
                    // selectedRowsWithLink={selectedRowsWithLink}
                    account={account}
                    alias={alias}
                    // source={source}
                />

                <StyledDownloadTable
                    rowKey={(record: TODO_ANY) => record.exportDataId}
                    // rowSelection={rowSelection}
                    pagination={false}
                    dataSource={downloadInfo.response?.data}
                    columns={columns(actions.getDownloadUrl)}
                    onChange={onChange}
                />
            </StyledTableWrapper>
            <ExportPaginationWrapper>
                <Pagination
                    disabled={loading}
                    currentPage={(downloadInfo?.response?.page as number) || DEFAULT_PAGE}
                    changeUrlParams={getDownloadInfoData}
                    pageSize={JOBS_PER_PAGE}
                    total={totalCount}
                    sortType={sortType}
                    sortOrderType={sortOrderType}
                />
            </ExportPaginationWrapper>
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    downloadInfo: state.jobs.downloadInfo
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            getDownloadUrl
        },
        dispatch
    )
})

export const DownloadTable = connect(mapStateToProps, mapDispatchToProps)(DownloadTableRaw)
