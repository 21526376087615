import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    min-height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: ${variables.gutterMd};
    border-bottom: 1px solid ${variables.navyBorder};
    .ant-checkbox ~ span {
        font-weight: 600;
        font-size: ${variables.fontSizeBase};
        color: ${variables.navyAsphalt};
        padding-left: 16px;
    }
`
