import styled from 'styled-components'
import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'
import {SubTitle} from 'ca-common/ui-lib/atoms/Typography'

export const StyledActionsWrapper = styled.div``

export const StyledActionButton = styled(Button)`
    pointer-events: ${props => props.disabled && 'none'};
    margin-right: ${variables.gutterMd};
`

export const StyledButtonWrapper = styled.span`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
export const StyledHeadings = styled(SubTitle)`
    && {
        color: ${variables.navyBlue};
    }
`
