import React from 'react'
import type {ModalFuncProps} from 'antd'

import {i18n} from 'ca-common/locales'
import variables from 'ca-common/variables'

import {AccountDeleted, AccessDenied} from './templates'
import type {ModalName} from './types'

const defaultModalConfig = {
    width: 680,
    icon: null,
    okText: i18n.t('common:buttons:continue'),
    okButtonProps: {
        style: {
            background: variables.navyBlue,
            fontWeight: 700,
            fontFamily: variables.accentFont
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getModalConfig = (modalName: ModalName, text?: string): ModalFuncProps | null => {
    switch (modalName) {
        case 'AccessDenied':
            return {
                ...defaultModalConfig,
                content: <AccessDenied />
            }
        case 'AccountDeleted':
            return {
                ...defaultModalConfig,
                content: <AccountDeleted />
            }
        default:
            return null
    }
}
