import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'
import {Redirect, Switch, useHistory} from 'react-router'

import {PAGES} from 'ca-common/constants'
import {isExternal} from 'src/newcore/utils/companyInfo'

import {TwoFaPage} from './TwoFA/TwoFa'
import {SecurityPage} from './SecurityPage'

export const Security = (props: any) => {
    const history = useHistory()
    useEffect(() => {
        if (isExternal()) {
            history.push(`/${PAGES.SETTINGS}`)
        }
    }, [])

    const {match} = props

    return (
        <Switch>
            <Route path={`${match.url}/`} exact component={SecurityPage} />
            <Route path={`${match.url}/twoFa`} exact component={TwoFaPage} />
            <Redirect to={`${match.url}/`} />
        </Switch>
    )
}
