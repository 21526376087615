export const initZendesk = (onload = () => {}, onerror = () => {}) => {
    if (!window.zE) {
        const script = document.createElement('script')

        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_CHAT_API_KEY}`
        script.async = true
        script.id = 'ze-snippet'

        script.onload = onload
        script.onerror = onerror

        document.body.appendChild(script)
    }
}
