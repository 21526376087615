import React, {useMemo, useState} from 'react'
import i18n from 'i18next'
import {Trans, useTranslation} from 'react-i18next'
import {Modal} from 'antd'

import ExportIcon from 'ca-common/icons/Export.svg'

import {IconButton} from 'ca-common/ui-lib/molecules/IconButton'
import {Button} from 'ca-common/ui-lib/atoms'

import {ExportButtonWrapper, StyledEmail, StyledTitle} from './StyledExportUsageReportButton'

const MODAL_WIDTH = 722

type ExportUsageReportButtonProps = {
    email?: string
    onConfirm: () => Promise<void>
    disabled?: boolean
    count?: number
    isPP?: boolean
    tableAllSelected?: boolean
    total?: number
}

export const ExportUsageReportButton = ({
    onConfirm,
    disabled,
    email = '',
    count,
    isPP,
    tableAllSelected,
    total
}: ExportUsageReportButtonProps) => {
    const {t} = useTranslation()
    const [isShownConfirm, setIsShownConfirm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const selectedCount = tableAllSelected ? total : count

    const onOk = async () => {
        setIsLoading(true)
        onConfirm()
            .then(() => {
                setIsLoading(false)
                setIsShownConfirm(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const exportUsageTitle = useMemo(() => {
        if (!count) {
            return t('exportUsage:title')
        }

        if (count === 1) {
            return t('exportUsage:title_one', {count})
        }

        return t('exportUsage:title_many', {selectedCount})
    }, [count])

    return (
        <div>
            <ExportButtonWrapper isPP={isPP}>
                <IconButton
                    onClick={() => setIsShownConfirm(true)}
                    tooltipText={i18n.t('exportUsage:exportButtonTooltip')}
                    icon={ExportIcon}
                    disabled={disabled}
                />
            </ExportButtonWrapper>

            <Modal
                open={isShownConfirm}
                onOk={onOk}
                onCancel={() => setIsShownConfirm(false)}
                width={MODAL_WIDTH}
                maskClosable={false}
                title={<StyledTitle>{exportUsageTitle}</StyledTitle>}
                destroyOnClose
                footer={[
                    <Button type="link" onClick={() => setIsShownConfirm(false)} disabled={isLoading}>
                        {t('modals:buttons:cancel')}
                    </Button>,
                    <Button type="primary" onClick={onOk} disabled={isLoading} loading={isLoading}>
                        {t('modals:buttons:confirm')}
                    </Button>
                ]}
            >
                <Trans i18nKey={'exportUsage:text'} tOptions={{email}}>
                    <StyledEmail></StyledEmail>
                </Trans>
            </Modal>
        </div>
    )
}
