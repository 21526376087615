import {
    TRIGGERED_BY_ALL,
    TRIGGERED_BY_SINGLE,
    UNIT_RECORD,
    UNIT_PERCENT,
    ALERT_LEVEL_ALL,
    ALERT_LEVEL_OBJECT
} from 'src/newcore/features/SmartAlerts/lib/constants'

export const warningConfigs = [
    {
        level: ALERT_LEVEL_ALL,
        triggeredBy: TRIGGERED_BY_ALL,
        unit: UNIT_RECORD,
        amount: 100
    },
    {
        level: ALERT_LEVEL_ALL,
        triggeredBy: TRIGGERED_BY_ALL,
        unit: UNIT_PERCENT,
        amount: 10
    },
    {
        level: ALERT_LEVEL_ALL,
        triggeredBy: TRIGGERED_BY_SINGLE,
        unit: UNIT_RECORD,
        amount: 50
    },
    {
        level: ALERT_LEVEL_ALL,
        triggeredBy: TRIGGERED_BY_SINGLE,
        unit: UNIT_PERCENT,
        amount: 5
    },
    {
        level: ALERT_LEVEL_OBJECT,
        triggeredBy: TRIGGERED_BY_ALL,
        unit: UNIT_RECORD,
        amount: 10
    },
    {
        level: ALERT_LEVEL_OBJECT,
        triggeredBy: TRIGGERED_BY_ALL,
        unit: UNIT_PERCENT,
        amount: 5
    },
    {
        level: ALERT_LEVEL_OBJECT,
        triggeredBy: TRIGGERED_BY_SINGLE,
        unit: UNIT_RECORD,
        amount: 10
    },
    {
        level: ALERT_LEVEL_OBJECT,
        triggeredBy: TRIGGERED_BY_SINGLE,
        unit: UNIT_PERCENT,
        amount: 5
    }
]
