import React from 'react'
import {CASelectAllWrapper} from 'ca-common/ui-lib/components/TableElements'
import {SELECTION_TYPE} from 'ca-common/common/enum/Table'
import {checkedOnThisPage} from 'ca-common/utils/table'

export const CASelectAll = ({rowSelection, items}) => {
    let infoBlock

    if (rowSelection && rowSelection.type === SELECTION_TYPE.CHECKBOX && items.total) {
        if (rowSelection.areAllItemsSelected || rowSelection.selectedItems.length === items.total) {
            infoBlock = <p>Selected all {items.total} items</p>
        } else if (rowSelection.selectedItems.length > 0) {
            const checkedOnThisPageLength = checkedOnThisPage(rowSelection).length

            if (
                checkedOnThisPageLength === rowSelection.selectedItems.length &&
                checkedOnThisPageLength === rowSelection.size
            ) {
                // Only current page's items are selected
                infoBlock = (
                    <p>
                        All {rowSelection.size} items on this page are selected.{' '}
                        <span className="ca-link" onClick={() => rowSelection.toggleSelectAllItems()}>
                            Select all {items.total} items
                        </span>
                    </p>
                )
            } else if (checkedOnThisPageLength < rowSelection.selectedItems.length) {
                // There are some checked items on other pages
                infoBlock = (
                    <p>
                        {rowSelection.selectedItems.length} items are selected.{' '}
                        <span className="ca-link" onClick={() => rowSelection.toggleSelectAllItems()}>
                            Select all {items.total} items
                        </span>{' '}
                        or{' '}
                        <span className="ca-link" onClick={() => rowSelection.setSelectAllItems(false)}>
                            Deselect all
                        </span>
                    </p>
                )
            }
        }
    }

    return infoBlock ? <CASelectAllWrapper>{infoBlock}</CASelectAllWrapper> : null
}
