import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Button, ButtonWithState} from 'ca-common/ui-lib/atoms/Button'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledButton = styled(
    ({google, isNew, ...props}: ButtonWithState & {google?: boolean; isNew?: boolean}) => <Button {...props} />
)`
    margin: 0 0 ${variables.gutterSm} 0;
    padding: 0;
    position: relative;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${variables.text};
    border: 1px solid ${variables.navyBlue};
    width: 100%;
    text-shadow: none;

    &:hover {
        background: ${variables.blueLight};
        color: ${variables.text};
    }

    ${props =>
        props.google &&
        css`
            &:hover {
                background: ${variables.brightBlue};
                color: ${variables.white};
            }
        `}

    ${props =>
        props.isNew &&
        css`
            background: ${variables.signupText};
            color: ${variables.white};
            border-radius: 25px;
            gap: ${variables.gutterXs};
            justify-content: center;
            height: 46px;

            ${IconWrapper} {
                background-color: transparent;
            }

            ${ServiceName} {
                margin: 0;
            }

            &:hover {
                background: ${variables.signupText};
                color: ${variables.white};
            }
        `}

    &:last-child {
        margin-bottom: 0;
    }
`
export const ServiceIcon = styled(Icon)`
    font-size: 24px;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
`
export const IconWrapper = styled.div`
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ServiceName = styled.p`
    margin: 0 auto;
    padding-right: ${variables.gutterLg};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
`
