import React, {ChangeEventHandler} from 'react'
import {useTranslation} from 'react-i18next'

import {Postfix, StyledBackupNameInput, StyledInput} from './StyledBackupNameInput'

type BackupNameInputProps = {
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
}

export const BackupNameInput = (props: BackupNameInputProps) => {
    const {t} = useTranslation()

    return (
        <StyledBackupNameInput>
            <StyledInput {...props} />
            <Postfix>{t('oneClickActivation:wizard:backupNamePostfix')}</Postfix>
        </StyledBackupNameInput>
    )
}
