import styled from 'styled-components'
import {Col, Row} from 'antd'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledCol = styled(Col)`
    text-align: center;
`

export const StyledRow = styled(Row)`
    margin-top: ${variables.gutterSm};
`

export const StyledHeader = styled.h2`
    margin-bottom: ${variables.gutterMd};

    font-family: ${variables.publicPageFont};
    font-size: ${variables.mainTitleFontSize};
    font-weight: 700;
    text-align: center;

    color: ${variables.text};
`

export const StyledText = styled.div`
    padding: ${variables.gutterXs};

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    text-align: center;

    color: ${variables.navyAsphalt};
`

export const StyledLinkText = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;

    color: ${variables.navyBlue};
`

export const StyledLink = styled(CALink)`
    padding: 0;
`

export const StyledContentContainer = styled.div`
    display: flex;
    width: 360px;
    box-sizing: border-box;
    margin-top: 132px;
    margin-bottom: ${variables.gutterMd};
    flex-direction: column;
`

export const StyledButtonText = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
`
