import React from 'react'
import {Input} from 'antd'
import {useTranslation} from 'react-i18next'
import {FORM_FIELDS} from 'ca-common/features/IpRestriction/lib'

import {StyledDescription, StyledLeft, StyledRight, StyledFormItem} from './StyledDescription'

type DescriptionProps = {
    width?: number
    initialValue?: string
    withOptional?: boolean
}

export const Description = (props: DescriptionProps): JSX.Element => {
    const {t} = useTranslation()
    const {width = 512, initialValue, withOptional = false} = props

    return (
        <React.Fragment>
            <StyledDescription width={width}>
                <StyledLeft>{t('ipRestrictions:description:header')}</StyledLeft>
                {withOptional && <StyledRight>{t('ipRestrictions:description:optional')}</StyledRight>}
            </StyledDescription>
            <StyledFormItem name={FORM_FIELDS.DESCRIPTION} initialValue={initialValue}>
                <Input style={{borderRadius: 4, width}} placeholder={t('ipRestrictions:description:placeholder')} />
            </StyledFormItem>
        </React.Fragment>
    )
}
