import React from 'react'
import {Dispatch} from '@reduxjs/toolkit'
import {AccountInfoButton} from 'ca-common/features/BaseHeader/molecules/AccountInfoButton'
import {LogoutButton} from 'ca-common/features/BaseHeader/molecules/LogoutButton'
import type {Email, Name, Url} from 'ca-common/types'
import {StyledDropdownContent} from './StyledDropdownContent'

type DropdownContentProps = {
    isPortal: boolean
    isZix: boolean
    isPrimary: boolean
    userEmail: Email
    userName: Name
    signOutFunction: Dispatch
    settingsUrl: Url
    isMsEndUser?: boolean
}

export const DropdownContent = ({
    isPortal,
    isZix,
    isPrimary,
    userEmail,
    userName,
    signOutFunction,
    settingsUrl,
    isMsEndUser
}: DropdownContentProps) => {
    return (
        <StyledDropdownContent>
            <AccountInfoButton
                settingsUrl={settingsUrl}
                userEmail={(isPortal && !(isZix && isPrimary)) || !isPortal ? userEmail : ''}
                userName={userName}
                isMsEndUser={isMsEndUser}
            />
            <LogoutButton onClick={signOutFunction} />
        </StyledDropdownContent>
    )
}
