import _ from 'lodash'

import en from './resources.locale-en'
import _imagesMap from './Icons'
//    res_svc_dropbox: 'service_icons/dropbox.jpeg',

/**
 * Resource Manager
 * @constructor
 */
function ResourceManager() {
    /**
     * @type {ResourceManager}
     * @private
     */
    const self = this
    /**
     *
     * @type {Array.<Image>}
     * @private
     */
    const _images = []
    /**
     *
     * @type {Array.<string>}
     * @private
     */
    const _strings = []
    /**
     *
     * @type {Number}
     * @private
     */
    let _imageLoadCounter = 0

    /**
     * image map
     * @type {Object.<string, Image>}
     * @protected
     */
    this.images = {}

    /**
     * @type {Object.<string, string>}
     */
    this.imageUrls = _imagesMap

    /**
     * strings map
     * @type {Object.<string, string>}
     */
    this.strings = {}

    /**
     * onload event handler
     * @type {function()}
     */
    this.onload = null

    /**
     * @param {!string} id
     * @param {!string} src
     */
    this.addImage = function (src, id) {
        function decrementImageCounter() {
            _imageLoadCounter--
            if (_imageLoadCounter === 0 && self.onload) {
                self.onload()
            }
        }

        _imageLoadCounter++

        setTimeout(() => {
            try {
                const newImage = new Image()
                newImage.onload = decrementImageCounter
                newImage.onerror = decrementImageCounter
                newImage.onabort = decrementImageCounter
                let SH = ''
                const s = document.location.protocol
                if (s === 'file:') {
                    SH = 'file://C:/dev/ca/CAWeb/cloudallyWeb/src/main/webapp'
                }
                /*STATIC_HOST +*/
                newImage.src = `${SH}/images/app/${src}`
                _images[id] = newImage
                self.images[id] = newImage
            } catch (e) {
                console.log(e)
                decrementImageCounter()
            }
        }, 10)
    }

    /**
     * get image
     * @param {!string} id image id
     * @return {Image}
     */
    this.getImage = function (id) {
        return _images[id]
    }

    /**
     *
     * @param {!string} id
     * @return {string}
     */
    this.getString = function (id) {
        return _strings[id]
    }

    /**
     *
     */
    this.loadResources = function (onload) {
        if (onload) {
            self.onload = onload
        }
        _.forEach(_imagesMap, self.addImage)
    }
}

const resMgr = new ResourceManager()

export default {
    ...resMgr,
    strings: {
        ...resMgr.strings,
        ...en
    }
}
