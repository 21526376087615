import React from 'react'
import {ColumnProps} from 'antd/lib/table'

import {InfinityScroller} from 'ca-common/features/InfinityScroller'
import {ActivityStatusValues} from 'ca-common/utils/getActivityStatusIcon'
import {getRowClassNameFromStatus} from './lib/getRowClassNameFromStatus'

import {StyledActivityInfinityTable, StyledTable} from './StyledActivityInfinityTable'
import {SOURCE} from 'ca-common/common/enum'
import {AuditActivityValues} from 'ca-common/features/ActivityInfinityTable'

type ActivityInfinityTableProps = {
    fetchOnScroll: () => void
    columns: ColumnProps<any>[]
    dataSource: AccountActivityResponse['data']
    loading?: boolean
    scrollAfterX: number
}

export type ActivityRecord = {
    activity: AuditActivityValues
    activityStatus: ActivityStatusValues
    description: any
    date: number
    source: SOURCE
    isExpired?: boolean
    downloadPermitted?: boolean
    statusDescription?: string
}

export type AccountActivityResponse = {
    data: ActivityRecord[]
    page: number
    success: boolean
}

export const ActivityInfinityTable = (props: ActivityInfinityTableProps) => {
    const {columns, fetchOnScroll, loading, dataSource, scrollAfterX} = props

    return (
        <StyledActivityInfinityTable>
            <InfinityScroller fetchOnScroll={fetchOnScroll}>
                <StyledTable
                    columns={columns}
                    rowKey={(item: {id: string}) => item.id}
                    rowClassName={(item: {activityStatus?: ActivityStatusValues; status: ActivityStatusValues}) =>
                        getRowClassNameFromStatus(item.activityStatus || item.status)
                    }
                    dataSource={dataSource}
                    loading={loading}
                    pagination={false}
                    scroll={{x: scrollAfterX}}
                />
            </InfinityScroller>
        </StyledActivityInfinityTable>
    )
}
