import styled from 'styled-components'

import {ModalCenterContentWrapper, ModalContentWrapper} from 'ca-common/components/Modal'

export const SeedingContentWrapper = styled(ModalContentWrapper)`
    height: 432px;
`

export const SeedingCenterContentWrapper = styled(ModalCenterContentWrapper)`
    height: 432px;
`
