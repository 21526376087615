import styled, {css} from 'styled-components'
import {ButtonType} from 'antd/es/button'

import {Button} from 'ca-common/ui-lib/atoms'

export const CancelButton = styled(Button)<{type: ButtonType}>`
    box-shadow: none !important;

    ${props =>
        props.type !== 'primary' &&
        css`
            background-color: transparent !important;
            border-color: transparent !important;
        `}
`

export const BackButton = styled(CancelButton)`
    padding-left: 0;
`
