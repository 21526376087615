import {post} from 'src/newcore/utils/rest'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'

const EXEC_SMART_ALERTS = 'EXEC_SMART_ALERTS'
const EXEC_SMART_ALERTS_API = 'execSmartAlert'
const execSfcSmartAlertWrapper = fetchWrapperRT(
    EXEC_SMART_ALERTS,
    async data => await post(EXEC_SMART_ALERTS_API, data)
)

export const execSfcSmartAlert = execSfcSmartAlertWrapper.fetcher
