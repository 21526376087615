import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {debounce} from 'lodash'
import {ColumnProps} from 'antd/es/table'

import {getColumnsWidth} from 'src/newcore/features/BillingStatus/lib/getColumnsWidth'

export const usePaymentsColumns = (): ColumnProps<any>[] => {
    const {t} = useTranslation()
    const [columnsWidth, setColumnsWidth] = useState(getColumnsWidth())

    const {
        billableQuantityWidth,
        totalQuantityWidth,
        totalAmountWidth,
        unitPriceWidth,
        serviceWidth,
        emptyFieldWidth
    } = columnsWidth

    const handleResize = debounce(() => {
        setColumnsWidth(getColumnsWidth())
    }, 500)

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return [
        {
            title: t('table:columns:service'),
            dataIndex: 'service',
            width: serviceWidth
        },
        {
            title: t('table:columns:originalQty'),
            dataIndex: 'originalQty',
            width: totalQuantityWidth
        },
        {
            title: t('table:columns:quantity'),
            dataIndex: 'quantity',
            width: billableQuantityWidth
        },
        {
            title: t('table:columns:price'),
            dataIndex: 'price',
            width: unitPriceWidth,
            align: 'right'
        },
        {
            title: t('table:columns:totalAmount'),
            dataIndex: 'total',
            width: totalAmountWidth,
            align: 'right'
        },
        {width: emptyFieldWidth}
    ]
}
