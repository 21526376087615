import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 2px 2px ${variables.gutterXs};
    margin-bottom: ${variables.gutterXs};
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px;
        background: ${variables.blue};
    }

    &::-webkit-scrollbar-thumb {
        background: ${variables.asphaltLight};
        border-radius: 5px;
    }

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-form-item {
        margin-right: 0;
    }

    > :last-child {
        margin-right: 0;
        margin-left: 12px;
    }
`

export const StyledFormContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${variables.gutterMd};
    padding-bottom: 0;
    background-color: ${variables.white};
`

export const StyledRow = styled.div<{wrap?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};

    > *:not(:last-child) {
        margin-right: 12px;
    }
`
