import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {IconComponent} from 'ca-common/types'
import variables from 'ca-common/variables'

import {BADGES} from 'src/newcore/features/SignUp/lib'

export const StyledAwards = styled.div`
    display: flex;
    gap: 75px;
    padding: 80px ${variables.gutterXxl};
    align-items: center;
    justify-content: center;
    background-color: ${variables.white};

    @media screen and (max-width: ${variables.screenXl}) {
        flex-direction: column;
    }
`
export const StyledColumn = styled.div``
export const StyledTitle = styled.div`
    font-size: 52px;
    font-weight: 700;
    color: ${variables.signupText};
    line-height: 54px;
    margin-bottom: ${variables.gutterLg};

    span {
        font-weight: 300;
        font-style: italic;
        color: #f38b0b;
        line-height: 28px;
    }
`
export const StyledText = styled.div`
    font-size: 24px;
    font-weight: 400;
    color: ${variables.signupText};

    span {
        font-size: 32px;
        font-weight: 700;
        color: #f38b0b;
    }
`
export const StyledMedals = styled.div`
    display: flex;
    row-gap: ${variables.gutterXxl};
    column-gap: ${variables.gutterSm};
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 400px;
`

const desktopParams = {
    [BADGES.SA_FR_2023]: {height: '101px', width: '91px'},
    [BADGES.C_SL_2023]: {height: '91px', width: '109px'},
    [BADGES.HPW_2023]: {height: '109px', width: '89px'},
    [BADGES.SF_CLU_2023]: {height: '96px', width: '90px'},
    [BADGES.GA_CL_2023]: {height: '88px', width: '112px'}
}

const laptopParams = {
    [BADGES.SA_FR_2023]: {height: '101px', width: '91px'},
    [BADGES.C_SL_2023]: {height: '91px', width: '109px'},
    [BADGES.HPW_2023]: {height: '109px', width: '89px'},
    [BADGES.SF_CLU_2023]: {height: '96px', width: '90px'},
    [BADGES.GA_CL_2023]: {height: '88px', width: '112px'}
}

type MedalProps = {
    medalType: BADGES
    component: IconComponent
}
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export const StyledMedal = styled(({medalType, ...props}: MedalProps) => <Icon {...props} />)`
    width: calc(33% - 2 * ${variables.gutterXs});

    svg {
        ${props => laptopParams[props.medalType]}
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        svg {
            ${props => desktopParams[props.medalType]}
        }
    }
`
