import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum'
import {SwitchBar} from 'ca-common/ui-lib/molecules/SwitchBar'
import {WIZARD_ICON} from 'ca-common/ui-lib/atoms/WizardTitleIcon'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {getServiceName} from 'src/newcore/utils/sources'
import {useCreateMSTasksMutation} from 'src/newcore/features/OneClickActivation/redux'
import {
    changeConfig,
    setWizardDescription,
    setWizardIcon,
    setWizardTitle,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'

import type {ModalSelectionWizardData} from 'src/newcore/features/OneClickActivation/types'
import {Footer} from 'src/newcore/features/OneClickActivation/molecules'
import {ServiceIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {StyledSwitchWrapper, Subtitle, Body, StyledError} from './StyledOCAServicesSelectionStep'

const SERVICES_SELECTION_STEP = '0'

export const OCAServicesSelectionStep = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {enabledServices, error, availableServices} = useAppSelector(wizardDataSelector) as ModalSelectionWizardData
    const [createMSTasks, {isLoading}] = useCreateMSTasksMutation()

    const initialValues = useMemo(
        () => ({
            [SOURCE.MS365]: enabledServices ? enabledServices.includes(SOURCE.MS365) : true,
            [SOURCE.MS365GROUPS]: enabledServices ? enabledServices.includes(SOURCE.MS365GROUPS) : true,
            [SOURCE.SHAREPOINT_MULTI]: enabledServices ? enabledServices.includes(SOURCE.SHAREPOINT_MULTI) : true,
            [SOURCE.ONEDRIVE]: enabledServices ? enabledServices.includes(SOURCE.ONEDRIVE) : true
        }),
        []
    )

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:title')))
        dispatch(setWizardDescription(''))
        dispatch(setWizardIcon(WIZARD_ICON.ONE_CLICK_ACTIVATION))
        dispatch(
            changeConfig({
                beforeStepChange: undefined,
                maskClosable: false,
                destroyInactiveTabPane: true,
                hiddenTabBarItems: [SERVICES_SELECTION_STEP],
                stepsWithHiddenTabBar: [SERVICES_SELECTION_STEP]
            })
        )
    }, [])

    const [isRequestInProgress, setIsRequestInProgress] = useState(false)
    const [form, setForm] = useState(initialValues)

    useEffect(() => {
        setForm(initialValues)
    }, [initialValues])

    const onChangeCreator = (source: SOURCE) => (value: boolean) => {
        setForm(prev => ({...prev, [source]: value}))
    }

    const onSubmit = async () => {
        const services = Object.entries(form).reduce((acc, [key, value]) => {
            if (value && availableServices.includes(key as SOURCE)) {
                acc.push(key as SOURCE)
            }
            return acc
        }, [] as SOURCE[])

        createMSTasks({services}).then(response => {
            const url = (response as {data?: string})?.data
            setIsRequestInProgress(true)
            if (url) {
                window.location.href = url
            }
        })
    }

    return (
        <div>
            <Body>
                <Subtitle>{t('oneClickActivation:subtitle')}</Subtitle>
                <StyledSwitchWrapper>
                    {availableServices?.includes(SOURCE.MS365) && (
                        <SwitchBar
                            checked={form[SOURCE.MS365]}
                            icon={<ServiceIcon source={SOURCE.MS365} />}
                            name={getServiceName({source: SOURCE.MS365})}
                            onChange={onChangeCreator(SOURCE.MS365)}
                        />
                    )}
                    {availableServices?.includes(SOURCE.MS365GROUPS) && (
                        <SwitchBar
                            checked={form[SOURCE.MS365GROUPS]}
                            icon={<ServiceIcon source={SOURCE.MS365GROUPS} />}
                            name={getServiceName({source: SOURCE.MS365GROUPS})}
                            onChange={onChangeCreator(SOURCE.MS365GROUPS)}
                        />
                    )}
                    {availableServices?.includes(SOURCE.SHAREPOINT_MULTI) && (
                        <SwitchBar
                            checked={form[SOURCE.SHAREPOINT_MULTI]}
                            icon={<ServiceIcon source={SOURCE.SHAREPOINT_MULTI} />}
                            name={getServiceName({source: SOURCE.SHAREPOINT_MULTI})}
                            onChange={onChangeCreator(SOURCE.SHAREPOINT_MULTI)}
                        />
                    )}
                    {availableServices?.includes(SOURCE.ONEDRIVE) && (
                        <SwitchBar
                            checked={form[SOURCE.ONEDRIVE]}
                            icon={<ServiceIcon source={SOURCE.ONEDRIVE} />}
                            name={getServiceName({source: SOURCE.ONEDRIVE})}
                            onChange={onChangeCreator(SOURCE.ONEDRIVE)}
                        />
                    )}
                </StyledSwitchWrapper>
                <StyledError>{error}</StyledError>
            </Body>
            <Footer
                onSubmit={onSubmit}
                nextButtonText={t('forms:common:actions:authenticate')}
                loading={isLoading || isRequestInProgress}
                disabled={!Object.values(form).some(value => value)}
            />
        </div>
    )
}
