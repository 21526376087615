import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'

import DashboardDownload from 'ca-common/icons/old/uEA11-dashboard-download.svg'
import {StyledIcon, StyledLink} from './StyledReferenceumber'
import {track, EVENT_NAMES} from 'src/newcore/utils/mixpanel'
@withTranslation('table')
export class CAReferenceNumber extends React.Component {
    static propTypes = {
        item: PropTypes.shape({
            documentNumber: PropTypes.number
        }).isRequired
    }

    urlDetection = url => {
        const greeninvoice = 'greeninvoice'
        return url.includes(greeninvoice) ? <StyledIcon component={DashboardDownload} /> : null
    }

    render() {
        const {item, subscriptionType} = this.props
        const sendEvent = () => {
            track(EVENT_NAMES.BILLING_HISTORY_DOWNLOAD_DOCUMENT, {
                Subscription: subscriptionType
            })
        }
        return (
            <div>
                {item.downloadUrl ? (
                    <StyledLink pureLink to={item.downloadUrl} target="_blank" onClick={sendEvent}>
                        {item.documentNumber}
                        {this.urlDetection(item.downloadUrl)}
                    </StyledLink>
                ) : (
                    item.documentNumber
                )}
            </div>
        )
    }
}
