import Audit from 'ca-common/icons/old/uEA54-homescreen-security-audit.svg'
import Activity from 'ca-common/icons/old/uEA3F-system-activity.svg'
import {PAGES} from 'ca-common/constants'

export const SECTIONS = [
    {
        icon: Activity,
        name: 'system',
        permissionKey: PAGES.SYSTEM_ACTIVITY
    },
    {
        icon: Audit,
        name: 'security',
        permissionKey: PAGES.ACCOUNT_SECURITY_AUDIT
    }
]
