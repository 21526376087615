import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledComingSoon = styled.div`
    padding: 2px 5px;
    background-color: ${variables.asphaltLight};
    text-transform: uppercase;
    font-size: 8px;
    border-radius: 6px;
    color: ${variables.white};
    font-weight: 600;
    position: absolute;
    right: ${variables.gutterXs};
    top: ${variables.gutterXs};
`
