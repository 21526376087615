import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import pick from 'lodash/pick'

import {TASK_STATUS, PAGES, TaskStatus} from 'ca-common/constants'
import {SOURCE, AZURE_APP_VERSION} from 'ca-common/common/enum'
import type {Bit} from 'ca-common/types'
import {makeUrlParams} from 'ca-common/utils/url'
import {i18n} from 'src/locales'

import type {TaskObj} from 'src/newcore/features/Homepage/types'
import {sourcesWithNoActivatedEntitiesNote} from 'src/newcore/features/Homepage/utils/constants'

export const isTaskPaused = (status: keyof typeof TASK_STATUS): boolean => status === TASK_STATUS.PAUSED
export const isTaskProcessing = (status: keyof typeof TASK_STATUS): boolean => status === TASK_STATUS.INPROCESS
export const isTaskQueued = (status: keyof typeof TASK_STATUS): boolean => status === TASK_STATUS.QUEUED
export const isTaskCancelled = (progress: {isCancelled: boolean}): boolean => progress && progress.isCancelled

export const backupNowTaskState = (status: keyof typeof TASK_STATUS, progress: {isCancelled: boolean}): boolean =>
    isTaskProcessing(status) || isTaskCancelled(progress)
export const cancelTaskState = (status: keyof typeof TASK_STATUS, progress: {isCancelled: boolean}): boolean =>
    !isTaskProcessing(status) || isTaskCancelled(progress)

export const linkToExactBackup = (id: string): string => `${PAGES.BACKUPS}/${id}`

export function renderValueForNextBackup(
    progress: {isCancelled: boolean},
    status: keyof typeof TaskStatus,
    nextBackup: string
): string {
    if (progress && progress.isCancelled) {
        return i18n.t('BackupStatus:CANCEL')
    }

    if (status === TaskStatus.DISPATCHED) {
        return i18n.t('TaskStatus:INPROCESS')
    }

    return nextBackup
}

type urlData = {
    source: SOURCE
    id: string
    alias: string
    account: string
    region: string
    index: 1 | 0
    useRest: boolean
    domain: string
    endpointURI: string
    canDeleted: boolean
    isTeam: boolean
    isSupportPermissions: boolean
    isIndexMessagesChannel: boolean
    indexUpdatedByIdField: boolean
    azureAppVersion: AZURE_APP_VERSION
}

export const configureUrlData = (data: TaskObj): Partial<urlData> => {
    const indexStatus = data.indexStatus ? 1 : 0

    const pickedParams = pick(data, [
        'source',
        'id',
        'account',
        'domain',
        'region',
        'entity',
        'backupDate',
        'service',
        'beginDate',
        'endDate',
        'index',
        'extid',
        'useRest',
        'canDeleted',
        'isTeam',
        'isDropboxTeamFolder',
        'isIndexMessagesChannel',
        'isSupportPermissions',
        'indexUpdatedByIdField',
        'azureAppVersion'
    ])

    const computedParams = {
        alias: encodeURIComponent(data.alias),
        index: indexStatus as Bit,
        endpointURI: encodeURIComponent(data.endpointURI)
    }

    return {...pickedParams, ...computedParams}
}

export const getUrlToTaskOnOldApp = (
    base: typeof PAGES.RESTORE_SEARCH | typeof PAGES.RESTORE_ITEMS,
    data: Partial<urlData>
): string => {
    return `/App/${base}?${makeUrlParams(data)}`
}

export const useActiveEntityTooltip = (params: {
    source: SOURCE
    subActiveAccountsCount: number
    subAccountsCount: number
}) => {
    const {subActiveAccountsCount, subAccountsCount, source} = params
    const {t} = useTranslation()

    return useMemo(() => {
        if (sourcesWithNoActivatedEntitiesNote.includes(source) && !subActiveAccountsCount) {
            const entityName = t(`homepage:rowName:entityNames:${source}`)
            return t(`homepage:rowName:activatedBackups`, {
                activated: subActiveAccountsCount,
                total: subAccountsCount,
                entityName,
                interpolation: {escapeValue: false}
            })
        }
    }, [subAccountsCount, subActiveAccountsCount, source])
}
