import React, {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Tree} from 'antd'
import type {TreeProps} from 'antd/lib/tree/Tree'
import type {Key} from 'rc-tree/lib/interface'

import {CheckedElements, CheckedIds, TreeMap} from 'src/newcore/organisms/CustomTree/molecules/TreeMap'
import {StyledNodeTitle} from 'src/newcore/organisms/CustomTree/atoms/StyledNodeTitle'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree/atoms/Node'

const {DirectoryTree} = Tree

type onCheckTree = TreeProps['onCheck']
type onExpandTree = TreeProps['onExpand']

export type TreeElement = {
    title: string
    key: string
    noIcon?: boolean
    children?: Array<TreeElement>
}

export type RenderTreeNode = (data: Array<TreeElement>) => Array<JSX.Element>

export type TreeData = {
    data: Array<TreeElement>
    search?: boolean
    restoredFolders: Record<string, boolean>
}

export type TreeStateProps = {
    checkedKeys: CheckedIds
    setCheckedKeys: (keys: CheckedElements) => void
    selectedKey: string
}

export type TreeMethods = {
    setCheckedKey: (eventKey: string, status: CHECK_STATE, connectState?: CHECK_STATE) => void
    getCheckedKeys: () => CheckedIds | undefined
    onCheckAll: (check: boolean) => void
    filterTree: (str: string) => void
    setCheckedKeys: (check: CheckedIds) => void
}

export type CustomTree = TreeProps & TreeData & TreeStateProps

export const CustomTree = forwardRef((props: CustomTree, ref) => {
    const {data, onSelect, search = false, selectedKey, checkedKeys, setCheckedKeys, icon, restoredFolders} = props
    const [expandedKeys, setExpandedKeys] = useState<Array<Key>>([])
    const map = useRef(new TreeMap(data))

    useImperativeHandle(
        ref,
        (): TreeMethods => ({
            setCheckedKey: (eventKey, status, connectState) => {
                const checkedKeys = map.current.setCheckedNodes(eventKey, status, connectState).getCheckedNodes()
                setCheckedKeys(checkedKeys)
            },
            getCheckedKeys: () => {
                return checkedKeys
            },
            onCheckAll: (check: boolean) => {
                const newKeys = map.current.setAllCheckedNodes(check).getCheckedNodes()
                setCheckedKeys(newKeys)
            },
            filterTree: str => {
                const res = map.current.getFilteredNodes(str)
                setExpandedKeys(res)
            },
            setCheckedKeys: check => {
                map.current.setPartialAndCheckedNodes(check)
            }
        })
    )

    useEffect(() => {
        setExpandedKeys([])
        map.current = new TreeMap(data)
    }, [data])

    const onCheck: onCheckTree = (_checked, info) => {
        const {
            node: {key},
            checked: check
        } = info
        const newKeys = map.current.setCheckedNodes(String(key), check).getCheckedNodes()
        setCheckedKeys(newKeys)
    }

    const onExpand: onExpandTree = (keys, info) => {
        const {
            expanded,
            node: {key}
        } = info
        let newKeys = [...keys]
        if (!expanded) {
            const excludedKeys = map.current.getExpandedNodes(String(key)) as Key[]
            newKeys = expandedKeys.filter(el => !excludedKeys.includes(el))
        }
        setExpandedKeys(newKeys)
    }

    return (
        <DirectoryTree
            checkable={true}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            checkStrictly={true}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            onSelect={onSelect}
            autoExpandParent={search}
            expandAction="doubleClick"
            height={636}
            virtual={true}
            selectedKeys={[selectedKey]}
            titleRender={node =>
                restoredFolders[node.key] ? <StyledNodeTitle>{node.title}</StyledNodeTitle> : node.title
            }
            treeData={data}
            icon={icon}
        />
    )
})

export type InnerTreeProps = CustomTree & {
    parent: Ref<unknown> | undefined
}
