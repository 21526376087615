import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {RECOVERY_TYPE, SOURCE} from 'ca-common/common/enum'
import {MSTaskEntityType} from 'ca-common/types'
import {DateChoose} from 'src/newcore/features/RecoveryWizard/molecules/DateChoose'
import {BACKUP_DATE_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'
import {SelectedDayInfo} from 'src/newcore/molecules/SelectedDayInfo'

import {MainWrapper, SectionsWrapper, StyledLabel} from './StyledDateConfiguration'

type DateConfigurationProps = {
    entity: MSTaskEntityType
    lastBackupDate: number
    date: number
    setDate: React.Dispatch<React.SetStateAction<number>>
    dateType: BACKUP_DATE_OPTIONS
    setDateType: React.Dispatch<React.SetStateAction<BACKUP_DATE_OPTIONS>>
    taskId: string
    fullRecoveryOptions: RECOVERY_TYPE
    source: SOURCE
}

export const DateConfiguration = ({
    entity,
    lastBackupDate,
    date,
    setDate,
    dateType,
    setDateType,
    taskId,
    fullRecoveryOptions,
    source
}: DateConfigurationProps): JSX.Element => {
    const {t} = useTranslation()

    const calendarPayload = {
        accountId: entity?.entity,
        extid: entity?.extid,
        taskId
    }

    useEffect(() => {
        if (lastBackupDate) setDateType(BACKUP_DATE_OPTIONS.LAST_SUCCESSFUL_BACKUP)
    }, [])

    return (
        <MainWrapper>
            <StyledLabel>{t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:label')}</StyledLabel>
            <SectionsWrapper>
                <DateChoose
                    setBackupDate={setDate}
                    setBackupDateType={setDateType}
                    lastBackupDate={lastBackupDate}
                    firstBackupDate={entity?.firstBackupDate}
                    retentionDate={entity?.retentionDate}
                    dateType={dateType}
                    calendarPayload={calendarPayload}
                />
                {dateType === BACKUP_DATE_OPTIONS.SPECIFIC_BACKUP_DATE && (
                    <SelectedDayInfo
                        backupDate={date}
                        setBackupDate={setDate}
                        taskId={calendarPayload.taskId}
                        fullRecoveryOptions={fullRecoveryOptions}
                        source={source}
                    />
                )}
            </SectionsWrapper>
        </MainWrapper>
    )
}
