import React from 'react'
import {useTranslation} from 'react-i18next'

import {MSIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {StyledNewHomePageButton, StyledNewWrapper, StyledTitleText} from './StyledOCAButton'

type OneClickActivationButtonProps = {
    onClick: () => void
}

export const OCAButton = ({onClick}: OneClickActivationButtonProps) => {
    const {t} = useTranslation()
    return (
        <StyledNewWrapper onClick={onClick}>
            <StyledNewHomePageButton>
                <MSIcon />
                <StyledTitleText>{t('oneClickActivation:title')}</StyledTitleText>
            </StyledNewHomePageButton>
        </StyledNewWrapper>
    )
}
