import React from 'react'
import {Row, Col, Input, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {
    StyledBackupTagsForm,
    BackupTagsHeader,
    BackupTagsBody,
    BackupTagsFooter
} from 'src/newcore/components/BackupTags'
import {track, getServiceNameMixpanel} from 'src/newcore/utils/mixpanel'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

export const BackupTagsForm = props => {
    const {onSubmit, onCancel, loading} = props
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const onFinishForm = values => {
        const Service = getServiceNameMixpanel(props.service)
        track(EVENT_NAMES.CREATE_NEW_TAG, {Service})
        track(EVENT_NAMES.TAG_ASSIGNED, {
            'Tag Type': 'Custom',
            Service
        })
        onSubmit(values, form)
    }

    return (
        <StyledBackupTagsForm onFinish={onFinishForm} form={form} layout="horizontal" hideRequiredMark>
            <BackupTagsHeader>{t('forms:backupTags:text:modalTitle')}</BackupTagsHeader>
            <BackupTagsBody>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label={t('forms:backupTags:fields:text:label')}
                            colon={false}
                            name="text"
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:validation:required')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </BackupTagsBody>
            <BackupTagsFooter>
                <Button type="link" onClick={onCancel}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('forms:common:actions:create')}
                </Button>
            </BackupTagsFooter>
        </StyledBackupTagsForm>
    )
}
