import {createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'
import {Id, TODO_ANY} from 'ca-common/types'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'

const GET_JOB_ITEMS = 'GET_JOB_ITEMS'
const GET_JOB_ITEMS_API = 'getBackupTaskInfo'
const getJobItemsWrapper = fetchWrapperRT(GET_JOB_ITEMS, async data => await get(GET_JOB_ITEMS_API, data))

type BackupItem = {
    entity: string
    extid: Id
    id: Id
    lastBackup: string
    selected: boolean
    size: string
    status: string
}

type BackupTaskInfoResponse = {
    data: BackupItem[]
    total: number
    success: boolean
    totalPages: number
}

export const getJobItems = (data: Record<string, TODO_ANY>) => (dispatch: AppDispatch) =>
    dispatch(
        getJobItemsWrapper.fetcher({
            _search: false,
            rows: 1000,
            page: 1,
            sord: 'asc',
            ...data
        })
    )

const itemsSlice = createSlice({
    name: 'items',
    initialState: InitFetchedState as FetchedState<BackupItem[]>,
    reducers: {
        clearJobItems: () => InitFetchedState as FetchedState<BackupItem[]>
    },
    extraReducers: builder => getJobItemsWrapper.makeReducers(builder)
})

export const {clearJobItems} = itemsSlice.actions
export const items = itemsSlice.reducer
