import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledReplicatesList = styled.div`
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }
`

export const HeaderRow = styled.div`
    display: flex;
    margin: 0 0 ${variables.gutterSm};
    align-items: center;
    justify-content: space-between;
`

export const Description = styled.div`
    font-size: 18px;
    font-weight: 300;
    color: ${variables.asphalt};
`
