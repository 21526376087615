import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {UnmountClosed} from 'react-collapse'

import {makeRowModifiers} from 'ca-common/utils/table'
import {item as itemShape, config as configShape, rowSelection as rowSelectionShape} from 'ca-common/shapes/table'

import {CARowWrapper, CARowIndicator, CARowCollapse} from 'ca-common/ui-lib/components/TableElements'
import {CARowCells} from './RowCells'

export class CARow extends React.Component {
    static propTypes = {
        i: PropTypes.number.isRequired,
        item: PropTypes.shape(itemShape).isRequired,
        config: PropTypes.shape(configShape).isRequired,
        rowSelection: PropTypes.shape(rowSelectionShape)
    }

    static defaultProps = {
        rowSelection: null
    }

    state = {
        isExpanded: false
    }

    render() {
        const {item, i, config, rowSelection, disabled} = this.props
        const {isExpanded} = this.state

        const modifier = disabled ? 'disabled' : _.join(makeRowModifiers(item, i, config), ' ')
        const collapse = {onExpand: () => this.setState({isExpanded: !isExpanded}), isExpanded}
        const RowCollapseContent = config.table.rowCollapseContent

        return (
            <CARowWrapper id={item.id} modifier={modifier}>
                <CARowIndicator />
                <CARowCells item={item} config={config} rowSelection={rowSelection} collapse={collapse} />
                {RowCollapseContent && (
                    <UnmountClosed isOpened={isExpanded}>
                        <CARowCollapse>
                            <RowCollapseContent item={item} index={i} />
                        </CARowCollapse>
                    </UnmountClosed>
                )}
            </CARowWrapper>
        )
    }
}
