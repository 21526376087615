import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {ADDRESS_REGEXP, isAddressNumbersValid} from 'ca-common/features/IpRestriction/lib'

export const useAddressListValidator = () => {
    const {t} = useTranslation()
    return useCallback((addresses: string, isNeededValidation?: boolean) => {
        if (!isNeededValidation) {
            return Promise.resolve()
        }

        let error = ''
        const addressesArr = addresses.trim().split(/\s*,\s*/)

        if (addressesArr.length < 0) {
            return Promise.reject(t('validation:invalidIpAddressFormat'))
        }

        addressesArr.some((address, index, arr) => {
            const addressNumbers = address.split('.').map(Number)

            if (!ADDRESS_REGEXP.test(address)) {
                error = t('validation:invalidIpAddressFormat')
                return true
            }

            if (!isAddressNumbersValid(addressNumbers)) {
                error = t('validation:invalidIpAddressNumbers')
                return true
            }

            if (arr.lastIndexOf(address) !== index) {
                error = t('validation:ipAddressExists')
                return true
            }

            return false
        })

        if (error) {
            return Promise.reject(error)
        }

        return Promise.resolve()
    }, [])
}
