import {createAction, createReducer, combineReducers} from '@reduxjs/toolkit'

export const TOGGLE_TWO_FA_SIGN_IN = 'TOGGLE_TWO_FA_SIGN_IN'
export const toggleTwoFA = createAction<boolean>(TOGGLE_TWO_FA_SIGN_IN)
export const displayTwoFAReducer = createReducer(false, {
    [TOGGLE_TWO_FA_SIGN_IN]: (state, action) => action.payload
})

export const TOGGLE_RECOVERY_EXPIRED_VISIBLE = 'TOGGLE_RECOVERY_EXPIRED_VISIBLE'
export const toggleRecoveryExpiredVisible = createAction<boolean>(TOGGLE_RECOVERY_EXPIRED_VISIBLE)
export const isRecoveryExpiredVisible = createReducer(false, {
    [TOGGLE_RECOVERY_EXPIRED_VISIBLE]: (state, action) => action.payload
})

export const signIn = combineReducers({
    displayTwoFA: displayTwoFAReducer,
    isRecoveryExpiredVisible: isRecoveryExpiredVisible
})
