import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: ${variables.gutterXs};
    margin-bottom: -${variables.gutterXs};
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px;
        background: ${variables.blue};
    }

    &::-webkit-scrollbar-thumb {
        background: ${variables.asphaltLight};
        border-radius: 5px;
    }

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-form-item {
        margin-right: ${variables.gutterMd};
    }

    .ant-form-item:last-child {
        margin-right: 0;
    }
`

export const StyledField = styled(Form.Item)`
    && {
        flex: 1;
        max-width: 415px;
        margin-right: auto;
    }
`

export const StyledRow = styled.div<{wrap?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
    > *:not(:last-child) {
        margin-right: 12px;
    }
`

export const StyledFormContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${variables.gutterMd};
    background-color: ${variables.white};
    margin-bottom: 20px;
    border-radius: ${variables.borderRadiusBlocks};
`
