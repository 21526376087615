import styled, {css} from 'styled-components'

import variables from 'ca-common/variables'

export const StyledActiveBackup = styled.div`
    display: flex;
    align-items: center;
    font-size: ${variables.fontSizeSecondary};
    white-space: pre-wrap;
    margin-top: ${variables.gutterXs};
`

export const StyledAction = styled.div`
    color: ${variables.navyAsphalt};

    ${props =>
        props.active &&
        css`
            color: ${variables.navyBlue};
            text-decoration: underline;
        `};

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`

export const ActionWrapper = styled.div`
    margin-right: ${variables.gutterXs};

    &:last-child {
        margin-right: 0;
    }

    :hover {
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    }

    .ant-tooltip-inner {
        text-align: center;
    }
`
