import styled from 'styled-components'
import variables from 'ca-common/variables'
import {HeadingTitle} from 'ca-common/ui-lib/atoms/Typography'

export const Title = styled(HeadingTitle)`
    && {
        @media screen and (max-width: ${variables.screenLaptop}) {
            font-size: ${variables.subTitleFontSize};
        }
    }
`
