import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Form, Input} from 'antd'

import {CREDENTIALS_TYPES} from 'ca-common/constants'
import {useTimer} from 'ca-common/utils/useTimer'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {
    LIMITS,
    passwordPatternValidation,
    trailingOrLeadingSpace,
    passwordSpecialCharValidation
} from 'ca-common/utils/validation'
import {ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'

import {
    ModalHeader,
    StyledClickableText,
    StyledFormWrapper,
    StyledModal,
    StyledText,
    StyledTimer,
    StyledWrapper
} from './StyledResendConfirmationEmail'

const TIMER = 30

type ResendConfirmationEmailProps = {
    resend: (password?: string) => Promise<void>
    type: CREDENTIALS_TYPES
}

export const ResendConfirmationEmail = ({resend, type: credentialType}: ResendConfirmationEmailProps) => {
    const {t} = useTranslation()
    const {timer, runTimer} = useTimer(TIMER)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm()

    const onResendClick = () => {
        if (timer <= 0) {
            if (credentialType === CREDENTIALS_TYPES.EMAIL) {
                setIsModalOpen(true)
            } else {
                resend()
                runTimer()
            }
        }
    }

    const onFinish = ({password}: {password: string}) => {
        setIsLoading(true)

        resend(password)
            .then(() => {
                setIsModalOpen(false)
                setIsLoading(false)
                runTimer()
            })
            .catch((e: {message: string}) => {
                openErrorNotification(e.message)
                setIsLoading(false)
            })
    }

    return (
        <StyledWrapper>
            {timer > 0 ? (
                <StyledText>
                    <Trans i18nKey="userManagement:waitForResend" tOptions={{timer}}>
                        <StyledTimer />
                    </Trans>
                </StyledText>
            ) : (
                <StyledClickableText onClick={onResendClick}>
                    {t('userManagement:resendEmailConfirmation')}
                </StyledClickableText>
            )}
            <StyledModal
                open={isModalOpen}
                title={<ModalHeader>{t('userManagement:createPasswordModal:title')}</ModalHeader>}
                closable={false}
                destroyOnClose
                footer={null}
                width={748}
                centered
            >
                <StyledFormWrapper>
                    <Form form={form} onFinish={onFinish} colon={false} layout="vertical" validateTrigger="onBlur">
                        <Form.Item
                            label={t('userManagement:createPasswordModal:passwordLabel')}
                            name="password"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:atLeastOneNumAndChar'),
                                    pattern: passwordPatternValidation
                                },
                                {
                                    required: true,
                                    message: t('validation:specialCharValidation'),
                                    pattern: passwordSpecialCharValidation
                                },
                                {
                                    required: true,
                                    message: t('validation:trailingOrLeadingSpace'),
                                    pattern: trailingOrLeadingSpace
                                },
                                {
                                    max: LIMITS.PASSWORD.MAX,
                                    message: t('validation:maxPassword', [LIMITS.PASSWORD.MAX])
                                },
                                {
                                    min: LIMITS.PASSWORD.MIN,
                                    message: t('validation:minPassword', [LIMITS.PASSWORD.MIN])
                                }
                            ]}
                            validateFirst
                        >
                            <Input
                                placeholder={t('userManagement:createPasswordModal:passwordPlaceholder')}
                                type="password"
                            />
                        </Form.Item>
                    </Form>
                </StyledFormWrapper>
                <ModalFooter>
                    <Button loading={isLoading} type="primary" onClick={() => form.submit()}>
                        {t('userManagement:createPasswordModal:done')}
                    </Button>
                    <Button loading={isLoading} type="text" onClick={() => setIsModalOpen(false)}>
                        {t('modals:buttons:cancel')}
                    </Button>
                </ModalFooter>
            </StyledModal>
        </StyledWrapper>
    )
}
