import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledWrapper = styled.div<{creditCardPayment: boolean}>`
    .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
    }
    .ant-radio-button-wrapper:first-child {
        height: 70px;
        margin: 0px ${variables.headingFontSize} 0px 0px;
        border-radius: 10px;
        width: 191px;
        border: ${props => {
            if (props.creditCardPayment) {
                return `2px ${variables.navyBlue} solid`
            }
            return `1.25px ${variables.ligthBlack} solid`
        }};
    }
    .ant-radio-button-wrapper:last-child {
        height: 70px;
        width: 191px;
        border-radius: 10px;
        border: ${props => {
            if (!props.creditCardPayment) {
                return `2px ${variables.navyBlue} solid`
            }
            return `1.25px ${variables.ligthBlack} solid`
        }};
    }
`

export const PaymentTypeWrapper = styled.div`
    font-size: ${variables.paymentIconSize};
    display: inline;
`
export const PaymentTypeContentsWrapper = styled.span`
    margin-left: ${variables.fontSizeXs};
    color: ${variables.text};
    font-size: ${variables.fontSizeBase};
    font-family:  ${variables.publicPageFont};;
    font-weight: 600;
    word-wrap: break-word 
    position: absolute;
    margin-left: 51px;
    margin-top: -${variables.headingFontSize};
`
export const PaymentContentsWrapper = styled.span`
    position: absolute;
    margin-left: 51px;
    margin-top: -${variables.headingFontSize};
`
export const PaymentDetailsIcon = styled(Icon)`
    position: absolute;
    font-size: ${variables.gutterXxl};
    margin-top: ${variables.fontSizeBase};
`
export const StyledSelectIcon = styled(Icon)`
    position: absolute;
    top: -${variables.headingFontSize};
    right: -${variables.fontSizeXs};
    fontsize: ${variables.headingFontSize};
`
