import React from 'react'
import {useTranslation} from 'react-i18next'

import {StyledPagination} from './StyledPagination'

type PaginationProps = {
    total?: number
    pageSize: number
    currentPage: number
    changeUrlParams: (arg0: any, arg1: any, arg2: any) => void
    disabled: boolean
    sortType?: string
    sortOrderType?: string
}

export const Pagination = ({
    total,
    pageSize,
    changeUrlParams,
    currentPage,
    disabled,
    sortType,
    sortOrderType
}: PaginationProps): JSX.Element => {
    const {t} = useTranslation()

    const itemRender = (
        page: number,
        type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
        originalElement: React.ReactNode
    ): React.ReactNode => {
        if (type === 'prev') {
            return <a>{t('pagination:prev')}</a>
        }

        if (type === 'next') {
            return <a>{t('pagination:next')}</a>
        }
        return originalElement
    }

    const setCurrentPage = (page: number) => {
        if (sortType != undefined && sortOrderType != undefined) {
            changeUrlParams(page, sortOrderType, sortType)
        } else {
            changeUrlParams({page}, {}, {})
        }
    }

    return (
        <StyledPagination
            disabled={disabled}
            hideOnSinglePage
            current={currentPage}
            onChange={setCurrentPage}
            showTotal={(total, range) => t('JobsPage:pagination:countInfo', {start: range[0], end: range[1], total})}
            pageSize={pageSize}
            total={total}
            showSizeChanger={false}
            itemRender={itemRender}
        />
    )
}
