import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Spin} from 'ca-common/ui-lib/atoms/Spin'

import {
    StyledForecastCard,
    StyledForecastCardTitle,
    StyledForecastCardSubtitle,
    StyledForecastCardNote,
    StyledForecastCardTooltip,
    StyledForecastCardButton,
    StyledForecastCardCost,
    StyledForecastCardTooltipWrapper,
    StyledNotAvailable
} from './StyledForecastCard'

type ForecastCardProps = {
    title: string
    subtitle: string | JSX.Element
    note?: string | JSX.Element
    data?: Record<string, any>
    subscriptionType: 'YEARLY' | 'MONTHLY'
    isNoBillsStatus?: boolean
    onClick: () => void
    isLoading?: boolean
    showPaymentDetails?: boolean
    isUnavailableCurrency?: boolean
    isLoadingData?: boolean
}

export const ForecastCard = (props: ForecastCardProps) => {
    const {
        title,
        subtitle,
        note,
        data,
        subscriptionType,
        isNoBillsStatus,
        onClick,
        isLoading,
        showPaymentDetails,
        isUnavailableCurrency,
        isLoadingData
    } = props
    const {t} = useTranslation()
    const subscriptionCost = subscriptionType === 'YEARLY' ? data?.totalAnnual : data?.totalMonthly

    const isUnavailableSubscription = isUnavailableCurrency && subscriptionType === 'MONTHLY'

    return (
        <StyledForecastCard
            type={subscriptionType}
            showPaymentDetails={showPaymentDetails}
            isLoadingData={isLoadingData}
        >
            {isLoadingData ? (
                <Spin />
            ) : (
                <>
                    {isUnavailableSubscription ? (
                        <StyledNotAvailable>
                            <Trans i18nKey="forecastCard:subscriptionNotAvailable" />
                        </StyledNotAvailable>
                    ) : (
                        <>
                            <StyledForecastCardTitle>{title}</StyledForecastCardTitle>
                            <StyledForecastCardCost showPaymentDetails={showPaymentDetails}>
                                {!isNoBillsStatus && showPaymentDetails && subscriptionCost}
                            </StyledForecastCardCost>
                        </>
                    )}
                    {!isUnavailableSubscription && showPaymentDetails && (
                        <StyledForecastCardSubtitle isUnavailableCurrency={isUnavailableCurrency}>
                            {subtitle}
                        </StyledForecastCardSubtitle>
                    )}
                    {!isUnavailableSubscription && (
                        <StyledForecastCardButton type="primary" onClick={() => !isLoading && onClick()}>
                            {t(`forecastCard:subscribeButtonText`)}
                        </StyledForecastCardButton>
                    )}
                    {!isNoBillsStatus && showPaymentDetails && !isUnavailableSubscription && (
                        <StyledForecastCardNote>{note}</StyledForecastCardNote>
                    )}
                    {subscriptionType === 'YEARLY' &&
                        !isNoBillsStatus &&
                        showPaymentDetails &&
                        !isUnavailableCurrency && (
                            <StyledForecastCardTooltipWrapper>
                                <StyledForecastCardTooltip>
                                    <b>{t(`forecastCard:discountAnnualBold`, data)}</b>
                                    <br />
                                    {t(`forecastCard:discountAnnual`)}
                                </StyledForecastCardTooltip>
                                <StyledForecastCardTooltip>
                                    <b>{t(`forecastCard:annualGrowthBold`)}</b>
                                    <br />
                                    {t(`forecastCard:annualGrowth`)}
                                </StyledForecastCardTooltip>
                            </StyledForecastCardTooltipWrapper>
                        )}{' '}
                </>
            )}
        </StyledForecastCard>
    )
}
