import {useEffect} from 'react'
import * as SentryLib from '@sentry/browser'

export const Sentry = () => {
    useEffect(() => {
        if (process.env.ENVIRONMENT !== 'dev') {
            SentryLib.init({dsn: process.env.CENTRY_DSN, environment: process.env.ENVIRONMENT})
        }
    }, [])

    return null
}
