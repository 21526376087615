import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router'
import Icon from '@ant-design/icons'
import {ButtonType} from 'antd/lib/button/button'

import {LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'

import ArrowLeft from 'ca-common/icons/ArrowLeft.svg'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {closeWizard, nextStep, previousStep, wizardConfigSelector} from 'src/newcore/redux/modules/wizard'

import {BackButton, CancelButton} from './StyledFooter'
import {useIsFirstStep} from 'src/newcore/features/OneClickActivation/lib/useIsFirstStep'

type FooterProps = {
    hideNextButton?: boolean
    loading?: boolean
    onSubmit?: () => void
    cancelButtonText?: string
    cancelButtonType?: ButtonType
    nextButtonText?: string
    disabled?: boolean
    isBackupSettingsStep?: boolean
    onCancel?: () => void
}

export const Footer = ({
    hideNextButton,
    loading,
    onSubmit,
    cancelButtonText,
    cancelButtonType,
    nextButtonText,
    disabled,
    isBackupSettingsStep,
    onCancel
}: FooterProps) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation()
    const isFirstStep = useIsFirstStep()
    const {beforeStepChange} = useAppSelector(wizardConfigSelector)

    const handleNextStep = async () => {
        try {
            beforeStepChange && (await beforeStepChange())
            onSubmit ? onSubmit() : dispatch(nextStep())
        } catch (e) {
            return
        }
    }

    const handlePreviousStep = async () => {
        try {
            beforeStepChange && (await beforeStepChange())
            dispatch(previousStep())
        } catch (e) {
            if (isBackupSettingsStep) {
                dispatch(previousStep())
            }

            return
        }
    }

    const handleCancel = () => {
        onCancel && onCancel()
        history.replace(location.pathname)
        dispatch(closeWizard())
    }

    return (
        <ModalFooter>
            <LinkWrapper>
                <CancelButton type={cancelButtonType || 'link'} onClick={handleCancel} disabled={loading}>
                    {cancelButtonText || t('modals:buttons:cancel')}
                </CancelButton>
                {!hideNextButton && (
                    <Button type="primary" onClick={handleNextStep} loading={loading} disabled={disabled}>
                        {nextButtonText || t('modals:buttons:next')}
                    </Button>
                )}
            </LinkWrapper>
            {!isFirstStep && (
                <BackButton
                    onClick={handlePreviousStep}
                    disabled={loading}
                    type="link"
                    icon={<Icon component={ArrowLeft} style={{fontSize: '16px'}} />}
                >
                    {t('modals:buttons:back')}
                </BackButton>
            )}
        </ModalFooter>
    )
}
