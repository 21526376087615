import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledBillingStatusContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterMd};

    .ant-table-wrapper {
        margin-bottom: ${variables.gutterMd};
    }
`

export const Email = styled.span`
    color: ${variables.navyBlue};
`
