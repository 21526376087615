import React, {useMemo} from 'react'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import reject from 'lodash/reject'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar'
import {TASK_TYPE} from 'ca-common/common/enum'

import {getServiceName} from 'src/newcore/utils/sources'

import {JOB_FIELDS, getActivityFriendly, getJobStatusFriendly, JOB_STATUS_OPTIONS} from 'src/newcore/features/Jobs/lib'

import {StyledSelectedFilters} from './StyledSelectedFilters'

type Props = {
    loading: boolean
    values: any
    setValues: any
    jobTasks: any
}

export const SelectedFilters = (props: Props): JSX.Element | null => {
    const {loading, setValues, values, jobTasks} = props

    const remove = (value: string) => {
        const [parent, target] = value.split('.')

        const parentFromValues = values[parent]

        if (isArray(parentFromValues)) {
            values[parent] = reject(parentFromValues, i => i === target)
        } else if (parent === JOB_FIELDS.TASK_QUERY) {
            values[parent] = undefined
            if (values?.[JOB_FIELDS.ITEM_QUERY]) {
                values[JOB_FIELDS.ITEM_QUERY] = undefined
            }
        } else {
            values[parent] = undefined
        }

        setValues(values)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: []}), {})

        setValues(newValues)
    }

    const selectedFilterValues = useMemo(() => {
        const localize = (key: string, item: string) => {
            switch (key) {
                case JOB_FIELDS.ACTIVITY:
                    return getActivityFriendly(item as TASK_TYPE)
                case JOB_FIELDS.SERVICE:
                    return getServiceName({source: item})
                case JOB_FIELDS.STATUS:
                    return getJobStatusFriendly(item as JOB_STATUS_OPTIONS)
                case JOB_FIELDS.INITIATOR:
                    return item
                case JOB_FIELDS.TASK_QUERY: {
                    const founded = jobTasks?.find((i: {id: string}) => i?.id === item)

                    return founded?.alias || founded?.id
                }
                case JOB_FIELDS.ITEM_QUERY:
                    return item
                default:
                    return ''
            }
        }

        return reduce<any, Array<{value: string; label: string}>>(
            values,
            (result, current, key) => [
                ...result,
                ...(isArray(current)
                    ? map(current, item => ({
                          value: `${key}.${item}`,
                          label: localize(key, item)
                      }))
                    : [
                          {
                              value: `${key}.${current}`,
                              label: localize(key, current)
                          }
                      ])
            ],
            []
        )
    }, [values, jobTasks])

    return isEmpty(values) ? null : (
        <StyledSelectedFilters disabled={loading}>
            <SelectedFiltersBar onRemove={remove} onRemoveAll={removeAll} selectedFilterValues={selectedFilterValues} />
        </StyledSelectedFilters>
    )
}
