import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const VALIDATE_SFC_WHERE_CLAUSE = 'VALIDATE_SFC_WHERE_CLAUSE'
const VALIDATE_SFC_WHERE_CLAUSE_API = 'validateSfcWhereClause'
const validateSfcWhereClauseWrapper = fetchWrapperRT(
    VALIDATE_SFC_WHERE_CLAUSE,
    async data => await post(VALIDATE_SFC_WHERE_CLAUSE_API, data)
)
export const validateSfcWhereClause = validateSfcWhereClauseWrapper.fetcher

const validateSfcWhereClauseResultSlice = createSlice({
    name: 'validateSfcWhereClauseResult',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => validateSfcWhereClauseWrapper.makeReducers(builder)
})
export const validateSfcWhereClauseResultReducer = validateSfcWhereClauseResultSlice.reducer
