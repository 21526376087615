import React, {useEffect} from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import {useTranslation} from 'react-i18next'

import {openErrorModal} from 'ca-common/utils/modals'

import {useAppDispatch} from 'src/newcore/components/Root'
import {setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {
    clearActiveDirectoryAccounts,
    getActiveDirectoryAccounts
} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'
import {setExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {AccountItem} from './AccountItem'
import {AccountListWrapper} from './Styled'

const FIRST_PAGE = 1
const PAGE_SIZE = 20

const EntitiesRaw = props => {
    const {activeDirectoryAccounts, bulkActivation, excludeEmails} = props
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const params = useParams()
    const {data, page, totalPages, cacheToken, total = 0} = activeDirectoryAccounts.response || {}
    const propertyList = _.get(bulkActivation, 'activeDirectory.accountPropertyList.response.data', [])
    const selectedPropertyName =
        _.find(propertyList, ['id', bulkActivation.activeDirectory.accountPropertyName])?.name ||
        bulkActivation.activeDirectory.accountPropertyName

    const fetchActiveDirectoryAccounts = page => {
        return dispatch(
            getActiveDirectoryAccounts({
                taskId: params.taskId,
                rows: PAGE_SIZE,
                page,
                cacheToken: page !== FIRST_PAGE ? cacheToken : undefined,
                property: bulkActivation.activeDirectory.accountPropertyName,
                propertyValues: bulkActivation.activeDirectory.checkedAccountPropertyValues
            })
        )
            .unwrap()
            .catch(err => openErrorModal(err.status))
    }

    const handleScroll = () => {
        if (activeDirectoryAccounts.status === 'fulfilled' && page < totalPages) {
            fetchActiveDirectoryAccounts(page + 1)
        }
    }

    const onClick = mail => {
        dispatch(setExcludeEmails(mail))
    }

    useEffect(() => {
        dispatch(clearActiveDirectoryAccounts())
        fetchActiveDirectoryAccounts(FIRST_PAGE)
    }, [])

    useEffect(() => {
        dispatch(
            setWizardDescription(
                t('backups:bulkActivation:accountsToActivate', {
                    count: total - excludeEmails.length
                })
            )
        )
    }, [excludeEmails, total])

    return (
        <AccountListWrapper>
            <InfiniteScroll
                initialLoad={false}
                pageStart={FIRST_PAGE}
                useWindow={false}
                hasMore={page < totalPages}
                loadMore={handleScroll}
            >
                <Spin spinning={activeDirectoryAccounts.status === 'pending'}>
                    {_.map(data, entity => (
                        <AccountItem
                            {...entity}
                            propertyName={selectedPropertyName}
                            active={!_.includes(excludeEmails, entity.mail)}
                            key={entity.id}
                            onClick={() => onClick(entity.mail)}
                        />
                    ))}
                </Spin>
            </InfiniteScroll>
        </AccountListWrapper>
    )
}

const mapStateToProps = state => ({
    activeDirectoryAccounts: state.backups.bulkActivation.activeDirectory.activeDirectoryAccounts,
    bulkActivation: state.backups.bulkActivation,
    excludeEmails: state.backups.bulkActivation.excludeEmails
})

export const AccountList = connect(mapStateToProps, null)(EntitiesRaw)
