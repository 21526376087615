import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Body = styled.div`
    padding: 50px 100px 0;
    border-top: 1px solid ${variables.blue};
    display: flex;
    flex-direction: column;
    min-height: 445px;
    justify-content: center;

    @media (max-width: ${variables.screenLg}) {
        padding: 50px ${variables.gutterSm} 0;
    }
`
export const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterMd};
`

export const NoEntitiesSelected = styled.div`
    font-style: italic;
    font-weight: 300;
    font-size: ${variables.fontSizeBase};
    text-align: end;
    color: ${variables.navyAsphalt};
    margin-top: auto;
    margin-bottom: ${variables.gutterSm};
    height: 20px;
`
