import React from 'react'
import {useTranslation} from 'react-i18next'

import {NotesTableItem} from 'src/newcore/features/Recovery/fetch'

import {EmptyRow, StyledExpandedRow} from 'src/newcore/features/Recovery/atoms'
import {StyledSingleField} from './StyledExpandedNoteRow'

type ExpandedNoteRowProps = {
    record: NotesTableItem
}

export const ExpandedNoteRow = (props: ExpandedNoteRowProps): JSX.Element => {
    const {
        record: {body}
    } = props
    const {t} = useTranslation()

    if (!body) {
        return <EmptyRow />
    }

    return (
        <StyledExpandedRow>
            <StyledSingleField title={t('table:rows:fields:content')} value={body} isShortValue />
        </StyledExpandedRow>
    )
}
