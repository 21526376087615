import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Trans, withTranslation} from 'react-i18next'

import {eatModal} from 'ca-common/redux/modules/modal'
import {checkExportActivityTask, getExportActivityLink} from 'src/newcore/redux/modules/accountActivity'
import {openErrorModal} from 'ca-common/utils/modals'

@withTranslation()
class ActivityDownload extends Component {
    state = {
        link: null
    }
    componentDidMount() {
        const {match, actions} = this.props

        actions
            .checkExportActivityTask({
                date: match.params.id,
                type: match.params.activityType
            })
            .unwrap()
            .then(() => {
                return actions
                    .getExportActivityLink({
                        date: match.params.id,
                        type: match.params.activityType
                    })
                    .unwrap()
            })
            .then(response => {
                this.setState({
                    link: response
                })

                setTimeout(() => {
                    window.open(response)
                }, 100)
            })
            .catch(error => openErrorModal(`${error.status}`))
    }

    render = () => {
        const {link} = this.state

        return (
            link && (
                <Trans i18nKey="notifications:downloadFallback">
                    <a data-auto-download href={link}>
                        here
                    </a>
                </Trans>
            )
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                getExportActivityLink,
                checkExportActivityTask,
                eatModal
            },
            dispatch
        )
    }
})

export const ActivityDownloadPage = connect(null, mapDispatchToProps)(ActivityDownload)
