import React from 'react'
import {useTranslation} from 'react-i18next'

import {InfoWrapper, Left, Right} from './StyledInformation'

type InformationProps = {
    square: React.ReactNode
    category: string
    count: string
}

export const Information = (props: InformationProps): JSX.Element => {
    const {square, category, count} = props
    const {t} = useTranslation()

    return (
        <InfoWrapper>
            <Left>
                {square}
                <span>{t(`backupChart:${category}`)}</span>
            </Left>
            <Right>{count}</Right>
        </InfoWrapper>
    )
}
