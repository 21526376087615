import {useEffect} from 'react'

import variables from 'ca-common/variables'
import {PayloadAction} from '@reduxjs/toolkit'

const SUBMIT_CARD_ERROR = -1

export const CARD_SECURE_FIELDS_ID = 'card-secure-fields'

const style = {
    base: {
        fontWeight: 400,
        fontSize: variables.fontSizeBase,
        height: '100%',
        color: variables.dark,
        secureFields: {
            overflowX: 'hidden',
            position: 'static',
            height: '100%',
            color: variables.dark,
            fontSize: variables.fontSizeBase,
            display: 'flex',
            left: '40px',
            width: '100%',
            padding: '7px 0 0px 50px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        pan: {
            width: 'auto',
            maxWidth: '160px'
        },
        expirationDate: {
            width: 'auto',
            maxWidth: '55px',
            textAlign: 'center'
        },
        cvv: {
            width: 'auto',
            maxWidth: '40px',
            padding: '0 10px 0 0',
            textAlign: 'right'
        },
        cardImage: {
            position: 'absolute',
            height: '30px',
            top: 0,
            padding: '2px',
            width: 'auto'
        }
    }
}

export const usePaymentOS = (dispatch: any, getPosPublicKey: any) => {
    const initPaymentOS = () => {
        dispatch(getPosPublicKey()).then(
            (
                response: PayloadAction<{
                    paymentOSPublicToken: string
                    paymentOSEnv: string
                }>
            ) => {
                const {paymentOSPublicToken, paymentOSEnv} = response.payload

                window.POS.setPublicKey(paymentOSPublicToken)
                window.POS.setEnvironment(paymentOSEnv)
                window.POS.setStyle(style)
                window.POS.initSecureFields(CARD_SECURE_FIELDS_ID)
            }
        )
    }

    useEffect(() => {
        initPaymentOS()
    }, [])

    const createToken = (holder_name: string) => {
        return new Promise((resolve, reject) => {
            window.POS.createToken({holder_name}, result => {
                const response = JSON.parse(result)
                const {description, token} = response

                if (description) {
                    reject({statusCode: SUBMIT_CARD_ERROR, description})
                }

                if (token) {
                    resolve(response)
                }
            })
        }) as Promise<{vendor: string; token: string}>
    }

    return createToken
}
