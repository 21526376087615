import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {StyledIcon} from './StyledJobsBreadcrumb'
import {useRouteMatch} from 'react-router'

export const JobsBreadcrumb = (): JSX.Element => {
    const {t} = useTranslation()
    const match = useRouteMatch()

    const hasDownloadReport = match.url.split('/').includes('downloadReport')

    return (
        <>
            {t('breadcrumb:jobs:mainPage')}
            {!hasDownloadReport && (
                <Tooltip
                    title={t('breadcrumb:jobs:tooltip')}
                    placement="bottomLeft"
                    overlayInnerStyle={{width: '546px'}}
                    arrowPointAtCenter
                >
                    <StyledIcon component={Info} />
                </Tooltip>
            )}
        </>
    )
}
