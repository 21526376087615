import React, {useEffect, useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Select, Form} from 'antd'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'
import {APP_DEFAULT_PAGE, PORTAL_BASENAME} from 'ca-common/constants'
import {getUrlParams} from 'ca-common/utils/navigation'
import {utf8ToB64} from 'ca-common/utils/base64'

import {endpoint, OKTADomains, OKTALandingFormFields} from 'src/newcore/features/OKTALanding/lib/constants'
import {RoundedInput, RoundedSelect, StyledForm, StyledInputWrapper} from './StyledOKTALandingForm'

const {Option} = Select

export const OKTALandingForm = () => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const parsedURL = new URL(window.location.href)
    const {redirect, status, domain, oktaDomain} = getUrlParams()
    const redirectUrl =
        (redirect && encodeURIComponent(redirect)) ||
        utf8ToB64(`${parsedURL.origin}/${PORTAL_BASENAME}/${APP_DEFAULT_PAGE}`)
    const [domainValue, setDomainValue] = useState(domain || '')
    const [oktaDomainValue, setOktaDomainValue] = useState(oktaDomain || OKTADomains.com)
    const [error, setError] = useState('')
    const href = useMemo(
        () =>
            `${endpoint}?${OKTALandingFormFields.domain}=${domainValue}&${OKTALandingFormFields.oktaDomain}=${oktaDomainValue}&redirect=${redirectUrl}`,
        [domainValue, oktaDomainValue]
    )

    const clearError = () => setError('')

    const onInput = (e: any) => {
        setDomainValue(e.target.value)
        clearError()
    }

    const onSelect = (val: any) => {
        setOktaDomainValue(val)
        clearError()
    }

    useEffect(() => {
        setError(status)
    }, [status])

    return (
        <StyledForm form={form}>
            <StyledInputWrapper>
                <Form.Item
                    validateStatus={error ? 'error' : ''}
                    help={error}
                    name={OKTALandingFormFields.domain}
                    initialValue={domainValue}
                    rules={[{required: true, message: t('forms:common:validation:shortRequired')}]}
                >
                    <RoundedInput
                        id={OKTALandingFormFields.domain}
                        size="large"
                        placeholder={t('OKTALandingPage:domainPlaceholder')}
                        onChange={onInput}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={error ? 'error' : ''}
                    name={OKTALandingFormFields.oktaDomain}
                    initialValue={oktaDomain || OKTADomains.com}
                >
                    <RoundedSelect size="large" id={OKTALandingFormFields.oktaDomain} onChange={onSelect}>
                        {Object.values(OKTADomains).map(value => (
                            <Option key={value} value={value}>
                                {value}
                            </Option>
                        ))}
                    </RoundedSelect>
                </Form.Item>
            </StyledInputWrapper>

            <Button
                block
                size="large"
                color={variables.iceBlue}
                type="primary"
                htmlType="button"
                href={href}
                disabled={!domainValue || !!error}
            >
                {t('OKTALandingPage:signIn')}
            </Button>
        </StyledForm>
    )
}
