import React from 'react'
import {useTranslation} from 'react-i18next'

import {ClearTag as StyledClearTag, CloseIcon, FilterName, StyledFilterTag} from './StyledFilterTag'

import Close from 'ca-common/icons/closeIcon.svg'

export type FilterTagProps = {
    label: string
    value: string
    icon?: JSX.Element
    onClick: () => void
    isSubSource?: boolean
}

type ClearTagProps = {
    onClick: () => void
}

export const FilterTag = ({label, icon, onClick, isSubSource}: FilterTagProps): JSX.Element => (
    <StyledFilterTag isSubSource={isSubSource}>
        {icon}
        <FilterName>{label}</FilterName>
        <CloseIcon onClick={onClick} component={Close} />
    </StyledFilterTag>
)

export const ClearTag = ({onClick}: ClearTagProps) => {
    const {t} = useTranslation()
    return (
        <StyledClearTag data-action="clear" onClick={onClick}>
            {t('table:dashboard:filterBar:clearTag')}
        </StyledClearTag>
    )
}
