import React from 'react'
import Icon from '@ant-design/icons'
import {ButtonProps} from 'antd'

import AddNew from 'ca-common/icons/backupAction/AddNewAccount.svg'

import {StyledAddNewButton} from './StyledAddNewButton'

type AddNewButtonProps = {
    text: string
} & ButtonProps

export const AddNewButton = (props: AddNewButtonProps): JSX.Element => {
    const {text} = props
    return (
        <StyledAddNewButton {...props} type="link">
            <Icon component={AddNew} />
            {text}
        </StyledAddNewButton>
    )
}
