import {createSlice} from '@reduxjs/toolkit'

import {get} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_PARENT_FOLDERS_INFO = 'GET_PARENT_FOLDERS_INFO'

const getParentFoldersInfoWrapper = fetchWrapperRT(
    GET_PARENT_FOLDERS_INFO,
    async data => await get('getParentFoldersInfoAsync', data)
)
export const getParentFoldersInfo = getParentFoldersInfoWrapper.fetcher

const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getParentFoldersInfoWrapper.makeReducers(builder)
})
export const breadcrumbsReducer = breadcrumbsSlice.reducer
