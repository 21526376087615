import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledContainer = styled.ul`
    display: flex;
    width: 100%;
    margin: 20px 0 0;
    padding-bottom: 20px;
    list-style: none;
    overflow-x: auto;
    gap: ${variables.gutterXs};

    @media screen and (min-width: ${variables.screenDesktop}) {
        overflow: visible;
        gap: ${variables.gutterLg};
    }
`
