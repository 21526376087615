import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'

export const FieldTitle = styled.div`
    color: ${variables.navyBlue};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    margin-bottom: 4px;
`

export const FieldValue = styled.div<{isShort?: boolean}>`
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.text};
    ${({isShort}) =>
        isShort &&
        css`
            max-height: 56px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        `}
`
