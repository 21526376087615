import {FORM_FIELDS, RESTRICTION_RULES, parseAddressesToFE} from 'ca-common/features/IpRestriction/lib'
import {RestrictionEntry} from 'ca-common/features/IpRestriction/types'

export const getTableAddress = (record: RestrictionEntry): string => {
    switch (record.type) {
        case RESTRICTION_RULES.LIST:
            return parseAddressesToFE(record[FORM_FIELDS.ADDRESSES])

        case RESTRICTION_RULES.RANGE:
            return `${record[FORM_FIELDS.FROM]} - ${record[FORM_FIELDS.TO]}`

        case RESTRICTION_RULES.CIDR:
            return `${record[FORM_FIELDS.ADDRESS]} / ${record[FORM_FIELDS.CIDR]}`

        default:
            return ''
    }
}
