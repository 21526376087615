import React, {useMemo} from 'react'
import {Form} from 'antd'
import _ from 'lodash'
import {useTranslation} from 'react-i18next'

import {SearchField} from 'ca-common/ui-lib/atoms/SearchField'
import {MultiSelect} from 'ca-common/ui-lib/atoms/MultiSelect'
import {DEBOUNCE_TIMEOUT} from 'ca-common/constants'
import {getActivityStatusIcon, getActivityStatusFriendly} from 'ca-common/common/enum/Statuses'

import {
    FIELD_SEARCH_QUERY,
    FIELD_OBJECT_STATUS,
    FIELD_OBJECT_TYPE,
    TYPES_OPTIONS,
    STATUS_OPTIONS,
    getTypeFrendly
} from 'src/newcore/features/Objects/libs'

import {SelectedFilters} from 'src/newcore/features/Objects/molecules'

import {StyledForm, StyledField, StyledIcon} from './StyledFilterForm'

const {Item: FormItem} = Form

export const FilterForm = props => {
    const {loading, values} = props
    const [form] = Form.useForm()
    const {setFieldsValue} = form
    const {t} = useTranslation()

    const selectedFilters = useMemo(() => _.pick(values, [FIELD_OBJECT_STATUS, FIELD_OBJECT_TYPE]), [values])

    const onChangeFilters = newValues => {
        setFieldsValue(newValues)
        props.changeURLSearchParams(newValues)
    }

    const statusOptions = useMemo(
        () =>
            _.map(STATUS_OPTIONS, status => ({
                label: (
                    <>
                        <StyledIcon component={getActivityStatusIcon(status)} />
                        {getActivityStatusFriendly(status)}
                    </>
                ),
                value: status
            })),
        []
    )

    const objectTypeOptions = useMemo(
        () =>
            _.map(TYPES_OPTIONS, type => ({
                label: getTypeFrendly(type),
                value: type
            })),
        []
    )

    return (
        <>
            <StyledForm
                layout="inline"
                form={form}
                onValuesChange={_.debounce((changedValues, allValues) => {
                    props.changeURLSearchParams(allValues)
                }, DEBOUNCE_TIMEOUT)}
            >
                <StyledField name={[FIELD_SEARCH_QUERY]}>
                    <SearchField disabled={loading} placeholder={t('objectsList:searchPlaceholder')} />
                </StyledField>
                <FormItem name={[FIELD_OBJECT_TYPE]}>
                    <MultiSelect
                        width={148}
                        disabled={loading}
                        buttonText={t('objectsList:typeButtonText')}
                        options={objectTypeOptions}
                    />
                </FormItem>
                <FormItem name={[FIELD_OBJECT_STATUS]}>
                    <MultiSelect
                        width={116}
                        disabled={loading}
                        buttonText={t('objectsList:statusButtonText')}
                        options={statusOptions}
                        placement={'bottomRight'}
                        popoverWidth={232}
                    />
                </FormItem>
            </StyledForm>
            <SelectedFilters loading={loading} values={selectedFilters} setValues={onChangeFilters} />
        </>
    )
}
