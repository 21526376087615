import React, {Fragment, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import _ from 'lodash'

import variables from 'ca-common/variables'
import {DropDownButton} from 'ca-common/ui-lib/atoms/DropDownButton'

import {
    ACTION_OPTIONS,
    getSmartAlertActionIcon,
    getSmartAlertActionFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import {StyledIcon} from './StyledMultiAction'

export const MultiActions = props => {
    const {onMultiActionHandler, disabled} = props
    const {t} = useTranslation()

    const actionOptions = useMemo(
        () =>
            _.map(ACTION_OPTIONS, action => ({
                value: action,
                label: (
                    <Fragment>
                        <StyledIcon component={getSmartAlertActionIcon(action)} />
                        {getSmartAlertActionFriendly(action)}
                    </Fragment>
                )
            })),
        []
    )

    return (
        <DropDownButton
            width={120}
            color={variables.iceBlue}
            buttonText={t('smartAlerts:actionButtonText')}
            action={onMultiActionHandler}
            options={actionOptions}
            disabled={disabled}
        />
    )
}
