export const TABLE_COLUMN_TYPE = {
    name: 'USERNAME',
    entity: 'EMAIL',
    status: 'STATUS',
    size: 'SIZE',
    lastBackup: 'LAST_BACKUP',
    type: 'TYPE',
    email: 'EMAIL',
    licenseStatus: 'LICENSE'
}

export const DASHBOARD_COLUMNS = {
    name: 'NAME',
    email: 'EMAIL',
    size: 'SIZE',
    accounts: 'ACCOUNTS',
    entities: 'ACCOUNTS',
    billingAmount: 'AMOUNT',
    userStatus: 'ACCOUNT_STATUS',
    billingStatus: 'BILLING_STATUS',
    taskStatus: 'TASK_STATUS'
}
