export enum PaymentStatus {
    PAID = 'PAID',
    NO_BILLS = 'NO_BILLS',
    OVERDUE = 'OVERDUE',
    DUE = 'DUE',
    UNPAID = 'UNPAID'
}

export const SCROLL_AFTER_X_MIN = 500
export const SCROLL_AFTER_X_MAX = 1080
