import _ from 'lodash'

export const getEntitiesKeys = (entities: _.Dictionary<unknown> | _.NumericDictionary<unknown> | null | undefined) =>
    _.map(entities, 'id')
export const isFilterValuesExists = (filterParams: any, queryParams: any) =>
    _.some(filterParams, _.partial(_.has, queryParams))

export const getRequestParamsFromUrl = (data: any) => {
    const {statusFilter, ...params} = _.omit(data, ['status', 'msgType', 'useBasicAuth', 'useRest', 'id'])

    return statusFilter ? {...params, statusList: _.isArray(statusFilter) ? [...statusFilter] : [statusFilter]} : params
}
