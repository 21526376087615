import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const RecoveryButton = styled(Icon)<{disabled: boolean}>`
    margin-right: 8px;
    border-radius: 50%;
    font-size: 24px;
    color: ${props => (props.disabled ? variables.asphaltLight : variables.navyBlue)};
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out;

    ${props =>
        !props.disabled &&
        css`
            &:hover {
                background-color: ${variables.navyBlue};
                color: ${variables.white};
            }
        `}
`
