import {createSlice} from '@reduxjs/toolkit'
import {postLessData} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {NewTaskSettings} from 'ca-common/types'

const NEW_BACKUP_TASK_SETTINGS = 'NEW_BACKUP_TASK_SETTINGS'
const NEW_BACKUP_TASK_SETTINGS_API = 'getNewBackupTaskSettings'
const newBackupTaskSettingsWrapper = fetchWrapperRT(
    NEW_BACKUP_TASK_SETTINGS,
    async data => await postLessData(NEW_BACKUP_TASK_SETTINGS_API, data)
)

export const getNewBackupTaskSettings = newBackupTaskSettingsWrapper.fetcher

const settingsSlice = createSlice({
    name: 'settings',
    initialState: InitFetchedState as FetchedState<NewTaskSettings>,
    reducers: {
        clearBackupTaskSettings: () => InitFetchedState as FetchedState<NewTaskSettings>,
        appendLocalBackupTaskSettings: (state, action) => {
            state.response = {
                ...state.response,
                ...action.payload
            }
        },
        overwriteLocalBackupTaskSettings: (state, action) => {
            state.response = action.payload
        }
    },
    extraReducers: builder => newBackupTaskSettingsWrapper.makeReducers(builder)
})

export const {
    clearBackupTaskSettings,
    overwriteLocalBackupTaskSettings,
    appendLocalBackupTaskSettings
} = settingsSlice.actions

export const settings = settingsSlice.reducer

const BACKUP_TASK_SETTINGS_SET = 'BACKUP_TASK_SETTINGS_SET'
const BACKUP_TASK_SETTINGS_SET_API = 'setBackupTaskSettings'
const setBackupTaskSettingsWrapper = fetchWrapperRT(BACKUP_TASK_SETTINGS_SET, async (data, {rejectWithValue}) => {
    try {
        return await postLessData(BACKUP_TASK_SETTINGS_SET_API, data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})

export const setBackupTaskSettings = setBackupTaskSettingsWrapper.fetcher
