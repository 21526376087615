import _ from 'lodash'
import React, {Fragment} from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'

import {Button} from 'ca-common/ui-lib/atoms/Button'

import {getCountedEntityName, getSvcAccountsDescription} from 'ca-common/utils/text'
import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'

import {
    getAvailableStatusesByTaskAction,
    getEntitiesStatusRequest,
    getEntitiesSum,
    getPerformAvailableEntities,
    limitExceeded
} from 'ca-common/utils/backup/backupActions'

import {clearEntitiesStatus, getEntitiesStatus} from 'src/newcore/redux/modules/backups/backupEntities'
import {Header, Footer, StatusResult} from '../StyledConfirmAction'

export const MAXIMUM_ALLOWED_ITEMS = 200

@withTranslation()
class RunRaw extends React.Component {
    componentDidMount() {
        const {
            action,
            actions,
            location,
            selectedAll,
            selectedRowKeys,
            taskSettings: {taskId}
        } = this.props

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        actions.getEntitiesStatus(
            getEntitiesStatusRequest(action, taskId, selectedAll, selectedRowKeys, getRequestParamsFromUrl(queryParams))
        )
    }

    componentWillUnmount() {
        this.props.actions.clearEntitiesStatus()
    }

    render() {
        const {t, action, taskSettings, entitiesStatus, onSubmit, onCancel, loading} = this.props

        const availableEntities = getPerformAvailableEntities(
            getAvailableStatusesByTaskAction(action),
            _.get(entitiesStatus, 'response.data')
        )
        const sum = getEntitiesSum(_.get(entitiesStatus, 'response.data'))
        const entityName = _.toLower(getSvcAccountsDescription(taskSettings.source, true))

        const maxEntities = getCountedEntityName(taskSettings.source, MAXIMUM_ALLOWED_ITEMS)
        const inProcessEntitiesCount = _.get(entitiesStatus, 'response.data.IN_PROCESS', 0)
        const inProcessEntities = getCountedEntityName(taskSettings.source, inProcessEntitiesCount)
        const isLimitExceeded = limitExceeded(availableEntities, inProcessEntitiesCount, MAXIMUM_ALLOWED_ITEMS)

        return (
            <Spin spinning={'pending' === entitiesStatus.status}>
                <Header>
                    <StatusResult>
                        {isLimitExceeded
                            ? t(`modals:performAction:limitExceeded`, {
                                  availableEntities,
                                  maxEntities,
                                  inProcessEntities
                              })
                            : t(`modals:performAction:statusResult:${action}`, {
                                  availableEntities,
                                  sum,
                                  entityName
                              })}
                    </StatusResult>
                    <BackupTaskAction action={action} withTitle={false} />
                </Header>

                <Footer>
                    {isLimitExceeded ? (
                        <Button onClick={onCancel} type="primary">
                            {t('modals:buttons:ok')}
                        </Button>
                    ) : (
                        <Fragment>
                            <Button onClick={onCancel} type="link">
                                {t('modals:buttons:cancel')}
                            </Button>
                            <Button
                                onClick={() => onSubmit(action)}
                                disabled={!availableEntities}
                                loading={loading}
                                type="primary"
                            >
                                {t('modals:buttons:confirm')}
                            </Button>
                        </Fragment>
                    )}
                </Footer>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    entitiesStatus: state.backups.backupEntities.entitiesStatus,
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getEntitiesStatus,
            clearEntitiesStatus
        },
        dispatch
    )
})

export const Run = withRouter(connect(mapStateToProps, mapDispatchToProps)(RunRaw))
