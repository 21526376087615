import React from 'react'

import {SOURCE} from 'ca-common/common/enum/Source'

import App from 'ca-common/icons/subsource/app.svg'
import Calendar from 'ca-common/icons/subsource/calendar.svg'
import Channel from 'ca-common/icons/subsource/channel.svg'
import Contact from 'ca-common/icons/subsource/contacts.svg'
import Conversation from 'ca-common/icons/subsource/conversation.svg'
import Doc from 'ca-common/icons/subsource/doc.svg'
import Mail from 'ca-common/icons/subsource/mails.svg'
import Meta from 'ca-common/icons/subsource/meta.svg'
import Note from 'ca-common/icons/subsource/notes.svg'
import Site from 'ca-common/icons/subsource/site.svg'
import Task from 'ca-common/icons/subsource/tasks.svg'

import {StyledIcon} from './StyledSourceIcon'

export const SourceIcon = ({source, ...props}) => {
    return (
        <StyledIcon
            component={(() => {
                switch (source) {
                    case SOURCE.O365MAIL:
                    case SOURCE.GMAIL:
                        return Mail

                    case SOURCE.MS365GROUP_CONVERSATIONS:
                        return Conversation

                    case SOURCE.O365CALENDAR:
                    case SOURCE.GCALENDAR:
                    case SOURCE.MS365GROUP_CALENDAR:
                        return Calendar

                    case SOURCE.O365NOTES:
                        return Note

                    case SOURCE.O365CONTACTS:
                    case SOURCE.GCONTACTS:
                        return Contact

                    case SOURCE.O365TASKS:
                    case SOURCE.GTASK:
                        return Task

                    case SOURCE.GDOC:
                    case SOURCE.GDRIVETEAM:
                    case SOURCE.BOX:
                    case SOURCE.DROPBOX:
                        return Doc

                    case SOURCE.GSITES:
                    case SOURCE.MS365GROUP_SITE:
                    case SOURCE.SHAREPOINT_MULTI:
                    case SOURCE.ONEDRIVE:
                        return Site

                    case SOURCE.MS365TEAM_CHANNELS:
                        return Channel

                    case SOURCE.MS365TEAM_APPS:
                        return App

                    case SOURCE.MS365GROUP_META:
                        return Meta

                    default:
                        return null
                }
            })()}
            {...props}
        />
    )
}
