import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_ENTITIES_STATUS = 'GET_ENTITIES_STATUS'
const GET_ENTITIES_STATUS_API = 'entitiesStatus'

const getEntitiesStatusWrapper = fetchWrapperRT(
    GET_ENTITIES_STATUS,
    async data => await postLessData(GET_ENTITIES_STATUS_API, data)
)
export const getEntitiesStatus = getEntitiesStatusWrapper.fetcher

const entitiesStatusSlice = createSlice({
    name: 'entitiesStatus',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearEntitiesStatus: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getEntitiesStatusWrapper.makeReducers(builder)
})

export const {clearEntitiesStatus} = entitiesStatusSlice.actions
export const entitiesStatus = entitiesStatusSlice.reducer
