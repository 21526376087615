import styled from 'styled-components'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    background-color: ${variables.blueLight};

    & > div {
        flex: 1;
    }
`
export const Text = styled.div`
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.text};
`

export const SwitcherWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${variables.gutterXs};
`

export const DownloadAnonymizationTemplate = styled(CALink)`
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.text};
    text-decoration: underline !important;
    cursor: pointer;
    text-align: end;
    position: absolute;
    right: ${variables.gutterSm};
`
