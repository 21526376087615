import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'

import {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {OCA_SERVICES} from 'src/newcore/features/OneClickActivation/lib/constants'
import {getBackupConfigDefaultValue} from 'src/newcore/features/OneClickActivation/lib/getBackupConfigDefaultValue'

export const checkIsDefaultTaskSettings = (wizardData: OCAWizardData, services?: SOURCE[]) => {
    return (services || OCA_SERVICES).every(service => {
        const settings = wizardData[service]?.settings
        if (settings) {
            return _.isEqual(settings, getBackupConfigDefaultValue(service).settings)
        }

        return true
    })
}
