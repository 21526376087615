import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'

import {CABody, CAScrollableWrapper} from 'ca-common/ui-lib/components/TableElements'

import {config as configShape, items as itemsShape, rowSelection as rowSelectionShape} from 'ca-common/shapes/table'

import {CARow, CARowEmpty, CARowLoader} from './Row'

@withTranslation('table', {withRef: true})
export class CAContent extends React.Component {
    static propTypes = {
        config: PropTypes.shape(configShape).isRequired,
        items: PropTypes.shape(itemsShape).isRequired,
        rowSelection: PropTypes.shape(rowSelectionShape)
    }

    static defaultProps = {
        rowSelection: null
    }

    render() {
        const {items, disabled} = this.props

        switch (items.status) {
            case 'init':
            case 'pending':
                return <CARowLoader />
            case 'fulfilled':
                return this.renderContent(items, disabled)
            default:
                return null
        }
    }

    renderContent = (items, disabled) => {
        return this.props.scrollable ? (
            <CAScrollableWrapper>{this.renderBody(items, disabled)}</CAScrollableWrapper>
        ) : (
            this.renderBody(items, disabled)
        )
    }

    renderBody = (items, disabled) => {
        const {scrollable, minWidth, config, rowSelection} = this.props

        if (items.data.length > 0) {
            return (
                <CABody modifier="blue-stage" style={scrollable ? minWidth : null}>
                    {items.data.map((item, i) => (
                        <CARow
                            key={item.id || i}
                            item={item}
                            config={config}
                            i={i}
                            rowSelection={rowSelection}
                            disabled={disabled}
                        />
                    ))}
                </CABody>
            )
        }

        return (
            <CABody>
                <CARowEmpty />
            </CABody>
        )
    }
}
