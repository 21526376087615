import _ from 'lodash'
import React from 'react'

import {CARadio} from 'ca-common/ui-lib/components/Radio'
import {CACellModifier} from 'ca-common/ui-lib/components/TableElements/index'

export class CASelectItemRadio extends React.Component {
    render() {
        const {
            item,
            rowSelection: {onItemClick, selectedItems}
        } = this.props

        return (
            <CACellModifier onClick={() => onItemClick(item)}>
                <CARadio checked={this.isChecked(selectedItems, item.id)} />
            </CACellModifier>
        )
    }

    isChecked = (selectedItems, id) => _.some(selectedItems, {id})
}
