import React, {Component} from 'react'
import {ResponsiveContainer, BarChart, Bar, Brush, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts'
import {isEmpty} from 'lodash'

import variables from 'ca-common/variables'

import {CustomTooltip} from './CustomTooltip'

const MAIN_COLOR = variables.asphaltLight
const GRID_COLOR = `${MAIN_COLOR}4D`
const X_AXIS_DATA_KEY = 'date'
const BAR_DATA_KEY = 'count'
const START_INDEX = 15
const BRUSH_HEIGHT = 40
const TOOLTIP_CURSOR = {stroke: GRID_COLOR}
const BAR_CHART_MARGIN = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
}
const MAIN_HEIGHT = 160
const HORIZONTAL_LINES = [0, 22, 45, 67]

export class Chart extends Component {
    render() {
        const {data} = this.props

        return (
            <ResponsiveContainer height={MAIN_HEIGHT}>
                <BarChart data={data} margin={BAR_CHART_MARGIN}>
                    <CartesianGrid vertical={false} stroke={GRID_COLOR} horizontalPoints={HORIZONTAL_LINES} />
                    <XAxis dataKey={X_AXIS_DATA_KEY} />
                    <YAxis hide />
                    <Tooltip content={<CustomTooltip />} cursor={TOOLTIP_CURSOR} wrapperStyle={{zIndex: 10}} />
                    <Bar dataKey={BAR_DATA_KEY} fill={MAIN_COLOR} />
                    {!isEmpty(data) && (
                        <Brush
                            dataKey={X_AXIS_DATA_KEY}
                            height={BRUSH_HEIGHT}
                            stroke={MAIN_COLOR}
                            startIndex={START_INDEX}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        )
    }
}
