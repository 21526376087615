import React from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Form} from 'antd'

import {changeData} from 'src/newcore/redux/modules/wizard'
import {SnapshotCalendar} from 'src/newcore/organisms/SnapshotCalendar'

const SnapshotSelectionRaw = ({actions, taskSettings, name, disabled, innerRef}) => {
    const {taskId} = useParams()

    const onValuesChange = values => {
        actions.changeData(values)
    }

    return (
        <Form onValuesChange={onValuesChange}>
            <Form.Item name={name}>
                <SnapshotCalendar
                    payload={{taskId}}
                    retentionDate={taskSettings.retentionDate}
                    firstBackupDate={taskSettings.firstBackupDate}
                    disabled={disabled}
                    innerRef={innerRef}
                />
            </Form.Item>
        </Form>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeData
        },
        dispatch
    )
})

export const SnapshotSelection = connect(null, mapDispatchToProps)(SnapshotSelectionRaw)
