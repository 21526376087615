import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledActionButton = styled(ButtonHoverReverse)`
    display: flex;
    align-items: center;

    width: 100px;
    padding: 0 ${variables.gutterXs};
    background-color: ${variables.white};
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);

    color: ${variables.navyBlue};
    line-height: 30px;
    font-weight: 400;

    &:disabled {
        background-color: ${variables.navyBorder};
        color: ${variables.navyAsphalt};
    }
`

export const StyledActionButtonIcon = styled(Icon)`
    width: 20px;
    height: 20px;
    font-size: 20px;
`
