import React from 'react'
import {MS365SubSource} from 'ca-common/types'
import {getSourceName} from 'ca-common/utils/sources'
import {StyledNameContainer, StyledSubSourceIcon} from './StyledSubSourceNameWithIcon'
import {getSubsourceIcon} from 'src/newcore/features/Recovery/lib/getSubsourceIcon'

export const SubSourceNameWithIcon = ({source}: {source: MS365SubSource}) => {
    const name = getSourceName(source)
    return (
        <StyledNameContainer>
            <StyledSubSourceIcon component={getSubsourceIcon(source)} />
            {name}
        </StyledNameContainer>
    )
}
