import _ from 'lodash'
import React from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import {DEFAULT_PAGE} from '../ConfirmationList'

import {Entity} from '../Entity'
import {StyledEntities} from './StyledEntities'

class EntitiesRaw extends React.Component {
    render() {
        const {selectedEntities, entitiesStatus, excludeList, handleCheckboxChange, loadMore, taskSettings} = this.props

        const {entities} = _.get(selectedEntities, 'response.data', [])
        const {page, totalPages} = _.get(selectedEntities, 'response', 0)

        const isEntitiesNotVisible =
            'fulfilled' === entitiesStatus.status && _.isArray(entities) && entities.length === 0

        return (
            <Spin spinning={'pending' === selectedEntities.status && 'pending' !== entitiesStatus.status}>
                <StyledEntities isEntitiesNotVisible={isEntitiesNotVisible}>
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={DEFAULT_PAGE}
                        useWindow={false}
                        hasMore={page < totalPages}
                        loadMore={() => loadMore(page + 1)}
                    >
                        {_.map(entities, entity => (
                            <Entity
                                taskSettings={taskSettings}
                                key={entity.id}
                                checked={!_.includes(excludeList, entity.id)} // this is bad
                                entity={entity}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </InfiniteScroll>
                </StyledEntities>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    selectedEntities: state.backups.backupEntities.selectedEntities,
    entitiesStatus: state.backups.backupEntities.entitiesStatus
})

export const Entities = connect(mapStateToProps)(EntitiesRaw)
