import React from 'react'
import {useTranslation} from 'react-i18next'
import {TableProps, ColumnsType} from 'antd/lib/table/Table'

import {SOURCE} from 'ca-common/common/enum'
import {SearchField} from 'ca-common/ui-lib/atoms/SearchField'
import {useInfinityScrollForTable} from 'ca-common/utils/useInfinityScrollForTable'

import type {Entity} from 'src/newcore/features/OneClickActivation/types'
import {TABLE_HEIGHT} from 'src/newcore/features/OneClickActivation/lib'

import {EntitiesCounters, EntitiesCounter, StyledTable, SearchFieldWrapper} from './StyledEntitiesSelectionTabContent'

type EntitiesSelectionTabContentProps = {
    totalEntities: number
    service: SOURCE
    tableData: Entity[]
    columns: ColumnsType<Entity>
    search: string
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    totalSelectedEntities: number
    rowSelection: {
        onSelectAll: (value: boolean) => void
        selectedRowKeys: string[]
        onSelect: ({id, ordinal}: {id: string; ordinal: number}, checked: boolean) => void
    }
    fetchMore: () => void
    isLoading: boolean
}

export const EntitiesSelectionTabContent = (props: EntitiesSelectionTabContentProps) => {
    const {
        totalEntities,
        service,
        tableData,
        columns,
        isLoading,
        totalSelectedEntities,
        search,
        onSearchChange,
        rowSelection,
        fetchMore
    } = props
    const {t} = useTranslation()

    const tableRef = useInfinityScrollForTable(fetchMore)

    return (
        <div>
            <EntitiesCounters>
                <EntitiesCounter>
                    <span>{totalEntities}</span>
                    <span>{t('oneClickActivation:wizard:confirmationStep:totalEntities')}</span>
                </EntitiesCounter>
                <EntitiesCounter>
                    <span>{totalSelectedEntities}</span>
                    <span>{t('oneClickActivation:wizard:confirmationStep:selectedEntities')}</span>
                </EntitiesCounter>
            </EntitiesCounters>
            <SearchFieldWrapper>
                <SearchField
                    value={search}
                    onChange={onSearchChange}
                    placeholder={t(`oneClickActivation:wizard:placeholders:searchField:${service}`)}
                />
            </SearchFieldWrapper>
            <StyledTable<
                (
                    props: TableProps<Entity> & {
                        ref?: React.Ref<HTMLDivElement> | undefined
                    }
                ) => JSX.Element
            >
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                ref={tableRef}
                scroll={{x: '100%', y: TABLE_HEIGHT}}
                loading={isLoading}
                pagination={false}
                rowKey="id"
            />
        </div>
    )
}
