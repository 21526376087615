import React from 'react'
import {useTranslation} from 'react-i18next'

import {getStatusIcon} from 'ca-common/utils/taskStatus'

import {TASK_STATUS as DEEP_TASK_STATUS} from 'ca-common/common/enum'

import {StyledTaskStatus, TaskStatusIcon} from './StyledTaskStatus'
type TaskStatusProps = {
    status: DEEP_TASK_STATUS
    reverse?: boolean
}

export const TaskStatus = ({status, reverse, ...props}: TaskStatusProps): JSX.Element => {
    const {t} = useTranslation()
    return (
        <StyledTaskStatus {...props} data-task-status="taskStatus">
            <TaskStatusIcon component={getStatusIcon(status)} reverse={reverse} />
            {t(`backups:newTaskStatus:${status}`)}
        </StyledTaskStatus>
    )
}
