import styled from 'styled-components'

import variables from 'ca-common/variables'
import {AccountDeactivationInputNumber} from 'src/newcore/features/BackupSettings/molecules/AccountDeactivation'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterSm};
    font-family: 'Lato';

    .ant-form-item {
        margin: 0;
    }

    .ant-form-item-control-input {
        min-height: 20px;
        height: 20px;
    }

    ${AccountDeactivationInputNumber} {
        width: 50px;
        margin-right: 0;
    }
`
