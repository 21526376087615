import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledDescription = styled.div<{width?: number}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${props => props.width || 512}px;
`

export const StyledLeft = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeBase};
`

export const StyledRight = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.navyAsphalt};
`

export const StyledFormItem = styled(Form.Item)`
    margin-top: ${variables.gutterXs};
    margin-bottom: 0;
`
