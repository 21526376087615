export const cancellable = (promise: Promise<any>) => {
    let cancelled = false
    const next: any = promise.then(val => {
        if (cancelled) return Promise.reject(new Error('Cancelled'))
        return val
    })
    next.cancel = () => {
        cancelled = true
    }
    return next
}
