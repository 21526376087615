import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const BackupSettingsForm = styled(Form)`
    /* del this */
    padding: ${variables.gutterMd} ${variables.gutterMd} 40px;
    border-top: 1px solid ${variables.blue};
`

export const SettingsInfo = styled.div`
    margin-bottom: ${variables.gutterSm};
    display: flex;
    flex-flow: column;
`

export const BackupSettingsDivider = styled.div`
    padding: 12px 0;
    display: flex;
    justify-content: center;

    &:after {
        content: '';
        display: block;
        width: 1px;
        background: ${variables.blue};

        @media (min-width: ${variables.screenLg}) {
            height: 370px;
        }

        @media (min-width: ${variables.screenXl}) {
            height: 165px;
        }
    }
`
