import React from 'react'
import {TWOFA} from 'src/ca-common/constants'
import {Trans, useTranslation} from 'react-i18next'
import {disableTwoFa, fetchTwoFa} from 'src/newcore/redux/modules/security'
import {openSuccessNotification} from 'src/ca-common/utils/toasts'
import {openErrorModal} from 'src/ca-common/utils/modals'
import {useAppDispatch} from 'src/newcore/components/Root'

import {StyledButton, StyledTwoFaBlock, StyledTwoFaTitle} from './StyledTwoFA'

type EnabledType = {
    companyName?: string
}
export const Enabled = (props: EnabledType) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {companyName} = props
    const disableTwoFaClicked = () => {
        dispatch(disableTwoFa())
            .then(() => {
                openSuccessNotification(t('twoFaAuthPage:disable2FA'))
                dispatch(fetchTwoFa())
            })
            .catch(() => openErrorModal(t('twoFaAuthPage:disableFailed')))
    }
    return (
        <StyledTwoFaBlock modifier="enabled">
            <StyledTwoFaTitle>{t('twoFaAuthPage:enabledTitle')}</StyledTwoFaTitle>

            <StyledButton type="primary" onClick={disableTwoFaClicked}>
                {t('twoFaAuthPage:actions:disable')}
            </StyledButton>

            <h4>{t('twoFaAuthPage:lockOutRemind', [companyName])}</h4>
            <p>{t('twoFaAuthPage:enabledCodes')}</p>

            <p>
                <Trans i18nKey="twoFaAuthPage:recoveryCodeAdvice">
                    <a href={TWOFA.TwoFa_URL.lastpass} className="ca-link" target="_blank">
                        LastPass
                    </a>
                    <a href={TWOFA.TwoFa_URL.onePassword} className="ca-link" target="_blank">
                        1Password
                    </a>
                    <a href={TWOFA.TwoFa_URL.keeper} className="ca-link" target="_blank">
                        Keeper
                    </a>
                </Trans>
            </p>
            <StyledButton type="primary" href={TWOFA.TwoFa_URL.saveCodes}>
                {t('twoFaAuthPage:actions:save')}
            </StyledButton>
        </StyledTwoFaBlock>
    )
}
