import React from 'react'
import {useTranslation} from 'react-i18next'

import {Status} from './StyledDeepTaskStatus'
import {TASK_STATUS} from 'ca-common/common/enum'

type DeepTaskStatusProps = {
    status?: TASK_STATUS
}

export const DeepTaskStatus = ({status}: DeepTaskStatusProps): JSX.Element => {
    const {t} = useTranslation()

    return status ? <Status status={status}>{t(`homepage:taskStatus:${status}`)}</Status> : <></>
}
