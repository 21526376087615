import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import {StyledHeader, HeaderTitile, BarIcon, ActionButton} from '../StyledFileDiffItemList'

import DownloadAll from 'ca-common/icons/DownloadAll.svg'
import Bar from 'ca-common/icons/Bar.svg'

@withTranslation()
export class Header extends Component {
    render() {
        const {
            t,
            recordCount,
            onClickLineView,
            onClickListView,
            isActiveLineView,
            downloadComparedFiles,
            title
        } = this.props

        return (
            <StyledHeader>
                <HeaderTitile>
                    <p>
                        {title}
                        <span>{t('backups:compare:listHeader:recordCount', {count: recordCount})}</span>
                    </p>
                </HeaderTitile>
                <div>
                    <BarIcon active={!isActiveLineView ? 1 : 0} onClick={onClickListView} component={Bar} />
                    <BarIcon active={isActiveLineView} onClick={onClickLineView} component={Bar} />
                </div>
                <div>
                    <ActionButton type="primary" onClick={downloadComparedFiles}>
                        <Icon component={DownloadAll} />
                        {t('backups:compare:buttons:downloadAll')}
                    </ActionButton>
                </div>
            </StyledHeader>
        )
    }
}
