import React, {memo} from 'react'
import {FillingBar, ProgressContainer, StyledPopover, SubSourceContainer} from './StyledProgressBar'

type ProgressBarProps = {
    progressInfo: React.ReactNode
    children?: React.ReactNode
    progress?: number
    pending?: boolean
}

export const ProgressBar = memo(
    ({progressInfo, children, progress, pending}: ProgressBarProps): JSX.Element => {
        return (
            <StyledPopover content={progressInfo}>
                <ProgressContainer>
                    <FillingBar progress={progress} pending={pending} />
                    <SubSourceContainer>{children}</SubSourceContainer>
                </ProgressContainer>
            </StyledPopover>
        )
    }
)
