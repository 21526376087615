import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledContainer = styled.div`
    width: 416px;
    display: flex;
`

export const StyledHeader = styled.h2`
    margin-bottom: ${variables.gutterMd};

    font-family: ${variables.publicPageFont};
    font-size: ${variables.mainTitleFontSize};
    font-weight: 700;
    text-align: center;

    color: ${variables.text};
`

export const StyledContentContainer = styled(StyledContainer)`
    flex-direction: column;
    margin-top: ${variables.gutterXxl};
    padding: ${variables.gutterXxl};
`

export const StyledText = styled.span`
    text-align: center;
    color: ${variables.navyAsphalt};
    font-size: ${variables.subTitleFontSize};
`
