import type {IconComponent} from 'ca-common/types'

import Failed from 'ca-common/icons/backupStatusNew/Failed.svg'
import Paused from 'ca-common/icons/backupStatusNew/Pause.svg'
import Partial from 'ca-common/icons/backupStatusNew/Partial.svg'
import Success from 'ca-common/icons/backupStatusNew/Success.svg'
import Cancelled from 'ca-common/icons/backupStatusNew/Cancelled.svg'

export const ACTIVITY_STATUS = {
    SUCCESS: 'Completed',
    COMPLETED: 'Succeeded',
    FAILED: 'Failed',
    EXCEPTION: 'Partially Succeeded',
    CANCELLED: 'Cancelled',
    PAUSED: 'Paused'
} as const

export type ActivityStatusKeys = keyof typeof ACTIVITY_STATUS
export type ActivityStatusValues = typeof ACTIVITY_STATUS[ActivityStatusKeys]

export function getStatusIcon(status: ActivityStatusValues): IconComponent | undefined {
    switch (status) {
        case ACTIVITY_STATUS.COMPLETED:
        case ACTIVITY_STATUS.SUCCESS:
            return Success
        case ACTIVITY_STATUS.FAILED:
            return Failed
        case ACTIVITY_STATUS.EXCEPTION:
            return Partial
        case ACTIVITY_STATUS.PAUSED:
            return Paused
        case ACTIVITY_STATUS.CANCELLED:
            return Cancelled
        default:
            return undefined
    }
}
