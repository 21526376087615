import _ from 'lodash'
import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'

const PureUserBreadcrumb = ({name, match}) => <NavLink to={match.url}>{name}</NavLink>

const mapStateToProps = state => {
    const request = state.backups.settings

    return {
        name: _.get(request, 'response.alias')
    }
}

export const BackupBreadcrumb = connect(mapStateToProps)(PureUserBreadcrumb)
