import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
`

export const StyledInnerWrapper = styled.div`
    flex-grow: 1;
`

export const StyledIcon = styled(Icon)`
    font-size: 174px;
    width: 174px;
    margin-right: ${variables.gutterLg};
`

export const SpinnerWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
`

export const StyledInputWrapper = styled.div`
    display: flex;
    width: 340px;

    > *:not(:last-child) {
        margin-right: 12px;
    }

    && input {
        color: ${variables.navyAsphalt};
        background-color: ${variables.blueLight};
    }
`

export const StyledHeader = styled.h3`
    margin-bottom: ${variables.gutterMd};

    font-family: ${variables.accentFont};
    font-size: 16px;
    font-weight: 700;

    color: ${variables.black};
`

export const StyledNote = styled.p`
    margin-bottom: 12px;

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};

    color: ${variables.navyAsphalt};
`
