import {createGlobalStyle, css} from 'styled-components'

import 'antd/dist/antd.less'
import 'rc-calendar/assets/index.css'
import 'ca-common/ui-lib/common/css/common.scss'

import {SelectGlobalStyle} from 'ca-common/ui-lib/atoms/Select'
import {RangePickerGlobalStyle} from 'ca-common/ui-lib/atoms/RangePicker'
import {InlineManualGlobalStyle} from 'ca-common/features/InlineManual'
import variables from 'ca-common/variables'
import {headerGradient} from 'ca-common/features/BaseHeader/lib/constants'

const reset = css`
    * {
        padding: 0;
        margin: 0;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    #root .ant-layout-sider {
        background: ${headerGradient} no-repeat center center fixed;
        overflow: auto;
    }
    #main_div {
        background: ${headerGradient} no-repeat center center fixed;
        overflow: auto;
    }
`

const resetAntDefaults = css`
    .ant-tabs-tab * {
        text-shadow: none !important;
    }

    .ant-table-title,
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid ${variables.navyBorder};
    }

    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

    .ant-modal-footer {
        button {
            font-weight: bold;
        }
    }

    .ant-input,
    .ant-input-number,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-checkbox .ant-checkbox-inner,
    .ant-checkbox.ant-checkbox-checked::after {
        border-radius: 4px;
    }
`

const TooltipGlobalStyle = css`
    .ant-tooltip-inner {
        padding: ${variables.gutterSm} 20px;
        background-color: ${variables.tooltipBackground};
        border-radius: 2px;
    }

    .ant-tooltip-arrow-content {
        --antd-arrow-background-color: ${variables.tooltipBackground};
    }
`

const PickerDropdownGlobalStyle = css`
    .ant-picker-dropdown {
        z-index: 1;
    }
`

export const GlobalStyles = createGlobalStyle`
    ${reset}
    ${resetAntDefaults}
    ${SelectGlobalStyle}
    ${RangePickerGlobalStyle}
    ${InlineManualGlobalStyle}
    ${TooltipGlobalStyle}
    ${PickerDropdownGlobalStyle}
`
