import React from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {i18n} from 'src/locales'

import {COMPARE_FORMAT} from 'ca-common/utils/datetime'
import {EntitiesTaskStatus} from 'src/newcore/molecules/EntitiesTaskStatus'

import {BACKUP_PROGRESS_STATUS} from 'ca-common/constants'
import {NewTaskSettings} from 'ca-common/types'
import {SingleBackupActions} from 'src/newcore/components/BackupActions'
import {withPulling} from 'src/newcore/hoc/withPulling'
import {
    LastBackup,
    Size,
    Status,
    StyledAdditionalBackupInfo,
    StyledBackupActions,
    Title
} from './StyledAdditionalBackupInfo'

const lastBackup = (utcAtTime?: string) => {
    return utcAtTime ? moment.utc(utcAtTime, COMPARE_FORMAT).fromNow().toString() : i18n.t('lastBackup:never')
}

type AdditionalBackupInfoProps = {
    taskSettings: NewTaskSettings
    progress: {
        backupState: BACKUP_PROGRESS_STATUS
        currentCount: number
    }
}

const AdditionalBackupInfoRaw = ({taskSettings, progress}: AdditionalBackupInfoProps) => {
    const {t} = useTranslation()

    return (
        <StyledAdditionalBackupInfo>
            <Status>
                <Title>{t('table:columns:status')}</Title>
                <EntitiesTaskStatus progress={progress} status={taskSettings.taskStatus} />
            </Status>
            <Size>
                <Title>{t('table:columns:size')}</Title>
                {taskSettings.size}
            </Size>
            <LastBackup>
                <Title>{t('table:columns:lastBackup')}</Title>
                {lastBackup(taskSettings.lastBackup)}
            </LastBackup>
            <StyledBackupActions>
                <Title>{t('table:columns:backupActions')}</Title>
                <SingleBackupActions
                    taskInfo={{status: taskSettings.taskStatus, cached: taskSettings.cached}}
                    performEntities={[taskSettings.taskId]}
                    source={taskSettings.source}
                    account={taskSettings.account}
                    name={t(`serviceNames:${taskSettings.source}`)}
                />
            </StyledBackupActions>
        </StyledAdditionalBackupInfo>
    )
}

export const AdditionalBackupInfo = withPulling(AdditionalBackupInfoRaw)
