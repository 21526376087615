import React from 'react'
import {useTranslation} from 'react-i18next'
import {Row} from 'antd'

import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {TRIGGERED_BY_ALL, TRIGGERED_BY_SINGLE} from 'src/newcore/features/SmartAlerts/lib/constants'

export const TriggerUserSelection = ({triggeredBy = TRIGGERED_BY_SINGLE, changeTrigger}) => {
    const {t} = useTranslation()

    return (
        <Row style={{maxWidth: '360px'}}>
            <RadioBlock
                checked={triggeredBy === TRIGGERED_BY_SINGLE}
                value={triggeredBy}
                onChange={() => changeTrigger(TRIGGERED_BY_SINGLE)}
                showRadioIcon
                title={t('smartAlerts:triggeredBy:singleUser')}
                description={t(`smartAlerts:wizard:alertConfigDescription:singleUser`)}
            />
            <RadioBlock
                checked={triggeredBy === TRIGGERED_BY_ALL}
                value={triggeredBy}
                onChange={() => changeTrigger(TRIGGERED_BY_ALL)}
                showRadioIcon
                title={t('smartAlerts:triggeredBy:allUsers')}
                description={t(`smartAlerts:wizard:alertConfigDescription:allUsers`)}
            />
        </Row>
    )
}
