import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 ${variables.gutterMd};
    background: ${variables.white};
    border-top: 1px solid ${variables.blue};
    border-radius: 0 0 ${variables.borderRadiusBase} ${variables.borderRadiusBase};
    color: ${variables.asphaltLight};
`

export const StyledActions = styled.div``

export const StyledControl = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
`
