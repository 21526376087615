import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledConfigurationWrapper = styled.section`
    display: flex;
    padding: ${variables.gutterMd};
    min-height: 409px;
`

export const StyledDiv = styled.div`
    width: 50%;
`

export const StyledWarning = styled.div`
    display: flex;
    opacity: ${props => (props.warning && !props.error ? 100 : 0)};
    transition: opacity 100ms ease-in;
    pointer-events: none;

    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${variables.fontSizeSecondary};
    line-height: 18px;

    color: ${variables.orange};
`

export const StyledIcon = styled(Icon)`
    color: ${variables.orange};
    margin: 4px 8px 0 0;
`
