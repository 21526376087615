import React from 'react'
import {withTranslation} from 'react-i18next'
import _ from 'lodash'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {Tooltip} from 'antd'

import {
    HeaderCell,
    HeaderCellTitle,
    CentralCell,
    Badge,
    StyledTable,
    Total,
    BadgeTitle,
    CellWrapper
} from './StyledCompareTable'
import {COMPARE_RESULT_TYPE} from 'ca-common/common/enum/CompareItem'
import {getDownloadFileLink} from 'src/newcore/redux/modules/backups/backupCompare'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'

@withTranslation('compareSnapshot')
class CompareTableRaw extends React.Component {
    state = {
        sortedInfo: null,
        data: []
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter
        })
    }

    prepareDataForTable = data => {
        this.setState({
            data: _.map(data, (val, key) => {
                return {
                    ...val,
                    key,
                    name: key
                }
            })
        })
    }

    componentDidMount() {
        this.prepareDataForTable(this.props.data.changes)
    }

    componentDidUpdate(prevProps) {
        if (!_.eq(prevProps, this.props)) {
            this.prepareDataForTable(this.props.data.changes)
        }
    }

    onClick = (objectName, resultType) => {
        const type = _.get(this, 'props.data.comparisonType')
        const {match, history} = this.props

        if (type === SFC_DATA_TYPE.METADATA) {
            history.push(`${match.url}/${resultType}/${objectName}`)
        } else {
            this.props.actions
                .getDownloadFileLink({
                    objectName,
                    resultType,
                    resultId: this.props.match.params.compareId
                })
                .unwrap()
                .then(downloadLink => {
                    setTimeout(() => {
                        window.open(downloadLink, 'Download')
                    }, 100)
                })
                .catch(err => openErrorNotification(err.status))
        }
    }

    render() {
        const {
            t,
            data: {comparisonType}
        } = this.props
        let {sortedInfo} = this.state

        sortedInfo = sortedInfo || {}
        const columns = [
            {
                title: (
                    <HeaderCell>
                        <HeaderCellTitle>{t('total')}</HeaderCellTitle>
                        <span>{t('records')}</span>
                    </HeaderCell>
                ),
                dataIndex: 'totalLeft',
                key: 'totalLeft',
                render: count => (
                    <CellWrapper>
                        <BadgeTitle>{t('total')}</BadgeTitle>
                        <Total>{count}</Total>
                    </CellWrapper>
                ),
                sorter: (a, b) => a.totalLeft - b.totalLeft,
                sortOrder: sortedInfo.columnKey === 'totalLeft' && sortedInfo.order
            },
            {
                title: (
                    <HeaderCell>
                        <HeaderCellTitle>{t('unique')}</HeaderCellTitle>
                        <span>{t('records')}</span>
                    </HeaderCell>
                ),
                dataIndex: 'uniqueLeft',
                key: 'uniqueLeft',
                render: (count, el) => (
                    <CellWrapper onClick={() => count !== 0 && this.onClick(el.name, COMPARE_RESULT_TYPE.UNIQUE_LEFT)}>
                        <BadgeTitle>{t('unique')}</BadgeTitle>
                        {SFC_DATA_TYPE.METADATA !== comparisonType && count > 0 ? (
                            <Tooltip title={t(`backups:compare:tooltips:download`)}>
                                <Badge>{count}</Badge>
                            </Tooltip>
                        ) : (
                            <Badge>{count}</Badge>
                        )}
                    </CellWrapper>
                ),
                sorter: (a, b) => a.uniqueLeft - b.uniqueLeft,
                sortOrder: sortedInfo.columnKey === 'uniqueLeft' && sortedInfo.order
            },
            {
                title: (
                    <HeaderCell>
                        <HeaderCellTitle>{t('changed')}</HeaderCellTitle>
                        <span>{t('records')}</span>
                    </HeaderCell>
                ),
                dataIndex: 'changed',
                key: 'changed',
                render: (count, el) => (
                    <CentralCell onClick={() => count !== 0 && this.onClick(el.name, COMPARE_RESULT_TYPE.DIFF)}>
                        {el.name}
                        {SFC_DATA_TYPE.METADATA !== comparisonType && count > 0 ? (
                            <Tooltip title={t(`backups:compare:tooltips:download`)}>
                                <Badge>{count}</Badge>
                            </Tooltip>
                        ) : (
                            <Badge>{count}</Badge>
                        )}
                    </CentralCell>
                ),
                sorter: (a, b) => a.changed - b.changed,
                sortOrder: sortedInfo.columnKey === 'changed' && sortedInfo.order
            },
            {
                title: (
                    <HeaderCell>
                        <HeaderCellTitle>{t('unique')}</HeaderCellTitle>
                        <span>{t('records')}</span>
                    </HeaderCell>
                ),
                dataIndex: 'uniqueRight',
                key: 'uniqueRight',
                render: (count, el) => (
                    <CellWrapper onClick={() => count !== 0 && this.onClick(el.name, COMPARE_RESULT_TYPE.UNIQUE_RIGHT)}>
                        <BadgeTitle>{t('unique')}</BadgeTitle>
                        {SFC_DATA_TYPE.METADATA !== comparisonType && count > 0 ? (
                            <Tooltip title={t(`backups:compare:tooltips:download`)}>
                                <Badge>{count}</Badge>
                            </Tooltip>
                        ) : (
                            <Badge>{count}</Badge>
                        )}
                    </CellWrapper>
                ),
                sorter: (a, b) => a.uniqueRight - b.uniqueRight,
                sortOrder: sortedInfo.columnKey === 'uniqueRight' && sortedInfo.order
            },
            {
                title: (
                    <HeaderCell>
                        <HeaderCellTitle>{t('total')}</HeaderCellTitle>
                        <span>{t('records')}</span>
                    </HeaderCell>
                ),
                dataIndex: 'totalRight',
                key: 'totalRight',
                render: count => (
                    <CellWrapper>
                        <BadgeTitle>{t('total')}</BadgeTitle>
                        <Total>{count}</Total>
                    </CellWrapper>
                ),
                sorter: (a, b) => a.totalRight - b.totalRight,
                sortOrder: sortedInfo.columnKey === 'totalRight' && sortedInfo.order
            }
        ]
        return (
            <div>
                <StyledTable
                    columns={columns}
                    dataSource={this.state.data}
                    onChange={this.handleChange}
                    pagination={false}
                />
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getDownloadFileLink
        },
        dispatch
    )
})

export const CompareTable = withRouter(connect(null, mapDispatchToProps)(CompareTableRaw))
