import {combineReducers} from '@reduxjs/toolkit'

import {alerts, getSfcSmartAlerts, clearSfsSmartAlerts, updateSfcSmartAlertsLocal} from './sfcSmartAlerts'
import {sfcAlertObjects} from 'src/newcore/features/SmartAlertsWizard/redux'
import {tableAllSelected, tableAllSelectedActions} from './tableAllSelected'
import {tableSelectedItems, tableSelectedItemsActions} from './tableSelectedItems'
export * from './execSmartAlertaction'
export * from './editSmartAlert'

const sfcSmartAlerts = combineReducers({
    alerts,
    tableAllSelected,
    tableSelectedItems,
    sfcAlertObjects
})

export {
    getSfcSmartAlerts,
    clearSfsSmartAlerts,
    tableAllSelectedActions,
    tableSelectedItemsActions,
    sfcSmartAlerts,
    updateSfcSmartAlertsLocal
}
