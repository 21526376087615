import React from 'react'
import {useTranslation} from 'react-i18next'

import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'

import Activate from 'ca-common/icons/backupAction/Activate.svg'
import Pause from 'ca-common/icons/backupAction/Pause.svg'
import Run from 'ca-common/icons/backupAction/Now.svg'
import Delete from 'ca-common/icons/backupAction/Delete.svg'

import {StyledBackupTaskAction, TaskActionIcon} from './StyledBackupTaskAction'

type Props = {
    action: TASK_ACTION
    withTitle?: boolean
}

export const BackupTaskAction = (props: Props): JSX.Element => {
    const {t} = useTranslation()
    const {action, withTitle = true} = props

    return (
        <StyledBackupTaskAction>
            <TaskActionIcon
                component={(() => {
                    switch (action) {
                        case TASK_ACTION.ACTIVATE:
                            return Activate
                        case TASK_ACTION.PAUSE:
                            return Pause
                        case TASK_ACTION.RUN:
                            return Run
                        case TASK_ACTION.DELETE:
                            return Delete
                        default:
                            return null
                    }
                })()}
            />
            {withTitle && t(`backups:newActions:${action}`)}
        </StyledBackupTaskAction>
    )
}
