import styled from 'styled-components'
import {Radio} from 'antd'

import variables from 'src/ca-common/variables'

export const StyledRadio = styled(Radio)`
    background: ${variables.white};
    border-radius: 22px;
    border: 0;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    width: 100%;
    max-width: 624px;
    min-height: 32px;
    margin: 0 ${variables.gutterMd} ${variables.gutterXs} 0;
    transition: all 0.2s;

    &.ant-radio-wrapper-checked,
    &:focus,
    &:hover {
        background: ${variables.radioBlockBackground};
        transition: all 0.2s;
        color: ${variables.navyBlue};
    }
    span.ant-radio {
        display: none;
        & + * {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
`
export const Mailbox = styled.p`
    margin: 0;
    font-weight: 600;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
`
export const Email = styled.p`
    margin: 0;
    font-size: ${variables.fontSizeSecondary};
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
`
