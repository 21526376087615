import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {SOURCE} from 'ca-common/common/enum'

const GET_JOB_SERVICES = 'GET_JOB_SERVICES'

const GET_JOB_SERVICES_API = 'getUserSources'
const getJobServicesWrapper = fetchWrapperRT(GET_JOB_SERVICES, async data => await post(GET_JOB_SERVICES_API, data))
export const getJobServices = getJobServicesWrapper.fetcher

const servicesSlice = createSlice({
    name: 'services',
    initialState: InitFetchedState as FetchedState<SOURCE[]>,
    reducers: {
        clearJobServices: () => InitFetchedState as FetchedState<SOURCE[]>
    },
    extraReducers: builder => getJobServicesWrapper.makeReducers(builder)
})

export const {clearJobServices} = servicesSlice.actions
export const services = servicesSlice.reducer
