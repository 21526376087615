import {createSelector, createSlice} from '@reduxjs/toolkit'
import {postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_PUBLIC_COMPANYINFO = 'GET_PUBLIC_COMPANYINFO'
const GET_PUBLIC_COMPANYINFO_API = 'getPublicCompanyInfo'
const getPublicCompanyInfoWrapper = fetchWrapperRT(
    GET_PUBLIC_COMPANYINFO,
    async data => await postLessData(GET_PUBLIC_COMPANYINFO_API, data)
)

export const getPublicCompanyInfo = getPublicCompanyInfoWrapper.fetcher

const appendPrivateSignature = response => {
    return {
        isPrivateCompanyInfo: true,
        ...response
    }
}

const GET_PRIVATE_COMPANYINFO = 'GET_PRIVATE_COMPANYINFO'
const GET_PRIVATE_COMPANYINFO_API = 'getCompanyInfo'
const getPrivateCompanyInfoWrapper = fetchWrapperRT(
    GET_PRIVATE_COMPANYINFO,
    async data => await postLessData(GET_PRIVATE_COMPANYINFO_API, data),
    {
        after: appendPrivateSignature
    }
)

export const getPrivateCompanyInfo = getPrivateCompanyInfoWrapper.fetcher

const companyInfoSlice = createSlice({
    name: 'companyInfo',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => {
        getPublicCompanyInfoWrapper.makeReducers(builder)
        getPrivateCompanyInfoWrapper.makeReducers(builder)
    }
})
export const companyInfo = companyInfoSlice.reducer

export const companyInfoSelector = createSelector(
    state => state.companyInfo,
    ({response, status}) => ({...response, status})
)
