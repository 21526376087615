import React from 'react'
import moment, {MomentInput} from 'moment'
import {Tooltip} from 'antd'

import {FULL_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

export const LastBackup = ({entity}: {entity: {lastBackup: any; lastBackupDate: MomentInput}}) => (
    <>
        {entity.lastBackupDate ? (
            <Tooltip
                placement="top"
                title={moment(entity.lastBackupDate, LONG_DATE_FORMAT).format(FULL_FORMAT)}
                mouseEnterDelay={0.4}
            >
                <span>{entity.lastBackup}</span>
            </Tooltip>
        ) : (
            <span>{entity.lastBackup}</span>
        )}
    </>
)
