import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {transparentize} from 'polished'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    max-width: 624px;
    width: 100%;
    margin-bottom: ${variables.gutterMd};
`
export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`
export const SearchIcon = styled(Icon)`
    color: ${transparentize(0.75, variables.dark)};
`
