export enum SEEDING_FILTERS {
    ALL_RECORDS = 'ALL_RECORDS',
    PICK = 'PICK',
    WHERE_CLAUSE = 'WHERE_CLAUSE',
    MIXED = 'MIXED'
}

export enum SEEDING_PICK_OPTIONS {
    NEWEST = 'NEWEST',
    OLDEST = 'OLDEST',
    RANDOM = 'RANDOM'
}

export enum SEEDING_DESTINATION_TYPE {
    SAME = 'SAME',
    OTHER = 'OTHER'
}

export enum SEEDING_OBJECT_TYPES {
    FULL = 'FULL',
    MULTIPLE = 'MULTIPLE'
}

export const METADATA_ENTITY_TYPE = 'MD_FOLDER'

export enum ANONYMIZATION_MODE {
    RANDOM = 'RANDOM',
    PRE_DEFINED = 'PRE_DEFINED'
}
