import React, {Component} from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {
    getPermissions,
    setPermissions,
    clearPermissions,
    fetchCredentials
} from 'src/newcore/redux/modules/userManagement'
import {
    parseFormPermissions,
    parseExistPermission,
    getRole,
    getServicesByWhiteList
} from 'ca-common/utils/usersManagement'
import {permittedWhiteList, shrinkPermissionsByServiceWhiteList} from 'src/newcore/utils/permissions'

import {UserPageForm} from './UserForm'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

const dumbEditUser = dumbBem('ca-editUser-page')
const CAEditUser = tx(dumbEditUser)('div')

const USERS_PAGE = '/settings/users'

@withTranslation('userManagementToast')
class EditUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            serviceList: {},
            userPermission: {},
            stateFilled: false
        }
    }

    componentDidMount() {
        const {
            actions,
            match: {
                params: {id}
            },
            credentials,
            userInfo: {permissions}
        } = this.props

        if (credentials.status !== 'fulfilled') {
            actions.fetchCredentials()
        }

        actions
            .getPermissions({id})
            .then(response => {
                this.setState({
                    serviceList: getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(permissions)),
                    userPermission: shrinkPermissionsByServiceWhiteList(response.payload),
                    stateFilled: true
                })
            })
            .catch(data => {
                if (!data.success) {
                    openErrorModal(data.status)
                }
            })
    }

    componentWillUnmount = () => this.props.actions.clearPermissions()

    render() {
        const {
            permission,
            credentials,
            match: {
                params: {id}
            },
            userInfo: {isFinra},
            userInfo,
            companyInfo
        } = this.props
        const {stateFilled} = this.state

        if (!this.isDataLoaded(stateFilled, permission, credentials)) {
            return <Spinner modifier="page" />
        }

        const {serviceList, userPermission} = this.state
        const currentCredential = this.getCurrentCredential(credentials, id)
        const generalList = getGeneralList(companyInfo, userInfo)
        const tasksList = getTasksList()
        const ORIGINAL_LIST = [serviceList, generalList, tasksList]

        return (
            <CAEditUser>
                <UserPageForm
                    onSubmit={this.submitForm(ORIGINAL_LIST)}
                    isNew={false}
                    initialValues={{
                        permissions: parseExistPermission(userPermission, ORIGINAL_LIST),
                        credentials: {
                            ...currentCredential,
                            role: getRole(permission.response.accessLevel),
                            name: currentCredential.name || ''
                        }
                    }}
                    groups={{
                        services: serviceList,
                        tasks: tasksList,
                        general: generalList
                    }}
                    isFinra={isFinra}
                />
            </CAEditUser>
        )
    }

    submitForm = originalList => values => {
        const {t, actions, history} = this.props
        const data = {
            type: values.credentials.type,
            email: values.credentials.email.toLowerCase(),
            permissions: parseFormPermissions(values, originalList)
        }

        return actions
            .setPermissions(data)
            .unwrap()
            .then(() => {
                openSuccessNotification(t('userManagementToast:permissionsUpdated'))
                history.push(USERS_PAGE)
            })
            .catch(err => openErrorModal(err.status))
    }

    getCurrentCredential = (credentials, id) => credentials.response.data.find(cred => cred.id === id)

    isDataLoaded = (stateFilled, permission, credentials) => {
        return stateFilled && [permission.status, credentials.status].every(status => status === 'fulfilled')
    }
}

const mapStateToProps = state => ({
    credentials: state.credentials,
    permission: state.permission,
    userInfo: state.userInfo.response,
    companyInfo: state.companyInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                getPermissions,
                setPermissions,
                clearPermissions,
                fetchCredentials
            },
            dispatch
        )
    }
})

export const EditUserPage = connect(mapStateToProps, mapDispatchToProps)(EditUser)
