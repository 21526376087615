import {Group} from '@visx/group'
import React from 'react'
import {AxisBottom, AxisLeft} from '@visx/axis'
import moment from 'moment'
import {GridRows} from '@visx/grid'
import {BarGroup} from '@visx/shape'
import {ScaleBand, ScaleLinear} from 'd3-scale'

import variables from 'ca-common/variables'

import {FAILED, DATE, TOTAL} from 'src/newcore/features/BackupChart/lib'
import {ChartGroup} from '..'

const axisBottomTickLabelProps = {
    textAnchor: 'middle' as const,
    fontFamily: 'Arial',
    fontSize: 12,
    lineHeight: 16,
    weight: 400,
    fill: variables.asphalt
}
const axisLeftTickLabelProps = {
    dx: '-0.25px',
    dy: '0.25px',
    fontFamily: 'Arial',
    fontSize: 10,
    textAnchor: 'end' as const,
    fill: variables.asphalt
}

type AreaChartProps = {
    data: any[]
    width: number
    yMax: number
    margin: {top: number; right: number; bottom: number; left: number}
    simplified?: boolean
    top?: number
    left?: number
    children?: React.ReactNode
    getDate: (d: any) => string
    keys: string[]
    colorScale?: any
    yScale: ScaleLinear<number, number>
    xBandScale?: any
    xScale: ScaleBand<string>
    onMouseLeaveHandler?: any
    onMouseMoveHandler?: any
    xMax?: number
    yNumTicks?: number
}

export const AreaChart = ({
    data,
    width,
    yMax,
    margin,
    simplified = false,
    top,
    left,
    children,
    keys,
    xScale,
    xBandScale,
    yScale,
    colorScale,
    getDate,
    onMouseLeaveHandler,
    onMouseMoveHandler,
    xMax = 0,
    yNumTicks
}: AreaChartProps): JSX.Element | null => {
    if (width < 10) return null

    yScale.range([yMax, 0])

    return (
        <Group left={left || margin.left} top={top || margin.top}>
            {!simplified && <GridRows scale={yScale} width={xMax} height={yMax} stroke="#e0e0e0" />}
            <BarGroup
                data={data}
                keys={keys}
                height={yMax}
                x0={getDate}
                x0Scale={xScale}
                x1Scale={xBandScale}
                yScale={yScale}
                color={colorScale}
            >
                {barGroups =>
                    barGroups.map(barGroup => (
                        <ChartGroup
                            key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                            barGroup={barGroup}
                            onMouseLeaveHandler={onMouseLeaveHandler}
                            onMouseMoveHandler={onMouseMoveHandler}
                            gradientWidth={xScale.bandwidth()}
                            gradientHeight={yMax}
                            failed={data[barGroup.index][FAILED]}
                            date={data[barGroup.index][DATE]}
                            total={data[barGroup.index][TOTAL]}
                            simplified={simplified}
                        />
                    ))
                }
            </BarGroup>
            {!simplified && (
                <AxisBottom
                    top={yMax}
                    scale={xScale}
                    numTicks={10}
                    stroke={variables.asphalt}
                    tickStroke="transparent"
                    tickLabelProps={() => axisBottomTickLabelProps}
                    tickFormat={tick => moment(tick, 'YYYYMMDD').format('DD/MM').toString()}
                />
            )}
            {!simplified && (
                <AxisLeft
                    scale={yScale}
                    numTicks={yNumTicks}
                    stroke={variables.asphalt}
                    tickStroke="transparent"
                    tickLabelProps={() => axisLeftTickLabelProps}
                    tickFormat={tick => (tick as number).toFixed(0)}
                />
            )}

            {children}
        </Group>
    )
}
