import React from 'react'

import {SpinnerWrapper, SpinnerCircle} from './StyledSpinner'
import type {SpinnerProps} from './types'

const pieces = [...Array(12).keys()]

export const Spinner = ({modifier}: SpinnerProps): JSX.Element => {
    return (
        <SpinnerWrapper modifier={modifier}>
            {pieces.map(key => (
                <SpinnerCircle key={key} positionNumber={key} />
            ))}
        </SpinnerWrapper>
    )
}
