import i18n from 'i18next'

import {post, postLessData} from 'src/newcore/utils/rest'
import {toggleRecoveryExpiredVisible} from 'ca-common/redux/modules/sign'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {TODO_ANY} from 'ca-common/types'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'
import {createAsyncThunk} from '@reduxjs/toolkit'

const SIGN_UP = 'SIGN_UP'
const SIGN_UP_API = 'signUp'

export const fetchSignUp = createAsyncThunk(SIGN_UP, async (data: any, {rejectWithValue}) => {
    try {
        return await postLessData(SIGN_UP_API, data)
    } catch (err: any) {
        return rejectWithValue(err.data)
    }
})

const SIGN_IN = 'SIGN_IN'
const SIGN_IN_API = 'SignIn'

export const fetchSignIn = createAsyncThunk(SIGN_IN, async (data: any, {rejectWithValue}) => {
    try {
        return await postLessData(SIGN_IN_API, data)
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

const SEND_USER_REGISTRATION = 'SIGN_UP'
const SEND_USER_REGISTRATION_API = 'sendUserRegistration'
const SendUserRegistration = fetchWrapperRT(
    SEND_USER_REGISTRATION,
    async data => await post(SEND_USER_REGISTRATION_API, data)
)
export const sendUserRegistration = SendUserRegistration.fetcher

const CHANGE_SIGN_IN_PASSWORD = 'CHANGE_SIGN_IN_PASSWORD'
const CHANGE_SIGN_IN_PASSWORD_API = 'ChangePasswordSignIn'

export const changeSignInPassword = createAsyncThunk(CHANGE_SIGN_IN_PASSWORD, async (data: any, {rejectWithValue}) => {
    try {
        return await postLessData(CHANGE_SIGN_IN_PASSWORD_API, data)
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

const changePassword = createAsyncThunk('CHANGE_PASSWORD', async (data: any, {rejectWithValue}) => {
    try {
        return await post('changePasswordPublic', data)
    } catch (err: any) {
        return rejectWithValue(err.data)
    }
})

export const changeTemporaryPassword = (data: TODO_ANY, dispatch: TODO_ANY, onSuccess: () => void) => {
    const {email, token, newPassword} = data

    return dispatch(changePassword({email, resetToken: token, newPassword}))
        .unwrap()
        .then(() => {
            dispatch(toggleRecoveryExpiredVisible(false))
            openSuccessNotification(i18n.t('partnerPortalSite:signIn:changePasswordSuccess'))
            onSuccess && onSuccess()
        })
        .catch(() => {
            dispatch(toggleRecoveryExpiredVisible(true))
        })
}
