import {i18n} from 'src/locales'

import {UNIT_PERCENT, UNIT_RECORD} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getSmartAlertUnitFriendly = unit => {
    switch (unit) {
        case UNIT_PERCENT:
            return i18n.t('smartAlerts:unit:percent')

        case UNIT_RECORD:
            return i18n.t('smartAlerts:unit:record')

        default:
            return unit
    }
}
