import {Input, Form} from 'antd'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {VerticalFormItem, Label} from 'ca-common/ui-lib/components/FormItem'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {SNAPSHOT_TYPE} from 'ca-common/common/enum/Backups'

import {SnapshotCalendar} from 'src/newcore/organisms/SnapshotCalendar'
import {Wrapper, InputGroupWrapper} from '../StyledCompareSnapshot'

export const SelectCurrentSnapshot = ({task}) => {
    const {t} = useTranslation()

    return (
        <Wrapper>
            <RadioBlock
                isStatic
                hideInactive={false}
                title={task.alias}
                value={SNAPSHOT_TYPE.CURRENT}
                showRadioIcon={false}
            >
                <InputGroupWrapper>
                    <VerticalFormItem
                        label={
                            <Label>
                                <span>{t('forms:compareSnapshots:fields:currentBackupTask:label')}</span>
                            </Label>
                        }
                        colon={false}
                    >
                        <Form.Item>
                            <Input style={{width: '100%'}} disabled value={task.alias} />
                        </Form.Item>
                    </VerticalFormItem>
                    <VerticalFormItem>
                        <Form.Item
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:compareSnapshots:fields:currentSnapShotDate:message')
                                }
                            ]}
                        >
                            <SnapshotCalendar
                                flexFlow={'column'}
                                payload={{taskId: task.id}}
                                firstBackupDate={task.firstBackupDate}
                                retentionDate={task.retentionDate}
                            />
                        </Form.Item>
                    </VerticalFormItem>
                </InputGroupWrapper>
            </RadioBlock>
        </Wrapper>
    )
}
