import styled from 'styled-components'
import {Radio} from 'antd'
import variables from 'ca-common/variables'

export const RadioGroup = styled(Radio.Group)`
    display: flex;
    flex-wrap: wrap;
    margin-left: -${variables.gutterXs};
    margin-right: -${variables.gutterXs};
`

export const RadioButton = styled(Radio)`
    border-radius: 8px;
    background: ${variables.blue};
    padding: ${variables.gutterSm} ${variables.gutterMd};
    transition: all 0.3s;
    flex: 1;
    margin: ${variables.gutterXs};

    .ant-radio-inner {
        border-color: ${variables.navyBlue};
    }

    .ca-tooltip {
        display: inline-block;
        vertical-align: middle;
    }

    &.ant-radio-wrapper-checked {
        background: ${variables.navyBlue};
        color: ${variables.white};

        .ca-tooltip {
            .ca-icon {
                color: ${variables.white};
            }
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: ${variables.white};
            background-color: ${variables.navyBlue};

            &::after {
                background-color: ${variables.white};
            }
        }

        .ant-radio-disabled + span {
            color: ${variables.disabled};
        }
    }
`
