import React, {Fragment, useState} from 'react'
import {Form, Checkbox, FormInstance} from 'antd'
import {useTranslation} from 'react-i18next'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

import {BackupUnlicensedAccountsForm} from 'src/newcore/forms/BackupUnlicensedAccounts'
import {BackupUnlicensedAccountsModal} from 'src/newcore/components/Modals/BackupUnlicensedAccountsModal'

type BackupUnlicensedAccountsProps = {
    backupUnlicensed?: boolean
    namePrefix?: string
    form: FormInstance<Record<string, any>>
}

export const BackupUnlicensedAccounts = ({backupUnlicensed, namePrefix = '', form}: BackupUnlicensedAccountsProps) => {
    const name = `${namePrefix ? `${namePrefix}.` : ''}backupUnlicensed`

    const {t} = useTranslation()
    const [unlicensedAccountsModalVisible, setUnlicensedAccountsModalVisible] = useState(false)

    const toggleVisible = () => setUnlicensedAccountsModalVisible(state => !state)

    const handleChange = (event: CheckboxChangeEvent) => {
        if (!event.target.checked) {
            toggleVisible()
        }
    }

    const cancel = () => {
        form && form.setFieldsValue({[name]: true})
        toggleVisible()
    }

    return (
        <Fragment>
            <Form.Item name={name} initialValue={backupUnlicensed} valuePropName="checked">
                <Checkbox onChange={handleChange}>{t('forms:backupSettings:fields:backupUnlicensed:label')}</Checkbox>
            </Form.Item>

            <BackupUnlicensedAccountsModal
                open={unlicensedAccountsModalVisible}
                onCancel={toggleVisible}
                maskClosable={false}
            >
                <BackupUnlicensedAccountsForm onCancel={cancel} onSubmit={toggleVisible} namePrefix={namePrefix} />
            </BackupUnlicensedAccountsModal>
        </Fragment>
    )
}
