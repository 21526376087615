import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledAdditionalBackupInfo = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex: 0 1 100%;
    margin-top: 10px;
    margin-bottom: 12px;
    font-size: ${variables.fontSizeBase};
`

const AdditionalBackupInfoBlock = styled.div`
    display: flex;
    margin-right: ${variables.gutterLg};

    :last-child {
        margin-right: 0;

        strong {
            margin-right: ${variables.gutterSm};
        }
    }
`

export const Status = styled(AdditionalBackupInfoBlock)`
    align-items: center;
`

export const Size = styled(AdditionalBackupInfoBlock)``

export const LastBackup = styled(AdditionalBackupInfoBlock)``

export const StyledBackupActions = styled(AdditionalBackupInfoBlock)``

export const Title = styled.strong`
    margin-right: ${variables.gutterXs};
    font-weight: 600;
`
