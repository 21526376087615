import {i18n} from 'src/locales'

import {EVENTS_ADDED, EVENTS_DELETED, EVENTS_UPDATED} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getSmartAlertEventFriendly = event => {
    switch (event) {
        case EVENTS_ADDED:
            return i18n.t('smartAlerts:event:added')

        case EVENTS_DELETED:
            return i18n.t('smartAlerts:event:deleted')

        case EVENTS_UPDATED:
            return i18n.t('smartAlerts:event:updated')

        default:
            return ''
    }
}
