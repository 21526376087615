import styled from 'styled-components'
import {rgba} from 'polished'

import {Table} from 'ca-common/ui-lib/organisms/Table'
import variables from 'ca-common/variables'

export const StyledTable = styled(Table)`
    .ant-table table tr:nth-child(2n) {
        background-color: ${rgba(variables.brightBlue, 0.02)};
    }
`
