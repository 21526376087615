import {useCallback} from 'react'

import {SOURCE} from 'ca-common/common/enum'

import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {changeData} from 'src/newcore/redux/modules/wizard'

import type {OCAWizardData, OCAWizardConfig} from 'src/newcore/features/OneClickActivation/types'

type UseWriteEntitiesToWizardDataAndClean = (params: {
    service: SOURCE
    wizardData: OCAWizardData
    wizardConfig: OCAWizardConfig
    clearTableData: () => void
}) => (params: {enabledEntitiesBitSet: string; cancelFetchEntities: () => void}) => void

export const useWriteEntitiesToWizardDataAndClean: UseWriteEntitiesToWizardDataAndClean = ({
    service,
    wizardData,
    wizardConfig,
    clearTableData
}) => {
    const dispatch = useAppDispatch()

    return useCallback(
        ({enabledEntitiesBitSet, cancelFetchEntities}) => {
            cancelFetchEntities()

            const newData = {
                [service]: {
                    ...wizardData[service],
                    enabledEntitiesBitSet
                }
            } as Partial<OCAWizardData>

            dispatch(changeData(newData))
            clearTableData()
        },
        [service, wizardData, wizardConfig, clearTableData]
    )
}
