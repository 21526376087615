import React, {useContext, useState} from 'react'
import {Modal, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import {InternalFieldData} from 'rc-field-form/lib/interface'

import DeleteActionSVG from 'ca-common/icons/ipRestrictions/Delete.svg'
import EditActionSVG from 'ca-common/icons/ipRestrictions/Edit.svg'
import Delete from 'ca-common/icons/backupAction/Delete.svg'

import {
    RESTRICTION_TABLE_ACTIONS,
    useOnValidateFailedProps,
    IpRestrictionActionsContext,
    isFieldsChanged
} from 'ca-common/features/IpRestriction/lib'
import {EditForm} from 'ca-common/features/IpRestriction/molecules'
import type {RestrictionEntry} from 'ca-common/features/IpRestriction/types'

import {
    StyledTableActions,
    StyledIcon,
    ModalWrapper,
    StyledHeader,
    StyledHeaderWrapper,
    StyledDescription,
    StyledRemove,
    RemoveIcon
} from './StyledTableActions'

type TableActionsProps = {
    record: RestrictionEntry
}

export const TableActions = (props: TableActionsProps): JSX.Element => {
    const {record} = props
    const actionHandler = useContext(IpRestrictionActionsContext)
    const [visible, setVisible] = useState(false)
    const [visibleRemove, setVisibleRemove] = useState(false)
    const [loading, setLoading] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [form] = Form.useForm()
    const {t} = useTranslation()

    const onEditHandler = () => {
        setVisible(true)
    }

    const onDeleteHandler = () => {
        setVisibleRemove(true)
    }

    const onCancelHandler = () => {
        setVisible(false)
        form.resetFields()
    }

    const {onValidateFailedFromBackend, disabled, onValidateFailed} = useOnValidateFailedProps(form)

    const onOkHandler = () => {
        if (!isFieldsChanged(record, form.getFieldsValue())) {
            onCancelHandler()
            return
        }
        setLoading(true)
        form.validateFields()
            .then(values => {
                actionHandler &&
                    actionHandler({type: RESTRICTION_TABLE_ACTIONS.EDIT, entry: values, id: record.id})
                        .then(() => {
                            setVisible(false)
                            setLoading(false)
                        })
                        .catch(error => {
                            setLoading(false)

                            if (error?.data) {
                                onValidateFailedFromBackend(error.data, values)
                            }
                        })
            })
            .catch((args: {values: Record<string, any>; errorFields: InternalFieldData[]}) => {
                onValidateFailed(args)
                setLoading(false)
            })
    }

    const onOkRemove = async () => {
        setRemoving(true)
        actionHandler && (await actionHandler({type: RESTRICTION_TABLE_ACTIONS.DELETE, id: record.id}))
        setRemoving(false)
        setVisibleRemove(false)
    }

    const onCancelRemove = () => {
        setVisibleRemove(false)
    }

    return (
        <React.Fragment>
            <StyledTableActions>
                <StyledIcon component={EditActionSVG} onClick={onEditHandler} />
                <StyledIcon component={DeleteActionSVG} onClick={onDeleteHandler} />
            </StyledTableActions>
            <Modal
                open={visible}
                closable={false}
                okText="Save"
                cancelButtonProps={{type: 'text'}}
                okButtonProps={{loading, disabled}}
                width={516}
                onOk={onOkHandler}
                onCancel={onCancelHandler}
                destroyOnClose
                centered
            >
                <ModalWrapper>
                    <EditForm form={form} record={record} />
                </ModalWrapper>
            </Modal>
            <Modal
                width={426}
                open={visibleRemove}
                closable={false}
                okText="Delete"
                okButtonProps={{type: 'primary', danger: true, loading: removing}}
                destroyOnClose
                onOk={onOkRemove}
                onCancel={onCancelRemove}
                centered
            >
                <StyledRemove>
                    <StyledHeaderWrapper>
                        <StyledHeader>{t('ipRestrictions:removeConfirmationHeader')}</StyledHeader>
                        <RemoveIcon component={Delete} />
                    </StyledHeaderWrapper>
                    <StyledDescription>{record.description}</StyledDescription>
                </StyledRemove>
            </Modal>
        </React.Fragment>
    )
}
