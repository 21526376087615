import styled from 'styled-components'
import {CalendarOutlined} from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledButton = styled(Button)`
    line-height: 30px;
    font-weight: 400;
    min-width: 250px;

    > span:first-child {
        margin-right: 36px;
    }
`

export const StyledCalendarIconWrapper = styled.span`
    display: inline-flex;
    position: absolute !important;
    right: 0;
    width: 36px;
    height: 30px;
    background-color: ${variables.navyBlue};
    color: ${variables.white};
    border-radius: 0 ${variables.borderRadiusBase} ${variables.borderRadiusBase} 0;
`

export const StyledCalendarIcon = styled(CalendarOutlined)`
    font-size: 16px;
`
