import _ from 'lodash'
import React from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import queryString from 'query-string'

import {SignInPage as SignIn} from 'ca-common/components/SignInPage'
import {fetchSignIn} from 'src/newcore/redux/modules/sign'
import {toggleTwoFA} from 'ca-common/redux/modules/sign'
import {b64ToUtf8} from 'ca-common/utils/base64'
import {OAUTH_SERVICES_LINK, ERROR_CODES, PORTAL_BASENAME, PUBLIC_PAGES} from 'ca-common/constants'
import {getPortalSignInOAuthServices} from 'ca-common/utils/oauth'

import {callReload} from 'src/newcore/utils/logout'
const APP_LOCATION = '/'

class SignInPageRaw extends React.Component {
    state = {
        error: null,
        email: '',
        loading: false
    }

    componentWillUnmount() {
        this.props.actions.toggleTwoFA(false)
    }

    componentDidMount() {
        if (this.props.companyInfo.externalUserManagment) {
            callReload()
        }
    }

    errorState = error => this.setState({error})

    handleChange = error => this.setState({error})

    updateLinks = links => {
        const params = queryString.parse(this.props.history.location.search)

        const requestParams = {}

        if (params.redirect) {
            requestParams.redirect = params.redirect + this.props.history.location.hash
        }

        if (!this.props.companyInfo?.response?.isCloudally) {
            requestParams.Pid = this.props.companyInfo.response.partnerId
        }

        const parsedParams = queryString.stringify(requestParams)

        return _.reduce(
            links,
            (link, value, key) => {
                link[key] = `${value}${parsedParams ? `?${parsedParams}` : ''}`
                return link
            },
            {}
        )
    }

    handleSubmit = values => {
        const {actions, t, history} = this.props

        this.setState({loading: true})
        actions
            .fetchSignIn(values)
            .unwrap()
            .then(data => {
                const redirectLink = /\?redirect=([^?&]*)/.exec(window.location.toString())?.[1]
                const responseRedirect = data?.redirectUrl

                if (values.twoFaRecoveryCode) {
                    history.push('/settings/security/twoFa')
                } else if (responseRedirect) {
                    window.location = responseRedirect
                } else if (redirectLink) {
                    try {
                        //?redirect=nonurlvalue11
                        const parsedURL = new URL(b64ToUtf8(redirectLink))
                        const isValid =
                            parsedURL.host === window.location.host &&
                            parsedURL.protocol === window.location.protocol &&
                            parsedURL.pathname !== `/${PORTAL_BASENAME}${PUBLIC_PAGES.SIGN_IN}`

                        if (isValid) {
                            window.location.href = parsedURL
                        } else {
                            history.push(APP_LOCATION)
                        }
                    } catch (error) {
                        history.push(APP_LOCATION)
                    }
                } else {
                    history.push(APP_LOCATION)
                }
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'login'
                })
            })
            .catch(error => {
                this.setState({loading: false})
                if (error.status === ERROR_CODES.MISSING_2FA_CODE) {
                    this.setState({error: false, email: values.email})
                    actions.toggleTwoFA(true)
                } else if (error.status === ERROR_CODES.INCORRECT_2FA_CODE) {
                    this.errorState(t('responseErrors:incorrectTwoFaCode'))
                } else if (error.status === ERROR_CODES.INCORRECT_2FA_RECOVERY_CODE) {
                    this.errorState(t('responseErrors:incorrectTwoFaRecoveryCode'))
                } else if (error.status === ERROR_CODES.SUSPENDED) {
                    this.errorState(t('responseErrors:userSuspended'))
                } else if (error.status === ERROR_CODES.UNTRUSTED_IP) {
                    this.errorState(t('responseErrors:assessDenied'))
                } else if (error.status === ERROR_CODES.PASSWORD_EXPIRED) {
                    history.push(`change-password-sign-in?email=${values.email || this.state.email}`)
                } else if (error.status === ERROR_CODES.BLOCKED) {
                    this.errorState(t('responseErrors:userBlocked'))
                } else {
                    this.errorState(t('responseErrors:invalidCredential'))
                }
            })
    }

    render() {
        const {t} = this.props
        const {error, loading} = this.state

        return (
            <SignIn
                account
                error={error}
                services={getPortalSignInOAuthServices(this.updateLinks(OAUTH_SERVICES_LINK.PORTAL))}
                title={t('sign:in:portal')}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                loading={loading}
            />
        )
    }
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            fetchSignIn,
            toggleTwoFA
        },
        dispatch
    )
})
export const SignInPage = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignInPageRaw))
