import React from 'react'
import {Modal} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'

import {getSFCTasks} from 'src/newcore/redux/modules/backups/backupCompare'

import {CompareSnapshotsForm} from './CompareSnapshotsForm'

const MODAL_WIDTH = 916

class CompareSnapshotsRaw extends React.Component {
    componentDidMount() {
        this.props.actions.getSFCTasks()
    }

    render() {
        const {visible, handleCancel} = this.props

        return (
            <Modal
                bodyStyle={{padding: 0}}
                open={visible}
                onCancel={handleCancel}
                width={MODAL_WIDTH}
                closable={false}
                destroyOnClose={true}
                footer={null}
            >
                <CompareSnapshotsForm handleCancel={handleCancel} />
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getSFCTasks
        },
        dispatch
    )
})

export const CompareSnapshots = connect(null, mapDispatchToProps)(CompareSnapshotsRaw)
