import React, {useRef, useState, useEffect} from 'react'
import {Input, Select, Form} from 'antd'
import {Trans, useTranslation} from 'react-i18next'
import {debounce} from 'lodash'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import SmartAlert from 'ca-common/icons/smartAlerts/SmartAlert.svg'
import CloseIcon from 'ca-common/icons/closeIcon.svg'

import {
    COLUMN_NAMES,
    UNIT_OPTIONS,
    EVENTS_OPTIONS,
    getSmartAlertTriggeredByFriendly,
    getObjectFriendly,
    getSmartAlertRuleFriendly,
    getSmartAlertUnitFriendly,
    getSmartAlertEventFriendly,
    UNIT_PERCENT,
    EVENTS_UPDATED,
    EVENTS_DELETED
} from 'src/newcore/features/SmartAlerts/lib'

import {
    StyledForm,
    StyledWrapper,
    StyledFooter,
    StyledButton,
    StyledSpan,
    StyledHeader,
    StyledIcon,
    StyledInputNumber,
    StyledWarningIcon,
    StyledFrame,
    StyledError,
    StyledErrorIcon
} from './StyledEditForm'

import {StyledWarning} from 'src/newcore/features/SmartAlertsWizard/organisms/AlertConfiguration/StyledAlertConfiguration'

const {Item: FormItem} = Form
const {Option} = Select

export const formatNumber = val => {
    if (!val) return 0
    return String(Number.parseInt(val, 10))
}

export const parserNumber = val => {
    if (!val) return 0

    return Number.parseInt(val, 10)
}

export const EditForm = ({
    handleSubmit,
    loading,
    closeModal,
    initialValues,
    warning,
    error: responseError,
    checkValues
}) => {
    const [form] = Form.useForm()
    const inputRef = useRef()
    const {t} = useTranslation()
    const [validateStatus, setValidateStatus] = useState(null)

    useEffect(() => {
        if (warning) setValidateStatus('warning')
    }, [warning])

    const validatePercentage = () => {
        const amount = form.getFieldValue(COLUMN_NAMES.TOTAL)
        const unit = form.getFieldValue(COLUMN_NAMES.UNIT)
        const event = form.getFieldValue(COLUMN_NAMES.EVENT)

        if (unit === UNIT_PERCENT && (event === EVENTS_UPDATED || event === EVENTS_DELETED) && amount > 100) {
            setValidateStatus('error')
            return Promise.reject(new Error(t('smartAlerts:wizard:ruleConfig:percentageError')))
        }
        setValidateStatus(null)
        return Promise.resolve()
    }

    const validateRequired = () => {
        const value = inputRef.current?.value
        if (value) {
            setValidateStatus(null)
            return Promise.resolve()
        }
        setValidateStatus('error')
        return Promise.reject(new Error(t('forms:common:validation:shortRequired')))
    }

    const validateIsInteger = () => {
        const value = inputRef.current?.value
        const pattern = new RegExp(/^\d+$/g)

        if (pattern.test(value)) {
            setValidateStatus(null)
            return Promise.resolve()
        }
        setValidateStatus('error')
        return Promise.reject(new Error(t('smartAlerts:wizard:error:integer')))
    }

    const initialData = {
        [COLUMN_NAMES.TOTAL]: initialValues[COLUMN_NAMES.TOTAL],
        [COLUMN_NAMES.UNIT]: initialValues[COLUMN_NAMES.UNIT],
        [COLUMN_NAMES.EVENT]: initialValues[COLUMN_NAMES.EVENT]
    }

    return (
        <StyledForm
            form={form}
            onFinish={e => handleSubmit(e, form)}
            initialValues={initialData}
            onValuesChange={debounce((changedValues, allValues) => {
                checkValues({...allValues, amount: allValues.total})
            }, 300)}
        >
            <StyledWrapper>
                <StyledFrame>
                    <StyledHeader>
                        <StyledIcon component={SmartAlert} />
                        {t('smartAlerts:edit:bellText')}
                    </StyledHeader>
                    <FormItem name={[COLUMN_NAMES.RULE]}>
                        <Input disabled placeholder={getSmartAlertRuleFriendly(initialValues[COLUMN_NAMES.RULE])} />
                    </FormItem>
                    <FormItem
                        hasFeedback
                        validateFirst={true}
                        validateTrigger={['onInput', 'onBlur', 'onFocus', 'onChange']}
                        validateStatus={validateStatus}
                        dependencies={[COLUMN_NAMES.UNIT, COLUMN_NAMES.EVENT]}
                        name={[COLUMN_NAMES.TOTAL]}
                        rules={[
                            {
                                validator: validateRequired
                            },
                            {
                                validator: validateIsInteger
                            },
                            {
                                validator: validatePercentage
                            }
                        ]}
                    >
                        <StyledInputNumber
                            min={1}
                            placeholder="0"
                            disabled={loading}
                            ref={inputRef}
                            stringMode={true}
                        />
                    </FormItem>
                    <FormItem
                        name={[COLUMN_NAMES.UNIT]}
                        rules={[
                            {
                                required: true,
                                message: t('forms:common:validation:shortRequired')
                            }
                        ]}
                    >
                        <Select disabled={loading} getPopupContainer={t => t.parentElement}>
                            {UNIT_OPTIONS.map(value => (
                                <Option value={value} key={value}>
                                    {getSmartAlertUnitFriendly(value)}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <StyledSpan>
                        {t('smartAlerts:edit:alert', {
                            alertLevel: getObjectFriendly(
                                initialValues[COLUMN_NAMES.ALERT_LEVEL],
                                initialValues?.[COLUMN_NAMES.SPECIFIC_OBJECT]
                            )
                        })}
                    </StyledSpan>
                    <FormItem
                        name={[COLUMN_NAMES.EVENT]}
                        rules={[
                            {
                                required: true,
                                message: t('forms:common:validation:shortRequired')
                            }
                        ]}
                    >
                        <Select disabled={loading} getPopupContainer={t => t.parentElement}>
                            {EVENTS_OPTIONS.map(value => (
                                <Option value={value} key={value}>
                                    {getSmartAlertEventFriendly(value)}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <StyledSpan>
                        {t('smartAlerts:edit:triggered', {
                            triggeredBy: getSmartAlertTriggeredByFriendly(initialValues[COLUMN_NAMES.TRIGGERED_BY])
                        })}
                    </StyledSpan>
                    {warning && (
                        <StyledWarning warning={warning}>
                            <StyledWarningIcon />
                            <Trans i18nKey={'smartAlerts:wizard:warning:wideCriteria'} />
                        </StyledWarning>
                    )}
                    {responseError && (
                        <StyledError>
                            <StyledErrorIcon component={CloseIcon} />
                            {responseError}
                        </StyledError>
                    )}
                </StyledFrame>
            </StyledWrapper>
            <StyledFooter>
                <StyledButton htmlType="reset" disabled={loading} onClick={closeModal} type="link">
                    {t('smartAlerts:edit:cancelButton')}
                </StyledButton>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={validateStatus === 'error' || responseError}
                >
                    {t('smartAlerts:edit:okButton')}
                </Button>
            </StyledFooter>
        </StyledForm>
    )
}
