import {post, postLessData} from 'src/newcore/utils/rest'
import {signOutLogout} from 'src/newcore/utils/logout'
import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit'
import {updateUserInfo as updateUserInfoOld} from 'ca-common/redux/modules/user'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch, AppState} from 'src/newcore/components/Root'
import {UserInfoDataType} from 'ca-common/types'

const USER_INFO = 'USER_INFO'
const userInfoWrapper = fetchWrapperRT(USER_INFO, async data => await post('getLoggedUser', data))

export const fetchUserInfo = userInfoWrapper.fetcher

const SignOut = fetchWrapperRT<{redirectUrl?: string}>('SIGN_OUT', async data => await postLessData('SignOut', data))

export const signOut = () => (dispatch: AppDispatch) =>
    dispatch(SignOut.fetcher({}))
        .unwrap()
        .then(response => {
            const redirectURL = response?.redirectUrl

            if (redirectURL) {
                window.location.href = redirectURL
            } else {
                signOutLogout()
            }
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.clearAll()
            }
            return response
        })

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: InitFetchedState as FetchedState<UserInfoDataType>,
    reducers: {
        clearUserInfo: () => InitFetchedState as FetchedState<UserInfoDataType>,
        updateUserInfo: (state, action) => {
            state.response = {
                ...state.response,
                ...action.payload
            }
        }
    },
    extraReducers: builder => userInfoWrapper.makeReducers(builder)
})

export const {clearUserInfo, updateUserInfo} = userInfoSlice.actions
export const userInfo = userInfoSlice.reducer

const HIDE_BILLS_STATE = 'HIDE_BILLS_STATE_URL'
const HIDE_BILLS_STATE_URL = 'hideBillsState'
export const hideBillsState = createAsyncThunk(HIDE_BILLS_STATE, async (_, {dispatch}) => {
    post(HIDE_BILLS_STATE_URL, {}).then(() => {
        dispatch(updateUserInfo({reminder: null}))
        dispatch(updateUserInfoOld({reminder: null}))
    })
})

const AccountWrapper = fetchWrapperRT('CHANGE_ACCOUNT_SETTINGS', async (data, {rejectWithValue}) => {
    try {
        return await post('changeSettings', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const setAccountSettings = AccountWrapper.fetcher

const CustomS3Credentials = fetchWrapperRT('SET_CUSTOMS3_CREDENTIALS', async (data, {rejectWithValue}) => {
    try {
        return await post('setCustomS3Credentials', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const setCustomS3Credentials = CustomS3Credentials.fetcher

const UnsubscribeWrapper = fetchWrapperRT('UNSUBSCRIBE', async data => await post('unsubscribe', data))
export const setUnsubscribe = UnsubscribeWrapper.fetcher

export const userInfoSelector = createSelector(
    (state: AppState) => state.userInfo,
    userInfo => userInfo.response
)

const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL'
const RESEND_CONFIRMATION_EMAIL_URL = 'resendConfirmChange'

const resendConfirmationEmailWrapper = fetchWrapperRT(RESEND_CONFIRMATION_EMAIL, async (_: any, {rejectWithValue}) => {
    try {
        return await post(RESEND_CONFIRMATION_EMAIL_URL, {})
    } catch (err: any) {
        return rejectWithValue(err.status)
    }
})

export const resendConfirmationEmail = resendConfirmationEmailWrapper.fetcher

const DISCARD_EMAIL_CHANGE = 'DISCARD_EMAIL_CHANGE'
const DISCARD_EMAIL_CHANGE_URL = 'discardChange'

const discardEmailChangeWrapper = fetchWrapperRT(DISCARD_EMAIL_CHANGE, post.bind(null, DISCARD_EMAIL_CHANGE_URL))

export const discardEmailChange = discardEmailChangeWrapper.fetcher

const GET_ACCOUNT_SETTINGS = 'GET_ACCOUNT_SETTINGS'
const GET_ACCOUNT_SETTINGS_URL = 'getAccountSettings'

const getAccountSettingsWrapper = fetchWrapperRT(GET_ACCOUNT_SETTINGS, post.bind(null, GET_ACCOUNT_SETTINGS_URL))

export const getAccountSettings = getAccountSettingsWrapper.fetcher

const accountSettingsSlice = createSlice({
    name: GET_ACCOUNT_SETTINGS,
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getAccountSettingsWrapper.makeReducers(builder)
})

export const accountSettings = accountSettingsSlice.reducer
