import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {DROPBOX_TYPE, SOURCE, TASK_STATUS} from 'ca-common/common/enum'
import {TagsColumn} from 'ca-common/ui-lib/molecules/TagsColumn'

import {EntitiesTaskStatus} from 'src/newcore/molecules/EntitiesTaskStatus'
import {BackupActions} from 'src/newcore/components/BackupActions'
import {LimitedAccess} from 'src/newcore/molecules/LimitedAccess'

import {LastBackup, Name} from '../CellModifier'
import {TAGS_WIDTH, TASK_STATUS_WIDTH, BACKUP_SIZE_WIDTH, LAST_BACKUP_WIDTH, ACTIONS_WIDTH} from '.'

export const dropbox = (source: SOURCE | DROPBOX_TYPE) =>
    [
        {
            title: getSvcAccountsDescription(source),
            dataIndex: 'name',
            render: (_: any, entity: {isDropboxTeamFolder: any; entity: string; name: string}) => (
                <Name name={entity.isDropboxTeamFolder ? entity.entity : entity.name} />
            ),
            onCell: () => ({'data-hj-suppress': true}),
            ellipsis: true,
            sorter: true
        },
        {
            title: i18n.t('table:columns:email'),
            dataIndex: 'entity',
            render: (_: any, entity: {isDropboxTeamFolder: any; entity: string}) => (
                <Name name={!entity.isDropboxTeamFolder ? entity.entity : ''} />
            ),
            ellipsis: true,
            sorter: true
        },
        source === SOURCE.DROPBOX && {
            title: i18n.t('table:columns:tags'),
            render: (entity: any) => (
                <>
                    <TagsColumn entity={entity} />
                    {entity.limitedAccess && <LimitedAccess isBackground={true} />}
                </>
            ),
            width: TAGS_WIDTH
        },
        {
            title: i18n.t('table:columns:status'),
            dataIndex: 'status',
            render: (_: any, entity: {current: any; state: any; status: TASK_STATUS}) => (
                <EntitiesTaskStatus
                    progress={{currentCount: entity?.current, backupState: entity?.state}}
                    status={entity.status}
                />
            ),
            width: TASK_STATUS_WIDTH,
            sorter: true
        },
        {
            title: i18n.t('table:columns:backupSize'),
            dataIndex: 'size',
            width: BACKUP_SIZE_WIDTH,
            sorter: true
        },
        {
            title: i18n.t('table:columns:lastBackup'),
            dataIndex: 'lastBackup',
            render: (_: any, entity: {lastBackup: any; lastBackupDate: number}) => <LastBackup entity={entity} />,
            width: LAST_BACKUP_WIDTH,
            sorter: true
        },
        {
            title: i18n.t('table:columns:backup'),
            render: (_: any, entity: {id: any; status: TASK_STATUS}) => (
                <BackupActions taskInfo={entity} performEntities={[entity.id]} source={SOURCE.DROPBOX} />
            ),
            width: ACTIONS_WIDTH
        },
        Table.EXPAND_COLUMN
    ].filter(Boolean)
