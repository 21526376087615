import React from 'react'
import {Title, Text} from './StyledBackupTaskSettingsTooltip'
import {Trans} from 'react-i18next'

export const BackupTaskSettingsTooltip = () => {
    return (
        <div>
            <Title>
                <Trans i18nKey="oneClickActivation:wizard:tooltips:backupTaskSettings:title" />
            </Title>
            <Text>
                <Trans i18nKey="oneClickActivation:wizard:tooltips:backupTaskSettings:text" />
            </Text>
        </div>
    )
}
