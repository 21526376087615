import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'

import type {BackupSettings, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {OCA_SERVICES} from 'src/newcore/features/OneClickActivation/lib/constants'

export const convertTaskSettingsToWizardData = (
    wizardData: OCAWizardData,
    formData: Record<string, any>
): OCAWizardData => {
    return Object.entries(formData).reduce((acc, [key, value]) => {
        const [service, newKey] = key.split('.') as [service: SOURCE, key: keyof BackupSettings]
        const settings = acc[service]?.settings

        if (OCA_SERVICES.includes(service) && settings) {
            const isForcedUndefinedValue =
                formData[`${service}.backupUnlicensed`] && newKey === 'disableUnlicensedOption'

            ;(settings[newKey] as BackupSettings[keyof BackupSettings]) = isForcedUndefinedValue ? undefined : value
        }

        return acc
    }, _.cloneDeep(wizardData))
}
