import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import map from 'lodash/map'

import {SOURCE} from 'ca-common/common/enum'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {MSTaskEntityType, TODO_ANY} from 'ca-common/types'

import {
    EntitiesWrapper,
    MainWrapper,
    ScrollWrapper,
    StyledLabel
} from 'src/newcore/features/RecoveryWizard/organisms/MailboxSelection/StyledMailboxSelection'
import {SearchField} from 'src/newcore/features/RecoveryWizard/atoms/SearchField'
import {RadioMailbox} from 'src/newcore/features/RecoveryWizard/atoms/RadioMailbox'
import {getBackupEntities} from 'src/newcore/features/RecoveryWizard/redux'
import {changeData, setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {useSearchedData} from 'src/newcore/features/RecoveryWizard/hooks/useSearchedData'

type MailboxSelectionProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export const MailboxSelectionRaw = ({
    taskId,
    actions,
    allEntities,
    status,
    wizardEntityId
}: MailboxSelectionProps): JSX.Element => {
    const {t} = useTranslation()
    const entitiesToRecover = reject(allEntities, ['lastBackupDate', 0])

    const [searchedData, setQuery] = useSearchedData(entitiesToRecover)

    useEffect(() => {
        actions.getBackupEntities({
            taskId,
            rows: 10000,
            page: 1,
            source: SOURCE.MS365
        })
    }, [])
    const setMailboxToRecover = (entity: MSTaskEntityType) => {
        actions.changeData({entity: entity})
        actions.setWizardDescription(entity.entity)
    }
    return (
        <MainWrapper>
            <StyledLabel>{t('recovery:wizard:mailboxSelection:label')}</StyledLabel>
            <SearchField setQuery={setQuery} />
            <EntitiesWrapper>
                {status === 'fulfilled' ? (
                    <ScrollWrapper>
                        {map(searchedData, entity => {
                            return (
                                <RadioMailbox
                                    wizardEntityId={wizardEntityId}
                                    setMailboxToRecover={setMailboxToRecover}
                                    key={entity.id}
                                    entity={entity}
                                />
                            )
                        })}
                    </ScrollWrapper>
                ) : (
                    <Spinner modifier="modal" />
                )}
            </EntitiesWrapper>
        </MainWrapper>
    )
}

const mapStateToProps = (state: any) => ({
    taskId: state.wizard.data.taskId,
    wizardEntityId: state.wizard.data.entity?.id,
    allEntities: state.backups.backupEntities.allEntities.response?.data,
    status: state.backups.backupEntities.allEntities.status
})

const mapDispatchToProps = (dispatch: TODO_ANY) => ({
    actions: bindActionCreators(
        {
            getBackupEntities,
            changeData,
            setWizardDescription
        },
        dispatch
    )
})

export const MailboxSelection = connect(mapStateToProps, mapDispatchToProps)(MailboxSelectionRaw)
