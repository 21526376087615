import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledJobFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    border-top: 1px solid ${variables.navyBorder};
    padding: ${variables.gutterSm} 0 0 0;
    @media screen and (min-width: ${variables.screenMd}) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const LogAndDownloadWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
