import React from 'react'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'

import {siteFormatter} from 'ca-common/utils/entity'

const CADrillDownItemRaw = ({item, match}) => (
    <Link to={`${match.url}/${item.id}`} title={item.name}>
        {siteFormatter(item.name)}
    </Link>
)

export const CADrillDownItem = withRouter(CADrillDownItemRaw)
