import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledStatusItem = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: ${variables.gutterXs};
    white-space: nowrap;
    min-width: 150px;
    font-size: ${variables.fontSizeBase};
`

export const Title = styled.span`
    font-weight: 300;
`

export const ValueWrapper = styled.div`
    font-weight: 400;
`
