import React from 'react'
import {Field} from 'redux-form'
import {withTranslation} from 'react-i18next'
import LabeledInput from 'ca-common/ui-lib/components/ReduxFormElements/LabeledInput'
import {ReactSelect} from 'ca-common/ui-lib/components/ReduxFormElements/ReactSelect'
import {getDataCenters, getFriendlyRegion} from 'ca-common/utils/Region'
import {requiredValidate} from 'ca-common/utils/validation'

@withTranslation('REGION')
export class CustomS3Form extends React.Component {
    constructor(props) {
        super(props)
        const activeRegions = [...getDataCenters().map(value => ({label: getFriendlyRegion(value), value}))]

        this.state = {
            archiveLocations: activeRegions
        }
    }

    render() {
        const {
            props: {allFieldsRequired, t},
            state: {archiveLocations}
        } = this

        const validation = allFieldsRequired ? [requiredValidate] : null

        return (
            <div>
                <Field
                    name="s3bucket"
                    type="text"
                    component={LabeledInput}
                    label={t('forms:customS3:fields:s3bucket:label')}
                    htmlFor="s3name"
                    validate={validation}
                />
                <Field
                    name="s3region"
                    component={ReactSelect}
                    isClearable={true}
                    placeholder={t('forms:customS3:fields:s3region:placeholder')}
                    options={archiveLocations}
                    label={t('forms:customS3:fields:s3region:label')}
                    htmlFor="s3region"
                    validate={validation}
                />
                <Field
                    name="s3key"
                    type="text"
                    component={LabeledInput}
                    label={t('forms:customS3:fields:s3key:label')}
                    htmlFor="s3key"
                    validate={validation}
                />
                <Field
                    name="s3secret"
                    type="password"
                    component={LabeledInput}
                    label={t('forms:customS3:fields:s3secret:label')}
                    htmlFor="s3secretkey"
                    validate={validation}
                />
            </div>
        )
    }
}
