import styled from 'styled-components'

export const StyledStatusDashboard = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
`
