import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledStatus = styled.div`
    display: flex;
`

export const StyledIcon = styled(Icon)`
    font-size: 24px;
    margin-right: ${variables.gutterXs};
`
