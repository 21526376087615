import React from 'react'
import {notification} from 'antd'
import i18n from 'i18next'

const DEFAULT_DURATION = 4.5
const HEADER_HEIGHT = 60
const MARGIN_FROM_HEADER = 16
const TOP_OFFSET = HEADER_HEIGHT + MARGIN_FROM_HEADER

export const openSuccessNotification = (
    description?: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:success'),
    duration = DEFAULT_DURATION
): void => {
    notification.success({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openErrorNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:error'),
    duration = DEFAULT_DURATION
): void => {
    notification.error({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openInfoNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:info'),
    duration = DEFAULT_DURATION
): void => {
    notification.info({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}

export const openWarningNotification = (
    description: React.ReactNode,
    title: React.ReactNode = i18n.t('modals:title:warning'),
    duration = DEFAULT_DURATION
): void => {
    notification.warning({
        message: title,
        description,
        duration,
        top: TOP_OFFSET
    })
}
