import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {SearchOutlined} from '@ant-design/icons'
import _ from 'lodash'

import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'
import {RoundedInput} from 'ca-common/ui-lib/components/FormItem'
import {SEEDING_OBJECT_TYPES} from 'ca-common/common/enum/seeding'

import {GroupListWrapper} from 'src/newcore/components/BulkActivation/StyledBulkActivation'
import {
    nextStep,
    closeWizard,
    setWizardDescription,
    previousStep,
    changeProgress,
    changeStep
} from 'src/newcore/redux/modules/wizard'

import {ObjectsList} from './ObjectList'
import {ContentWrapper, ListWrapper} from './Styled'
import {CHOOSE_OBJECT_TYPE_STEP, SELECT_DATA_OBJECT_STEP} from 'src/newcore/components/Seeding/seedingConstants'

export class MetadataThirdStepRaw extends React.Component {
    state = {
        search: ''
    }

    componentDidMount() {
        const {actions, wizard} = this.props

        if (wizard.objectType === SEEDING_OBJECT_TYPES.FULL) {
            actions.changeStep(CHOOSE_OBJECT_TYPE_STEP)
        }
    }

    onChange = e => {
        this.setState({
            search: e.target.value
        })
    }

    onNextClick = () => {
        const {actions} = this.props

        actions.changeProgress(SELECT_DATA_OBJECT_STEP)
        actions.nextStep()
    }

    render() {
        const {t, actions, objectsList, dataType} = this.props
        const anyChecked = _.some(objectsList)

        return (
            <Fragment>
                <GroupListWrapper>
                    <ContentWrapper>
                        <RoundedInput
                            prefix={<SearchOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                            onChange={this.onChange}
                            placeholder={t('seeding:thirdStep:objectsSearch')}
                        />
                        <ListWrapper>
                            <ObjectsList search={this.state.search} dataType={dataType} />
                        </ListWrapper>
                    </ContentWrapper>
                </GroupListWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={() => actions.closeWizard()}> {t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={this.onNextClick} disabled={!anyChecked}>
                            {t('forms:common:actions:next')}
                        </Button>
                    </LinkWrapper>
                    <BackLink onClick={() => actions.previousStep()} />
                </ModalFooter>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    objectsList: _.get(state, 'backups.seeding.sourceObjectsList.response.data', []),
    wizard: state.wizard.data,
    taskSettings: state.backups.settings.response,
    dataType: state?.wizard?.data?.dataType
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            previousStep,
            changeProgress,
            changeStep,
            setWizardDescription
        },
        dispatch
    )
})

export const MetadataThirdStep = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MetadataThirdStepRaw))
