import React from 'react'
import {Input, Form} from 'antd'

import {ValidateStatus} from '../ValidateStatus'
import {withTranslation} from 'react-i18next'

const SQLWhereClauseFormRaw = ({t, formValidateStatus, onPressEnter, value}) => (
    <Form.Item
        validateStatus={formValidateStatus.validateStatus}
        extra={<ValidateStatus formValidateStatus={formValidateStatus} />}
        help={t('seeding:fields:SQLWhereClause:help')}
    >
        <Input.TextArea
            placeholder={t('seeding:fields:SQLWhereClause:placeholder')}
            rows={6}
            defaultValue={value}
            onPressEnter={onPressEnter}
        />
    </Form.Item>
)

export const SQLWhereClauseForm = withTranslation()(SQLWhereClauseFormRaw)
