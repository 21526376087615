import _ from 'lodash'
import {createAction, createReducer} from '@reduxjs/toolkit'

const TOGGLE_ITEM = 'TOGGLE_ITEM'
const TOGGLE_SELECT_ALL_ITEMS = 'TOGGLE_SELECT_ALL_ITEMS'
const SET_SELECT_ALL_ITEMS = 'SET_SELECT_ALL_ITEMS'
const RESET_SELECTED_ITEMS = 'RESET_SELECTED_ITEMS'

export const toggleItem = createAction(TOGGLE_ITEM)
export const toggleSelectAllItems = createAction(TOGGLE_SELECT_ALL_ITEMS)
export const setSelectAllItems = createAction(SET_SELECT_ALL_ITEMS)
export const resetSelectedItems = createAction(RESET_SELECTED_ITEMS)

const toggleItemReducer = (state, action) => {
    const newItems = _.xorBy(state.items, [...action.payload], 'id')

    return {...state, items: newItems, areAllItemsSelected: false}
}

const toggleSelectAllItemsReducer = state => {
    return {
        ...state,
        items: [],
        areAllItemsSelected: !state.areAllItemsSelected
    }
}

const setSelectAllItemsReducer = (state, action) => {
    return {
        ...state,
        items: [],
        areAllItemsSelected: action.payload
    }
}

const resetSelectedItemsReducer = state => {
    return {
        ...state,
        items: [],
        areAllItemsSelected: false
    }
}

export const itemsReducer = createReducer(
    {areAllItemsSelected: false, items: []},
    {
        [TOGGLE_ITEM]: toggleItemReducer,
        [TOGGLE_SELECT_ALL_ITEMS]: toggleSelectAllItemsReducer,
        [SET_SELECT_ALL_ITEMS]: setSelectAllItemsReducer,
        [RESET_SELECTED_ITEMS]: resetSelectedItemsReducer
    }
)
