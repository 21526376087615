import React, {Component} from 'react'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import _ from 'lodash'

import {
    getEntitySubSources,
    clearEntitySubSources
} from 'src/newcore/redux/modules/backups/backupDrilldown/entitySubSources'
import {EntitySourceInfo} from 'src/newcore/components/EntitySourceInfo'

class EntitySubSourcesListRaw extends Component {
    static propTypes = {
        entityId: PropTypes.string.isRequired
    }

    componentDidMount() {
        const {
            actions,
            entityId,
            taskSettings: {taskId, source}
        } = this.props

        actions.getEntitySubSources({
            taskId,
            entityId,
            source
        })
    }

    componentWillUnmount() {
        const {actions} = this.props

        actions.clearEntitySubSources()
    }

    render() {
        const {entitySubSources} = this.props
        const sourceInfoItemList = _.get(entitySubSources, 'sourceInfoItemList', [])
        const indexEnabled = _.get(entitySubSources, 'indexEnabled')

        return (
            <Row gutter={16}>
                {_.map(sourceInfoItemList, entitySubSource => (
                    <Col key={entitySubSource.subsource} xs={{span: 24}} md={{span: 12}} xl={{span: 8}} xxl={{span: 6}}>
                        <EntitySourceInfo {...entitySubSource} indexEnabled={indexEnabled} />
                    </Col>
                ))}
            </Row>
        )
    }
}

const mapStateToProps = state => ({
    entitySubSources: {
        ...state.backups.backupDrilldown.entitySubSources.response,
        status: state.backups.backupDrilldown.entitySubSources.status
    },
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getEntitySubSources,
            clearEntitySubSources
        },
        dispatch
    )
})

export const EntitySubSourcesList = connect(mapStateToProps, mapDispatchToProps)(EntitySubSourcesListRaw)
