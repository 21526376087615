import styled from 'styled-components'

import {ModalCenterWrapper} from 'ca-common/components/Modal'

export const FirstStepModalWrapper = styled(ModalCenterWrapper)`
    justify-content: center;
    display: flex;
    flex-flow: column;

    height: 460px;
`
