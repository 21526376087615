import React from 'react'
import {Modal} from 'antd'
import {withTranslation} from 'react-i18next'
import _ from 'lodash'
import PropTypes from 'prop-types'

import {CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {ModalContent, ObjectLabel, ObjectsWrapper} from './StyledOverviewModal'

const MODAL_WIDTH = 620

@withTranslation('compareSnapshot')
export class OverviewModal extends React.Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.string).isRequired,
        handleCancel: PropTypes.func.isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        visible: PropTypes.bool.isRequired
    }

    render() {
        const {visible, handleCancel, t, data, title} = this.props

        return (
            <Modal
                bodyStyle={{padding: 0}}
                open={visible}
                onCancel={handleCancel}
                width={MODAL_WIDTH}
                closable={false}
                footer={null}
            >
                <ModalContent>
                    <h2>{title}</h2>
                    <ObjectsWrapper>
                        {_.map(data, el => (
                            <ObjectLabel key={el}>{el}</ObjectLabel>
                        ))}
                    </ObjectsWrapper>
                </ModalContent>

                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    </LinkWrapper>
                </ModalFooter>
            </Modal>
        )
    }
}
