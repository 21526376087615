import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledBody = styled.div`
    padding: ${variables.gutterLg} ${variables.gutterMd} 77px;
    border-top: 1px solid ${variables.blue};
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 65px;
`

export const StyledTitle = styled.div<{color?: 'red' | 'orange'}>`
    color: ${props => (props.color ? variables[props.color] : variables.text)};
    font-weight: 300;
    max-width: 80%;

    @media screen and (max-width: ${variables.screenMd}) {
        max-width: 100%;
    }
`

export const StyledSources = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterMd};
    width: 100%;
    max-width: 70%;

    & > div {
        margin-bottom: 0;
    }

    @media screen and (max-width: ${variables.screenMd}) {
        max-width: 100%;
    }
`

export const StyledEmail = styled.span`
    color: ${variables.navyBlue};
    text-decoration: underline;
`
