import {CompanyInfoType, PartnerInfo, UserInfoDataType} from 'ca-common/types'
import {isDevelopment} from 'ca-common/utils/runtime'
import {INTEGRATION_TYPE} from 'ca-common/constants'

interface PPProps {
    userInfo: PartnerInfo
}

interface PortalProps {
    userInfo: UserInfoDataType
    companyInfo: CompanyInfoType
}

type IMProps = PPProps | PortalProps

function isPortal(props: PortalProps | PPProps): props is PortalProps {
    return (props as PortalProps).userInfo.isFinra !== undefined
}

export const createInlineManualPlayer = (props: IMProps): void => {
    let retries = 0
    const {userInfo} = props
    const inlineManualTrackingConfig: Record<string, unknown> = {
        uid: userInfo.realId || userInfo.id
    }

    if (isPortal(props)) {
        inlineManualTrackingConfig.white_label = Number(props.companyInfo?.whiteLable)
    }

    if (isPortal(props)) {
        inlineManualTrackingConfig.zix_or_ot = Number(userInfo?.integrationType === INTEGRATION_TYPE.ZIX)
    }

    if (!isPortal(props)) {
        inlineManualTrackingConfig.partner_type = props.userInfo.partnerType
    }

    const timer = setInterval(() => {
        if (window.createInlineManualPlayer) {
            window.inlineManualTracking = inlineManualTrackingConfig
            window.createInlineManualPlayer(window.inlineManualPlayerData)

            if (isDevelopment) {
                console.log('INLINE PLAYER HAS BEEN CREATED')
            }

            clearInterval(timer)
        } else if (retries > 50) {
            clearInterval(timer)
        }
        retries++
    }, 500)
}
