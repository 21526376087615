import React, {Component, Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import Icon from '@ant-design/icons'
import {Affix, Empty, Tooltip} from 'antd'
import queryString from 'query-string'

import {
    StyledFileDiffItemList,
    FileDiffItemListCollapse,
    FileDiffItemListPanel,
    ListTitle,
    FileContentIcon,
    ArrowDownIcon,
    DownloadIcon
} from './StyledFileDiffItemList'

import {Header} from './Header'

import {GH_LIKE_VIEW} from 'ca-common/common/enum/CompareItem'

import DownloadAll from 'ca-common/icons/DownloadAll.svg'
import ArrowDown from 'ca-common/icons/ArrowDown.svg'
import FileContent from 'ca-common/icons/FileContent.svg'
import {CompareDiff} from '../../CompareResult/CompareDiffItem'

@withTranslation()
class FileDiffItemListRaw extends Component {
    state = {
        view: GH_LIKE_VIEW.SIDE
    }

    onListView = () => this.setState({view: GH_LIKE_VIEW.SIDE})

    onLineView = () => this.setState({view: GH_LIKE_VIEW.LINE})

    isActive = view => (view === this.state.view ? 1 : 0)

    downloadComparedFiles = fileNumber => {
        const {
            resultId,
            match: {
                params: {objectName, resultType}
            }
        } = this.props

        const link = queryString.stringify({
            resultId,
            object: objectName,
            compareType: resultType,
            fileNumber
        })

        setTimeout(() => {
            window.open(`/application/service/downloadCompare?${link}`, '_blank')
        }, 100)
    }

    downloadCompareFile = (e, id) => {
        e.stopPropagation()
        return this.downloadComparedFiles(id)
    }

    render() {
        const {t, data, resultId, match} = this.props
        const {view} = this.state

        return (
            <Fragment>
                {data.length ? (
                    <StyledFileDiffItemList>
                        <Affix offsetTop={0}>
                            <Header
                                recordCount={data.length}
                                onClickLineView={this.onLineView}
                                isActiveLineView={this.isActive(GH_LIKE_VIEW.LINE)}
                                onClickListView={this.onListView}
                                downloadComparedFiles={() => this.downloadComparedFiles()}
                                title={match.params.objectName}
                            />
                        </Affix>
                        <FileDiffItemListCollapse
                            expandIconPosition="right"
                            bordered={false}
                            expandIcon={({isActive}) => (
                                <ArrowDownIcon
                                    component={ArrowDown}
                                    active={isActive.toString()}
                                    rotate={isActive ? 180 : 0}
                                />
                            )}
                        >
                            {data.map((item, i) => (
                                <FileDiffItemListPanel
                                    header={
                                        <Fragment>
                                            <FileContentIcon component={FileContent} />
                                            <ListTitle>{item.name}</ListTitle>
                                            <DownloadIcon
                                                onClick={e => {
                                                    this.downloadCompareFile(e, item.id)
                                                }}
                                            >
                                                <Tooltip title={t(`backups:compare:tooltips:download`)}>
                                                    <Icon component={DownloadAll} />
                                                </Tooltip>
                                            </DownloadIcon>
                                        </Fragment>
                                    }
                                    key={i}
                                >
                                    <CompareDiff outputFormat={view} fileId={item.id} resultId={resultId} />
                                </FileDiffItemListPanel>
                            ))}
                        </FileDiffItemListCollapse>
                    </StyledFileDiffItemList>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Fragment>
        )
    }
}

export const FileDiffItemList = withRouter(FileDiffItemListRaw)
