import {createReducer, createAction} from '@reduxjs/toolkit'

import {post, postLessData} from 'src/newcore/utils/rest'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'
import {bulkActivateAccounts} from 'src/newcore/redux/modules/backups/bulkActivation/bulkActivateAccounts'
import _ from 'lodash'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'
import {AppDispatch} from 'src/newcore/components/Root'

const BULK_ACTIVATE = 'BULK_ACTIVATE'
const BULK_ACTIVATE_API = 'bulkActivateTasks'
const bulkActivateWrapper = fetchWrapperRT(BULK_ACTIVATE, async data => await post(BULK_ACTIVATE_API, data))

export const bulkActivate = bulkActivateWrapper.fetcher

const BULK_SAVE_RULES = 'BULK_SAVE_RULES'
const BULK_SAVE_RULES_API = 'createBulkActivationRule'
const bulkSaveRulesWrapper = fetchWrapperRT(BULK_SAVE_RULES, async data => await post(BULK_SAVE_RULES_API, data))

export const bulkSaveRules = bulkSaveRulesWrapper.fetcher

export const activateBulkFlowActions = async (
    {
        taskSettings,
        selectedGroups,
        isGoogleOu,
        createRule,
        cacheToken,
        excludes,
        selectedProperty,
        selectedPropertyValues,
        urlConditions
    }: TODO_ANY,
    dispatch: AppDispatch
) => {
    const bulkSaveActivationRules = () => {
        if (createRule) {
            if (selectedGroups) {
                //ms365 flow & Google OU FLow
                return dispatch(
                    bulkSaveRules({
                        cacheToken,
                        taskId: taskSettings.taskId,
                        groupItems: selectedGroups
                    })
                )
            }
            if (urlConditions) {
                //SP flow
                return dispatch(
                    bulkSaveRules({
                        cacheToken,
                        taskId: taskSettings.taskId,
                        urlConditions: urlConditions
                    })
                )
            }
            return dispatch(
                bulkSaveRules({
                    cacheToken,
                    taskId: taskSettings.taskId,
                    property: selectedProperty,
                    propertyValues: selectedPropertyValues
                })
            )
        }
        return createRule
    }

    const activateBulk = (rule: TODO_ANY) => {
        return dispatch(
            bulkActivateAccounts({
                cacheToken,
                taskId: taskSettings.taskId,
                excludes,
                rule
            })
        )
    }

    const {payload} = await bulkSaveActivationRules()

    return activateBulk(payload)
}

export const activateBulkFlow = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return new Promise((res, rej) => {
        activateBulkFlowActions(data, dispatch)
            .then(response => {
                if (data.createRule) {
                    dispatch(getBulkActivationRules({taskId: data.taskSettings.taskId}))
                    dispatch(getNewBackupTaskSettings({taskId: data.taskSettings.taskId}))
                }
                res(_.get(response, 'payload'))
            })
            .catch(err => rej(err))
    })
}

const GET_BULK_ACTIVATION_RULES = 'GET_BULK_ACTIVATION_RULES'
const GET_BULK_ACTIVATION_RULES_API = 'getBulkActivationRules'
const getBulkActivationRulesWrapper = fetchWrapperRT(
    GET_BULK_ACTIVATION_RULES,
    async data => await postLessData(GET_BULK_ACTIVATION_RULES_API, data)
)

export const getBulkActivationRules = getBulkActivationRulesWrapper.fetcher

const REMOVE_BULK_ACTIVATION_RULES = 'REMOVE_BULK_ACTIVATION_RULES'
const REMOVE_BULK_ACTIVATION_RULES_API = 'removeBulkActivationRules'
const removeBulkActivationRulesWrapper = fetchWrapperRT(
    REMOVE_BULK_ACTIVATION_RULES,
    async data => await post(REMOVE_BULK_ACTIVATION_RULES_API, data)
)

export const removeBulkActivationRules = removeBulkActivationRulesWrapper.fetcher
// export const bulkActivationRules = getBulkActivationRulesWrapper.getReducer()

const REMOVED_RULES_LIST = 'REMOVED_RULES_LIST'
export const addRulesToRemovedList = createAction<TODO_ANY>(REMOVED_RULES_LIST)

const CLEAR_REMOVED_RULES_LIST = 'CLEAR_REMOVED_RULES_LIST'

export const clearRemovedRulesList = createAction(CLEAR_REMOVED_RULES_LIST)

export const removedRulesList = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearRemovedRulesList, () => [])
    builder.addCase(addRulesToRemovedList, (state, action) => [...state, ...action.payload])
})
