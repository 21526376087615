import styled from 'styled-components'

import variables from 'ca-common/variables'
import {StyledFilterTag} from 'ca-common/ui-lib/atoms/FilterTag/StyledFilterTag'

export const FiltersBar = styled.div`
    background: ${variables.blueLight};
    width: 100%;
    padding: ${variables.gutterSm} ${variables.gutterSm} 0 ${variables.gutterSm};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ${StyledFilterTag} {
        margin-right: ${variables.gutterSm};
        margin-bottom: ${variables.gutterSm};
    }
`
