import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Wrapper = styled.div`
    min-width: 208px;
    background-color: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    display: flex;
    flex-direction: column;

    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
`

export const Header = styled.div`
    font-weight: 600;
    border-bottom: 1px solid ${variables.navyBorder};
    padding: ${variables.gutterXs} ${variables.gutterSm};
`

export const Content = styled.div`
    padding: ${variables.gutterSm};
    display: flex;
    flex-direction: column;
`
