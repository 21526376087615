import {Input, Select, Form} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const RoundedInput = styled(Input)`
    border-radius: ${variables.borderRadiusBase};

    &[disabled] {
        background-color: ${variables.disabled};
        border-color: ${variables.navyBorder};
        color: ${variables.navyAsphalt};
    }
`

export const RoundedSelect = styled(Select)`
    .ant-select-selection {
        border-radius: ${variables.borderRadiusBase};
    }
`

export const StyledLabel = styled.label`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;

    display: flex;
    align-items: center;
    margin-bottom: ${variables.gutterXs};
    color: ${variables.text};
`

export const StyledForm = styled(Form)`
    width: 100%;
    margin-bottom: ${variables.gutterXxl};
`

export const StyledInputWrapper = styled.div`
    display: flex;
    gap: 16px;
    > * {
        flex-grow: 1;
    }
`
