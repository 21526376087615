import React from 'react'

import {SeparatorWrapper, TextSeparator, StyledLineSeparator} from './StyledLineSeparator'

export const LineSeparator = ({text}: {text: string}): JSX.Element => {
    return (
        <SeparatorWrapper>
            <StyledLineSeparator />
            <TextSeparator>{text}</TextSeparator>
        </SeparatorWrapper>
    )
}
