import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {DATE_WITH_AT_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {StyledFromSpan} from 'src/newcore/features/Recovery/molecules/RecoveryItemsTable'

export const emailsColumns = [
    {
        title: i18n.t('table:columns:from'),
        dataIndex: 'from',
        key: 'emailFrom',
        width: '20%',
        ellipsis: true,
        render: (text: string) => <StyledFromSpan data-hj-suppress>{text}</StyledFromSpan>
    },
    {
        title: i18n.t('table:columns:subject'),
        dataIndex: 'subject',
        key: 'emailSubject',
        width: '55%',
        render: (text: string) => {
            return {
                children: text,
                props: {
                    'data-hj-suppress': true
                }
            }
        }
    },
    {
        title: i18n.t('table:columns:date'),
        dataIndex: 'sentDate',
        key: 'emailSentDate',
        width: '20%',
        sorter: true,
        render: (text: string): string => getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT)
    },
    Table.EXPAND_COLUMN
]
