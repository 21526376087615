import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import _ from 'lodash'

import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'
import {SEEDING_OBJECT_TYPES} from 'ca-common/common/enum/seeding'

import {nextStep, closeWizard, setWizardDescription, previousStep, changeStep} from 'src/newcore/redux/modules/wizard'
import {CenteredSpinner, ContentWrapper, Hint} from './Styled'
import {ObjectsFilteringList} from './ObjectsFilteringList'
import {ObjectsFilteringForm} from './ObjectsFilteringForm'

import {CHOOSE_OBJECT_TYPE_STEP} from 'src/newcore/components/Seeding/seedingConstants'

export class SeedingWizardFourthStepRaw extends React.Component {
    componentDidMount() {
        const {t, actions, wizard} = this.props

        if (wizard.data.objectType === SEEDING_OBJECT_TYPES.FULL) {
            actions.changeStep(CHOOSE_OBJECT_TYPE_STEP)
        }
        actions.setWizardDescription(t('seeding:fourthStep:description'))
    }

    render() {
        const {t, actions, checkedObjects} = this.props
        const anyChecked = !_.every(_.get(checkedObjects, 'response.data'), 'isDeleted')

        return (
            <Fragment>
                <Hint>{t('seeding:fourthStep:hint')}</Hint>
                <ContentWrapper>
                    {checkedObjects.status === 'fulfilled' ? (
                        <Fragment>
                            <ObjectsFilteringList />
                            <ObjectsFilteringForm />
                        </Fragment>
                    ) : (
                        <CenteredSpinner />
                    )}
                </ContentWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={() => actions.closeWizard()}> {t('modals:buttons:cancel')}</CancelLink>
                        <Button type="primary" onClick={() => actions.nextStep()} disabled={!anyChecked}>
                            {t('forms:common:actions:next')}
                        </Button>
                    </LinkWrapper>
                    <BackLink onClick={() => actions.previousStep()} />
                </ModalFooter>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    checkedObjects: state.backups.seeding.objectsList,
    wizard: state.wizard
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            previousStep,
            changeStep,
            setWizardDescription
        },
        dispatch
    )
})

export const SeedingWizardFourthStep = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(SeedingWizardFourthStepRaw))
