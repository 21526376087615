import styled from 'styled-components'
import variables from 'ca-common/variables'

export const AccountListWrapper = styled.div`
    width: 100%;
    padding: ${variables.gutterMd};
    padding-top: 0;
    height: 100%;
    overflow: auto;
`

export const AccountHeader = styled.div`
    display: flex;
    border-bottom: 1px solid ${variables.navyBorder};
    gap: ${variables.gutterXs};
`

export const StyledHeaderCell = styled.div`
    flex-basis: ${props => props.width || '205px'};
    flex-grow: 1;
    padding: ${variables.gutterXs};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    line-height: 17px;
`

export const AccountWrapper = styled.div`
    display: flex;
    cursor: pointer;
    margin: ${variables.gutterXs} 0;

    & > div {
        margin: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const AccountCheckbox = styled.div`
    width: 24px;
`

export const AccountName = styled.div`
    width: 165px;
    flex-grow: 1;
`

export const AccountEmail = styled.div`
    width: 205px;
    flex-grow: 1;
`

export const AccountSite = styled.div`
    width: 255px;
    flex-grow: 1;
`

export const AccountGroups = styled.div`
    width: 180px;
    flex-grow: 1;
`
