export const RESTORE_OPTION = {
    NON_DESTRUCTIVE: 'NON_DESTRUCTIVE',
    IN_PLACE: 'IN_PLACE'
}

export const IN_PLACE_RESTORE_OPTION = {
    REPLACE: 'REPLACE',
    BYPASS: 'BYPASS',
    DUPLICATE: 'DUPLICATE'
}

export enum RESTORE_OPTIONS {
    ADD_RESTORE_TAG = 'ADD_RESTORE_TAG',
    ADD_RESTORE_FOLDER_PREFIX = 'ADD_RESTORE_FOLDER_PREFIX',
    CREATE_SNAPSHOT = 'CREATE_SNAPSHOT'
}

export enum RESTORE_TYPE {
    NON_DESTRUCTIVE = 'NON_DESTRUCTIVE',
    REPLACE = 'REPLACE',
    BYPASS = 'BYPASS'
}
