import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledSecurityAuditTableWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${variables.gutterMd} ${variables.gutterMd} 0px;
    background-color: ${variables.white};

    .ant-form-item {
        margin-right: 20px;
    }

    .ca-btn--secondary {
        margin-top: 4px;
    }
`
