import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledLabel = styled.p`
    margin: 0 0 12px ${variables.gutterMd};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};

    color: ${variables.brandDark};
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${variables.gutterMd} auto 125px auto;
`
export const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
