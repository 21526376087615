import {screenDesktop} from 'ca-common/variables'

const ISSUE_DATE_WIDTH = '15.9%'
const STATUS_WIDTH = '15.3%'
const DUE_DATE_WIDTH = '11%'
const DUE_DATE_WIDTH_SMALL_SCREEN = '11.2%'
const EFFECTIVE_DATE_WIDTH = '11%'
const TOTAL_AMOUNT_WIDTH = '11%'
const DOWNLOAD_INVOICE_WIDTH = '26.9%'
const DOWNLOAD_INVOICE_WIDTH_SMALL_SCREEN = '21.2%'
const EMAIL_INVOICE_WIDTH = '11.5%'
const EMAIL_INVOICE_WIDTH_SMALL_SCREEN = '12.1%'
const SERVICE_WIDTH = '17.9%'
const TOTAL_QUANTITY_WIDTH = '21.3%'
const BILLABLE_QUANTITY_WIDTH = '18.2%'
const UNIT_PRICE_WIDTH = '11.9%'
const EMPTY_FIELD_WIDTH = '0.01%' // 0% works wrong

export const getColumnsWidth = () => {
    const isLgScreen = window.innerWidth > Number(screenDesktop.replace(/\D/g, ''))

    return {
        issueDateWidth: ISSUE_DATE_WIDTH,
        statusWidth: STATUS_WIDTH,
        dueDateWidth: isLgScreen ? DUE_DATE_WIDTH : DUE_DATE_WIDTH_SMALL_SCREEN,
        effectiveDateWidth: EFFECTIVE_DATE_WIDTH,
        totalAmountWidth: TOTAL_AMOUNT_WIDTH,
        downloadInvoiceWidth: isLgScreen ? DOWNLOAD_INVOICE_WIDTH : DOWNLOAD_INVOICE_WIDTH_SMALL_SCREEN,
        emailInvoiceWidth: isLgScreen ? EMAIL_INVOICE_WIDTH : EMAIL_INVOICE_WIDTH_SMALL_SCREEN,
        serviceWidth: SERVICE_WIDTH,
        totalQuantityWidth: TOTAL_QUANTITY_WIDTH,
        billableQuantityWidth: BILLABLE_QUANTITY_WIDTH,
        unitPriceWidth: UNIT_PRICE_WIDTH,
        emptyFieldWidth: isLgScreen ? undefined : EMPTY_FIELD_WIDTH
    }
}
