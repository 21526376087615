import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const GET_ACTIVE_ENTITIES = 'GET_ACTIVE_ENTITIES'
const GET_ACTIVE_ENTITIES_API = 'getActiveEntities'

const getActiveEntitiesWrapper = fetchWrapperRT(
    GET_ACTIVE_ENTITIES,
    async data => await post(GET_ACTIVE_ENTITIES_API, data)
)
export const getActiveEntities = getActiveEntitiesWrapper.fetcher

const activeEntitiesSlice = createSlice({
    name: 'activeEntities',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getActiveEntitiesWrapper.makeReducers(builder)
})
export const activeEntitiesReducer = activeEntitiesSlice.reducer
