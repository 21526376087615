import React from 'react'
import {StyledRangePicker} from './StyledRangePicker'
import Icon from '@ant-design/icons'

import type {RangePickerProps} from 'antd/lib/date-picker'
import Calendar from 'ca-common/icons/Calendar.svg'

export const RangePicker = React.forwardRef(({format, separator, ...props}: RangePickerProps, ref) => (
    <StyledRangePicker
        suffixIcon={<Icon component={Calendar} />}
        separator={separator || '-'}
        format={format || 'DD MMM YYYY'}
        {...props}
        {...ref}
    />
))
