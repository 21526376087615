import {useState, useEffect, useCallback} from 'react'
import {useHistory} from 'react-router'

import {PAGES} from 'ca-common/constants'

export const useExitModal = (isBlockNeeded?: boolean) => {
    const [showExitModal, setShowExitModal] = useState(false)
    const [onOk, setOnOk] = useState<() => void>()
    const history = useHistory()

    const onCancel = useCallback(() => {
        setShowExitModal(false)
    }, [])

    useEffect(() => {
        const unblock = history.block(({pathname, search, hash}) => {
            const newAppGoalPath = pathname + (search || '')
            let oldAppGoalPath = ''

            if (newAppGoalPath === `/${PAGES.RESTORE}`) {
                oldAppGoalPath = '/App/restore'
            }

            if (newAppGoalPath.includes('/redirect?to=')) {
                oldAppGoalPath = newAppGoalPath.split('/redirect?to=')[1] + hash
            }

            const redirect = () => {
                if (oldAppGoalPath) {
                    window.location.href = oldAppGoalPath
                } else {
                    history.push(newAppGoalPath)
                }
            }

            if (!window.location.href.includes(history.location.pathname) || window.location.href.includes(pathname)) {
                unblock()
                return
            }

            if (isBlockNeeded) {
                setShowExitModal(true)
                setOnOk(() => {
                    return () => {
                        setShowExitModal(false)
                        unblock()
                        redirect()
                    }
                })

                return false
            } else {
                unblock()
                redirect()

                if (oldAppGoalPath) {
                    return false
                }
            }
        })

        return unblock
    }, [isBlockNeeded])

    return {
        show: showExitModal,
        setShow: setShowExitModal,
        onOk,
        onCancel
    }
}
