import i18n from 'i18next'

import {SOURCE, SOURCE_INFO} from 'ca-common/common/enum/Source'
import {UNSUPPORTED_SERVICES} from 'ca-common/constants'
import {getServiceSvgIcon} from 'images'
import {permittedWhiteList, permittedServiceList} from './permissions'
import {TODO_ANY} from 'ca-common/types'
import {DROPBOX_TYPE} from 'ca-common/common/enum'

export const hasEntity = (source: SOURCE): boolean => !!SOURCE[source] && SOURCE_INFO[source].hasSubAccounts

export const getServiceIcon = (source: TODO_ANY): string =>
    SOURCE_INFO[source] && SOURCE_INFO[source].icon && getServiceSvgIcon(SOURCE_INFO[source].icon)

export const shouldShowIndex = (source: TODO_ANY): boolean => SOURCE_INFO[source] && SOURCE_INFO[source].indexed

export const isSupportedService = (source: TODO_ANY): boolean => {
    return !Object.keys(UNSUPPORTED_SERVICES).some(service => SOURCE[UNSUPPORTED_SERVICES[service]] === source)
}

/**
 * @returns {Boolean} is source allowed for these permissions
 * @param source - Source from SOURCE_INFO
 * @param permissions - UserInfo's permissions
 */
export const isAllowedWhiteList = (source: TODO_ANY, permissions: TODO_ANY): boolean => {
    const permittedWhitelist = permittedWhiteList(permissions)
    return permittedWhitelist.includes(source)
}

export const sortSourceInfoByCost = (left: TODO_ANY, right: TODO_ANY): number => {
    return SOURCE_INFO[left].cost - SOURCE_INFO[right].cost
}

export const sortSourcesByCost = (left: TODO_ANY, right: TODO_ANY): number => {
    return SOURCE_INFO[left.source].cost - SOURCE_INFO[right.source].cost
}

export const getDisplayName = (source: SOURCE): string => {
    return SOURCE[source] && SOURCE_INFO[source].displayName
}

export const hasSubSource = (source: SOURCE): boolean => {
    return [SOURCE.MS365, SOURCE.MS365GROUPS, SOURCE.GACCOUNTS, SOURCE.GOOGLEAPPS].some(s => s === source)
}

export const getAllowedServices = (permissions: TODO_ANY): TODO_ANY => {
    return Object.keys(SOURCE_INFO)
        .filter(source => isAllowedWhiteList(source, permissions))
        .filter(isSupportedService)
        .sort(sortSourceInfoByCost)
}

/**
 * @returns {Array<String>} array of allowed for these permissions sources
 *  (including blacklisted but existing in user's backup list)
 * @param permissions - UserInfo's permissions
 * @param sources - raw list of sources
 */
export const getAccessableServices = (permissions: TODO_ANY, sources = Object.keys(SOURCE_INFO)): string[] => {
    return permittedServiceList(sources, permissions).filter(isSupportedService).sort(sortSourceInfoByCost)
}

type serviceName = {
    source: string
    short?: boolean
    isDropboxProfessionalAccount?: boolean
    isDropboxShort?: boolean
}
export const getServiceName = ({
    source,
    short = false,
    isDropboxProfessionalAccount = false,
    isDropboxShort
}: serviceName): string => {
    let key = source
    if (source === SOURCE.DROPBOX) {
        if (isDropboxShort) {
            key = SOURCE.DROPBOX
        } else if (isDropboxProfessionalAccount) {
            key = DROPBOX_TYPE.DROPBOX_PROFESSIONAL
        } else {
            key = DROPBOX_TYPE.DROPBOX_BUSINESS
        }
    }

    if (short) {
        return i18n.t(`serviceNames:${key}`)
    }

    return i18n.t([`serviceNames:${key}_FULL`, `serviceNames:${key}`])
}
