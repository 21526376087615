import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {PAGES} from 'ca-common/constants'
import {AddNewButton} from 'ca-common/ui-lib/atoms/AddNewButton'

export const AddNew = (): JSX.Element => {
    const {t} = useTranslation()
    return (
        <Link to={`${PAGES.BACKUPS}/new`}>
            <AddNewButton text={t(`homepage:addNewTitle`)} />
        </Link>
    )
}
