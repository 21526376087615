import React from 'react'
import Folder from 'ca-common/icons/Folder.svg'
import type {AntdTreeNodeAttribute} from 'antd/es/tree'
import {StyledIcon} from './StyledTreeNode'

export type FolderTreeNodeProps = Omit<AntdTreeNodeAttribute, 'children'> & {
    accent: boolean
    noIcon?: boolean
    data?: {children: Array<any>}
}

export const FolderTreeIcon = ({accent, checked, halfChecked, noIcon, data}: FolderTreeNodeProps) => {
    return noIcon && !data?.children ? (
        <></>
    ) : (
        <StyledIcon component={Folder} accent={accent} checked={checked} halfChecked={halfChecked} />
    )
}
