import {
    ActivateIpRules,
    CreateIpRules,
    RemoveIpRules,
    UpdateIpRules,
    RestrictionEntry
} from 'ca-common/features/IpRestriction/types'
import {post, postLessData} from 'src/newcore/utils/rest'
import {
    createRuleLocal,
    modifyRuleLocal,
    removeRuleLocal,
    selectIpRules,
    setActivateLocal
} from 'src/newcore/features/IpRestriction/redux'
import {errorPreprocessor} from 'src/newcore/features/IpRestriction/utils'

export {getIpRules} from 'src/newcore/features/IpRestriction/getIpRules'
export {selectIpRules}

const ACTIVATE_IP_RULE_API = 'activateIpRules'

export const activateIpRules: ActivateIpRules = data => dispatch =>
    post(ACTIVATE_IP_RULE_API, data)
        .then((response: any) => {
            dispatch(setActivateLocal(data.activate))

            return Promise.resolve(response)
        })
        .catch(errorPreprocessor)

const CREATE_IP_RULE_API = 'createIpRule'

export const createIpRules: CreateIpRules = data => async dispatch => {
    return postLessData(CREATE_IP_RULE_API, data)
        .then((response: {success?: boolean; data?: any}) => {
            if (response?.success && response?.data) {
                dispatch(createRuleLocal(response.data as RestrictionEntry))
            }
        })
        .catch(errorPreprocessor)
}

const REMOVE_IP_RULE_API = 'deleteIpRule'

export const removeIpRules: RemoveIpRules = data => async dispatch => {
    const {options, ...passedData} = data

    return post(REMOVE_IP_RULE_API, {...passedData, id: options.id})
        .then(() => {
            dispatch(removeRuleLocal(data.options.id))
        })
        .catch(errorPreprocessor)
}

const UPDATE_IP_RULE_API = 'updateIpRule'

export const updateIpRules: UpdateIpRules = data => async dispatch => {
    const {options, ...passedData} = data

    return postLessData(UPDATE_IP_RULE_API, {...passedData, id: options.id})
        .then((response: {success?: boolean; data?: any}) => {
            if (response?.success && response?.data) {
                dispatch(modifyRuleLocal(response.data as RestrictionEntry))
            }
        })
        .catch(errorPreprocessor)
}
