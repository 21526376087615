import React from 'react'

import {SYSTEM_AUDIT_ACTIVITY, ActivityRecord} from 'ca-common/features/ActivityInfinityTable'
import {
    ExportPopoverContent,
    DefaultPopoverContent,
    RestorePopoverContent,
    ComparePopoverContent
} from 'src/newcore/features/ActivityTable/templates/popovers'

export const PopoverContent = ({
    activity,
    description,
    date,
    source,
    activityStatus,
    downloadPermitted,
    isExpired
}: ActivityRecord): JSX.Element => {
    switch (activity) {
        case SYSTEM_AUDIT_ACTIVITY.EXPORT:
            return <ExportPopoverContent description={description} date={date} />
        case SYSTEM_AUDIT_ACTIVITY.RESTORE:
            return <RestorePopoverContent activityStatus={activityStatus} source={source} description={description} />
        case SYSTEM_AUDIT_ACTIVITY.COMPARE:
            return downloadPermitted && !isExpired ? (
                <ComparePopoverContent description={description} />
            ) : (
                <DefaultPopoverContent date={date} description={description} />
            )
        default:
            return <DefaultPopoverContent date={date} description={description} />
    }
}
