import React, {useState, useEffect} from 'react'
import {matchPath, useLocation} from 'react-router'

export function withTitle(WrappedComponent, routeConfig) {
    return function (props) {
        const [title, setTitle] = useState(null)
        const location = useLocation()

        function checkTitle(pathname) {
            setTitle(null)

            routeConfig.forEach(route => {
                const isRouteMatched = matchPath(pathname, {
                    path: route.path,
                    exact: true
                })

                if (isRouteMatched) {
                    setTitle(route.title)
                }
            })
        }

        useEffect(() => {
            checkTitle(location.pathname)
        })

        return <WrappedComponent title={title} {...props} />
    }
}
