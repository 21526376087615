import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {values} from 'lodash'

import {
    changeData,
    closeWizard,
    nextStep,
    setWizardDescription,
    setWizardTitle,
    previousStep
} from 'src/newcore/redux/modules/wizard'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {Button} from 'ca-common/ui-lib/atoms'

import {TRIGGERED_BY_SINGLE} from 'src/newcore/features/SmartAlerts/lib/constants'

import {AlertConfiguration} from '../organisms/AlertConfiguration'

const SmartAlertsSecondStepRaw = ({actions, specificObject, currentStep}) => {
    const {t} = useTranslation()
    const [config, setConfig] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [triggeredBy, setTriggeredBy] = useState(TRIGGERED_BY_SINGLE)
    const [step] = useState(currentStep)

    const checkConfig = useCallback(
        config => !config || values(config).includes(undefined) || values(config).includes(null),
        [config]
    )

    const notCompleted = useMemo(() => checkConfig(config) || error || !triggeredBy, [config, error, triggeredBy])

    const stepTitle = useMemo(
        () =>
            specificObject
                ? t('smartAlerts:wizard:secondStepSpecificObjectTitle', {specificObject})
                : t('smartAlerts:wizard:secondStepAllObjectsTitle'),
        [specificObject]
    )

    const handleCancel = () => actions.closeWizard()

    const handleBack = () => {
        actions.previousStep()
        setConfig(undefined)
        setTriggeredBy(TRIGGERED_BY_SINGLE)
        setError(undefined)
    }
    const handleNext = () => {
        actions.changeData({config, triggeredBy})
        actions.nextStep()
    }

    useEffect(() => {
        if (step === currentStep) {
            actions.setWizardDescription([t('smartAlerts:wizard:secondStepDescription')])
            actions.setWizardTitle(stepTitle)
        }
    }, [currentStep])

    return (
        <>
            <AlertConfiguration
                error={error}
                config={config}
                setConfig={setConfig}
                triggeredBy={triggeredBy}
                changeTrigger={setTriggeredBy}
                setError={setError}
                specificObject={specificObject}
                currentStep={currentStep}
            />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={handleNext} disabled={notCompleted}>
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={handleBack} />
            </ModalFooter>
        </>
    )
}

const mapStateToProps = state => ({
    specificObject: state.wizard.data.specificObject,
    currentStep: state.wizard.step
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            previousStep,
            closeWizard,
            changeData,
            setWizardTitle,
            setWizardDescription
        },
        dispatch
    )
})

export const SASecondStep = connect(mapStateToProps, mapDispatchToProps)(SmartAlertsSecondStepRaw)
