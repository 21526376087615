import React from 'react'
import {Trans} from 'react-i18next'

import SA_FR_2023 from 'ca-common/icons/medals/01_SA_FR_2023.svg'
import C_SL_2023 from 'ca-common/icons/medals/02_C_SL_2023.svg'
import HPW_2023 from 'ca-common/icons/medals/03_HPW_2023.svg'
import SF_CLU_2023 from 'ca-common/icons/medals/04_SF_CLU_2023.svg'
import GA_CL_2023 from 'ca-common/icons/medals/05_GA_CL_2023.svg'

import {BADGES} from 'src/newcore/features/SignUp/lib'

import {StyledAwards, StyledMedal, StyledMedals, StyledText, StyledColumn, StyledTitle} from './StyledAwards'

export const Awards = () => {
    return (
        <StyledAwards>
            <StyledColumn>
                <StyledTitle>
                    <Trans i18nKey="sign:up:titles:second">
                        <span>text</span>
                    </Trans>
                </StyledTitle>
                <StyledText>
                    <Trans i18nKey="sign:up:customersTitle">
                        <span>text</span>
                    </Trans>
                </StyledText>
            </StyledColumn>
            <StyledColumn>
                <StyledMedals>
                    <StyledMedal medalType={BADGES.C_SL_2023} component={C_SL_2023} />
                    <StyledMedal medalType={BADGES.SA_FR_2023} component={SA_FR_2023} />
                    <StyledMedal medalType={BADGES.HPW_2023} component={HPW_2023} />
                    <StyledMedal medalType={BADGES.GA_CL_2023} component={GA_CL_2023} />
                    <StyledMedal medalType={BADGES.SF_CLU_2023} component={SF_CLU_2023} />
                </StyledMedals>
            </StyledColumn>
        </StyledAwards>
    )
}
