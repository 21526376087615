import React from 'react'
import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {StyledThroughput, ThroughputInputNumber} from './StyledThroughput'

export const Throughput = props => {
    const {t} = useTranslation()
    const {throughput} = props

    return (
        <StyledThroughput
            label={t('forms:backupSettings:fields:throughput:label')}
            colon={false}
            name="throughput"
            initialValue={throughput}
        >
            <ThroughputInputNumber min={0} max={1} step={0.05} />

            <Tooltip title={t('forms:backupSettings:fields:throughput:tooltip')}>
                <Icon component={Info} />
            </Tooltip>
        </StyledThroughput>
    )
}

export const ThroughputSFC = props => {
    const {t} = useTranslation()
    const {throughput} = props

    return (
        <StyledThroughput
            label={t('forms:backupSettings:fields:throughput:tooltip')}
            colon={false}
            name="throughput"
            initialValue={throughput}
        >
            <ThroughputInputNumber
                min={0}
                max={1}
                step={0.05}
                formatter={value => `${Math.floor(value * 100)}%`}
                parser={value => Number(value.replace('%', '') / 100).toFixed(2)}
            />
        </StyledThroughput>
    )
}
