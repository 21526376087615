import React from 'react'

import {InfinityScroller} from 'ca-common/features/InfinityScroller'
import {SORD_ORDER} from 'ca-common/common/enum/SordOrder'

import {SORT_NAMES} from 'src/newcore/features/Objects/libs'
import {DrillDown as ExpandIcon} from 'src/newcore/components/BackupEntities/CellModifier/DrillDown'

import {StyledTable} from './StyledObjectsTable'
import {columns} from './ObjectsTableConfig'
import {ObjectHistory} from 'src/newcore/features/ObjectHistory'

export const ObjectsTable = props => {
    const {
        sfcObjects,
        changeURLSearchParams,
        fetchOnScroll,
        loading,
        expandedRowKeys,
        setExpandedRowKeys,
        taskId
    } = props

    const manualSortingHandler = (pagination, filters, sorter) => {
        const order = SORD_ORDER[sorter.order]
        const sortBy = order && SORT_NAMES[sorter.field]

        changeURLSearchParams({order, sortBy})
    }

    const onRowHandler = record => expandedRowKeys.includes(record.id) && {className: 'expand-parent'}

    return (
        <InfinityScroller fetchOnScroll={fetchOnScroll}>
            <StyledTable
                pagination={false}
                dataSource={sfcObjects}
                columns={columns}
                rowKey={record => record.id}
                onChange={manualSortingHandler}
                loading={loading}
                onRow={onRowHandler}
                expandIconAsCell={false}
                expandIcon={expandIconProps => <ExpandIcon {...expandIconProps} onClickHandler={setExpandedRowKeys} />}
                expandedRowKeys={expandedRowKeys}
                expandedRowRender={(record, index, indent, expanded) => {
                    return expanded && <ObjectHistory objectName={record?.name} taskId={taskId} />
                }}
                showSorterTooltip={false}
            />
        </InfinityScroller>
    )
}
