import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import Icon from '@ant-design/icons'

import CloseModal from 'ca-common/icons/old/uEA10-close-modal.svg'
import AddScreanshot from 'ca-common/icons/old/uEA69-add-screanshot.svg'

import './FileInput.scss'

const dumbFormGroup = dumbBem('ca-form-group')
const FormGroup = tx(dumbFormGroup)('div')
const Error = tx([{element: 'error'}, dumbFormGroup])('span')

export default class FileInput extends Component {
    static propTypes = {
        icon: PropTypes.func.string,
        title: PropTypes.func.string
    }

    static defaultProps = {
        icon: AddScreanshot,
        title: 'Add new file'
    }

    constructor(props) {
        super(props)
        this.state = {files: []}
    }

    onDrop = files => {
        const {input} = this.props
        this.setState({files})
        return input.onChange(files)
    }

    removeFiles = () => {
        const {input} = this.props
        const files = []
        this.setState({files: []})
        return input.onChange(files)
    }

    render() {
        const {
            title,
            meta: {error, touched, warning},
            icon
        } = this.props

        const {files} = this.state

        return (
            <FormGroup>
                {Array.isArray(files) && !files.length ? (
                    <Dropzone onDrop={this.onDrop} multiple={false} accept="image/*" className="fileInput">
                        {title}
                    </Dropzone>
                ) : null}
                {Array.isArray(files) && files.length > 0 ? (
                    <ul className="fileName">
                        {files.map((file, index) => (
                            <li key={index}>
                                {icon && <Icon component={icon} className="icon" />}
                                <span>{file.name}</span>
                                <Icon component={CloseModal} onClick={this.removeFiles} />
                            </li>
                        ))}
                    </ul>
                ) : null}
                {(error && touched && <Error>{error}</Error>) || (warning && <span>{warning}</span>)}
            </FormGroup>
        )
    }
}
