import {post, postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {Id, Permissions} from 'ca-common/types'
import {CREDENTIALS_TYPES, USER_ACCOUNT_TYPE} from 'ca-common/constants'

const SET_CREDENTIAL = 'SET_CREDENTIAL'
const setCredentialsWrapper = fetchWrapperRT(SET_CREDENTIAL, async (data, {rejectWithValue}) => {
    try {
        return await post('newCredential', data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})
export const addCredential = setCredentialsWrapper.fetcher

export const removeCredential = async (data: any) => await post('removeCredential', data)

const SET_PERMISSIONS = 'SET_PERMISSIONS'
const setPermissionsWrapper = fetchWrapperRT(SET_PERMISSIONS, async (data, {rejectWithValue}) => {
    try {
        return await post('setPermissions', data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})
export const setPermissions = setPermissionsWrapper.fetcher

const PERMISSIONS = 'PERMISSIONS'
const permissionsWrapper = fetchWrapperRT(PERMISSIONS, async data => await post('getPermissions', data))
export const getPermissions = permissionsWrapper.fetcher

const permissionSlice = createSlice({
    name: 'permission',
    initialState: InitFetchedState as FetchedState<Permissions>,
    reducers: {
        clearPermissions: () => InitFetchedState as FetchedState<Permissions>
    },
    extraReducers: builder => permissionsWrapper.makeReducers(builder)
})

export const {clearPermissions} = permissionSlice.actions
export const permission = permissionSlice.reducer

const CREDENTIALS = 'CREDENTIALS'
const credentialsWrapper = fetchWrapperRT(CREDENTIALS, async data => await postLessData('getCredentials', data))
export const fetchCredentials = credentialsWrapper.fetcher

export enum TWO_FA_STATUS {
    TWO_FA_DISABLED = 'TWO_FA_DISABLED'
}

export type UserCredential = {
    email: string
    id: Id
    isNotactive: boolean
    isSpecialPartnerAccount: boolean
    twoFactorEnabled: boolean
    twoFactorStatus: TWO_FA_STATUS
    type: CREDENTIALS_TYPES
    userAccountType: USER_ACCOUNT_TYPE
}

export type UserCredentialResponse = {
    data: UserCredential[]
    page: number
    total: number
    totalPages: number
    success: boolean
}

const credentialsSlice = createSlice({
    name: 'credentials',
    initialState: InitFetchedState as FetchedState<UserCredentialResponse>,
    reducers: {
        clearCredentials: () => InitFetchedState as FetchedState<UserCredentialResponse>,
        delLocalCredentials: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response.data = state.response.data.filter(credential => credential.email !== action.payload)
            }

            return state
        },
        updateLocalCredentials: (state, action) => {
            state.response = {
                ...state.response,
                ...action.payload
            }
        }
    },
    extraReducers: builder => credentialsWrapper.makeReducers(builder)
})

export const {clearCredentials, delLocalCredentials, updateLocalCredentials} = credentialsSlice.actions
export const credentials = credentialsSlice.reducer
