import _ from 'lodash'

export const countryFilterConfig = {
    matchFrom: 'start'
}

export const mapOptionsByLabelValue = (options, label, value) =>
    _.map(options, option => ({
        value: option[value],
        label: option[label]
    }))
