import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import PropTypes from 'prop-types'
import {bindActionCreators} from '@reduxjs/toolkit'

import NewBackup from 'ca-common/icons/old/uEA30-new-backup.svg'
import {UserManagementTable} from 'ca-common/features/UserManagementTable'
import {PAGES} from 'ca-common/constants'
import {post} from 'src/newcore/utils/rest'
import {removeCredential} from 'src/newcore/redux/modules/userManagement'
import {StyledIcon} from './StyledUsers'

const dumbActions = dumbBem('ca-users')
const Actions = tx([{element: 'actions'}, dumbActions])('div')
const Text = tx([{element: 'text'}, dumbActions])('div')

const dumbNew = dumbBem('new-user')
const ActionNew = tx(dumbNew)('span')

const USERS_PAGE = '/settings/users'
const RESEND_CONFIRMATION_API_URL = 'newapp/resendCredentialConfirmation'

@withTranslation()
class UsersPageTableRaw extends Component {
    static propTypes = {
        credentials: PropTypes.shape({
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    email: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired
                })
            )
        }).isRequired
    }

    render = () => {
        const {pageSize, credentials, fetchCredentials, companyInfo, isZix, t, userInfo} = this.props

        return (
            <div>
                <Text>
                    <h2>{t('settings:users:title', {companyName: companyInfo.name})}</h2>
                    {!isZix && <p>{t('settings:users:text', {companyName: companyInfo.name})}</p>}
                </Text>
                {!isZix && (
                    <Actions>
                        <Link to={`${USERS_PAGE}/new`}>
                            <StyledIcon component={NewBackup} />
                            <ActionNew>{t('settings:users:addNew')}</ActionNew>
                        </Link>
                    </Actions>
                )}
                <UserManagementTable
                    total={credentials.total}
                    status={credentials.status}
                    data={credentials.data || []}
                    page={credentials.page}
                    isZix={isZix}
                    fetchItemsByPage={(page = 1) => fetchCredentials({limit: pageSize, page})}
                    isPartnerPortal={false}
                    getEditPageUrl={id => `/${PAGES.SETTINGS}/${PAGES.USERS}/${id}`}
                    removeCredential={({email, type}, onSuccess, onFail) => {
                        removeCredential({
                            credential: {email, type}
                        })
                            .then(() => {
                                onSuccess()
                                fetchCredentials({limit: pageSize, page: credentials.page})
                            })
                            .catch(onFail)
                    }}
                    userInfo={userInfo}
                    resendConfirmationEmail={data => post(RESEND_CONFIRMATION_API_URL, data)}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo.response,
    userInfo: state.userInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                removeCredential
            },
            dispatch
        )
    }
})

export const UsersPageTable = connect(mapStateToProps, mapDispatchToProps)(UsersPageTableRaw)
