import {getUrlParams as getUrlParamsFromSearch, makeUrlParams} from 'ca-common/utils/url'

export function getUrlParams() {
    const query = location.search.split('?')[1]

    return getUrlParamsFromSearch(query)
}

export function navigate(base, data, wait = false) {
    function _navigate() {
        history.pushState({}, '', makeUrl(`/App/${base}`, data))
    }
    if (wait) {
        setTimeout(_navigate, 500)
    } else {
        _navigate()
    }
}

export function makeUrl(base, data) {
    if (!data) {
        return base
    }

    const params = makeUrlParams(data)
    return `${base}?${params}`
}
