import styled from 'styled-components'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'
import variables from 'ca-common/variables'

export const FaqPageWrapper = styled(Jumbotron)`
    padding: ${variables.gutterMd};

    ul,
    ol {
        margin-left: ${variables.gutterSm};
    }
`

export const FaqContentWrapper = styled.div``
