import React, {Dispatch, SetStateAction} from 'react'

import TileToggle from 'ca-common/icons/viewToggle/TileToggle.svg'
import ListToggle from 'ca-common/icons/viewToggle/ListToggle.svg'

import {BACKUPS_VIEW} from '../../utils/constants'
import {IconToggle, Knobs, Layer, StyledInput, StyledSwitch, ToggleWrapper} from './StyledViewToggle'

type ViewToggleProps = {
    backupView: string
    setBackupView: Dispatch<SetStateAction<BACKUPS_VIEW>>
}

export const ViewToggle = ({backupView, setBackupView}: ViewToggleProps): JSX.Element => {
    function toggleView() {
        if (backupView === BACKUPS_VIEW.LIST) {
            setBackupView(BACKUPS_VIEW.TILE)
        }
        if (backupView === BACKUPS_VIEW.TILE) {
            setBackupView(BACKUPS_VIEW.LIST)
        }
    }
    return (
        <ToggleWrapper>
            <StyledSwitch>
                <StyledInput defaultChecked={backupView === BACKUPS_VIEW.LIST} onClick={toggleView} type="checkbox" />
                <Knobs />
                <Layer />
            </StyledSwitch>
            <IconToggle isActive={backupView === BACKUPS_VIEW.TILE} component={TileToggle} />
            <IconToggle isActive={backupView === BACKUPS_VIEW.LIST} component={ListToggle} />
        </ToggleWrapper>
    )
}
