import styled from 'styled-components'
import variables from 'ca-common/variables'
import {
    BackupActionButton,
    DisabledButtonWrapper
} from 'src/newcore/features/Homepage/organisms/BackupActions/StyledBackupActions'

export const StyledTile = styled.div`
    background: ${variables.white};
    display: flex;
    flex-direction: column;
    border: 1px solid ${variables.blueLight};
    box-sizing: border-box;
    border-radius: 2px;
    padding: ${variables.gutterSm};
    transition: 0.5s ease all;

    &:hover {
        box-shadow: 0 12px 28px rgba(32, 40, 128, 0.16);
        transition: 0.5s ease all;
    }
`
export const StatusContentWrapper = styled.div``
export const StyledActionsTile = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    ${BackupActionButton} {
        margin-bottom: ${variables.gutterSm};
    }
    @media screen and (min-width: ${variables.screenSm}) {
        flex-direction: row;
        ${DisabledButtonWrapper} {
            margin-right: ${variables.gutterSm};
            &:last-child {
                margin-right: 0;
            }
        }
        ${BackupActionButton} {
            margin-bottom: 0;
        }
    }
`
