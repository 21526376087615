import React, {useState, useEffect} from 'react'
import Icon from '@ant-design/icons'
import {Col, Row} from 'antd'
import {Trans, useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {Button} from 'ca-common/ui-lib/atoms'
import {GoogleSignIn} from 'src/newcore/features/GoogleSignIn'

import {Footer, Header} from 'src/newcore/features/BackupAuth/organisms'
import {TaskCreatedAccount} from 'src/newcore/molecules'

import ArrowLeft from 'ca-common/icons/ArrowLeft.svg'

import {
    BackupAuthBody,
    StyledAuthList,
    StyledBackButton,
    StyledFailureBody,
    StyledBackupAuthForm
} from '../StyledTemplates'
import {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

const GSuiteRaw = ({
    source,
    onSubmit,
    onCancel,
    backupSettings,
    companyInfo,
    visible
}: BackupAuthTemplate & ReturnType<typeof mapStateToProps>) => {
    const [noPermission, setNoPermission] = useState<string>('')
    const {t} = useTranslation()

    useEffect(() => {
        setNoPermission('')
    }, [visible])

    return (
        <StyledBackupAuthForm initialValues={{account: backupSettings.account}} layout="horizontal" onFinish={onSubmit}>
            <Header source={source} failed={Boolean(noPermission)} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        {noPermission ? (
                            <StyledFailureBody>
                                <p>{t('forms:backupAuth:text:GoogleIntegrationFailed:text')}</p>
                                <StyledAuthList>
                                    {(t('forms:backupAuth:text:GoogleIntegrationFailed:list', {
                                        returnObjects: true
                                    }) as string[]).map(item => (
                                        <li>{item}</li>
                                    ))}
                                </StyledAuthList>
                            </StyledFailureBody>
                        ) : (
                            <Trans i18nKey="forms:backupAuth:text:GSuiteInfo">
                                <p>Sign in to your Google Workspace administrator user</p>
                                <p>
                                    Use of information received from
                                    <a
                                        href="https://developers.google.com/terms/api-services-user-data-policy"
                                        target="_blank"
                                    >
                                        Google API Services
                                    </a>
                                </p>
                            </Trans>
                        )}
                    </Col>
                    {backupSettings?.account && (
                        <Col>
                            <TaskCreatedAccount account={backupSettings.account} />
                        </Col>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                {noPermission ? (
                    <>
                        <StyledBackButton onClick={() => setNoPermission('')}>
                            <Icon component={ArrowLeft} />
                            Back
                        </StyledBackButton>
                        <Button type="primary" onClick={onCancel} target="_blank" href={noPermission}>
                            {t('forms:backupAuth:text:GoogleIntegrationFailed:button')}
                        </Button>
                    </>
                ) : (
                    <GoogleSignIn
                        googleClientId={companyInfo.googleAppsMarketplaceId}
                        callback={setNoPermission}
                        onCancel={onCancel}
                    />
                )}
            </Footer>
        </StyledBackupAuthForm>
    )
}

const mapStateToProps = ({companyInfo}: any) => ({
    companyInfo: companyInfo.response
})

export const GSuite = connect(mapStateToProps)(GSuiteRaw)
