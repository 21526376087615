import React from 'react'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import {Trans} from 'react-i18next'
import {CAPageNavigationRaw} from 'ca-common/components/PageNavigation'
import {BackupsBreadcrumb, CreateBackupsBreadcrumb} from './BackupsBreadcrumb'
import {
    RecoveryBreadcrumb,
    RecoveryBreadcrumbEntity,
    RecoveryHomepageBreadcrumb,
    RecoveryTitle
} from './RecoveryBreadcrumb'
import {UserManagementBreadcrumb, SingleUserManagementBreadcrumb} from './UserManagmentBreadcrumb'
import {withTitle} from './withTitle'
import {BackupTitle} from 'src/newcore/components/BackupTitle'
import {PAGES} from 'ca-common/constants'
import {NotificationsBreadcrumb} from './NotificationsBreadcrumb'
import {JobsBreadcrumb} from './JobsBreadcrumb'

const navConfig = [
    {path: '/backups', breadcrumb: 'Homepage', title: <Trans i18nKey="breadcrumb:homepage" />},
    {path: '/backups/:id', breadcrumb: props => <BackupsBreadcrumb {...props} />, title: <BackupTitle />},
    {
        path: '/backups/new',
        breadcrumb: props => <BackupsBreadcrumb {...props} />,
        title: <Trans i18nKey="breadcrumb:newbackup" />
    },
    {path: '/backups/new/:src', breadcrumb: props => <CreateBackupsBreadcrumb {...props} />},
    {path: '/backups/:id/:compareId', breadcrumb: 'Compare'},
    {path: '/backups/:id/:compareId/:type', breadcrumb: null},
    {
        path: '/recovery',
        breadcrumb: props => <RecoveryHomepageBreadcrumb {...props} />,
        title: <Trans i18nKey="breadcrumb:homepage" />
    },
    {path: '/recovery/:taskId/', breadcrumb: props => <RecoveryBreadcrumb {...props} />},
    {
        path: '/recovery/:taskId/:entityId',
        breadcrumb: props => <RecoveryBreadcrumbEntity {...props} />,
        title: <RecoveryTitle />
    },
    {path: '/settings/security/twoFa', breadcrumb: 'Two-Factor Authentication'}, //@TODO text
    {path: '/settings/users', breadcrumb: props => <UserManagementBreadcrumb {...props} />}, //@TODO text
    {path: '/settings/users/new', breadcrumb: 'New user'}, //@TODO text
    {path: '/settings/users/:id', breadcrumb: props => <SingleUserManagementBreadcrumb {...props} />},
    {path: '/settings/notifications/new', breadcrumb: 'New Email report'}, //@TODO text
    {path: '/settings/notifications/:id', breadcrumb: props => <NotificationsBreadcrumb {...props} />},
    {path: '/download/:id', breadcrumb: null},
    {path: `/${PAGES.DOWNLOAD_AUDIT}/:id/:activityType`, breadcrumb: null},
    {path: `/${PAGES.DOWNLOAD_EXPORT}/:id`, breadcrumb: null},
    {path: '/faq/:source/', breadcrumb: null},
    {path: '/faq/:source/:id', breadcrumb: null},
    // {path: '/settings/users/:id', breadcrumb: UserManagmentBreadcrumb, title: 'kek'} - example with title
    {path: `/${PAGES.ACCOUNT_ACTIVITY}/security`, breadcrumb: 'Security audit'}, //@TODO text
    {path: `/${PAGES.ACCOUNT_ACTIVITY}/system`, breadcrumb: 'System activity'}, //@TODO text
    {
        path: `/${PAGES.JOBS}`,
        breadcrumb: props => <JobsBreadcrumb {...props} />
    },
    {
        path: `/${PAGES.JOBS}/downloadReport`,
        breadcrumb: props => <Trans i18nKey="breadcrumb:jobs:downloadReport" {...props} />
    },
    {
        path: '/billing/annualSubscription',
        breadcrumb: 'Payment'
    }
]

export const CAPageNavigation = withBreadcrumbs(navConfig, {
    excludePaths: ['/', '/download', '/faq']
})(withTitle(CAPageNavigationRaw, navConfig))
