import React from 'react'
import LabeledInput, {DebouncedLabeledInput} from 'ca-common/ui-lib/components/ReduxFormElements/LabeledInput'
import FileInput from 'ca-common/ui-lib/components/ReduxFormElements/FileInput'
import {CACheckBox} from 'ca-common/ui-lib/components/ReduxFormElements/CheckBox'
import {ReactSelect} from 'ca-common/ui-lib/components/ReduxFormElements/ReactSelect'
import {LabeledTextArea} from 'ca-common/ui-lib/components/ReduxFormElements/LabeledTextArea'
import {RadioGroup} from 'ca-common/ui-lib/components/ReduxFormElements/RadioGroup'
import {Field} from 'redux-form'

const setComponent = type => {
    switch (type) {
        case 'checkbox':
            return CACheckBox

        case 'select':
            return ReactSelect

        case 'textarea':
            return LabeledTextArea

        case 'debounce':
            return DebouncedLabeledInput
        case 'file':
            return FileInput

        default:
            return LabeledInput
    }
}

export const renderField = ({name, label, type, options, ...props}, key = null) => {
    return type === 'radio' ? (
        <RadioGroup name={name} label={label} options={options} key={key} {...props} />
    ) : (
        <Field
            name={name}
            key={key}
            type={type}
            component={setComponent(type)}
            label={label}
            htmlFor={name}
            options={options}
            {...props}
        />
    )
}
