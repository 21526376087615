import React from 'react'
import {Table} from 'antd'

import {i18n} from 'src/locales'

import {getTypeFrendly, transformBeNumbers} from 'src/newcore/features/Objects/libs'
import {COLUMN_NAMES} from 'src/newcore/features/Objects/libs/constants'
import {LastSnapshot, Status, DataColumn, TotalColumnHeader, Number} from 'src/newcore/features/Objects/molecules'
import {LastSnapshotColumnHeader} from '../../molecules/LastSnapshotColumnHeader'

export const columns = [
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.TYPE}`),
        dataIndex: COLUMN_NAMES.TYPE,
        key: COLUMN_NAMES.TYPE,
        sorter: true,
        render: type => getTypeFrendly(type)
    },
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.NAME}`),
        dataIndex: COLUMN_NAMES.NAME,
        key: COLUMN_NAMES.NAME,
        sorter: true
    },
    {
        title: <TotalColumnHeader>{i18n.t(`objectsList:columns:${COLUMN_NAMES.TOTAL}`)}</TotalColumnHeader>,
        dataIndex: COLUMN_NAMES.TOTAL,
        key: COLUMN_NAMES.TOTAL,
        sorter: true,
        align: 'right',
        className: 'right-position',
        render: total => <Number>{transformBeNumbers(total)}</Number>
    },
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.STATUS}`),
        dataIndex: COLUMN_NAMES.STATUS,
        key: COLUMN_NAMES.STATUS,
        sorter: true,
        render: (status, record) => <Status status={status} failureReason={record?.failureReason} />
    },
    {
        title: (
            <LastSnapshotColumnHeader>
                {i18n.t(`objectsList:columns:${COLUMN_NAMES.LAST_SNAPSHOT}`)}
            </LastSnapshotColumnHeader>
        ),
        dataIndex: COLUMN_NAMES.LAST_SNAPSHOT,
        key: COLUMN_NAMES.LAST_SNAPSHOT,
        sorter: true,
        render: timestamp => <LastSnapshot date={timestamp} />
    },
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.ADDED}`),
        dataIndex: COLUMN_NAMES.ADDED,
        key: COLUMN_NAMES.ADDED,
        sorter: true,
        align: 'right',
        className: 'right-position',
        render: added => <DataColumn data={added} type={COLUMN_NAMES.ADDED} />
    },
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.UPDATED}`),
        dataIndex: COLUMN_NAMES.UPDATED,
        key: COLUMN_NAMES.UPDATED,
        sorter: true,
        align: 'right',
        className: 'right-position',
        render: updated => <DataColumn data={updated} type={COLUMN_NAMES.UPDATED} />
    },
    {
        title: i18n.t(`objectsList:columns:${COLUMN_NAMES.DELETED}`),
        dataIndex: COLUMN_NAMES.DELETED,
        key: COLUMN_NAMES.DELETED,
        sorter: true,
        align: 'right',
        className: 'right-position',
        render: deleted => <DataColumn data={deleted} type={COLUMN_NAMES.DELETED} />
    },
    Table.EXPAND_COLUMN
]

export const PAGE_SIZE = 50
export const DEFAULT_PAGE = 1
