import {createAction, createReducer} from '@reduxjs/toolkit'
import {UserInfoDataType} from 'ca-common/types'

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'

export const updateUserInfo = createAction<Partial<UserInfoDataType>>(UPDATE_USER_INFO)
export const userInfoReducer = createReducer({} as UserInfoDataType, builder => {
    builder.addCase(updateUserInfo, (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    })
})
