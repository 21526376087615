import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledIcon = styled(({fade, ...props}) => <Icon {...props} />)`
    display: flex;
    align-items: center;
    font-size: 28px;
    width: 28px;
    height: 28px;
    cursor: ${props => (props.fade ? 'not-allowed' : 'pointer')};

    svg {
        rect {
            fill: ${props => (props.fade ? variables.blueLight : variables.navyBlue)};
        }
        path:not(:nth-child(2)) {
            fill: ${props => (props.fade ? variables.white : variables.navyBlue)};
        }
        path:nth-child(2) {
            fill: ${props => (props.fade ? variables.asphaltLight : variables.white)};
        }
        path:nth-child(3) {
            fill: ${props => (props.fade ? variables.navyAsphalt : variables.asphaltLight)};
        }
    }
`
