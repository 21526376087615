import React from 'react'
import {useTranslation} from 'react-i18next'

import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {ALERT_LEVEL_ALL, ALERT_LEVEL_OBJECT, ALERT_LEVEL_RECORD} from 'src/newcore/features/SmartAlerts/lib/constants'
import {SpecificObjectSelection} from '../../molecules/SpecificObjectSelection'
import {StyledSelectionWrapper, StyledDescription} from './StyledAlertLevelSelection'

export const AlertLevelSelection = ({
    selectedObjectType = '',
    changeObjectType,
    specificObjectFlowEnabled,
    specificObject,
    setSpecificObject
}) => {
    const {t} = useTranslation()

    return (
        <StyledSelectionWrapper>
            <RadioBlock
                checked={ALERT_LEVEL_ALL === selectedObjectType}
                value={selectedObjectType}
                onChange={() => changeObjectType(ALERT_LEVEL_ALL)}
                showRadioIcon={false}
                title={t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_ALL}:label`)}
                description={
                    <StyledDescription>
                        {t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_ALL}:description`)}
                    </StyledDescription>
                }
            />
            <RadioBlock
                checked={ALERT_LEVEL_OBJECT === selectedObjectType}
                value={selectedObjectType}
                onChange={() => changeObjectType(ALERT_LEVEL_OBJECT)}
                showRadioIcon={false}
                disabled={!specificObjectFlowEnabled}
                title={t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_OBJECT}:label`)}
                description={
                    <StyledDescription>
                        {specificObjectFlowEnabled
                            ? t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_OBJECT}:description`)
                            : t('smartAlerts:wizard:specificObjectDisabled')}
                    </StyledDescription>
                }
            >
                <SpecificObjectSelection
                    checked={ALERT_LEVEL_OBJECT === selectedObjectType}
                    specificObject={specificObject}
                    setSpecificObject={setSpecificObject}
                />
            </RadioBlock>
            <RadioBlock
                checked={ALERT_LEVEL_RECORD === selectedObjectType}
                value={selectedObjectType}
                onChange={() => changeObjectType(ALERT_LEVEL_RECORD)}
                showRadioIcon={false}
                disabled
                comingSoon
                title={t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_RECORD}:label`)}
                description={
                    <StyledDescription>
                        {t(`smartAlerts:wizard:alertLevel:${ALERT_LEVEL_RECORD}:description`)}
                    </StyledDescription>
                }
            />
        </StyledSelectionWrapper>
    )
}
