import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Radio} from 'antd'
import Icon from '@ant-design/icons'

export const StyledLabel = styled.span`
    margin-bottom: ${variables.gutterSm};
    font-size: ${variables.fontSizeBase};
    color: ${variables.brandDark};
    font-weight: 600;
`
export const RecoveryOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledRadioGroup = styled(Radio.Group)`
    display: flex;
    flex-direction: row;
`
export const StyledRadio = styled(Radio)`
    align-items: center;
    display: flex;
    &:first-child {
        margin-right: 60px;
    }
    span.ant-radio {
        top: 0;
        left: 0;
        display: inline-block;
        position: relative;
        margin: 0 12px 0 0;
    }
`
export const StyledIcon = styled(Icon)`
    margin-left: ${variables.gutterXs};
    text-align: center;
`
