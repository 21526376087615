import React from 'react'
import {useTranslation} from 'react-i18next'

import {JOBS_STATUSES} from 'ca-common/common/enum'

import {StatusTile} from 'src/newcore/features/Jobs/atoms'
import {getDashboardStatusFriendly, JOB_FIELDS, JOBS_DASHBOARD_STATUSES} from 'src/newcore/features/Jobs/lib'
import {track} from 'src/newcore/utils/mixpanel'

import {StyledStatusDashboard} from './StyledStatusDashboard'
import {DashboardItem} from 'src/newcore/features/Jobs/redux'

type DashboardStatus = {
    status: JOBS_DASHBOARD_STATUSES
    count: number
}

type StatusDashboardProps = {
    statuses?: DashboardItem[]
    changeUrlParams: any
    loading: boolean
}

const jobDashboardKeys = [JOBS_STATUSES.IN_PROCESS, JOBS_STATUSES.SUCCEEDED, JOBS_STATUSES.FAILED]

const PENDING_COUNT = -1

export const StatusDashboard = (props: StatusDashboardProps): JSX.Element => {
    const {statuses, changeUrlParams, loading} = props
    const {t} = useTranslation()

    return (
        <StyledStatusDashboard>
            {Object.values(jobDashboardKeys).map(jobStatus => {
                const {status, count} =
                    statuses?.find(i => i.status === jobStatus) ||
                    ({status: jobStatus, count: PENDING_COUNT} as DashboardItem)

                const sendAnalytics = () => {
                    track('Jobs Dashboard Filter Selected', {
                        'Dashboard Filter': getDashboardStatusFriendly(status)
                    })
                }

                const inlineValue = `InlineManual-jobspage-${status}`

                return (
                    <StatusTile
                        key={`${status}-${count}`}
                        count={count === -1 ? t('JobsPage:dashboardPending') : count.toString()}
                        status={status}
                        onClick={() => {
                            status === JOBS_STATUSES.FAILED
                                ? changeUrlParams({[JOB_FIELDS.STATUS]: [status, JOBS_STATUSES.PARTIALLY_SUCCEEDED]})
                                : changeUrlParams({[JOB_FIELDS.STATUS]: [status]})
                            sendAnalytics()
                        }}
                        data-inlinemanual={inlineValue}
                        disabled={loading}
                    />
                )
            })}
        </StyledStatusDashboard>
    )
}
