import styled from 'styled-components'
import variables from 'ca-common/variables'

export const DateConfigurationSection = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 70px 0 ${variables.gutterMd};
`
export const RadioDescription = styled.p`
    padding-left: ${variables.gutterMd};
    margin: 0;
`
export const RadioDescriptionSmall = styled.span`
    font-size: ${variables.fontSizeSecondary};
    padding-left: ${variables.gutterMd};
    margin: 0 0 ${variables.gutterXs} 0;
`
export const CalendarInputWrapper = styled.div`
    padding-left: ${variables.gutterMd};
`
