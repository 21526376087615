/* eslint-disable @typescript-eslint/no-extra-semi */
import React, {Component} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {formValueSelector, change} from 'redux-form'

import {havePositiveValue} from 'ca-common/utils/usersManagement'
import {CheckboxTree} from 'ca-common/ui-lib/components/CheckboxTree'

class TasksListRaw extends Component {
    componentDidUpdate(prevProps) {
        const {
            hasServices,
            actions,
            data,
            isExploreDependsActivated,
            explore,
            hasTasks,
            isActionsActivated
        } = this.props

        if (prevProps.hasServices !== hasServices) {
            if (!hasServices && hasTasks) {
                data.sub.forEach(task => {
                    actions.setValue(`permissions.tasks.sub.${task.name}`, false)
                })
            }
        }

        if (
            isExploreDependsActivated !== prevProps.isExploreDependsActivated ||
            isActionsActivated !== prevProps.isActionsActivated
        ) {
            if ((isActionsActivated || isExploreDependsActivated) && !explore) {
                actions.setValue('permissions.tasks.sub.RESTORE_AND_DOWNLOAD', true)
            }
        }

        if (explore !== prevProps.explore) {
            if (!explore && isActionsActivated) {
                ;['BACKUPS', 'EDIT', 'DELETE', 'PAUSE', 'EXECUTE', 'CANCEL'].forEach(action => {
                    actions.setValue(`permissions.tasks.sub.${action}`, false)
                })
            }
        }
    }

    render() {
        const {hasServices, data, isFinra, isExploreDependsActivated, finraTooltip, ...passThroughProps} = this.props

        return (
            <CheckboxTree
                data={this.modifyArributes({data, hasServices, isFinra, isExploreDependsActivated, finraTooltip})}
                {...passThroughProps}
            />
        )
    }

    modifyArributes = params => {
        const {data, hasServices, isFinra, isExploreDependsActivated, finraTooltip} = params

        return {
            ...data,
            disabled: isFinra || !hasServices,
            sub: data.sub.map(sub => {
                const isDeletesDisabled = this.changeDeletesDisable(sub.name, isFinra)
                const isExploreDisabled = this.changeExploreDisable(sub.name, isExploreDependsActivated)

                return {
                    ...sub,
                    disabled: isDeletesDisabled || !hasServices || isExploreDisabled,
                    tooltip: hasServices && isDeletesDisabled && !isExploreDisabled && finraTooltip
                }
            })
        }
    }

    changeExploreDisable = (name, disabled) => {
        return name === 'RESTORE_AND_DOWNLOAD' && disabled
    }

    changeDeletesDisable = (name, disabled) => {
        return ['DELETEITEMS', 'DELETE'].some(i => i === name) && disabled
    }
}

const mapStateToProps = (state, {form}) => {
    const selector = formValueSelector(form)

    const getValue = name => selector(state, name)
    const hasServices = () =>
        getValue('permissions.services.all') || havePositiveValue(getValue('permissions.services.sub'))
    const hasTasks = () => getValue('permissions.tasks.all') || havePositiveValue(getValue('permissions.tasks.sub'))

    return {
        hasServices: hasServices(),
        isExploreDependsActivated:
            getValue('permissions.tasks.sub.RESTORE') ||
            getValue('permissions.tasks.sub.DOWNLOAD') ||
            getValue('permissions.tasks.sub.PREVIEW'),
        isActionsActivated: ['BACKUPS', 'EDIT', 'DELETE', 'PAUSE', 'EXECUTE', 'CANCEL', 'PREVIEW'].some(i =>
            getValue(`permissions.tasks.sub.${i}`)
        ),
        explore: getValue('permissions.tasks.sub.RESTORE_AND_DOWNLOAD'),
        hasTasks: hasTasks()
    }
}

const mapDispatchToProps = (dispatch, {form}) => ({
    actions: bindActionCreators(
        {
            setValue: (name, value) => dispatch(change(form, name, value))
        },
        dispatch
    )
})

export const TasksList = connect(mapStateToProps, mapDispatchToProps)(TasksListRaw)
