import React, {Component} from 'react'
import {Select, Spin, Form} from 'antd'
import _ from 'lodash'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {getAccountPropertyList} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'

import {StyledSelect, StyledPlaceholder, StyledPlaceholderIcon, StyledForm} from './StyledSearchFiled'

const {Option} = Select

export const Placeholder = ({text}) => (
    <StyledPlaceholder>
        <StyledPlaceholderIcon type="search" />
        {text}
    </StyledPlaceholder>
)

class SearchFieldFormRaw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            query: undefined
        }
    }

    componentDidMount() {
        const {taskSettings, actions} = this.props

        actions.getAccountPropertyList({
            taskId: taskSettings.taskId
        })
    }

    onSearch = query => {
        this.setState({
            query
        })
    }

    onChange = property => {
        const {handleChange} = this.props

        if (_.isEmpty(property)) {
            this.setState({
                query: undefined
            })
        }

        handleChange(property)
    }

    render() {
        const {accountPropertyList, t, name} = this.props

        const {query} = this.state

        return (
            <StyledForm layout="inline">
                <Form.Item
                    name="property"
                    initialValue={name || undefined} // undefined is needed to show placeholder while initialValue = empty string
                >
                    <StyledSelect
                        placeholder={<Placeholder text={t('backups:bulkActivation:propertyPlaceholder')} />}
                        showSearch
                        showArrow
                        onSearch={this.onSearch}
                        onChange={this.onChange}
                        notFoundContent={accountPropertyList.status === 'pending' ? <Spin size="small" /> : null}
                        allowClear
                        getPopupContainer={trigger => trigger.parentElement}
                    >
                        {_(accountPropertyList.data)
                            .filter(property => !query || property.name.toLowerCase().includes(query.toLowerCase()))
                            .map(property => (
                                <Option value={property.id} key={property.id}>
                                    {property.name}
                                </Option>
                            ))
                            .value()}
                    </StyledSelect>
                </Form.Item>
            </StyledForm>
        )
    }
}

const mapStateToProps = state => {
    const {accountPropertyList} = state.backups.bulkActivation.activeDirectory

    return {
        accountPropertyList: {
            status: accountPropertyList.status,
            ...accountPropertyList.response
        },
        taskSettings: state.backups.settings.response
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAccountPropertyList
        },
        dispatch
    )
})

export const SearchFieldForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchFieldFormRaw))
