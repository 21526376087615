import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import queryString from 'query-string'
import {TableProps} from 'antd/lib/table'
import {withRouter, useHistory} from 'react-router-dom'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {Alert} from 'ca-common/ui-lib/components/Alert'
import {Table} from 'ca-common/ui-lib/organisms/Table'
import {PageStatus} from 'ca-common/components/Billing/PageStatus'
import {CARow, CACol} from 'ca-common/ui-lib/components/Grid'
import {CURRENCIES, PAGES, PAYMENT_TYPE, USER_STATUS} from 'ca-common/constants'
import {openSuccessNotification} from 'src/ca-common/utils/toasts'
import {PROVIDER} from 'src/ca-common/common/enum/Provider'
import {openErrorModal} from 'src/ca-common/utils/modals'
import {convertUnixToDate, NEXT_PAYMENT_DATE} from 'ca-common/utils/datetime'
import variables from 'ca-common/variables'
import ActionExclamation from 'ca-common/icons/old/uEA04-action-exclamation.svg'

import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {
    getBillingLine,
    getNextPaymentDate,
    getPaymentMethod,
    getUsersBilling,
    setWhiteLabelSubscribe,
    setZoozSubscribeMonthly,
    updatePaymentMethod
} from 'src/newcore/redux/modules/billing'
import {UserBillingTask} from 'src/newcore/components/Billing/Pages/Status/types'
import {NoBackupsInfo} from 'src/newcore/components/Billing/Pages/Status/NoBackupsInfo'
import {SubscriptionBlock} from 'src/newcore/components/Billing/Pages/Status/SubscriptionBlock'
import {isWhiteLable} from 'src/newcore/utils/companyInfo'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {fetchUserInfo} from 'src/newcore/redux/modules/account'

import './Status.scss'

import {getStatusConfig} from './StatusConfig'
import {
    StyledIcon,
    StyledDetails,
    StyledDetailsText,
    StyledDetailsButtonsWrapper,
    StyledDetailsButton,
    CurrencyBlockWrapper
} from './StyledStatus'
import {getUrlParams, makeUrlParams} from 'ca-common/utils/url'
import {useCurrentCurrency} from 'src/newcore/utils/useCurrentCurrency'
import {Switcher} from 'ca-common/ui-lib/atoms/Switcher'

const dumbStatus = dumbBem('ca-status-page')
const CAStatus = tx(dumbStatus)('div')
const CAStatusSubscription = tx([{element: 'subscription'}, dumbStatus])('div')

const ANNUAL_PAGE = `/${PAGES.BILLING}/${PAGES.ANNUAL_SUBSCRIPTION}`
const MONTHLY_PAGE = `/${PAGES.BILLING}/${PAGES.PAYMENTS_DETAILS}`
const PAGE = 20

const PARTNER_IS_NOT_SUBSCRIBED = 1
const PARTNER_SUBSCRIPTION_INVALID = 2
const REQUIRE_ADDITIONAL_INFO = 3
const INVALID_REQUEST = 4
const USER_ALREADY_SUBSCRIBED = 5

const scrollAfterX = 1080

const options = Object.values(CURRENCIES).map(v => ({value: v, label: v}))

const BillingStatus = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [monthSelection, setMonthSelection] = useState(false)
    const [isPageLoadedFirstTime, setIsPageLoadedFirstTime] = useState(false)
    const billingLine = useAppSelector((state: AppState) => state.billing.billingLine)
    const usersBilling = useAppSelector((state: AppState) => state.billing.usersBilling)
    const nextPaymentDate = useAppSelector((state: AppState) => state.billing.nextPaymentDate)
    const companyInfo = useAppSelector(companyInfoSelector)
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false)
    const userInfo = useAppSelector((state: AppState) => state.userInfo)
    const history = useHistory()
    const trialExpired = 'trialExpired'
    const trialDaysLeft = 'trialDaysLeft'
    const showCurrencySwitcher =
        !userInfo?.response?.partnerID && userInfo?.response?.status !== USER_STATUS.UNSUBSCRIBED
    const showPaymentDetails = userInfo?.response?.billingView !== 'ZERRO_TOTAL'
    const currency = useCurrentCurrency()

    const isBillingLoading = [usersBilling, billingLine, nextPaymentDate].some(item => item.status !== 'fulfilled')

    useEffect(() => {
        if (!isPageLoadedFirstTime) {
            setIsPageLoadedFirstTime(
                [userInfo, usersBilling, billingLine, nextPaymentDate].every(item => item.status === 'fulfilled')
            )
        }
    }, [userInfo, usersBilling, billingLine, nextPaymentDate])

    const setCurrency = (value: string) => {
        if ([CURRENCIES.JPY, CURRENCIES.AUD].includes(value as CURRENCIES)) {
            setMonthSelection(false)
        }

        history.replace({
            search: makeUrlParams({...getUrlParams(history.location.search), currency: value as CURRENCIES})
        })
    }

    useEffect(() => {
        const params = queryString.parse(history.location.search)
        const page = +(params?.page || 1)

        dispatch(getUsersBilling({limit: PAGE, page: 1, currency}))
        dispatch(getBillingLine({limit: PAGE, page, currency}))
    }, [currency])

    const annualDetails = () => {
        setMonthSelection(false)
    }

    const monthlyDetails = () => {
        setMonthSelection(true)
    }

    const subscribeCatchHandler = (redirect: string) => (error: {statusCode: number}) => {
        setIsSubscriptionLoading(false)
        switch (error?.statusCode) {
            case PARTNER_IS_NOT_SUBSCRIBED:
                openErrorModal(<Trans i18nKey="billing:zoozMessages:missingSubscription" />)
                break

            case PARTNER_SUBSCRIPTION_INVALID:
                openErrorModal(
                    <Trans i18nKey="billing:zoozMessages:invalidPartnerSubscription" values={{mail: companyInfo.email}}>
                        <a href={`mailto:${companyInfo.email}`}>Email</a>
                    </Trans>
                )
                break

            case USER_ALREADY_SUBSCRIBED:
                openSuccessNotification(t('billing:zoozMessages:subscriptionAlreadyExist'))
                break

            case REQUIRE_ADDITIONAL_INFO:
                history.push(redirect)
                break

            case INVALID_REQUEST:
            default:
                openErrorModal(
                    <Trans
                        i18nKey="billing:zoozMessages:unexpectedSubscriptionException"
                        values={{mail: companyInfo.email}}
                    >
                        <a href={`mailto:${companyInfo.email}`}>Email</a>
                    </Trans>
                )
                break
        }
    }

    const onAnnualSubscription = () => {
        setIsSubscriptionLoading(true)
        const annualPage = `${ANNUAL_PAGE}?${makeUrlParams({currency})}`

        if (isWhiteLable()) {
            dispatch(setWhiteLabelSubscribe({subscriptionType: PAYMENT_TYPE.ANNUAL.toUpperCase(), currency}))
                .unwrap()
                .then(() => {
                    dispatch(fetchUserInfo({}))
                    openSuccessNotification(t('billing:toasts:whiteLabel:success'))
                    setIsSubscriptionLoading(false)
                    history.push(`/${PAGES.BACKUPS}`)
                })
                .catch(subscribeCatchHandler(annualPage))
        } else {
            dispatch(setZoozSubscribeMonthly({provider: PROVIDER.OTHER}))
                .unwrap()
                .then(() => {
                    dispatch(fetchUserInfo({}))
                    openSuccessNotification(t('billing:toasts:whiteLabel:success'))
                    setIsSubscriptionLoading(false)
                    history.push(`/${PAGES.BACKUPS}`)
                })
                .catch(subscribeCatchHandler(annualPage))
        }
    }

    const onMonthlySubscription = () => {
        setIsSubscriptionLoading(true)
        const monthlyPage = `${MONTHLY_PAGE}?${makeUrlParams({currency})}`

        if (isWhiteLable()) {
            dispatch(setWhiteLabelSubscribe({subscriptionType: PAYMENT_TYPE.MONTHLY.toUpperCase(), currency}))
                .unwrap()
                .then(() => {
                    dispatch(fetchUserInfo({}))
                    openSuccessNotification(t('billing:toasts:whiteLabel:success'))
                    setIsSubscriptionLoading(false)
                    history.push(`/${PAGES.BACKUPS}`)
                })
                .catch(subscribeCatchHandler(monthlyPage))
        } else {
            dispatch(setZoozSubscribeMonthly({provider: PROVIDER.ZOOZ}))
                .unwrap()
                .then(() => {
                    openSuccessNotification(t('billing:toasts:whiteLabel:success'))
                    dispatch(fetchUserInfo({}))
                    dispatch(getPaymentMethod())
                    dispatch(getNextPaymentDate())
                    dispatch(updatePaymentMethod({subscriptionType: PAYMENT_TYPE.MONTHLY, isSubscribe: true}))
                    dispatch(getUsersBilling({limit: PAGE, page: 1}))
                    dispatch(getBillingLine({limit: PAGE, page: 1}))
                    setIsSubscriptionLoading(false)
                    history.push(`/${PAGES.BACKUPS}`)
                })
                .catch(subscribeCatchHandler(monthlyPage))
        }
    }

    const mapBillingLineTotalForTable = (
        billingLines: Record<string, any>,
        usersBilling: Record<string, any>,
        showPaymentDetails: boolean
    ) => {
        const totalValue = {
            service: 'Total',
            totalMonthly: usersBilling?.response.totalMonthly,
            totalAnnual: usersBilling?.response.totalAnnual
        }
        let billingValue = billingLines.response.data ? billingLines.response.data : {status: billingLines.status}
        billingValue = showPaymentDetails
            ? billingValue && billingValue.length > 0 && [...billingValue, totalValue]
            : billingValue
        return billingValue
    }

    const isNoBillingForecast = !billingLine.response?.data?.length
    const isUnavailableCurrency = [CURRENCIES.JPY, CURRENCIES.AUD].includes(currency)

    return (
        <div>
            {!isPageLoadedFirstTime ? (
                <Spinner modifier="page" />
            ) : (
                <CAStatus>
                    <CAStatusSubscription>
                        {!userInfo.response?.isSubscribed ? (
                            <div>
                                <PageStatus
                                    status={'nobill'}
                                    title={usersBilling.response.trialDaysLeft <= 0 ? trialExpired : trialDaysLeft}
                                    titleParameters={{days: usersBilling.response.trialDaysLeft}}
                                    data={usersBilling.response}
                                />
                                {showCurrencySwitcher && (
                                    <CurrencyBlockWrapper>
                                        <Switcher options={options} activeValue={currency} onChange={setCurrency} />
                                    </CurrencyBlockWrapper>
                                )}
                                <SubscriptionBlock
                                    usersBilling={usersBilling.response}
                                    isNoBillsStatus={isNoBillingForecast}
                                    showCurrencySwitcher={showCurrencySwitcher}
                                    onAnnualSubscription={onAnnualSubscription}
                                    onMonthlySubscription={onMonthlySubscription}
                                    isSubscriptionLoading={isSubscriptionLoading}
                                    showPaymentDetails={showPaymentDetails}
                                    isUnavailableCurrency={isUnavailableCurrency}
                                    isLoading={isBillingLoading}
                                    isPartnerCustomerAccount={userInfo?.response?.partnerID != undefined}
                                />
                                {!isNoBillingForecast && (
                                    <StyledDetails>
                                        {showPaymentDetails && (
                                            <CARow>
                                                <CACol>
                                                    <StyledDetailsText>
                                                        {t('table:billing.billingForecast')}
                                                    </StyledDetailsText>
                                                </CACol>
                                                <CACol>
                                                    <StyledDetailsButtonsWrapper isLoading={isBillingLoading}>
                                                        <StyledDetailsButton
                                                            onClick={monthlyDetails}
                                                            isActive={monthSelection}
                                                            isLoading={isBillingLoading}
                                                            isDisabled={isUnavailableCurrency}
                                                        >
                                                            {t('table:billing:subscribeType:monthlyPrices')}
                                                        </StyledDetailsButton>
                                                        <StyledDetailsButton
                                                            onClick={annualDetails}
                                                            isActive={!monthSelection}
                                                            isLoading={isBillingLoading}
                                                        >
                                                            {t('table:billing:subscribeType:annualPrices')}
                                                        </StyledDetailsButton>
                                                    </StyledDetailsButtonsWrapper>
                                                </CACol>
                                            </CARow>
                                        )}
                                    </StyledDetails>
                                )}
                            </div>
                        ) : (
                            <Alert>
                                <CARow>
                                    <CACol>
                                        <StyledIcon
                                            component={ActionExclamation}
                                            style={{color: variables.old.green}}
                                        />
                                        {`${t('billing:status:paymentForecast:paymentForecast')} ${
                                            usersBilling.response.total
                                        }, Due on ${convertUnixToDate(
                                            nextPaymentDate.data.nextPaymentDate,
                                            NEXT_PAYMENT_DATE
                                        )}`}
                                    </CACol>
                                    <CACol />
                                </CARow>
                            </Alert>
                        )}
                        {isNoBillingForecast ? (
                            <NoBackupsInfo />
                        ) : (
                            <Table<(props: TableProps<UserBillingTask>) => JSX.Element>
                                columns={getStatusConfig(monthSelection, showPaymentDetails)}
                                dataSource={
                                    isBillingLoading
                                        ? []
                                        : mapBillingLineTotalForTable(billingLine, usersBilling, showPaymentDetails)
                                }
                                loading={isBillingLoading}
                                rowKey="service"
                                scroll={{x: scrollAfterX}}
                                pagination={false}
                            />
                        )}
                    </CAStatusSubscription>
                </CAStatus>
            )}
        </div>
    )
}

export const StatusPage = withRouter(BillingStatus)
