import styled from 'styled-components'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'

import {Select, Form} from 'antd'

export const StyledSelect = styled(Select)`
    width: 100%;
`

export const StyledPlaceholder = styled.div`
    display: flex;
    align-items: center;
`

export const StyledPlaceholderIcon = styled(Icon)`
    margin-right: 10px;
`

export const StyledHeader = styled.div`
    margin-bottom: 5px;
`

export const StyledForm = styled(Form)`
    padding-bottom: ${variables.gutterSm};
    width: 100%;

    & .ant-row.ant-form-item {
        width: 100%;
    }

    & .ant-col.ant-form-item-control-wrapper {
        width: 100%;
    }

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }
`
