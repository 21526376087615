import React, {useState} from 'react'
import forEach from 'lodash/forEach'
import moment from 'moment'
import {Spin} from 'antd'

import {DATE_FORMAT} from 'ca-common/utils/datetime'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'

import {
    AllFailedDate,
    AllPartialDate,
    AllSuccessDate,
    CalendarCell,
    MixedDate,
    MixedPartialDate,
    OneFailedDate,
    OnePartialDate,
    OneSuccessDate,
    StyledCalendar
} from './StyledSnapshotCalendar'

export const CACompareCalendar = React.forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false)
    const {snapshotsList, fetchData, showPartialStatus} = props
    const [isNextBtnsAllowed, setIsNextBtnsAllowed] = useState({
        isNextYearBtnAllowed: false,
        isNextMonthBtnAllowed: false
    })
    const [value, setValue] = useState(moment())

    const checkDisabledDate = day => {
        let found = false

        forEach(snapshotsList, d => {
            if (moment(day).isSame(d.dateTime, 'day')) {
                found = true
            }
        })

        return !found
    }
    const renderDateWithPartialStatus = current => {
        let partialTaskCount = 0
        let successfulTasksCount = 0
        forEach(snapshotsList, d => {
            if (moment(current).isSame(d.dateTime, 'day')) {
                if (d.status === ACTIVITY_STATUS.SUCCEED) {
                    successfulTasksCount++
                } else {
                    partialTaskCount++
                }
            }
        })
        if (partialTaskCount === 0) {
            if (successfulTasksCount === 1) {
                const data = snapshotsList.find(snapshot => snapshot.dateTime.isSame(current, 'day'))
                return <OneSuccessDate retention={data.retention}>{current.date()}</OneSuccessDate>
            } else if (successfulTasksCount > 1) {
                return <AllSuccessDate>{current.date()}</AllSuccessDate>
            }
        } else if (successfulTasksCount === 0) {
            if (partialTaskCount === 1) {
                return <OnePartialDate>{current.date()}</OnePartialDate>
            } else if (partialTaskCount > 1) {
                return <AllPartialDate>{current.date()}</AllPartialDate>
            }
        } else {
            return <MixedPartialDate>{current.date()}</MixedPartialDate>
        }

        return <CalendarCell>{current.date()}</CalendarCell>
    }
    const renderDateWithFailedStatus = current => {
        let failedTaskCount = 0
        let successfulTasksCount = 0

        forEach(snapshotsList, d => {
            if (moment(current).isSame(d.dateTime, 'day')) {
                if (d.succeed) {
                    successfulTasksCount++
                } else {
                    failedTaskCount++
                }
            }
        })

        if (failedTaskCount === 0) {
            if (successfulTasksCount === 1) {
                const data = snapshotsList.find(snapshot => snapshot.dateTime.isSame(current, 'day'))
                return <OneSuccessDate retention={data.retention}>{current.date()}</OneSuccessDate>
            } else if (successfulTasksCount > 1) {
                return <AllSuccessDate>{current.date()}</AllSuccessDate>
            }
        } else if (successfulTasksCount === 0) {
            if (failedTaskCount === 1) {
                return (
                    <OneFailedDate
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        {current.date()}
                    </OneFailedDate>
                )
            } else if (failedTaskCount > 1) {
                return (
                    <AllFailedDate
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        {current.date()}
                    </AllFailedDate>
                )
            }
        } else {
            return <MixedDate>{current.date()}</MixedDate>
        }

        return <CalendarCell>{current.date()}</CalendarCell>
    }

    const onChange = e => {
        let newValue = e

        if (e.year() > moment().year() || (e.year() === moment().year() && e.month() > moment().month())) {
            newValue = moment()
        }

        setValue(newValue)

        if (newValue.year() < moment().year()) {
            setIsNextBtnsAllowed({
                isNextYearBtnAllowed: true,
                isNextMonthBtnAllowed: true
            })
        } else if (newValue.year() === moment().year() && newValue.month() < moment().month()) {
            setIsNextBtnsAllowed({
                isNextYearBtnAllowed: false,
                isNextMonthBtnAllowed: true
            })
        } else {
            setIsNextBtnsAllowed({
                isNextYearBtnAllowed: false,
                isNextMonthBtnAllowed: false
            })
        }

        if (!loading) {
            setLoading(true)
            fetchData(newValue.month(), newValue.year()).then(() => {
                setLoading(false)
            })
        }
    }

    return (
        <Spin spinning={loading}>
            <StyledCalendar
                {...props}
                style={{zIndex: 1001, width: 282}}
                format={DATE_FORMAT}
                focusablePanel={false}
                disabledDate={checkDisabledDate}
                showToday={false}
                dateRender={showPartialStatus ? renderDateWithPartialStatus : renderDateWithFailedStatus}
                onChange={onChange}
                ref={ref}
                isNextYearBtnAvailable={isNextBtnsAllowed.isNextYearBtnAllowed}
                isNextMonthBtnAvailable={isNextBtnsAllowed.isNextMonthBtnAllowed}
                value={value}
            />
        </Spin>
    )
})
