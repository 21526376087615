import styled, {css} from 'styled-components'
import {Radio} from 'antd'

import variables from 'ca-common/variables'
import {SubTitle} from 'ca-common/ui-lib/atoms/Typography'

export const StyledContent = styled.div`
    white-space: normal;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const RadioBlockLabel = styled(SubTitle)`
    text-transform: capitalize;

    && {
        ${props => (props.description ? '' : 'margin-bottom : 0')};
    }
`

export const StyledRadio = styled(Radio)<{
    inline?: boolean
    hideInactive?: boolean
    isStatic?: boolean
    showRadioIcon?: boolean
}>`
    ${props => (props.inline ? '' : 'width: 100%;')};
    padding: ${variables.gutterSm};
    background: none;
    border-radius: ${variables.borderRadiusBase};
    border: 1px solid ${variables.blue};
    display: ${props => (props.inline ? 'inline-block' : 'block')};
    margin-right: ${props => (props.inline ? variables.gutterMd : 0)};
    margin-bottom: ${props => (props.inline ? '20px' : variables.gutterSm)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    white-space: normal;
    transition: all 0.2s;

    ${StyledContent} {
        display: ${props => (props.hideInactive ? 'none' : 'block')};
    }

    &.ant-radio-wrapper::after {
        content: none;
    }

    &.ant-radio-wrapper-checked {
        ${StyledContent} {
            display: block;
        }
    }

    &.ant-radio-wrapper-checked,
    &:focus,
    &:hover {
        ${props =>
            !props.isStatic &&
            !props.disabled &&
            css`
                background: ${variables.radioBlockBackground};
                border-color: ${variables.navyBlue};

                ${SubTitle} {
                    color: ${variables.navyBlue};
                }
            `};
    }

    .ant-form-item {
        margin-bottom: 10px;
    }

    span.ant-radio {
        display: ${props => (props.showRadioIcon ? 'inline-block' : 'none')};
        position: absolute;
        top: 3px;
        left: 0;
        margin: ${variables.gutterSm};

        & + * {
            padding: 0;
        }
    }

    ${RadioBlockLabel} {
        ${props => props.showRadioIcon && 'margin-left: 25px'};
        ${props => props.disabled && 'opacity: .3'};
    }
`

export const StyledInputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
