import React from 'react'
import {TablePaginationConfig, TableProps} from 'antd'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router'
import queryString from 'query-string'

import type {Notification, DeleteUsersNotification} from 'ca-common/features/NotificationsTable/types'
import {useColumns} from 'ca-common/features/NotificationsTable/lib/useColumns'
import {PAGE_SIZE, TABLE_X_SCROLL_AFTER} from 'ca-common/features/NotificationsTable/lib/constants'

import {StyledTable} from './StyledNotificationsTable'
import {FetchedStateStatuses} from 'ca-common/utils/fetchWrapperRT'

type NotificationsTableProps = {
    data: Notification[]
    page: number
    status: FetchedStateStatuses
    fetchItemsByPage: (page: number) => Promise<void>
    total: number
    deleteUsersNotification: DeleteUsersNotification
    isPartnerPortal?: boolean
}

export const NotificationsTable = (props: NotificationsTableProps) => {
    const {status, data, page, fetchItemsByPage, total, deleteUsersNotification, isPartnerPortal} = props
    const location = useLocation()
    const history = useHistory()
    const {t} = useTranslation()
    const columns = useColumns({
        fetchUsersNotifications: () => fetchItemsByPage(1),
        deleteUsersNotification,
        isPartnerPortal
    })

    const onPageChange = (pagination?: TablePaginationConfig) => {
        const page = pagination?.current || 1
        const query = queryString.parse(location.search)
        const params = queryString.stringify({...query, page})
        history.replace(`${location.pathname}?${params}`)
        fetchItemsByPage(page)
    }

    return (
        <StyledTable<(props: TableProps<Notification>) => JSX.Element>
            rowKey={item => item.id}
            columns={columns}
            dataSource={data}
            scroll={{x: TABLE_X_SCROLL_AFTER}}
            loading={status !== 'fulfilled'}
            pagination={{
                pageSize: PAGE_SIZE,
                showSizeChanger: false,
                showTotal: (total: number, range: number[]) =>
                    t('table:footer:itemPagination', {
                        start: range[0],
                        end: range[1],
                        total
                    }),
                size: 'small',
                total,
                current: page
            }}
            onChange={onPageChange}
        />
    )
}
