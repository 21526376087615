import React, {Fragment, useState} from 'react'
import {Group} from '@visx/group'
import {BarGroup} from '@visx/shape/lib/types'
import {transparentize} from 'polished'
import {LinearGradient} from '@visx/gradient'

import variables from 'ca-common/variables'

import {CustomBarGroup, failureBarHeight} from '../../lib'

const GROUP_ID = 'group_gradient'

type ChartGroupProps = {
    simplified?: boolean
    gradientWidth: number
    gradientHeight: number
    onMouseLeaveHandler: any
    onMouseMoveHandler: any
    barGroup: BarGroup<string>
    failed: boolean
    date: string
    total: number
}

export const ChartGroup = (props: ChartGroupProps): JSX.Element => {
    const {
        simplified = false,
        barGroup,
        gradientWidth,
        gradientHeight,
        onMouseLeaveHandler,
        onMouseMoveHandler,
        failed,
        date,
        total
    } = props
    const [hideGradient, setHideGradient] = useState(true)

    const onMouseLeave = () => {
        setHideGradient(true)

        onMouseLeaveHandler()
    }

    const onMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        setHideGradient(false)

        onMouseMoveHandler(event)({...barGroup, date, total} as CustomBarGroup<string>)
    }

    const getGradientFill = (hideGradient: boolean): string => {
        return hideGradient ? 'transparent' : `url(#${GROUP_ID})`
    }

    return (
        <Fragment>
            {!simplified && (
                <Fragment>
                    {!hideGradient && (
                        <LinearGradient
                            id={GROUP_ID}
                            from={failed ? transparentize(1, variables.red) : variables.interaction20}
                            to={failed ? transparentize(0.74, variables.red) : variables.interaction20}
                        />
                    )}
                    <rect
                        x={barGroup.x0}
                        width={gradientWidth}
                        height={gradientHeight}
                        fill={getGradientFill(hideGradient)}
                        onMouseLeave={onMouseLeave}
                        onMouseMove={onMouseMove}
                    />
                </Fragment>
            )}
            {failed && barGroup.bars.length > 0 ? (
                <rect
                    x={barGroup.x0}
                    y={gradientHeight - failureBarHeight}
                    width={gradientWidth}
                    height={failureBarHeight}
                    fill={variables.red}
                />
            ) : (
                <Group left={barGroup.x0} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
                    {barGroup.bars.map(bar => {
                        const isNegativeBar = bar.value <= 0
                        const barIndex = `${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`

                        return (
                            <>
                                {!simplified && !isNegativeBar && (
                                    <defs>
                                        <clipPath id={`clip-${barIndex}`}>
                                            <rect
                                                x={bar.x}
                                                y={bar.y}
                                                width={bar.width}
                                                height={bar.height + 5}
                                                rx="5"
                                                ry="5"
                                            />
                                        </clipPath>
                                    </defs>
                                )}
                                <rect
                                    key={`bar-group-bar-${barIndex}`}
                                    clipPath={!simplified && !isNegativeBar ? `url(#clip-${barIndex})` : undefined}
                                    x={bar.x}
                                    y={isNegativeBar ? gradientHeight - failureBarHeight : bar.y}
                                    width={bar.width}
                                    height={isNegativeBar ? failureBarHeight : bar.height}
                                    fill={bar.color}
                                />
                            </>
                        )
                    })}
                </Group>
            )}
        </Fragment>
    )
}
