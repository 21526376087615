import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum'
import {SourceIcon} from 'ca-common/components/SourceIcon'

import {BackupAuthHeader, HeaderBase} from './StyledHeader'

type Props = {
    howTo?: React.ReactNode
    source: SOURCE
    failed?: boolean
    titleText?: string
}

export const Header = ({howTo = null, source, failed, titleText}: Props): JSX.Element => {
    const {t} = useTranslation()
    const title = useMemo(() => {
        if (failed) {
            return t('forms:backupAuth:text:modalTitleFailed')
        }

        if (titleText) {
            return titleText
        }

        return t('forms:backupAuth:text:modalTitle')
    }, [failed, titleText])

    return (
        <BackupAuthHeader>
            <HeaderBase>
                <SourceIcon source={source} fontSize={48} />
                <h3>{title}</h3>
            </HeaderBase>

            {howTo}
        </BackupAuthHeader>
    )
}
