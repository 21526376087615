import React from 'react'
import {
    AdvancedRecoveryOptions,
    FullMailBoxRestoreOrExport,
    FullMailboxDateConfigure,
    Confirmation
} from 'src/newcore/features/RecoveryWizard/templates'
import {SelectionOfMailbox} from 'src/newcore/features/RecoveryWizard/templates/SelectionOfMailbox'
import {WizardState} from 'src/newcore/redux/modules/wizard'
import {PAGES} from 'src/ca-common/constants'

export const getRecoverySteps = (wizard: WizardState): React.FC[] => {
    const referral = wizard?.data?.from
    switch (referral) {
        case PAGES.HOMEPAGE:
            return [
                SelectionOfMailbox,
                AdvancedRecoveryOptions,
                FullMailboxDateConfigure,
                FullMailBoxRestoreOrExport,
                Confirmation
            ]
        case PAGES.RECOVERY:
            return [FullMailBoxRestoreOrExport, Confirmation]
        default:
            return [AdvancedRecoveryOptions, FullMailboxDateConfigure, FullMailBoxRestoreOrExport, Confirmation]
    }
}
