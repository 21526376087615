import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Wrapper = styled.div`
    display: flex;
    gap: ${variables.gutterLg};

    .ant-form-item {
        margin: 0;
        width: 128px;
    }

    .ant-form-item-label {
        font-weight: 600;
    }
`
