import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {SOURCE} from 'ca-common/common/enum'
import {TagsColumn} from 'ca-common/ui-lib/molecules/TagsColumn'
import type {MSTaskEntityType} from 'ca-common/types'
import {TextWithInfo} from 'ca-common/ui-lib/atoms/TextWithInfo'

import {EntitiesTaskStatus} from 'src/newcore/molecules/EntitiesTaskStatus'
import {BackupActions} from 'src/newcore/components/BackupActions'
import {RecoveryActionButton} from 'src/newcore/features/Recovery/atoms'
import {LimitedAccess} from 'src/newcore/molecules/LimitedAccess'

import {LastBackup, Name} from '../CellModifier'
import {StyledActions} from '../StyledBackupEntities'
import {ACTIONS_WIDTH, BACKUP_SIZE_WIDTH, LAST_BACKUP_WIDTH, LICENSING_WIDTH, TAGS_WIDTH, TASK_STATUS_WIDTH} from '.'
import {BackupEntity} from 'src/newcore/redux/modules/backups'

export const ms365 = (source: SOURCE) => [
    {
        title: getSvcAccountsDescription(source),
        dataIndex: 'name',
        render: (name: string, entity: BackupEntity) => <Name name={name} entity={entity} />,
        onCell: () => ({'data-hj-suppress': true}),
        ellipsis: true,
        sorter: true
    },
    {
        title: i18n.t('table:columns:email'),
        dataIndex: 'entity',
        render: (entity: string) => <Name name={entity} />,
        ellipsis: true,
        sorter: true
    },
    {
        title: i18n.t('table:columns:tags'),
        render: (entity: any) => (
            <>
                <TagsColumn entity={entity} />
                {entity.limitedAccess && <LimitedAccess isBackground={true} />}
            </>
        ),
        width: TAGS_WIDTH
    },
    {
        title: (
            <TextWithInfo
                title={i18n.t('table:columns:MSLicensing')}
                tooltipText={i18n.t('backups:licensingTooltip')}
            />
        ),
        dataIndex: 'licenseStatus',
        render: (_: any, entity: MSTaskEntityType) =>
            entity.licenseStatus && i18n.t(`backups:licenseStatus:${entity.licenseStatus}`),
        width: LICENSING_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:status'),
        dataIndex: 'status',
        render: (_: any, entity: MSTaskEntityType) => (
            <EntitiesTaskStatus progressMulti={entity.progressData} status={entity.status} isMulti />
        ),
        width: TASK_STATUS_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:backupSize'),
        dataIndex: 'size',
        width: BACKUP_SIZE_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:lastBackup'),
        dataIndex: 'lastBackup',
        render: (_: any, entity: MSTaskEntityType) => <LastBackup entity={entity} />,
        width: LAST_BACKUP_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:actions'),
        render: (_: any, entity: MSTaskEntityType) => (
            <StyledActions>
                <RecoveryActionButton entityInfo={entity} source={source} />
                <BackupActions taskInfo={entity} performEntities={[entity.id]} source={source} />
            </StyledActions>
        ),
        width: ACTIONS_WIDTH
    },
    Table.EXPAND_COLUMN
]
