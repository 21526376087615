import styled, {css} from 'styled-components'
import {DatePicker} from 'antd'
import {RangePickerProps} from 'antd/lib/date-picker'
import variables from 'ca-common/variables'

export const RangePickerGlobalStyle = css`
    //DatePicker Calendar
    .ant-picker-date-panel .ant-picker-content {
        .ant-picker-cell:not(.ant-picker-cell-disabled).ant-picker-cell-in-view {
            .ant-picker-cell-inner {
                color: ${variables.navyAsphalt};
                font-family: ${variables.mainFont};
                border-radius: ${variables.borderRadiusBase} !important;
                position: relative;
            }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
            .ant-picker-cell-inner {
                color: ${variables.navyBlue};
            }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
            .ant-picker-cell-inner {
                color: ${variables.white};
                background: ${variables.navyBlue};
            }
        }

        th {
            color: ${variables.navyBlue};
            font-family: ${variables.mainFont};
            font-size: ${variables.fontSizeSecondary};
        }

        .ant-picker-cell-today {
            .ant-picker-cell-inner {
                border-color: ${variables.navyAsphalt};
                border-radius: ${variables.borderRadiusBase} !important;
            }
        }
    }
`

export const StyledRangePicker = styled(DatePicker.RangePicker)<RangePickerProps>`
    &&&&& {
        width: 230px;
        border-radius: 22px;
        border-width: 2px !important;
        padding: 3px ${variables.gutterXs};
        min-height: 32px;
        background-color: ${props => (props.value?.length ? variables.navyBlue : variables.white)};
        color: ${props => (props.value?.length ? variables.white : variables.navyBlue)};
        border-color: ${variables.navyBlue};

        :hover {
            cursor: pointer;
        }
    }

    .ant-picker-range-separator {
        min-width: 2px;
        font-weight: 600;
        color: inherit;
        padding: 0 8px 0 0;
    }

    .ant-picker-suffix {
        svg {
            fill: transparent;
        }
    }

    .ant-picker-active-bar {
        margin-left: 7px;
        width: 75px !important;
        background-color: ${variables.navyBlue};
    }

    .ant-picker-icon,
    .ant-picker-clear,
    .ant-picker-suffix {
        color: inherit;
        background: ${props => (props.value?.length ? 'transparent' : variables.white)};
        transition: opacity 300ms ease-in-out;
    }

    .ant-picker-clear {
        border-radius: 50%;
        border: 3px solid ${props => (props.value?.length ? variables.navyBlue : variables.white)};
    }

    .ant-picker-suffix {
        margin-left: 2px;
    }

    .ant-picker-input {
        width: 87px;
        top: 1px;

        input {
            font-family: ${variables.accentFont};
            font-weight: 700;
            font-size: ${variables.fontSizeBase};
            line-height: 16px;
            color: ${props => (props.value?.length ? variables.white : variables.navyBlue)} !important;
            transition: 0s !important;

            ::placeholder {
                color: ${variables.navyBlue};
            }
        }
    }

    &:not(.ant-picker-disabled):hover {
        background: ${variables.blueLight};
        color: ${variables.navyBlue};

        .ant-picker-suffix {
            opacity: ${props => (props.value?.length ? '0' : '1')};
        }
    }

    &&&&&.ant-picker-disabled {
        border-color: ${variables.asphaltLight};
        background: ${variables.blueLight};
        color: ${variables.asphaltLight};
        cursor: not-allowed;

        input {
            color: ${variables.asphaltLight} !important;

            ::placeholder {
                color: ${variables.asphaltLight};
            }
        }

        .ant-picker-active-bar {
            background-color: ${variables.asphaltLight};
        }

        .ant-picker-suffix {
            background: transparent;
        }
    }
`
