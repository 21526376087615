import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'
import {StyledModal, StyledTitle} from './StyledLimitedAccessModal'

type ModalProps = {
    modalVisible: boolean
    updateModal: (val: boolean) => any
}

export const LimitedAccessModal = ({modalVisible, updateModal}: ModalProps) => {
    const {t} = useTranslation()
    return (
        <StyledModal
            centered
            width={600}
            open={modalVisible}
            destroyOnClose={true}
            onCancel={() => updateModal(false)}
            title={
                <StyledTitle>
                    <Trans i18nKey="forms:backupTags:limitedAccessModal:header" />
                </StyledTitle>
            }
            footer={[
                <Button type="link" onClick={() => updateModal(false)}>
                    {t('modals:buttons:cancel')}
                </Button>,
                <Button type="primary" onClick={() => updateModal(true)}>
                    {t('modals:buttons:yes')}
                </Button>
            ]}
        >
            <Trans i18nKey="forms:backupTags:limitedAccessModal:message" />
        </StyledModal>
    )
}
