import {createAction, createReducer} from '@reduxjs/toolkit'
import {PANES_KEYS} from 'src/newcore/components/SFCTabs'

const BACKUP_TASK_SETTINGS_SET_ACTIVE_TAB = 'BACKUP_TASK_SETTINGS_SET_ACTIVE_TAB'

export const setActiveTab = createAction<PANES_KEYS>(BACKUP_TASK_SETTINGS_SET_ACTIVE_TAB)

export const backupTaskSettingsActiveTab = createReducer(PANES_KEYS.COMPARES, builder => {
    builder.addCase(setActiveTab, (state, action) => action.payload)
})
