import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'

import {COMPARE_RESULT_TYPE} from 'ca-common/common/enum/CompareItem'

import {useAppDispatch} from 'src/newcore/components/Root'
import {getCompareForFile} from 'src/newcore/redux/modules/backups/backupCompare'

import {CompareDiffItem} from './CompareDiffItem'
import {StyledWrapper} from './StyledCompareDiffItem'

const CompareDiffRaw = props => {
    const [firstFile, setFirstFile] = useState()
    const [secondFile, setSecondFile] = useState()
    const {resultId, fileId, outputFormat} = props
    const dispatch = useAppDispatch()

    useEffect(() => {
        const {resultType} = props.match.params
        if (resultType === COMPARE_RESULT_TYPE.UNIQUE_LEFT || resultType === COMPARE_RESULT_TYPE.DIFF) {
            dispatch(
                getCompareForFile({
                    object: props.match.params.objectName,
                    part: 'first',
                    resultId,
                    fileNumber: fileId,
                    compareType: props.match.params.resultType
                })
            )
                .unwrap()
                .then(response => {
                    setFirstFile(response)
                })
        } else {
            setFirstFile('')
        }

        if (resultType === COMPARE_RESULT_TYPE.UNIQUE_RIGHT || resultType === COMPARE_RESULT_TYPE.DIFF) {
            dispatch(
                getCompareForFile({
                    object: props.match.params.objectName,
                    part: 'second',
                    resultId,
                    fileNumber: fileId,
                    compareType: props.match.params.resultType
                })
            )
                .unwrap()
                .then(response => {
                    setSecondFile(response)
                })
        } else {
            setSecondFile('')
        }
    }, [])

    // firstFile and secondFile are strings only when all queries are fullfilled
    const compareReady = _.isString(firstFile) && _.isString(secondFile)
    return (
        <StyledWrapper>
            <Spin size="small" spinning={!compareReady}>
                {compareReady && <CompareDiffItem outputFormat={outputFormat} first={firstFile} second={secondFile} />}
            </Spin>
        </StyledWrapper>
    )
}

export const CompareDiff = withRouter(CompareDiffRaw)
