import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Trans, withTranslation} from 'react-i18next'

import {eatModal} from 'ca-common/redux/modules/modal'
import {checkReportFile} from 'src/newcore/redux/modules/notifications'
import {openErrorModal} from 'ca-common/utils/modals'

@withTranslation()
class Download extends Component {
    state = {
        link: null
    }
    componentDidMount() {
        const {match, actions} = this.props

        actions
            .checkReportFile({
                date: match.params.id
            })
            .then(() => {
                const link = `${window.location.origin}/application/service/downloadReport?date=${match.params.id}`

                this.setState({
                    link
                })

                setTimeout(() => {
                    window.open(link)
                }, 100)
            })
            .catch(error => openErrorModal(`${error.status}`))
    }

    render = () => {
        const {link} = this.state

        return (
            link && (
                <div>
                    <Trans i18nKey="notifications:downloadFallback">
                        <a data-auto-download href={link}>
                            here
                        </a>
                    </Trans>
                </div>
            )
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                checkReportFile,
                eatModal
            },
            dispatch
        )
    }
})

export const DownloadPage = connect(null, mapDispatchToProps)(Download)
