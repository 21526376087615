import i18n from 'i18next'
import moment from 'moment'
import {FULL_FORMAT, getUTCDateFromString, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

export const compareDateFormat = date => {
    return moment(getUTCDateFromString(date), LONG_DATE_FORMAT).format(FULL_FORMAT)
}

export const compareDateDescription = ({date, isSandbox}) => {
    if (date === -1) {
        if (isSandbox) {
            return i18n.t('compareSnapshot:sandbox')
        }
        return i18n.t('compareSnapshot:production')
    }
    return compareDateFormat(date)
}
