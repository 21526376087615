import styled from 'styled-components'
import {InputNumber} from 'antd'

import variables from 'ca-common/variables'
import {ModalCenterWrapper} from 'ca-common/components/Modal'

export const StyledIncludeAttachments = styled.div`
    background-color: ${variables.blueLight};
    height: 40px;
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 ${variables.gutterMd};
`

export const StyledModalContentWrapper = styled(ModalCenterWrapper)`
    display: flex;
    align-items: center;
`

export const StyledDepth = styled.div`
    margin-left: 45px;
    display: flex;
    align-items: center;
`

export const StyledInputNumber = styled(InputNumber)`
    margin: 0 ${variables.gutterXs};
`
