import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {SEEDING_OBJECT_TYPES} from 'ca-common/common/enum/seeding'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {
    changeConfig,
    changeData,
    setWizardDescription,
    setWizardTitle,
    wizardDataSelector,
    wizardStepSelector
} from 'src/newcore/redux/modules/wizard'

import {
    useGetObjectsQuery,
    dataAnonymizationApi,
    objectsListSelector
} from 'src/newcore/components/Seeding/DataAnonymizationStep/redux'
import {
    MODAL_WIDTH,
    useFooterProps,
    useAnonymizationSettingsChanges,
    useBeforeAnonymizationSettingsStepChange
} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib'

import type {
    Field,
    GetAnonymizationObjectsPayload,
    WizardData
} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'
import {ObjectAnonymization} from 'src/newcore/components/Seeding/DataAnonymizationStep/ObjectAnonymization'
import {ObjectsList} from 'src/newcore/components/Seeding/DataAnonymizationStep/ObjectsList'

import {
    Body,
    ColumnsWrapper,
    DefaultSystemTemplateButton,
    Description,
    ObjectAnonymizationWrapper,
    ObjectListWrapper,
    ResetToDefaultSystemTemplateButton,
    Subheader
} from './StyledDataAnonymizationStep'

export const DataAnonymizationStep = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const {onClose, onNext, onBack} = useFooterProps()
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)
    const selectedObjects = useAppSelector(objectsListSelector)
    const currentStep = useAppSelector(wizardStepSelector)
    const wizardData = useAppSelector(wizardDataSelector) as WizardData

    const [activeObjectName, setActiveObjectName] = useState<string | undefined>()

    const isCurrentStepActive = useMemo(() => {
        if (wizardData.objectType === SEEDING_OBJECT_TYPES.FULL) {
            return currentStep === 2
        }

        return currentStep === 4
    }, [currentStep, wizardData.objectType])

    const {
        anonymizationSettingsChanges,
        setAnonymizationSettingsChanges,
        resetAnonymizationChanges
    } = useAnonymizationSettingsChanges(wizardData.anonymizationSettingsChanges || {})

    const isDefaultTemplate = isEmpty(anonymizationSettingsChanges)

    const {reinitBeforeStepChange} = useBeforeAnonymizationSettingsStepChange(
        anonymizationSettingsChanges,
        isCurrentStepActive
    )

    const onAnonymizationChange = (field: string, value: Partial<Field>) => {
        const newAnonymizationSettingsChanges = setAnonymizationSettingsChanges(
            activeObjectName as string,
            field,
            value
        )
        reinitBeforeStepChange(newAnonymizationSettingsChanges)
    }

    const onResetAnonymizationChanges = () => {
        resetAnonymizationChanges()
        reinitBeforeStepChange({})
    }

    const payload: GetAnonymizationObjectsPayload = {
        date: wizardData.date,
        taskId: taskSettings?.taskId,
        objects: wizardData.objectType === SEEDING_OBJECT_TYPES.FULL ? [] : selectedObjects
    }

    const {data, isFetching} = useGetObjectsQuery(
        {
            payload
        },
        {
            skip: !isCurrentStepActive
        }
    )

    const fields = useMemo(() => {
        return data?.data?.find(item => item.name === activeObjectName)?.fields || []
    }, [data, activeObjectName])

    useEffect(() => {
        dispatch(changeData({anonymizationSettingsChanges: {}}))
        dispatch(dataAnonymizationApi.util.resetApiState())
    }, [wizardData.objectType, wizardData.dataType, wizardData.date, JSON.stringify(selectedObjects)])

    useEffect(() => {
        if (isCurrentStepActive) {
            dispatch(changeConfig({modalWidth: MODAL_WIDTH}))
            dispatch(setWizardTitle(`${taskSettings?.alias} ${t('seeding:secondStep:title')}`))
            dispatch(setWizardDescription(t('seeding:dataAnonymizationStep:description')))
        }
    }, [isCurrentStepActive])

    useEffect(() => {
        resetAnonymizationChanges()
    }, [JSON.stringify(selectedObjects)])

    return (
        <div>
            <Body>
                <Subheader>
                    <Description>{t('seeding:dataAnonymizationStep:subtitle')}</Description>
                    {isDefaultTemplate ? (
                        <DefaultSystemTemplateButton>
                            {t('seeding:dataAnonymizationStep:defaultSystemTemplate')}
                        </DefaultSystemTemplateButton>
                    ) : (
                        <ResetToDefaultSystemTemplateButton onClick={onResetAnonymizationChanges}>
                            {t('seeding:dataAnonymizationStep:resetToDefaultSystemTemplate')}
                        </ResetToDefaultSystemTemplateButton>
                    )}
                </Subheader>
                <ColumnsWrapper>
                    {data && !isFetching ? (
                        <>
                            <ObjectListWrapper>
                                <ObjectsList
                                    activeObject={activeObjectName}
                                    setActiveObject={setActiveObjectName}
                                    objectNameList={data.data.map(({name}) => name)}
                                />
                            </ObjectListWrapper>
                            <ObjectAnonymizationWrapper>
                                <ObjectAnonymization
                                    preview={data.preview}
                                    randomPreview={data.randomPreview}
                                    fields={fields}
                                    onAnonymizationChange={onAnonymizationChange}
                                    anonymizationChanges={anonymizationSettingsChanges[activeObjectName as string]}
                                />
                            </ObjectAnonymizationWrapper>
                        </>
                    ) : (
                        <Spinner modifier="table" />
                    )}
                </ColumnsWrapper>
            </Body>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onClose}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={onNext}>
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={onBack} />
            </ModalFooter>
        </div>
    )
}
