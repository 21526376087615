import React from 'react'
import {useTranslation} from 'react-i18next'

import {Title} from './StyledCountBackupTitle'

type CountBackupTitleProps = {
    count: number
}
export const CountBackupTitle = ({count}: CountBackupTitleProps): JSX.Element => {
    const {t} = useTranslation()

    return <Title data-inlinemanual="InlineManual-homepage-title">{t(`homepage:title`, {count})}</Title>
}
