import bowser from 'bowser'
import i18n from 'i18next'

const MOBILE = 'mobile'

export const getBrowser = () => {
    return bowser.getParser(window.navigator.userAgent)
}

export const getPlatformType = browser => {
    return browser.getPlatformType()
}

export const isMobilePlatform = platformType => {
    return platformType === MOBILE
}

export const showMobileAlert = () => {
    const isMobile = isMobilePlatform(getPlatformType(getBrowser()))

    if (isMobile) {
        alert(i18n.t('screenResolution:alert')) // eslint-disable-line no-alert
    }
}
