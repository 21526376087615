import styled from 'styled-components'

import {Button} from '../Button'
import variables from 'ca-common/variables'

export const StyledAddNewButton = styled(Button)`
    padding: 0;
    color: ${variables.navyBlue};
    box-sizing: content-box;

    .anticon {
        border-radius: 50%;
        font-size: 32px;
        height: 32px;
    }

    span {
        vertical-align: middle;
    }

    svg {
        transition: fill 300ms ease-in-out;
        fill: transparent;
    }

    &:hover {
        color: ${variables.navyBlue};

        svg {
            fill: ${variables.navyBlue};

            path {
                fill: ${variables.white};
            }
        }
    }

    &:focus {
        color: ${variables.navyBlue};
        box-shadow: none;
        .anticon {
            box-shadow: 0 0 0 2px rgba(38, 45, 97, 0.2);
        }
    }

    &:disabled,
    &:disabled:hover {
        background: transparent;
        border: none;

        span {
            color: ${variables.blue};
        }

        svg {
            fill: ${variables.blueLight};

            path {
                fill: ${variables.blue};
            }

            circle {
                stroke: ${variables.blue};
            }
        }
    }
`
