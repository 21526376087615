import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {Field, formValueSelector, change} from 'redux-form'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {every} from 'lodash'

import {CARow, CACol} from 'ca-common/ui-lib/components/Grid'
import LabeledInput from 'ca-common/ui-lib/components/ReduxFormElements/LabeledInput'
import {ReactSelect} from 'ca-common/ui-lib/components/ReduxFormElements/ReactSelect'
import {requiredValidate, emailValidate, passwordValidate} from 'ca-common/utils/validation'
import {CREDENTIAL_TYPE_SELECT, ROLE_OPTIONS, CREDENTIALS_TYPES, ACCESS_LEVEL} from 'ca-common/constants'

@withTranslation()
class UserDetailRaw extends Component {
    static propTypes = {
        isNew: PropTypes.bool.isRequired,
        form: PropTypes.string.isRequired,
        isPP: PropTypes.bool
    }

    componentDidUpdate = prevProps => {
        const {actions, roleByCheckboxes} = this.props

        if (prevProps.roleByCheckboxes !== roleByCheckboxes && prevProps.roleByCheckboxes === ACCESS_LEVEL.FULL) {
            actions.setValue('credentials.role', roleByCheckboxes)
        }
    }

    render = () => {
        const {isNew, disableOAuth, isEmail, t, handleRole, isPP} = this.props
        const credentialTypeOptions = () => {
            if (disableOAuth) {
                return CREDENTIAL_TYPE_SELECT.slice(0, 2)
            } else if (isPP) {
                return CREDENTIAL_TYPE_SELECT.filter(cType => cType.value !== CREDENTIALS_TYPES.OKTA)
            } else {
                return CREDENTIAL_TYPE_SELECT
            }
        }

        return (
            <div>
                <CARow>
                    <CACol>
                        <Field
                            component={LabeledInput}
                            disabled={!isNew}
                            htmlFor="credentials.email"
                            label={`${t('userManagement:email')}:`}
                            name="credentials.email"
                            type="text"
                            validate={[requiredValidate, emailValidate]}
                        />
                    </CACol>
                    <CACol>
                        <Field
                            component={ReactSelect}
                            disabled={!isNew}
                            htmlFor="credentials.type"
                            label={`${t('userManagement:type')}:`}
                            name="credentials.type"
                            options={credentialTypeOptions()}
                            placeholder={t('userManagement:typePlaceholder')}
                            type="select"
                            validate={[requiredValidate]}
                        />
                    </CACol>
                </CARow>
                <CARow>
                    <CACol>
                        {isEmail && isNew && (
                            <Field
                                component={LabeledInput}
                                htmlFor="credentials.password"
                                label={t('userManagement:password')}
                                name="credentials.password"
                                type="password"
                                validate={[requiredValidate, passwordValidate]}
                            />
                        )}
                    </CACol>
                    <CACol>
                        <Field
                            component={ReactSelect}
                            htmlFor="credentials.role"
                            label={t('userManagement:role')}
                            name="credentials.role"
                            options={ROLE_OPTIONS}
                            placeholder={t('userManagement:rolePlaceholder')}
                            type="select"
                            onChange={handleRole}
                        />
                    </CACol>
                </CARow>
            </div>
        )
    }
}

const mapStateToProps = (state, {form, groups}) => {
    const selector = formValueSelector(form)
    const getRole = () => {
        const isCheckedEveryAll = every(groups, group => {
            return selector(state, `permissions.${group.label}.${group.name}`)
        })

        return isCheckedEveryAll ? ACCESS_LEVEL.FULL : ACCESS_LEVEL.CUSTOM
    }

    return {
        isEmail: selector(state, 'credentials.type') === CREDENTIALS_TYPES.EMAIL,
        roleByCheckboxes: getRole()
    }
}

const mapDispatchToProps = (dispatch, {form}) => ({
    actions: {
        ...bindActionCreators(
            {
                setValue: (name, value) => dispatch(change(form, name, value))
            },
            dispatch
        )
    }
})

export const UserDetail = connect(mapStateToProps, mapDispatchToProps)(UserDetailRaw)
