import styled, {keyframes} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {SUBSOURCE_PROGRESS_STATUS} from 'ca-common/common/enum'

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const getSubSourceColorFromStatus = (status: SUBSOURCE_PROGRESS_STATUS) => {
    switch (status) {
        case SUBSOURCE_PROGRESS_STATUS.PENDING:
            return variables.asphaltLight
        case SUBSOURCE_PROGRESS_STATUS.DONE:
        case SUBSOURCE_PROGRESS_STATUS.UNAVAILABLE:
            return variables.navyBlue
        default:
            return variables.inProcess
    }
}

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ItemRow = styled.div<{status: SUBSOURCE_PROGRESS_STATUS}>`
    display: flex;
    width: 100%;
    color: ${props => getSubSourceColorFromStatus(props.status)};
    font-weight: ${props => (props.status === SUBSOURCE_PROGRESS_STATUS.INPROGRESS ? 600 : 400)};
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }
`

export const ItemIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const ItemName = styled.p`
    margin: 0;
`
export const ItemCount = styled.p`
    margin: 0;
`

export const SubSourceIcon = styled(Icon)<{status: SUBSOURCE_PROGRESS_STATUS}>`
    animation: ${spinner} 2s infinite linear;
    margin-right: 4px;
    font-size: 8px;
    circle {
        color: ${props =>
            props.status === SUBSOURCE_PROGRESS_STATUS.PENDING ? variables.asphaltLight : variables.navyBlue};
    }
    path {
        display: ${props => props.status !== SUBSOURCE_PROGRESS_STATUS.INPROGRESS && 'none'};
    }
`
