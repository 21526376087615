import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TablePaginationConfig, TableProps} from 'antd'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router'

import type {SecurityAuditItem} from 'src/newcore/features/SecurityAudit/types'
import {useColumns} from 'src/newcore/features/SecurityAudit/lib/useColumns'
import {PAGE_SIZE, TABLE_X_SCROLL_AFTER} from 'src/newcore/features/SecurityAudit/lib/constants'

import {StyledTable} from './StyledSecurityAuditTable'

type SecurityAuditTableProps = {
    status: boolean
    data: SecurityAuditItem[]
    page: number
    fetchItemsByPage: (page: number) => void
}

export const SecurityAuditTable = (props: SecurityAuditTableProps) => {
    const {status, data, page, fetchItemsByPage} = props
    const location = useLocation()
    const history = useHistory()
    const {t} = useTranslation()
    const columns = useColumns()

    const {start, end, total} = useMemo(() => {
        const start = (page - 1) * PAGE_SIZE + 1
        const end = Math.max(start, start + (data?.length || 0) - 1)
        const total = start + (data?.length || 0)

        return {start, end, total}
    }, [data, page])

    const onPageChange = (pagination?: TablePaginationConfig) => {
        const page = pagination?.current || 1
        const query = queryString.parse(location.search)
        const params = queryString.stringify({...query, page})
        history.replace(`${location.pathname}?${params}`)
        fetchItemsByPage(page)
    }

    return (
        <StyledTable<(props: TableProps<SecurityAuditItem>) => JSX.Element>
            rowKey={item => item.id}
            columns={columns}
            dataSource={data}
            scroll={{x: TABLE_X_SCROLL_AFTER}}
            loading={!status}
            pagination={{
                hideOnSinglePage: true,
                pageSize: PAGE_SIZE,
                showSizeChanger: false,
                showTotal: () =>
                    t('table:footer:noTotalItemPagination', {
                        start,
                        end
                    }),
                size: 'small',
                total,
                current: page
            }}
            onChange={onPageChange}
        />
    )
}
