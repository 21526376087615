import styled from 'styled-components'

export const StyledPayInvoiceWrapper = styled.span`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

export const StyledTooltipWrapper = styled.span`
    margin-top: 4px;
`
