import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHowTo = styled.span`
    color: ${variables.navyBlue};
    font-weight: 600;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
`
