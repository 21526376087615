import {Select, Form} from 'antd'
import React from 'react'
import {withTranslation} from 'react-i18next'
import _ from 'lodash'

import {VerticalFormItem, Label} from 'ca-common/ui-lib/components/FormItem'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {SNAPSHOT_TYPE} from 'ca-common/common/enum/Backups'
import {SFC_TYPE} from 'ca-common/common/enum/SalesforceType'

import {SnapshotCalendar} from 'src/newcore/organisms/SnapshotCalendar'
import {Wrapper, InputGroupWrapper, AccountName} from '../StyledCompareSnapshot'

const {Option} = Select

export const SelectCompareSnapshot = withTranslation()(
    class extends React.Component {
        state = {
            selectedBlock: SNAPSHOT_TYPE.BACKUP
        }

        componentDidMount() {
            this.props.addDataToRequest({
                snapshotType: this.state.selectedBlock
            })
        }

        onRadioBlockChange = e => {
            this.setState({
                selectedBlock: e.target.value
            })

            this.props.addDataToRequest({
                snapshotType: e.target.value
            })

            this.props.form.setFieldsValue({
                compareDate: null
            })
        }

        onBackupTaskSelect = taskId => {
            const {form, setCurrentTask, SFCTasks} = this.props

            form.setFieldsValue({
                compareDate: null
            })

            setCurrentTask(_.find(SFCTasks, {id: taskId}))
        }

        render() {
            const {selectedBlock} = this.state
            const {t, SFCTasks, form, currentTask, isMetadata} = this.props
            const compareTaskId = form.getFieldValue('compareTaskId') || currentTask.id

            return (
                <Wrapper>
                    <RadioBlock
                        checked={selectedBlock === SNAPSHOT_TYPE.BACKUP}
                        title={t('compareSnapshot:backup')}
                        value={SNAPSHOT_TYPE.BACKUP}
                        onChange={this.onRadioBlockChange}
                    >
                        {selectedBlock === SNAPSHOT_TYPE.BACKUP && (
                            <InputGroupWrapper>
                                <VerticalFormItem
                                    label={
                                        <Label>
                                            <span>{t('forms:compareSnapshots:fields:backupTask:label')}</span>
                                        </Label>
                                    }
                                    colon={false}
                                >
                                    <Form.Item
                                        name="compareTaskId"
                                        onClick={e => e.preventDefault()}
                                        rules={[
                                            {
                                                required: selectedBlock === SNAPSHOT_TYPE.BACKUP,
                                                message: t('forms:compareSnapshots:fields:backupTask:message')
                                            }
                                        ]}
                                        initialValue={currentTask.id}
                                    >
                                        <Select
                                            style={{width: '100%'}}
                                            onChange={value => this.onBackupTaskSelect(value)}
                                            getPopupContainer={t => t.parentElement}
                                        >
                                            {_.map(SFCTasks, task => (
                                                <Option value={task.id} key={task.id}>
                                                    {task.alias}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </VerticalFormItem>
                                <VerticalFormItem>
                                    <Form.Item
                                        name="compareDate"
                                        rules={[
                                            {
                                                required: selectedBlock === SNAPSHOT_TYPE.BACKUP,
                                                message: t('forms:compareSnapshots:fields:snapShotDate:message')
                                            }
                                        ]}
                                    >
                                        <SnapshotCalendar
                                            flexFlow={'column'}
                                            payload={{taskId: compareTaskId}}
                                            firstBackupDate={currentTask.firstBackupDate}
                                            retentionDate={currentTask.retentionDate}
                                        />
                                    </Form.Item>
                                </VerticalFormItem>
                            </InputGroupWrapper>
                        )}
                    </RadioBlock>
                    <RadioBlock
                        checked={selectedBlock === SNAPSHOT_TYPE.SALESFORCE}
                        title={t('compareSnapshot:salesforce:title')}
                        value={SNAPSHOT_TYPE.SALESFORCE}
                        onChange={this.onRadioBlockChange}
                    >
                        {selectedBlock === SNAPSHOT_TYPE.SALESFORCE && (
                            <div>
                                <p>{t('compareSnapshot:salesforce:description')}</p>
                                <AccountName>{currentTask.account}</AccountName>
                            </div>
                        )}
                    </RadioBlock>
                    {!!isMetadata && (
                        <RadioBlock
                            checked={selectedBlock === SNAPSHOT_TYPE.OTHER}
                            title={t('compareSnapshot:other:title')}
                            value={SNAPSHOT_TYPE.OTHER}
                            onChange={this.onRadioBlockChange}
                        >
                            {selectedBlock === SNAPSHOT_TYPE.OTHER && (
                                <div>
                                    <p>{t('compareSnapshot:other:description')}</p>
                                    <Form.Item name="sfcType" initialValue={SFC_TYPE.PRODUCTION}>
                                        <Select style={{width: '100%'}} getPopupContainer={t => t.parentElement}>
                                            <Option value={SFC_TYPE.PRODUCTION}>
                                                {t('compareSnapshot:production')}
                                            </Option>
                                            <Option value={SFC_TYPE.SANDBOX}>{t('compareSnapshot:sandbox')}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}
                        </RadioBlock>
                    )}
                </Wrapper>
            )
        }
    }
)
