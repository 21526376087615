import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledBackupEntitiesHeader = styled.div`
    padding: ${variables.gutterMd};
    display: flex;
    flex-direction: column;
`

export const ActionsBar = styled.div`
    display: flex;
`

export const FirstRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
