import React, {PropsWithChildren} from 'react'

import {StyledStatusItem, Title, ValueWrapper} from './StyledStatusItem'

type StatusItemProps = PropsWithChildren<{
    title: string
}>

export const StatusItem = (props: StatusItemProps) => {
    const {children, title} = props

    return (
        <StyledStatusItem>
            <Title>{title}</Title>
            <ValueWrapper>{children}</ValueWrapper>
        </StyledStatusItem>
    )
}
