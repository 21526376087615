import React, {Fragment, useState} from 'react'
import Icon from '@ant-design/icons'
import {Form, Row, Col, Radio, RadioChangeEvent} from 'antd'
import {useTranslation} from 'react-i18next'

import {DISABLE_UNLICENSED_OPTIONS} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms'

import Info from 'ca-common/icons/Info.svg'

import {Header, HeaderTitle, Body, Footer, StyledInfo, Text, RadioGroup} from './StyledBackupUnlicensedAccounts'

type BackupUnlicensedAccountsFormProps = {
    onCancel: () => void
    onSubmit: () => void
    namePrefix?: string
}

export const BackupUnlicensedAccountsForm = ({onCancel, onSubmit, namePrefix}: BackupUnlicensedAccountsFormProps) => {
    const {t} = useTranslation()
    const [group, setGroup] = useState(DISABLE_UNLICENSED_OPTIONS.PAUSE)
    const name = `${namePrefix ? `${namePrefix}.` : ''}disableUnlicensedOption`

    const onChange = (event: RadioChangeEvent) => {
        const value = event?.target?.value

        if (value && value !== group) {
            setGroup(value)
        }
    }

    return (
        <Fragment>
            <Header>
                <HeaderTitle>{t('forms:backupUnlicensedAccounts:title')}</HeaderTitle>
            </Header>
            <Body>
                <p>{t('forms:backupUnlicensedAccounts:text')}</p>
                <Row gutter={16}>
                    <Col>
                        <Form.Item name={name} initialValue={DISABLE_UNLICENSED_OPTIONS.PAUSE}>
                            <RadioGroup onChange={onChange}>
                                {Object.values(DISABLE_UNLICENSED_OPTIONS).map(option => (
                                    <Radio key={option} value={option}>
                                        {t(`forms:backupUnlicensedAccounts:fields:${option.toLowerCase()}:label`)}
                                    </Radio>
                                ))}
                            </RadioGroup>
                        </Form.Item>
                        <StyledInfo>
                            <Icon component={Info} />
                            <Text>{t(`modals:backupUnlicensedModal:groups:${group}`)}</Text>
                        </StyledInfo>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <Button type="link" onClick={onCancel}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type="primary" htmlType="submit" onClick={onSubmit}>
                    {t('forms:common:actions:confirm')}
                </Button>
            </Footer>
        </Fragment>
    )
}
