export default {
    RestoreStatus: {
        RESTORE: 'Restore',
        EXPORT: 'Download',
        DELETEITEMS: 'Remove',
        CANCEL: 'Canceling'
    },
    BackupStatus: {
        PREPARE: 'Preparing',
        BACKUP: 'Backup',
        CANCEL: 'Canceling',
        FINALIZE: 'Finalizing'
    },
    TaskStatus: {
        PENDING: 'Pending',
        INPROCESS: 'In process',
        READY_FOR_DOWNLOAD: 'Ready',
        ACTIVE: 'Active',
        NOTACTIVE: 'Not active',
        PAUSED: 'Paused',
        QUEUED: 'In process',
        DISPATCHED: 'In process',
        SKIPPED: 'In process',
        ARCHIVED: 'Archived'
    },
    DateOptions: {
        BOT: 'Select date',
        TODAY: 'Select date'
    },

    CheckboxOptions: {
        CHECKED: 'checked',
        UNCHECKED: ''
    },

    deactivate: 'Deactivate',
    activate: 'Activate',
    deleteTask: 'Delete',
    pause: 'Pause',
    cancel: 'Cancel',
    backupNow: 'Backup now',
    restoreProgress: 'In process: {0} items',
    backupProgress: '({0} items)',

    //Strings
    //Global strings
    res_str_glb_AppTitle: '{0} | Backup your online data', //CompanyName

    serviceTypes: {
        GACCOUNTS: {
            name: 'Google account',
            service: 'Account',
            services: 'Accounts',
            description: 'Mail, Chat, Calendar, Contacts, Drive and Tasks'
        },
        GOOGLEAPPS: {
            name: 'Google Workspace',
            service: 'Google domain',
            services: 'Google accounts',
            description: 'Mail, Chat, Calendar, Contacts, Drive and Tasks'
        },
        GDRIVETEAM: {
            name: 'Google Shared Drives',
            service: 'Google domain',
            services: 'Google accounts',
            description: 'Files and Folders'
        }
    },

    restore_selection: {
        all_page_items: 'All {0} items on this page are selected.',

        primary_page_items: 'All {0} items in Primary are selected.',
        select_all_in_primary: 'Select all {0} messages Primary',

        primary_page_items_limited: '{0} items in Primary are selected (limit exceeded).',
        select_all_in_primary_limited: 'Select {0} (limitation) of {1} messages Primary.',

        clear_selection: 'Clear selection'
    },

    permissions: {
        manage_user_info: 'Update {0} account settings and unsubscribe from {0}', // CompanyName
        manage_user_permissions: 'Add users, edit permissions and delete users',
        manage_payments: 'View billing page and manage payment details',
        view_support: 'View support page',
        view_accounts_acivity: 'View account activity',
        activate_backup: 'Activate new backup tasks',
        edit_backup: 'Modify backup tasks',
        delete_backup: 'Delete backup tasks',
        pause_backup: 'Pause backup tasks',
        execute_backup: 'Execute backup tasks',
        cancel_backup: 'Cancel backup tasks',
        explore_backup: 'View {0} backups', // CompanyName
        restore_backup: 'Restore {0} backups', // CompanyName
        download_backup: 'Download {0} backups', // CompanyName
        delete_items: 'Delete backup Items - GDPR Right to Erasure'
    },

    //Service Names
    res_str_disp_GACCOUNTS: 'Google Account',
    res_str_disp_YMAIL: 'Yahoo Mail',
    res_str_disp_GOOGLEAPPS: 'Google Workspace',
    res_str_disp_GDRIVETEAM: 'Google Shared Drives',
    res_str_disp_SFC: 'Salesforce',
    res_str_disp_SDB: 'SimpleDB',
    res_str_disp_DDB: 'DynamoDB',
    res_str_disp_MS365: 'Microsoft Exchange',
    res_str_disp_SHAREPOINT: 'Microsoft SharePoint',
    res_str_disp_SHAREPOINT_MULTI: 'Microsoft SharePoint',
    res_str_disp_ONEDRIVE: 'Microsoft OneDrive',
    res_str_disp_IMAP: 'IMAP',
    res_str_disp_BOX: 'Box',
    res_str_disp_DROPBOX: 'Dropbox Business',
    res_str_disp_DROPBOX_PROFESSIONAL: 'Dropbox Professional',
    res_str_disp_GMAIL: 'Mail',
    res_str_disp_GDOC: 'Documents',
    res_str_disp_GCALENDAR: 'Calendar',
    res_str_disp_GCONTACTS: 'Contacts',
    res_str_disp_GTASKS: 'Tasks',
    res_str_disp_GSITES: 'Sites',
    res_str_disp_O365MAIL: 'Mail',
    res_str_disp_O365CONTACTS: 'Contacts',
    res_str_disp_O365CALENDAR: 'Calendar',
    res_str_disp_O365TASKS: 'Tasks',
    res_str_disp_CHANGEACCOUNTDATA: 'Account data changed',
    res_str_disp_SIGNIN: 'Sign in',
    res_str_disp_MS365GROUPS: 'Microsoft Groups / Teams',
    //Sub svc count description
    res_str_subSvcCountDisp_GACCOUNTS: 'Accounts',
    res_str_subSvcCountDisp_YMAIL: 'Accounts',
    res_str_subSvcCountDisp_GOOGLEAPPS: 'Users',
    res_str_subSvcCountDisp_GDRIVETEAM: 'Drives',
    res_str_subSvcCountDisp_SFC: 'Seats',
    res_str_subSvcCountDisp_SDB: 'Domains',
    res_str_subSvcCountDisp_DDB: 'Tables',
    res_str_subSvcCountDisp_MS365: 'Mailboxes',
    res_str_subSvcCountDisp_SHAREPOINT: 'Sites',
    res_str_subSvcCountDisp_SHAREPOINT_MULTI: 'Sites',
    res_str_subSvcCountDisp_ONEDRIVE: 'Sites',
    res_str_subSvcCountDisp_IMAP: 'Users',
    res_str_subSvcCountDisp_BOX: 'Users',
    res_str_subSvcCountDisp_MS365GROUPS: 'Groups / Teams',
    res_str_subSvcCountDisp_DROPBOX: 'Users / Team folders',

    res_str_subSvcCountDisp_single_GACCOUNTS: 'Account',
    res_str_subSvcCountDisp_single_YMAIL: 'Account',
    // TODO: should be account but apparently conflicts with restore needs
    res_str_subSvcCountDisp_single_GOOGLEAPPS: 'User',
    res_str_subSvcCountDisp_single_GDRIVETEAM: 'Drive',
    res_str_subSvcCountDisp_single_SFC: 'Seat',
    res_str_subSvcCountDisp_single_SDB: 'Domain',
    res_str_subSvcCountDisp_single_DDB: 'Table',
    res_str_subSvcCountDisp_single_MS365: 'Mailbox',
    res_str_subSvcCountDisp_single_SHAREPOINT: 'Site',
    res_str_subSvcCountDisp_single_SHAREPOINT_MULTI: 'Site',
    res_str_subSvcCountDisp_single_ONEDRIVE: 'Site',
    res_str_subSvcCountDisp_single_IMAP: 'User',
    res_str_subSvcCountDisp_single_BOX: 'User',
    res_str_subSvcCountDisp_single_MS365GROUPS: 'Group / Team',
    res_str_subSvcCountDisp_single_DROPBOX: 'User / Team folder',

    //main menu
    res_str_appMenu_Section_Services: 'Services',
    res_str_appMenu_Section_Services_dashboard: 'Dashboard',
    res_str_appMenu_Section_Services_backups: 'Backups',
    res_str_appMenu_Section_Services_restore: 'Restore & Download',
    res_str_appMenu_Section_Services_assign: 'Activate a New Backup',
    res_str_appMenu_Section_Settings: 'Settings',
    res_str_appMenu_Section_Settings_changeSettings: '{0} Account', // CompanyName
    res_str_appMenu_Section_Settings_changePassword: '{0} Password', // CompanyName
    res_str_appMenu_Section_Settings_userManagement: 'User management',
    res_str_appMenu_Section_Billing: 'Billing',
    res_str_appMenu_Section_Billing_subscriptions: 'Subscriptions',
    res_str_appMenu_Section_Billing_payments: 'Payments',

    //QuickAssign
    //res_str_quickAssign_title: 'Quick activate new backup',
    //res_str_quickAssign_btn_link_text: 'add...',

    //Screens
    //Backup tasks
    res_str_backupTasks_gridTitle: 'Backup Tasks',
    res_str_backupTasks_footer_Services: '{0} Backup Tasks',
    res_str_backupTasks_footer_addNewLink: 'Activate a New Backup',

    res_str_restore_search_enterNameOrEmail: 'Enter a name or e-mail address',
    res_str_restore_search_enterEmail: 'Enter e-mail address',
    res_str_restore_search_enterName: 'Enter a name',
    res_str_restore_search_enterMailTerm: 'Enter a term that matches a part of the mail subject',
    res_str_restore_search_enterEventTerm: 'Enter a text that matches a part of the event description',
    res_str_restore_search_enterFileTerm: 'Enter a term that matches a part of the file name',
    res_str_restore_search_sfcUserIdPlaceholder: 'Enter Salesforce User ID',
    res_str_restore_search_sfcUniqueIDsPlaceholder: 'Example: 5003000000D8cuI , 5003000000D8cuIQAA',

    res_str_generalError: 'An error has occurred, please refresh the page and contact us if the problem is not solved.',

    res_str_restore_details: 'Details',
    res_str_restore_download: 'Download',

    res_str_dialog_ok: 'OK',
    res_str_dialog_cancel: 'Cancel',
    res_str_dialog_save: 'Save',
    res_str_dialog_yes: 'Yes',
    res_str_dialog_no: 'No',
    res_str_dialog_close: 'Close',

    res_str_required_email: 'E-mail is required',
    res_str_required_password: 'Password is required',

    res_str_dialog_supportTitle: 'Open a Support Ticket',
    res_error: 'An error occured. Please try again.',
    res_wait: 'Please wait',
    res_service_required_help: 'Enable at least one service',
    res_explore_backups_help: 'Disable restore and download first',

    res_str_upload_location_help: 'Data was successfully uploaded to {0} storage in folder CloudAlly Export',

    retentionPeriod: {
        months: 'Months',
        years: 'Years',
        unlimited: 'Unlimited'
    },
    res_str_backups_addUserModalTitle: 'Add User',
    res_str_backups_addSiteModalTitle: 'Add Site',

    res_str_backups_emailModalFieldLabel: 'E-mail',
    res_str_backups_siteModalFieldLabel: 'Site',

    res_str_backups_ms365ModalTitle: 'Add user to your Microsoft Exchange users list',
    res_str_backups_sharepointModalTitle: 'Add site to your SharePoint sites list',
    res_str_backups_onedriveModalTitle: 'Add site to your OneDrive sites list',

    res_str_select_all: 'Select all',
    res_str_unselect_all: 'Unselect all',

    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    invalidPassword:
        'Your password must be at least 8 characters long and contain at least one number, one capital character and one non-capital character.',

    accountInfo: {
        subscribe: 'Subscribe'
    },

    grid: {
        filter: 'Filter:',
        search: 'Search'
    },

    annualSubscription: {
        incorrectEmail: 'Incorrect email',
        incorrectPhone: 'Incorrect phone',
        requiredField: '*',
        optionalField: '(optional)',

        fieldNotFilled: 'Field {0} should be filled',

        billingDetailsAdded:
            'Thank you, you are subscribed now, an invoice will be emailed to you soon. Please contact support if there is any question',
        title: 'Annual Subscription',
        startingDate: 'Subscription Starting Date',
        contactEmail: 'Billing Contact Email Address',
        orderNumber: 'Purchase Order Number',
        companyAddress: 'Company Billing Address',
        companyNumber: 'Company Number',
        billingContactEmailAddress: 'Billing Contact Email Address',
        billingName: 'Name',
        billingFirstName: 'First',
        billingLastName: 'Last',
        billingPhoneNumber: 'Billing phone number',
        nonProfitOrEducation: 'Non Profit or Education',
        subscribe: 'Subscribe'
    },
    backupTasks: {
        title: 'Backup Tasks',
        backupNow: 'Backup Now',
        activate: 'Activate a New Backup',
        noAvailableTasks: 'No available task',
        noAvailableTasksFound: 'No available tasks found',
        next: 'Next backup',
        last: 'Last backup:',
        details: 'details',
        showingOption: 'Showing option:',
        newestFirst: 'newest first',

        edit: 'Edit',
        deleteLabel: 'Delete',
        errorRetrivingTasks: 'Error retrieving backup tasks: '
    },
    assignToServices: {
        title: 'Activate a New Backup',
        including: 'Including:',
        breadcrumbs: 'Activate a New Backup',
        gridTitle: 'Activate a New Backup'
    },
    downloadDetails: {
        title: 'Files ready for download',
        description: 'The following files are ready for download of the snapshot of {0} downloaded at {1}'
    },
    restore: {
        breadcrumb: 'Restore & Download',
        gridTitle: 'Active Backup Accounts',
        restoreAndDownload: '<i class="fa fa-undo"></i>',
        restore: 'Restore',
        download: 'download',
        downloading: 'Starting download task...',
        continueLabel: 'continue',
        itemsTitle: {
            GOOGLEAPPS: 'Restore or download {0} active users', // CompanyName
            GDRIVETEAM: 'Restore or download {0} active team drives', // CompanyName
            SDB: 'Restore or download {0} domains', // CompanyName
            DDB: 'Restore or download {0} tables', // CompanyName
            BOX: 'Restore or download {0} active users', // CompanyName
            MS365: 'Restore or download {0} active mailboxes', // CompanyName
            DROPBOX: 'Restore or download {0} active users / team folders',
            SHAREPOINT_MULTI: 'Restore or download {0} sites', // CompanyName
            SHAREPOINT: 'Restore or download {0} sites', // CompanyName
            ONEDRIVE: 'Restore or download {0} sites', // CompanyName
            MS365GROUPS: 'Restore or download {0}' // CompanyName
        },
        descriptionLabel: {
            GOOGLEAPPS:
                'Listed below are the active users within your {0} domain backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            GDRIVETEAM:
                'Listed below are the active team drives within your {0} administrator backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            SDB:
                'Listed below are the domains within your {0} account backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            DDB:
                'Listed below are the tables within your {0} account backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            BOX: 'Listed below are the users within your {0} users backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            MS365:
                'Listed below are the active mailboxes within your {0} domain backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            DROPBOX:
                'Listed below are the users / team folders within your {0} organization backed up by {1}, ready for restore and download.',
            SHAREPOINT_MULTI:
                'Listed below are the sites within your {0} domain backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            SHAREPOINT:
                'Listed below are the sites within your {0} domain backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            ONEDRIVE:
                'Listed below are the sites within your {0} domain backed up by {1}, ready for restore and download.', // %SERVICE_NAME%, CompanyName
            MS365GROUPS:
                'Listed below are the active groups within your {0} domain backed up by {1}, ready for restore and download.'
        },

        services: {
            GMAIL: {img: 'iconMail.png', text: 'E-mails', breadcrumb: 'E-mails'},
            GDOC: {img: 'iconDocs.png', text: 'Documents', breadcrumb: 'Documents'},
            BOX: {img: 'iconDocs.png', text: 'Documents', breadcrumb: 'Documents'},
            DROPBOX: {img: 'iconDocs.png', text: 'Documents', breadcrumb: 'Documents'},
            GCONTACTS: {img: 'iconContacts.png', text: 'Contacts', breadcrumb: 'Contacts'},
            GTASK: {img: 'iconTasks.png', text: 'Tasks', breadcrumb: 'Tasks'},
            GCALENDAR: {img: 'iconCalendar.png', text: 'Calendar', breadcrumb: 'Calendars'},
            GSITES: {img: 'iconSites.png', text: 'Sites', breadcrumb: 'Sites'},
            GDRIVETEAM: {img: 'iconDocs.png', text: 'Google Shared Drives', breadcrumb: 'Google Shared Drives'},
            SFC: {img: 'iconSites.png', text: 'Salesforce', breadcrumb: 'Salesforce'},
            O365MAIL: {img: 'iconMail.png', text: 'E-mails', breadcrumb: 'E-mails'},
            O365CONTACTS: {img: 'iconContacts.png', text: 'Contacts', breadcrumb: 'Contacts'},
            O365TASKS: {img: 'iconTasks.png', text: 'Tasks', breadcrumb: 'Tasks'},
            O365CALENDAR: {img: 'iconCalendar.png', text: 'Calendar', breadcrumb: 'Calendars'},
            O365NOTES: {img: 'iconNotes.png', text: 'Notes', breadcrumb: 'Notes'},
            YMAIL: {img: 'iconMail.png', text: 'E-mails', breadcrumb: 'E-mails'},
            IMAP: {img: 'iconMail.png', text: 'E-mails', breadcrumb: 'E-mails'},
            SHAREPOINT: {img: 'iconDocs.png', text: 'Sharepoints', breadcrumb: 'Sharepoints'},
            SHAREPOINT_MULTI: {img: 'iconDocs.png', text: 'Sharepoints', breadcrumb: 'Sharepoints'},
            ONEDRIVE: {img: 'iconDocs.png', text: 'OneDrive', breadcrumb: 'OneDrive'},
            SDB: {img: 'iconDocs.png', text: 'SDBS', breadcrumb: 'SDBS'},
            DDB: {img: 'iconDocs.png', text: 'DDBS', breadcrumb: 'DDBS'},
            MS365GROUP_CONVERSATIONS: {img: 'iconMail.png', text: 'Conversation', breadcrumb: 'Conversation'},
            MS365GROUP_META: {img: 'metaIcon.png', text: 'Metadata', breadcrumb: 'Metadata'},
            MS365GROUP_CALENDAR: {img: 'iconCalendar.png', text: 'Calendar', breadcrumb: 'Calendars'},
            MS365GROUP_SITE: {img: 'iconSites.png', text: 'Sites', breadcrumb: 'Sites'},
            MS365TEAM_CHANNELS: {img: 'team_channel.svg', text: 'Channel', breadcrumb: 'Channels'},
            MS365TEAM_APPS: {img: 'team_app.svg', text: 'Apps', breadcrumb: 'Apps'}
        }
    },
    restoreSnapshot: {
        snapshotDateError: "From date shouldn't be greater than To date."
    },
    restoreSearch: {
        breadcrumb: 'Restore & Download',
        activeaccounts: 'Active accounts',
        gridTitle: 'Active Backup Accounts',
        restoreAndDownload: '<i class="fa fa-undo"></i>',
        restore: 'Restore',
        download: 'Download',
        itemsTitle: 'Restore or download {0} Backup task: {1}',
        descriptionLabel: 'Please select your preferred choice for restore',
        errorSearchPhraseEmpty: 'Search term should be longer than 2 characters',
        errorSearchFormEmpty: 'Search field should be filled'
    },
    restoreSearchResults: {
        breadcrumb: 'Restore & Download',
        activeaccounts: 'Active accounts',
        gridTitle: 'Active Backup Accounts',
        restoreAndDownload: '<i class="fa fa-undo"></i>',
        restore: 'Restore',
        download: 'Download',
        itemsTitle: 'Restore or download {0} from {1} archive',
        descriptionLabel:
            'Select the items from the list below, then click "restore" to perform a non-destructive restore directly to the online service, or "download" to create a zip extract of the data.',
        restoreDialog:
            'You are about to restore <strong>{0}</strong> items from archive of <strong>{1}</strong><br><br>The restore will go to <input type="text" id="userRestoreEmail" value="{2}" /> @{3}<br><div class="note marginLeft">*You can restore the data to a different account</div><br><br>A confirmation e-mail will be sent to you upon completion.',
        restoreDialogTitle: 'Please confirm restore request',
        downloadDialog: {
            title: 'Please confirm the download request',
            text: {
                GOOGLEAPPS: {
                    gdoc:
                        'You are about to download <strong>{0}</strong> items from archive of <strong>{1}</strong><br><br>A notification e-mail will be sent to you upon completion.',
                    general:
                        'You are about to download <strong>{0}</strong> items from archive of <strong>{1}</strong><br><br>{2}&nbsp;Download as default format according to type&nbsp;{3}<br>{4}&nbsp;Download as a PST file<br><br>A notification e-mail with a download link will be sent to you upon completion.'
                },
                GDRIVETEAM:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                SFC:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                YMAIL:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>{1}&nbsp;Download in EML format<br>{2}&nbsp;Download as a PST file<br><br>A notification e-mail with a download link will be sent to you upon completion.',
                GACCOUNTS:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>{1}&nbsp;Download as default format according to type&nbsp;{2}<br>{3}&nbsp;Download as a PST file<br><br>A notification e-mail with a download link will be sent to you upon completion.',
                IMAP:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>{1}&nbsp;Download in EML format<br>{2}&nbsp;Download as a PST file<br><br>A notification e-mail with a download link will be sent to you upon completion.',
                SDB:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                DDB:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                MS365:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>{1}&nbsp;Download as default format according to type&nbsp;{2}<br>{3}&nbsp;Download as a PST file<br><br>A notification e-mail with a download link will be sent to you upon completion.',
                BOX:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                SHAREPOINT:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                DROPBOX:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification Email with a download link will be sent to you upon completion.',
                SHAREPOINT_MULTI:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.',
                ONEDRIVE:
                    'You are about to download items from the archive of <strong>{0}</strong><br><br>A notification e-mail with a download link will be sent to you upon completion.'
            }
        },
        downloadTooltip: 'The downloaded file(s) will be in standard EML, VCF or ICS format'
    },
    restoreSingleServiceSnapshotResults: {
        breadcrumb: 'Restore & Download',
        activeaccounts: 'Active accounts',
        gridTitle: 'Active Backup Accounts',
        restoreAndDownload: '<i class="fa fa-undo"></i>',
        restore: 'Restore',
        download: 'Download',
        itemsTitle: 'Restore or download from {0} archive',
        descriptionLabel:
            'Select the items from the list below, then click "restore" to perform a non-destructive restore directly to the online service, or "download" to create a zip extract of the data.',
        downloadTooltip: 'The downloaded file(s) will be in standard EML, VCF or ICS format',
        snapshots: 'snapshots'
    },
    restoreSnapshotResults: {
        breadcrumb: 'Restore & Download',
        activeaccounts: 'Active accounts',
        gridTitle: 'Active Backup Accounts',
        restoreAndDownload: '<i class="fa fa-undo"></i>',
        restore: 'Restore',
        download: 'Download',
        itemsTitle: 'Restore or download {0} from {1} archived at {2}',
        descriptionLabel:
            'Select the items from the list below, then  click on "restore" to perform a non-destructive restore directly to the online service, or "download" to create a zip extract of the data.',
        downloadTooltip: 'The downloaded file(s) will be in standard EML, VCF or ICS format',
        snapshots: 'snapshots',
        requestTimeoutExceeded: 'Timeout for that request has exceeded, try to reload'
    },
    removeModal: {
        title: 'Please confirm the delete request',
        prefix: 'You are about to delete items from the archive of ',
        postfix: 'A notification email with a delete will be sent to you upon completion.',
        note:
            'Note: Ensure that the item you wish to remove is already removed from the source. If it is not, it will continue to be backed up, even if you deleted it using the Delete button'
    },
    exportModal: {
        spaceLimit:
            'If there is insufficient storage space to complete the export, the data will be exported locally and available for download.'
    },
    customDownload: {
        targets: {
            cloudAllyS3: 'for download',
            amazonS3: 'to your Amazon S3',
            azure: 'to your Azure Blob',
            box: 'to your Box.com',
            dropbox: 'to your Dropbox',
            compatibleS3Endpoint: 'to your AWS S3 compatible',
            googleCloudPlatform: 'to your GCP storage'
        },
        emptyFieldsError: 'One of the fields is empty'
    },
    accountActivity: {
        title: 'View your {0} account activity', // CompanyName
        mainText: 'Your {0} account activity is listed below', // CompanyName
        breadcrumb: 'Account Activity',
        viewCombo: {
            label: 'View',
            lastMonth: 'Last month',
            lastBackups: 'Last backups',
            notifications: "Last month's notifications",
            restoreAndDownloads: "Last week's restores & downloads"
        }
    },
    payments: {
        tableColumns: ['Date', 'Transaction', 'Description', 'Amount']
    },
    changePassword: {
        title: '{0} Password', // CompanyName
        informationText: 'You can change your {0} password by entering your existing password and new password below.', // CompanyName
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm new Password',
        confirmationMismatch: 'Password confirmation does not match the new password'
    },
    changeSettings: {
        title: '{0} Account', // CompanyName
        informationText: 'You can change your {0} settings below', // CompanyName
        unsubscribeInformationText: 'To cancel your {0} service plan, please use the Unsubscribe button below',
        unsubscribeInformationText2: 'This includes removing all your data at {0}', // CompanyName
        dailyReport: 'E-mail daily report',

        email: 'E-mail',
        name: 'Display Name',
        partnerId: 'Partner ID',
        location: 'Archive Location',

        submit: 'Submit',
        remove: 'Remove',
        credentialRemoved: '{0} Credential {1} removed successfully',
        removeFailed: 'Failed to remove credential, ',
        credentialAdded: 'Credential added successfully',
        addFailed: 'Failed to add credential, ',
        unsubscribeApprove: 'I approve the removal of my data from {0}', // CompanyName
        unsubscribeFailed: 'Unsubscribed failed',
        unsubscribeMessage:
            'You have successfully unsubscribed from {0}. Your account is now closed and all data will be removed within 5 days at which time a confirmation e-mail will be sent to you. ' +
            'Thank you for using {0} and we hope that you will consider us again in the future.' // CompanyName
    },
    users: {
        title: 'Add new users, edit permissions, or delete users from your {0} account.', // CompanyName
        permissionsUpdated: 'Permissions changed successfully',
        permissionsUpdateFailed: 'Failed to update permissions'
    },
    includeMetadataOptions: {
        ALL: 'Include all data',
        EXCLUDE_META: 'Exclude all metadata'
    },
    twoFaSetAccount: {
        mainText2:
            "Open Google Authenticator and add your {0} account by scanning the QR code to the right.<br />If you can't use a QR code then enter this text code:", // CompanyName
        lockOutRemind: "Don't get locked out of your {0} account!" // CompanyName
    },
    zoozPaymentStatus: {
        0: 'Valid',
        1: 'Invalid',
        2: 'Expired'
    },
    zoozMessages: {
        cardWasAddedAndSubscribed: 'Card has been added, you are subscribed now',
        cardWasDeleted: 'Card has been deleted',
        cardWasAdded: 'Card has been added',
        cardWasActivated: 'Card has been activated',
        cardIsExpired: 'Card is expired, try another one or contact support',
        cardIsInvalid: 'Card is invalid, try another one or contact support',
        subscriptionFailed: 'Subscription has not been activated, please try again',
        fillFormFields: 'Please complete the fields marked in red',
        billingDetailsUpdated: 'Billing details are updated',
        errors: {
            262416: 'Sorry, but there is a problem with the card number. Please check the number and try again'
        }
    },
    zoozActions: {
        subscribe: 'Subscribe',
        addCard: 'Add card',
        activateCard: 'Activate card',
        deleteCard: 'Delete card',
        updateBillingDetails: 'Update billing details',
        completeSubscriptions: 'Complete Subscription'
    },
    finraTooltip: 'This account is Finra section 17 complied, deletion is not available',
    tableHeader:
        'From the archive list below select the relevant items, then click on "restore" to perform a non-destructive restore directly to the online service, or "download" to create a zip extract of the data.',
    tableSFCHeader:
        'From the archive list below select the relevant items, then click on "restore" to perform a restore directly to the online service, or "download" to create a zip extract of the data.'
}
