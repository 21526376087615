import React, {memo} from 'react'
import {Skeleton} from 'antd'

import {SOURCE} from 'ca-common/common/enum'
import {MS365SubSource} from 'ca-common/types'

import {SubsourceCard} from 'src/newcore/features/Recovery/molecules/SubsourceCard'
import {SubsourceIconWithStatus} from 'src/newcore/features/Recovery/molecules/SubsourceIconWithStatus'
import {Controls} from 'src/newcore/features/Recovery/molecules/Controls'
import {StyledContainer} from './StyledSubsourcesBlock'
import type {CheckAllPayload} from 'src/newcore/features/Recovery/localState/actionsLocalState'
import type {SubsourcesCheckedState} from 'src/newcore/features/Recovery/localState/localState'
import {SubsourcesInfo} from 'src/newcore/features/Recovery/redux/getSubsourcesInfo'

type SubsourcesBlockProps = {
    subsources: SubsourcesInfo[]
    selectedSubsource: string
    selectedItemList: SubsourcesCheckedState
    selectSubsource: (subsource: MS365SubSource) => void
    selectAction: (value: CheckAllPayload) => void
    subsourceFetched: boolean
}

export const SubsourcesBlock = memo(
    ({
        subsources,
        selectedSubsource,
        selectedItemList,
        selectSubsource,
        selectAction,
        subsourceFetched
    }: SubsourcesBlockProps): JSX.Element => {
        const order = [SOURCE.O365MAIL, SOURCE.O365CONTACTS, SOURCE.O365TASKS, SOURCE.O365CALENDAR, SOURCE.O365NOTES]

        return (
            <Skeleton
                active
                title={{style: {height: '74px', margin: '20px 0'}}}
                paragraph={false}
                loading={!subsourceFetched}
            >
                <StyledContainer>
                    {order.map(subsourceName => {
                        const subsourceData = subsources.find(
                            subsource => subsource.source === subsourceName && subsource.items >= 0
                        )
                        if (!subsourceData) return null
                        const disabled = subsourceData.items < 1
                        const selected = selectedSubsource === subsourceData.source
                        const selection = selectedItemList[subsourceData.source]
                        const icon = (
                            <SubsourceIconWithStatus
                                name={subsourceData.source}
                                status={subsourceData.status}
                                disabled={disabled}
                                selection={selection}
                            />
                        )
                        const controls = (
                            <Controls
                                disabled={disabled}
                                selection={selection}
                                onSelectAll={() => selectAction({check: true, subsource: subsourceData.source})}
                                onClearSelected={() => selectAction({check: false, subsource: subsourceData.source})}
                            />
                        )
                        return (
                            <SubsourceCard
                                key={subsourceData.source}
                                icon={icon}
                                count={subsourceData.items}
                                source={subsourceData.source}
                                controls={controls}
                                selected={selected}
                                select={selectSubsource}
                            />
                        )
                    })}
                </StyledContainer>
            </Skeleton>
        )
    }
)
