import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSignInPage = styled.div`
    max-width: 288px;
    width: 100%;
    margin: ${variables.gutterXs} auto ${variables.gutterSm};
    padding: ${variables.gutterSm} 0 0;

    @media screen and (min-width: ${variables.screenMd}) {
        max-width: 524px;
        padding: ${variables.gutterXxl} ${variables.gutterLg} ${variables.gutterLg};
    }
`

export const Header = styled.div`
    h1 {
        font-weight: 700;
        font-size: ${variables.subTitleFontSize};
        text-align: center;
        color: ${variables.text};
        margin-bottom: ${variables.gutterXs};
        font-family: ${variables.publicPageFont};

        @media screen and (min-width: ${variables.screenMd}) {
            font-size: ${variables.mainTitleFontSize};
        }
    }
`

export const Content = styled.div`
    width: 100%;
`

export const EmailSignIn = styled.div``
