import styled from 'styled-components'

import variables from 'ca-common/variables'
import OktaLogo from 'ca-common/icons/Okta_logo.svg'

export const Content = styled.section`
    margin: auto;
    padding: ${variables.gutterXxl};
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 720px;
`

export const OktaTitle = styled.h2`
    margin-bottom: ${variables.gutterMd};
    color: ${variables.text};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`

export const OktaSubTitle = styled.div`
    margin-bottom: ${variables.gutterXxl};
    color: ${variables.navyBlue};
    font-size: 14px;
    text-align: center;
`

export const StyledOktaLogo = styled(OktaLogo)`
    width: 152px;
    height: 52px;
    margin-top: 100px;
    margin-bottom: ${variables.gutterLg};
`
