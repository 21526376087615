import React, {useEffect} from 'react'

import {BILLING_VIEW} from 'ca-common/common/enum'

import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {BillingStatusContent} from 'src/newcore/features/BillingStatus/organisms/BillingStatusContent'
import {getBilling, getBillingTableData, getPaymentsTableData} from 'src/newcore/features/BillingStatus/redux'

export const BillingStatus = () => {
    const dispatch = useAppDispatch()

    const userInfo = useAppSelector(userInfoSelector)

    const isCustomerShowWithoutDiscount = !!(
        userInfo?.partnerID && userInfo.billingView === BILLING_VIEW.SHOW_TOTAL_WITHOUT_DISCOUNT
    )

    useEffect(() => {
        const fn = async () => {
            const {payload}: any = await dispatch(getBilling({}))
            !isCustomerShowWithoutDiscount && dispatch(getBillingTableData({paymentStatus: payload.status}))
            dispatch(getPaymentsTableData({paymentStatus: payload.status}))
        }

        fn()
    }, [])

    return <BillingStatusContent isCustomerShowWithoutDiscount={isCustomerShowWithoutDiscount} />
}
