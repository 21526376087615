import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import _ from 'lodash'
import {withRouter} from 'react-router'
import {Form} from 'antd'
import queryString from 'query-string'
import {useTranslation, Trans} from 'react-i18next'

import {openSuccessNotification, openErrorNotification} from 'ca-common/utils/toasts'
import {PAGES} from 'ca-common/constants'
import NoticeIcon from 'ca-common/icons/Notice.svg'

import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'
import {getBackupFrequencyValue, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {
    getBulkActivationRules,
    removeBulkActivationRules,
    clearRemovedRulesList
} from 'src/newcore/redux/modules/backups/bulkActivate'
import {activateCachedTask} from 'src/newcore/redux/modules/backups'
import {setBackupTaskSettings, getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

import {BackupSettingsFormContext} from './contexts'
import {SettingsTemplateSelector} from './templates'
import {useTogglePanel} from './hooks'
import {
    StyledConfirmText,
    StyledConfirmTitle,
    StyledFooter,
    StyledImageWrapper,
    StyledFooterButton,
    StyledIcon,
    StyledModal
} from './StyledBackupSettings'

export const CABackupSettingsRaw = props => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const {userInfo, removedRulesList, location, taskSettings, actions, history, onBackupAuthClick} = props
    const urlHasIsNew = queryString.parse(location.search).isNew
    const [activeKey, togglePanel] = useTogglePanel(urlHasIsNew)
    const [showModal, setShowModal] = useState(false)
    const [isMultiFrequencyBlocked, setIsMultiFrequencyBlocked] = useState(false)
    const editBackupSettings = values => {
        setLoading(true)
        actions
            .setBackupTaskSettings({
                ...values,
                taskId: taskSettings.taskId
            })
            .unwrap()
            .then(() => {
                const deactivationOption = values.deactivationOption
                if (deactivationOption && deactivationOption !== taskSettings.deactivationOption) {
                    track(EVENT_NAMES.DEACTIVATION_RULE, {
                        'Type of deactivation rule': t(
                            `backups:bulkActivation:deactivation:types:${deactivationOption}`
                        )
                    })
                }

                if (taskSettings.backupFrequency !== values.backupFrequency) {
                    track(EVENT_NAMES.BACKUP_FREQUENCY_CHANGED, {
                        Service: getServiceNameMixpanel(taskSettings.source),
                        'Backup Frequency': getBackupFrequencyValue(values.backupFrequency)
                    })
                }

                if (removedRulesList.length) {
                    actions
                        .removeBulkActivationRules({
                            taskId: taskSettings.taskId,
                            ids: removedRulesList
                        })
                        .then(() => actions.getBulkActivationRules({taskId: taskSettings.taskId}))
                    actions.clearRemovedRulesList()
                }
                actions.getNewBackupTaskSettings({taskId: taskSettings.taskId})
                actions.getBulkActivationRules({taskId: taskSettings.taskId})
                togglePanel()
                setLoading(false)
                openSuccessNotification(t('forms:backupSettings:response:updated'))
            })
            .catch(error => {
                if (error.data?.isHighFrequencyBackupBlocked) {
                    setShowModal(true)
                    setIsMultiFrequencyBlocked(true)
                    form.setFieldValue('backupFrequency', taskSettings.backupFrequency)
                    togglePanel()
                    setLoading(false)
                } else {
                    setIsMultiFrequencyBlocked(false)
                    setLoading(false)
                    openErrorNotification(error.status)
                }
            })
    }

    const clearExistRemovedRulesList = () => {
        if (removedRulesList.length) {
            actions.clearRemovedRulesList()
        }
    }

    const activateBackup = values => {
        setLoading(true)
        actions
            .activateCachedTask({
                ...values,
                taskId: taskSettings.taskId
            })
            .then(response => {
                setLoading(false)
                openSuccessNotification(t('backups:activateBackup'))
                history.push(`/${PAGES.BACKUPS}/${response?.payload.taskId}`)
                togglePanel()
            })
            .catch(error => {
                setLoading(false)
                openErrorNotification(error.status)
            })
    }

    return (
        <BackupSettingsFormContext.Provider value={form}>
            <SettingsTemplateSelector
                activeKey={activeKey}
                togglePanel={togglePanel}
                isFinra={userInfo.isFinra}
                isHighFrequencyBackupEnabled={userInfo.isHighFrequencyBackupEnabled}
                removedRulesList={removedRulesList}
                taskSettings={taskSettings}
                editBackupSettings={editBackupSettings}
                clearRemovedRulesList={clearExistRemovedRulesList}
                onBackupAuthClick={onBackupAuthClick}
                activateBackup={activateBackup}
                loading={loading}
                form={form}
                isSpecialPartnerAccount={userInfo.isSpecialPartnerAccount}
                isMultiFrequencyBlocked={isMultiFrequencyBlocked}
            />
            <StyledModal
                centered
                open={showModal}
                closable
                width={722}
                onCancel={() => setShowModal(false)}
                footer={
                    <StyledFooter>
                        <StyledFooterButton type="primary" onClick={() => setShowModal(false)}>
                            {t('forms:common:actions:ok')}
                        </StyledFooterButton>
                    </StyledFooter>
                }
                title={
                    <StyledConfirmTitle>
                        <StyledImageWrapper>
                            <StyledIcon component={NoticeIcon} />
                        </StyledImageWrapper>
                        <span>{t('backups:notice')}</span>
                    </StyledConfirmTitle>
                }
            >
                <StyledConfirmText>
                    <Trans i18nKey="forms:backupSettings:fields:backupFrequency:highBackupTitle" />
                </StyledConfirmText>
            </StyledModal>
        </BackupSettingsFormContext.Provider>
    )
}

const mapStateToProps = state => ({
    taskSettings: _.get(state.backups.settings, 'response', {}),
    removedRulesList: state.backups.bulkActivation.bulkActivationRules.removedRulesList,
    userInfo: state.userInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getNewBackupTaskSettings,
            setBackupTaskSettings,
            getBulkActivationRules,
            activateCachedTask,
            removeBulkActivationRules,
            clearRemovedRulesList
        },
        dispatch
    )
})

export const CABackupSettings = withRouter(connect(mapStateToProps, mapDispatchToProps)(CABackupSettingsRaw))
