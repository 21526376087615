import React from 'react'
import capitalize from 'lodash/capitalize'
import {useTranslation} from 'react-i18next'

import {JOBS_STATUSES, SOURCE} from 'ca-common/common/enum'
import {DeepTaskStatus} from 'ca-common/atoms/DeepTaskStatus'

import {HeaderTaskInfo} from 'src/newcore/features/Jobs/atoms'
import {JobFields} from 'src/newcore/features/Jobs/types'
import {MultiSourceProgressBar} from 'src/newcore/molecules/ProgressBar/MultiSourceProgressBar'
import {SingleSourceJobsProgressBar} from 'src/newcore/molecules/ProgressBar/SingleSourceJobsProgressBar'

import {HeaderInformation, HeaderTitle, StatusWrapper} from './StyledJobHeader'

type JobHeaderProps = Pick<JobFields, 'taskType' | 'source' | 'account' | 'alias' | 'status' | 'progressData'>

export const JobHeader = ({taskType, source, account, alias, status, progressData}: JobHeaderProps): JSX.Element => {
    const {t} = useTranslation()

    return (
        <HeaderInformation>
            <HeaderTaskInfo source={source} account={account} alias={alias} />
            <HeaderTitle>{capitalize(taskType)}</HeaderTitle>
            {status === JOBS_STATUSES.IN_PROCESS ? (
                source === SOURCE.MS365 ? (
                    <MultiSourceProgressBar progressData={progressData} />
                ) : (
                    <SingleSourceJobsProgressBar progressData={progressData} />
                )
            ) : (
                <StatusWrapper>
                    <DeepTaskStatus status={status} statusText={t(`JobsPage:jobTile:status:${status}`)} />
                </StatusWrapper>
            )}
        </HeaderInformation>
    )
}
