import styled from 'styled-components'

import variables from 'ca-common/variables'

export const ModalHeader = styled.div`
    padding: 12px ${variables.gutterMd};
    border-bottom: 1px solid ${variables.navyBorder};
`

export const ModalBody = styled.div`
    padding: ${variables.gutterMd};
`

export const StyledRadioLabel = styled.div`
    font-family: ${variables.mainFont};
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    margin-top: 20px;
`
