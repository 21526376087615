import styled from 'styled-components'

import variables from 'ca-common/variables'

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 58px ${variables.gutterXxl};
    background-color: ${variables.white};

    @media screen and (min-width: ${variables.screenDesktop}) {
        padding: 58px 0;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 0 ${variables.gutterXs};
    justify-content: space-between;
    gap: ${variables.gutterLg};

    @media screen and (min-width: ${variables.screenXl}) {
        justify-content: space-around;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
    }
`
