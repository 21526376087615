import styled from 'styled-components'

import variables from 'ca-common/variables'

export const LoadingText = styled.div`
    width: 285px;
    text-align: center;
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterMd};
    align-items: center;
    margin-bottom: 114px;
`
