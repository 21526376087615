import React, {Fragment, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'

import {SnapshotSelection} from './SnapshotSelection'

export const DataTypeSelection = ({selectedDataType = '', changeDataType, taskSettings}) => {
    const {t} = useTranslation()
    const dataRef = useRef(null)
    const metadataRef = useRef(null)

    const onDataTypeChange = value => {
        dataRef.current?.reset()
        metadataRef.current?.reset()
        changeDataType(value)
    }

    return (
        <Fragment>
            <RadioBlock
                checked={SFC_DATA_TYPE.DATA === selectedDataType}
                value={selectedDataType}
                onChange={() => onDataTypeChange(SFC_DATA_TYPE.DATA)}
                showRadioIcon={false}
                title={t(`seeding:dataType:${SFC_DATA_TYPE.DATA}:label`)}
                description={t(`seeding:dataType:${SFC_DATA_TYPE.DATA}:description`)}
            >
                <SnapshotSelection
                    taskSettings={taskSettings}
                    name="dataDate"
                    disabled={SFC_DATA_TYPE.METADATA === selectedDataType}
                    innerRef={dataRef}
                />
            </RadioBlock>
            <RadioBlock
                checked={SFC_DATA_TYPE.METADATA === selectedDataType}
                value={selectedDataType}
                onChange={() => onDataTypeChange(SFC_DATA_TYPE.METADATA)}
                showRadioIcon={false}
                title={t(`seeding:dataType:${SFC_DATA_TYPE.METADATA}:label`)}
                description={t(`seeding:dataType:${SFC_DATA_TYPE.METADATA}:description`)}
            >
                <SnapshotSelection
                    taskSettings={taskSettings}
                    name="metadataDate"
                    disabled={SFC_DATA_TYPE.DATA === selectedDataType}
                    innerRef={metadataRef}
                />
            </RadioBlock>
        </Fragment>
    )
}
