import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledForecastCard = styled.div<{type?: string; showPaymentDetails?: boolean; isLoadingData?: boolean}>`
    font-family: ${variables.publicPageFont};
    padding: ${variables.gutterXxl} 22px ${variables.gutterSm};
    width: 359px;
    min-height: ${props => (props.showPaymentDetails ? '370px' : '230px')};
    border-radius: ${variables.borderRadiusBlocks};
    letter-spacing: 0;
    background-color: ${props => {
        switch (props.type) {
            case 'YEARLY':
                return variables.offwhite
            default:
                return variables.lightgrey
        }
    }};
    position: relative;
    border: none;
    text-align: center;

    ${props =>
        props.isLoadingData &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        `}
`
export const StyledForecastCardTitle = styled.div`
    color: ${variables.black};
    font-size: ${variables.gutterXxl};
    font-weight: 700;
    line-height: 54px;
`

export const StyledForecastCardCost = styled(StyledForecastCardTitle)<{showPaymentDetails?: boolean}>`
    margin-bottom: ${props => (props.showPaymentDetails ? variables.gutterSm : '0px')}};
    min-height: 54px;
`

export const StyledForecastCardSubtitle = styled.div<{isUnavailableCurrency?: boolean}>`
    color: ${variables.black};
    font-size: ${variables.subTitleFontSize};
    font-weight: 400;
    margin-bottom: ${variables.gutterSm};
    line-height: 22px;
    text-align: ${props => (props.isUnavailableCurrency ? 'centre' : 'left')};
    min-height: 44px;
`

export const StyledForecastCardNote = styled.div`
    color: ${variables.black};
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin-top: ${variables.gutterLg};
`

export const StyledForecastCardTooltip = styled.div`
    background-color: ${variables.systemGreen};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    line-height: 20px;
    border-radius: ${variables.borderRadiusBlocks};
    text-align: center;
    color: ${variables.white};
    height: 48px;
    padding: 4px ${variables.gutterSm};
    white-space: nowrap;
`

export const StyledForecastCardTooltipWrapper = styled.div`
    position: absolute;
    left: 260px;
    top: ${variables.gutterSm};
    display: flex;
    flex-direction: column;
    gap: ${variables.gutterXs};
`

export const StyledForecastCardButton = styled(Button)`
    width: 224px;
    height: 54px;
    border-width: 0;
    font-size: ${variables.headingFontSize};
    font-weight: 600;
    color: ${variables.white};
    background-color: ${variables.brightOrange};
    :hover,
    :focus {
        color: ${variables.white};
        background-color: ${variables.brightOrange};
    }
`

export const StyledNotAvailable = styled.div`
    margin-bottom: ${variables.gutterLg};
    text-align: left;
    font-size: ${variables.headingFontSize};
    font-weight: 400;
`
