import {clearAnalytics} from 'src/newcore/utils/mixpanel'

import {utf8ToB64} from 'ca-common/utils/base64'
import {PAGES} from 'ca-common/constants'

export const callReload = (redirect?: string, modalName?: string): void => {
    const redirectSuffix = redirect ? `?redirect=${redirect}` : ''
    window.location.href = modalName ? `/newapp/${PAGES.BACKUPS}${modalName}` : `/reload${redirectSuffix}`
}

export const logout = (): void => {
    callReload(utf8ToB64(window.location.href))
}

export const signOutLogout = (): void => {
    clearAnalytics()
    callReload()
}
