import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Modal} from 'antd'

import Warning from 'ca-common/icons/Warning.svg'

type CancelConfirmationProps = {
    button: (arg0: () => void) => JSX.Element
    cancelTask: () => void
    type: string
    email: string
    source: string
}

import {ButtonStyles, StyledIcon} from './StyledCancelConfirmation'

export const CancelConfirmation = (props: CancelConfirmationProps): JSX.Element => {
    const {button, cancelTask, type, email, source} = props
    const {t} = useTranslation()

    const [modalVisible, setModalVisible] = useState(false)

    const onClick = () => {
        setModalVisible(true)
    }

    const onOkHandler = () => {
        setModalVisible(false)
        cancelTask()
    }

    const onCancelHandler = () => {
        setModalVisible(false)
    }

    return (
        <>
            {button(onClick)}
            <Modal
                width={722}
                title={
                    <>
                        <StyledIcon component={Warning} />
                        <span>{t('jobs:cancelTitle', {type})}</span>
                    </>
                }
                open={modalVisible}
                maskClosable={false}
                destroyOnClose
                closable={false}
                onOk={onOkHandler}
                onCancel={onCancelHandler}
                okText={t('jobs:cancelingOkButton')}
                cancelButtonProps={{type: 'link', style: ButtonStyles}}
                okButtonProps={{style: ButtonStyles}}
            >
                <Trans i18nKey={'jobs:cancelingText'} values={{email, type, source}}>
                    <strong></strong>
                </Trans>
            </Modal>
        </>
    )
}
