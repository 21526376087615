import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'

type JumbotronProps = {
    centered?: boolean
    tile?: boolean
    isNotMaximized?: boolean
}

/**
 * @deprecated do not use Jumbotron, Jumbotron will be removed in the future, use a custom block instead
 */
export const Jumbotron = styled.div<JumbotronProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 15px 21px;
    color: ${variables.text};
    background: ${variables.white};
    box-shadow: 0 0 10px #e1e1e1;

    ${props =>
        props.centered &&
        css`
            align-items: center;
            justify-content: space-between;
        `}

    ${props =>
        props.tile &&
        css`
            height: 245px;
            flex: 0 1 auto;
            flex-direction: column;
            width: 238px;
            margin-right: 20px;
        `}

    ${props =>
        !props.isNotMaximized &&
        css`
            * {
                max-width: 100%;
            }
        `}


    h3 {
        margin-bottom: 14px;
        font-size: 18px;
        font-weight: 600;
    }

    h4 {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
    }

    p {
        font-size: 14px;
    }

    .ca-link {
        font-size: 14px;
    }
`
