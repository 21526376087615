import React from 'react'
import {Skeleton} from 'antd'
import {Trans, useTranslation} from 'react-i18next'

import {PAYMENT_TYPE} from 'ca-common/constants'
import {BILLING_PAGE_DATE_FORMAT, convertUnixToDate} from 'ca-common/utils/datetime'

import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'
import {PaymentsStatus} from 'src/newcore/features/BillingStatus/atoms/PaymentsStatus'
import {StatusItem} from 'src/newcore/features/BillingStatus/atoms/StatusItem'
import type {BillingResponse} from 'src/newcore/features/BillingStatus/types'

import {
    StatusColumnsWrapper,
    StatusInfoWrapper,
    StatusItemWrapper,
    UpdateCreditCardLink,
    UpdateCreditCardLinkWrapper
} from './StyledStatusInfo'

type StatusInfoProps = {
    data?: BillingResponse
    loading?: boolean
    isCustomerShowWithoutDiscount: boolean
}

export const StatusInfo = ({data, loading, isCustomerShowWithoutDiscount}: StatusInfoProps) => {
    const {t} = useTranslation()
    const showNextInfo = data?.status === PaymentStatus.NO_BILLS || data?.status === PaymentStatus.PAID

    return (
        <Skeleton active title={{style: {height: '74px', margin: 0}}} paragraph={false} loading={loading || !data}>
            {data?.paymentType && (
                <StatusInfoWrapper>
                    <StatusColumnsWrapper>
                        <StatusItem title={t('billing:labels:subscription')}>
                            {t(`billing:subscriptionType:${data.paymentType.toLowerCase()}`)}
                        </StatusItem>
                        {!isCustomerShowWithoutDiscount && (
                            <StatusItemWrapper>
                                <StatusItem title={t('billing:labels:paymentStatus')}>
                                    {<PaymentsStatus status={data.status} amount={data.total} />}
                                </StatusItem>
                            </StatusItemWrapper>
                        )}
                        {showNextInfo && data.period && (
                            <StatusItemWrapper>
                                <StatusItem title={t('billing:labels:nextRenewalDate')}>
                                    {convertUnixToDate(+data.period, BILLING_PAGE_DATE_FORMAT)}
                                </StatusItem>
                            </StatusItemWrapper>
                        )}
                        {data.total && (
                            <StatusItemWrapper>
                                <StatusItem title={t('billing:labels:forecastForNextPayment')}>
                                    {data.forecastTotal}
                                </StatusItem>
                            </StatusItemWrapper>
                        )}
                    </StatusColumnsWrapper>
                    {data.paymentType === PAYMENT_TYPE.MONTHLY && data.total && !isCustomerShowWithoutDiscount && (
                        <UpdateCreditCardLinkWrapper>
                            <span>
                                <Trans i18nKey="billing:labels:updateCreditCard">
                                    <UpdateCreditCardLink to="/billing/paymentDetails">link</UpdateCreditCardLink>
                                </Trans>
                            </span>
                        </UpdateCreditCardLinkWrapper>
                    )}
                </StatusInfoWrapper>
            )}
        </Skeleton>
    )
}
