import React from 'react'
import Icon from '@ant-design/icons'

import MSIconSVG from 'ca-common/icons/source/MS365.svg'

import {IconWrapper} from './StyledMSIcon'

export const MSIcon = () => {
    return (
        <IconWrapper>
            <Icon component={MSIconSVG} style={{fontSize: '24px'}} />
        </IconWrapper>
    )
}
