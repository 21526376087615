import styled from 'styled-components'
import {Table} from 'antd'

import variables from 'ca-common/variables'

export const StyledDownloadTable = styled(Table)`
    .ant-table-body {
        .ant-table-thead {
            .ant-table-column-title {
                font-weight: 600;
                color: ${variables.text};
            }

            tr {
                th {
                    border-top: 1px solid ${variables.navyBorder};
                }
            }
        }
        .ant-table-tbody > .ant-table-row > td {
            font-size: ${variables.fontSizeSecondary};
            color: ${variables.text};
            background-color: ${variables.white};
            border-bottom: 1px solid ${variables.navyBorder};
        }
    }

    .file-name {
        padding-left: 24px;
        span {
            word-break: break-all;
        }
    }
`

export const StyledTableWrapper = styled.section`
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    margin-bottom: ${variables.gutterMd};
`
export const ExportPaginationWrapper = styled.div`
    padding-bottom: 20px;
`
