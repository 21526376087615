import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledDescriptionBlock = styled.section``
export const CenteredContent = styled.div`
    align-items: center;
`
export const AdvantagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${variables.gutterMd};
`
export const FirstTitle = styled.div`
    font-size: 74px;
    font-weight: 700;
    color: ${variables.signupText};

    & > div {
        font-style: italic;
        font-weight: 300;
        color: #4fc7ff;
        line-height: 12px;
    }
`
export const Subtitle = styled.h3`
    font-size: ${variables.sectionFontSize};
    font-weight: 700;
    color: ${variables.signupText};
`
export const SecondTitle = styled.div`
    font-size: 18px;
    font-weight: 400;
`
