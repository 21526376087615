import {NavLink} from 'react-router-dom'
import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

const commonLinkStyles = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${variables.gutterSm} 0;
    font-size: ${variables.fontSizeSecondary};
    transition: all 0.3s linear;
    border-left: 3px solid ${variables.darkBlueBackground};
    &:hover {
        background: rgba(0, 0, 0, 0.4);
    }
    && {
        color: ${variables.white};
    }

    &&&.active-menu-item {
        font-weight: 600;
        background: ${variables.white};
        color: ${variables.navyBlue};

        &:hover {
            color: ${variables.navyBlue};
        }
    }
`

export const StyledNavLink = styled(NavLink)`
    ${commonLinkStyles}
`

export const StyledLink = styled(CALink)`
    ${commonLinkStyles}
`

export const StyledIcon = styled(Icon)`
    font-size: 20px;
    margin: 0 0 4px;
`

export const StyledBadge = styled(Icon)`
    font-size: 40px !important;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 4px;
    top: -2px;
`

export const StyledTitle = styled.div`
    max-width: 90%;
    white-space: pre-wrap;
    text-align: center;
    display: contents;
`

export const StyledMenuLink = styled(CALink)`
    display: flex;
    line-height: normal;
    align-content: center;
    align-items: center;
    height: 72px;
    text-decoration: none !important;
    .anticon {
        font-size: 20px;
        height: auto;
        margin: 0 14px 0 0;
    }
`
