import {Input, Select} from 'antd'
import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'

import DatePicker from 'rc-calendar/lib/Picker'
import {DATE_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT, TIME_FORMAT} from 'ca-common/utils/datetime'

import {uniqBy} from 'ca-common/utils/uniqBy'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {Label} from 'ca-common/ui-lib/components/FormItem'
import {postLessData} from 'src/newcore/utils/rest'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'
import {CACompareCalendar} from './CalendarEntity'
import {StyledSnapshotCalendar, DayPicker, TimePicker} from './StyledSnapshotCalendar'
import {setCalendarSnapshots} from './redux'

const {Option} = Select

const VISIBLE_CALENDAR_RANGE = 112000000

const SnapshotCalendarRaw = ({
    initialValue,
    value,
    payload = {},
    onChange,
    flexFlow,
    innerRef,
    showPartialStatus,
    label,
    disabled,
    dateFormat,
    needTimePicker,
    firstBackupDate,
    retentionDate,
    succeedOnly,
    actions
}) => {
    const [timeValue, setTimeValue] = useState()
    const [dateValue, setDateValue] = useState([initialValue])
    const [snapshots, setSnapshots] = useState({})
    const [alreadyFetchedMonths, setAlreadyFetchedMonths] = useState(new Map())

    const {t} = useTranslation()

    const updateAlreadyFetchedMonths = (k, v) => {
        setAlreadyFetchedMonths(new Map(alreadyFetchedMonths.set(k, v)))
    }

    const onSelectDate = (date, isFirstRender = false) => {
        if (!date) {
            setDateValue([])
            setTimeValue()
            !isFirstRender && onChange('')
            return
        }

        if (_.isString(date)) {
            date = moment(date, LONG_DATE_FORMAT)
        }

        if (date) {
            const snapshotsThatDay = []
            _.forEach(snapshots[payload.taskId], d => {
                if (date.isSame(d.dateTime, 'day')) {
                    snapshotsThatDay.push(d)
                }
            })

            if (snapshotsThatDay.length === 0) {
                setDateValue([])
                setTimeValue()
                !isFirstRender && onChange('')
                return
            }

            if (snapshotsThatDay.length === 1) {
                setDateValue([snapshotsThatDay[0]])
                !isFirstRender && onChange(_.get(snapshotsThatDay[0], 'dateTime').format(LONG_DATE_FORMAT))
                return
            }

            for (let i = 0; i < snapshotsThatDay.length; i++) {
                if (snapshotsThatDay[i].succeed) {
                    const time = snapshotsThatDay[i].dateTime.format(LONG_DATE_FORMAT)
                    setDateValue(snapshotsThatDay)
                    setTimeValue(time)
                    !isFirstRender && onChange(time)
                    return
                }
            }
        }
    }

    const onTimeSelect = time => {
        _.forEach(dateValue, date => {
            if (date.dateTime.isSame(moment(time, LONG_DATE_FORMAT))) {
                setTimeValue(time)
                onChange(time)
            }
        })
    }

    const convertTimeStringToMoment = dataList => {
        return _.map(dataList, snapshot => ({
            ...snapshot,
            dateTime: moment(getLocalDateFromUTC(snapshot.dateTime), LONG_DATE_FORMAT) //2019 08 14 11:43:55
        }))
    }

    const getSnapshotsCalendar = async (month, year) => {
        const data = await postLessData('getSnapshotsCalendar', {
            ...payload,
            year,
            month
        })
        return data?.data
    }

    const generateFakeBackups = (month, year) => {
        const daysInMonth = new Date(year, month, 0).getDate()
        const newSnapshots = []
        for (let day = 1; day <= daysInMonth; day++) {
            const generatedDate = month * 10 ** 8 + year * 10 ** 10 + day * 10 ** 6
            if (generatedDate > firstBackupDate && generatedDate < retentionDate) {
                newSnapshots.push({
                    dateTime: generatedDate,
                    succeed: true,
                    status: ACTIVITY_STATUS.SUCCEED,
                    retention: true
                })
            }
        }
        return newSnapshots
    }

    const cacheSnapshotIndices = (month, year, taskId) => {
        if (!alreadyFetchedMonths.has(taskId)) {
            updateAlreadyFetchedMonths(taskId, new Map())
        }
        if (alreadyFetchedMonths.get(taskId).has(year)) {
            updateAlreadyFetchedMonths(
                taskId,
                alreadyFetchedMonths.get(taskId).set(year, [...alreadyFetchedMonths.get(taskId).get(year), --month])
            )
        } else {
            updateAlreadyFetchedMonths(taskId, alreadyFetchedMonths.get(taskId).set(year, [--month]))
        }
    }

    const getSnapshotsForCalendar = async (month, year) => {
        const {taskId} = payload

        if (alreadyFetchedMonths.get(taskId)?.get(year)?.includes(month) || !taskId || !firstBackupDate) return

        let newSnapshots = []

        month++
        const calendarPageDate = month * 10 ** 8 + year * 10 ** 10

        if (firstBackupDate - calendarPageDate > VISIBLE_CALENDAR_RANGE) return

        if (retentionDate - calendarPageDate < VISIBLE_CALENDAR_RANGE) {
            try {
                newSnapshots = await getSnapshotsCalendar(month, year)
            } catch (e) {
                openErrorNotification(t('snapshotCalendar:fetchingError'))
                console.error(e)
                return
            }
        }

        if (succeedOnly) {
            newSnapshots = newSnapshots.filter(snapshot => snapshot.succeed)
        }

        if (firstBackupDate - calendarPageDate < VISIBLE_CALENDAR_RANGE && calendarPageDate < retentionDate) {
            newSnapshots = [...newSnapshots, ...generateFakeBackups(month, year)]
        }

        cacheSnapshotIndices(month, year, taskId)

        setSnapshots(prevState => {
            return {
                ...prevState,
                [taskId]: uniqBy(
                    prevState[taskId]?.length
                        ? [...prevState[taskId], ...convertTimeStringToMoment(newSnapshots)]
                        : convertTimeStringToMoment(newSnapshots),
                    o => o.dateTime.toString()
                )
            }
        })
    }

    useEffect(() => {
        getSnapshotsForCalendar(moment().month(), moment().year())
    }, [payload.taskId])

    useEffect(() => {
        actions.setCalendarSnapshots(snapshots)
    }, [snapshots])

    useEffect(() => {
        onSelectDate(value, true)
        if (innerRef?.current) {
            innerRef.current.reset = () => {
                setTimeValue()
                setDateValue([initialValue])
                setAlreadyFetchedMonths(new Map())
            }
        }
    }, [])

    const datePickerContainerRef = useRef()

    return (
        <StyledSnapshotCalendar flexFlow={flexFlow}>
            <DayPicker ref={datePickerContainerRef} onClick={e => e.preventDefault()}>
                <Label>{label ? <span>{label}</span> : <span>{t('snapshotCalendar:currentDate')}</span>}</Label>
                <DatePicker
                    ref={innerRef}
                    calendar={
                        <CACompareCalendar
                            showPartialStatus={showPartialStatus}
                            fetchData={(month, year) => getSnapshotsForCalendar(month, year)}
                            snapshotsList={snapshots[payload.taskId]}
                        />
                    }
                    value={dateValue[0] && dateValue[0].dateTime}
                    onChange={onSelectDate}
                    getCalendarContainer={() => datePickerContainerRef?.current}
                >
                    {({value}) => <Input disabled={disabled} value={(value && value.format(dateFormat)) || ''} />}
                </DatePicker>
            </DayPicker>

            {!needTimePicker && dateValue.length > 1 && (
                <TimePicker>
                    <Label>
                        <span>{t('snapshotCalendar:currentTime')}</span>
                    </Label>
                    <Select
                        style={{width: '100%'}}
                        onSelect={onTimeSelect}
                        value={timeValue}
                        onChange={setTimeValue}
                        getPopupContainer={t => t.parentElement}
                    >
                        {_.map(dateValue, snapshot => {
                            const dateStamp = snapshot.dateTime.format(LONG_DATE_FORMAT)

                            return (
                                <Option value={dateStamp} key={dateStamp} disabled={!snapshot.succeed}>
                                    {snapshot.dateTime.format(TIME_FORMAT)}
                                </Option>
                            )
                        })}
                    </Select>
                </TimePicker>
            )}
        </StyledSnapshotCalendar>
    )
}

SnapshotCalendarRaw.propTypes = {
    dateFormat: PropTypes.string,
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    flexFlow: PropTypes.string,
    showPartialData: PropTypes.bool,
    needTimePicker: PropTypes.bool,
    taskId: PropTypes.string,
    firstBackupDate: PropTypes.number,
    retentionDate: PropTypes.number,
    succeedOnly: PropTypes.bool
}

SnapshotCalendarRaw.defaultProps = {
    dateFormat: DATE_FORMAT,
    initialValue: null,
    disabled: false,
    onChange: () => {},
    flexFlow: 'row'
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setCalendarSnapshots
        },
        dispatch
    )
})

const CalendarConnected = connect(null, mapDispatchToProps)(SnapshotCalendarRaw)

export const SnapshotCalendar = React.forwardRef((props, ref) => <CalendarConnected innerRef={ref} {...props} />)
