import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'

import {Button} from 'src/ca-common/ui-lib/atoms'
import {TODO_ANY} from 'ca-common/types'

import {CancelLink, LinkWrapper, ModalFooter} from 'src/ca-common/components/Modal'
import {closeWizard, nextStep, setWizardTitle} from 'src/newcore/redux/modules/wizard'
import {MailboxSelection} from 'src/newcore/features/RecoveryWizard/organisms/MailboxSelection'

type SelectionOfMailboxProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const SelectionOfMailboxRaw = ({actions, entity}: SelectionOfMailboxProps) => {
    const {t} = useTranslation()
    useEffect(() => {
        actions.setWizardTitle(t('recovery:wizard:mailboxSelection:title'))
    }, [])

    return (
        <>
            <MailboxSelection />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={() => actions.closeWizard()}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button disabled={!entity} onClick={() => actions.nextStep()} type="primary">
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
            </ModalFooter>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    fullRecoveryOptions: state.wizard.data.fullRecoveryOptions,
    entity: state.wizard.data?.entity
})
const mapDispatchToProps = (dispatch: TODO_ANY) => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            setWizardTitle
        },
        dispatch
    )
})

export const SelectionOfMailbox = connect(mapStateToProps, mapDispatchToProps)(SelectionOfMailboxRaw)
