import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {RoundedThreeSelect} from 'ca-common/ui-lib/components/FormItem'

export const StyledTagsFilter = styled(({className, ...props}) => (
    <RoundedThreeSelect {...props} popupClassName={className} />
))`
    .ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
        width: calc(100% - 16px);
    }

    .ant-select-tree li,
    .ant-select-tree-treenode {
        margin: 0;
        padding: ${variables.gutterXs} ${variables.gutterSm};
    }

    .ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
    .ant-select-tree-treenode span:hover {
        background: transparent;
    }

    .ant-select-tree li:hover,
    .ant-select-tree-treenode:hover {
        background-color: ${variables.blueLight};
    }

    .ant-select-tree {
        padding: 0;
    }

    .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop,
    .ant-select-tree-switcher.ant-select-tree-switcher-noop {
        display: none;
    }
`

export const TagIcon = styled(Icon)`
    position: absolute;
    z-index: 1;
    color: transparent;
    top: calc(50% - 12px);
    left: ${variables.gutterXs};
    font-size: 24px;
`
