import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledProgressBox = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: ${variables.gutterSm} ${variables.gutterMd};
    width: 100%;
`

export const NormalTitle = styled.div`
    color: ${variables.dark};
    font-weight: 600;
`

export const ExceptionTitle = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: ${variables.gutterXs};
`

export const ExceptionTitleHeader = styled.div`
    color: ${props => props.color};
    font-weight: 600;
`

export const ExceptionTitleText = styled.div`
    color: ${variables.asphalt};
`
