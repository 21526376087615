import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {JumbotronLink} from 'ca-common/ui-lib/molecules/JumbotronLink'
import {MainMenu} from 'ca-common/ui-lib/components/MainMenu'
import {updateQueryParams} from 'ca-common/redux/modules/navigation'
import {PAGES} from 'ca-common/constants'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

import {SECTIONS} from './Constants'
import {validatePageAccess} from 'ca-common/utils/permissions'

export const AccountActivityPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector(userInfoSelector)
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateQueryParams())
    }, [])

    const accountActivityList =
        userInfo &&
        SECTIONS.map(
            (section, index) =>
                validatePageAccess({page: section.permissionKey, userInfo}) && (
                    <JumbotronLink
                        key={index}
                        title={t(`accountActivity:${section.name}:title`)}
                        icon={section.icon}
                        link={`${PAGES.ACCOUNT_ACTIVITY}/${section.name}`}
                        description={t(`accountActivity:${section.name}:caption`)}
                        tile
                    />
                )
        ).filter(Boolean)

    return <MainMenu>{accountActivityList}</MainMenu>
}
