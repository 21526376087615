import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledFooter = styled.div`
    align-items: center;
    border-top: 1px solid ${variables.blue};
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0 ${variables.gutterMd};
`
