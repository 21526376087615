import {ReactSVGElement} from 'react'

import {i18n} from 'ca-common/locales'
import Failed from 'ca-common/icons/backupStatusNew/Failed.svg'
import Partial from 'ca-common/icons/backupStatusNew/Partial.svg'
import Success from 'ca-common/icons/backupStatusNew/Success.svg'
import Cancelled from 'ca-common/icons/backupStatusNew/Cancelled.svg'
import Archived from 'ca-common/icons/backupStatus/Archived.svg'
import InProcess from 'ca-common/icons/backupStatus/InProcess.svg'

// datamodel/Status
export enum ACTIVITY_STATUS {
    UNDEFINED = 'UNDEFINED',
    SUCCEED = 'SUCCEED',
    PARTIALLY_SUCCEED = 'PARTIALLY_SUCCEED',
    FAILED = 'FAILED',
    NOTAUTHENTICATED = 'NOTAUTHENTICATED',
    CANCELLED = 'CANCELLED',
    SKIPPED = 'SKIPPED',
    ARCHIVED = 'ARCHIVED'
}

// datamodel.TaskStatus
/*export enum TASK_STATUS {
    ACTIVE = 'ACTIVE',
    NOTACTIVE = 'NOTACTIVE',
    IN_ANOTHER = 'IN_ANOTHER',
    PENDING = 'PENDING',
    PAUSED = 'PAUSED',
    ON_PREMISE = 'ON_PREMISE',
    SKIPPED = 'SKIPPED',
    DISPATCHED = 'DISPATCHED',
    QUEUED = 'QUEUED',
    INPROCESS = 'INPROCESS',
    ARCHIVED = 'ARCHIVED'
}*/

// datamodel/task/ExecutionStatus
export enum EXECUTION_STATUS {
    SUCCESS = 'SUCCESS',
    PARTIAL = 'PARTIAL',
    FAILED = 'FAILED',
    NOT_ACTIVE = 'NOT_ACTIVE',
    SCHEDULED = 'SCHEDULED',
    IN_PROCESS = 'IN_PROCESS',
    PAUSED = 'PAUSED',
    ARCHIVED = 'ARCHIVED',
    DUPLICATE = 'DUPLICATE'
}

export const getActivityStatusIcon = (status: ACTIVITY_STATUS): ReactSVGElement | '' => {
    switch (status) {
        case ACTIVITY_STATUS.UNDEFINED:
        case ACTIVITY_STATUS.FAILED:
        case ACTIVITY_STATUS.NOTAUTHENTICATED:
            return Failed

        case ACTIVITY_STATUS.SUCCEED:
            return Success

        case ACTIVITY_STATUS.PARTIALLY_SUCCEED:
            return Partial

        case ACTIVITY_STATUS.CANCELLED:
            return Cancelled

        case ACTIVITY_STATUS.ARCHIVED:
            return Archived

        case ACTIVITY_STATUS.SKIPPED:
            return InProcess

        default:
            return ''
    }
}

export const getActivityStatusFriendly = (status?: ACTIVITY_STATUS): string => {
    switch (status) {
        case ACTIVITY_STATUS.UNDEFINED:
        case ACTIVITY_STATUS.FAILED:
        case ACTIVITY_STATUS.NOTAUTHENTICATED:
            return i18n.t('statuses:activity:failed')

        case ACTIVITY_STATUS.SUCCEED:
            return i18n.t('statuses:activity:success')

        case ACTIVITY_STATUS.PARTIALLY_SUCCEED:
            return i18n.t('statuses:activity:partial')

        case ACTIVITY_STATUS.CANCELLED:
            return i18n.t('statuses:activity:cancelled')

        case ACTIVITY_STATUS.ARCHIVED:
            return i18n.t('statuses:activity:archived')

        case ACTIVITY_STATUS.SKIPPED:
            return i18n.t('statuses:activity:skipped')

        default:
            return ''
    }
}
