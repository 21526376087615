import styled from 'styled-components'
import {Checkbox} from 'antd'
import variables from 'ca-common/variables'

export const StyledEntity = styled.div`
    margin-bottom: ${variables.gutterMd};
`

export const StyledCheckboxRow = styled(Checkbox)`
    display: flex;
    align-items: center;

    > span:last-child {
        display: flex;
        margin-bottom: 2px;
    }
`

const EntityCol = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 ${variables.gutterSm};
`

export const Name = styled(EntityCol)`
    width: 180px;
`

export const Email = styled(EntityCol)`
    width: 240px;
`
