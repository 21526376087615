import styled from 'styled-components'
import {Input} from 'antd'

import variables from 'ca-common/variables'

export const Postfix = styled.div`
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
`

export const StyledBackupNameInput = styled.div`
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
`

export const StyledInput = styled(Input)`
    width: 246px;
    font-weight: 600;
    border-radius: 2px;
`
