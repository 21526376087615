import {PROGRESS_BOX_STATUS} from 'ca-common/common/enum/ProgressBox'

export const SEEDING_STATUS = {
    SUCCESS: 'SUCCESS',
    IN_PROCESS: 'INPROCESS',
    FAILED: 'FAILED',
    LIMIT_EXCEEDED: 'API_LIMIT_EXCEEDED',
    PARTIALLY_SUCCESS: 'PARTIALLY_SUCCESS',
    CANCELED: 'CANCELED'
}

export const adaptSeedingStatusToProgressBox = compareStatus => {
    switch (compareStatus) {
        case SEEDING_STATUS.CANCELED:
            return PROGRESS_BOX_STATUS.CANCELED

        case SEEDING_STATUS.FAILED:
            return PROGRESS_BOX_STATUS.FAILED

        case SEEDING_STATUS.IN_PROCESS:
            return PROGRESS_BOX_STATUS.IN_PROCESS

        case SEEDING_STATUS.LIMIT_EXCEEDED:
            return PROGRESS_BOX_STATUS.LIMIT_EXCEEDED

        case SEEDING_STATUS.SUCCESS:
            return PROGRESS_BOX_STATUS.SUCCESS

        case SEEDING_STATUS.PARTIALLY_SUCCESS:
            return PROGRESS_BOX_STATUS.PARTIALLY_SUCCESS

        default:
            return ''
    }
}
