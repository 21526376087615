import React from 'react'

import {SELECTION_TYPE} from 'ca-common/common/enum/Table'
import {CACheckBox} from 'ca-common/ui-lib/components/CheckBox'
import {CACellModifier} from 'ca-common/ui-lib/components/TableElements/index'
import {uncheckedOnThisPage, isAllItemsOnPageAreSelected} from 'ca-common/utils/table'

export class CASelectAll extends React.Component {
    onClick(checked, rowSelection) {
        if (checked) {
            if (rowSelection.areAllItemsSelected) {
                rowSelection.setSelectAllItems(false)
            } else {
                rowSelection.onItemClick(rowSelection.currentPageItems)
            }
        } else {
            rowSelection.onItemClick(uncheckedOnThisPage(rowSelection))
        }
    }

    render() {
        const {column, rowSelection} = this.props

        switch (rowSelection.type) {
            case SELECTION_TYPE.CHECKBOX: {
                const checked = isAllItemsOnPageAreSelected(rowSelection) || rowSelection.areAllItemsSelected

                return (
                    <CACellModifier>
                        <CACheckBox
                            id={column.name}
                            checked={checked}
                            onChange={() => this.onClick(checked, rowSelection)}
                        />
                    </CACellModifier>
                )
            }
            default:
                return <CACellModifier />
        }
    }
}
