import {useCallback} from 'react'

import {WIZARD_TYPE} from 'ca-common/constants'
import {SOURCE} from 'ca-common/common/enum'

import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {changeData, openWizardWithConfig} from 'src/newcore/redux/modules/wizard'

const SERVICES_SELECTION_STEP = '0'

type OpenServicesSelectionModalParams = {
    availableServices: SOURCE[]
    enabledServices?: SOURCE[]
    error?: string
}

export const useOpenServicesSelectionModal = () => {
    const dispatch = useAppDispatch()

    return useCallback((params: OpenServicesSelectionModalParams) => {
        dispatch(
            openWizardWithConfig({
                type: WIZARD_TYPE.MS_BACKUP,
                step: SERVICES_SELECTION_STEP,
                config: {
                    maskClosable: false,
                    destroyInactiveTabPane: true
                }
            })
        )
        dispatch(changeData(params))
    }, [])
}
