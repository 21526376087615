import React from 'react'
import {connect} from 'react-redux'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'
import {AppState} from 'src/newcore/components/Root'

const SDBRaw = ({
    source,
    backupSettings,
    onSubmit,
    onCancel,
    userInfo
}: BackupAuthTemplate & ReturnType<typeof mapStateToProps>): JSX.Element => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const {t} = useTranslation()

    const initialValues = {
        sdbregion: backupSettings.dbRegion || userInfo.response.region,
        alias: backupSettings.alias,
        sdbkey: backupSettings.domain
    }

    const requiredRule = {
        required: true,
        message: t('forms:common:validation:required')
    }

    return (
        <StyledBackupAuthForm initialValues={initialValues} layout="horizontal" onFinish={values => onSubmit(values)}>
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>
                        <Form.Item
                            name="sdbregion"
                            label={t('forms:backupAuth:fields:sdbRegion:label')}
                            colon={false}
                            rules={[requiredRule]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="sdbkey"
                            label={t('forms:backupAuth:fields:sdbAccessKey:label')}
                            colon={false}
                            rules={[requiredRule]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="sdbsecret"
                            label={t('forms:backupAuth:fields:sdbSecretKey:label')}
                            colon={false}
                            rules={[requiredRule]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:common:actions:apply')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}

const mapStateToProps = (state: AppState) => ({
    userInfo: state.userInfo
})

export const SDB = connect(mapStateToProps)(SDBRaw)
