import styled from 'styled-components'
import Splitter from 'react-split'
import variables from 'ca-common/variables'

export const StyledRecoverySplitter = styled(Splitter)`
    background-color: ${variables.white};
    height: 692px;
    display: flex;
    flex-direction: row;

    .gutter {
        height: 637px;
        align-self: flex-end;
        background-color: ${variables.navyBorder};
        background-repeat: no-repeat;
        cursor: col-resize;

        &:hover {
            background-color: ${variables.inProcess};
        }

        &:active {
            background-color: ${variables.navyBlue};
        }
    }

    > *:not(.ant-table-wrapper) {
        overflow: hidden;
    }
`
