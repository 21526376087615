import React from 'react'
import PropTypes from 'prop-types'

import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import './CheckBox.scss'

const dumbCheckBox = dumbBem('ca-form-checkbox')
const CheckBox = tx(dumbCheckBox)('div')
const Input = tx([{element: 'input'}, dumbCheckBox])('input')
const Label = tx([{element: 'label'}, dumbCheckBox])('label')

export class CACheckBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {checked: (props && props.checked) || false}
    }
    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.string
    }

    static defaultProps = {
        title: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            checked: (props && props.checked) || false
        }
    }

    handleChange(event) {
        this.setState({checked: event.target.checked})
        this.props.onChange(event)
    }

    render() {
        const {
            props: {id, label, ...rest}
        } = this

        return (
            <CheckBox modifier={label ? 'labeled' : ''}>
                <Input
                    id={id}
                    {...rest}
                    checked={this.state.checked}
                    onChange={e => this.handleChange(e)}
                    type="checkbox"
                />
                <Label htmlFor={id}>{label}</Label>
            </CheckBox>
        )
    }
}

export default CACheckBox
