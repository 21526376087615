import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables, {interaction20} from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    font-size: 17px;
    margin-right: ${variables.gutterXs};
    vertical-align: middle;

    & > svg {
        vertical-align: middle;
    }
`
export const StyledDetails = styled.div`
    margin-bottom: 0px;
    background: ${variables.white};
`

export const StyledDetailsText = styled.div`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.subTitleFontSize};
    font-weight: 400;
    line-height: 3.65;
    margin-left: ${variables.gutterMd};
`

export const StyledDetailsButtonsWrapper = styled.div<{isLoading?: boolean}>`
    display: flex;
    border: 1px solid ${variables.navyBlue};
    border-radius: 20px;
    background-color: ${variables.navyBlue};
    margin-top: ${variables.gutterSm};
    width: max-content;
    margin-left: auto;

    button:nth-child(1) {
        padding-right: 40px;
    }

    button:nth-child(2) {
        margin-left: -27px;
        padding-left: 40px;
    }

    ${props =>
        props.isLoading &&
        css`
            border-color: ${variables.navyAsphalt} !important;
            background-color: ${variables.navyAsphalt};
            color: ${variables.asphaltLight} !important;
            pointer-events: none;
        `}
`

export const StyledDetailsButton = styled.button<{isActive?: boolean; isLoading?: boolean; isDisabled?: boolean}>`
    border: 1px solid ${props => (props.isLoading ? variables.navyAsphalt : variables.navyBlue)};
    border-radius: inherit;
    padding: 4px 30px;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;
    color: ${props =>
        props.isLoading ? variables.asphaltLight : props.isDisabled ? variables.navyAsphalt : variables.white};

    ${props =>
        props.isDisabled &&
        css`
            pointer-events: none;
            cursor: not-allowed;
        `}

    ${props =>
        props.isActive
            ? css`
                  background-color: ${props.isLoading ? variables.interaction20 : variables.white};
                  z-index: 2;
                  cursor: default;
                  color: ${props.isLoading ? variables.asphaltLight : variables.navyBlue};
                  font-weight: 600;
              `
            : css`
                  &:hover {
                      color: ${variables.white};
                  }
              `}
`

export const CurrencyBlockWrapper = styled.div`
    margin-bottom: ${variables.gutterSm};
    display: flex;
    justify-content: center;
`
