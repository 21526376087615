import React from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {N_A, CustomBarGroup} from '../../lib'
import {Square, Information} from '../../atoms'

import {Wrapper, Header, Content, Total, Date} from './StyledTooltipContent'

type TooltipContentProps = {
    tooltipData: CustomBarGroup<string>
}

export const TooltipContent = (props: TooltipContentProps): JSX.Element => {
    const {tooltipData} = props

    const {t} = useTranslation()

    const getValueFrendly = (value: number): string => {
        return value === N_A ? t('table:date:noDate') : value.toString()
    }

    return (
        <Wrapper>
            <Header>
                <Total>{t('backupChart:tooltip:total', {total: tooltipData.total})}</Total>
                <Date>{moment(tooltipData.date, 'YYYYMMDD').format('ddd, MMM DD').toString()}</Date>
            </Header>
            <Content>
                {tooltipData?.bars?.map(bar => {
                    const {key, value, color} = bar

                    return (
                        <Information
                            square={<Square color={color} />}
                            category={key}
                            count={getValueFrendly(value)}
                            key={key}
                        />
                    )
                })}
            </Content>
        </Wrapper>
    )
}
