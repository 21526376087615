import {createAction, createReducer} from '@reduxjs/toolkit'

const SELECT_SNAPSHOT = 'SELECT_SNAPSHOT'
const UNSELECT_SNAPSHOT = 'UNSELECT_SNAPSHOT'

export const selectSnapshot = createAction(SELECT_SNAPSHOT)
export const unselectSnapshot = createAction(UNSELECT_SNAPSHOT)

const selectSnapshotReducer = (state, action) => {
    return [action.payload]
}

const unselectSnapshotReducer = () => []

export const snapshotReducer = createReducer([], {
    [SELECT_SNAPSHOT]: selectSnapshotReducer,
    [UNSELECT_SNAPSHOT]: unselectSnapshotReducer
})
