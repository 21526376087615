import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledDropdownContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${variables.gutterXs};
    gap: 10px;

    width: 186px;

    background: ${variables.white};
    box-shadow: 0 12px 24px rgba(4, 28, 59, 0.08);
    border-radius: ${variables.borderRadiusBase};
`
