import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledNoJobsFound = styled.div`
    margin: 74px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const IconRoundWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: ${variables.interaction};
    margin-bottom: ${variables.gutterSm};
`
export const StyledNoJobsIcon = styled(Icon)`
    width: 112px;
    height: 105px;
    font-size: 112px;
`
export const NoJobsText = styled.p`
    margin-bottom: 0;
    font-weight: 300;
    text-transform: capitalize;
    color: ${variables.asphaltLight};
    font-size: ${variables.mainTitleFontSize};
`
