import React from 'react'

type SquareProps = {
    color: string
    size?: number
    width?: number
    height?: number
    rx?: number
    strokeColor?: string
    fillColor?: string
}

export const Square = (props: SquareProps): JSX.Element => {
    const {strokeColor, fillColor, color, width, height, size = 8, rx = 2} = props

    return (
        <svg width={width || size} height={height || size}>
            <rect
                stroke={strokeColor || color}
                fill={fillColor || color}
                width={width || size}
                height={height || size}
                rx={rx}
            />
        </svg>
    )
}
