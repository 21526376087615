import {createSlice} from '@reduxjs/toolkit'

import {postLessData} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const GET_MS365_GROUPS = 'GET_MS365_GROUPS'
const GET_MS365_GROUPS_API = 'getGroups'
const getMS365GroupsWrapper = fetchWrapperRT(
    GET_MS365_GROUPS,
    async data => await postLessData(GET_MS365_GROUPS_API, data)
)
export const getMS365Groups = getMS365GroupsWrapper.fetcher

const APPEND_MS365_GROUPS = 'APPEND_MS365_GROUPS'
const appendBulkGroupsWrapper = fetchWrapperRT<MS365GroupsListResponse>(
    APPEND_MS365_GROUPS,
    async data => await postLessData(GET_MS365_GROUPS_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                return {
                    ...response,
                    data: [...state.response.data, ...response.data]
                }
            }

            return response
        }
    }
)
export const appendMS365Groups = appendBulkGroupsWrapper.fetcher

type MS365GroupsListResponse = {data: TODO_ANY[]}

const MS365GroupsListSlice = createSlice({
    name: 'MS365GroupsList',
    initialState: InitFetchedState as FetchedState<MS365GroupsListResponse>,
    reducers: {
        clearMS365Groups: () => InitFetchedState as FetchedState<MS365GroupsListResponse>
    },
    extraReducers: builder => {
        getMS365GroupsWrapper.makeReducers(builder)
        appendBulkGroupsWrapper.makeReducers(builder)
    }
})

export const {clearMS365Groups} = MS365GroupsListSlice.actions
export const MS365GroupsListReducer = MS365GroupsListSlice.reducer
