import React, {useState} from 'react'
import {Modal} from 'antd'
import {useTranslation} from 'react-i18next'

import {GracePeriodNotificationForm} from './GracePeriodNotificationForm'

type GracePeriodNotificationProps = {
    createTask: (arg0: any) => Promise<any>
    closeModal: () => void
    showModal: boolean
}

export const GracePeriodNotification = ({
    showModal,
    closeModal,
    createTask
}: GracePeriodNotificationProps): JSX.Element => {
    const [isWaitingCompletionTask, setIsWaitingCompletionTask] = useState(false)
    const {t} = useTranslation()

    const handleSubmit = (values: {gracePeriodCreateType: any}) => {
        setIsWaitingCompletionTask(true)

        createTask({creationType: values.gracePeriodCreateType}).then(() => {
            setIsWaitingCompletionTask(false)
            closeModal()
        })
    }

    return (
        <Modal
            open={showModal}
            title={t('gracePeriodNotification:title')}
            closable={false}
            maskClosable={false}
            footer={null}
        >
            <GracePeriodNotificationForm
                handleSubmit={handleSubmit}
                loading={isWaitingCompletionTask}
                closeModal={closeModal}
            />
        </Modal>
    )
}
