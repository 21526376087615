export const COUNTRIES_REQUIRED_TAX_ID = ['IL']

export const EMAIL_INVOICE_TYPE_OPTIONS = [
    {value: 'LINK', title: 'Link'},
    {value: 'ATTACHMENT', title: 'Attachment'}
]

export const createRules = (validators: ((value: any) => string | undefined)[]) => {
    return [
        () => ({
            validator: (_: any, value: any) => {
                let error: string | undefined

                for (const validator of validators) {
                    error = validator(value)

                    if (error) {
                        return Promise.reject(error)
                    }
                }
                return Promise.resolve()
            }
        })
    ]
}
