import React, {Fragment, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import {BackLink, CancelLink, LinkWrapper, ModalFooter, ModalCenterContentWrapper} from 'ca-common/components/Modal'

import {SearchField} from './SearchField'
import {FieldValues} from './FieldValues'
import {closeWizard, nextStep, previousStep, setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {GroupListWrapper} from 'src/newcore/components/BulkActivation/StyledBulkActivation'

const ActiveDirectoryRaw = ({actions, checkedAccountPropertyValues}) => {
    const {t} = useTranslation()

    useEffect(() => {
        actions.setWizardDescription(t('backups:ActiveDirectory:description'))
    }, [])

    return (
        <Fragment>
            <GroupListWrapper>
                <ModalCenterContentWrapper>
                    <SearchField />
                    <FieldValues />
                </ModalCenterContentWrapper>
            </GroupListWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={() => actions.closeWizard()}> {t('modals:buttons:cancel')}</CancelLink>
                    <Button
                        type="primary"
                        onClick={() => actions.nextStep()}
                        disabled={checkedAccountPropertyValues.length < 1}
                    >
                        {t('forms:common:actions:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => actions.previousStep()} />
            </ModalFooter>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    checkedAccountPropertyValues: state.backups.bulkActivation.activeDirectory.checkedAccountPropertyValues,
    createRule: state.backups.bulkActivation.createRule
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            previousStep,
            closeWizard,
            setWizardDescription
        },
        dispatch
    )
})

export const ActiveDirectory = withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveDirectoryRaw))
