import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Text = styled.span<{isWrapped?: boolean}>`
    font-weight: 300;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;
    white-space: nowrap;
`

export const AnonymizationSelectWrapper = styled.div`
    .ant-select-arrow {
        color: ${variables.text};
    }

    .ant-select-selection-item {
        color: ${variables.text};
    }

    .ant-select-single {
        width: 100%;
    }

    .ant-select-disabled {
        .ant-select-selector {
            background-color: transparent !important;
        }

        .ant-select-arrow {
            display: none;
        }
    }
`
