import React, {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {reduxForm, change} from 'redux-form'
import {useTranslation} from 'react-i18next'
import {Spin} from 'antd'

import CAButton from 'ca-common/ui-lib/components/Button'
import {CAFormPage, CAFormPageFooter, CAFormPageError} from 'ca-common/ui-lib/components/FormPage'
import {emailValidate, requiredValidate} from 'ca-common/utils/validation'
import {getOptionsFromDataCenters} from 'ca-common/utils/Region'
import {CustomS3Form} from 'ca-common/components/AddNewAccount/CustomS3'
import {AWSS3CompatibleForm} from 'ca-common/components/AddNewAccount/AWSS3Compatible'
import {GoogleCloudPlatform} from 'ca-common/components/AddNewAccount/GoogleCloudPlatform'
import {AzureForm} from 'ca-common/components/AddNewAccount/Azure'
import {STORAGE_TYPE} from 'ca-common/common/enum'
import {INTEGRATION_TYPE} from 'ca-common/constants'
import {post} from 'src/newcore/utils/rest'
import {openErrorNotification} from 'ca-common/utils/toasts'
import {ZixBackButton} from 'ca-common/atoms/ZixBackButton'

import {renderField} from 'src/newcore/components/Settings/Utils'

const formName = 'ArchiveLocation'
const adminsEndpoint = 'getAdminEmail'

const ArchiveLocationRaw = props => {
    const {
        handleSubmit,
        submitting,
        invalid,
        pristine,
        storageType,
        errorMessage,
        companyInfo,
        userInfo,
        change,
        formValues
    } = props

    const {t} = useTranslation(['archiveLocation', 'modals'])
    const [admins, setAdmins] = useState({})
    const [loading, setLoading] = useState(true)
    const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX
    const adminEmailList = useMemo(() => Object.keys(admins), [admins])

    const handleAdminChange = (e, newValue) => change(formName, 'name', admins[newValue])

    const archiveLocationOptions = useMemo(
        () => [
            ...getOptionsFromDataCenters(),
            {
                label: t('forms:customS3:title'),
                value: STORAGE_TYPE.AMAZON_S3
            },
            {
                label: t('forms:awsS3Compatible:title'),
                value: STORAGE_TYPE.AMAZON_S3_COMPATIBLE
            },
            {
                label: t('forms:gcp:title'),
                value: STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM
            },
            {
                label: t('forms:azure:title'),
                value: STORAGE_TYPE.AZURE
            }
        ],
        []
    )

    const getCustomStorageOptions = (storageType, currentStorage) => {
        switch (true) {
            case storageType === STORAGE_TYPE.AMAZON_S3 || currentStorage === STORAGE_TYPE.AMAZON_S3:
                return <CustomS3Form allFieldsRequired={true} />
            case storageType === STORAGE_TYPE.AMAZON_S3_COMPATIBLE ||
                currentStorage === STORAGE_TYPE.AMAZON_S3_COMPATIBLE:
                return <AWSS3CompatibleForm allFieldsRequired={true} />
            case storageType === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM ||
                currentStorage === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM:
                return <GoogleCloudPlatform allFieldsRequired={true} />
            case storageType === STORAGE_TYPE.AZURE || currentStorage === STORAGE_TYPE.AZURE:
                return <AzureForm allFieldsRequired={true} />
            default:
                return null
        }
    }

    useEffect(() => {
        post(adminsEndpoint, null)
            .then(response => {
                setAdmins(response)
            })
            .catch(e => {
                openErrorNotification(e.status)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <CAFormPage>
            <form onSubmit={handleSubmit}>
                <h4>
                    {isZix
                        ? t('modals:archiveLocation:zixLocation')
                        : t('modals:archiveLocation:location', {
                              companyName: companyInfo.name
                          })}
                </h4>
                {!storageType &&
                    renderField({
                        name: 'region',
                        type: 'select',
                        placeholder: t('modals:archiveLocation:placeholder'),
                        options: archiveLocationOptions,
                        validate: [requiredValidate]
                    })}
                {getCustomStorageOptions(storageType, formValues?.region)}
                {isZix && (
                    <Spin spinning={loading}>
                        <h4>{t('modals:archiveLocation:email')}</h4>
                        <div style={{display: 'flex', height: '56px', alignItems: 'flex-start'}}>
                            <div style={{flex: 1, marginRight: '24px'}}>
                                {adminEmailList.length > 0
                                    ? renderField({
                                          name: 'email',
                                          key: 'email',
                                          type: 'select',
                                          placeholder: t('modals:archiveLocation:emailPlaceholder'),
                                          validate: [requiredValidate],
                                          options: adminEmailList.map(email => ({
                                              value: email,
                                              label: email
                                          })),
                                          onChange: handleAdminChange,
                                          modifier: 'q3LMEFjzJs'
                                      })
                                    : renderField({
                                          name: 'email',
                                          key: 'email',
                                          placeholder: t('modals:archiveLocation:emailPlaceholder'),
                                          validate: [requiredValidate, emailValidate],
                                          formGroupModifier: 'q3LMEFjzJs'
                                      })}
                            </div>
                            {renderField({
                                name: 'name',
                                key: 'name',
                                placeholder: t('modals:archiveLocation:namePlaceholder'),
                                validate: [requiredValidate],
                                formGroupModifier: 'q3LMEFjzJs'
                            })}
                        </div>
                    </Spin>
                )}

                <CAFormPageFooter>
                    {isZix && <ZixBackButton url={companyInfo.siteUrl} isArchiveLocation />}
                    <CAFormPageError>{errorMessage}</CAFormPageError>
                    <CAButton modifier={'primary'} disabled={invalid || pristine || submitting} type="submit">
                        {t('modals:buttons:save')}
                    </CAButton>
                </CAFormPageFooter>
            </form>
        </CAFormPage>
    )
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo.response,
    userInfo: state.userInfo.response,
    formValues: state.form[formName]?.values
})

const mapDispatchToProps = dispatch => bindActionCreators({change}, dispatch)

export const ArchiveLocation = connect(mapStateToProps, mapDispatchToProps)(ArchiveLocationRaw)

export const ArchiveLocationForm = reduxForm({form: formName, destroyOnUnmount: false})(ArchiveLocation)
