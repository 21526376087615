import React, {useEffect} from 'react'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'

import {
    AccountDeactivation,
    ActivateAllNewAccounts,
    BackupFrequency,
    BackupHour,
    BulkCondition,
    DetectNewAccounts,
    IndexStatus,
    RetentionPeriod,
    StorageRegion
} from 'src/newcore/features/BackupSettings/molecules'
import {MultiEntitySettings} from 'src/newcore/features/BackupSettings/organisms'
import {BulkActivation} from 'src/newcore/components/BulkActivation'

import {BackupSettingsDivider, SettingsInfo} from '../StyledSettings'
import {useAppSelector} from 'src/newcore/components/Root'

export const SharepointMulti = props => {
    const {t} = useTranslation()
    const {
        taskSettings,
        isFinra,
        isHighFrequencyBackupEnabled,
        form,
        isMultiFrequencyBlocked,
        isSpecialPartnerAccount
    } = props

    const bulkActivationRules = useAppSelector(
        state => state.backups.bulkActivation.bulkActivationRules.rules.response?.data || []
    )

    useEffect(() => {
        form.setFieldValue('detectNewAccounts', taskSettings.detectNewAccounts)
    }, [taskSettings.detectNewAccounts])

    return (
        <MultiEntitySettings {...props}>
            <Row gutter={16}>
                <Col lg={14}>
                    <SettingsInfo>
                        <h2>{t('forms:backupSettings:title')}</h2>
                        <p>{t('forms:backupSettings:text:noticeContent')}</p>
                    </SettingsInfo>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col lg={16} xl={14} xxl={11}>
                    <IndexStatus indexStatus={taskSettings.indexStatus} />

                    {taskSettings.oauth ? (
                        <ActivateAllNewAccounts
                            detectNewAccounts={taskSettings.detectNewAccounts}
                            source={taskSettings.source}
                        />
                    ) : (
                        <DetectNewAccounts
                            detectNewAccounts={taskSettings.detectNewAccounts}
                            source={taskSettings.source}
                        />
                    )}

                    <AccountDeactivation
                        daysToAccountDeactivation={taskSettings.daysToAccountDeactivation}
                        source={taskSettings.source}
                        isFinra={isFinra}
                    />
                </Col>

                <Col span={1}>
                    <BackupSettingsDivider />
                </Col>

                <Col lg={7} xl={9} xxl={12}>
                    <Row gutter={16}>
                        <Col lg={24} xl={12}>
                            <StorageRegion storageRegion={taskSettings.storageRegion} />
                        </Col>
                        <Col lg={24} xl={12}>
                            <RetentionPeriod
                                retentionType={taskSettings.retentionType}
                                retentionPeriod={taskSettings.retentionPeriod}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupFrequency
                                backupFrequency={taskSettings.backupFrequency}
                                isHighFrequencyBackupEnabled={isHighFrequencyBackupEnabled}
                                isHighFrequencyBackupBlocked={
                                    taskSettings.isHighFrequencyBackupBlocked || isMultiFrequencyBlocked
                                }
                                isSpecialPartnerAccount={isSpecialPartnerAccount}
                                isSurchargeNotificationDisplayed={taskSettings.isSurchargeNotificationDisplayed}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupHour
                                backupHour={taskSettings.backupHour}
                                backupFrequency={taskSettings.backupFrequency}
                                isDistributedBackupHour={taskSettings.isDistributedBackupHour}
                                distributedBackupHourValue={taskSettings.distributedBackupHourValue}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {taskSettings.oauth && (
                <>
                    <Row>
                        <BulkActivation isAuthenticated={taskSettings.isAuthenticated} source={taskSettings.source} />
                    </Row>
                    <Row>
                        <Col>
                            {bulkActivationRules?.map(rule => (
                                <BulkCondition rule={rule} key={rule.id} />
                            ))}
                        </Col>
                    </Row>
                </>
            )}
        </MultiEntitySettings>
    )
}
