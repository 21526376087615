import styled from 'styled-components'

import variables from 'ca-common/variables'
import {MainTitle} from '../../ui-lib/atoms/Typography'

export const StyledCustomers = styled.div`
    padding: ${variables.gutterLg};
    background: ${variables.brandDark};
    text-align: center;
    @media (max-width: ${variables.screenMd}) {
        padding: ${variables.gutterLg} ${variables.gutterMd};
    }
`

export const CustomersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: auto;
    align-items: center;

    @media (max-width: ${variables.screenMd}) {
        max-width: 400px;
    }
`

export const Header = styled(MainTitle)`
    && {
        padding: ${variables.gutterMd} 0 ${variables.gutterXxl};
        color: ${variables.white};
        font-family: ${variables.publicPageFont};
        font-size: ${variables.subTitleFontSize};
        line-height: 20px;
        margin: 0 auto;

        @media (min-width: ${variables.screenMd}) {
            font-size: 24px;
            line-height: 30px;
            max-width: 800px;
        }
    }
`

export const Content = styled.div`
    height: 100px;
    padding: 0 ${variables.gutterLg};
    @media (max-width: ${variables.screenMd}) {
        padding: 0 ${variables.gutterLg};
    }
`
