import React from 'react'
import {Tooltip} from 'antd'

import {Info, InfoLabel, StyledInfoColumn} from './StyledInfoColumn'

type InfoColumnProps = {
    label: string
    shortInfo?: string
    info?: string | number
    withTooltip?: boolean
}

export const InfoColumn = ({label, info = '-', withTooltip, shortInfo}: InfoColumnProps): JSX.Element => (
    <StyledInfoColumn>
        <InfoLabel>{label}</InfoLabel>
        {withTooltip ? (
            <Tooltip placement="top" title={info}>
                <Info>{shortInfo ?? info}</Info>
            </Tooltip>
        ) : (
            <Info>{info}</Info>
        )}
    </StyledInfoColumn>
)
