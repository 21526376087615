import styled from 'styled-components'
import {Divider} from 'antd'
import variables from 'ca-common/variables'

export const StyledColSpace = styled.div`
    padding: 4px;
`

export const StyledContainer = styled.div<{flexCondition?: any}>`
    background-color: ${variables.white};
    text-align: center;
    padding: 28px 57px;
    gap: 10px;
    border-radius: ${variables.gutterXs};
    flex: ${props => props.flexCondition};
`

export const StyledHomeContentContainer = styled.div<{flexCondition?: any}>`
    background-color: ${variables.white};
    text-align: center;
    padding: ${props => (props.flexCondition >= 3 ? '' : '28px 57px')};
    padding-top: ${props => (props.flexCondition >= 3 ? '28px' : '')};
    padding-bottom: ${props => (props.flexCondition >= 3 ? '28px' : '')};
    gap: 10px;
    border-radius: ${variables.gutterXs};
    justify-content: center;
    align-items: center;
    flex: ${props => (props.flexCondition > 2 ? '1' : '1 0 auto')};
`

export const StyledContainerContents = styled.div`
    background-color: ${variables.white};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${variables.gutterLg};
`

export const StyledHeader = styled.text`
    color: var(--Navy---Blue, ${variables.navyBlue});
    text-align: center;
    font-family: 'Open Sans';
    font-size: ${variables.gutterSm};
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
`

export const StyledSubText = styled.text`
    color: var(--Text-Dark, ${variables.text});
    text-align: center;
    font-family: Lato;
    font-size: ${variables.fontSizeBase};
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 22.4px */
`

export const StyledSubLine = styled.div`
    width: 0.75px;
    height: 24.546px;
    background: ${variables.asphaltLight};
`

export const StyledSubHeading = styled.text`
    font-weight: 400;
    font-family: ${variables.accentFont};
    font-size: ${variables.headingFontSize};
`
export const StyledHomeContainer = styled.div<{flexVal?: any}>`
    text-align: center;
    gap: 8px;
    display: ${props => (props.flexVal >= 2 ? 'flex' : 'block')};
    min-width: 661px;
    max-width: 661px;
    width: 100%;
    margin: 0 auto;
`

export const StyledHomeDivider = styled(Divider)`
    margin-top: ${variables.gutterXs};
    margin-bottom: ${variables.gutterMd};
`
