import styled from 'styled-components'

import {SectionTitle} from 'ca-common/ui-lib/atoms/Typography'
import variables from 'ca-common/variables'

export const Title = styled(SectionTitle)`
    width: 100%;

    && {
        color: ${variables.text};
        margin-bottom: 0;
    }
`
