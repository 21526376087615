import React from 'react'
import {useTranslation} from 'react-i18next'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {isTaskPaused, isTaskProcessing, isTaskQueued} from '../../../utils/utils'
import {ACTIONS} from '../../../utils/constants'
import {status} from 'src/newcore/features/Homepage/types'
import {TaskActionsItem} from 'src/newcore/features/Homepage/atoms/TaskActionsItem'

type ResumeProps = {
    status: status
    pullAndUpdateLocalServices: () => void
    sendAction: any
    disabled: boolean
}

export const ResumeItem = ({status, pullAndUpdateLocalServices, sendAction, disabled}: ResumeProps): JSX.Element => {
    const {t} = useTranslation()

    return <TaskActionsItem action={ACTIONS.RESUME} onClick={onClickHandler} disabled={disabled} />

    function onClickHandler() {
        if (!isTaskProcessing(status) && !isTaskQueued(status)) {
            const activate = isTaskPaused(status)

            sendAction(ACTIONS.RESUME, activate)
                .then(() => {
                    openInfoNotification(t(`backups:statusChange`, [t(`backups:activate:success:true`)]))
                    pullAndUpdateLocalServices()
                })
                .catch((error: any) => {
                    openErrorModal(
                        t(`backups:failedTo`, [t(`backups:activate:error:true`)]) + (error ? error.status : '')
                    )
                })
        }
    }
}
