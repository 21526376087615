import React, {useMemo} from 'react'
import moment from 'moment'

import {JOBS_STATUSES, SOURCE, STORAGE_TYPE, TASK_TYPE} from 'ca-common/common/enum'
import {PAGES, PORTAL_BASENAME} from 'ca-common/constants'
import {LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {makeUrlParams} from 'ca-common/utils/url'

import {JobFields} from 'src/newcore/features/Jobs/types'

import {DownloadElement} from './DownloadElement'

type DownloadReportButtonProps = Pick<
    JobFields,
    'taskId' | 'account' | 'alias' | 'taskType' | 'source' | 'status' | 'endTime'
> & {
    downloadReportPermitted: boolean
    storageType: STORAGE_TYPE
}

const EXPIRED_FROM_HOURS = 72
const MOMENT_DIFF_TYPE = 'hours'

export const DownloadReportButton = ({
    taskType,
    status,
    source,
    taskId,
    alias,
    account,
    endTime,
    downloadReportPermitted,
    storageType
}: DownloadReportButtonProps): JSX.Element | null => {
    const downloadsAvailable = useMemo(() => {
        return [JOBS_STATUSES.SUCCEEDED, JOBS_STATUSES.PARTIALLY_SUCCEEDED].includes(status)
    }, [status])

    const checkSFCReport = useMemo(() => {
        if (taskType === TASK_TYPE.RESTORE) {
            return source === SOURCE.SFC
        }

        return true // taskType === TASK_TYPE.EXPORT
    }, [taskType, source])

    const isDirectDownload = useMemo(() => {
        if (taskType === TASK_TYPE.EXPORT) {
            return storageType === STORAGE_TYPE.CLOUDALLY_S3
        }

        return true // taskType === TASK_TYPE.RESTORE
    }, [taskType, storageType])

    const downloadIsExpired = useMemo(() => {
        const endTimeUtc = moment.utc(endTime, LONG_DATE_FORMAT)
        const currentUtc = moment.utc()

        const diff = currentUtc.diff(endTimeUtc, MOMENT_DIFF_TYPE)

        return diff >= EXPIRED_FROM_HOURS
    }, [endTime])

    const urlString = `/${PORTAL_BASENAME}/${PAGES.JOBS}/downloadReport?${makeUrlParams({taskId, alias, account})}`

    return downloadsAvailable && checkSFCReport && isDirectDownload ? (
        <DownloadElement
            isReport={taskType === TASK_TYPE.RESTORE}
            linkTo={urlString}
            downloadIsExpired={downloadIsExpired}
            permitted={downloadReportPermitted}
            taskType={taskType}
            source={source}
        />
    ) : null
}
