import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit'

import {ANONYMIZATION_MODE, SEEDING_DESTINATION_TYPE} from 'ca-common/common/enum/seeding'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {LongDateNumber} from 'ca-common/types'
import {changeData} from 'src/newcore/redux/modules/wizard'

import {StyledSelectDestination, RadioWrapper, Email} from './StyledSelectDestination'

type SeedingWizardData = {
    anonymizationMode?: ANONYMIZATION_MODE | null
    dataType: SFC_DATA_TYPE
    date: LongDateNumber
    objectType: any
    disableTriggers: boolean
    destinationType: SEEDING_DESTINATION_TYPE
}

export type SelectDestinationProps = {
    sameEmail: string
    actions: {
        changeData: (changes: Record<string, unknown>) => void
    }
    wizard: SeedingWizardData
}

export const SelectDestinationRaw = ({sameEmail, actions, wizard}: SelectDestinationProps) => {
    useEffect(() => {
        actions.changeData({
            disableTriggers: true,
            destinationType: SEEDING_DESTINATION_TYPE.SAME
        })
    }, [])
    const {t} = useTranslation()

    const handleDisableTriggersClick = (value: boolean) => {
        actions.changeData({
            disableTriggers: value
        })
    }

    const handleDestinationTypeClick = (value: SEEDING_DESTINATION_TYPE) => {
        actions.changeData({
            destinationType: value
        })
    }

    return (
        <StyledSelectDestination>
            <RadioWrapper>
                <RadioBlock
                    title={t('seeding:destinationType:same:label')}
                    description={<Email>{sameEmail}</Email>}
                    value={SEEDING_DESTINATION_TYPE.SAME}
                    checked={wizard.destinationType === SEEDING_DESTINATION_TYPE.SAME}
                    onChange={() => handleDestinationTypeClick(SEEDING_DESTINATION_TYPE.SAME)}
                />
                <RadioBlock
                    title={t('seeding:destinationType:other:label')}
                    description={t('seeding:destinationType:other:description')}
                    value={SEEDING_DESTINATION_TYPE.OTHER}
                    checked={wizard.destinationType === SEEDING_DESTINATION_TYPE.OTHER}
                    onChange={() => handleDestinationTypeClick(SEEDING_DESTINATION_TYPE.OTHER)}
                />
            </RadioWrapper>
            {wizard.dataType === SFC_DATA_TYPE.DATA && (
                <Checkbox checked={wizard.disableTriggers} onChange={e => handleDisableTriggersClick(e.target.checked)}>
                    {t('seeding:destinationType:autoDisableRules')}
                </Checkbox>
            )}
        </StyledSelectDestination>
    )
}

const mapStateToProps = (state: {wizard: {data: SeedingWizardData}}) => ({
    wizard: state.wizard.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(
        {
            changeData
        },
        dispatch
    )
})

export const SelectDestination = connect(mapStateToProps, mapDispatchToProps)(SelectDestinationRaw)
