import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledBackupAddForm = styled(Form)``

const BackupAddUserBlock = styled.div`
    padding: 0 ${variables.gutterMd};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const BackupAddUserHeader = styled.div`
    padding: ${variables.gutterMd} ${variables.gutterMd} 0;
    display: flex;
    align-items: baseline;
`

export const BackupAddUserHeaderTitle = styled.h2`
    font-size: 18px;
    margin: 0;
`

export const BackupAddUserHeaderMessage = styled.span`
    font-size: 18px;
    margin: 0 0 0 ${variables.gutterXs};
    color: ${variables.asphalt};
`

export const BackupAddUserFooter = styled(BackupAddUserBlock)`
    height: 60px;
`

export const BackupAddUserBody = styled.div`
    border-bottom: 1px solid ${variables.blue};
    padding: ${variables.gutterMd};
`

export const StyledErrorMessage = styled.div`
    strong {
        font-weight: 600;
    }
`

export const ErrorTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${variables.red};
    margin-bottom: ${variables.gutterXs};
    font-size: ${variables.fontSizeBase};
`

export const ErrorIcon = styled(Icon)`
    font-size: ${variables.fontSizeBase};
    margin-right: ${variables.gutterSm};
    color: transparent;
`
