import {post} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

export const GetSupportTicketDialogs = fetchWrapperRT(
    'GET_SUPPORT_DIALOG_MESSAGES',
    async data => await post('getSupportTicketDialog', data)
)
export const getSupportTicketDialogsFetcher = GetSupportTicketDialogs.fetcher

export const AddMessageToSupportDialog = fetchWrapperRT(
    'ADD_MESSAGE_TO_SUPPORT_DIALOG',
    async data => await post('addMessage', data)
)
export const addMessageToSupportDialog = AddMessageToSupportDialog.fetcher

export const AddSupportTicket = fetchWrapperRT('ADD_SUPPORT_TICKET', async data => await post('supportTicketAdd', data))
export const addSupportTicket = AddSupportTicket.fetcher

export const ZendeskChat = fetchWrapperRT('ZENDESK_CHAT', async data => await post('zendeskChat', data))
export const zendeskChat = ZendeskChat.fetcher

const addMessageToDialog = (dialog, payload) => ({
    ...dialog,
    messages: [...dialog.messages, payload]
})

const updateDialogs = (data, payload) =>
    data.map(dialog => {
        return dialog.id === payload.id ? addMessageToDialog(dialog, payload.message) : dialog
    })

const addMessageToDialogLocalReducer = (state, action) => ({
    ...state,
    response: {
        ...state.response,
        data: [...updateDialogs(state.response.data, action.payload)]
    }
})

const supportTicketDialogsSlice = createSlice({
    name: 'supportTicketDialogs',
    initialState: InitFetchedState,
    reducers: {
        addMessageToDialogLocal: addMessageToDialogLocalReducer
    },
    extraReducers: builder => GetSupportTicketDialogs.makeReducers(builder)
})

export const {addMessageToDialogLocal} = supportTicketDialogsSlice.actions
export const supportTicketDialogs = supportTicketDialogsSlice.reducer
