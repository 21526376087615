import _ from 'lodash'
import React from 'react'
import {NavLink} from 'react-router-dom'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

const dumbBreadcrumbs = dumbBem('ca-breadcrumbs')
export const BreadcrumbsWrapper = tx(dumbBreadcrumbs)('ul')
export const BreadcrumbsItem = tx([{element: 'item'}, dumbBreadcrumbs])('li')

import './Breadcrumbs.scss'

export const CABreadcrumbsRaw = ({breadcrumbs}) => (
    <BreadcrumbsWrapper>
        {_.map(breadcrumbs, (breadcrumb, i) => (
            <BreadcrumbsItem key={breadcrumb.key}>
                {React.isValidElement(breadcrumb?.breadcrumb) &&
                i !== 0 &&
                _.isFunction(breadcrumb?.breadcrumb.type) ? (
                    breadcrumb.breadcrumb
                ) : (
                    <NavLink to={breadcrumb.match.url}>{breadcrumb.breadcrumb}</NavLink>
                )}
                {i < breadcrumbs.length - 1 && <span>&gt;</span>}
            </BreadcrumbsItem>
        ))}
    </BreadcrumbsWrapper>
)
