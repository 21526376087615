import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    display: flex;
    flex: 1;

    &.ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .ant-form-item-control {
        line-height: normal;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-form-item {
        margin-right: ${variables.gutterMd};
    }

    .ant-form-item:last-child {
        margin-right: 0;
    }
`

export const StyledField = styled(Form.Item)`
    && {
        flex: 1;
        max-width: 415px;
        margin-right: auto;
    }
`

export const StyledIcon = styled(Icon)`
    margin-right: 16px;
    vertical-align: middle;
    font-size: 24px;
`
