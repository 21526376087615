import Icon from '@ant-design/icons'
import styled, {keyframes} from 'styled-components'

import variables from 'ca-common/variables'

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ItemsRow = styled.div`
    display: flex;
    width: 100%;
    color: ${variables.inProcess};
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }
`
export const ItemIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const ItemName = styled.p`
    margin: 0;
`
export const ItemCount = styled.p`
    margin: 0;
`
export const ItemIcon = styled(Icon)`
    animation: ${spinner} 2s infinite linear;
    margin-right: 4px;
    font-size: 8px;
    circle {
        color: ${variables.navyBlue};
    }
`
