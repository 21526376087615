import React from 'react'

import {
    ContactsTableItem,
    CalendarsTableItem,
    EmailsTableItem,
    NotesTableItem,
    TableDataItems
} from 'src/newcore/features/Recovery/fetch'
import {SOURCE} from 'ca-common/common/enum'
import type {MS365SubSource} from 'ca-common/types'

import {
    ExpandedContactRow,
    ExpandedCalendarRow,
    ExpandedEmailRow,
    ExpandedNoteRow
} from 'src/newcore/features/Recovery/atoms'

type ExpandedRowProps = {
    record: TableDataItems
    selectedSubSource: MS365SubSource
}

export const ExpandedRow = (props: ExpandedRowProps): JSX.Element | null => {
    const {selectedSubSource, record} = props

    switch (selectedSubSource) {
        case SOURCE.O365MAIL:
            return <ExpandedEmailRow record={record as EmailsTableItem} />
        case SOURCE.O365CALENDAR:
            return <ExpandedCalendarRow record={record as CalendarsTableItem} />
        case SOURCE.O365CONTACTS:
            return <ExpandedContactRow record={record as ContactsTableItem} />
        case SOURCE.O365NOTES:
            return <ExpandedNoteRow record={record as NotesTableItem} />
        default:
            return null
    }
}
