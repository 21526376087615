import React from 'react'

import type {TableConfig} from 'ca-common/types/tableConfig'
import {CABaseTable} from 'ca-common/components/Table'
import {PageStatus} from 'ca-common/components/Billing/PageStatus'

type InvoiceHistoryItem = {
    id: string
    documentNumber: number
    issueDate: string
    dueDate: string
    currency: string
    totalAmountWithTax: number
    amount: string
    description: string
    status: string
    downloadUrl: string
    originUrl: string
}

type InvoiceHistory = {
    data: InvoiceHistoryItem[]
    page: number
    status: string
    success: boolean
    total: number
    totalPages: number
}

type BillingHistoryProps = {
    invoiceHistory: InvoiceHistory
    getInvoiceHistory: (data: any) => void
    config: TableConfig
    pageSize?: number
}

export const BillingHistory = (props: BillingHistoryProps) => {
    const {invoiceHistory, getInvoiceHistory, config, pageSize} = props

    return (
        <div>
            {invoiceHistory.total === 0 ? (
                <PageStatus title="noInvoices" status="nobill" />
            ) : (
                <CABaseTable
                    parent
                    scrollable
                    config={config}
                    items={invoiceHistory}
                    fetchItems={getInvoiceHistory}
                    pageSize={pageSize}
                />
            )}
        </div>
    )
}
