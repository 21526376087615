import {warningConfigs} from './warningConfigs'
import {isEqual, omit} from 'lodash'
import {RULES} from './rules'

export const checkAlertConfiguration = config => {
    const {values, level, triggeredBy} = config
    const {unit, amount} = values
    const wizardValues = {
        level,
        triggeredBy,
        unit
    }
    let needWarning = false

    warningConfigs.forEach(warningConfig => {
        const configValues = omit(warningConfig, RULES.AMOUNT)

        if (isEqual(configValues, wizardValues) && amount && amount < warningConfig.amount) {
            needWarning = true
        }
    })

    return needWarning
}
