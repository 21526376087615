import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {ResetPasswordInit} from 'ca-common/components/ResetPassword'

import {resetPassword} from 'src/newcore/redux/modules/resetPassword'

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            resetPassword
        },
        dispatch
    )
})

const ResetPasswordInitRaw = ({actions, history}) => {
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = values => actions.resetPassword(values).then(() => setSubmitted(true))

    return (
        <ResetPasswordInit
            submitted={submitted}
            onSubmit={handleSubmit}
            signIn={{
                to: {
                    pathname: '/sign-in',
                    search: history.location.search
                }
            }}
            signUp={{
                to: {
                    pathname: '/sign-up',
                    search: history.location.search
                }
            }}
        />
    )
}

export const ResetPasswordInitPage = withRouter(connect(null, mapDispatchToProps)(ResetPasswordInitRaw))
