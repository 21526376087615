import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {HeaderWithIp} from 'ca-common/features/IpRestriction/atoms'

import {StyledIntroduction, StyledBody} from './StyledIntroduction'

type IntroductionProps = {
    yourIpAddress: string
}

export const Introduction = (props: IntroductionProps): JSX.Element => {
    const {yourIpAddress} = props
    const {t} = useTranslation()

    return (
        <StyledIntroduction>
            <HeaderWithIp yourIpAddress={yourIpAddress} title={t('ipRestrictions:introductionHeader')} />

            <StyledBody>
                <Trans i18nKey="ipRestrictions:introduction" />
            </StyledBody>
        </StyledIntroduction>
    )
}
