import {useState} from 'react'

import {SOURCE} from 'ca-common/common/enum'

import {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {getTotalSelectedEntities} from 'src/newcore/features/OneClickActivation/lib/getTotalSelectedEntities'

type UseEntitiesInfoStateProps = {
    wizardData: OCAWizardData
    service: SOURCE
    entitiesBitSet: string
    totalEntities: number
}

export const useEntitiesInfoState = ({
    wizardData,
    service,
    entitiesBitSet,
    totalEntities
}: UseEntitiesInfoStateProps) => {
    const [enabledEntitiesBitSet, setEnabledEntitiesBitSet] = useState(wizardData[service]!.enabledEntitiesBitSet)
    const [totalSelectedEntities, _setTotalSelectedEntities] = useState(
        getTotalSelectedEntities(wizardData[service]!.enabledEntitiesBitSet)
    )

    const setTotalSelectedEntitiesFromBitSet = (enabledEntitiesBitSet: string) => {
        _setTotalSelectedEntities(getTotalSelectedEntities(enabledEntitiesBitSet))
    }

    const updateOneEnabledEntitiesBitSet = (ordinal: number, checked: boolean) => {
        setEnabledEntitiesBitSet(prev => {
            if (ordinal >= prev.length) {
                return prev
            }

            const newValue =
                prev.substring(0, totalEntities - ordinal - 1) +
                (checked ? '1' : '0') +
                prev.substring(totalEntities - ordinal)

            setTotalSelectedEntitiesFromBitSet(newValue)

            return newValue
        })
    }

    const updateManyEnabledEntitiesBitSet = (checked: boolean) => {
        setEnabledEntitiesBitSet(prev => {
            let newValue: string
            if (checked) {
                newValue = (BigInt(`0b${entitiesBitSet}`) | BigInt(`0b${prev}`)).toString(2)
            } else {
                newValue = (~BigInt(`0b${entitiesBitSet}`) & BigInt(`0b${prev}`)).toString(2)
            }

            setTotalSelectedEntitiesFromBitSet(newValue)

            return newValue.padStart(prev.length, '0')
        })
    }

    return {
        enabledEntitiesBitSet,
        updateOneEnabledEntitiesBitSet,
        updateManyEnabledEntitiesBitSet,
        totalSelectedEntities,
        setTotalSelectedEntitiesFromBitSet,
        setEnabledEntitiesBitSet
    }
}
