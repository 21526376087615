import React from 'react'
import {Row, Col, Input, Alert, Form} from 'antd'
import {useTranslation, Trans} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'
import {SOURCE} from 'ca-common/common/enum/Source'
import Error from 'ca-common/icons/Error.svg'
import {getSvcAccountsDescription} from 'ca-common/utils/text'

import {
    StyledBackupAddForm,
    BackupAddUserHeader,
    BackupAddUserBody,
    BackupAddUserFooter,
    BackupAddUserHeaderTitle,
    BackupAddUserHeaderMessage,
    StyledErrorMessage
} from './StyledBackupAddUser'

export const BackupAddUserForm = props => {
    const {onSubmit, onCancel, source, error, loading} = props
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const rules =
        source === SOURCE.MS365
            ? [
                  {required: true, message: t('forms:common:fields:email:validation:required')},
                  {type: 'email', message: t('forms:common:fields:email:validation:invalid')}
              ]
            : [{required: true, message: t('forms:common:validation:required')}]
    const entityName = getSvcAccountsDescription(source)

    const onFinishHandler = values => {
        onSubmit(values, form)
    }

    return (
        <StyledBackupAddForm layout="vertical" onFinish={onFinishHandler} hideRequiredMark form={form}>
            <BackupAddUserHeader>
                <BackupAddUserHeaderTitle>
                    {t('backups:addModalTitle', [entityName])}
                    <BackupAddUserHeaderMessage>
                        {t('backups:addUserModalMessage', [
                            t(`serviceNames:${source}`),
                            getSvcAccountsDescription(source, true).toLowerCase()
                        ])}
                    </BackupAddUserHeaderMessage>
                </BackupAddUserHeaderTitle>
            </BackupAddUserHeader>
            <BackupAddUserBody>
                <Row gutter={16}>
                    <Col>
                        <Form.Item label={t(`subSvcCountDisp:${source}`)} colon={false} name="entity" rules={rules}>
                            <Input />
                        </Form.Item>
                        {error && (
                            <StyledErrorMessage>
                                <Alert
                                    message={
                                        <Trans
                                            values={{entityName: entityName.toLowerCase()}}
                                            i18nKey={'forms:addEntity:response:error'}
                                        />
                                    }
                                    description={error}
                                    type="error"
                                    showIcon
                                    icon={<Error />}
                                />
                            </StyledErrorMessage>
                        )}
                    </Col>
                </Row>
            </BackupAddUserBody>
            <BackupAddUserFooter>
                <Button type="link" onClick={onCancel}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type="primary" htmlType="submit" ghost loading={loading}>
                    {t('forms:common:actions:save')}
                </Button>
            </BackupAddUserFooter>
        </StyledBackupAddForm>
    )
}
