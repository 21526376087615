import React, {useMemo} from 'react'
import {Form, FormInstance, FormListFieldData, Input, Select, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'
import {useWatch} from 'antd/lib/form/Form'

import MinusCircle from 'ca-common/icons/MinusCircle.svg'
import PlusCircle from 'ca-common/icons/PlusCircle.svg'

import {URLOperator} from 'src/newcore/components/BulkActivation/SharepointUrl/constants'
import {StyledActionButton, StyledCondition, StyledConditionsActions, StyledConditionText} from './StyledConditionList'

const {Option} = Select

type ConditionProps = {
    field: FormListFieldData
    remove: (index: number | number[]) => void
    add: (defaultValue?: any, insertIndex?: number | undefined) => void
    fields: FormListFieldData[]
    form: FormInstance
}

export const Condition = ({field: {key, name, ...restField}, remove, add, fields, form}: ConditionProps) => {
    const {t} = useTranslation()
    const {urlConditions} = useWatch([], form) || {}

    const tooltip = useMemo(() => {
        const operator = urlConditions?.[key]?.operator
        return operator && t(`backups:bulkActivation:sharepoint:url:tooltips:${operator}`)
    }, [urlConditions?.[key]?.operator])

    return (
        <StyledCondition>
            <StyledConditionText>
                {fields[0].key === key
                    ? t('backups:bulkActivation:sharepoint:url:text:url')
                    : t('backups:bulkActivation:sharepoint:url:text:or')}
            </StyledConditionText>

            <Form.Item
                {...restField}
                name={[name, 'operator']}
                validateTrigger={['onBlur']}
                rules={[
                    {
                        message: t('backups:bulkActivation:sharepoint:url:fields:condition:error'),
                        required: true
                    }
                ]}
            >
                <Select
                    placeholder={t('backups:bulkActivation:sharepoint:url:fields:condition:placeholder')}
                    style={{width: '166px'}}
                    getPopupContainer={trigger => trigger.parentElement}
                >
                    {Object.values(URLOperator).map(operator => (
                        <Option key={operator} value={operator}>
                            {t(`backups:bulkActivation:sharepoint:url:conditions:${operator}`)}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Tooltip title={tooltip} {...(!tooltip ? {open: false} : {})} overlayStyle={{maxWidth: '300px'}}>
                <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    validateTrigger={['onBlur']}
                    rules={[
                        {
                            message: t('backups:bulkActivation:sharepoint:url:fields:value:error'),
                            required: true
                        }
                    ]}
                >
                    <Input
                        placeholder={t('backups:bulkActivation:sharepoint:url:fields:value:placeholder')}
                        style={{width: '200px'}}
                    />
                </Form.Item>
            </Tooltip>
            <StyledConditionsActions>
                {fields.length > 1 && (
                    <StyledActionButton
                        icon={<Icon component={MinusCircle} />}
                        borderWidth={0}
                        shape="circle"
                        size="middle"
                        type="default"
                        onClick={() => remove(name)}
                    />
                )}

                <StyledActionButton
                    icon={<Icon component={PlusCircle} />}
                    borderWidth={0}
                    shape="circle"
                    size="middle"
                    type="default"
                    onClick={() => add()}
                />
            </StyledConditionsActions>
        </StyledCondition>
    )
}
