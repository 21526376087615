import styled from 'styled-components'
import variables from 'ca-common/variables'

import {Input, Form} from 'antd'

const {Item} = Form

export const StyledWrapper = styled.section`
    width: 336px;
`

export const StyledExportSubtitle = styled.div`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};

    margin-bottom: ${variables.gutterXs};
`

export const StyledInput = styled(Input)`
    width: 330px;
    display: block;
`

export const StyledPasswordInput = styled(Input.Password)`
    width: 330px;
    height: 32px;
`

export const StyledLabel = styled.span`
    display: block;
    width: 330px;
    margin-top: ${variables.gutterSm};
    margin-bottom: ${variables.gutterXs};

    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
`

export const FormItem = styled(Item)`
    margin-bottom: ${variables.gutterSm};

    .ant-form-item-control {
        line-height: 100%;
    }
`
