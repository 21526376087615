import {combineReducers} from '@reduxjs/toolkit'
import {clearGoogleOU, GoogleOUListReducer as GoogleOUList} from './GoogleOUList'
import {clearSelectedOU, selectedOUReducer as selectedOU} from './SelectedOU'
import {clearAccountsWithOU, GoogleAccountsWithOU} from './GoogleAccountsWithOU'
import {clearBlockedOU, blockedOUReducer as blockedOU} from './BlockedOU'
import {AppDispatch} from 'src/newcore/components/Root'

export const GoogleOUReducer = combineReducers({
    GoogleOUList,
    GoogleAccountsWithOU,
    selectedOU,
    blockedOU
})

export const clearGoogleData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(clearSelectedOU())
        dispatch(clearAccountsWithOU())
        dispatch(clearGoogleOU())
        dispatch(clearBlockedOU())
    }
}
