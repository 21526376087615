import {createSlice} from '@reduxjs/toolkit'

import {postLessData} from 'src/newcore/utils/rest'

import {
    DEFAULT_PAGE,
    ROWS_ACCOUNT_PROPERTY_LIST
} from 'src/newcore/components/BulkActivation/ActiveDirectory/ActiveDirectory/SearchField'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'
import {TODO_ANY} from 'ca-common/types'

const GET_ACCOUNT_PROPERTY_LIST = 'GET_ACCOUNT_PROPERTY_LIST'
const GET_ACCOUNT_PROPERTY_LIST_API = 'getAccountPropertyList'
const getAccountPropertyListWrapper = fetchWrapperRT(
    GET_ACCOUNT_PROPERTY_LIST,
    async data => await postLessData(GET_ACCOUNT_PROPERTY_LIST_API, data)
)

export const getAccountPropertyList = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(
        getAccountPropertyListWrapper.fetcher({
            page: DEFAULT_PAGE,
            rows: ROWS_ACCOUNT_PROPERTY_LIST,
            ...data
        })
    )
}

const accountPropertyListSlice = createSlice({
    name: 'accountPropertyList',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearAccountPropertyList: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getAccountPropertyListWrapper.makeReducers(builder)
})

export const {clearAccountPropertyList} = accountPropertyListSlice.actions
export const accountPropertyList = accountPropertyListSlice.reducer
