import React, {useEffect} from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'
import {withTranslation} from 'react-i18next'
import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'

import {openErrorModal} from 'ca-common/utils/modals'
import {SOURCE} from 'ca-common/common/enum'

import {AccountItem} from './AccountItem'
import {
    clearAccountsWithGroups,
    getAccountsWithGroups
} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups/MS365AccountsWithGroups'

import {setExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {AccountHeader, AccountListWrapper, StyledHeaderCell} from './Styled'

const FIRST_PAGE = 1
const PAGE_SIZE = 20

const EntitiesRaw = props => {
    const {bulkAccounts, excludeEmails, taskSettings, t} = props
    const handleScroll = () => {
        const {bulkAccounts} = props

        if (bulkAccounts.status === 'fulfilled' && bulkAccounts.response.page < bulkAccounts.response.totalPages) {
            fetchAccountsWithGroups(bulkAccounts.response.page + 1)
        }
    }

    const fetchAccountsWithGroups = page => {
        const {actions, bulkAccounts, selectedGroups, match, t} = props

        actions
            .getAccountsWithGroups({
                taskId: match.params.taskId,
                rows: PAGE_SIZE,
                page,
                cacheToken: page !== FIRST_PAGE ? get(bulkAccounts, 'response.cacheToken') : undefined,
                groups: selectedGroups
            })
            .then(data => {
                actions.setWizardDescription(
                    t('backups:bulkActivation:accountsToActivate', {
                        count: get(data, 'payload.total', 0) - excludeEmails.length
                    })
                )
            })
            .catch(err => openErrorModal(err.status))
    }

    const onClick = mail => {
        props.actions.setExcludeEmails(mail)
    }

    useEffect(() => {
        props.actions.clearAccountsWithGroups()
        fetchAccountsWithGroups(FIRST_PAGE)
    }, [])

    useEffect(() => {
        const {actions, t, bulkAccounts, excludeEmails} = props

        actions.setWizardDescription(
            t('backups:bulkActivation:accountsToActivate', {
                count: get(bulkAccounts, 'response.total', 0) - excludeEmails.length
            })
        )
    }, [props.excludeEmails])

    const data = get(bulkAccounts, 'response.data')
    const page = get(bulkAccounts, 'response.page')
    const totalPages = get(bulkAccounts, 'response.totalPages')

    return (
        <AccountListWrapper data-hj-suppress>
            <AccountHeader>
                <StyledHeaderCell width="185px">{t('backups:bulkActivation:accountListHeaders:name')}</StyledHeaderCell>
                <StyledHeaderCell width="205px">
                    {t('backups:bulkActivation:accountListHeaders:email')}
                </StyledHeaderCell>
                {taskSettings.source === SOURCE.ONEDRIVE && (
                    <StyledHeaderCell width="255px">
                        {t('backups:bulkActivation:accountListHeaders:site')}
                    </StyledHeaderCell>
                )}
                <StyledHeaderCell width="180px">
                    {t('backups:bulkActivation:accountListHeaders:group')}
                </StyledHeaderCell>
            </AccountHeader>
            <InfiniteScroll
                initialLoad={false}
                pageStart={FIRST_PAGE}
                useWindow={false}
                hasMore={page < totalPages}
                loadMore={handleScroll}
            >
                <Spin spinning={bulkAccounts.status === 'pending'}>
                    {map(data, account => {
                        const active =
                            taskSettings.source === SOURCE.ONEDRIVE
                                ? !includes(excludeEmails, account.url)
                                : !includes(excludeEmails, account.mail)
                        return (
                            <AccountItem
                                {...account}
                                active={active}
                                key={account.mail}
                                onClick={() =>
                                    onClick(taskSettings.source === SOURCE.ONEDRIVE ? account.url : account.mail)
                                }
                            />
                        )
                    })}
                </Spin>
            </InfiniteScroll>
        </AccountListWrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAccountsWithGroups,
            clearAccountsWithGroups,
            setWizardDescription,
            setExcludeEmails
        },
        dispatch
    )
})

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    bulkAccounts: state.backups.bulkActivation.MS365Groups.MS365AccountsWithGroups,
    selectedGroups: state.backups.bulkActivation.MS365Groups.selectedGroups,
    excludeEmails: state.backups.bulkActivation.excludeEmails
})

export const AccountList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EntitiesRaw)))
