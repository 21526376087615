import React from 'react'
import {useTranslation} from 'react-i18next'

import {RestrictionEntry} from 'ca-common/features/IpRestriction/types'

import {TableWrapper, StyledTable, StyledEmpty} from './StyledRestrictionsTable'
import {useColumns} from 'ca-common/features/IpRestriction/organisms/RestrictionsTable/useColumns'

type RestrictionsTableProps = {
    data: RestrictionEntry[]
    tableDisabled: boolean
    loading: boolean
}

export const RestrictionsTable = (props: RestrictionsTableProps): JSX.Element => {
    const {data, tableDisabled, loading} = props
    const {t} = useTranslation()
    const columns = useColumns(data)

    return (
        <TableWrapper disabled={tableDisabled}>
            <StyledTable
                columns={columns}
                dataSource={data}
                rowKey={(record: RestrictionEntry) => record.id}
                locale={{
                    emptyText: <StyledEmpty>{t('ipRestrictions:table:emptyText')} </StyledEmpty>
                }}
                loading={loading}
                pagination={false}
            />
        </TableWrapper>
    )
}
