import React from 'react'

import {Customers} from 'ca-common/molecules/Customers'
import {SOCIAL_NETWORK_LINKS} from 'ca-common/constants'
import {Footer} from '../MarketingFooter/Footer'
import {Header, PublicHeaderProps} from '../Header'
import {AWSFooter} from '../AWSFooter'

import {PublicContainer, PublicLayoutWrapper} from './StyledContainer'

export type ContainerProps = {
    type?: any
    marketing?: boolean
    children: React.ReactChildren
    isCloudally?: boolean
} & PublicHeaderProps

export const Container = ({type, marketing, children, isCloudally, ...rest}: ContainerProps) => {
    return (
        <PublicLayoutWrapper type={type}>
            <Header {...rest} />
            <PublicContainer>{children}</PublicContainer>
            {marketing ? (
                <>
                    <Customers />
                    <Footer socialNetworks={SOCIAL_NETWORK_LINKS} />
                </>
            ) : (
                <AWSFooter isCloudally={isCloudally} />
            )}
        </PublicLayoutWrapper>
    )
}
