import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {TODO_ANY} from 'ca-common/types'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'

const GET_JOBS_DASHBOARD = 'GET_JOBS_DASHBOARD'
const GET_JOBS_DASHBOARD_API = 'getJobsDasboard'
const getJobsDashboardWrapper = fetchWrapperRT(
    GET_JOBS_DASHBOARD,
    async data => await post(GET_JOBS_DASHBOARD_API, data)
)

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearJobsDashboard: () => InitFetchedState as FetchedState,
        updateJobsDashboard: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response.data = action.payload.data
            }

            return state
        }
    },
    extraReducers: builder => getJobsDashboardWrapper.makeReducers(builder)
})

export const {clearJobsDashboard, updateJobsDashboard} = dashboardSlice.actions
export const dashboard = dashboardSlice.reducer

const PULL_JOBS_DASHBOARD = 'PULL_JOBS_DASHBOARD'
const pullJobsDashboardWrapper = fetchWrapperRT(
    PULL_JOBS_DASHBOARD,
    async data => await post(GET_JOBS_DASHBOARD_API, data)
)

export const getJobsDashboard = (data: Record<string, any>) => (dispatch: AppDispatch) => {
    const {pull, ...passedData} = data

    if (pull) {
        return dispatch(pullJobsDashboardWrapper.fetcher(passedData)).then((response: TODO_ANY) => {
            return dispatch(updateJobsDashboard(response.payload.data))
        })
    }

    return dispatch(getJobsDashboardWrapper.fetcher(passedData))
}
