import React, {useEffect, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import _ from 'lodash'

import {Button} from 'ca-common/ui-lib/atoms'
import {CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {changeData, closeWizard, nextStep, setWizardDescription, setWizardTitle} from 'src/newcore/redux/modules/wizard'
import {AlertLevelSelection} from '../organisms/AlertLevelSelection'
import {ALERT_LEVEL_ALL, ALERT_LEVEL_OBJECT} from 'src/newcore/features/SmartAlerts/lib/constants'

export const SmartAlertsFirstStepRaw = ({actions, objects, taskSettings, currentStep}) => {
    const {t} = useTranslation()
    const [alertLevel, setAlertLevel] = useState(ALERT_LEVEL_ALL)
    const [specificObject, setSpecificObject] = useState(undefined)
    const [step] = useState(currentStep)
    const disableNext = alertLevel === ALERT_LEVEL_OBJECT && !specificObject

    const goToNextStep = () => {
        actions.changeData({alertLevel, specificObject})
        actions.nextStep()
    }

    const handleCancel = () => actions.closeWizard()

    useEffect(() => {
        if (step === currentStep) {
            actions.setWizardTitle(t('smartAlerts:wizard:firstStepTitle'))
            actions.setWizardDescription([t('smartAlerts:wizard:firstStepDescription')])
        }
    }, [currentStep])

    useEffect(() => {
        setSpecificObject(undefined)
    }, [alertLevel])

    return (
        <>
            <AlertLevelSelection
                selectedObjectType={alertLevel}
                changeObjectType={setAlertLevel}
                specificObject={specificObject}
                setSpecificObject={setSpecificObject}
                specificObjectFlowEnabled={taskSettings.lastBackup && objects?.data?.length > 0}
            />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={handleCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={goToNextStep} disabled={disableNext}>
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
            </ModalFooter>
        </>
    )
}

const mapStateToProps = state => ({
    objects: {
        status: state.backups.sfcSmartAlerts.sfcAlertObjects.status,
        data: state.backups.sfcSmartAlerts.sfcAlertObjects.response
    },
    taskSettings: _.get(state.backups.settings, 'response', {}),
    currentStep: state.wizard.step
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            nextStep,
            closeWizard,
            changeData,
            setWizardTitle,
            setWizardDescription
        },
        dispatch
    )
})

export const SAFirstStep = connect(mapStateToProps, mapDispatchToProps)(SmartAlertsFirstStepRaw)
