import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'
import {Button} from 'src/ca-common/ui-lib/atoms'

export const StyledTwoFa = styled.div`
    display: flex;
`

export const StyledTwoFaBlock = styled.div<{modifier: any}>`
    padding: 22px 19px;
    font-size: 14px;
    margin: 10px 30px 0 0;
    border: 1px solid #e1e1e1;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    button {
        width: 100%;
    }

    ${props =>
        props.modifier === 'guide' &&
        css`
            width: 364px;
        `}
    ${props =>
        props.modifier === 'qrcode' &&
        css`
            width: 309px;
            height: 309px;
        `}
    ${props =>
        props.modifier === 'enabled' &&
        css`
            width: 550px;
            height: 390px;
            button {
                width: 20%;
                font-size: 15px;
            }
            h4 {
                margin-top: ${variables.gutterLg};
            }
            p {
                margin-bottom: ${variables.gutterLg};
            }
            a {
                text-decoration: none;
                font-size: 15px;
                font-weight: 400;
            }
            .ant-btn {
                font-weight: 600;
            }
        `}
`

export const ListIndent = styled.div`
    font-weight: bold;
    font-size: 18px;
    padding-right: ${variables.gutterXs};
`

export const ListStage = styled.div`
    display: flex;
    button {
        width: 24%;
        height: 25px;
        margin-top: 4px;
    }
    input {
        border-radius: 0;
        margin-right: ${variables.gutterXs};
    }
`
export const StyledTwoFaTitle = styled.div`
    font-size: ${variables.gutterSm};
    padding-bottom: ${variables.gutterXxl};
`
export const ListInfo = styled.div`
    padding-bottom: ${variables.gutterMd};
`

export const StyledButton = styled(Button)`
    font-weight: 700;
    border-radius: ${variables.gutterXxl};
`
