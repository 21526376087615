import {Checkbox, Input, Select, Form} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const RoundedSelect = styled(Select)`
    .ant-select-selection {
        border-radius: ${variables.borderRadiusBase};
    }

    .ant-select-selection__placeholder,
    .ant-select-selection-selected-value {
        color: ${variables.navyBlue};
    }
`

export const RoundedInput = styled(Input)`
    border-radius: ${variables.borderRadiusBase};

    &[disabled] {
        background-color: ${variables.disabled};
        border-color: ${variables.navyBorder};
        color: ${variables.navyAsphalt};
    }
`

export const StyledLabel = styled.label`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;

    display: flex;
    align-items: center;
    margin-bottom: ${variables.gutterXs};
    color: ${variables.text};
`

export const StyledForm = styled(Form)`
    width: 100%;
    max-width: 552px;
    margin: 0 auto ${variables.gutterMd};

    @media screen and (min-width: ${variables.screenMd}) {
        margin-bottom: ${variables.gutterLg};
        box-sizing: content-box;
    }
`

export const StyledCheckbox = styled(Checkbox)`
    margin-top: ${variables.gutterXxl};
`

export const StyledCheckboxContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -${variables.gutterSm};
    gap: 12px;
`

export const StyledNameContainer = styled.div`
    .ant-form-item {
        width: 100%;
    }

    @media screen and (min-width: ${variables.screenMd}) {
        display: flex;
        gap: ${variables.gutterSm};
        justify-content: space-between;
    }
`

export const StyledNamePart = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const StyledPlanContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledPlan = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;

    color: ${variables.navyAsphalt};
`

export const StyledButtonText = styled.span`
    font-family: ${variables.publicPageFont};
    font-weight: 700;
    font-size: ${variables.fontSizeBase};
`
