import React from 'react'
import {RadioProps} from 'antd/lib/radio'

import {ComingSoon} from 'ca-common/ui-lib/atoms/ComingSoon'

import {RadioBlockLabel, StyledContent, StyledRadio} from './StyledRadioBlock'

type RadioBlockProps = RadioProps & {
    description?: string | JSX.Element
    title: string | JSX.Element
    showRadioIcon?: boolean
    hideInactive?: boolean
    inline?: boolean
    comingSoon?: boolean
    isStatic?: boolean
}

export const RadioBlock = (props: RadioBlockProps): JSX.Element => {
    const {title, description, children, comingSoon = false, ...rest} = props

    return (
        <StyledRadio {...rest}>
            {comingSoon && <ComingSoon />}
            <RadioBlockLabel description={description}>{title}</RadioBlockLabel>
            {description && <p>{description}</p>}
            {children && <StyledContent>{children}</StyledContent>}
        </StyledRadio>
    )
}
