import React from 'react'
import PropTypes from 'prop-types'

import {column as columnShape, item as itemShape, rowSelection as rowSelectionShape} from 'ca-common/shapes/table'

export const CACell = ({configColumn, item, rowSelection, collapse}) => {
    const CAContentCell = configColumn.contentCell
    const cellValue = item[configColumn.name]

    return CAContentCell ? (
        <CAContentCell configColumn={configColumn} item={item} rowSelection={rowSelection} collapse={collapse} />
    ) : (
        <p title={cellValue}>{cellValue}</p>
    )
}

CACell.propTypes = {
    configColumn: PropTypes.shape(columnShape).isRequired,
    item: PropTypes.shape(itemShape).isRequired,
    rowSelection: PropTypes.shape(rowSelectionShape)
}

CACell.defaultProps = {
    rowSelection: null
}
