import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from 'ca-common/ui-lib/atoms'
import {Tooltip} from 'antd'
import {StyledTooltipIcon} from 'ca-common/ui-lib/atoms/Alert/StyledAlert'
import {StyledPayInvoiceWrapper, StyledTooltipWrapper} from './StyledPayInvoiceButton'
import {PaymentStatus} from '../../lib/constants'

export type PayInvoiceButtonProps = {
    billingStatus: PaymentStatus
    paymentUrl: string
    onClick: () => void
}

export const PayInvoiceButton = ({billingStatus, paymentUrl, onClick}: PayInvoiceButtonProps) => {
    const {t} = useTranslation()
    const isDisabled = useMemo(() => {
        const status = [PaymentStatus.DUE, PaymentStatus.OVERDUE]
        return paymentUrl === undefined || !status.includes(billingStatus)
    }, [billingStatus])
    return (
        <StyledPayInvoiceWrapper>
            <Button type="primary" onClick={onClick} href={paymentUrl} target="_blank" disabled={isDisabled}>
                {t('billing:tooltips:payInvoice')}
            </Button>
            <Tooltip
                arrowPointAtCenter={true}
                overlayStyle={{width: '200px'}}
                placement="bottomRight"
                title={t('billing:tooltips:payInvoiceTooltip')}
            >
                <StyledTooltipWrapper>
                    <StyledTooltipIcon />
                </StyledTooltipWrapper>
            </Tooltip>
        </StyledPayInvoiceWrapper>
    )
}
