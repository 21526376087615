import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import Spinner from 'ca-common/icons/spinnerProgress.svg'

import {ProgressBar} from 'ca-common/ui-lib/atoms/ProgressBar'
import {BACKUP_PROGRESS_STATUS} from 'ca-common/constants'

import {Content, ItemCount, ItemIcon, ItemIconWrapper, ItemName, ItemsRow} from './StyledSingleSourceProgressBar'

const FILLING_BAR_WIDTH = {
    [BACKUP_PROGRESS_STATUS.PREPARE]: 10,
    [BACKUP_PROGRESS_STATUS.BACKUP]: 50,
    [BACKUP_PROGRESS_STATUS.FINALIZE]: 100
}

type SingleEntityProgressBarProps = {
    progress?: {
        backupState: BACKUP_PROGRESS_STATUS
        currentCount: number
    }
}

export const SingleSourceProgressBar = ({progress}: SingleEntityProgressBarProps): JSX.Element => {
    const {t} = useTranslation()
    const progressInfo = useMemo(
        () => (
            <Content>
                <ItemsRow>
                    <ItemIconWrapper>
                        <ItemIcon component={Spinner} />
                        <ItemName>{t('progressBar:singleEntityItemName')}</ItemName>
                    </ItemIconWrapper>
                    <ItemCount>{progress?.currentCount}</ItemCount>
                </ItemsRow>
            </Content>
        ),
        [progress?.currentCount]
    )

    return (
        <ProgressBar
            progressInfo={progressInfo}
            progress={(progress && FILLING_BAR_WIDTH[progress.backupState]) || 0}
        />
    )
}
