import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const GET_ACCOUNTS_WITH_OU_API = 'getAccountsWithGroups'

const GET_ACCOUNTS_WITH_OU = 'GET_ACCOUNTS_WITH_OUS'
const getAccountsWithOUWrapper = fetchWrapperRT<GoogleAccountsWithOUResponse>(
    GET_ACCOUNTS_WITH_OU,
    async (data, {rejectWithValue}) => {
        try {
            return await postLessData(GET_ACCOUNTS_WITH_OU_API, data)
        } catch (err: any) {
            return rejectWithValue(err)
        }
    }
)
export const getAccountsWithOU = getAccountsWithOUWrapper.fetcher

export type GoogleAccountsWithOUResponse = {
    data: TODO_ANY[]
    page: number
    cacheToken: string
    totalPages: number
    total: number
    hasBlockedOUs: boolean
    blockedOUs: any
}

const GoogleAccountsWithOUSlice = createSlice({
    name: 'GoogleAccountsWithOU',
    initialState: InitFetchedState as FetchedState<GoogleAccountsWithOUResponse>,
    reducers: {
        clearAccountsWithOU: () => InitFetchedState as FetchedState<GoogleAccountsWithOUResponse>
    },
    extraReducers: builder => getAccountsWithOUWrapper.makeReducers(builder)
})

export const {clearAccountsWithOU} = GoogleAccountsWithOUSlice.actions
export const GoogleAccountsWithOU = GoogleAccountsWithOUSlice.reducer
