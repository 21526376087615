import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {TagsColumn} from 'ca-common/ui-lib/molecules/TagsColumn'
import {SOURCE, TASK_STATUS} from 'ca-common/common/enum'

import {EntitiesTaskStatus} from 'src/newcore/molecules/EntitiesTaskStatus'
import {BackupActions} from 'src/newcore/components/BackupActions'
import {ProgressData} from 'src/ca-common/types'
import {LimitedAccess} from 'src/newcore/molecules/LimitedAccess'
import {TASK_STATUS_WIDTH, BACKUP_SIZE_WIDTH, LAST_BACKUP_WIDTH, ACTIONS_WIDTH, TAGS_WIDTH} from '.'
import {LastBackup, Name} from '../CellModifier'

export const accountLike = (source: SOURCE) => [
    {
        title: i18n.t('table:columns:username'),
        dataIndex: 'name',
        onCell: () => ({'data-hj-suppress': true}),
        render: (name: string) => <Name name={name} />,
        ellipsis: true,
        sorter: true
    },
    {
        title: i18n.t('table:columns:email'),
        dataIndex: 'entity',
        render: (entity: any) => <Name name={entity} />,
        ellipsis: true,
        sorter: true
    },
    {
        title: i18n.t('table:columns:tags'),
        render: (entity: any) => (
            <>
                <TagsColumn entity={entity} />
                {entity.limitedAccess && <LimitedAccess isBackground={true} />}
            </>
        ),
        width: TAGS_WIDTH
    },
    {
        title: i18n.t('table:columns:status'),
        dataIndex: 'status',
        render: (_: any, entity: {progressData: ProgressData[]; status: TASK_STATUS}) => (
            <EntitiesTaskStatus progressMulti={entity.progressData} status={entity.status} isMulti />
        ),
        width: TASK_STATUS_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:backupSize'),
        dataIndex: 'size',
        width: BACKUP_SIZE_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:lastBackup'),
        dataIndex: 'lastBackup',
        render: (_: any, entity: any) => <LastBackup entity={entity} />,
        width: LAST_BACKUP_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:backup'),
        render: (_: any, entity: {id: any; status: TASK_STATUS}) => (
            <BackupActions taskInfo={entity} performEntities={[entity.id]} source={source} />
        ),
        width: ACTIONS_WIDTH
    },
    Table.EXPAND_COLUMN
]
