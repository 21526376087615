import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {SOURCE} from 'src/ca-common/common/enum'
import {TODO_ANY} from 'src/ca-common/types'
import {PAGES} from 'ca-common/constants'
import {openErrorNotification} from 'ca-common/utils/toasts'

import {
    changeConfig,
    changeStep,
    closeWizard,
    setWizardDescription,
    setWizardTitle,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {getBackupTasks} from 'src/newcore/features/Homepage/redux'

import {
    useActivateBackupsMutation,
    useCreateOCATasksQuery,
    useGetMSTotalEntitiesQuery
} from 'src/newcore/features/OneClickActivation/redux'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {
    STEPS,
    prepareActivationPayloadData,
    getTotalSelectedEntities,
    checkIsDefaultTaskSettings,
    trackOCA
} from 'src/newcore/features/OneClickActivation/lib'

import {Footer, Card} from 'src/newcore/features/OneClickActivation/molecules'
import {Body, CardsWrapper, NoEntitiesSelected} from './StyledOCAConfirmationStep'

export const OCAConfirmationStep = (): JSX.Element => {
    const {t} = useTranslation()

    const history = useHistory()

    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData

    const {data: totalEntities = []} = useGetMSTotalEntitiesQuery(wizardData.id)
    const {data: createOCATasksData} = useCreateOCATasksQuery(wizardData.stateId)
    const [activateBackups, {isLoading}] = useActivateBackupsMutation()

    const onSubmit = async () => {
        const res: TODO_ANY = await activateBackups(prepareActivationPayloadData(wizardData))

        if (res.error) {
            return openErrorNotification(res.error?.status)
        }

        trackOCA(wizardData, createOCATasksData?.enabledServices)

        dispatch(closeWizard())
        history.replace(`/${PAGES.BACKUPS}`)
        dispatch(getBackupTasks({}))
    }

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:confirmationStep')))
        dispatch(setWizardDescription(wizardData.email))
        dispatch(changeConfig({beforeStepChange: undefined}))
    }, [])

    const onChangeSettings = (service: SOURCE) => {
        dispatch(changeConfig({defaultOpenedTaskSettings: service}))
        dispatch(changeStep(Number(STEPS.SETTINGS_SELECTION)))
    }

    const onChangeEntities = (service: SOURCE) => {
        dispatch(changeConfig({defaultOpenedEntitiesSelector: service}))
        dispatch(changeStep(Number(STEPS.ENTITIES_SELECTION)))
    }

    const servicesWithNoEntities = totalEntities
        .map(({source}) =>
            wizardData.tasksForReinstate?.find(task => task.source === source) ||
            getTotalSelectedEntities(wizardData[source]?.enabledEntitiesBitSet)
                ? false
                : source
        )
        .filter(Boolean) as SOURCE[]

    return (
        <div>
            <Body>
                <CardsWrapper>
                    {totalEntities.map(({source, totalItems}) => {
                        const taskForReinstate = wizardData.tasksForReinstate?.find(task => task.source === source)
                        return (
                            <Card
                                key={source}
                                selectedEntities={
                                    (taskForReinstate && taskForReinstate.totalItems) ||
                                    getTotalSelectedEntities(wizardData[source]?.enabledEntitiesBitSet)
                                }
                                totalEntities={(taskForReinstate && taskForReinstate.totalItems) || totalItems}
                                service={source}
                                isForReinstate={!!taskForReinstate}
                                isDefaultTaskSettings={checkIsDefaultTaskSettings(wizardData, [source])}
                                onChangeSettings={onChangeSettings}
                                onChangeEntities={onChangeEntities}
                            />
                        )
                    })}
                </CardsWrapper>
                <NoEntitiesSelected>
                    {!!servicesWithNoEntities.length &&
                        t(
                            `oneClickActivation:wizard:confirmationStep:noEntitiesSelected:${servicesWithNoEntities.length}`,
                            {
                                service1: t(
                                    `oneClickActivation:wizard:confirmationStep:services:${servicesWithNoEntities[0]}`
                                ),
                                service2: t(
                                    `oneClickActivation:wizard:confirmationStep:services:${servicesWithNoEntities[1]}`
                                ),
                                service3: t(
                                    `oneClickActivation:wizard:confirmationStep:services:${servicesWithNoEntities[2]}`
                                ),
                                service4: t(
                                    `oneClickActivation:wizard:confirmationStep:services:${servicesWithNoEntities[3]}`
                                )
                            }
                        )}
                </NoEntitiesSelected>
            </Body>
            <Footer nextButtonText={t('modals:buttons:confirm')} loading={isLoading} onSubmit={onSubmit} />
        </div>
    )
}
