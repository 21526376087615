import React from 'react'
import {useTranslation} from 'react-i18next'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {ACTIONS} from '../../../utils/constants'
import {isTaskProcessing, isTaskQueued} from '../../../utils/utils'

import {status} from 'src/newcore/features/Homepage/types'
import {TaskActionsItem} from 'src/newcore/features/Homepage/atoms/TaskActionsItem'

type BackupNowProps = {
    sendAction: any
    pullAndUpdateLocalServices: () => void
    status: status
    disabled: boolean
    trialDaysLeft: number
    isSubscribed: boolean
}

export const BackupNowItem = ({
    sendAction,
    pullAndUpdateLocalServices,
    status,
    disabled,
    isSubscribed,
    trialDaysLeft
}: BackupNowProps): JSX.Element => {
    const {t} = useTranslation()

    return isSubscribed || trialDaysLeft > 0 ? (
        <TaskActionsItem action={ACTIONS.BACKUP_NOW} onClick={onClickHandler} disabled={disabled} />
    ) : (
        <TaskActionsItem action={ACTIONS.BACKUP_NOW} disabled />
    )

    function onClickHandler() {
        if (!isTaskProcessing(status) && !isTaskQueued(status)) {
            sendAction(ACTIONS.BACKUP_NOW)
                .then(() => {
                    openInfoNotification(t('backups:taskScheduled'))
                    pullAndUpdateLocalServices()
                })
                .catch((error: any) => openErrorModal(t('backups:failedToSchedule') + (error ? error.status : '')))
        }
    }
}
