import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledReviewWrapper = styled.section`
    padding: 80px ${variables.gutterMd} ${variables.gutterMd};
    min-height: 409px;
`

export const StyledInnerWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const StyledIcon = styled(Icon)`
    font-size: 120px;
    margin-left: 110px;
`

export const StyledLabel = styled.span`
    display: inline-block;
    width: 100%;
    padding-right: ${variables.gutterSm};

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: ${variables.brandDark};

    @media screen and (min-width: ${variables.screenMd}) {
        width: 250px;
    }
`

export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`

export const StyledInputWrapper = styled.div`
    display: flex;
    width: 340px;

    > *:not(:last-child) {
        margin-right: 12px;
    }

    && input {
        color: ${variables.navyAsphalt};
        background-color: ${variables.blueLight};
    }
`

export const StyledHeader = styled.h3`
    margin-bottom: ${variables.gutterLg};

    font-family: ${variables.accentFont};
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;

    color: ${variables.navyBlue};
`

export const StyledErrorIcon = styled(Icon)`
    color: ${variables.red};
    margin: 4px 8px 0 0;
`

export const StyledError = styled.div`
    display: flex;
    pointer-events: none;

    margin-top: 40px;

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeSecondary};
    line-height: 18px;

    color: ${variables.red};
`
