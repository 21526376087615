import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'

export type StyledJumbotronToggleProps = {
    isOpen?: boolean
    um?: boolean
    padding?: string
    margin?: string
}

export const Content = styled.div<{isOpen?: boolean}>`
    height: ${props => (props.isOpen ? 'auto' : 0)};
`

export const Description = styled.div<{um?: boolean}>`
    padding: 20px 0;
    line-height: 1.3;
    padding-bottom: ${props => props.um && 0};
`

export const Header = styled.h4`
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    width: 100%;
    margin: 0 !important;
    line-height: 24px;
    user-select: none;
`

export const Title = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
`

export const StyledJumbotronToggle = styled(Jumbotron)<StyledJumbotronToggleProps>`
    width: 100%;
    position: relative;
    overflow: ${props => !props.isOpen && 'hidden'};
    margin: ${props => props.margin && props.margin};
    box-shadow: ${props => props.um && '0 0 0 transparent'};

    padding: ${props => {
        if (props.padding) {
            return props.padding
        }

        if (props.um) {
            return '0 9px'
        }

        return '19px 30px'
    }};
`

export const StyledIcon = styled(Icon)`
    color: ${variables.old.grayDark};
    font-size: 9px;
    width: 10px;
    height: 10px;
`
