import {SOURCE} from 'ca-common/common/enum'

import {ActivateBackupsPayload, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {FIELDS} from 'src/newcore/features/OneClickActivation/lib/constants'
import {getHexFromBinary} from 'src/newcore/features/OneClickActivation/lib/convertInt'

const prepareService = (source: SOURCE, data: OCAWizardData, reinstateSources?: Record<SOURCE, boolean>) => {
    return reinstateSources?.[source]
        ? undefined
        : {
              settings: data[source]!.settings,
              enabledEntitiesBitSet: getHexFromBinary(data[source]?.enabledEntitiesBitSet)
          }
}

export const prepareActivationPayloadData = (data: OCAWizardData): ActivateBackupsPayload => {
    const tasksForReinstate = data.tasksForReinstate?.map(({id}) => id) || []

    const reinstateSources = data.tasksForReinstate?.reduce((acc, task) => {
        acc[task.source as SOURCE] = true
        return acc
    }, {} as Record<SOURCE, boolean>)

    return {
        id: data.id,
        backupName: data[FIELDS.BACKUP_NAME],
        tasksForReinstate,
        stateId: data.stateId,
        sources: {
            [SOURCE.MS365]: data[SOURCE.MS365] && prepareService(SOURCE.MS365, data, reinstateSources),
            [SOURCE.MS365GROUPS]:
                data[SOURCE.MS365GROUPS] && prepareService(SOURCE.MS365GROUPS, data, reinstateSources),
            [SOURCE.SHAREPOINT_MULTI]:
                data[SOURCE.SHAREPOINT_MULTI] && prepareService(SOURCE.SHAREPOINT_MULTI, data, reinstateSources),
            [SOURCE.ONEDRIVE]: data[SOURCE.ONEDRIVE] && prepareService(SOURCE.ONEDRIVE, data, reinstateSources)
        }
    }
}
