import React, {useEffect} from 'react'
import {Tabs, Spin} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import map from 'lodash/map'

import {WizardHeader} from 'ca-common/ui-lib/molecules/WizardHeader'

import {changeStep, changeProgress} from 'src/newcore/redux/modules/wizard'
import {getTabBarItems} from './utils/getTabBarItems'

const {TabPane} = Tabs

const WizardContentRaw = ({actions, wizard, steps, clearWizard}) => {
    const {
        title,
        description,
        step,
        progress,
        icon,
        config = {destroyInactiveTabPane: true, autoDismissProgress: false}
    } = wizard

    const {
        destroyInactiveTabPane,
        autoDismissProgress,
        disabledSteps = [],
        hiddenTabBarItems = [],
        stepsWithHiddenTabBar = []
    } = config

    useEffect(() => {
        if (autoDismissProgress && progress > step) actions.changeProgress(step)
    }, [step, progress])

    useEffect(() => {
        return clearWizard
    }, [])

    const onStepChange = async key => {
        try {
            wizard.config.beforeStepChange && (await wizard.config.beforeStepChange())
            actions.changeStep(Number(key))
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Spin spinning={false}>
            <Tabs
                activeKey={wizard.step.toString()}
                destroyInactiveTabPane={destroyInactiveTabPane}
                renderTabBar={props => {
                    const panes = getTabBarItems({
                        tabBarItems: props.panes,
                        currentStep: wizard.step.toString(),
                        disabledSteps,
                        hiddenTabBarItems,
                        stepsWithHiddenTabBar
                    })

                    return (
                        <WizardHeader
                            icon={icon}
                            title={title}
                            description={description}
                            progress={wizard.progress}
                            onChange={onStepChange}
                            {...props}
                            panes={panes}
                        />
                    )
                }}
            >
                {map(steps, (Step, index) => (
                    <TabPane tab={`Tab ${index}`} key={index}>
                        <Step />
                    </TabPane>
                ))}
            </Tabs>
        </Spin>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeStep,
            changeProgress
        },
        dispatch
    )
})

export const WizardContent = connect(null, mapDispatchToProps)(WizardContentRaw)
