import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'

export const StyledTutorialButton = styled.div`
    flex: 0 1 25%;
    padding: 10px 0 0;

    a {
        color: ${variables.navyAsphalt};

        &:hover,
        &:focus,
        &:visited,
        &:active {
            color: ${variables.navyAsphalt};
        }
    }
`

export const StyledTutorialLink = styled.a`
    display: block;
    height: 60px;
    background-color: ${variables.white};
    padding: 0 10px;
    position: relative;

    .anticon {
        font-size: 25px;
        color: ${variables.navyAsphalt};
        margin-left: 60px;
        padding: 8px;
        border: 0.1px solid ${variables.lightGray};
        border-radius: 4px;
    }
`

export const StyledTutorialText = styled.div`
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
    color: ${variables.navyBlue};
`

export const StyledTutorialJumbotron = styled(Jumbotron)`
    padding: 50px 21px;
    margin: 0;
    width: 223px;
    height: 156px;
`
