import styled from 'styled-components'
import variables from 'ca-common/variables'

export const RestoreWrapper = styled.div`
    .ant-radio-wrapper {
        font-weight: 400;
    }
`

export const StyledDetails = styled.div``

export const StyledCheckboxWrapper = styled.div``

export const StyledRestoreCheckbox = styled.div`
    margin-bottom: ${variables.gutterMd};
`

export const StyledRadioWrapper = styled.div``

export const StyledRestoreRadio = styled.div`
    margin-top: ${variables.gutterMd};
    margin-bottom: ${variables.gutterMd};
    font-weight: 400;
    .ant-space-vertical {
        gap: ${variables.gutterSm} !important;
        margin-left: ${variables.gutterXs};
    }
`

export const StyledModeRadio = styled.div`
    margin: ${variables.gutterMd} 0 ${variables.gutterMd} 0;
    .ant-space-vertical {
        gap: ${variables.gutterSm} !important;
        margin-left: ${variables.gutterXs};
    }
`

export const StyledRadioModeWrapper = styled.div`
    padding-top: ${variables.gutterMd};
`

export const StyledText = styled.span`
    margin-top: ${variables.gutterSm};
    margin-bottom: ${variables.gutterXs};
`

export const StyledRestoreFooter = styled.div`
    border-top: 1px solid ${variables.blue};
    padding-top: ${variables.gutterSm};
    margin: 20px 0;
    text-align: right;
    font-size: ${variables.fontSizeBase};
    button:nth-child(1) {
        background-color: ${variables.brand};
        color: ${variables.white};
        font-weight: 700;
        margin-right: 10px;
    }

    button:nth-child(2) {
        color: ${variables.brand};
        background-color: ${variables.white};
        margin-left: 10px;
    }

    button:hover {
        opacity: 0.7;
    }
`

export const StyledRestoreButton = styled.button<{isActive: boolean}>`
    display: inline-block;
    border: 1px solid ${variables.navyBlue};
    border-radius: 12px;
    padding: 0px 14px;
    cursor: pointer;
    color: ${variables.navyAsphalt};
`
