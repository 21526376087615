import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Select, Form} from 'antd'
import {debounce} from 'lodash'

import {getDataCenters, getFriendlyRegion} from 'ca-common/utils/Region'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {Terms} from 'ca-common/components/PublicSite/Terms'
import variables from 'ca-common/variables'
import {EMPTY_ERROR_MESSAGE, hasSomeErrorsAntd4} from 'ca-common/utils/form'
import {DEBOUNCE_TIMEOUT} from 'ca-common/constants'
import {msLandingFormFields} from 'src/newcore/features/MSLanding/lib/constants'
import {
    RoundedSelect,
    StyledForm,
    RoundedInput,
    StyledLabel,
    StyledCheckbox,
    StyledCheckboxContainer,
    StyledNameContainer,
    StyledPlanContainer,
    StyledPlan,
    StyledButtonText,
    StyledNamePart
} from './StyledMSLandingForm'

const {Option} = Select

export const MSLandingForm = ({onSubmit, initialValues, changeURLSearchParams}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const [checked, setChecked] = useState(true)

    const formHandler = values => {
        if (!hasSomeErrorsAntd4(form.getFieldsError())) {
            changeURLSearchParams(values)
        }
    }

    return (
        <StyledForm
            onValuesChange={debounce(formHandler, DEBOUNCE_TIMEOUT)}
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
        >
            <StyledNameContainer>
                <StyledNamePart>
                    <StyledLabel htmlFor={msLandingFormFields.firstName}>
                        {t('MSLandingPage:formLabels:firstName')}
                    </StyledLabel>
                    <Form.Item
                        name={msLandingFormFields.firstName}
                        rules={[
                            {
                                required: true,
                                message: EMPTY_ERROR_MESSAGE
                            }
                        ]}
                    >
                        <RoundedInput
                            id={msLandingFormFields.firstName}
                            size="large"
                            placeholder={t('forms:common:fields:firstName:placeholder')}
                        />
                    </Form.Item>
                </StyledNamePart>
                <StyledNamePart>
                    <StyledLabel htmlFor={msLandingFormFields.lastName}>
                        {t('MSLandingPage:formLabels:lastName')}
                    </StyledLabel>
                    <Form.Item
                        name={msLandingFormFields.lastName}
                        rules={[
                            {
                                required: true,
                                message: EMPTY_ERROR_MESSAGE
                            }
                        ]}
                    >
                        <RoundedInput
                            id={msLandingFormFields.lastName}
                            size="large"
                            placeholder={t('forms:common:fields:lastName:placeholder')}
                        />
                    </Form.Item>
                </StyledNamePart>
            </StyledNameContainer>
            <StyledLabel htmlFor={msLandingFormFields.mail}>{t('MSLandingPage:formLabels:mail')}</StyledLabel>
            <Form.Item
                name={msLandingFormFields.mail}
                rules={[
                    {
                        required: true,
                        message: EMPTY_ERROR_MESSAGE
                    },
                    {
                        type: 'email',
                        message: t('forms:common:fields:email:validation:invalid')
                    }
                ]}
            >
                <RoundedInput
                    id={msLandingFormFields.mail}
                    size="large"
                    placeholder={t('forms:common:fields:email:placeholder')}
                    disabled
                />
            </Form.Item>
            <StyledLabel htmlFor={msLandingFormFields.region}>{t('MSLandingPage:formLabels:region')}</StyledLabel>
            <Form.Item
                name={msLandingFormFields.region}
                rules={[
                    {
                        required: true,
                        message: t('MSLandingPage:regionErrorText')
                    }
                ]}
            >
                <RoundedSelect
                    id={msLandingFormFields.region}
                    size="large"
                    placeholder={t('MSLandingPage:locationPlaceholder')}
                    autoFocus
                    getPopupContainer={t => t.parentElement}
                >
                    {getDataCenters().map(dataCenter => (
                        <Option key={dataCenter} value={dataCenter}>
                            {getFriendlyRegion(dataCenter)}
                        </Option>
                    ))}
                </RoundedSelect>
            </Form.Item>
            <StyledPlanContainer>
                <StyledLabel>{t('MSLandingPage:formLabels:plan')}</StyledLabel>
                <StyledPlan>{t('MSLandingPage:subscription:monthly')}</StyledPlan>
            </StyledPlanContainer>
            <StyledCheckboxContainer>
                <StyledCheckbox onChange={() => setChecked(!checked)} defaultChecked />
                <Terms isCloudally={true} />
            </StyledCheckboxContainer>
            <Button
                block
                size="large"
                color={variables.brightOrange}
                type="primary"
                htmlType="submit"
                disabled={!checked}
            >
                <StyledButtonText>{t('MSLandingPage:submitText')}</StyledButtonText>
            </Button>
        </StyledForm>
    )
}
