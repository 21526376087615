import React from 'react'
import {InputProps} from 'antd'
import Icon from '@ant-design/icons'

import Search from 'ca-common/icons/Search.svg'
import {RoundedInput} from 'ca-common/ui-lib/components/FormItem'

export const SearchField = React.forwardRef((props: InputProps, ref) => (
    <RoundedInput
        prefix={<Icon component={Search} style={{color: 'rgba(0,0,0,.25)'}} />}
        allowClear
        ref={ref}
        {...props}
    />
))
