import {getServiceWhitelist} from 'src/newcore/utils/companyInfo'
import {checkPermission} from 'ca-common/utils/permissions'
import {pick} from 'lodash'

export const isPagePermitted = (action, permissions) => {
    return checkPermission(`PAGE.${action}`, permissions)
}

export const isBackupPermitted = (action, permissions) => {
    return checkPermission(`BACKUP.${action}`, permissions)
}

export const permittedServiceList = (serviceList, permissions) => {
    if (!Array.isArray(serviceList) || serviceList.length === 0) {
        return []
    }

    return serviceList.filter(service => isBackupPermitted(`${service}.ACCESS`, permissions))
}

export const permittedWhiteList = permissions => {
    const serviceWhitelist = getServiceWhitelist()

    return permittedServiceList(serviceWhitelist, shrinkPermissionsByServiceWhiteList(permissions, serviceWhitelist))
}

export const shrinkPermissionsByServiceWhiteList = (permissions, serviceWhitelist = getServiceWhitelist()) => {
    return {
        ...permissions,
        groups: {
            ...permissions.groups,
            BACKUP: pick(permissions.groups.BACKUP, serviceWhitelist)
        }
    }
}
