import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const ActionItem = styled.div<{disabled: boolean}>`
    display: flex;
    ${props =>
        props.disabled &&
        css`
            cursor: not-allowed;
            pointer-events: none;
            .anticon {
                color: ${variables.asphaltLight};
            }
            span {
                color: ${variables.asphaltLight};
            }
        `}
`
export const ActionItemIcon = styled(Icon)`
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: ${variables.navyBlue};
`
export const ActionItemText = styled.span`
    font-size: ${variables.fontSizeBase};
    color: ${variables.navyBlue};
`
