import i18n from 'i18next'
import {RECOVERY_TYPE} from 'ca-common/common/enum'
import {TARGET_DESTINATION} from 'src/newcore/features/RecoveryWizard/lib/constants'

export const getConfirmButtonName = (recoveryOption: RECOVERY_TYPE, destination: TARGET_DESTINATION): JSX.Element => {
    if (recoveryOption === RECOVERY_TYPE.RESTORE) {
        return i18n.t(`recovery:wizard:confirmation:button:restore`)
    } else if (destination === TARGET_DESTINATION.CLOUDALLY_S3) {
        return i18n.t(`recovery:wizard:confirmation:button:export`)
    }
    return i18n.t(`recovery:wizard:confirmation:button:authorize`)
}
