import React from 'react'
import {useTranslation} from 'react-i18next'

import {StyledComingSoon} from './StyledComingSoon'

export const ComingSoon = () => {
    const {t} = useTranslation()

    return <StyledComingSoon>{t('backups:comingSoon')}</StyledComingSoon>
}
