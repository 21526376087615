import React from 'react'
import {Modal} from 'antd'

export class BackupAddUserModal extends React.Component {
    static width = 510

    render() {
        const {children, ...rest} = this.props

        return (
            <Modal {...rest} width={BackupAddUserModal.width} closable={false} footer={null} bodyStyle={{padding: 0}}>
                {children}
            </Modal>
        )
    }
}
