import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const BACKUP_TASK = 'BACKUP_TASK'

const BACKUP_TASKS = 'BACKUP_TASKS'

const GET_BACKUP_TASK_INFO = 'GET_BACKUP_TASK_INFO'

const getBackupTaskWrapper = fetchWrapperRT(BACKUP_TASK, async data => await post('getTaskInfo', data))
export const getBackupTask = getBackupTaskWrapper.fetcher

const backupTaskSlice = createSlice({
    name: 'backupTask',
    initialState: InitFetchedState,
    reducers: {
        clearBackupTask: () => InitFetchedState
    },
    extraReducers: builder => getBackupTaskWrapper.makeReducers(builder)
})

export const {clearBackupTask} = backupTaskSlice.actions

const getBackupTasksWrapper = fetchWrapperRT(BACKUP_TASKS, async data => await post('getBackupTasks', data))
export const getBackupTasks = getBackupTasksWrapper.fetcher

const backupTasksSlice = createSlice({
    name: 'backupTasks',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getBackupTasksWrapper.makeReducers(builder)
})

const getBackupTaskInfoWrapper = fetchWrapperRT(
    GET_BACKUP_TASK_INFO,
    async data => await post('getBackupTaskInfo', data)
)
export const getBackupTaskInfo = getBackupTaskInfoWrapper.fetcher

const backupTaskInfoSlice = createSlice({
    name: 'backupTaskInfo',
    initialState: InitFetchedState,
    reducers: {
        clearBackupTaskInfo: () => InitFetchedState
    },
    extraReducers: builder => getBackupTaskInfoWrapper.makeReducers(builder)
})

export const {clearBackupTaskInfo} = backupTaskInfoSlice.actions

export const backupTasksReducer = combineReducers({
    backupTask: backupTaskSlice.reducer,
    backupTasks: backupTasksSlice.reducer,
    backupTaskInfo: backupTaskInfoSlice.reducer
})
