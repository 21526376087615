import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useHistory} from 'react-router'
import axios from 'axios'

import {makeUrlParams, getUrlParams} from 'ca-common/utils/url'
import {MSLandingForm} from 'src/newcore/features/MSLanding/organisms'
import {endpoint} from 'src/newcore/features/MSLanding/lib/constants'

import {Content, LandingHeader} from './StyledMSLanding'

import {PUBLIC_PAGES} from 'ca-common/constants'
import PB_AWS_logo_RGB from 'ca-common/components/PublicSite/AWSFooter/PB_AWS_logo_RGB.png'

export const MSLanding = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const initialValues = getUrlParams(location.search)

    const handleOnProceed = values => {
        axios
            .post(endpoint, JSON.stringify(values))
            .then(() => {
                return history.push('/')
            })
            .catch(() => {
                return history.push(`${PUBLIC_PAGES.MS_LANDING_ERROR}`)
            })
    }

    const changeURLSearchParams = values => {
        const newParams = {
            ...getUrlParams(location.search),
            ...values
        }

        history.push({
            search: makeUrlParams(newParams)
        })
    }

    return (
        <Content>
            <LandingHeader>{t('MSLandingPage:landingHeader')}</LandingHeader>
            <MSLandingForm
                initialValues={initialValues}
                changeURLSearchParams={changeURLSearchParams}
                onSubmit={handleOnProceed}
            />
            <img src={PB_AWS_logo_RGB} width={169} height={30} alt={t(`footer:aws`)} />
        </Content>
    )
}
