import React from 'react'
import {AdvantageString, AdvantageText, StyledLogo} from 'src/newcore/features/SignUp/atoms/Advantage/StyledAdvantage'

type Props = {
    text: string
}

export const AdvantageRow = ({text}: Props): JSX.Element => {
    return (
        <AdvantageString>
            <StyledLogo />
            <AdvantageText>{text}</AdvantageText>
        </AdvantageString>
    )
}
