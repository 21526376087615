import _ from 'lodash'
import React from 'react'

import {TagsPopover} from 'ca-common/ui-lib/molecules/TagsPopover'
import {Tags} from 'ca-common/ui-lib/molecules/Tags'

export const TagsColumn = ({entity, showingTagsCount = 3}: {entity: any; showingTagsCount?: number}) => {
    const initialTags = _.has(entity, 'tags') ? entity.tags : []
    const isInitialTagsExceeded = initialTags.length > showingTagsCount
    const resultTags = isInitialTagsExceeded ? initialTags.slice(0, showingTagsCount) : initialTags

    return (
        <Tags tags={resultTags}>
            {isInitialTagsExceeded ? <TagsPopover tags={initialTags} showingTagsCount={showingTagsCount} /> : null}
        </Tags>
    )
}
