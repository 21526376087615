import {postMultipart, post} from 'src/newcore/utils/rest'
import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {bulkActivate} from '../bulkActivate'
import {ROWS_UPLOADED_CSV_EMAILS} from 'src/newcore/components/BulkActivation/CSV'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'
import {AppDispatch} from 'src/newcore/components/Root'

const UPLOAD_CSV = 'UPLOAD_CSV'
const UPLOAD_CSV_API = 'uploadAndParseCsvFileCommand'
const uploadWrapper = fetchWrapperRT(UPLOAD_CSV, async (data, {rejectWithValue}) => {
    try {
        return await postMultipart(UPLOAD_CSV_API, data)
    } catch (e: any) {
        return rejectWithValue(e.data)
    }
})
export const getUploadedCSVToken = uploadWrapper.fetcher

const tokenSlice = createSlice({
    name: 'token',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearUploadedCSVToken: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => uploadWrapper.makeReducers(builder)
})

export const {clearUploadedCSVToken} = tokenSlice.actions

const GET_PARSED_EMAILS = 'GET_PARSED_EMAILS'
const GET_PARSED_EMAILS_API = 'exchangeCsvEmails'
const getParsedEmailsWrapper = fetchWrapperRT(GET_PARSED_EMAILS, async data => await post(GET_PARSED_EMAILS_API, data))
export const getParsedEmails = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(
        getParsedEmailsWrapper.fetcher({
            page: 1,
            rows: ROWS_UPLOADED_CSV_EMAILS,
            ...data
        })
    )
}

const appendParsedEmailsAfter = (response: TODO_ANY, state: TODO_ANY) => {
    return {
        ...response,
        data: [...state.response.data, ...response.data]
    }
}

const APPEND_PARSED_EMAILS = 'APPEND_PARSED_EMAILS'
const appendParsedEmailsWrapper = fetchWrapperRT(
    APPEND_PARSED_EMAILS,
    async data => await post(GET_PARSED_EMAILS_API, data),
    {
        after: appendParsedEmailsAfter
    }
)

export const appendParsedEmails = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(
        appendParsedEmailsWrapper.fetcher({
            page: 1,
            rows: ROWS_UPLOADED_CSV_EMAILS,
            ...data
        })
    )
}

const emailsSlice = createSlice({
    name: 'emails',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearUploadedCSVEmails: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => {
        getParsedEmailsWrapper.makeReducers(builder)
        appendParsedEmailsWrapper.makeReducers(builder)
    }
})

export const {clearUploadedCSVEmails} = emailsSlice.actions

export const uploadCSV = combineReducers({
    emails: emailsSlice.reducer,
    token: tokenSlice.reducer
})

export const bulkCSVActivate = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(bulkActivate(data)).then(() => {
        dispatch(clearUploadedCSVEmails())
        dispatch(clearUploadedCSVToken())
    })
}
