import React from 'react'
import {useTranslation} from 'react-i18next'

import {getObjKeyByValue} from 'ca-common/utils/object'
import {getStatusIcon} from 'ca-common/utils/getActivityStatusIcon'
import {StatusIcon} from 'ca-common/atoms/Status'
import {PopoverContent} from 'src/newcore/features/ActivityTable/molecules/PopoverContent'
import {ActivityRecord, SYSTEM_AUDIT_ACTIVITY} from 'ca-common/features/ActivityInfinityTable'

import {StyledActivityEntity, ActivityName, PopoverTitle, InfoIcon, StyledPopover} from './StyledActivityEntity'
import Info from 'ca-common/icons/Info.svg'

export const ActivityEntity = ({data}: {data: ActivityRecord}): JSX.Element => {
    const {t} = useTranslation()
    const {activityStatus, activity, description, date, source, downloadPermitted, isExpired} = data

    const title = (
        <PopoverTitle>
            <StatusIcon component={getStatusIcon(activityStatus)} />
            <ActivityName>{t(`activity:activities:${getObjKeyByValue(SYSTEM_AUDIT_ACTIVITY, activity)}`)}</ActivityName>
        </PopoverTitle>
    )
    return (
        <StyledActivityEntity>
            <StyledPopover
                placement="right"
                content={
                    <PopoverContent
                        source={source}
                        activity={activity}
                        activityStatus={activityStatus}
                        description={description}
                        date={date}
                        downloadPermitted={downloadPermitted}
                        isExpired={isExpired}
                    />
                }
                title={title}
            >
                <InfoIcon component={Info} />
            </StyledPopover>
            <ActivityName>{t(`activity:activities:${getObjKeyByValue(SYSTEM_AUDIT_ACTIVITY, activity)}`)}</ActivityName>
        </StyledActivityEntity>
    )
}
