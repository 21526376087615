import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledBulkActivation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${variables.gutterSm};
    color: ${variables.text};
`

export const StyledBulkActivationTitle = styled.div`
    display: flex;
    align-items: center;
    color: ${variables.text};
    font-size: ${variables.fontSizeBase}
    line-height: 32px;
    font-family: ${variables.publicPageFont};
    
    > h3 {
        margin: 0 8px 0 0;
        font-weight: bold;
        font-size: ${variables.subTitleFontSize};
    }
`

export const ButtonIcon = styled(Icon)`
    font-size: 24px;
    color: ${variables.white};
`

export const GroupListWrapper = styled.div`
    position: relative;
`
