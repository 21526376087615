import styled from 'styled-components'
import {Select} from 'antd'

import variables from 'ca-common/variables'

export const StyledSelect = styled(Select)`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0;

    color: ${variables.navyBlue};

    .ant-select-selection__placeholder {
        font-size: ${variables.fontSizeSecondary};
        line-height: 22px;

        color: ${variables.navyBlue};
    }
`
