import React from 'react'

import Email from 'ca-common/icons/Email.svg'

import {StyledEmailIcon} from './StyledEmailIcon'

type EmailIconProps = {
    onClick?: () => void
}

export const EmailIcon = (props: EmailIconProps) => {
    const {onClick} = props

    return <StyledEmailIcon component={Email} onClick={onClick} />
}
