import React from 'react'
import {Popover} from 'antd'

import {DuplicatePopoverContent} from './DuplicatePopoverContent'

type DuplicatePopoverProps = {
    children: React.ReactNode
    duplicate?: {
        alias: string
        taskId: string
    }
    type?: string
    entityName?: string
}

export const DuplicatePopover = ({children, ...rest}: DuplicatePopoverProps): JSX.Element => {
    return (
        <Popover content={<DuplicatePopoverContent {...rest} />}>
            <div>{children}</div> {/*need to add div for proper working*/}
        </Popover>
    )
}
