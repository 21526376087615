import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledAddressList = styled.div`
    margin-top: ${variables.gutterSm};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

export const StyledHeader = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeBase};
`

export const StyledComments = styled.span`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.navyAsphalt};
    margin-top: ${variables.gutterXs};
`

export const StyledFormItem = styled(Form.Item)`
    margin-top: ${variables.gutterXs};
    margin-bottom: 0;
`
