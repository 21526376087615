import React, {Component} from 'react'
import {connect} from 'react-redux'
import {formValueSelector, change} from 'redux-form'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'

import {CheckboxTree} from 'ca-common/ui-lib/components/CheckboxTree'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'
import {havePositiveValue} from 'ca-common/utils/usersManagement'

import {TasksList} from './TasksList'

@withTranslation()
class PortalListsRaw extends Component {
    componentDidUpdate(prevProps) {
        const {hasGeneral, hasServices, isDashboardEnabled, portalGeneral, actions, services} = this.props

        if (prevProps.isDashboardEnabled !== isDashboardEnabled && !isDashboardEnabled) {
            if (hasGeneral) {
                portalGeneral.sub.forEach(general => {
                    actions.setValue(`permissions.general.sub.${general.name}`, isDashboardEnabled)
                })
            }

            if (hasServices) {
                services.sub.forEach(service => {
                    actions.setValue(`permissions.services.sub.${service.name}`, isDashboardEnabled)
                })
            }
        }
    }

    render() {
        const {t, portalGeneral, tasks, services, isDashboardEnabled, ...passThroughProps} = this.props

        return (
            <div>
                <JumbotronToggle title={t('userManagement:ppPortalOperations')} um>
                    <CheckboxTree data={this.changeDisable(portalGeneral, isDashboardEnabled)} {...passThroughProps} />
                    <TasksList data={this.changeDisable(tasks, isDashboardEnabled)} {...passThroughProps} />
                </JumbotronToggle>
                <JumbotronToggle title={t('userManagement:services')} um>
                    <CheckboxTree data={this.changeDisable(services, isDashboardEnabled)} {...passThroughProps} />
                </JumbotronToggle>
            </div>
        )
    }

    changeDisable = (data, isDashboardEnabled) => {
        return {
            ...data,
            disabled: !isDashboardEnabled,
            sub: data.sub.map(sub => ({
                ...sub,
                disabled: !isDashboardEnabled
            }))
        }
    }
}

const mapStateToProps = (state, {form}) => {
    const selector = formValueSelector(form)

    const getValue = name => selector(state, name)
    const hasServices = () =>
        getValue('permissions.services.all') || havePositiveValue(getValue('permissions.services.sub'))
    const hasGeneral = () =>
        getValue('permissions.general.all') || havePositiveValue(getValue('permissions.general.sub'))

    return {
        hasServices: hasServices(),
        hasGeneral: hasGeneral(),
        isDashboardEnabled: getValue('permissions.ppGeneral.sub.DASHBOARD')
    }
}

const mapDispatchToProps = (dispatch, {form}) => ({
    actions: {
        ...bindActionCreators(
            {
                setValue: (name, value) => dispatch(change(form, name, value))
            },
            dispatch
        )
    }
})

export const PortalLists = connect(mapStateToProps, mapDispatchToProps)(PortalListsRaw)
