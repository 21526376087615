import {ACCESS_LEVEL, UNSUPPORTED_SERVICES, USER_MANAGEMENT_GROUPS, PERMISSION_GROUPS} from 'ca-common/constants'
import {some, isObject} from 'lodash'

export const getSubsByType = (subs, type) =>
    subs.filter(sub => sub.permissionGroup && sub.permissionGroup.type === type)

export const getPagesPermissionGroup = originalList =>
    originalList.reduce((result, {sub, label}) => {
        const hasPageList = getSubsByType(sub, PERMISSION_GROUPS.PAGES)

        return [...result, ...hasPageList.map(({name, permissionGroup: {action}}) => ({name, action, label}))]
    }, [])

export const getBackupsPermissionGroup = originalList =>
    originalList.reduce((result, {sub}) => {
        const hasBackupList = getSubsByType(sub, PERMISSION_GROUPS.BACKUP)

        return [...result, ...hasBackupList.map(({name}) => name)]
    }, [])

export const getPagePermissionAction = ({value, action}) => ({
    [action]: {
        enabled: value,
        permissionId: action
    }
})

export const getPageGroup = (perms, pages) => ({
    PAGE: pages.reduce((result, {name, action, label}) => {
        if (name === 'BACKUPS' && result.BACKUPS) {
            return {
                ...result,
                BACKUPS: {
                    ...result.BACKUPS,
                    permissions: {
                        ...result.BACKUPS.permissions,
                        ...getPagePermissionAction({
                            action,
                            value: (perms[label] && perms[label][name]) || false
                        })
                    }
                }
            }
        }

        return {
            ...result,
            [name]: {
                serviceId: name,
                permissions: {
                    ...getPagePermissionAction({
                        action,
                        value: (perms[label] && perms[label][name]) || false
                    })
                }
            }
        }
    }, {})
})

export const backupPermission = ({name, value}) => ({
    [name]: {
        enabled: value,
        permissionId: name
    }
})

export const getBackupGroupPermissions = (formTasks, originalList) => {
    const backups = getBackupsPermissionGroup(originalList)

    return backups.reduce(
        (result, current) => ({
            ...result,
            ...backupPermission({
                name: current,
                value: formTasks[current] || false
            })
        }),
        {}
    )
}

export const getBackupGroup = (perms, originalList) => {
    const SERVICES_LIST = originalList.find(group => group.label === USER_MANAGEMENT_GROUPS.SERVICES)
    const formServices = perms[USER_MANAGEMENT_GROUPS.SERVICES] || {}
    const formTasks = perms[USER_MANAGEMENT_GROUPS.TASKS] || {}

    return {
        BACKUP: SERVICES_LIST.sub.reduce(
            (result, service) => ({
                ...result,
                [service.name]: {
                    serviceId: service.name,
                    permissions: {
                        ...backupPermission({
                            name: 'ACCESS',
                            value: formServices[service.name] || false
                        }),
                        ...getBackupGroupPermissions(formTasks, originalList)
                    }
                }
            }),
            {}
        )
    }
}

export const getSubValues = permissions => {
    return permissions
        ? Object.keys(permissions).reduce((result, current) => {
              const hasSub = permissions[current].sub && Object.keys(permissions[current].sub).length

              return hasSub ? {...result, [current]: permissions[current].sub} : result
          }, {})
        : {}
}

export const isAllFullChecked = (permissions, originalLenght) => {
    return permissions && Object.values(permissions).filter(item => item.all).length === originalLenght
}

export const hasPositiveSubs = subValues => {
    return Object.keys(subValues).some(subGroup =>
        Object.keys(subValues[subGroup]).some(subValue => subValues[subGroup][subValue])
    )
}

export const parseFormPermissions = ({permissions, credentials}, originalList) => {
    if (isAllFullChecked(permissions, originalList.length) && credentials.role === ACCESS_LEVEL.FULL) {
        return {
            accessLevel: ACCESS_LEVEL.FULL
        }
    }

    const subValues = getSubValues(permissions)

    if (!permissions || !hasPositiveSubs(subValues)) {
        return {
            accessLevel: ACCESS_LEVEL.NONE
        }
    }

    return {
        accessLevel: ACCESS_LEVEL.CUSTOM,
        groups: {
            ...getPageGroup(subValues, getPagesPermissionGroup(originalList)),
            ...getBackupGroup(subValues, originalList)
        }
    }
}

export const groupLabel = (list, name) => list.find(i => i.sub.find(s => s.name === name)).label

const parseGroups = (list, match) =>
    match.reduce((result, current) => {
        const label = isObject(current) ? current.label : groupLabel(list, current)
        const item = isObject(current) ? {[current.name]: true} : {[current]: true}

        return result.hasOwnProperty(label)
            ? {...result, [label]: {...result[label], sub: {...result[label].sub, ...item}}}
            : {...result, [label]: {sub: {...item}}}
    }, {})

export const isServiceEnabled = (services, name) => {
    return (
        services[name] &&
        services[name].permissions &&
        services[name].permissions.ACCESS &&
        services[name].permissions.ACCESS.enabled
    )
}

export const isPermissionEnabled = (permissions, name) => {
    return name !== 'ACCESS' && permissions[name] && permissions[name].enabled
}

export const isPageEnabled = (perms, page) => {
    const {name, action} = page

    return (
        name !== 'USERS' &&
        perms[name] &&
        perms[name].permissions &&
        perms[name].permissions[action] &&
        perms[name].permissions[action].enabled
    )
}

export const getEnabledPages = (pagePerms, pages) =>
    pages.reduce((result, current) => {
        const state = isPageEnabled(pagePerms, current)
        return state ? [...result, {name: current.name, label: current.label}] : result
    }, [])

export const getEnabledServices = services =>
    services &&
    Object.keys(services)
        .filter(item => isServiceEnabled(services, item))
        .filter(item => !some(UNSUPPORTED_SERVICES, i => i === item))

export const getEnabledPermissions = permissions =>
    permissions && Object.keys(permissions).filter(item => isPermissionEnabled(permissions, item))

export const getServicePermissions = (services, name) =>
    (services.hasOwnProperty(name) && services[name].permissions) || {}

export const getEnabled = (perms, originalList) => {
    const services = getEnabledServices(perms.groups.BACKUP)
    const permissions = getEnabledPermissions(getServicePermissions(perms.groups.BACKUP, services[0] || ''))
    const pages = getPagesPermissionGroup(originalList)

    return [...getEnabledPages(perms.groups.PAGE, pages), ...services, ...permissions]
}

export const setAllSubs = sub => {
    return sub.reduce(
        (result, current) => ({
            ...result,
            [current.name]: true
        }),
        {}
    )
}

export const setFull = originalList => {
    return originalList.reduce(
        (result, current) => ({
            ...result,
            [current.label]: {all: true, sub: setAllSubs(current.sub)}
        }),
        {}
    )
}

export const countPositiveValues = obj => obj && Object.values(obj).filter(i => i).length

export const getOriginalSubCounts = originalList => {
    return originalList.reduce((result, current) => {
        return {...result, [current.label]: current.sub.length}
    }, {})
}

export const setCustom = (perms, originalList) => {
    const counts = getOriginalSubCounts(originalList)
    const groups = parseGroups(originalList, getEnabled(perms, originalList))

    return Object.keys(groups).reduce(
        (result, current) => ({
            ...result,
            [current]: {...groups[current], all: countPositiveValues(groups[current].sub) === counts[current]}
        }),
        {}
    )
}

export const parseExistPermission = (permission, originalList) => {
    switch (permission.accessLevel) {
        case ACCESS_LEVEL.FULL:
            return setFull(originalList)

        case ACCESS_LEVEL.CUSTOM:
            return setCustom(permission, originalList)

        default:
            return {}
    }
}

export const havePositiveValue = obj => obj && Object.values(obj).some(i => i)

export const getRole = role => {
    return role === ACCESS_LEVEL.FULL ? ACCESS_LEVEL.FULL : ACCESS_LEVEL.CUSTOM
}

export const parseFormAccounts = accounts => {
    if (!accounts) {
        return []
    }

    const keysOfAccounts = Object.keys(accounts)

    return keysOfAccounts.reduce((result, current) => {
        return accounts[current] ? [...result, current] : result
    }, [])
}

export const parseExistAccounts = userAccounts => {
    if (!Array.isArray(userAccounts) || userAccounts.length < 1) {
        return {}
    }

    return userAccounts.reduce((result, current) => {
        return {...result, [current]: true}
    }, {})
}

export const filterByWhiteList = (serviceList, whiteList) => {
    if (!Array.isArray(whiteList)) {
        return serviceList
    }

    return serviceList.filter(service => whiteList.includes(service.name))
}

export const getServicesByWhiteList = (services, servicesWhiteList) => {
    return {
        ...services,
        sub: filterByWhiteList(services.sub, servicesWhiteList)
    }
}

export const getUserTypeLabel = (name, credentialTypeOptions) => {
    return credentialTypeOptions.find(i => i.value === name).label
}

export const parsePortalGeneralList = (isWlUser, generalList) => {
    const filteredSubs = generalList.sub.filter(sub => {
        return !(isWlUser && (sub.name === 'SHOW_ALL_SUPPORT_TICKETS' || sub.name === 'SUPPORT'))
    })

    return {
        ...generalList,
        sub: filteredSubs
    }
}
