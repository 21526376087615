import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledWrapped = styled.section`
    background: ${variables.white};
    border-radius: ${variables.btnBorderRadiusBase};
    display: flex;
    flex-direction: column;
`
