import styled from 'styled-components'
import {Tabs} from 'antd'

export const StyledTabs = styled(Tabs)`
    min-height: 100px;

    .ant-tabs-tab {
        padding-left: 0;
        padding-right: 0;
        font-size: 18px;
    }
`
