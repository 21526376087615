import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledCompareDiffItem = styled.div`
    .d2h-file-header {
        display: none;
    }

    .d2h-file-wrapper {
        border: 0;
        border-radius: 0;
        margin: 0;
    }
`

export const StyledWrapper = styled.div`
    background: ${variables.white};
    padding: ${variables.gutterMd} 0;
`
