import styled from 'styled-components'
import {Modal} from 'antd'

import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import variables from 'ca-common/variables'

export const StyledConfirmAction = styled(Modal)``

export const Header = styled.div`
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${variables.gutterMd};
    border-bottom: 1px solid ${variables.blue};

    .anticon {
        font-size: 48px;
        color: ${props => (props.action === TASK_ACTION.DELETE ? variables.red : variables.asphalt)};
    }
`

export const StatusResult = styled.p`
    font-size: 18px;
    margin-bottom: 0;
    width: 340px;

    span {
        color: ${variables.asphalt};
    }
`

export const Footer = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${variables.gutterMd};
`
