import _ from 'lodash'
import {SEEDING_FILTERS} from 'ca-common/common/enum/seeding'
import {Filter, ObjectsList} from 'src/newcore/components/Seeding/types'

export const statusFormat = (t: any, filter: Filter) => {
    if (filter.name === SEEDING_FILTERS.PICK) {
        const pickName = t(`seeding:filters:${filter.name}`)
        const pickOptions = t(`seeding:pickOptions:${filter.filteringType}`)
        return `${pickName} ${filter.pickCount} ${pickOptions}`
    }
    return t(`seeding:filters:${filter.name}`)
}

export const getFilterOfObjects = (list: ObjectsList) => {
    let filter = (null as unknown) as Filter
    let isMixed = false

    _.forOwn(list, object => {
        if (filter) {
            if (!_.isEqual(object.filter, filter)) {
                isMixed = true
                return false
            }
        } else {
            filter = object.filter
        }
    })

    if (isMixed) {
        return {name: SEEDING_FILTERS.MIXED}
    }

    return filter
}
