import React from 'react'
import {TablePaginationConfig, TableProps} from 'antd'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router'
import {useTranslation} from 'react-i18next'

import {UserInfoDataType} from 'ca-common/types'
import {FetchedStateStatuses} from 'ca-common/utils/fetchWrapperRT'

import {PAGE_SIZE, TABLE_X_SCROLL_AFTER} from 'ca-common/features/UserManagementTable/lib/constants'
import type {
    GetEditPageUrlFnType,
    RemoveCredentialsFnType,
    ResendConfirmationEmailFnType,
    UserCredential
} from 'ca-common/features/UserManagementTable/types'
import {useColumns} from 'ca-common/features/UserManagementTable/lib/useColumns'

import {StyledTable} from './StyledUserManagementTable'

type UserManagementTableProps = {
    data: UserCredential[]
    page: number
    status: FetchedStateStatuses
    fetchItemsByPage: (page: number) => Promise<void>
    total: number
    resendConfirmationEmail: ResendConfirmationEmailFnType
    userInfo: UserInfoDataType
    getEditPageUrl: GetEditPageUrlFnType
    removeCredential: RemoveCredentialsFnType
    isZix?: boolean
    isPP?: boolean
}

export const UserManagementTable = (props: UserManagementTableProps) => {
    const {
        status,
        data,
        page,
        fetchItemsByPage,
        total,
        getEditPageUrl,
        removeCredential,
        resendConfirmationEmail,
        isZix,
        userInfo,
        isPP
    } = props
    const location = useLocation()
    const history = useHistory()
    const {t} = useTranslation()

    const columns = useColumns({
        isZix,
        userInfo,
        getEditPageUrl,
        removeCredential,
        resendConfirmationEmail,
        isPP
    })

    const onPageChange = (pagination?: TablePaginationConfig) => {
        const page = pagination?.current || 1
        const query = queryString.parse(location.search)
        const params = queryString.stringify({...query, page})
        history.replace(`${location.pathname}?${params}`)
        fetchItemsByPage(page)
    }

    return (
        <StyledTable<(props: TableProps<UserCredential>) => JSX.Element>
            rowKey={item => item.id}
            columns={columns}
            dataSource={data}
            scroll={{x: TABLE_X_SCROLL_AFTER}}
            loading={status !== 'fulfilled'}
            pagination={{
                pageSize: PAGE_SIZE,
                showSizeChanger: false,
                showTotal: (total: number, range: number[]) =>
                    t('table:footer:itemPagination', {
                        start: range[0],
                        end: range[1],
                        total
                    }),
                size: 'small',
                total,
                current: page
            }}
            onChange={onPageChange}
        />
    )
}
