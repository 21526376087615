import React from 'react'

import {WIZARD_ICON, WizardTitleIcon} from 'ca-common/ui-lib/atoms/WizardTitleIcon'

import {HeaderWrapper, StyledStep, StyledStepsWrapper, StyledTitle} from './StyledWizardHeader'

type WizardHeaderProps = {
    activeKey: string
    title?: string
    description?: string
    progress: string
    onChange?: (key: string) => void
    icon?: WIZARD_ICON
    panes: {
        key: string
        disabled?: boolean
    }[]
}

export const WizardHeader = (props: WizardHeaderProps) => {
    const {panes, activeKey, title, progress, onChange, description, icon} = props

    return (
        <HeaderWrapper>
            <StyledTitle>
                {icon && <WizardTitleIcon icon={icon} />}
                <h2>{title}</h2>
                <span>{description}</span>
            </StyledTitle>
            <StyledStepsWrapper>
                {panes.map(el => (
                    <StyledStep
                        key={el.key}
                        active={activeKey === el.key}
                        unavailable={el.key > progress || el.disabled}
                        onClick={() => (onChange && !el.disabled ? onChange(el.key) : null)}
                    />
                ))}
            </StyledStepsWrapper>
        </HeaderWrapper>
    )
}
