import {useCallback, useState} from 'react'

import {SOURCE} from 'ca-common/common/enum'

import type {Entity} from 'src/newcore/features/OneClickActivation/types'

type UseTableData = () => {
    appendToTableData: (data: Entity[]) => void
    modifyEntity: ({id, checked}: {service: SOURCE; id: string; checked: boolean}) => void
    clearTableData: () => void
    tableData: Entity[]
    modifyAllEntities: ({checked}: {checked: boolean}) => void
}

const DEFAULT_TABLE_DATA = [] as Entity[]

export const useTableData: UseTableData = () => {
    const [tableData, setTableData] = useState(DEFAULT_TABLE_DATA)

    const appendToTableData = useCallback((data: Entity[]) => {
        setTableData(prev => [...prev, ...data])
    }, [])

    const modifyEntity = useCallback(({id, checked}: {service: SOURCE; id: string; checked: boolean}) => {
        setTableData(prev =>
            prev.map(entity => {
                if (entity.id === id) {
                    return {
                        ...entity,
                        checked
                    }
                }

                return entity
            })
        )
    }, [])

    const modifyAllEntities = useCallback(({checked}: {checked: boolean}) => {
        setTableData(prev => prev.map(entity => ({...entity, checked})))
    }, [])

    const clearTableData = useCallback(() => {
        setTableData(() => DEFAULT_TABLE_DATA)
    }, [])

    return {tableData, appendToTableData, clearTableData, modifyEntity, modifyAllEntities}
}
