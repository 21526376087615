import _ from 'lodash'

export const getStartIndex = (scrollPage: number, pageSize: number) => (scrollPage - 2) * pageSize + 1
export const getEndIndex = (scrollPage: number, pageSize: number) => (scrollPage + 1) * pageSize

export const getEntitiesBefore = <T extends {id: string}[]>(entities: T, startIndex: number) =>
    startIndex > 0 ? _.slice(entities, 0, startIndex) : []
export const getEntitiesAfter = <T extends {id: string}[]>(entities: T, endIndex: number) =>
    _.slice(entities, endIndex, entities.length)

export const getPulledResult = <T extends {id: string}[]>(
    newEntities: T,
    oldEntities: T,
    scrollPage: number,
    pageSize: number
) => {
    const startIndex = getStartIndex(scrollPage, pageSize)
    const endIndex = getEndIndex(scrollPage, pageSize)

    const entitiesBefore = getEntitiesBefore(oldEntities, startIndex)
    const entitiesAfter = getEntitiesAfter(oldEntities, endIndex)

    return _.map(_.groupBy([...entitiesBefore, ...newEntities, ...entitiesAfter], 'id'), _.last) as T
}
export const getBottomEntityIds = <T extends {id: string}[]>(entities: T, pageSize: number, bottomEntityGap: number) =>
    _.map(
        _.filter(entities, (entity, i) => i % pageSize === pageSize - bottomEntityGap - 1),
        'id'
    ) as string[]
