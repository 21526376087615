import styled from 'styled-components'
import {Checkbox} from 'antd'

const CheckboxGroup = Checkbox.Group

export const InfinityContainer = styled.div`
    height: 250px;
    overflow: auto;
`

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    & .ant-checkbox-group-item {
        display: flex;
        margin-bottom: 19px;
    }
`
