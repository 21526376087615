import React from 'react'
import PropTypes from 'prop-types'

import AACP from 'ca-common/icons/clientLogo/AACP.svg'
import BGC from 'ca-common/icons/clientLogo/BGC.svg'
import Jaguar from 'ca-common/icons/clientLogo/Jaguar.svg'
import Ramvik from 'ca-common/icons/clientLogo/Ramvik.svg'
import ShooshMonkey from 'ca-common/icons/clientLogo/ShooshMonkey.svg'
import Singlehanded from 'ca-common/icons/clientLogo/Singlehanded.svg'
import WestLondon from 'ca-common/icons/clientLogo/WestLondon.svg'

import {CLIENT_LOGO} from 'ca-common/constants'

import {StyledClientLogo} from './StyledClientLogo'

const iconWrapper = (Component: ({width}: {width: string}) => JSX.Element) => {
    return <Component width="100%" />
}

export const ClientLogo = ({client}: {client: keyof typeof CLIENT_LOGO}) => {
    const renderClientLogo = () => {
        switch (client) {
            case CLIENT_LOGO.AACP:
                return AACP
            case CLIENT_LOGO.BGC:
                return BGC
            case CLIENT_LOGO.JAGUAR:
                return Jaguar
            case CLIENT_LOGO.RAMVIK:
                return Ramvik
            case CLIENT_LOGO.SHOOSHMONKEY:
                return ShooshMonkey
            case CLIENT_LOGO.SINGLEHANDED:
                return Singlehanded
            case CLIENT_LOGO.WESTLONDON:
                return WestLondon
            default:
                return null
        }
    }

    return <StyledClientLogo client={client}>{iconWrapper(renderClientLogo())}</StyledClientLogo>
}

ClientLogo.propTypes = {
    client: PropTypes.string.isRequired
}
