import React from 'react'
import {connect} from 'react-redux'

import {PageTitle} from 'ca-common/ui-lib/atoms/PageTitle'
import {END_USER_RECOVERY_FILTER} from 'ca-common/constants'

import {CABreadcrumbsRaw} from '../Breadcrumbs'
import {PageNavigation} from './StyledPageNavigation'

export const PageNavigationRaw = ({breadcrumbs, title, userInfo}) => {
    const updatedBreadcrumb = !userInfo?.isMsEndUser
        ? breadcrumbs
        : breadcrumbs.filter(value => value.match.path !== END_USER_RECOVERY_FILTER)

    return (
        updatedBreadcrumb.length > 0 && (
            <PageNavigation>
                <PageTitle>{title || updatedBreadcrumb[0].breadcrumb}</PageTitle>
                {updatedBreadcrumb.length > 1 && <CABreadcrumbsRaw breadcrumbs={updatedBreadcrumb} />}
            </PageNavigation>
        )
    )
}

const mapStateToProps = state => ({
    userInfo: state.userInfo?.response
})

export const CAPageNavigationRaw = connect(mapStateToProps)(PageNavigationRaw)
