import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Spin} from 'antd'
import moment from 'moment'
import {get} from 'lodash'

import {getReplicates, clearReplicates} from 'src/newcore/redux/modules/backups/backupSeeding'

import {ReplicatesList} from './ReplicatesList'
import {getDownloadUrl} from 'src/newcore/redux/modules/recovery/recoveries'

const mapStateToProps = state => ({
    replicates: state.backups.seeding.replicates,
    taskSettings: state.backups.settings.response
})

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                getReplicates,
                clearReplicates,
                getDownloadUrl
            },
            dispatch
        )
    }
}

const pullConfigTimes = {
    short: moment.duration(1, 'minutes'),
    long: moment.duration(5, 'minutes'),
    stop: moment.duration(20, 'minutes')
}

export const Replicates = connect(
    mapStateToProps,
    mapDispatchToProps
)(({actions, replicates, taskSettings}) => {
    const fetchReplicates = () => {
        const {taskId} = taskSettings

        actions.getReplicates({
            taskId
        })
    }

    useEffect(() => {
        fetchReplicates()

        return () => {
            actions.clearReplicates()
        }
    }, [taskSettings.taskId])

    return (
        <Spin spinning={replicates.status !== 'fulfilled'}>
            <ReplicatesList
                taskAlias={taskSettings.alias}
                data={get(replicates, 'response', [])}
                getReplicates={fetchReplicates}
                getDownloadUrl={actions.getDownloadUrl}
                pullConfig={{
                    pull: fetchReplicates,
                    ...pullConfigTimes
                }}
            />
        </Spin>
    )
})
