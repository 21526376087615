import React from 'react'
import {DefaultOptionType} from 'antd/lib/select'

import {ActivityStatusValues, getStatusIcon} from 'ca-common/utils/getActivityStatusIcon'

import {MultiSelect, MultiSelectProps} from 'ca-common/ui-lib/atoms/MultiSelect'

import {StyledOptionWrapper, StatusIcon} from './StyledStatusMultiSelect'

type StatusMultiSelectProps = Omit<MultiSelectProps, 'options'> & {
    options: {
        label: ActivityStatusValues | string
        value: DefaultOptionType['value']
    }[]
}

export const StatusMultiSelect = React.forwardRef(({options, ...restProps}: StatusMultiSelectProps, ref) => {
    const preparedOptions = options.map(option => ({
        value: option.value,
        label: (
            <StyledOptionWrapper>
                <StatusIcon component={getStatusIcon(option.label as ActivityStatusValues)} />
                {option.label}
            </StyledOptionWrapper>
        )
    }))

    return <MultiSelect options={preparedOptions} {...restProps} {...ref} />
})
