import {createAction, createReducer, createSlice} from '@reduxjs/toolkit'

import {postLessData} from 'src/newcore/utils/rest'

import {
    DEFAULT_PAGE,
    ROWS_PROPERTY_VALUES
} from 'src/newcore/components/BulkActivation/ActiveDirectory/ActiveDirectory/FieldValues'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'
import {AppDispatch} from 'src/newcore/components/Root'

const GET_ACCOUNT_PROPERTY_VALUES = 'GET_ACCOUNT_PROPERTY_VALUES'
const GET_ACCOUNT_PROPERTY_VALUES_API = 'getAccountPropertyValues'
const getAccountPropertyValuesWrapper = fetchWrapperRT(
    GET_ACCOUNT_PROPERTY_VALUES,
    async data => await postLessData(GET_ACCOUNT_PROPERTY_VALUES_API, data)
)

export const getAccountPropertyValues = (data: TODO_ANY) => (dispatch: AppDispatch) => {
    return dispatch(
        getAccountPropertyValuesWrapper.fetcher({
            page: DEFAULT_PAGE,
            rows: ROWS_PROPERTY_VALUES,
            ...data
        })
    ).unwrap()
}

const APPEND_ACCOUNT_PROPERTY_VALUES = 'APPEND_ACCOUNT_PROPERTY_VALUES'
const appendAccountPropertyValuesWrapper = fetchWrapperRT<{data: TODO_ANY[]}>(
    APPEND_ACCOUNT_PROPERTY_VALUES,
    async data => await postLessData(GET_ACCOUNT_PROPERTY_VALUES_API, data),
    {
        after: (response, state) => {
            if (state.status === 'fulfilled') {
                return {
                    ...response,
                    data: [...state.response.data, ...response.data]
                }
            }

            return response
        }
    }
)

export const appendAccountPropertyValues = appendAccountPropertyValuesWrapper.fetcher

const accountPropertyValuesSlice = createSlice({
    name: 'accountPropertyValues',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearAccountPropertyValues: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => {
        getAccountPropertyValuesWrapper.makeReducers(builder)
        appendAccountPropertyValuesWrapper.makeReducers(builder)
    }
})

export const {clearAccountPropertyValues} = accountPropertyValuesSlice.actions
export const accountPropertyValues = accountPropertyValuesSlice.reducer
const SET_ACCOUNT_PROPERTY_NAME = 'SET_ACCOUNT_PROPERTY_NAME'

export const setAccountPropertyName = createAction<string>(SET_ACCOUNT_PROPERTY_NAME)
const CLEAR_ACCOUNT_PROPERTY_NAME = 'CLEAR_ACCOUNT_PROPERTY_NAME'

export const clearAccountPropertyName = createAction(CLEAR_ACCOUNT_PROPERTY_NAME)

export const accountPropertyName = createReducer('', builder => {
    builder.addCase(clearAccountPropertyName, () => '')
    builder.addCase(setAccountPropertyName, (state, action) => action.payload)
})

const SET_CHECKED_ACCOUNT_PROPERTY_VALUES = 'SET_CHECKED_ACCOUNT_PROPERTY_VALUES'
export const setCheckedAccountPropertyValues = createAction<TODO_ANY[]>(SET_CHECKED_ACCOUNT_PROPERTY_VALUES)

const CLEAR_CHECKED_ACCOUNT_PROPERTY_VALUES = 'CLEAR_CHECKED_ACCOUNT_PROPERTY_VALUES'
export const clearCheckedAccountPropertyValues = createAction(CLEAR_CHECKED_ACCOUNT_PROPERTY_VALUES)

export const checkedAccountPropertyValues = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearCheckedAccountPropertyValues, () => [])
    builder.addCase(setCheckedAccountPropertyValues, (state, action) => action.payload)
})
