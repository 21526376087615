import React from 'react'
import {Tree} from 'antd'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'
import Info from 'ca-common/icons/Info.svg'
import {TreeWrapper} from './StyledGoogleOUList'

type NestedCheckboxprops = {
    ousList: any
    onCheck: any
    defaultCheckedKeysList: any
    blockedOusAccount?: any
}

export const NestedCheckbox = (props: NestedCheckboxprops) => {
    const {t} = useTranslation()
    const {ousList, onCheck, defaultCheckedKeysList, blockedOusAccount} = props
    const isBlockedOu = (ouId: string) => {
        return blockedOusAccount?.find((blockedOus: any) => blockedOus.id == ouId)
    }
    const transformedGoogleOuList = ousList?.map((data: any) => ({
        key: data.id,
        title: data.name,
        disableCheckbox: isBlockedOu(data.id),
        icon: ({disableCheckbox}: {disableCheckbox: any}) =>
            disableCheckbox ? (
                <Tooltip title={t('backups:bulkActivation:Google:DisableOUtooltip')}>
                    <Icon component={Info} />
                </Tooltip>
            ) : null,
        children: data.nestedItems?.map((nestedData: any) => ({
            key: nestedData.id,
            title: nestedData.name,
            disableCheckbox: isBlockedOu(nestedData.id),
            icon: ({disableCheckbox}: {disableCheckbox: any}) =>
                disableCheckbox ? (
                    <Tooltip title={t('backups:bulkActivation:Google:DisableOUtooltip')}>
                        <Icon component={Info} />
                    </Tooltip>
                ) : null,
            children: nestedData.nestedItems?.map((nestedChildrenData: any) => ({
                key: nestedChildrenData.id,
                title: nestedChildrenData.name,
                disableCheckbox: isBlockedOu(nestedChildrenData.id),
                icon: ({disableCheckbox}: {disableCheckbox: any}) =>
                    disableCheckbox ? (
                        <Tooltip title={t('backups:bulkActivation:Google:DisableOUtooltip')}>
                            <Icon component={Info} />
                        </Tooltip>
                    ) : null
            }))
        }))
    }))

    return (
        <TreeWrapper>
            <Tree
                showIcon
                checkable
                checkStrictly={true}
                defaultCheckedKeys={defaultCheckedKeysList}
                onCheck={onCheck}
                treeData={transformedGoogleOuList}
            />
        </TreeWrapper>
    )
}
