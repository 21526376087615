import {combineReducers} from '@reduxjs/toolkit'
import {reducer as formReducer} from 'redux-form'

import {modalsReducer as modals} from 'ca-common/redux/modules/modal'
import {supportTicketDialogs} from 'src/newcore/redux/modules/support'
import {securityAudit} from 'src/newcore/redux/modules/accountActivity'
import {activity} from 'src/newcore/features/ActivityTable/redux'
import {companyInfo} from 'src/newcore/redux/modules/companyInfo'
import {userInfo, accountSettings} from 'src/newcore/redux/modules/account'
import {credentials, permission} from 'src/newcore/redux/modules/userManagement'
import {twoFa} from 'src/newcore/redux/modules/security'
import {expandedRowKeys} from 'src/newcore/redux/modules/expandedRowKeys'
import {
    zoozPaymentMethodsReducer as zoozPaymentMethods,
    zoozSubscribeMonthlyReducer as zoozSubscribeMonthly,
    zoozSubscribeReducer as zoozSubscribe,
    usersBillingReducer as usersBilling,
    annualSubscribeInfoReducer as annualSubscribeInfo,
    invoiceHistoryReducer as invoiceHistory,
    editAnnualSubscribeReducer as editAnnualSubscribe,
    paymentMethodReducer as paymentMethod,
    billingLineReducer as billingLine,
    removeZoozPaymentMethodReducer as removeZoozPaymentMethod,
    posPublicKeyReducer as posPublicKey,
    whiteLabelSubscribeReducer as whiteLabelSubscribe,
    nextPaymentDateReducer as nextPaymentDate
} from 'src/newcore/redux/modules/billing'
import {
    usersNotificationsReducer,
    getNotificationSettingsReducer,
    usersNotificationReducer
} from 'src/newcore/redux/modules/notifications'
import {recoveryReducer as recovery} from 'src/newcore/redux/modules/recovery'
import {backups} from 'src/newcore/redux/modules/backups'
import {wizardReducers as wizard} from 'src/newcore/redux/modules/wizard'

import {queryParamsReducer as queryParams} from 'ca-common/redux/modules/navigation'
import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import {getFAQReducer} from 'src/newcore/redux/modules/faq'

import {signIn} from 'ca-common/redux/modules/sign'
import {calendar} from 'src/newcore/organisms/SnapshotCalendar/redux'
import {recoveryNew} from 'src/newcore/features/Recovery/redux'
import {jobs} from 'src/newcore/features/Jobs/redux'
import {ipRestrictions} from 'src/newcore/features/IpRestriction'
import {billingStatus} from 'src/newcore/features/BillingStatus'

export const newAppRootReducer = {
    accountSettings,
    companyInfo,
    supportTicketDialogs,
    userInfo,
    queryParams,
    signIn,
    modals,
    form: formReducer,
    twoFa,
    credentials,
    permission,
    billing: combineReducers({
        usersBilling,
        zoozPaymentMethods,
        zoozSubscribe,
        zoozSubscribeMonthly,
        annualSubscribeInfo,
        invoiceHistory,
        editAnnualSubscribe,
        paymentMethod,
        billingLine,
        removeZoozPaymentMethod,
        posPublicKey,
        whiteLabelSubscribe,
        nextPaymentDate,
        billingStatus
    }),
    notifications: combineReducers({
        notificationList: usersNotificationsReducer,
        notificationReport: usersNotificationReducer,
        notificationsSettings: getNotificationSettingsReducer
    }),
    securityAudit,
    activity,
    recovery,
    recoveryNew,
    backups,
    faq: getFAQReducer,
    wizard,
    expandedRowKeys,
    calendar,
    jobs,
    ipRestrictions,
    [rtkQueryBaseApi.reducerPath]: rtkQueryBaseApi.reducer
}
