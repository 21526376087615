import React from 'react'
import {reduce, map, reject, pick} from 'lodash'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import queryString from 'query-string'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar/SelectedFiltersBar'
import {getStatusIcon} from 'ca-common/utils/taskStatus'

import {StyledSelectedFilters, StyledIcon} from './StyledSelectedFilters'

const ARRAY_FORMAT = {arrayFormat: 'bracket'}

export const SelectedFilters = withRouter(props => {
    const getURLQuery = () => {
        const search = props?.location?.search || {}

        return queryString.parse(search, ARRAY_FORMAT)
    }

    const {values = pick(getURLQuery(), ['statusFilter', 'licenseStatus']), fetched} = props
    const {t} = useTranslation()

    const setURLSearch = params => {
        const {history} = props

        const newParams = {
            ...getURLQuery(),
            ...params
        }

        history.push({
            search: queryString.stringify(newParams, ARRAY_FORMAT)
        })
    }

    const remove = value => {
        const [parent, target] = value.split('.')

        const newValues = reduce(
            values,
            (result, current, key) => {
                let newValue

                if (Array.isArray(current)) {
                    newValue = parent === key ? reject(current, i => i === target) : current
                } else {
                    newValue = parent === key ? undefined : current
                }

                return {
                    ...result,
                    [key]: newValue
                }
            },
            {}
        )

        setURLSearch(newValues)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: []}), {})

        setURLSearch(newValues)
    }

    const prepareData = data => {
        return reduce(
            data,
            (result, current, key) => {
                let newData = []
                if (Array.isArray(current)) {
                    newData = map(current, item => ({
                        value: `${key}.${item}`,
                        label: t(`backups:newTaskStatus:${item}`),
                        icon: <StyledIcon component={getStatusIcon(item)} />
                    }))
                }

                if (typeof current === 'string') {
                    newData = [
                        {
                            value: `${key}.${current}`,
                            label: t(`backups:${key}:${current}`),
                            icon: null
                        }
                    ]
                }

                return [...result, ...newData]
            },
            []
        )
    }

    return (
        <StyledSelectedFilters disabled={fetched}>
            <SelectedFiltersBar selectedFilterValues={prepareData(values)} onRemove={remove} onRemoveAll={removeAll} />
        </StyledSelectedFilters>
    )
})
