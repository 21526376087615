import styled from 'styled-components'
import variables from 'ca-common/variables'

export const ModalContent = styled.div<{padding?: string}>`
    ${props => (props.padding ? `padding: ${props.padding}` : '')};
`

export const StyledImageWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: ${variables.gutterMd};
`

export const StyledTitle = styled.h3`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.sectionFontSize};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0;
    text-align: center;

    margin-bottom: ${variables.gutterMd};
`

export const StyledParagraph = styled.p<{mb?: number; padding?: string; textAlign?: string}>`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.headingFontSize};
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0;
    text-align: ${props => (props.textAlign ? props.textAlign : 'center')};

    margin-bottom: ${props => (props.mb ? `${props.mb}px` : variables.gutterXs)};
    ${props => (props.padding ? `padding: ${props.padding}` : '')}
`
