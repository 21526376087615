import React, {Fragment} from 'react'
import {withTranslation, Trans} from 'react-i18next'
import _ from 'lodash'

import {Badge, Description} from './StyledCompareTable'
import {OverviewModal} from '../OverviewModal'
import {SFC_DATA_TYPE} from 'ca-common/common/enum/Backups'
import {compareDateDescription} from '../../../utils/backups'
import {COMPARE_FILE_CASES} from 'ca-common/common/enum/CompareItem'

@withTranslation()
export class CompareSummary extends React.Component {
    state = {
        selectedComparison: ''
    }

    onClickCompareBadge = type => {
        if (this.props.data[type].length) {
            this.setState({
                selectedComparison: type
            })
        }
    }

    closeModal = () => {
        this.setState({
            selectedComparison: ''
        })
    }

    getDescription = (type, onClick) => {
        if (!type) {
            return ''
        }

        const {data, t} = this.props
        const length = data[type].length

        if (!length) {
            return ''
        }

        const typeTitle =
            data.comparisonType === SFC_DATA_TYPE.METADATA
                ? t('compareSnapshot:metadata:title')
                : t('compareSnapshot:data:title')

        switch (type) {
            case COMPARE_FILE_CASES.UNCHANGED:
                return (
                    <Trans
                        i18nKey="compareSnapshot:descriptionWithoutChanges"
                        key={COMPARE_FILE_CASES.UNCHANGED}
                        values={{withoutChanges: length, type: typeTitle}}
                    >
                        <Badge onClick={onClick}>0</Badge>
                    </Trans>
                )
            case COMPARE_FILE_CASES.LEFT:
                return (
                    <Trans
                        i18nKey="compareSnapshot:descriptionOfChanges"
                        values={{
                            number: length,
                            backup: `${data.backupAccount} - ${compareDateDescription({
                                date: data.backupDate
                            })}`,
                            type: typeTitle
                        }}
                        key={COMPARE_FILE_CASES.LEFT}
                    >
                        <Badge onClick={onClick}>0</Badge>
                    </Trans>
                )
            case COMPARE_FILE_CASES.RIGHT:
                return (
                    <Trans
                        i18nKey="compareSnapshot:descriptionOfChanges"
                        key={COMPARE_FILE_CASES.RIGHT}
                        values={{
                            number: length,
                            backup: `${data.compareAccount} - ${compareDateDescription({
                                date: data.compareBackupDate,
                                isSandbox: data.isSandbox
                            })}`,
                            type: typeTitle
                        }}
                    >
                        <Badge onClick={onClick}>0</Badge>
                    </Trans>
                )
            default:
                return ''
        }
    }

    render() {
        const {data} = this.props
        const {selectedComparison} = this.state

        return (
            <Fragment>
                <Description>
                    {this.getDescription(COMPARE_FILE_CASES.UNCHANGED, () =>
                        this.onClickCompareBadge(COMPARE_FILE_CASES.UNCHANGED)
                    )}
                </Description>
                <Description>
                    {this.getDescription(COMPARE_FILE_CASES.LEFT, () =>
                        this.onClickCompareBadge(COMPARE_FILE_CASES.LEFT)
                    )}
                </Description>
                <Description>
                    {this.getDescription(COMPARE_FILE_CASES.RIGHT, () =>
                        this.onClickCompareBadge(COMPARE_FILE_CASES.RIGHT)
                    )}
                </Description>
                <OverviewModal
                    data={_.get(data, `${selectedComparison}`, [])}
                    handleCancel={this.closeModal}
                    title={this.getDescription(selectedComparison)}
                    visible={!!selectedComparison}
                />
            </Fragment>
        )
    }
}
