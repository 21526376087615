import styled from 'styled-components'

import variables from 'ca-common/variables'
import {SourceIcon} from 'ca-common/components/SourceIcon'

export const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`

export const StyledHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterMd};
`

export const StyledHeaderRight = styled.div`
    display: flex;
    align-items: center;
`

export const StyledSourceIcon = styled(SourceIcon)`
    margin-right: ${variables.gutterMd};
`
