import {i18n} from 'ca-common/locales'
import {STORAGE_TYPE} from 'ca-common/common/enum'

export const getFriendlyToStorageType = (storageType: STORAGE_TYPE) => {
    return i18n.t(`STORAGE_TYPE:${storageType}`)
}

export const getFriendlyStorageTypeTitle = (storageType: STORAGE_TYPE) => {
    switch (storageType) {
        case STORAGE_TYPE.AMAZON_S3:
            return i18n.t('forms:customS3:title')

        case STORAGE_TYPE.AMAZON_S3_COMPATIBLE:
            return i18n.t('forms:awsS3Compatible:title')

        case STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM:
            return i18n.t('forms:gcp:title')

        case STORAGE_TYPE.AZURE:
            return i18n.t('forms:azure:title')

        default:
            return ''
    }
}
