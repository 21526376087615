import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {SOURCE} from 'src/ca-common/common/enum'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {setWizardDescription, setWizardTitle, wizardDataSelector} from 'src/newcore/redux/modules/wizard'

import {useCreateOCATasksQuery} from 'src/newcore/features/OneClickActivation/redux'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {useBeforeRecentlyDeletedTasksStepChange} from 'src/newcore/features/OneClickActivation/lib'
import {Footer} from 'src/newcore/features/OneClickActivation/molecules'
import {RecentlyDeletedTaskSource} from 'src/newcore/features/OneClickActivation/atoms'

import {
    StyledBody,
    StyledContent,
    StyledEmail,
    StyledRecentlyDeletedTaskSources,
    StyledSubtitle
} from './StyledOCARecentlyDeletedTasksStep'

export const OCARecentlyDeletedTasksStep = () => {
    const {t} = useTranslation()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const {data} = useCreateOCATasksQuery(wizardData.stateId)
    const dispatch = useAppDispatch()

    const {recentlyDeletedTasks = [], email, availableSources = []} = data || {}

    const initFormData = recentlyDeletedTasks.reduce((acc, {source}) => {
        acc[source as SOURCE] = !!wizardData.tasksForReinstate?.find(task => task.source === source)
        return acc
    }, {} as Record<SOURCE, boolean>)

    const [formData, setFormData] = useState(initFormData)
    const {reinitBeforeStepChange} = useBeforeRecentlyDeletedTasksStepChange(
        initFormData,
        availableSources,
        recentlyDeletedTasks
    )

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:recentlyDeletedTasksStep')))
        dispatch(setWizardDescription(''))
    }, [])

    const onChange = (source: SOURCE, value: boolean) => {
        const newFormData = {
            ...formData,
            [source]: value
        }

        setFormData(newFormData)
        reinitBeforeStepChange(newFormData)
    }

    return (
        <StyledContent>
            <StyledBody>
                <StyledSubtitle>
                    <Trans i18nKey="oneClickActivation:wizard:labels:recentlyDeletedTasks" tOptions={{email}}>
                        <StyledEmail />
                    </Trans>
                </StyledSubtitle>
                {recentlyDeletedTasks && (
                    <StyledRecentlyDeletedTaskSources>
                        {recentlyDeletedTasks!.map(({source}) => (
                            <RecentlyDeletedTaskSource
                                key={source}
                                source={source}
                                isForReinstate={formData[source]}
                                onChange={onChange}
                            />
                        ))}
                    </StyledRecentlyDeletedTaskSources>
                )}
            </StyledBody>
            <Footer />
        </StyledContent>
    )
}
