import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledContainer = styled.section`
    display: flex;
    flex-direction: column;
    min-width: 300px;
`

export const StyledTreeContainer = styled.div`
    min-width: 300px;
    min-height: 600px;
    width: 100%;
    background: ${variables.white};
    overflow-y: hidden;
    overflow-x: hidden;

    .ant-tree {
        padding: 0;

        * {
            transition: all 0ms;
        }

        .ant-tree-iconEle.ant-tree-icon__customize path {
            stroke: ${variables.interaction20};
        }

        .ant-tree-list-holder {
            overflow-x: hidden;
        }

        .ant-tree-treenode {
            padding-left: 2px;
        }

        .ant-tree-treenode-selected {
            &:before {
                border-left: 2px solid ${variables.navyBlue};
            }

            .ant-tree-iconEle.ant-tree-icon__customize path {
                stroke: ${variables.navyBlue};
            }
        }

        .ant-tree-treenode:active,
        .ant-tree-treenode-selected {
            &:before,
            .ant-tree-node-content-wrapper,
            .ant-tree-switcher,
            .ant-tree-checkbox {
                background-color: ${variables.interaction};
                transition: all 0s;
            }

            &:hover {
                &:before,
                .ant-tree-node-content-wrapper,
                .ant-tree-switcher,
                .ant-tree-checkbox {
                    background-color: ${variables.interaction5};
                }
            }
        }

        .ant-tree-node-content-wrapper {
            &:hover {
                background-color: ${variables.blueLight};
            }
        }

        > .ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
        .ant-tree-child-tree > .ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
            border-color: ${variables.navyBlue};
        }
    }

    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
        margin-right: 7px;
        vertical-align: sub;
    }
    .ant-tree-checkbox {
        margin: 0;
        top: 0.69em;
        .ant-tree-checkbox-inner {
            border: 1px solid ${variables.clearTagColor};
            border-radius: 2px;
        }
    }

    .ant-tree.ant-tree-directory > span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > span.ant-tree-node-content-wrapper:hover::before {
        height: 36px;
    }

    .ant-tree.ant-tree-directory > span.ant-tree-node-content-wrapper.ant-tree-node-selected,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > span.ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: ${variables.navyBlue};
        font-weight: 600;
    }

    .ant-tree .ant-tree-node-content-wrapper,
    .ant-tree span.ant-tree-switcher {
        height: 36px;
        line-height: 36px;
        vertical-align: middle;
        color: ${variables.navyBlue};
    }

    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
        color: ${variables.navyBlue};
    }

    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        white-space: nowrap;
        padding-left: 12px;

        .ant-tree-iconEle {
            margin-left: -${variables.gutterXs};
        }
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: ${variables.navyBlue};
    }

    .ant-tree-checkbox.ant-tree-checkbox-indeterminate ~ .ant-tree-node-content-wrapper {
        color: ${variables.navyBlue};
        font-weight: 600;

        .ant-tree-iconEle.ant-tree-icon__customize path {
            stroke: ${variables.navyBlue};
            fill: ${variables.interaction20};
        }
    }

    .ant-tree-checkbox.ant-tree-checkbox-checked ~ .ant-tree-node-content-wrapper {
        color: ${variables.navyBlue};
        font-weight: 600;

        .ant-tree-iconEle.ant-tree-icon__customize path {
            stroke: ${variables.navyBlue};
            fill: ${variables.navyBlue};
        }
    }
`
