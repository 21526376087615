import TaskTypeRestore from 'ca-common/icons/old/uEA68-task-type-restore.svg'
import TaskTypeDownload from 'ca-common/icons/old/uEA67-task-type-download.svg'

export enum TASK_TYPE {
    RESTORE = 'RESTORE',
    EXPORT = 'EXPORT'
}

export const TASK_TYPE_INFO = {
    RESTORE: {icon: TaskTypeRestore},
    EXPORT: {icon: TaskTypeDownload}
}
