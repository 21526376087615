import _ from 'lodash'
import {withRouter} from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'

import {CARowWrapper, CAPageControl, CAPaginationInfo} from 'ca-common/ui-lib/components/TableElements'
import {CurrentPage} from './CurrentPage'
import {SELECTION_TYPE} from 'ca-common/common/enum/Table'

import {items as itemsShape, config} from 'ca-common/shapes/table'
import {StyledIcon} from './StyledNoTotalFooter'
import ArrowLeft from 'ca-common/icons/old/uEA0C-arrow-left.svg'
import ArrowRight from 'ca-common/icons/old/uEA0D-arrow-right.svg'

@withTranslation('table')
class CANoTotalFooterRaw extends React.Component {
    /**
     * React based dictionary of properties
     * @type {Object}
     * @property {Object} propTypes.config is a config of the table
     * @property {Object} propTypes.items is an collection of pageable items
     * @property {Function} propTypes.fetchItems gets a new pageable collection of items
     * @property {Number} propTypes.pageSize defines amount of items at one page
     * @property {Object} propTypes.options defines an option parametr for pathParamsFormatter
     */

    static propTypes = {
        config: PropTypes.shape(config).isRequired,
        items: PropTypes.shape(itemsShape).isRequired,
        fetchItems: PropTypes.func.isRequired,
        pageSize: PropTypes.number.isRequired,
        options: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        _.bindAll(this, ['renderFooter', 'countItemsFrom', 'countItemsTo', 'nextPage', 'previousPage', 'navigatePage'])
    }

    render() {
        const {items} = this.props

        switch (items.status) {
            case 'fulfilled':
                return this.renderFooter(items)
            default:
                return null
        }
    }

    renderFooter(items) {
        const {t} = this.props

        return (
            <CARowWrapper modifier="footer">
                {items.page > 1 && (
                    <StyledIcon data-action="previous" component={ArrowLeft} onClick={this.previousPage} />
                )}
                <CAPageControl>
                    {t('table:footer:page')}
                    <CurrentPage items={this.props.items} navigatePage={this.navigatePage} />
                    {items.page <= items.totalPages && `${t('table:footer:of')} ${items.totalPages}`}
                </CAPageControl>
                {items.data.length > 0 && (
                    <StyledIcon data-action="next" component={ArrowRight} onClick={this.nextPage} />
                )}
                <CAPaginationInfo>
                    {t('table:footer:noTotalItemPagination', {
                        start: this.countItemsFrom(),
                        end: this.countItemsTo()
                    })}
                </CAPaginationInfo>
            </CARowWrapper>
        )
    }

    countItemsFrom() {
        return this.countItemsTo() - this.props.items.data.length + 1
    }

    countItemsTo() {
        let counter = 0
        if (this.props.items) {
            counter = this.props.items.data.length
            counter += this.props.pageSize * (this.props.items.page - 1)
        }
        return counter
    }

    nextPage() {
        this.navigatePage(this.props.items.page + 1)
    }

    previousPage() {
        this.navigatePage(this.props.items.page - 1)
    }

    navigatePage(pageNumber) {
        const {pageSize, options} = this.props
        const {limitKey} = this.props.config.table
        const {rowSelection} = this.props

        if (rowSelection && rowSelection.type === SELECTION_TYPE.CHECKBOX && rowSelection.areAllItemsSelected) {
            rowSelection.setSelectAllItems(false)
        }

        const query = queryString.parse(this.props.history.location.search)

        query.page = pageNumber

        const params = queryString.stringify(query, {sort: false})
        this.props.history.push(`${this.props.history.location.pathname}?${params}`)

        this.props.fetchItems({
            ...options,
            page: pageNumber,
            [limitKey]: pageSize
        })
    }
}

export const CANoTotalFooter = withRouter(CANoTotalFooterRaw)
