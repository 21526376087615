import React from 'react'
import {Route, RouteComponentProps, Switch} from 'react-router'

import {Jobs, DownloadTable} from 'src/newcore/features/Jobs'

export const JobsRouting = ({match}: RouteComponentProps): JSX.Element => {
    return (
        <Switch>
            <Route path={`${match.url}/`} exact component={Jobs} />
            <Route path={`${match.url}/downloadReport`} exact component={DownloadTable} />
        </Switch>
    )
}
