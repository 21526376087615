import React from 'react'

import {
    COLUMN_NAMES,
    getColumnNameFriendly,
    getSmartAlertRuleFriendly,
    getSmartAlertEventFriendly,
    getSmartAlertTriggeredByFriendly,
    getSmartAlertUnitFriendly,
    getObjectFriendly
} from 'src/newcore/features/SmartAlerts/lib'

import {Actions} from 'src/newcore/features/SmartAlerts/molecules'
import {Number} from 'src/newcore/atoms'

const ACTIONS_WIDTH = 96
const TRIGGERED_BY_WIDTH = 148

export const columns = onSingleActionHandler => [
    {
        title: getColumnNameFriendly(COLUMN_NAMES.TRIGGER_OBJECT),
        dataIndex: COLUMN_NAMES.ALERT_LEVEL,
        key: COLUMN_NAMES.TRIGGER_OBJECT,
        sorter: true,
        render: (alertLevel, record) => getObjectFriendly(alertLevel, record?.[COLUMN_NAMES.SPECIFIC_OBJECT])
    },
    {
        title: getColumnNameFriendly(COLUMN_NAMES.RULE),
        dataIndex: COLUMN_NAMES.RULE,
        key: COLUMN_NAMES.RULE,
        sorter: true,
        render: rule => getSmartAlertRuleFriendly(rule)
    },
    {
        title: getColumnNameFriendly(COLUMN_NAMES.TOTAL),
        dataIndex: COLUMN_NAMES.TOTAL,
        key: COLUMN_NAMES.TOTAL,
        sorter: true,
        align: 'right',
        render: total => <Number>{total}</Number>
    },
    {
        title: getColumnNameFriendly(COLUMN_NAMES.UNIT),
        dataIndex: COLUMN_NAMES.UNIT,
        key: COLUMN_NAMES.UNIT,
        sorter: true,
        render: unit => getSmartAlertUnitFriendly(unit)
    },
    {
        title: getColumnNameFriendly(COLUMN_NAMES.EVENT),
        dataIndex: COLUMN_NAMES.EVENT,
        key: COLUMN_NAMES.EVENT,
        sorter: true,
        render: event => getSmartAlertEventFriendly(event)
    },
    {
        title: getColumnNameFriendly(COLUMN_NAMES.TRIGGERED_BY),
        dataIndex: COLUMN_NAMES.TRIGGERED_BY,
        key: COLUMN_NAMES.TRIGGERED_BY,
        sorter: true,
        render: triggeredBy => getSmartAlertTriggeredByFriendly(triggeredBy),
        width: TRIGGERED_BY_WIDTH
    },
    {
        render: (_, record) => <Actions record={record} onSingleActionHandler={onSingleActionHandler} />,
        width: ACTIONS_WIDTH
    }
]

export const PAGE_SIZE = 50
export const DEFAULT_PAGE = 1
