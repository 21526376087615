import React, {useState} from 'react'
import {base64Url} from 'ca-common/utils/base64Url'
import {Input} from 'antd'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'

import Button from 'ca-common/ui-lib/components/Button'
import {STAGES} from 'src/ca-common/common/enum/TwoFa'
import {verifyTwoFa, updateTwoFa} from 'src/newcore/redux/modules/security'
import {openErrorModal} from 'src/ca-common/utils/modals'
import {openSuccessNotification} from 'src/ca-common/utils/toasts'
import {TWOFA} from 'src/ca-common/constants'
import {useAppDispatch} from 'src/newcore/components/Root'

import {ListIndent, ListInfo, ListStage, StyledTwoFa, StyledTwoFaBlock} from './StyledTwoFA'

type DisabledType = {
    twoFa: Record<string, any>
    companyName?: string
}
export const Disabled = (props: DisabledType) => {
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {twoFa, companyName} = props
    const [verifyCode, setInputValue] = useState('')
    const [stage, setVerifyStage] = useState(STAGES.INIT)

    const verifyTwoFaCode = () => {
        if (verifyCode) {
            dispatch(verifyTwoFa({code: verifyCode}))
                .unwrap()
                .then((response: {success: any; data: any}) => {
                    const {success, data} = response

                    if (success && data) {
                        openSuccessNotification(t('twoFaAuthPage:codeVerified'))
                        dispatch(
                            updateTwoFa({
                                twoFaEnabled: true
                            })
                        )

                        return history.push(TWOFA.SECURITY_PAGE)
                    }

                    return openErrorModal(t('twoFaAuthPage:verifyFailed'))
                })
                .catch(() => openErrorModal(t('twoFaAuthPage:verifyFailed')))
        }
    }
    return (
        <StyledTwoFa>
            <StyledTwoFaBlock modifier={'guide'}>
                <StyledTwoFa>
                    <ListIndent>1</ListIndent>
                    <ListInfo>{t('twoFaAuthPage:instruction1')}</ListInfo>
                </StyledTwoFa>
                <StyledTwoFa>
                    <ListIndent>2</ListIndent>
                    <ListInfo>
                        {t('twoFaAuthPage:instruction2', {
                            companyName: companyName,
                            code: twoFa.response.code
                        })}
                    </ListInfo>
                </StyledTwoFa>
                {stage === STAGES.VERIFY ? (
                    <ListStage>
                        <Input
                            onChange={(e: any) => setInputValue(e.target.value)}
                            type="text"
                            placeholder={t('twoFaAuthPage:verifyPlaceholder')}
                        />
                        <Button modifier="primary" onClick={verifyTwoFaCode}>
                            {t('twoFaAuthPage:actions:verify')}
                        </Button>
                    </ListStage>
                ) : (
                    <Button modifier="primary" onClick={() => setVerifyStage(STAGES.VERIFY)}>
                        {t('twoFaAuthPage:actions:next')}
                    </Button>
                )}
            </StyledTwoFaBlock>
            <StyledTwoFaBlock modifier={'qrcode'}>
                <img alt={TWOFA.QRCODE} src={base64Url(twoFa.response.qrCode)} />
            </StyledTwoFaBlock>
        </StyledTwoFa>
    )
}
