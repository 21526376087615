import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {SummaryReport} from 'ca-common/ui-lib/components/Notifications/SummaryReport'
import {getNotificationSettings, updateNotificationSettings} from 'src/newcore/redux/modules/notifications'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {AppState, useAppSelector, useAppDispatch} from 'src/newcore/components/Root'
import {TableContainer} from './TableContainer'

export const NotificationsPage = () => {
    const {t} = useTranslation()
    const notificationsSettings = useAppSelector((state: AppState) => state.notifications.notificationsSettings)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getNotificationSettings())
    }, [])

    return (
        <div>
            {notificationsSettings.response && (
                <SummaryReport
                    initialValues={{
                        ...notificationsSettings.response
                    }}
                    onSubmit={(values: any) => {
                        dispatch(updateNotificationSettings(values))
                            .then(() => {
                                dispatch(getNotificationSettings())
                                openSuccessNotification(t('notifications:emailUpdated'))
                            })
                            .catch(() => {
                                openErrorModal(t('notifications:updateFailed'))
                            })
                    }}
                    isPP={false}
                />
            )}
            <TableContainer />
        </div>
    )
}
