import {post} from 'src/newcore/utils/rest'
import {combineReducers, createSlice} from '@reduxjs/toolkit'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'
import {BillingLineResponseItem, BillingResponse} from 'src/newcore/features/BillingStatus/types'

const GET_BILLING = 'GET_BILLING'
const GET_BILLING_URL = 'billing'

const getBillingWrapper = fetchWrapperRT(GET_BILLING, async data => await post(GET_BILLING_URL, data))

export const getBilling = getBillingWrapper.fetcher

const billingSlice = createSlice({
    name: GET_BILLING,
    initialState: InitFetchedState as FetchedState<BillingResponse>,
    reducers: {
        clearBilling: () => InitFetchedState as FetchedState<BillingResponse>
    },
    extraReducers: builder => getBillingWrapper.makeReducers(builder)
})

const billingReducer = billingSlice.reducer

const GET_BILLING_TABLE_DATA = 'GET_BILLING_TABLE_DATA'
const GET_BILLING_LINES_URL = 'getBillingLine'
const GET_UNPAID_BILLS_URL = 'getUnpaidBills'
const GET_INVOICES_URL = 'getInvoices'
const GET_PAYMENT_TABLE_DATA = 'GET_PAYMENT_TABLE_DATA'

const getBillingTableDataWrapper = fetchWrapperRT(GET_BILLING_TABLE_DATA, async data => {
    const {paymentStatus} = data || {}

    switch (paymentStatus) {
        case PaymentStatus.DUE:
        case PaymentStatus.OVERDUE:
            return post(GET_INVOICES_URL, {
                status: paymentStatus
            })
        case PaymentStatus.UNPAID:
            return post(GET_UNPAID_BILLS_URL, {})
    }
})

const getPaymentsTableDataWrapper = fetchWrapperRT(GET_PAYMENT_TABLE_DATA, async data => {
    return post(GET_BILLING_LINES_URL, {page: 1, limit: 1000})
})

export const getBillingTableData = getBillingTableDataWrapper.fetcher

export const getPaymentsTableData = getPaymentsTableDataWrapper.fetcher

const billingTableDataSlice = createSlice({
    name: GET_BILLING_TABLE_DATA,
    initialState: InitFetchedState as FetchedState<BillingLineResponseItem[]>,
    reducers: {
        clearBillingStatus: () => InitFetchedState as FetchedState<BillingLineResponseItem[]>
    },
    extraReducers: builder => getBillingTableDataWrapper.makeReducers(builder)
})

const billingTableDataReducer = billingTableDataSlice.reducer

const paymentTableDataSlice = createSlice({
    name: GET_PAYMENT_TABLE_DATA,
    initialState: InitFetchedState as FetchedState<BillingLineResponseItem[]>,
    reducers: {
        clearBillingStatus: () => InitFetchedState as FetchedState<BillingLineResponseItem[]>
    },
    extraReducers: builder => getPaymentsTableDataWrapper.makeReducers(builder)
})

const paymentTableDataReducer = paymentTableDataSlice.reducer

export const billingStatusReducer = combineReducers({billingReducer, billingTableDataReducer, paymentTableDataReducer})
