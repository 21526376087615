import React from 'react'

import {PasswordTokenExpired} from 'ca-common/organisms/PasswordTokenExpired'
import {ChangePublicPassword} from 'ca-common/organisms/ChangePublicPassword'

export const ChangePassword = ({isRecoveryExpiredVisible, actions}) => {
    const {toggleRecoveryExpiredVisible, changeTemporaryPassword} = actions

    return isRecoveryExpiredVisible ? (
        <PasswordTokenExpired actions={{toggleRecoveryExpiredVisible}} />
    ) : (
        <ChangePublicPassword actions={{changeTemporaryPassword}} />
    )
}
