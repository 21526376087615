import React from 'react'
import {Trans} from 'react-i18next'

import {StyledHeader, StyledTitle, StyledIpAddress} from './StyledHeaderWithIp'

type HeaderWithIpProps = {
    title: JSX.Element | string
    yourIpAddress?: string
}

export const HeaderWithIp = (props: HeaderWithIpProps): JSX.Element => {
    const {title, yourIpAddress} = props

    return (
        <StyledHeader>
            <StyledTitle>{title}</StyledTitle>
            {yourIpAddress && (
                <StyledIpAddress>
                    <Trans i18nKey="ipRestrictions:headerYouIp" values={{yourIpAddress}}>
                        <strong>yourIpAddress</strong>
                    </Trans>
                </StyledIpAddress>
            )}
        </StyledHeader>
    )
}
