import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SelectProps, Tooltip} from 'antd'

import {DEACTIVATION_OPTIONS, DEACTIVATION_TYPES} from 'ca-common/constants'
import {Select} from 'ca-common/ui-lib/atoms/Select'
import variables from 'ca-common/variables'

import {StyledFormItem} from './StyledDeactivationRule'

type DeactivationRuleProps = {
    deactivationOption: DEACTIVATION_TYPES
}

export const DeactivationRule = ({deactivationOption}: DeactivationRuleProps) => {
    const {t} = useTranslation()
    const name = 'deactivationOption'

    return (
        <StyledFormItem
            colon={false}
            label={t('backups:bulkActivation:deactivation:label')}
            name={name}
            initialValue={deactivationOption}
        >
            <SelectWithTooltip />
        </StyledFormItem>
    )
}

const SelectWithTooltip = (props: Partial<SelectProps>) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const {t} = useTranslation()

    return (
        <Tooltip
            title={t(`backups:bulkActivation:deactivation:tooltips:${props.value}`)}
            open={showTooltip}
            overlayInnerStyle={{
                width: '260px',
                fontFamily: variables.publicPageFont,
                fontWeight: 300,
                fontSize: variables.fontSizeSecondary
            }}
        >
            <Select
                onMouseEnter={() => dropdownVisible || setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                dropdownMatchSelectWidth={false}
                clear
                options={DEACTIVATION_OPTIONS}
                allowClear={false}
                showSearch={false}
                onDropdownVisibleChange={open => {
                    if (open) setShowTooltip(false)
                    setDropdownVisible(open)
                }}
                {...props}
            />
        </Tooltip>
    )
}
