import {get, post, postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_USERS_BILLING = 'GET_USERS_BILLING'
const GET_ZOOZ_PAYMENT_METHODS = 'GET_ZOOZ_PAYMENT_METHODS'
const ZOOZ_SUBSCRIBE = 'ZOOZ_SUBSCRIBE'
const ZOOZ_SUBSCRIBE_MONTHLY = 'ZOOZ_SUBSCRIBE_MONTHLY'
const GET_ANNUAL_SUBSCRIBE_INFO = 'GET_ANNUAL_SUBSCRIBE_INFO'
const GET_ANNUAL_SUBSCRIBE = 'GET_ANNUAL_SUBSCRIBE'
const GET_INVOICE_HISTORY = 'GET_INVOICE_HISTORY'
const EDIT_ANNUAL_SUBSCRIBE = 'EDIT_ANNUAL_SUBSCRIBE'
const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD'
const GET_BILLING_LINE = 'GET_BILLING_LINE'
const REMOVE_ZOOZ_PAYMENT_METHOD = 'REMOVE_ZOOZ_PAYMENT_METHOD'
const GET_POS_PUBLIC_KEY = 'GET_POS_PUBLIC_KEY'
const WHITE_LABEL_SUBSCRIBE = 'WHITE_LABEL_SUBSCRIBE'
const GET_NEXT_PAYMENT_DATE = 'GET_NEXT_PAYMENT_DATE'

const UsersBilling = fetchWrapperRT(GET_USERS_BILLING, async data => await post('billing', data))
export const getUsersBilling = UsersBilling.fetcher

const updateUsersBillingReducer = (state, action) => ({
    ...state,
    response: {
        ...state.response,
        data: {
            ...state.response.data,
            ...action.payload
        }
    }
})

const usersBillingSlice = createSlice({
    name: 'usersBilling',
    initialState: InitFetchedState,
    reducers: {
        updateUsersBilling: updateUsersBillingReducer
    },
    extraReducers: builder => UsersBilling.makeReducers(builder)
})

export const {updateUsersBilling} = usersBillingSlice.actions
export const usersBillingReducer = usersBillingSlice.reducer

const ZoozPaymentMethods = fetchWrapperRT(
    GET_ZOOZ_PAYMENT_METHODS,
    async data => await post('getZoozPaymentMethods', data)
)
export const getZoozPaymentMethods = ZoozPaymentMethods.fetcher

const zoozPaymentMethodsSlice = createSlice({
    name: 'zoozPaymentMethods',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => ZoozPaymentMethods.makeReducers(builder)
})
export const zoozPaymentMethodsReducer = zoozPaymentMethodsSlice.reducer

const ZoozSubscribeMonthly = fetchWrapperRT(ZOOZ_SUBSCRIBE_MONTHLY, async (data, {rejectWithValue}) => {
    try {
        return await post('subscribe', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const setZoozSubscribeMonthly = ZoozSubscribeMonthly.fetcher

const zoozSubscribeMonthlySlice = createSlice({
    name: 'zoozSubscribeMonthly',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => ZoozSubscribeMonthly.makeReducers(builder)
})
export const zoozSubscribeMonthlyReducer = zoozSubscribeMonthlySlice.reducer

const ZoozSubscribe = fetchWrapperRT(ZOOZ_SUBSCRIBE, async (data, {rejectWithValue}) => {
    try {
        return await post('zoozSubscribe', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})

export const setZoozSubscribe = ZoozSubscribe.fetcher

const zoozSubscribeSlice = createSlice({
    name: 'zoozSubscribe',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => ZoozSubscribe.makeReducers(builder)
})
export const zoozSubscribeReducer = zoozSubscribeSlice.reducer

const AnnualSubscribeInfo = fetchWrapperRT(
    GET_ANNUAL_SUBSCRIBE_INFO,
    async data => await post('getAnnualSubscribeInfo', data)
)
export const getAnnualSubscribeInfo = AnnualSubscribeInfo.fetcher

const updateAnnualSubscribeInfoReducer = (state, action) => ({
    ...state,
    response: {
        ...state.response,
        data: {
            ...state.response.data,
            ...action.payload
        }
    }
})

const annualSubscribeInfoSlice = createSlice({
    name: 'annualSubscribeInfo',
    initialState: InitFetchedState,
    reducers: {
        updateAnnualSubscribeInfo: updateAnnualSubscribeInfoReducer
    },
    extraReducers: builder => AnnualSubscribeInfo.makeReducers(builder)
})

export const {updateAnnualSubscribeInfo} = annualSubscribeInfoSlice.actions
export const annualSubscribeInfoReducer = annualSubscribeInfoSlice.reducer

const AnnualSubscribe = fetchWrapperRT(GET_ANNUAL_SUBSCRIBE, async data => await post('annualSubscribe', data))
export const annualSubscribe = AnnualSubscribe.fetcher

const InvoiceHistory = fetchWrapperRT(GET_INVOICE_HISTORY, async data => await postLessData('getInvoiceHistory', data))
export const getInvoiceHistory = InvoiceHistory.fetcher

const invoiceHistorySlice = createSlice({
    name: 'invoiceHistory',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => InvoiceHistory.makeReducers(builder)
})
export const invoiceHistoryReducer = invoiceHistorySlice.reducer

const EditAnnualSubscribe = fetchWrapperRT(EDIT_ANNUAL_SUBSCRIBE, async data => await post('editAnnualSubscribe', data))
export const editAnnualSubscribe = EditAnnualSubscribe.fetcher

const editAnnualSubscribeSlice = createSlice({
    name: 'editAnnualSubscribe',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => EditAnnualSubscribe.makeReducers(builder)
})
export const editAnnualSubscribeReducer = editAnnualSubscribeSlice.reducer

const PaymentMethod = fetchWrapperRT(GET_PAYMENT_METHOD, async data => await post('getPaymentMethod', data))
export const getPaymentMethod = PaymentMethod.fetcher

const updatePaymentMethodReducer = (state, action) => ({
    ...state,
    response: {
        ...state.response,
        data: {
            ...state.response.data,
            ...action.payload
        }
    }
})

const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState: InitFetchedState,
    reducers: {
        updatePaymentMethod: updatePaymentMethodReducer
    },
    extraReducers: builder => PaymentMethod.makeReducers(builder)
})

export const {updatePaymentMethod} = paymentMethodSlice.actions
export const paymentMethodReducer = paymentMethodSlice.reducer

const BillingLine = fetchWrapperRT(GET_BILLING_LINE, async (data, {rejectWithValue}) => {
    try {
        return await postLessData('getBillingLine', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const getBillingLine = BillingLine.fetcher

const billingLineSlice = createSlice({
    name: 'billingLine',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => BillingLine.makeReducers(builder)
})
export const billingLineReducer = billingLineSlice.reducer

const RemoveZoozPaymentMethod = fetchWrapperRT(
    REMOVE_ZOOZ_PAYMENT_METHOD,
    async data => await post('removeZoozPaymentMethod', data)
)
export const removeZoozPaymentMethod = RemoveZoozPaymentMethod.fetcher

const removeZoozPaymentMethodSlice = createSlice({
    name: 'removeZoozPaymentMethod',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => RemoveZoozPaymentMethod.makeReducers(builder)
})
export const removeZoozPaymentMethodReducer = removeZoozPaymentMethodSlice.reducer

const PosPublicKey = fetchWrapperRT(GET_POS_PUBLIC_KEY, async data => await post('posPublicKey', data))
export const getPosPublicKey = PosPublicKey.fetcher

const posPublicKeySlice = createSlice({
    name: 'posPublicKey',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => PosPublicKey.makeReducers(builder)
})
export const posPublicKeyReducer = posPublicKeySlice.reducer

const WhiteLabelSubscribe = fetchWrapperRT(WHITE_LABEL_SUBSCRIBE, async (data, {rejectWithValue}) => {
    try {
        return await post('wlSubscribe', data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const setWhiteLabelSubscribe = WhiteLabelSubscribe.fetcher

const whiteLabelSubscribeSlice = createSlice({
    name: 'whiteLabelSubscribe',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => WhiteLabelSubscribe.makeReducers(builder)
})
export const whiteLabelSubscribeReducer = whiteLabelSubscribeSlice.reducer

const NextPaymentDate = fetchWrapperRT(GET_NEXT_PAYMENT_DATE, async data => await get('nextPaymentDate', data))
export const getNextPaymentDate = NextPaymentDate.fetcher

const nextPaymentDateSlice = createSlice({
    name: 'nextPaymentDate',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => NextPaymentDate.makeReducers(builder)
})
export const nextPaymentDateReducer = nextPaymentDateSlice.reducer

const SET_BILLING_DETAILS = 'SET_BILLING_DETAILS'

const BillingDetails = fetchWrapperRT(SET_BILLING_DETAILS, async (data, {rejectWithValue}) => {
    try {
        return await post('setBillingDetails', data)
    } catch (e) {
        return rejectWithValue(e)
    }
})
export const setBillingDetails = BillingDetails.fetcher
