import {Input, Radio, Tabs} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'

export const StyledIcon = styled(Icon)`
    margin-top: ${variables.gutterXs};
`

export const StyledJumbotron = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 15px 21px;
    color: ${variables.dark};
    background: ${variables.white};
    box-shadow: 0 0 10px ${variables.lightGray};

    &__footer {
        display: flex;
        align-items: center;

        color: ${variables.navyAsphalt};
        text-align: center;
    }

    * {
        max-width: 100%;
    }

    .ca-link {
        font-size: 14px;
    }

    p {
        padding: 0 0 18px;
        font-size: ${variables.fontSizeBase};
        line-height: ${variables.fontSizeBase};
        display: flex;
        align-items: center;

        span {
            min-width: 290px;
            display: inline-block;
            margin-right: 15px;
        }

        & > div {
            min-width: 290px;
            display: inline-flex;
            white-space: nowrap;
            margin-right: 15px;
        }

        .ca-btn {
            min-width: 170px;
        }

        &:last-child {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            button {
                margin-right: 15px;
            }
        }
    }

    h3 {
        margin-bottom: 14px;

        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.3px;
        color: ${variables.dark};
    }

    .ca-form-checkbox {
        margin-bottom: 10px;
    }

    .switcher {
        &__control {
            margin-left: 5px;
        }
        &__wrapper {
            margin-bottom: 0;
        }
    }
`

export const CompanyNameText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledSecurityPageWrapper = styled.div`
    position: relative;
`

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        &::before {
            border-color: #e8e8e8;
        }

        .ant-tabs-tab {
            padding: 12px 16px;
        }

        .ant-tabs-tab .ant-tabs-tab-btn {
            font-family: 'Quicksand', sans-serif;
            font-size: ${variables.headingFontSize};
            color: ${variables.asphalt};
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${variables.navyBlue};
            font-weight: 600;
        }
        .ant-tabs-ink-bar {
            background-color: ${variables.navyBlue};
        }
    }
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${variables.gutterSm};
    padding-top: ${variables.gutterSm};
    margin-top: ${variables.gutterSm};
    border-top: 1px solid ${variables.blue};
`

export const StyledSAMLWrapper = styled.div`
    background-color: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.gutterMd} ${variables.gutterMd} ${variables.gutterSm};
`

export const StyledProviders = styled.div`
    margin-bottom: ${variables.gutterLg};
`

export const StyledUpload = styled.div`
    margin-bottom: ${variables.gutterXxl};
`

export const StyledHeader = styled.h5`
    font-family: 'Open Sans', sans-serif;
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
`

export const StyledSubTitle = styled.p`
    margin-bottom: ${variables.gutterMd};

    font-family: 'Open Sans', sans-serif;
    font-size: ${variables.fontSizeBase};
    color: ${variables.asphalt};
`

export const StyledRadioGroup = styled(Radio.Group)`
    width: 100%;
    max-width: 750px;
`

export const StyledRadio = styled(Radio)`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${variables.gutterMd};

    .ant-radio {
        margin-top: 4px;
    }

    span.ant-radio + * {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${variables.gutterXs};
        padding-left: ${variables.gutterSm};
    }

    .ant-form-explain {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;

        margin-top: ${variables.gutterXs};

        color: ${variables.navyAsphalt};
    }

    .has-error .ant-form-explain {
        color: ${variables.red};
    }
`

export const StyledRadioNote = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;

    color: ${variables.navyAsphalt};
`

export const StyledUploadInput = styled(Input)`
    flex-grow: 1;
`

export const StyledModalTitle = styled.h3`
    font-family: 'Open Sans', sans-serif;
    font-size: ${variables.fontSizeBase};
    margin-bottom: 12px;
    font-weight: 600;
`

export const StyledModalBody = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: ${variables.fontSizeBase};
`

export const StyledIndicator = styled.div`
    position: absolute;
    top: -60px;
    height: 40px;
    right: 0;
    padding: 0 ${variables.gutterSm};
    background-color: ${variables.white};
    border-radius: 20px;

    line-height: 40px;
    font-weight: 600;
`

export const StyledActive = styled.span`
    font-weight: 400;
    color: ${variables.systemGreen};
    margin-left: ${variables.gutterXs};
`
