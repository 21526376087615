import React from 'react'

import {Input, Form} from 'antd'
import {useTranslation} from 'react-i18next'

type Props = {
    account: string
}

export const TaskCreatedAccount = ({account}: Props): JSX.Element => {
    const {t} = useTranslation()

    return (
        <Form.Item label={t('forms:backupAuth:text:account')} name="account" colon={false}>
            <Input disabled placeholder={account} />
        </Form.Item>
    )
}
