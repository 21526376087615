import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TaskSettings} from 'ca-common/types'

const GET_JOB_TASKS = 'GET_JOB_TASKS'
const GET_JOB_TASKS_API = 'getBackupTasks'
const getJobTasksWrapper = fetchWrapperRT(GET_JOB_TASKS, async data => await post(GET_JOB_TASKS_API, data))
export const getJobTasks = getJobTasksWrapper.fetcher

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: InitFetchedState as FetchedState<TaskSettings[]>,
    reducers: {
        clearJobTasks: () => InitFetchedState as FetchedState<TaskSettings[]>
    },
    extraReducers: builder => getJobTasksWrapper.makeReducers(builder)
})

export const {clearJobTasks} = tasksSlice.actions
export const tasks = tasksSlice.reducer
