import React from 'react'
import moment from 'moment'

import {DATE_WITH_AT_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

import {StyledButton, StyledCalendarIcon, StyledCalendarIconWrapper} from './StyledRecoveryCalendarButton'

type RecoveryCalendarButtonProps = {
    onClick: () => void
    date: number
}

export const RecoveryCalendarButton = ({onClick, date}: RecoveryCalendarButtonProps): JSX.Element => {
    return (
        <StyledButton onClick={onClick}>
            {moment(date, LONG_DATE_FORMAT).format(DATE_WITH_AT_FORMAT)}
            <StyledCalendarIconWrapper>
                <StyledCalendarIcon />
            </StyledCalendarIconWrapper>
        </StyledButton>
    )
}
