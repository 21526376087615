import React, {Fragment} from 'react'
import Icon from '@ant-design/icons'
import {Checkbox, Tooltip, Form, CheckboxProps} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'

const CustomCheckbox = (props: CheckboxProps) => {
    const {t} = useTranslation()

    return (
        <Fragment>
            <Checkbox disabled={true} {...props}>
                {t('forms:backupSettings:fields:indexStatus:label')}
            </Checkbox>
            <Tooltip title={t('forms:backupSettings:fields:indexStatus:tooltip')}>
                <Icon component={Info} />
            </Tooltip>
        </Fragment>
    )
}

type IndexStatusProps = {
    indexStatus?: boolean
    namePrefix?: string
}

export const IndexStatus = ({indexStatus, namePrefix = ''}: IndexStatusProps) => {
    const name = `${namePrefix ? `${namePrefix}.` : ''}indexStatus`

    return (
        <Form.Item name={name} initialValue={indexStatus} valuePropName="checked">
            <CustomCheckbox />
        </Form.Item>
    )
}
