import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    color: ${variables.white};
    opacity: 0.5;
    font-size: 12px;

    &:hover {
        opacity: 1;
    }
`
