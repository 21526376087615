import React from 'react'
import {Tooltip} from 'antd'

import Info from 'ca-common/icons/Info.svg'
import {RowName, RowValue, StyledEntityRow, StyledIcon} from './StyledEntityRow'

type EntityRowProps = {
    name: string
    value: string | number
    tooltipText?: string
}

export const EntityRow = ({name, value, tooltipText}: EntityRowProps): JSX.Element => {
    return (
        <StyledEntityRow isHighlighted={!!tooltipText}>
            <RowName>
                {name}
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <StyledIcon component={Info} />
                    </Tooltip>
                )}
            </RowName>
            <RowValue>{value}</RowValue>
        </StyledEntityRow>
    )
}
