import styled from 'styled-components'
import {InputNumber} from 'antd'
import variables from 'ca-common/variables'

export const AccountDeactivationInputNumber = styled(InputNumber)`
    width: 65px;
    margin-right: ${variables.gutterXs};
`

export const LabelPostfix = styled.span<{disabled?: boolean}>`
    padding: 0 ${variables.gutterXs};
    color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'auto')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`
