import React from 'react'
import {Link} from 'react-router-dom'

import {StyledLink, StyledText} from './StyledLink'
import {useHistory} from 'react-router'
import {SOURCE} from 'ca-common/common/enum'
import {useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

import {EVENT_NAMES, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'

type LinkProps = {
    to: string
    disabled?: boolean
    source: SOURCE
}

export const SearchLink = ({children, to, disabled, source}: React.PropsWithChildren<LinkProps>): JSX.Element => {
    const history = useHistory()
    const userInfo = useAppSelector(userInfoSelector)
    const isMsEndUser = userInfo?.isMsEndUser
    const redirect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()

        track(EVENT_NAMES.RECOVERY_LINK_TO_OLD_SEARCH_PRESSED, {
            Service: getServiceNameMixpanel(source),
            'Is End User': isMsEndUser
        })

        history.replace(to)
    }
    return (
        <StyledLink disabled={disabled}>
            <Link to={to} onClick={redirect}>
                <StyledText>{children}</StyledText>
            </Link>
        </StyledLink>
    )
}
