import styled from 'styled-components'
import variables from 'ca-common/variables'

export const SFCBox = styled.div`
    border-radius: ${variables.borderRadiusBlocks};
    border: 1px solid ${variables.blue};
    display: flex;
    flex-direction: column;
    height: 90px;
    justify-content: space-around;
    min-width: 240px;
    width: 240px;
    padding: 12px;

    :last-child {
        text-align: right;
    }
`

export const SFCBoxTitle = styled.div`
    font-weight: 600;
`

export const SFCAlias = styled.div`
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
