import React from 'react'
import {useTranslation} from 'react-i18next'

import exchangeIcon from 'ca-common/icons/source/Exchange.svg'
import {TASK_STATUS} from 'ca-common/common/enum'
import {DeepTaskStatus} from 'ca-common/ui-lib/atoms/DeepTaskStatus'

import {MultiSourceProgressBar} from 'src/newcore/molecules/ProgressBar/MultiSourceProgressBar'
import {RecoveryHeaderSection} from 'src/newcore/features/Recovery/atoms/RecoveryHeaderSection'
import {StyledContainer, StyledIcon} from './StyledRecoveryHeader'
import {INFO_TITLES} from 'src/newcore/features/Recovery/lib/constants'
import {MSSelectiveRecoveryProps} from 'src/newcore/features/Recovery/templates'

export const RecoveryHeader = ({entity}: Pick<MSSelectiveRecoveryProps, 'entity'>): JSX.Element => {
    const {t} = useTranslation()

    const inProcess = entity.status === TASK_STATUS.IN_PROCESS

    const titles = [
        INFO_TITLES.USERNAME,
        INFO_TITLES.EMAIL,
        INFO_TITLES.LAST_SUCCESSFUL_BACKUP,
        INFO_TITLES.TOTAL_SIZE,
        INFO_TITLES.BACKUP_STATUS
    ]

    return (
        <StyledContainer>
            <StyledIcon component={exchangeIcon} />
            {titles.map(title => (
                <RecoveryHeaderSection
                    title={t(`recovery:MS365:infoTitles:${title}`)}
                    key={title}
                    loading={!entity.status}
                >
                    {title === INFO_TITLES.BACKUP_STATUS ? (
                        inProcess && entity.progressData ? (
                            <MultiSourceProgressBar progressData={entity.progressData} />
                        ) : (
                            <DeepTaskStatus status={entity.status} />
                        )
                    ) : (
                        (entity[title] as string)
                    )}
                </RecoveryHeaderSection>
            ))}
        </StyledContainer>
    )
}
