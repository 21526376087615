import React from 'react'
import {connect} from 'react-redux'

import {BULK_ACTIVATION_TYPE} from 'ca-common/common/enum/BulkActivation'
import {UploadCSV} from 'src/newcore/components/BulkActivation/CSV'
import {MS365GroupsList} from 'src/newcore/components/BulkActivation/MS365Groups'
import {ActiveDirectory} from 'src/newcore/components/BulkActivation/ActiveDirectory'
import {ConditionsList} from 'src/newcore/components/BulkActivation/SharepointUrl/ConditionsList/ConditionsList'
import {GoogleOUList} from 'src/newcore/components/BulkActivation/GoogleOU/GoogleOUList'
const SecondRaw = props => {
    const {wizard} = props

    const getComponentByFlow = flow => {
        switch (flow) {
            case BULK_ACTIVATION_TYPE.CSV:
                return <UploadCSV />
            case BULK_ACTIVATION_TYPE.GROUPS:
                return <MS365GroupsList />
            case BULK_ACTIVATION_TYPE.ACTIVE_DIRECTORY:
                return <ActiveDirectory />
            case BULK_ACTIVATION_TYPE.SHAREPOINT_URL:
                return <ConditionsList />
            case BULK_ACTIVATION_TYPE.GOOGLE_OU:
                return <GoogleOUList />
            default:
                return null
        }
    }
    return getComponentByFlow(wizard.data.bulkTypeFlow)
}

const mapStateToProps = state => ({
    wizard: state.wizard
})

export const Second = connect(mapStateToProps)(SecondRaw)
