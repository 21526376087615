import {postLessData} from 'src/newcore/utils/rest'
import {createSlice} from '@reduxjs/toolkit'
import moment from 'moment'
import {reduce} from 'lodash'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TODO_ANY} from 'ca-common/types'

const BE_RESPONSE_DATA_FORMAT = 'YYYYMMDD' // 20200112
const UI_DATA_FORMAT = 'DD/MM'
const initial = {date: '', count: 0, sources: {}}
const DAYS = 30
let formattedDataRange: {date: string; count: number; sources: Record<string, any>}[]

const getFormattedDataRange = () => {
    if (!formattedDataRange) {
        const today = moment()

        formattedDataRange = Array(DAYS)
            .fill(initial)
            .map((i, index) => {
                const date = today
                    .clone()
                    .subtract(DAYS - (index + 1), 'days')
                    .format(UI_DATA_FORMAT)
                    .toString()

                return {...i, date}
            })

        return formattedDataRange
    }

    return formattedDataRange
}

const formatDate = (date: string) => {
    return moment(date, BE_RESPONSE_DATA_FORMAT).format(UI_DATA_FORMAT).toString()
}

const countSources = (sources: Record<string, any>) => {
    return reduce(sources, (result, current) => result + current, 0)
}

const formatData = (range: TODO_ANY[], data: TODO_ANY[]) => {
    return reduce(
        data,
        (result, current) => {
            const currentDate = formatDate(current.date)

            return result.map((i: {date: string}) => {
                if (i.date === currentDate) {
                    return {
                        ...i,
                        count: countSources(current.sources),
                        sources: current.sources
                    }
                }

                return i
            })
        },
        range
    )
}

const GET_BACKUP_ENTITY_DELTA = 'GET_BACKUP_ENTITY_DELTA'
const GET_BACKUP_ENTITY_DELTA_API = 'getBackupEntityDelta'
const backupEntityDeltaWrapper = fetchWrapperRT<BackupEntityDeltaResponse>(
    GET_BACKUP_ENTITY_DELTA,
    async data => await postLessData(GET_BACKUP_ENTITY_DELTA_API, data),
    {
        after: response => {
            if (response.data && Array.isArray(response.data)) {
                return {
                    ...response,
                    data: formatData(getFormattedDataRange(), response.data)
                }
            }

            return response
        }
    }
)
export const getBackupEntityDelta = backupEntityDeltaWrapper.fetcher

type BackupEntityDeltaResponse = {data: TODO_ANY[]}

const backupEntityDeltaSlice = createSlice({
    name: 'backupEntityDelta',
    initialState: InitFetchedState as FetchedState<BackupEntityDeltaResponse>,
    reducers: {
        clearBackupEntityDelta: () => InitFetchedState as FetchedState<BackupEntityDeltaResponse>
    },
    extraReducers: builder => backupEntityDeltaWrapper.makeReducers(builder)
})

export const {clearBackupEntityDelta} = backupEntityDeltaSlice.actions
export const backupEntityDelta = backupEntityDeltaSlice.reducer
