import React from 'react'
import {SearchOutlined} from '@ant-design/icons'
import {Form} from 'antd'
import {useTranslation} from 'react-i18next'
import debounce from 'lodash/debounce'
import {hasSomeErrorsAntd4} from 'ca-common/utils/form'

import {StyledForm, SearchInput} from './StyledMS365GroupsForm'

const DEBOUNCE_TIME = 1000
const MIN_LENGTH_TO_SEARCH = 3

export const MS365GroupsForm = props => {
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const formHandler = values => {
        if (!hasSomeErrorsAntd4(form.getFieldsError())) {
            props.onSubmit(values)
        }
    }

    return (
        <StyledForm layout="inline" onValuesChange={debounce(formHandler, DEBOUNCE_TIME)} form={form}>
            <Form.Item
                name="query"
                rules={[
                    {
                        min: MIN_LENGTH_TO_SEARCH,
                        message: t('forms:common:validation:minLength', [MIN_LENGTH_TO_SEARCH])
                    }
                ]}
            >
                <SearchInput
                    prefix={<SearchOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                    placeholder={t('backups:bulkActivation:fields:searchGroup:placeholder')}
                    allowClear
                />
            </Form.Item>
        </StyledForm>
    )
}
