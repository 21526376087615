import React from 'react'
import {Input} from 'antd'
import {useTranslation} from 'react-i18next'

import {useAddressRangeValidator} from 'ca-common/features/IpRestriction/lib/useAddressRangeValidator'
import {FORM_FIELDS} from 'ca-common/features/IpRestriction/lib'

import {
    StyledAddressRange,
    StyledHeader,
    StyledComments,
    StyledFormItem,
    StyledWrapper,
    StyledCol,
    RowWrapper
} from './StyledAddressRange'

type AddressRangeProps = {
    disabled?: boolean
    initialValueFrom?: string
    initialValueTo?: string
}

export const AddressRange = (props: AddressRangeProps): JSX.Element => {
    const {disabled = false, initialValueFrom = '', initialValueTo = ''} = props
    const {t} = useTranslation()

    const addressRangeValidator = useAddressRangeValidator()

    const normalize = (value: string) => value.replace(/[^\d.]+/g, '')

    return (
        <StyledAddressRange>
            <StyledHeader>{t('ipRestrictions:addAddress:range:header')}</StyledHeader>
            <StyledWrapper>
                <StyledCol>
                    <StyledComments>{t('ipRestrictions:addAddress:range:from')}</StyledComments>
                    <RowWrapper>
                        <StyledFormItem
                            initialValue={initialValueFrom}
                            name={FORM_FIELDS.FROM}
                            dependencies={[FORM_FIELDS.TO]}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator: (_, from) =>
                                        addressRangeValidator(from, getFieldValue(FORM_FIELDS.TO), !disabled)
                                })
                            ]}
                            normalize={normalize}
                        >
                            <Input disabled={disabled} />
                        </StyledFormItem>
                    </RowWrapper>
                </StyledCol>
                <StyledCol>
                    <StyledComments>{t('ipRestrictions:addAddress:range:to')}</StyledComments>
                    <StyledFormItem name={FORM_FIELDS.TO} initialValue={initialValueTo} normalize={normalize} help="">
                        <Input disabled={disabled} />
                    </StyledFormItem>
                </StyledCol>
            </StyledWrapper>
            <StyledComments>{t('ipRestrictions:addAddress:range:example')}</StyledComments>
        </StyledAddressRange>
    )
}
