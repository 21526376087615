type GetTabBarItemsParams = {
    tabBarItems: TabBarItem[]
    stepsWithHiddenTabBar: string[]
    hiddenTabBarItems: string[]
    disabledSteps: string[]
    currentStep: string
}

type TabBarItem = {
    key: string
    disabled?: boolean
}

export const getTabBarItems = ({
    stepsWithHiddenTabBar,
    currentStep,
    tabBarItems,
    hiddenTabBarItems,
    disabledSteps
}: GetTabBarItemsParams): TabBarItem[] =>
    stepsWithHiddenTabBar.includes(currentStep)
        ? []
        : tabBarItems.reduce((acc, pane) => {
              if (hiddenTabBarItems.includes(pane.key)) {
                  return acc
              }

              if (disabledSteps.includes(pane.key)) {
                  acc.push({...pane, disabled: true})
              } else {
                  acc.push(pane)
              }
              return acc
          }, [] as TabBarItem[])
