import React from 'react'
import {Tooltip} from 'antd'
import {Trans} from 'react-i18next'
import Icon from '@ant-design/icons'

import Info from 'ca-common/icons/Info.svg'
import variables from 'ca-common/variables'

import {StyledIconWrapper, StyledEmail} from './StyledEmailColumnItem'

type EmailProps = {
    email: string
    isNotactive?: boolean
}

export const EmailColumnItem = (props: EmailProps) => {
    const {email, isNotactive} = props
    return (
        <span>
            <span>{email}</span>
            {isNotactive && (
                <Tooltip
                    title={
                        <Trans i18nKey="userManagement:emailTooltip" tOptions={{email}}>
                            <StyledEmail />
                        </Trans>
                    }
                    overlayInnerStyle={{
                        minWidth: '257px',
                        fontFamily: variables.publicPageFont,
                        fontSize: variables.fontSizeSecondary,
                        fontWeight: 300
                    }}
                >
                    <StyledIconWrapper>
                        <Icon component={Info} />
                    </StyledIconWrapper>
                </Tooltip>
            )}
        </span>
    )
}
