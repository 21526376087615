import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledSecuredCloudBackup = styled.div`
    padding: ${variables.gutterMd} ${variables.gutterXxl};
    background: ${variables.blueLight};
    text-align: center;
    box-shadow: 0px 0px 15px 0px #00000026;
    z-index: 1;

    @media (max-width: ${variables.screenLaptop}) {
        padding: ${variables.gutterXxl} ${variables.gutterMd};
    }

    @media screen and (max-width: ${variables.screenMd}) {
        display: none;
    }
`
export const ServiceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: auto;
    align-items: center;
`
