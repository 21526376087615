import React from 'react'
import {FieldTitle, FieldValue} from './StyledSingleField'

type SingleFieldProps = {
    className?: string
    title: string
    value: string | JSX.Element
    isShortValue?: boolean
}

export const SingleField = (props: SingleFieldProps): JSX.Element => {
    const {value, title, className, isShortValue} = props
    return (
        <div className={className}>
            <FieldTitle>{title}:</FieldTitle>
            <FieldValue data-hj-suppress isShort={isShortValue}>
                {value}
            </FieldValue>
        </div>
    )
}
