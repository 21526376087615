import {i18n} from 'src/locales'

export const MODAL_WIDTH = 1360
export const TOOLTIP_MOUSE_ENTER_DELAY = 0.4
export const DROPDOWN_Z_INDEX = 1001

export enum ANONYMIZATION_MODE_FIELD_FILTER {
    ALL_FIELDS = 'ALL_FIELDS',
    ONLY_ANONYMIZED = 'ONLY_ANONYMIZED',
    NOT_ANONYMIZED = 'NOT_ANONYMIZED'
}

export enum MODE {
    PRE_DEFINED = 'PRE_DEFINED',
    RANDOM = 'RANDOM',
    SKIP = 'SKIP'
}

export enum FIELD_TYPE {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    NAME = 'NAME',
    SALUTATION = 'SALUTATION',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    MIDDLE_NAME = 'MIDDLE_NAME',
    SUFFIX = 'SUFFIX',
    TITLE = 'TITLE',
    COMPANY = 'COMPANY',
    CITY = 'CITY',
    COUNTRY = 'COUNTRY',
    COUNTRY_CODE = 'COUNTRY_CODE',
    STATE = 'STATE',
    STATE_CODE = 'STATE_CODE',
    STREET = 'STREET',
    POSTAL_CODE = 'POSTAL_CODE',
    LATITUDE = 'LATITUDE',
    LONGITUDE = 'LONGITUDE',
    URL = 'URL',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER'
}

export enum DATA_TYPE {
    STRING = 'STRING',
    LOOKUP = 'LOOKUP',
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    PICKLIST = 'PICKLIST',
    DATE = 'DATE',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    URL = 'URL',
    ENCRYPTED_STRING = 'ENCRYPTED_STRING'
}

export const FIELD_FILTER_OPTIONS = Object.values(ANONYMIZATION_MODE_FIELD_FILTER).map(value => ({
    label: i18n.t(`seeding:dataAnonymizationStep:fieldFilterOptions:${value}`),
    value
}))
