import React, {useCallback, useEffect, useState} from 'react'
import {Tooltip} from 'antd'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import {useTranslation} from 'react-i18next'

import {SOURCE, RECOVERY_TYPE} from 'ca-common/common/enum'
import {SwitchBar, SwitchCheckboxGroup} from 'ca-common/ui-lib/molecules/SwitchBar'

import {getDisplayName} from 'src/newcore/utils/sources'
import {InlineErrorMessage} from 'src/newcore/features/RecoveryWizard/atoms/InlineErrorMessage'

import Info from 'ca-common/icons/Info.svg'
import Calendar from 'ca-common/icons/subServices/Calendar.svg'
import Contacts from 'ca-common/icons/subServices/Contacts.svg'
import Emails from 'ca-common/icons/subServices/Emails.svg'
import Notes from 'ca-common/icons/subServices/Notes.svg'
import Tasks from 'ca-common/icons/subServices/Tasks.svg'

import {
    EMAIL_SETTINGS,
    EmailSettingsType,
    FULL_RECOVERY_ERRORS,
    RecoveryErrorType,
    SubsourcesBooleanState
} from 'src/newcore/features/RecoveryWizard/lib'
import {
    IconWrapper,
    InfoIcon,
    StyledNote,
    StyledSubTitle,
    StyledSwitchWrapper,
    StyledWrapper
} from './StyledSubservicesSelection'
import Icon from '@ant-design/icons'

type Props = {
    subServices: SubsourcesBooleanState
    setSubServices: React.Dispatch<React.SetStateAction<SubsourcesBooleanState>>
    settings: EmailSettingsType
    setSettings: React.Dispatch<React.SetStateAction<EmailSettingsType>>
    fullRecoveryOptions: RECOVERY_TYPE
    error?: RecoveryErrorType
}

const checkSubService = (subService: string, error?: RecoveryErrorType) =>
    error && error.sourcesAlreadyExist.includes(subService)

export const SubservicesSelection = ({
    subServices,
    setSubServices,
    settings,
    setSettings,
    fullRecoveryOptions,
    error
}: Props): JSX.Element => {
    const {t} = useTranslation()

    const emailSettingOptions = [
        {
            label: t(`forms:export:fields:${EMAIL_SETTINGS.includeLegalHold}:label`),
            value: EMAIL_SETTINGS.includeLegalHold
        },
        {label: t(`forms:export:fields:${EMAIL_SETTINGS.includeDeleted}:label`), value: EMAIL_SETTINGS.includeDeleted},
        {label: t(`forms:export:fields:${EMAIL_SETTINGS.preferSinglePST}:label`), value: EMAIL_SETTINGS.preferSinglePST}
    ]

    const isExport = fullRecoveryOptions === RECOVERY_TYPE.EXPORT

    const handleSubServiceSwitch = (sub: keyof SubsourcesBooleanState) => {
        setSubServices((prev: SubsourcesBooleanState) => ({...prev, [sub]: !prev[sub]}))
    }

    const handleContactsSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365CONTACTS), [])
    const handleMailsSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365MAIL), [])
    const handleTasksSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365TASKS), [])
    const handleCalendarSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365CALENDAR), [])
    const handleNotesSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365NOTES), [])

    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    useEffect(() => {
        if (!subServices.O365MAIL) {
            setSettings({})
        }
    }, [subServices.O365MAIL])

    return (
        <StyledWrapper>
            <StyledSubTitle>
                {t(`recovery:wizard:subServiceSelection:subtitle`)}
                <Tooltip title={t(`recovery:wizard:subServiceSelection:subtitleTooltip`)}>
                    <InfoIcon component={Info} />
                </Tooltip>
            </StyledSubTitle>
            <StyledSwitchWrapper>
                <SwitchBar
                    excluded={subServices.O365MAIL === undefined}
                    checked={subServices.O365MAIL}
                    error={checkSubService(SOURCE.O365MAIL, error)}
                    icon={
                        <IconWrapper>
                            <Icon component={Emails} style={{fontSize: '24px'}} />
                        </IconWrapper>
                    }
                    name={getDisplayName(SOURCE.O365MAIL)}
                    onChange={handleMailsSwitch}
                    isSettingsOpen={isSettingsOpen}
                    setIsSettingsOpen={setIsSettingsOpen}
                >
                    {isExport ? (
                        <SwitchCheckboxGroup
                            value={Object.keys(settings)}
                            onChange={(e: CheckboxValueType[]) => {
                                setSettings(e.reduce((acc, current) => ({...acc, [current as string]: true}), {}))
                            }}
                            options={emailSettingOptions}
                        />
                    ) : null}
                </SwitchBar>
                <SwitchBar
                    excluded={subServices.O365CONTACTS === undefined}
                    checked={subServices.O365CONTACTS}
                    error={checkSubService(SOURCE.O365CONTACTS, error)}
                    icon={
                        <IconWrapper>
                            <Icon component={Contacts} style={{fontSize: '24px'}} />
                        </IconWrapper>
                    }
                    name={getDisplayName(SOURCE.O365CONTACTS)}
                    onChange={handleContactsSwitch}
                />
                <SwitchBar
                    excluded={subServices.O365TASKS === undefined}
                    checked={subServices.O365TASKS}
                    error={checkSubService(SOURCE.O365TASKS, error)}
                    icon={
                        <IconWrapper>
                            <Icon component={Tasks} style={{fontSize: '24px'}} />
                        </IconWrapper>
                    }
                    name={getDisplayName(SOURCE.O365TASKS)}
                    onChange={handleTasksSwitch}
                />
                <SwitchBar
                    excluded={subServices.O365CALENDAR === undefined}
                    checked={subServices.O365CALENDAR}
                    error={checkSubService(SOURCE.O365CALENDAR, error)}
                    icon={
                        <IconWrapper>
                            <Icon component={Calendar} style={{fontSize: '24px'}} />
                        </IconWrapper>
                    }
                    name={getDisplayName(SOURCE.O365CALENDAR)}
                    onChange={handleCalendarSwitch}
                />
                <SwitchBar
                    excluded={subServices.O365NOTES === undefined}
                    checked={subServices.O365NOTES}
                    error={checkSubService(SOURCE.O365NOTES, error)}
                    icon={
                        <IconWrapper>
                            <Icon component={Notes} style={{fontSize: '24px'}} />
                        </IconWrapper>
                    }
                    name={getDisplayName(SOURCE.O365NOTES)}
                    onChange={handleNotesSwitch}
                />
            </StyledSwitchWrapper>
            {isExport && <StyledNote>{t(`recovery:wizard:subServiceSelection:exportFormatNote`)}</StyledNote>}
            {error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.SUB_TASKS_ALREADY_EXIST &&
                Boolean(error?.sourcesAlreadyExist.length) && <InlineErrorMessage status={error.status} />}
        </StyledWrapper>
    )
}
