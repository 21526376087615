import React from 'react'
import {useTranslation} from 'react-i18next'

import SelectAllIcon from 'ca-common/icons/Apply.svg'
import ClearSelectedIcon from 'ca-common/icons/Delete.svg'
import DropDownIcon from 'ca-common/icons/backupActionsNew/actionDropdown.svg'

import {SELECTION_MENU_ACTION_NAME} from 'src/newcore/features/Recovery/lib/constants'
import {
    StyledMenu,
    StyledMenuItem,
    StyledIcon,
    StyledText,
    StyledKebabIcon,
    StyledKebabButton,
    StyledDropDown
} from './StyledControls'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'

type ControlsProps = {
    selection: CHECK_STATE
    onSelectAll: () => void
    onClearSelected: () => void
    disabled: boolean
}

export const Controls = ({selection, onSelectAll, onClearSelected, disabled}: ControlsProps): JSX.Element => {
    const {t} = useTranslation()
    const menu = (
        <StyledMenu disabled={disabled}>
            <StyledMenuItem
                key="0"
                disabled={selection === CHECK_STATE.CHECKED}
                selectionActionName={SELECTION_MENU_ACTION_NAME.SELECT_ALL}
                onClick={onSelectAll}
            >
                <StyledIcon component={SelectAllIcon} />
                <StyledText>{t('recovery:MS365:selectionActions:selectAll')}</StyledText>
            </StyledMenuItem>
            <StyledMenuItem
                key="1"
                disabled={selection === CHECK_STATE.UNCHECKED}
                selectionActionName={SELECTION_MENU_ACTION_NAME.CLEAR_SELECTED}
                onClick={onClearSelected}
            >
                <StyledIcon component={ClearSelectedIcon} />
                <StyledText>{t('recovery:MS365:selectionActions:clearSelected')}</StyledText>
            </StyledMenuItem>
        </StyledMenu>
    )
    return (
        <div onClick={e => e.stopPropagation()}>
            <StyledDropDown disabled={disabled} overlay={menu} trigger={['click']} placement="bottom">
                <StyledKebabButton onClick={e => e.stopPropagation()}>
                    <StyledKebabIcon component={DropDownIcon} />
                </StyledKebabButton>
            </StyledDropDown>
        </div>
    )
}
