import styled from 'styled-components'
import Icon from '@ant-design/icons'

export const ConnectIcon = styled(Icon)`
    font-size: 20px;

    svg {
        fill: none;
    }
`
