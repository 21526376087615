import {FORM_FIELDS, RESTRICTION_RULES, parseAddressesToBE} from 'ca-common/features/IpRestriction/lib'
import {AddIpFormFields} from 'ca-common/features/IpRestriction/types'

export const prepareData = (values: Partial<AddIpFormFields>) => {
    if (values.type === RESTRICTION_RULES.RANGE) {
        return {
            ...values,
            addresses: null,
            range: {from: values[FORM_FIELDS.FROM], to: values[FORM_FIELDS.TO]}
        }
    }

    if (values.type === RESTRICTION_RULES.LIST) {
        return {
            ...values,
            addresses: parseAddressesToBE(values[FORM_FIELDS.ADDRESSES])
        }
    }

    return values
}
