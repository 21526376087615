import axios, {AxiosResponse} from 'axios'
import isString from 'lodash/isString'

import {makeHeaders} from 'ca-common/utils/restHeader'

import {callReload, logout} from 'src/newcore/utils/logout'

const APPLICATION_PATH = '/application/service/'

const ASYNC_STATUS = {
    WAIT: 'WAIT',
    OK: 'OK',
    ERROR: 'ERROR'
}

const ASYNC_DELAY = 1500

export function setUpHeaders(config: Record<string, any> = {}) {
    if (!config.headers) {
        config.headers = {}
    }

    if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json; charset=utf-8'
    }

    config.headers = makeHeaders(config.headers)

    return config
}

export async function post<Response = any, Request = any>(commandName: string, data: Request, config = {}) {
    return (
        await appInstance.post<any>(APPLICATION_PATH + commandName, JSON.stringify(data), {
            ...setUpHeaders({withCredentials: true}),
            ...config
        })
    ).data.data as Response
}

export async function postLessData<Response = any, Request = any>(commandName: string, data: Request, config = {}) {
    return (
        await appInstance.post(APPLICATION_PATH + commandName, JSON.stringify(data), {
            ...setUpHeaders({withCredentials: true}),
            ...config
        })
    ).data as Response
}

export async function postMultipart<Response = any>(commandName: string, {formData = new FormData(), json = {}} = {}) {
    formData.append('json', JSON.stringify(json))

    const path = APPLICATION_PATH + commandName
    const axiosConfig = setUpHeaders({
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    return (await appInstance.post(path, formData, axiosConfig)).data as Response
}

export async function get<Response = any>(commandName: string, data: Record<string, any>) {
    const CONFIG = setUpHeaders({withCredentials: true})
    CONFIG.params = data

    return (await appInstance.get<any>(APPLICATION_PATH + commandName, CONFIG)).data.data as Response
}

export async function getLessData<Response = any>(commandName: string, data: Record<string, any>) {
    const CONFIG = setUpHeaders({withCredentials: true})
    CONFIG.params = data

    return (await appInstance.get<any>(APPLICATION_PATH + commandName, CONFIG)).data as Response
}

export async function getPlain<Response = string>(commandName: string, data: Record<string, any>) {
    const CONFIG = setUpHeaders({withCredentials: true})
    CONFIG.params = data
    const result = (await axios.get(APPLICATION_PATH + commandName, CONFIG)).data as Response

    if (isString(result)) {
        return result
    }

    return Promise.reject(result)
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const appInstance = axios.create()

const fixResponseIfWaiting = async (response: AxiosResponse<any>): Promise<AxiosResponse<any>> => {
    const {config, data} = response

    if (data?.result === ASYNC_STATUS.WAIT) {
        config.params = {reqId: data.id}

        return await fixResponseIfWaiting(await delay(ASYNC_DELAY).then(() => axios(config)))
    }

    return response
}

appInstance.interceptors.response.use(
    async (response: AxiosResponse<any>) => {
        const result = await fixResponseIfWaiting(response)

        if (result.data.success === true) {
            return result
        }

        return Promise.reject(result.data)
    },
    error => {
        if (error?.response?.status === 401) {
            logout()
        }

        if (error?.response?.status === 403) {
            callReload()
        }

        return Promise.reject(error.response)
    }
)
