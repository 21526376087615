import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {post, getLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const RESTORE_DOWNLOAD_STATUS = 'RESTORE_DOWNLOAD_STATUS'
const RESTORE_DOWNLOAD_STATUS_PREVIEW = 'RESTORE_DOWNLOAD_STATUS_PREVIEW'
const CANCEL_TASK = 'CANCEL_TASK'
const GET_DOWNLOAD_URL = 'GET_DOWNLOAD_URL'

const getRestoreDownloadStatusWrapper = fetchWrapperRT(
    RESTORE_DOWNLOAD_STATUS,
    async data => await post('getRestoreDownloadStatus', data)
)
export const getRestoreDownloadStatus = getRestoreDownloadStatusWrapper.fetcher

const recoveriesSlice = createSlice({
    name: 'recoveries',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getRestoreDownloadStatusWrapper.makeReducers(builder)
})

// need for separate reducers to display preview and full recoveries lists
const getRestoreDownloadStatusPreviewWrapper = fetchWrapperRT(
    RESTORE_DOWNLOAD_STATUS_PREVIEW,
    async data => await post('getRestoreDownloadStatus', data)
)
export const getRestoreDownloadStatusPreview = getRestoreDownloadStatusPreviewWrapper.fetcher

const getCancelTaskWrapper = fetchWrapperRT(CANCEL_TASK, async data => await post('cancelTask', data))
export const cancelTask = getCancelTaskWrapper.fetcher

const getDownloadUrlWrapper = fetchWrapperRT(GET_DOWNLOAD_URL, async (data, {rejectWithValue}) => {
    try {
        const response = await getLessData('getDownloadUrl', data)

        if (!response.success) {
            return rejectWithValue(response)
        }

        return response
    } catch (e) {
        return rejectWithValue(e)
    }
})
export const getDownloadUrl = getDownloadUrlWrapper.fetcher

const recoveriesPreviewSlice = createSlice({
    name: 'recoveriesPreview',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getRestoreDownloadStatusPreviewWrapper.makeReducers(builder)
})

export const recoveriesReducer = combineReducers({
    recoveries: recoveriesSlice.reducer,
    recoveriesPreview: recoveriesPreviewSlice.reducer
})
