import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledErrors = styled.div`
    color: ${variables.red};
    text-align: center;
    margin: 0 -${variables.gutterMd};

    @media screen and (min-width: ${variables.screenMd}) {
        width: 508px;
    }
`
