import styled from 'styled-components'

export const SecurityAuditWrapper = styled.div`
    .ant-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .ca-btn--secondary {
            height: 24px;
            margin-top: 4px;
        }
    }
`
