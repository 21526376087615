import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {SubmissionError} from 'redux-form'

import {PAGES} from 'ca-common/constants'
import {NotificationForm} from 'ca-common/ui-lib/components/Notifications/NotificationForm'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {newUsersNotification} from 'src/newcore/redux/modules/notifications'
import {useAppDispatch} from 'src/newcore/components/Root'

export const NewNotificationPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()

    const submitForm = (values: any) => {
        return dispatch(newUsersNotification(values))
            .unwrap()
            .then(() => {
                openSuccessNotification(t('notifications:emailAdded'))
                history.push(`/${PAGES.SETTINGS}/${PAGES.NOTIFICATIONS}`)
            })
            .catch((err: {status: string}) => {
                if (err.status === 'Notification user exists in the notification list') {
                    throw new SubmissionError({
                        email: err.status
                    })
                } else {
                    openErrorModal(`${t('notifications:addFailed')}`)
                }
            })
    }
    return <NotificationForm isPortal={true} onSubmit={submitForm} isLoaded={true} initialValues={{}} />
}
