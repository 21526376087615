import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledPageStatus} from './StyledPageStatus'

type PageStatusProps = {
    status: string
    title: string
    message?: string
    titleParameters?: Record<string, any>
    data?: Record<string, any>
}

export const PageStatus = (props: PageStatusProps) => {
    const {status, message, titleParameters, title, data} = props
    const {t} = useTranslation()

    const lowerCasedStatus = status.toLowerCase()

    return (
        <StyledPageStatus status={lowerCasedStatus} titleParameters={titleParameters}>
            {title == 'trialExpired' && status == 'nobill' && titleParameters != undefined ? (
                <div>
                    <p>{t(`pageStatus:trialDaysLeft`, titleParameters)}</p>
                    <span>{t(`pageStatus:trialAccountDeletion`, data)}</span>
                </div>
            ) : (
                <p>{t(`pageStatus:${title}`, titleParameters)}</p>
            )}
            {!!message && <p>{message}</p>}
        </StyledPageStatus>
    )
}
