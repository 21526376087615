import React, {useState, useEffect} from 'react'

import {getColumns} from './ActivityTableConfig'
import {ActivityFilters} from 'src/newcore/features/ActivityTable/organisms/ActivityFilters'
import {FieldValues} from 'src/newcore/features/ActivityTable/types/fieldValues'
import {ActivityInfinityTable, AccountActivityResponse} from 'ca-common/features/ActivityInfinityTable'
import {FetchedState} from 'ca-common/utils/fetchWrapperRT'

type ActivityTableProps = {
    activity: FetchedState<AccountActivityResponse>
    submitExport: (queryParams: FieldValues) => () => void
    fetchActivity: (queryParams: FieldValues, newPage?: number) => void
    isLastPage: boolean
}

const TABLE_X_SCROLL_AFTER = 1080

export const ActivityTable = ({activity, submitExport, fetchActivity, isLastPage}: ActivityTableProps): JSX.Element => {
    const columns = getColumns()
    const [queryParams, setQueryParams] = useState<FieldValues | undefined>(undefined)

    const appendActivity = () => {
        if (!isLastPage && activity.status !== 'pending') {
            fetchActivity(queryParams as FieldValues)
        }
    }

    useEffect(() => {
        if (queryParams === undefined) return
        fetchActivity(queryParams, 1)
    }, [queryParams])

    return (
        <>
            <ActivityFilters
                loading={activity.status === 'pending'}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                fetchActivity={fetchActivity}
                submitExport={submitExport}
            />
            <ActivityInfinityTable
                fetchOnScroll={appendActivity}
                columns={columns}
                dataSource={activity.response?.data || []}
                loading={activity.status === 'pending'}
                scrollAfterX={TABLE_X_SCROLL_AFTER}
            />
        </>
    )
}
