import React from 'react'
import i18n from 'i18next'
import {ColumnProps} from 'antd/es/table'

import {TODO_ANY} from 'ca-common/types'
import {DownloadButton} from 'src/newcore/features/Jobs/atoms'

export const columns = (
    onDownloadHandler: (arg0: {taskId: string}) => Record<string, TODO_ANY>
): ColumnProps<TODO_ANY>[] => [
    {
        title: i18n.t('JobsPage:downloadTable:columns:fileName'),
        dataIndex: 'fileName',
        sorter: (a, b) => a.fileName.localeCompare(b.fileName, undefined, {numeric: true}),
        width: '70%',
        render: fileName => <span>{fileName}</span>,
        className: 'file-name'
    },
    {
        title: i18n.t('JobsPage:downloadTable:columns:size'),
        dataIndex: 'size',
        width: '20%',
        sorter: (a, b) => a.size - b.size,
        render: size => <span>{size}</span>
    },
    {
        title: i18n.t('JobsPage:downloadTable:columns:actions'),
        dataIndex: 'downloadLink',
        align: 'center',
        render: (text, record) => <DownloadButton onDownloadHandler={onDownloadHandler} record={record} />
    }
]
