import React from 'react'
import {RadioChangeEvent, Radio, Tooltip, Space} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import Info from 'ca-common/icons/Info.svg'

import {ENTITIES_OPTION, SETTINGS_OPTION} from 'src/newcore/features/OneClickActivation/lib/constants'
import {RadioGroupWrapper, FieldTitle} from './StyledRadioGroup'

type RadioGroupFieldProps = {
    fieldName: string
    options: {value: string; tooltip?: JSX.Element | string; disabled?: boolean}[]
    value?: ENTITIES_OPTION | SETTINGS_OPTION
    onChange?: (e: RadioChangeEvent) => void
}

export const RadioGroupField = ({fieldName, options, value, onChange}: RadioGroupFieldProps) => {
    const {t} = useTranslation()

    return (
        <RadioGroupWrapper value={value} onChange={onChange}>
            <FieldTitle>{fieldName}</FieldTitle>
            {options.map(({value, tooltip, disabled}) => (
                <Radio key={value} value={value} disabled={disabled}>
                    <Space size={4}>
                        <span>{t(`oneClickActivation:wizard:options:${value}`)}</span>
                        {tooltip && (
                            <Tooltip overlayInnerStyle={{width: 'max-content'}} title={tooltip}>
                                <Icon component={Info} />
                            </Tooltip>
                        )}
                    </Space>
                </Radio>
            ))}
        </RadioGroupWrapper>
    )
}
