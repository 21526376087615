import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {Progress} from 'antd'
import {useTranslation} from 'react-i18next'

import variables from 'ca-common/variables'
import {useTimer} from 'ca-common/utils/useTimer'

import {
    changeConfig,
    changeData,
    nextStep,
    setWizardDescription,
    setWizardTitle,
    wizardConfigSelector,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

import {useCreateOCATasksQuery} from 'src/newcore/features/OneClickActivation/redux'
import {STEPS, WAITING_DURATION} from 'src/newcore/features/OneClickActivation/lib/constants'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'

import {OCAProcessingStepCommon} from 'src/newcore/features/OneClickActivation/orgamisms'
import {Footer} from 'src/newcore/features/OneClickActivation/molecules'

import {ContentWrapper, LoadingText} from './StyledOCAInitProcessingStep'

export const OCAInitProcessingStep = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const wizardConfig = useAppSelector(wizardConfigSelector)
    const {data} = useCreateOCATasksQuery(wizardData.stateId, {
        skip: !wizardData.isWaitingOver
    })
    const {timer, runTimer} = useTimer(WAITING_DURATION)
    const [isTimerStarted, setIsTimerStarted] = useState(false)

    const onCancel = () => {
        const data = {
            'Loading Phase': 'Timeout Progress Bar'
        } as Record<string, any>

        if (wizardData.firstProcessingStartedAt) {
            data['Loading Page Duration'] = (Date.now() - wizardData.firstProcessingStartedAt) / 1000
        }

        track(EVENT_NAMES.OCA_CANCEL_LOADING_PAGE, data)
    }

    useEffect(() => {
        if (data) {
            const disabledSteps = [STEPS.INIT_PROCESSING]

            if (!data.existedTasks?.length) {
                disabledSteps.push(STEPS.TASKS_ALREADY_EXIST)
            }

            if (!data.recentlyDeletedTasks?.length) {
                disabledSteps.push(STEPS.RECENTLY_DELETED_TASKS)
            }

            dispatch(changeConfig({disabledSteps: _.union(wizardConfig.disabledSteps, disabledSteps)}))
            dispatch(
                changeData({
                    email: data.email,
                    id: data.id
                })
            )
            dispatch(nextStep())
        }
    }, [data])

    useEffect(() => {
        dispatch(setWizardTitle(''))
        dispatch(setWizardDescription(''))
        dispatch(changeConfig({modalWidth: 400}))
        runTimer()
        setIsTimerStarted(true)

        if (!wizardData.firstProcessingStartedAt) {
            dispatch(changeData({firstProcessingStartedAt: Date.now()}))
        }

        return () => {
            dispatch(changeConfig({modalWidth: null}))
        }
    }, [])

    useEffect(() => {
        if (timer <= 0 && !wizardData.isWaitingOver && isTimerStarted) {
            dispatch(changeData({isWaitingOver: true}))
        }
    }, [timer, wizardData.isWaitingOver])

    const percent = Math.floor(((WAITING_DURATION - timer) * 100) / WAITING_DURATION)

    if (wizardData.isWaitingOver) {
        return <OCAProcessingStepCommon />
    }

    return (
        <div>
            <ContentWrapper>
                <Progress type="circle" percent={percent} strokeColor={variables.royalBlue} />
                <LoadingText>
                    {timer > WAITING_DURATION / 2
                        ? t('oneClickActivation:wizard:loadingText1')
                        : t('oneClickActivation:wizard:loadingText2')}
                </LoadingText>
            </ContentWrapper>
            <Footer hideNextButton onCancel={onCancel} />
        </div>
    )
}
