import styled from 'styled-components'
import {Modal} from 'antd'

import variables from 'ca-common/variables'

export const StyledText = styled.span`
    font-weight: 300;
    font-size: ${variables.fontSizeSecondary};
    font-family: ${variables.publicPageFont};
    color: ${variables.text};
    white-space: nowrap;
`

export const StyledClickableText = styled(StyledText)`
    text-decoration: underline;
    cursor: pointer;
`

export const StyledTimer = styled.span`
    font-weight: 500;
`

export const ModalHeader = styled.div`
    font-size: ${variables.headingFontSize};
    font-weight: 400;
    font-family: ${variables.publicPageFont};
`

export const StyledWrapper = styled.div`
    white-space: nowrap;
`

export const StyledFormWrapper = styled.div`
    min-height: 168px;
    padding: ${variables.gutterMd};
    padding-bottom: 0;

    .ant-form-item-required::before {
        display: none !important;
    }
`

export const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`
