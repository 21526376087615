import {ZENDESK_LOGIN} from 'ca-common/constants'

import {useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

export const SupportRedirect = () => {
    const companyInfo = useAppSelector(companyInfoSelector)

    if (!companyInfo.whiteLable) {
        window.location.href = ZENDESK_LOGIN
        return null
    } else if (companyInfo.supportLink) {
        window.location.href = companyInfo.supportLink
        return null
    }

    return null
}
