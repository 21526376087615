import React from 'react'
import {useTranslation} from 'react-i18next'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {DROPBOX_TYPE, SOURCE_INFO} from 'ca-common/common/enum'

import {renderValueForNextBackup, useActiveEntityTooltip} from 'src/newcore/features/Homepage/utils'
import {Task} from 'src/newcore/features/Homepage/types'
import {EntityRow} from 'src/newcore/features/Homepage/atoms/Row'

import {Content, EntitiesRawWrapper, SingleEntityWrapper} from './StyledBackupContent'

export const BackupContent = ({task}: Task): JSX.Element => {
    const {t} = useTranslation()

    const {
        nextBackup,
        status,
        progress,
        isDropboxProfessionalAccount,
        lastBackup,
        size,
        subAccountsCount,
        source,
        subActiveAccountsCount
    } = task

    const nextBackupValue = renderValueForNextBackup(progress, status, nextBackup)

    const entityName = getSvcAccountsDescription(
        isDropboxProfessionalAccount ? DROPBOX_TYPE.DROPBOX_PROFESSIONAL : source,
        true
    )

    const tooltipText = useActiveEntityTooltip({source, subActiveAccountsCount, subAccountsCount})

    return (
        <Content>
            <EntitiesRawWrapper data-inlinemanual="InlineManual-homepage-overview">
                <SingleEntityWrapper>
                    {!isDropboxProfessionalAccount && (
                        <EntityRow
                            name={t(`homepage:rowName:entityTotal`, {entity: entityName})}
                            value={subAccountsCount}
                        />
                    )}
                    {!isDropboxProfessionalAccount && SOURCE_INFO[source].hasSubAccounts && (
                        <EntityRow
                            name={t(`homepage:rowName:entityActive`, {entity: entityName})}
                            value={subActiveAccountsCount}
                            tooltipText={tooltipText}
                        />
                    )}
                </SingleEntityWrapper>
            </EntitiesRawWrapper>
            <EntitiesRawWrapper data-inlinemanual="InlineManual-homepage-dataSize">
                <EntityRow name={t(`homepage:rowName:totalSize`)} value={size} />
            </EntitiesRawWrapper>
            <EntitiesRawWrapper data-inlinemanual="InlineManual-homepage-backupSchedule">
                <EntityRow name={t(`homepage:rowName:lastSnapshot`)} value={lastBackup} />
                <EntityRow name={t(`homepage:rowName:nextBackup`)} value={nextBackupValue} />
            </EntitiesRawWrapper>
        </Content>
    )
}
