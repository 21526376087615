import React from 'react'
import i18n from 'i18next'
import moment from 'moment'
import {Table} from 'antd'

import {DATE_WITH_AT_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {StyledFromSpan} from 'src/newcore/features/Recovery/molecules/RecoveryItemsTable'
import {getTaskStatusFriendlyName} from 'src/newcore/features/Recovery/lib/getTaskStatusFriendlyName'
import {MS365TASK_STATUS} from 'src/newcore/features/Recovery/lib/constants'

export const tasksColumns = [
    {
        title: i18n.t('table:columns:subject'),
        dataIndex: 'subject',
        key: 'taskSubject',
        width: '40%',
        ellipsis: true,
        render: (text: string): JSX.Element => <StyledFromSpan data-hj-suppress>{text}</StyledFromSpan>
    },
    {
        title: i18n.t('table:columns:status'),
        dataIndex: 'status',
        key: 'taskStatus',
        width: '15%',
        render: (status: MS365TASK_STATUS): JSX.Element => getTaskStatusFriendlyName(status)
    },
    {
        title: i18n.t('table:columns:dueDate'),
        dataIndex: 'dueDate',
        key: 'taskDueDate',
        width: '20%',
        render: (text: moment.MomentInput): string =>
            text ? getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT) : ''
    },
    {
        title: i18n.t('table:columns:updated'),
        dataIndex: 'updateDate',
        key: 'taskUpdateDate',
        width: '20%',
        sorter: true,
        render: (text: moment.MomentInput): string => getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT)
    },
    Table.EXPAND_COLUMN
]
