import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledWrapped = styled.section`
    background: ${variables.white};
    border-radius: ${variables.btnBorderRadiusBase};
    border: 0.5px solid ${variables.navyBorder};
    display: flex;
    flex-direction: column;
`

export {Number} from 'src/newcore/atoms'
