import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router'
import {useTranslation, Trans} from 'react-i18next'

import {TASK_STATUS} from 'ca-common/common/enum/TaskStatus'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {PAGES} from 'ca-common/constants'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {openErrorModal} from 'ca-common/utils/modals'
import {SOURCE} from 'ca-common/common/enum'

import {AppState, useAppDispatch} from 'src/newcore/components/Root'
import {performAction} from 'src/newcore/redux/modules/backups/backupActions'
import {getNewBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

import {BackupActivate, BackupCancel, BackupPause, BackupNow, BackupDelete} from '../BackupActions'
import {isBackupPermitted} from '../../utils/permissions'
import {StyledBackupActions} from './StyledBackupActions'

type SingleBackupActionsProps = {
    performEntities: Array<string>
    taskInfo: {
        status: TASK_STATUS
        cached: boolean
    }
    source: SOURCE
    account: string
    name: string
} & ReturnType<typeof mapStateToProps>

const SingleBackupActionsRaw = ({name, account, taskInfo, userInfo, source, companyName}: SingleBackupActionsProps) => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const {permissions, isSubscribed, trialDaysLeft} = userInfo.response
    const {t} = useTranslation()
    const match = useRouteMatch<any>()
    const history = useHistory()
    const [disabled, setDisabled] = useState(false)
    const dispatch = useAppDispatch()

    const handlePerformBackupTaskAction = (action: TASK_ACTION) => {
        const taskId = match.params.taskId

        const performActionRequest = {
            action,
            taskId
        }

        setDisabled(true)

        dispatch(performAction(performActionRequest))
            .then(() => {
                if (action === TASK_ACTION.DELETE) {
                    if (!taskInfo.cached) {
                        openSuccessNotification(
                            <Trans i18nKey={'backups:modal:delete:gracePeriodNotification:text'} />,
                            t('backups:modal:delete:gracePeriodNotification:title'),
                            0
                        )
                    }

                    history.push(`/${PAGES.BACKUPS}`)
                } else {
                    openSuccessNotification(t(`backups:backupActions:${action}:responseSuccess`))
                    dispatch(
                        getNewBackupTaskSettings({
                            taskId: match.params.taskId
                        })
                    )
                }
            })
            .catch(() => openErrorNotification(t(`backups:backupActions:${action}:responseFailed`)))
            .finally(() => setDisabled(false))
    }
    const openSubscribeErrorModal = () => {
        openErrorModal(t('backups:unsubscribedErrorMessage', {companyName}))
    }

    return (
        <StyledBackupActions disabled={disabled}>
            {(() => {
                switch (taskInfo.status) {
                    case TASK_STATUS.NOT_ACTIVE:
                    case TASK_STATUS.PAUSED:
                        return (
                            isBackupPermitted(`${source}.PAUSE`, permissions) && (
                                <BackupActivate handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                            )
                        )
                    case TASK_STATUS.IN_PROCESS:
                        return (
                            isBackupPermitted(`${source}.CANCEL`, permissions) && (
                                <BackupCancel handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                            )
                        )
                    case TASK_STATUS.SCHEDULED:
                    case TASK_STATUS.SUCCESS:
                    case TASK_STATUS.FAILED:
                    case TASK_STATUS.PARTIAL:
                        return (
                            <>
                                {!taskInfo.cached && isBackupPermitted(`${source}.PAUSE`, permissions) && (
                                    <BackupPause handlePerformBackupTaskAction={handlePerformBackupTaskAction} />
                                )}
                                {!taskInfo.cached &&
                                    isBackupPermitted(`${source}.EXECUTE`, permissions) &&
                                    (isSubscribed || trialDaysLeft > 0 ? (
                                        <BackupNow
                                            handlePerformBackupTaskAction={() =>
                                                handlePerformBackupTaskAction(TASK_ACTION.RUN)
                                            }
                                        />
                                    ) : (
                                        <BackupNow greyedOut handlePerformBackupTaskAction={openSubscribeErrorModal} />
                                    ))}
                            </>
                        )
                    default:
                        return null
                }
            })()}
            {isBackupPermitted(`${source}.DELETE`, permissions) && (
                <BackupDelete
                    handlePerformBackupTaskAction={handlePerformBackupTaskAction}
                    account={account}
                    name={name}
                />
            )}
        </StyledBackupActions>
    )
}

const mapStateToProps = (state: AppState) => ({
    entities: state.backups.backupEntities.entities.response,
    userInfo: state.userInfo,
    companyName: state.companyInfo.status === 'fulfilled' ? state.companyInfo.response.name : ''
})

export const SingleBackupActions = connect(mapStateToProps)(SingleBackupActionsRaw)
