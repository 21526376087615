import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledText = styled.span`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
    text-decoration: underline;
    line-height: 32px;
    color: ${variables.navyBlue};
`

export const StyledLink = styled.div<{disabled?: boolean}>`
    pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
`
