import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'

import variables from 'ca-common/variables'
import {SOURCE, TASK_TYPE} from 'ca-common/common/enum'

import {getActivityFriendly} from 'src/newcore/features/Jobs/lib'
import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'

import {StyledDownloadElement} from './StyledDownloadReportButton'

type DownloadElementProps = {
    downloadIsExpired: boolean
    linkTo: string
    isReport: boolean
    permitted: boolean
    taskType: TASK_TYPE
    source: SOURCE
}

export const DownloadElement = (props: DownloadElementProps): JSX.Element => {
    const {isReport, downloadIsExpired, linkTo, permitted, taskType, source} = props
    const {t} = useTranslation()

    const tooltipMessage = useMemo(() => {
        if (!permitted) {
            return t('JobsPage:downloadTable:downloadTooltip:permitted')
        }

        if (downloadIsExpired) {
            return t('JobsPage:downloadTable:downloadTooltip:expired')
        }

        if (isReport) {
            return t('JobsPage:buttons:downloadReport')
        } else if (!isReport) {
            return t('JobsPage:buttons:downloadResults')
        }

        return undefined
    }, [downloadIsExpired, isReport, permitted])

    const sendAnalytics = () => {
        track('Download Job Results', {
            Activity: getActivityFriendly(taskType),
            Service: getServiceNameMixpanel(source)
        })
    }

    return (
        <Tooltip title={tooltipMessage}>
            <div>
                <StyledDownloadElement
                    color={variables.navyBlue}
                    type="primary"
                    disabled={!permitted || downloadIsExpired}
                    onClick={sendAnalytics}
                    href={linkTo}
                >
                    {t(isReport ? 'JobsPage:buttons:downloadReport' : 'JobsPage:buttons:downloadResults')}
                </StyledDownloadElement>
            </div>
        </Tooltip>
    )
}
