import moment from 'moment'
import resMgr from '../../infra/resourceManager'
import i18n from 'i18next'

export const DATE_FORMAT = 'MMM DD YYYY'
export const BILLING_DATE_FORMAT = 'DD/MM/YYYY'
export const BILLING_PAGE_DATE_FORMAT = 'll'
export const STANDARD_DATE_FORMAT = 'LL'
export const NEXT_PAYMENT_DATE = 'MMM DD, YYYY'
export const LONG_DATE_FORMAT = 'YYYYMMDDHHmmss'
export const FULL_FORMAT = 'DD/MM/YY hh:mm:ss A'
export const FULL_FORMAT_NEW = 'MMM DD YYYY hh:mm:ss A'
export const ALTERNATIVE_FULL_FORMAT = 'hh:mm A MMM DD YYYY'
export const COMPARE_FORMAT = 'DD/MM/YYYY [at] hh:mm A'
export const DATE_WITH_AT_FORMAT = 'MMM DD, YYYY [at] hh:mm A'
export const COMPARE_FORMAT_NEW = 'MMM DD YYYY [at] hh:mm A'
export const TIME_FORMAT = 'hh:mm:ss A'
export const DAY_MONTH_FORMAT = 'YYYYMMDD'
export const DATE_FORMAT_RECOVERY = 'MMM DD YYYY'
export const TIME_FORMAT_RECOVERY = 'hh:mm A'

export function getUTCDateFromString(inputDate: number): Date | '' {
    if (!inputDate) {
        return ''
    }
    let res = inputDate

    function mod() {
        const sec = res % 100
        res = (res - sec) / 100
        return sec
    }

    const sec = mod()
    const min = mod()
    const hours = mod()
    const day = mod()
    const month = mod()
    const year = res

    return new Date(Date.UTC(year, month - 1, day, hours, min, sec))
}

export function formatUTCDateTime(inputDate?: number): string {
    if (!inputDate) {
        return ''
    }

    const date = getUTCDateFromString(inputDate) as Date

    return `${resMgr.strings.months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${getFormattedTime(
        date.getHours(),
        date.getMinutes()
    )}`
}

export function getFormattedTime(hours: number, minutes: number): string | undefined {
    if (hours >= 24 || hours < 0 || minutes >= 60 || minutes < 0) {
        return undefined
    }

    let hour = hours % 12
    hour = hour === 0 ? 12 : hour
    const _hours = twoDigit(hour)
    const _minutes = twoDigit(minutes)
    return `${_hours}:${_minutes} ${hours >= 12 ? 'PM' : 'AM'}`
}

export function twoDigit(value: number): string | undefined {
    if (value < 0 || value >= 100) {
        return undefined
    }
    return (value < 10 ? '0' : '') + value
}

export function convertUnixToDate(ms: number, format: string): string | undefined {
    return moment.unix(ms / 1000).format(format)
}

type DaysOption = {
    value: BACKUP_FREQUENCY
    label: string
    disabled?: boolean
    tooltip?: string
}

export enum BACKUP_FREQUENCY {
    DAILY = 'DAILY',
    EVERY3DAYS = 'EVERY3DAYS',
    WEEKLY = 'WEEKLY',
    DAILY3TIMES = 'DAILY3TIMES'
}

export const getOptionsOfFrequency = (isHighFrequencyDisabled?: boolean): DaysOption[] => [
    {value: BACKUP_FREQUENCY.DAILY, label: i18n.t('frequency:daily')},
    {value: BACKUP_FREQUENCY.EVERY3DAYS, label: i18n.t('frequency:every3')},
    {value: BACKUP_FREQUENCY.WEEKLY, label: i18n.t('frequency:weekly')},
    {
        value: BACKUP_FREQUENCY.DAILY3TIMES,
        label: i18n.t('frequency:threeTimesADay'),
        disabled: isHighFrequencyDisabled,
        tooltip: i18n.t('forms:backupSettings:fields:backupFrequency:daily3TimesTooltip')
    }
]

type HourOption = {
    value: string
    label: string
}

export const getOptionsOfHours = (tripleBackup: boolean): HourOption[] =>
    [...Array(24).keys()].map(hour => ({
        value: hour.toString(),
        label: tripleBackup ? `${hour}:00; ${(hour + 8) % 24}:00; ${(hour + 16) % 24}:00` : `${hour}:00`
    }))

export const dateFormat = (date: number, format = 'YYYY-MM-DD'): string | undefined => moment(date).format(format)

export const getTodayDay = (format = 'MMMM Do, YYYY'): string | undefined => moment().format(format)

export const getStartDate = (date: number): string | undefined => {
    const startDate = date ? moment(date) : moment(0)
    return startDate.format(LONG_DATE_FORMAT)
}

export const getEndDate = (date: number): string | undefined => {
    const endDate = date ? moment(date) : moment().endOf('day')
    return endDate.format(LONG_DATE_FORMAT)
}

export const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

export const compareDateFormat = (date: number): string | undefined =>
    moment(getUTCDateFromString(date)).format(COMPARE_FORMAT)

export const getUTCDateFromLocal = (
    date: moment.MomentInput,
    format = LONG_DATE_FORMAT,
    outputFormat?: string
): string =>
    moment(date, format)
        .utc()
        .format(outputFormat || format)

export const getLocalDateFromUTC = (
    date: moment.MomentInput,
    format = LONG_DATE_FORMAT,
    outputFormat?: string
): string =>
    moment
        .utc(date, format)
        .local()
        .format(outputFormat || format)

export const getCompareDateFromLocalNew = (date: moment.MomentInput): string =>
    moment(getLocalDateFromUTC(date), LONG_DATE_FORMAT).format(COMPARE_FORMAT_NEW)
