import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {makeYesNoModal, eatModal} from 'ca-common/redux/modules/modal'
import {useTimer} from 'ca-common/utils/useTimer'
import {STORAGE_TYPE} from 'ca-common/common/enum'
import {getFriendlyToStorageType} from 'ca-common/utils/friendlyStorageType'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {INTEGRATION_TYPE, PAGES, USER_ACCOUNT_TYPE} from 'ca-common/constants'
import {getFriendlyRegion} from 'ca-common/utils/Region'

import {
    fetchUserInfo,
    setAccountSettings,
    setUnsubscribe,
    getAccountSettings,
    userInfoSelector
} from 'src/newcore/redux/modules/account'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {updatePaymentMethod} from 'src/newcore/redux/modules/billing'
import {invoiceLineType} from 'src/newcore/components/Settings/Utils'

import {AccountPageForm} from './AccountForm'
import {AccountDestroyForm} from './AccountDestroyForm'

export const AccountPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const paymentMethod = useAppSelector((state: AppState) => state.billing.paymentMethod)
    const accountSettings = useAppSelector((state: AppState) => state.accountSettings)
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const {timer, runTimer} = useTimer(30)

    const [isLoading, setIsLoading] = useState(false)

    const getLocation = (userInfo: any) => {
        if (userInfo.storageType && userInfo.storageType !== STORAGE_TYPE.CLOUDALLY_S3) {
            return getFriendlyToStorageType(userInfo.storageType)
        }

        return getFriendlyRegion(userInfo.region)
    }

    const unsubscribe = () => {
        dispatch(setUnsubscribe({}))
            .then(() => {
                dispatch(fetchUserInfo({}))
                if (paymentMethod.status === 'fulfilled') {
                    dispatch(
                        updatePaymentMethod({
                            isSubscribe: false
                        })
                    )
                }
                openSuccessNotification(t('accountPage:unsubscribeMessage', [companyInfo.name]))
                dispatch(eatModal())
            })
            .catch(() => openErrorModal(t('accountPage:unsubscribeFailed')))
    }

    const submitForm = (values: any) => {
        if (values.unsubscribe) {
            dispatch(
                makeYesNoModal({
                    children: t('accountPage:terminate:checkbox', [companyInfo.name]),
                    title: t('accountPage:terminate:confirmTitle'),
                    accept: unsubscribe
                })
            )
        } else {
            setIsLoading(true)

            dispatch(
                setAccountSettings({
                    name: values.name || userInfo?.name,
                    email: values.email || userInfo?.email,
                    newEmail: values.newEmail,
                    invoiceLineType: invoiceLineType(userInfo?.invoiceLineType),
                    dailyReport: values.dailyReport
                })
            )
                .then(() => {
                    dispatch(getAccountSettings({}))
                    runTimer()
                    openSuccessNotification(t('accountPage:settingsChanged'))
                })
                .catch((err: any) =>
                    openErrorModal(err.data ? `${t('accountPage:settingsFailed')} ${err.data?.status}` : err.status)
                )
                .finally(() => setIsLoading(false))
        }
    }

    useEffect(() => {
        const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX
        if (isZix) {
            history.push(`/${PAGES.SETTINGS}`)
        }
        dispatch(getAccountSettings({}))

        return () => {
            dispatch(eatModal())
        }
    }, [])

    return (
        <>
            {accountSettings?.status === 'fulfilled' && (
                <AccountPageForm
                    loading={isLoading}
                    onSubmit={submitForm}
                    isSubscribed={Boolean(userInfo?.isSubscribed)}
                    initialValues={{
                        partnerID: userInfo?.partnerID,
                        accountArchiveLocation: getLocation(userInfo),
                        dailyReport: userInfo?.dailyReport,
                        name: userInfo?.isSpecialPartnerAccount
                            ? t('partnerPortalSite:partnerAccount')
                            : accountSettings?.response?.name,
                        email: accountSettings?.response?.email,
                        newEmail: accountSettings?.response?.newEmail,
                        unsubscribe: false
                    }}
                    disabledPartner={Boolean(
                        userInfo?.logedInUser && userInfo?.partnerID && userInfo?.isSpecialPartnerAccount
                    )}
                    isExternal={companyInfo.externalUserManagment}
                    getAccountSettings={() => dispatch(getAccountSettings({}))}
                    timer={timer}
                    runTimer={runTimer}
                    isSecondary={userInfo?.userAccountType === USER_ACCOUNT_TYPE.CREDENTIAL_ONLY}
                />
            )}
            <AccountDestroyForm
                onSubmit={submitForm}
                isSubscribed={Boolean(userInfo?.isSubscribed && !userInfo?.isMsMarketplaceUser)}
                isFinra={Boolean(userInfo?.isFinra)}
            />
        </>
    )
}
