import type {MS365SubSource, Id} from 'ca-common/types'
import type {CheckedElements, CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import type {UIEntity} from 'src/newcore/features/Recovery/lib/types'

export enum LOCAL_STATE_ACTION {
    SELECT_SUBSOURCE = 'SELECT_SUBSOURCE',
    CHECK_ALL = 'CHECK_ALL',
    UNCHECK_ALL = 'UNCHECK_ALL',
    SELECT_FOLDER = 'SELECT_FOLDER',
    CHECK_FOLDER = 'CHECK_FOLDER',
    CHECK_ITEM = 'CHECK_ITEM',
    RESET_STORE = 'RESET_STORE'
}

export type LocalStateAction =
    | SelectSubrsource
    | CheckAll
    | SelectFolder
    | CheckItem
    | CheckFolder
    | ResetStore
    | UncheckAll

type SelectSubrsource = {
    type: LOCAL_STATE_ACTION.SELECT_SUBSOURCE
    payload: MS365SubSource
}

export type CheckAllPayload = {
    subsource?: MS365SubSource
    check?: boolean
}

type CheckAll = {
    type: LOCAL_STATE_ACTION.CHECK_ALL
    payload: CheckAllPayload
}

type UncheckAll = {
    type: LOCAL_STATE_ACTION.UNCHECK_ALL
}

type SelectFolder = {
    type: LOCAL_STATE_ACTION.SELECT_FOLDER
    payload: string
}

export type CheckTablePayload = {
    keys: Array<string>
    checkAll: CHECK_STATE
    checkedId: string | null
    uncheckedId: string | null
    itemsInfo: Record<Id, UIEntity>
}

type CheckItem = {
    type: LOCAL_STATE_ACTION.CHECK_ITEM
    payload: CheckTablePayload
}

export type CheckFolderPayload = {keys: CheckedElements; checkAll: CHECK_STATE}

type CheckFolder = {
    type: LOCAL_STATE_ACTION.CHECK_FOLDER
    payload: CheckFolderPayload
}

type ResetStore = {
    type: LOCAL_STATE_ACTION.RESET_STORE
}
