import styled from 'styled-components'
import Icon from '@ant-design/icons'

export const StyledSubSourceIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-right: 4px;
`

export const StyledNameContainer = styled.span`
    display: flex;
    align-items: center;
`
