import React from 'react'

import {StyledErrors} from './StyledErrors'

export const Errors = ({error}: {error: string}) => {
    const errors = error.split('<br>')

    return (
        <StyledErrors>
            {errors.map(error => (
                <React.Fragment key={error}>
                    {error}
                    <br />
                </React.Fragment>
            ))}
        </StyledErrors>
    )
}
