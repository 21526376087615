import React from 'react'
import {Trans} from 'react-i18next'

import {RESTORE_OPTIONS, RESTORE_TYPE} from 'ca-common/common/enum'

export const getRestoreTypeDescriptionFriendly = (restoreType?: RESTORE_TYPE) => {
    switch (restoreType) {
        case RESTORE_TYPE.BYPASS:
            return (
                <Trans i18nKey="restoreType:bypass:description">
                    <b>0</b>
                    <span>1</span>
                </Trans>
            )
        case RESTORE_TYPE.NON_DESTRUCTIVE:
            return (
                <Trans i18nKey="restoreType:nonDestructive:description">
                    <b>0</b>
                </Trans>
            )
        case RESTORE_TYPE.REPLACE:
            return (
                <Trans i18nKey="restoreType:replace:description">
                    <b>0</b>
                    <span>1</span>
                </Trans>
            )
        default:
            return ''
    }
}

export const getRestoreTypeFriendly = (restoreType?: RESTORE_TYPE) => {
    switch (restoreType) {
        case RESTORE_TYPE.REPLACE:
            return <Trans i18nKey="restoreType:replace:label" />
        case RESTORE_TYPE.BYPASS:
            return <Trans i18nKey="restoreType:bypass:label" />
        case RESTORE_TYPE.NON_DESTRUCTIVE:
            return <Trans i18nKey="restoreType:nonDestructive:label" />

        default:
            return ''
    }
}

export const getRestoreOptionsFriendly = (restoreOption?: RESTORE_OPTIONS) => {
    switch (restoreOption) {
        case RESTORE_OPTIONS.ADD_RESTORE_TAG:
            return <Trans i18nKey="restoreOptions:addTag" />

        case RESTORE_OPTIONS.ADD_RESTORE_FOLDER_PREFIX:
            return <Trans i18nKey="restoreOptions:addPrefix" />

        case RESTORE_OPTIONS.CREATE_SNAPSHOT:
            return <Trans i18nKey="restoreOptions:createSnapshot" />

        default:
            return ''
    }
}

export const getRestoreTypeSelectOptions = () => {
    return Object.values(RESTORE_TYPE).map(i => ({
        label: getRestoreTypeFriendly(i),
        value: i
    }))
}
