import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledRow = styled.div`
    display: flex;

    :not(:last-child) {
        margin-bottom: 4px;
    }
`

export const StyledLabel = styled.span<{labelWidth: number}>`
    display: block;
    margin-bottom: 0;
    color: ${variables.navyAsphalt};
    font-size: ${variables.fontSizeSecondary};
    line-height: 18px;
    width: ${props => `${props.labelWidth}px`};
`

export const StyledText = styled.span<{textWidth: number}>`
    max-width: ${props => `${props.textWidth}px`};
    word-break: break-word;
    color: ${variables.navyBlue};
    font-size: ${variables.fontSizeSecondary};
    line-height: 18px;
    text-align: left;
    margin-left: ${variables.gutterMd};
`
