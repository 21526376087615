import React, {useEffect, useMemo} from 'react'
import {Space} from 'antd'
import {Trans, useTranslation} from 'react-i18next'

import {SwitchBar} from 'ca-common/ui-lib/molecules/SwitchBar'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {listTasksSelector} from 'src/newcore/features/Homepage/redux'
import {changeConfig, setWizardDescription, setWizardTitle, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {getServiceName} from 'src/newcore/utils/sources'

import {useCreateOCATasksQuery} from 'src/newcore/features/OneClickActivation/redux'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {Footer} from 'src/newcore/features/OneClickActivation/molecules'
import {ExistedTask, ServiceIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {StyledBody, StyledEmail, StyledSources, StyledTitle} from './StyledOCATasksAlreadyExistStep'

export const OCATasksAlreadyExistStep = () => {
    const {t} = useTranslation()
    const {stateId} = useAppSelector(wizardDataSelector) as OCAWizardData
    const {data} = useCreateOCATasksQuery(stateId)
    const allExistedTasks = useAppSelector(listTasksSelector)
    const dispatch = useAppDispatch()

    if (!allExistedTasks) {
        return <></>
    }

    const {existedTasks: existedTasksIds, availableSources, email} = data || {}

    const existedTasks = useMemo(() => {
        return allExistedTasks.filter(task => existedTasksIds?.includes(task.id))
    }, [allExistedTasks])

    const hasAvailableSources = !!availableSources?.length

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:tasksAlreadyExistStep')))
        dispatch(setWizardDescription(''))
        dispatch(changeConfig({beforeStepChange: undefined}))
    }, [])

    return (
        <div>
            <StyledBody>
                {hasAvailableSources && (
                    <Space size={16} direction="vertical">
                        <StyledTitle>{t('oneClickActivation:wizard:labels:availableSources')}</StyledTitle>
                        <StyledSources>
                            {availableSources?.map(source => (
                                <SwitchBar
                                    key={source}
                                    checked
                                    active={false}
                                    icon={<ServiceIcon source={source} />}
                                    name={getServiceName({source})}
                                />
                            ))}
                        </StyledSources>
                    </Space>
                )}
                {!!existedTasks?.length && (
                    <Space size={16} direction="vertical">
                        <StyledTitle color={hasAvailableSources ? 'orange' : 'red'}>
                            <Trans
                                i18nKey={`oneClickActivation:wizard:labels:${
                                    hasAvailableSources ? 'partiallyExistedTasks' : 'onlyExistedTasks'
                                }`}
                                tOptions={{email}}
                            >
                                <StyledEmail />
                            </Trans>
                        </StyledTitle>
                        <StyledSources>
                            {existedTasks.map(({id, source, alias, deepTaskStatus}) => (
                                <ExistedTask key={id} source={source} status={deepTaskStatus} taskName={alias} />
                            ))}
                        </StyledSources>
                    </Space>
                )}
            </StyledBody>
            <Footer
                hideNextButton={!hasAvailableSources}
                cancelButtonType={hasAvailableSources ? undefined : 'primary'}
                cancelButtonText={hasAvailableSources ? undefined : t('modals:buttons:close')}
            />
        </div>
    )
}
