import {combineReducers} from '@reduxjs/toolkit'

import {selectorReducer as selector} from './selector'
import {searchItemsReducer as searchItems} from './searchItems'
import {breadcrumbsReducer as breadcrumbs} from './breadcrumbs'
import {snapshotsReducer as snapshots} from './snapshot'
import {backupTasksReducer as backupTasks} from './backupTask'
import {restoreItemsReducer as restoreItems} from './restoreItems'
import {recoveriesReducer as recoveries} from './recoveries'
import {backupedItemsReducer as backupedItems} from './backupedItems'
import {sfcReducer as sfc} from './sfc'

export const recoveryReducer = combineReducers({
    selector,
    searchItems,
    breadcrumbs,
    snapshots,
    backupTasks,
    restoreItems,
    recoveries,
    backupedItems,
    sfc
})
