import React, {Fragment, useEffect, useState} from 'react'
import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'
import {withTranslation, Trans} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {BULK_ACTIVATION_TYPE, BULK_ACTIVATION_METHODS_BY_SOURCE} from 'ca-common/common/enum/BulkActivation'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'
import {SOURCE} from 'src/ca-common/common/enum'
import {TOOLTIPINFO_GOOGLE_OU_URL} from 'src/ca-common/constants'
import {openErrorNotification} from 'ca-common/utils/toasts'

import Info from 'ca-common/icons/Info.svg'

import {
    changeData,
    closeWizard,
    nextStep,
    setWizardTitle,
    setWizardDescription,
    changeProgress
} from 'src/newcore/redux/modules/wizard'
import {clearMS365Data} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups'
import {clearGoogleData} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU'
import {clearExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {clearActiveDirectoryData} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'
import {googleBulkActivateAllowed} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/GoogleBulkActivateAccounts'

import {Description, TooltipInfoWrapper, RadioBlockWrapper, StyledModalCenterContentWrapper} from './StyledFirst'

const TooltipWrapper = ({children, hasBulkType, title}) => {
    return hasBulkType ? (
        children
    ) : (
        <Tooltip placement="top" title={title}>
            {children}
        </Tooltip>
    )
}

const checkDisabled = (bulkTypeFlow, oauth) => {
    return !(bulkTypeFlow === BULK_ACTIVATION_TYPE.CSV || oauth)
}

const FirstRaw = props => {
    const {t, wizard, actions, taskSettings, googleBulkActivationStatus} = props
    const [isAllowed, setIsAllowed] = useState(null)

    useEffect(() => {
        {
            taskSettings.source === SOURCE.GOOGLEAPPS
                ? actions.setWizardTitle(t('backups:bulkActivation:Google:title'))
                : actions.setWizardTitle(t('backups:bulkActivation:title'))
        }

        actions.setWizardDescription()
        taskSettings.source === SOURCE.GOOGLEAPPS &&
            actions
                .googleBulkActivateAllowed({taskId: taskSettings.taskId})
                .unwrap()
                .then(response => {
                    if (response.isBulkActivationAllowed) {
                        return setIsAllowed(true)
                    }

                    return setIsAllowed(false)
                })
                .catch(error => {
                    actions.closeWizard()
                    openErrorNotification(error.status)
                })
    }, [])

    const setBulkTypeFlow = e => {
        props.actions.changeData({
            bulkTypeFlow: e.target.value
        })
    }

    const next = () => {
        // reset all data
        actions.changeProgress(1) // hack to reset progress if you riched third step and then returning to first ti choose another flow
        actions.clearMS365Data()
        actions.clearActiveDirectoryData()
        actions.nextStep()
        actions.clearGoogleData()
    }

    return (
        <Fragment>
            <StyledModalCenterContentWrapper>
                {taskSettings.source === SOURCE.GOOGLEAPPS &&
                (googleBulkActivationStatus.status !== 'fulfilled' || isAllowed === null) ? (
                    <Spinner modifier="page" />
                ) : (
                    <>
                        {BULK_ACTIVATION_METHODS_BY_SOURCE[taskSettings.source]?.map(bulkTypeFlow => (
                            <Tooltip
                                title={
                                    (taskSettings.source === SOURCE.GOOGLEAPPS
                                        ? checkDisabled(bulkTypeFlow, true)
                                        : checkDisabled(bulkTypeFlow, taskSettings.oauth)) &&
                                    t('backups:bulkActivation:disabled')
                                }
                                key={bulkTypeFlow}
                            >
                                <RadioBlockWrapper>
                                    <RadioBlock
                                        value={bulkTypeFlow}
                                        onChange={setBulkTypeFlow}
                                        hideInactive={false}
                                        checked={bulkTypeFlow === wizard.data.bulkTypeFlow}
                                        showRadioIcon={false}
                                        title={t(`backups:bulkActivation:choice:${bulkTypeFlow}:header`)}
                                        disabled={
                                            taskSettings.source === SOURCE.GOOGLEAPPS
                                                ? checkDisabled(bulkTypeFlow, isAllowed)
                                                : checkDisabled(bulkTypeFlow, taskSettings.oauth)
                                        }
                                    >
                                        <Description>
                                            {t(`backups:bulkActivation:choice:${bulkTypeFlow}:description`)}
                                        </Description>
                                    </RadioBlock>
                                    {taskSettings.source === SOURCE.GOOGLEAPPS &&
                                        checkDisabled(bulkTypeFlow, isAllowed) && (
                                            <TooltipInfoWrapper>
                                                <Tooltip
                                                    title={
                                                        <Trans>
                                                            {t(`backups:bulkActivation:Google:tooltipInfoGoogleOU`)}
                                                            <a href={TOOLTIPINFO_GOOGLE_OU_URL} target="_blank">
                                                                {TOOLTIPINFO_GOOGLE_OU_URL}
                                                            </a>
                                                        </Trans>
                                                    }
                                                >
                                                    <Icon component={Info} />
                                                </Tooltip>
                                            </TooltipInfoWrapper>
                                        )}
                                </RadioBlockWrapper>
                            </Tooltip>
                        ))}
                    </>
                )}
            </StyledModalCenterContentWrapper>

            <ModalFooter>
                <LinkWrapper>
                    <Button type="link" onClick={() => actions.closeWizard()}>
                        {t('forms:common:actions:cancel')}
                    </Button>
                    <TooltipWrapper
                        title={t(`backups:bulkActivation:choice:selectType`)}
                        hasBulkType={wizard.data.bulkTypeFlow}
                    >
                        <Button type="primary" onClick={next} disabled={!wizard.data.bulkTypeFlow}>
                            {t('forms:common:actions:next')}
                        </Button>
                    </TooltipWrapper>
                </LinkWrapper>
            </ModalFooter>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    wizard: state.wizard,
    taskSettings: state.backups.settings.response,
    googleBulkActivationStatus: {
        // status: state.backups.bulkActivation.GoogleOUs.GoogleBulkAccountsAllowedData?.status,
        status: 'fulfilled',
        ...state.backups.bulkActivation.GoogleOUs.GoogleBulkAccountsAllowedData?.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeData,
            nextStep,
            closeWizard,
            setWizardTitle,
            setWizardDescription,
            clearMS365Data,
            clearExcludeEmails,
            changeProgress,
            clearActiveDirectoryData,
            clearGoogleData,
            googleBulkActivateAllowed
        },
        dispatch
    )
})

export const First = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FirstRaw))
