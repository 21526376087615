import styled from 'styled-components'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledSignService = styled.div`
    width: 100%;
`

export const StyledTooltipWrapper = styled.div<{disabled: boolean}>`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
`

export const Title = styled.h2`
    margin-bottom: ${variables.gutterLg};
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: ${variables.navyAsphalt};
    font-family: ${variables.accentFont};
`

export const StyledButton = styled(Button)`
    margin: 0 0 ${variables.gutterMd};
    padding: 0;
    position: relative;

    > .anticon {
        font-size: 32px;
        line-height: 1.5;
        top: 0;
        bottom: 0;
        position: absolute;
    }

    > .anticon + span {
        margin-left: 0;
    }
`

export const ServiceName = styled.span`
    margin: 0;
    width: 100%;
    margin-left: -${variables.gutterLg};
    font-family: ${variables.accentFont};
`

export const StyledLink = styled(CALink)`
    border-radius: ${variables.borderRadiusBase};
    color: ${variables.white};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin: 0 0 ${variables.gutterMd};
    padding: 0 4px;

    && {
        i {
            font-size: 32px;
            line-height: 0;
        }
    }
`
