import styled from 'styled-components'
import {Input, Form} from 'antd'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledSignInTwoFA = styled.div``

export const Title = styled.h2`
    margin-bottom: ${variables.gutterLg};
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: ${variables.navyAsphalt};
    font-family: ${variables.publicPageFont};
`

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: ${variables.gutterXs};

    div:first-child {
        margin: 0;

        span + div {
            margin-bottom: ${variables.gutterMd};
        }
    }
`

export const TwoFaInfo = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${variables.gutterSm};
    font-size: ${variables.fontSizeSecondary};

    i {
        font-size: 48px;
        color: transparent;
        margin-right: ${variables.gutterSm};
    }
`

export const Code = styled.div<{error: null | string}>`
    padding: ${variables.gutterXs} ${variables.gutterMd};
    margin: ${variables.gutterSm} auto 60px;
    background: ${props => (props.error ? variables.mistyRose : variables.blueLight)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${variables.borderRadiusBase};
`

export const CodeElement = styled.span<{error: null | string}>`
    width: 4px;
    height: 4px;
    background: ${props => (props.error ? variables.red : variables.asphaltLight)};
    border-radius: 50%;
    margin: 4px;
`

export const StyledInput = styled(Input)`
    border-radius: ${variables.borderRadiusBase};

    &::placeholder {
        color: ${variables.navyAsphalt};
    }
`

export const StyledPasswordInput = styled(Input.Password)`
    border-radius: ${variables.borderRadiusBase};
    padding: 0;

    input {
        border-radius: ${variables.borderRadiusBase};
        padding: 6.5px 11px !important;

        &::placeholder {
            color: ${variables.navyAsphalt};
        }
    }

    .ant-input-suffix {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
`

export const StyledNoAccount = styled.div`
    display: flex;
    justify-content: center;
    gap: ${variables.gutterSm};
    margin-bottom: ${variables.gutterSm};

    font-size: ${variables.fontSizeSecondary};

    @media screen and (min-width: ${variables.screenMd}) {
        margin-bottom: ${variables.gutterMd};
        font-size: ${variables.fontSizeBase};
    }
`

export const StyledForgotPassword = styled.p`
    margin-top: -${variables.gutterXs};
    margin-bottom: ${variables.gutterMd};
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.navyAsphalt};
    text-decoration: underline;

    @media screen and (min-width: ${variables.screenMd}) {
        margin-top: -${variables.gutterSm};
    }
`

export const StyledLinkText = styled.span`
    color: ${variables.iceBlue};
`

export const StyledLink = styled(CALink)`
    padding: 0;
`

export const StyledFont = styled.span`
    font-family: ${variables.publicPageFont};
    font-weight: 700;
    font-size: ${variables.fontSizeBase};
    text-shadow: none;
`

export const StyledFormItem = styled(Form.Item)`
    margin-bottom: ${variables.gutterSm};

    @media screen and (min-width: ${variables.screenMd}) {
        margin-bottom: ${variables.gutterMd};
    }
`
