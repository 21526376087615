import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'

import {SourceIcon} from 'ca-common/components/SourceIcon'

import type {SecurityAuditItem} from 'src/newcore/features/SecurityAudit/types'

import {StyledServiceCell, StyledServiceName} from './StyledServiceCell'

type ServiceCellProps = Pick<SecurityAuditItem, 'subSource' | 'source'>

export const ServiceCell = (props: ServiceCellProps) => {
    const {subSource, source} = props
    const {t} = useTranslation()

    const serviceName = t(`serviceNames:${subSource || source}`)

    return (
        <StyledServiceCell>
            <SourceIcon source={source} fontSize={20} />
            <Tooltip title={serviceName}>
                <StyledServiceName>{serviceName}</StyledServiceName>
            </Tooltip>
        </StyledServiceCell>
    )
}
