import i18n from 'i18next'
import _ from 'lodash'
import {TODO_ANY} from 'ca-common/types'

const filtersList = [
    {value: 'lastMonth', label: i18n.t('forms:common:fields:lastMonth:label')},
    {value: 'lastBackups', label: i18n.t('forms:common:fields:lastBackups:label')},
    {value: 'lastLogin', label: i18n.t('forms:common:fields:lastLogin:label')}
]

export default filtersList

export type LocalizedOption = {
    value: string
    label: string
}

export const getLocalizedOptions = (rootNamespace: string, options: TODO_ANY, namespace: string): LocalizedOption[] => {
    return _.map(options, option => ({
        value: option,
        label: getLocalizedOption(rootNamespace, namespace, option)
    }))
}

export const getLocalizedOption = (rootNamespace: string, parent: string, option: string): string =>
    i18n.t(`${rootNamespace}:${parent}:${option}`)
