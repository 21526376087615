export const uniqBy = (arr: any[], predicate: any) => {
    const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate]

    return [
        ...arr
            .reduce((map, item: any) => {
                const key = cb(item)

                map.has(key) || map.set(key, item)

                return map
            }, new Map())
            .values()
    ]
}
