import React from 'react'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import {SOURCE} from 'ca-common/common/enum'
import {CheckedIds, CHECK_STATE} from 'src/newcore/organisms/CustomTree'
import {LOCAL_STATE_ACTION, LocalStateAction} from 'src/newcore/features/Recovery/localState/actionsLocalState'
import {INITIAL_SUBSERVICES_STATE} from 'src/newcore/features/RecoveryWizard/lib'
import type {Id, MS365SubSource} from 'ca-common/types'
import type {UIEntity} from 'src/newcore/features/Recovery/lib/types'

export type TableItemState = {
    checked: Array<string> | true
    unchecked: Array<string>
}

type CheckedTableItems = {
    [key: string]: TableItemState
}

export type SubsourceState = {
    checkAll: CHECK_STATE
    checkedFolders: CheckedIds
    selectedFolder: string
    checkedItems: CheckedTableItems
    itemsInfo?: Record<Id, UIEntity>
}

export type SelectiveRecoveryState = {
    selectedSubsource: MS365SubSource
} & Record<MS365SubSource, SubsourceState>

const initialSubsource: SubsourceState = {
    checkAll: CHECK_STATE.UNCHECKED,
    checkedFolders: {checked: [], halfChecked: []},
    selectedFolder: '',
    checkedItems: {}
}

export const initialState: SelectiveRecoveryState = {
    selectedSubsource: SOURCE.O365MAIL,
    [SOURCE.O365MAIL]: {...initialSubsource},
    [SOURCE.O365CONTACTS]: {...initialSubsource},
    [SOURCE.O365NOTES]: {...initialSubsource},
    [SOURCE.O365CALENDAR]: {...initialSubsource},
    [SOURCE.O365TASKS]: {...initialSubsource}
}
export const checkIsStoreEmpty = (state: SelectiveRecoveryState): boolean => {
    let result = true
    INITIAL_SUBSERVICES_STATE.forEach(subsource => {
        const stateToCompare = omit(state[subsource], 'itemsInfo')
        stateToCompare.selectedFolder = ''
        if (!isEqual(stateToCompare, initialSubsource)) {
            result = false
        }
    })
    return result
}

export const localReducer: React.Reducer<SelectiveRecoveryState, LocalStateAction> = (state, action) => {
    switch (action.type) {
        case LOCAL_STATE_ACTION.SELECT_SUBSOURCE: {
            return {
                ...state,
                selectedSubsource: action.payload,
                [action.payload]: {
                    selectedFolder: '',
                    checkedItems: state[action.payload].checkedItems,
                    checkedFolders: state[action.payload].checkedFolders,
                    checkAll: state[action.payload].checkAll
                }
            }
        }
        case LOCAL_STATE_ACTION.CHECK_ALL: {
            let newValue
            const subSource = action.payload.subsource ?? state.selectedSubsource
            const oldValue = state[subSource]
            if (typeof action.payload.check !== 'boolean') {
                newValue =
                    oldValue.checkAll === CHECK_STATE.UNCHECKED || oldValue.checkAll === CHECK_STATE.PARTIAL
                        ? CHECK_STATE.CHECKED
                        : CHECK_STATE.UNCHECKED
            } else {
                newValue = action.payload.check ? CHECK_STATE.CHECKED : CHECK_STATE.UNCHECKED
            }
            return {...state, [subSource]: {...oldValue, checkAll: newValue}}
        }
        case LOCAL_STATE_ACTION.UNCHECK_ALL: {
            return Object.entries(state).reduce((acc, [key, value]) => {
                if (typeof value !== 'object') {
                    acc[key as 'selectedSubsource'] = value
                } else {
                    acc[key as MS365SubSource] = {
                        ...initialSubsource,
                        selectedFolder: value.selectedFolder
                    }
                }
                return acc
            }, {} as SelectiveRecoveryState)
        }
        case LOCAL_STATE_ACTION.SELECT_FOLDER: {
            const oldValue = state[state.selectedSubsource]
            return {...state, [state.selectedSubsource]: {...oldValue, selectedFolder: action.payload}}
        }
        case LOCAL_STATE_ACTION.CHECK_ITEM: {
            const {keys, checkAll, checkedId, uncheckedId, itemsInfo} = action.payload
            const oldValue = state[state.selectedSubsource]
            const oldCheckedItems = oldValue.checkedItems
            const oldSelectedItemState = {...oldValue.checkedItems[oldValue.selectedFolder]}

            const selectedItemState = oldSelectedItemState ? oldSelectedItemState : {checked: [], unchecked: []}
            if (selectedItemState.checked === true) {
                if (uncheckedId) {
                    selectedItemState.unchecked = [...selectedItemState.unchecked, uncheckedId]
                }
                if (checkedId) {
                    selectedItemState.unchecked = [...selectedItemState.unchecked].filter(el => el !== checkedId)
                }
                if (keys.length === 0) {
                    selectedItemState.checked = []
                    selectedItemState.unchecked = []
                }
            } else {
                selectedItemState.checked = checkAll === CHECK_STATE.CHECKED ? true : keys
                selectedItemState.unchecked = []
            }

            return {
                ...state,
                [state.selectedSubsource]: {
                    ...oldValue,
                    checkedItems: {
                        ...oldCheckedItems,
                        [oldValue.selectedFolder]: selectedItemState
                    },
                    itemsInfo: {
                        ...state[state.selectedSubsource].itemsInfo,
                        ...itemsInfo
                    }
                }
            }
        }
        case LOCAL_STATE_ACTION.CHECK_FOLDER: {
            const {
                keys: {checked, halfChecked, checkedConnectedEntities},
                checkAll
            } = action.payload

            const oldValue = {...state[state.selectedSubsource]}
            const oldChecked = oldValue.checkedFolders.checked.filter(el => !checked.includes(el))
            const newItemsTable = {...oldValue.checkedItems}
            checked.forEach(el => {
                newItemsTable[el] = {
                    checked: true,
                    unchecked: []
                }
            })

            oldChecked
                .filter(el => !checkedConnectedEntities.includes(el))
                .forEach(el => {
                    if (newItemsTable[el].unchecked.length === 0) {
                        delete newItemsTable[el]
                    }
                })

            return {
                ...state,
                [state.selectedSubsource]: {
                    ...oldValue,
                    checkedFolders: {checked, halfChecked},
                    checkedItems: checkAll === CHECK_STATE.UNCHECKED ? {} : newItemsTable,
                    checkAll: checkAll
                }
            }
        }
        case LOCAL_STATE_ACTION.RESET_STORE: {
            return {...initialState, selectedSubsource: state.selectedSubsource}
        }

        default:
            return state
    }
}

export type SubsourcesCheckedState = {
    [key: string]: CHECK_STATE
}
export const getCheckedSubsources = (state: SelectiveRecoveryState): SubsourcesCheckedState => {
    const subsources: Array<MS365SubSource> = [
        SOURCE.O365MAIL,
        SOURCE.O365CONTACTS,
        SOURCE.O365NOTES,
        SOURCE.O365CALENDAR,
        SOURCE.O365TASKS
    ]
    const res: SubsourcesCheckedState = {}
    subsources.forEach(el => (res[el] = state[el].checkAll))
    return res
}
