import React from 'react'
import {Radio, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'ca-common/common/enum'
import variables from 'ca-common/variables'

import {getServiceName} from 'src/newcore/utils/sources'

import {ServiceIcon} from 'src/newcore/features/OneClickActivation/atoms'

import {
    StyledLabel,
    StyledRadioGroup,
    StyledRecentlyDeletedTaskSource,
    StyledSourceName
} from './StyledRecentlyDeletedTaskSource'

type RecentlyDeletedTaskSourceProps = {
    source: SOURCE
    isForReinstate: boolean
    onChange: (source: SOURCE, value: boolean) => void
}

const tooltipOverlayInnerStyle = {
    fontSize: variables.fontSizeSecondary,
    width: '231px',
    fontFamily: variables.publicPageFont,
    fontWeight: 300
}

export const RecentlyDeletedTaskSource = ({source, isForReinstate, onChange}: RecentlyDeletedTaskSourceProps) => {
    const {t} = useTranslation()
    const sourceName = getServiceName({source})

    return (
        <StyledRecentlyDeletedTaskSource>
            <ServiceIcon source={source} />
            <StyledSourceName>{sourceName}</StyledSourceName>
            <StyledRadioGroup
                value={isForReinstate}
                onChange={e => {
                    onChange(source, e.target.value)
                }}
            >
                <Radio value={true}>
                    <Tooltip
                        title={t('oneClickActivation:wizard:tooltips:reinstate')}
                        mouseEnterDelay={0.7}
                        overlayInnerStyle={tooltipOverlayInnerStyle}
                    >
                        <StyledLabel checked={isForReinstate}>
                            {t('oneClickActivation:wizard:options:reinstate')}
                        </StyledLabel>
                    </Tooltip>
                </Radio>
                <Radio value={false}>
                    <Tooltip
                        title={t('oneClickActivation:wizard:tooltips:createNewTask')}
                        mouseEnterDelay={0.7}
                        overlayInnerStyle={tooltipOverlayInnerStyle}
                    >
                        <StyledLabel checked={!isForReinstate}>
                            {t('oneClickActivation:wizard:options:createNewTask')}
                        </StyledLabel>
                    </Tooltip>
                </Radio>
            </StyledRadioGroup>
        </StyledRecentlyDeletedTaskSource>
    )
}
