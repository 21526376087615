import React from 'react'
import {Tooltip} from 'antd'

import Info from 'ca-common/icons/Info.svg'

import {StyledIcon} from './StyledTooltipedInfo'

export const TooltipedInfo = (props: {title: string}): JSX.Element => {
    const {title} = props

    return (
        <Tooltip title={title}>
            <StyledIcon component={Info} />
        </Tooltip>
    )
}
