import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledStatusTile = styled.button`
    border: none;
    background-color: ${variables.white};
    padding: 20px ${variables.gutterMd};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: ${variables.borderRadiusBlocks};
    display: inline-flex;
    text-align: start;
    cursor: pointer;
    justify-content: start;
    align-items: center;
    column-gap: 20px;
    transition: all 0.2s linear;
    :hover {
        transition: all 0.2s linear;
        box-shadow: 0 12px 28px rgba(32, 40, 128, 0.16); // taken from homepage tiles
    }
    :focus {
        outline: none;
        box-shadow: 0 12px 28px rgba(32, 40, 128, 0.16);
    }
    :disabled {
        opacity: 0.5;
    }
`
export const StatusIcon = styled(Icon)`
    width: 60px;
    height: 60px;
    font-size: 60px;
`

export const StatusTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const StatusCount = styled.p`
    font-size: ${variables.mainTitleFontSize};
    color: ${variables.navyBlue};
    margin-bottom: 0;
    font-weight: bold;
`

export const StatusText = styled.p`
    font-size: ${variables.headingFontSize};
    margin: 0;
    color: ${variables.navyBlue};
    font-weight: 300;
`
