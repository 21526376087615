import React, {useMemo, useRef, useState} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import Info from 'ca-common/icons/Info.svg'
import {Select} from 'ca-common/ui-lib/atoms/Select'

import {
    getCurrentAnonymizationValue,
    ANONYMIZATION_MODE_FIELD_FILTER,
    FIELD_FILTER_OPTIONS,
    MODE
} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib'
import type {Field, Preview, RandomPreview} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'
import {FieldRow} from 'src/newcore/components/Seeding/DataAnonymizationStep/FieldRow'

import {ListWrapper, SelectWrapper, StyledSearchField, Title, Wrapper, TableHeader} from './StyledObjectAnonymization'

type ObjectAnonymizationProps = {
    fields: Field[]
    preview: Preview
    randomPreview: RandomPreview
    onAnonymizationChange: (fieldName: string, changes: Partial<Field>) => void
    anonymizationChanges?: Record<string, Partial<Field>>
}

export const ObjectAnonymization = ({
    fields,
    preview,
    onAnonymizationChange,
    anonymizationChanges,
    randomPreview
}: ObjectAnonymizationProps) => {
    const {t} = useTranslation()
    const [filter, setFilter] = useState(ANONYMIZATION_MODE_FIELD_FILTER.ONLY_ANONYMIZED)
    const [search, setSearch] = useState('')
    const tableHeaderRef = useRef<HTMLDivElement | null>(null)
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value)
    }

    const showedFields = useMemo(() => {
        return fields.filter(item => {
            if (item.name.toLowerCase().trim().includes(search.toLowerCase())) {
                const mode = getCurrentAnonymizationValue(item.mode, item.fieldType, anonymizationChanges?.[item.name])

                if (filter === ANONYMIZATION_MODE_FIELD_FILTER.ONLY_ANONYMIZED) {
                    return mode !== MODE.SKIP
                }

                if (filter === ANONYMIZATION_MODE_FIELD_FILTER.NOT_ANONYMIZED) {
                    return mode === MODE.SKIP
                }

                return true
            }

            return false
        })
    }, [fields, filter, search, anonymizationChanges])

    return (
        <Wrapper>
            <StyledSearchField
                value={search}
                onChange={onSearchChange}
                placeholder={t('seeding:dataAnonymizationStep:searchFieldPlaceholder')}
            />
            <TableHeader ref={tableHeaderRef}>
                <SelectWrapper>
                    <Select
                        borderWidth={0}
                        options={FIELD_FILTER_OPTIONS}
                        placement="bottomLeft"
                        clear
                        showSearch={false}
                        allowClear={false}
                        dropdownMatchSelectWidth={false}
                        value={filter}
                        onChange={setFilter}
                        getPopupContainer={() => tableHeaderRef.current?.parentElement as HTMLElement}
                    />
                </SelectWrapper>
                <Title>{t('seeding:dataAnonymizationStep:titles:replaceWith')}</Title>
                <Title>
                    <span>{t('seeding:dataAnonymizationStep:titles:preview')}</span>
                    <Tooltip title={t('seeding:dataAnonymizationStep:tooltips:preview')}>
                        <Icon component={Info} />
                    </Tooltip>
                </Title>
            </TableHeader>
            <ListWrapper>
                {showedFields.map(({name, mode, fieldType, dataType}) => (
                    <FieldRow
                        key={name}
                        fieldType={fieldType}
                        fieldName={name}
                        mode={mode}
                        dataType={dataType}
                        preview={preview}
                        randomPreview={randomPreview}
                        anonymizationChanges={anonymizationChanges}
                        onAnonymizationChange={onAnonymizationChange}
                    />
                ))}
            </ListWrapper>
        </Wrapper>
    )
}
