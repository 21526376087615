import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledEndUserCards = styled.div`
    row-gap: ${variables.gutterSm};
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    align-items: center;
    flex-wrap: wrap;
    display: grid;
`
