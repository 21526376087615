import React from 'react'
import {Modal} from 'antd'
import type {ModalProps} from 'antd/es'

const DEFAULT_MODAL_WIDTH = 708

export const BackupAuthModal = ({children, ...rest}: ModalProps): JSX.Element => (
    <Modal width={DEFAULT_MODAL_WIDTH} {...rest} closable={false} footer={null} bodyStyle={{padding: 0}} destroyOnClose>
        {children}
    </Modal>
)
