import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledLogoutButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${variables.gutterXs};

    width: 170px;
    height: 40px;

    border: none !important;
    border-radius: ${variables.borderRadiusBase};

    font-family: ${variables.publicPageFont};
    color: ${variables.brandDark} !important;
    font-weight: 300;

    &&&:hover {
        background-color: ${variables.blueLight} !important;
    }
`

export const StyledLogoutIcon = styled(Icon)`
    height: 16px;
    margin: 0 4px;
    font-size: 16px;
    color: ${variables.navyAsphalt};
`
