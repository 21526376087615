import React from 'react'
import {StyledSwitcher, SwitcherItem} from './StyledSwitcher'

type SwitcherProps = {
    options: {
        value: string
        label: string
    }[]
    activeValue: string
    onChange: (newValue: string) => void
}

export const Switcher = (props: SwitcherProps) => {
    const {onChange, activeValue, options} = props

    return (
        <StyledSwitcher>
            {options.map(({value, label}) => (
                <SwitcherItem isActive={activeValue === value} onClick={() => onChange(value)}>
                    {label}
                </SwitcherItem>
            ))}
        </StyledSwitcher>
    )
}
