import React from 'react'
import {Trans} from 'react-i18next'

import NoDataFound from 'ca-common/icons/NoDataFound.svg'
import {StyledIcon} from 'src/newcore/features/Recovery/atoms/StyledIcon'

import {StyledEmpty} from './StyledEmptyIcon'

export const EmptyIcon = (): JSX.Element => {
    return (
        <StyledEmpty
            image={<StyledIcon component={NoDataFound} />}
            description={<Trans i18nKey={'forms:recovery:table:empty'} />}
        />
    )
}
