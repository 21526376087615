import {createSelector, createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {AppState} from 'src/newcore/components/Root/newAppStore'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {TaskSettings, TODO_ANY} from 'ca-common/types'

const ACTION_ON_TASK = 'ACTION_ON_TASK'
const ACTION_ON_TASK_API = 'actionOnTask'
const actionOnTaskWrapper = fetchWrapperRT(ACTION_ON_TASK, async data => await post(ACTION_ON_TASK_API, data))

export const actionOnTask = actionOnTaskWrapper.fetcher

export const actionOnTaskReducer = createSlice({
    name: 'actionOnTask',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => actionOnTaskWrapper.makeReducers(builder)
}).reducer

const BACKUP_TASKS = 'GET_BACKUP_TASKS'
const BACKUP_TASKS_API = 'getBackupTasks'
const backupTasksWrapper = fetchWrapperRT(BACKUP_TASKS, async data => await post(BACKUP_TASKS_API, data))

export const getBackupTasks = backupTasksWrapper.fetcher

const BACKUP_TASKS_PULL = 'BACKUP_TASKS_PULL'
const backupTasksPullWrapper = fetchWrapperRT<TODO_ANY[]>(
    BACKUP_TASKS_PULL,
    async data => await post(BACKUP_TASKS_API, data)
)

export const getBackupTasksPull = backupTasksPullWrapper.fetcher

const getDataAfterDelete = (data: TaskSettings[], payload: TaskSettings) => {
    return data.filter(task => task.id !== payload.id)
}

const updateDataById = (data: TaskSettings[], payload: TaskSettings) => {
    const {id, ...rest} = payload

    return data.map(task => {
        return task.id === id
            ? {
                  ...task,
                  ...rest
              }
            : task
    })
}

const listTasksNewSlice = createSlice({
    name: 'listTasksNew',
    initialState: InitFetchedState as FetchedState<TaskSettings[]>,
    reducers: {
        clearBackupTasks: () => InitFetchedState as FetchedState<TaskSettings[]>,
        updateLocalBackupTasks: (state, action) => {
            if (state.status === 'fulfilled') {
                if (action.payload.full) {
                    state.response = action.payload.data
                } else {
                    state.response = updateDataById(state.response, action.payload)
                }
            }

            return state
        },
        deleteLocalBackupTasks: (state, action) => {
            if (state.status === 'fulfilled') {
                state.response = getDataAfterDelete(state.response, action.payload)
            }

            return state
        }
    },
    extraReducers: builder => backupTasksWrapper.makeReducers(builder)
})

export const {clearBackupTasks, updateLocalBackupTasks, deleteLocalBackupTasks} = listTasksNewSlice.actions
export const listTasksNew = listTasksNewSlice.reducer

export const listTasksSelector = createSelector(
    (state: AppState) => state.backups.listTasksNew.response,
    response => response
)

const EXPORT_USAGE_API = 'usageReport'
export const exportUsage = async () => await post(EXPORT_USAGE_API, {})
