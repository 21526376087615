import _ from 'lodash'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import queryString from 'query-string'

import {ButtonState} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

import {BackupTagsModal} from 'src/newcore/components/Modals'
import {BackupTagsForm} from 'src/newcore/forms/BackupTags'

import {Content} from './Content'
import {
    PopoverInnerContent,
    DropdownButtonWrapper,
    Search,
    StyledPopover,
    StyledSearchIcon,
    StyledDropdownIcon
} from './StyledBackupTags'

import SearchIcon from 'ca-common/icons/Search.svg'
import DropDown from 'ca-common/icons/DropDown.svg'

import {
    createAndApplyTagState,
    addEntityTag,
    removeEntityTag,
    resetTagsState,
    updateTags
} from 'src/newcore/redux/modules/backups/backupTags'
import {getBackupEntities} from 'src/newcore/redux/modules/backups'

import {openSuccessNotification} from 'ca-common/utils/toasts'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'

class BackupTagsRaw extends React.Component {
    state = {
        backupTagsPopoverVisible: false,
        searchTag: '',
        backupTagsFormVisible: false,
        loading: false,
        initialTagsState: null
    }

    setBackupTagsPopoverVisible = isVisible => this.setState({backupTagsPopoverVisible: isVisible})

    setSearchTag = searchTag => this.setState({searchTag})

    toggleBackupTagsForm = () => {
        const {backupTagsFormVisible, backupTagsPopoverVisible} = this.state
        this.setState({
            backupTagsFormVisible: !backupTagsFormVisible,
            backupTagsPopoverVisible: !backupTagsPopoverVisible
        })
    }

    setInitialTagsState = tags => this.setState({initialTagsState: [...tags]})

    createAndApplyTagState = (values, form) => {
        const {
            t,
            history,
            actions,
            taskSettings: {taskId, source},
            tagsState,
            selectedRowKeys,
            selectedAll
        } = this.props

        const queryParams = queryString.parse(history.location.search, {arrayFormat: 'bracket'})

        const getRequsetParams = tagIds => ({
            entityIds: selectedRowKeys,
            source,
            tagIds,
            all: selectedAll,
            taskId,
            taskFilter: getRequestParamsFromUrl(queryParams)
        })

        this.setState({loading: true})
        actions
            .createAndApplyTagState({
                ...values,
                taskId,
                source
            })
            .unwrap()
            .then(payload => {
                actions.addEntityTag(getRequsetParams([payload.data.id]))
                actions.resetTagsState()
                actions.updateTags(payload.data)
                actions.getBackupEntities({
                    taskId,
                    rows: PAGE_SIZE,
                    page: DEFAULT_PAGE,
                    source,
                    ...getRequestParamsFromUrl(queryParams)
                })
                this.toggleBackupTagsForm()
                form.resetFields()
                this.setInitialTagsState(tagsState.response)
                openSuccessNotification(t('forms:backupTags:response:success'))
            })
            .catch(error => {
                if (error.status) {
                    form.setFields([
                        {
                            name: 'text',
                            value: values.text,
                            errors: [error.status]
                        }
                    ])
                }
            })
            .finally(() => this.setState({loading: false}))
    }

    render() {
        const {t, taskSettings, selectedAll, selectedRowKeys} = this.props
        const {backupTagsPopoverVisible, searchTag, backupTagsFormVisible, loading, initialTagsState} = this.state

        const isDropdownDisabled = !selectedAll && _.isEmpty(selectedRowKeys)

        const content = (
            <PopoverInnerContent>
                <Search
                    value={searchTag}
                    placeholder={t('forms:backupTags:fields:search:placeholder')}
                    onChange={e => this.setSearchTag(e.target.value)}
                    prefix={<StyledSearchIcon component={SearchIcon} />}
                />
                <Content
                    selectedAll={selectedAll}
                    selectedRowKeys={selectedRowKeys}
                    setPopoverVisible={this.setBackupTagsPopoverVisible}
                    toggleBackupTagsForm={this.toggleBackupTagsForm}
                    searchTag={searchTag}
                    setSearchTag={this.setSearchTag}
                    setInitialTagsState={this.setInitialTagsState}
                    initialTagsState={initialTagsState}
                />
            </PopoverInnerContent>
        )

        return (
            <Fragment>
                <StyledPopover
                    onOpenChange={() =>
                        !isDropdownDisabled && this.setBackupTagsPopoverVisible(!backupTagsPopoverVisible)
                    }
                    open={backupTagsPopoverVisible}
                    placement="bottomRight"
                    trigger="click"
                    content={content}
                    getPopupContainer={t => t.parentElement}
                    destroyTooltipOnHide
                >
                    <Tooltip
                        placement="top"
                        title={
                            isDropdownDisabled &&
                            t('backups:backupActions:disabledTooltip', {
                                entityName: _.toLower(getSvcAccountsDescription(taskSettings.source))
                            })
                        }
                    >
                        <DropdownButtonWrapper disabled={isDropdownDisabled}>
                            <ButtonState
                                shape="round"
                                disabled={isDropdownDisabled}
                                active={backupTagsPopoverVisible}
                                color={variables.iceBlue}
                            >
                                <span>{t('forms:backupTags:title')}</span>
                                <StyledDropdownIcon component={DropDown} />
                            </ButtonState>
                        </DropdownButtonWrapper>
                    </Tooltip>
                </StyledPopover>

                <BackupTagsModal
                    destroyOnClose={true}
                    open={backupTagsFormVisible}
                    onCancel={this.toggleBackupTagsForm}
                >
                    <BackupTagsForm
                        onSubmit={this.createAndApplyTagState}
                        onCancel={this.toggleBackupTagsForm}
                        loading={loading}
                        service={taskSettings.source}
                    />
                </BackupTagsModal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    tagsState: state.backups.backupTags.tagsState
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            createAndApplyTagState,
            addEntityTag,
            removeEntityTag,
            getBackupEntities,
            resetTagsState,
            updateTags
        },
        dispatch
    )
})

export const BackupTags = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BackupTagsRaw)))
