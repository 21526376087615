import {isDevelopment} from 'ca-common/utils/runtime'

export const reInitInlineManualPlayer = (props?: Record<string, string | number | string[]>, callback?: any): void => {
    if (!window.inline_manual_player) return
    window.inline_manual_player.updateProfile(props, callback)

    if (isDevelopment) {
        console.log('INLINE PLAYER HAS BEEN REINITIALIZED WITH PROPS', props)
    }
}
