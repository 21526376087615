import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledEntities = styled.div`
    display: ${props => (props.isEntitiesNotVisible ? 'none' : 'block')};
    overflow: auto;
    min-height: 100px;
    max-height: 354px;
    width: 100%;
    padding: ${variables.gutterMd} ${variables.gutterMd} 0;
    border-bottom: 1px solid ${variables.blue};
`
