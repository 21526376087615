import {Form, Row} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledExpirationForm = styled(Form)`
    .ant-col {
        display: flex;
    }
    .ant-form-item {
        margin-bottom: ${variables.gutterXs};
    }
`
export const StyledEnforceTwoStep = styled.div`
    .ant-form-item-row {
        display: flex;
        flex-direction: row-reverse;
    }
    .ant-form-item-label {
        padding-left: ${variables.gutterXs};
    }
`
export const StyledExpiredPasswordRow = styled(Row)`
    .ant-row {
        margin-right: ${variables.gutterMd};
    }
`
