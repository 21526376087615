import styled from 'styled-components'

import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledActionButton = styled(Button)`
    svg {
        font-size: 24px;
    }
`

export const StyledCondition = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-left: 36px;
    padding-top: 2px;
    margin-bottom: ${variables.gutterMd};

    .ant-form-item {
        margin-bottom: 0;
    }
`

export const StyledConditionText = styled.span`
    color: ${variables.navyBlue};
    width: 30px;
`

export const StyledConditionsActions = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
`
