import React from 'react'
import {Link, LinkProps} from 'react-router-dom'

export type CALinkProps = LinkProps & {
    pureLink?: boolean
}

export const CALink = ({children, pureLink = false, to, ...rest}: CALinkProps): JSX.Element => {
    return pureLink && typeof to === 'string' ? (
        <a href={to} {...rest}>
            {children}
        </a>
    ) : (
        <Link to={to} {...rest}>
            {children}
        </Link>
    )
}
