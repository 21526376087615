import React from 'react'
import PropTypes from 'prop-types'
import tx from 'transform-props-with'
import {dumbFormGroup, FormGroup, Label, Error} from '../FormGroup'

import './LabeledTextArea.scss'

const TextArea = tx([{element: 'textarea'}, dumbFormGroup])('textarea')

export const LabeledTextArea = ({input, label, htmlFor, meta: {touched, error, warning}, ...rest}) => {
    return (
        <FormGroup modifier="textarea">
            {label && <Label htmlFor={htmlFor}>{label}</Label>}
            <TextArea {...input} {...rest} id={htmlFor} />
            {touched && ((error && <Error modifier="textarea">{error}</Error>) || (warning && <span>{warning}</span>))}
        </FormGroup>
    )
}

LabeledTextArea.PropTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired
    }).isRequired,
    label: PropTypes.string,
    htmlFor: PropTypes.string
}

LabeledTextArea.defaultProps = {
    label: '',
    htmlFor: ''
}
