import _ from 'lodash'
import React, {Fragment, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Trans, useTranslation} from 'react-i18next'
import queryString from 'query-string'
import {withRouter} from 'react-router'

import {openErrorModal} from 'ca-common/utils/modals'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {CancelLink, LinkWrapper, ModalContentWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import Warning from 'ca-common/icons/Warning.svg'

import {clearExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {clearWizard, previousStep, setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {clearMS365Data} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups'
import {activateBulkFlow} from 'src/newcore/redux/modules/backups/bulkActivate'
import {pullBackupEntities} from 'src/newcore/redux/modules/backups/backupEntities/entities'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {ConfirmationRuleSelectionModal} from 'src/newcore/components/Modals/ConfirmationRuleSelectionModal'
import {Header, WarningIcon} from 'src/newcore/forms/ConfirmationRuleSelection/StyledConfirmationRuleSelection'
import {AccountList} from './AccountList'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {trackBulkActivation, TypesOfRules} from 'src/newcore/components/BulkActivation/trackBulkActivation'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

export const MS365GroupAccountsRaw = ({
    actions,
    taskSettings,
    selectedGroups,
    groupAccounts,
    excludeEmails,
    location,
    bulkActivationRules
}) => {
    const [isLoading, setLoading] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const {t} = useTranslation()

    const groupsRules = bulkActivationRules?.filter(item => item.type === 'GROUPS')
    const rulesNames = groupsRules?.flatMap(i => i.groupItems).map(item => item.name)
    const notValidSelectedItems = selectedGroups
        .filter(item => rulesNames.includes(item.name))
        .map(item => ` ${item.name}`)

    const handleActivate = (createRule = false) => {
        setLoading(true)
        return actions
            .activateBulkFlow({
                taskSettings,
                selectedGroups,
                excludes: excludeEmails,
                createRule,
                cacheToken: groupAccounts.response.cacheToken
            })
            .then(data => {
                const succeed = data.succeed
                const total = data.total

                openSuccessNotification(t('backups:activatedSuccessfully', {succeed, total}))
            })
            .catch(() => {
                openErrorNotification(t('backups:MS365Groups:activationFailed'))
            })
            .finally(() => {
                const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

                actions.clearWizard()
                actions.clearMS365Data()
                setLoading(false)
                actions.pullBackupEntities({
                    taskId: taskSettings.taskId,
                    rows: PAGE_SIZE,
                    page: DEFAULT_PAGE,
                    ...getRequestParamsFromUrl(queryParams)
                })
            })
    }

    const handleActivateRule = async () => {
        if (taskSettings.detectNewAccounts) {
            setIsVisible(true)
        } else {
            await handleActivate(true)
        }

        trackBulkActivation(EVENT_NAMES.CREATE_AN_ACTIVATION_RULE, taskSettings?.source, TypesOfRules.GROUPS)
    }
    const handleConfirmationCancelClick = () => {
        setIsVisible(false)
        trackBulkActivation(
            EVENT_NAMES.REFUSAL_TO_CONFIRM_ACTIVATION_RULES_CREATION,
            taskSettings?.source,
            TypesOfRules.GROUPS
        )
    }
    const handleConfirmationSubmit = () => {
        setIsVisible(false)
        handleActivate(true).then(() => {
            trackBulkActivation(
                EVENT_NAMES.CONFIRM_AN_ACTIVATION_RULE_CREATION,
                taskSettings?.source,
                TypesOfRules.GROUPS
            )
        })
    }

    const isActivationDisabled = () =>
        'fulfilled' !== groupAccounts.status || _.get(groupAccounts, 'response.total') === excludeEmails.length

    const onCancel = () => {
        trackBulkActivation(EVENT_NAMES.CANCEL_BULK_ACTIVATION_LAST_STEP, taskSettings?.source, TypesOfRules.GROUPS)
        actions.clearWizard()
    }

    const onActivate = () => {
        handleActivate().then(() => {
            trackBulkActivation(EVENT_NAMES.PERFORM_ONE_TIME_ACTIVATION, taskSettings.source, TypesOfRules.GROUPS)
        })
    }

    useEffect(() => {
        return () => {
            actions.clearExcludeEmails()
        }
    }, [])

    return (
        <Fragment>
            <ModalContentWrapper>
                <AccountList />
            </ModalContentWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}> {t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={onActivate} disabled={isActivationDisabled()} loading={isLoading}>
                        <span>{t('backups:bulkActivation:activate')}</span>
                    </Button>
                    <Button
                        type="primary"
                        onClick={
                            notValidSelectedItems.length > 0
                                ? () =>
                                      openErrorModal(
                                          <Trans
                                              i18nKey="backups:bulkActivation:groupsValidationError"
                                              values={{
                                                  type: t('backups:bulkActivation:choice:GROUPS:type'),
                                                  items: notValidSelectedItems.toString()
                                              }}
                                          >
                                              <strong>0</strong>
                                          </Trans>
                                      )
                                : handleActivateRule
                        }
                        disabled={isActivationDisabled()}
                        loading={isLoading}
                    >
                        <span>{t('backups:bulkActivation:activateRule')}</span>
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => actions.previousStep()} />
            </ModalFooter>
            <ConfirmationRuleSelectionModal
                open={isVisible}
                onOk={handleConfirmationSubmit}
                onCancel={handleConfirmationCancelClick}
            >
                <Header>
                    <WarningIcon component={Warning} />
                    <p>
                        {t('backups:byCondition:popoverHeader', {
                            entityName: _.toLower(getSvcAccountsDescription(taskSettings.source, true))
                        })}
                    </p>
                </Header>
            </ConfirmationRuleSelectionModal>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    groupAccounts: state.backups.bulkActivation.MS365Groups.MS365AccountsWithGroups,
    selectedGroups: state.backups.bulkActivation.MS365Groups.selectedGroups,
    excludeEmails: state.backups.bulkActivation.excludeEmails,
    bulkActivationRules: state.backups.bulkActivation.bulkActivationRules.rules.response?.data || []
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            clearExcludeEmails,
            clearWizard,
            setWizardDescription,
            previousStep,
            clearMS365Data,
            activateBulkFlow,
            pullBackupEntities
        },
        dispatch
    )
})

export const MS365GroupAccounts = withRouter(connect(mapStateToProps, mapDispatchToProps)(MS365GroupAccountsRaw))
