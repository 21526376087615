import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {StyledBackupTitle, StyledBackupEdit} from './StyledBackupTitle'
import {BackupTitleModal} from 'src/newcore/components/Modals'
import {BackupTitle as BackupTitleForm} from 'src/newcore/forms/BackupTitle'
import BackupEdit from 'ca-common/icons/BackupEdit.svg'
import {truncate} from 'lodash'

import {changeBackupName} from 'src/newcore/redux/modules/backups/backupName'
import {appendLocalBackupTaskSettings} from 'src/newcore/features/BackupSettings/redux'

import {openErrorModal} from 'ca-common/utils/modals'
import {openSuccessNotification} from 'ca-common/utils/toasts'

export const ALIAS_TRIM_LENGTH = 53

@withTranslation()
class BackupTitleRaw extends Component {
    state = {
        backupTitleFormVisible: false,
        loading: false
    }

    toggleVisible = () =>
        this.setState({
            backupTitleFormVisible: !this.state.backupTitleFormVisible
        })

    handleSubmit = values => {
        const {t, actions, taskSettings} = this.props
        this.setState({loading: true})
        actions
            .changeBackupName({
                taskId: taskSettings.response.taskId,
                ...values
            })
            .unwrap()
            .then(() => {
                actions.appendLocalBackupTaskSettings(values)
                this.toggleVisible()
                openSuccessNotification(t('forms:backupTitle:response:nameChanged'))
            })
            .catch(error => {
                if (error.status) {
                    openErrorModal(error.status)
                }
            })
            .finally(() => this.setState({loading: false}))
    }

    render() {
        const {taskSettings} = this.props

        const {backupTitleFormVisible, loading} = this.state

        switch (taskSettings.status) {
            case 'pending':
                return <Spinner modifier="page" />
            case 'fulfilled':
                return (
                    <StyledBackupTitle title={taskSettings.response.alias}>
                        {truncate(taskSettings.response.alias, {length: ALIAS_TRIM_LENGTH})}
                        <StyledBackupEdit component={BackupEdit} onClick={this.toggleVisible} />
                        <BackupTitleModal open={backupTitleFormVisible} onCancel={this.toggleVisible}>
                            <BackupTitleForm
                                alias={taskSettings.response.alias}
                                onSubmit={this.handleSubmit}
                                onCancel={this.toggleVisible}
                                loading={loading}
                            />
                        </BackupTitleModal>
                    </StyledBackupTitle>
                )
            default:
                return null
        }
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            changeBackupName,
            appendLocalBackupTaskSettings
        },
        dispatch
    )
})

export const BackupTitle = connect(mapStateToProps, mapDispatchToProps)(BackupTitleRaw)
