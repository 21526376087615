import {SORD_ORDER} from 'ca-common/common/enum/SordOrder'
import {TASK_TYPE} from 'ca-common/common/enum/TaskType'
import {JOBS_STATUSES} from 'src/ca-common/common/enum'

export const JOB_FIELDS = {
    INITIATOR: 'jobInitiator',
    SERVICE: 'jobSources',
    ACTIVITY: 'jobActivity',
    STATUS: 'jobStatuses',
    SORT_BY: 'jobSorted',
    TASK_QUERY: 'taskQuery',
    ITEM_QUERY: 'itemQuery'
} as const

export const SORT_BY = {
    LATEST_FIRST: SORD_ORDER.descend,
    OLDER_FIRTS: SORD_ORDER.ascend
} as const

export const ACTIVITY_OPTIONS = [TASK_TYPE.RESTORE, TASK_TYPE.EXPORT]
export const JOB_STATUS_OPTIONS = [
    JOBS_STATUSES.IN_PROCESS,
    JOBS_STATUSES.SUCCEEDED,
    JOBS_STATUSES.PARTIALLY_SUCCEEDED,
    JOBS_STATUSES.FAILED,
    JOBS_STATUSES.PENDING,
    JOBS_STATUSES.CANCELLED
] as const
export type JOB_STATUS_OPTIONS = typeof JOB_STATUS_OPTIONS[number]

export const SORT_BY_OPTIONS = [SORT_BY.LATEST_FIRST, SORT_BY.OLDER_FIRTS]

export type JOBS_DASHBOARD_STATUSES = JOBS_STATUSES.IN_PROCESS | JOBS_STATUSES.SUCCEEDED | JOBS_STATUSES.FAILED

export const JOBS_PER_PAGE = 8
export const DEFAULT_PAGE = 1
