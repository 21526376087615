import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledIcon = styled(Icon)`
    font-size: 34px;
    color: ${variables.old.blueLight};

    &:hover {
        cursor: pointer;
    }
`
