import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledBackupActions = styled.div<{disabled: boolean}>`
    display: flex;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    color: ${variables.asphalt};

    &:hover {
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`

export const BackupAction = styled(Icon)`
    font-size: 24px;
    margin-right: 8px;
    opacity: 1;
    color: ${variables.brand};
`

export const GreyedBackupAction = styled(BackupAction)`
    opacity: 0.5;
`

export const StyledDeleteAction = styled(BackupAction)`
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

    &:hover {
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`

export const TooltipWrapper = styled.div<{disabled: boolean}>`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
