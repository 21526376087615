import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {RECOVERY_TYPE} from 'ca-common/common/enum'
import InfoIcon from 'ca-common/icons/Info.svg'

import {
    RecoveryOptionsWrapper,
    StyledIcon,
    StyledLabel,
    StyledRadio,
    StyledRadioGroup
} from './StyledFullRecoveryOptions'
import {RECOVERY_OPTIONS} from 'src/newcore/features/RecoveryWizard/enums'

type FullRecoveryOptionsType = {
    changeFullRecoveryOptions: (arg0: RECOVERY_TYPE) => void
    fullRecoveryOptions?: RECOVERY_TYPE
}

export const FullRecoveryOptions = ({
    changeFullRecoveryOptions,
    fullRecoveryOptions
}: FullRecoveryOptionsType): JSX.Element => {
    const {t} = useTranslation()

    return (
        <RecoveryOptionsWrapper>
            <StyledLabel>
                {t(`recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:chooseRecoveryOptions`)}
            </StyledLabel>
            <StyledRadioGroup>
                <StyledRadio
                    onChange={() => changeFullRecoveryOptions(RECOVERY_TYPE.EXPORT)}
                    checked={RECOVERY_TYPE.EXPORT === fullRecoveryOptions}
                    value={RECOVERY_TYPE.EXPORT}
                >
                    {t(
                        `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:${RECOVERY_TYPE.EXPORT}`
                    )}
                    <Tooltip
                        title={t(
                            `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:exportTooltip`
                        )}
                    >
                        <StyledIcon component={InfoIcon} />
                    </Tooltip>
                </StyledRadio>
                <StyledRadio
                    onChange={() => changeFullRecoveryOptions(RECOVERY_TYPE.RESTORE)}
                    checked={RECOVERY_TYPE.RESTORE === fullRecoveryOptions}
                    value={RECOVERY_TYPE.RESTORE}
                >
                    {t(
                        `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:${RECOVERY_TYPE.RESTORE}`
                    )}
                    <Tooltip
                        title={t(
                            `recovery:wizard:advancedOptions:${RECOVERY_OPTIONS.FULL_MAILBOX_RECOVERY}:restoreTooltip`
                        )}
                    >
                        <StyledIcon component={InfoIcon} />
                    </Tooltip>
                </StyledRadio>
            </StyledRadioGroup>
        </RecoveryOptionsWrapper>
    )
}
