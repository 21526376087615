import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledCheckCell = styled.div`
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: ${variables.brand};
`
