export enum ENTITY_SHAPE {
    /**
     * Used for selected folders and objects in SFC
     */
    FOLDER = 'FOLDER',
    /**
     * Used for selected items
     */
    DOCUMENT = 'DOCUMENT',
    /**
     * Used for item search level selection
     */
    QUERY = 'QUERY',
    /**
     * Used for selection medata object in SFC
     */
    MD_FOLDER = 'MD_FOLDER',
    /**
     * Used for exclusion items from whole folder
     */
    SEMI_FOLDER = 'SEMI_FOLDER'
}
