import styled from 'styled-components'

import {BackupAuthBlock} from '../StyledOrganisms'

export const BackupAuthFooter = styled(BackupAuthBlock)`
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-btn-link {
        padding: 0;
    }
`
