import React from 'react'
import {CloseOutlined, PlusCircleOutlined} from '@ant-design/icons'

import {ObjectItemAction, ObjectItemName, ObjectItemStatus, ObjectItemWrapper} from './Styled'

export const ObjectItem = ({name, status, isDeleted, isActive, onClick, onDelete}) => {
    return (
        <ObjectItemWrapper onClick={onClick} isActive={isActive} isDeleted={isDeleted}>
            <ObjectItemName>{name}</ObjectItemName>
            <ObjectItemStatus>{status}</ObjectItemStatus>
            <ObjectItemAction>
                {isDeleted ? <PlusCircleOutlined onClick={onDelete} /> : <CloseOutlined onClick={onDelete} />}
            </ObjectItemAction>
        </ObjectItemWrapper>
    )
}
