import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledSelectedFilters = styled.div`
    margin-top: ${variables.gutterSm};
    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;

            > * {
                pointer-events: none;
            }

            :hover {
                cursor: not-allowed;
            }
        `}
`

export const StyledIcon = styled(Icon)`
    font-size: ${variables.mainTitleFontSize};
    margin-right: 12px;
`
