import React, {useState} from 'react'
import {Spin} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {VALIDATE_STATUS} from 'ca-common/common/enum/ValidateStatus'
import {validateSfcWhereClause} from 'src/newcore/redux/modules/backups/seeding/SQLWhereClause'

import {SQLWhereClauseForm} from './SQLWhereClauseForm'
import {useTranslation} from 'react-i18next'

const SQLWhereClauseRaw = ({actions, match, validateSfcWhereClauseResult, onValidatePass, value}) => {
    const [formValidateStatus, setFormValidateStatus] = useState({validateStatus: '', message: ''})
    const {t} = useTranslation()

    const onPressEnter = e => {
        if (e.shiftKey) {
            return null
        }

        const whereClause = e.target.value

        return actions
            .validateSfcWhereClause({whereClause, taskId: match.params.taskId})
            .then(() => {
                setFormValidateStatus({
                    validateStatus: VALIDATE_STATUS.SUCCESS,
                    message: t('seeding:fields:SQLWhereClause:validation:complete')
                })
                onValidatePass(whereClause)
            })
            .catch(err => {
                setFormValidateStatus({
                    validateStatus: VALIDATE_STATUS.ERROR,
                    message: err.status
                })
            })
    }

    return (
        <Spin spinning={'pending' === validateSfcWhereClauseResult.status}>
            <SQLWhereClauseForm value={value} formValidateStatus={formValidateStatus} onPressEnter={onPressEnter} />
        </Spin>
    )
}

const mapStateToProps = state => ({
    validateSfcWhereClauseResult: state.backups.seeding.validateSfcWhereClauseResult
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            validateSfcWhereClause
        },
        dispatch
    )
})

export const SQLWhereClause = withRouter(connect(mapStateToProps, mapDispatchToProps)(SQLWhereClauseRaw))
