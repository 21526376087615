import React from 'react'
import {Popconfirm, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Cancel from 'ca-common/icons/backupAction/Cancel.svg'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {BackupAction} from '../StyledBackupActions'

type BackupCancelProps = {
    handlePerformBackupTaskAction: (arg0: TASK_ACTION) => void
    disabled?: boolean
}

export const BackupCancel = ({handlePerformBackupTaskAction, disabled}: BackupCancelProps) => {
    const {t} = useTranslation()

    return (
        <Popconfirm
            placement="topRight"
            title={t(`backups:backupActions:${TASK_ACTION.CANCEL}:confirm`)}
            onConfirm={() => handlePerformBackupTaskAction(TASK_ACTION.CANCEL)}
            okText={t('modals:buttons:yes')}
            cancelText={t('modals:buttons:no')}
        >
            <Tooltip title={t(`backups:newActions:${TASK_ACTION.CANCEL}`)}>
                <BackupAction component={Cancel} disabled={disabled} data-icon="cancel" />
            </Tooltip>
        </Popconfirm>
    )
}
