import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {truncate} from 'lodash'
import {useTranslation} from 'react-i18next'

import {NEW_BACKUP_URL} from 'ca-common/constants'

import {ALIAS_TRIM_LENGTH} from 'src/newcore/components/BackupTitle'

const PureUserBreadcrumb = ({name, match}) => {
    const {t} = useTranslation()
    const newBackupTitle = match.url === NEW_BACKUP_URL ? t(`breadcrumb:newbackup`) : name
    return (
        <NavLink title={newBackupTitle} to={match.url}>
            {truncate(newBackupTitle, {length: ALIAS_TRIM_LENGTH})}
        </NavLink>
    )
}

const mapStateToProps = state => {
    const request = state.backups.settings

    return {
        name:
            request.status === 'fulfilled' && request.response ? request.response.alias || request.response.account : ''
    }
}

export const BackupsBreadcrumb = connect(mapStateToProps)(PureUserBreadcrumb)
