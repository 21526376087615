import React from 'react'
import {Tooltip} from 'antd'

import Person from 'ca-common/icons/Person.svg'
import type {Email, Name, Url} from 'ca-common/types'

import {
    StyledAccountIcon,
    StyledAccountInfoButton,
    StyledAccountInfo,
    StyledUserName,
    StyledUserEmail,
    TooltipWrapper
} from './StyledAccountInfoButton'

type AccountInfoButtonProps = {
    userName: Name
    userEmail: Email
    settingsUrl: Url
    isMsEndUser?: boolean
}

export const AccountInfoButton = ({userName, userEmail, settingsUrl, isMsEndUser}: AccountInfoButtonProps) => {
    return (
        <TooltipWrapper>
            <Tooltip
                mouseEnterDelay={1}
                placement="topRight"
                getPopupContainer={node => node.parentElement!}
                title={
                    <div>
                        <div>{userName}</div>
                        <div>{userEmail}</div>
                    </div>
                }
            >
                <StyledAccountInfoButton
                    disabled={isMsEndUser}
                    href={settingsUrl}
                    icon={<StyledAccountIcon component={Person} />}
                >
                    <StyledAccountInfo>
                        <StyledUserName>{userName}</StyledUserName>
                        <StyledUserEmail>{userEmail}</StyledUserEmail>
                    </StyledAccountInfo>
                </StyledAccountInfoButton>
            </Tooltip>
        </TooltipWrapper>
    )
}
