import React from 'react'
import {useTranslation} from 'react-i18next'

import {OAuthButton} from 'ca-common/ui-lib/atoms/OAuthButton'
import {OAuthServiceType} from 'ca-common/types'
import {getOauthServiceIcon, getOauthServiceTitle} from 'ca-common/utils/oauth'

import {StyledSignService} from './StyledSignService'

type SignServiceProps = {
    services: OAuthServiceType[]
    isSignUp?: boolean
    isNew?: boolean
}

export const SignService = ({services, isSignUp, isNew}: SignServiceProps) => {
    const {t} = useTranslation()

    return (
        <StyledSignService>
            {services.map(({href, color, service}) => (
                <OAuthButton
                    href={href}
                    color={color}
                    title={t(`sign:${isSignUp ? 'up' : 'in'}:with:service`, {service: getOauthServiceTitle(service)})}
                    icon={getOauthServiceIcon(service)}
                    service={service}
                    key={service}
                    isNew={isNew}
                />
            ))}
        </StyledSignService>
    )
}
