import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Logo = styled.img`
    width: 130px;
    height: 26px;
`

export const StyledHeader = styled.div`
    width: 100%;
    padding: ${variables.gutterLg} ${variables.gutterLg} 0;

    @media (min-width: 768px) {
        padding: 48px ${variables.gutterLg};
    }

    @media (min-width: 1200px) {
        padding: 48px 72px;
    }
`
