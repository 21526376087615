import {useState} from 'react'

import {PANEL_KEY} from 'src/newcore/features/BackupSettings/lib'

const EMPTY_STRING = ''

export const useTogglePanel = isNew => {
    const [panelName, setPanelName] = useState(isNew ? PANEL_KEY : EMPTY_STRING)

    const togglePanelName = () => {
        setPanelName(name => (name === PANEL_KEY ? EMPTY_STRING : PANEL_KEY))
    }

    return [panelName, togglePanelName]
}
