import React, {useMemo} from 'react'
import {Tooltip} from 'antd'

import {Select} from 'ca-common/ui-lib/atoms/Select'

import {
    DATA_TYPE,
    FIELD_TYPE,
    getAnonymizationOptions,
    getCurrentAnonymizationValue,
    MODE,
    TOOLTIP_MOUSE_ENTER_DELAY,
    DROPDOWN_Z_INDEX
} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib'
import type {Field, Preview, RandomPreview} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'
import {Row} from 'src/newcore/components/Seeding/DataAnonymizationStep/StyledDataAnonymizationStep'

import {AnonymizationSelectWrapper, Text} from './StyledFieldRow'

const DEFAULT_PREVIEW = '-'

type FieldRowProps = {
    preview: Preview
    randomPreview: RandomPreview
    mode: MODE
    fieldType: FIELD_TYPE
    anonymizationChanges?: Record<string, Partial<Field>>
    fieldName: string
    dataType: DATA_TYPE
    onAnonymizationChange: (name: string, changes: Partial<Field>) => void
}

export const FieldRow = (props: FieldRowProps) => {
    const {
        preview,
        fieldType,
        fieldName,
        dataType,
        onAnonymizationChange,
        anonymizationChanges,
        mode,
        randomPreview = {}
    } = props

    const value = getCurrentAnonymizationValue(mode, fieldType, anonymizationChanges?.[fieldName])
    const options = getAnonymizationOptions(dataType)

    const previewText = useMemo(() => {
        if (value === MODE.RANDOM) {
            return randomPreview[dataType] || DEFAULT_PREVIEW
        }

        return preview[value as FIELD_TYPE] || DEFAULT_PREVIEW
    }, [value])

    const onChange = (value: MODE | FIELD_TYPE) => {
        if (value === MODE.SKIP || value === MODE.RANDOM) {
            onAnonymizationChange(fieldName, {mode: value as MODE})
        } else {
            onAnonymizationChange(fieldName, {mode: MODE.PRE_DEFINED, fieldType: value as FIELD_TYPE})
        }
    }

    return (
        <Row isHighlighted={value !== MODE.SKIP}>
            <Tooltip title={fieldName} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
                <Text>{fieldName}</Text>
            </Tooltip>
            <AnonymizationSelectWrapper>
                <Select
                    borderWidth={0}
                    options={options}
                    placement="bottomLeft"
                    clear
                    showSearch={false}
                    allowClear={false}
                    dropdownMatchSelectWidth={false}
                    value={value}
                    onChange={onChange}
                    disabled={options.length === 1}
                    dropdownStyle={{zIndex: DROPDOWN_Z_INDEX}}
                />
            </AnonymizationSelectWrapper>
            <Tooltip
                title={previewText}
                mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
                {...(previewText === DEFAULT_PREVIEW ? {open: false} : {})}
            >
                <Text>{previewText}</Text>
            </Tooltip>
        </Row>
    )
}
