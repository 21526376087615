import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {compareDateFormat} from 'src/newcore/utils/backups'

import {StyledHeader, HeaderTitle, ObjectCounts, HeaderRightText} from './StyledHeader'

@withTranslation()
export class SeedingItemHeader extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        createDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        object: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }

    static defaultProps = {
        object: ''
    }

    render() {
        const {type, object, t, createDate} = this.props

        return (
            <StyledHeader>
                <HeaderTitle>
                    {t('seedingItem:title', [t(`seedingItem:type:${type.toLowerCase()}`)])}
                    {` ${t('seedingItem:object')}`}
                    {object > 0 ? (
                        <ObjectCounts>{` -  ${t('seedingItem:objCounts:obj', {count: +object})}`}</ObjectCounts>
                    ) : (
                        <ObjectCounts>{` -  ${t('seedingItem:allObjects')}`}</ObjectCounts>
                    )}
                </HeaderTitle>
                <HeaderRightText>{compareDateFormat(createDate)}</HeaderRightText>
            </StyledHeader>
        )
    }
}
