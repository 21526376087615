import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'
import {Switch, useLocation} from 'react-router'

import {PAGES, PUBLIC_PAGES} from 'ca-common/constants'
import {NotFound} from 'ca-common/components/NotFound'
import {getOtherPermittedPage, PermissionRoute} from 'ca-common/components/PermissionRoute'
import {b64ToUtf8} from 'ca-common/utils/base64'

import {CAApp} from 'src/newcore/components/App'
import {useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

import {SettingsRouting} from 'src/newcore/components/Routing/SettingsRouting'
import {ActivityRouting} from 'src/newcore/components/Routing/ActivityRouting'
import {BackupsRouting} from 'src/newcore/components/Routing/BackupsRouting'
import {JobsRouting} from 'src/newcore/components/Routing/JobsRouting'
import {BillingRouting} from 'src/newcore/components/Routing/BillingRouting'

import {SupportRedirect} from 'src/newcore/components/Support'
import {DownloadPage} from 'src/newcore/components/Download'
import {CAFaq} from 'src/newcore/components/Faq'
import {ActivityDownloadPage} from 'src/newcore/components/ActivityDownload'
import {Download} from 'src/newcore/features/Download'
import {SelectiveRecovery} from 'src/newcore/pages/SelectiveRecovery'

const APP_LOCATION = '/'

export const AuthenticatedUserRouting = props => {
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)

    useEffect(() => {
        const redirectLink = /\?redirectUrl=([^?&]*)/.exec(window.location.toString())?.[1]

        if (redirectLink) {
            const parsedURL = new URL(b64ToUtf8(redirectLink))

            const isValid = parsedURL.host === window.location.host && parsedURL.protocol === window.location.protocol

            if (isValid && !parsedURL.pathname?.includes(PUBLIC_PAGES.SIGN_IN)) {
                window.location.href = parsedURL
            } else {
                props.history.push(APP_LOCATION)
            }
        }
    }, [])

    useEffect(() => {
        if (props.location.pathname === APP_LOCATION && userInfo && companyInfo) {
            const permittedPage = getOtherPermittedPage(userInfo, companyInfo)
            permittedPage && props.history.push(`/${permittedPage}`)
        }
    }, [userInfo, props.location, companyInfo])

    return (
        <CAApp>
            <Switch>
                <PermissionRoute checkPage={PAGES.BACKUPS} path={`/${PAGES.BACKUPS}`} component={BackupsRouting} />
                <PermissionRoute
                    checkPage={PAGES.RECOVERY}
                    path={`/${PAGES.RECOVERY}/:taskId/:entityId`}
                    exact
                    component={SelectiveRecovery}
                />
                <PermissionRoute checkPage={PAGES.JOBS} path={`/${PAGES.JOBS}`} component={JobsRouting} />
                <PermissionRoute
                    checkPage={PAGES.RESTORE}
                    path={`/${PAGES.RESTORE}`}
                    component={() => {
                        window.location.replace(`${window.location.origin}/App/restore`)
                        return null
                    }}
                />
                <PermissionRoute checkPage={PAGES.BILLING} path={`/${PAGES.BILLING}`} component={BillingRouting} />
                <PermissionRoute checkPage={PAGES.SUPPORT} path={`/${PAGES.SUPPORT}`} component={SupportRedirect} />
                <Route path={`/${PAGES.SETTINGS}`} component={SettingsRouting} />
                <Route path={`/${PAGES.FAQ}/:source/:id`} component={CAFaq} />
                <Route path={`/${PAGES.DOWNLOAD}/:id`} component={DownloadPage} />
                <Route path={`/${PAGES.DOWNLOAD_AUDIT}/:id/:activityType`} component={ActivityDownloadPage} />
                <Route path={`/${PAGES.DOWNLOAD_EXPORT}/:id`} component={Download} />
                <PermissionRoute
                    checkPage={PAGES.ACCOUNT_ACTIVITY}
                    path={`/${PAGES.ACCOUNT_ACTIVITY}`}
                    component={ActivityRouting}
                />
                <Route
                    path={`/${PAGES.REDIRECT}`}
                    component={() => {
                        const {search} = useLocation()
                        const query = new URLSearchParams(search)
                        const to = query.get('to')
                        to && window.location.replace(to)
                        return null
                    }}
                />
                <Route path={'*'} component={NotFound} />
            </Switch>
        </CAApp>
    )
}
