import {NewTaskSettings} from '../../types'
import {BackupEntity} from 'src/newcore/redux/modules/backups'

// for now only for MS entities, but could be extended with union type when other sources are ready
export const isEntityAvailableForSelectiveRecovery = (task?: NewTaskSettings, entity?: BackupEntity): boolean => {
    if (!entity) {
        return false
    }

    return Boolean(task?.indexStatus && entity?.lastBackupDate && entity.lastBackupDate > entity.retentionDate)
}
