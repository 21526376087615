import {PROGRESS_BOX_STATUS} from 'ca-common/common/enum/ProgressBox'

export const COMPARE_STATUS = {
    SUCCESS: 'SUCCESS',
    IN_PROCESS: 'INPROCESS',
    FAILED: 'FAILED',
    LIMIT_EXCEEDED: 'API_LIMIT_EXCEEDED',
    CANCELED: 'CANCELED'
}

export const SFC_TYPE = {
    BACKUP: 'BACKUP',
    SFC: 'SALESFORCE',
    ORG: 'ORGANIZATION'
}

export const COMPARE_RESULT_TYPE = {
    DIFF: 'DIFF',
    UNIQUE_LEFT: 'UNIQUE_FIRST',
    UNIQUE_RIGHT: 'UNIQUE_SECOND'
}

export const GH_LIKE_VIEW = {
    SIDE: 'side-by-side',
    LINE: 'line-by-line'
}

export const COMPARE_FILE_CASES = {
    LEFT: 'uniqueLeft',
    UNCHANGED: 'unchangedObjects',
    RIGHT: 'uniqueRight'
}

export const adaptCompareStatusToProgressBox = compareStatus => {
    switch (compareStatus) {
        case COMPARE_STATUS.CANCELED:
            return PROGRESS_BOX_STATUS.CANCELED

        case COMPARE_STATUS.FAILED:
            return PROGRESS_BOX_STATUS.FAILED

        case COMPARE_STATUS.IN_PROCESS:
            return PROGRESS_BOX_STATUS.IN_PROCESS

        case COMPARE_STATUS.LIMIT_EXCEEDED:
            return PROGRESS_BOX_STATUS.LIMIT_EXCEEDED

        case COMPARE_STATUS.SUCCESS:
            return PROGRESS_BOX_STATUS.SUCCESS

        default:
            return ''
    }
}
