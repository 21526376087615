import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Modal} from 'antd'

export const StyledModal = styled(Modal)`
    .ant-modal-header {
        padding: ${variables.gutterMd} ${variables.gutterMd} 0;
        border-bottom: none;
    }

    .ant-modal-title {
        font-family: ${variables.accentFont};
        font-size: ${variables.headingFontSize};
        line-height: 150%;
    }

    .ant-modal-footer {
        padding: ${variables.gutterSm} ${variables.gutterMd};

        .ant-btn-primary:not([disabled]) {
            background-color: ${variables.navyBlue};
        }

        button {
            font-family: ${variables.accentFont};
            font-weight: 700;
        }
    }
`
