import React from 'react'
import {Row, Col} from 'antd'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const Box = ({source, backupSettings, onSubmit, onCancel}: BackupAuthTemplate) => {
    const isNew = isEmpty(backupSettings)
    const {t} = useTranslation()

    return (
        <StyledBackupAuthForm
            initialValues={{alias: backupSettings.alias, account: backupSettings.account}}
            layout="horizontal"
            onFinish={values => onSubmit(values)}
        >
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>

                    <Col>
                        <p>{t('forms:backupAuth:text:BoxInfo')}</p>
                    </Col>
                    {!isNew && (
                        <Col>
                            <TaskCreatedAccount account={backupSettings.account} />
                        </Col>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:backupAuth:text:BoxSubmittingText')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
