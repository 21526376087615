import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import _ from 'lodash'

import {Button} from 'ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'

import {StyledCompare, StyledDivider} from '../StyledCompareSnapshot'
import {SelectCurrentSnapshot} from './SelectCurrentSnapshot'
import {SelectCompareSnapshot} from './SelectCompareSnapshot'

const SelectSnapshotRaw = ({previousTab, closeModal, SFCTasks, form, addDataToRequest, isMetadata}) => {
    const {t} = useTranslation()
    const {taskId} = useParams()
    const initialTaskState = _.find(_.get(SFCTasks, 'data', {}), {id: taskId})
    const [currentTask, setCurrentTask] = useState(initialTaskState)

    useEffect(() => {
        addDataToRequest({taskId})
    }, [])

    return (
        <div>
            <StyledCompare>
                <SelectCurrentSnapshot // left side
                    task={initialTaskState}
                    form={form}
                />
                <StyledDivider />
                <SelectCompareSnapshot // right side
                    isMetadata={isMetadata}
                    SFCTasks={_.get(SFCTasks, 'data')}
                    addDataToRequest={addDataToRequest}
                    currentTask={currentTask}
                    setCurrentTask={setCurrentTask}
                    form={form}
                />
            </StyledCompare>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={closeModal}> {t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" htmlType="submit">
                        {t('compareSnapshot:startCompare')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={previousTab} />
            </ModalFooter>
        </div>
    )
}

const mapStateToProps = state => ({
    SFCTasks: {
        status: state.backups.compare.tasksSFC.status,
        data: state.backups.compare.tasksSFC.response
    },
    compareMetadata: {
        status: state.backups.compare.objects.metadata.status,
        data: state.backups.compare.objects.metadata.response
    }
})

export const SelectSnapshot = connect(mapStateToProps, null)(SelectSnapshotRaw)
