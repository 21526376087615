import {InternalFieldData} from 'rc-field-form/lib/interface'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Form, FormInstance} from 'antd'

import {BOUND_FIELDS, FORM_FIELDS, RESTRICTION_RULES} from 'ca-common/features/IpRestriction/lib/constants'
import type {AddIpFormFields} from 'ca-common/features/IpRestriction/types'
import {prepareError} from './prepareErrors'

export const useOnValidateFailedProps = (form: FormInstance<AddIpFormFields>) => {
    const [fieldsWithError, setFieldsWithError] = useState<FORM_FIELDS[]>([])

    const onValidateFailed = useCallback(
        ({values, errorFields}: {values: Record<string, any>; errorFields: InternalFieldData[]}) => {
            const errors = errorFields.reduce((acc, field) => {
                const fieldName = field.name[0] as FORM_FIELDS

                if (acc[fieldName]) {
                    return acc
                }

                const fieldErrors = field.errors || []

                if (BOUND_FIELDS.includes(fieldName)) {
                    BOUND_FIELDS.forEach(boundField => (acc[boundField] = fieldErrors))
                } else {
                    acc[fieldName] = fieldErrors
                }

                return acc
            }, {} as Record<FORM_FIELDS, string[]>)

            const newFields = (Object.keys(values) as FORM_FIELDS[]).map(key => {
                return {name: key, value: values[key], errors: errors[key] || []}
            })
            form.setFields(newFields)

            setFieldsWithError(errorFields.map(field => field.name[0]) as FORM_FIELDS[])
        },
        [form]
    )

    const onValidateFailedFromBackend = useCallback(
        (data: any, values: any) => {
            form.setFields(prepareError(data, values))

            const fieldsWithError = Object.keys(data).reduce((acc, key) => {
                if (key === 'range') {
                    return acc.concat([FORM_FIELDS.FROM, FORM_FIELDS.TO])
                }

                if (key === 'securitySettings') {
                    return acc.concat(
                        values.type === RESTRICTION_RULES.LIST
                            ? [FORM_FIELDS.ADDRESSES]
                            : [FORM_FIELDS.FROM, FORM_FIELDS.TO]
                    )
                }

                return acc.concat([key as FORM_FIELDS])
            }, [] as FORM_FIELDS[])

            setFieldsWithError(fieldsWithError)
        },
        [form]
    )

    const addresses = Form.useWatch(FORM_FIELDS.ADDRESSES, form)
    const from = Form.useWatch(FORM_FIELDS.FROM, form)
    const to = Form.useWatch(FORM_FIELDS.TO, form)
    const type = Form.useWatch(FORM_FIELDS.TYPE, form)
    const description = Form.useWatch(FORM_FIELDS.DESCRIPTION, form)

    useEffect(() => {
        if (fieldsWithError.includes(FORM_FIELDS.ADDRESSES)) {
            setFieldsWithError(prev => prev.filter(field => field !== FORM_FIELDS.ADDRESSES))
        }
    }, [addresses, type])

    useEffect(() => {
        if (fieldsWithError.includes(FORM_FIELDS.FROM) || fieldsWithError.includes(FORM_FIELDS.TO)) {
            setFieldsWithError(prev => prev.filter(field => field !== FORM_FIELDS.FROM && field !== FORM_FIELDS.TO))
        }
    }, [from, to, type])

    useEffect(() => {
        if (fieldsWithError.includes(FORM_FIELDS.DESCRIPTION)) {
            setFieldsWithError(prev => prev.filter(field => field !== FORM_FIELDS.DESCRIPTION))
        }
    }, [description])

    const disabled = useMemo(() => {
        if (fieldsWithError.length) {
            return true
        }

        if (type === RESTRICTION_RULES.RANGE) {
            return !(from && to)
        }

        return !addresses
    }, [addresses, from, to, fieldsWithError, type])

    return {
        onValidateFailed,
        disabled,
        onValidateFailedFromBackend
    }
}
