import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledTitle = styled.div`
    margin-top: -${variables.gutterMd};
    margin-bottom: -${variables.gutterMd};
    padding-top: ${variables.gutterMd};
    padding-bottom: ${variables.gutterMd};
    font-size: 20px;
    color: ${variables.brandDark};
    font-family: ${variables.accentFont};
`
