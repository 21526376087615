import React from 'react'
import {Trans} from 'react-i18next'
import {StyledSeats} from './StyledSeats'

export const Seats = ({seats}) => (
    <StyledSeats>
        <Trans i18nKey="backups:seats" values={{seats}}>
            <span>{{seats}}</span>
        </Trans>
    </StyledSeats>
)
