import {post} from 'src/newcore/utils/rest'

import {setCancellingStatus} from 'src/newcore/features/Jobs/redux'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'

const JOB_CANCELING = 'JOB_CANCELING'

const JOB_CANCELING_API = 'cancelTask'
const jobCancelingWrapper = fetchWrapperRT(JOB_CANCELING, async data => await post(JOB_CANCELING_API, data))

export const jobCanceling = (id: any) => (dispatch: AppDispatch) => {
    return dispatch(jobCancelingWrapper.fetcher(id)).then(() => {
        return dispatch(setCancellingStatus({id: id?.taskId}))
    })
}
