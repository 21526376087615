import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {RadioButton, RadioGroup} from 'ca-common/ui-lib/components/RadioBox'
import {GRACE_PERIOD_TAKS_CREATE_TYPE} from 'ca-common/common/enum'
import {Button} from 'ca-common/ui-lib/atoms/Button'

import {StyledFormItem, StyledForm, StyledWrapper, SlyledFoorter} from './StyledGracePeriodNotification'

type GracePeriodNotificationFormProps = {
    handleSubmit: (arg0: any) => void
    closeModal: () => void
    loading: boolean
}

export const GracePeriodNotificationForm = ({loading, handleSubmit, closeModal}: GracePeriodNotificationFormProps) => {
    const {t} = useTranslation()

    return (
        <StyledForm onFinish={handleSubmit}>
            <StyledWrapper>
                <p>{t('gracePeriodNotification:content')}</p>
                <StyledFormItem name="gracePeriodCreateType" initialValue={GRACE_PERIOD_TAKS_CREATE_TYPE.REDO}>
                    <RadioGroup>
                        <Tooltip title={t('gracePeriodNotification:gracePeriodCreateTypeUndo.title')}>
                            <RadioButton
                                value={GRACE_PERIOD_TAKS_CREATE_TYPE.UNDO}
                                key={GRACE_PERIOD_TAKS_CREATE_TYPE.UNDO}
                            >
                                {t('gracePeriodNotification:gracePeriodCreateTypeUndo.text')}
                            </RadioButton>
                        </Tooltip>
                        <Tooltip title={t('gracePeriodNotification:gracePeriodCreateTypeRedo.title')}>
                            <RadioButton
                                value={GRACE_PERIOD_TAKS_CREATE_TYPE.REDO}
                                key={GRACE_PERIOD_TAKS_CREATE_TYPE.REDO}
                            >
                                {t('gracePeriodNotification:gracePeriodCreateTypeRedo.text')}
                            </RadioButton>
                        </Tooltip>
                    </RadioGroup>
                </StyledFormItem>
            </StyledWrapper>
            <SlyledFoorter>
                <Button htmlType="reset" disabled={loading} onClick={() => closeModal()}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('forms:common:actions:ok')}
                </Button>
            </SlyledFoorter>
        </StyledForm>
    )
}
