import {createAction, createReducer} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'

export const setBlockedOU = createAction<TODO_ANY[]>('SET_BLOCKED_OU')

export const clearBlockedOU = createAction('CLEAR_BLOCKED_OU')

export const blockedOUReducer = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearBlockedOU, () => [])
    builder.addCase(setBlockedOU, (state, action) => action.payload)
})
