import {useHistory} from 'react-router'
import {useMemo} from 'react'

import {getUrlParams} from 'ca-common/utils/url'
import {CURRENCIES} from 'ca-common/constants'

import {useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

export const useCurrentCurrency = () => {
    const userInfo = useAppSelector(userInfoSelector)
    const history = useHistory()

    const currencyFromUrl = getUrlParams(history.location.search)?.currency

    return useMemo(() => {
        if (userInfo?.isSubscribed) {
            return userInfo?.currency as CURRENCIES
        }

        return (currencyFromUrl || userInfo?.currency) as CURRENCIES
    }, [currencyFromUrl, userInfo?.currency, userInfo?.isSubscribed])
}
