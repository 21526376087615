import React, {useEffect, useState} from 'react'

import {
    changeConfig,
    changeData,
    nextStep,
    wizardConfigSelector,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import {useGetMSTotalEntitiesQuery} from 'src/newcore/features/OneClickActivation/redux'
import {STEPS} from 'src/newcore/features/OneClickActivation/lib/constants'
import type {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'

import {OCAProcessingStepCommon} from 'src/newcore/features/OneClickActivation/orgamisms'

export const OCAProcessingStep = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const wizardConfig = useAppSelector(wizardConfigSelector)
    const {data: totalEntities} = useGetMSTotalEntitiesQuery(wizardData.id)

    const [isStepChanged, setIsStepChanged] = useState(false)

    useEffect(() => {
        if (totalEntities && !isStepChanged) {
            const disabledSteps = wizardConfig.disabledSteps.includes(STEPS.PROCESSING)
                ? wizardConfig.disabledSteps
                : wizardConfig.disabledSteps.concat(STEPS.PROCESSING)

            setIsStepChanged(true)
            dispatch(changeConfig({disabledSteps}))
            dispatch(nextStep())
        }
    }, [totalEntities])

    useEffect(() => {
        if (!wizardData.secondProcessingStartedAt) {
            dispatch(changeData({secondProcessingStartedAt: Date.now()}))
        }
    }, [wizardData.secondProcessingStartedAt])

    return <OCAProcessingStepCommon />
}
