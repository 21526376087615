import _ from 'lodash'
import {createAction, createReducer} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'

const SET_EXCLUDE_EMAILS = 'SET_EXCLUDE_EMAILS'
export const setExcludeEmails = createAction<TODO_ANY[]>(SET_EXCLUDE_EMAILS)

const CLEAR_EXCLUDE_EMAILS = 'CLEAR_EXCLUDE_EMAILS'
export const clearExcludeEmails = createAction(CLEAR_EXCLUDE_EMAILS)

export const excludeEmailsReducer = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearExcludeEmails, () => [])
    builder.addCase(setExcludeEmails, (state, action) => {
        return _.includes(state, action.payload)
            ? _.filter(state, n => n !== action.payload)
            : [...state, action.payload]
    })
})
