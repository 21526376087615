import {SOURCE} from 'ca-common/common/enum'
import {track} from 'src/newcore/utils/mixpanel'

const services = {
    [SOURCE.MS365]: 'MS Exchange',
    [SOURCE.MS365GROUPS]: 'MS Groups & Teams',
    [SOURCE.SHAREPOINT_MULTI]: 'MS Sharepoint',
    [SOURCE.SHAREPOINT]: 'MS Sharepoint',
    [SOURCE.ONEDRIVE]: 'MS OneDrive',
    [SOURCE.GOOGLEAPPS]: 'Google Workspace'
} as Record<SOURCE, string>

export enum TypesOfRules {
    ACTIVE_DIRECTORY = 'By MS Active Directory',
    GROUPS = 'By MS Groups',
    SHAREPOINT = 'By ShP properties',
    IMPORT_CSV = 'Import CSV',
    OU = 'Organizational Unit'
}

export const trackBulkActivation = (eventName: string, source?: SOURCE, typeOfRules?: string) => {
    const trackData = {} as Record<string, string>

    if (source) {
        trackData['Service'] = services[source]
    }

    if (typeOfRules) {
        trackData['Type of activation rules'] = typeOfRules
    }

    track(eventName, trackData)
}
