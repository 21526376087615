import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Content = styled.section`
    margin: 0 ${variables.gutterSm};
    padding: ${variables.gutterLg} 0;
    border-radius: ${variables.borderRadiusBase};
    max-height: 682px;
    flex-grow: 1;

    @media screen and (min-width: ${variables.screenMd}) {
        padding: ${variables.gutterXxl} ${variables.gutterLg};
        max-width: 720px;
        margin: auto;
    }
`

export const ErrorContent = styled(Content)`
    margin-top: ${variables.gutterLg};

    @media screen and (min-width: ${variables.screenMd}) {
        margin-top: ${variables.gutterXxl};
        padding: 80px ${variables.gutterLg};
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin: auto;
        padding: 120px ${variables.gutterLg};
    }
`

export const LandingHeader = styled.h1`
    margin-bottom: ${variables.gutterSm};
    color: ${variables.text};
    font-family: ${variables.publicPageFont};
    font-style: normal;
    font-weight: bold;
    font-size: ${variables.subTitleFontSize};
    text-align: center;

    @media screen and (min-width: ${variables.screenMd}) {
        margin-bottom: ${variables.gutterMd};
        font-size: ${variables.mainTitleFontSize};
    }
`

export const ErrorMessage = styled.div`
    margin-bottom: auto;
    padding: ${variables.gutterSm};
    color: ${variables.text};
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${variables.fontSizeBase};
    line-height: 20px;
    text-align: center;

    @media screen and (min-width: ${variables.screenMd}) {
        padding: ${variables.gutterLg};
        font-size: 20px;
        line-height: 32px;
    }
`
