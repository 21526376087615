import React from 'react'
import {Row, Col} from 'antd'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const MS365GROUPS = ({source, backupSettings, onSubmit, onCancel, loading}: BackupAuthTemplate): JSX.Element => {
    const {t} = useTranslation()
    const isNew = isEmpty(backupSettings)

    return (
        <StyledBackupAuthForm
            initialValues={{alias: backupSettings.alias, account: backupSettings.account}}
            layout="horizontal"
            onFinish={values => onSubmit(values)}
        >
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>{t('forms:backupAuth:text:MS365GroupsOauthInfo')}</Col>
                    {!isNew && (
                        <Col>
                            <TaskCreatedAccount account={backupSettings.account} />
                        </Col>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                    {t('forms:common:actions:authenticate')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
