import React, {Component} from 'react'
import {Field} from 'redux-form'
import PropTypes from 'prop-types'

import {FormGroup} from '../FormGroup'
import {RadioButton} from '../RadioButton'
import 'ca-common/ui-lib/components/RadioGroup/RadioGroup.scss'

export class RadioGroup extends Component {
    static propType = {
        options: PropTypes.arrayOf([
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
                tooltip: PropTypes.string
            })
        ]).isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }

    render = () => {
        const {
            props: {name, label, key, options, inline, ...props}
        } = this

        return (
            <FormGroup modifier={`radio-group ${inline && 'inline'}`}>
                {label && <label className="ca-form-group__label">{label}</label>}

                {Array.isArray(options)
                    ? options.map((option, index) => {
                          const htmlFor = `${name}-r${index}`

                          return (
                              <Field
                                  name={name}
                                  key={key || htmlFor}
                                  type={'radio'}
                                  component={RadioButton}
                                  option={option}
                                  label={option.label}
                                  value={option.value}
                                  htmlFor={htmlFor}
                                  {...props}
                              />
                          )
                      })
                    : null}
            </FormGroup>
        )
    }
}
