import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox, Col, Form, Row, Tooltip} from 'antd'

import {Button} from 'ca-common/ui-lib/atoms'
import {useAppSelector} from 'src/newcore/components/Root'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {
    StyledCard,
    TooltipWrapper,
    StyledFooter,
    StyledDestroyHeader,
    StyledDestroyDescription,
    StyledAccountDestroyForm,
    StyledDestroyCheckbox
} from './StyledAccountForm'

type AccountDestroyFormType = {
    onSubmit: (arg: any) => void
    isSubscribed: boolean
    isFinra: boolean
}
export const AccountDestroyForm = (props: AccountDestroyFormType) => {
    const {t} = useTranslation()
    const {onSubmit, isSubscribed, isFinra} = props
    const [disable, setDisable] = useState(true)
    const companyName = useAppSelector(companyInfoSelector)?.name
    const [accountDestroyForm] = Form.useForm<AccountDestroyFormType>()
    return (
        <StyledAccountDestroyForm
            onFinish={onSubmit}
            colon={false}
            form={accountDestroyForm}
            onValuesChange={() => setDisable(!disable)}
        >
            {!isSubscribed || (
                <Tooltip title={isFinra && t('finra:accountTooltip')}>
                    <TooltipWrapper disabled={isFinra}>
                        <StyledCard>
                            <StyledDestroyHeader>{t('accountPage:terminate:title', [companyName])}</StyledDestroyHeader>
                            <StyledDestroyDescription>
                                {t('accountPage:terminate:text', [companyName])}
                            </StyledDestroyDescription>
                            <Row>
                                <Col>
                                    <StyledDestroyCheckbox>
                                        <Form.Item
                                            name={'unsubscribe'}
                                            label={t('accountPage:terminate:checkbox', [companyName])}
                                            colon={false}
                                            htmlFor={'unsubscribe'}
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </StyledDestroyCheckbox>
                                </Col>
                            </Row>
                            <StyledFooter>
                                <Button htmlType="submit" disabled={disable} type="primary">
                                    {t('accountPage:actions:removeAccount')}
                                </Button>
                            </StyledFooter>
                        </StyledCard>
                    </TooltipWrapper>
                </Tooltip>
            )}
        </StyledAccountDestroyForm>
    )
}
