import React from 'react'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {CARowWrapper} from 'ca-common/ui-lib/components/TableElements'

export const CARowLoader = () => (
    <CARowWrapper modifier="loading">
        <Spinner />
    </CARowWrapper>
)
