import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledTerms = styled.div`
    font-size: ${variables.fontSizeBase};
    margin-top: ${variables.gutterXs};

    a {
        color: ${variables.signupText};
        text-decoration: none;
        font-weight: 700;
    }

    @media screen and (min-width: ${variables.screenDesktop}) {
        margin-bottom: ${variables.gutterMd};
        font-size: ${variables.fontSizeBase};
    }
`
