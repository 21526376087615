import React, {useEffect, useRef} from 'react'

import type {MS365SubSource} from 'ca-common/types'
import {SOURCE} from 'ca-common/common/enum'

import {RecoveryTable, RecoveryTableOwnProps} from 'src/newcore/features/Recovery/organisms/RecoveryTable'
import {FolderTree, FolderTreeProps} from 'src/newcore/features/Recovery/organisms/FolderTree'
import {CHECK_STATE, TreeMethods} from 'src/newcore/organisms/CustomTree'
import {getRecoveryTableColumns} from 'src/newcore/features/Recovery/templates/tablesConfig'
import {TablePlaceholder} from 'src/newcore/features/Recovery/molecules/TablePlaceholder'
import type {CheckTablePayload} from 'src/newcore/features/Recovery/localState/actionsLocalState'
import {StyledRecoverySplitter} from './StyledRecoveryContent'

type RecoveryContentOwnProps = {
    setTableItem: ({keys, checkAll}: CheckTablePayload) => void
    selectedSubSource: MS365SubSource
    prevSelectedSubSource: MS365SubSource | undefined
    restoredFolders: Record<string, boolean>
    hasPermissionToExpand?: boolean
    source: SOURCE
}

type RecoveryContentProps = Omit<RecoveryTableOwnProps, 'onTableItemChecked' | 'columns' | 'tablePlaceholder'> &
    Omit<FolderTreeProps, 'parent'> &
    RecoveryContentOwnProps

export const RecoveryContent = ({
    checked,
    onCheckHeader,
    treeData,
    treeSize,
    checkedFolders,
    setCheckedFolders,
    onChangeTable,
    selectedFolder,
    setSelectedFolder,
    tableData,
    tableSize,
    setTableItem,
    checkedTableItems,
    loadingTable,
    loadingFolders,
    selectedSubSource,
    prevSelectedSubSource,
    restoredFolders,
    hasPermissionToExpand,
    source
}: RecoveryContentProps): JSX.Element => {
    const childRef = useRef<TreeMethods>()

    const onTableItemChecked = ({keys, checkAll, checkedId, uncheckedId, itemsInfo}: CheckTablePayload) => {
        setTableItem({keys, checkAll, checkedId, uncheckedId, itemsInfo})
        childRef.current?.setCheckedKey(selectedFolder, checkAll, checkAll)
    }

    useEffect(() => {
        if (prevSelectedSubSource !== undefined && selectedSubSource !== prevSelectedSubSource) return
        if (loadingFolders) return
        if (checked === CHECK_STATE.CHECKED) {
            childRef.current?.onCheckAll(true)
        }
        if (checked === CHECK_STATE.UNCHECKED) {
            childRef.current?.onCheckAll(false)
        }
        if (checked === CHECK_STATE.PARTIAL) {
            childRef.current?.setCheckedKeys(checkedFolders)
        }
    }, [checked, loadingFolders])

    return (
        <StyledRecoverySplitter
            sizes={[25, 75]}
            minSize={[200, 200]}
            expandToMin={true}
            gutterSize={4}
            snapOffset={10}
            dragInterval={3}
            direction="horizontal"
            cursor="col-resize"
        >
            <FolderTree
                checked={checked}
                onCheckHeader={onCheckHeader}
                treeData={treeData}
                treeSize={treeSize}
                restoredFolders={restoredFolders}
                checkedFolders={checkedFolders}
                setCheckedFolders={setCheckedFolders}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                parent={childRef}
                loadingFolders={loadingFolders}
            />
            <RecoveryTable
                source={source}
                selectedFolder={selectedFolder}
                onChangeTable={onChangeTable}
                checkedTableItems={checkedTableItems}
                tableData={tableData}
                tableSize={tableSize}
                treeSize={treeSize}
                onTableItemChecked={onTableItemChecked}
                loadingTable={loadingTable}
                columns={getRecoveryTableColumns(selectedSubSource)}
                selectedSubSource={selectedSubSource}
                hasPermissionToExpand={hasPermissionToExpand}
                tablePlaceholder={
                    <TablePlaceholder
                        loading={loadingTable || loadingFolders}
                        tableSize={tableSize}
                        treeSize={treeSize}
                        selectedFolder={selectedFolder}
                        selectedSubSource={selectedSubSource}
                    />
                }
            />
        </StyledRecoverySplitter>
    )
}
