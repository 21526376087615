import React, {Fragment} from 'react'
import Icon from '@ant-design/icons'
import {Checkbox, Tooltip, Form, CheckboxProps} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {SOURCE} from 'ca-common/common/enum'

type CustomCheckboxProps = {
    entityName: string
} & CheckboxProps

const CustomCheckbox = ({entityName, ...passProps}: CustomCheckboxProps) => {
    const {t} = useTranslation()

    return (
        <Fragment>
            <Checkbox {...passProps}>{t('forms:backupSettings:fields:detectNewAccounts:label', {entityName})}</Checkbox>
            <Tooltip title={t('forms:backupSettings:fields:detectNewAccounts:tooltip', {entityName})}>
                <Icon component={Info} />
            </Tooltip>
        </Fragment>
    )
}

type DetectNewAccountsProps = {
    detectNewAccounts?: boolean
    source: SOURCE
    namePrefix?: string
}

export const DetectNewAccounts = ({detectNewAccounts, source, namePrefix = ''}: DetectNewAccountsProps) => {
    const entityName = getSvcAccountsDescription(source, true).toLowerCase()
    const name = `${namePrefix ? `${namePrefix}.` : ''}detectNewAccounts`

    return (
        <Form.Item name={name} initialValue={detectNewAccounts} valuePropName="checked">
            <CustomCheckbox entityName={entityName} />
        </Form.Item>
    )
}
