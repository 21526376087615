import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledTableActions = styled.div`
    display: flex;
    align-items: center;

    .anticon + .anticon {
        margin-left: ${variables.gutterXs};
    }
`

export const StyledIcon = styled(Icon)`
    font-size: 24px;
`

export const ModalWrapper = styled.div`
    margin: -${variables.gutterMd};
`

export const StyledRemove = styled.div``

export const StyledHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${variables.gutterMd};
`

export const StyledHeader = styled.span`
    font-family: ${variables.accentFont};
    color: ${variables.text};
    font-size: ${variables.headingFontSize};
`

export const RemoveIcon = styled(Icon)`
    font-size: 32px;
    color: ${variables.red};
`

export const StyledDescription = styled.span`
    font-family: ${variables.mainFont};
    color: ${variables.navyBlue};
    font-weight: 600;
`
