import {SOURCE} from 'ca-common/common/enum'

import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

import {OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {ENTITIES_OPTION, FIELDS, SETTINGS_OPTION} from 'src/newcore/features/OneClickActivation/lib/constants'
import {checkIsDefaultTaskSettings} from 'src/newcore/features/OneClickActivation/lib/checkIsDefaultTaskSettings'

const friendlyServiceName = {
    [SOURCE.MS365]: 'MS Exchange',
    [SOURCE.SHAREPOINT_MULTI]: 'MS SharePoint',
    [SOURCE.ONEDRIVE]: 'MS OneDrive',
    [SOURCE.MS365GROUPS]: 'MS Groups & Teams'
} as Record<SOURCE, string>

export const trackOCA = (wizardData: OCAWizardData, enabledServices: SOURCE[] = []) => {
    const {
        servicesWithActivateAllEntities,
        servicesWithActivateSelectedEntities,
        servicesWithDefaultSettings,
        servicesWithCustomSettings
    } = enabledServices.reduce(
        (acc, service) => {
            if (wizardData[service]!) {
                if (checkIsDefaultTaskSettings(wizardData, [service])) {
                    acc.servicesWithDefaultSettings.push(friendlyServiceName[service])
                } else {
                    acc.servicesWithCustomSettings.push(friendlyServiceName[service])
                }

                if (wizardData[service]?.enabledEntitiesBitSet.includes('0')) {
                    acc.servicesWithActivateSelectedEntities.push(friendlyServiceName[service])
                } else {
                    acc.servicesWithActivateAllEntities.push(friendlyServiceName[service])
                }
            }

            return acc
        },
        {
            servicesWithActivateAllEntities: [],
            servicesWithActivateSelectedEntities: [],
            servicesWithDefaultSettings: [],
            servicesWithCustomSettings: []
        } as Record<string, string[]>
    )

    const data = {
        'Services Included': enabledServices.map(s => friendlyServiceName[s]),
        'Entities Activation Option':
            wizardData[FIELDS.ENTITIES_OPTION] === ENTITIES_OPTION.ACTIVATE_ALL_ENTITIES
                ? 'Activate All'
                : 'Select Entities',
        'Task Settings Option':
            wizardData[FIELDS.SETTINGS_OPTION] === SETTINGS_OPTION.USE_DEFAULT_SETTINGS
                ? 'Default Settings'
                : 'Customize Settings',
        'Services Activate All Entities': servicesWithActivateAllEntities,
        'Services Activate Some Entities': servicesWithActivateSelectedEntities,
        'Services With Default Settings': servicesWithDefaultSettings,
        'Services With Customized Settings': servicesWithCustomSettings
    } as Record<string, any>

    track(EVENT_NAMES.OCA_ACTIVATION, data)
}
