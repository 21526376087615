import React, {useState} from 'react'
import {PlusOutlined} from '@ant-design/icons'
import {Modal, Form, FormInstance} from 'antd'
import {useTranslation} from 'react-i18next'
import {InternalFieldData} from 'rc-field-form/lib/interface'

import CloseCircle from 'ca-common/icons/restrictions/CloseCircle.svg'

import {AddIpFormFields} from 'ca-common/features/IpRestriction/types'
import {useOnValidateFailedProps} from 'ca-common/features/IpRestriction/lib'

import {
    StyledButton,
    StyledButtonText,
    ModalWrapper,
    StyledIcon,
    StyledWrapper,
    StyledErrorHeader,
    StyledErrorText
} from './StyledAddIpAddress'

type AddIpAddressProps = {
    addNewForm: (form: FormInstance<AddIpFormFields>) => JSX.Element
    addIpAddressHandler: (values: AddIpFormFields) => Promise<any>
    isLimitReached: boolean
}

export const AddIpAddress = (props: AddIpAddressProps): JSX.Element => {
    const [visible, setVisible] = useState(false)
    const {addNewForm, addIpAddressHandler, isLimitReached} = props
    const [form] = Form.useForm<AddIpFormFields>()
    const {t} = useTranslation()
    const [submitting, setSubmitting] = useState(false)
    const [visibleLimitReached, setVisibleLimitReached] = useState(false)

    const onClickHandler = () => {
        if (isLimitReached) {
            setVisibleLimitReached(true)
        } else {
            setVisible(true)
        }
    }

    const {onValidateFailed, disabled, onValidateFailedFromBackend} = useOnValidateFailedProps(form)

    const onOkHandler = () => {
        setSubmitting(true)
        form.validateFields()
            .then((values: Record<string, any>) => {
                addIpAddressHandler(values as AddIpFormFields)
                    .then(() => {
                        setSubmitting(false)
                        setVisible(false)
                        form.resetFields()
                    })
                    .catch((error: {data: Record<string, any>}) => {
                        setSubmitting(false)

                        if (error?.data) {
                            onValidateFailedFromBackend(error.data, values)
                        }
                    })
            })
            .catch((args: {values: Record<string, any>; errorFields: InternalFieldData[]}) => {
                onValidateFailed(args)
                setSubmitting(false)
            })
    }

    const onCancelHandler = () => {
        setVisible(false)
        form.resetFields()
    }

    const handleCloseLimitReached = () => {
        setVisibleLimitReached(false)
    }

    return (
        <React.Fragment>
            <StyledButton icon={<PlusOutlined />} onClick={onClickHandler}>
                <StyledButtonText>{t('ipRestrictions:addAddress:buttonAdd')}</StyledButtonText>
            </StyledButton>
            <Modal
                open={visible}
                closable={false}
                okText="Save"
                cancelButtonProps={{type: 'text'}}
                okButtonProps={{loading: submitting, disabled}}
                onOk={onOkHandler}
                onCancel={onCancelHandler}
                width={640}
                destroyOnClose
                centered
            >
                <ModalWrapper>{addNewForm(form)}</ModalWrapper>
            </Modal>
            <Modal
                centered
                open={visibleLimitReached}
                closable
                width={520}
                cancelButtonProps={{style: {display: 'none'}}}
                bodyStyle={{display: 'flex'}}
                onCancel={handleCloseLimitReached}
                onOk={handleCloseLimitReached}
            >
                <React.Fragment>
                    <StyledIcon component={CloseCircle} />
                    <StyledWrapper>
                        <StyledErrorHeader>{t('ipRestrictions:limitExceededHeader')}</StyledErrorHeader>
                        <StyledErrorText>{t('responseErrors:tooManyIpRules')}</StyledErrorText>
                    </StyledWrapper>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    )
}
