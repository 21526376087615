import Icon from '@ant-design/icons'
import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledWrapper = styled.section<{isSelective?: boolean}>`
    display: flex;
    flex-direction: column;
    max-width: ${props => (props.isSelective ? '464px' : '410px')};
`

export const StyledSwitchWrapper = styled.div<{isSelective?: boolean}>`
    width: ${props => (props.isSelective ? '460px' : '338px')};
`

export const StyledSubTitle = styled.h5`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};

    margin-bottom: ${variables.gutterSm};
`

export const InfoIcon = styled(Icon)`
    margin-left: ${variables.gutterXs};
    width: 12px;
    font-size: ${variables.fontSizeSecondary};
    cursor: pointer;
`

export const StyledNote = styled.div`
    margin-top: auto;
    margin-bottom: ${variables.gutterMd};

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.navyAsphalt};

    white-space: nowrap;
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${variables.interaction20};
`
