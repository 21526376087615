import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {transparentize} from 'polished'

import variables from 'ca-common/variables'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'

const getColorForIcon = (status: ACTIVITY_STATUS) => {
    switch (status) {
        case ACTIVITY_STATUS.SUCCEED:
            return variables.systemGreen
        case ACTIVITY_STATUS.FAILED:
            return variables.red
        case ACTIVITY_STATUS.PARTIALLY_SUCCEED:
            return variables.orange
        default:
            return variables.navyBlue
    }
}

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: ${variables.gutterXs} 0 ${variables.gutterXs} 0;
`
type StyledIconProps = {
    status: ACTIVITY_STATUS
}

export const StyledIcon = styled(Icon)<StyledIconProps>`
    svg {
        fill: none;
    }
    font-size: 22px;
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid ${({status}) => transparentize(0.7, getColorForIcon(status))};
    display: flex;
    background: ${({status}) => transparentize(0.97, getColorForIcon(status))};
    color: ${({status}) => getColorForIcon(status)};
    align-items: center;
    margin-right: ${variables.gutterXs};
    &:last-child {
        margin-right: 0;
    }
`
