import styled from 'styled-components'
import {rgba} from 'polished'

import {Table} from 'ca-common/ui-lib/organisms/Table'
import variables from 'ca-common/variables'

export const StyledTable = styled(Table)`
    .ant-table table tr:nth-child(2n) {
        background-color: ${rgba(variables.brightBlue, 0.02)};
    }

    .ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
        display: none;
    }

    .ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
    .ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
        display: none;
    }
`
