import {createSelector, createSlice} from '@reduxjs/toolkit'

import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {GetSitesWithMatchedConditionsResponse} from 'src/newcore/components/BulkActivation/SharepointUrl/types'
import {postLessData} from 'src/newcore/utils/rest'
import {AppState} from 'src/newcore/components/Root'

const GET_SITES_WITH_MATCHED_CONDITIONS = 'GET_SITES_WITH_MATCHED_CONDITIONS'
const GET_SITES_WITH_MATCHED_CONDITIONS_API = 'getSitesWithMatchedConditions'

const getSitesWithMatchedConditionsWrapper = fetchWrapperRT<GetSitesWithMatchedConditionsResponse>(
    GET_SITES_WITH_MATCHED_CONDITIONS,
    async data => await postLessData(GET_SITES_WITH_MATCHED_CONDITIONS_API, data),
    {
        after: (response, state) => {
            if (response.page !== 1) {
                return {
                    ...response,
                    data: [...(state.response?.data || []), ...response.data]
                }
            }
            return response
        }
    }
)

export const getSitesWithMatchedConditions = getSitesWithMatchedConditionsWrapper.fetcher

const bulkActivationSPSlice = createSlice({
    name: 'bulkActivationSP',
    initialState: InitFetchedState as FetchedState<GetSitesWithMatchedConditionsResponse>,
    reducers: {
        clearBulkActivationSP: () => InitFetchedState as FetchedState<GetSitesWithMatchedConditionsResponse>
    },
    extraReducers: builder => {
        getSitesWithMatchedConditionsWrapper.makeReducers(builder)
    }
})

export const {clearBulkActivationSP} = bulkActivationSPSlice.actions

export const bulkActivationSP = bulkActivationSPSlice.reducer

export const matchedSitesSelector = createSelector(
    (state: AppState) => state.backups.bulkActivation.bulkActivationSP,
    data => {
        return data
    }
)
