import {post, getPlain, postLessData} from 'src/newcore/utils/rest'
import {combineReducers, createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'

import {SOURCE} from 'ca-common/common/enum'
import {TODO_ANY} from 'ca-common/types'
import {getLocalDateFromUTC} from 'ca-common/utils/datetime'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SFC_DATA_OBJECTS = 'GET_SFC_DATA_OBJECTS'
const getCompareDataObjectsWrapper = fetchWrapperRT(
    GET_SFC_DATA_OBJECTS,
    async data => await postLessData('getSfcObjects', data)
)
export const getCompareDataObjects = getCompareDataObjectsWrapper.fetcher

const GET_SFC_METADATA_OBJECTS = 'GET_SFC_METADATA_OBJECTS'
const getCompareMetadataObjectsWrapper = fetchWrapperRT(
    GET_SFC_METADATA_OBJECTS,
    async data => await postLessData('getSfcObjects', data)
)
export const getCompareMetadataObjects = getCompareMetadataObjectsWrapper.fetcher

const transformDataDateToLocal = (response: TODO_ANY) => {
    const newData = setDateToLocal(response.data)

    return {
        ...response,
        data: newData
    }
}
const setDateToLocal = (snapshotList: TODO_ANY) =>
    _.map(snapshotList, snapshot => ({
        ...snapshot,
        dateTime: getLocalDateFromUTC(snapshot.dateTime)
    }))

const GET_SNAPSHOT_CALENDAR = 'GET_SNAPSHOT_CALENDAR' // year, month, taskId
const getSnapshotCalendarWrapper = fetchWrapperRT(
    GET_SNAPSHOT_CALENDAR,
    async data => await post('getSnapshotsCalendar', data),
    {
        after: transformDataDateToLocal
    }
)
export const getSnapshotCalendar = getSnapshotCalendarWrapper.fetcher

const snapshotsSlice = createSlice({
    name: 'snapshots',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getSnapshotCalendarWrapper.makeReducers(builder)
})

const GET_SFC_TASKS = 'GET_SFC_TASKS'
const getSFCTasksWrapper = fetchWrapperRT(GET_SFC_TASKS, async data => await post('getBackupTasks', data)) // {source: 'SFC'}
export const getSFCTasks = (): TODO_ANY =>
    getSFCTasksWrapper.fetcher({
        source: SOURCE.SFC
    })

const tasksSFCSlice = createSlice({
    name: 'tasksSFC',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getSFCTasksWrapper.makeReducers(builder)
})

const START_COMPARE_TASK = 'START_COMPARE_TASK'
const startCompareTaskWrapper = fetchWrapperRT(START_COMPARE_TASK, async (data, {rejectWithValue}) => {
    try {
        return await postLessData('startCompareTask', data)
    } catch (err: any) {
        return rejectWithValue(err.data)
    }
})
export const startCompareTask = startCompareTaskWrapper.fetcher

const GET_COMPARE_RESULTS = 'GET_COMPARE_RESULTS'
const getCompareResultsWrapper = fetchWrapperRT(
    GET_COMPARE_RESULTS,
    async data => await postLessData('getCompareResults', data)
)
export const getCompareResults = getCompareResultsWrapper.fetcher

const compareResultsSlice = createSlice({
    name: 'compareResults',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getCompareResultsWrapper.makeReducers(builder)
})

const GET_COMPARE_RESULT = 'GET_COMPARE_RESULT'
const getCompareResultWrapper = fetchWrapperRT(
    GET_COMPARE_RESULT,
    async data => await postLessData('getCompareResult', data)
)
export const getCompareResult = getCompareResultWrapper.fetcher

const compareResultSlice = createSlice({
    name: 'compareResult',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        cancelCompareTask: (state, action) => {
            state.response.data = _.reject(state.response.data, {taskId: action.payload})
        },
        clearCompareResults: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getCompareResultWrapper.makeReducers(builder)
})

export const {cancelCompareTask, clearCompareResults} = compareResultSlice.actions

const GET_DOWNLOAD_FILE_LINK = 'GET_DOWNLOAD_FILE_LINK'
const getDownloadFileLinkWrapper = fetchWrapperRT(GET_DOWNLOAD_FILE_LINK, async (data, {rejectWithValue}) => {
    try {
        return await post('downloadComparedFiles', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const getDownloadFileLink = getDownloadFileLinkWrapper.fetcher

const dataSlice = createSlice({
    name: 'data',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSFCObjects: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getCompareDataObjectsWrapper.makeReducers(builder)
})

// export const {clearSFCObjects} = dataSlice.actions //TODO create new name and fix usage

const metadataSlice = createSlice({
    name: 'metadata',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearSFCObjects: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getCompareMetadataObjectsWrapper.makeReducers(builder)
})

export const compareObjects = combineReducers({
    metadata: metadataSlice.reducer,
    data: dataSlice.reducer
})

export const {clearSFCObjects} = metadataSlice.actions

const GET_COMPARE_METADATAFILES = 'GET_COMPARE_METADATAFILES'
const GET_COMPARE_METADATAFILES_API = 'getCompareMetadataFiles'
const getCompareMetadataFilesWrapper = fetchWrapperRT(
    GET_COMPARE_METADATAFILES,
    async data => await postLessData(GET_COMPARE_METADATAFILES_API, data)
)
export const getCompareMetadataFiles = getCompareMetadataFilesWrapper.fetcher

const compareMetadataFilesSlice = createSlice({
    name: 'compareMetadataFiles',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getCompareMetadataFilesWrapper.makeReducers(builder)
})

const GET_COMPARE_FOR_FILE = 'GET_COMPARE_FOR_FILE'
const GET_COMPARE_FOR_FILE_API = 'getCompareForFile'
const getCompareForFileWrapper = fetchWrapperRT(GET_COMPARE_FOR_FILE, async (data, {rejectWithValue}) => {
    try {
        return await getPlain(GET_COMPARE_FOR_FILE_API, data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const getCompareForFile = getCompareForFileWrapper.fetcher

export const compareReducers = combineReducers({
    objects: compareObjects,
    tasksSFC: tasksSFCSlice.reducer,
    snapshots: snapshotsSlice.reducer,
    compareResult: compareResultSlice.reducer,
    compareResults: compareResultsSlice.reducer,
    compareMetadataFiles: compareMetadataFilesSlice.reducer
})
