import {SOURCE, TASK_STATUS} from 'ca-common/common/enum'
import omit from 'lodash/omit'

export const getTaskStatuses = (source: SOURCE | ''): Partial<typeof TASK_STATUS> => {
    switch (source) {
        case SOURCE.SHAREPOINT_MULTI:
            return TASK_STATUS
        default:
            return omit(TASK_STATUS, 'DUPLICATE')
    }
}
