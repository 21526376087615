import React from 'react'
import {withTranslation} from 'react-i18next'
import {reduxForm} from 'redux-form'

import {FORMS} from 'ca-common/constants'
import {CAFormPage, CAFormPageFooter, CAFormPageSection} from 'ca-common/ui-lib/components/FormPage'
import {CAButton} from 'ca-common/ui-lib/components/Button'

@withTranslation()
class RecoveryModalFormRaw extends React.Component {
    render() {
        const {t, handleSubmit, closeModal, account, children, errorStatus} = this.props

        return (
            <CAFormPage>
                <form onSubmit={handleSubmit}>
                    <CAFormPageSection modifier="entity">
                        <p>
                            {t('forms:recovery:account')} <b>{account}</b>
                        </p>
                    </CAFormPageSection>

                    {children}

                    <CAFormPageSection modifier="export-info">
                        <p>{t('forms:export:text:emailNotification')}</p>
                    </CAFormPageSection>

                    <CAFormPageSection modifier="error-status">
                        <p>{errorStatus}</p>
                    </CAFormPageSection>

                    <CAFormPageFooter>
                        <CAButton modifier={'primary'} type="submit">
                            {t('modals:buttons:ok')}
                        </CAButton>
                        <CAButton modifier={'secondary'} type="button" onClick={closeModal}>
                            {t('modals:buttons:cancel')}
                        </CAButton>
                    </CAFormPageFooter>
                </form>
            </CAFormPage>
        )
    }
}

export const ExportModalForm = reduxForm({form: FORMS.RECOVERY_EXPORT_MODAL})(RecoveryModalFormRaw)
