import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledPausedStatus = styled.span`
    min-width: 92px;
    border-radius: 8px;
    margin-left: 16px;
    background-color: ${variables.blueLight};
    border: 1px solid ${variables.navyBorder};
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    text-align: center;
`
