import styled from 'styled-components'
import variables from 'ca-common/variables'

export const Container = styled.div`
    height: 560px;
`

export const SmallContainer = styled.div`
    height: 260px;
`
export const ChartContainer = styled.div`
    position: relative;
    * {
        user-select: none;
    }
`

export const LegendWrapper = styled.div`
    position: absolute;
    top: ${(props: {top: number}) => `${props.top}px`};
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: ${variables.fontSizeBase};
`
