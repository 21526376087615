import React from 'react'
import {withTranslation} from 'react-i18next'
import i18n from 'i18next'
import _ from 'lodash'
import {withRouter} from 'react-router'
import queryString from 'query-string'

import {getTaskStatuses} from 'ca-common/utils/backupTaskStatus'
import {getStatusIcon} from 'ca-common/utils/taskStatus'
import {hasSomeErrorsAntd4} from 'ca-common/utils/form'
import {MultiSelect} from 'ca-common/ui-lib/atoms/MultiSelect'
import {LICENSE_STATUS, SOURCE} from 'ca-common/common/enum'
import {TagsFilter} from 'ca-common/ui-lib/molecules/TagsFilter'
import {Select} from 'ca-common/ui-lib/atoms/Select'

import Search from 'ca-common/icons/Search.svg'

import {BackupTags} from 'src/newcore/components/BackupTags'
import {BackupTaskActions} from 'src/newcore/components/BackupTaskActions'

import {
    SearchInput,
    StyledForm,
    SearchIcon,
    StyledFormItem,
    StyledFormItemTags,
    StyledFormItemStatus,
    StyledIcon,
    StyledLabel,
    StyledFormItemLicensing
} from './StyledBackupEntities'

const DEBOUNCE_TIME = 1000
const MIN_LENGTH_TO_SEARCH = 3

const licensingList = Object.values(LICENSE_STATUS)
    .filter(v => v !== LICENSE_STATUS.NA)
    .map(value => ({
        label: i18n.t(`backups:licenseStatus:${value}`),
        value
    }))

class SearchEntitiesFormRaw extends React.Component {
    getValidStatuses = statuses => {
        const temp = {...statuses}
        delete temp.PENDING
        return temp
    }

    formRef = React.createRef()

    state = {
        statusList: _.map(this.getValidStatuses(getTaskStatuses(this.props.source)), status => ({
            label: (
                <StyledLabel>
                    <StyledIcon component={getStatusIcon(status)} />
                    {this.props.t(`backups:newTaskStatus:${status}`)}
                </StyledLabel>
            ),
            value: status
        })),
        statusPopoverVisible: false
    }

    setStatusPopoverVisible = value => {
        this.setState({
            statusPopoverVisible: value
        })
    }

    componentDidMount() {
        this.debounceChange = _.debounce(this.handleSubmit, DEBOUNCE_TIME)
    }

    componentDidUpdate(prevProps) {
        const queryParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
        const oldQueryParams = queryString.parse(prevProps.location.search, {arrayFormat: 'bracket'})

        if (!_.isEqual(queryParams, oldQueryParams)) {
            this.formRef.current.setFieldsValue({
                query: queryParams.query,
                statusFilter: queryParams.statusFilter,
                tagIds: queryParams.tagIds,
                licenseStatus: queryParams.licenseStatus
            })
        }
    }

    handleSubmit = values => {
        if (!hasSomeErrorsAntd4(this.formRef.current.getFieldsError())) {
            this.props.onSubmit(values)
        }
    }

    render() {
        const {t, initialValues, source, tags, selectedAll, selectedRowKeys, resetRowSelection} = this.props

        return (
            <StyledForm
                layout="inline"
                ref={this.formRef}
                onValuesChange={this.debounceChange}
                initialValues={{
                    query: initialValues.query,
                    tagIds: initialValues.tagIds,
                    statusFilter: initialValues.statusFilter,
                    licenseStatus: initialValues.licenseStatus
                }}
            >
                <StyledFormItem
                    name="query"
                    rules={[
                        {
                            min: MIN_LENGTH_TO_SEARCH,
                            message: t('forms:common:validation:minLength', [MIN_LENGTH_TO_SEARCH])
                        }
                    ]}
                >
                    <SearchInput
                        prefix={<SearchIcon component={Search} style={{color: 'rgba(0,0,0,.25)'}} />}
                        placeholder={t(`searchPlaceholder:${source}`)}
                        allowClear
                    />
                </StyledFormItem>
                <StyledFormItemTags name="tagIds">
                    <TagsFilter tags={tags} />
                </StyledFormItemTags>
                {source === SOURCE.MS365 && (
                    <StyledFormItemLicensing name="licenseStatus">
                        <Select
                            options={licensingList}
                            placeholder={t('table:columns:licensing')}
                            selectWidth={130}
                            listHeight={700}
                            showSearch={false}
                        />
                    </StyledFormItemLicensing>
                )}
                <StyledFormItemStatus name="statusFilter">
                    <MultiSelect
                        options={this.state.statusList}
                        buttonText={t('backups:status')}
                        width={110}
                        popoverWidth={165}
                        listHeight={700}
                    />
                </StyledFormItemStatus>
                <BackupTags selectedAll={selectedAll} selectedRowKeys={selectedRowKeys} />
                <BackupTaskActions
                    selectedAll={selectedAll}
                    selectedRowKeys={selectedRowKeys}
                    resetRowSelection={resetRowSelection}
                />
            </StyledForm>
        )
    }
}

export const SearchEntitiesForm = withRouter(withTranslation()(SearchEntitiesFormRaw))
