import React from 'react'
import {FormInstance, Form, Space, Input} from 'antd'
import {useTranslation} from 'react-i18next'

import {AddIpFormFields, RestrictionEntry} from 'ca-common/features/IpRestriction/types'
import {
    HeaderWithIp,
    Description,
    AddressList,
    AddressRange
    // AddressCIDR,
} from 'ca-common/features/IpRestriction/atoms'
import {
    FORM_FIELDS,
    RESTRICTION_RULES,
    useOnFieldsChange,
    parseAddressesToFE
} from 'ca-common/features/IpRestriction/lib'

import {ModalHeader, ModalBody} from './StyledEditForm'

type EditFormProps = {
    form: FormInstance<AddIpFormFields>
    record: RestrictionEntry
}

export const EditForm = (props: EditFormProps): JSX.Element => {
    const {form, record} = props
    const {t} = useTranslation()

    const onFieldsChange = useOnFieldsChange(form)

    return (
        <React.Fragment>
            <ModalHeader>
                <HeaderWithIp title={t('ipRestrictions:editIpAddressHeader')} />
            </ModalHeader>
            <ModalBody>
                <Form form={form} layout="vertical" validateTrigger="onSubmit" onFieldsChange={onFieldsChange}>
                    <Description width={468} initialValue={record[FORM_FIELDS.DESCRIPTION]} />

                    <Space direction="vertical">
                        {record.type === RESTRICTION_RULES.LIST && (
                            <AddressList width={468} initialValue={parseAddressesToFE(record[FORM_FIELDS.ADDRESSES])} />
                        )}
                        {record.type === RESTRICTION_RULES.RANGE && (
                            <AddressRange
                                initialValueFrom={record[FORM_FIELDS.FROM]}
                                initialValueTo={record[FORM_FIELDS.TO]}
                            />
                        )}
                        {/* {record.type === RuleType.CIDR && (
                            <AddressCIDR initialValueAddress={record[FORM_FIELDS.ADDRESS]} initialValueCidr={record[FORM_FIELDS.CIDR]} />
                        )} */}
                    </Space>
                    <Form.Item hidden name={FORM_FIELDS.TYPE} initialValue={record[FORM_FIELDS.TYPE]}>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name={FORM_FIELDS.VERSION} initialValue={record[FORM_FIELDS.VERSION]}>
                        <Input />
                    </Form.Item>
                </Form>
            </ModalBody>
        </React.Fragment>
    )
}
