import React from 'react'
import {Route, RouteComponentProps, Switch} from 'react-router'

import {
    NotificationsPage,
    NewNotificationPage,
    EditNotificationPage
} from 'src/newcore/components/Settings/Pages/Notifications'

export const NotificationsRouting = ({match}: RouteComponentProps): JSX.Element => {
    return (
        <Switch>
            <Route path={`${match.url}/`} exact component={NotificationsPage} />
            <Route path={`${match.url}/new`} component={NewNotificationPage} />
            <Route path={`${match.url}/:id`} component={EditNotificationPage} />
        </Switch>
    )
}
