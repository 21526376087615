import React, {ChangeEvent, useState, useEffect} from 'react'
import {UploadOutlined} from '@ant-design/icons'
import {Checkbox, Upload, Modal, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {RcFile} from 'antd/lib/upload'
import {RadioChangeEvent} from 'antd/es/radio'
import {UploadFile} from 'antd/es/upload/interface'

import {Button} from 'ca-common/ui-lib/atoms'

import {post, postMultipart} from 'src/newcore/utils/rest'
import {URLregex} from 'src/newcore/components/Settings/Utils'

import {
    StyledSAMLWrapper,
    StyledHeader,
    StyledProviders,
    StyledUpload,
    StyledFooter,
    StyledRadio,
    StyledRadioNote,
    StyledUploadInput,
    StyledRadioGroup,
    StyledModalBody,
    StyledModalTitle,
    StyledSubTitle
} from './StyledSecurity'

const FormItem = Form.Item

enum UploadType {
    FILE = 'FILE',
    LINK = 'LINK'
}

const oktaEndpoint = 'activateOkta'
const postData = (formData: FormData, uploadType: UploadType, url: string) =>
    uploadType === UploadType.FILE
        ? postMultipart(oktaEndpoint, {formData, json: {type: uploadType}})
        : post(oktaEndpoint, {type: uploadType, link: url})

export const SAML = ({fetchUserInfo}: {fetchUserInfo: () => void}): JSX.Element => {
    const {t} = useTranslation()
    const [uploadType, setUploadType] = useState<UploadType>(UploadType.LINK)
    const [showModal, setShowModal] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [url, setUrl] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const validateStatus = error ? 'error' : ''
    const helpText = error ? error : t('securityPage:saml:URLDescription')

    const handleRadioChange = (e: RadioChangeEvent) => setUploadType(e.target.value)
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)

    const beforeUpload = (file: UploadFile) => {
        setFileList([file])

        return false
    }

    const onRemove = () => {
        setFileList([])

        return false
    }

    const handleConfirm = () => {
        const formData = new FormData()
        formData.append('media', fileList[0] as RcFile)
        setConfirmLoading(true)

        postData(formData, uploadType, url)
            .then(() => {
                fetchUserInfo()
            })
            .catch(e => {
                if (uploadType === UploadType.FILE) {
                    fileList[0].status = 'error'
                    fileList[0].response = e.status
                } else {
                    setError(e.status)
                }
            })
            .finally(() => {
                setConfirmLoading(false)
                setShowModal(false)
            })
    }

    const handleActivate = () => {
        setShowModal(true)
    }

    const handleCancel = () => {
        setShowModal(false)
    }

    useEffect(() => {
        if (url.length && !url.match(URLregex)) {
            setError(t('securityPage:saml:URLError'))
        } else {
            setError('')
        }
    }, [url])

    useEffect(() => {
        setError('')
        if (uploadType === UploadType.LINK) {
            setFileList([])
        } else {
            setUrl('')
        }
    }, [uploadType])

    return (
        <StyledSAMLWrapper>
            <StyledProviders>
                <StyledHeader>{t('securityPage:saml:provider')}</StyledHeader>
                <Checkbox checked disabled>
                    {t('securityPage:saml:okta')}
                </Checkbox>
            </StyledProviders>
            <StyledUpload>
                <StyledHeader>{t('securityPage:saml:metadataTitle')}</StyledHeader>
                <StyledSubTitle>{t('securityPage:saml:metadataSubTitle')}</StyledSubTitle>
                <StyledRadioGroup onChange={handleRadioChange} value={uploadType}>
                    <StyledRadio value={UploadType.LINK}>
                        {t('securityPage:saml:URL')}
                        <FormItem validateStatus={validateStatus} help={helpText}>
                            <StyledUploadInput
                                onChange={handleInputChange}
                                value={url}
                                disabled={uploadType !== UploadType.LINK}
                            />
                        </FormItem>
                    </StyledRadio>
                    <StyledRadio value={UploadType.FILE}>
                        {t('securityPage:saml:file')}
                        <Upload fileList={fileList} beforeUpload={beforeUpload} onRemove={onRemove} accept=".xml">
                            <Button type="primary" disabled={!!fileList?.length || uploadType !== UploadType.FILE}>
                                <UploadOutlined />
                                {t('securityPage:saml:choose')}
                            </Button>
                        </Upload>
                        {!fileList?.length && <StyledRadioNote>{t('securityPage:saml:noFile')}</StyledRadioNote>}
                    </StyledRadio>
                </StyledRadioGroup>
            </StyledUpload>
            <StyledFooter>
                <Button
                    type="primary"
                    htmlType="button"
                    onClick={handleActivate}
                    disabled={!!error || (!url && !fileList?.length) || fileList[0]?.status === 'error'}
                >
                    {t('securityPage:saml:activate')}
                </Button>
            </StyledFooter>
            <Modal
                width={628}
                open={showModal}
                confirmLoading={confirmLoading}
                footer={[
                    <Button key="cancel" htmlType="button" onClick={handleCancel}>
                        {t('forms:common:actions:cancel')}
                    </Button>,
                    <Button key="activate" type="primary" htmlType="button" onClick={handleConfirm}>
                        {t('forms:common:actions:confirm')}
                    </Button>
                ]}
            >
                <StyledModalTitle>{t('securityPage:saml:modalTitle')}</StyledModalTitle>
                <StyledModalBody>{t('securityPage:saml:modalBody')}</StyledModalBody>
                <StyledModalBody>{t('securityPage:saml:modalWarning')}</StyledModalBody>
            </Modal>
        </StyledSAMLWrapper>
    )
}
