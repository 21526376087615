import {FIELD_TYPE, MODE} from 'src/newcore/components/Seeding/DataAnonymizationStep/lib/constants'
import type {Field} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'

export const getCurrentAnonymizationValue = (mode: MODE, fieldType: FIELD_TYPE, field?: Partial<Field>) => {
    if (field) {
        return field.mode === MODE.PRE_DEFINED ? field.fieldType : field.mode
    }

    return mode === MODE.PRE_DEFINED ? fieldType : mode
}
