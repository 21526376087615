import styled from 'styled-components'

import variables from 'ca-common/variables'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'

export const StyledHeader = styled.div`
    padding: ${variables.gutterMd};
    border-bottom: 1px solid ${variables.blue};
`

export const StyledHeaderWithIcon = styled(StyledHeader)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;

    .anticon {
        font-size: 48px;
        color: ${props => (props.action === TASK_ACTION.DELETE ? variables.red : variables.asphalt)};
        margin-left: 56px;
    }
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${variables.gutterSm};
    > * + * {
        margin-left: ${variables.gutterMd};
    }
`

export const StyledModal = styled.div`
    margin: -${variables.gutterMd};
    word-break: break-word;
`

export const StyledLink = styled.a`
    :link,
    :visited,
    :hover,
    :active {
        color: ${variables.navyBlue};
    }
`
