import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {DATE_WITH_AT_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {StyledFromSpan} from 'src/newcore/features/Recovery/molecules/RecoveryItemsTable'

export const notesColumns = [
    {
        title: i18n.t('table:columns:subject'),
        dataIndex: 'subject',
        key: 'noteSubject',
        width: '75%',
        ellipsis: true,
        render: (text: string): JSX.Element => <StyledFromSpan data-hj-suppress>{text}</StyledFromSpan>
    },
    {
        title: i18n.t('table:columns:updated'),
        dataIndex: 'updateDate',
        key: 'noteUpdateDate',
        width: '20%',
        sorter: true,
        render: (text: string): string => getLocalDateFromUTC(text, LONG_DATE_FORMAT, DATE_WITH_AT_FORMAT)
    },
    Table.EXPAND_COLUMN
]
