import styled, {css} from 'styled-components'
import {rgba} from 'polished'
import variables from 'ca-common/variables'

export type StyledNoteProps = {
    type: 'info' | 'warning'
}

export const StyledNote = styled.div<StyledNoteProps>`
    background: ${variables.interaction};
    border-radius: ${variables.borderRadiusBase};
    border: 1px solid ${variables.interaction};
    padding: ${variables.gutterSm};
    font-size: ${variables.subTitleFontSize};
    font-family: ${variables.publicPageFont};
    ${props => {
        if (props.type === 'warning') {
            return css`
                background: ${rgba(variables.orange, 0.1)};
                border-color: ${variables.orange};
            `
        }
    }}

    .anticon {
        margin-right: ${variables.gutterSm};
    }
`

export const NoteTitle = styled.span`
    font-weight: 700;
`
