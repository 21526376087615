import React, {Fragment, useEffect} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import get from 'lodash/get'

import {DEACTIVATION_TYPES} from 'ca-common/constants'
import {addRulesToRemovedList, clearRemovedRulesList} from 'src/newcore/redux/modules/backups/bulkActivate'

import {BulkActivation} from 'src/newcore/components/BulkActivation'

import {
    AccountDeactivation,
    ActivateAllNewAccounts,
    BackupFrequency,
    BackupHour,
    BackupUnlicensedAccounts,
    BulkCondition,
    DeactivationRule,
    DetectNewAccounts,
    IndexStatus,
    RetentionPeriod,
    StorageRegion
} from 'src/newcore/features/BackupSettings/molecules'
import {MultiEntitySettings} from 'src/newcore/features/BackupSettings/organisms'

import {BackupSettingsDivider, SettingsInfo} from '../StyledSettings'

const MS365Raw = props => {
    const {t} = useTranslation()
    const {
        taskSettings,
        bulkActivationRules,
        isFinra,
        form,
        isHighFrequencyBackupEnabled,
        isMultiFrequencyBlocked,
        isSpecialPartnerAccount
    } = props
    const backupUnlicensed = get(taskSettings, 'backupUnlicensed', true)
    const rules = get(bulkActivationRules, 'data', [])
    const canDeactivate = rules?.some(rule => rule.canDeactivate)

    useEffect(() => {
        form.setFieldValue('detectNewAccounts', taskSettings.detectNewAccounts)
    }, [taskSettings.detectNewAccounts])

    return (
        <MultiEntitySettings {...props}>
            <Row gutter={16}>
                <Col lg={14}>
                    <SettingsInfo>
                        <h2>{t('forms:backupSettings:title')}</h2>
                        <p>{t('forms:backupSettings:text:noticeContent')}</p>
                    </SettingsInfo>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col lg={16} xl={14} xxl={11}>
                    <IndexStatus indexStatus={taskSettings.indexStatus} />

                    <BackupUnlicensedAccounts backupUnlicensed={backupUnlicensed} form={form} />

                    <AccountDeactivation
                        daysToAccountDeactivation={taskSettings.daysToAccountDeactivation}
                        source={taskSettings.source}
                        isFinra={isFinra}
                    />

                    {taskSettings.oauth ? (
                        <ActivateAllNewAccounts
                            detectNewAccounts={taskSettings.detectNewAccounts}
                            source={taskSettings.source}
                        />
                    ) : (
                        <DetectNewAccounts
                            detectNewAccounts={taskSettings.detectNewAccounts}
                            source={taskSettings.source}
                        />
                    )}
                </Col>

                <Col span={1}>
                    <BackupSettingsDivider />
                </Col>

                <Col lg={7} xl={9} xxl={12}>
                    <Row gutter={16}>
                        <Col lg={24} xl={12}>
                            <StorageRegion storageRegion={taskSettings.storageRegion} />
                        </Col>
                        <Col lg={24} xl={12}>
                            <RetentionPeriod
                                retentionType={taskSettings.retentionType}
                                retentionPeriod={taskSettings.retentionPeriod}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupFrequency
                                backupFrequency={taskSettings.backupFrequency}
                                isHighFrequencyBackupEnabled={isHighFrequencyBackupEnabled}
                                isHighFrequencyBackupBlocked={
                                    taskSettings.isHighFrequencyBackupBlocked || isMultiFrequencyBlocked
                                }
                                isSpecialPartnerAccount={isSpecialPartnerAccount}
                                isSurchargeNotificationDisplayed={taskSettings.isSurchargeNotificationDisplayed}
                            />
                        </Col>
                        <Col lg={24} xl={12}>
                            <BackupHour
                                backupHour={taskSettings.backupHour}
                                backupFrequency={taskSettings.backupFrequency}
                                isDistributedBackupHour={taskSettings.isDistributedBackupHour}
                                distributedBackupHourValue={taskSettings.distributedBackupHourValue}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {taskSettings.oauth && (
                <Fragment>
                    <Row>
                        <BulkActivation isAuthenticated={taskSettings.isAuthenticated} source={taskSettings.source} />
                    </Row>
                    <Row>
                        <Col>
                            {rules.map(rule => (
                                <BulkCondition rule={rule} key={rule.id} />
                            ))}
                        </Col>
                    </Row>
                    {canDeactivate && (
                        <Row>
                            <Col>
                                <DeactivationRule
                                    deactivationOption={
                                        taskSettings.deactivationOption || DEACTIVATION_TYPES.MAINTAIN_CURRENT_STATUS
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}
        </MultiEntitySettings>
    )
}

const mapStateToProps = state => ({
    bulkActivationRules: {
        status: state.backups.bulkActivation.bulkActivationRules.rules.status,
        ...state.backups.bulkActivation.bulkActivationRules.rules.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            addRulesToRemovedList,
            clearRemovedRulesList
        },
        dispatch
    )
})

export const MS365 = connect(mapStateToProps, mapDispatchToProps)(MS365Raw)
