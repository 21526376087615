import React, {useEffect, useState} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {useHistory, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {TreeNode} from 'antd/lib/tree-select'

import {DAY_MONTH_FORMAT, dateFormat} from 'ca-common/utils/datetime'
import {getUrlParams, makeUrlParams} from 'ca-common/utils/url'
import {SOURCE_INFO} from 'ca-common/common/enum'
import {getSubsources} from 'ca-common/utils/sources'
import type {ResponseType} from 'ca-common/types'

import {getAccessableServices} from 'src/newcore/utils/sources'
import {
    clearActivity,
    clearTaskInfo,
    clearTasks,
    clearServiceList,
    getServiceList,
    getTaskInfo,
    getTasks
} from 'src/newcore/features/ActivityTable/redux'
import {ActivityFilterForm} from 'src/newcore/features/ActivityTable/organisms/ActivityFilterForm'
import type {FieldValues} from 'src/newcore/features/ActivityTable/types/fieldValues'
import {AppDispatch, AppState} from 'src/newcore/components/Root'

export type ActivityFiltersProps = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        submitExport: (queryParams: FieldValues) => () => void
        queryParams: FieldValues | undefined
        loading: boolean
        setQueryParams: React.Dispatch<React.SetStateAction<FieldValues | undefined>>
        serviceList: ResponseType<string[]>
        fetchActivity: (queryParams: FieldValues, newPage?: number) => void
    }

export type ServicesTree = Array<typeof TreeNode & {title: string}>

const buildServicesTree = (services: string[]) =>
    services.map(source => ({
        title: SOURCE_INFO[source]?.displayName,
        value: source,
        key: source,
        children: getSubsources(source).map(subsource => ({
            title: SOURCE_INFO[subsource].displayName,
            value: `${source}__${subsource}`,
            id: source + subsource,
            isLeaf: true
        }))
    }))

export const ActivityFiltersRaw = ({
    queryParams = {} as FieldValues,
    setQueryParams,
    userInfo,
    taskInfo,
    submitExport,
    loading,
    serviceList,
    fetchActivity,
    actions,
    tasks,
    activity
}: ActivityFiltersProps): JSX.Element => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const location = useLocation()
    const history = useHistory()
    const [treeData, setTreeData] = useState<any>([])

    useEffect(() => {
        actions.getTasks({})
        actions.getServiceList({})

        return () => {
            actions.clearTasks()
            actions.clearActivity()
            actions.clearServiceList()
            actions.clearTaskInfo()
        }
    }, [])

    useEffect(() => {
        actions.clearActivity()
        setQueryParams(getUrlParams(location.search) as FieldValues)
    }, [location.search])

    useEffect(() => {
        const {permissions} = userInfo.response
        const accessibleServices = getAccessableServices(permissions, serviceList.data as string[])

        setTreeData(buildServicesTree(accessibleServices))
    }, [serviceList.data])

    const changeURLSearchParams = (values: Partial<FieldValues>) => {
        const newParams: FieldValues = {
            ...queryParams,
            ...values
        }

        if (Object.hasOwnProperty.call(values, 'taskId')) {
            //clear entities if taskId changes
            newParams.entities = []
            newParams.externalId = undefined
        }

        const dateRange = values.dateRange?.map((date: string) => dateFormat(+date, DAY_MONTH_FORMAT))

        if (dateRange?.length) {
            newParams.dateRange = dateRange as string[]
        }

        const newUrlParams = makeUrlParams(newParams)

        if (!(newUrlParams === location.search.substring(1))) {
            history.push({
                search: newUrlParams
            })
        }
    }

    return (
        <ActivityFilterForm
            queryParams={queryParams}
            changeURLSearchParams={changeURLSearchParams}
            treeData={treeData}
            fetchActivity={fetchActivity}
            loading={loading}
            serviceList={serviceList}
            setQueryParams={setQueryParams}
            submitExport={submitExport}
            taskInfo={taskInfo}
            tasks={tasks}
            userInfo={userInfo}
            actions={actions}
            activity={activity}
        />
    )
}

const mapStateToProps = ({activity: {accountActivity, tasks, taskInfo, serviceList}, userInfo}: AppState) => ({
    activity: {
        status: accountActivity.status,
        ...accountActivity.response
    },
    tasks,
    taskInfo,
    serviceList: {
        status: serviceList.status,
        ...serviceList.response
    },
    userInfo
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: {
        ...bindActionCreators(
            {
                getTasks,
                clearTasks,
                getTaskInfo,
                clearTaskInfo,
                clearActivity,
                getServiceList,
                clearServiceList
            },
            dispatch
        )
    }
})

export const ActivityFilters = connect(mapStateToProps, mapDispatchToProps)(ActivityFiltersRaw)
