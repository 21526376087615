import {createSlice} from '@reduxjs/toolkit'

import {postLessData} from 'src/newcore/utils/rest'
import {JOBS_STATUSES} from 'src/ca-common/common/enum'

import type {JobFields} from 'src/newcore/features/Jobs/types'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {AppDispatch} from 'src/newcore/components/Root'
import {TODO_ANY} from 'ca-common/types'
import {JOBS_DASHBOARD_STATUSES} from 'src/newcore/features/Jobs/lib'

const GET_EXPORT_RESTORE_JOBS = 'GET_EXPORT_RESTORE_JOBS'

const GET_EXPORT_RESTORE_JOBS_API = 'getExportRestoreJobs'
const getExportRestoreJobsWrapper = fetchWrapperRT(
    GET_EXPORT_RESTORE_JOBS,
    async data => await postLessData(GET_EXPORT_RESTORE_JOBS_API, data)
)

const PULL_EXPORT_RESTORE_JOBS = 'PULL_EXPORT_RESTORE_JOBS'
const pullExportRestoreJobsWrapper = fetchWrapperRT(
    PULL_EXPORT_RESTORE_JOBS,
    async data => await postLessData(GET_EXPORT_RESTORE_JOBS_API, data)
)

export type DashboardItem = {status: JOBS_DASHBOARD_STATUSES; count: number}
export type exportRestoreResponseType = {
    dashboard: {dashboardElements: DashboardItem[]; total: number}
    data: JobFields[]
    page: number
    success: boolean
    total: number
    totalPages: number
}

export const getExportRestoreJobs = (data: Record<string, unknown> = {}) => (dispatch: AppDispatch) => {
    const {pull, ...passedData} = data

    if (pull) {
        return dispatch(pullExportRestoreJobsWrapper.fetcher(passedData)).then((response: TODO_ANY) => {
            return dispatch(updateExportRestoreJobs(response.payload))
        })
    }

    return dispatch(getExportRestoreJobsWrapper.fetcher(passedData))
}

const exportRestoreJobsSlice = createSlice({
    name: 'exportRestoreJobs',
    initialState: InitFetchedState as FetchedState<exportRestoreResponseType>,
    reducers: {
        clearExportRestoreJobs: () => InitFetchedState as FetchedState<exportRestoreResponseType>,
        updateExportRestoreJobs: (state, action) => {
            if (state.status === 'fulfilled') {
                return {
                    ...state,
                    response: {
                        ...state.response,
                        dashboard: action.payload.dashboard,
                        data: state.response.data.map(i => {
                            const found = action.payload.data.find((j: JobFields) => j.taskId === i.taskId)

                            return {
                                ...i,
                                ...found
                            }
                        })
                    }
                }
            }

            return state
        },
        setCancellingStatus: (state, action) => {
            if (state.status === 'fulfilled') {
                return {
                    ...state,
                    response: {
                        ...state.response,
                        data: state.response.data.map(i => {
                            if (i.taskId === action.payload.id) {
                                return {...i, status: JOBS_STATUSES.CANCELLING}
                            }
                            return {...i}
                        })
                    }
                }
            }

            return state
        }
    },
    extraReducers: builder => getExportRestoreJobsWrapper.makeReducers(builder)
})

export const {clearExportRestoreJobs, updateExportRestoreJobs, setCancellingStatus} = exportRestoreJobsSlice.actions
export const exportRestoreJobs = exportRestoreJobsSlice.reducer
