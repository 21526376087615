import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {debounce} from 'lodash'
import {ColumnProps} from 'antd/es/table'
import {Tooltip} from 'antd'

import variables from 'ca-common/variables'
import {EmailIcon} from 'ca-common/atoms/EmailIcon'
import {
    getLocalDateFromUTC,
    LONG_DATE_FORMAT,
    convertUnixToDate,
    BILLING_PAGE_DATE_FORMAT
} from 'ca-common/utils/datetime'

import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'
import {getColumnsWidth} from 'src/newcore/features/BillingStatus/lib/getColumnsWidth'
import {DownloadInvoice} from 'src/newcore/features/BillingStatus/atoms/DownloadInvoice'
import {PayInvoiceButton} from '../atoms/PayInvoiceButton/PayInvoiceButton'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'

type UseColumnsProps = {
    paymentStatus?: PaymentStatus
    sendUserInvoiceEmail: (documentId: string) => Promise<void>
    response?: Record<string, any>
}

export const useColumns = ({paymentStatus, response, sendUserInvoiceEmail}: UseColumnsProps): ColumnProps<any>[] => {
    const {t} = useTranslation()
    const isUnpaid = paymentStatus === PaymentStatus.UNPAID

    const [columnsWidth, setColumnsWidth] = useState(getColumnsWidth())

    const {
        downloadInvoiceWidth,
        emailInvoiceWidth,
        totalAmountWidth,
        issueDateWidth,
        dueDateWidth,
        effectiveDateWidth,
        statusWidth,
        emptyFieldWidth
    } = columnsWidth

    const handleResize = debounce(() => {
        setColumnsWidth(getColumnsWidth())
    }, 500)

    const sendEvent = (record: Record<string, any>, value: string) => {
        track(value, {
            'Billing Status': t(`billing:statusDecoding:${record.status}`),
            Subscription: response?.paymentType
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return [
        {
            title: t('table:columns:issueDate'),
            dataIndex: 'issueDate',

            width: issueDateWidth,
            render: (date: string) => convertUnixToDate(+date, BILLING_PAGE_DATE_FORMAT)
        },
        !isUnpaid && {
            title: t('table:columns:status'),
            dataIndex: 'status',
            width: statusWidth,
            render: (status: PaymentStatus) => t(`billing:statusDecoding:${status}`)
        },
        {
            title: t('table:columns:dueDate'),
            dataIndex: 'dueDate',
            width: dueDateWidth,
            render: (date: string) => convertUnixToDate(+date, BILLING_PAGE_DATE_FORMAT)
        },
        {
            title: t('table:columns:effectiveDate'),
            dataIndex: 'effectiveDate',
            width: effectiveDateWidth,
            render: (value: any) => (value === 0 ? '' : getLocalDateFromUTC(value, LONG_DATE_FORMAT, 'll'))
        },
        {
            title: t('table:columns:totalAmount'),
            dataIndex: 'total',
            width: totalAmountWidth,
            align: 'right',
            render: (text: string) => <span style={{color: variables.red}}>{text}</span>
        },
        !isUnpaid && {
            title: t('table:columns:downloadInvoice'),
            dataIndex: 'invoiceNumber',
            width: downloadInvoiceWidth,
            align: 'center',
            render: (text: string, record: any) =>
                record.invoiceLink ? (
                    <DownloadInvoice
                        invoiceNumber={record.invoiceNumber}
                        link={record.invoiceLink}
                        onClick={() => sendEvent(record, EVENT_NAMES.DOWNLOAD_UNPAID_INVOICE)}
                    />
                ) : null
        },
        !isUnpaid && {
            title: t('table:columns:emailInvoice'),
            dataIndex: 'id',
            width: emailInvoiceWidth,
            align: 'center',
            render: (id: string, record: Record<string, any>) =>
                id ? (
                    <Tooltip
                        placement="bottomRight"
                        title={t('billing:tooltips:emailInvoice')}
                        arrowPointAtCenter
                        overlayInnerStyle={{fontSize: variables.fontSizeSecondary, width: '190px'}}
                    >
                        <span>
                            <EmailIcon
                                onClick={() => {
                                    sendEvent(record, EVENT_NAMES.RESEND_UNPAID_INVOICE)
                                    sendUserInvoiceEmail(id)
                                }}
                            />
                        </span>
                    </Tooltip>
                ) : null
        },
        !isUnpaid && {
            render: (record: any) => {
                const paymentUrl = record.paymentUrl
                return paymentUrl ? (
                    <PayInvoiceButton
                        onClick={() => sendEvent(record, EVENT_NAMES.PAY_INVOICE_BUTTON)}
                        billingStatus={record.status}
                        paymentUrl={paymentUrl}
                    />
                ) : null
            }
        },
        isUnpaid && {width: emptyFieldWidth}
    ].filter(Boolean) as ColumnProps<any>[]
}
