export const adroll = `
            if (!window.adroll_adv_id) {
                adroll_adv_id = "XJZ5PE7UBNC4NOKFNVGBE3";
                adroll_pix_id = "MBQ7ITGQTFCITE3PBCWHXL";
                adroll_version = "2.0";
                function initAdroll(w, d, e, o, a) {
                    w.__adroll_loaded = true;
                    w.adroll = w.adroll || [];
                    w.adroll.f = [ 'setProperties', 'identify', 'track' ];
                    var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                        + "/roundtrip.js";
                    for (a = 0; a < w.adroll.f.length; a++) {
                        w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                            return function() {
                                w.adroll.push([ n, arguments ])
                            }
                        })(w.adroll.f[a])
                    }
                    e = d.createElement('script');
                    o = d.getElementsByTagName('script')[0];
                    e.async = 1;
                    e.src = roundtripUrl;
                    o.parentNode.insertBefore(e, o);
                }
                initAdroll(window, document);
            }
            adroll.track('pageView');
`
