import React from 'react'
import {useTranslation} from 'react-i18next'

import {SUBSCRIPTION_TYPE} from 'ca-common/constants'
import {convertUnixToDate, NEXT_PAYMENT_DATE} from 'ca-common/utils/datetime'
import {isMonthlyManualBilling} from 'ca-common/utils/billing'

import {useAppSelector, AppState} from 'src/newcore/components/Root'

import {StyledWrapper, StyledData} from './StyledBillingWrapper'

export const CABillingWrapper = (props: {children: Record<string, any>}) => {
    const {children} = props
    const {t} = useTranslation()
    const userInfo = useAppSelector((state: AppState) => state.userInfo)
    const paymentMethod = useAppSelector((state: AppState) => state.billing.paymentMethod)
    const nextPaymentDate = useAppSelector((state: AppState) => state.billing.nextPaymentDate)

    const setTitle = () => {
        if (!userInfo?.response?.isSubscribed) {
            return t('table:billing:subscribeType:trial')
        }
        const getSubscriptionType =
            paymentMethod?.status === 'fulfilled' ? paymentMethod?.response?.subscriptionType : null

        const getPaymentProvider =
            paymentMethod?.status === 'fulfilled' ? paymentMethod?.response?.paymentProvider : null
        const nextPaymentDateInfo =
            nextPaymentDate.status === 'fulfilled'
                ? `, ${t(`billing:status:paymentForecast:nextPayment`)} ${convertUnixToDate(
                      nextPaymentDate?.response?.nextPaymentDate,
                      NEXT_PAYMENT_DATE
                  )}`
                : ''

        switch (getSubscriptionType) {
            case SUBSCRIPTION_TYPE.MONTHLY:
            case SUBSCRIPTION_TYPE.ANNUAL:
                return isMonthlyManualBilling(getPaymentProvider, getSubscriptionType)
                    ? t('billing:monthlyInvoice')
                    : `${getSubscriptionType} subscription${nextPaymentDateInfo}`
            default:
                return t('table:billing:subscribeType:trial')
        }
    }
    return (
        <StyledWrapper>
            <StyledData>{setTitle()}</StyledData>
            {children}
        </StyledWrapper>
    )
}
