import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Form, FormProps, Input} from 'antd'

import {Button} from 'ca-common/ui-lib/atoms'
import {EmailSettings} from 'ca-common/organisms/EmailSettings'

import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {
    resendConfirmationEmail as resendConfirmationEmailThunk,
    discardEmailChange
} from 'src/newcore/redux/modules/account'
import {StyledForm, StyledButton, StyledFooter, StyledCard, StyledFormItem} from './StyledAccountForm'

type SettingsFormFields = {
    name: string
    email: string
    newEmail?: string
    accountArchiveLocation: string
    partnerID?: string
    dailyReport?: boolean
    unsubscribe: boolean
}

type AccountPageFormProps = {
    onSubmit: (values: unknown) => void
    initialValues: SettingsFormFields
    disabledPartner: boolean
    isExternal: boolean
    loading: boolean
    isSubscribed: boolean
    getAccountSettings: () => void
    timer: number
    runTimer: () => void
    isSecondary: boolean
}

export const AccountPageForm = (props: AccountPageFormProps) => {
    const {initialValues, onSubmit, disabledPartner, isExternal, loading, getAccountSettings, timer, runTimer} = props
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const [isCanBeSubmitted, setCanBeSubmitted] = useState(false)
    const {getFieldsValue, setFieldsValue} = form

    const nameValidator = (_rule: unknown, name: string) => {
        if (!name) {
            return Promise.reject()
        } else if (name.length < 2) {
            return Promise.reject(t('validation:minLength', {0: 2}))
        } else if (name.length > 60) {
            return Promise.reject(t('validation:maxLength', {length: 60}))
        } else {
            return Promise.resolve()
        }
    }

    const handleFieldsChange: FormProps<SettingsFormFields>['onFieldsChange'] = data => {
        const hasErrors = data.filter(field => field.errors?.length).length > 0
        let isValuesChanged = false
        const currentFields = getFieldsValue()
        Object.entries(initialValues).forEach(([key, value]) => {
            if (!isValuesChanged && value) {
                isValuesChanged = currentFields[key] !== value
            }
        })
        setCanBeSubmitted(!hasErrors && isValuesChanged)
    }

    const handleCancelClick = () => {
        setFieldsValue(initialValues)
        setCanBeSubmitted(false)
    }

    const handleOnFinish = (v: unknown) => {
        setCanBeSubmitted(false)
        onSubmit(v)
        runTimer()
    }

    const resendConfirmationEmail = () => {
        runTimer()
        dispatch(resendConfirmationEmailThunk({}))
            .unwrap()
            .then(() => {
                openSuccessNotification()
            })
            .catch((error: any) => {
                openErrorNotification(error)
            })
    }

    return (
        <>
            <StyledForm requiredMark={false} onFinish={handleOnFinish} onFieldsChange={handleFieldsChange} form={form}>
                <StyledCard bordered={false}>
                    <StyledFormItem
                        name="name"
                        label={t('accountPage:name')}
                        initialValue={initialValues.name}
                        rules={[
                            {required: true, message: t('validation:requiredField')},
                            {
                                validator: nameValidator
                            }
                        ]}
                    >
                        <Input disabled={disabledPartner} />
                    </StyledFormItem>
                    <EmailSettings
                        discard={() => dispatch(discardEmailChange({})).unwrap()}
                        initialValues={initialValues}
                        emailLabel={t('accountPage:email')}
                        resendConfirmationEmail={resendConfirmationEmail}
                        disabled={isExternal || disabledPartner || Boolean(initialValues.newEmail)}
                        getAccountSettings={getAccountSettings}
                        FormItemComponent={Form.Item}
                        timer={timer}
                    />
                    <StyledFormItem
                        name="accountArchiveLocation"
                        label={t('accountPage:archiveLocation')}
                        initialValue={initialValues.accountArchiveLocation}
                        rules={[{required: true, message: t('validation:requiredField')}]}
                    >
                        <Input disabled />
                    </StyledFormItem>
                    {initialValues.partnerID && (
                        <StyledFormItem
                            name="partnerID"
                            label={t('accountPage:partnerId')}
                            initialValue={initialValues.partnerID}
                        >
                            <Input disabled />
                        </StyledFormItem>
                    )}
                    <StyledFooter>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={loading || !isCanBeSubmitted}
                        >
                            {t('forms:common:actions:save')}
                        </Button>
                        <StyledButton disabled={loading} onClick={handleCancelClick}>
                            {t('forms:common:actions:cancel')}
                        </StyledButton>
                    </StyledFooter>
                </StyledCard>
            </StyledForm>
        </>
    )
}
