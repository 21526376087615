import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from 'antd'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {isTaskCancelled, isTaskProcessing, isTaskQueued} from '../../../utils/utils'
import {ACTIONS} from '../../../utils/constants'
import {id, progress, status} from 'src/newcore/features/Homepage/types'
import {TaskActionsItem} from 'src/newcore/features/Homepage/atoms/TaskActionsItem'

type CancelProps = {
    id: id
    status: status
    progress: progress
    pullAndUpdateLocalServices: () => void
    sendAction: any
    disabled: boolean
}

export const CancelItem = ({
    id,
    status,
    progress,
    sendAction,
    pullAndUpdateLocalServices,
    disabled
}: CancelProps): JSX.Element => {
    const {t} = useTranslation()
    const {confirm} = Modal
    return <TaskActionsItem action={ACTIONS.CANCEL} onClick={showModalConfirmation} disabled={disabled} />

    function confirmCancellation() {
        if (isTaskQueued(status) || (isTaskProcessing(status) && !isTaskCancelled(progress))) {
            sendAction(ACTIONS.CANCEL)
                .then(() => {
                    openInfoNotification(t('backups:taskCanceled'))
                    pullAndUpdateLocalServices()
                })
                .catch(() => {
                    openErrorModal(`${t('backups:errorCancelingTask')} ${id}`)
                })
        }
    }

    function showModalConfirmation() {
        confirm({
            title: t('backups:modal:cancel:title'),
            content: t('backups:modal:cancel:text'),
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                confirmCancellation()
            }
        })
    }
}
