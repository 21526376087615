import React from 'react'
import {ItemFooterWrapper} from './StyledItemFooter'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {useTranslation} from 'react-i18next'

export const ItemFooter = ({onDownloadHandler}) => {
    const {t} = useTranslation()

    return (
        <ItemFooterWrapper>
            <Button type="primary" onClick={onDownloadHandler}>
                {t('seedingItem:download')}
            </Button>
        </ItemFooterWrapper>
    )
}
