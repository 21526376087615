import {i18n} from 'ca-common/locales'
import {SOCIAL_TYPES, SOURCE} from './common/enum'

export const ZENDESK_LOGIN = '/CloudAlly/ZendeskLogin'
export const ZENDESK_SSO = 'zendesk'
export const PARTNER_ZONE_LOGIN = '/CloudAlly/PartnerZoneLogin'
export const PARTNER_ZONE_SSO = 'partnerZone'
export const ZIX_SUPPORT = 'https://support.zixcorp.com/'

export const CLOUDALLY_URL = 'https://www.cloudally.com/'
export const TOOLTIPINFO_GOOGLE_OU_URL = 'https://support.google.com/a/answer/172983?sjid=1558192849335455758-EU.'
export const PAGES = {
    ACCOUNT: 'account',
    ACCOUNT_ACTIVITY: 'activity',
    ACTIVITY_SECURITY: 'security',
    ACTIVITY_SYSTEM: 'system',
    ANNUAL_SUBSCRIPTION: 'annualSubscription',
    ASSIGN_TO_SERVICES: 'assignToServices',
    BACKUPS: 'backups',
    BACKUPS_NEW: 'backupsNew',
    BILLING: 'billing',
    CHANGE_PASSWORD: 'changePassword',
    CHANGE_SETTINGS: 'changeSettings',
    COMPARE: 'compare',
    DASHBOARD: 'dashboard',
    DASHBOARD_NEW: 'dashboardNew',
    DOWNLOAD: 'download',
    DOWNLOAD_AUDIT: 'downloadAudit',
    DOWNLOAD_EXPORT: 'downloadExport',
    ERROR: 'x',
    FAQ: 'faq',
    HISTORY: 'history',
    HOMEPAGE: 'homepage',
    HOMEPAGE_ENDUSER: 'endUserHomepage',
    JOBS: 'jobs',
    LEARNING_HUB: 'learningHub',
    NOTIFICATIONS: 'notifications',
    OBJECTS: 'objects',
    PARTNER_ZONE: 'partnerZone',
    PAYMENTS: 'payments',
    PAYMENTS_DETAILS: 'paymentDetails',
    RECOVERY: 'recovery',
    REDIRECT: 'redirect',
    REPLICATE: 'replicate',
    RESTORE: 'restore',
    RESTORE_ITEMS: 'restoreItems',
    RESTORE_SEARCH: 'restoreSearch',
    RESTORE_SEARCH_RESULTS: 'restoreSearchResults',
    RESTORE_SEARCH_RESULTS_V2: 'restoreSearchResultsv2',
    RESTORE_SINGLE_SERVICE_SNAPSHOT_RESULTS: 'restoreSingleServiceSnapshotResults',
    RESTORE_SNAPSHOT_RESULTS: 'restoreSnapshotResults',
    SECURITY: 'userSecurity',
    SERVICES: 'services',
    SETTINGS: 'settings',
    SMART_ALERTS: 'notify',
    SYSTEM_ACTIVITY: 'systemActivity',
    ACCOUNT_SECURITY_AUDIT: 'securityAudit',
    STATUS: 'status',
    SUPPORT: 'support',
    SUPPORT_MAIL: 'supportMail',
    SUPPORT_PHONE: 'supportPhone',
    SUPPORT_TICKET: 'supportTicket',
    TOKEN: 'token',
    TUTORIALS: 'tutorials',
    TWO_FA_SET_ACCOUNT: 'twoFaSetAccount',
    UNPAID_BILLS: 'unpaidBills',
    USERS: 'users'
} as const

export const APP_DEFAULT_PAGE = PAGES.BACKUPS
export const PP_DEFAULT_PAGE = PAGES.DASHBOARD
export const PORTAL_BASENAME = 'newapp'

export const PARTNER_PAGES = {
    LINKS: {
        ACCOUNTS: '/accounts',
        BILLING: '/billing',
        DASHBOARD: '/dashboard',
        DASHBOARD_NEW: `/${PAGES.DASHBOARD_NEW}`,
        LEARNING_HUB: '/talentLMS/sso',
        PAYMENT: '/payment',
        RESELLER_AGREEMENT: '/reseller-agreement',
        SECURITY_AUDIT: '/securityAudit',
        SETTINGS: '/settings',
        SUPPORT: '/support'
    },
    NAMES: {
        ACCOUNTS: 'accounts',
        BILLING: 'billing',
        DASHBOARD: 'dashboard',
        DASHBOARD_NEW: 'dashboard-new',
        DIST_STATUS: 'distributor-status',
        LEARNING_HUB: 'learningHub',
        PARTNER_ZONE: 'partnerZone',
        PAYMENT: 'payment',
        RESELLER_AGREEMENT: 'reseller-agreement',
        SECURITY: 'security',
        SECURITY_AUDIT: 'securityAudit',
        SETTINGS: 'settings',
        STATUS: 'status',
        SUPPORT: 'support',
        UNPAID_BILLS: 'unpaid-bills'
    }
} as const

export enum INVOICE_LINE_TYPE {
    PERSERVICE = 'PERSERVICE',
    PERTASK = 'PERTASK'
}

export enum ACCESS_LEVEL {
    CUSTOM = 'CUSTOM',
    FULL = 'FULL',
    NONE = 'NONE'
}

export const ROLE_OPTIONS = [
    {label: i18n.t('userManagement:roles:administrator'), value: ACCESS_LEVEL.FULL},
    {label: i18n.t('userManagement:roles:custom'), value: ACCESS_LEVEL.CUSTOM}
]

export enum CREDENTIALS_TYPES {
    AZURE = 'AZURE',
    EMAIL = 'EMAIL',
    GOOGLE = 'GOOGLE',
    OKTA = 'OKTA',
    ZIX = 'ZIX'
}

export const CREDENTIAL_TYPE_SELECT = [
    {label: 'Azure', value: CREDENTIALS_TYPES.AZURE},
    {label: 'Email', value: CREDENTIALS_TYPES.EMAIL},
    {label: 'Google+', value: CREDENTIALS_TYPES.GOOGLE},
    {label: 'Okta', value: CREDENTIALS_TYPES.OKTA}
]
export const SORT_TYPE_ASC = 'asc'
export const SORT_TYPE_DESC = 'desc'
export const SORT_TYPE_DESCEND = 'descend'
export const SIZE = 'Size'
export const FILE_SIZE = 'FILE_SIZE'
export const FILE_NAME = 'FILE_NAME'
export const TASK_STATUS = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
    DISPATCHED: 'DISPATCHED',
    INPROCESS: 'INPROCESS',
    IN_ANOTHER: 'IN_ANOTHER',
    NOTACTIVE: 'NOTACTIVE',
    PAUSED: 'PAUSED',
    PENDING: 'PENDING',
    QUEUED: 'QUEUED',
    SKIPPED: 'SKIPPED'
}

export enum BACKUP_PROGRESS_STATUS {
    BACKUP = 'BACKUP',
    FINALIZE = 'FINALIZE',
    PREPARE = 'PREPARE'
}

export const TaskStatus = {
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
    DISPATCHED: 'Executing',
    INPROCESS: 'In process',
    IN_ANOTHER: 'Duplicate',
    NOTACTIVE: 'Not active',
    PAUSED: 'Paused',
    PENDING: 'Scheduled',
    QUEUED: 'Queued',
    SKIPPED: 'Executing'
}

export const PARTNER_TASK_STATUS = {
    ACTIVE: {
        ICON: 'status-success',
        MESSAGE: 'Active'
    },
    COMPLETE: {
        ICON: 'status-success',
        MESSAGE: 'Complete'
    },
    FAILED: {
        ICON: 'status-attention',
        MESSAGE: 'Failed'
    },
    IN_PROCESS: {
        ICON: 'status-running',
        MESSAGE: 'In process'
    },
    IN_PROCESS_FAIL: {
        ICON: 'status-running',
        MESSAGE: 'In process, but has failed previously'
    },
    PAUSED: {
        ICON: 'action-stop',
        MESSAGE: 'Paused'
    }
}

export const TASK_TYPE = {
    BACKUP: 'BACKUP',
    CHANGEACCOUNTDATA: 'CHANGEACCOUNTDATA',
    DEACTIVATEUSER: 'DEACTIVATEUSER',
    DELETEACCOUNTDATA: 'DELETEACCOUNTDATA',
    DELETEINDEXDATA: 'DELETEINDEXDATA',
    EXPORT: 'EXPORT',
    FINISHEDACK: 'FINISHEDACK',
    INDEX: 'INDEX',
    MIGRATE: 'MIGRATE',
    RESTORE: 'RESTORE',
    SEARCH: 'SEARCH',
    SIZECALCULATION: 'SIZECALCULATION'
}

export const FORMS = {
    ACCOUNT_ACTIVITY: 'AccountActivity',
    ADD_NEW_CARD_DETAILS: 'AddNewCardDetails',
    ADVANCED_SEARCH: 'AdvancedSearch',
    ANNUAL_SUBSCRIPTION: 'AnnualSubscription',
    BACKUP_SETTINGS: 'BackupSettings',
    CARDS: 'Cards',
    CHANGE_PASSWORD: 'changePassword',
    EXPORT_MODAL: 'ExportModal',
    NOTIFICATIONS: 'Notification',
    PAYMENT_DETAILS: 'PaymentDetails',
    RECOVERY_EXPORT_MODAL: 'RecoveryExportModal',
    RECOVERY_MODAL: 'RecoveryModal',
    RECOVERY_OPTIONS: 'RecoveryOptions',
    RESTORE_REQUEST: 'RestoreRequest',
    SECURITY_AUDIT: 'SecurityAudit',
    SETTINGS_EXPIRATION: 'SettingsExpiration',
    SETTINGS_SECURITY: 'SettingsSecurity',
    SIGN_IN: 'signIn',
    SYSTEM_ACTIVITY: 'SystemActivity',
    TWO_FA: 'TwoFA',
    UNLICENCED_OPTION: 'UnlicensedOption',
    USER_MANAGEMENT: 'UserManagement'
}

export const ERROR_MESSAGES = {
    EMAIL_ALREADY_EXIST: {
        CODE: '0x3',
        MESSAGE: 'Email already exist'
    }
}

export enum CURRENCIES {
    USD = 'USD',
    CAD = 'CAD',
    EUR = 'EUR',
    GBP = 'GBP',
    AUD = 'AUD',
    JPY = 'JPY'
}

export const CURRENCY = [
    {value: 'CAD', label: 'CAD'},
    {value: 'EUR', label: 'EUR'},
    {value: 'GBP', label: 'GBP'},
    {value: 'USD', label: 'USD'} /*,
    {value: 'AUD', label: 'AUD'}*/
]

export const ANNUAL_CURRENCY = [
    ...CURRENCY,
    {value: 'AUD', label: 'AUD'},
    {value: CURRENCIES.JPY, label: CURRENCIES.JPY}
]

export const ANNUAL_ONLY_CURRENCY = [
    {value: CURRENCIES.AUD, label: CURRENCIES.AUD},
    {value: CURRENCIES.JPY, label: CURRENCIES.JPY}
]

export const VENDOR = {
    AMEX: 'AMERICAN EXPRESS'
}

export const TITLE = [
    {value: 'Mr', label: 'Mr'},
    {value: 'Mrs', label: 'Mrs'},
    {value: 'Ms', label: 'Ms'},
    {value: 'Miss', label: 'Miss'},
    {value: 'Dr', label: 'Dr'},
    {value: 'Prof', label: 'Prof'}
]

export const ZOOZ_COMMANDS = {
    ADD_PAYMENT_METHOD: 'AddPaymentMethod',
    CARD_NOT_ADDED: 'CardNotAdded',
    CARD_NOT_REMOVED: 'CardNotRemoved',
    CLEAN_FORM: 'CleanForm',
    REMOVE_PAYMENT_METHOD: 'RemovePaymentMethod',
    VALIDATE_FORM: 'ValidateFormData'
}

export const ZOOZ_PAYMENT_STATUS = {
    VALID: 0,
    EXPIRED: 1,
    INVALID: 2
}

export const ZOOZ_SUBSCRIBE_RESPONSE_STATUS = {
    SUCCESS: 0,
    PARAMS_NOT_VALID: 1,
    BAD_SUBSCRIBTION: 2
}

export const PAGE_STATUS = {
    FAILED: 'FAILED',
    SUCCEED: 'SUCCEED'
}

export enum PAYMENT_STATUS {
    NO_BILLS = 'NO_BILLS',
    OVERDUE = 'OVERDUE',
    DUE = 'DUE',
    PAID = 'PAID'
}

export enum END_USER_STATUS {
    ACTIVE = 'ACTIVE',
    NOT_ACTIVE = 'NOT_ACTIVE',
    NO_DATA = 'NO_DATA'
}

export enum USER_STATUS {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DELETED = 'DELETED',
    NOTACTIVE = 'NOTACTIVE',
    TRIAL = 'TRIAL',
    UNSUBSCRIBED = 'UNSUBSCRIBED'
}

export const getUserStatusFriendly = (status: USER_STATUS) => {
    switch (status) {
        case USER_STATUS.ACTIVE:
            return i18n.t('table:userStatus:active')
        case USER_STATUS.BLOCKED:
            return i18n.t('table:userStatus:blocked')
        case USER_STATUS.DELETED:
            return i18n.t('table:userStatus:deleted')
        case USER_STATUS.NOTACTIVE:
            return i18n.t('table:userStatus:notactive')
        case USER_STATUS.TRIAL:
            return i18n.t('table:userStatus:trial')
        case USER_STATUS.UNSUBSCRIBED:
            return i18n.t('table:userStatus:unsubscribed')
    }
}

export enum USER_ACCOUNT_TYPE {
    CREDENTIAL_ONLY = 'CREDENTIAL_ONLY',
    PRIMARY = 'PRIMARY'
}

export enum PAYMENT_TYPE {
    ANNUAL = 'Annual',
    MONTHLY = 'Monthly',
    TRIAL = 'Trial'
}
export enum PAYMENT_METHOD {
    CREDIT_CARD_PAYMENT = 'Credit card payment',
    INVOICE_PAYMENT = 'Invoice payment'
}
export enum BILLING_PREFERENCES {
    CONSOLIDATED_BILL = 'Consolidated bill',
    INDIVIDUAL_BILLS = 'Individual bills'
}

export enum PAYMENT_TYPE_UPPER_CASE {
    ANNUAL = 'ANNUAL',
    MONTHLY = 'MONTHLY',
    TRIAL = 'TRIAL'
}

export const SUBSCRIPTION_TYPE_SELECT = [
    {value: 'ALL', label: 'All'},
    {value: 'MONTHLY', label: 'Monthly'},
    {value: 'ANNUAL', label: 'Annual'}
]

export const BILLING_STATUS_SELECT = [
    {value: 'ALL', label: 'All'},
    {value: 'UPCOMING', label: 'Current'},
    {value: 'OVERDUE', label: 'Overdue'},
    {value: 'TRIAL', label: 'Trial'}
]

export const OVERALL_BILLING_STATUS_SELECT = [
    {value: 'ALL', label: 'All'},
    {value: 'SUCCESSFUL', label: 'Successful'},
    {value: 'FAILURES', label: 'Failures'},
    {value: 'ALL_BILLS_ON_TRACK', label: 'All bills on track'},
    {value: 'WITH_EXCEPTIONS', label: 'With exceptions'}
]

export const TASK_STATUS_SELECT = [
    {value: 'ALL', label: 'All'},
    {value: 'COMPLETE', label: 'Completed'},
    {value: 'FAILED', label: 'Failed'},
    {value: 'NO_TASKS', label: 'No tasks'}
]

export const USER_STATUS_SELECT = [
    {value: 'ALL', label: 'All'},
    {value: 'ACTIVE', label: 'Active'},
    {value: 'NOTACTIVE', label: 'Not active'},
    {value: 'TRIAL', label: 'Trial'},
    {value: 'UNSUBSCRIBED', label: 'Unsubscribed'}
]

export const OAUTH_SERVICES_LINK = {
    PARTNER_PORTAL: {
        AZURE: '/auth/azure',
        GOOGLE: '/auth/google'
    },
    PORTAL: {
        AZURE: '/AzureOpenID',
        GOOGLE: '/CloudAlly/GoogleAppsInstallation',
        OKTA: `/${PORTAL_BASENAME}/OKTALanding`
    }
}

export const UNSUPPORTED_SERVICES: Record<string, SOURCE> = {
    SHAREPOINT: SOURCE.SHAREPOINT,
    DYNAMICS365: SOURCE.DYNAMICS365
}

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const DEBOUNCE_TIMEOUT = 1 * SECOND
export const THROTTLE_TIMEOUT = 0.2 * SECOND
export const PAGE_SIZE = 20
export const MIN_LENGTH_TO_SEARCH = 3
export const DEFAULT_PAGE = 1
export const ROWS_BULK_GROUPS = 50
export const INTERVAL = {
    DAY: 'DAY',
    MONTH: 'MONTH',
    UNLIMITED: 'UNLIMITED',
    WEEK: 'WEEK',
    YEAR: 'YEAR'
}

export const USER_MANAGEMENT_GROUPS = {
    GENERAL: 'general',
    PP_GENERAL: 'ppGeneral',
    SERVICES: 'services',
    TASKS: 'tasks'
}

export enum PERMISSION_GROUPS {
    BACKUP = 'BACKUP',
    PAGES = 'PAGES'
}

export enum DISABLE_UNLICENSED_OPTIONS {
    CONTINUE = 'CONTINUE',
    DELETE = 'DELETE',
    PAUSE = 'PAUSE'
}

export enum DEACTIVATION_TYPES {
    PAUSE = 'PAUSE',
    DELETE = 'DELETE',
    MAINTAIN_CURRENT_STATUS = 'MAINTAIN_CURRENT_STATUS'
}

export const DEACTIVATION_OPTIONS = Object.values(DEACTIVATION_TYPES).map(value => ({
    label: i18n.t(`backups:bulkActivation:deactivation:types:${value}`),
    value
}))

export enum WIZARD_TYPE {
    BULK = 'BULK',
    COMPARE = 'COMPARE',
    ONE_CLICK_ACTIVATION = 'ONE_CLICK_ACTIVATION',
    MS_BACKUP = 'MS_BACKUP',
    RECOVERY = 'RECOVERY',
    SEEDING = 'SEEDING',
    SMART_ALERT = 'SMART_ALERT'
}

export const MEDAL_LOGO = {
    HIGH_PERFORMER: 'HIGHPERFORMER',
    SMALL_BUSINESS: 'SMALLBUSINESS',
    USERS: 'USERSLOVEUS'
}

export const CLIENT_LOGO = {
    AACP: 'AACP',
    BGC: 'BGC',
    JAGUAR: 'JAGUAR',
    RAMVIK: 'RAMVIK',
    SHOOSHMONKEY: 'SHOOSHMONKEY',
    SINGLEHANDED: 'SINGLEHANDED',
    WESTLONDON: 'WESTLONDON'
} as const

export const ERROR_CODES = {
    BLOCKED: 'BLOCKED',
    INCORRECT_2FA_CODE: 'INCORRECT_2FA_CODE',
    INCORRECT_2FA_RECOVERY_CODE: 'INCORRECT_2FA_RECOVERY_CODE',
    MISSING_2FA_CODE: 'MISSING_2FA_CODE',
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
    SUSPENDED: 'SUSPENDED',
    UNTRUSTED_IP: 'UNTRUSTED_IP'
}

export type ErrorCodeType = keyof typeof ERROR_CODES

export const PUBLIC_PAGES = {
    CHANGE_PASSWORD: '/change-password-sign-in',
    ERROR: '/error',
    EXPIRATION_PASSWORD: '/expiration-password',
    LOGOUT: '/log-out',
    MS_LANDING: '/ms-landing',
    MS_LANDING_ERROR: '/ms-landing-error',
    OKTA_LANDING: '/OKTALanding',
    PASSWORD_SENT: '/password-sent',
    RESET_PASSWORD: '/reset-password',
    RESET_PASSWORD_CONFIRM: '/reset-password-confirm',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    THANK_YOU: '/thankyou'
}

export const JSESSIONID = 'JSESSIONID'

export const PROMISE_ERRORS = {
    INVALID_REFRESH_TOKEN: 'INVALID_REFRESH_TOKEN',
    UNAUTHORIZED: 'UNAUTHORIZED'
}

export const SOCIAL_NETWORK_LINKS = {
    BLOGGER: 'https://www.cloudally.com/blog',
    FACEBOOK: 'https://www.facebook.com/CloudAllyanOpenTextCompany',
    LINKEDIN: 'https://linkedin.com/company/cloudally',
    RSS: 'https://www.cloudally.com/?call_custom_simple_rss=1&csrp_posts_per_page=10&csrp_orderby=name&csrp_order=DESC',
    TWITTER: 'https://twitter.com/cloudally'
} as Record<SOCIAL_TYPES, string>

export enum PARTNER_TYPE {
    AFFILIATE_RESELLER = 'AFFILIATE_RESELLER',
    INDIRECT_RESELLER = 'INDIRECT_RESELLER',
    DIRECT_RESELLER = 'DIRECT_RESELLER',
    DISTRIBUTOR = 'DISTRIBUTOR'
}

export const INTEGRATION_TYPE = {
    REGULAR: 'REGULAR',
    ZIX: 'ZIX'
}

export enum ContainerType {
    ARROW,
    CLOUD
}

export const LIMITED_ACCESS = 'LIMITED_ACCESS'

export const SUBSCRIPTION_TYPE = {
    MONTHLY: 'Monthly',
    ANNUAL: 'Annual'
}

export const PASSWORD_POLICIES = {
    TWOFA_PAGE: '/settings/security/twoFa',
    SAVE_CODES: '/application/service/saveRecoveryCodes'
}

export const TWOFA = {
    QRCODE: 'QRCode',
    TwoFa_URL: {
        lastpass: 'https://lastpass.com/',
        onePassword: 'https://agilebits.com/onepassword',
        keeper: 'https://keepersecurity.com',
        saveCodes: '/application/service/saveRecoveryCodes'
    },
    SECURITY_PAGE: '/settings/security'
}
export const options: Intl.DateTimeFormatOptions = {month: 'long', day: 'numeric', year: 'numeric'}
export const SUBMIT_CARD_ERROR = -1
export const AMEX_WITHOUT_USD_ERROR = 3
export const FULFILLED = 'fulfilled'
export const PENDING = 'pending'
export const PAGE = 'page'
export const EN_US = 'en-US'
export const CARD_HOLDER_NAME = 'cardholderName'
export const CURRENCY_Value = 'currency'
export const NEW_BACKUP_URL = '/backups/new'

export const emailInvoiceTypeList = [
    {value: 'LINK', title: 'Link'},
    {value: 'ATTACHMENT', title: 'Attachment'}
]
export const VENDOR_CURRENCY_ERROR = 3
export const ZOOZ_SUBSCRIBE_ERROR = -2

export const END_USER_RECOVERY_FILTER = '/recovery/:taskId/'

export const COMPANY_NAME_ERROR = 'This company name already exists. Please enter a different name'
