import React from 'react'
import i18n from 'i18next'

import {Modal} from 'antd'

export const openWarningModal = (content?: React.ReactNode, title = i18n.t('modals:title:warning')): void => {
    Modal.warning({
        title,
        content
    })
}

export const openInfoModal = (content?: React.ReactNode, title = i18n.t('modals:title:info')): void => {
    Modal.info({
        title,
        content
    })
}

export const openErrorModal = (
    content?: React.ReactNode,
    title = i18n.t('modals:title:error'),
    onOk?: () => void
): void => {
    Modal.error({
        title,
        content,
        onOk
        // okButtonProps: {
        //     style: {
        //         // color: '#fff',
        //         // background: '#b0bbc6',
        //         // borderColor: '#b0bbc6',
        //         height: '24px'
        //     }
        // }
        // example, how to custom buttons
    })
}
