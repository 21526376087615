import styled from 'styled-components'
import variables from 'ca-common/variables'
import {transparentize} from 'polished'

export const StyledHomeServiceButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledHomeServiceContent = styled.button`
    display: block;
    border-radius: 50%;
    border: ${variables.gutterXs};
    padding: ${variables.gutterXs};
    background-color: transparent;
    color: ${variables.text};
    cursor: pointer;
    align-items: center;
    &:hover {
        background-color: ${transparentize(0.1, variables.blueLight)};
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    &:active {
        background-color: ${variables.blueLight};
    }
`

export const StyledHomeServiceName = styled.span`
    color: ${variables.text};
    text-align: center;
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 300;
    line-height: 160%; /* 22.4px */
`

export const StyledIndividualServiceName = styled.span`
    color: ${variables.navyBlueBorder};
    font-family: ${variables.mainFont};
    font-size: ${variables.gutterSm};
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    vertical-align: text-top;
`
