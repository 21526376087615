import {i18n} from 'src/locales'

import {N_A, DASH} from './constants'

export const transformBeNumbers = num => {
    switch (num) {
        case N_A:
            return i18n.t('table:date:noDate')

        case DASH:
            return '-'

        default:
            return num.toString()
    }
}
