import React from 'react'
import {throttle} from 'lodash'

import {THROTTLE_TIMEOUT} from 'ca-common/constants'

import {StyledInfiniteScroller} from './StyledInfinityScroller'

const LISTENER = 'scroll'

export class InfinityScroller extends React.Component {
    constructor(props) {
        super(props)
        // throttle needs to avoid multiple calling of listener which leads to scroll freezing
        this.throttledOnScroll = throttle(this.onScroll, THROTTLE_TIMEOUT)
    }

    onScroll = e => {
        const {documentElement} = e.target

        const preventHeight = documentElement.clientHeight / 2

        if (documentElement.scrollTop + documentElement.offsetHeight + preventHeight >= documentElement.scrollHeight) {
            this.props.fetchOnScroll()
        }
    }

    componentDidMount() {
        window.addEventListener(LISTENER, this.throttledOnScroll)
    }

    componentWillUnmount() {
        window.removeEventListener(LISTENER, this.throttledOnScroll, false)
    }

    render() {
        const {children} = this.props

        return <StyledInfiniteScroller>{children}</StyledInfiniteScroller>
    }
}
