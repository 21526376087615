import React, {useCallback, useEffect, useState} from 'react'
import {Tooltip} from 'antd'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import {useTranslation} from 'react-i18next'
import {omit} from 'lodash'

import {RECOVERY_TYPE, SOURCE} from 'ca-common/common/enum'
import {SwitchBar, SwitchCheckboxGroup, SwitchSettingsLabel} from 'ca-common/ui-lib/molecules/SwitchBar'

import {getDisplayName} from 'src/newcore/utils/sources'
import {InlineErrorMessage} from 'src/newcore/features/RecoveryWizard/atoms/InlineErrorMessage'
import {FormatRadioGroup} from 'src/newcore/features/RecoveryWizard/molecules/FormatRadioGroup'

import Info from 'ca-common/icons/Info.svg'
import Calendar from 'ca-common/icons/subServices/Calendar.svg'
import Contacts from 'ca-common/icons/subServices/Contacts.svg'
import Emails from 'ca-common/icons/subServices/Emails.svg'
import Notes from 'ca-common/icons/subServices/Notes.svg'
import Tasks from 'ca-common/icons/subServices/Tasks.svg'

import {
    CALENDARS_FORMAT,
    CONTACTS_FORMAT,
    EMAIL_FORMAT,
    EMAIL_SETTINGS,
    EmailSettingsType,
    FULL_RECOVERY_ERRORS,
    getEmailSettingsOptions,
    getFormatSettingsOptions,
    NOTES_FORMAT,
    RecoveryErrorType,
    SelectedSubsourses,
    SubsourceFormat,
    SubsourcesBooleanState,
    TASKS_FORMAT
} from 'src/newcore/features/RecoveryWizard/lib'
import {
    IconWrapper,
    InfoIcon,
    StyledNote,
    StyledSubTitle,
    StyledSwitchWrapper,
    StyledWrapper
} from './StyledSubservicesSelection'
import Icon from '@ant-design/icons'

type Props = {
    subServices: SubsourcesBooleanState
    setSubServices: React.Dispatch<React.SetStateAction<SubsourcesBooleanState>>
    settings: EmailSettingsType
    setSettings: React.Dispatch<React.SetStateAction<EmailSettingsType>>
    fullRecoveryOptions: RECOVERY_TYPE
    error?: RecoveryErrorType
    selectedSubsources: SelectedSubsourses
    format: SubsourceFormat
    setFormat: React.Dispatch<React.SetStateAction<SubsourceFormat>>
}

const checkSubService = (subService: string, error?: RecoveryErrorType) =>
    error && error.sourcesAlreadyExist.includes(subService)

export const SelectiveRecoverySubservicesSelection = ({
    subServices,
    setSubServices,
    settings,
    setSettings,
    fullRecoveryOptions,
    error,
    selectedSubsources,
    format,
    setFormat
}: Props): JSX.Element => {
    const {t} = useTranslation()
    const isExport = fullRecoveryOptions === RECOVERY_TYPE.EXPORT
    const {size, subSourcesState, allEmails} = selectedSubsources
    const isOnlySubSource = size === 1

    const handleSubServiceSwitch = (sub: keyof SubsourcesBooleanState) => {
        setSubServices((prev: SubsourcesBooleanState) => ({...prev, [sub]: !prev[sub]}))
    }

    const handleContactsSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365CONTACTS), [])
    const handleMailsSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365MAIL), [])
    const handleTasksSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365TASKS), [])
    const handleCalendarSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365CALENDAR), [])
    const handleNotesSwitch = useCallback(() => handleSubServiceSwitch(SOURCE.O365NOTES), [])
    const handleSubSourceFormatChange = useCallback(e => setFormat(e.target.value), [])
    const handleEmailFormatChange = useCallback(e => {
        const format: EMAIL_FORMAT = e.target.value
        setFormat(format)
        if (format !== EMAIL_FORMAT.PST) {
            setSettings(prevState => omit(prevState, EMAIL_SETTINGS.preferSinglePST))
        }
    }, [])

    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    const emailSettings = isExport ? (
        <>
            {isOnlySubSource && (
                <FormatRadioGroup
                    options={getFormatSettingsOptions(EMAIL_FORMAT.EML)}
                    handleFormatChange={handleEmailFormatChange}
                    value={format}
                />
            )}
            <>
                <SwitchSettingsLabel>
                    {t('recovery:wizard:subServiceSelection:optionallyIncludeTitle')}
                </SwitchSettingsLabel>
                <SwitchCheckboxGroup
                    value={Object.keys(settings)}
                    onChange={(e: CheckboxValueType[]) => {
                        setSettings(e.reduce((acc, current) => ({...acc, [current as string]: true}), {}))
                    }}
                    options={getEmailSettingsOptions(isOnlySubSource && allEmails, format)}
                />
            </>
        </>
    ) : null

    const getSubSourceSettings = (alternateFormat: SubsourceFormat) =>
        isExport && isOnlySubSource ? (
            <FormatRadioGroup
                options={getFormatSettingsOptions(alternateFormat)}
                handleFormatChange={handleSubSourceFormatChange}
                value={format}
            />
        ) : null

    useEffect(() => {
        if (!subServices.O365MAIL) {
            setSettings({})
        }
    }, [subServices.O365MAIL])

    return (
        <StyledWrapper isSelective>
            <StyledSubTitle>
                {t(`recovery:wizard:subServiceSelection:subtitle`)}
                {isOnlySubSource ? null : (
                    <Tooltip title={t(`recovery:wizard:subServiceSelection:subtitleTooltip`)}>
                        <InfoIcon component={Info} />
                    </Tooltip>
                )}
            </StyledSubTitle>
            <StyledSwitchWrapper isSelective>
                {subSourcesState[SOURCE.O365MAIL] && (
                    <SwitchBar
                        active={size > 1}
                        excluded={subServices.O365MAIL === undefined}
                        checked={subServices.O365MAIL}
                        error={checkSubService(SOURCE.O365MAIL, error)}
                        icon={
                            <IconWrapper>
                                <Icon component={Emails} style={{fontSize: '24px'}} />
                            </IconWrapper>
                        }
                        name={getDisplayName(SOURCE.O365MAIL)}
                        onChange={handleMailsSwitch}
                        isSettingsOpen={isSettingsOpen}
                        setIsSettingsOpen={setIsSettingsOpen}
                    >
                        {emailSettings}
                    </SwitchBar>
                )}
                {subSourcesState[SOURCE.O365CONTACTS] && (
                    <SwitchBar
                        active={size > 1}
                        excluded={subServices.O365CONTACTS === undefined}
                        checked={subServices.O365CONTACTS}
                        error={checkSubService(SOURCE.O365CONTACTS, error)}
                        icon={
                            <IconWrapper>
                                <Icon component={Contacts} style={{fontSize: '24px'}} />
                            </IconWrapper>
                        }
                        name={getDisplayName(SOURCE.O365CONTACTS)}
                        onChange={handleContactsSwitch}
                        isSettingsOpen={isOnlySubSource && isSettingsOpen}
                        setIsSettingsOpen={setIsSettingsOpen}
                    >
                        {getSubSourceSettings(CONTACTS_FORMAT.VCF)}
                    </SwitchBar>
                )}
                {subSourcesState[SOURCE.O365TASKS] && (
                    <SwitchBar
                        active={size > 1}
                        excluded={subServices.O365TASKS === undefined}
                        checked={subServices.O365TASKS}
                        error={checkSubService(SOURCE.O365TASKS, error)}
                        icon={
                            <IconWrapper>
                                <Icon component={Tasks} style={{fontSize: '24px'}} />
                            </IconWrapper>
                        }
                        name={getDisplayName(SOURCE.O365TASKS)}
                        onChange={handleTasksSwitch}
                        isSettingsOpen={isOnlySubSource && isSettingsOpen}
                        setIsSettingsOpen={setIsSettingsOpen}
                    >
                        {getSubSourceSettings(TASKS_FORMAT.ICS)}
                    </SwitchBar>
                )}
                {subSourcesState[SOURCE.O365CALENDAR] && (
                    <SwitchBar
                        active={size > 1}
                        excluded={subServices.O365CALENDAR === undefined}
                        checked={subServices.O365CALENDAR}
                        error={checkSubService(SOURCE.O365CALENDAR, error)}
                        icon={
                            <IconWrapper>
                                <Icon component={Calendar} style={{fontSize: '24px'}} />
                            </IconWrapper>
                        }
                        name={getDisplayName(SOURCE.O365CALENDAR)}
                        onChange={handleCalendarSwitch}
                        isSettingsOpen={isOnlySubSource && isSettingsOpen}
                        setIsSettingsOpen={setIsSettingsOpen}
                    >
                        {getSubSourceSettings(CALENDARS_FORMAT.ICAL)}
                    </SwitchBar>
                )}
                {subSourcesState[SOURCE.O365NOTES] && (
                    <SwitchBar
                        active={size > 1}
                        excluded={subServices.O365NOTES === undefined}
                        checked={subServices.O365NOTES}
                        error={checkSubService(SOURCE.O365NOTES, error)}
                        icon={
                            <IconWrapper>
                                <Icon component={Notes} style={{fontSize: '24px'}} />
                            </IconWrapper>
                        }
                        name={getDisplayName(SOURCE.O365NOTES)}
                        onChange={handleNotesSwitch}
                        isSettingsOpen={isOnlySubSource && isSettingsOpen}
                        setIsSettingsOpen={setIsSettingsOpen}
                    >
                        {getSubSourceSettings(NOTES_FORMAT.CSV)}
                    </SwitchBar>
                )}
            </StyledSwitchWrapper>
            {isExport && size === 1 && (
                <StyledNote>{t(`recovery:wizard:subServiceSelection:exportFormatNoteSelective`)}</StyledNote>
            )}
            {error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.SUB_TASKS_ALREADY_EXIST &&
                Boolean(error?.sourcesAlreadyExist.length) && <InlineErrorMessage status={error.status} />}
        </StyledWrapper>
    )
}
