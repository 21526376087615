import React, {useEffect, useCallback, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {Radio, RadioChangeEvent} from 'antd/es'
import {Form} from 'antd'
import find from 'lodash/find'
import Creditcard from 'ca-common/icons/PaymentDetails/Creditcard.svg'
import Invoice from 'ca-common/icons/PaymentDetails/Invoice.svg'
import SelectIcon from 'ca-common/icons/PaymentDetails/SelectIcon.svg'
import {setZoozSubscribeMonthly} from 'src/newcore/redux/modules/billing'
import {
    USER_STATUS,
    ZOOZ_SUBSCRIBE_RESPONSE_STATUS,
    VENDOR,
    CURRENCIES,
    PAGES,
    PAYMENT_TYPE,
    PAYMENT_METHOD,
    PAYMENT_TYPE_UPPER_CASE,
    options,
    AMEX_WITHOUT_USD_ERROR,
    SUBMIT_CARD_ERROR,
    PENDING,
    CURRENCY,
    PAGE,
    EN_US,
    CARD_HOLDER_NAME,
    CURRENCY_Value,
    TITLE,
    FULFILLED,
    ANNUAL_ONLY_CURRENCY,
    ANNUAL_CURRENCY
} from 'ca-common/constants'

import {Alert} from 'ca-common/ui-lib/atoms/Alert'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {PROVIDER} from 'ca-common/common/enum/Provider'
import {openSuccessNotification, openWarningNotification} from 'ca-common/utils/toasts'
import {getUrlParams} from 'ca-common/utils/url'
import {CAFormPage} from 'ca-common/ui-lib/components/FormPage'
import {CardDetailsPage} from 'src/ca-common/components/Billing/Payment/CardDetails'
import {openErrorModal} from 'ca-common/utils/modals'

import {PaymentDetails} from 'src/ca-common/components/Billing/Payment/PaymentDetails'
import {useAppSelector, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {
    getAnnualSubscribeInfo,
    setBillingDetails,
    annualSubscribe,
    updateUsersBilling,
    getNextPaymentDate,
    updateAnnualSubscribeInfo,
    getPaymentMethod,
    editAnnualSubscribe,
    getZoozPaymentMethods,
    setZoozSubscribe,
    updatePaymentMethod,
    removeZoozPaymentMethod
} from 'src/newcore/redux/modules/billing'
import {fetchUserInfo} from 'src/newcore/redux/modules/account'
import {updateUserInfo, setUnsubscribe} from 'src/newcore/redux/modules/account'
import {PaymentDetailsType} from 'src/ca-common/components/Billing/Payment/types'
import {Cards} from 'src/ca-common/components/Billing/Payment/Cards'
import {
    StyledWrapper,
    PaymentTypeWrapper,
    PaymentTypeContentsWrapper,
    PaymentDetailsIcon,
    PaymentContentsWrapper
} from './StyledAnnualSubscription'

import {usePaymentOS} from 'src/ca-common/components/Billing/Payment/CardDetails/usePaymentOS'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {getPosPublicKey} from 'src/newcore/redux/modules/billing'
import {useCurrentCurrency} from 'src/newcore/utils/useCurrentCurrency'

export const AnnualSubscriptionPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const userInfo = useAppSelector((state: AppState) => state.userInfo)
    const [showAmexCurrencyError, setShowAmexCurrencyError] = useState(false)
    const [activateCardLoader, setactivateCardLoader] = useState(false)
    const [deleteCardLoader, setdeleteCardLoader] = useState(false)
    const annualSubscribeInfo = useAppSelector((state: AppState) => state.billing.annualSubscribeInfo)
    const paymentMethod = useAppSelector((state: AppState) => state.billing.paymentMethod)
    const zoozPaymentMethods = useAppSelector((state: AppState) => state.billing.zoozPaymentMethods)
    const getIsOnlyAnnualCurrency = () => {
        const selectedCurrency = getUrlParams(history.location.search)?.currency
        return ANNUAL_ONLY_CURRENCY.some(i => i.value === selectedCurrency)
    }
    const [isOpenCardAdding, setIsOpenCardAdding] = useState(true)
    const [submittingDetails, setsubmittingDetails] = useState(false)
    const [isCreditCardPymntSubscribed, setisCreditCardPymntSubscribed] = useState(false)
    const [isInvoicePymntSubscribed, setisInvoicePymntSubscribed] = useState(false)
    const isOnlyAnnual = getIsOnlyAnnualCurrency()
    const [isCreditCardPaymentMethod, setisCreditCardPaymentMethod] = useState(!isOnlyAnnual)
    const initCurrency = useCurrentCurrency()
    //need to use `useCurrentCurrency` after refactoring
    const initialCurrency = userInfo?.response?.isSubscribed
        ? userInfo?.response?.currency
        : getUrlParams(history.location.search)?.currency || userInfo?.response?.currency

    const initialValues = {
        ...annualSubscribeInfo?.response,
        currency: initialCurrency || CURRENCIES.USD
    }
    const {zoozSubscribeStatus} = useAppSelector((state: AppState) => ({
        zoozSubscribeStatus: state.billing.zoozSubscribe.status
    }))

    const createToken = usePaymentOS(dispatch, getPosPublicKey)
    const [paymentDetailsForm] = Form.useForm<PaymentDetailsType>()
    const watchedCurrency = Form.useWatch(CURRENCY_Value, paymentDetailsForm) as CURRENCIES
    const companyInfo = useAppSelector(companyInfoSelector)

    useEffect(() => {
        if (
            paymentMethod.status === FULFILLED &&
            paymentMethod?.response.isSubscribe &&
            paymentMethod?.response.subscriptionType &&
            paymentMethod?.response.subscriptionType === PAYMENT_TYPE.MONTHLY
        ) {
            history.push('/billing/paymentDetails')
        }
        if (
            paymentMethod.status === FULFILLED &&
            paymentMethod?.response.subscriptionType &&
            paymentMethod?.response.subscriptionType === PAYMENT_TYPE.ANNUAL &&
            paymentMethod?.response.paymentProvider == PROVIDER.ZOOZ
        ) {
            setisCreditCardPaymentMethod(true)
            setisCreditCardPymntSubscribed(true)
        } else {
            if (paymentMethod.status === FULFILLED && paymentMethod?.response.isSubscribe) {
                setisInvoicePymntSubscribed(true)
                setisCreditCardPaymentMethod(false)
            }
        }

        dispatch(getZoozPaymentMethods()).then((response: any) => {
            const data = response.payload

            if (
                paymentMethod.status === FULFILLED &&
                paymentMethod?.response.isSubscribe &&
                data.paymentMethods.length
            ) {
                setIsOpenCardAdding(false)
            }
        })
        dispatch(getAnnualSubscribeInfo())
    }, [paymentMethod])

    const completeInvoiceTypeSubscription = (values: PaymentDetailsType) => {
        setsubmittingDetails(true)
        if (!annualSubscribeInfo?.response.isSubscribe) {
            dispatch(
                annualSubscribe({
                    ...values,
                    companyNumber: ''
                })
            )
                .then(() => {
                    setsubmittingDetails(false)
                    setisInvoicePymntSubscribed(true)
                    openSuccessNotification(t('billing:toasts:annualSubscriptions:success'))
                    dispatch(updateUsersBilling({paymentType: PAYMENT_TYPE.ANNUAL}))
                    dispatch(fetchUserInfo({}))
                    dispatch(getNextPaymentDate())
                    dispatch(
                        updateAnnualSubscribeInfo({
                            ...values,
                            companyNumber: '',
                            isSubscribe: true
                        })
                    )
                    dispatch(getPaymentMethod())
                    dispatch(getAnnualSubscribeInfo())
                })
                .catch((error: any) => {
                    setsubmittingDetails(false)
                    console.log(error)

                    openWarningNotification(t('table:messages:subscription:single:failed'))
                })
        } else {
            dispatch(
                editAnnualSubscribe({
                    ...values,
                    companyNumber: ''
                })
            )
                .then(() => {
                    setsubmittingDetails(false)
                    setisInvoicePymntSubscribed(true)
                    dispatch(
                        updateAnnualSubscribeInfo({
                            ...values,
                            companyNumber: ''
                        })
                    )
                    dispatch(getAnnualSubscribeInfo())
                    openSuccessNotification(t('billing:toasts:setPaymentDetails:success'))
                })
                .catch((error: any) => {
                    setsubmittingDetails(false)
                    openWarningNotification(t('table:messages:subscription:single:failed'))
                })
        }
    }

    const initSubscriptionStartedDate = () => {
        const subscriptionDate = new Date()
        if (typeof userInfo?.response?.trialDaysLeft === 'number' && userInfo?.response?.trialDaysLeft !== 0) {
            subscriptionDate.setDate(subscriptionDate.getDate() + userInfo?.response?.trialDaysLeft)
            return subscriptionDate.toLocaleString(EN_US, options)
        }
        return subscriptionDate.toLocaleString(EN_US, options)
    }
    const checkIsPaymentDetailsValid = useCallback(async () => {
        try {
            await paymentDetailsForm.validateFields()
            return true
        } catch {
            return false
        }
    }, [paymentDetailsForm])
    const onChangePaymentType = (e: RadioChangeEvent): void => {
        const paymentMethod = e.target.value
        if (paymentMethod == PAYMENT_METHOD.INVOICE_PAYMENT) {
            setisCreditCardPaymentMethod(false)
        }
        if (paymentMethod == PAYMENT_METHOD.CREDIT_CARD_PAYMENT) {
            setisCreditCardPaymentMethod(true)
        }
    }

    const submitCardData = () => {
        const cardholderName = paymentDetailsForm.getFieldValue(CARD_HOLDER_NAME)

        return createToken(cardholderName)
    }

    const createBillingMethod = (data: {vendor: string; token: string}, values: PaymentDetailsType) => {
        const {vendor, token} = data
        const subscriptionType = PAYMENT_TYPE_UPPER_CASE.ANNUAL
        return dispatch(
            setZoozSubscribe({
                ...values,
                watchedCurrency,
                vendor,
                paymentMethodToken: token,
                subscriptionType
            })
        ).then(() => {
            setsubmittingDetails(false)
            setIsOpenCardAdding(false)
            setisCreditCardPymntSubscribed(true)
            openSuccessNotification(t('billing:toasts:annualSubscriptions:success'))
            dispatch(getZoozPaymentMethods())
            dispatch(updateUserInfo({isSubscribed: true, status: USER_STATUS.ACTIVE}))
            dispatch(updateUsersBilling({paymentType: t('table:billing:subscribeType:Annual')}))
            dispatch(getNextPaymentDate())
            dispatch(getPaymentMethod())
            dispatch(
                updatePaymentMethod({
                    subscriptionType: t('table:billing:subscribeType:Annual'),
                    isSubscribe: true
                })
            )
        })
    }

    const handleOnClick = (values: PaymentDetailsType) => {
        return submitCardData()
            .then(data => {
                if (data.vendor === VENDOR.AMEX && watchedCurrency !== CURRENCIES.USD) {
                    return Promise.reject({statusCode: AMEX_WITHOUT_USD_ERROR})
                }

                return createBillingMethod(data, values)
            })
            .catch(err => {
                let errorMessage: string | JSX.Element = t('billing:zoozMessages:fillFormFields')

                if (err.statusCode === SUBMIT_CARD_ERROR) {
                    errorMessage = err.description
                } else if (err.statusCode === AMEX_WITHOUT_USD_ERROR) {
                    errorMessage = (
                        <Trans i18nKey="billing:zoozMessages:amex" values={{supportEmail: companyInfo.supportEmail}}>
                            <br />
                            <a href={`mailto:${companyInfo.supportEmail}`}>Support Email</a>
                        </Trans>
                    )

                    setShowAmexCurrencyError(true)
                }
                setsubmittingDetails(false)
                return Promise.reject(errorMessage)
            })
    }

    const completeCreditCardSubscription = async (values: PaymentDetailsType) => {
        setsubmittingDetails(true)
        if (await checkIsPaymentDetailsValid()) {
            handleOnClick(values).catch(err => openErrorModal(err))
        } else {
            openErrorModal(t('billing:zoozMessages:fillFormFields'))
            setsubmittingDetails(false)
        }
    }
    const unsubscribe = async () => {
        try {
            await dispatch(setUnsubscribe({}))
            await dispatch(updateUserInfo({isSubscribed: false}))

            if (paymentMethod.status === FULFILLED) {
                await dispatch(
                    updatePaymentMethod({
                        isSubscribe: false
                    })
                )
            }
            openSuccessNotification(t('accountPage:unsubscribeMessage', [companyInfo.name]))
        } catch {
            openErrorModal(t('accountPage:unsubscribeFailed'))
        }
    }
    const onDeleteCard = async (paymentMethodToken: string, isLastCard?: boolean) => {
        try {
            await dispatch(removeZoozPaymentMethod({paymentMethodToken}))
            await dispatch(getZoozPaymentMethods())
            openSuccessNotification(t('billing:zoozMessages:cardWasDeleted'))
            setIsOpenCardAdding(false)
            if (isLastCard) await unsubscribe()
        } catch (error: any) {
            openErrorModal(`${error?.status}`)
        }
    }
    const activateCard = useCallback(
        async (paymentMethodToken: string) => {
            if (!checkIsPaymentDetailsValid()) {
                openErrorModal(t('billing:zoozMessages:fillFormFields'))
                return
            }

            const cardMethod = find(zoozPaymentMethods.response?.paymentMethods || [], {
                paymentMethodToken
            })

            const paymentDetailsValues = paymentDetailsForm.getFieldsValue()

            dispatch(
                setZoozSubscribe({
                    ...paymentDetailsValues,
                    paymentMethodToken,
                    vendor: cardMethod?.vendor || ''
                })
            )
                .then(() => {
                    openSuccessNotification(t('billing:zoozMessages:cardWasActivated'))
                    setIsOpenCardAdding(false)
                    dispatch(getZoozPaymentMethods())
                    dispatch(updateUserInfo({isSubscribed: true, status: USER_STATUS.ACTIVE}))
                    dispatch(updateUsersBilling({paymentType: PAYMENT_TYPE.MONTHLY}))
                    dispatch(getNextPaymentDate())
                    dispatch(updatePaymentMethod({subscriptionType: PAYMENT_TYPE.MONTHLY, isSubscribe: true}))
                })
                .catch((error: any) => {
                    if (error.statusCode === ZOOZ_SUBSCRIBE_RESPONSE_STATUS.PARAMS_NOT_VALID) {
                        return openErrorModal(t('billing:zoozMessages:fillFormFields'))
                    }
                    return openErrorModal(t('billing:zoozMessages:subscriptionFailed'))
                })
        },
        [checkIsPaymentDetailsValid, zoozPaymentMethods, paymentDetailsForm]
    )
    const updatePaymentDetails = useCallback((values: PaymentDetailsType) => {
        return dispatch(setBillingDetails(values))
            .unwrap()
            .then(() => {
                dispatch(getZoozPaymentMethods())
                openSuccessNotification(t('billing:toasts:setPaymentDetails:success'))
            })
            .catch((response: {status: string}) => {
                openErrorModal(response?.status || t('billing:toasts:setPaymentDetails:error'))
            })
    }, [])

    return (
        <StyledWrapper creditCardPayment={isCreditCardPaymentMethod}>
            {userInfo?.response?.status === USER_STATUS.TRIAL && !isOnlyAnnual && (
                <>
                    <Alert
                        type="INFO"
                        title={t('billing:trialUserMessage:annual:title')}
                        message={t('billing:trialUserMessage:annual:message')}
                        link={{
                            name: t('billing:trialUserMessage:annual:link'),
                            url: `/${PAGES.BILLING}/${PAGES.PAYMENTS_DETAILS}${history.location.search}`
                        }}
                    />
                    <br />
                </>
            )}
            {isCreditCardPaymentMethod && zoozPaymentMethods.status === PENDING && <Spinner modifier={PAGE} />}
            {!isCreditCardPaymentMethod && annualSubscribeInfo.status === PENDING && <Spinner modifier={PAGE} />}
            {!isCreditCardPymntSubscribed &&
                !isInvoicePymntSubscribed &&
                zoozPaymentMethods.status === FULFILLED &&
                t('forms:paymentDetails:paymentMethod')}
            {!isCreditCardPymntSubscribed && !isInvoicePymntSubscribed && zoozPaymentMethods.status === FULFILLED && (
                <Form.Item name="paymentMethod">
                    <Radio.Group size="large" onChange={onChangePaymentType}>
                        <Radio.Button
                            value={PAYMENT_METHOD.CREDIT_CARD_PAYMENT}
                            disabled={isInvoicePymntSubscribed || isOnlyAnnual}
                        >
                            <PaymentTypeWrapper>
                                <PaymentDetailsIcon component={Creditcard} />
                            </PaymentTypeWrapper>
                            <PaymentTypeContentsWrapper>
                                {t('forms:paymentDetails:paymentMethodType:creditCard')}
                                <br />
                                <PaymentContentsWrapper>
                                    {' '}
                                    {t('forms:paymentDetails:paymentMethodType:payment')}
                                </PaymentContentsWrapper>
                            </PaymentTypeContentsWrapper>
                            {isCreditCardPaymentMethod && (
                                <PaymentDetailsIcon
                                    component={SelectIcon}
                                    style={{position: 'absolute', top: '-20px', right: '-11px', fontSize: '22px'}}
                                />
                            )}
                        </Radio.Button>

                        <Radio.Button value={PAYMENT_METHOD.INVOICE_PAYMENT} disabled={isCreditCardPymntSubscribed}>
                            <PaymentTypeWrapper>
                                <PaymentDetailsIcon component={Invoice} />
                            </PaymentTypeWrapper>
                            <PaymentTypeContentsWrapper>
                                {t('forms:paymentDetails:paymentMethodType:invoicePayment')}
                                <br />
                                <PaymentContentsWrapper>
                                    {' '}
                                    {t('forms:paymentDetails:paymentMethodType:invoice')}
                                </PaymentContentsWrapper>
                            </PaymentTypeContentsWrapper>
                            {!isCreditCardPaymentMethod && (
                                <PaymentDetailsIcon
                                    component={SelectIcon}
                                    style={{position: 'absolute', top: '-20px', right: '-11px', fontSize: '22px'}}
                                />
                            )}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
            )}

            {isCreditCardPaymentMethod && !isOnlyAnnual && zoozPaymentMethods.status === FULFILLED && (
                <CAFormPage>
                    {isCreditCardPymntSubscribed && userInfo.status == FULFILLED && (
                        <Cards
                            isPP={false}
                            paymentMethods={zoozPaymentMethods.response.paymentMethods}
                            userInfo={userInfo?.response}
                            zoozSubscribeStatus={zoozSubscribeStatus}
                            deleteCard={onDeleteCard}
                            activateCard={activateCard}
                            addCard={() => setIsOpenCardAdding(true)}
                            isLastCard={!(zoozPaymentMethods?.response?.paymentMethods?.length > 1)}
                            activateCardLoader={activateCardLoader}
                            deleteCardLoader={deleteCardLoader}
                        />
                    )}
                    {isCreditCardPaymentMethod && isOpenCardAdding && (
                        <CardDetailsPage
                            isPP={false}
                            zoozSubscribeStatus={zoozSubscribeStatus}
                            closeAddBlock={() => setIsOpenCardAdding(false)}
                            companyInfo={companyInfo}
                            checkIsPaymentDetailsValid={checkIsPaymentDetailsValid}
                            currency={watchedCurrency}
                            setShowAmexCurrencyError={setShowAmexCurrencyError}
                            getPaymentDetailsValues={paymentDetailsForm.getFieldsValue}
                            subscriptionType={PAYMENT_TYPE_UPPER_CASE.ANNUAL}
                            form={paymentDetailsForm}
                            isSubscribed={paymentMethod.response?.isSubscribe}
                            dispatch={dispatch}
                            getPosPublicKey={getPosPublicKey}
                            setZoozSubscribe={setZoozSubscribe}
                            getZoozPaymentMethods={getZoozPaymentMethods}
                            updateUsersBilling={updateUsersBilling}
                            updatePaymentMethod={updatePaymentMethod}
                            getNextPaymentDate={getNextPaymentDate}
                        />
                    )}
                    <PaymentDetails
                        isPP={false}
                        setPaymentDetails={
                            paymentMethod.response?.isSubscribe ? updatePaymentDetails : completeCreditCardSubscription
                        }
                        paymentCurrency={zoozPaymentMethods?.response?.currency}
                        currencyEditable={zoozPaymentMethods?.response?.currencyEditable}
                        paymentMethod={paymentMethod}
                        paymentMethods={zoozPaymentMethods?.response?.paymentMethods}
                        showAmexCurrencyError={showAmexCurrencyError}
                        setShowAmexCurrencyError={setShowAmexCurrencyError}
                        form={paymentDetailsForm}
                        subscriptionType={PAYMENT_TYPE.ANNUAL}
                        subscriptionStartedDate={initSubscriptionStartedDate}
                        submitting={submittingDetails}
                        annualSubsPaymentMethod={PAYMENT_METHOD.CREDIT_CARD_PAYMENT}
                        isSubscribed={paymentMethod.response?.isSubscribe}
                        initialValues={{
                            ...(zoozPaymentMethods.response.billingDetails || {}),
                            subscriptionDate: initSubscriptionStartedDate(),
                            currency: CURRENCY.some(i => i.value === initCurrency)
                                ? initCurrency
                                : zoozPaymentMethods.response.currency || CURRENCIES.USD,
                            customerTitle: zoozPaymentMethods.response.billingDetails.customerTitle || TITLE[0].value
                        }}
                        setZoozSubscribeMonthly={setZoozSubscribeMonthly}
                        isCreditCardPaymentMethod
                    />
                </CAFormPage>
            )}
            {!isCreditCardPaymentMethod && annualSubscribeInfo.status === FULFILLED && (
                <CAFormPage>
                    <PaymentDetails
                        isPP={false}
                        setPaymentDetails={completeInvoiceTypeSubscription}
                        paymentCurrency={annualSubscribeInfo?.response?.currency || CURRENCIES.USD}
                        currencyEditable={true}
                        paymentMethod={paymentMethod}
                        paymentMethods={annualSubscribeInfo?.response?.paymentMethods}
                        showAmexCurrencyError={showAmexCurrencyError}
                        setShowAmexCurrencyError={setShowAmexCurrencyError}
                        form={paymentDetailsForm}
                        subscriptionType={PAYMENT_TYPE.ANNUAL}
                        subscriptionStartedDate={initSubscriptionStartedDate}
                        submitting={submittingDetails}
                        annualSubsPaymentMethod={PAYMENT_METHOD.INVOICE_PAYMENT}
                        isSubscribed={annualSubscribeInfo?.response.isSubscribe}
                        initialValues={{
                            ...(initialValues || {}),
                            subscriptionDate: initSubscriptionStartedDate(),
                            currency: ANNUAL_CURRENCY.some(i => i.value === initCurrency)
                                ? initCurrency
                                : annualSubscribeInfo?.response?.currency || CURRENCIES.USD,
                            customerTitle: initialValues.billingDetails?.customerTitle || TITLE[0].value
                        }}
                        setZoozSubscribeMonthly={setZoozSubscribeMonthly}
                    />
                </CAFormPage>
            )}
        </StyledWrapper>
    )
}
