import {ActionReducerMapBuilder, AsyncThunkPayloadCreator, createAsyncThunk} from '@reduxjs/toolkit'

export const InitFetchedState = {
    status: 'init',
    response: undefined,
    error: undefined
}

export type FetchedStateStatuses = 'init' | 'pending' | 'fulfilled' | 'rejected'

export type ExpandResponse<T extends Record<string, {response: any}>> = {
    [P in keyof T]: T[P]['response']
}

export type FetchedState<Response = any, Error = any> =
    | {
          status: 'fulfilled'
          response: Response
          error: undefined
      }
    | {
          status: 'pending'
          response: undefined
          error: undefined
      }
    | {
          status: 'rejected'
          error: Error
          response: undefined
      }
    | {
          status: 'init'
          response: undefined
          error: undefined
      }

const afterHook = <Data = any, State = FetchedState<Data>>(
    conf: {after?: (data: Data, state: State) => Data},
    data: Data,
    state: State
) => (conf.after && conf.after(data, state)) || data

export const fetchWrapperRT = <Response = any, Request = any>(
    name: string,
    fetcher: AsyncThunkPayloadCreator<Response, Request>,
    conf: {after?: (data: Response, state: FetchedState<Response>) => Response} = {}
) => {
    const _fetcher = createAsyncThunk(name, fetcher)

    const _builder = (builder: ActionReducerMapBuilder<any>): void => {
        builder.addCase(_fetcher.fulfilled, (state, action) => {
            state.status = action.meta.requestStatus
            state.response = afterHook<Response>(conf, action.payload, state)
        })

        builder.addCase(_fetcher.rejected, (state, action) => {
            state.status = 'rejected'
            state.error = action.payload
        })

        builder.addCase(_fetcher.pending, state => {
            state.status = 'pending'
        })
    }

    return {fetcher: _fetcher, makeReducers: _builder}
}
