import React, {useEffect, useMemo, useReducer, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import type {TablePaginationConfig} from 'antd/es'
import type {FilterValue, SorterResult} from 'antd/es/table/interface'

import {checkPermission} from 'ca-common/utils/permissions'
import {openErrorNotification} from 'ca-common/utils/toasts'

import type {MS365SubSource, TODO_ANY} from 'ca-common/types'
import {PAGES, WIZARD_TYPE} from 'ca-common/constants'
import {getUrlParams} from 'ca-common/utils/url'
import {getLocalDateFromUTC, getUTCDateFromLocal, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {SORD_ORDER} from 'ca-common/common/enum/SordOrder'
import {getActivityStatusFriendly, RECOVERY_TYPE, SOURCE} from 'ca-common/common/enum'
import {getSourceName} from 'ca-common/utils/sources'

import {clearSubsourcesInfo, getCalendar, getSubsourcesInfo} from 'src/newcore/features/Recovery/redux'
import {
    changeData,
    openWizardWithConfig,
    setWizardDescription,
    setWizardIcon,
    setWizardTitle
} from 'src/newcore/redux/modules/wizard'
import {RecoveryHeader} from 'src/newcore/features/Recovery/organisms/RecoveryHeader'
import {SubsourcesBlock} from 'src/newcore/features/Recovery/organisms/SubsourcesBlock'
import {RecoveryContent} from 'src/newcore/features/Recovery/organisms/RecoveryContent/RecoveryContent'
import {RecoveryCalendar} from 'src/newcore/features/Recovery/organisms/RecoveryCalendar'
import {ExportButton, RestoreButton} from 'src/newcore/features/Recovery/atoms/ActionButton'
import {WizardModal} from 'src/newcore/components/Wizard'
import {usePrevious} from 'src/newcore/hook'
import {
    ExitModal,
    SearchLink,
    StyledActionsButtonContainer,
    StyledActionsContainer
} from 'src/newcore/features/Recovery/atoms'

import {
    checkIsStoreEmpty,
    getCheckedSubsources,
    initialState,
    localReducer
} from 'src/newcore/features/Recovery/localState/localState'
import {
    CheckFolderPayload,
    CheckTablePayload,
    LOCAL_STATE_ACTION
} from 'src/newcore/features/Recovery/localState/actionsLocalState'

import {
    cancellableFetchTableItems,
    cancellableFetchTree,
    FoldersData,
    FoldersDataState,
    getParser,
    TableDataItems,
    TableDataState
} from 'src/newcore/features/Recovery/fetch'
import type {SubsourceDataStructure} from 'src/newcore/features/Recovery/lib/types'
import {getRestoreSearchUrl} from 'src/newcore/utils/getRestoreSearchUrl'
import {EVENT_NAMES, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'
import {AppDispatch, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {FetchedStateStatuses} from 'ca-common/utils/fetchWrapperRT'
import {BackupEntity} from 'src/newcore/redux/modules/backups'
import {SubsourcesInfoResponse} from 'src/newcore/features/Recovery/redux/getSubsourcesInfo'
import {callReload} from 'src/newcore/utils/logout'

// Function checks that the date is in LONG_DATE_FORMAT with hours, minutes and seconds
const isLongDate = (date: number) => date > 11111111111111

export type SubsourceInfo = {
    status: FetchedStateStatuses
    data: {
        data: SubsourceDataStructure[]
    }
}

export type MSSelectiveRecoveryProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const TABLE_INITIAL_STATE = {data: [], size: 0, loading: false}

export const MSSelectiveRecoveryRaw = ({
    userInfo,
    subsourcesInfo,
    entity,
    calendar,
    actions,
    task
}: MSSelectiveRecoveryProps): JSX.Element => {
    const {taskId, entityId} = useParams<{taskId?: string; entityId?: string}>()

    if (!task.response || task.response?.taskId !== taskId || !userInfo.response) {
        return <></>
    }

    const {t} = useTranslation()
    const history = useHistory()
    const [folders, setFolders] = useState<FoldersDataState>({data: [], size: 0, restored: {}, loading: true})
    const [fetcher, setFetcher] = useState<any>(undefined)
    const [tableItemsFetcher, setTableItemsFetcher] = useState<any>(undefined)
    const [table, setTable] = useState<TableDataState>(TABLE_INITIAL_STATE)
    const hasPermission = useMemo(() => {
        return (
            checkPermission('BACKUP.MS365.DOWNLOAD', userInfo.response?.permissions) ||
            checkPermission('BACKUP.MS365.RESTORE', userInfo.response?.permissions)
        )
    }, [userInfo.response?.permissions])

    if (!hasPermission) {
        openErrorNotification(t('recovery:notifications:notPermitted'))
        history.goBack()
    }

    const hasPermissionToExpand = useMemo(
        () => checkPermission('PAGE.PREVIEW.ACCESS', userInfo.response?.permissions),
        [userInfo.response?.permissions]
    )

    const sendAnalytics = (subsourcesInfo: SubsourcesInfoResponse, source: SOURCE) => {
        const founded = subsourcesInfo?.data?.find(i => i.source === source)

        if (founded) {
            track(EVENT_NAMES.RECOVERY_SUB_SERVICE_CARD_SELECTED, {
                Service: getServiceNameMixpanel(task.response.source),
                'Sub Service Name': getSourceName(source),
                'Items Counter': founded?.items,
                'Backup Status': getActivityStatusFriendly(founded?.status),
                'Is Empty': founded ? founded.items < 1 && founded.folders < 1 : true
            })
        }
    }

    const [localState, dispatch] = useReducer(localReducer, initialState)

    const appDispatch = useAppDispatch()
    const selectSubsource = (subsource: MS365SubSource) => {
        if (subsource !== localState.selectedSubsource) {
            setTable(prev => ({...prev, data: [], size: 0}))
        }

        subsourcesInfo.status === 'fulfilled' && sendAnalytics(subsourcesInfo.response, subsource)
        dispatch({type: LOCAL_STATE_ACTION.SELECT_SUBSOURCE, payload: subsource})
    }
    const checkTreeItem = (data: CheckFolderPayload) => dispatch({type: LOCAL_STATE_ACTION.CHECK_FOLDER, payload: data})
    const selectTreeItem = (data: string) => dispatch({type: LOCAL_STATE_ACTION.SELECT_FOLDER, payload: data})
    const setHeader = (data?: any) => dispatch({type: LOCAL_STATE_ACTION.CHECK_ALL, payload: data})
    const setHeaderFromSubsources = (data?: any) => {
        track(EVENT_NAMES.RECOVERY_SUBSERVICE_CARD_MENU_OPTION_SELECTED, {
            Service: getServiceNameMixpanel(task.response.source),
            'Sub Service Name': getSourceName(data.subsource),
            'Selected Option': data.check
                ? t('recovery:MS365:selectionActions:selectAll')
                : t('recovery:MS365:selectionActions:clearSelected')
        })

        setHeader(data)
    }
    const checkTableItem = (data: CheckTablePayload) => dispatch({type: LOCAL_STATE_ACTION.CHECK_ITEM, payload: data})
    const resetStore = () => {
        setTable(TABLE_INITIAL_STATE)
        dispatch({type: LOCAL_STATE_ACTION.RESET_STORE})
    }

    const uncheckAll = () => {
        dispatch({type: LOCAL_STATE_ACTION.UNCHECK_ALL})
    }

    const location = useLocation()
    const initialDate = +(getUrlParams(location.search)?.date || 0) || +getLocalDateFromUTC(entity.lastBackupDate)
    const [date, setDate] = useState(0)
    const prevSubSource = usePrevious<MS365SubSource>(localState.selectedSubsource)
    const subsourceFetched = subsourcesInfo?.status === 'fulfilled'
    const selectedItemList = useMemo(() => getCheckedSubsources(localState), [
        localState[SOURCE.O365MAIL].checkAll,
        localState[SOURCE.O365CONTACTS].checkAll,
        localState[SOURCE.O365TASKS].checkAll,
        localState[SOURCE.O365CALENDAR].checkAll,
        localState[SOURCE.O365NOTES].checkAll
    ])
    const isStoreEmpty: boolean = useMemo(() => checkIsStoreEmpty(localState), [localState])
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [isPartialSelectedDate, setIsPartialSelectedDate] = useState(false)

    useEffect(() => {
        if (entity.loadingStatus !== 'fulfilled') return
        setDate(initialDate)

        actions.getCalendar({
            taskId: taskId as string,
            accountId: entity.entity,
            extid: entity.extid,
            year: moment(initialDate, LONG_DATE_FORMAT).year(),
            month: moment(initialDate, LONG_DATE_FORMAT).month() + 1
        })
    }, [entity.loadingStatus])

    useEffect(() => {
        if ((date && !isLongDate(date)) || calendar.status !== 'fulfilled') return
        const batchId =
            calendar?.response?.find((it: TODO_ANY) => +getLocalDateFromUTC(it.dateTime) == date)?.batchId || ''
        if (taskId && entityId && date) {
            appDispatch(
                getSubsourcesInfo({
                    taskId,
                    entityId,
                    batchId
                })
            )
                .unwrap()
                .then(response => {
                    if (isFirstTime) {
                        sendAnalytics(response, SOURCE.O365MAIL)
                        setIsFirstTime(false)
                    }
                })
                .catch(err => {
                    openErrorNotification(err.status)
                })
        }
        resetStore()
    }, [date, calendar.status])

    useEffect(() => {
        window.scrollTo(0, 0)

        if (history.action === 'POP') {
            const searchParams = history.location.search
            const newRecoveryInitiatedFrom = new URLSearchParams(searchParams).get('newRecoveryInitiatedFrom')

            track(EVENT_NAMES.VIEW_RECOVERY_PAGE, {
                Service: getServiceNameMixpanel(SOURCE.MS365),
                'Initiated From': newRecoveryInitiatedFrom || 'Other'
            })

            if (newRecoveryInitiatedFrom) {
                window.location.replace(window.location.href.replace(searchParams, ''))
            }
        }

        return () => {
            actions.clearSubsourcesInfo()
            resetStore()
        }
    }, [])

    const getFoldersTree = () => {
        const subSourceHasFolders = Boolean(
            subsourcesInfo?.response?.data?.find(sub => sub.source === localState.selectedSubsource)?.folders
        )

        setFolders({data: [], size: 0, restored: {}, loading: subSourceHasFolders})
        setTable(TABLE_INITIAL_STATE)
        if (!subSourceHasFolders || entity.loadingStatus !== 'fulfilled') return
        const cancellableFetch = cancellableFetchTree({
            account: entity.entity,
            taskId,
            backupDate: +getUTCDateFromLocal(date),
            extid: entity.extid,
            source: localState.selectedSubsource
        })

        cancellableFetch
            .then((data: FoldersData) => {
                setFolders({...data, loading: false})
            })
            .catch((error: any) => {
                if (error.status === 403) callReload('', '?msgType=custom&modalName=AccessDenied')
                setFolders({data: [], size: 0, restored: {}, loading: false})
            })
        return cancellableFetch
    }

    useEffect(() => {
        if (subsourceFetched) {
            if (tableItemsFetcher) {
                tableItemsFetcher.cancel()
            }

            const fetcherCall = getFoldersTree()

            if (fetcher) {
                fetcher.cancel()
            }
            setFetcher(fetcherCall)
        }
    }, [subsourcesInfo?.status, localState.selectedSubsource])

    function getSortBy(sorter: SorterResult<TableDataItems>) {
        const result = sorter.field?.toString().toUpperCase()
        if (result == 'UPDATEDATE') {
            return 'BACKUPDATE'
        }
        return result
    }

    const openRecoveryWizard = (recoveryActionType: RECOVERY_TYPE) => {
        actions.setWizardDescription(entity.entity)
        actions.setWizardTitle(t('recovery:wizard:selectiveRecovery:title', {option: capitalize(recoveryActionType)}))
        actions.setWizardIcon(recoveryActionType)
        actions.changeData({
            date,
            entity,
            taskId,
            from: PAGES.RECOVERY,
            source: SOURCE.MS365,
            fullRecoveryOptions: recoveryActionType,
            selectiveRecovery: localState,
            uncheckAll,
            originSubsources: subsourcesInfo?.response?.data,
            isPartialSelectedDate
        })
        actions.openWizardWithConfig({
            type: WIZARD_TYPE.RECOVERY,
            config: {
                destroyInactiveTabPane: false,
                autoDismissProgress: true
            }
        })
    }

    const openRestoreWizard = () => openRecoveryWizard(RECOVERY_TYPE.RESTORE)
    const openExportWizard = () => openRecoveryWizard(RECOVERY_TYPE.EXPORT)

    const getTableItems = (
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<TableDataItems> | SorterResult<TableDataItems>[]
    ) => {
        const current = pagination?.current ?? 1
        setTable(prev => ({...prev, loading: true}))
        const req: TODO_ANY = {
            account: entity.entity,
            rows: 30,
            page: current,
            folder: localState[localState.selectedSubsource].selectedFolder,
            taskId,
            backupDate: +getUTCDateFromLocal(date),
            extid: entity.extid,
            source: localState.selectedSubsource
        }
        if (!Array.isArray(sorter) && sorter?.order) {
            req.sortBy = getSortBy(sorter)
            req.order = SORD_ORDER[sorter.order]
        }

        const cancellableFetch = cancellableFetchTableItems(req, getParser(localState.selectedSubsource))
        setTableItemsFetcher(cancellableFetch)

        cancellableFetch
            .then((data: TableDataState) => {
                setTable({...data, loading: false})
            })
            .catch(() => setTable(TABLE_INITIAL_STATE))
    }

    useEffect(() => {
        if (localState[localState.selectedSubsource].selectedFolder !== '') {
            setTable(TABLE_INITIAL_STATE)
            getTableItems()
        }
    }, [localState[localState.selectedSubsource].selectedFolder])

    const searchPageLink = useMemo(() => {
        const param = getRestoreSearchUrl(task.response, entity as BackupEntity, {
            searchVia: 'search',
            isMsEndUser: !!userInfo.response?.isMsEndUser
        }).toString()

        return `/${PAGES.REDIRECT}?to=${param}`
    }, [task, entity])

    return (
        <>
            <ExitModal needToShowExitModal={!isStoreEmpty} />
            <RecoveryHeader entity={entity} />
            <StyledActionsContainer>
                <RecoveryCalendar
                    subsourcesInfo={subsourcesInfo.response}
                    entity={entity}
                    isStoreEmpty={isStoreEmpty}
                    date={date}
                    setDate={setDate}
                    source={task.response.source}
                    setIsPartialSelectedDate={setIsPartialSelectedDate}
                />
                <StyledActionsButtonContainer>
                    <SearchLink disabled={!task || !entity} to={searchPageLink} source={task.response.source}>
                        {t('recovery:actions:search')}
                    </SearchLink>

                    <RestoreButton onClick={openRestoreWizard} userInfo={userInfo} disabled={isStoreEmpty} />
                    <ExportButton onClick={openExportWizard} userInfo={userInfo} disabled={isStoreEmpty} />
                </StyledActionsButtonContainer>
            </StyledActionsContainer>
            <SubsourcesBlock
                subsources={subsourcesInfo?.response?.data || []}
                selectedSubsource={localState.selectedSubsource}
                selectedItemList={selectedItemList}
                selectSubsource={subsource => selectSubsource(subsource)}
                selectAction={setHeaderFromSubsources}
                subsourceFetched={subsourceFetched}
            />
            <RecoveryContent
                treeData={folders.data}
                treeSize={folders.size}
                restoredFolders={folders.restored}
                tableData={table.data}
                tableSize={table.size}
                loadingTable={table.loading || !subsourceFetched}
                loadingFolders={folders.loading || !subsourceFetched}
                checked={localState[localState.selectedSubsource].checkAll}
                onCheckHeader={setHeader}
                setSelectedFolder={selectTreeItem}
                setCheckedFolders={checkTreeItem}
                setTableItem={checkTableItem}
                onChangeTable={getTableItems}
                checkedTableItems={
                    localState[localState.selectedSubsource].checkedItems[
                        localState[localState.selectedSubsource].selectedFolder
                    ]
                }
                checkedFolders={localState[localState.selectedSubsource].checkedFolders}
                selectedFolder={localState[localState.selectedSubsource].selectedFolder}
                selectedSubSource={localState.selectedSubsource}
                prevSelectedSubSource={prevSubSource}
                hasPermissionToExpand={hasPermissionToExpand}
                source={task.response.source}
            />
            <WizardModal />
        </>
    )
}

const mapStateToProps = ({userInfo, recoveryNew}: AppState) => ({
    calendar: recoveryNew.calendar,
    userInfo,
    subsourcesInfo: recoveryNew.subsourcesInfo,
    entity: {...(recoveryNew.entities.response?.data?.[0] || {}), loadingStatus: recoveryNew.entities.status},
    task: recoveryNew.settings
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: {
        ...bindActionCreators(
            {
                getSubsourcesInfo,
                clearSubsourcesInfo,
                getCalendar,
                openWizardWithConfig,
                setWizardDescription,
                changeData,
                setWizardTitle,
                setWizardIcon
            },
            dispatch
        )
    }
})

export const MSSelectiveRecovery = connect(mapStateToProps, mapDispatchToProps)(MSSelectiveRecoveryRaw)
