export {SORD_ORDER} from 'ca-common/common/enum/SordOrder'

export const FIELD_SEARCH_QUERY = 'searchQuery'
export const FIELD_RULES = 'rules'
export const FIELD_EVENTS = 'events'
export const FIELD_TRIGGERED_BY = 'triggeredBy'

export const TRIGGERED_BY_SINGLE = 'SINGLE_USER'
export const TRIGGERED_BY_ALL = 'ALL_USERS'

export const EVENTS_ADDED = 'ADDED'
export const EVENTS_UPDATED = 'UPDATED'
export const EVENTS_DELETED = 'DELETED'

export const RULE_MORE_THAN = 'MORE_THAN'
export const RULE_LESS_THAN = 'LESS_THAN'
export const RULE_RECORD_LEVEL = 'RECORD_LEVEL'

export const ACTION_ENABLE = 'ENABLE'
export const ACTION_DISABLE = 'DISABLE'
export const ACTION_DELETE = 'DELETE'
export const ACTION_EDIT = 'EDIT'

export const UNIT_PERCENT = 'PERCENT'
export const UNIT_RECORD = 'RECORD'

export const ALERT_LEVEL_ALL = 'ALL'
export const ALERT_LEVEL_OBJECT = 'OBJECT'
export const ALERT_LEVEL_RECORD = 'RECORD'

export const ACTION_OPTIONS = [ACTION_ENABLE, ACTION_DISABLE, ACTION_DELETE]
export const TRIGGERED_BY_OPTIONS = [TRIGGERED_BY_SINGLE, TRIGGERED_BY_ALL]
export const EVENTS_OPTIONS = [EVENTS_ADDED, EVENTS_UPDATED, EVENTS_DELETED]
export const RULE_OPTIONS = [RULE_MORE_THAN]
export const UNIT_OPTIONS = [UNIT_PERCENT, UNIT_RECORD]

export const ALERT_STATUS_PAUSED = 'PAUSED'
export const ALERT_STATUS_ACTIVE = 'ACTIVE'

export const COLUMN_NAMES = {
    TRIGGER_OBJECT: 'object',
    RULE: 'rule',
    TOTAL: 'total',
    UNIT: 'unit',
    EVENT: 'event',
    TRIGGERED_BY: 'triggerType',
    ALERT_LEVEL: 'level',
    SPECIFIC_OBJECT: 'object',
    ALERT_STATUS: 'status',
    ID: 'id'
}

export const SORT_NAMES = {
    [COLUMN_NAMES.TRIGGER_OBJECT]: 'TRIGGER_OBJECT',
    [COLUMN_NAMES.RULE]: 'RULE',
    [COLUMN_NAMES.TOTAL]: 'TOTAL',
    [COLUMN_NAMES.UNIT]: 'UNIT',
    [COLUMN_NAMES.EVENT]: 'EVENT',
    [COLUMN_NAMES.TRIGGERED_BY]: 'TRIGGERED_BY'
}
