import _ from 'lodash'
import React from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import queryString from 'query-string'

import {Button} from 'ca-common/ui-lib/atoms/Button'

import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'

import {
    getEntitiesStatusRequest,
    getEntitiesSum,
    getSelectedEntitiesRequest
} from 'ca-common/utils/backup/backupActions'

import {
    getSelectedEntities,
    clearSelectedEntities,
    clearEntitiesStatus,
    getEntitiesStatus
} from 'src/newcore/redux/modules/backups/backupEntities'

import {Entities} from './Entities'
import {Header, Footer, StatusResult} from '../StyledConfirmAction'

const PAGE_SIZE = 30
export const DEFAULT_PAGE = 1

@withTranslation()
class ConfirmationListRaw extends React.Component {
    state = {
        excludeList: [],
        cacheToken: ''
    }

    componentDidMount() {
        const {
            action,
            actions,
            location,
            selectedAll,
            selectedRowKeys,
            taskSettings: {taskId}
        } = this.props

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        actions.getEntitiesStatus(
            getEntitiesStatusRequest(action, taskId, selectedAll, selectedRowKeys, getRequestParamsFromUrl(queryParams))
        )

        const selectedEntitiesRequest = getSelectedEntitiesRequest(
            action,
            taskId,
            selectedAll,
            selectedRowKeys,
            getRequestParamsFromUrl(queryParams),
            PAGE_SIZE,
            DEFAULT_PAGE
        )

        actions.getSelectedEntities(selectedEntitiesRequest).then(response => {
            this.setCacheToken(response.payload.data.cacheToken)
        })
    }

    componentWillUnmount() {
        this.props.actions.clearEntitiesStatus()
        this.props.actions.clearSelectedEntities()
    }

    loadMore = page => {
        const {
            action,
            actions,
            location,
            selectedAll,
            selectedRowKeys,
            taskSettings: {taskId},
            selectedEntities
        } = this.props

        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        const selectedEntitiesRequest = getSelectedEntitiesRequest(
            action,
            taskId,
            selectedAll,
            selectedRowKeys,
            getRequestParamsFromUrl(queryParams),
            PAGE_SIZE,
            page
        )

        const entities = _.get(selectedEntities, 'response')

        if (entities.page < entities.totalPages) {
            actions.getSelectedEntities(selectedEntitiesRequest)
        }
    }

    setCacheToken = cacheToken => this.setState({cacheToken})

    handleCheckboxChange = entity => {
        const {excludeList} = this.state
        const isEntityExcluded = _.includes(excludeList, entity.id)

        this.setState({
            excludeList: isEntityExcluded ? _.filter(excludeList, n => n !== entity.id) : [...excludeList, entity.id]
        })
    }

    render() {
        const {
            t,
            entitiesStatus,
            taskSettings,
            action,
            onSubmit,
            onCancel,
            loading,
            selectedAll,
            selectedRowKeys,
            selectedEntities
        } = this.props

        const {excludeList, cacheToken} = this.state

        const sum = getEntitiesSum(_.get(entitiesStatus, 'response.data'))
        const entityName = _.toLower(getSvcAccountsDescription(taskSettings.source, false, action === 'DELETE'))
        const isAllEntitiesUnchecked =
            excludeList.length === _.get(selectedEntities, 'response.data.entities', []).length

        const availableEntities = _.get(selectedEntities, 'response.data.entities', []).length - excludeList.length

        return (
            <Spin spinning={'pending' === entitiesStatus.status}>
                <Header action={action}>
                    <StatusResult>
                        {t(`modals:performAction:statusResult:${action}`, {
                            availableEntities,
                            sum,
                            entityName
                        })}
                    </StatusResult>
                    <BackupTaskAction action={action} withTitle={false} />
                </Header>

                <Entities
                    taskSettings={taskSettings}
                    action={action}
                    selectedAll={selectedAll}
                    selectedRowKeys={selectedRowKeys}
                    excludeList={excludeList}
                    handleCheckboxChange={this.handleCheckboxChange}
                    loadMore={this.loadMore}
                />

                <Footer>
                    <Button onClick={onCancel} type="link">
                        {t('modals:buttons:cancel')}
                    </Button>
                    <Button
                        onClick={() => onSubmit(action, excludeList, cacheToken)}
                        disabled={!availableEntities || isAllEntitiesUnchecked}
                        loading={loading}
                        type={action === TASK_ACTION.DELETE ? 'danger' : 'primary'}
                    >
                        {t(`backups:newActions:${action}`)}
                    </Button>
                </Footer>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    entitiesStatus: state.backups.backupEntities.entitiesStatus,
    selectedEntities: state.backups.backupEntities.selectedEntities
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getSelectedEntities,
            clearSelectedEntities,
            getEntitiesStatus,
            clearEntitiesStatus
        },
        dispatch
    )
})

export const ConfirmationList = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmationListRaw))
