import React, {memo} from 'react'
import {Checkbox} from 'antd'
import {StyledSiteWrapper} from './StyledSitesList'

type SiteProps = {
    checked: boolean
    url: string
    onChange: (url: string) => void
}

export const Site = memo(({checked, url, onChange}: SiteProps) => {
    return (
        <StyledSiteWrapper>
            <Checkbox onChange={() => onChange(url)} checked={checked}>
                {url}
            </Checkbox>
        </StyledSiteWrapper>
    )
})
