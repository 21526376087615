import {post} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'
import {createSlice} from '@reduxjs/toolkit'

const GET_FAQ = 'GET_FAQ'
const getFAQWrapper = fetchWrapperRT(GET_FAQ, async data => await post('getFaq', data))
export const getFAQ = getFAQWrapper.fetcher

const faqSlice = createSlice({
    name: 'faq',
    initialState: InitFetchedState,
    reducers: {},
    extraReducers: builder => getFAQWrapper.makeReducers(builder)
})
export const getFAQReducer = faqSlice.reducer
