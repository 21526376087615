import React, {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'

import Search from 'ca-common/icons/SearchNew.svg'

import {
    SearchIcon,
    StyledForm,
    StyledFormItem
} from 'src/newcore/features/RecoveryWizard/atoms/SearchField/StyledSearchField'
import {RoundedInput} from 'src/ca-common/ui-lib/components/FormItem'

type SearchFieldProps = {
    setQuery: Dispatch<SetStateAction<string>>
}

export const SearchField = ({setQuery}: SearchFieldProps): JSX.Element => {
    const {t} = useTranslation()

    const onValuesChange = (values: {field: any}) => {
        setQuery(values.field)
    }

    return (
        <StyledForm onValuesChange={onValuesChange}>
            <StyledFormItem name="field">
                <RoundedInput
                    prefix={<SearchIcon component={Search} />}
                    allowClear
                    placeholder={t('recovery:wizard:mailboxSelection:searchPlaceholder')}
                />
            </StyledFormItem>
        </StyledForm>
    )
}
