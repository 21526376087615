import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledError = styled.div`
    display: flex;
    margin-top: ${variables.gutterXs};
    margin-bottom: ${variables.gutterMd};

    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeSecondary};
    color: ${variables.red};
`

export const ErrorIcon = styled(Icon)`
    margin-right: ${variables.gutterXs};
    padding-top: 4px;
    color: ${variables.red};
    width: 12px;
    font-size: ${variables.fontSizeSecondary};
`
