import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Modal} from 'antd'

export const StyledModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: 0px;
    }
    .ant-modal-body {
        padding-top: 0px !important;
    }

    .ant-modal-footer {
        padding: ${variables.gutterSm} !important;
    }
`

export const StyledTitle = styled.div`
    font-size: ${variables.fontSizeBase};
    font-weight: 600;
`
