import React from 'react'
import styled from 'styled-components'
import {Input, Select, Form} from 'antd'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

export const HalfWidthFormItem = styled(Form.Item)`
    width: calc(50% - ${variables.gutterXs});
    @media screen and (max-width: ${variables.screenSm}) {
        width: 100%;
    }
`
export const HalfWidthFormItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${variables.screenSm}) {
        flex-direction: column;
    }
`

export const StyledForm = styled(Form)`
    width: 100%;
`
export const StyledError = styled.div`
    color: ${variables.red};
    text-align: center;
    margin: -20px 0 ${variables.gutterXs} 0px;
`
export const ButtonWrapper = styled.span<{disabled: boolean}>`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export const StyledButton = styled(({isCloudally, ...props}) => <Button {...props} />)`
    margin-bottom: ${props => !props.isCloudally && variables.gutterSm};
    pointer-events: ${props => props.disabled && 'none'};
    font-family: ${variables.publicPageFont};
    text-shadow: none;
    border-radius: 25px;
    color: ${variables.signupText};
    font-weight: 400;
    background-color: ${variables.brightOrange};
    transition: 0.3s;
    border-width: 0;
    height: 46px;

    &:hover {
        color: ${variables.white};
        background-color: ${variables.signupText};
    }

    &:disabled,
    &:focus {
        color: ${variables.signupText};
        background-color: ${variables.brightOrange};
    }
`

export const RoundedSelect = styled(Select)`
    &&&& .ant-select-selector {
        border-radius: 25px;
        height: 46px;

        .ant-select-selection-item {
            line-height: 44px;
            padding-left: 19px;
        }
    }

    .ant-select-arrow {
        font-size: 24px;
        color: #252a32;
        margin-right: 19px;
    }
`

export const RoundedInput = styled(Input)`
    border-radius: 25px;
    height: 46px;
    padding: 0 ${variables.gutterLg};
`

export const RoundedPasswordInput = styled(Input.Password)`
    border-radius: 25px;
    height: 46px;
    padding: 0;

    input {
        border-radius: 25px;
        padding: 0 30px !important;

        &::placeholder {
            color: ${variables.navyAsphalt};
        }
    }

    .ant-input-suffix {
        position: absolute;
        right: ${variables.gutterLg};
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
    }
`
