import {tint} from 'polished'

import {inProcess, orange, red, systemGreen, white} from 'ca-common/variables'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'
import type {Status} from './types'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'

export function getBorderColor(selection: CHECK_STATE): string {
    switch (selection) {
        case CHECK_STATE.CHECKED:
            return tint(0.4, inProcess)
        case CHECK_STATE.PARTIAL:
            return tint(0.7, inProcess)
        case CHECK_STATE.UNCHECKED:
            return tint(0.7, inProcess)
    }
}

export function getFillColor(selection: CHECK_STATE): string {
    switch (selection) {
        case CHECK_STATE.CHECKED:
            return tint(0.7, inProcess)
        case CHECK_STATE.PARTIAL:
            return tint(0.9, inProcess)
        case CHECK_STATE.UNCHECKED:
            return white
    }
}

export function getStatusColor(status: Status): string {
    switch (status) {
        case ACTIVITY_STATUS.SUCCEED:
            return systemGreen
        case ACTIVITY_STATUS.PARTIALLY_SUCCEED:
            return orange
        case ACTIVITY_STATUS.FAILED:
            return red
    }
}
