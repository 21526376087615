import React from 'react'
import styled from 'styled-components'
import {Popover} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

const PopoverRaw = ({className = '', ...props}) => <Popover {...props} overlayClassName={className} />

export const StyledPopover = styled(PopoverRaw)`
    max-width: 500px;

    .ant-popover-inner {
        padding: ${variables.gutterXs} ${variables.gutterSm};
    }

    .ant-popover-title {
        padding: 2px 0 0 0;
        min-height: 28px;
    }

    .ant-popover-inner-content {
        padding: ${variables.gutterXs} 0 0 0;
    }
`

export const StyledActivityEntity = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const InfoIcon = styled(Icon)`
    margin-right: 12px;
    width: ${variables.fontSizeBase};
    font-size: ${variables.fontSizeBase};
    cursor: pointer;
`

export const ActivityName = styled.p`
    margin-bottom: 0;
    line-height: 1.2;
`

export const PopoverTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
