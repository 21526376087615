import styled from 'styled-components'
import {Pagination} from 'antd'

import variables from 'ca-common/variables'

export const StyledPagination = styled(Pagination)`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    .ant-pagination-total-text {
        color: ${variables.navyBlue};
        margin-right: auto;
    }
    .ant-pagination-next,
    .ant-pagination-prev {
        width: 76px;
        height: 32px;
        border: 1px solid ${variables.navyBorder};
        background: ${variables.white};
        border-radius: ${variables.borderRadiusBase};
        a {
            color: ${variables.navyBlue};
        }
        :focus:not(.ant-pagination-disabled) {
            border-color: ${variables.navyBlue};
        }
        :hover:not(.ant-pagination-disabled) {
            border-color: ${variables.navyBlue};
            transition: all 0.3s;
        }
    }
    .ant-pagination-item.ant-pagination-item-active {
        background: ${variables.navyBlue};
        border: 0;

        a {
            color: ${variables.white};
        }
    }
    .ant-pagination-item {
        border-radius: ${variables.borderRadiusBase};
        a {
            color: ${variables.navyBlue};
        }
    }
`
