import styled from 'styled-components'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledHelpButton = styled(Button)`
    background: transparent;

    &:hover,
    &:active,
    &:focus {
        background: transparent;
    }

    svg {
        font-size: 18px;
    }
`
