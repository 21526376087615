import Icon from '@ant-design/icons'
import styled from 'styled-components'
import React from 'react'

import variables from 'ca-common/variables'

export const StyledTaskStatus = styled.div`
    display: flex;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TaskStatusIcon = styled(({reverse, ...props}) => <Icon {...props} />)`
    font-size: 24px;
    margin-left: ${props => (props.reverse ? variables.gutterXs : 0)};
    margin-right: ${props => (props.reverse ? 0 : variables.gutterXs)};
    order: ${props => (props.reverse ? 1 : 0)};
`
