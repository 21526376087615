import React from 'react'
import i18n from 'i18next'
import {PopoverContentRow} from '../../atoms/PopoverContentRow'
import {formatUTCDateTime} from 'ca-common/utils/datetime'
import {DefaultPopoverProps, PopoverDescription} from 'src/newcore/features/ActivityTable/types'
import {Content} from './StyledPopoverContent'

export const ExportPopoverContent = ({description, date}: DefaultPopoverProps<PopoverDescription>): JSX.Element => {
    const {countEntities, performedBy, exportDestination, snapshotDate} = description
    const exportDestinationText = exportDestination && i18n.t(`activity:exportDestination:${exportDestination}`)
    return (
        <Content>
            <PopoverContentRow
                label="snapshotDate"
                rowText={snapshotDate ? formatUTCDateTime(snapshotDate) : formatUTCDateTime(date)}
            />
            <PopoverContentRow label="numberOfRecords" rowText={countEntities} />
            <PopoverContentRow label="exportDestination" rowText={exportDestinationText} />
            <PopoverContentRow label="initiator" rowText={performedBy} />
        </Content>
    )
}
