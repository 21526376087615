import React, {useState} from 'react'
import {Select, Tooltip, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import queryString from 'query-string'

import type {FieldData} from 'rc-field-form/lib/interface'

import {EMPTY_ERROR_MESSAGE, hasEmptyErrorsAntd4, hasErrorsAntd4} from 'ca-common/utils/form'
import {
    passwordPatternValidation,
    passwordSpecialCharValidation,
    trailingOrLeadingSpace
} from 'ca-common/utils/validation'
import {getDataCenters, getFriendlyRegion} from 'ca-common/utils/Region'
import {REGION} from 'ca-common/common/enum'

import {
    ButtonWrapper,
    StyledError,
    HalfWidthFormItem,
    HalfWidthFormItemWrapper,
    StyledButton,
    StyledForm,
    RoundedInput,
    RoundedPasswordInput,
    RoundedSelect
} from './StyledSignUpForm'

const MAX_PASSWORD_LENGTH = 60
const MIN_PASSWORD_LENGTH = 8

const {Option} = Select

type Props = {
    error?: string | string[] | null
    companyInfo: {
        isCloudally?: boolean
        partnerRegion?: string
    }
    onSubmit: (arg0: any, arg1: string) => void
    loading: boolean
    queryParams: string
}

export const SignUpForm = ({error, companyInfo, onSubmit, loading, queryParams}: Props): JSX.Element => {
    const {t} = useTranslation()
    const history = useHistory()
    const {email} = queryString.parse(history.location.search)

    const [form] = Form.useForm()
    const {getFieldValue} = form

    const [hasErrors, setHasErrors] = useState(false)
    const [hasEmptyErrors, setHasEmptyErrors] = useState(false)

    const region = companyInfo.partnerRegion || REGION.US_EST

    const compareToFirstPassword = async (rule: any, value: any) => {
        if (value && value !== getFieldValue('password')) {
            throw new Error(t('forms:common:fields:passwordConfirm:validation:invalid'))
        }
    }

    const onFieldsChange = (_: FieldData[], allValues: FieldData[]) => {
        setHasErrors(state => {
            const temporary = hasErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })

        setHasEmptyErrors(state => {
            const temporary = hasEmptyErrorsAntd4(allValues)

            return state !== temporary ? temporary : state
        })
    }

    const onSubmitHandler = () => onSubmit(form.getFieldsValue(), queryParams)

    return (
        <StyledForm onFinish={onSubmitHandler} onFieldsChange={onFieldsChange} form={form} initialValues={{email}}>
            <HalfWidthFormItemWrapper>
                <HalfWidthFormItem
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: EMPTY_ERROR_MESSAGE
                        }
                    ]}
                >
                    <RoundedInput size="large" placeholder={t('forms:common:fields:firstName:placeholder')} />
                </HalfWidthFormItem>
                <HalfWidthFormItem
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: EMPTY_ERROR_MESSAGE
                        }
                    ]}
                >
                    <RoundedInput size="large" placeholder={t('forms:common:fields:lastName:placeholder')} />
                </HalfWidthFormItem>
            </HalfWidthFormItemWrapper>
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: EMPTY_ERROR_MESSAGE
                    },
                    {
                        type: 'email',
                        message: t('forms:common:fields:email:validation:invalid')
                    }
                ]}
            >
                <RoundedInput size="large" placeholder={t('forms:common:fields:email:placeholder')} />
            </Form.Item>
            <HalfWidthFormItemWrapper>
                <HalfWidthFormItem
                    name="password"
                    rules={[
                        {
                            max: MAX_PASSWORD_LENGTH,
                            message: t('validation:maxPassword', [MAX_PASSWORD_LENGTH])
                        },
                        {
                            min: MIN_PASSWORD_LENGTH,
                            message: t('validation:minPassword', [MIN_PASSWORD_LENGTH])
                        },
                        {
                            required: true,
                            message: t('validation:atLeastOneNumAndChar'),
                            pattern: passwordPatternValidation
                        },
                        {
                            required: true,
                            message: t('validation:specialCharValidation'),
                            pattern: passwordSpecialCharValidation
                        },
                        {
                            required: true,
                            message: t('validation:trailingOrLeadingSpace'),
                            pattern: trailingOrLeadingSpace
                        }
                    ]}
                    validateFirst
                >
                    <RoundedPasswordInput size="large" placeholder={t('forms:common:fields:password:placeholder')} />
                </HalfWidthFormItem>
                <HalfWidthFormItem
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: EMPTY_ERROR_MESSAGE
                        },
                        {
                            validator: compareToFirstPassword
                        }
                    ]}
                >
                    <RoundedPasswordInput
                        size="large"
                        placeholder={t('forms:common:fields:passwordConfirm:placeholder')}
                    />
                </HalfWidthFormItem>
            </HalfWidthFormItemWrapper>
            <Form.Item name="location" initialValue={region}>
                <RoundedSelect
                    size="large"
                    placeholder={t('forms:common:fields:location:placeholder')}
                    getPopupContainer={t => t.parentElement as HTMLElement}
                >
                    {getDataCenters().map((location: REGION) => (
                        <Option key={location} value={location}>
                            {getFriendlyRegion(location)}
                        </Option>
                    ))}
                </RoundedSelect>
            </Form.Item>
            {error && <StyledError>{error}</StyledError>}
            <Tooltip title={hasEmptyErrors && t('forms:common:fields:common:validation:required')}>
                <ButtonWrapper disabled={hasEmptyErrors}>
                    <StyledButton
                        isCloudally={companyInfo.isCloudally}
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={hasEmptyErrors || hasErrors || loading}
                    >
                        {t('sign:up:submitText')}
                    </StyledButton>
                </ButtonWrapper>
            </Tooltip>
        </StyledForm>
    )
}
