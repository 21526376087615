import React from 'react'
import {useTranslation} from 'react-i18next'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

import {ALTERNATIVE_FULL_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import MailBoxExport from 'ca-common/icons/recoveryWizard/MailboxExport.svg'
import MailboxRestore from 'ca-common/icons/recoveryWizard/MailboxRestore.svg'
import {RECOVERY_TYPE, RESTORE_TYPE} from 'ca-common/common/enum'
import {MS365SubSource} from 'ca-common/types'

import {SubsourceFormat, TARGET_DESTINATION} from 'src/newcore/features/RecoveryWizard/lib'
import {ConfirmationRow} from 'src/newcore/features/RecoveryWizard/atoms/ConfirmationRow'
import {SubSourcesList} from 'src/newcore/features/RecoveryWizard/molecules/SubSourcesList'
import {StyledHeader, StyledWrapper, StyledIcon, StyledInnerWrapper} from './StyledConfirmation'

type Props = {
    date: number
    account: string
    targetAccount: string
    multipleSources: Array<MS365SubSource>
    storageType: TARGET_DESTINATION
    fullRecoveryOptions: RECOVERY_TYPE
    format?: SubsourceFormat
    restoreType?: RESTORE_TYPE
}

export const RestoreOrExportConfirmation = ({
    date,
    multipleSources,
    storageType,
    targetAccount,
    fullRecoveryOptions,
    account,
    format,
    restoreType
}: Props): JSX.Element => {
    const {t} = useTranslation()
    const sourcesListWithIcons = <SubSourcesList sources={multipleSources} />
    const isExport = fullRecoveryOptions === RECOVERY_TYPE.EXPORT
    const confirmationImage = isExport ? MailBoxExport : MailboxRestore

    return (
        <StyledWrapper>
            <StyledInnerWrapper>
                <StyledHeader>{t(`recovery:wizard:confirmation:title`)}</StyledHeader>
                <ConfirmationRow
                    label={t(`recovery:wizard:confirmation:labels:subServices`, {
                        action: capitalize(fullRecoveryOptions)
                    })}
                    content={sourcesListWithIcons}
                />
                {isExport && (
                    <ConfirmationRow
                        label={t(`recovery:wizard:confirmation:labels:format`)}
                        content={t(`recovery:wizard:confirmation:format`, {format})}
                    />
                )}
                <ConfirmationRow
                    label={t(`recovery:wizard:confirmation:labels:date`)}
                    content={moment(date, LONG_DATE_FORMAT).format(ALTERNATIVE_FULL_FORMAT)}
                />
                <ConfirmationRow
                    label={t(`recovery:wizard:confirmation:labels:archive`, {
                        action: capitalize(fullRecoveryOptions)
                    })}
                    content={account}
                />
                {isExport ? (
                    <ConfirmationRow
                        label={t(`recovery:wizard:confirmation:labels:storage`)}
                        content={t(`recovery:wizard:targets:${storageType}`)}
                    />
                ) : (
                    <ConfirmationRow label={t(`recovery:wizard:confirmation:labels:target`)} content={targetAccount} />
                )}
                {restoreType && (
                    <ConfirmationRow
                        label={t(`recovery:wizard:confirmation:labels:restoreType`)}
                        content={t(`recovery:wizard:confirmation:restoreType:${restoreType}`)}
                    />
                )}
            </StyledInnerWrapper>
            <StyledIcon component={confirmationImage} />
        </StyledWrapper>
    )
}
