import React from 'react'
import Icon from '@ant-design/icons'
import PropTypes from 'prop-types'

import Gear from 'ca-common/icons/Gear.svg'
import GearActive from 'ca-common/icons/GearActive.svg'
import {BackupAuthStatus} from 'ca-common/components/BackupAuthStatus'

import {PANEL_KEY} from 'src/newcore/features/BackupSettings/lib'

import {StyledHeader, StyledHeaderLeft, StyledHeaderRight, StyledSourceIcon} from '../StyledHeader'

export const MultiEntityHeader = props => {
    const {source, isAuthenticated, onBackupAuthClick, togglePanel, activeKey} = props

    return (
        <StyledHeader onClick={togglePanel}>
            <StyledHeaderLeft>
                <StyledSourceIcon source={source} fontSize={48} />
                <BackupAuthStatus isAuthenticated={isAuthenticated} onClick={onBackupAuthClick} />
            </StyledHeaderLeft>

            <StyledHeaderRight>
                <Icon component={activeKey === PANEL_KEY ? GearActive : Gear} style={{fontSize: '22px'}} />
            </StyledHeaderRight>
        </StyledHeader>
    )
}

MultiEntityHeader.propTypes = {
    togglePanel: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool,
    activeKey: PropTypes.string
}

MultiEntityHeader.defaultProps = {
    isAuthenticated: false,
    activeKey: ''
}
