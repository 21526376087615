import styled from 'styled-components'
import {Button} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledButton = styled(Button)`
    padding-left: ${variables.gutterSm};
    padding-right: ${variables.gutterSm};
    background: ${variables.navyBlue};
    color: ${variables.white};
`

export const StyledButtonText = styled.span`
    font-family: ${variables.accentFont};
    font-style: normal;
    font-weight: 700;
    font-size: ${variables.fontSizeBase};
    line-height: 16px;
`

export const ModalWrapper = styled.div`
    margin: -24px;
`

export const StyledIcon = styled(Icon)`
    font-size: 40px;
    margin-right: 24px;
`

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledErrorHeader = styled.div`
    color: ${variables.red};
    font-family: ${variables.mainFont};
    font-weight: 600;
`

export const StyledErrorText = styled.div`
    margin-top: 12px;
    font-family: ${variables.mainFont};
`
