import {USER_ACCOUNT_TYPE, INVOICE_LINE_TYPE, CREDENTIALS_TYPES} from 'ca-common/constants'
import {TODO_ANY} from 'ca-common/types'

export const isPrimary = (accountType: USER_ACCOUNT_TYPE): boolean => accountType === USER_ACCOUNT_TYPE.PRIMARY

export const isOpenID = (credentialType?: CREDENTIALS_TYPES): boolean => credentialType !== CREDENTIALS_TYPES.EMAIL

export const invoiceLineType = (invoiceLineType?: INVOICE_LINE_TYPE): TODO_ANY => {
    return invoiceLineType ? INVOICE_LINE_TYPE.PERSERVICE : INVOICE_LINE_TYPE.PERTASK
}
