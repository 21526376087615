import {ACTIVITY_STATUS, ActivityStatusValues} from 'ca-common/utils/getActivityStatusIcon'

const ROW_CLASSES = {
    SUCCEED: 'ant-table-row--succeeded',
    FAILED: 'ant-table-row--failed',
    PARTIAL: 'ant-table-row--partial'
} as const

export const getRowClassNameFromStatus = (status: ActivityStatusValues): string => {
    switch (status) {
        case ACTIVITY_STATUS.COMPLETED:
        case ACTIVITY_STATUS.SUCCESS:
            return ROW_CLASSES.SUCCEED
        case ACTIVITY_STATUS.FAILED:
            return ROW_CLASSES.FAILED
        case ACTIVITY_STATUS.EXCEPTION:
            return ROW_CLASSES.PARTIAL
        default:
            return ''
    }
}
