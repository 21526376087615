import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledText = styled.div`
    margin-top: ${variables.gutterMd};
    color: ${variables.brandDark};
    text-align: center;
`

export const StyledIcon = styled(Icon)`
    margin-top: ${variables.gutterMd};
    font-size: 50px;
`
