import React from 'react'
import {StyledValue} from 'src/newcore/features/Homepage/atoms/ValueInfo/StyledValueInfo'

type ValueInfoProps = {
    value: string | number
}

export const ValueInfo = ({value}: ValueInfoProps): JSX.Element => {
    return <StyledValue>{value}</StyledValue>
}
