import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;

    .ant-select {
        margin-top: -3px;
    }

    .ant-select-arrow {
        color: ${variables.text};
    }

    .ant-select-selection-item {
        color: ${variables.text};
        font-weight: 400;
        text-decoration: underline;
        font-size: ${variables.subTitleFontSize};
    }

    .ant-form-item-control {
        margin-left: -${variables.gutterSm};
    }

    .ant-form-item-label > label {
        font-size: ${variables.subTitleFontSize};
    }
`
