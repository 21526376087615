import React from 'react'
import i18n from 'i18next'
import {Table} from 'antd'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {EntitiesTaskStatus} from 'src/newcore/molecules/EntitiesTaskStatus'
import {TagsColumn} from 'ca-common/ui-lib/molecules/TagsColumn'
import {SOURCE, TASK_STATUS} from 'ca-common/common/enum'

import {BackupActions} from 'src/newcore/components/BackupActions'
import {LimitedAccess} from 'src/newcore/molecules/LimitedAccess'
import {LongName, LastBackup} from '../CellModifier'
import {TASK_STATUS_WIDTH, BACKUP_SIZE_WIDTH, LAST_BACKUP_WIDTH, ACTIONS_WIDTH, TAGS_WIDTH} from '.'

export const gdriveTeam = (source: SOURCE) => [
    {
        title: getSvcAccountsDescription(source),
        dataIndex: 'entity',
        render: (entity: string) => <LongName name={entity} />,
        onCell: () => ({'data-hj-suppress': true}),
        ellipsis: true,
        sorter: true
    },
    {
        title: i18n.t('table:columns:tags'),
        render: (entity: any) => (
            <>
                <TagsColumn entity={entity} />
                {entity.limitedAccess && <LimitedAccess isBackground={true} />}
            </>
        ),
        width: TAGS_WIDTH
    },
    {
        title: i18n.t('table:columns:status'),
        dataIndex: 'status',
        render: (_: any, entity: {current: any; state: any; status: TASK_STATUS}) => (
            <EntitiesTaskStatus
                progress={{currentCount: entity?.current, backupState: entity?.state}}
                status={entity.status}
            />
        ),
        width: TASK_STATUS_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:backupSize'),
        dataIndex: 'size',
        width: BACKUP_SIZE_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:lastBackup'),
        dataIndex: 'lastBackup',
        render: (_: any, entity: {lastBackup: any; lastBackupDate: number}) => <LastBackup entity={entity} />,
        width: LAST_BACKUP_WIDTH,
        sorter: true
    },
    {
        title: i18n.t('table:columns:backup'),
        render: (_: any, entity: {id: any; status: TASK_STATUS}) => (
            <BackupActions taskInfo={entity} performEntities={[entity.id]} source={source} />
        ),
        width: ACTIONS_WIDTH
    },
    Table.EXPAND_COLUMN
]
