import styled from 'styled-components'

export const CompanyNameText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledSecurityPageTitle = styled.div`
    display: inline-flex;
    white-space: nowrap;
    line-height: 16px;
`
