import React, {Fragment} from 'react'
import {Upload} from 'antd'
import {withTranslation, Trans} from 'react-i18next'
import {isEmpty} from 'lodash'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import {CancelLink, LinkWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'

import {
    getUploadedCSVToken,
    clearUploadedCSVEmails,
    clearUploadedCSVToken
} from 'src/newcore/redux/modules/backups/uploadCSV'

import {ModalContentWrapper, StyledSampleButton, UploadText, UploadHint} from './StyledUploadCSV'

import {UploadCSVFailed} from './UploadCSVFailed'
import {clearWizard, nextStep, setWizardDescription, previousStep} from 'src/newcore/redux/modules/wizard'

const {Dragger} = Upload
const SAMPLE_LINK = 'https://public-content-resources.s3.amazonaws.com/static/sample.csv'

@withTranslation()
export class UploadCSVRaw extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fileList: []
        }
    }

    static isMulti = false
    static singleIndex = 0
    static maxFileSize = 5000000

    componentDidMount() {
        const {actions, t} = this.props

        actions.setWizardDescription(t('backups:uploadCSV:uploadTitleDescription'))
    }

    render() {
        const {t, uploadedCSVToken, actions} = this.props
        const {fileList} = this.state
        const getTokenIsFailed = uploadedCSVToken.status === 'rejected'
        const getTokenIsPending = uploadedCSVToken.status === 'pending'
        const fileSize = fileList[UploadCSVRaw.singleIndex]?.size
        const invalidFileSize = fileSize > UploadCSVRaw.maxFileSize
        const errorMsg = uploadedCSVToken.error?.status

        const draggerProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)

                    return {
                        fileList: newFileList
                    }
                })
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: UploadCSVRaw.isMulti ? [...state.fileList, file] : [file]
                }))

                if (getTokenIsFailed) {
                    actions.clearUploadedCSVToken()
                }

                return false
            },
            fileList,
            showUploadList: false,
            multiple: UploadCSVRaw.isMulti
        }

        return (
            <Fragment>
                <ModalContentWrapper>
                    {getTokenIsFailed || invalidFileSize ? (
                        <UploadCSVFailed
                            handleSampleClick={this.handleSampleClick}
                            fileList={fileList}
                            draggerProps={draggerProps}
                            invalidFileSize={invalidFileSize}
                            errorMsg={errorMsg}
                        />
                    ) : (
                        <Dragger {...draggerProps} disabled={getTokenIsPending}>
                            {this.renderContent(fileList)}
                        </Dragger>
                    )}
                </ModalContentWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <CancelLink onClick={this.clearDataAndCloseModal} disabled={getTokenIsPending}>
                            {t('modals:buttons:cancel')}
                        </CancelLink>
                        {!getTokenIsFailed && !invalidFileSize && (
                            <Button
                                type="primary"
                                onClick={this.handleUpload}
                                disabled={isEmpty(fileList) || getTokenIsPending}
                                loading={getTokenIsPending}
                            >
                                <span>{t('backups:uploadCSV:uploadButton')}</span>
                            </Button>
                        )}
                    </LinkWrapper>
                    <BackLink onClick={() => actions.previousStep()} />
                </ModalFooter>
            </Fragment>
        )
    }

    handleSampleClick = e => {
        e.stopPropagation()

        setTimeout(() => {
            window.open(SAMPLE_LINK, 'Download')
        }, 100)
    }

    renderContent = fileList => {
        const {t} = this.props

        return isEmpty(fileList) ? (
            <Fragment>
                <UploadText>{t('backups:uploadCSV:selectText')}</UploadText>
                <UploadHint>
                    <Trans i18nKey="backups:uploadCSV:selectHint">
                        <StyledSampleButton type="link" size="small" onClick={this.handleSampleClick}>
                            sample
                        </StyledSampleButton>
                    </Trans>
                </UploadHint>
            </Fragment>
        ) : (
            <Fragment>
                <UploadText>{t('backups:uploadCSV:fileAdded')}</UploadText>
                <UploadHint>
                    {fileList.map((i, index) => (
                        <span key={index}>{i.name}</span>
                    ))}
                </UploadHint>
            </Fragment>
        )
    }

    handleUpload = () => {
        const {actions, match} = this.props
        const {fileList} = this.state
        const formData = new FormData()
        const {taskId} = match.params

        formData.append('media', fileList[UploadCSVRaw.singleIndex])
        actions
            .getUploadedCSVToken({formData, json: {taskId}})
            .unwrap()
            .then(() => {
                actions.nextStep()
            })
    }

    clearDataAndCloseModal = () => {
        const {actions} = this.props

        actions.clearUploadedCSVToken()
        actions.clearUploadedCSVEmails()
        actions.clearWizard()
    }
}

const mapStateToProps = state => {
    const {token} = state.backups.uploadCSV

    return {
        uploadedCSVToken: {
            error: token.error,
            status: token.status,
            ...token.response
        }
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getUploadedCSVToken,
            clearUploadedCSVEmails,
            clearUploadedCSVToken,
            nextStep,
            previousStep,
            clearWizard,
            setWizardDescription
        },
        dispatch
    )
})

export const UploadCSV = withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadCSVRaw))
