import {SOURCE} from 'ca-common/common/enum'

const services = [SOURCE.MS365, SOURCE.MS365GROUPS, SOURCE.SHAREPOINT_MULTI, SOURCE.ONEDRIVE]

export const isOCAVisible = (availableServices: SOURCE[]) => {
    const totalServicesWithPermissions = services.reduce((acc, service) => {
        if (availableServices.includes(service)) acc += 1
        return acc
    }, 0)

    return totalServicesWithPermissions >= 2
}
