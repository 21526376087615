import React, {Component} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'

import {setWizardDescription, setWizardTitle} from 'src/newcore/redux/modules/wizard'

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setWizardTitle,
            setWizardDescription
        },
        dispatch
    )
})

export function withSetWizardTitleAndDescription(WrappedComponent) {
    return connect(
        null,
        mapDispatchToProps
    )(
        class extends Component {
            componentDidMount() {
                const {actions, title, description} = this.props

                actions.setWizardTitle(title)
                actions.setWizardDescription(description)
            }

            render() {
                return <WrappedComponent {...this.props} />
            }
        }
    )
}
