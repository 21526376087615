import styled from 'styled-components'
import {Table} from 'antd'

import variables from 'ca-common/variables'

export const StyledTable = styled(Table)`
    .ant-table-body {
        overflow-x: auto !important;

        .ant-table-thead > tr > th {
            border-top: 1px solid ${variables.navyBorder};
        }

        .ant-table-tbody > .ant-table-row > td {
            background-color: ${variables.white};
            border-bottom-color: ${variables.white};
        }
        .ant-table-column-title {
            font-weight: 600;
        }
    }
`
