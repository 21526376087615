import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'
import {
    StyledBackupTitleForm,
    BackupTitleHeader,
    BackupTitleBody,
    BackupTitleFooter
} from 'src/newcore/components/BackupTitle'
import {BackupName} from 'src/newcore/molecules'

type BackupTitleProps = {
    alias: string
    onSubmit: (values: any) => void
    onCancel: any
    loading: boolean
}

export const BackupTitle = ({alias, onSubmit, onCancel, loading}: BackupTitleProps): JSX.Element => {
    const {t} = useTranslation()

    return (
        <StyledBackupTitleForm
            initialValues={{alias}}
            layout="horizontal"
            onFinish={values => onSubmit(values)}
            hideRequiredMark
        >
            <BackupTitleHeader>{t('forms:backupTitle:text:modalTitle')}</BackupTitleHeader>
            <BackupTitleBody>
                <BackupName />
            </BackupTitleBody>
            <BackupTitleFooter>
                <Button type="link" onClick={onCancel}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type="primary" htmlType="submit" ghost loading={loading}>
                    {t('forms:common:actions:save')}
                </Button>
            </BackupTitleFooter>
        </StyledBackupTitleForm>
    )
}
