import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Popconfirm} from 'antd'

import {COMPARE_STATUS} from 'ca-common/common/enum/CompareItem'
import {Button} from 'ca-common/ui-lib/atoms/Button'

import {StyledFooter} from './StyledFooter'

@withTranslation()
export class CompareItemFooter extends Component {
    render() {
        const {t, status, cancelTask, redirect, hasPermissions} = this.props

        return (
            <StyledFooter>
                {status === COMPARE_STATUS.IN_PROCESS && (
                    <Popconfirm
                        placement="topRight"
                        title={t(`compareItem:confirmCancel`)}
                        onConfirm={cancelTask}
                        okText={t('modals:buttons:yes')}
                        cancelText={t('modals:buttons:no')}
                    >
                        <Button type="link" disabled={!hasPermissions}>
                            {t('compareItem:cancelButton')}
                        </Button>
                    </Popconfirm>
                )}
                <Button
                    type="primary"
                    disabled={!hasPermissions || status !== COMPARE_STATUS.SUCCESS}
                    onClick={redirect}
                >
                    {t('compareItem:resultButton')}
                </Button>
            </StyledFooter>
        )
    }
}
