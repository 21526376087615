import {SOURCE} from 'ca-common/common/enum'

import type {BackupConfig, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {OCA_SERVICES} from 'src/newcore/features/OneClickActivation/lib/constants'

export const getTaskSettingsInitialValues = (wizardData: OCAWizardData): Record<string, any> => {
    return Object.entries(wizardData).reduce((acc, [service, value]) => {
        const settings = (value as BackupConfig)?.settings
        if (OCA_SERVICES.includes(service as SOURCE) && settings) {
            Object.entries(settings).forEach(([key, value]) => {
                acc[`${service}.${key}`] = value
            })
        }

        return acc
    }, {} as Record<string, any>)
}
