import React, {memo, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import type {TFunction, TFunctionResult} from 'i18next'

import Spinner from 'ca-common/icons/spinnerProgress.svg'
import {ProgressBar} from 'ca-common/ui-lib/atoms/ProgressBar'
import {SOURCE, SUBSOURCE_PROGRESS_STATUS} from 'ca-common/common/enum'
import type {ProgressData} from 'ca-common/types'

import {Content, ItemCount, ItemIconWrapper, ItemName, ItemRow, SubSourceIcon} from './StyledSingleSourceProgressBar'

type JobsProgressBarProps = {
    progressData: ProgressData[]
}

const minProgressWidth = 10 // in %
const SOURCES_WITHOUT_TOTAL = [SOURCE.SFC, SOURCE.SHAREPOINT_MULTI, SOURCE.ONEDRIVE]

const getSubSourceCountOrStatus = (subSource: ProgressData, t: TFunction): number | TFunctionResult => {
    switch (subSource.taskProgress.status) {
        case SUBSOURCE_PROGRESS_STATUS.INPROGRESS:
            return subSource.taskProgress.totalCount && !SOURCES_WITHOUT_TOTAL.includes(subSource.subSource as SOURCE)
                ? t('progressBar:jobsProgress', {
                      count: subSource.taskProgress.count,
                      total: subSource.taskProgress.totalCount
                  })
                : subSource.taskProgress.count
        case SUBSOURCE_PROGRESS_STATUS.UNAVAILABLE:
            return t('progressBar:unavailable')
        default:
            return t(`progressBar:status:${subSource.taskProgress.status}`)
    }
}

const INITIAL_SUBSOURCE_VALUE = {
    subSource: undefined,
    taskProgress: {
        status: SUBSOURCE_PROGRESS_STATUS.PENDING,
        count: 0,
        totalCount: 0
    }
}

export const SingleSourceJobsProgressBar = memo(
    ({progressData}: JobsProgressBarProps): JSX.Element => {
        const {t} = useTranslation()
        const source = progressData[0] || INITIAL_SUBSOURCE_VALUE
        const progressInfo = useMemo(
            () => (
                <Content>
                    <ItemRow status={source.taskProgress.status}>
                        <ItemIconWrapper>
                            <SubSourceIcon status={source.taskProgress.status} component={Spinner} />
                            <ItemName>{t('progressBar:singleEntityItemName')}</ItemName>
                        </ItemIconWrapper>
                        <ItemCount>{getSubSourceCountOrStatus(source, t)}</ItemCount>
                    </ItemRow>
                </Content>
            ),
            [source.taskProgress.count]
        )
        const progress = SOURCES_WITHOUT_TOTAL.includes(source.subSource as SOURCE)
            ? 100
            : Math.round((source.taskProgress.count / (source.taskProgress.totalCount || 1)) * 100)

        return (
            <ProgressBar
                pending={source.taskProgress.status === SUBSOURCE_PROGRESS_STATUS.PENDING}
                progressInfo={progressInfo}
                progress={progress < minProgressWidth ? minProgressWidth : progress}
            />
        )
    }
)
