import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSelectDestination = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

export const RadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 389px;

    & > :last-child {
        margin-bottom: ${variables.gutterSm};
    }
`

export const Email = styled.span`
    color: ${variables.iceBlue};
`
