import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'

import {SOURCE} from 'ca-common/common/enum'
import {ITEM_TYPE, makeFolderDrilldownLink} from 'ca-common/utils/recoveryUtils'

const CAExplorerItemNameRaw = ({item, match, snapshot}) => {
    if (SOURCE.GSITES === snapshot.response.data.subsource) {
        return <p>{item.name}</p>
    }

    switch (item.entityType) {
        case ITEM_TYPE.FOLDER:
            return <Link to={makeFolderDrilldownLink(item, match)}>{item.name}</Link>
        case ITEM_TYPE.DOCUMENT:
            return <p>{item.name}</p>
        default:
            return null
    }
}

const mapStateToProps = state => ({
    snapshot: state.recovery.snapshots.snapshot
})

export const CAExplorerItemName = withRouter(connect(mapStateToProps)(CAExplorerItemNameRaw))
