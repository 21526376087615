import React, {useEffect, useState} from 'react'
import Icon from '@ant-design/icons'
import {Radio, Select, Tooltip} from 'antd'
import {Trans, useTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import _ from 'lodash'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

import {SEEDING_FILTERS, SEEDING_PICK_OPTIONS} from 'ca-common/common/enum/seeding'
import Info from 'ca-common/icons/Info.svg'

import {
    setActiveObjectsFilter,
    setAllObjectsFilter,
    appendActiveObjectsFilter,
    appendAllObjectsFilter
} from 'src/newcore/redux/modules/backups/seeding'
import {getFilterOfObjects} from 'src/newcore/utils/seeding'
import {SQLWhereClause} from '../SQLWhereClause'

import {
    FilteringListWrapper,
    FormTitle,
    FormTitleLabel,
    LabelWithTooltip,
    SeedingPickTypeSelect,
    SQLWhereClauseWrapper,
    StyledInputNumber,
    StyledRadio,
    StyledRadioWithoutMargin,
    StyledRadioWrapper,
    StyledRecords
} from './Styled'

const {Option} = Select

const DEFAULT_PICK_COUNT = 200

export const ObjectsFilteringFormRaw = ({checkedObjects, actions, objectsList}) => {
    const [currentFilter, setCurrentFilter] = useState({})
    const [countSelected, setCountSelected] = useState(0)
    const [temporarySQLSelection, setTemporarySQLSelection] = useState(false)
    const {t} = useTranslation()

    const onNumberChange = pickCount => {
        setPickRecordsValues({pickCount})
    }

    const onSelectChange = filteringType => {
        setPickRecordsValues({filteringType})
    }

    const onFilterChange = value => {
        switch (value) {
            case SEEDING_FILTERS.ALL_RECORDS:
                setFilters({
                    name: SEEDING_FILTERS.ALL_RECORDS
                })
                break
            case SEEDING_FILTERS.PICK:
                setFilters({
                    name: SEEDING_FILTERS.PICK,
                    pickCount: DEFAULT_PICK_COUNT,
                    filteringType: SEEDING_PICK_OPTIONS.NEWEST
                })
                break
            case SEEDING_FILTERS.WHERE_CLAUSE:
                setTemporarySQLSelection(true)
                return
        }

        if (temporarySQLSelection) {
            setTemporarySQLSelection(false)
        }
    }

    const setPickRecordsValues = filter => {
        if (countSelected) {
            actions.appendActiveObjectsFilter(filter)
        } else {
            actions.appendAllObjectsFilter(filter)
        }
    }

    const setFilters = filter => {
        if (countSelected) {
            actions.setActiveObjectsFilter(filter)
        } else {
            actions.setAllObjectsFilter(filter)
        }
    }

    const handleValidatePass = query => {
        setFilters({name: SEEDING_FILTERS.WHERE_CLAUSE, query})
    }

    useEffect(() => {
        const activeObjects = _.pickBy(checkedObjects, 'isActive')
        const selectionSize = _.size(activeObjects)
        setCountSelected(selectionSize)

        if (selectionSize) {
            setCurrentFilter(getFilterOfObjects(activeObjects))
        } else {
            setCurrentFilter(getFilterOfObjects(checkedObjects))
        }
        setTemporarySQLSelection(false)
    }, [objectsList])

    const currentValue = temporarySQLSelection ? SEEDING_FILTERS.WHERE_CLAUSE : currentFilter.name

    return (
        <FilteringListWrapper>
            <FormTitle>
                {t(`seeding:fourthStep:filteringFor`)}
                <FormTitleLabel disabled={countSelected}>{t(`seeding:fourthStep:allObjects`)}</FormTitleLabel>
            </FormTitle>
            <Radio.Group value={currentValue} onChange={e => onFilterChange(e.target.value)}>
                <StyledRadio value={SEEDING_FILTERS.ALL_RECORDS}>
                    {t(`seeding:filters:${SEEDING_FILTERS.ALL_RECORDS}`)}
                </StyledRadio>
                <StyledRadioWrapper>
                    <StyledRadioWithoutMargin value={SEEDING_FILTERS.PICK}>
                        {t(`seeding:filters:${SEEDING_FILTERS.PICK}`)}
                    </StyledRadioWithoutMargin>
                    <StyledInputNumber
                        min={1}
                        value={currentFilter.pickCount || DEFAULT_PICK_COUNT}
                        onChange={onNumberChange}
                        disabled={currentValue !== SEEDING_FILTERS.PICK}
                    />
                    <SeedingPickTypeSelect
                        value={currentFilter.filteringType || SEEDING_PICK_OPTIONS.NEWEST}
                        onChange={onSelectChange}
                        disabled={currentValue !== SEEDING_FILTERS.PICK}
                        getPopupContainer={t => t.parentElement}
                    >
                        {_.map(SEEDING_PICK_OPTIONS, option => (
                            <Option key={option} value={option}>
                                {t(`seeding:pickOptions:${option}`)}
                            </Option>
                        ))}
                    </SeedingPickTypeSelect>
                    <StyledRecords>{t(`seeding:fourthStep:pickRecords`)}</StyledRecords>
                </StyledRadioWrapper>
                <StyledRadio value={SEEDING_FILTERS.WHERE_CLAUSE}>
                    <LabelWithTooltip>
                        {t(`seeding:filters:${SEEDING_FILTERS.WHERE_CLAUSE}`)}
                        <Tooltip title={<Trans i18nKey={`seeding:fourthStep:whereClauseHint`} />}>
                            <Icon component={Info} />
                        </Tooltip>
                    </LabelWithTooltip>
                </StyledRadio>
                {currentValue === SEEDING_FILTERS.WHERE_CLAUSE && (
                    <SQLWhereClauseWrapper>
                        <SQLWhereClause
                            key={`${countSelected}sql`}
                            onValidatePass={handleValidatePass}
                            value={currentFilter.query}
                        />
                    </SQLWhereClauseWrapper>
                )}
                {currentValue === SEEDING_FILTERS.MIXED && (
                    <StyledRadio isSeparated value={SEEDING_FILTERS.MIXED}>
                        {t(`seeding:filters:${SEEDING_FILTERS.MIXED}`)}
                    </StyledRadio>
                )}
            </Radio.Group>
        </FilteringListWrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setActiveObjectsFilter,
            setAllObjectsFilter,
            appendActiveObjectsFilter,
            appendAllObjectsFilter
        },
        dispatch
    )
})

const mapStateToProps = state => ({
    checkedObjects: _.get(state, 'backups.seeding.objectsList.response.data', {}),
    objectsList: state.backups.seeding.objectsList
})

export const ObjectsFilteringForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectsFilteringFormRaw))
