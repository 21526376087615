import React from 'react'
import i18n from 'i18next'

import {CompareList} from 'src/newcore/components/CompareList'
import {Replicates} from 'src/newcore/components/Replicates'
import {Objects} from 'src/newcore/features/Objects'
import {SmartAlerts} from 'src/newcore/features/SmartAlerts'
import {PAGES} from 'ca-common/constants'

export enum PANES_KEYS {
    COMPARES = 'compares',
    REPLICATES = 'replicates',
    SMART_ALERTS = 'smartAlerts',
    OBJECTS = 'objects'
}

export const PANES = [
    {
        key: PANES_KEYS.OBJECTS,
        title: i18n.t('objectsList:title'),
        content: <Objects />,
        permission: PAGES.OBJECTS
    },
    {
        key: PANES_KEYS.COMPARES,
        title: i18n.t('compareList:title'),
        content: <CompareList />,
        permission: PAGES.COMPARE
    },
    {
        key: PANES_KEYS.REPLICATES,
        title: i18n.t('replicateList:title'),
        content: <Replicates />,
        permission: PAGES.REPLICATE
    },
    {
        key: PANES_KEYS.SMART_ALERTS,
        title: i18n.t('smartAlerts:title'),
        content: <SmartAlerts />,
        permission: PAGES.SMART_ALERTS
    }
]
