import React from 'react'
import isEmpty from 'lodash/isEmpty'

import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {OneDriveREST} from './OneDriveREST'
import {OneDriveSOAP} from './OneDriveSOAP'

export const OneDrive = ({backupSettings, ...props}: BackupAuthTemplate) => {
    const isNew = isEmpty(backupSettings)
    const Component = isNew || backupSettings.useRest ? OneDriveREST : OneDriveSOAP

    return <Component backupSettings={backupSettings} {...props} />
}
