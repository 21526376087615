import {SideMenuItem} from 'ca-common/types'

export const prepareMenu = (menuItems: SideMenuItem[]) => {
    return menuItems
        .filter(i => !i.hide)
        .map(i => {
            if (i.submenu) {
                return {...i, submenu: i.submenu.filter(j => !j.hide)}
            }

            return i
        })
}
