import React from 'react'
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'

import {PAGES} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {getSiteName} from 'ca-common/utils/entity'

import {StyledFooter, StyledHeader, StyledHeaderKeys, StyledPopoverContent} from './StyledDuplicatePopover'
type DuplicatePopoverContentProps = {
    duplicate?: {
        alias: string
        taskId: string
    }
    type?: string
    entityName?: string
}

export const DuplicatePopoverContent = ({duplicate, type, entityName}: DuplicatePopoverContentProps): JSX.Element => {
    const {t} = useTranslation()
    return (
        <StyledPopoverContent>
            <StyledHeader>
                <Trans
                    i18nKey="backups:duplicatePopover:text"
                    values={{
                        type,
                        alias: duplicate?.alias
                    }}
                >
                    <StyledHeaderKeys>value key</StyledHeaderKeys>
                </Trans>
            </StyledHeader>
            <StyledFooter>
                <Button type="link">
                    <Link to={`/${PAGES.BACKUPS}/${duplicate?.taskId}?query=${getSiteName(entityName)}`}>
                        {t('backups:duplicatePopover:openTask')}
                    </Link>
                </Button>
            </StyledFooter>
        </StyledPopoverContent>
    )
}
