import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.section`
    margin: ${variables.gutterSm} ${variables.gutterMd};
    > * + * {
        margin-top: 16px;
    }
`

export const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
`
