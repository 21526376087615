import React, {memo} from 'react'
import {Select} from 'antd'
import moment, {Moment} from 'moment'
import type {CalendarMode} from 'antd/lib/calendar/generateCalendar'

import {LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import type {LongDateNumber} from 'ca-common/types'

import {
    StyledCalendarArrowButton,
    StyledHeaderContainer,
    StyledMonthSelectButton,
    StyledYearSelect
} from './StyledCustomCalendarHeader'

export type AntHeaderProps = {
    value: Moment
    type?: string
    onChange: (arg0: Moment) => void
    onTypeChange: (arg0: CalendarMode) => void
    sinceDate: LongDateNumber
    toDate: LongDateNumber
}

const getYearsList = (sinceYear: number, toYear: number) => {
    const years = []
    for (let i = sinceYear; i <= toYear; i++) {
        years.push(
            <Select.Option key={i} value={i}>
                {i}
            </Select.Option>
        )
    }
    return years
}

export const CustomCalendarHeader = memo(
    ({value, onChange, type, onTypeChange, sinceDate, toDate}: AntHeaderProps): JSX.Element => {
        const toMomentDate = moment.utc(toDate, LONG_DATE_FORMAT).local()
        const sinceMomentDate = moment.utc(sinceDate, LONG_DATE_FORMAT).local()
        const disabledBack = value.isBefore(sinceMomentDate)
        const disabledForward = value.isAfter(toMomentDate)

        const changeYear = (number: number) => onChange(value.clone().year(value.year() + number))
        const changeMonth = (number: number) => onChange(value.clone().month(value.month() + number))
        const onYearSelect = (newYear: unknown) => onChange(value.clone().year(newYear as number))
        const onMonthSelect = () => onTypeChange(type === 'year' ? 'month' : 'year')

        return (
            <StyledHeaderContainer>
                <div>
                    <StyledCalendarArrowButton disabled={disabledBack} onClick={() => changeYear(-1)} double />
                    <StyledCalendarArrowButton disabled={disabledBack} onClick={() => changeMonth(-1)} />
                </div>
                <div>
                    <StyledMonthSelectButton type="link" onClick={onMonthSelect}>
                        {value.format('MMM')}
                    </StyledMonthSelectButton>
                    <StyledYearSelect size="small" onChange={onYearSelect} value={String(value.year())}>
                        {getYearsList(sinceMomentDate.year(), toMomentDate.year())}
                    </StyledYearSelect>
                </div>

                <div>
                    <StyledCalendarArrowButton disabled={disabledForward} onClick={() => changeMonth(1)} toRight />
                    <StyledCalendarArrowButton
                        disabled={disabledForward}
                        onClick={() => changeYear(1)}
                        double
                        toRight
                    />
                </div>
            </StyledHeaderContainer>
        )
    }
)
