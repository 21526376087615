import React, {useMemo} from 'react'
import {connect} from 'react-redux'

import {SOURCE} from 'ca-common/common/enum/Source'

import {TotalObjects} from 'ca-common/molecules/TotalObjects'

import {BackupEntitiesTitle, ActiveBackup, AddEntity, RefreshEntities} from 'src/newcore/molecules'

import {StyledBackupEntitiesHeader, ActionsBar, FirstRow} from './StyledBackupEntitiesHeader'

const BackupEntitiesHeaderRaw = props => {
    const {entities, taskSettings} = props
    const showAddAction = [SOURCE.MS365, SOURCE.ONEDRIVE, SOURCE.SHAREPOINT_MULTI].includes(taskSettings.source)

    const loading = useMemo(() => entities.status === 'pending', [entities.status])

    return (
        <StyledBackupEntitiesHeader>
            <FirstRow>
                <TotalObjects loading={loading}>
                    <BackupEntitiesTitle
                        affectedByFilters={entities?.response?.total}
                        total={entities?.response?.totalTaskEntities}
                        source={taskSettings.source}
                        isDropboxProfessionalAccount={taskSettings.isDropboxProfessionalAccount}
                    />
                </TotalObjects>
                <ActionsBar>
                    <RefreshEntities />
                    {showAddAction && <AddEntity />}
                </ActionsBar>
            </FirstRow>
            {!taskSettings.isDropboxProfessionalAccount && (
                <ActiveBackup source={taskSettings.source} fetched={loading} />
            )}
        </StyledBackupEntitiesHeader>
    )
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    entities: state.backups.backupEntities.entities
})

export const BackupEntitiesHeader = connect(mapStateToProps)(BackupEntitiesHeaderRaw)
