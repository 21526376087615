import styled from 'styled-components'
import Icon from '@ant-design/icons'

import {Button} from 'ca-common/ui-lib/atoms/Button'
import variables from 'ca-common/variables'

export const StyledBackupTaskActions = styled.div`
    flex: 0;
`

// mouse events don't trigger at disabled button in Chrome
// https://github.com/react-component/tooltip/issues/18
export const DropdownButtonWrapper = styled.div`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const DropdownButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;

    width: 140px;
    padding: 0 8px;
`

export const ActionIcon = styled(Icon)`
    font-size: 18px;
    color: ${variables.navyBlue};
`

export const TooltipWrapper = styled.div`
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`
