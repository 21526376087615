import React from 'react'
import _ from 'lodash'

import {StyledListView} from './StyledListView'
import {List} from '../../organisms/List'
import {TaskObj} from 'src/newcore/features/Homepage/types'

interface ListViewProps {
    tasks: TaskObj[]
}

export const ListView = ({tasks}: ListViewProps): JSX.Element => {
    return (
        <StyledListView>
            {_.map(
                tasks,
                (task: TaskObj): React.ReactNode => (
                    <List key={task.id} task={task} />
                )
            )}
        </StyledListView>
    )
}
