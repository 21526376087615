import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'

export const StyledFilterTag = styled.span<{isSubSource?: boolean}>`
    background: ${props => (props.isSubSource ? variables.interaction20 : variables.white)};
    color: ${props => (props.isSubSource ? variables.navyBlue : 'inherit')};
    border-radius: 24px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    padding: 4px ${variables.gutterXs} 4px 12px;
    min-height: 28px;
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;
    max-width: 100%;

    ${props =>
        props.isSubSource &&
        css`
            svg {
                rect {
                    fill: currentColor;
                }
            }
        `}
`
export const CloseIcon = styled(Icon)`
    cursor: pointer;
    margin-left: ${variables.gutterXs};
    font-size: ${variables.fontSizeSecondary};
`
export const FilterName = styled.p<{white?: boolean}>`
    color: ${props => (props.white ? variables.white : variables.text)};
    margin: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`
export const ClearTag = styled.a`
    color: ${variables.navyBlue};
    text-decoration: underline;
    font-weight: 600;
    align-self: center;
    margin-bottom: ${variables.gutterSm};
    margin-left: auto;
`
