import styled from 'styled-components'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledDownloadTableHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: ${variables.text};
    justify-content: space-between;
    padding: ${variables.gutterMd} ${variables.gutterMd} 18px ${variables.gutterMd};
`
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const TaskName = styled.p`
    font-weight: bold;
`
export const Account = styled.p`
    margin-bottom: 0;
    word-break: break-all;
    font-size: ${variables.fontSizeSecondary};
`
export const DownloadButton = styled(Button)``
