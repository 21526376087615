import {createSlice} from '@reduxjs/toolkit'

import {post, get, postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SECURITY_AUDIT_API = 'securityAudit'
const GET_SECURITY_AUDIT = 'GET_SECURITY_AUDIT'
const SecurityAudit = fetchWrapperRT(GET_SECURITY_AUDIT, async data => await postLessData(GET_SECURITY_AUDIT_API, data))
export const getSecurityAudit = SecurityAudit.fetcher

const securityAuditSlice = createSlice({
    name: 'securityAudit',
    initialState: InitFetchedState,
    reducers: {
        clearSecurityAudit: () => InitFetchedState
    },
    extraReducers: builder => SecurityAudit.makeReducers(builder)
})

export const {clearSecurityAudit} = securityAuditSlice.actions
export const securityAudit = securityAuditSlice.reducer

const GET_ACTIVITY_AUDIT_CSV = 'GET_ACTIVITY_AUDIT_CSV'
const GET_ACTIVITY_AUDIT_CSV_API = 'accountActivityToCsv'
const activityAuditCsvWrapper = fetchWrapperRT(
    GET_ACTIVITY_AUDIT_CSV,
    async data => await post(GET_ACTIVITY_AUDIT_CSV_API, data)
)
export const getActivityAuditCsv = activityAuditCsvWrapper.fetcher

const GET_SECURITY_AUDIT_CSV = 'GET_SECURITY_AUDIT_CSV'
const GET_SECURITY_AUDIT_CSV_API = 'securityAuditToCsv'
const securityAuditCsvWrapper = fetchWrapperRT(
    GET_SECURITY_AUDIT_CSV,
    async data => await post(GET_SECURITY_AUDIT_CSV_API, data)
)
export const getSecurityAuditCsv = securityAuditCsvWrapper.fetcher

const CREATE_EXPORT_ACTIVITY_TASK = 'CREATE_EXPORT_ACTIVITY_TASK'
const CREATE_EXPORT_ACTIVITY_TASK_API = 'exportActivity'
const createExportActivityTaskWrapper = fetchWrapperRT(
    CREATE_EXPORT_ACTIVITY_TASK,
    async data => await post(CREATE_EXPORT_ACTIVITY_TASK_API, data)
)
export const createExportActivityTask = createExportActivityTaskWrapper.fetcher

const CHECK_EXPORT_ACTIVITY_TASK = 'CREATE_EXPORT_ACTIVITY_TASK'
const CHECK_EXPORT_ACTIVITY_TASK_API = 'getDownloadAuditReportAvailable'
const checkExportActivityTaskWrapper = fetchWrapperRT(CHECK_EXPORT_ACTIVITY_TASK, async (data, {rejectWithValue}) => {
    try {
        return await post(CHECK_EXPORT_ACTIVITY_TASK_API, data)
    } catch (e) {
        return rejectWithValue(e)
    }
})
export const checkExportActivityTask = checkExportActivityTaskWrapper.fetcher

const GET_EXPORT_ACTIVITY_LINK = 'GET_EXPORT_ACTIVITY_LINK'
const GET_EXPORT_ACTIVITY_LINK_API = 'downloadAudit'
const getExportActivityLinkWrapper = fetchWrapperRT(GET_EXPORT_ACTIVITY_LINK, async (data, {rejectWithValue}) => {
    try {
        return await get(GET_EXPORT_ACTIVITY_LINK_API, data)
    } catch (e) {
        throw rejectWithValue(e)
    }
})
export const getExportActivityLink = getExportActivityLinkWrapper.fetcher
