import React, {Component, Fragment} from 'react'
import {Progress} from 'antd'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'

import {PROGRESS_BOX_STATUS} from 'ca-common/common/enum/ProgressBox'
import variables from 'ca-common/variables'

import {
    ExceptionTitle,
    ExceptionTitleHeader,
    ExceptionTitleText,
    NormalTitle,
    StyledProgressBox
} from './StyledProgressBox'

@withTranslation()
export class ProgressBox extends Component {
    render() {
        const {status, progress, t, exception} = this.props

        return (
            <StyledProgressBox>
                {(() => {
                    switch (status) {
                        case PROGRESS_BOX_STATUS.LIMIT_EXCEEDED:
                            return (
                                <Fragment>
                                    <ExceptionTitle>
                                        <ExceptionTitleHeader color={variables.orange}>
                                            {t('progressBox:limitedException:title')}
                                        </ExceptionTitleHeader>
                                        <ExceptionTitleText>
                                            {t('progressBox:limitedException:description', [exception])}
                                        </ExceptionTitleText>
                                    </ExceptionTitle>
                                    <Progress percent={50} strokeColor={variables.orange} showInfo={false} />
                                </Fragment>
                            )

                        case PROGRESS_BOX_STATUS.PARTIALLY_SUCCESS:
                            return (
                                <Fragment>
                                    <ExceptionTitle>
                                        <ExceptionTitleHeader color={variables.orange}>
                                            {t('progressBox:partiallySucceed:title')}
                                        </ExceptionTitleHeader>
                                        <ExceptionTitleText>{exception}</ExceptionTitleText>
                                    </ExceptionTitle>
                                    <Progress percent={100} strokeColor={variables.orange} showInfo={false} />
                                </Fragment>
                            )

                        case PROGRESS_BOX_STATUS.CANCELED:
                            return (
                                <Fragment>
                                    <NormalTitle>{t('progressBox:canceling')}</NormalTitle>
                                    <Progress percent={50} strokeColor={variables.orange} showInfo={false} />
                                </Fragment>
                            )

                        case PROGRESS_BOX_STATUS.FAILED:
                            return (
                                <Fragment>
                                    <ExceptionTitle>
                                        <ExceptionTitleHeader color={variables.red}>
                                            {t('progressBox:failedException:title')}
                                        </ExceptionTitleHeader>
                                        <ExceptionTitleText>
                                            {exception || t('progressBox:failedException:description')}
                                        </ExceptionTitleText>
                                    </ExceptionTitle>
                                    <Progress percent={50} strokeColor={variables.red} showInfo={false} />
                                </Fragment>
                            )

                        case PROGRESS_BOX_STATUS.SUCCESS:
                            return (
                                <Fragment>
                                    <NormalTitle>{t('progressBox:normalProgress:completed', [progress])}</NormalTitle>
                                    <Progress
                                        percent={100}
                                        strokeColor={variables.green}
                                        showInfo={false}
                                        status={'success'}
                                    />
                                </Fragment>
                            )

                        default:
                            return (
                                <Fragment>
                                    <NormalTitle>{t('progressBox:normalProgress:process', [progress])}</NormalTitle>
                                    <Progress percent={progress} strokeColor={variables.green} showInfo={false} />
                                </Fragment>
                            )
                    }
                })()}
            </StyledProgressBox>
        )
    }
}

ProgressBox.propTypes = {
    status: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    exception: PropTypes.string
}

ProgressBox.defaultProps = {
    exception: ''
}
