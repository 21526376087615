export default {
    Interval: {
        DAY_one: '{{count}} Day',
        DAY_other: '{{count}} Days',
        MONTH_one: '{{count}} Month',
        MONTH_other: '{{count}} Months',
        UNLIMITED: 'Unlimited',
        WEEK_one: '{{count}} Week',
        WEEK_other: '{{count}} Weeks',
        YEAR_one: '{{count}} Year',
        YEAR_other: '{{count}} Years'
    },
    JobsPage: {
        buttons: {
            downloadReport: 'Download Report',
            downloadResults: 'Download Results',
            viewLog: 'View Log'
        },
        cancelNotInProcess: 'Not `In process`',
        cancelNotPermitted: 'You don’t have permission for this operation',
        cancelText: 'Cancel',
        dashboard: {
            failed: 'Failed / Partially Succeeded',
            inProcess: 'In Progress',
            succeed: 'Successfully Completed'
        },
        dashboardPending: 'Pending ...',
        downloadTable: {
            columns: {
                actions: 'Actions',
                fileName: 'File Name',
                size: 'Size'
            },
            download: 'Download',
            downloadTooltip: {
                expired: 'Download Expired',
                permitted: 'You don’t have permission for this operation'
            }
        },
        jobPending: 'This Job will start soon',
        jobTile: {
            columns: {
                label: {
                    backupDate: 'Backup Date:',
                    destination: 'Destination:',
                    initiator: 'Initiator:',
                    size: 'Size',
                    startedAt: 'Started at:',
                    totalItems: 'Total Items:'
                }
            },
            status: {
                CANCELLED: 'Cancelled',
                CANCELLING: 'Cancelling',
                FAILED: 'Failed',
                PARTIALLY_SUCCEEDED: 'Partially Succeeded',
                PENDING: 'Pending',
                SUCCEEDED: 'Succeeded'
            }
        },
        noJobsFound: 'No Jobs found the last 7 days',
        pagination: {
            countInfo: 'Showing {{start}} to {{end}} of {{total}} tasks'
        },
        subtitle: 'Total Jobs for last 7 Days',
        viewLogNotPermitted: 'You don’t have permission for this operation'
    },
    MSLandingPage: {
        errorLandingHeader: "We couldn't identify this purchase",
        errorMsg:
            'Please reopen this SaaS subscription in the Azure Portal or in Microsoft 365 Admin Center and select "Configure Account" or "Manage Account" again',
        formLabels: {
            firstName: 'First Name',
            lastName: 'Last Name',
            mail: 'Email',
            plan: 'Selected Plan:',
            region: 'Data Center Location'
        },
        landingHeader: 'Let’s finish setting up your account',
        locationPlaceholder: 'Select your Data Center Location',
        regionErrorText: 'Please Select your Data Center Location',
        submitText: 'Proceed',
        subscription: {
            monthly: 'Monthly Subscription'
        }
    },
    OKTALandingPage: {
        domainPlaceholder: 'mycompany',
        signIn: 'Sign In with Okta',
        subtitle: "Please enter your organization's Okta company domain to sign-in with Okta",
        title: 'Your Okta Company Domain'
    },
    PUBLIC_PAGE_META: {
        SIGN_IN: {
            description:
                'Sign into your {{companyName}} Backup for Google Workspace, Microsoft Office 365 and Salesforce accounts here. Check-in on your backup and recovery status for your organization.',
            keywords:
                '365 login, google 365 portal login, 365 portal admin login, office 365 login portal admin, partners outlook 365 login, 0365 portal login, 365 com login, 365 login, office 365 portal admin login, 365 education login, admin 365 login, google office 365 login, 0365 login portal, admin portal login o365, 365 portal login in, office365 login admin, o365 portal login, o365 admin portal login, portal office365 us login, portal office365 us'
        },
        SIGN_UP: {
            description:
                'Want to fully try the most trusted backup & recovery solution for Google Workspace, Microsoft 365, and Salesforce? Start a 14-day free trial of {{companyName}} Backup today!',
            keywords:
                'office 365 trial, microsoft 365 free trial, office 365 free trial, microsoft 365 trial, microsoft office 365 free trial, microsoft office 365 trial, office 365 trial download, office 365 cloud trial, office 365 free trial account, o365 trial, microsoft 365 trial free, microsoft free trial 365, o365 trial version, office 365 login free trial, get office 365 trial, 365 day trial, office 365 sign up free trial, 0365 trial, free trial download office 365, trial office 365 tenant, install trial office 365, download microsoft 365 free trial, office 365 business free trial, try office 365 trial'
        }
    },
    PUBLIC_PAGE_TITLES: {
        CHANGE_PASSWORD: 'Change your password | {{companyName}}',
        ERROR: 'Error | {{companyName}}',
        LOGOUT: 'Thank you | {{companyName}}',
        MS_LANDING: 'Sign up | CloudAlly',
        OKTA_LANDING: 'Sign In with Okta',
        RESET_PASSWORD: 'Password reset | {{companyName}}',
        SIGN_IN: '{{companyName}} Backup Login Portal',
        SIGN_UP: 'Free Trial: {{companyName}} Backup for G Suite, Office 365 & Salesforce',
        THANK_YOU: 'Thank you for signing up | {{companyName}}'
    },
    TASK_TYPE: {
        EXPORT: 'Download',
        RESTORE: 'Restore'
    },
    TaskStatus: {
        ACTIVE: 'Active',
        DISPATCHED: 'In process',
        INPROCESS: 'In process',
        NOTACTIVE: 'Not active',
        PAUSED: 'Paused',
        PENDING: 'Pending',
        QUEUED: 'In process',
        READY_FOR_DOWNLOAD: 'Ready',
        SKIPPED: 'In process'
    },
    accountPage: {
        actions: {
            removeAccount: 'Remove Account',
            resend: 'Resend Email Confirmation'
        },
        archiveLocation: 'Data Center Location',
        confirmationSent:
            'Confirmation was sent to your new email <0>{{newEmail}}</0> and is waiting for your approval.',
        dailyReport: 'Email daily report',
        discardMessage:
            'Are you sure you want to cancel the email change from<br /> <0>{{email}}</0> to <0>{{newEmail}}</0>?',
        email: 'Email',
        emailExtra: 'If you change your email address, we will send an email to your new address for confirmation. ',
        emailExtraBold: 'The new address will not become active until it is confirmed',
        name: 'Account Name',
        newEmail: 'New Email - Waiting for Approval',
        note: 'Note:',
        partnerId: 'Partner ID',
        resendMessage: 'Resend link will appear again in <0>{{timer}}</0> seconds',
        settingsChanged: 'Settings changed successfully',
        settingsFailed: 'Failed to change settings,',
        terminate: {
            checkbox: 'I approve the removal of my data from {{0}}',
            confirmTitle: 'Are you sure?',
            text: 'This includes removing all your data at {{0}}',
            title: 'To terminate your {{0}} subscription, click on the REMOVE ACCOUNT button.'
        },
        unsubscribeFailed: 'Unsubscribed failed',
        unsubscribeMessage:
            'You have successfully unsubscribed from {{0}}. Your account is now closed and all data will be removed within 7 days at which time a confirmation email will be sent to you. Thank you for using {{0}} and we hope that you will consider us again in the future.'
    },
    activity: {
        activities: {
            BACKUP: 'Backup',
            COMPARE: 'Compare',
            DELETEACCOUNTDATA: 'Delete Backup',
            EXPORT: 'Export',
            NOTIFICATION: 'Notification',
            RESTORE: 'Restore'
        },
        activityFilters: {
            activity: 'Activity',
            searchEntityPlaceholder: 'Search {{amount}} items...',
            searchQueryPlaceholder: 'Search by Task name',
            service: 'Service',
            status: 'Status'
        },
        description: {
            comparedObject: 'Compared Object:',
            comparedObjectType: 'Compared Object Type:',
            dataSize: 'Data Size:',
            description: 'Description:',
            execTime: 'Execution Time:',
            exportDestination: 'Export Destination:',
            failedMetadata: 'Number of Failed Metadata Records:',
            failedRecords: 'Number of Failed Records:',
            firstComparedDate: 'First Compared Backup Task Date:',
            firstComparedName: 'First Compared Backup Task Name:',
            initiator: 'Initiator:',
            numberOfRecords: 'Number of Records:',
            performedBy: 'Performed By:',
            restoreDestination: 'Restore Destination:',
            restoreSource: 'Restore Source:',
            secondComparedDate: 'Second Compared Bac kup Task Date:',
            secondComparedName: 'Second Compared Backup Task Name:',
            snapshotDate: 'Snapshot Date:'
        },
        downloadTooltip: {
            compare: 'View the Comparison Results',
            compareExpired: 'View Result Expired',
            download: 'Download',
            expired: 'Download Expired',
            notPermitted: 'You don’t have permission for this operation',
            report: 'Download Report'
        },
        exportButtonTooltip: 'Export Activity Log',
        exportDestination: {
            AMAZON_S3: 'S3',
            AMAZON_S3_COMPATIBLE: 'S3 Compatible',
            AZURE: 'Azure Blob',
            BOX: 'Box',
            CLOUDALLY_S3: 'Direct Download',
            DROPBOX: 'Dropbox',
            FINRA_S3: 'Finra',
            GOOGLE_CLOUD_PLATFORM: 'GCP'
        },
        statuses: {
            CANCELLED: 'Cancelled',
            COMPLETED: 'Succeeded',
            EXCEPTION: 'Partially Succeeded',
            FAILED: 'Failed',
            PAUSED: 'Paused'
        }
    },
    addEntity: {
        tooltip: 'Add a new {{entity}}'
    },
    annualSubscription: {
        billingDetailsAdded:
            'Thank you, you are subscribed now, an invoice will be emailed to you soon. Please contact Support if there is any question'
    },
    assignService: {
        BOX: 'Files and Folders',
        DDB: 'Amazon DynamoDB data',
        DROPBOX: 'Files and Folders',
        GACCOUNTS: 'Mail, Calendar, Contacts, Drive and Tasks',
        GDRIVETEAM: 'Files and Folders',
        GOOGLEAPPS: 'Mail, Calendar, Contacts, Drive and Tasks',
        IMAP: 'Mail',
        MS365: 'Mail, Calendar, Contacts and Tasks',
        MS365GROUPS: 'Conversation, Calendar, Sites, Apps, Channels, Metadata',
        ONEDRIVE: 'Personal sites documents and Libraries',
        SDB: 'Amazon SimpleDB data',
        SFC: 'Organization data and Chatter',
        SHAREPOINT_MULTI: 'Sites documents and Libraries',
        YMAIL: 'Mail',
        activateTitle: 'Create New Backup Task',
        directText: 'You will be directed to your account for authentication',
        including: 'Including:'
    },
    backupChart: {
        added: 'Added',
        deleted: 'Deleted',
        tooltip: {
            total: 'Total Records: {{total}}'
        },
        updated: 'Updated'
    },
    compareItem: {
        cancelButton: 'Cancel',
        confirmCancel: 'Are you sure you want to cancel compare process?',
        object: 'Object',
        resultButton: 'View Result',
        title: 'Comparing {{0}}',
        type: {
            data: 'Data',
            metadata: 'Metadata'
        }
    },
    compareList: {
        empty: 'No compares yet',
        title: 'Compares'
    },
    compareSnapshot: {
        allObjects: 'All objects',
        backup: 'Backup',
        changed: 'Changed',
        comparison: 'Comparison',
        data: {
            description:
                'Compare particular object (e.g Account or Opportunity) or all objects across two snapshots to identify changes to records and fields',
            title: 'data',
            tooltipTitle:
                'System fields excluded from comparison: CreatedById, CreatedDate, LastModifiedById, LastModifiedDate, LastViewedDate, LastReferencedDate, SystemModstamp.'
        },
        description:
            'Use Compare to compare Salesforce Data or Metadata, as whole or by object/s. You can compare any backup snapshot with other backup snapshot, or with other Salesforce backup task, or with Salesforce service. Metadata can be event compared with different organizations.',
        descriptionFirstStep: 'Select type and objects to compare',
        descriptionOfChanges: 'The following <0>{{number}}</0> {{type}} object(s) exist only in {{- backup}}',
        descriptionSecondStep: 'Select left and right side to compare',
        descriptionWithoutChanges: 'The following <0>{{withoutChanges}}</0> {{type}} object(s) had no changes',
        login: 'Login',
        metadata: {
            description:
                'Use this tool to compare all your metadata objects(e.g. Reports, Workflows, Apex Code, etc.) across two snapshots to identify changes',
            title: 'metadata'
        },
        objectLabel: 'Object',
        other: {
            description:
                "You will be asked to authorise on the organisation website. Don't worry we don't store your credentials",
            title: 'Other Organisation'
        },
        production: 'Production',
        records: 'Records',
        salesforce: {
            description: 'Compare the data in the specified snapshot to the data of the Salesforce Production',
            title: 'Salesforce'
        },
        sandbox: 'Sandbox',
        searchObject: 'Search Object',
        startCompare: 'Start Compare',
        title: 'Compare',
        titleDescription: 'Compare results are saved for 7 days',
        total: 'Total',
        unique: 'Unique'
    },
    customDownload: {
        emptyFieldsError: 'One of the fields is empty',
        targets: {
            amazonS3: 'to your Amazon S3',
            azure: 'to your Azure Blob',
            box: 'to your Box.com',
            cloudAllyS3: 'for download',
            dropbox: 'to your Dropbox'
        }
    },
    entitySourceInfo: {
        itemsLabel: 'Items: ',
        itemsTooltipTitle: {
            indexEnabled: 'Total unique items since the beginning of backup',
            indexNotEnabled: 'Please enable Indexation in Backup Settings to view this information'
        },
        noItems: 'N/A',
        notApplicable: 'N/A'
    },
    finra: {
        accountTooltip: 'This account is Finra section 17 complied, please contact Support',
        taskTooltip: 'This account is Finra section 17 complied, deletion is not available'
    },
    gracePeriodNotification: {
        content:
            'We have found that a backup task of the account you have specified has been deleted recently. The data of that backup is not lost yet and can be restored. Please choose one of the options below:',
        gracePeriodCreateTypeRedo: {
            text: 'Continue',
            title:
                'To create a new backup of the specified account. The backup task will have no access to the data of the old backup, backup data collection will begin anew. The old backup data will be deleted 7 days after the backup task has been deleted.'
        },
        gracePeriodCreateTypeUndo: {
            text: 'Restore',
            title:
                'To restore the old task with all the backed up data. The backup process will continue for this task and you can perform recovery of data from any of the collected backup points.'
        },
        title: 'Backup of this domain has been recently deleted'
    },
    homepage: {
        addNewTitle: 'Add Backup Task',
        alternateText: 'Or select a service to back up',
        editName: 'Edit Name',
        headerSubTitle: 'What would you like to back up?',
        newHomePage: {
            BOX: {
                altText: '',
                displayName: 'Box',
                displayTitle: ''
            },
            DDB: {
                altText: '',
                displayName: 'DynamoDB',
                displayTitle: ''
            },
            DROPBOX: {
                altText: 'Business/Professional',
                displayName: 'Dropbox',
                displayTitle: ''
            },
            GACCOUNTS: {
                altText: '',
                displayName: 'Google Account',
                displayTitle: ''
            },
            GDRIVETEAM: {
                altText: '',
                displayName: 'Google',
                displayTitle: 'Shared Drives'
            },
            GOOGLEAPPS: {
                altText: 'Workspace',
                displayName: 'Google',
                displayTitle: 'Workspace'
            },
            Google: {
                displayName: 'Google'
            },
            IMAP: {
                altText: '',
                displayName: 'IMAP',
                displayTitle: ''
            },
            MS365: {
                altText: '',
                displayName: 'Microsoft Exchange',
                displayTitle: 'Exchange'
            },
            MS365GROUPS: {
                altText: '',
                displayName: 'Microsoft Groups / Teams',
                displayTitle: 'Teams'
            },
            Microsoft: {
                displayName: 'Microsoft 365'
            },
            ONEDRIVE: {
                altText: '',
                displayName: 'OneDrive',
                displayTitle: 'OneDrive'
            },
            SFC: {
                altText: '',
                displayName: 'Salesforce',
                displayTitle: ''
            },
            SHAREPOINT: {
                altText: '',
                displayName: 'SharePoint',
                displayTitle: 'SharePoint'
            },
            SHAREPOINT_MULTI: {
                altText: '',
                displayName: 'SharePoint',
                displayTitle: 'SharePoint'
            }
        },
        rowName: {
            activatedBackups: '{{activated}} of {{total}} {{entityName}} have activated backups.',
            active: 'Active',
            backupInProgress:
                'The first backup of your <br>mailbox is not finished yet.<br>For assistance, contact your admin.',
            deltaSize: 'Delta Size',
            entityActive: 'Active {{- entity}}',
            entityNames: {
                BOX: 'users',
                DROPBOX: 'users / team folders',
                GDRIVETEAM: 'drives',
                GOOGLEAPPS: 'users',
                MS365: 'mailboxes',
                MS365GROUPS: 'groups / teams',
                ONEDRIVE: 'sites',
                SHAREPOINT: 'sites',
                SHAREPOINT_MULTI: 'sites'
            },
            entityTotal: 'Total {{- entity}}',
            lastBackup: 'Last Backup',
            lastSnapshot: 'Last Snapshot',
            nextBackup: 'Next Backup',
            noBackups: 'Your mailbox backup <br>has not yet been activated.<br>For assistance, contact your admin.',
            sfc: {
                totalObjects: 'Total Objects'
            },
            total: 'Total',
            totalSize: 'Total Size'
        },
        searchPlaceholder: 'Search',
        taskStatus: {
            ARCHIVED: 'Archived',
            FAILED: 'Failed',
            NOT_ACTIVE: 'Not Active',
            PARTIAL: 'Partially Succeeded',
            PAUSED: 'Paused',
            SCHEDULED: 'Awaiting First Run',
            SUCCESS: 'Succeeded'
        },
        title: '{{count}} Backups',
        tooltip: {
            connected: 'Task is connected.',
            disconnected: 'Task is disconnected. Please fix the authentication issue at the backup task settings page.'
        },
        whatWouldYouLikeToRecover: 'What would you like to recover?'
    },
    jobs: {
        activity: {
            export: 'Export',
            restore: 'Restore'
        },
        cancelTitle: 'Cancel Your {{type}}',
        cancelingOkButton: 'Confirm',
        cancelingText: 'Are you sure you want to cancel the {{type}} of {{- source}} <0>{{- email}}</0>?',
        filterFields: {
            activity: {
                text: 'Activity'
            },
            initiator: {
                placeholder: 'Initiator'
            },
            item: {
                placeholder: 'Search by Item'
            },
            service: {
                text: 'Service'
            },
            sortBy: {
                text: 'Order by'
            },
            status: {
                text: 'Status'
            },
            task: {
                placeholder: 'Search by Task'
            }
        },
        jobStatus: {
            cancelled: 'Cancelled',
            failed: 'Failed',
            inProcess: 'In Progress',
            partiallySucceed: 'Partially Succeeded',
            pending: 'Pending',
            succeed: 'Succeeded'
        },
        na: 'N/A',
        sortBy: {
            latest: 'Latest First',
            oldest: 'Oldest First'
        }
    },
    lastBackup: {
        never: 'Never'
    },
    newServiceAssign: {
        accountName: 'Account Name',
        accountTitle: 'Use credential based authorization',
        actions: {
            apply: 'Apply',
            cancel: 'Cancel',
            goTo: 'Authenticate in {{0}}',
            goToMicrosoft: 'Authenticate in Microsoft'
        },
        adminTitle: 'Use OAuth based authorization',
        boxRedirect: 'You will be redirected to Box for authentication.',
        daysToAccountDeactivation: {
            prefix: 'Retain auto-archived backups for ',
            suffix: ' days before deleting'
        },
        ddbAccessKey: 'DynamoDB Access Key',
        ddbRegion: 'DynamoDB Region',
        ddbSecretKey: 'DynamoDB Secret Key',
        dropboxRedirect: 'You will be redirected to Dropbox for authentication.',
        filter: 'Filter',
        gaccountRedirect:
            'By pressing the start button below, you will be redirected to Google for authentication using your Google user and will be asked to enable {{0}} access to backup your account.',
        gappsRedirect:
            'Sign in to your {{1}} administrator user in another browser tab page and then click the "Integrate with Google" button. You will be redirected to Google for authentication and will be asked to enable {{0}} access for your account.',
        imapPassword: 'Password',
        imapUrl: 'Server Name',
        imapUserName: 'User Name',
        info: {
            MS365: 'How to configure Application Impersonation',
            MS365GROUPS: 'How to configure Application Impersonation',
            SFC: 'How to enable API access'
        },
        mso365Mail: 'Microsoft 365 admin Mail address',
        mso365Password: 'Microsoft 365 admin Mail password',
        oneDriveUrlInfo:
            'Enter the OneDrive high-level URL (yourdomain-my.sharepoint.com) if the domain is different from your Admin User ID domain.',
        onedriveUrl: 'OneDrive URL',
        retentionPeriodTooltip:
            'This field is display only. We provide unlimited retention of your daily backups for as long as you maintain your account subscription, but you can specify a backup retention limit if needed by contacting Support.',
        sdbAccessKey: 'SimpleDB Access Key',
        sdbRegion: 'SimpleDB Region',
        sdbSecretKey: 'SimpleDB Secret Key',
        sfcBackupProduction: 'Production backup',
        sfcBackupSandbox: 'Sandbox backup',
        sfcBackupType: 'Backup type:',
        sfcRedirect: 'You will be redirected to Salesforce for authentication.',
        sharePointUrlInfo:
            'Enter the SharePoint high-level URL (yourdomain.sharepoint.com) if the domain is different from your Admin User ID domain.',
        sharepointPassword: 'Password',
        sharepointUrl: 'SharePoint URL',
        sharepointUserName: 'User Name',
        storageRegion: 'Backup location selected when you setup your account.',
        throughput: 'Throughput',
        throughputDDBHelp: 'Daily DynamoDB API usage limit, by default 0.5',
        throughputHelp: 'Daily API usage limit',
        throughputSfcHelp: 'Daily Salesforce API usage limit',
        useOAuthSimple: 'Our authorization process uses OAuth and requires a Global Admin account.',
        yahooMail: 'Yahoo Mail address',
        yahooPassword: 'Yahoo Mail password'
    },
    objectsList: {
        columns: {
            added: 'Added',
            deleted: 'Deleted',
            lastSnapshot: 'Last Snapshot',
            name: 'Object Name',
            status: 'Status',
            total: 'Record Count',
            type: 'Object Type',
            updated: 'Updated'
        },
        lastSnapshotTooltip: 'How long ago a successful backup was made',
        notAvailableTooltip:
            '{{companyName}} is not able to display the <0>{{type}}</0> value for this object, as Salesforce doesn’t maintain the relevant information for it.',
        objectTypes: {
            DATA: 'Data',
            METADATA: 'Metadata'
        },
        searchPlaceholder: 'Search by Object name..',
        statusButtonText: 'Status',
        title: 'Objects',
        totalObjects: '{{totalObjects}} Total Objects',
        totalTooltip: 'Total Record Count',
        typeButtonText: 'Object Type'
    },
    oneClickActivation: {
        button: {
            beta: 'BETA',
            description: 'Including: Exchange | Groups / Teams | SharePoint | OneDrive',
            serviceName: 'Complete Microsoft 365'
        },
        subtitle: 'Select which Microsoft 365 services you would like to back up',
        title: 'Complete Microsoft 365 Backup',
        wizard: {
            backupNamePostfix: '_[Service Name]',
            columns: {
                email: 'Email',
                mailboxName: 'Mailbox Name',
                name: 'Name',
                site: 'Site',
                type: 'Type'
            },
            confirmationStep: {
                customizedTaskSettings: 'Customized Task Settings',
                defaultTaskSettings: 'Default Task Settings',
                noEntitiesSelected: {
                    1: 'No entities were selected for {{- service1}} service',
                    2: 'No entities were selected for {{- service1}} and {{- service2}} services',
                    3: 'No entities were selected for {{- service1}}, {{- service2}} and {{- service3}} services',
                    4: 'No entities were selected for {{- service1}}, {{- service2}}, {{- service3}} and {{- service4}} services'
                },
                reinstateTaskSettings: 'Reinstate Task Settings',
                selectedEntities: 'Selected entities',
                services: {
                    MS365: 'Exchange',
                    MS365GROUPS: 'Groups / Teams',
                    ONEDRIVE: 'OneDrive',
                    SHAREPOINT_MULTI: 'SharePoint'
                },
                totalEntities: 'Total entities'
            },
            errors: {
                backupNameRequired: 'Please provide an input for the field'
            },
            labels: {
                activationOfBackupEntities: 'Activation of Backup Entities',
                availableSources: 'The following backup tasks will be created when you click "Next"',
                backupName: 'Backup Name',
                backupTaskSettings: 'Backup Task Settings',
                onlyExistedTasks:
                    'All the backup tasks of <0>{{email}}</0> already exist. If you would like to continue to creating the selected backup tasks, you can authenticate with a different user.',
                partiallyExistedTasks:
                    'The following backup tasks of <0>{{email}}</0> already exist.<br>When you click "Next", the tasks below will not be re-created.',
                recentlyDeletedTasks:
                    'One or more of the backup tasks of <0>{{email}}</0> have recently been deleted.<br>The data of that backup tasks is not lost yet and can be reinstated. Please choose one of the options below for each service:'
            },
            loadingText1: 'Initial application setup is being performed',
            loadingText2: 'Preparing your backup tasks',
            loadingText3: 'Your backup tasks are being processed. This may take a few moments.',
            options: {
                ACTIVATE_ALL_ENTITIES: 'Activate all entities for all backup tasks',
                ACTIVATE_SELECTED_ENTITIES: 'Select which entities to activate per backup task',
                USE_CUSTOM_SETTINGS: 'Customize settings for each backup task',
                USE_DEFAULT_SETTINGS: 'Use Default settings for all backup tasks',
                createNewTask: 'Create New Task',
                reinstate: 'Reinstate'
            },
            placeholders: {
                searchField: {
                    MS365: 'Search by account name or email',
                    MS365GROUPS: 'Search by team or group',
                    ONEDRIVE: 'Search by site',
                    SHAREPOINT_MULTI: 'Search by site'
                }
            },
            titles: {
                backupSettingsStep: 'Backup Settings For',
                confirmationStep: 'Confirmation of Backup Task Creation For',
                entitiesSelectionStep: 'Entities Activation For',
                recentlyDeletedTasksStep: 'Backup tasks have recently been deleted',
                settingsSelectionStep: 'Backup Task Settings For',
                tasksAlreadyExistStep: 'Backup Tasks Already Exist'
            },
            tooltips: {
                backupName:
                    'The name of the backup tasks will be the name you choose + the name of the service <br> (e.g. XXX_Exchange).',
                backupTaskSettings: {
                    text:
                        'Daily backup<br>Backup unlicensed mailbox accounts (Exchange)<br>Automatically activate new entities (site/teams,mailbox)<br>Retain auto archived backups indefintly',
                    title: 'Default Settings:<br>'
                },
                comingSoon: 'COMING SOON',
                createNewTask:
                    'To create a new backup of the specified account. The backup task will have no access to the data of the old backup, backed data collection will begin as new. The old backup data will be deleted 7 days after the backup task has been deleted.',
                reinstate:
                    'To reinstate the old task with all the backed up data. The backup process will continue for this task and you can perform recovery of data from any of the collected backup point.'
            }
        }
    },
    progressBar: {
        jobsProgress: '{{count}} of {{total}}',
        singleEntityItemName: 'Items',
        status: {
            DONE: 'Done',
            INPROGRESS: 'In Progress',
            PENDING: 'Pending',
            UNAVAILABLE: 'Unavailable'
        },
        unavailable: 'N/A'
    },
    progressBox: {
        canceling: 'Canceling',
        failedException: {
            description: 'Please contact Support',
            title: 'Error'
        },
        limitedException: {
            description: 'You have used {{0}} of {{0}} API requests',
            title: 'API limit is exceeded'
        },
        normalProgress: {
            completed: '{{0}}% Completed',
            process: 'In Process {{0}}%'
        },
        partiallySucceed: {
            title: 'Partially Succeed'
        }
    },
    refreshEntities: {
        tooltip: "Refresh the table's contents"
    },
    replicateList: {
        empty: 'No replicates yet',
        title: 'Replicates',
        titleDescription: 'Replicate results are saved for 7 days'
    },
    searchPlaceholder: {
        BOX: 'Search Users',
        DDB: 'Search Tables',
        DROPBOX: 'Search Users',
        GDRIVETEAM: 'Search Drives',
        GOOGLEAPPS: 'Search by account name or email',
        MS365: 'Search by account name or email',
        MS365GROUPS: 'Search by Group / Team name',
        ONEDRIVE: 'Search Sites',
        SDB: 'Search Tables',
        SHAREPOINT_MULTI: 'Search Sites'
    },
    seeding: {
        anonymizationMode: {
            disable: 'Without data anonymization',
            preDefined: 'Data anonymization by object types with pre-defined values',
            random: 'Data anonymization by field types with random values'
        },
        dataAnonymizationStep: {
            defaultSystemTemplate: 'Default System Template ',
            description: 'Data Anonymization',
            fieldFilterOptions: {
                ALL_FIELDS: 'All Fields',
                NOT_ANONYMIZED: 'Not Anonymized',
                ONLY_ANONYMIZED: 'Only Anonymized'
            },
            fieldTypes: {
                CITY: 'City',
                COMPANY: 'Company',
                COUNTRY: 'Country',
                COUNTRY_CODE: 'Country Code',
                EMAIL: ' E-mail',
                FIRST_NAME: 'First name',
                LAST_NAME: 'Last name',
                LATITUDE: 'Latitude',
                LONGITUDE: 'Longitude',
                MIDDLE_NAME: 'Middle name',
                NAME: 'Account Name',
                NUMBER: 'Number',
                PHONE: 'Phone',
                PICKLIST: 'Picklist',
                POSTAL_CODE: 'Postal Code',
                RANDOM: 'Random',
                SALUTATION: 'Salutation',
                SKIP: 'Skip',
                STATE: 'State',
                STATE_CODE: 'State Code',
                STREET: 'Street',
                SUFFIX: 'Suffix',
                TEXT: 'Text',
                TITLE: 'Title',
                URL: 'URL'
            },
            objectFilterOptions: {
                ALL_OBJECTS: 'All Objects',
                SEARCH_RESULT: 'Search Results'
            },
            resetToDefaultSystemTemplate: 'Reset to Default System Template',
            searchFieldPlaceholder: 'Search Fields',
            searchObjectPlaceholder: 'Search Object',
            subtitle: 'Choose object from the list below to determine which fields you want to anonymize.',
            titles: {
                preview: 'Preview',
                replaceWith: 'Replace with'
            },
            tooltips: {
                preview: 'This is preview only and not the exact value'
            }
        },
        dataType: {
            DATA: {
                description: 'Replicate selected Data object/s (e.g. Accounts or Opportunities) or all Data objects',
                label: 'Data'
            },
            METADATA: {
                description:
                    'Replicate selected Metadata object/s (e.g. Workflows or Apex Code) or all Metadata objects',
                label: 'Metadata'
            }
        },
        description:
            'Replicate is used mostly for Seeding purposes. By using Replicate you can restore production data into sandbox by applying various functions on data.',
        destinationType: {
            autoDisableRules: 'Automatically disable all Validation Rules, Workflows, Triggers and Processes',
            other: {
                description:
                    "You will be asked to authorise on the organisation website. Don't worry we don't store your credentials",
                label: 'Other Organisation Sandbox'
            },
            same: {
                label: 'Same Organisation Sandbox'
            }
        },
        fields: {
            SQLWhereClause: {
                help: 'Press Shift + Enter to add a new line without validating the query',
                placeholder: 'type',
                validation: {
                    complete: 'Validation Complete'
                }
            }
        },
        fifthStep: {
            description: 'Select Destination',
            submitButton: 'Replicate',
            title: 'Data Replicate'
        },
        filters: {
            ALL_RECORDS: 'Use all records',
            MIXED: 'Mixed',
            PICK: 'Pick',
            WHERE_CLAUSE: 'SOQL WHERE clause'
        },
        firstStep: {
            description: 'Select type and snapshot date to Replicate',
            title: 'Replicate'
        },
        fourthStep: {
            allObjects: 'All objects',
            description: 'Object Filtering',
            deselect: 'Deselect',
            filteringFor: 'Filtering for',
            hint: 'You can narrow down the data being replicated, by filtering each object',
            pickRecords: 'records',
            whereClauseHint:
                'Please specify a condition that follows after the WHERE keyword in an SOQL WHERE clause, for example: " account_id = 1 ″.<br />You can use standard SOQL operators (IN, LIKE, AND, OR, NOT), Booleans (TRUE/FALSE) and Dates (e.g. 2020-02-19T12:37:24Z). The following query elements are not supported: nested queries, literals, operators INCLUDES and EXCLUDES, lookups, functions.'
        },
        hierarchyDepth: {
            info: 'Replicate also child objects according to set hierarchy depth',
            title: 'Hierarchy depth'
        },
        includeAttachments: {
            info: 'If checked attachments will be replicated',
            title: 'Include Attachments'
        },
        metadataObjectTypes: {
            FULL: {
                description:
                    'A "Full Org" replicate job will migrate all Metadata from the source Org Snapshot to the destination Org.',
                label: 'Full Organisation'
            },
            MULTIPLE: {
                description: 'Select one or multiple Metadata Types to include in the replication data set.',
                label: 'Granular Selection'
            }
        },
        objectTypes: {
            FULL: {
                description:
                    'A "Full Org"replicate job will migrate all data across all Objects from the source Org Snapshot to the desitnation Org.',
                label: 'Full Organisation'
            },
            MULTIPLE: {
                description:
                    'Select Parent Objects to include in the replication data set. Our system will automatically identfy the descendant related Object records and will include them in the replicate job as well. These records will be migrated to the destination Org with relationships',
                label: 'Multiple Parent Objects'
            }
        },
        pickOptions: {
            NEWEST: 'Newest',
            OLDEST: 'Oldest',
            RANDOM: 'Random'
        },
        secondStep: {
            anonymizeData: 'Anonymize data',
            anonymizeDataTooltip:
                'Data in the created replica will be altered in order to protect sensitive information.<br>Depending on the field, the original data may be anonymised using a pre-defined replacement value (i.e. john.doe@company.com) or with a random set of symbols, preserving the original data type and format (i.e. aaa@bbb.ccc).',
            dataDescription: 'Select Data Object',
            downloadAnonymizationTemplate: 'Download the Full Anonymization Template',
            metadataDescription: 'Select Metadata Object',
            metadataTitle: 'Metadata Replicate',
            title: 'Data Replicate'
        },
        thirdStep: {
            description: 'Select Data Object',
            objectsSearch: 'Search object'
        },
        title: 'Replicate'
    },
    seedingItem: {
        allObjects: 'All objects',
        download: 'Download report',
        objCounts: {
            obj_one: '{{count}} Object',
            obj_other: '{{count}} Objects'
        },
        object: 'Object',
        title: 'Replicating {{0}}',
        type: {
            data: 'Data',
            metadata: 'Metadata'
        }
    },
    smartAlerts: {
        action: {
            delete: 'Delete',
            disable: 'Disable',
            enable: 'Enable'
        },
        actionButtonText: 'Action',
        actionTooltip: {
            delete: 'Delete Alert',
            disable: 'Disable Alert',
            edit: 'Edit Alert',
            enable: 'Enable Alert'
        },
        addNewAlert: 'Add New Alert',
        alertLevel: {
            all: 'All Objects'
        },
        columns: {
            event: 'Event',
            rule: 'Rule',
            total: 'Total',
            triggerObjects: 'Trigger Object',
            triggeredBy: 'Triggered By',
            unit: 'Unit'
        },
        deleteConfirmation: '{{confirmationSuffix}} Smart Alerts will be deleted.<br>Please confirm',
        description:
            '<p>Create Smart Alerts to detect irregular objects changes between backup snapshots.<br>Use the alerts to quickly get notified about anomalies and activity red flags that could indicate problems such as potential data loss.</p>',
        edit: {
            alert: 'In {{alertLevel}} are',
            bellText: 'You have configured to notify you when',
            cancelButton: 'Cancel',
            okButton: 'Save Changes',
            title: 'Edit Smart Alert',
            triggered: 'By {{triggeredBy}}'
        },
        event: {
            added: 'Added',
            deleted: 'Deleted',
            updated: 'Updated'
        },
        eventButtonText: 'Event',
        rule: {
            lessThan: 'Less than',
            moreThan: 'More than',
            recordLevel: 'Record level'
        },
        ruleButtonText: 'Rule',
        searchPlaceholder: 'Search by Trigger Object..',
        selectedAll: 'All',
        tableWithoutAlertsPlaceholder: 'No Active Smart Alerts<br>+ Add New Alert at the top of the table',
        title: 'Smart Alerts',
        totalSmartAlerts: '{{totalSmartAlerts}} Total Smart Alerts',
        triggeredBy: {
            allUsers: 'All Users',
            singleUser: 'Single User'
        },
        triggeredByButtonText: 'Triggered By',
        unit: {
            percent: '%',
            record: 'Records'
        },
        wizard: {
            alertConfigDescription: {
                allUsers: 'Changes made by all users',
                singleUser: 'Changes made by a single user'
            },
            alertLevel: {
                ALL: {
                    description:
                        'Create an Alert for all Objects that compares changes between the previous and latest Backup',
                    label: 'All Objects'
                },
                OBJECT: {
                    description:
                        'Create an Alert for Specific Object that compares changes between the previous and latest Backup',
                    label: 'Specific Object'
                },
                RECORD: {
                    description: 'Create an Alert when records are changed between the previous and latest Backup',
                    label: 'Record Level Alert'
                }
            },
            chooseSpecificObject: 'Choose a Specific Object',
            configurationMoreTitle: 'Alert when more than',
            configurationTriggerTitle: 'Triggered by',
            error: {
                integer: 'Amount must be a positive integer'
            },
            firstStepDescription: 'Select Alert Level',
            firstStepTitle: 'Salesforce Backup Smart Alert',
            review: {
                eventAll: 'In All Objects are',
                eventSpecific: 'In All {{specificObject}} are',
                moreThan: 'More than',
                triggeredBy: 'Triggered by'
            },
            ruleConfig: {
                amount: 'Choose An Amount',
                event: 'Choose an Event',
                eventPlaceholder: 'Select Event',
                percentageError: 'Amount must be less than or equal to 100% in case of Updated/Delete event',
                unit: 'Choose Unit',
                unitPlaceholder: 'Select Unit'
            },
            secondStepAllObjectsTitle: 'Smart Alert For All Objects',
            secondStepDescription: 'Select Alert Configuration',
            secondStepSpecificObjectTitle: 'Smart Alert For {{specificObject}}',
            specificObjectDisabled: 'This option will be available after the next successful backup run',
            specificObjectPlaceholder: 'Trigger Object',
            thirdStepDescription: 'Review & Confirmation',
            thirdStepTitle: 'You have configured to notify you when',
            warning: {
                wideCriteria:
                    'You have created an alert with very wide criteria.<br>This means you will probably receive an alert after each backup cycle.<br>Are you sure that you want to proceed?'
            }
        }
    },
    snapshotCalendar: {
        currentDate: 'Snapshot date',
        currentTime: 'Snapshot time',
        fetchingError: "We couldn't fetch the snapshots. Please try again later"
    },
    startUsing: {
        addBackups: 'Add Backups',
        howTo: 'How To',
        howToBackup: 'How to back up data',
        title: 'Start Using The System',
        videos: 'Videos -'
    },
    subSvcCountDisp: {
        BOX_one: 'User',
        BOX_other: 'Users',
        DDB_one: 'Table',
        DDB_other: 'Tables',
        DROPBOX_PROFESSIONAL_one: 'User',
        DROPBOX_PROFESSIONAL_other: 'Users',
        DROPBOX_one: 'User / Team folder',
        DROPBOX_other: 'Users / Team folders',
        GACCOUNTS_one: 'Account',
        GACCOUNTS_other: 'Accounts',
        GDRIVETEAM_one: 'Drive',
        GDRIVETEAM_other: 'Drives',
        GOOGLEAPPS_one: 'User',
        GOOGLEAPPS_other: 'Users',
        IMAP_one: 'User',
        IMAP_other: 'Users',
        MS365GROUPS_one: 'Group / Team',
        MS365GROUPS_other: 'Groups / Teams',
        MS365_one: 'Mailbox',
        MS365_other: 'Mailboxes',
        ONEDRIVE_one: 'Site',
        ONEDRIVE_other: 'Sites',
        SDB_one: 'Domain',
        SDB_other: 'Domains',
        SFC_one: 'Seat',
        SFC_other: 'Seats',
        SHAREPOINT_MULTI_one: 'Site',
        SHAREPOINT_MULTI_other: 'Sites',
        SHAREPOINT_one: 'Site',
        SHAREPOINT_other: 'Sites',
        YMAIL_one: 'Account',
        YMAIL_other: 'Accounts'
    },
    support: {
        contacts: {
            title: 'Contact Us'
        },
        guides: {
            G_SUITE: {
                name: 'Google Workspace',
                video: 'CloudAlly GSuite Backup'
            },
            OFFICE_365: {
                name: 'Microsoft 365',
                video: 'CloudAlly Office365 backup'
            },
            ONE_DRIVE: {
                name: 'One Drive',
                video: 'CloudAlly OneDrive for Business Backup Activation'
            },
            OTHER: {
                name: 'Other',
                video: ['CloudAlly Trial Setup', 'CloudAlly Restore and Download', 'CloudAlly Backup Activation']
            },
            SHARE_POINT: {
                name: 'Share Point Online',
                video: 'CloudAlly SharePoint Online Backup Activation'
            },
            USER_GUIDE: {
                name: 'CloudAlly User Guide',
                title:
                    'Our user guide will assist you with activating backups, restoring data, and exporting data for onsite use.The guide includes sections for Microsoft Exchange, SharePoint/OneDrive, Google Workspace and Salesforce.You will also find instructions for managing your account settings and billing information.'
            },
            caption: 'Additional User Guides, Brochures and Tutorials',
            title: 'Guides & Tutorials'
        },
        knowledge: {
            caption: 'Archive of additional information',
            title: 'Knowledge Base'
        },
        tickets: {
            caption: 'Contact Support',
            catigories: {
                backup: 'Backup related issues',
                billing: 'Billing related issues',
                download: 'Download related issues',
                general: 'General Question',
                restore: 'Restore related issues'
            },
            new: {
                fileLabel: 'Add Screenshot',
                placeholder: {
                    category: 'Choose the type of ticket',
                    description: 'Free text description',
                    subject: 'Ticket subject'
                },
                send: 'send',
                text: 'Send us your request, and we`ll be in touch as soon as possible',
                title: 'Let us know how we can help'
            },
            table: {
                action: 'New Support Ticket',
                createdDate: 'Date',
                messages: 'From',
                status: 'Status',
                subject: 'Subject'
            },
            ticketPrefix: 'Ticket',
            title: 'Support Ticket'
        }
    },
    tasksStatus: {
        error: {
            backup: 'Backup Problem',
            download: 'Download Problem',
            recovery: 'Recovery Problem',
            task: 'Tasks Problem'
        },
        issue: 'Issue',
        issues: 'Issues',
        success: 'All Tasks Successful'
    }
}
