import styled from 'styled-components'

import variables from 'ca-common/variables'
import {TextSeparator} from 'src/ca-common/ui-lib/atoms/LineSeparator'

export const SignUpFormSection = styled.section`
    padding: 40px;
    z-index: 5;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    flex: 1;
    background-color: ${variables.signupBackground};
    box-shadow: 0px 0px 15px 0px #00000026;
    max-width: 560px;
`

export const ContentWrapper = styled.div`
    max-width: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;

    ${TextSeparator} {
        background-color: ${variables.signupBackground};
    }
`

export const FormTitle = styled.h2`
    font-size: 44px;
    align-self: center;
    color: ${variables.signupText};
    margin-bottom: ${variables.gutterXs};
`
