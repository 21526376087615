import React from 'react'
import PropTypes from 'prop-types'

import {CACellWrapper, CARowItems} from 'ca-common/ui-lib/components/TableElements'
import {CACell} from '../../Cell'

import {item as itemShape, config as configShape, rowSelection as rowSelectionShape} from 'ca-common/shapes/table'

export const CARowCells = ({item, config, rowSelection, collapse}) => {
    return (
        <CARowItems>
            {config.columns.map((column, index) => {
                const key = index + column.name + item.id

                return (
                    <CACellWrapper key={key} modifier={column.modifier}>
                        <CACell configColumn={column} item={item} rowSelection={rowSelection} collapse={collapse} />
                    </CACellWrapper>
                )
            })}
        </CARowItems>
    )
}

CARowCells.propTypes = {
    item: PropTypes.shape(itemShape).isRequired,
    config: PropTypes.shape(configShape).isRequired,
    rowSelection: PropTypes.shape(rowSelectionShape)
}

CARowCells.defaultProps = {
    rowSelection: null
}
