import React, {useState} from 'react'
import {FormInstance, Form, Radio, RadioChangeEvent, Space, Input} from 'antd'
import {useTranslation} from 'react-i18next'

import {AddIpFormFields, RestrictionEntry} from 'ca-common/features/IpRestriction/types'
import {
    HeaderWithIp,
    Description,
    AddressList,
    AddressRange
    // AddressCIDR,
} from 'ca-common/features/IpRestriction/atoms'
import {FORM_FIELDS, IP_VERSION, RESTRICTION_RULES, useOnFieldsChange} from 'ca-common/features/IpRestriction/lib'

import {ModalBody, ModalHeader, StyledRadioLabel} from './StyledAddNewForm'

type AddNewFormProps = {
    yourIpAddress: string
    form: FormInstance<AddIpFormFields>
    data?: RestrictionEntry[]
}

const defaultRuleType = RESTRICTION_RULES.LIST

export const AddNewForm = (props: AddNewFormProps): JSX.Element => {
    const {yourIpAddress, form} = props
    const [ruleType, setRuleType] = useState(defaultRuleType)
    const {t} = useTranslation()

    const onChangeRuleType = (e: RadioChangeEvent): void => {
        const type = e.target.value
        setRuleType(type)
        form.setFieldsValue({...form.getFieldsValue(), type})
        form.validateFields(
            type === RESTRICTION_RULES.RANGE ? [FORM_FIELDS.ADDRESSES] : [FORM_FIELDS.FROM, FORM_FIELDS.TO]
        )
    }

    const onFieldsChange = useOnFieldsChange(form)

    return (
        <React.Fragment>
            <ModalHeader>
                <HeaderWithIp title={t('ipRestrictions:addAddress:formHeader')} yourIpAddress={yourIpAddress} />
            </ModalHeader>
            <ModalBody>
                <Form form={form} layout="vertical" validateTrigger="onSubmit" onFieldsChange={onFieldsChange}>
                    <Description withOptional />

                    <StyledRadioLabel>{t('ipRestrictions:addAddress:radioGroupLabel')}</StyledRadioLabel>

                    <Radio.Group onChange={onChangeRuleType} value={ruleType} name={FORM_FIELDS.TYPE}>
                        <Space direction="vertical">
                            <Radio value={RESTRICTION_RULES.LIST}>
                                <AddressList disabled={ruleType !== RESTRICTION_RULES.LIST} />
                            </Radio>
                            <Radio value={RESTRICTION_RULES.RANGE}>
                                <AddressRange disabled={ruleType !== RESTRICTION_RULES.RANGE} />
                            </Radio>
                            {/* <Radio value={RuleType.CIDR}>
                                <AddressCIDR disabled={ruleType !== RuleType.CIDR} />
                            </Radio> */}
                        </Space>
                    </Radio.Group>
                    <Form.Item hidden name={FORM_FIELDS.TYPE} initialValue={defaultRuleType}>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name={FORM_FIELDS.VERSION} initialValue={IP_VERSION.IPv4}>
                        <Input />
                    </Form.Item>
                </Form>
            </ModalBody>
        </React.Fragment>
    )
}
