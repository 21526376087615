import React from 'react'
import {useTranslation} from 'react-i18next'

import Activate from 'ca-common/icons/backupActionsNew/Activate.svg'

import {openInfoNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {ActionIcon, BackupActionButton} from '../../../organisms/BackupActions/StyledBackupActions'
import {isTaskPaused, isTaskProcessing, isTaskQueued} from '../../../utils/utils'
import {ACTIONS} from '../../../utils/constants'
import {status} from 'src/newcore/features/Homepage/types'

type ResumeProps = {
    status: status
    setSpin: (value: boolean) => void
    pullAndUpdateLocalServices: () => void
    sendAction: any
}

export const Resume = ({status, setSpin, pullAndUpdateLocalServices, sendAction}: ResumeProps): JSX.Element => {
    const {t} = useTranslation()

    return (
        <BackupActionButton data-action={ACTIONS.RESUME.toLowerCase()} onClick={onClickHandler}>
            <ActionIcon component={Activate} />
            {t(`backups:actions:${ACTIONS.RESUME}`)}
        </BackupActionButton>
    )

    function onClickHandler() {
        if (!isTaskProcessing(status) && !isTaskQueued(status)) {
            const activate = isTaskPaused(status)
            setSpin(true)
            sendAction(ACTIONS.RESUME, activate)
                .then(() => {
                    openInfoNotification(t(`backups:statusChange`, [t(`backups:activate:success:true`)]))
                    pullAndUpdateLocalServices()
                })
                .catch((error: any) => {
                    setSpin(false)
                    openErrorModal(
                        t(`backups:failedTo`, [t(`backups:activate:error:true`)]) + (error ? error.status : '')
                    )
                })
        }
    }
}
