import _ from 'lodash'
import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'

import {checkLimitedAccess} from 'ca-common/utils/backup/limitedAccess'
import {TAG_STATE} from 'ca-common/common/enum/TagsState'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {LIMITED_ACCESS_PAGES} from 'ca-common/common/enum/Backups'
import {INTEGRATION_TYPE} from 'ca-common/constants'

import {getTagsState, updateTagState} from 'src/newcore/redux/modules/backups/backupTags'
import {LimitedAccess} from 'src/newcore/molecules/LimitedAccess'

import {Footer} from '../Footer'
import {
    StyledContent,
    StyledEmptyContent,
    Tags,
    TagName,
    TagCheckbox,
    TagTooltip,
    NewTagLabel,
    ContentSpin
} from '../StyledBackupTags'

@withTranslation()
class ContentRaw extends React.Component {
    state = {
        selectedEntityValue: [],
        /*
        true - every entity has limited access
        false - no entities with limited access
        undefined - combined
         */
        selectedLimitedAccess: false,
        initialSelectedLimitedAccess: undefined //
    }

    componentDidMount() {
        this.initialTagsState()
    }

    componentDidUpdate(prevProps) {
        const {selectedRowKeys, setSearchTag} = this.props
        if (!_.isEqual(prevProps.selectedRowKeys, selectedRowKeys)) {
            this.initialTagsState()
            setSearchTag('')
        }
    }

    initialTagsState = () => {
        const {
            actions,
            taskSettings: {taskId, source},
            history,
            selectedAll,
            selectedRowKeys,
            setInitialTagsState
        } = this.props

        const queryParams = queryString.parse(history.location.search, {arrayFormat: 'bracket'})

        actions
            .getTagsState({
                taskId,
                source,
                all: selectedAll,
                entityIDs: selectedRowKeys,
                taskFilter: getRequestParamsFromUrl(queryParams)
            })
            .then(response => setInitialTagsState(response.payload))

        const entitiesData = this.props.entities?.response?.data
        const selectedEntityData = entitiesData && entitiesData.filter(value => selectedRowKeys.includes(value.id))

        let selectedLimitedAccess = undefined

        if (selectedEntityData.every(i => i.limitedAccess)) {
            selectedLimitedAccess = true
        }

        if (selectedEntityData.every(i => !i.limitedAccess)) {
            selectedLimitedAccess = false
        }

        this.setState({
            selectedEntityValue: selectedEntityData,
            selectedLimitedAccess,
            initialSelectedLimitedAccess: selectedLimitedAccess
        })
    }

    onGroupChange = id => {
        const {actions, initialTagsState} = this.props
        actions.updateTagState({id, initialTagsState})
    }
    getFilteredTags = (tagsState, searchedTag) =>
        tagsState.filter(tag => tag.text?.toLowerCase().includes(searchedTag.toLowerCase()))

    updateLimitedValue = () => {
        this.setState({
            selectedLimitedAccess: !this.state.selectedLimitedAccess
        })
    }

    render() {
        const {
            t,
            selectedAll,
            selectedRowKeys,
            tagsState,
            searchTag,
            setPopoverVisible,
            toggleBackupTagsForm,
            setSearchTag,
            setInitialTagsState,
            userInfo
        } = this.props

        const data = _.get(tagsState, 'response', [])
        const filteredTags = this.getFilteredTags(data, searchTag)
        const isLoaded = tagsState.status !== 'pending'
        const isCheckEnable = checkLimitedAccess(LIMITED_ACCESS_PAGES.TAG_DROPDOWN, {
            limitedAccess: this.state.selectedLimitedAccess,
            denySetAccessTag: this.state.selectedEntityValue.some(i => i.denySetAccessTag),
            denyDeleteAccessTag: this.state.selectedEntityValue.some(i => i.denyDeleteAccessTag)
        })

        return (
            <StyledContent isLoaded={isLoaded}>
                {isLoaded ? (
                    <Fragment>
                        <Tags>
                            {userInfo?.integrationType !== INTEGRATION_TYPE.ZIX && (
                                <TagCheckbox
                                    indeterminate={this.state.selectedLimitedAccess === undefined}
                                    value="limitedAccess"
                                    key="limitedAccess"
                                    disabled={isCheckEnable}
                                    onChange={() => this.updateLimitedValue()}
                                    checked={this.state.selectedLimitedAccess !== false}
                                >
                                    <LimitedAccess isBackground={false} isCheckEnable={isCheckEnable} />
                                </TagCheckbox>
                            )}
                            {filteredTags.length ? (
                                filteredTags.map(item => {
                                    return (
                                        <TagCheckbox
                                            indeterminate={item.state === TAG_STATE.INDETERMINATE}
                                            value={item.id}
                                            key={item.id}
                                            onChange={() => this.onGroupChange(item.id)}
                                            checked={item.state === TAG_STATE.CHECKED}
                                        >
                                            <TagTooltip placement={'topLeft'} title={<span>{item.text}</span>}>
                                                <TagName new={item.new}>{item.text}</TagName>
                                            </TagTooltip>
                                            {item.new && <NewTagLabel>{t('forms:backupTags:new')}</NewTagLabel>}
                                        </TagCheckbox>
                                    )
                                })
                            ) : (
                                <StyledEmptyContent />
                            )}
                        </Tags>
                        <Footer
                            selectedAll={selectedAll}
                            selectedRowKeys={selectedRowKeys}
                            searchTag={searchTag}
                            tags={data}
                            filteredTags={filteredTags}
                            setSearchTag={setSearchTag}
                            setInitialTagsState={setInitialTagsState}
                            setPopoverVisible={setPopoverVisible}
                            toggleBackupTagsForm={toggleBackupTagsForm}
                            updatedLimitedAccess={
                                this.state.selectedLimitedAccess !== this.state.initialSelectedLimitedAccess
                            }
                            selectedLimitedAccess={this.state.selectedLimitedAccess}
                        />
                    </Fragment>
                ) : (
                    <ContentSpin />
                )}
            </StyledContent>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    tagsState: state.backups.backupTags.tagsState,
    entities: state.backups.backupEntities.entities,
    userInfo: state.userInfo.response
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            updateTagState,
            getTagsState
        },
        dispatch
    )
})

export const Content = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentRaw))
