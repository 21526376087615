import React from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'

import Edit from 'ca-common/icons/old/uEA5E-action-edit.svg'
import Cancellation from 'ca-common/icons/old/uEA02-action-cancellation.svg'

import {PAGES} from 'ca-common/constants'
import {makeYesNoModal, eatModal} from 'ca-common/redux/modules/modal'
import {openErrorModal} from 'ca-common/utils/modals'
import {openSuccessNotification} from 'ca-common/utils/toasts'

import type {DeleteUsersNotification} from 'ca-common/features/NotificationsTable/types'

import {StyledActionsCell, StyledIcon} from './StyledActionsCell'

export type ActionsCellProps = {
    email: string
    id: number
    deleteUsersNotification: DeleteUsersNotification
    fetchUsersNotifications: () => Promise<void>
}

export const ActionsCell = (props: ActionsCellProps) => {
    const {id, email, fetchUsersNotifications, deleteUsersNotification} = props

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const onDelete = () => {
        deleteUsersNotification(email)
            .then(() => {
                fetchUsersNotifications()
                openSuccessNotification(t('notifications:removedSuccessfully', [email]))
                dispatch(eatModal())
            })
            .catch((err: {status: string}) => openErrorModal(`${t('notifications:removeFailed')} ${err.status}`))
    }

    const openDeleteModal = () => {
        dispatch(
            makeYesNoModal({
                children: t('notifications:removeConfirmation'),
                title: t('modals:removeModal:title'),
                accept: onDelete
            })
        )
    }

    return (
        <StyledActionsCell>
            <Link to={`/${PAGES.SETTINGS}/${PAGES.NOTIFICATIONS}/${id}`}>
                <StyledIcon component={Edit} />
            </Link>
            <StyledIcon component={Cancellation} onClick={openDeleteModal} />
        </StyledActionsCell>
    )
}
