import Icon from '@ant-design/icons'
import styled from 'styled-components'

import variables from 'ca-common/variables'
import {SOURCE} from 'ca-common/common/enum'

export const StyledProductLogo = styled.div<{product: SOURCE}>`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    @media (max-width: ${variables.screenXl}) {
        ${props => {
            switch (props.product) {
                case SOURCE.MS365:
                    return `
                    width: 50%;
                `
                case SOURCE.GOOGLEAPPS:
                    return `
                    width: 50%;
                `
                case SOURCE.SFC:
                    return `
                     width: 20%;
                `
                case SOURCE.BOX:
                    return `
                     width: 20%;
                     
                `
                case SOURCE.DROPBOX:
                    return `
                     width: 30%;
                `
                default:
                    return ''
            }
        }}
    }

    @media (max-width: ${variables.screenMd}) {
        ${props => {
            switch (props.product) {
                case SOURCE.MS365:
                    return `
                    width: 60%;
                    order: 1;
                `
                case SOURCE.GOOGLEAPPS:
                    return `
                    width: 100%;
                    order: 3;
                `
                case SOURCE.SFC:
                    return `
                     width: 40%;
                     order: 2;
                `
                case SOURCE.BOX:
                    return `
                     width: 30%;
                     order: 5;
                `
                case SOURCE.DROPBOX:
                    return `
                     width: 56%;
                     order: 4;
                `
                default:
                    return ''
            }
        }}
    }
`

export const StyledIcon = styled(Icon)<{product: SOURCE}>`
    svg {
        ${props => {
            switch (props.product) {
                case SOURCE.MS365:
                    return `
                    width: 235px;
                    height: 64px;
                `
                case SOURCE.GOOGLEAPPS:
                    return `
                    width: 280px;
                    height: 64px;
                `
                case SOURCE.SFC:
                    return `
                     width: 100px;
                     height: 100px;
                `
                case SOURCE.BOX:
                    return `
                     width: 88px;
                     height: 88px;
                `
                case SOURCE.DROPBOX:
                    return `
                     width: 234px;
                     height: 64px;
                `
                default:
                    return ''
            }
        }}
    }

    @media (max-width: ${variables.screenMd}) {
        svg {
            ${props => {
                switch (props.product) {
                    case SOURCE.MS365:
                        return `
                        width: 162px;
                        height: 44px;
                    `
                    case SOURCE.GOOGLEAPPS:
                        return `
                        width: 240px;
                        height: 44px;
                    `
                    case SOURCE.SFC:
                        return `
                         width: 84px;
                         height: 84px;
                    `
                    case SOURCE.BOX:
                        return `
                         width: 76px;
                         height: 76px;
                    `
                    case SOURCE.DROPBOX:
                        return `
                         width: 161px;
                         height: 44px;
                    `
                    default:
                        return ''
                }
            }}
        }
    }
`
