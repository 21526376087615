import styled from 'styled-components'
import {TreeSelect} from 'antd'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'

export const StyledTreeSelect = styled(TreeSelect)<typeof TreeSelect & {width: number; value: string}>`
    &:hover:not(.ant-select-disabled) + .anticon {
        color: ${variables.navyBlue} !important;
    }

    &&&&& .ant-select-selector {
        border-radius: 22px;
        border-style: solid;
        border-color: ${variables.navyBlue};
        border-width: 2px !important;
        padding: 0;
        height: 32px;
        width: ${props => (props.width ? `${props.width}px` : '120px')};
        cursor: pointer;
        background-color: ${props =>
            ((props.value as unknown) as Array<string>)?.length ? variables.navyBlue : 'transparent'};

        &:focus {
            box-shadow: 0 0 0 2px rgba(38, 45, 97, 0.2);
        }

        .ant-select-selection-overflow {
            height: 28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            padding-left: 6px;
            border-radius: 22px;
            background-color: ${props =>
                ((props.value as unknown) as Array<string>)?.length ? variables.navyBlue : 'transparent'};
            font-family: ${variables.accentFont};
            font-weight: 600;
            color: ${variables.white};
            transition: background-color 300ms ease-in-out;
            pointer-events: none;
        }

        &:hover {
            .ant-select-selection-overflow {
                background-color: ${variables.blueLight};
                border-color: ${variables.navyBlue};

                .ant-select-selection-item {
                    color: ${variables.navyBlue};
                }
            }
        }

        .ant-select-selection-placeholder {
            color: ${variables.navyBlue};
            font-weight: 600;
            top: 50%;
        }

        .ant-select-selection-item {
            background: transparent;
            color: ${variables.white};
            font-weight: 600;
            font-family: ${variables.accentFont};
            text-align: center;
            border: 0;
            margin-top: 3px;
            padding-inline-start: 6px;
        }
    }

    &&&&&.ant-select-disabled {
        .ant-select-selector {
            border-color: ${variables.asphaltLight};
            background: ${variables.blueLight};
            cursor: not-allowed;

            .ant-select-selection-placeholder {
                color: ${variables.asphaltLight};
            }

            .ant-select-selection-overflow {
                background: ${variables.blueLight};
                border-color: ${variables.asphaltLight};

                .ant-select-selection-item {
                    color: ${variables.asphaltLight};
                }
            }
        }
    }
`

export const StyledTreeSelectContainer = styled.div`
    position: relative;
`

export const StyledIcon = styled(Icon)<{selected: boolean; disabled: boolean}>`
    position: absolute;
    right: 12px;
    top: 11px;
    font-size: ${variables.fontSizeBase};
    pointer-events: none;
    color: ${props => (props.disabled ? variables.asphaltLight : props.selected ? variables.white : 'inherit')};
`
