import {post, postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT} from 'ca-common/utils/fetchWrapperRT'

const CREATE_BACKUP_TASK = 'CREATE_BACKUP_TASK'
const createBackupAuthWrapper = fetchWrapperRT(CREATE_BACKUP_TASK, async (data, {rejectWithValue}) => {
    try {
        return await post('createTask', data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})
export const createBackupAuth = createBackupAuthWrapper.fetcher

const ACTIVATE_CACHED_TASK = 'ACTIVATE_CACHED_TASK'
const activateCachedTaskWrapper = fetchWrapperRT(
    ACTIVATE_CACHED_TASK,
    async data => await postLessData('activateCachedTask', data)
)
export const activateCachedTask = activateCachedTaskWrapper.fetcher

const UPDATE_BACKUP_AUTH = 'UPDATE_BACKUP_TASK_AUTH'
const updateBackupAuthWrapper = fetchWrapperRT(UPDATE_BACKUP_AUTH, async (data, {rejectWithValue}) => {
    try {
        return await post('serviceUpdate', data)
    } catch (e: any) {
        throw rejectWithValue(e.data)
    }
})

export const updateBackupAuth = updateBackupAuthWrapper.fetcher

const CONTINUE_CREATE_OAUTH_TASK = 'CONTINUE_CREATE_OAUTH_TASK'
const CONTINUE_CREATE_OAUTH_TASK_API = 'continueCreateOauthTask'
const continueCreateOauthTaskWrapper = fetchWrapperRT(
    CONTINUE_CREATE_OAUTH_TASK,
    async data => await post(CONTINUE_CREATE_OAUTH_TASK_API, data)
)
export const continueCreateOauthTask = continueCreateOauthTaskWrapper.fetcher
