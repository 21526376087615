import _ from 'lodash'
import React from 'react'

import {CACheckBox} from 'ca-common/ui-lib/components/CheckBox'
import {CACellModifier} from 'ca-common/ui-lib/components/TableElements/index'

export class CASelectItemCheckbox extends React.Component {
    render() {
        const {item, rowSelection} = this.props

        return (
            <CACellModifier
                onClick={() => {
                    this.onClick(item)
                }}
            >
                <CACheckBox id={item.id} checked={this.isChecked(rowSelection, item.id)} />
            </CACellModifier>
        )
    }

    onClick = item => {
        const {rowSelection} = this.props

        if (rowSelection.areAllItemsSelected) {
            rowSelection.onItemClick(_.reject(rowSelection.currentPageItems, ['id', item.id]))
        } else {
            rowSelection.onItemClick([item])
        }
    }

    isChecked = (rowSelection, id) => rowSelection.areAllItemsSelected || _.some(rowSelection.selectedItems, {id})
}
