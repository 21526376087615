import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useRouteMatch} from 'react-router'
import queryString from 'query-string'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import toLower from 'lodash/toLower'

import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'ca-common/components/Modal'
import {openSuccessNotification, openErrorNotification} from 'ca-common/utils/toasts'
import {Button} from 'ca-common/ui-lib/atoms'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {SOURCE} from 'ca-common/common/enum'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import Warning from 'ca-common/icons/Warning.svg'

import {trackBulkActivation, TypesOfRules} from 'src/newcore/components/BulkActivation/trackBulkActivation'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {activateBulkFlow} from 'src/newcore/redux/modules/backups/bulkActivate'
import {changeData, clearWizard, previousStep, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {ConfirmationRuleSelectionModal} from 'src/newcore/components/Modals/ConfirmationRuleSelectionModal'
import {pullBackupEntities} from 'src/newcore/redux/modules/backups/backupEntities/entities'
import {
    clearBulkActivationSP,
    getSitesWithMatchedConditions,
    matchedSitesSelector
} from 'src/newcore/components/BulkActivation/SharepointUrl/redux/redux'
import type {WizardData} from 'src/newcore/components/BulkActivation/SharepointUrl/types'
import {DEFAULT_PAGE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'

import {Site} from 'src/newcore/components/BulkActivation/SharepointUrl/SitesList'
import {
    Header,
    SitesListHeader,
    SitesListHeaderCell,
    SitesListWrapper,
    StyledModalContentWrapper,
    WarningIcon
} from './StyledSitesList'

const FIRST_PAGE = 1
const PAGE_SIZE = 20

export const SitesList = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const match = useRouteMatch<{taskId: string}>()
    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const wizardData = useAppSelector(wizardDataSelector) as WizardData
    const matchedSites = useAppSelector(matchedSitesSelector)
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)

    const excludedUrls = wizardData.excludedUrls || {}

    const hasMore =
        matchedSites.status === 'fulfilled' ? matchedSites.response.page < matchedSites.response.totalPages : false

    const isDisabled =
        !matchedSites.response?.data?.length ||
        Object.values(excludedUrls).filter(Boolean).length === matchedSites.response?.data?.length

    const fetchData = (page?: number) => {
        dispatch(
            getSitesWithMatchedConditions({
                urlConditions: wizardData.urlConditions,
                taskId: match.params.taskId,
                rows: PAGE_SIZE,
                page: page || (matchedSites.response?.page || 0) + 1
            })
        )
    }

    const onChange = useCallback(
        (url: string) => {
            dispatch(changeData({excludedUrls: {...excludedUrls, [url]: !excludedUrls[url]}}))
        },
        [excludedUrls]
    )

    const handleActivate = (createRule = false) => {
        setLoading(true)
        return dispatch(
            activateBulkFlow({
                taskSettings,
                urlConditions: wizardData.urlConditions,
                excludes: Object.keys(excludedUrls).filter(url => excludedUrls[url]),
                createRule,
                cacheToken: matchedSites.response?.cacheToken
            })
        )
            .then((data: any) => {
                const succeed = data.succeed
                const total = data.total

                openSuccessNotification(t('backups:activatedSuccessfully', {succeed, total}))

                const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

                dispatch(clearBulkActivationSP())
                dispatch(clearWizard())
                dispatch(
                    pullBackupEntities({
                        taskId: taskSettings?.taskId,
                        rows: PAGE_SIZE,
                        page: DEFAULT_PAGE,
                        ...getRequestParamsFromUrl(queryParams)
                    })
                )
            })
            .catch(() => {
                openErrorNotification(t('backups:MS365Groups:activationFailed'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleConfirmationCancelClick = () => {
        setIsVisible(false)
        trackBulkActivation(
            EVENT_NAMES.REFUSAL_TO_CONFIRM_ACTIVATION_RULES_CREATION,
            taskSettings?.source,
            TypesOfRules.SHAREPOINT
        )
    }

    const handleConfirmationSubmit = () => {
        setIsVisible(false)
        handleActivate(true).then(() => {
            trackBulkActivation(
                EVENT_NAMES.CONFIRM_AN_ACTIVATION_RULE_CREATION,
                taskSettings?.source,
                TypesOfRules.SHAREPOINT
            )
        })
    }

    const onActivateRule = async () => {
        if (taskSettings?.detectNewAccounts) {
            setIsVisible(true)
        } else {
            await handleActivate(true)
        }

        trackBulkActivation(EVENT_NAMES.CREATE_AN_ACTIVATION_RULE, taskSettings?.source, TypesOfRules.SHAREPOINT)
    }

    const onCancel = () => {
        trackBulkActivation(EVENT_NAMES.CANCEL_BULK_ACTIVATION_LAST_STEP, taskSettings?.source, TypesOfRules.SHAREPOINT)
        dispatch(clearBulkActivationSP())
        dispatch(clearWizard())
    }

    const onActivate = () => {
        handleActivate(false).then(() => {
            trackBulkActivation(EVENT_NAMES.PERFORM_ONE_TIME_ACTIVATION, taskSettings?.source, TypesOfRules.SHAREPOINT)
        })
    }

    useEffect(() => {
        fetchData(FIRST_PAGE)
    }, [])

    return (
        <>
            <StyledModalContentWrapper>
                <SitesListHeader>
                    <SitesListHeaderCell>{t('backups:bulkActivation:sitesListHeaders:site')}</SitesListHeaderCell>
                </SitesListHeader>
                <SitesListWrapper>
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={FIRST_PAGE}
                        useWindow={false}
                        hasMore={hasMore}
                        loadMore={fetchData}
                    >
                        <Spin spinning={matchedSites.status !== 'fulfilled'}>
                            {matchedSites.response?.data?.map(({url, id}) => (
                                <Site onChange={onChange} checked={!excludedUrls[url]} key={id} url={url} />
                            ))}
                        </Spin>
                    </InfiniteScroll>
                </SitesListWrapper>
            </StyledModalContentWrapper>
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button type="primary" onClick={onActivate} disabled={isDisabled} loading={isLoading}>
                        <span>{t('backups:bulkActivation:activate')}</span>
                    </Button>
                    <Button type="primary" onClick={onActivateRule} loading={isLoading} disabled={isDisabled}>
                        <span>{t('backups:bulkActivation:activateRule')}</span>
                    </Button>
                </LinkWrapper>
                <BackLink onClick={() => dispatch(previousStep())} disabled={isLoading} />
            </ModalFooter>
            <ConfirmationRuleSelectionModal
                open={isVisible}
                onOk={handleConfirmationSubmit}
                onCancel={handleConfirmationCancelClick}
            >
                <Header>
                    <WarningIcon component={Warning} />
                    <p>
                        {t('backups:byCondition:popoverHeader', {
                            entityName: toLower(getSvcAccountsDescription(SOURCE.ONEDRIVE, true))
                        })}
                    </p>
                </Header>
            </ConfirmationRuleSelectionModal>
        </>
    )
}
