import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Input, Modal} from 'antd'
import FormItem from 'antd/lib/form/FormItem'

import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'

import {
    StyledEmailSettings,
    StyledConfirmationActionButton,
    StyledConfirmationNote,
    StyledConfirmationNoteTitle,
    StyledEmailExtra,
    StyledEmailExtraBold,
    StyledEmailWrapper,
    StyledModalContent,
    StyledNewEmailWrapper,
    StyledResendMessage,
    StyledEmailFormItemWrapper
} from './StyledEmailSettings'

type EmailSettingsProps = {
    disabled?: boolean
    initialValues: {
        email: string
        newEmail?: string
    }
    resendConfirmationEmail: () => void
    discard: () => Promise<any>
    emailLabel: string
    getAccountSettings: () => void
    FormItemComponent: typeof FormItem
    timer: number
}

export const EmailSettings = (props: EmailSettingsProps) => {
    const {
        emailLabel,
        initialValues,
        disabled,
        resendConfirmationEmail,
        discard,
        getAccountSettings,
        FormItemComponent,
        timer
    } = props
    const {t} = useTranslation()
    const [isCancelModalVisible, setCancelModalVisible] = useState(false)

    const handleDiscardButtonClick = () => {
        discard()
            .then(() => {
                openSuccessNotification()
                getAccountSettings()
            })
            .catch((error: any) => openErrorNotification(error.status))

        setCancelModalVisible(false)
    }

    return (
        <StyledEmailSettings>
            <StyledEmailWrapper changing={Boolean(initialValues.newEmail)}>
                <StyledEmailFormItemWrapper>
                    <FormItemComponent
                        extra={
                            initialValues.newEmail ? null : (
                                <StyledEmailExtra>
                                    {t('accountPage:emailExtra')}
                                    <StyledEmailExtraBold>{t('accountPage:emailExtraBold')}</StyledEmailExtraBold>
                                </StyledEmailExtra>
                            )
                        }
                        name="email"
                        label={emailLabel}
                        initialValue={initialValues.email}
                        rules={[
                            {required: true, message: t('validation:requiredField')},
                            {type: 'email', message: t('validation:validEmailAddress')}
                        ]}
                    >
                        <Input disabled={disabled} />
                    </FormItemComponent>
                </StyledEmailFormItemWrapper>

                {initialValues.newEmail && (
                    <StyledNewEmailWrapper>
                        <FormItemComponent
                            name="newEmail"
                            label={t('accountPage:newEmail')}
                            initialValue={initialValues.newEmail}
                            rules={[
                                {required: true, message: t('validation:requiredField')},
                                {type: 'email', message: t('validation:validEmailAddress')}
                            ]}
                        >
                            <Input disabled />
                        </FormItemComponent>
                        <StyledConfirmationActionButton type="button" onClick={() => setCancelModalVisible(true)}>
                            {t('forms:common:actions:discard')}
                        </StyledConfirmationActionButton>
                    </StyledNewEmailWrapper>
                )}
            </StyledEmailWrapper>

            {initialValues.newEmail && (
                <StyledConfirmationNote>
                    <span>
                        <StyledConfirmationNoteTitle>{t('accountPage:note')}</StyledConfirmationNoteTitle>
                        <Trans i18nKey={'accountPage:confirmationSent'} values={{newEmail: initialValues.newEmail}}>
                            <strong>0</strong>
                        </Trans>
                    </span>
                    {timer ? (
                        <StyledResendMessage>
                            <Trans i18nKey={'accountPage:resendMessage'} values={{timer}}>
                                <strong>0</strong>
                            </Trans>
                        </StyledResendMessage>
                    ) : (
                        <StyledConfirmationActionButton onClick={resendConfirmationEmail} type="button">
                            {t('accountPage:resend')}
                        </StyledConfirmationActionButton>
                    )}
                </StyledConfirmationNote>
            )}
            <Modal
                open={isCancelModalVisible}
                children={
                    <StyledModalContent>
                        <span>
                            <Trans
                                i18nKey={'accountPage:discardMessage'}
                                values={{email: initialValues.email, newEmail: initialValues.newEmail}}
                            >
                                <strong>0</strong>
                            </Trans>
                        </span>
                    </StyledModalContent>
                }
                closable={false}
                centered
                width={600}
                okText={t('modals:buttons:yes')}
                onOk={handleDiscardButtonClick}
                cancelText={t('modals:buttons:no')}
                cancelButtonProps={{type: 'link'}}
                onCancel={() => setCancelModalVisible(false)}
            />
        </StyledEmailSettings>
    )
}
