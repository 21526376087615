import React from 'react'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'

import CAInput from '../../Input'

import './LabeledInput.scss'

const dumbFormGroup = dumbBem('ca-form-group')
const FormGroup = tx(dumbFormGroup)('div')
const Label = tx([{element: 'label'}, dumbFormGroup])('label')
const Error = tx([{element: 'error'}, dumbFormGroup])('span')

export class LabeledInput extends React.Component {
    render() {
        const {
            input,
            label,
            htmlFor,
            meta: {touched, error, warning},
            required,
            width,
            formGroupModifier,
            ...rest
        } = this.props

        const fieldModifier = touched && error ? 'error' : ''
        const labelModifier = required ? 'required' : ''
        const newFormGroupModifier = formGroupModifier ? formGroupModifier : 'default labeled'

        return (
            <FormGroup style={{width: width}} modifier={newFormGroupModifier}>
                {label && (
                    <Label htmlFor={htmlFor} modifier={labelModifier}>
                        {label}
                    </Label>
                )}
                <CAInput {...input} {...rest} modifier={fieldModifier} id={htmlFor} />
                {touched && ((error && <Error>{error}</Error>) || (warning && <span>{warning}</span>))}
            </FormGroup>
        )
    }
}

export default LabeledInput
