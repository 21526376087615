import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import Select, {createFilter} from 'react-select'

import {mapOptionsByLabelValue} from 'ca-common/utils/select'
import {inputShape, optionShape, filterConfigShape} from 'ca-common/shapes/select'

import './ReactSelect.scss'

const dumbFormGroup = dumbBem('ca-form-group')
const FormGroup = tx(dumbFormGroup)('div')
const Label = tx([{element: 'label'}, dumbFormGroup])('label')
const Error = tx([{element: 'error'}, dumbFormGroup])('span')

export class ReactSelect extends React.Component {
    static propTypes = {
        input: PropTypes.shape(inputShape).isRequired,
        options: PropTypes.arrayOf(PropTypes.shape(optionShape)).isRequired,
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.PropTypes.shape(optionShape)]),
        isMulti: PropTypes.bool,
        isSearchable: PropTypes.bool,
        isClearable: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        valueKey: PropTypes.string.isRequired,
        labelKey: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        filterConfig: PropTypes.shape(filterConfigShape),
        customStyles: PropTypes.object,
        menuPlacement: PropTypes.string,
        modifier: PropTypes.string
    }

    static defaultProps = {
        valueKey: 'value',
        labelKey: 'label',
        isMulti: false,
        isSearchable: false,
        isClearable: false,
        disabled: false,
        defaultValue: '',
        className: 'react-select',
        placeholder: 'Select...',
        filterConfig: {},
        label: '',
        customStyles: {},
        menuPlacement: 'auto',
        modifier: ''
    }

    render() {
        const {
            input,
            options,
            defaultValue,
            isMulti,
            valueKey,
            labelKey,
            label,
            placeholder,
            disabled,
            meta,
            isSearchable,
            isClearable,
            filterConfig,
            required,
            className,
            customStyles,
            menuPlacement,
            modifier
        } = this.props

        const {name, value, onChange, onFocus} = input
        const {touched, visited, error, warning} = meta
        const labelModifier = required ? 'required' : ''
        const isDisabled = disabled

        const mappedOptions = mapOptionsByLabelValue(options, labelKey, valueKey)
        const transformedValue =
            defaultValue && !value ? defaultValue : this.transformValue(value, mappedOptions, isMulti)
        const modifiers = label ? 'labeled' : ''

        return (
            <FormGroup modifier={`${modifiers} ${modifier}`}>
                {label && (
                    <Label htmlFor={name} modifier={labelModifier}>
                        {label}
                    </Label>
                )}
                <Select
                    name={name}
                    value={transformedValue}
                    isMulti={isMulti}
                    options={mappedOptions}
                    onChange={isMulti ? this.multiChangeHandler(onChange) : this.singleChangeHandler(onChange)}
                    // @TODO check the bug https://github.com/JedWatson/react-select/issues/3097 (not on all devices)
                    // onBlur={() => onBlur(value)}
                    onFocus={onFocus}
                    classNamePrefix={className}
                    className={className}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    filterOption={createFilter(filterConfig)}
                    styles={customStyles}
                    menuPlacement={menuPlacement}
                />
                {(touched || visited) && ((error && <Error>{error}</Error>) || (warning && <span>{warning}</span>))}
            </FormGroup>
        )
    }

    /**
     * onChange from Redux Form Field has to be called explicity.
     */
    singleChangeHandler = func => {
        return function handleSingleChange(option) {
            func(option ? option.value : '')
        }
    }

    /**
     * onBlur from Redux Form Field has to be called explicity.
     */
    multiChangeHandler = func => {
        return function handleMultiHandler(options) {
            func(options.map(option => option.value))
        }
    }

    /**
     * For single select, Redux Form keeps the value as a string, while React Select
     * wants the value in the form { value: "grape", label: "Grape" }
     *
     * * For multi select, Redux Form keeps the value as array of strings, while React Select
     * wants the array of values in the form [{ value: "grape", label: "Grape" }]
     */
    transformValue = (value, options, isMulti) => {
        if (isMulti && _.isString(value)) {
            return []
        }

        const filteredOptions = options.filter(option => {
            return isMulti ? _.includes(value, option.value) : option.value === value
        })

        return isMulti ? filteredOptions : filteredOptions[0]
    }
}
