import React from 'react'
import {Tooltip} from 'antd'

import {ActivityStatusValues, getStatusIcon} from 'ca-common/utils/getActivityStatusIcon'
import {StatusIcon, StatusWrapper} from './StyledStatus'

type StatusProps = {
    status: ActivityStatusValues
    statusDescription?: string
}

export const Status = ({status, statusDescription}: StatusProps): JSX.Element => (
    <StatusWrapper>
        <Tooltip title={statusDescription}>
            <StatusIcon component={getStatusIcon(status)} />
        </Tooltip>
        {status}
    </StatusWrapper>
)
