import React from 'react'
import {StyledTreeSelect, StyledTreeSelectContainer, StyledIcon} from './StyledTreeSelect'
import DropDown from 'ca-common/icons/DropDown.svg'
import {TODO_ANY} from 'ca-common/types'

export const TreeSelect = React.forwardRef((props: TODO_ANY, ref) => {
    return (
        <StyledTreeSelectContainer>
            <StyledTreeSelect
                maxTagCount={0}
                showSearch={false}
                treeCheckable={true}
                {...ref}
                {...props}
                maxTagPlaceholder={`${props.maxTagPlaceholder} (${props.value?.length})`}
            />
            <StyledIcon disabled={props.disabled} selected={props.value?.length > 0} component={DropDown} />
        </StyledTreeSelectContainer>
    )
})
