import {configureStore, AnyAction, ThunkDispatch} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {setupListeners} from '@reduxjs/toolkit/query'

import {isDevelopment} from 'ca-common/utils/runtime'
import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import {newAppRootReducer} from './newAppRootReducer'

export const store = configureStore({
    reducer: newAppRootReducer,
    devTools: isDevelopment,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({serializableCheck: false}).concat(rtkQueryBaseApi.middleware)

        return middlewares
    }
})

setupListeners(store.dispatch)

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
