import {useEffect, useRef} from 'react'
import {throttle} from 'lodash'

import {THROTTLE_TIMEOUT} from 'ca-common/constants'

const ANT_TABLE_BODY_CLASSNAME = 'ant-table-body'

export const useInfinityScrollForTable = (onEndReached: () => void, antTableClassName = ANT_TABLE_BODY_CLASSNAME) => {
    const tableRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const onScroll = (e: Event) => {
            const target = e.target as HTMLDivElement

            if (target) {
                const preventHeight = target.clientHeight / 2

                if (target.scrollTop + target.offsetHeight + preventHeight >= target.scrollHeight) {
                    onEndReached()
                }
            }
        }
        const throttledOnScroll = throttle(onScroll, THROTTLE_TIMEOUT)

        const tbody = tableRef.current?.querySelector(`.${antTableClassName}`)
        tbody?.addEventListener('scroll', throttledOnScroll)

        return () => {
            tbody?.removeEventListener('scroll', throttledOnScroll)
        }
    }, [onEndReached])

    return tableRef
}
