import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledBackupSettings = styled.div`
    margin-bottom: ${variables.gutterMd};
`

export const BackupSettingsForm = styled(Form)`
    padding: ${variables.gutterMd} ${variables.gutterMd} 40px;
    border-top: 1px solid ${variables.blue};
`
