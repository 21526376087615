import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CloseOutlined} from '@ant-design/icons'

import {
    StyledAlert,
    StyledLink,
    StyledTitle,
    StyledDate,
    StyledMessage,
    StyledReminderIcon,
    StyledWarningIcon,
    StyledLinkContainer,
    StyledRightContainer,
    StyledAlertButton,
    StyledTooltipIcon,
    StyledTooltip
} from './StyledAlert'

export type AlertType = 'ERROR' | 'INFO' | 'WARNING'
export type AlertProps = {
    title: string
    message: string
    type?: AlertType
    link?: {
        name: string
        url: string
    }
    updated?: string
    tooltipMessage?: string
    onClose?: () => void
    className?: string
}

export const Alert = ({
    title,
    message,
    link,
    updated,
    type = 'ERROR',
    tooltipMessage,
    onClose,
    className
}: AlertProps) => {
    const [visible, setVisible] = useState(true)
    const {t} = useTranslation()
    const handleOnClose = () => {
        setVisible(false)
        if (onClose) onClose()
    }
    return visible ? (
        <StyledAlert type={type} className={className}>
            {type === 'ERROR' || type === 'WARNING' ? <StyledWarningIcon /> : <StyledReminderIcon />}
            <StyledTitle type={type}>{title}:</StyledTitle>
            <StyledMessage>{message}</StyledMessage>
            {link && (
                <StyledLinkContainer>
                    <StyledLink to={link.url}>{link.name}</StyledLink>
                </StyledLinkContainer>
            )}
            <StyledRightContainer>
                {updated && (
                    <>
                        {tooltipMessage && (
                            <StyledTooltip
                                title={tooltipMessage}
                                getPopupContainer={triggerNode => triggerNode.parentElement!}
                            >
                                <StyledTooltipIcon />
                            </StyledTooltip>
                        )}
                        <StyledDate>
                            {t('common:uiLib:alert:date')}
                            {updated}
                        </StyledDate>
                    </>
                )}
                {type === 'INFO' && (
                    <StyledAlertButton type="text" size="small" icon={<CloseOutlined />} onClick={handleOnClose} />
                )}
            </StyledRightContainer>
        </StyledAlert>
    ) : null
}
