import {getLessData, post, postLessData} from 'src/newcore/utils/rest'
import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import type {
    ActivateBackupsPayload,
    CreateMSTasksResponse,
    CreateOCATasksResponse,
    GetTotalEntitiesResponse,
    PreparedCreateOCATasksResponse,
    PrepareMsTasksPayload,
    RecentlyDeletedTask
} from 'src/newcore/features/OneClickActivation/types'

export const oneClickActivationWizardApi = rtkQueryBaseApi.injectEndpoints({
    endpoints: build => ({
        getMSTotalEntities: build.query<GetTotalEntitiesResponse['data']['data'], string>({
            query: id =>
                ({
                    url: 'getMSTotalEntities',
                    method: getLessData,
                    data: {id}
                } as any),
            transformResponse: (res: GetTotalEntitiesResponse) => {
                return res.data?.data
            }
        }),
        activateBackups: build.mutation<void, ActivateBackupsPayload>({
            query: data => ({
                url: 'activateBackups',
                method: post,
                data
            })
        }),
        createMSTasks: build.mutation<string, PrepareMsTasksPayload>({
            query: data => ({
                url: 'createMSTasks',
                method: postLessData,
                data
            }),
            transformResponse: (res: CreateMSTasksResponse) => {
                return res.data.redirectUrl
            }
        }),
        createOCATasks: build.query<PreparedCreateOCATasksResponse, string>({
            query: stateId => ({
                url: 'createOCATasks',
                method: postLessData,
                data: {
                    stateId
                }
            }),
            transformResponse: (res: CreateOCATasksResponse) => {
                const data = res.data
                const recentlyDeletedTasks = [] as RecentlyDeletedTask[]
                const existedTasks = [] as PreparedCreateOCATasksResponse['existedTasks']
                let availableSources = data.enabledServices

                data.existedTasks?.forEach(task => {
                    if (task.isRecentlyDeleted) {
                        recentlyDeletedTasks.push({
                            id: task.id,
                            source: task.source,
                            totalItems: task.totalTaskEntities
                        })
                    } else {
                        existedTasks.push(task.id)
                        availableSources = availableSources.filter(source => source !== task.source)
                    }
                })

                return {
                    ...data,
                    recentlyDeletedTasks,
                    existedTasks,
                    availableSources,
                    email: data.account
                }
            }
        })
    })
})

export const {
    useGetMSTotalEntitiesQuery,
    useActivateBackupsMutation,
    useCreateMSTasksMutation,
    useCreateOCATasksQuery
} = oneClickActivationWizardApi
