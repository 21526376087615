import React from 'react'
import {useTranslation} from 'react-i18next'

import BackArrow from 'ca-common/icons/BackArrow.svg'

import {BackButtonLink, StyledCoreLink, StyledIcon} from './StyledZixBackButton'

type ZixBackButtonProps = {
    url: string
    isArchiveLocation?: boolean
}

export const ZixBackButton = ({url, isArchiveLocation = false}: ZixBackButtonProps): JSX.Element => {
    const {t} = useTranslation()

    const LinkComponent = isArchiveLocation ? StyledCoreLink : BackButtonLink

    return (
        <LinkComponent type="link" size="large" href={url}>
            <StyledIcon component={BackArrow} />
            {t('header:menu:toZix')}
        </LinkComponent>
    )
}
