import React from 'react'
import {Tooltip} from 'antd'

import Info from 'ca-common/icons/Info.svg'

import {StyledTextWithInfo, StyledIcon} from './StyledTextWithInfo'

type TextWithInfoProps = {
    title: string | JSX.Element
    tooltipText: string
}

export const TextWithInfo = (props: TextWithInfoProps) => {
    const {tooltipText, title} = props

    return (
        <StyledTextWithInfo>
            <span>{title}</span>
            <Tooltip title={tooltipText} overlayInnerStyle={{width: '300px'}}>
                <StyledIcon component={Info} />
            </Tooltip>
        </StyledTextWithInfo>
    )
}
