import styled from 'styled-components'
import variables from 'ca-common/variables'
import {BlockTitle} from 'ca-common/ui-lib/atoms/Typography'
import {SourceName as SourceNameRaw, TaskNameWrapper as TaskNameWrapperRaw} from '../../atoms/TaskView'

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
`
export const IconBorder = styled.div`
    border: 1px solid ${variables.blueLight};
    border-radius: 4px;
    padding: 4px;
    margin-right: ${variables.gutterXs};
`
export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

export const TaskTitle = styled(BlockTitle)`
    margin: 0;
    color: ${variables.navyBlue};
    text-overflow: ellipsis;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
`
export const SourceAndStatusWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const TaskNameWrapper = styled(TaskNameWrapperRaw)`
    width: fit-content;
`

export const SourceName = styled(SourceNameRaw)`
    max-width: 200px;
`
