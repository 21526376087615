import React from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router'

import {TODO_ANY} from 'ca-common/types'
import {MessageWrapper} from './StyledError'
import {Button} from 'ca-common/ui-lib/atoms'
import {getUrlParams} from 'ca-common/utils/url'

const ErrorRaw = (): JSX.Element => {
    const history = useHistory()
    const queryParams = getUrlParams(history.location.search)
    const {status, title} = queryParams

    const onClick = () => {
        history.goBack()
    }

    return (
        <MessageWrapper>
            <h3>{title || 'Error'}</h3>
            <div>
                {status || (
                    <>
                        You don’t have permission to view this page. <br />
                        Please contact your administrator.
                    </>
                )}
            </div>
            <Button onClick={onClick}>Back</Button>
        </MessageWrapper>
    )
}

const mapStateToProps = (state: TODO_ANY) => ({
    companyInfo: state.companyInfo
})

export const Error = connect(mapStateToProps)(ErrorRaw)
