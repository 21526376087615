import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const StyledText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
`

export const NoData = () => {
    const {t} = useTranslation()

    return <StyledText>{t('backups:bulkActivation:noData')}</StyledText>
}
