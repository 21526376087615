import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'

import type {Id} from 'ca-common/types'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

export type CalendarPayload = {
    taskId: Id
    accountId: Id
    extid: Id
    year: number
    month: number
}

const GET_CALENDAR_RECOVERY = 'GET_CALENDAR_RECOVERY'
const GET_CALENDAR_RECOVERY_API = 'getSnapshotsCalendar'
const getCalendarWrapper = fetchWrapperRT(
    GET_CALENDAR_RECOVERY,
    async data => await post(GET_CALENDAR_RECOVERY_API, data)
)
export const getCalendar = getCalendarWrapper.fetcher

const calendarSlice = createSlice({
    name: 'calendar',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearCalendar: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getCalendarWrapper.makeReducers(builder)
})

export const {clearCalendar} = calendarSlice.actions
export const calendar = calendarSlice.reducer
