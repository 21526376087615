import {createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_ENTITY_SUBSOURCES = 'GET_ENTITY_SUBSOURCES'
const GET_ENTITY_SUBSOURCES_API = 'getBackupEntitySourceInfo'
const getEntitySubSourcesWrapper = fetchWrapperRT(
    GET_ENTITY_SUBSOURCES,
    async data => await post(GET_ENTITY_SUBSOURCES_API, data)
)

export const getEntitySubSources = getEntitySubSourcesWrapper.fetcher

const entitySubSourcesSlice = createSlice({
    name: 'entitySubSources',
    initialState: InitFetchedState as FetchedState,
    reducers: {
        clearEntitySubSources: () => InitFetchedState as FetchedState
    },
    extraReducers: builder => getEntitySubSourcesWrapper.makeReducers(builder)
})

export const {clearEntitySubSources} = entitySubSourcesSlice.actions
export const entitySubSources = entitySubSourcesSlice.reducer
