import _ from 'lodash'
import React from 'react'
import {Checkbox, Spin} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'

import {ObjectCheckboxWrapper, ObjectsListWrapper} from './Styled'
import {getSourceObjectsList, toggleSourceObject} from 'src/newcore/redux/modules/backups/seeding/sourceObjectsList'

class EntitiesRaw extends React.Component {
    componentDidMount() {
        const {actions, date, dataType} = this.props

        actions.getSourceObjectsList({
            taskId: this.props.match.params.taskId,
            backupDate: date,
            type: dataType
        })
    }

    render() {
        const {objectsList, actions, search} = this.props

        const data = _.get(objectsList, 'response.data', [])

        return (
            <ObjectsListWrapper>
                <Spin spinning={objectsList.status === 'pending'}>
                    {_.map(
                        data,
                        (object, name) =>
                            _.includes(name.toLowerCase(), search.toLowerCase()) && (
                                <ObjectCheckboxWrapper key={name}>
                                    <Checkbox checked={object} onClick={() => actions.toggleSourceObject(name)}>
                                        {name}
                                    </Checkbox>
                                </ObjectCheckboxWrapper>
                            )
                    )}
                </Spin>
            </ObjectsListWrapper>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getSourceObjectsList,
            toggleSourceObject
        },
        dispatch
    )
})

const mapStateToProps = state => ({
    objectsList: state.backups.seeding.sourceObjectsList,
    date: _.get(state, 'wizard.data.date')
})

export const ObjectsList = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesRaw))
