import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'

import {SOURCE, RECOVERY_TYPE} from 'ca-common/common/enum'
import {DAY_MONTH_FORMAT, getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'

import {ExactBackup} from 'src/newcore/molecules/ExactBackup'
import {ScrollWrapper, TilesWrapper} from './StyledSelectedDayInfo'

const filteredSnapshotsByDate = (snapshots: Record<string, any>[], backupDate: number) => {
    if (!snapshots?.length) return []
    return snapshots
        .map((obj: any) => ({...obj, dateTime: getLocalDateFromUTC(obj.dateTime)}))
        .filter(
            (obj: any) =>
                moment(obj.dateTime, LONG_DATE_FORMAT).format(DAY_MONTH_FORMAT) === String(backupDate).slice(0, 8)
        )
        .sort((a, b) => a.dateTime - b.dateTime)
}

type SelectedDayInfoProps = {
    setBackupDate: React.Dispatch<React.SetStateAction<number>>
    backupDate: number
    taskId: string
    snapshots?: any
    fullRecoveryOptions?: RECOVERY_TYPE
    source: SOURCE
    setIsValidDate?: (arg0: boolean) => void
}

export const SelectedDayInfoRaw = ({
    backupDate,
    setBackupDate,
    taskId,
    snapshots,
    fullRecoveryOptions,
    source,
    setIsValidDate
}: SelectedDayInfoProps): JSX.Element => {
    const [backups, setBackups] = useState([] as any)

    useEffect(() => {
        if (!backupDate || !snapshots || (!snapshots[taskId] && !snapshots.response)) return setBackups([])
        const snapshotsByDate = filteredSnapshotsByDate(snapshots[taskId] || snapshots.response, backupDate)
        setBackups(snapshotsByDate)
        if (snapshotsByDate.length === 1 && backupDate !== snapshotsByDate[0].dateTime) {
            setBackupDate(Number(snapshotsByDate[0].dateTime))
        }
    }, [backupDate, snapshots])

    useEffect(() => {
        if (setIsValidDate) {
            const dateTimeArray: Array<number> = []
            backups.forEach((backup: Record<string, any>) => dateTimeArray.push(Number(backup.dateTime)))
            setIsValidDate(dateTimeArray.some((date: number) => date === backupDate))
        }
    }, [backups, backupDate])

    return (
        <ScrollWrapper>
            <TilesWrapper>
                {backups.map((backup: Record<string, any>) => {
                    return (
                        backup.succeed && (
                            <ExactBackup
                                backupDate={backupDate}
                                setBackupDate={setBackupDate}
                                key={backup.dateTime}
                                date={backup.dateTime}
                                subsources={backup.subsSourceResponseList}
                                status={backup.status}
                                fullRecoveryOptions={fullRecoveryOptions}
                                source={source}
                                retention={backup.retention}
                            />
                        )
                    )
                })}
            </TilesWrapper>
        </ScrollWrapper>
    )
}

const mapStateToProps = (state: any) => ({
    snapshots: state.calendar
})

const mapStateToPropsRecovery = ({recoveryNew}: any) => ({
    snapshots: recoveryNew.calendar
})

export const SelectedDayInfo = connect(mapStateToProps, null)(SelectedDayInfoRaw)
export const SelectedDayInfoRecovery = connect(mapStateToPropsRecovery, null)(SelectedDayInfoRaw)
