import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Checkbox} from 'antd'

export const InfiniteContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 270px;
    padding-left: ${variables.gutterMd};
`

export const StyledGroup = styled.div`
    margin-bottom: ${variables.gutterMd};
`

export const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: center;
    > span {
        top: 0;
    }
    > span:last-child {
        display: flex;
        margin-bottom: 2px;
    }
`
