import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {reduxForm} from 'redux-form'
import {withTranslation} from 'react-i18next'

import {FORMS, ACCESS_LEVEL} from 'ca-common/constants'
import {CAFormPage, CAFormPageFooter} from 'ca-common/ui-lib/components/FormPage'
import CAButton from 'ca-common/ui-lib/components/Button'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'
import {CARow, CACol} from 'ca-common/ui-lib/components/Grid'
import {CheckboxTree} from 'ca-common/ui-lib/components/CheckboxTree'
import {UserDetail, TasksList} from 'ca-common/ui-lib/components/UserManagement'

@withTranslation('userManagement')
class UserForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        isNew: PropTypes.bool,
        isFinra: PropTypes.bool
    }

    static defaultProps = {
        isNew: true,
        isFinra: false
    }

    render = () => {
        const {
            t,
            handleSubmit,
            pristine,
            submitting,
            invalid,
            isNew,
            groups,
            reset,
            initialValues,
            isFinra,
            disableOAuth
        } = this.props

        return (
            <CAFormPage>
                <form onSubmit={handleSubmit}>
                    <Jumbotron style={{overflow: 'inherit'}}>
                        <UserDetail
                            disableOAuth={disableOAuth}
                            isNew={isNew}
                            form={FORMS.USER_MANAGEMENT}
                            handleRole={this.handleRole}
                        />
                    </Jumbotron>
                    <CARow>
                        <CACol>
                            <JumbotronToggle title={t('userManagement:operations')}>
                                <CheckboxTree data={groups.general} form={FORMS.USER_MANAGEMENT} prefix="permissions" />
                                <TasksList
                                    data={groups.tasks}
                                    form={FORMS.USER_MANAGEMENT}
                                    prefix="permissions"
                                    isFinra={isFinra}
                                    finraTooltip={t('finra:taskTooltip')}
                                />
                            </JumbotronToggle>
                        </CACol>
                        <CACol>
                            <JumbotronToggle title={t('userManagement:services')}>
                                <CheckboxTree
                                    data={groups.services}
                                    form={FORMS.USER_MANAGEMENT}
                                    prefix="permissions"
                                />
                            </JumbotronToggle>
                        </CACol>
                    </CARow>
                    <CAFormPageFooter>
                        {!initialValues.credentials.isSpecialPartnerAccount && (
                            <CAButton modifier={'primary'} disabled={invalid || pristine || submitting} type="submit">
                                {t('userManagement:actions:save')}
                            </CAButton>
                        )}
                        <CAButton modifier={'secondary'} disabled={submitting} onClick={reset} type="button">
                            {t('userManagement:actions:cancel')}
                        </CAButton>
                    </CAFormPageFooter>
                </form>
            </CAFormPage>
        )
    }

    handleRole = (_event, value, previous) => {
        const {groups, change} = this.props

        if (value !== previous) {
            const isFull = value === ACCESS_LEVEL.FULL

            Object.keys(groups).forEach(group => {
                groups[group].sub.forEach(permission => {
                    change(`permissions.${groups[group].label}.sub.${permission.name}`, isFull)
                })
            })
        }
    }
}

export const UserPageForm = reduxForm({form: FORMS.USER_MANAGEMENT, enableReinitialize: true})(UserForm)
