import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: ${variables.gutterMd};

    p {
        margin-bottom: 0;
    }
`

export const Body = styled.div`
    border-bottom: 1px solid ${variables.blue};
    padding: 0 ${variables.gutterMd};
`

export const Footer = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${variables.gutterMd};
`

export const WarningIcon = styled(Icon)`
    font-size: 24px;
    color: ${variables.orange};
    margin-right: ${variables.gutterMd};
`

export const RadioConfirmationRule = styled(RadioBlock)`
    font-weight: 600;

    && {
        font-size: 16px;
    }
`
