import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'

const PureUserBreadcrumb = ({name, match}) => <NavLink to={match.url}>{name}</NavLink>

const mapStateToProps = state => ({
    name:
        state.notifications.notificationReport.status === 'fulfilled' &&
        state.notifications.notificationReport.response.data.name
})

export const NotificationsBreadcrumb = connect(mapStateToProps)(PureUserBreadcrumb)
