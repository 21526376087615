import styled from 'styled-components'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledAWSFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ${variables.gutterLg};
`

export const Terms = styled.p`
    margin-bottom: ${variables.gutterSm};
`

export const StyledCAlink = styled(CALink)`
    color: ${variables.navyBlue};
`
