import React from 'react'
import {useTranslation} from 'react-i18next'

import {StyledHeader, HeaderTitle, ObjectName} from './StyledHeader'

export const CompareItemHeader = ({type, object}) => {
    const {t} = useTranslation()

    return (
        <StyledHeader>
            <HeaderTitle>
                {t('compareItem:title', [t(`compareItem:type:${type.toLowerCase()}`)])}
                {object && ` ${t('compareItem:object')}`}
                <ObjectName>{` -  ${object || t('compareSnapshot:allObjects')}`}</ObjectName>
            </HeaderTitle>
        </StyledHeader>
    )
}
