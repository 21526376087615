import React from 'react'
import {i18n} from 'ca-common/locales'
import {CARowWrapper, CACellWrapper} from 'ca-common/ui-lib/components/TableElements'

export const CARowEmpty = () => {
    return (
        <CARowWrapper>
            <CACellWrapper modifier={'center'}>{i18n.t('table:messages:common:noRecords')}</CACellWrapper>
        </CARowWrapper>
    )
}
