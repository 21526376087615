import React from 'react'
import {Modal} from 'antd'

export class BackupTagsModal extends React.Component {
    static width = 475

    render() {
        const {children, ...rest} = this.props

        return (
            <Modal {...rest} width={BackupTagsModal.width} closable={false} footer={null} bodyStyle={{padding: 0}}>
                {children}
            </Modal>
        )
    }
}
