import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {CACurrentPage} from 'ca-common/ui-lib/components/TableElements'
import {isEmpty} from 'ca-common/utils/form'

export class CurrentPage extends Component {
    state = {
        currentPage: this.props.items.page || 1
    }

    static propTypes = {
        navigatePage: PropTypes.func.isRequired,
        items: PropTypes.shape({
            page: PropTypes.number
        }).isRequired
    }

    render = () => {
        return (
            <CACurrentPage
                type="text"
                value={this.state.currentPage}
                onChange={this.changeCurrentPage}
                onKeyPress={this.handlePressEnter}
            />
        )
    }

    // changeCurrentPage = event => {
    changeCurrentPage = () => {
        // const newPage = +event.target.value
        // if (!isNaN(newPage) && this.isPageChanged(newPage) && this.isPageOnRange(newPage)) {
        //     this.setState({currentPage: newPage})
        // } //@TODO error with infinite loop of setstate
    }

    handlePressEnter = event => {
        const {currentPage} = this.state
        const {items, navigatePage} = this.props

        if (event.key === 'Enter' && !isEmpty(currentPage) && this.isPageChanged(items.page)) {
            navigatePage(currentPage)
        }
    }

    isPageOnRange = page => page >= 1 && page <= this.props.items.totalPages

    isPageChanged = page => page !== this.state.currentPage
}
