import styled from 'styled-components'

import variables from 'ca-common/variables'

export const CardSecureFields = styled.div`
    height: 32px;
    width: 100%;
    border: 1px solid ${variables.old.gray};
    overflow: hidden;
`
