import React from 'react'
import {Skeleton} from 'antd'

import {StyledSection, StyledTitle, StyledText} from './StyledRecoveryHeaderSection'

type InfoSectionProps = {
    loading: boolean
    title: string
    children: JSX.Element | string
}

export const RecoveryHeaderSection = ({loading, title, children}: InfoSectionProps): JSX.Element => {
    let sectionContent
    switch (typeof children) {
        case 'string':
            sectionContent = <StyledText data-hj-suppress>{children}</StyledText>
            break
        default:
            sectionContent = children
    }
    return (
        <StyledSection>
            <StyledTitle>{title}</StyledTitle>
            <Skeleton active title={{style: {margin: '0'}}} paragraph={false} loading={loading}>
                {sectionContent}
            </Skeleton>
        </StyledSection>
    )
}
