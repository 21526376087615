import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import './FormPage.scss'

export const dumbFormPage = dumbBem('ca-form-page')
export const CAFormPage = tx(dumbFormPage)('div')
export const CAFormPageSection = tx([{element: 'section'}, dumbFormPage])('div')
export const CAFormPageSectionTitle = tx([{element: 'section-title'}, dumbFormPage])('h3')
export const CAFormPageFooter = tx([{element: 'footer'}, dumbFormPage])('div')
export const CAFormPageError = tx([{element: 'error'}, dumbFormPage])('div')
