import {i18n} from 'src/locales'

import {ALERT_LEVEL_ALL, ALERT_LEVEL_OBJECT} from 'src/newcore/features/SmartAlerts/lib'

export const getObjectFriendly = (alertLevel, specificObject) => {
    switch (alertLevel) {
        case ALERT_LEVEL_ALL:
            return i18n.t('smartAlerts:alertLevel:all')

        case ALERT_LEVEL_OBJECT:
            return specificObject

        default:
            return ''
    }
}
