import React from 'react'
import {map} from 'lodash'

import {SocialIcon} from 'ca-common/components/SocialIcon'
import {SOCIAL_TYPES} from 'ca-common/common/enum'
import {SOCIAL_NETWORK_LINKS} from 'ca-common/constants'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

import {StyledFooterSocialLinks} from './StyledFooterSocialLinks'

const SocialLink = ({type, to}: {type: SOCIAL_TYPES; to: string}) => {
    return (
        <CALink to={to} pureLink target="_blank">
            <SocialIcon type={type} />
        </CALink>
    )
}

export const FooterSocialLinks = ({socialNetworks}: {socialNetworks: typeof SOCIAL_NETWORK_LINKS}): JSX.Element => {
    return (
        <StyledFooterSocialLinks>
            {map(socialNetworks, (link, type) => {
                return <SocialLink to={link} type={type as SOCIAL_TYPES} key={type} />
            })}
        </StyledFooterSocialLinks>
    )
}
