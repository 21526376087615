import React, {useState} from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import Delete from 'ca-common/icons/backupAction/Delete.svg'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'

import {ActionDeleteConfirmation} from 'src/newcore/molecules/ActionDeleteConfirmation'

import {StyledDeleteAction, TooltipWrapper} from '../StyledBackupActions'
import {AppState} from 'src/newcore/components/Root'

type BackupDeleteRawProps = {
    handlePerformBackupTaskAction: (arg0: TASK_ACTION) => void
    disabled?: boolean
    name: string
    account: string
} & ReturnType<typeof mapStateToProps>

const BackupDeleteRaw = ({handlePerformBackupTaskAction, disabled, userInfo, name, account}: BackupDeleteRawProps) => {
    if (userInfo.status !== 'fulfilled') {
        return <></>
    }

    const [showConfirmation, setShowConfirmation] = useState(false)
    const {t} = useTranslation()

    const hasEnabledFinra = userInfo.response.isFinra

    return (
        <>
            <Tooltip title={t(hasEnabledFinra ? 'finra:taskTooltip' : `backups:newActions:${TASK_ACTION.DELETE}`)}>
                <TooltipWrapper disabled={Boolean(hasEnabledFinra || disabled)}>
                    <StyledDeleteAction
                        component={Delete}
                        disabled={hasEnabledFinra || disabled}
                        onClick={() => setShowConfirmation(true)}
                    />
                </TooltipWrapper>
            </Tooltip>
            <ActionDeleteConfirmation
                onDelete={() => {
                    setShowConfirmation(false)
                    handlePerformBackupTaskAction(TASK_ACTION.DELETE)
                }}
                onCancel={() => setShowConfirmation(false)}
                visible={showConfirmation}
                name={name}
                account={account}
            />
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    userInfo: state.userInfo
})

export const BackupDelete = connect(mapStateToProps)(BackupDeleteRaw)
