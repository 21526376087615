export enum RESTRICTION_TABLE_ACTIONS {
    EDIT = 'EDIT',
    DELETE = 'DELETE'
}

export enum RESTRICTION_RULES {
    LIST = 'LIST',
    RANGE = 'RANGE',
    CIDR = 'CIDR'
}

export enum IP_VERSION {
    IPv4 = 'V4',
    IPv6 = 'V6'
}

export enum FORM_FIELDS {
    TYPE = 'type',
    FROM = 'from',
    TO = 'to',
    ADDRESSES = 'addresses',
    DESCRIPTION = 'description',
    ADDRESS = 'address',
    CIDR = 'cidr',
    VERSION = 'version'
}

export const GET_IP_RULES = 'GET_IP_RULES'
export const ADDRESS_REGEXP = /^\d+\.\d+\.\d+\.\d+$/
export const BOUND_FIELDS = [FORM_FIELDS.FROM, FORM_FIELDS.TO]

export const MAX_IP_RULES_ALLOWED = 100
