import React from 'react'

import {SOURCE} from 'ca-common/common/enum'

import {ProductLogo} from 'src/newcore/features/SignUp/atoms/ProductLogo'

import {ServiceWrapper, StyledSecuredCloudBackup} from './StyledSecureCloudBackup'

const SERVICES = [SOURCE.MS365, SOURCE.SFC, SOURCE.GOOGLEAPPS, SOURCE.BOX, SOURCE.DROPBOX]

type SecureCloudBackupProps = {
    serviceWhitelist: SOURCE[]
}

export const SecureCloudBackup = ({serviceWhitelist}: SecureCloudBackupProps) => {
    return (
        <StyledSecuredCloudBackup>
            <ServiceWrapper>
                {SERVICES.filter(item => serviceWhitelist.includes(item)).map(product => (
                    <ProductLogo key={product} product={product} />
                ))}
            </ServiceWrapper>
        </StyledSecuredCloudBackup>
    )
}
