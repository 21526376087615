import React from 'react'
import {map, get} from 'lodash'

import {Wrapper, Header, Content} from './StyledCustomTooltip'
import {Row} from './Row'

export const CustomTooltip = ({active, payload, label}) => {
    const count = get(payload, '[0].payload.count', 0)

    if (active && count > 0) {
        const sources = get(payload, '[0].payload.sources', {})

        return (
            <Wrapper>
                <Header>{label}</Header>
                <Content>
                    {map(sources, (value, source) => (
                        <Row key={source} value={value} source={source} />
                    ))}
                </Content>
            </Wrapper>
        )
    }

    return null
}
