import styled from 'styled-components'
import {WarningOutlined} from '@ant-design/icons'
import variables from 'ca-common/variables'

export const ModalTitle = styled.div`
    font-family: ${variables.accentFont};
    font-size: ${variables.headingFontSize};
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0;
    text-align: left;
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: ${variables.gutterXs};
    padding: ${variables.gutterLg};
`

export const StyledWarningIcon = styled(WarningOutlined)`
    color: ${variables.orange};
    font-size: 24px;
`
