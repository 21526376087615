import {Dispatch, SetStateAction, useMemo, useState} from 'react'
import filter from 'lodash/filter'
import some from 'lodash/some'
import includes from 'lodash/includes'

import {MSTaskEntityType} from 'ca-common/types'

export const useSearchedData = (data: MSTaskEntityType[]): [MSTaskEntityType[], Dispatch<SetStateAction<string>>] => {
    const [query, setQuery] = useState('')
    const searchedData = useMemo(() => {
        return filter(data, item =>
            some(
                [item.entity, item.name],
                criteria => criteria && includes(criteria.toLowerCase(), query.toLowerCase())
            )
        )
    }, [data, query])
    return [searchedData, setQuery]
}
