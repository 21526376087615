import React from 'react'
import {useTranslation} from 'react-i18next'

import {ErrorContent, ErrorMessage, LandingHeader} from './StyledMSLanding'

export const MSLandingError = () => {
    const {t} = useTranslation()

    return (
        <ErrorContent>
            <LandingHeader>{t('MSLandingPage:errorLandingHeader')}</LandingHeader>
            <ErrorMessage>{t('MSLandingPage:errorMsg')}</ErrorMessage>
        </ErrorContent>
    )
}
