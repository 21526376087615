import styled, {css} from 'styled-components'

import variables from 'ca-common/variables'

export const StyledSelectedFilters = styled.div`
    margin-top: ${variables.gutterSm};

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;

            > * {
                pointer-events: none;
            }

            :hover {
                cursor: not-allowed;
            }
        `}
`
