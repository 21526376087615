import {createAction, createReducer} from '@reduxjs/toolkit'
import {TODO_ANY} from 'ca-common/types'

export const setSelectedOU = createAction<TODO_ANY[]>('SET_SELECTED_OU')

export const clearSelectedOU = createAction('CLEAR_SELECTED_OU')

export const selectedOUReducer = createReducer<TODO_ANY[]>([], builder => {
    builder.addCase(clearSelectedOU, () => [])
    builder.addCase(setSelectedOU, (state, action) => action.payload)
})
