import styled from 'styled-components'

import variables from 'ca-common/variables'

export const Body = styled.div`
    padding: ${variables.gutterLg} ${variables.gutterMd} ${variables.gutterMd};
    border-top: 1px solid ${variables.blue};
    display: flex;
    flex-direction: column;

    .ant-tabs-nav-list {
        width: 100%;
        gap: 2px;
    }

    .ant-tabs-tab {
        width: 25%;
        margin: 0 !important;
        border: 1px solid ${variables.blueLight} !important;
        border-bottom: none !important;
        border-radius: ${variables.borderRadiusBase} ${variables.borderRadiusBase} 0 0 !important;
        background-color: ${variables.blueLight} !important;
        justify-content: center;

        &.ant-tabs-tab-active {
            background-color: ${variables.white} !important;
        }
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
        visibility: visible;
        color: ${variables.navyBlue};
    }

    .ant-tabs-nav {
        margin: 0 !important;
    }

    .ant-tabs-content-holder {
        border: 1px solid ${variables.blueLight} !important;
        border-top: 0;
        padding: ${variables.gutterSm};
    }
`
