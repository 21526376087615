import React, {useEffect} from 'react'
import {Select, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import {values, every, debounce} from 'lodash'

import {StyledSelect} from '../../atoms'
import {RULES} from '../../lib'
import {
    EVENTS_DELETED,
    EVENTS_OPTIONS,
    EVENTS_UPDATED,
    UNIT_OPTIONS,
    UNIT_PERCENT
} from 'src/newcore/features/SmartAlerts/lib/constants'
import {getSmartAlertEventFriendly, getSmartAlertUnitFriendly} from 'src/newcore/features/SmartAlerts/lib'
import {StyledRuleWrapper, FormItem, StyledInputNumber} from './StyledRuleConfiguration'

const {Option} = Select

export const RuleConfiguration = ({
    config,
    validateStatus,
    triggeredBy,
    level,
    checkValues,
    setConfig,
    setError,
    currentStep
}) => {
    const [form] = Form.useForm()
    const {t} = useTranslation()
    const empty = every(values(form.getFieldsValue()), el => el === undefined)

    useEffect(() => {
        checkValues(form.getFieldsValue())
    }, [triggeredBy, level])

    useEffect(() => {
        !config && form.resetFields()
    }, [currentStep])

    const validatePercentage = (rule, value, callback) => {
        const amount = form.getFieldValue(RULES.AMOUNT)
        const unit = form.getFieldValue(RULES.UNIT)
        const event = form.getFieldValue(RULES.EVENT)

        if (unit === UNIT_PERCENT && (event === EVENTS_UPDATED || event === EVENTS_DELETED) && amount > 100) {
            callback(t('smartAlerts:wizard:ruleConfig:percentageError'))
        } else {
            callback()
        }
    }

    const validateAmount = (rule, value, callback) => {
        form.validateFields([RULES.AMOUNT], {force: true})
        callback()
    }

    return (
        <StyledRuleWrapper empty={empty}>
            <Form
                onValuesChange={debounce((changedValues, allValues) => {
                    checkValues(allValues)
                    setConfig(allValues)
                    setError(form.getFieldError(RULES.AMOUNT)?.length)
                }, 200)}
                form={form}
            >
                <div>{t('smartAlerts:wizard:ruleConfig:amount')}</div>
                <FormItem
                    hasFeedback
                    validateStatus={validateStatus}
                    name={RULES.AMOUNT}
                    initialValue={config && config[RULES.AMOUNT]}
                    rules={[
                        {
                            required: true,
                            message: t('forms:common:validation:shortRequired')
                        },
                        {
                            validator: validatePercentage
                        }
                    ]}
                >
                    <StyledInputNumber
                        formatter={number => String(number).replace(/[e.-]/, '')}
                        min={1}
                        placeholder="0"
                    />
                </FormItem>

                <div>{t('smartAlerts:wizard:ruleConfig:unit')}</div>
                <FormItem
                    name={RULES.UNIT}
                    initialValue={config && config[RULES.UNIT]}
                    rules={[
                        {
                            required: true,
                            message: t('forms:common:validation:shortRequired')
                        },
                        {
                            validator: validateAmount
                        }
                    ]}
                >
                    <StyledSelect
                        placeholder={t('smartAlerts:wizard:ruleConfig:unitPlaceholder')}
                        getPopupContainer={t => t.parentElement}
                    >
                        {UNIT_OPTIONS.map(value => (
                            <Option value={value} key={value}>
                                {getSmartAlertUnitFriendly(value)}
                            </Option>
                        ))}
                    </StyledSelect>
                </FormItem>

                <div>{t('smartAlerts:wizard:ruleConfig:event')}</div>
                <FormItem
                    name={RULES.EVENT}
                    initialValue={config && config[RULES.EVENT]}
                    rules={[
                        {
                            required: true,
                            message: t('forms:common:validation:shortRequired')
                        },
                        {
                            validator: validateAmount
                        }
                    ]}
                >
                    <StyledSelect
                        placeholder={t('smartAlerts:wizard:ruleConfig:eventPlaceholder')}
                        getPopupContainer={t => t.parentElement}
                    >
                        {EVENTS_OPTIONS.map(value => (
                            <Option value={value} key={value}>
                                {getSmartAlertEventFriendly(value)}
                            </Option>
                        ))}
                    </StyledSelect>
                </FormItem>
            </Form>
        </StyledRuleWrapper>
    )
}
