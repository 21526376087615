import styled from 'styled-components'
import {Dropdown} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const ActionsDropdownIcon = styled(Icon)`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
        background: ${variables.interaction};
        transition: background-color 0.3s ease-in-out;
    }
`
export const StyledDropdown = styled(Dropdown)`
    grid-area: dropdown;
`
