import React from 'react'
import styled from 'styled-components'
import {Popover} from 'antd'
import variables from 'ca-common/variables'

export const StyledTagsPopover = styled(({className, ...props}) => <Popover {...props} overlayClassName={className} />)`
    && {
        .ant-popover-inner-content {
            padding: ${variables.gutterSm};
            max-height: 408px;
            overflow: auto;
        }
    }
`

export const Content = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
`

export const ContentTag = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    span {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${variables.navyAsphalt};
    }

    :last-child span {
        margin: 0;
    }
`
