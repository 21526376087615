import styled from 'styled-components'
import variables from 'ca-common/variables'

export const AccountListWrapper = styled.div`
    width: 100%;
    padding: ${variables.gutterMd};
    height: 100%;
    overflow: auto;
`

export const AccountWrapper = styled.div`
    display: flex;
    cursor: pointer;
    margin: ${variables.gutterXs} 0;

    & > div {
        margin: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const AccountCheckbox = styled.div`
    width: 24px;
`

export const AccountName = styled.div`
    width: 200px;
`

export const AccountEmail = styled.div`
    width: 300px;
`

export const AccountField = styled.div`
    width: 100px;
`

export const AccountValues = styled.div`
    width: 200px;
`
