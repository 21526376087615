import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {RadioBlock} from 'ca-common/ui-lib/molecules/RadioBlock'

export const StyledCompare = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: auto;
    padding: ${variables.gutterMd};
    margin-bottom: ${variables.gutterLg};
`

export const StyledDivider = styled.div`
    min-height: 100%;
    width: 1px;
    background-color: ${variables.blue};
    margin: 0 48px;
`

export const Wrapper = styled.div`
    display: flex;
    width: 316px;
    flex-direction: column;

    ${RadioBlock}:last-child {
        margin-bottom: 0;
    }
`

export const InputGroupWrapper = styled.div``

export const ModalContent = styled.div`
    width: 540px;
    margin: ${variables.gutterMd} auto;
`

export const StyledIcon = styled(Icon)`
    color: ${variables.asphaltLight};
    margin-left: ${variables.gutterXs};
`

export const AccountName = styled.span`
    color: ${variables.navyBlue};
`
