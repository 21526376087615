import _ from 'lodash'
import {TODO_ANY} from 'ca-common/types'

export const mapValuesDeep = (obj: TODO_ANY, cb: TODO_ANY): TODO_ANY => {
    if (_.isArray(obj)) {
        return obj.map(innerObj => mapValuesDeep(innerObj, cb))
    } else if (_.isObject(obj)) {
        return _.mapValues(obj, val => mapValuesDeep(val, cb))
    }

    return cb(obj)
}

export const getObjKeyByValue = (object: TODO_ANY, value: TODO_ANY): TODO_ANY => {
    return _.findKey(object, objectItem => objectItem === value)
}
