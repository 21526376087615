import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSelectionWrapper = styled.section`
    max-width: 584px;
    margin: 0 auto;
    min-height: 409px;
`

export const StyledDescription = styled.span`
    font-size: ${variables.fontSizeSecondary};
`
