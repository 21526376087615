import styled from 'styled-components'
import {Form} from 'antd'

import variables from 'ca-common/variables'

export const StyledForm = styled(Form)`
    margin-top: ${variables.gutterMd};

    > .ant-btn + .ant-btn {
        margin-top: ${variables.gutterSm};
    }
`

export const StyledError = styled.div`
    color: ${variables.red};
    text-align: center;
    margin: -20px 0 ${variables.gutterXs} 0;
`

export const StyledButtonText = styled.span`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
`
