import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {ModalCenterContentWrapper} from 'ca-common/components/Modal'

export const ModalContentWrapper = styled(ModalCenterContentWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .ant-upload.ant-upload-drag {
        width: 538px;
        height: 138px;
    }
`
export const UploadText = styled.p`
    color: ${variables.navyBlue};
`

export const UploadHint = styled.p`
    color: ${variables.asphalt};
`

export const UploadFailedText = styled.p`
    color: ${variables.dark};
    margin-bottom: 32px;
`

export const UploadFailedFiles = styled.p`
    color: ${variables.asphalt};
    margin-bottom: 39px;
`

export const StyledSampleButton = styled(Button)`
    padding: 0;
`

export const StyledFailedIcon = styled(Icon)`
    font-size: 30px;
`

export const StyledFailedText = styled.div`
    color: ${variables.red};
`
