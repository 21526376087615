import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Tabs} from 'antd'

import {SOURCE} from 'ca-common/common/enum'

import {
    setWizardDescription,
    setWizardTitle,
    wizardConfigSelector,
    wizardDataSelector
} from 'src/newcore/redux/modules/wizard'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'

import type {OCAWizardConfig, OCAWizardData} from 'src/newcore/features/OneClickActivation/types'
import {useGetMSTotalEntitiesQuery} from 'src/newcore/features/OneClickActivation/redux'
import {useGetColumns, useEntitiesSelectionStep} from 'src/newcore/features/OneClickActivation/lib'
import {EntitiesSelectionTabContent, Footer} from 'src/newcore/features/OneClickActivation/molecules'

import {Body} from './StyledOCAEntitiesSelectionStep'

export const OCAEntitiesSelectionStep = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const getColumns = useGetColumns()
    const wizardData = useAppSelector(wizardDataSelector) as OCAWizardData
    const wizardConfig = useAppSelector(wizardConfigSelector) as OCAWizardConfig

    const {data: totalEntities = []} = useGetMSTotalEntitiesQuery(wizardData.id)
    const editableEntities = totalEntities.filter(
        ({source}) => !wizardData.tasksForReinstate?.find(task => task.source === source)
    )

    const {
        rowSelection,
        tableData,
        isLoading,
        onTabChange,
        service,
        onSearchChange,
        search,
        totalSelectedEntities,
        fetchMore
    } = useEntitiesSelectionStep({
        totalEntities: editableEntities,
        wizardData,
        wizardConfig
    })

    useEffect(() => {
        dispatch(setWizardTitle(t('oneClickActivation:wizard:titles:entitiesSelectionStep')))
        dispatch(setWizardDescription(wizardData.email))
    }, [])

    return (
        <div>
            <Body>
                <Tabs
                    type="card"
                    destroyInactiveTabPane
                    defaultActiveKey={service}
                    onChange={service => onTabChange(service as SOURCE)}
                >
                    {editableEntities.map(({source, totalItems}) => (
                        <Tabs.TabPane
                            tab={t(`oneClickActivation:wizard:confirmationStep:services:${source}`)}
                            key={source}
                        >
                            <EntitiesSelectionTabContent
                                service={source}
                                totalEntities={totalItems}
                                tableData={tableData}
                                columns={getColumns(source)}
                                rowSelection={rowSelection}
                                onSearchChange={onSearchChange}
                                isLoading={isLoading}
                                totalSelectedEntities={totalSelectedEntities}
                                fetchMore={fetchMore}
                                search={search}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Body>
            <Footer />
        </div>
    )
}
