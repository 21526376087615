import {createSlice} from '@reduxjs/toolkit'

import {post, postLessData} from 'src/newcore/utils/rest'
import {fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const SET_PASSWORD_EXPIRED = 'SET_PASSWORD_EXPIRED'
const passwordExpiredWrapper = fetchWrapperRT(
    SET_PASSWORD_EXPIRED,
    async data => await post('setPasswordExpired', data)
)
export const passwordExpired = passwordExpiredWrapper.fetcher

const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
const passwordWrapper = fetchWrapperRT(CHANGE_PASSWORD, async (data, {rejectWithValue}) => {
    try {
        return await post('changePassword', data)
    } catch (err) {
        return rejectWithValue(err.data)
    }
})
export const changePassword = passwordWrapper.fetcher

const TWO_FA_DISABLE = 'TWO_FA_DISABLE'
const disableTwoFaWrap = fetchWrapperRT(TWO_FA_DISABLE, async data => await post('twoFaDisable', data))
export const disableTwoFa = disableTwoFaWrap.fetcher

const TWO_FA_VERIFY = 'TWO_FA_VERIFY'
const verifyTwoFaWrap = fetchWrapperRT(TWO_FA_VERIFY, async (data, {rejectWithValue}) => {
    try {
        return await postLessData('twoFaSignIn', data)
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const verifyTwoFa = verifyTwoFaWrap.fetcher

const TWO_FA = 'TWO_FA'
const twoFaWrap = fetchWrapperRT(TWO_FA, async data => await post('twoFaGetBarcode', data))
export const fetchTwoFa = twoFaWrap.fetcher

const SET_ENFORCE_TWO_FA = 'SET_ENFORCE_TWO_FA'
const enforceTwoFaWrapper = fetchWrapperRT(SET_ENFORCE_TWO_FA, async data => await post('setEnforceTwoFACommand', data))
export const enforceTwoFa = enforceTwoFaWrapper.fetcher

const twoFaSlice = createSlice({
    name: 'twoFa',
    initialState: InitFetchedState,
    reducers: {
        clearTwoFa: () => InitFetchedState,
        updateTwoFa: (state, action) => {
            state.response.data = {
                ...state.response.data,
                ...action.payload
            }
        }
    },
    extraReducers: builder => twoFaWrap.makeReducers(builder)
})

export const {clearTwoFa, updateTwoFa} = twoFaSlice.actions
export const twoFa = twoFaSlice.reducer
