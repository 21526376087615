import React from 'react'
import {Skeleton} from 'antd'
import {useTranslation} from 'react-i18next'

import {PaymentStatus} from 'src/newcore/features/BillingStatus/lib/constants'

import {StyledTableTitle} from './StyledTableTitle'

type TableTitleProps = {
    paymentStatus: PaymentStatus
}

export const TableTitle = ({paymentStatus}: TableTitleProps) => {
    const {t} = useTranslation()

    return (
        <Skeleton
            loading={!paymentStatus}
            active
            paragraph={false}
            title={{width: 200, style: {height: '24px', margin: 0}}}
        >
            <StyledTableTitle
                isDanger={paymentStatus === PaymentStatus.OVERDUE || paymentStatus === PaymentStatus.UNPAID}
            >
                {t(`billing:tableTitle:${paymentStatus}`)}
            </StyledTableTitle>
        </Skeleton>
    )
}
