export enum CHECK_STATE {
    CHECKED = 'CHECKED',
    UNCHECKED = 'UNCHECKED',
    PARTIAL = 'PARTIAL'
}

export type DataWithChildren = {
    key: string
    title: string
    children?: Array<DataWithChildren>
}

export class Node {
    value: string
    title: string
    check: CHECK_STATE
    children: Array<Node> | null
    parent: Node | null
    connectedState: CHECK_STATE
    connectedEntity: boolean

    constructor({key, title}: DataWithChildren) {
        this.value = key
        this.title = title
        this.children = null
        this.parent = null
        this.check = CHECK_STATE.UNCHECKED
        this.connectedState = CHECK_STATE.UNCHECKED
        this.connectedEntity = true
    }

    addChild(el: DataWithChildren): Node {
        if (this.children === null) {
            this.children = []
        }
        const node = new Node(el)
        node.parent = this
        this.children.push(node)
        return node
    }

    getAllChildren(): Array<CHECK_STATE> {
        return this.children === null ? [] : this.children.map(el => el.check)
    }
}
