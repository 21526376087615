import React, {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {TableColumnsType, Tooltip} from 'antd'

import type {Notification, DeleteUsersNotification} from 'ca-common/features/NotificationsTable/types'
import {CheckCell, ColumnTitle} from 'ca-common/features/NotificationsTable/atoms'
import {ActionsCell} from 'ca-common/features/NotificationsTable/molecules'

const EMAIL_WIDTH = 240
const NAME_WIDTH = 180
const SUMMARY_REPORT_WIDTH = 160
const RECOVERY_WIDTH = 170
const EXCEPTION_WIDTH = 170
const STATUS_REPORT_WIDTH = 200
const SMART_ALERTS_WIDTH = 180
const ACTIONS_WIDTH = 100
const TRIAL_ACCOUNTS_REPORTS_WIDTH = 160
export type UseColumnsProps = {
    deleteUsersNotification: DeleteUsersNotification
    fetchUsersNotifications: () => Promise<void>
    isPartnerPortal?: boolean
}

export const useColumns = (props: UseColumnsProps): TableColumnsType<Notification> => {
    const {fetchUsersNotifications, deleteUsersNotification, isPartnerPortal} = props
    const {t} = useTranslation()

    return useMemo(
        () =>
            ([
                {
                    title: t('table:columns:email'),
                    width: EMAIL_WIDTH,
                    ellipsis: true,
                    render: (_, {email}) => <Tooltip title={email}>{email}</Tooltip>
                },
                {
                    title: t('table:columns:displayName'),
                    width: NAME_WIDTH,
                    ellipsis: true,
                    render: (_, {name}) => <Tooltip title={name}>{name}</Tooltip>
                },
                {
                    title: t('table:columns:summaryReport'),
                    width: SUMMARY_REPORT_WIDTH,
                    render: (_, {summaryReport}) => summaryReport && <CheckCell />
                },
                {
                    title: (
                        <ColumnTitle
                            title={<Trans i18nKey="table:columns:recovery" />}
                            tooltipText={t('notifications:recoveryHeaderTooltip')}
                        />
                    ),
                    width: RECOVERY_WIDTH,
                    render: (_, {recovery}) => recovery && <CheckCell />
                },
                {
                    title: (
                        <ColumnTitle
                            title={<Trans i18nKey="table:columns:exception" />}
                            tooltipText={t('notifications:exceptionHeaderTooltip')}
                        />
                    ),
                    width: EXCEPTION_WIDTH,
                    render: (_, {exception}) => exception && <CheckCell />
                },
                {
                    title: (
                        <ColumnTitle
                            title={<Trans i18nKey="table:columns:autoDiscoveryNotification" />}
                            tooltipText={t('notifications:autoDiscoveryHeaderTooltip')}
                        />
                    ),
                    width: STATUS_REPORT_WIDTH,
                    render: (_, {statusReport}) => statusReport && <CheckCell />
                },
                !isPartnerPortal && {
                    title: (
                        <ColumnTitle
                            title={t('table:columns:smartAlerts')}
                            tooltipText={t('notifications:smartAlertsHeaderTooltip')}
                        />
                    ),
                    width: SMART_ALERTS_WIDTH,
                    render: (_, {smartAlerts}) => smartAlerts && <CheckCell />
                },
                isPartnerPortal && {
                    title: (
                        <ColumnTitle
                            title={<Trans i18nKey="table:columns:trialAccountsReport" />}
                            tooltipText={t('notifications:trialAccountsReportTooltip')}
                        />
                    ),
                    width: TRIAL_ACCOUNTS_REPORTS_WIDTH,
                    render: (_, {trialAccountsReport}) => trialAccountsReport && <CheckCell />
                },
                {
                    title: t('table:columns:action'),
                    width: ACTIONS_WIDTH,
                    render: (_, {isDefaultRecipient, email, id}) =>
                        !isDefaultRecipient && (
                            <ActionsCell
                                email={email}
                                id={id}
                                deleteUsersNotification={deleteUsersNotification}
                                fetchUsersNotifications={fetchUsersNotifications}
                            />
                        )
                }
            ] as TableColumnsType<Notification>).filter(Boolean),
        []
    )
}
