import React from 'react'
import Icon from '@ant-design/icons'
import {Input, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {INTERVAL} from 'ca-common/constants'
import {VerticalFormItem, Label} from 'ca-common/ui-lib/components/FormItem'

export const RetentionPeriod = props => {
    const {t} = useTranslation()
    const {retentionType, retentionPeriod} = props

    return (
        <VerticalFormItem
            label={
                <Label>
                    <span>{t('forms:backupSettings:fields:retentionPeriod:label')}</span>
                    <Tooltip title={t('forms:backupSettings:fields:retentionPeriod:tooltip')}>
                        <Icon component={Info} />
                    </Tooltip>
                </Label>
            }
            colon={false}
        >
            <Input
                value={
                    retentionPeriod
                        ? t(`Interval:${retentionType}`, {count: +retentionPeriod})
                        : t(`Interval:${INTERVAL.UNLIMITED}`)
                }
                disabled
            />
        </VerticalFormItem>
    )
}
