import React from 'react'

import type {IconComponent} from 'ca-common/types'

import {StyledSuffixIcon, StyledSpinner} from './StyledSuffixIcon'

const Spinner = () => <StyledSpinner size="small" />

type SuffixIconProps = {
    loading?: boolean
    component: IconComponent
}

export const SuffixIcon: React.FC<SuffixIconProps> = ({component, loading}): JSX.Element => {
    if (loading) return <StyledSuffixIcon component={Spinner} />
    return <StyledSuffixIcon component={component} />
}
