import React, {useEffect, useMemo} from 'react'
import {Form, Spin, Tooltip} from 'antd'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import {useTranslation} from 'react-i18next'

import {MultiSelect} from 'ca-common/ui-lib/atoms/MultiSelect'
import {Select, Option} from 'ca-common/ui-lib/atoms/Select'
import SearchIcon from 'ca-common/icons/Search.svg'
import {DEBOUNCE_TIMEOUT} from 'ca-common/constants'
import {SourceIcon} from 'ca-common/components/SourceIcon'
import {siteFormatter} from 'ca-common/utils/entity'
import {END_USER_SERVICE} from 'ca-common/common/enum/Source'

import {getServiceName, isSupportedService, sortSourceInfoByCost} from 'src/newcore/utils/sources'
import {track} from 'src/newcore/utils/mixpanel'

import {
    JOB_FIELDS,
    SORT_BY_OPTIONS,
    getSortByFriendly,
    getJobStatusFriendly,
    JOB_STATUS_OPTIONS,
    ACTIVITY_OPTIONS,
    getActivityFriendly
} from 'src/newcore/features/Jobs/lib'
import {SelectedFilters} from 'src/newcore/features/Jobs/molecules'

import {StyledForm, StyledRow, StyledFormContainer} from './StyledFilters'
import {JobsProps} from 'src/newcore/features/Jobs/templates/Jobs'
import {ExpandResponse} from 'ca-common/utils/fetchWrapperRT'

const {Item: FormItem} = Form

type FiltersProps = {
    changeUrlParams: (params: any) => void
    values: Partial<Record<JOB_STATUS_OPTIONS, string | string[]>>
    loading: boolean
    isMsEndUser?: boolean
} & ExpandResponse<Pick<JobsProps, 'jobTasks' | 'jobItems' | 'jobInitiators' | 'jobServices'>>

export const Filters = (props: FiltersProps): JSX.Element => {
    const {values, loading, jobInitiators, jobTasks, jobItems, jobServices, isMsEndUser} = props
    const [form] = Form.useForm()
    const {setFieldsValue, getFieldValue, isFieldTouched} = form
    const {t} = useTranslation()

    const sortByOptions = useMemo(
        () =>
            SORT_BY_OPTIONS.map((i: string) => (
                <Option key={i} title={getSortByFriendly(i)} value={i}>
                    {getSortByFriendly(i)}
                </Option>
            )),
        []
    )

    const jobStatusOptions = useMemo(
        () =>
            JOB_STATUS_OPTIONS.map((i: string) => ({
                label: getJobStatusFriendly(i as JOB_STATUS_OPTIONS),
                value: i
            })),
        []
    )

    const jobActivityOptions = useMemo(
        () =>
            ACTIVITY_OPTIONS.map(i => ({
                label: getActivityFriendly(i),
                value: i
            })),
        []
    )

    const taskOptions = useMemo(() => {
        let allJobs, availableJobs
        allJobs = jobTasks?.map(({alias, id, source}) => ({
            title: alias,
            value: id,
            source,
            label: (
                <>
                    <SourceIcon fontSize={16} source={source} margin={'0 4px 0 0'} />
                    {alias}
                </>
            )
        }))
        availableJobs = isMsEndUser ? allJobs?.filter(data => END_USER_SERVICE.includes(data.source)) : allJobs
        return availableJobs
    }, [jobTasks])

    const itemOptions = useMemo(() => {
        const uniques = [...new Set(jobItems?.map(i => i?.entity))]

        return uniques?.map(i => ({label: i, value: i}))
    }, [jobItems])

    const initiatorOptions = useMemo(() => {
        return jobInitiators?.initiators?.map(({mail, name}) => ({label: name || mail, value: mail}))
    }, [jobInitiators])

    const sourceOptions = useMemo(() => {
        let allServices, availableServices
        allServices = jobServices
            ?.filter(isSupportedService)
            ?.sort(sortSourceInfoByCost)
            ?.map(i => ({value: i, label: getServiceName({source: i})}))
        availableServices = isMsEndUser
            ? allServices?.filter(data => END_USER_SERVICE.includes(data.value))
            : allServices
        return availableServices
    }, [jobServices])

    const selectedFilters = useMemo(
        () =>
            pick(values, [
                JOB_FIELDS.INITIATOR,
                JOB_FIELDS.ACTIVITY,
                JOB_FIELDS.SERVICE,
                JOB_FIELDS.STATUS,
                JOB_FIELDS.TASK_QUERY,
                JOB_FIELDS.ITEM_QUERY
            ]),
        [values]
    )

    const sendAnalytics = () => {
        track('Jobs Sort By Option Selected', {
            'Sort By': getSortByFriendly(getFieldValue(JOB_FIELDS.SORT_BY))
        })
    }

    useEffect(() => {
        isFieldTouched(JOB_FIELDS.SORT_BY) && sendAnalytics()
    }, [getFieldValue(JOB_FIELDS.SORT_BY)])

    const taskNotSelected = isEmpty(getFieldValue(JOB_FIELDS.TASK_QUERY))

    const resetItemsFields = () => setFieldsValue({[JOB_FIELDS.ITEM_QUERY]: []})

    useEffect(() => {
        setFieldsValue(values)
    }, [values])

    const onValuesChange = debounce(values => {
        const newPositiveValues = filter(values, i => !isEmpty(i))

        const hasNewFormValues = !isEqual(newPositiveValues, props.values)

        if (hasNewFormValues) {
            props.changeUrlParams(values)
        }
    }, DEBOUNCE_TIMEOUT)

    const onChangeFilters = (newValues: any) => {
        setFieldsValue(newValues)
        props.changeUrlParams(newValues)
    }

    return (
        <Spin spinning={loading} indicator={<></>}>
            <StyledFormContainer>
                <StyledForm layout="inline" form={form} onValuesChange={onValuesChange}>
                    <StyledRow>
                        {!isMsEndUser && (
                            <FormItem
                                name={JOB_FIELDS.INITIATOR}
                                data-inlinemanual="InlineManual-jobspage-filterInitiator"
                            >
                                <Select
                                    selectWidth={130}
                                    filterOption
                                    optionFilterProp="title"
                                    placeholder={t('jobs:filterFields:initiator:placeholder')}
                                    disabled={loading}
                                    loading={loading}
                                    placeholderWeight="bold"
                                    dropdownMatchSelectWidth={false}
                                >
                                    {initiatorOptions?.map(initiator => (
                                        <Option key={initiator.value} title={initiator.label} value={initiator.value}>
                                            {initiator.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        )}
                        <FormItem name={JOB_FIELDS.ACTIVITY} data-inlinemanual="InlineManual-jobspage-filterActivity">
                            <MultiSelect
                                buttonText={t('jobs:filterFields:activity:text')}
                                width={120}
                                popoverWidth={144}
                                options={jobActivityOptions}
                                disabled={loading}
                                borderWidth={2}
                            />
                        </FormItem>
                        <FormItem name={JOB_FIELDS.SERVICE} data-inlinemanual="InlineManual-jobspage-filterService">
                            <MultiSelect
                                buttonText={t('jobs:filterFields:service:text')}
                                width={120}
                                popoverWidth={240}
                                options={sourceOptions}
                                disabled={loading}
                                borderWidth={2}
                            />
                        </FormItem>
                        <FormItem name={JOB_FIELDS.STATUS} data-inlinemanual="InlineManual-jobspage-filterStatus">
                            <MultiSelect
                                buttonText={t('jobs:filterFields:status:text')}
                                width={120}
                                popoverWidth={200}
                                options={jobStatusOptions}
                                disabled={loading}
                                borderWidth={2}
                            />
                        </FormItem>
                    </StyledRow>
                    <StyledRow>
                        <StyledRow data-inlinemanual="InlineManual-jobspage-filterQuery">
                            <StyledRow>
                                <FormItem name={JOB_FIELDS.TASK_QUERY}>
                                    <Select
                                        selectWidth={206}
                                        filterOption
                                        optionFilterProp="title"
                                        placeholder={t('jobs:filterFields:task:placeholder')}
                                        prefixIcon={SearchIcon}
                                        disabled={loading}
                                        loading={loading}
                                        onChange={resetItemsFields}
                                        dropdownMatchSelectWidth={false}
                                        dropdownStyle={{maxWidth: '350px'}}
                                    >
                                        {taskOptions?.map(task => (
                                            <Option key={task.value} title={task.title} value={task.value}>
                                                {task.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </StyledRow>
                            {!isMsEndUser && (
                                <StyledRow>
                                    <FormItem name={JOB_FIELDS.ITEM_QUERY}>
                                        <Select
                                            selectWidth={206}
                                            filterOption
                                            optionFilterProp="title"
                                            placeholder={t('jobs:filterFields:item:placeholder')}
                                            prefixIcon={SearchIcon}
                                            dropdownMatchSelectWidth={false}
                                            dropdownStyle={{maxWidth: '350px'}}
                                            loading={loading}
                                            disabled={loading || taskNotSelected || itemOptions.length === 0}
                                        >
                                            {itemOptions?.map(item => (
                                                <Option key={item.value} title={item.label} value={item.value}>
                                                    <Tooltip title={item.label}>{siteFormatter(item.label)}</Tooltip>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </StyledRow>
                            )}
                        </StyledRow>
                        <FormItem name={JOB_FIELDS.SORT_BY}>
                            <Select
                                placeholder={t('jobs:filterFields:sortBy:text')}
                                allowClear={false}
                                showSearch={false}
                                selectWidth={130}
                                clear
                                disabled={loading}
                            >
                                {sortByOptions}
                            </Select>
                        </FormItem>
                    </StyledRow>
                </StyledForm>
                <SelectedFilters
                    setValues={onChangeFilters}
                    loading={loading}
                    values={selectedFilters}
                    jobTasks={jobTasks}
                />
            </StyledFormContainer>
        </Spin>
    )
}
