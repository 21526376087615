import React from 'react'
import {useTranslation} from 'react-i18next'

import {GLOBAL_LINKS, LINK_TYPES, LOCAL_LINKS} from 'ca-common/common/enum'
import PB_AWS_logo_RGB from './PB_AWS_logo_RGB.png'

import {StyledAWSFooter, Terms, StyledCAlink} from './StyledAWSFooter'

export const AWSFooter = ({isCloudally}: {isCloudally?: boolean}): JSX.Element => {
    const {t} = useTranslation()

    return (
        <StyledAWSFooter>
            <Terms>
                <StyledCAlink
                    to={isCloudally ? GLOBAL_LINKS[LINK_TYPES.TERMS] : LOCAL_LINKS[LINK_TYPES.TERMS]}
                    pureLink
                    target="_blank"
                >
                    {t(`footer:footerLinks:${LINK_TYPES.TERMS}`)}
                </StyledCAlink>
                {' and '}
                <StyledCAlink
                    to={isCloudally ? GLOBAL_LINKS[LINK_TYPES.PRIVACY] : LOCAL_LINKS[LINK_TYPES.PRIVACY]}
                    pureLink
                    target="_blank"
                >
                    {t(`footer:footerLinks:${LINK_TYPES.PRIVACY}`)}
                </StyledCAlink>
            </Terms>
            <img width={169} height={30} src={PB_AWS_logo_RGB} alt={t(`footer:aws`)} />
        </StyledAWSFooter>
    )
}
