import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const Title = styled.p`
    color: ${variables.navyAsphalt};
    margin: 0;
    text-align: right;
`
export const StyledIcon = styled(Icon)<{isWarningIcon?: boolean}>`
    margin-left: 4px;

    ${props =>
        props.isWarningIcon &&
        css`
            path {
                fill: ${variables.orange};
            }
        `}
`
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
