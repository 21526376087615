import {FORM_FIELDS, RESTRICTION_RULES} from 'ca-common/features/IpRestriction/lib'

export const prepareError = (responseData: Record<string, string>, values: Record<string, any>) => {
    const erroredKeys = Object.keys(responseData)

    return Object.keys(values).map(i => {
        if (
            erroredKeys.includes('securitySettings') &&
            i === FORM_FIELDS.ADDRESSES &&
            values.type === RESTRICTION_RULES.LIST
        ) {
            const errorText = responseData.securitySettings

            return Object.assign({}, {name: i, errors: [errorText]})
        }

        if (
            erroredKeys.some(j => ['securitySettings', 'range.from', 'range'].includes(j)) &&
            (i === FORM_FIELDS.FROM || i === FORM_FIELDS.TO) &&
            values.type === RESTRICTION_RULES.RANGE
        ) {
            const errorText = responseData.securitySettings || responseData['range.from'] || responseData['range']

            return Object.assign({}, {name: i, value: values[i], errors: [errorText]})
        }

        if (erroredKeys.includes('range.to') && i === FORM_FIELDS.TO && values.type === RESTRICTION_RULES.RANGE) {
            const errorText = responseData['range.to']

            return Object.assign({}, {name: i, value: values[i], errors: [errorText]})
        }

        if (erroredKeys.includes(i)) {
            const errorText = responseData[i]

            return Object.assign({}, {name: i, value: values[i], errors: [errorText]})
        }

        return Object.assign({}, {name: i, value: values[i]})
    })
}
