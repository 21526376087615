import {combineReducers, createSlice} from '@reduxjs/toolkit'

import {post} from 'src/newcore/utils/rest'
import {FetchedState, fetchWrapperRT, InitFetchedState} from 'ca-common/utils/fetchWrapperRT'

const GET_SFC_TABLES = 'GET_SFC_TABLES'

const getSfcTablesWrapper = fetchWrapperRT(GET_SFC_TABLES, async data => await post('getSfcTables', data))
export const getSfcTables = getSfcTablesWrapper.fetcher

const tablesSlice = createSlice({
    name: 'tables',
    initialState: InitFetchedState as FetchedState,
    reducers: {},
    extraReducers: builder => getSfcTablesWrapper.makeReducers(builder)
})

export const sfcReducer = combineReducers({
    tables: tablesSlice.reducer
})
