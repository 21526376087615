import React from 'react'
import {Route, RouteComponentProps, Switch} from 'react-router'

import {NotFound} from 'ca-common/components/NotFound'
import {PAGES} from 'ca-common/constants'
import {PermissionRoute} from 'ca-common/components/PermissionRoute'

import {Activity} from 'src/newcore/pages/Activity'
import {AccountActivityPage} from 'src/newcore/components/AccountActivity/Pages'
import {SecurityAuditPage} from 'src/newcore/features/SecurityAudit'

export const ActivityRouting = ({match}: RouteComponentProps): JSX.Element => {
    return (
        <Switch>
            <Route path={`${match.url}/`} exact component={AccountActivityPage} />
            <PermissionRoute
                path={`${match.url}/security`}
                checkPage={PAGES.ACCOUNT_ACTIVITY}
                exact
                component={SecurityAuditPage}
            />
            <PermissionRoute
                path={`${match.url}/system`}
                checkPage={PAGES.SYSTEM_ACTIVITY}
                exact
                component={Activity}
            />
            <Route component={NotFound} />
        </Switch>
    )
}
