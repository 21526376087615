import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'

import {getAllowedServices} from 'src/newcore/utils/sources'

import {AddBackup} from 'src/newcore/features/AddBackup'
import {callReload} from 'src/newcore/utils/logout'
import {NewHome} from '../NewHomePage/NewHomepage'

class AvailableSourceListRaw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            backupAuthVisible: false,
            pickedSource: ''
        }
    }

    toggleVisible = () => {
        this.setState(state => ({
            backupAuthVisible: !state.backupAuthVisible
        }))
    }

    render() {
        const {backupAuthVisible, pickedSource} = this.state
        const {permissions, isMsEndUser} = this.props.userInfo.response

        isMsEndUser && callReload('', '?msgType=custom&modalName=AccessDenied')
        return (
            <Fragment>
                <NewHome sources={getAllowedServices(permissions)} onClickHandler={this.handleClick} isBackup={true} />
                <AddBackup source={pickedSource} visible={backupAuthVisible} toggleVisible={this.toggleVisible} />
            </Fragment>
        )
    }

    handleClick = source => () => {
        this.setState({backupAuthVisible: true, pickedSource: source})
    }
}

const mapStateToProps = state => ({
    userInfo: state.userInfo
})

export const AvailableSourceList = connect(mapStateToProps)(AvailableSourceListRaw)
