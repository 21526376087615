import _ from 'lodash'
import {createAction, createReducer} from '@reduxjs/toolkit'

const INITIAL_EXPANDED_ROWS = []

const SET_EXPANDED_ROW_KEYS = 'SET_EXPANDED_ROW_KEYS'
export const setExpandedRowKeys = createAction(SET_EXPANDED_ROW_KEYS)
const expandedRowKeysReducer = (state, action) =>
    !_.isEqual(state, action.payload) ? action.payload : INITIAL_EXPANDED_ROWS

const CLEAR_EXPANDED_ROW_KEYS = 'CLEAR_EXPANDED_ROW_KEYS'
export const clearExpandedRowKeys = createAction(CLEAR_EXPANDED_ROW_KEYS)
const clearExpandedRowKeysReducer = () => INITIAL_EXPANDED_ROWS

export const expandedRowKeys = createReducer(INITIAL_EXPANDED_ROWS, {
    SET_EXPANDED_ROW_KEYS: expandedRowKeysReducer,
    CLEAR_EXPANDED_ROW_KEYS: clearExpandedRowKeysReducer
})
