import React from 'react'
import {Trans} from 'react-i18next'
import {EMAIL_FORMAT, SubsourceFormat} from 'src/newcore/features/RecoveryWizard/lib/constants'

export const getFormatSettingsOptions = (alternativeFormat: SubsourceFormat) => {
    return [
        {
            label: (
                <Trans
                    i18nKey={`recovery:wizard:subServiceSelection:format:alternative`}
                    values={{alternativeFormat}}
                />
            ),
            value: alternativeFormat
        },
        {
            label: <Trans i18nKey={'recovery:wizard:subServiceSelection:format:PST'} />,
            value: EMAIL_FORMAT.PST
        }
    ]
}
