import styled from 'styled-components'
import {InputNumber, Form} from 'antd'

import variables from 'ca-common/variables'

const {Item} = Form

export const StyledRuleWrapper = styled.section`
    max-width: 344px;
    padding: 20px 20px 0;
    margin-bottom: ${variables.gutterSm};
    border: 1px solid ${props => (props.empty ? variables.navyBorder : variables.navyBlue)};
    background-color: ${props => (props.empty ? 'transparent' : variables.radioBlockBackground)};
    box-sizing: border-box;
    border-radius: ${variables.borderRadiusBase};
`

export const FormItem = styled(Item)`
    margin-bottom: 20px;

    .has-warning .ant-input {
        border: 1px solid ${variables.orange};
    }

    .has-warning.has-feedback .ant-form-item-children-icon {
        color: ${variables.orange};
    }

    .ant-input-number-suffix {
        margin-right: ${variables.gutterMd};
    }
`

export const StyledInputNumber = styled(InputNumber)`
    width: 100%;
`
