import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StartUsingContainer = styled.div`
    a {
        text-decoration: none;
        outline: none;

        &:hover,
        &:focus,
        &:visited,
        &:active {
            text-decoration: none;
            outline: none;
        }
    }

    .ca-tutorial {
        @include flex(0 1 25%);
        padding: 10px 10px 0;

        a {
            color: ${variables.asphalt};

            &:hover,
            &:focus,
            &:visited,
            &:active {
                color: ${variables.navyAsphalt};
            }
        }
        .ca-jumbotron {
            padding: 12px 21px;
            margin: 0;
        }

        .ca-tutorial__link {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            min-width: 285px;
            height: 60px;
            background-color: ${variables.white};
            padding: 0 10px;
            position: relative;

            .anticon {
                font-size: 25px;
                color: ${variables.asphalt};
                margin-left: 8px;
            }

            .ca-tutorial__splitter {
                height: 50%;
                box-shadow: 0 0 1px 1px ${variables.blue};
                padding: 0;
                margin: 10px;
            }

            .ca-tutorial__text {
                width: 100px;
            }

            .ca-tutorial__container {
                padding: 24px 0;
                border-collapse: separate;
                border-spacing: 10px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
`

export const StartUsingHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
`

export const StartUsingTitle = styled.div`
    text-align: center;
    font-size: 20px;
    margin-bottom: 8px;
    color: ${variables.text};
`

export const StartUsingBackupsLink = styled.a`
    font-size: 55px;
    font-weight: 100;
    line-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ca-icon {
        font-size: 55px;
        margin-right: 16px;
    }

    span {
        color: ${variables.brand};
    }
`

export const StartUsingServices = styled.div`
    background-color: ${variables.white};
    flex-direction: column;
    box-shadow: 0 0 3px 1px ${variables.blue};
    padding: 10px;
`

export const StartUsingServiceRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
`

export const StartUsingTutorials = styled.div`
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0.1;
    flex-wrap: wrap;
`

export const StyledLine = styled.div`
    box-shadow: 0 0 1px 1px ${variables.disabled};
    margin-top: ${variables.gutterLg};
`

export const StyledHowTo = styled.div`
    padding-top: ${variables.gutterLg};
    display: flex;
    align-items: center;
    gap: ${variables.gutterXs};
    font-size: ${variables.headingFontSize};
    color: ${variables.text};
    line-height: 32px;
    font-weight: 300;

    .anticon {
        font-size: 24px;
        color: ${variables.brand};
    }
`
