import React from 'react'
import {Tooltip} from 'antd'

import {StyledEntity, StyledCheckboxRow, Name, Email} from './StyledEntity'
import {siteFormatter} from 'ca-common/utils/entity'
import {SOURCE} from 'ca-common/common/enum'

export class Entity extends React.Component {
    formatEntityName = (source, entity) => {
        switch (source) {
            case SOURCE.SHAREPOINT_MULTI:
            case SOURCE.ONEDRIVE:
                return siteFormatter(entity)
            default:
                return entity
        }
    }
    render() {
        const {entity, checked, handleCheckboxChange, taskSettings} = this.props

        return (
            <StyledEntity>
                <StyledCheckboxRow checked={checked} value={entity.id} onChange={() => handleCheckboxChange(entity)}>
                    {entity.name && (
                        <Tooltip placement="top" title={entity.name}>
                            <Name>{this.formatEntityName(taskSettings.source, entity.name)}</Name>
                        </Tooltip>
                    )}
                    {entity.entity && (
                        <Tooltip placement="top" title={entity.entity}>
                            <Email>{this.formatEntityName(taskSettings.source, entity.entity)}</Email>
                        </Tooltip>
                    )}
                </StyledCheckboxRow>
            </StyledEntity>
        )
    }
}
