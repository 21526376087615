import React, {memo} from 'react'

import {TASK_STATUS} from 'ca-common/common/enum/TaskStatus'
import {TaskStatus} from 'ca-common/components/TaskStatus'
import {BACKUP_PROGRESS_STATUS} from 'ca-common/constants'

import {DuplicatePopover} from './DuplicatePopover'
import {MultiSourceProgressBar} from 'src/newcore/molecules/ProgressBar/MultiSourceProgressBar'
import {SingleSourceProgressBar} from 'src/newcore/molecules/ProgressBar/SingleSourceProgressBar'
import type {ProgressData} from 'ca-common/types'

type EntitiesTaskStatusProps = {
    status: TASK_STATUS
    duplicate?: {
        alias: string
        taskId: string
    }
    type?: string
    entityName?: string
    isMulti?: boolean
    progress?: {
        backupState: BACKUP_PROGRESS_STATUS
        currentCount: number
    }
    progressMulti?: ProgressData[]
}

export const EntitiesTaskStatus = memo(
    ({status, duplicate, type, entityName, isMulti, progress, progressMulti}: EntitiesTaskStatusProps): JSX.Element => {
        switch (status) {
            case TASK_STATUS.DUPLICATE:
                return (
                    <DuplicatePopover duplicate={duplicate} type={type} entityName={entityName}>
                        <TaskStatus status={status} />
                    </DuplicatePopover>
                )

            case TASK_STATUS.IN_PROCESS:
                return isMulti && progressMulti ? (
                    <MultiSourceProgressBar progressData={progressMulti} />
                ) : (
                    <SingleSourceProgressBar progress={progress} />
                )

            default:
                return <TaskStatus status={status} />
        }
    }
)
