import {combineReducers} from '@reduxjs/toolkit'

import {entitiesReducer as entities} from './entities'
import {entitiesStatus} from './entitiesStatus'
import {activeEntitiesReducer as activeEntities} from './activeEntities'
import {refreshEntitiesReducer as refreshEntities} from './refreshEntities'
import {selectedEntities} from './selectedEntities'
import {allEntities} from 'src/newcore/features/RecoveryWizard/redux'

export const backupEntitiesReducer = combineReducers({
    allEntities,
    entities,
    entitiesStatus,
    activeEntities,
    refreshEntities,
    selectedEntities
})
