import React from 'react'
import {Space} from 'antd'
import Icon from '@ant-design/icons'

import DownloadIcon from 'ca-common/icons/DownloadAll.svg'

import {DownloadLink} from './StyledDownloadInvoice'

type DownloadInvoiceProps = {
    invoiceNumber: number
    link: string
    onClick: () => void
}

export const DownloadInvoice = ({invoiceNumber, link, onClick}: DownloadInvoiceProps) => {
    return (
        <Space>
            <span>{invoiceNumber}</span>
            <DownloadLink onClick={onClick} pureLink to={link} target="_blank">
                <Icon component={DownloadIcon} />
            </DownloadLink>
        </Space>
    )
}
