import styled from 'styled-components'
import {Table} from 'antd'

import variables from 'ca-common/variables'

export const StyledTable = styled(Table)`
    .ant-table-thead {
        tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }

        span.ant-table-column-title {
            font-weight: 600;
            flex-basis: content;
            flex-grow: 1;
        }

        .ant-table-column-sorters {
            justify-content: flex-start;
        }
        .right-position > .ant-table-column-sorters {
            justify-content: flex-end;
        }
    }

    .ant-table-tbody > tr > td {
        border-bottom: none;
    }

    .ant-table-body {
        overflow-x: auto !important;
        border-radius: ${variables.btnBorderRadiusBase};
        cursor: default;

        .ant-table-tbody {
            > .ant-table-row > td {
                background-color: ${variables.white};
                border-bottom-color: ${variables.white};
            }

            .expand-parent,
            .expand-parent:hover,
            .ant-table-expanded-row,
            .ant-table-expanded-row:hover {
                background-color: ${variables.blueLight};
            }
        }
    }
`
