import {useState, useMemo, Dispatch, SetStateAction} from 'react'

import {SOURCE_INFO} from 'ca-common/common/enum'

import {sortSourcesByCost} from 'src/newcore/utils/sources'

export const useFilteredData = (data: any[]): [any[], Dispatch<SetStateAction<string>>] => {
    const [filter, setFilter] = useState('')
    const filteredData = useMemo(() => {
        return data
            .filter(item => {
                return (
                    SOURCE_INFO[item.source] &&
                    [item.alias, item.account].some(
                        criteria => criteria && criteria.toLowerCase().includes(filter.toLowerCase())
                    )
                )
            })
            .sort(sortSourcesByCost)
    }, [filter, data])

    return [filteredData, setFilter]
}
