import styled from 'styled-components'
import {Title} from 'src/newcore/features/SignUp/atoms/Title'
import CheckedLogo from 'ca-common/icons/CheckedBlue.svg'
import variables from 'ca-common/variables'

export const StyledLogo = styled(CheckedLogo)`
    width: 32px;
    height: 32px;
`
export const AdvantageString = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    :last-child {
        margin-bottom: 0;
    }
`
export const AdvantageText = styled(Title)`
    font-family: ${variables.publicPageFont};

    && {
        margin: 0 0 0 ${variables.gutterXs};
        font-size: ${variables.fontSizeBase};

        @media screen and (min-width: ${variables.screenDesktop}) {
            font-size: ${variables.subTitleFontSize};
        }
    }
`
