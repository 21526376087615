import {SOURCE, DROPBOX_TYPE} from 'ca-common/common/enum'
import {accountLike} from './AccountLike'
import {dropbox} from './Dropbox'
import {sharepointMulti} from './SharepointMulti'
import {ms365Groups} from './MS365Groups'
import {defaultSource} from './Default'
import {box} from './Box'
import {ms365} from './MS365'
import {gdriveTeam} from './gdriveTeam'

export const DEFAULT_PAGE = 1
export const PAGE_SIZE = 100
export const BOTTOM_ENTITY_GAP = 50

export const getTableConfigBySource = (source: SOURCE | DROPBOX_TYPE) => {
    switch (source) {
        case SOURCE.MS365:
            return ms365(source)
        case SOURCE.GOOGLEAPPS:
            return accountLike(source)
        case SOURCE.GDRIVETEAM:
            return gdriveTeam(source)
        case SOURCE.BOX:
            return box(source)
        case SOURCE.DROPBOX:
        case DROPBOX_TYPE.DROPBOX_PROFESSIONAL:
            return dropbox(source)
        case SOURCE.SHAREPOINT_MULTI:
        case SOURCE.ONEDRIVE:
            return sharepointMulti(source)
        case SOURCE.MS365GROUPS:
            return ms365Groups(source)
        default:
            return defaultSource(source as SOURCE)
    }
}

export const BACKUP_SIZE_WIDTH = 135
export const LAST_BACKUP_WIDTH = 135
export const ACTIONS_WIDTH = 110
export const TASK_STATUS_WIDTH = 170
export const TAGS_WIDTH = 150
export const LICENSING_WIDTH = 170
export const DROPDOWN_WIDTH = 50
