import React, {Component} from 'react'

import {adaptCompareStatusToProgressBox} from 'ca-common/common/enum/CompareItem'
import {compareItemPropTypes, compareItemDefaultProps} from 'ca-common/shapes/CompareItemPropTypes'

import {ProgressBox} from 'src/newcore/components/ProgressBox'
import {SFCBackup} from 'src/newcore/components/SFCBackup'

import {StyledCompareItem, StyledContent} from './StyledCompareItem'
import {CompareItemHeader} from './CompareItemHeader'
import {CompareItemFooter} from './CompareItemFooter'

export class CompareItem extends Component {
    static propTypes = compareItemPropTypes
    static defaultProps = compareItemDefaultProps

    render() {
        const {
            leftObject,
            rightObject,
            compareStatus,
            redirect,
            cancelTask,
            progress,
            exception,
            type,
            object,
            hasPermissions
        } = this.props

        return (
            <StyledCompareItem>
                <CompareItemHeader type={type} object={object} />
                <StyledContent>
                    <SFCBackup {...leftObject} />
                    <ProgressBox
                        status={adaptCompareStatusToProgressBox(compareStatus)}
                        progress={progress}
                        exception={exception}
                    />
                    <SFCBackup {...rightObject} />
                </StyledContent>
                <CompareItemFooter
                    status={compareStatus}
                    cancelTask={cancelTask}
                    redirect={redirect}
                    hasPermissions={hasPermissions}
                />
            </StyledCompareItem>
        )
    }
}
