import React from 'react'
import {useTranslation} from 'react-i18next'

import {OKTALandingForm} from 'src/newcore/features/OKTALanding/organisms'

import {Content, OktaTitle, OktaSubTitle, StyledOktaLogo} from './StyledOKTALanding'

export const OKTALanding = (): JSX.Element => {
    const {t} = useTranslation()

    return (
        <Content>
            <StyledOktaLogo />
            <OktaSubTitle>{t('OKTALandingPage:subtitle')}</OktaSubTitle>
            <OktaTitle>{t('OKTALandingPage:title')}</OktaTitle>
            <OKTALandingForm />
        </Content>
    )
}
