import React from 'react'
import {Tooltip} from 'antd'

import {MSTaskEntityType} from 'ca-common/types'
import {Email, Mailbox, StyledRadio} from 'src/newcore/features/RecoveryWizard/atoms/RadioMailbox/StyledRadioMailbox'

type RadioMailboxProps = {
    entity: MSTaskEntityType
    setMailboxToRecover: (arg0: MSTaskEntityType) => void
    wizardEntityId: string
}

export const RadioMailbox = ({entity, setMailboxToRecover, wizardEntityId}: RadioMailboxProps): JSX.Element => {
    return (
        <StyledRadio onChange={() => setMailboxToRecover(entity)} checked={entity.id === wizardEntityId}>
            <Tooltip title={entity.name}>
                <Mailbox>{entity.name}</Mailbox>
            </Tooltip>
            <Tooltip title={entity.entity}>
                <Email>{entity.entity}</Email>
            </Tooltip>
        </StyledRadio>
    )
}
