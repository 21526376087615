import React, {useCallback, useEffect, useState} from 'react'
import debounce from 'lodash/debounce'
import {ChooseFolderIcon, EmptyIcon} from 'src/newcore/features/Recovery/atoms'
import type {MS365SubSource} from 'ca-common/types'

type TablePlaceholderProps = {
    selectedFolder: string
    selectedSubSource: MS365SubSource
    tableSize: number
    treeSize: number
    loading: boolean
}

export const TablePlaceholder = ({
    selectedFolder,
    tableSize,
    treeSize,
    selectedSubSource,
    loading
}: TablePlaceholderProps) => {
    const [placeholder, setPlaceholder] = useState<JSX.Element | null>(null)
    const getPlaceholder = useCallback(
        debounce(state => {
            if (state.loading) {
                setPlaceholder(null)
            } else if (!state.selectedFolder && state.treeSize) {
                setPlaceholder(() => <ChooseFolderIcon selectedSubSource={state.selectedSubSource} />)
            } else if (!state.treeSize || !state.tableSize) {
                setPlaceholder(() => <EmptyIcon />)
            } else {
                setPlaceholder(null)
            }
        }, 200),
        []
    )

    useEffect(
        () =>
            getPlaceholder({
                selectedFolder,
                tableSize,
                treeSize,
                selectedSubSource,
                loading
            }),
        [selectedFolder, tableSize, treeSize, selectedSubSource, loading]
    )

    return placeholder
}
