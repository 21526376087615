import React from 'react'

import {SELECTION_TYPE} from 'ca-common/common/enum/Table'
import {CASelectItemCheckbox, CASelectItemRadio} from '../SelectItem'

export class CASelectItem extends React.Component {
    render() {
        const {item, rowSelection} = this.props

        switch (rowSelection.type) {
            case SELECTION_TYPE.RADIO:
                return <CASelectItemRadio item={item} rowSelection={rowSelection} />
            case SELECTION_TYPE.CHECKBOX:
                return <CASelectItemCheckbox item={item} rowSelection={rowSelection} />
            default:
                return null
        }
    }
}
