import React from 'react'
import {useTranslation} from 'react-i18next'

import BackupNow from 'ca-common/icons/backupActionsNew/BackupNow.svg'
import Resume from 'ca-common/icons/backupActionsNew/Activate.svg'
import Pause from 'ca-common/icons/backupActionsNew/Pause.svg'
import Cancel from 'ca-common/icons/backupActionsNew/Cancel.svg'
import Delete from 'ca-common/icons/backupActionsNew/Delete.svg'

import {
    ActionItem,
    ActionItemIcon,
    ActionItemText
} from 'src/newcore/features/Homepage/atoms/TaskActionsItem/StyledTaskActionsItem'
import {ACTIONS} from 'src/newcore/features/Homepage/utils/constants'

type TaskActionsItemProps = {
    action: ACTIONS
    onClick?: () => void
    disabled: boolean
}

export const TaskActionsItem = ({action, onClick, disabled}: TaskActionsItemProps): JSX.Element => {
    const {t} = useTranslation()
    const iconComponent = (action: ACTIONS) => {
        switch (action) {
            case ACTIONS.BACKUP_NOW:
                return BackupNow
            case ACTIONS.RESUME:
                return Resume
            case ACTIONS.PAUSE:
                return Pause
            case ACTIONS.DELETE:
                return Delete
            case ACTIONS.CANCEL:
                return Cancel
            default:
                return ''
        }
    }
    return (
        <ActionItem data-action={action.toLowerCase()} onClick={onClick} disabled={disabled}>
            <ActionItemIcon component={iconComponent(action)} />
            <ActionItemText>{t(`backups:actions:${action}`)}</ActionItemText>
        </ActionItem>
    )
}
