import React, {memo, useState} from 'react'
import moment, {Moment} from 'moment'
import {useHistory, useRouteMatch} from 'react-router'
import {useTranslation} from 'react-i18next'

import {ACTIVITY_STATUS, getActivityStatusFriendly, SOURCE} from 'ca-common/common/enum'
import {DAY_MONTH_FORMAT, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {makeUrlParams} from 'ca-common/utils/url'

import {SelectedDayInfoRecovery} from 'src/newcore/molecules/SelectedDayInfo'
import {RecoverySnapshotCalendar} from 'src/newcore/features/Recovery/molecules/RecoverySnapshotCalendar'
import {EVENT_NAMES, getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'

import {StyledChooseDateModal} from './StyledChooseDateModal'

type ChooseDateModalProps = {
    visible: boolean
    setVisible: (arg0: boolean) => void
    setDate: React.Dispatch<React.SetStateAction<number>>
    setIsPartialSelectedDate: React.Dispatch<React.SetStateAction<boolean>>
    date: number
    source: SOURCE
}

export const ChooseDateModal = memo(
    ({visible, setVisible, date, setDate, source, setIsPartialSelectedDate}: ChooseDateModalProps): JSX.Element => {
        const {
            params: {taskId}
        }: any = useRouteMatch('/recovery/:taskId/')
        const {t} = useTranslation()
        const history = useHistory()
        const [isValidDate, setIsValidDate] = useState(false)
        const [modalDate, setModalDate] = useState(date)
        const [isPartial, setIsPartial] = useState(false)

        const handleCalendarSelect = ({selectedDate, isPartial}: {selectedDate?: Moment; isPartial: boolean}) => {
            setModalDate(+moment(selectedDate, LONG_DATE_FORMAT).format(DAY_MONTH_FORMAT))
            setIsPartial(isPartial)
        }

        const handleConfirm = () => {
            track(EVENT_NAMES.RECOVERY_CHANGE_DATE, {
                Service: getServiceNameMixpanel(source),
                'Target Date Backup Status': isPartial
                    ? getActivityStatusFriendly(ACTIVITY_STATUS.PARTIALLY_SUCCEED)
                    : getActivityStatusFriendly(ACTIVITY_STATUS.SUCCEED)
            })
            setDate(modalDate)
            setIsPartialSelectedDate(Boolean(isPartial))
            setVisible(false)
            history.replace({
                search: makeUrlParams({date: modalDate})
            })
        }

        const handleCancel = () => {
            setModalDate(date)
            setVisible(false)
        }

        return (
            <StyledChooseDateModal
                width={916}
                title={t('recovery:chooseDateModal:title')}
                open={visible}
                onOk={handleConfirm}
                onCancel={handleCancel}
                closable={false}
                okButtonProps={{disabled: !isValidDate}}
                destroyOnClose={true}
                cancelButtonProps={{type: 'link'}}
                okText={t('recovery:chooseDateModal:okText')}
            >
                <RecoverySnapshotCalendar backupDate={modalDate} onSelect={handleCalendarSelect} />
                <SelectedDayInfoRecovery
                    setIsValidDate={setIsValidDate}
                    setBackupDate={setModalDate}
                    backupDate={modalDate}
                    taskId={taskId}
                    source={SOURCE.MS365}
                />
            </StyledChooseDateModal>
        )
    }
)
