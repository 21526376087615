import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {TWOFA} from 'src/ca-common/constants'
import {fetchTwoFa, clearTwoFa} from 'src/newcore/redux/modules/security'
import {isOpenID} from 'src/newcore/components/Settings/Utils'

import {Enabled} from './Enabled'
import {Disabled} from './Disabled'

export const TwoFaPage = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const twoFa = useAppSelector((state: AppState) => state.twoFa)
    const userInfo = useAppSelector((state: AppState) => state.userInfo)
    const companyInfo = useAppSelector((state: AppState) => state.companyInfo)

    useEffect(() => {
        if (isOpenID(userInfo?.response?.credentialType)) {
            history.push(TWOFA.SECURITY_PAGE)
        } else {
            dispatch(fetchTwoFa()).then((response: {payload: {data: {twoFaEnabled: any}}}) => {
                if (response.payload.data?.twoFaEnabled) {
                    history.push(TWOFA.SECURITY_PAGE)
                }
            })
        }
        return () => {
            dispatch(clearTwoFa())
        }
    }, [])

    return twoFa.status !== 'fulfilled' ? (
        <Spinner modifier="page" />
    ) : twoFa.response.twoFaEnabled ? (
        <Enabled companyName={companyInfo?.response?.name} />
    ) : (
        <Disabled companyName={companyInfo?.response?.name} twoFa={twoFa} />
    )
}
