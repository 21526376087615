import styled from 'styled-components'
import variables from 'ca-common/variables'

export const PageNavigation = styled.div`
    margin-bottom: ${variables.gutterSm};

    .ca-breadcrumbs {
        display: flex;
        list-style-type: none;
        font-size: ${variables.fontSizeBase};
        color: ${variables.asphalt};
        text-decoration: none;

        li {
            a {
                color: ${variables.asphalt};
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`
