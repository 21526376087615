import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

import {SourceIcon} from 'src/newcore/components/SourceIcon'
import {TaskStatus} from 'ca-common/components/TaskStatus'

export const StyledContainer = styled.div`
    height: 83px;
    background: ${variables.white};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.gutterSm} ${variables.gutterSm} ${variables.gutterXs};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${variables.gutterMd};
`

export const StyledTooltipIcon = styled(Icon)`
    margin-left: ${variables.gutterXs};
`

export const StyledSourceIcon = styled(SourceIcon)`
    margin-right: ${variables.gutterSm};
`

export const StyledSource = styled.span`
    font-weight: 600;
`

export const StyledTaskStatus = styled(TaskStatus)`
    align-items: center;
`
