import {contactsColumns} from './ContactsConfig'
import {emailsColumns} from './EmailsConfig'
import {tasksColumns} from './TaskConfig'
import {notesColumns} from './NotesConfig'
import {calendarsColumns} from './CalendarsConfig'
import type {MS365SubSource} from 'ca-common/types'
import {SOURCE} from 'src/ca-common/common/enum'

export const getRecoveryTableColumns = (subsource: MS365SubSource) => {
    switch (subsource) {
        case SOURCE.O365MAIL:
            return emailsColumns
        case SOURCE.O365CONTACTS:
            return contactsColumns
        case SOURCE.O365CALENDAR:
            return calendarsColumns
        case SOURCE.O365TASKS:
            return tasksColumns
        case SOURCE.O365NOTES:
            return notesColumns
    }
}
