import styled from 'styled-components'
import variables from 'ca-common/variables'
import {SeedingCenterContentWrapper} from '../StyledSeeding'

export const ObjectsListWrapper = styled.div`
    width: 100%;
    padding: ${variables.gutterMd} 0;
    height: 100%;
    overflow: auto;
`

export const ObjectCheckboxWrapper = styled.div`
    margin-bottom: ${variables.gutterSm};
`

export const ContentWrapper = styled(SeedingCenterContentWrapper)`
    padding: 0;
    display: flex;
    flex-direction: column;
`

export const ListWrapper = styled.div`
    padding: 0 ${variables.gutterMd};
    height: 400px;
`
