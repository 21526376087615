import React from 'react'
import _ from 'lodash'
import Icon from '@ant-design/icons'
import {withTranslation} from 'react-i18next'

import {ButtonHoverReverse} from 'ca-common/ui-lib/atoms/Button'
import Refresh from 'ca-common/icons/Refresh.svg'
import {Empty} from 'ca-common/ui-lib/atoms/Empty'

import {withPulling} from 'src/newcore/hoc/withPulling'
import {SeedingItem} from 'src/newcore/components/SeedingItem'
import {HeaderRow, StyledReplicatesList, Description} from './StyledReplicatesList'

const ReplicatesListRaw = ({data, taskAlias, getReplicates, t, getDownloadUrl}) => {
    return (
        <StyledReplicatesList>
            <HeaderRow>
                <Description>{t('replicateList:titleDescription')}</Description>
                <ButtonHoverReverse shape="circle" onClick={getReplicates}>
                    <Icon component={Refresh} />
                </ButtonHoverReverse>
            </HeaderRow>
            {data.length ? (
                _.map(data, (seedingItem, index) => {
                    return (
                        <SeedingItem
                            key={`${index}item`}
                            {...seedingItem}
                            taskAlias={taskAlias}
                            getDownloadUrl={getDownloadUrl}
                        />
                    )
                })
            ) : (
                <Empty description={t('replicateList:empty')} />
            )}
        </StyledReplicatesList>
    )
}

export const ReplicatesList = withTranslation()(withPulling(ReplicatesListRaw))
