import styled from 'styled-components'

export const IconWrapper = styled.div`
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`
