import {Modal} from 'antd'
import {getModalConfig} from './modalsConfig'
import type {ModalName} from './types'

export const openCustomModalNotification = (modalName: ModalName, text?: string) => {
    const config = getModalConfig(modalName, text)
    if (config) {
        Modal.info(config)
    }
}
