import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

import {SmallBackupChart} from 'src/newcore/features/BackupChart'

import {getSfcObjectHistory, clearSfcObjectHistory} from './redux'
import {FetchedStateStatuses} from 'ca-common/utils/fetchWrapperRT'

export type SfcObjectHistory = {
    snapshot: number
    total: number
    added: number
    updated: number
    deleted: number
    failed: boolean
}

export type ObjectHistory = {
    status: FetchedStateStatuses
    response?: {
        data: SfcObjectHistory[]
        success: boolean
    }
    error?: any
}

type ObjectHistoryProps = {
    objectName: string
    taskId: string
    actions?: any
    sfcObjectHistory: ObjectHistory
}

const ObjectHistoryRaw = (props: ObjectHistoryProps): JSX.Element => {
    const {objectName, taskId, actions, sfcObjectHistory} = props

    useEffect(() => {
        actions.getSfcObjectHistory({taskId, object: objectName})

        return () => {
            actions.clearSfcObjectHistory()
        }
    }, [])

    return <SmallBackupChart sfcObjectHistory={sfcObjectHistory} />
}

const mapStateToProps = (state: any) => ({
    sfcObjectHistory: state.backups.sfcObjectHistory
})

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(
        {
            getSfcObjectHistory,
            clearSfcObjectHistory
        },
        dispatch
    )
})

export const ObjectHistory = connect(mapStateToProps, mapDispatchToProps)(ObjectHistoryRaw)
