import React from 'react'
import {connect} from 'react-redux'

import {BULK_ACTIVATION_TYPE} from 'ca-common/common/enum/BulkActivation'
import {EmailList} from 'src/newcore/components/BulkActivation/CSV'
import {MS365GroupAccounts} from 'src/newcore/components/BulkActivation/MS365Groups'
import {ActiveDirectoryAccountsStep} from 'src/newcore/components/BulkActivation/ActiveDirectory'
import {SitesList} from 'src/newcore/components/BulkActivation/SharepointUrl/SitesList/SitesList'
import {GoogleOuAccounts} from 'src/newcore/components/BulkActivation/GoogleOU'

export const ThirdRaw = props => {
    const {wizard} = props

    const getComponentByFlow = flow => {
        switch (flow) {
            case BULK_ACTIVATION_TYPE.CSV:
                return <EmailList />
            case BULK_ACTIVATION_TYPE.GROUPS:
                return <MS365GroupAccounts />
            case BULK_ACTIVATION_TYPE.ACTIVE_DIRECTORY:
                return <ActiveDirectoryAccountsStep />
            case BULK_ACTIVATION_TYPE.SHAREPOINT_URL:
                return <SitesList />
            case BULK_ACTIVATION_TYPE.GOOGLE_OU:
                return <GoogleOuAccounts />
            default:
                return null
        }
    }
    return getComponentByFlow(wizard.data.bulkTypeFlow)
}

const mapStateToProps = state => ({
    wizard: state.wizard
})

export const Third = connect(mapStateToProps)(ThirdRaw)
