import React from 'react'
import {Col, Form, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {DROPBOX_TYPE} from 'ca-common/common/enum'
import {Button} from 'ca-common/ui-lib/atoms'
import {RadioButton, RadioGroup} from 'ca-common/ui-lib/components/RadioBox'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {BackupAuthBody, StyledBackupAuthForm} from '../StyledTemplates'

export const Dropbox = ({source, backupSettings, onSubmit, onCancel}: BackupAuthTemplate): JSX.Element => {
    const isNew = isEmpty(backupSettings)
    const {t} = useTranslation()
    const titleText = backupSettings.isDropboxProfessionalAccount
        ? t('forms:backupAuth:text:DropboxProfessionalTitle')
        : ''

    const initialValues = {
        alias: backupSettings.alias,
        account: backupSettings.account,
        isDropboxProfessionalAccount: String(!!backupSettings.isDropboxProfessionalAccount)
    }

    return (
        <StyledBackupAuthForm initialValues={initialValues} layout="horizontal" onFinish={values => onSubmit(values)}>
            <Header source={source} titleText={titleText} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>
                        <p>{t('forms:backupAuth:text:DropboxInfo')}</p>
                    </Col>
                    <Col>
                        <Form.Item name="isDropboxProfessionalAccount">
                            <RadioGroup disabled={!isNew}>
                                <RadioButton value={'false'} key={DROPBOX_TYPE.DROPBOX_BUSINESS}>
                                    {t('forms:backupAuth:fields:DropboxBusiness:label')}
                                </RadioButton>
                                <RadioButton value={'true'} key={DROPBOX_TYPE.DROPBOX_PROFESSIONAL}>
                                    {t('forms:backupAuth:fields:DropboxProfessional:label')}
                                </RadioButton>
                            </RadioGroup>
                        </Form.Item>
                    </Col>
                    {!isNew && (
                        <Col>
                            <TaskCreatedAccount account={backupSettings.account} />
                        </Col>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:backupAuth:text:DropboxSubmittingText')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
