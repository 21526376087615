import React from 'react'
import Icon from '@ant-design/icons'
import {Select, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Info from 'ca-common/icons/Info.svg'
import {getOptionsFromDataCenters} from 'ca-common/utils/Region'
import {VerticalFormItem, Label} from 'ca-common/ui-lib/components/FormItem'

const {Option} = Select

export const StorageRegion = props => {
    const {t} = useTranslation()
    const {storageRegion} = props

    return (
        <VerticalFormItem
            label={
                <Label>
                    <span>{t('forms:backupSettings:fields:storageRegion:label')}</span>
                    <Tooltip title={t('forms:backupSettings:fields:storageRegion:tooltip')}>
                        <Icon component={Info} />
                    </Tooltip>
                </Label>
            }
            colon={false}
            name="storageType"
            initialValue={storageRegion}
        >
            <Select disabled>
                {getOptionsFromDataCenters().map(region => (
                    <Option key={region.value} value={region.value}>
                        {region.label}
                    </Option>
                ))}
            </Select>
        </VerticalFormItem>
    )
}
