import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 250px;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
`
