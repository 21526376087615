import React from 'react'
import {MS365SubSource} from 'ca-common/types'
import {SubSourceNameWithIcon} from 'src/newcore/features/RecoveryWizard/atoms/SubSourceNameWithIcon/SubSourceNameWithIcon'
import {StyledSubSourcesList} from './StyledSubSourcesList'

export const SubSourcesList = ({sources}: {sources: Array<MS365SubSource>}) => {
    return (
        <StyledSubSourcesList>
            {sources.map(source => (
                <SubSourceNameWithIcon source={source} key={source} />
            ))}
        </StyledSubSourcesList>
    )
}
