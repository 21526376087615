import styled from 'styled-components'
import variables from 'ca-common/variables'

export const SeparatorWrapper = styled.div`
    display: block;
    position: relative;
    height: 44px;
    width: 100%;

    @media screen and (min-width: ${variables.screenMd}) {
        height: 44px;
    }
`

export const StyledLineSeparator = styled.div`
    height: 1px;
    background: ${variables.navyBorder};
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
`

export const TextSeparator = styled.p`
    font-size: ${variables.fontSizeBase};
    color: ${variables.signupText};
    left: 50%;
    top: 50%;
    margin: 0;
    line-height: 20px;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 0 ${variables.gutterSm} 0 ${variables.gutterSm};
    background-color: ${variables.white};
`
