import {i18n} from 'src/locales'

import Delete from 'ca-common/icons/smartAlerts/Delete.min.svg'
import Edit from 'ca-common/icons/smartAlerts/Edit.min.svg'
import Pause from 'ca-common/icons/smartAlerts/Pause.min.svg'
import Play from 'ca-common/icons/smartAlerts/Play.min.svg'

import {ACTION_DELETE, ACTION_ENABLE, ACTION_DISABLE, ACTION_EDIT} from 'src/newcore/features/SmartAlerts/lib/constants'

export const getSmartAlertActionIcon = action => {
    switch (action) {
        case ACTION_DELETE:
            return Delete

        case ACTION_EDIT:
            return Edit

        case ACTION_ENABLE:
            return Play

        case ACTION_DISABLE:
            return Pause

        default:
            return null
    }
}

export const getSmartAlertActionFriendly = action => {
    switch (action) {
        case ACTION_DELETE:
            return i18n.t('smartAlerts:action:delete')

        case ACTION_ENABLE:
            return i18n.t('smartAlerts:action:enable')

        case ACTION_DISABLE:
            return i18n.t('smartAlerts:action:disable')

        default:
            return ''
    }
}

export const getSmartAlertActionTooltipFriendly = action => {
    switch (action) {
        case ACTION_DELETE:
            return i18n.t('smartAlerts:actionTooltip:delete')

        case ACTION_ENABLE:
            return i18n.t('smartAlerts:actionTooltip:enable')

        case ACTION_DISABLE:
            return i18n.t('smartAlerts:actionTooltip:disable')

        case ACTION_EDIT:
            return i18n.t('smartAlerts:actionTooltip:edit')

        default:
            return ''
    }
}
