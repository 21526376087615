import React, {useState} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import capitalize from 'lodash/capitalize'
import {Tooltip} from 'antd'

import ArrowDown from 'ca-common/icons/ArrowDown.svg'

import {DATE_FORMAT_RECOVERY, LONG_DATE_FORMAT, TIME_FORMAT_RECOVERY} from 'ca-common/utils/datetime'
import {SOURCE, ACTIVITY_STATUS, RECOVERY_TYPE} from 'ca-common/common/enum'

import {
    BackupStatus,
    DateDay,
    DateTime,
    DateWrapper,
    DropDownIcon,
    StatusWrapper,
    StyledRadio
} from './StyledExactBackup'

import {SubsourceIcons} from 'src/newcore/atoms/SubsourceIcons'
import {track} from 'src/newcore/utils/mixpanel'
import {getServiceName} from 'src/newcore/utils/sources'

type ExactBackupProps = {
    date: number
    status: ACTIVITY_STATUS
    subsources: {
        dateTime: number
        status: ACTIVITY_STATUS
        source: SOURCE
    }[]
    setBackupDate: React.Dispatch<React.SetStateAction<number>>
    backupDate: number
    fullRecoveryOptions?: RECOVERY_TYPE
    source: SOURCE
    retention: boolean
}

export const ExactBackup = ({
    backupDate,
    date,
    status,
    subsources,
    setBackupDate,
    fullRecoveryOptions,
    source,
    retention
}: ExactBackupProps): JSX.Element => {
    const {t} = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const expandStatuses = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault()
        if (!expanded && fullRecoveryOptions) {
            track('Specific Backup Partially Succeeded Status Expanded', {
                Service: getServiceName({source, short: true}),
                'Recovery Operation': capitalize(fullRecoveryOptions)
            })
        }

        setExpanded(!expanded)
    }

    return (
        <Tooltip
            title={retention ? t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:retentionTooltip') : null}
        >
            <StyledRadio
                checked={backupDate === Number(moment(date, LONG_DATE_FORMAT).format(LONG_DATE_FORMAT))}
                onChange={() => setBackupDate(Number(moment(date, LONG_DATE_FORMAT).format(LONG_DATE_FORMAT)))}
                status={status}
                retention={retention}
            >
                {status === ACTIVITY_STATUS.SUCCEED ? (
                    <BackupStatus>
                        {retention
                            ? t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:statusRetention')
                            : t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:statusSuccess')}
                    </BackupStatus>
                ) : (
                    <StatusWrapper>
                        <BackupStatus>
                            {t('recovery:wizard:fullMailboxDateConfigure:dateConfiguration:statusPartial')}
                        </BackupStatus>
                        <DropDownIcon onClick={expandStatuses} expanded={expanded} component={ArrowDown} />
                    </StatusWrapper>
                )}
                <DateWrapper>
                    {!retention && <DateTime>{moment(date, LONG_DATE_FORMAT).format(TIME_FORMAT_RECOVERY)}</DateTime>}
                    <DateDay>{moment(date, LONG_DATE_FORMAT).format(DATE_FORMAT_RECOVERY)}</DateDay>
                </DateWrapper>
                {expanded && <SubsourceIcons subsources={subsources} />}
            </StyledRadio>
        </Tooltip>
    )
}
