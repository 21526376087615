import React from 'react'
import {ButtonProps} from 'antd/es/button'

import {OAUTH_SERVICES} from 'ca-common/common/enum'
import type {IconComponent} from 'ca-common/types'

import {IconWrapper, ServiceIcon, ServiceName, StyledButton} from './StyledButton'

type Props = {
    title: string
    icon: IconComponent
    service: OAUTH_SERVICES
    isNew?: boolean
} & Omit<ButtonProps, 'icon'>

export const OAuthButton = ({title, icon, service, isNew, ...rest}: Props): JSX.Element => {
    return (
        <StyledButton
            isNew={isNew}
            google={service === OAUTH_SERVICES.GOOGLE}
            {...rest}
            block
            size="large"
            type="primary"
        >
            <IconWrapper>
                <ServiceIcon component={icon} />
            </IconWrapper>
            <ServiceName>{title}</ServiceName>
        </StyledButton>
    )
}
