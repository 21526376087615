import {SOURCE} from 'ca-common/common/enum'
import emailsIcon from 'ca-common/icons/subServices/Emails.svg'
import contactsIcon from 'ca-common/icons/subServices/Contacts.svg'
import tasksIcon from 'ca-common/icons/subServices/Tasks.svg'
import calendarsIcon from 'ca-common/icons/subServices/Calendar.svg'
import notesIcon from 'ca-common/icons/subServices/Notes.svg'
import type {IconComponent} from 'ca-common/types/antdTypes'
import type {MS365SubSource} from 'ca-common/types'

export function getSubsourceIcon(subsourceName: MS365SubSource): IconComponent | undefined {
    switch (subsourceName) {
        case SOURCE.O365MAIL:
            return emailsIcon
        case SOURCE.O365TASKS:
            return tasksIcon
        case SOURCE.O365NOTES:
            return notesIcon
        case SOURCE.O365CONTACTS:
            return contactsIcon
        case SOURCE.O365CALENDAR:
            return calendarsIcon
    }
}
