import React from 'react'
import {SearchOutlined} from '@ant-design/icons'
import {Form} from 'antd'
import {useTranslation} from 'react-i18next'
import debounce from 'lodash/debounce'
import {DEBOUNCE_TIMEOUT, MIN_LENGTH_TO_SEARCH} from 'src/ca-common/constants'
import {hasSomeErrorsAntd4} from 'src/ca-common/utils/form'
import {SearchInput, StyledForm} from './StyledGoogleOUListForm'

type GoogleOUFormProps = {
    handleFilterSubmit: (values: any) => void
}

export const GoogleOUForm = (props: GoogleOUFormProps) => {
    const {t} = useTranslation()
    const {handleFilterSubmit} = props
    const [form] = Form.useForm()

    const formHandler = (values: any) => {
        if (!hasSomeErrorsAntd4(form.getFieldsError())) {
            handleFilterSubmit(values)
        }
    }
    return (
        <StyledForm layout="inline" onValuesChange={debounce(formHandler, DEBOUNCE_TIMEOUT)} form={form}>
            <Form.Item
                name="query"
                rules={[
                    {
                        min: MIN_LENGTH_TO_SEARCH,
                        message: t('forms:common:validation:minLength', [MIN_LENGTH_TO_SEARCH])
                    }
                ]}
            >
                <SearchInput
                    prefix={<SearchOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                    placeholder={t('backups:bulkActivation:fields:searchGroup:placeholder')}
                    allowClear
                />
            </Form.Item>
        </StyledForm>
    )
}
