import React from 'react'
import {Spin} from 'antd'
import {connect} from 'react-redux'

import {DeltaChart} from 'src/newcore/components/DeltaChart'
import {EntitySubSourcesList} from './EntitySubSourcesList'

import {Wrapper} from './StyledBackupEntityDrilldown'

class BackupEntityDrilldownRaw extends React.Component {
    render() {
        const {entity, expanded, backupEntityDeltaStatus, entitySubSourcesStatus} = this.props

        return (
            expanded && (
                <Spin spinning={[backupEntityDeltaStatus, entitySubSourcesStatus].some(i => i === 'pending')}>
                    <Wrapper>
                        <DeltaChart entityId={entity.id} />
                        <EntitySubSourcesList entityId={entity.id} />
                    </Wrapper>
                </Spin>
            )
        )
    }
}

const mapStateToProps = state => {
    const {backupEntityDelta, entitySubSources} = state.backups.backupDrilldown

    return {
        backupEntityDeltaStatus: backupEntityDelta.status,
        entitySubSourcesStatus: entitySubSources.status
    }
}

export const BackupEntityDrilldown = connect(mapStateToProps)(BackupEntityDrilldownRaw)
