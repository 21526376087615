import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Modal} from 'antd'

import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledModal = styled(Modal)`
    .ant-modal-footer {
        padding: ${variables.gutterSm} ${variables.gutterMd};
    }

    .ant-modal-body {
        padding: ${variables.gutterLg} 56px;
    }
`

export const StyledConfirmText = styled.div`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.subTitleFontSize};
    font-weight: 300;
    color: ${variables.text};
`
export const StyledConfirmTitle = styled.span`
    font-family: ${variables.mainFont};
    font-weight: 400;
    font-size: ${variables.headingFontSize};
    color: ${variables.text};
`
export const StyledImageWrapper = styled.span`
    justify-content: space-around;
    margin-right: ${variables.gutterXs};
`

export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const StyledFooterButton = styled(Button)`
    text-transform: uppercase;
`
export const StyledIcon = styled(Icon)`
    font-size: 24px;
`
