import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledExistedTask = styled.div`
    padding: ${variables.gutterXs};
    display: flex;
    align-items: center;
    border: 1px solid ${variables.interaction};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: ${variables.borderRadiusBase};
`

export const StyledIcon = styled(Icon)`
    margin-right: 4px;

    svg {
        fill: none;
    }
`

export const StyledInfoWrapper = styled.div`
    max-width: 70%;
    margin-left: ${variables.gutterSm};
`

export const StyledTaskName = styled.div`
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.navyBlue};
    margin-bottom: -2px;
`

export const StyledSourceName = styled.div`
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: ${variables.navyBlue};
    margin-bottom: 2px;
`

export const StyledStatusWrapper = styled.div`
    font-size: 8px;
    line-height: 10px;
`
