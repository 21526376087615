import type {BaseQueryFn} from '@reduxjs/toolkit/query'
import type {AxiosRequestConfig, AxiosResponse} from 'axios'

export const axiosBaseQuery = <Response = any>(): BaseQueryFn<
    {
        url: string
        method: (commandName: string, data: any) => Promise<Response>
        data?: AxiosRequestConfig['data']
    },
    unknown,
    unknown
> => {
    return async ({url, method, data}) => {
        try {
            return {data: await method(url, data)}
        } catch (error) {
            return {error}
        }
    }
}
