import styled from 'styled-components'
import {headerGradient} from 'src/ca-common/features/BaseHeader/lib/constants'
import variables from 'ca-common/variables'
import Logo from 'ca-common/icons/Logo.svg'
import {Button} from 'ca-common/ui-lib/atoms'

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    height: 80px;
    z-index: 100;
    width: 100%;
    padding: 0 60px 0 ${variables.gutterXxl};
    background: ${headerGradient};
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
`

export const StyledButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${variables.gutterSm};
`

export const PublicHeaderLogo = styled.div`
    transition: none;
    width: clamp(128px, 20vw, 180px);

    a {
        height: 96px;
        display: flex;
        padding: ${variables.gutterMd} 0;
    }

    &:hover {
        opacity: 0.8;
    }
`

export const LogoIcon = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
`
export const CALogo = styled(Logo)`
    width: 260px;
    height: auto;

    @media (max-width: ${variables.screenSm}) {
        width: 200px;
    }
`

export const LoginBtn = styled(Button)`
    margin-left: ${variables.gutterXs};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.signupText};
    background-color: ${variables.brightOrange};
    border-width: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;

    &:hover {
        color: ${variables.signupText};
        background-color: ${variables.brightOrange};
    }

    svg {
        font-size: 20px;
    }
`
