import {useCallback, useEffect, useRef} from 'react'

import {useAppDispatch} from 'src/newcore/components/Root'
import {changeConfig, changeData} from 'src/newcore/redux/modules/wizard'

import {AnonymizationSettingsChanges} from 'src/newcore/components/Seeding/DataAnonymizationStep/types'

type BeforeStepChangeFnType = () => void

export const useBeforeAnonymizationSettingsStepChange = (
    anonymizationSettingsChanges: AnonymizationSettingsChanges,
    isCurrentStepActive?: boolean
) => {
    const dispatch = useAppDispatch()

    const getSetterDataToWizardData = useCallback(
        (anonymizationSettingsChanges: AnonymizationSettingsChanges) => () => {
            if (isCurrentStepActive) {
                dispatch(changeData({anonymizationSettingsChanges}))
                dispatch(changeConfig({modalWidth: null, beforeStepChange: undefined}))
            }
        },
        [isCurrentStepActive]
    )

    const beforeStepChangeRef = useRef<null | BeforeStepChangeFnType>(
        getSetterDataToWizardData(anonymizationSettingsChanges)
    )

    useEffect(() => {
        if (isCurrentStepActive) {
            dispatch(
                changeConfig({
                    beforeStepChange: () => beforeStepChangeRef.current && beforeStepChangeRef.current()
                })
            )
        }
    }, [isCurrentStepActive])

    const reinitBeforeStepChange = (anonymizationSettingsChanges: AnonymizationSettingsChanges) => {
        beforeStepChangeRef.current = getSetterDataToWizardData(anonymizationSettingsChanges)
    }

    return {reinitBeforeStepChange}
}
