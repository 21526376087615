import {useTranslation} from 'react-i18next'

import {OAUTH_SERVICES_LINK} from 'ca-common/constants'
import {OAUTH_SERVICES} from 'ca-common/common/enum'
import type {OAuthServiceType, IconComponent} from 'ca-common/types'
import variables from 'ca-common/variables'

import Google from 'ca-common/icons/oAuthServices/googleNew.svg'
import Azure from 'ca-common/icons/oAuthServices/microsoft.svg'
import Okta from 'ca-common/icons/oAuthServices/OKTA.svg'

export function getOauthServiceIcon(service: OAUTH_SERVICES): IconComponent {
    switch (service) {
        case OAUTH_SERVICES.AZURE:
            return Azure
        case OAUTH_SERVICES.GOOGLE:
            return Google
        case OAUTH_SERVICES.OKTA:
            return Okta
    }
}

export function getOauthServiceTitle(service: OAUTH_SERVICES): string {
    const {t} = useTranslation()

    switch (service) {
        case OAUTH_SERVICES.AZURE:
            return t('sign:services:azure')
        case OAUTH_SERVICES.GOOGLE:
            return t('sign:services:google')
        case OAUTH_SERVICES.OKTA:
            return t('sign:services:okta')
    }
}

export const getPortalSignUpOAuthServices = (LINKS: typeof OAUTH_SERVICES_LINK.PORTAL): OAuthServiceType[] => [
    {
        service: OAUTH_SERVICES.GOOGLE,
        href: LINKS.GOOGLE,
        color: variables.brightBlue
    },
    {
        service: OAUTH_SERVICES.AZURE,
        href: LINKS.AZURE,
        color: variables.navyBlue
    }
]

export const getPortalSignInOAuthServices = (LINKS: typeof OAUTH_SERVICES_LINK.PORTAL): OAuthServiceType[] => [
    {
        service: OAUTH_SERVICES.GOOGLE,
        href: LINKS.GOOGLE,
        color: variables.brightBlue
    },
    {
        service: OAUTH_SERVICES.AZURE,
        href: LINKS.AZURE,
        color: variables.navyBlue
    },
    {
        service: OAUTH_SERVICES.OKTA,
        href: LINKS.OKTA,
        color: variables.navyBlue
    }
]

export const PP_SIGNIN_OAUTH_CONFIG = [
    {
        service: OAUTH_SERVICES.GOOGLE,
        href: OAUTH_SERVICES_LINK.PARTNER_PORTAL.GOOGLE,
        color: variables.brightBlue
    },
    {
        service: OAUTH_SERVICES.AZURE,
        href: OAUTH_SERVICES_LINK.PARTNER_PORTAL.AZURE,
        color: variables.navyBlue
    }
]
